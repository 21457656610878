// import { IUserName } from 'app/pages/UsersPage/SelectdRowActions/IUsersRoles';
import {
  IRegisterUserModelDto,
  OTPModel,
  ResponseAccountModel,
} from 'app/pages/Account/Register/components/utils';
import { IUsers } from 'app/pages/UsersPage/IUsers';
import { AxiosRequestConfig } from 'axios';
import { httpClient } from './HttpClient';

export const UsersApi = {
  exportForImport: (payload: string) => {
    const data = {
      users: payload,
    };
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Disposition': 'attachment; filename=template.xls',
        'Content-Type': 'application/vnd.ms-excel',
        //'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    };
    const url = `/api/odata/v4/Users/ExportForImport`;
    return httpClient.post(url, data, config);
  },
  sendCode: (payload: OTPModel) => {
    const data = {
      user: { UserName: payload.UserName, Email: payload.Email, Token: '' },
    };
    const url = '/api/odata/v4/Users/SendVerificationCode';
    return httpClient.post<ResponseAccountModel>(url, data);
  },
  verifyCode: (payload: { model: OTPModel; code: string }) => {
    const data = {
      user: {
        UserName: payload.model.UserName,
        Email: payload.model.Email,
        Token: payload.code,
      },
    };
    const url = '/api/odata/v4/Users/VerifyCode';
    return httpClient.post<ResponseAccountModel>(url, data);
  },
  createAccount: (payload: IRegisterUserModelDto) => {
    const data = {
      user: payload,
    };
    const url = '/api/odata/v4/Users/CreateAccount';
    return httpClient.post<ResponseAccountModel>(url, data);
  },
  checkCaptcha: (payload: string | null) => {
    const BOOKIT_APP_SECRET_KEY = '6LcO4i4UAAAAAPFgGSxt2y3qlQKN5s2iSK-nPtMi';
    const reqUrl = `https://www.google.com/recaptcha/api/siteverify?secret=${BOOKIT_APP_SECRET_KEY}&response=${payload}`;
    return httpClient.post(reqUrl);
  },
  checkUserName: (payload: string) => {
    const data = {
      user: payload,
    };
    const url = '/api/odata/v4/Users/ValidateUserName';
    return httpClient.post<ResponseAccountModel>(url, data);
  },
  checkEmail: (payload: string) => {
    const data = {
      email: payload,
    };
    const url = '/api/odata/v4/Users/ValidateEmail';
    return httpClient.post<ResponseAccountModel>(url, data);
  },
  sendRegistrationEmail: (payload: string) => {
    const data = {
      user: payload,
    };
    const url = '/api/odata/v4/Users/SendRegistrationEmail';
    return httpClient.post<ResponseAccountModel>(url, data);
  },
  setActiveAdGroup: (adGroup: string) => {
    const url = '/api/odata/v4/ActiveUserGroup/SetActiveAdGroup';
    const data = { adGroup: adGroup };
    return httpClient.post(url, data);
  },
  getUser: (userName: string, params: object) => {
    const url = `/api/odata/v4/Users/${userName}`;
    return httpClient.get<IUsers>(url, params);
  },
  updateDefaultUserGroup: (userName: string, userGorup?: string) => {
    const data = {
      usergroup: {
        UserName: userName,
        UserGroupId: userGorup,
      },
    };
    const url = `/api/odata/v4/Users/SetDefaultUserGroup`;
    return httpClient.post(url, data);
  },
  updateDefaultBudget: (userName: string, budgetId?: number) => {
    const data = {
      budget: {
        UserName: userName,
        BudgetId: budgetId,
      },
    };
    const url = `/api/odata/v4/Users/SetDefaultBudget`;
    return httpClient.post(url, data);
  },
  updateInternalId: (userName: string, internalId?: string) => {
    const data = {
      internal: {
        UserName: userName,
        InternalId: internalId,
      },
    };
    const url = `/api/odata/v4/Users/SetInternalId`;
    return httpClient.post(url, data);
  },
  updateDtuCardId: (userName: string) => {
    const data = {
      username: userName,
    };
    const url = `/api/odata/v4/Users/DtuRequestCardNumber`;
    return httpClient.post(url, data);
  },
  updateInternalId2: (userName: string, internalId?: string) => {
    const data = {
      internal: {
        UserName: userName,
        InternalId: internalId,
      },
    };
    const url = `/api/odata/v4/Users/SetInternalId2`;
    return httpClient.post(url, data);
  },
  syncLdapUser: (userName: string) => {
    const data = {
      username: userName,
    };
    const url = `/api/odata/v4/Users/SyncLdapUser`;
    return httpClient.post(url, data);
  },
};
