import { useScriptTag } from '../../hooks/useScriptTag';

export interface usePrintScriptTagsProps {
  dymoEnabled: boolean;
  zebraEnabled: boolean;
}

/**
 * Adds the required legacy printer frameworks to the document.
 * Required for the Print*** to operate
 */
export function usePrintScriptTags({
  dymoEnabled,
  zebraEnabled,
}: usePrintScriptTagsProps) {
  useScriptTag({
    src: dymoEnabled && '/Scripts/dymo.connect.framework.js',
    cleanup: false,
  });
  useScriptTag({
    src: zebraEnabled && '/Script/BrowserPrint-3.0.216.min.js',
    cleanup: false,
  });
  useScriptTag({
    src: zebraEnabled && '/Script/BrowserPrint-Zebra-1.0.216.min.js',
    cleanup: false,
  });
}
