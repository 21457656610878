import {
  BaseURLPicker,
  BaseURLPickerProps,
} from 'app/components/BasicPickers/BaseURLPicker';
import * as React from 'react';
import { Entity } from 'types/common';

export interface ArticleWorkOrdersPickerProps
  extends Omit<BaseURLPickerProps<Entity<number>>, 'url'> {}

export function ArticleWorkOrdersPicker(props: ArticleWorkOrdersPickerProps) {
  return <BaseURLPicker url="/api/odata/v4/WorkOrders" {...props} />;
}
