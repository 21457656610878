import { AssetQuickSearchDetails } from 'app/components/AssetQuickSearch/slice/types';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { toURLSearchParams } from 'app/components/BasicTable/withSavedHistory';
import { initAssetsData } from 'app/components/pickers/MultiSelectPickers/AssetsPicker';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { CreateNewRequest } from 'app/pages/ServiceRequestListPage/CreateNewRequest';
import { useAppSettingsSlice } from 'app/slice';
import {
  selectAuthenticatedUser,
  selectIsAuthenticated,
  selectPublicUrl,
  selectShowAllCores,
} from 'app/slice/selectors';
import { AvailabilityTypes } from 'api/odata/generated/enums/AvailabilityTypes';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-table';
import { Entity } from 'types/common';
import { isEmptyOrWhitespace } from 'utils/typeUtils';
import { buildURL } from 'utils/url-utils';
import { getRequiredFilter } from '../DefaultFilter';
import { IMyAssetsRow } from '../IMyAssetsRow';
import { AssetsSelectionTable } from './AssetsSelectionTable';
import { useAssetsSelectionSlice } from './slice';
import { selectAssetsSelectionFilters } from './slice/selectors';
import { useAddNewActions } from './useAddNewActions';
import { RecentAssets } from './RecentAssets';
import { useSchedulerSlice } from 'app/pages/CalendarPage/Scheduler/slice';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import { Condition } from 'api/odata/ODataFilter';
import {
  selectExpandedSidePanel,
  selectIsCoverExpanded,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { DetectIsMobile } from 'utils/mobileDetect';
import { CustomEmptyTableProps } from 'app/components/BasicTable/components/CustomEmptyTable';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { BasicToggle } from 'app/components/BasicToggle';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { Roles } from 'api/odata/generated/enums/Roles';
import { AdminActions } from 'app/components/Admin/AdminActions';
import useSidePanelState, {
  SidePanelCloseState,
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { AssetPopUpProps } from 'app/pages/AssetPopUp';

export type AssetActionType =
  | 'Reservation'
  | 'WorkOrder'
  | 'OfflineService'
  | 'Request'
  | 'StartUsage'
  | 'TrainingSession'
  | 'AssetSearch';
export interface AssetsSelectionProps
  extends CoverProps,
    SidePanelContentProps {
  assetIds?: number[];
  serviceIds?: number[];
  offTypeIds?: number[];
  requestTypeIds?: number[];
  actionType?: AssetActionType;
  customPredicates?: (string | Condition<IMyAssetsRow>)[];
  pageLink?: string;
  onClose?: () => void;
  useCustomFilter?: boolean;
  customAppliedFilters?: IFilterSettings<IMyAssetsRow>[];
  customAvailableFilters?: IFilterSettings<IMyAssetsRow>[];
  customFilterChange?: (items: IFilterSettings<IMyAssetsRow>[]) => void;
  serviceTypeId?: number;
  assetCatId?: number;
  assetCatGroupId?: number;
  searchTerm?: string;
  hideCoresToggle?: boolean;
  serviceGroupId?: number;
}

export function AssetsSelection(props: AssetsSelectionProps) {
  const {
    useSidePanel,
    actionType = 'AssetSearch',
    assetIds,
    serviceIds,
    offTypeIds,
    requestTypeIds,
    customPredicates,
    onClose,
    serviceTypeId,
    searchTerm,
    hideCoresToggle,
    assetCatId,
    assetCatGroupId,
    closeCover,
    serviceGroupId,
    // useCustomFilter,
    // customAppliedFilters,
    // customAvailableFilters,
    // customFilterChange
  } = props;
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const filters = useSelector(selectAssetsSelectionFilters);
  const showAllCores = useSelector(selectShowAllCores);
  const coverExpanded = useSelector(selectIsCoverExpanded);
  //const hideNavigation = useSelector(selectHideNavigation);
  const dispatch = useDispatch();
  const { actions } = useAssetsSelectionSlice();
  const { t } = useTranslation();
  const { actions: appSettingsAction } = useAppSettingsSlice();
  const publicUrl = useSelector(selectPublicUrl);
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const isMobile = DetectIsMobile();
  // const [cover, setCover] = React.useState<React.ReactNode>();
  const { cover, openPanel, closePanel, coverClosed, onCloseCover } =
    useSidePanelState();
  const [openRequest, setOpenRequest] = React.useState<boolean | undefined>(
    undefined,
  );
  const [requestId, setRequestId] = React.useState<number | undefined>(
    undefined,
  );
  const [selectedAssets, setSelectedAssets] = React.useState<Entity<number>[]>(
    [],
  );
  const showShortView = React.useMemo(
    () =>
      isMobile ||
      (useSidePanel && !sidePanelExpanded) ||
      (props.isCover && !coverExpanded),
    [coverExpanded, isMobile, props.isCover, sidePanelExpanded, useSidePanel],
  );

  const { actions: schedActions } = useSchedulerSlice();
  // const multipleMode = useSelector(selectMultipleModeState);
  const pagelink = React.useMemo(
    () =>
      !!props.pageLink
        ? props.pageLink
        : buildURL(
            `${publicUrl}AssetSearch`,
            Object.assign({}, filters, {
              serviceTypeId: serviceTypeId,
              searchTerm: searchTerm,
              actionType: actionType,
              AssetCatGroupId: assetCatGroupId,
              AssetCatId: assetCatId,
            }),
          ),
    [
      actionType,
      assetCatGroupId,
      assetCatId,
      filters,
      props.pageLink,
      publicUrl,
      searchTerm,
      serviceTypeId,
    ],
  );
  // const closeCover = () => {
  //   setCover(undefined);
  // };

  const closeSidePanel = React.useCallback(() => {
    closePanel({
      isCover: props.isCover,
      useSidePanel: true,
      onClose: () => {
        if (hideCoresToggle) {
          dispatch(appSettingsAction.setShowAllCores(false));
        }
      },
    } as SidePanelCloseState);
    // if (props.isCover) {
    //   if (!!onClose) {
    //     onClose();
    //   } else {
    //     dispatch(layoutActions.resetSidePanel());
    //   }
    //   if (hideCoresToggle) {
    //     dispatch(appSettingsAction.setShowAllCores(false));
    //   }
    // } else {
    //   dispatch(layoutActions.resetSidePanel());
    //   if (hideCoresToggle) {
    //     dispatch(appSettingsAction.setShowAllCores(false));
    //   }
    // }
  }, [appSettingsAction, closePanel, dispatch, hideCoresToggle, props.isCover]);
  const openAsset = React.useCallback(
    (id: number) => {
      const assetProps: AssetPopUpProps = {
        identifier: {
          id: id,
        },
        showAllCores: showAllCores,
        useSidePanel: true,
      };
      openPanel({
        renderPageType: RenderPageType.AssetDetails,
        renderPageProps: assetProps,
        expanded: false,
        useSidePanel: useSidePanel,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [openPanel, showAllCores, useSidePanel],
  );
  // const saveCalendarMultiMode = React.useCallback((value: boolean) => {
  //   dispatch(
  //     appSettingsAction.updateUserProfileSettings({
  //       key: 'BookMultipleInstruments',
  //       model: {
  //         UserName: authenticatedUser?.Id,
  //         Key: 'BookMultipleInstruments',
  //         IsMultiple: value,
  //       } as UserProfileSettings,
  //     }),
  //   );
  // }, [appSettingsAction, authenticatedUser?.Id, dispatch]);
  const IsAdmin = React.useCallback(
    (asset: IMyAssetsRow) => {
      return isAuthenticated
        ? authenticatedUser?.IsAllGroupOrLabTechAdmin(
            [asset.ServiceGroupId ?? 0],
            [
              {
                Id: asset.ServiceId,
                Name: asset.Name,
                ServiceTypeId: asset.ServiceTypeId,
              } as IServiceTypeFilterDto,
            ],
          )
        : false;
    },
    [isAuthenticated, authenticatedUser],
  );
  const isRowSelectable = React.useCallback(
    (row: Row<IMyAssetsRow>): boolean => {
      if (actionType === 'OfflineService') {
        return (
          IsAdmin(row.original) ||
          (row.original.AllowToUser === true && row.original.StaffOnly !== true)
        );
      }
      return authenticatedUser?.IsReadOnlyUser() === false;
    },
    [IsAdmin, actionType, authenticatedUser],
  );
  const {
    createOffline,
    createReservation,
    createWorkOrder,
    startUsage,
    addTrainingSession,
  } = useAddNewActions();
  const allowMultiToggle = React.useMemo(() => {
    switch (actionType) {
      case 'Reservation':
      case 'WorkOrder':
      case 'OfflineService':
        return true;
      case 'Request':
      case 'StartUsage':
      case 'TrainingSession':
      case 'AssetSearch':
      default:
        return false;
    }
  }, [actionType]);
  const allowAllCoresToggle = React.useMemo(() => {
    switch (actionType) {
      case 'AssetSearch':
        return !hideCoresToggle;
      case 'Request':
      case 'StartUsage':
      case 'TrainingSession':
      case 'Reservation':
      case 'WorkOrder':
      case 'OfflineService':
      default:
        return false;
    }
  }, [actionType, hideCoresToggle]);
  const setfilterCallback = React.useCallback(
    (v: IFilterSettings<IMyAssetsRow>[] | undefined) => {
      const res = {};
      if (!!v) {
        toURLSearchParams(v).forEach((val, name) => {
          res[name] = val;
        });
      }
      dispatch(actions.setFilters(!v || v.length === 0 ? undefined : res));
    },
    [actions, dispatch],
  );
  const availabilityTypesArray = React.useMemo(() => {
    switch (actionType) {
      case 'Reservation':
      case 'StartUsage':
        return [
          AvailabilityTypes.FreelyBookable as number,
          AvailabilityTypes.BookableWApproval as number,
          AvailabilityTypes.TrainingProgram as number,
          AvailabilityTypes.LoanableEquipment as number,
          AvailabilityTypes.LectureRoom as number,
        ];
      case 'OfflineService':
        return [AvailabilityTypes.ServiceRelated as number];
      case 'Request':
        return [AvailabilityTypes.RequestRelated as number];
      case 'WorkOrder':
        return [
          AvailabilityTypes.FreelyBookable as number,
          AvailabilityTypes.BookableWApproval as number,
          AvailabilityTypes.TrainingProgram as number,
          AvailabilityTypes.LoanableEquipment as number,
          AvailabilityTypes.LectureRoom as number,
          AvailabilityTypes.ServiceRelated as number,
          AvailabilityTypes.RequestRelated as number,
        ];
      case 'TrainingSession':
        return [
          AvailabilityTypes.FreelyBookable as number,
          AvailabilityTypes.BookableWApproval as number,
          AvailabilityTypes.TrainingProgram as number,
          AvailabilityTypes.LoanableEquipment as number,
          AvailabilityTypes.LectureRoom as number,
        ];
      // case 'AssetSearch':
      //   if (!!serviceTypeId) {
      //     switch (serviceTypeId) {
      //       case ServiceType.Online:
      //         return [
      //           AvailabilityTypes.FreelyBookableBillable as number,
      //           AvailabilityTypes.ApprovalRequiredBillable as number,
      //           AvailabilityTypes.FreelyBookable as number,
      //           AvailabilityTypes.BookableWApproval as number,
      //           AvailabilityTypes.TrainingProgram as number,
      //           AvailabilityTypes.LoanableEquipment as number,
      //           AvailabilityTypes.LectureRoom as number,
      //         ];
      //       case ServiceType.Offline:
      //         return [AvailabilityTypes.ServiceRelated as number];
      //       case ServiceType.Sample:
      //         return [AvailabilityTypes.RequestRelated as number];
      //       default:
      //         return [];
      //     }
      //   }
      //   return [];
      default:
        return [];
    }
  }, [actionType]);
  const screenName = React.useMemo(() => {
    switch (actionType) {
      case 'Reservation':
        return t(translations.CreateReservation) as string;
      case 'StartUsage':
        return t(translations.StartUsage) as string;
      case 'OfflineService':
        return t(translations.SelectConsumables) as string;
      case 'Request':
        return t(translations.CreateRequest) as string;
      case 'WorkOrder':
        return t(translations.CreateAWorkOrder) as string;
      case 'TrainingSession':
        return t(translations.AddTrainingSession);
      case 'AssetSearch':
        return t(translations.InstrumentAssetSearch) as string;
      default:
        return t(translations.CreateReservation) as string;
    }
  }, [actionType, t]);
  const noTableData = React.useMemo(() => {
    switch (actionType) {
      case 'OfflineService':
        return {
          headTitle: t(translations.NoConsumablesHeadTitle) as string,
          title: t(translations.NoConsumablesTitle) as string,
        } as CustomEmptyTableProps;
      case 'Reservation':
      case 'StartUsage':
      case 'Request':
      case 'WorkOrder':
      case 'TrainingSession':
      default:
        return undefined;
    }
  }, [actionType, t]);
  const requiredFilter = (search: string) => {
    const result = new URLSearchParams(getRequiredFilter(search));
    if (availabilityTypesArray.length > 0) {
      if (
        result.has('AvailabilityTypeId') === false ||
        result.get('AvailabilityTypeId') === null ||
        result.get('AvailabilityTypeId') === ''
      ) {
        result.set('AvailabilityTypeId', availabilityTypesArray.join('|'));
      }
    }
    if (!!serviceTypeId) {
      if (
        result.has('stid') === false ||
        result.get('stid') === null ||
        result.get('stid') === ''
      ) {
        result.set('stid', serviceTypeId.toString());
      }
    }
    if (!!serviceGroupId) {
      if (
        result.has('sgid') === false ||
        result.get('sgid') === null ||
        result.get('sgid') === ''
      ) {
        result.set('sgid', serviceGroupId.toString());
      }
    }
    const res = result.toString();
    return isEmptyOrWhitespace(res) ? undefined : res;
  };
  const primaryRecentAction = React.useCallback(
    (row: AssetQuickSearchDetails) => {
      if (actionType === 'Reservation') {
        dispatch(schedActions.updateMultiMode(false));
        setTimeout(
          () =>
            createReservation(row.ServiceId !== null ? [row.ServiceId] : []),
          10,
        );
        if (useSidePanel) {
          closeSidePanel();
        }
      } else if (actionType === 'WorkOrder') {
        createWorkOrder([row.Id], useSidePanel);
        if (useSidePanel) {
          closeSidePanel();
        }
      } else if (actionType === 'StartUsage') {
        if (row.ServiceId !== null) {
          startUsage(row.ServiceId, useSidePanel);
          if (useSidePanel) {
            closeSidePanel();
          }
        }
      } else if (actionType === 'AssetSearch') {
        openAsset(row.Id);
      }
    },
    [
      actionType,
      closeSidePanel,
      createReservation,
      createWorkOrder,
      dispatch,
      openAsset,
      schedActions,
      startUsage,
      useSidePanel,
    ],
  );
  const primaryAction = React.useCallback(
    (row: Row<IMyAssetsRow>) => {
      if (
        availabilityTypesArray.includes(row.original.AvailabilityTypeId) ||
        actionType === 'AssetSearch'
      ) {
        if (useSidePanel && !(actionType === 'AssetSearch')) {
          closeSidePanel();
        }
        switch (actionType) {
          case 'Reservation':
            dispatch(schedActions.updateMultiMode(false));
            setTimeout(
              () =>
                createReservation(
                  row.original.ServiceId !== null
                    ? [row.original.ServiceId]
                    : [],
                ),
              10,
            );
            break;
          case 'OfflineService':
            createOffline(
              row.original.ServiceId !== null ? [row.original.ServiceId] : [],
              false,
            );
            break;
          case 'StartUsage':
            if (row.original.ServiceId !== null) {
              startUsage(row.original.ServiceId, useSidePanel);
            }
            break;
          case 'WorkOrder':
            createWorkOrder([row.original.Id], useSidePanel);
            break;
          case 'Request':
            setRequestId(row.original.ServiceId ?? undefined);
            setOpenRequest(true);
            break;
          case 'TrainingSession':
            addTrainingSession(row.original.ServiceId as number);
            break;
          case 'AssetSearch':
            openAsset(row.original.Id);
            break;
        }
      } else {
        dispatch(
          appSettingsAction.addNotification({
            key: 'availability_error',
            message: `Can not perform this action for resource with Availability Type ${row.original.AvailabilityTypeName}.
                       Please change filters and try again.`,
            variant: 'error',
            persist: true,
          }),
        );
      }
    },
    [
      actionType,
      addTrainingSession,
      appSettingsAction,
      availabilityTypesArray,
      closeSidePanel,
      createOffline,
      createReservation,
      createWorkOrder,
      dispatch,
      openAsset,
      schedActions,
      startUsage,
      useSidePanel,
    ],
  );
  const primaryMultiAction = React.useCallback(
    (rows: IMyAssetsRow[]) => {
      if (
        !rows.some(f => availabilityTypesArray.includes(f.AvailabilityTypeId))
      ) {
        let notAvailable = rows
          .filter(f => !availabilityTypesArray.includes(f.AvailabilityTypeId))
          .map(f => f.AvailabilityTypeName);
        dispatch(
          appSettingsAction.addNotification({
            key: 'availability_error',
            message: `Can not perform this action for resources with Availability Type ${notAvailable.join(
              `, `,
            )}.
                       Please change filters and try again.`,
            variant: 'error',
            persist: true,
          }),
        );
      } else {
        switch (actionType) {
          case 'Reservation':
            dispatch(schedActions.updateMultiMode(true));
            setTimeout(
              () =>
                createReservation(
                  rows.map(f => f.ServiceId ?? 0).filter(f => f > 0),
                  true,
                ),
              10,
            );
            break;
          case 'OfflineService':
            //if (rows.length === 1) {
            createOffline(
              rows.map(f => f.ServiceId ?? 0).filter(f => f > 0),
              true,
            );
            // } else {
            //   dispatch(
            //     appSettingsAction.addNotification({
            //       key: 'multiSelect_error',
            //       message:
            //         'Can not perform this action for multiple services. Please select only one sevice and try again.',
            //       variant: 'error',
            //     }),
            //   );
            // }
            break;
          case 'StartUsage':
            if (rows.length === 1) {
              if (rows[0].ServiceId !== null) {
                startUsage(rows[0].ServiceId, useSidePanel);
              }
            } else {
              dispatch(
                appSettingsAction.addNotification({
                  key: 'multiSelect_error',
                  message:
                    'Can not perform this action for multiple services. Please select only one sevice and try again.',
                  variant: 'error',
                }),
              );
            }
            break;
          case 'TrainingSession':
            if (rows.length === 1) {
              addTrainingSession(rows[0].ServiceId ?? 0);
            } else {
              dispatch(
                appSettingsAction.addNotification({
                  key: 'multiSelect_error',
                  message:
                    'Can not perform this action for multiple services. Please select only one sevice and try again.',
                  variant: 'error',
                }),
              );
            }
            break;
          case 'WorkOrder':
            createWorkOrder(
              rows.map(f => f.Id),
              useSidePanel,
            );
            break;
          case 'Request':
            if (rows.length === 1) {
              setRequestId(rows[0].ServiceId ?? undefined);
              setOpenRequest(true);
            } else {
              dispatch(
                appSettingsAction.addNotification({
                  key: 'multiSelect_error',
                  message:
                    'Can not perform this action for multiple services. Please select only one sevice and try again.',
                  variant: 'error',
                }),
              );
            }
            break;
        }
        if (useSidePanel) {
          closeSidePanel();
        }
      }
    },
    [
      actionType,
      addTrainingSession,
      appSettingsAction,
      availabilityTypesArray,
      closeSidePanel,
      createOffline,
      createReservation,
      createWorkOrder,
      dispatch,
      schedActions,
      startUsage,
      useSidePanel,
    ],
  );
  const handleSearchAllCores = React.useCallback(
    (value: boolean) => {
      dispatch(appSettingsAction.setShowAllCores(value));
      //dispatch(layoutActions.setRefreshTable(true));
    },
    [appSettingsAction, dispatch],
  );
  const multiModeDisabled = React.useMemo(() => {
    switch (actionType) {
      case 'Reservation':
      case 'WorkOrder':
      case 'TrainingSession':
      case 'OfflineService':
      case 'AssetSearch':
        return false;

      case 'Request':
      case 'StartUsage':
        return true;
      default:
        return false;
    }
  }, [actionType]);
  const useRecentAssets = React.useMemo(() => {
    switch (actionType) {
      case 'Reservation':
      case 'StartUsage':
      case 'WorkOrder':
        return !isMobile;
      case 'OfflineService':
      case 'Request':
      case 'TrainingSession':
      case 'AssetSearch':
      default:
        return false;
    }
  }, [actionType, isMobile]);
  const pageActions: ActionRenderer[] = React.useMemo(() => {
    let actions: ActionRenderer[] = [];
    if (allowAllCoresToggle) {
      actions.push(() => (
        <BasicToggle
          label={t(translations.SearchAllCores)}
          checked={showAllCores ?? false}
          onChange={(value, event) => handleSearchAllCores(value)}
        />
      ));
    }

    if (
      !isMobile &&
      actionType === 'AssetSearch' &&
      !!authenticatedUser &&
      (authenticatedUser.Roles.includes(Roles.Administrators) ||
        authenticatedUser.Roles.includes(Roles.GroupAdministrators))
    ) {
      actions.push(() => (
        <AdminActions
          id={`assetsSearch_settings`}
          icon={'cog'}
          menuTab="Assets"
        />
      ));
    }
    return actions;
  }, [
    actionType,
    allowAllCoresToggle,
    authenticatedUser,
    handleSearchAllCores,
    isMobile,
    showAllCores,
    t,
  ]);
  useEffectOnMount(() => {
    //dispatch(layoutActions.setUpSnackBarIndex(true));
    if (!!assetIds && assetIds.length > 0) {
      (async () => {
        try {
          const data = await initAssetsData(assetIds.join(','), false);
          setSelectedAssets(
            data.map(f => {
              return { Id: f.Id, Name: f.Name };
            }),
          );
        } catch {}
      })();
    } else if (!!serviceIds && serviceIds.length > 0) {
      (async () => {
        try {
          const data = await initAssetsData(serviceIds.join(','), true);
          setSelectedAssets(
            data.map(f => {
              return { Id: f.Id, Name: f.Name };
            }),
          );
        } catch {}
      })();
    } else if (!!offTypeIds && offTypeIds.length > 0) {
      (async () => {
        try {
          const data = await initAssetsData(offTypeIds.join(','), true, [
            { Id: 2 },
          ]);
          setSelectedAssets(
            data.map(f => {
              return { Id: f.Id, Name: f.Name };
            }),
          );
        } catch {}
      })();
    } else if (!!requestTypeIds && requestTypeIds.length > 0) {
      (async () => {
        try {
          const data = await initAssetsData(requestTypeIds.join(','), true, [
            { Id: 3 },
          ]);
          setSelectedAssets(
            data.map(f => {
              return { Id: f.Id, Name: f.Name };
            }),
          );
        } catch {}
      })();
    }
    return () => {
      //dispatch(layoutActions.setUpSnackBarIndex(false));
    };
  });
  return (
    <PageWrapper
      pageLink={pagelink}
      useSidePanel={useSidePanel || false}
      pageName={screenName}
      //loading={loading}
      //pageName={<Link href="coreselection">{t(translations.menu_CoreSelection)}</Link>}
      titlePage={t(translations.menu_MyInstrumentsAssets)}
      closable={useSidePanel || props.isCover}
      //disableExpandToggle={true}
      //rightTopActions={topButtons}
      isCover={props.isCover}
      coverClosed={coverClosed}
      hideTitleBar={false}
      cover={cover}
      closeCover={!props.isCover ? onCloseCover : closeCover}
      closeSidePanel={props.isCover ? onClose : undefined}
      leftTopActions={pageActions}
      children={
        <React.Fragment>
          <AssetsSelectionTable
            useSidePanel={useSidePanel}
            setFilters={setfilterCallback}
            authenticatedUser={authenticatedUser}
            closeSidePanel={closeSidePanel}
            primaryAction={primaryAction}
            isRowSelectable={isRowSelectable}
            getRequiredFilter={requiredFilter}
            allowMultiToggle={allowMultiToggle}
            screenName={screenName}
            assets={selectedAssets}
            primaryMultiAction={primaryMultiAction}
            multiModeDisabled={multiModeDisabled}
            openAsset={openAsset}
            useRecentAssets={useRecentAssets}
            customPredicates={customPredicates}
            isShortView={showShortView}
            emptyTable={noTableData}
            searchTerm={searchTerm}
            serviceGroupId={serviceGroupId}
            RecentAssets={
              <RecentAssets
                actionType={actionType}
                primaryAction={primaryRecentAction}
                useSidePanel={useSidePanel}
                shortView={showShortView}
              />
            }
            actionType={actionType}
          />
          <CreateNewRequest
            isOpen={openRequest || false}
            setOpen={setOpenRequest}
            serviceId={requestId}
          />
        </React.Fragment>
      }
    />
  );
}
