import * as React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import {
  StyledForm,
  FormLeftSection,
  FormFieldsSection,
  FormRightSection,
  FormRow,
} from 'app/components/Forms/FormsLayout';
import { Formik, FormikProps } from 'formik';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { isEmpty } from 'lodash';
import { CostCentersDetailsState } from '../slice/types';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { FormServiceUserGroupPicker } from 'app/components/Forms/FormServiceUserGroupPicker';

export interface CostCentersFormProps {
  initialValues: CostCentersDetailsState;
  onSubmit: (item: CostCentersDetailsState) => void;
  isEdit: boolean;
  isAdmin: boolean;
  bindSubmitForm: any;
  useSidePanel: boolean;
  processing?: boolean;
  isCover?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
  formRef: React.MutableRefObject<FormikProps<CostCentersDetailsState> | null>;
}

export const CostCentersForm = React.memo(function CostCentersForm({
  initialValues,
  onSubmit,
  isEdit,
  isAdmin,
  bindSubmitForm,
  useSidePanel,
  processing,
  isCover,
  openPanelWithCover,
  formRef,
}: CostCentersFormProps): JSX.Element {
  //SETTINGS
  const { t } = useTranslation();

  //PROPS

  //STATES

  //FUNCTIONS
  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(value);
    }
  };

  //SCHEMA
  const computedSchema = React.useMemo(() => {
    const costCentersDetailsSchema: Yup.SchemaOf<CostCentersDetailsState> =
      Yup.object({
        Id: Yup.number().notRequired().default(-1),
        UserGroup: Yup.mixed()
          .label(t(translations.UserGroup) as string)
          .required(t(translations.err_UserGroupRequired) as string),
        CostCenter: Yup.string()
          .label(t(translations.CostCenter) as string)
          .nullable()
          .required(t(translations.CostCenterIsRequired) as string),
      });
    return costCentersDetailsSchema;
  }, [t]);

  //RENDER
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={computedSchema}
        validateOnMount={true}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        innerRef={formRef}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          // console.debug('Submitting: ', values);
          // formikHelpers.setSubmitting(true);
          // handleSubmit(values);
          formikHelpers.validateForm(values).then(responseErrors => {
            if (!isEmpty(responseErrors)) {
              formikHelpers.setSubmitting(false);
              console.debug('errors', responseErrors);
            } else {
              console.debug('submitting', values);
              formikHelpers.setSubmitting(true);
              handleSubmit(values);
            }
          });
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            console.log('CostCenters Details Errors: ', formik.errors);
          }
          return (
            <StyledForm onSubmit={formik.handleSubmit}>
              {/* <FormListener
                    onFormChange={handleFormChange}
                    fields={['Disabled', 'Mandatory']}
                  /> */}
              <FormLeftSection>
                <FormFieldsSection
                  titleSection={t(translations.CostCenterDetails) as string}
                >
                  <FormRow>
                    <FormServiceUserGroupPicker
                      id="UserGroupID"
                      name="UserGroup"
                      label={t(translations.UserGroup)}
                      placeholder={t(translations.UserGroup)}
                      disabled={formik.isSubmitting}
                      disableClearable={true}
                      fullWidth
                    />
                  </FormRow>
                </FormFieldsSection>
              </FormLeftSection>
              <FormRightSection>
                <FormFieldsSection>
                  <FormRow>
                    <FormTextField
                      id="CostCenterID"
                      name="CostCenter"
                      label={t(translations.CostCenter)}
                      placeholder={t(translations.CostCenter)}
                      disabled={formik.isSubmitting}
                      fullWidth
                    />
                  </FormRow>
                </FormFieldsSection>
              </FormRightSection>
            </StyledForm>
          );
        }}
      </Formik>
    </>
  );
});
