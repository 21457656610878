import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import * as React from 'react';

import { useTranslation } from 'react-i18next';
export interface ConfigureColumnsButtonProps {
  onClick?: () => void;
}
export function ConfigureColumnsButton(props: ConfigureColumnsButtonProps) {
  const { t } = useTranslation();
  const { onClick } = props;

  return (
    <Button
      title={t(translations.ConfigurableColumnsLink) as string}
      variant="white"
      size="small"
      startIcon={<Icon icon="table" />}
      onClick={onClick}
    >
      {t(translations.Columns)}
    </Button>
  );
}
