import { Dialog } from '@material-ui/core';
import { toQueryString, toRootedURL } from 'utils/url-utils';

import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Entity } from 'types/common';
import { Button } from 'app/components/BasicButtons/Button';
import { OnlineServiceSinglePicker } from 'app/components/pickers/MultiSelectPickers/OnlineServicesPicker';
import { useDispatch } from 'react-redux';
import { GetSchedulerPagePath } from 'app/pages/CalendarPage/GetCalendarPagePath';
import { IGradientsDto } from 'api/odata/IGradientsDto';
import { useRequestSamplesSlice } from '../../RequestSamplesPage/slice';
import { GradientsPicker } from 'app/components/pickers/AutocompletePickers/GradientsPicker';
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import React from 'react';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import {
  Condition,
  ODataOperators,
  quoteODataValue,
} from 'api/odata/ODataFilter';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { IServiceRequestTableRowModel } from '../../RequestSamplesPage/slice/types';
import { ReservationQueryStringParameters } from 'app/pages/ReservationDetails/Details/slice/types';
import { ReservationDetailsProps } from 'app/pages/ReservationDetails/Details';
import { debounce } from 'lodash';
import { tryParseInt } from 'utils/string-utils';
import { BasicDialogTitle } from 'app/components/BasicDialog/BasicDialogTitle';
import { BasicDialogActions } from 'app/components/BasicDialog/BasicDialogActions';
import { BasicDialogContent } from 'app/components/BasicDialog/BasicDialogContent';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
} from 'app/components/Forms/FormsLayout';

export interface CreateRequestReservationProps {
  serviceRequestId: number;
  requestOverheadMinutes: number | null;
  gradient: IGradientsDto | null;
  userName: string;
  budgetId?: number;
  serviceGroupId: number;
  serviceRequestRelatedEquipments: number[];
  rows: IServiceRequestTableRowModel[];
  useSidePanel?: boolean;
  openPanel: (state: SidePanelOpenState) => void;
  createOpen: boolean;
  onClose: () => void;
}

export const CreateRequestReservation = ({
  serviceRequestId,
  requestOverheadMinutes,
  gradient,
  userName,
  budgetId,
  serviceGroupId,
  serviceRequestRelatedEquipments,
  useSidePanel,
  rows,
  openPanel,
  createOpen,
  onClose,
}: CreateRequestReservationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useRequestSamplesSlice();
  const [open, setOpen] = React.useState(createOpen);
  React.useEffect(() => {
    setOpen(createOpen);
  }, [createOpen]);
  const equipmentsPredicates = React.useMemo(() => {
    const predicates: string | Condition<IServiceFilterDto>[] = [];
    predicates.push(
      new Condition<IServiceFilterDto>(
        'ServiceGroupId',
        ODataOperators.Equals,
        serviceGroupId,
      ),
    );
    if (serviceRequestRelatedEquipments.length > 0) {
      predicates.push(
        new Condition<IServiceFilterDto>(
          'Id',
          ODataOperators.Includes,
          serviceRequestRelatedEquipments,
        ),
      );
    }
    return predicates;
  }, [serviceGroupId, serviceRequestRelatedEquipments]);
  const [overheadMinText, setOverheadMinText] = React.useState<string>(
    String(requestOverheadMinutes ?? ''),
  );
  const [selectedGradient, setSelectedGradient] =
    React.useState<IGradientsDto | null>(gradient);
  const [selectedEquipment, setSelectedEquipment] =
    React.useState<Entity<number> | null>(null);
  const duration = React.useMemo(() => {
    return rows.length > 0
      ? ((selectedGradient?.EstimatedRuntimeMinutes ?? 0) + +overheadMinText) *
          rows.length
      : +overheadMinText;
  }, [overheadMinText, rows.length, selectedGradient?.EstimatedRuntimeMinutes]);
  const handleOverheadSave = React.useCallback(
    (value?: number) => {
      dispatch(
        actions.patch({
          Id: serviceRequestId,
          OverheadMinutes: value || null,
        }),
      );
    },
    [actions, dispatch, serviceRequestId],
  );
  const debouncedPoChange = React.useMemo(
    () => debounce(handleOverheadSave, 500),
    [handleOverheadSave],
  );

  const handleOverheadChange = React.useCallback(
    (value: string) => {
      setOverheadMinText(value);
      debouncedPoChange(tryParseInt(value));
    },
    [debouncedPoChange],
  );
  const validToCreate = React.useMemo(() => {
    return selectedEquipment !== null && open;
  }, [open, selectedEquipment]);
  const handleCreateReservationButtonClick = React.useCallback(
    (openIn: 'reservation' | 'calendar') => {
      if (!selectedEquipment) return;
      if (openIn === 'reservation') {
        const params: Record<string, any> = {
          selectedIds: String(selectedEquipment.Id),
          un: userName,
          bid: String(budgetId),
          srid: String(serviceRequestId),
          srrid: String(rows?.map(f => f.Id).join(',')),
          duration: String(duration),
        };
        const openProps = {
          renderPageType: RenderPageType.ReservationDetails,
          renderPageProps: {
            useSidePanel: useSidePanel ?? false,
            queryParams: params as ReservationQueryStringParameters,
          } as ReservationDetailsProps,
          expanded: false,
          useSidePanel: useSidePanel,
          isCover: useSidePanel,
        } as SidePanelOpenState;
        openPanel(openProps);
        onClose();
      } else {
        var p: Record<string, any> = {
          bid: budgetId,
          un: userName,
          srid: serviceRequestId,
          srrid: rows?.map(r => r.Id).join(','),
          eid: selectedEquipment.Id,
          duration: duration,
          openToBook: true,
        };
        window.open(
          toRootedURL(
            `${GetSchedulerPagePath('calendar')}?${toQueryString(p)}`,
          ),
          '_blank',
        );
        // dispatch(
        //   push({
        //     pathname: GetSchedulerPagePath('calendar'),
        //     search: toQueryString(p).toString(),
        //   }),
        // );
        onClose();
      }
    },
    [
      budgetId,
      duration,
      onClose,
      openPanel,
      rows,
      selectedEquipment,
      serviceRequestId,
      useSidePanel,
      userName,
    ],
  );
  return (
    <>
      {/* <Button size="small" onClick={e => setOpen(true)}>
        {t(translations.CreateReservation)}
      </Button> */}
      <Dialog open={open} onClose={() => onClose()}>
        <BasicDialogTitle>
          {t(translations.ServiceRequestCreateRservation)}
        </BasicDialogTitle>
        <BasicDialogContent>
          <FormLeftSection>
            <FormFieldsSection>
              {rows.length > 0 && (
                <FormRow>
                  <GradientsPicker
                    fullWidth
                    name="Gradient"
                    variant="filled"
                    value={selectedGradient}
                    label={t(translations.ServiceRequestGradient)}
                    placeholder={t(translations.ServiceRequestGradient)}
                    info={t(translations.ServiceRequestGradient_info)}
                    onChange={value => {
                      dispatch(
                        actions.updateGradient({
                          Id: serviceRequestId,
                          Gradient: value as IGradientsDto,
                        }),
                      );
                      setSelectedGradient(value as IGradientsDto);
                    }}
                    predicates={[
                      `ServiceGroupId eq ${quoteODataValue(serviceGroupId)}`,
                    ]}
                  />
                </FormRow>
              )}

              <FormRow>
                <TextControlField
                  variant="filled"
                  fullWidth
                  inputProps={{
                    type: 'number',
                    min: 0,
                  }}
                  info={t(translations.RequestReservationTime_info)}
                  value={overheadMinText}
                  onChange={event => handleOverheadChange(event.target.value)}
                  label={t(
                    rows.length > 0
                      ? translations.RequestReservationOverallOverheadTime
                      : translations.RequestReservationTime,
                  )}
                />
              </FormRow>
              <FormRow>
                <OnlineServiceSinglePicker
                  predicates={equipmentsPredicates}
                  placeholder={t(translations.PleaseSelectAnInstrument)}
                  label={t(translations.Equipment)}
                  onChange={value => {
                    setSelectedEquipment(value[0] ?? null);
                  }}
                  value={
                    selectedEquipment === null
                      ? undefined
                      : [selectedEquipment as IServiceFilterDto]
                  }
                  error={selectedEquipment === null}
                  variant="filled"
                  helperText={
                    selectedEquipment === null
                      ? t(translations.PleaseSelectAnEquipmentAndSamples)
                      : undefined
                  }
                  fullWidth
                  info={t(translations.PleaseSelectAnEquipmentAndSamples)}
                />
              </FormRow>
            </FormFieldsSection>
          </FormLeftSection>
        </BasicDialogContent>
        <BasicDialogActions
          leftActions={[
            () => (
              <Button
                variant="main"
                size="small"
                disabled={!validToCreate}
                onClick={() => {
                  handleCreateReservationButtonClick('reservation');
                }}
              >
                {t(translations.Continue)}
              </Button>
            ),
            () => (
              <Button
                variant="white"
                size="small"
                disabled={!validToCreate}
                onClick={() => {
                  handleCreateReservationButtonClick('calendar');
                }}
              >
                {t(translations.OpenInSchedule)}
              </Button>
            ),
          ]}
          rightActions={[
            () => (
              <Button
                autoFocus
                variant="ghost"
                size="small"
                onClick={() => onClose()}
              >
                {t(translations.Cancel)}
              </Button>
            ),
          ]}
        ></BasicDialogActions>
      </Dialog>
    </>
  );
};
