import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { IQuizDto } from 'api/odata/generated/entities/IQuizDto';
import { httpClient } from 'api/HttpClient';
import { usePromise } from 'app/hooks/usePromise';
import { IQuizSubmissionDto } from 'api/odata/generated/entities/IQuizSubmissionDto';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSettingsSlice } from 'app/slice';
import { push } from 'connected-react-router';
import { Button } from 'app/components/BasicButtons/Button';
import { DetectIsMobile } from 'utils/mobileDetect';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
import parse from 'html-react-parser';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Link } from 'app/components/ExternalLink';
import { Body } from 'app/components/Typography';
import { selectExpandedSidePanel } from 'app/Layout/FrontendLayout/slice/selectors';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import { QuizDetailsProps } from 'app/pages/QuizDetailsPage/QuizDetails';
import { QuizCustomForm } from 'app/components/CustomForm/QuizCustomForm';
import { ParseFormFieldEnums } from 'app/components/CustomForm/CustomFormUtils';
import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import { Progress } from 'app/components/LoadingIndicator';
import {
  FormFieldsSection,
  FormLeftSection,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import styled from 'styled-components';

export interface StartQuizProps extends SidePanelContentProps {
  id: string;
  closable: boolean;
}
export function StartQuiz(props: StartQuizProps) {
  const { id, useSidePanel } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const User = useSelector(selectAuthenticatedUser);
  const fetchQuiz = async (quizId: number) =>
    httpClient
      .get<IQuizDto>(`/api/odata/v4/quizzes(${quizId})`, {
        $expand: ['Submissions', 'ServiceGroups'].join(','),
      })
      .then(value => {
        // Enums are serialized using string representations
        // parse enum values
        ParseFormFieldEnums(value.FormFields);
        // shuffle questions according to quiz settings
        if (value.ShuffleFields) {
          value.FormFields = value.FormFields.sort(() => 0.5 - Math.random());
        }
        // enumerate questions
        value.FormFields.forEach(
          (formField, index) =>
            (formField.Label = `${t(translations.Question)} ${index + 1}. ${
              formField.Label
            }`),
        );
        value.UsedAttempts = value.Submissions.filter(
          item =>
            item.SubmittedById === User?.Id &&
            (item.Score ?? 0) < (value.QuizTrainingPassingScore ?? 0),
        ).length;
        value.UserCanStartQuiz =
          value.UsedAttempts === 0 ||
          (value.QuizNumberOfAttempts ?? 0) === 0 ||
          value.UsedAttempts < (value.QuizNumberOfAttempts ?? 0);
        return value;
      });
  const [fetchQuizPromiseState, fetchQuizPromise] = usePromise(fetchQuiz);
  const [state, setState] = useAsyncExtendedState<IQuizDto | undefined>(
    undefined,
  );
  const [startQuiz, setStartQuiz] = React.useState<boolean>(true);
  useEffectOnMount(() => {
    setState(fetchQuizPromise(+id));
    return () => {};
  });
  const isMobile = DetectIsMobile();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const { actions: layoutActions } = useLayoutSlice();
  React.useEffect(() => {
    const messageKey = 'QuizLoad';
    if (fetchQuizPromiseState.error) {
      dispatch(
        actions.addNotification({
          variant: 'error',
          message: 'Something went wrong.',
          key: messageKey,
        }),
      );
    }
  }, [fetchQuizPromiseState.error, actions, dispatch]);

  const [submitStatus, submitQuiz] = usePromise(
    async (values: Record<number, string>) => {
      const formValues: IFormValueDto[] = Object.entries(values).map(
        ([formFieldId, value]) => ({
          Id: -1,
          FormFieldId: +formFieldId,
          Value: value,
          SourceValueId: null,
          AlertId: null,
          AlertTypeId: null,
          Readonly: false,
          CustomFormId: null,
          CustomFormName: null,
          DisplayValue: null,
          RequestId: null,
          ReservationId: null,
          ExperimentItemId: null,
          BudgetExperimentId: null,
          ServiceGroupUserId: null,
          ServiceGroupId: null,
        }),
      );
      const quizSubmission: Partial<IQuizSubmissionDto> = {
        CustomFormId: +id,
        FormValues: formValues,
      };
      return httpClient.post<Partial<IQuizSubmissionDto>>(
        `/api/odata/v4/quizsubmissions`,
        quizSubmission,
      );
    },
  );
  const [readonly] = React.useState<boolean>(false);
  const [submission, setSubmission] = useAsyncExtendedState<
    Partial<IQuizSubmissionDto> | undefined
  >(undefined);

  React.useEffect(() => {
    const messageKey = 'QuizSubmission';
    if (submitStatus.status === 'resolved') {
      dispatch(
        actions.addNotification({
          variant: 'success',
          message: 'Submission success.',
          key: messageKey,
        }),
      );
    }
    if (submitStatus.status === 'rejected') {
      dispatch(
        actions.addNotification({
          variant: 'error',
          message: 'Submission failed.',
          key: messageKey,
        }),
      );
    }
  }, [actions, dispatch, submitStatus.status]);

  const submissionId = submission?.Id;
  const resultQuiz = React.useCallback(() => {
    if (useSidePanel) {
      dispatch(
        layoutActions.openSidePanel({
          type: RenderPageType.QuizzDetails,
          props: {
            id: '' + submissionId,
            useSidePanel: useSidePanel,
          } as QuizDetailsProps,
        }),
      );
    } else {
      dispatch(push(`/quizzes/details/${submissionId}`));
    }
  }, [dispatch, layoutActions, submissionId, useSidePanel]);
  React.useEffect(() => {
    if (submissionId !== undefined) {
      resultQuiz();
    }
  }, [resultQuiz, submissionId]);
  const handleSubmit = (values: Record<number, any>) =>
    setSubmission(submitQuiz(values));
  const submitFormRef = React.useRef<any>(null);
  const handleSubmitForm = e => {
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const leftActions = React.useMemo(
    () => [
      () =>
        readonly !== true && startQuiz ? (
          <React.Fragment>
            <Button
              size={isMobile || !sidePanelExpanded ? 'small' : 'medium'}
              startIcon={<Icon icon="save" />}
              onClick={() => setStartQuiz(false)}
            >
              {isMobile || !sidePanelExpanded
                ? t(translations.Continue)
                : t(translations.ContinueToQuiz)}
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button
              size={isMobile || !sidePanelExpanded ? 'small' : 'medium'}
              startIcon={<Icon icon="save" />}
              onClick={handleSubmitForm}
            >
              {t(translations.Submit)}
            </Button>
          </React.Fragment>
        ),
    ],
    [isMobile, readonly, sidePanelExpanded, startQuiz, t],
  );
  const rightActions = React.useMemo(() => {
    return [
      () =>
        readonly !== true && (
          <React.Fragment>
            <Button
              variant="ghost"
              size={isMobile || !sidePanelExpanded ? 'small' : 'medium'}
              startIcon={<Icon icon="times" />}
              onClick={() => {
                if (useSidePanel) {
                  dispatch(layoutActions.resetSidePanel());
                } else {
                  dispatch(push(`/quizzes`));
                }
              }}
            >
              {state?.UserCanStartQuiz
                ? isMobile || !sidePanelExpanded
                  ? t(translations.Cancel)
                  : t(translations.CancelYourSubmission)
                : t(translations.BackToQuizzes)}
            </Button>
          </React.Fragment>
        ),
    ] as ActionRenderer[];
  }, [
    dispatch,
    isMobile,
    layoutActions,
    readonly,
    sidePanelExpanded,
    state?.UserCanStartQuiz,
    t,
    useSidePanel,
  ]);
  const StyledQuizDiv = styled.div`
    margin-top: 24px; //MaterialUI default spacing function uses an 8px-based scale, so passing 3 as a value would result in 3 * 8px = 24px of margin.
  `;
  return (
    <PageWrapper
      pageName={
        !useSidePanel || sidePanelExpanded
          ? (t(translations.Quiz) as string)
          : undefined
      }
      useSidePanel={useSidePanel}
      titlePage={state?.Name}
      children={
        !!fetchQuizPromiseState.status &&
        fetchQuizPromiseState.status !== 'pending' &&
        !!state ? (
          <StyledFormFieldsContainer>
            <FormLeftSection>
              <React.Fragment>
                {state.UserCanStartQuiz ? (
                  <React.Fragment>
                    <FormFieldsSection>
                      <Body italic={true} color="secondary">
                        {(t(translations.QuizDescription) as string)
                          .replace('{0}', state?.FormFields?.length.toString())
                          .replace(
                            '{1}',
                            (state.QuizTrainingPassingScore ?? 0).toString(),
                          )}
                      </Body>
                      <StyledQuizDiv>
                        {startQuiz ? (
                          <React.Fragment>
                            {parse(state?.QuizInstructions || '')}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <StyledQuizDiv>
                              {
                                <QuizCustomForm
                                  bindSubmitForm={bindSubmitForm}
                                  formFields={state.FormFields}
                                  onSubmit={handleSubmit}
                                />
                              }
                            </StyledQuizDiv>
                            {/*</Box>*/}
                          </React.Fragment>
                        )}
                      </StyledQuizDiv>
                      {/*</Box>*/}
                    </FormFieldsSection>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <FormFieldsSection
                      titleSection={
                        <Body bold={true}>
                          {t(translations.QuizFiledAttemptsMessage).replace(
                            '{0}',
                            state.UsedAttempts.toString(),
                          )}
                        </Body>
                      }
                    >
                      <React.Fragment>
                        <Body bold={true}>
                          {t(translations.QuizFiledAttemptsInstrumentMessage)}
                        </Body>
                        <Link
                          href={`/Assets/Details.aspx?EquipmentId=${state.EquipmentId}`}
                          target="_blank"
                        >
                          {state.EquipmentName}
                        </Link>
                      </React.Fragment>
                    </FormFieldsSection>
                  </React.Fragment>
                )}
              </React.Fragment>
            </FormLeftSection>
          </StyledFormFieldsContainer>
        ) : (
          <React.Fragment>
            <Progress
              inProgress={fetchQuizPromiseState.status === 'pending'}
              size={80}
            />
          </React.Fragment>
        )
      }
      leftActions={state?.UserCanStartQuiz ? leftActions : undefined}
      rightActions={rightActions}
      closable={true}
    />
  );
}
