import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import {
  selectConfirmApproved,
  selectConfirmRejected,
  selectContinueState,
  selectContinueToLink,
  selectHasNotSavedChanges,
} from 'app/Layout/FrontendLayout/slice/selectors';
import {
  selectAuthenticatedUser,
  selectglobalSettings,
  selectPublicUrl,
} from 'app/slice/selectors';

import { push } from 'connected-react-router';
//import { selectAuthenticatedUser } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import { omit } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { buildURL, toQueryString } from 'utils/url-utils';
import { useTrainingSlice } from './slice';
import {
  selectCreateTraining,
  selectCreateTrainingCompleted,
  selectCreateTrainingHasError,
  selectInitTrainingProcessing,
  selectReservatiionsData,
  selectReservatiionsProcessing,
  selectReservatiionsUpdateCompleted,
  selectShowApproveReservatiions,
  selectShowRejectReservatiions,
  selectUpdateTraining,
  selectUpdateTrainingCompleted,
  selectUpdateTrainingHasError,
} from './slice/selectors';
import { TrainingDetailsState } from './slice/types';
import { UserTrainingForm } from './UserTrainingForm';
import { SaveFormButton, ActivateButton } from './Actions/TrainingActions';
import { Beforeunload } from 'react-beforeunload';
import { AssetPopUp } from 'app/pages/AssetPopUp';
import { AllowedSettings } from 'utils/globalSettings';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { useAppSettingsSlice } from 'app/slice';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';
import { IconButton } from 'app/components/BasicButtons/IconButton';

export interface TrainingDetailsProps
  extends CoverProps,
    SidePanelContentProps {
  id?: string;
  equId?: string;
  equIds?: string;
  fatherId?: string;
  rid?: string;
  date?: string;
  user?: string;
  onClose?: () => void;
  onTrainingClose?: () => void;
}

export function TrainingDetails(props: TrainingDetailsProps) {
  const {
    id,
    equId,
    equIds,
    fatherId,
    rid,
    date,
    user,
    useSidePanel,
    onClose,
    onTrainingClose,
  } = props;
  const { t } = useTranslation();
  const loading = useSelector(selectInitTrainingProcessing);
  const dispatch = useDispatch();
  const { actions } = useTrainingSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const { actions: appSettingsActions } = useAppSettingsSlice();

  const [busy, setBusy] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [showApprove, setShowApprove] = React.useState<boolean>(false);
  const [showReject, setShowReject] = React.useState<boolean>(false);
  const requestCreateCompleted = useSelector(selectCreateTrainingCompleted);
  const requestUpdateCompleted = useSelector(selectUpdateTrainingCompleted);
  const [requestCompleted, setRequestCompleted] = React.useState<
    boolean | undefined
  >(undefined);
  const continueLink = useSelector(selectContinueToLink);
  const approveReservations = useSelector(selectShowApproveReservatiions);
  const rejectReservations = useSelector(selectShowRejectReservatiions);
  const reservationProcessing = useSelector(selectReservatiionsProcessing);
  const relatedReservations = useSelector(selectReservatiionsData);
  const updateResCompleted = useSelector(selectReservatiionsUpdateCompleted);
  const hasCreateError = useSelector(selectCreateTrainingHasError);
  const hasUpdateError = useSelector(selectUpdateTrainingHasError);
  const hasError = hasCreateError || hasUpdateError;
  const createTraining = useSelector(selectCreateTraining, shallowEqual);
  const editTraining = useSelector(selectUpdateTraining, shallowEqual);
  const training = createTraining || editTraining;
  const confirmRejected = useSelector(selectConfirmRejected);
  const confirmApproved = useSelector(selectConfirmApproved);
  const hasChanges = useSelector(selectHasNotSavedChanges, shallowEqual);
  const continueToOther = useSelector(selectContinueState);
  const publicUrl = useSelector(selectPublicUrl);
  const settings = useSelector(selectglobalSettings);
  const User = useSelector(selectAuthenticatedUser);
  const currenTrainingServiceGroupId = training?.ServiceGroupId ?? undefined;
  const activateAllowed = React.useMemo(() => {
    return User?.HasAdminGroupPermissions(currenTrainingServiceGroupId);
  }, [User, currenTrainingServiceGroupId]);
  const IsAdmin = React.useMemo(() => {
    return User?.IsAllGroupOrLabTechAdmin(
      (training?.Equipments || []).map(
        g => (g as IServiceFilterDto).ServiceGroupId,
        (training?.Equipments || []).map(f => {
          return {
            Id: f.Id,
            Name: f.Name,
            ServiceTypeId: ServiceType.Online,
          } as IServiceTypeFilterDto;
        }),
      ),
    );
  }, [User, training?.Equipments]);

  useEffectOnMount(() => {
    if (id !== undefined && !isNaN(parseInt(id))) {
      dispatch(actions.initUpdateTraining({ Id: parseInt(id) }));
    } else {
      dispatch(
        actions.initCreateTraining({
          equId,
          equIds,
          fatherId,
          rid,
          date,
          user,
        }),
      );
    }
    setBusy(false);
    setEdit(false);
    setShowApprove(false);
    setShowReject(false);
    setRequestCompleted(undefined);
    return () => {};
  });
  const handleCancelClick = React.useCallback(() => {
    if (!!onClose) {
      onClose();
      if (!!onTrainingClose) {
        onTrainingClose();
      }
    } else {
      if (useSidePanel) {
        dispatch(layoutActions.resetSidePanel());
        if (!!onTrainingClose) {
          onTrainingClose();
        }
      } else {
        dispatch(push('/usertraining'));
      }
    }
    if (edit) {
      dispatch(actions.resetUpdateTrainingState());
    } else {
      dispatch(actions.resetCreateTrainingState());
    }
    dispatch(actions.resetReservationsState());
  }, [
    actions,
    dispatch,
    edit,
    layoutActions,
    onClose,
    onTrainingClose,
    useSidePanel,
  ]);
  React.useEffect(() => {
    let active = loading === false;
    if (active) {
      if (id !== undefined && !isNaN(parseInt(id))) {
        setEdit(true);
      }
      if (requestCreateCompleted === true || requestUpdateCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
        if (!hasError) {
          if (approveReservations || rejectReservations) {
            setShowApprove(approveReservations);
            setShowReject(rejectReservations);
          } else {
            handleCancelClick();
          }
        }
      } else if (
        requestCreateCompleted === false ||
        requestUpdateCompleted === false
      ) {
        setRequestCompleted(false);
        setBusy(false);
      }
      if (updateResCompleted) {
        handleCancelClick();
      }
    }
    return () => {
      active = false;
    };
  }, [
    approveReservations,
    handleCancelClick,
    hasError,
    id,
    loading,
    rejectReservations,
    requestCompleted,
    requestCreateCompleted,
    requestUpdateCompleted,
    updateResCompleted,
  ]);
  React.useEffect(() => {
    if (confirmApproved) {
      if (edit) {
        dispatch(actions.resetUpdateTrainingState());
      } else {
        dispatch(actions.resetCreateTrainingState());
      }
      dispatch(actions.resetReservationsState());
      if (continueToOther.continueOnApprove) {
        dispatch(layoutActions.resetSidePanel());
        setTimeout(() => {
          dispatch(
            layoutActions.openSidePanel({
              type: continueToOther.pageType,
              props: continueToOther.pageProps,
              expanded: continueToOther.expanded,
            }),
          );
          dispatch(layoutActions.resetContinueState());
        }, 200);
      } else if (continueLink !== undefined) {
        dispatch(appSettingsActions.navigate(continueLink));
        dispatch(layoutActions.resetSidePanel());
      } else {
        dispatch(layoutActions.resetSidePanel());
      }
      //dispatch(actions.setChangedState(false));
    }
    if (confirmRejected) {
      dispatch(layoutActions.resetConfirmState());
    }

    return () => undefined;
  }, [
    actions,
    appSettingsActions,
    confirmApproved,
    confirmRejected,
    continueLink,
    continueToOther,
    dispatch,
    edit,
    layoutActions,
  ]);
  const handleSubmit = React.useCallback(
    (values: TrainingDetailsState) => {
      setBusy(true);
      if (edit) {
        dispatch(
          actions.updateTraining({ current: values, original: training }),
        );
      } else {
        dispatch(actions.createTraining(values));
      }
    },
    [actions, dispatch, edit, training],
  );

  const submitFormRef = React.useRef<any>(null);
  const submitCommentRef = React.useRef<any>(null);
  const handleSubmitForm = e => {
    if (submitCommentRef.current) {
      submitCommentRef.current(e);
    }
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };
  const handleClose = () => {
    if (hasChanges) {
      dispatch(layoutActions.setConfirmOpen(true));
    } else {
      handleCancelClick();
    }
  };

  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);
  const handleActivateTraining = React.useCallback(() => {
    dispatch(
      actions.activateDeactivateTraining({
        Id: training?.Id ?? undefined,
        activate: !(training?.Active ?? undefined),
      }),
    );
  }, [actions, dispatch, training]);
  const handleReject = React.useCallback(() => {
    dispatch(
      actions.approveRejectReservations({
        trainings: relatedReservations,
        approve: false,
      }),
    );
  }, [actions, dispatch, relatedReservations]);
  const handleApprove = React.useCallback(() => {
    dispatch(
      actions.approveRejectReservations({
        trainings: relatedReservations,
        approve: true,
      }),
    );
  }, [actions, dispatch, relatedReservations]);
  const handleResCancel = React.useCallback(() => {
    dispatch(actions.resetReservationsState());
  }, [actions, dispatch]);
  const [cover, setCover] = React.useState<React.ReactNode>();
  const closeCover = () => {
    setCover(undefined);
  };

  const leftActions = [
    () => (
      <React.Fragment>
        <SaveFormButton
          size="small"
          startIcon={<Icon icon="save" />}
          onClick={handleSubmitForm}
          disabled={busy}
          processing={!requestCompleted && busy}
          edit={edit}
          text={t(translations.Save)}
        />
      </React.Fragment>
    ),
  ] as ActionRenderer[];
  if (
    edit &&
    settings.GetBooleanByKey(
      AllowedSettings.TrainingActivationAllowedBySGAOnly,
    ) &&
    activateAllowed
  ) {
    leftActions.push(() => (
      <ActivateButton
        Active={training?.Active ?? false}
        edit={edit}
        text={
          training?.Active
            ? t(translations.DeactivateTraining)
            : t(translations.ActivateTraining)
        }
        ServiceGroupId={training?.ServiceGroupId ?? undefined}
        onClick={handleActivateTraining}
      />
    ));
  }
  if (edit && IsAdmin) {
    leftActions.push(() => (
      <IconButton
        variant="white"
        href="/UserTrainingHistory"
        shape="square"
        icon="history"
        size="small"
        title={t(translations.UserTrainingModificationHistory) as string}
      />
    ));
  }
  const rightActions = [
    () => (
      <React.Fragment>
        <Button
          variant="ghost"
          size="small"
          startIcon={<Icon icon="times" />}
          onClick={handleCancelClick}
        >
          {t(translations.Cancel)}
        </Button>
      </React.Fragment>
    ),
  ] as ActionRenderer[];
  return (
    <>
      <PageWrapper
        pageName={
          edit
            ? t(translations.menu_EditUserTraining)
            : t(translations.menu_UserTraining)
        }
        titlePage={
          training
            ? edit
              ? ` ${t(translations.UserTraining)} #${training?.Id || 'N/A'}`
              : ` ${t(translations.menu_AddNewUserTraining)}`
            : undefined
        }
        loading={loading}
        useSidePanel={useSidePanel}
        //closable={true}
        closable={useSidePanel || props.isCover}
        disableExpandToggle={props.isCover}
        closeSidePanel={props.isCover ? onClose : handleClose}
        //closeSidePanel={!!onClose ? onClose : handleClose}
        children={
          training !== undefined && !loading ? (
            <UserTrainingForm
              initialValues={training || ({} as TrainingDetailsState)}
              bindSubmitForm={bindSubmitForm}
              onSubmit={handleSubmit}
              isEdit={edit}
              onEquipmentClicked={e =>
                setCover(
                  <AssetPopUp
                    closeCover={closeCover}
                    isCover
                    identifier={{ serviceId: e.Id, serviceType: 'Online' }}
                    onClose={closeCover}
                    useSidePanel={true}
                  />,
                )
              }
              commentsFormRef={submitCommentRef}
              isAdmin={IsAdmin}
            />
          ) : (
            <React.Fragment></React.Fragment>
          )
        }
        pageLink={buildURL(
          publicUrl + 'usertraining/details',
          omit(props, ['useSidePanel']),
        )}
        toPageLink={`usertraining/details?${toQueryString(
          omit(props, ['useSidePanel']),
        )}`}
        leftActions={leftActions}
        rightActions={rightActions}
        confirm={
          requestCompleted && rejectReservations ? (
            <DialogConfirm
              isOpen={showReject && !updateResCompleted}
              title={t(translations.RejectAllReservations)}
              processing={reservationProcessing}
              body={t(translations.UserTraining_ReviseReservations)}
              confirmButtonLabel={t(translations.RejectAll)}
              cancelButtonLabel={t(translations.Cancel)}
              onConfirm={handleReject}
              onCancel={handleResCancel}
            />
          ) : approveReservations ? (
            <DialogConfirm
              title={t(translations.ApproveAllReservations)}
              body={t(translations.UserTraining_ApproveReservations)}
              isOpen={showApprove && !updateResCompleted}
              processing={reservationProcessing}
              onConfirm={handleApprove}
              onCancel={handleResCancel}
              confirmButtonLabel={t(translations.ApproveAll)}
              cancelButtonLabel={t(translations.Cancel)}
            />
          ) : (
            <></>
          )
        }
        isCover={props.isCover}
        cover={cover}
        //closeCover={closeCover}
        closeCover={props.closeCover || closeCover}
      />
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </>
  );
}
