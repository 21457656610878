import { Link } from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';
import { useOpenReservationPopUp } from 'app/pages/Actions/GlobalSelectedActions/OpenReservation';
import { AssetLink } from 'app/pages/AssetPopUp/OpenAssetDetails';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClosableMessageProps, StartedReservation } from '../slice/type';

export const ReservationStartedMessage = (
  props: StartedReservation & ClosableMessageProps,
) => {
  const { close, Asset } = props;
  const { t } = useTranslation();
  const openSidePanel = useOpenReservationPopUp();
  const assets = (
    <React.Fragment key="asset_space_link">
      <AssetLink id={Asset.Id}>{Asset.Name}</AssetLink>
    </React.Fragment>
  );
  if (props.AllowUsersToShortenReservations && props.AllUsagesEnded) {
    return (
      <>
        {assets} {t(translations.InstrumentStillReservered)}
        <Button
          onClick={() => {
            openSidePanel({ id: props.Id });
            close();
          }}
        >
          {t(translations.ReleaseUnusedReservation)}
        </Button>
      </>
    );
  }
  let messages: React.ReactNode = '';
  if (props.AllowUsersToShortenReservations) {
    const message = t(translations.YouHaveOngoingReservations);
    messages = message
      .split('{0}')
      .map<React.ReactNode>((f, i) => (
        <React.Fragment>
          <span key={'ReservationStarted' + i}> {f} </span>
        </React.Fragment>
      ))
      .reduce((prev, curr) => [prev, assets, curr]);
  } else if (props.AllowExtendReservation || props.AllowModification) {
    const message = t(translations.ReservationStarted);
    const link = (
      <Link key="res_link" onClick={() => openSidePanel({ id: props.Id })}>
        {props.Id}
      </Link>
    );
    messages = message
      .split('{0}')
      .map<React.ReactNode>((m, idx) => (
        <span key={'assets_' + idx}>
          {' '}
          {m
            .split('{1}')
            .map<React.ReactNode>((f, i) => (
              <React.Fragment>
                <span key={'ReservationStarted' + i}> {f} </span>
              </React.Fragment>
            ))
            .reduce((prev, curr) => [prev, assets, curr])}
        </span>
      ))
      .reduce((prev, curr) => [prev, link, curr]);
  }
  const canEdit =
    props.AllowUsersToShortenReservations ||
    props.AllowModification ||
    props.AllowExtendReservation;
  return (
    <>
      {messages}
      {canEdit && (
        <Button
          onClick={() => {
            openSidePanel({ id: props.Id });
            close();
          }}
        >
          {t(translations.UpdateOngoingReservations)}
        </Button>
      )}
    </>
  );
};
