import styled from 'styled-components';

export const PrintPdfWrapper = styled.div`
  &.pdf-root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 32px;
    &.pdf-root-center {
      justify-content: space-between;
    }
    & .pdf-header {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 16px 20px 0px 32px;
      & .pdf-header-content {
        display: flex;
        justify-content: space-between;
      }
    }
    & .pdf-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 0px 32px;
    }
    & .pdf-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-self: flex-end;
      padding: 0px 20px 16px 32px;
      margin-top: auto;
      gap: 16px;
      justify-content: flex-start;
      &.pdf-footer-center {
        justify-content: center;
        margin-top: 0px;
      }
    }
  }
`;
