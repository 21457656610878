/**
 * Determines if a given url is an internal usable in router link or is external to react app and should be used in ordinary anchor element's href attribute.
 * @param item menu entry
 * @returns
 */
export function isExternal(link?: string) {
  if (link === undefined) {
    return false;
  }
  return link?.includes('aspx') ?? false;
}
