import { ODataOperators } from 'api/odata/ODataFilter';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import {
  GetNumberOrUndefined,
  GetPartialEntity,
  GetRawValue,
  initPickerValue,
  URLSearchParamsCI,
} from 'app/components/BasicTable/types/FilterParam';
import { translations } from 'locales/translations';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { IOfflineServiceTypeRefsDto } from 'api/odata/generated/entities/IOfflineServiceTypeRefsDto';
import { OfflineReferenceTypeFilter } from 'app/components/BasicTable/Filters/OfflineReferenceTypeFilter';
import { initOfflineReferenceTypeData } from 'app/components/pickers/StaticOptionsPickers/OfflineRefernceTypePicker';
import ServiceShortFilter from 'app/components/BasicTable/Filters/ServiceShortFilter';
import {
  InitServiceShortDataByRefType,
  ServiceByOfflineRefernceFilter,
} from 'app/components/pickers/AutocompletePickers/ServiceShortPicker';

export const GetFilters: GetPageFilters<IOfflineServiceTypeRefsDto> =
  (t, appSettings, user, settings) => search => {
    const params = new URLSearchParamsCI(search);
    var result: IFilterSettings<IOfflineServiceTypeRefsDto>[] = [
      {
        fieldName: 'RefTypeId',
        displayNameKey: translations.ReferenceType,
        operator: ODataOperators.Equals,
        queryStringSerializer: 'refTypeId',
        value: GetPartialEntity(
          GetNumberOrUndefined(GetRawValue(params, 'refTypeId')),
        ),
        initData: initPickerValue(initOfflineReferenceTypeData),
        component: OfflineReferenceTypeFilter,
        inversionDisabled: true,
        urlKey: 'refTypeId',
      },
      {
        fieldName: 'RefId',
        displayNameKey: translations.RelatedService,
        operator: ODataOperators.Equals,
        queryStringSerializer: 'refId',
        value: GetPartialEntity(
          GetNumberOrUndefined(GetRawValue(params, 'refId')),
        ),
        initData: initPickerValue(
          InitServiceShortDataByRefType(
            GetPartialEntity(
              GetNumberOrUndefined(GetRawValue(params, 'refTypeId')),
            )?.Id ?? undefined,
          ),
        ),
        component: ServiceShortFilter,
        dependencyProp: ['RefTypeId'],
        filterPredicates: ServiceByOfflineRefernceFilter,
        urlKey: 'refId',
      },
    ];
    return result;
  };
