import React from 'react';
import {
  BaseOptionsMultiPicker,
  BaseOptionsMultiPickerProps,
} from '../BaseOptionsMultiPicker';
import { GetOptionsFilterFunction } from 'utils/enumKeys';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';

export interface EnumMultiPickerProps<TKey extends string>
  extends Omit<
    BaseOptionsMultiPickerProps<TKey>,
    'getOptions' | 'value' | 'onChange'
  > {
  EnumNamesArray: Array<TKey>;
  value: TKey[] | null;
  onChange: (value: TKey[]) => void;
  nameRender?: (value: TKey) => string;
}

export function EnumMultiPicker<TKey extends string>({
  value,
  onChange,
  nameRender,
  EnumNamesArray,
  ...props
}: EnumMultiPickerProps<TKey>) {
  const { t } = useTranslation();
  const options = React.useMemo(
    () =>
      EnumNamesArray.map(key => ({
        Id: key,
        Name: !!nameRender ? nameRender(key) : t(key) ?? key,
      })),
    [nameRender, EnumNamesArray, t],
  );

  const getOptions = React.useMemo(
    () => GetOptionsFilterFunction(options),
    [options],
  );
  const selectedOption = React.useMemo(
    () => options.filter(f => value?.includes(f.Id)) ?? null,
    [options, value],
  );
  const handleChange = (value: Entity<TKey>[]) => {
    onChange?.(value.map(e => e.Id));
  };

  return (
    <BaseOptionsMultiPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      value={selectedOption}
      onChange={handleChange}
      {...props}
    />
  );
}

/**
 * Provides a picker with the predefined options based on the array of the enum names
 * @param array Array of the Union type enum names
 * @returns
 */
export function withEnumMultiOptions<TUnion extends string>(
  array: Array<TUnion>,
) {
  return (Component: React.ComponentType<EnumMultiPickerProps<TUnion>>) => {
    return (props: Omit<EnumMultiPickerProps<TUnion>, 'EnumNamesArray'>) => (
      <Component {...props} EnumNamesArray={array} />
    );
  };
}
