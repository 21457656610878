/**
 *
 * RegionPicker
 *
 */
import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { IRegionDtoFilter } from 'app/pages/Regions/IRegionDto';

export type RegionUrl = 'base' | 'filter' | 'public';

export interface RegionPickerProps
  extends Omit<AutocompletePickerProps<IRegionDtoFilter>, 'loadData'> {
  urlType: RegionUrl;
}

const url = '/api/odata/v4/regions';
const filterUrl = '/api/odata/v4/regions/RegionsFilter';
const publicUrl = '/api/odata/v4/regions/PublicRegionsFilter';

export const initRegionData = (
  initval: string | undefined,
  urlType: RegionUrl | undefined,
) => {
  if (initval === undefined) {
    return new Promise<IRegionDtoFilter[]>((resolve, reject) => {
      resolve([] as IRegionDtoFilter[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select:
        urlType === 'base' || urlType === undefined
          ? 'Id,Name,'
          : 'Id,Name,ServiceGroups',
      $skip: 0,
      $top: 1,
    };
    let initUrl =
      urlType === undefined
        ? url
        : urlType === 'public'
        ? publicUrl
        : urlType === 'filter'
        ? filterUrl
        : url;
    return httpClient
      .get(initUrl, params)
      .then(response => response.value as IRegionDtoFilter[]);
  }
};

export function RegionPicker(props: RegionPickerProps) {
  const loadData = getAutoCompleteLoadDataFn<IRegionDtoFilter>({
    url:
      props.urlType === 'public'
        ? publicUrl
        : props.urlType === 'filter'
        ? filterUrl
        : url,
    select:
      props.urlType === 'base' || props.urlType === undefined
        ? ['Id', 'Name']
        : ['Id', 'Name', 'ServiceGroups'],
    predicates: props.predicates,
  });
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      id={props.id || 'regionId'}
      size={props.size}
      loadData={loadData}
      {...props}
    />
  );
}

export const regionFilterUrl = url;
