import { IModelStateDictionary } from './Modelstate';

export interface IResponseType extends IResponseTypeBase {
  ErrorMessages: string[];
}
export interface IResponseTypeBase {
  SuccessMessages: string[];
  WarningMessages: string[];
}
export interface IModelStateResponseType extends IResponseTypeBase {
  ErrorMessages: IModelStateDictionary;
}
export interface IResponseTypeWithId extends IResponseType {
  Id?: number;
}
export interface IResponseTypeWithLink extends IResponseTypeWithId {
  link?: string;
}
export interface IResponseTypeWithStringData extends IResponseType {
  Data?: string;
}

export const GetResponseMessages = (
  response,
  infoText: string,
): IResponseType => {
  let messages: IResponseType = {
    SuccessMessages: [],
    ErrorMessages: [],
    WarningMessages: [],
  };
  if (typeof response.value === 'string') {
    if ((response.value = 'Ok')) {
      messages.SuccessMessages.push(infoText);
    } else {
      messages.ErrorMessages.push(infoText + ': ' + response.value);
    }
  } else {
    if (response.SuccessMessages && response.SuccessMessages.length > 0) {
      response.SuccessMessages.forEach(element => {
        messages.SuccessMessages.push(element);
      });
    }
    if (response.ErrorMessages && response.ErrorMessages.length > 0) {
      response.ErrorMessages.forEach(element => {
        messages.ErrorMessages.push(element);
      });
    }
    if (response.WarningMessages && response.WarningMessages.length > 0) {
      response.WarningMessages.forEach(element => {
        messages.WarningMessages.push(element);
      });
    }
    if (
      messages.SuccessMessages.length === 0 &&
      response.ErrorMessages.length === 0 &&
      response.WarningMessages.length === 0
    ) {
      messages.SuccessMessages = [infoText];
    }
  }
  return messages;
};
