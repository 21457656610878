import { Link } from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Roles } from 'api/odata/generated/enums/Roles';
import { translations } from 'locales/translations';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentProps } from './index';

export const AddPaymentButton = ({
  children,
  ...props
}: { children?: ReactNode } & PaymentProps) => {
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const { t } = useTranslation();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const singleAdminServiceGroup =
    authenticatedUser?.Roles?.includes(Roles.ServiceGroupBudgetsAdmin) &&
    authenticatedUser.AdminServiceGroups.length === 1
      ? authenticatedUser.AdminServiceGroups[0]
      : null;
  props.serviceGroup = props.serviceGroup ?? singleAdminServiceGroup ?? null;
  return (
    <Tooltip title={t(translations.AddPayment)} arrow>
      <Link
        onClick={() => {
          dispatch(actions.toggleSidePanel(true));
          dispatch(actions.setPageType(RenderPageType.AddPayment));
          dispatch(actions.setPageProps(props));
        }}
      >
        {children ?? t(translations.AddPaymentInvoice)}
      </Link>
    </Tooltip>
  );
};
