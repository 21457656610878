//C:\Users\mikea\Projects\bookitlab\web-ui [40.6 ≡ +3 ~15 -0 | +4 ~260 -0 !]> Get-ChildItem C:\Users\mikea\Projects\bookitlab\web-ui\node_modules\date-fns\esm\locale\ -Directory | select -ExpandProperty name | ConvertTo-Json
export const dateFnsLocalesList = [
  '_lib',
  'af',
  'ar',
  'ar-DZ',
  'ar-EG',
  'ar-MA',
  'ar-SA',
  'ar-TN',
  'az',
  'be',
  'bg',
  'bn',
  'bs',
  'ca',
  'cs',
  'cy',
  'da',
  'de',
  'de-AT',
  'el',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-IE',
  'en-IN',
  'en-NZ',
  'en-US',
  'en-ZA',
  'eo',
  'es',
  'et',
  'eu',
  'fa-IR',
  'fi',
  'fr',
  'fr-CA',
  'fr-CH',
  'fy',
  'gd',
  'gl',
  'gu',
  'he',
  'hi',
  'hr',
  'ht',
  'hu',
  'hy',
  'id',
  'is',
  'it',
  'ja',
  'ja-Hira',
  'ka',
  'kk',
  'km',
  'kn',
  'ko',
  'lb',
  'lt',
  'lv',
  'mk',
  'mn',
  'ms',
  'mt',
  'nb',
  'nl',
  'nl-BE',
  'nn',
  'pl',
  'pt',
  'pt-BR',
  'ro',
  'ru',
  'sk',
  'sl',
  'sq',
  'sr',
  'sr-Latn',
  'sv',
  'ta',
  'te',
  'th',
  'tr',
  'ug',
  'uk',
  'uz',
  'uz-Cyrl',
  'vi',
  'zh-CN',
  'zh-HK',
  'zh-TW',
];
