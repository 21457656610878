import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Link as MuiLink } from '@material-ui/core';
import React from 'react';
import { buildURL } from 'utils/url-utils';
import { useSelector } from 'react-redux';
import { selectPublicUrl } from 'app/slice/selectors';
import { SubscribersDetailsProps } from 'app/pages/SubscribersDetailsPage/Details';

export interface OpenSubscriberButtonProps extends ButtonProps {
  buttonName: React.ReactNode;
  subscriberId: number;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
}
export function OpenSubscriberButton(props: OpenSubscriberButtonProps) {
  const { buttonName, subscriberId, useSidePanel, openPanelWithCover } = props;
  const { t } = useTranslation();
  const { openPanel } = useSidePanelState();
  const openOrder = () => {
    let subscProps = {
      queryParams: { id: String(subscriberId) },
      useSidePanel: true,
    } as SubscribersDetailsProps;
    let panelProps = {
      renderPageType: RenderPageType.SubscribersDetails,
      renderPageProps: subscProps,
      expanded: false,
      useSidePanel: useSidePanel,
      isCover: useSidePanel,
    } as SidePanelOpenState;
    !!openPanelWithCover
      ? openPanelWithCover(panelProps)
      : openPanel(panelProps);
  };

  return (
    <Button
      variant={props.variant || 'white'}
      size="small"
      onClick={() => openOrder()}
    >
      {buttonName || t(translations.menu_Subscriptions_Edit)}
    </Button>
  );
}
export const SubscriberLink = (props: {
  id: number;
  children: React.ReactNode;
  title?: string;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
}) => {
  const { t } = useTranslation();
  const openSubscriber = useOpenSubscriberPopUp();
  const getLink = useSubscriberLinkUrl();
  const link = getLink(props.id);
  const open = () => {
    openSubscriber(
      {
        id: props.id,
        useSidePanel: props.useSidePanel,
      },
      props.openPanelWithCover,
    );
  };
  return (
    <Tooltip title={props.title ?? t(translations.SubscribersDetails)}>
      <MuiLink
        onClick={event => {
          if (!!link) {
            if (!event.ctrlKey) {
              event.preventDefault();
              open();
            }
          } else {
            open();
          }
        }}
        color="textPrimary"
        href={link}
      >
        {props.children}
      </MuiLink>
    </Tooltip>
  );
};

export const useOpenSubscriberPopUp = () => {
  const { openPanel } = useSidePanelState();
  return React.useCallback(
    (
      props: { id: number; useSidePanel?: boolean },
      openPanelWithCover?: (state: SidePanelOpenState) => void,
    ) => {
      let subscProps = {
        queryParams: { id: String(props.id) },
        useSidePanel: true,
      } as SubscribersDetailsProps;
      const panelState = {
        renderPageType: RenderPageType.SubscribersDetails,
        renderPageProps: subscProps,
        expanded: false,
        useSidePanel: props.useSidePanel,
        isCover: props.useSidePanel,
      } as SidePanelOpenState;
      if (!!openPanelWithCover) {
        openPanelWithCover(panelState);
      } else {
        openPanel({
          renderPageType: RenderPageType.SubscribersDetails,
          renderPageProps: subscProps,
          expanded: false,
          useSidePanel: false,
          isCover: false,
        } as SidePanelOpenState);
      }
    },
    [openPanel],
  );
};
export const useSubscriberLinkUrl = () => {
  const publicUrl = useSelector(selectPublicUrl);
  return React.useCallback(
    (Id: number) => {
      return buildURL(publicUrl + 'Subscribers/details', { id: Id });
    },
    [publicUrl],
  );
};
