/**
 *
 * AssetCatGroupFilter
 *
 */
import { AssetCatGroupPicker } from 'app/components/pickers/AutocompletePickers/AssetCatGroupPicker';
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { IAssetCatGroupDto } from 'api/odata/generated/entities/IAssetCatGroupDto';

export const AssetCatGroupFilter = (props: IFilterComponentProps) => {
  const { t } = useTranslation();
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  if (props.readonly === true) {
    return <>{props.value?.Name}</>;
  }
  return (
    <AssetCatGroupPicker
      ariaLabel={props.label}
      id={props.id}
      value={props.value as IAssetCatGroupDto}
      onChange={handleOnChange}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
      predicates={props.predicates}
      size="small"
      onPickerOpen={props.onOpen}
      noGlobalServiceGroupFilter={props.withoutServiceGroups ?? false}
      urlType={props.urlType}
      fullWidth={props.fullWidth}
    />
  );
};

export default AssetCatGroupFilter;
