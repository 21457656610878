import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { toURLSearchParams } from 'app/components/BasicTable/withSavedHistory';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { AssetPopUp } from 'app/pages/AssetPopUp';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';
import { Condition } from 'api/odata/ODataFilter';
import { selectExpandedSidePanel } from 'app/Layout/FrontendLayout/slice/selectors';
import { DetectIsMobile } from 'utils/mobileDetect';
import { selectInventoryLogFilters } from 'app/pages/AssetsInventory/Details/slice/selectors';
import { useAssetInventorySlice } from 'app/pages/AssetsInventory/Details/slice';
import { IBarcodeScanLogDto } from './IBarcodeScanLogDto';
import { InventoryLogTable } from './InventoryLogTable';
import { buildURL } from 'utils/url-utils';
import { IAuthenticatedUser } from 'types/AuthenticatedUser';
import { presetDatesRangeToQueryString } from 'app/components/BasicTable/types/FilterParam';
import { PresetDatesRange } from 'types/PresetDatesRange';
import { getPeriodTypesEntity } from 'app/components/DatePeriods';
import { dateUtils } from 'utils/date-utils';

export interface InventoryLogProps extends CoverProps, SidePanelContentProps {
  roomId?: number;
  customPredicates?: (string | Condition<IBarcodeScanLogDto>)[];
  pageLink?: string;
  onClose?: () => void;
  searchTerm?: string;
}

export function InventoryLog(props: InventoryLogProps) {
  const { useSidePanel, roomId, customPredicates, onClose, searchTerm } = props;
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const filters = useSelector(selectInventoryLogFilters);
  const dispatch = useDispatch();
  const { actions } = useAssetInventorySlice();
  const { t } = useTranslation();
  const { actions: layoutActions } = useLayoutSlice();
  const publicUrl = useSelector(selectPublicUrl);
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const isMobile = DetectIsMobile();
  const [cover, setCover] = React.useState<React.ReactNode>();

  const showShortView =
    isMobile || (useSidePanel && !sidePanelExpanded) || props.isCover;
  const pagelink = React.useMemo(
    () =>
      !!props.pageLink
        ? props.pageLink
        : buildURL(
            `${publicUrl}Assets/Inventory/Log`,
            Object.assign({}, filters, {
              RoomId: roomId,
              searchTerm: searchTerm,
            }),
          ),
    [filters, props.pageLink, publicUrl, roomId, searchTerm],
  );
  const closeCover = () => {
    setCover(undefined);
  };

  const closeSidePanel = React.useCallback(() => {
    if (props.isCover) {
      if (!!onClose) {
        onClose();
      } else {
        dispatch(layoutActions.resetSidePanel());
      }
    } else {
      dispatch(layoutActions.resetSidePanel());
    }
  }, [dispatch, layoutActions, onClose, props.isCover]);
  const openAsset = React.useCallback((id: number) => {
    setCover(
      <AssetPopUp
        closeCover={closeCover}
        isCover
        identifier={{ id: id }}
        onClose={closeCover}
        showAllCores={true}
        useSidePanel={true}
      />,
    );
  }, []);

  //   const IsAdmin = React.useCallback(
  //     (log: IBarcodeScanLogDto) => {
  //       return (
  //         authenticatedUser?.IsAllGroupOrLabTechAdmin(
  //           [log.ServiceGroupId ?? 0],
  //           [
  //             {
  //               Id: log.ServiceId,
  //               Name: log.AssetName,
  //               ServiceTypeId: log.ServiceTypeId,
  //             } as IServiceTypeFilterDto,
  //           ],
  //         ) ||
  //         authenticatedUser?.Roles?.includes(Roles.Administrators) ||
  //         authenticatedUser?.Roles?.includes(Roles.InventoryAdmin)
  //       );
  //     },
  //     [authenticatedUser],
  //   );

  const setfilterCallback = React.useCallback(
    (v: IFilterSettings<IBarcodeScanLogDto>[] | undefined) => {
      const res = {};
      if (!!v) {
        toURLSearchParams(v).forEach((val, name) => {
          res[name] = val;
        });
      }
      dispatch(actions.setLogFilters(!v || v.length === 0 ? undefined : res));
    },
    [actions, dispatch],
  );
  const defaultFilterSearch = React.useCallback(
    (user: IAuthenticatedUser) => {
      let dateFilter = presetDatesRangeToQueryString(
        'period',
        'st',
        'et',
      )(
        PresetDatesRange.fromInitPreset(
          getPeriodTypesEntity('CustomDate'),
          dateUtils.addMonths(new Date(), -1),
          new Date(),
        ),
      );
      if (roomId !== undefined) {
        dateFilter.set('roomId', roomId.toString());
      }
      return dateFilter;
    },
    [roomId],
  );
  return (
    <PageWrapper
      id="inventory_log_page"
      pageLink={pagelink}
      useSidePanel={useSidePanel || false}
      pageName={undefined}
      disableExpandToggle
      //loading={loading}
      //pageName={<Link href="coreselection">{t(translations.menu_CoreSelection)}</Link>}
      titlePage={t(translations.menu_AssetInventoryLog)}
      closable={useSidePanel || props.isCover}
      //disableExpandToggle={true}
      //rightTopActions={topButtons}
      isCover={props.isCover}
      hideTitleBar={false}
      cover={cover}
      closeCover={props.closeCover || closeCover}
      closeSidePanel={props.isCover ? onClose : undefined}
      //leftTopActions={pageActions}
      children={
        <React.Fragment>
          <InventoryLogTable
            useSidePanel={useSidePanel}
            setFilters={setfilterCallback}
            authenticatedUser={authenticatedUser}
            closeSidePanel={closeSidePanel}
            screenName={undefined}
            openAsset={openAsset}
            customPredicates={customPredicates}
            isShortView={showShortView}
            emptyTable={undefined}
            searchTerm={searchTerm}
            defaultFilter={defaultFilterSearch}
          />
        </React.Fragment>
      }
    />
  );
}
