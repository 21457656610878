/**
 *
 * SubscribersDetails
 *
 */
import { Box } from '@material-ui/core';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Progress } from 'app/components/LoadingIndicator';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import useSidePanelState, {
  SidePanelCloseState,
} from 'app/hooks/useSidePanelOpen';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import {
  selectConfirmApproved,
  selectConfirmRejected,
  selectContinueState,
  selectContinueToLink,
  selectHasNotSavedChanges,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';
import { useAppSettingsSlice } from 'app/slice';
import { selectPublicUrl } from 'app/slice/selectors';
import { push } from 'connected-react-router';
import { translations } from 'locales/translations';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { buildURL } from 'utils/url-utils';
import { SaveFormButton } from './Actions/SubscribersActions';
import { useSubscribersDetailsSlice } from './slice';
import {
  selectCreateSubscribersDetails,
  selectCreateSubscribersDetailsCompleted,
  selectSubscribersDetailsProcessing,
  selectUpdateSubscribersDetails,
  selectUpdateSubscribersDetailsCompleted,
} from './slice/selectors';
import {
  SubscribersDetailsQStringParameters,
  SubscribersDetailsState,
} from './slice/types';
import { SubscribersForm } from './SubscribersForm';
import { Beforeunload } from 'react-beforeunload';
import { FormikProps } from 'formik';

export interface SubscribersDetailsProps
  extends SidePanelContentProps,
    CoverProps {
  queryParams: SubscribersDetailsQStringParameters;
  useSwitchButtons?: boolean;
  onCloseSubscribers?: () => void;
}

export const SubscribersDetails = React.memo(function SubscribersDetails(
  props: SubscribersDetailsProps,
) {
  //PROPS
  const {
    queryParams,
    useSidePanel,
    //useSwitchButtons,
    closeCover,
    isCover,
    //onCloseSubscribers,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  //PERMISSIONS
  //const User = useSelector(selectAuthenticatedUser);
  //const IsAdmin =
  //  User !== undefined && User.Roles.includes(Roles.Administrators);

  //SLICES
  const { actions } = useSubscribersDetailsSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const { actions: appSettingsActions } = useAppSettingsSlice();

  //SELECTORS
  const publicUrl = useSelector(selectPublicUrl);
  //====================================================================
  const hasChanges = useSelector(selectHasNotSavedChanges, shallowEqual);
  const continueLink = useSelector(selectContinueToLink);
  const loading = useSelector(selectSubscribersDetailsProcessing);
  //====================================================================
  const confirmRejected = useSelector(selectConfirmRejected);
  const confirmApproved = useSelector(selectConfirmApproved);
  const continueToOther = useSelector(selectContinueState);
  //====================================================================
  const requestCreateCompleted = useSelector(
    selectCreateSubscribersDetailsCompleted,
  );
  const requestUpdateCompleted = useSelector(
    selectUpdateSubscribersDetailsCompleted,
  );
  //====================================================================
  const createModel = useSelector(selectCreateSubscribersDetails, shallowEqual);
  const editModel = useSelector(selectUpdateSubscribersDetails, shallowEqual);
  const baseModel = createModel || editModel;

  //COVER PROPS
  const { cover, openPanel, closePanel, coverClosed, onCloseCover } =
    useSidePanelState(
      () => {
        if (baseModel === createModel) {
          dispatch(actions.resetCreateSubscribersDetailsState());
        } else if (baseModel === editModel) {
          dispatch(actions.resetUpdateSubscribersDetailsState());
        }
        return true;
      },
      undefined,
      useSidePanel,
      isCover,
    );

  //STATES
  const [edit, setEdit] = useState<boolean>(false);
  const [busy, setBusy] = useState<boolean>(false);
  const [requestCompleted, setRequestCompleted] = useState<boolean | undefined>(
    undefined,
  );

  //REF
  const submitFormRef = useRef<any>(null);
  const innerFormRef =
    React.useRef<FormikProps<SubscribersDetailsState> | null>(null);

  //FUNCTIONS
  const bindSubmitForm = useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmitForm = (e: any) => {
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };

  const handleSaveClick = (e: any) => {
    handleSubmitForm(e);
  };

  const handleSubmit = useCallback(
    (values: SubscribersDetailsState) => {
      setBusy(true);
      if (edit) {
        dispatch(
          actions.updateSubscribersDetails({
            current: values,
            original: baseModel,
          }),
        );
      } else {
        dispatch(actions.createSubscribersDetails(values));
      }
    },
    [actions, baseModel, dispatch, edit],
  );

  const handleCloselClick = React.useCallback(() => {
    if (useSidePanel) {
      closePanel({
        isCover: isCover || !!cover,
        useSidePanel: useSidePanel,
        showConfirm: hasChanges,
        onClose: () => {
          if (baseModel === createModel) {
            dispatch(actions.resetCreateSubscribersDetailsState());
          } else if (baseModel === editModel) {
            dispatch(actions.resetUpdateSubscribersDetailsState());
          }
        },
      } as SidePanelCloseState);
    } else {
      dispatch(push('/Subscribers'));
    }
  }, [
    actions,
    baseModel,
    closePanel,
    cover,
    createModel,
    dispatch,
    editModel,
    hasChanges,
    isCover,
    useSidePanel,
  ]);

  //USE EFFECT ON MOUNT
  useEffectOnMount(() => {
    if (
      queryParams.id !== undefined &&
      !isNaN(parseInt(queryParams.id || ''))
    ) {
      dispatch(actions.initUpdateSubscribersDetails(queryParams));
    } else {
      dispatch(actions.initSubscribersDetails(queryParams));
    }
    setBusy(false);
    setEdit(false);
    setRequestCompleted(undefined);
    return () => {};
  });

  //USE EFFECT
  useEffect(() => {
    let active = loading === false;
    if (active) {
      if (queryParams.id !== undefined && !isNaN(parseInt(queryParams.id))) {
        setEdit(true);
      }
      if (requestCreateCompleted === true || requestUpdateCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
      } else if (
        requestCreateCompleted === false ||
        requestUpdateCompleted === false
      ) {
        setRequestCompleted(false);
        setBusy(false);
      }
      if (requestUpdateCompleted === true || requestCreateCompleted === true) {
        handleCloselClick();
      }
    }
    return () => {
      active = false;
    };
  }, [
    handleCloselClick,
    loading,
    queryParams.id,
    requestCreateCompleted,
    requestUpdateCompleted,
  ]);

  //USE EFFECT 2
  useEffect(() => {
    if (confirmApproved) {
      if (edit) {
        dispatch(actions.resetUpdateSubscribersDetailsState());
      } else {
        dispatch(actions.resetCreateSubscribersDetailsState());
      }
      if (continueToOther.continueOnApprove) {
        dispatch(layoutActions.resetSidePanel());
        setTimeout(() => {
          dispatch(
            layoutActions.openSidePanel({
              type: continueToOther.pageType,
              props: continueToOther.pageProps,
              expanded: continueToOther.expanded,
            }),
          );
          dispatch(layoutActions.resetContinueState());
        }, 200);
      } else if (continueLink !== undefined) {
        dispatch(appSettingsActions.navigate(continueLink));
        dispatch(layoutActions.resetSidePanel());
      } else {
        dispatch(layoutActions.resetSidePanel());
      }
    }
    if (confirmRejected) {
      dispatch(layoutActions.resetConfirmState());
    }
    return () => undefined;
  }, [
    actions,
    appSettingsActions,
    confirmApproved,
    confirmRejected,
    continueLink,
    continueToOther,
    dispatch,
    edit,
    layoutActions,
  ]);

  //LEFT ACTIONS
  const leftActions = [
    () => (
      <Fragment>
        <SaveFormButton
          size="small"
          startIcon={<Icon icon="save" />}
          onClick={handleSaveClick}
          disabled={busy}
          processing={!requestCompleted && busy}
          edit={edit}
          text={t(translations.Save)}
        />
      </Fragment>
    ),
  ] as ActionRenderer[];

  //RIGHT ACTIONS
  const rightActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <IconButton
            variant="ghost"
            size="small"
            title={t(translations.Close)}
            onClick={handleCloselClick}
          >
            <Icon icon="times" />
          </IconButton>
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [handleCloselClick, t]);

  //RENDER
  return (
    <>
      <PageWrapper
        pageName={
          edit
            ? t(translations.menu_Subscriptions_Edit)
            : t(translations.menu_Subscriptions_AddNew)
        }
        titlePage={
          baseModel
            ? edit
              ? ` ${t(translations.menu_Subscriptions_Edit)} #${
                  queryParams.id || 'N/A'
                }`
              : ` ${t(translations.menu_Subscriptions_AddNew)}`
            : undefined
        }
        loading={loading}
        useSidePanel={useSidePanel}
        leftActions={leftActions}
        rightActions={rightActions}
        closable={true}
        disableExpandToggle={true}
        closeSidePanel={handleCloselClick}
        pageLink={buildURL(
          publicUrl + 'Subscribers/details',
          props.queryParams,
        )}
        isCover={isCover}
        cover={cover}
        closeCover={!isCover ? onCloseCover : closeCover}
        coverClosed={coverClosed}
        children={
          baseModel !== undefined && !loading ? (
            <Fragment>
              <SubscribersForm
                initialValues={baseModel || ({} as SubscribersDetailsState)}
                bindSubmitForm={bindSubmitForm}
                onSubmit={handleSubmit}
                useSidePanel={useSidePanel}
                isCover={isCover}
                openPanelWithCover={openPanel}
                isEdit={edit}
                isAdmin={true}
                formRef={innerFormRef}
                //isAdmin={IsAdmin}
              />
            </Fragment>
          ) : (
            <Fragment>
              {' '}
              <Box component="div">
                <Progress inProgress={loading} size={80} />
              </Box>
            </Fragment>
          )
        }
      />
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </>
  );
});
