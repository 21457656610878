import { CostCentersDetailsQStringParameters } from 'app/pages/CostCentersDetailsPage/Details/slice/types';
import { IResponseType } from 'types/ResponseType';
import { httpClient } from './HttpClient';
import { ICostCentersDto } from './odata/generated/entities/ICostCentersDto';

export const CostCentersApi = {
  initCreateData: (payload: CostCentersDetailsQStringParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/CostCenters/InitCostCenters';
    return httpClient.post(url, data);
  },
  initUpdateData: (payload: CostCentersDetailsQStringParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/CostCenters/InitCostCenters';
    return httpClient.post(url, data);
  },
  insertCostCentersDetails: (payload: ICostCentersDto) => {
    const url = '/api/odata/v4/CostCenters'; //POST action in Controller
    return httpClient.post(url, payload);
  },
  updateCostCentersDetails: (payload: ICostCentersDto) => {
    const url = `/api/odata/v4/CostCenters(${payload.Id})`; //PUT action in Controller
    return httpClient.put(url, payload);
  },

  deleteCostCenter: (Id: number): Promise<IResponseType> => {
    let url = `/api/odata/v4/CostCenters(${Id})`;
    return httpClient.delete(url);
  },
  deleteSelectedRows: (payload: number[]) => {
    const url = `/api/odata/v4/CostCenters/DeleteSelected`;
    const data = { keys: payload };
    return httpClient.post(url, data);
  },
};
