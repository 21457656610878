import { BasicTabs } from 'app/components/BasicTabs';
import { TabContent } from 'app/components/BasicTabs/TabContent';
import { TabPanel, TabPanelProps } from 'app/components/BasicTabs/TabPanel';
import { MobileDropDownTabs } from 'app/pages/AssetPopUp/components/MobileDropDownTabs';
import * as React from 'react';

export interface UserTabsRenderProps {
  tabs: TabContent[];
  shortView: boolean;
  isMobile: boolean;
}
export function UserTabsRender(props: UserTabsRenderProps) {
  const { tabs, shortView, isMobile } = props;

  const [selectedTab, setSelectedTab] = React.useState<TabContent>(tabs[0]);

  const tabsId = 'user_profile_tab';
  const tabsPanelId = 'user_profile_tabpanel';

  const handleSelectedTabChange = (newValue: TabContent) => {
    setSelectedTab(newValue);
  };
  const tabsPanels: Array<React.ReactElement<TabPanelProps>> =
    React.useMemo(() => {
      return tabs.map((item, index) => (
        <TabPanel
          value={tabs.findIndex(f => f.Id === selectedTab.Id)}
          index={index}
          tabsId={tabsId}
          tabsPanelId={tabsPanelId}
          scrollableContent={true}
          key={item.Id}
        >
          {item.Content}
        </TabPanel>
      ));
    }, [selectedTab.Id, tabs]);
  return (
    <React.Fragment>
      {shortView ? (
        <MobileDropDownTabs
          tabsId={'user_profile_tab'}
          tabsPanelId={'user_profile_tabpanel'}
          tabs={tabs}
          isMobile={isMobile}
          handleTabChange={handleSelectedTabChange}
          selectedTab={selectedTab}
          tabPanels={tabsPanels}
        />
      ) : (
        <BasicTabs
          orientation="horizontal"
          tabsId={'user_profile_tab'}
          tabsPanelId={'user_profile_tabpanel'}
          tabs={tabs}
          variant={'scrollable'}
          selectedTabIndex={tabs.findIndex(f => f.Id === selectedTab.Id)}
          scrollableContent={true}
          handleTabChange={handleSelectedTabChange}
          tabPanels={tabsPanels}
        />
      )}
    </React.Fragment>
  );
}
