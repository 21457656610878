import { SamplePlatesDetailsQStringParameters } from 'app/pages/Samples/SamplePlatesDetailsPage/Details/slice/types';
import { httpClient } from './HttpClient';
import { ISamplePlateDto } from './odata/generated/entities/ISamplePlateDto';

export const SamplePlatesApi = {
  initCreateData: (payload: SamplePlatesDetailsQStringParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/SamplePlates/InitSamplePlates';
    return httpClient.post(url, data);
  },
  initUpdateData: (payload: SamplePlatesDetailsQStringParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/SamplePlates/InitSamplePlates';
    return httpClient.post(url, data);
  },
  insertSamplePlatesDetails: (payload: ISamplePlateDto) => {
    const url = '/api/odata/v4/SamplePlates'; //POST action in Controller
    return httpClient.post(url, payload);
  },
  updateSamplePlatesDetails: (changedId: string, payload: ISamplePlateDto) => {
    const url = '/api/odata/v4/SamplePlates/EditSamplePlate';
    const data = {
      samplePlateId: changedId,
      samplePlate: payload,
    };
    return httpClient.post(url, data);
  },
};
