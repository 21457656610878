import { DependencyList, useEffect, useState } from 'react';

export const useAsyncGetWithEffect = <T>(
  load: () => Promise<T>,
  defaultVal: T,
  deps?: DependencyList,
  setBusy?: (busy: boolean) => void,
) => {
  const [val, setVal] = useState<T>(defaultVal);
  useEffect(() => {
    let mounted = true;
    setBusy?.(true);
    (async () => {
      try {
        const res = await load();
        if (mounted) {
          setVal(() => res);
        }
      } catch (e) {
        console.error(e);
        if (mounted) {
          setBusy?.(false);
        }
      } finally {
        if (mounted) {
          setBusy?.(false);
        }
      }
    })();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return val;
};
