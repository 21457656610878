import { getAssetImageThumbSrc } from 'app/components/AssetThumbImage/getAssetImageSrc';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { useOpenAssetPopUp } from 'app/pages/AssetPopUp/OpenAssetDetails';
import { selectImageExist } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import { CellProps, Renderer } from 'react-table';

type RecordType = Record<string, any>;
const AssetImageRenderer: Renderer<CellProps<RecordType>> = ({
  value,
  row,
}) => {
  const imageExist = useSelector(state => selectImageExist(state, value));
  const openAsset = useOpenAssetPopUp();
  const open = () => {
    openAsset({ id: row.original.Id });
  };
  return (
    <>
      <Tooltip title={row.original.Name} arrow>
        <span onClick={open}>
          <img
            src={getAssetImageThumbSrc(value, 'thumbSmall', imageExist)}
            alt=""
          />
        </span>
      </Tooltip>
    </>
  );
};
export default AssetImageRenderer;
