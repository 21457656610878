import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarMessagesDetailsState } from '../slice/types';
import * as Yup from 'yup';
import { translations } from 'locales/translations';
import i18next from 'i18next';
import { Formik } from 'formik';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRightSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import { FormOnlineServicesSinglePicker } from 'app/components/Forms/FormEquipmentsPicker';
import { FormListener } from 'app/components/Forms/FormRender/FormRenderer';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { BaseOptionsMultiPicker } from 'app/components/BasicPickers/BaseOptionsMultiPicker';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Roles } from 'api/odata/generated/enums/Roles';
import { undefinedIfIsEmpty } from 'app/components/BasicTable/useProfileSetting/parseProfileUrl';
import { Entity } from 'types/common';
import { FormBookitDateTimePicker } from 'app/components/Forms/FormBookitDateTimePicker';
import { FormNumberField } from 'app/components/Forms/FormNumberField';
import { EntityNumberSchema } from 'app/components/Forms/Schemas';
import { FormServiceGroupsPicker } from 'app/components/Forms/formserviceGroupsPicker';
import { DetailsWrapper } from 'app/pages/ReservationDetails/Details/components/ReadOnlyDetails';
import { Body } from 'app/components/Typography';
import { dateUtils } from 'utils/date-utils';

export interface CalendarMessagesFormProps {
  initialValues: CalendarMessagesDetailsState;
  onSubmit: (item: CalendarMessagesDetailsState) => void;
  isEdit: boolean;
  isAdmin: boolean;
  bindSubmitForm: any;
}

export const CalendarMessagesForm = React.memo(function CalendarMessagesForm({
  initialValues,
  onSubmit,
  isEdit,
  isAdmin,
  bindSubmitForm,
}: CalendarMessagesFormProps): JSX.Element {
  //SETTINGS
  const { t } = useTranslation();
  const { newDate } = useSystemDate();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const initValues = React.useMemo(() => {
    if (isEdit) return initialValues;
    return { ...initialValues, StartDate: newDate(), EndDate: newDate() };
  }, [initialValues, isEdit, newDate]);

  //STATES
  const [IsServiceGroupChanged, setIsServiceGroupChanged] =
    useState<boolean>(false);

  //FUNCTIONS
  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(value);
    }
  };

  const handleFormChange = useCallback(
    (values: CalendarMessagesDetailsState, isValid, dirty, setValue) => {
      if (IsServiceGroupChanged) {
        setValue('Equipment', null, false);
      }
      setIsServiceGroupChanged(false);
    },
    [IsServiceGroupChanged],
  );

  const getDisabledInfo = useCallback(
    (entity: Entity<string>) => {
      const id = entity.Id;
      if (
        id === 'system' &&
        !authenticatedUser?.Roles.includes(Roles.Administrators)
      ) {
        return 'Disabled: You need to be a main admin';
      }
      if (
        id === 'core' &&
        !authenticatedUser?.Roles.includes(Roles.GroupAdministrators)
      ) {
        return 'Disabled: You need to be a core admin';
      }
      return undefined;
    },
    [authenticatedUser?.Roles],
  );

  const hasAdminGroups = !!authenticatedUser?.AdminServiceGroups.length;
  //SCHEMA
  const calendarMessagesDetailsSchema: Yup.SchemaOf<CalendarMessagesDetailsState> =
    Yup.object({
      Id: Yup.number().notRequired().default(-1),
      ServiceGroups: Yup.array(EntityNumberSchema)
        .when({
          is: () => !isAdmin && hasAdminGroups,
          then: schema => schema.min(1).required(),
          otherwise: schema => schema.notRequired(),
        })
        .label(i18next.t(translations.Laboratory)),
      Equipment: Yup.mixed()
        .nullable()
        .when({
          is: () => !isAdmin && !hasAdminGroups,
          then: schema => schema.required(),
          otherwise: schema => schema.notRequired(),
        })
        .label(i18next.t(translations.Equipment)),
      Title: Yup.string()
        .nullable()
        .default('')
        .required()
        //.required(i18next.t(translations.err_AttributeRequired))
        .label(i18next.t(translations.Title)),
      StartDate: Yup.date()
        .default(newDate())
        .nullable()
        .notRequired()
        .label(i18next.t(translations.StartDate)),
      EndDate: Yup.date()
        .default(newDate())
        .nullable()
        .notRequired()
        .label(i18next.t(translations.EndDate)),
      PlantDate: Yup.date()
        .nullable()
        .notRequired()
        .label(i18next.t(translations.PlantDate)),
      Body: Yup.string()
        .nullable()
        .default('')
        .label(i18next.t(translations.Body)),
      Description: Yup.string()
        .default('')
        .nullable()
        .notRequired()
        .label(i18next.t(translations.Description)),
      DisplaySeconds: Yup.number()
        .notRequired()
        .default(60)
        .label(i18next.t(translations.Group)),
      ShowOn: Yup.string()
        .default(null)
        .nullable()
        .required('Please select at least one option for Show On.')
        .label('Show on'),
      CreatedBy: Yup.mixed()
        .nullable()
        .notRequired()
        .label(i18next.t(translations.CreatedBy)),
      CreatedAt: Yup.date()
        .nullable()
        .notRequired()
        .label(i18next.t(translations.CreatedAt)),
      UpdatedAt: Yup.date()
        .nullable()
        .notRequired()
        .label(i18next.t(translations.UpdatedAt)),
    });

  //RENDER
  return (
    <>
      <Formik
        initialValues={initValues}
        validationSchema={calendarMessagesDetailsSchema}
        validateOnMount={true}
        validateOnBlur={false}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          console.debug('Submitting: ', values);
          formikHelpers.setSubmitting(true);
          handleSubmit(values);
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            //console.log('User Attributes Details Errors: ', formik.errors);
          }
          return (
            <>
              <StyledForm onSubmit={formik.handleSubmit}>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={['ServiceGroups', 'Equipment']}
                />
                <FormLeftSection>
                  <FormFieldsSection
                    titleSection={t(translations.Details) as string}
                  >
                    <FormFieldsSection>
                      <FormRow fullRow>
                        <BaseOptionsMultiPicker
                          getOptions={search => {
                            return options.filter(o => {
                              if (!!getDisabledInfo(o)) return false;
                              if ((search ?? '') === '') return true;
                              return o.Name.includes(search ?? '');
                            });
                          }}
                          onChange={val => {
                            formik.setFieldValue(
                              'ShowOn',
                              undefinedIfIsEmpty(val?.map(o => o.Id).join(',')),
                            );
                          }}
                          info={t(translations.ShowOn_information)}
                          value={
                            formik.values.ShowOn?.split(',')
                              .map(o => options.find(f => f.Id === o))
                              .filter(f => f !== undefined) as Entity<string>[]
                          }
                          placeholder="Select where to show"
                          disabled={formik.isSubmitting}
                          label="Show on"
                          fullWidth
                          variant="filled"
                          error={!!formik.errors.ShowOn}
                          helperText={formik.errors.ShowOn}
                        />
                      </FormRow>
                    </FormFieldsSection>
                    <FormRow fullRow>
                      <FormServiceGroupsPicker
                        id="ServiceGroups"
                        name="ServiceGroups"
                        placeholder={
                          isAdmin
                            ? t(translations.AllServiceGroups)
                            : t(translations.SelectCores)
                        }
                        label={t(translations.ServiceGroups)}
                        disabled={formik.isSubmitting}
                        urlType="admin"
                        onChange={() => setIsServiceGroupChanged(true)}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow fullRow>
                      <FormOnlineServicesSinglePicker
                        id="EquipmentID"
                        name="Equipment"
                        fullWidth
                        placeholder={t(translations.Equipment)}
                        label={t(translations.Equipment)}
                        disabled={formik.isSubmitting}
                        serviceGroups={formik.values.ServiceGroups}
                        urlType={'admin'}
                      />
                    </FormRow>
                    <FormRow>
                      <FormTextField
                        id="TitleID"
                        name="Title"
                        label={t(translations.Title)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow fullRow>
                      <FormRichTextField
                        id="BodyID"
                        name="Body"
                        placeholder={t(translations.Body)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow>
                      <FormBookitDateTimePicker
                        id="StartDateID"
                        name="StartDate"
                        // label={t(translations.StartDate)}
                        label={t(translations.From)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow>
                      <FormBookitDateTimePicker
                        id="EndDateID"
                        name="EndDate"
                        // label={t(translations.CalendarMessagesEndDate)}
                        label={t(translations.To)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                  </FormFieldsSection>
                </FormLeftSection>
                <FormRightSection>
                  <FormFieldsSection>
                    <FormRow fullRow>
                      <FormNumberField
                        id="DisplaySeconds"
                        name="DisplaySeconds"
                        inputMode="decimal"
                        label={t(translations.DisplaySeconds)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                  </FormFieldsSection>
                  {isEdit && (
                    <FormFieldsSection>
                      <DetailsWrapper className={'readonly-root'}>
                        <div className={'readonly-content'}>
                          {initialValues['CreatedAt'] !== null &&
                            !!initialValues['CreatedAt'] && (
                              <div className={'readonly-row'}>
                                <Body size="small">
                                  {t(translations.CreatedAt)}
                                </Body>
                                <Body size="small" bold={true}>
                                  {dateUtils.longDateTimeFormat(
                                    initialValues['CreatedAt'],
                                  )}
                                </Body>
                              </div>
                            )}
                          {initialValues['CreatedBy'] !== null &&
                            !!initialValues['CreatedBy']?.Id && (
                              <div className={'readonly-row'}>
                                <Body size="small">
                                  {t(translations.CreatedBy)}
                                </Body>
                                <Body size="small" bold={true}>
                                  {initialValues['CreatedBy']?.Name}
                                </Body>
                              </div>
                            )}
                        </div>
                      </DetailsWrapper>
                    </FormFieldsSection>
                  )}
                </FormRightSection>
              </StyledForm>
            </>
          );
        }}
      </Formik>
    </>
  );
});

const options = [
  { Id: 'system', Name: 'System Dashboard' },
  { Id: 'core', Name: 'Core Dashboard' },
  { Id: 'calendar', Name: 'Schedule' },
];
