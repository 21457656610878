/**
 *
 * PosLayout
 *
 */
import * as React from 'react';
import styled from 'styled-components';

export interface PosLayoutProps {
  children: React.ReactNode;
}

/**
 * Empty Layout optimized for touch enabled devices - medium screen size
 * Originally developed for a POS (large anminals - technion anm)
 */
export function PosLayout({ children }: PosLayoutProps) {
  return (
    <PosLayoutWrapper className={'pos-root'}>
      <main className={'pos-content'}>{children}</main>
    </PosLayoutWrapper>
  );
}
const PosLayoutWrapper = styled.div`
  &.pos-root {
    display: flex;
    & .pos-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100dvh;
      overflow: auto;
    }
  }
`;
