import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import React from 'react';
import { RegionDetailsProps } from './RegionsDetailsPage/Details';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { translations } from 'locales/translations';
import { Link as MuiLink } from '@material-ui/core';
import { IRegionDto } from './IRegionDto';

export interface RegionLinkProps {
  id: number;
  children: React.ReactNode;
  title?: string;
  useSidePanel?: boolean;
  initialModel?: IRegionDto;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
}

export const RegionLink = (props: RegionLinkProps) => {
  const { t } = useTranslation();
  const openRegion = useOpenRegionPopUp();

  const open = () => {
    openRegion(
      {
        id: props.id,
        useSidePanel: props.useSidePanel,
        initialModel: props.initialModel,
      },
      props.openPanelWithCover,
    );
  };
  return (
    <Tooltip title={props.title ?? t(translations.RegionDetails)}>
      <MuiLink onClick={() => open()} color="textPrimary">
        {props.children}
      </MuiLink>
    </Tooltip>
  );
};

export const useOpenRegionPopUp = () => {
  const { openPanel } = useSidePanelState();

  return React.useCallback(
    (
      props: { id: number; useSidePanel?: boolean; initialModel?: IRegionDto },
      openPanelWithCover?: (state: SidePanelOpenState) => void,
    ) => {
      const identifier = props.id;
      if (identifier !== undefined) {
        const popupProps: RegionDetailsProps = {
          id: '' + props.id,
          initialModel: props.initialModel,
          useSidePanel: props.useSidePanel ?? false,
        };
        if (!!openPanelWithCover) {
          openPanelWithCover({
            renderPageType: RenderPageType.RegionDetails,
            renderPageProps: popupProps,
            expanded: false,
            useSidePanel: props.useSidePanel,
            isCover: props.useSidePanel,
          });
        } else {
          openPanel({
            renderPageType: RenderPageType.RegionDetails,
            renderPageProps: popupProps,
            expanded: false,
            useSidePanel: false,
            isCover: false,
          } as SidePanelOpenState);
        }
      }
    },
    [openPanel],
  );
};
