import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import { DatePickerVariants } from 'app/components/DatePicker';
import { FormRow } from 'app/components/Forms/FormsLayout/FormRow';
import { ReservationDetailsState } from '../../slice/types';
import { dateUtils } from 'utils/date-utils';
import produce from '@reduxjs/toolkit/node_modules/immer';
import { DatePickerProps } from '@material-ui/pickers';
import { BookitDatePicker } from 'app/components/BookitDatePicker';
import { BookitDateTimePicker } from 'app/components/BookitDatePicker/BookitDateTimePicker';

export interface ReservationTimePeriodPickerProps {
  /**
   * Full Day / Regular reservation indicator
   */
  FullDayReservation: boolean;
  /**
   * Start time property name of the formik values object
   */
  StartTimePropertyName: string;
  /**
   * End time property name of the formik values object
   */
  EndTimePropertyName: string;
  /**
   * Disables the start time picker
   */
  startTimeDisabled: boolean;
  /**
   * Disables the end time picker
   */
  endTimeDisabled: boolean;
  /**
   * Start time change handler
   */
  onStartTimeChange: DatePickerProps['onChange'];
  /**
   * End time change handler
   */
  onEndTimeChange: DatePickerProps['onChange'];
  /**
   * Start time label & placeholder
   */
  startTimeLabel: string;
  /**
   * End time label & placeholder
   */
  endTimeLabel: string;
}
/**
 * Component that handles start/end time period UI on the reservation form.
 * Must be inside the formik context as the rest of the FormXXX components.
 * FullDayReservation might checkbox might need to also be handled here.
 * @param param0
 * @returns
 */
export function FormReservationTimePeriodPicker({
  FullDayReservation,
  ...props
}: React.PropsWithChildren<ReservationTimePeriodPickerProps>) {
  const formik = useFormikContext<ReservationDetailsState>();
  const [startField, startMeta] = useField<Date | string | null>({
    name: props.StartTimePropertyName,
  });
  const [endField, endMeta, endHelpers] = useField<Date | string | null>({
    name: props.EndTimePropertyName,
  });
  /**
   * For legacy reasons the reservation start/end is expected to be stored as string without tz info. for example:
   * "2023-08-09T01:05:00" = good
   * "2023-08-09T01:05:00z" = bad (not sure for what reason, but it's not the format being used in other places on reservation side panel)
   * @param value <date
   * @returns
   */
  const getStoreValue = (value: Date) => {
    return dateUtils.formatISO(dateUtils.dateOrStringToDate(value));
  };

  /**
   * Handles start time change (triggered by date selection on date picker UI).
   * @param value user selected date/time
   */
  const handleStartTimeChange: DatePickerProps['onChange'] = value => {
    // start time value is generally not clearable and should not be empty
    if (value !== null) {
      formik.setValues(
        produce((values: ReservationDetailsState) => {
          // set the current start time value
          values.StartTime = getStoreValue(value);
          // optionally adjust the end time to keep the reservation/period the same length
          // for example, changing the start time effectively moves current period to another date/time
          if (formik.values.EndTime !== null) {
            const endTime = adjustEndByStart(
              value,
              formik.values.StartTime,
              formik.values.EndTime,
            );
            values.EndTime = getStoreValue(endTime);
          }
        }),
      );
    }
    // raise the start time change event
    props.onStartTimeChange?.(value);
  };

  /**
   * Handles the end time change (triggered by date selection on date picker UI)
   * @param value new user selected end time
   */
  const handleEndTimeChange: DatePickerProps['onChange'] = value => {
    var formattedValue = value === null ? null : getStoreValue(value);
    // set the current end
    endHelpers.setValue(formattedValue);
    // rase the end time change event
    props.onEndTimeChange?.(value);
  };

  const datePickerVariant: DatePickerVariants = FullDayReservation
    ? 'date'
    : 'separated';

  return (
    <>
      <FormRow>
        {datePickerVariant === 'separated' ? (
          <BookitDateTimePicker
            id="StartTime"
            name={startField.name}
            label={props.startTimeLabel}
            dateId="startTimeDateId"
            dateName={startField.name}
            // dateLabel={props.startTimeLabel}
            timeId="startTimeTimeId"
            timeName={startField.name}
            // timeLabel={props.startTimeLabel}
            inputVariant="filled"
            onChange={handleStartTimeChange}
            onBlur={startField?.onBlur}
            value={startField.value}
            error={startMeta?.error !== undefined}
            fullWidth={true}
            helperText={startMeta.error}
            disabled={props.startTimeDisabled}
            placeholder={props.startTimeLabel}
            // strictCompareDates={true}
            minutesStep={15}
            truncateTime
          />
        ) : (
          <BookitDatePicker
            variant={datePickerVariant}
            id="StartTime"
            name={startField.name}
            label={props.startTimeLabel}
            inputVariant="filled"
            onChange={handleStartTimeChange}
            onBlur={startField?.onBlur}
            value={startField.value}
            error={startMeta?.error !== undefined}
            fullWidth={true}
            helperText={startMeta.error}
            disabled={props.startTimeDisabled}
            placeholder={props.startTimeLabel}
            truncateTime
          />
        )}
      </FormRow>

      <FormRow>
        {datePickerVariant === 'separated' ? (
          <BookitDateTimePicker
            id="EndTime"
            name={endField.name}
            label={props.endTimeLabel}
            inputVariant="filled"
            onChange={handleEndTimeChange}
            onBlur={endField?.onBlur}
            value={endField.value}
            error={endMeta?.error !== undefined}
            fullWidth={true}
            helperText={endMeta.error}
            disabled={props.endTimeDisabled}
            // strictCompareDates={true}
            minDate={
              formik.values.StartTime === undefined
                ? undefined
                : formik.values.StartTime === null
                ? undefined
                : dateUtils.dateOrStringToDate(formik.values.StartTime)
            }
            placeholder={props.endTimeLabel}
            truncateTime
            endTime
            dateId="endTimeDateId"
            dateName={endField.name}
            // dateLabel={props.endTimeLabel}
            timeId="endTimeTimeId"
            timeName={endField.name}
            minutesStep={15}
            // timeLabel={props.endTimeLabel}
          />
        ) : (
          <BookitDatePicker
            variant={datePickerVariant}
            id="EndTime"
            name={endField.name}
            label={props.endTimeLabel}
            inputVariant="filled"
            onChange={handleEndTimeChange}
            onBlur={endField?.onBlur}
            value={endField.value}
            error={endMeta?.error !== undefined}
            fullWidth={true}
            helperText={endMeta.error}
            disabled={props.endTimeDisabled}
            minDate={
              formik.values.StartTime === undefined
                ? undefined
                : formik.values.StartTime === null
                ? undefined
                : dateUtils.dateOrStringToDate(formik.values.StartTime)
            }
            placeholder={props.endTimeLabel}
            truncateTime
            endTime
          />
        )}
      </FormRow>
    </>
  );
}
function adjustEndByStart(
  value: Date,
  originalStartTime: Date | string,
  originalEndTime: Date | string,
) {
  const diff = dateUtils.differenceInMinutes(
    value,
    dateUtils.dateOrStringToDate(originalStartTime),
  );
  const endTime = dateUtils.addMinutes(
    dateUtils.dateOrStringToDate(originalEndTime),
    diff,
  );
  return endTime;
}
