import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import { FormFieldsSection, FormRow } from 'app/components/Forms/FormsLayout';
import {
  filteredWorkOrderTypes,
  WorkOrderTypePicker,
} from 'app/components/pickers/AutocompletePickers/WorkOrderTypePicker';
import { initAssetsData } from 'app/components/pickers/MultiSelectPickers/AssetsPicker';
import { useAsyncGetWithEffect } from 'app/hooks/useAsyncGetOnMount';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { IReservationEquipmentDto } from '../../slice/types';
import * as React from 'react';
import { quoteODataValue } from 'api/odata/ODataFilter';
import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import { loadWorkOrders } from 'app/components/pickers/AutocompletePickers/WorkOrdersPicker';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Tips } from 'app/components/Typography';
import { toQueryString } from 'utils/url-utils';
import { Button } from 'app/components/BasicButtons/Button';
import { useSelector } from 'react-redux';
import { selectRefreshWorkOrderLink } from '../../slice/selectors';
import styled from 'styled-components';

export interface ReservationWorkOrdersProps {
  reservationId: number;
  equipments: IReservationEquipmentDto[];
  onWorkTypeClick: (
    event: IWorkOrderTypeDto,
    equipmentIds: number[],
    reservationId: number,
  ) => void;
}
const OrderContent = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`;
export function ReservationWorkOrders(props: ReservationWorkOrdersProps) {
  const { equipments, reservationId, onWorkTypeClick } = props;
  const refreshWorkOrders = useSelector(selectRefreshWorkOrderLink);
  const equipmentIds = React.useMemo(() => {
    return equipments.map(f => f.Id);
  }, [equipments]);
  const { t } = useTranslation();
  const handleWorkTypeClick = (event: IWorkOrderTypeDto) => {
    onWorkTypeClick(event, equipmentIds, reservationId);
  };
  const relatedAssets = useAsyncGetWithEffect<IAssetDto[]>(
    async () =>
      initAssetsData(equipmentIds.join(',')).then(res => res as IAssetDto[]),
    [],
    [equipmentIds],
  );
  const relatedWorkOrders = useAsyncGetWithEffect<IWorkOrderDetailsDto[]>(
    async () =>
      loadWorkOrders([`Reservation eq ${quoteODataValue(reservationId)}`])(
        null,
        undefined,
      ).then(res => res.value),
    [],
    [reservationId, refreshWorkOrders],
  );
  const workOrdersLink = React.useMemo(() => {
    return `/workorders?${toQueryString({
      reservationId: reservationId,
    })}`;
  }, [reservationId]);
  const workOrdersLinkTitle = React.useMemo(() => {
    return relatedWorkOrders.length > 0
      ? (t(translations.ReservationAlertsLink) as string).replace(
          '{0}',
          relatedWorkOrders.length.toString(),
        )
      : undefined;
  }, [relatedWorkOrders.length, t]);
  return (
    <FormFieldsSection
      titleSection={t(translations.Alert)}
      titleLink={
        relatedWorkOrders.length > 0 ? (
          <Button variant="text" size="small" href={workOrdersLink}>
            {workOrdersLinkTitle}
          </Button>
        ) : (
          <OrderContent>
            <Tooltip title={t(translations.StaffOnly_Icon) as string}>
              <span>
                <Icon
                  icon="info-circle"
                  color="default"
                  colorExtend="textHover"
                />
              </span>
            </Tooltip>
            <Tips color="secondary">
              {t(translations.NoReservationAlerts) as string}
            </Tips>
          </OrderContent>
        )
      }
    >
      <FormRow>
        <WorkOrderTypePicker
          predicates={filteredWorkOrderTypes(
            relatedAssets,
            undefined,
            undefined,
          )}
          name={'workOrderTypeFilter'}
          variant="filled"
          placeholder={t(translations.PleaseSelectAnAlertType)}
          label={t(translations.AlertType)}
          onChange={value => {
            handleWorkTypeClick(value as IWorkOrderTypeDto);
          }}
          fullWidth={true}
          id="wotype-id"
        />
      </FormRow>
    </FormFieldsSection>
  );
}
