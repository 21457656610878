/**
 *
 * ErrorBoundary
 *
 */
import * as React from 'react';
import { getLogger } from 'utils/logLevel';

export class ErrorBoundary extends React.Component<
  { fallBack?: React.ReactElement; onError?: (error) => void },
  { hasError: boolean }
> {
  private _log = getLogger('ErrorBoundary');
  private fallBack?: React.FC;
  private onError?: (error) => void;

  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.fallBack = props.fallback;
    this.onError = props.onError;
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.onError?.(errorInfo);
    this._log.error(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallBack || <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}
