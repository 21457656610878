import { StyleRules } from '@material-ui/styles';
import { bookitColors } from 'styles/colors/bookitColors';
import { buttonS } from 'styles/typography/bookitTypography';

export const MuiButtonOverride = {
  root: {
    ...buttonS,
    textTransform: 'none',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexShrink: 1,
    boxShadow: 'unset',
    lineHeight: 'normal',
    border: '1px solid transparent',
    padding: '4px 16px',
    minWidth: '40px',
    height: '40px',
    '&.variantMain': {
      backgroundColor: bookitColors.primary.regular,
      color: bookitColors.base.white,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.hover,
        color: bookitColors.base.white,
      },
      '&$disabled': {
        backgroundColor: bookitColors.primary.disabled,
        color: bookitColors.base.white,
      },
    },
    '&.variantText': {
      backgroundColor: 'transparent',
      color: bookitColors.primary.regular,
      padding: '0px',
      '&:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
        color: bookitColors.primary.hover,
        textDecoration: 'underline',
      },
      '&$disabled': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.grayComponentsLight,
      },
      '& .buttonLabel': {
        whiteSpace: 'initial',
      },
    },
    '&.variantTextgray': {
      backgroundColor: 'transparent',
      color: bookitColors.grayscale.graySubtitles,
      padding: '0px',
      '&:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.blackComponents,
        textDecoration: 'underline',
      },
      '&$disabled': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.grayComponentsLight,
      },
    },
    '&.variantGray': {
      backgroundColor: bookitColors.grayscale.graybgComponents,
      color: bookitColors.grayscale.blackRegular,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.regular,
      },
      '&$disabled': {
        backgroundColor: bookitColors.grayscale.grayBackground,
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '&.variantAccent': {
      backgroundColor: bookitColors.grayscale.graySubtitles,
      color: bookitColors.base.white,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.grayscale.blackRegular,
        color: bookitColors.base.white,
      },
      '&$disabled': {
        backgroundColor: bookitColors.grayscale.grayBackground,
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '&.variantDanger': {
      backgroundColor: bookitColors.danger.regular,
      color: bookitColors.base.white,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.danger.hover,
        color: bookitColors.base.white,
      },
      '&$disabled': {
        backgroundColor: bookitColors.danger.disabled,
        color: bookitColors.base.white,
      },
    },
    '&.variantWhite': {
      backgroundColor: bookitColors.base.white,
      color: bookitColors.grayscale.blackRegular,
      border: `1px solid ${bookitColors.grayscale.grayBorders}`,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.hover,
        border: '1px solid transparent',
      },
      '&$disabled': {
        backgroundColor: bookitColors.grayscale.grayBackground,
        color: bookitColors.grayscale.grayBorders,
        border: '1px solid transparent',
      },
    },
    '&.variantGhostcolor': {
      backgroundColor: 'unset',
      color: bookitColors.primary.regular,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.hover,
      },
      '&$disabled': {
        backgroundColor: 'unset',
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '&.variantGhost': {
      backgroundColor: 'unset',
      color: bookitColors.grayscale.blackRegular,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.hover,
      },
      '&$disabled': {
        backgroundColor: 'unset',
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '& .buttonLabel': {
      height: '18px',
      whiteSpace: 'nowrap',
      '&.textSizeSmall': {
        height: '18px',
      },
      '&.textSizeMedium': {
        height: '20px',
      },
      '&.textSizeLarge': {
        height: '24px',
      },
      '&.truncateSmall': {
        whiteSpace: 'pre',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '16ch',
        display: 'inherit',
      },
      '&.truncateMedium': {
        whiteSpace: 'pre',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '32ch',
        display: 'inherit',
      },
      '&.truncateLarge': {
        whiteSpace: 'pre',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '64ch',
        display: 'inherit',
      },
    },
    '&.fileButton': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      padding: '6px 8px',
      boxShadow: 'unset',
      lineHeight: 'normal',
      height: '48px',
      backgroundColor: bookitColors.base.white,
      border: `1px solid ${bookitColors.grayscale.grayBorders}`,
      '&:hover': {
        backgroundColor: bookitColors.grayscale.grayBackground,
        border: `1px solid ${bookitColors.grayscale.grayBorders}`,
      },
      '&$disabled': {
        backgroundColor: bookitColors.grayscale.graybgComponents,
        color: bookitColors.grayscale.grayComponents,
        border: `1px solid ${bookitColors.grayscale.grayBorders}`,
      },
      '&>[class*="MuiButton-label"]': {
        gap: '8px',
        pointerEvents: 'none',
      },
      '&.shrinkLabel': {
        padding: '20px 8px 0px 8px',
      },
    },
  },
  contained: {
    borderRadius: '8px',
    boxShadow: 'none',
    color: bookitColors.grayscale.blackRegular,
    padding: '4px 16px',
    minWidth: '40px',
    height: '40px',
    '&.hasStartIcon.hasEndIcon': {
      padding: '4px 12px',
    },
    '&.hasStartIcon': {
      padding: '4px 16px 4px 12px',
    },
    '&.hasEndIcon': {
      padding: '4px 12px 4px 16px',
    },
    '&:hover': {
      backgroundColor: 'initial',
      boxShadow: 'unset',
      '@media (hover: none)': {
        boxShadow: 'unset',
        backgroundColor: 'initial',
      },
      '&$disabled': {
        backgroundColor: 'initial',
      },
    },
    '&$focusVisible': {
      boxShadow: 'unset',
    },
    '&:active': {
      boxShadow: 'unset',
    },
    '&$disabled': {
      boxShadow: 'unset',
      // color: theme.palette.action.disabled,
      // boxShadow: theme.shadows[0],
      // backgroundColor: theme.palette.action.disabledBackground
    },
    '&.variantMain': {
      backgroundColor: bookitColors.primary.regular,
      color: bookitColors.base.white,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.hover,
        color: bookitColors.base.white,
      },
      '&$disabled': {
        backgroundColor: bookitColors.primary.disabled,
        color: bookitColors.base.white,
      },
    },
    '&.variantText': {
      backgroundColor: 'transparent',
      color: bookitColors.primary.regular,
      padding: '0px',
      '&:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
        color: bookitColors.primary.hover,
        textDecoration: 'underline',
      },
      '&$disabled': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.grayComponentsLight,
      },
    },
    '&.variantTextgray': {
      backgroundColor: 'transparent',
      color: bookitColors.grayscale.graySubtitles,
      padding: '0px',
      '&:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.blackComponents,
        textDecoration: 'underline',
      },
      '&$disabled': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.grayComponentsLight,
      },
    },
    '&.variantGray': {
      backgroundColor: bookitColors.grayscale.graybgComponents,
      color: bookitColors.grayscale.blackRegular,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.regular,
      },
      '&$disabled': {
        backgroundColor: bookitColors.grayscale.grayBackground,
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '&.variantAccent': {
      backgroundColor: bookitColors.grayscale.graySubtitles,
      color: bookitColors.base.white,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.grayscale.blackRegular,
        color: bookitColors.base.white,
      },
      '&$disabled': {
        backgroundColor: bookitColors.grayscale.grayBackground,
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '&.variantDanger': {
      backgroundColor: bookitColors.danger.regular,
      color: bookitColors.base.white,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.danger.hover,
        color: bookitColors.base.white,
      },
      '&$disabled': {
        backgroundColor: bookitColors.danger.disabled,
        color: bookitColors.base.white,
      },
    },
    '&.variantWhite': {
      backgroundColor: bookitColors.base.white,
      color: bookitColors.grayscale.blackRegular,
      border: `1px solid ${bookitColors.grayscale.grayBorders}`,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.hover,
        border: '1px solid transparent',
      },
      '&$disabled': {
        backgroundColor: bookitColors.grayscale.grayBackground,
        color: bookitColors.grayscale.grayBorders,
        border: '1px solid transparent',
      },
    },
    '&.variantGhostcolor': {
      backgroundColor: 'unset',
      color: bookitColors.primary.regular,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.hover,
      },
      '&$disabled': {
        backgroundColor: 'unset',
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '&.variantGhost': {
      backgroundColor: 'unset',
      color: bookitColors.grayscale.blackRegular,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.hover,
      },
      '&$disabled': {
        backgroundColor: 'unset',
        color: bookitColors.grayscale.grayBorders,
      },
    },
  },
  containedSizeSmall: {
    borderRadius: '6px',
    padding: '4px 12px',
    minWidth: '32px',
    height: '32px',
    '&.hasStartIcon.hasEndIcon': {
      padding: '4px 8px',
    },
    '&.hasStartIcon': {
      padding: '4px 12px 4px 8px',
    },
    '&.hasEndIcon': {
      padding: '4px 8px 4px 12px',
    },
  },
  containedSizeLarge: {
    minWidth: '48px',
    height: '48px',
    borderRadius: '8px',
    padding: '4px 20px',
    '&.hasStartIcon.hasEndIcon': {
      padding: '4px 16px',
    },
    '&.hasStartIcon': {
      padding: '4px 20px 4px 16px',
    },
    '&.hasEndIcon': {
      padding: '4px 16px 4px 20px',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    margin: 0,
    gap: '8px',
    '.fileButton &': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px',
      margin: 0,
      height: '24px',
      justifyContent: 'flex-start',
      '& hr': {
        backgroundColor: bookitColors.grayscale.grayComponents,
      },
    },
  },
  startIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    margin: 0,
    marginLeft: 0,
    marginRight: 0,
    '&$iconSizeSmall': {
      marginLeft: 0,
    },
  },
  endIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px',
    flex: 'none',
    order: 2,
    flexGrow: 0,
    margin: 0,
    marginLeft: 0,
    marginRight: 0,
    '&$iconSizeSmall': {
      marginRight: 0,
    },
    '.fileButton &': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px',
      flex: 'none',
      order: 2,
      flexGrow: 0,
      marginLeft: 'auto',
      marginRight: 0,
    },
  },
  text: {
    padding: '0px',
    height: '20px',
  },
  textSizeSmall: {
    fontSize: '14px',
    height: '18px',
  },
  textSizeLarge: {
    fontSize: '20px',
    height: '24px',
  },
  sizeSmall: {},
  sizeLarge: {
    '&>[class*="MuiButton-label"]': {
      gap: '12px',
    },
  },
  iconSizeMedium: {
    fontSize: '16px',
    '&>:first-child': {
      fontSize: '16px',
      width: '16px',
      height: '16px',
    },
  },
  iconSizeLarge: {
    fontSize: '20px',
    '&>:first-child': {
      fontSize: '20px',
      width: '20px',
      height: '20px',
    },
  },
  iconSizeSmall: {
    fontSize: '14px',
    '&>:first-child': {
      fontSize: '14px',
      width: '14px',
      height: '14px',
    },
  },
} as Partial<StyleRules>;
export const MuiIconButtonOverride = {
  root: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    padding: '0px',
    border: '1px solid transparent',
    '&.variantMain': {
      backgroundColor: bookitColors.primary.regular,
      color: bookitColors.base.white,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.hover,
        color: bookitColors.base.white,
      },
      '&$disabled': {
        backgroundColor: bookitColors.primary.disabled,
        color: bookitColors.base.white,
      },
    },
    '&.variantText': {
      backgroundColor: 'transparent',
      color: bookitColors.primary.regular,
      padding: '0px',
      '&:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
        color: bookitColors.primary.hover,
        textDecoration: 'underline',
      },
      '&$disabled': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.grayComponentsLight,
      },
    },
    '&.variantTextgray': {
      backgroundColor: 'transparent',
      color: bookitColors.grayscale.graySubtitles,
      padding: '0px',
      '&:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.blackComponents,
        textDecoration: 'underline',
      },
      '&$disabled': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.grayComponentsLight,
      },
    },
    '&.variantGray': {
      backgroundColor: bookitColors.grayscale.graybgComponents,
      color: bookitColors.grayscale.blackRegular,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.regular,
      },
      '&$disabled': {
        backgroundColor: bookitColors.grayscale.grayBackground,
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '&.variantAccent': {
      backgroundColor: bookitColors.grayscale.graySubtitles,
      color: bookitColors.base.white,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.grayscale.blackRegular,
        color: bookitColors.base.white,
      },
      '&$disabled': {
        backgroundColor: bookitColors.grayscale.grayBackground,
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '&.variantDanger': {
      backgroundColor: bookitColors.danger.regular,
      color: bookitColors.base.white,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.danger.hover,
        color: bookitColors.base.white,
      },
      '&$disabled': {
        backgroundColor: bookitColors.danger.disabled,
        color: bookitColors.base.white,
      },
    },
    '&.absolute-close-button': {
      position: 'absolute',
      right: 8,
      top: 8,
    },
    '& .iconSizeLarge': {
      fontSize: '16px',
      '&>:first-child': {
        fontSize: '16px',
        width: '16px',
        height: '16px',
      },
    },
    '&.variantWhite': {
      backgroundColor: bookitColors.base.white,
      color: bookitColors.grayscale.blackRegular,
      border: `1px solid ${bookitColors.grayscale.grayBorders}`,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.hover,
        border: '1px solid transparent',
      },
      '&$disabled': {
        backgroundColor: bookitColors.grayscale.grayBackground,
        color: bookitColors.grayscale.grayBorders,
        border: '1px solid transparent',
      },
    },
    '&.variantGhostcolor': {
      backgroundColor: 'unset',
      color: bookitColors.primary.regular,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.hover,
      },
      '&$disabled': {
        backgroundColor: 'unset',
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '&.variantGhost': {
      backgroundColor: 'unset',
      color: bookitColors.grayscale.blackRegular,
      '&:hover, &:active, &:focus': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.hover,
      },
      '&$disabled': {
        backgroundColor: 'unset',
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '&.iconSizeLarge': {
      width: '48px',
      height: '48px',
      fontSize: '16px',
      '&.shapeSquare': {
        borderRadius: '8px',
      },
    },
    '&.iconSizeMedium': {
      width: '40px',
      height: '40px',
      fontSize: '16px',
      '&.shapeSquare': {
        borderRadius: '8px',
      },
    },
    '&.iconSizeSmall': {
      width: '32px',
      height: '32px',
      fontSize: '14px',
      '&.shapeSquare': {
        borderRadius: '6px',
      },
    },
    '&.iconSizeXs': {
      width: '24px',
      height: '24px',
      fontSize: '14px',
      padding: '4px 11px 4px 11px',
      '&.shapeSquare': {
        borderRadius: '4px',
      },
    },
    '&.shapeCircle': {
      borderRadius: '50%',
    },
    '&.absolutePositionSmall': {
      position: 'absolute',
      right: 8,
      top: 8,
      color: bookitColors.grayscale.graySubtitles,
    },
    '&.absolutePositionMedium': {
      position: 'absolute',
      right: 12,
      top: 12,
      color: bookitColors.grayscale.graySubtitles,
    },
    '&.absolutePositionLarge': {
      position: 'absolute',
      right: 16,
      top: 16,
      color: bookitColors.grayscale.graySubtitles,
    },
    '&.collapseButton': {
      position: 'absolute',
      top: 48,
      right: '50%',
      border: `1px solid ${bookitColors.grayscale.grayBorders}`,
      background: bookitColors.grayscale.graybgComponents,
      borderRadius: '1000px',
      width: '24px',
      '&.collapsedBar': {
        width: 'auto',
      },
      '& .collapseIcon': {
        color: bookitColors.grayscale.graySubtitles,
        width: '10px',
        height: '10px',
      },
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: '12px',
    cursor: 'pointer',
  },
} as Partial<StyleRules>;
export const MuiToggleButtonGroupOverride = {
  root: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '0px',
    backgroundColor: 'transparent',
    '&.variantMain': {
      backgroundColor: bookitColors.primary.bgHover,
    },
    '&.variantText': {
      padding: '0px',
    },
    '&.variantTextgray': {
      padding: '0px',
    },
    '&.variantGray': {
      backgroundColor: bookitColors.primary.bgLight,
    },
    '&.variantAccent': {
      backgroundColor: bookitColors.grayscale.graybgComponents,
    },
    '&.variantDanger': {
      backgroundColor: bookitColors.danger.background,
    },
    '&.variantWhite': {
      backgroundColor: bookitColors.base.white,
      border: `1px solid ${bookitColors.grayscale.grayBorders}`,
    },
    '&.separated': {
      gap: '8px',
    },
    '&.variantGhostcolor': {
      '&.separated': {
        padding: '0px',
      },
    },
    '&.variantGhost': {
      '&.separated': {
        padding: '0px',
      },
    },
    '&.sizeLarge': {
      borderRadius: '12px',
      '&.separated': {
        padding: '4px',
        gap: '4px',
      },
    },
    '&.sizeMedium': {
      borderRadius: '10px',
      '&.separated': {
        padding: '3px',
        gap: '3px',
      },
    },
    '&.sizeSmall': {
      borderRadius: '8px',
      '&.separated': {
        padding: '1px',
        gap: '1px',
      },
    },
    '&.errorBorder': {
      borderColor: bookitColors.danger.regular,
    },
    '&.fullWidth': {
      width: '100%',
    },
  },
  grouped: {
    '&.fullWidthButton': {
      flex: '1',
    },
  },
  /* Styles applied to the children if `orientation="horizontal"`. */
  groupedHorizontal: {
    '&:not(:first-child)': {
      marginLeft: '0px', //props => (props.variant === 'white' ? 0 : -1),
      borderLeft: '1px solid transparent',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      '&.separated': {
        '&.sizeLarge': {
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
        },
        '&.sizeMedium': {
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
        },
        '&.sizeSmall': {
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
        },
      },
    },
    '&:not(:last-child)': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      '&.separated': {
        '&.sizeLarge': {
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
        },
        '&.sizeMedium': {
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
        },
        '&.sizeSmall': {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6,
        },
      },
    },
    '&>[class*="withTitle"]:not(:first-child)': {
      '&>button': {
        marginLeft: '0px', // props => (props.variant === 'white' ? 0 : -1),
        borderLeft: '1px solid transparent',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        '&.separated': {
          '&.sizeLarge': {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
          },
          '&.sizeMedium': {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
          },
          '&.sizeSmall': {
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
          },
        },
      },
    },
    '&>[class*="withTitle"]:not(:last-child)': {
      '&>button': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        '&.separated': {
          '&.sizeLarge': {
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
          },
          '&.sizeMedium': {
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
          },
          '&.sizeSmall': {
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
          },
        },
      },
    },
  },
  /* Styles applied to the children if `orientation="vertical"`. */
  groupedVertical: {
    '&:not(:first-child)': {
      marginTop: -1,
      borderTop: '1px solid transparent',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      '&.separated': {
        '&.sizeLarge': {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        },
        '&.sizeMedium': {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        },
        '&.sizeSmall': {
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
        },
      },
    },
    '&:not(:last-child)': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      '&.separated': {
        '&.sizeLarge': {
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        },
        '&.sizeMedium': {
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        },
        '&.sizeSmall': {
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
        },
      },
    },
    '&>[class*="withTitle"]:not(:first-child)': {
      '&>button': {
        marginTop: -1,
        borderTop: '1px solid transparent',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        '&.separated': {
          '&.sizeLarge': {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          },
          '&.sizeMedium': {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          },
          '&.sizeSmall': {
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
          },
        },
      },
    },
    '&>[class*="withTitle"]:not(:last-child)': {
      '&>button': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        '&.separated': {
          '&.sizeLarge': {
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          },
          '&.sizeMedium': {
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          },
          '&.sizeSmall': {
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
          },
        },
      },
    },
  },
} as Partial<StyleRules>;
export const MuiToggleButtonOverride = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    textTransform: 'none',
    boxShadow: 'unset',
    boxSizing: 'border-box',
    border: '1px solid transparent',
    borderRadius: '8px',
    minWidth: '40px',
    height: '40px',
    ...buttonS,
    padding: '4px 16px',
    '&.hasMainIcon': {
      padding: '4px 10px',
    },
    '&.hasStartIcon.hasEndIcon': {
      padding: '4px 12px',
    },
    '&.hasStartIcon': {
      padding: '4px 16px 4px 12px',
    },
    '&.hasEndIcon': {
      padding: '4px 12px 4px 16px',
    },
    '&.fullWidth': {
      width: '100%',
    },
    '&.variantMain': {
      backgroundColor: bookitColors.primary.bgHover,
      color: bookitColors.primary.hover,
      '&$selected': {
        color: bookitColors.base.white,
        backgroundColor: bookitColors.primary.regular,
        border: `1px solid ${bookitColors.primary.borders}`,
        '&:hover': {
          backgroundColor: bookitColors.primary.hover,
          border: '1px solid transparent',
        },
        '& + &': {
          borderLeft: 0,
          marginLeft: 0,
        },
      },
      '&:hover': {
        backgroundColor: bookitColors.primary.regular,
        color: bookitColors.base.white,
      },
      '&:active, &:focus': {
        backgroundColor: bookitColors.primary.regular,
        color: bookitColors.base.white,
        border: `1px solid ${bookitColors.primary.borders}`,
      },
      '&$disabled': {
        backgroundColor: bookitColors.primary.disabled,
        color: bookitColors.base.white,
      },
    },
    '&.variantText': {
      backgroundColor: 'transparent',
      color: bookitColors.primary.regular,
      padding: '0px',
      '&$selected': {
        color: bookitColors.primary.hover,
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        '&:hover': {
          backgroundColor: 'transparent',
          color: bookitColors.primary.hover,
          textDecoration: 'underline',
        },
        '& + &': {
          borderLeft: 0,
          marginLeft: 0,
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
        color: bookitColors.primary.hover,
        textDecoration: 'underline',
      },
      '&:active, &:focus': {
        backgroundColor: 'transparent',
        color: bookitColors.primary.hover,
        textDecoration: 'underline',
      },
      '&$disabled': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.grayComponentsLight,
      },
    },
    '&.variantTextgray': {
      backgroundColor: 'transparent',
      color: bookitColors.grayscale.graySubtitles,
      padding: '0px',
      '&$selected': {
        color: bookitColors.grayscale.blackComponents,
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        '&:hover': {
          backgroundColor: 'transparent',
          color: bookitColors.grayscale.blackComponents,
          textDecoration: 'underline',
        },
        '& + &': {
          borderLeft: 0,
          marginLeft: 0,
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.blackComponents,
        textDecoration: 'underline',
      },
      '&:active, &:focus': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.blackComponents,
        textDecoration: 'underline',
      },
      '&$disabled': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.grayComponentsLight,
      },
    },
    '&.variantGray': {
      backgroundColor: bookitColors.primary.bgLight,
      color: bookitColors.grayscale.blackRegular,
      '&$selected': {
        color: bookitColors.primary.regular,
        backgroundColor: bookitColors.grayscale.graybgComponents,
        '&:hover': {
          backgroundColor: bookitColors.grayscale.graySelection,
          color: bookitColors.primary.regular,
        },
        '& + &': {
          borderLeft: 0,
          marginLeft: 0,
        },
      },
      '&:hover': {
        backgroundColor: bookitColors.grayscale.graybgComponents,
        color: bookitColors.primary.regular,
      },
      '&:active, &:focus': {
        backgroundColor: bookitColors.grayscale.graybgComponents,
        color: bookitColors.primary.regular,
      },
      '&$disabled': {
        backgroundColor: bookitColors.grayscale.grayBackground,
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '&.variantAccent': {
      backgroundColor: bookitColors.grayscale.graybgComponents,
      color: bookitColors.grayscale.blackRegular,
      '&$selected': {
        color: bookitColors.base.white,
        backgroundColor: bookitColors.grayscale.graySubtitles,
        '&:hover': {
          backgroundColor: bookitColors.grayscale.blackRegular,
          color: bookitColors.base.white,
        },
        '& + &': {
          borderLeft: 0,
          marginLeft: 0,
        },
      },
      '&:hover': {
        backgroundColor: bookitColors.grayscale.graybgComponents,
        color: bookitColors.grayscale.blackRegular,
      },
      '&:active, &:focus': {
        backgroundColor: bookitColors.grayscale.graybgComponents,
        color: bookitColors.grayscale.blackRegular,
      },
      '&$disabled': {
        backgroundColor: bookitColors.grayscale.grayBackground,
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '&.variantDanger': {
      backgroundColor: bookitColors.danger.background,
      color: bookitColors.grayscale.blackRegular,
      '&$selected': {
        color: bookitColors.base.white,
        backgroundColor: bookitColors.danger.regular,
        '&:hover': {
          backgroundColor: bookitColors.danger.hover,
          color: bookitColors.base.white,
        },
        '& + &': {
          borderLeft: 0,
          marginLeft: 0,
        },
      },
      '&:hover': {
        backgroundColor: bookitColors.danger.background,
        color: bookitColors.grayscale.blackRegular,
      },
      '&:active, &:focus': {
        backgroundColor: bookitColors.danger.background,
        color: bookitColors.grayscale.blackRegular,
      },
      '&$disabled': {
        backgroundColor: bookitColors.danger.disabled,
        color: bookitColors.base.white,
      },
    },
    '&.variantWhite': {
      backgroundColor: bookitColors.base.white,
      color: bookitColors.grayscale.blackRegular,
      '&$selected': {
        color: bookitColors.grayscale.blackRegular,
        backgroundColor: bookitColors.grayscale.graybgComponents,
        '&:hover': {
          backgroundColor: bookitColors.grayscale.graySelection,
          color: bookitColors.grayscale.blackRegular,
        },
        '& + &': {
          borderLeft: 0,
          marginLeft: 0,
        },
      },
      '&:hover': {
        backgroundColor: bookitColors.grayscale.graybgComponents,
        color: bookitColors.grayscale.blackRegular,
      },
      '&:active, &:focus': {
        backgroundColor: bookitColors.grayscale.graybgComponents,
        color: bookitColors.grayscale.blackRegular,
      },
      '&$disabled': {
        backgroundColor: bookitColors.grayscale.grayBackground,
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '&.variantGhostcolor': {
      backgroundColor: 'transparent',
      color: bookitColors.primary.regular,
      '&$selected': {
        color: bookitColors.primary.hover,
        backgroundColor: bookitColors.primary.bgHover,
        '&:hover': {
          backgroundColor: bookitColors.primary.bgHover,
          color: bookitColors.primary.hover,
        },
        '& + &': {
          borderLeft: 0,
          marginLeft: 0,
        },
      },
      '&:hover': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.hover,
      },
      '&:active, &:focus': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.hover,
      },
      '&$disabled': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.grayBorders,
      },
    },
    '&.variantGhost': {
      backgroundColor: 'transparent',
      color: bookitColors.grayscale.blackRegular,
      '&$selected': {
        color: bookitColors.primary.hover,
        backgroundColor: bookitColors.primary.bgHover,
        '&:hover': {
          backgroundColor: bookitColors.primary.bgHover,
          color: bookitColors.primary.hover,
        },
        '& + &': {
          borderLeft: 0,
          marginLeft: 0,
        },
      },
      '&:hover': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.hover,
      },
      '&:active, &:focus': {
        backgroundColor: bookitColors.primary.bgHover,
        color: bookitColors.primary.hover,
      },
      '&$disabled': {
        backgroundColor: 'transparent',
        color: bookitColors.grayscale.grayBorders,
      },
    },
  },
  sizeLarge: {
    padding: '4px 20px',
    borderRadius: '8px',
    minWidth: '48px',
    height: '48px',
    '&.hasMainIcon': {
      padding: '4px 14px',
    },
    '&.hasStartIcon.hasEndIcon': {
      padding: '4px 16px',
    },
    '&.hasStartIcon': {
      padding: '4px 20px 4px 16px',
    },
    '&.hasEndIcon': {
      padding: '4px 16px 4px 20px',
    },
    '&>$label': {
      gap: '12px',
    },
  },
  sizeSmall: {
    padding: '4px 12px',
    borderRadius: '8px',
    minWidth: '32px',
    height: '32px',
    '&.separated': {
      borderRadius: '6px',
    },
    '&.hasMainIcon': {
      padding: '4px 8px',
    },
    '&.hasStartIcon.hasEndIcon': {
      padding: '4px 8px',
    },
    '&.hasStartIcon': {
      padding: '4px 12px 4px 8px',
    },
    '&.hasEndIcon': {
      padding: '4px 8px 4px 12px',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    margin: 0,
    gap: '8px',
    '& .startIcon': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '0px',
      flex: 'none',
      order: 0,
      flexGrow: 0,
      margin: 0,
    },
    '& .endIcon': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '0px',
      flex: 'none',
      order: 2,
      flexGrow: 0,
      margin: 0,
    },
    '& .mainIcon': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '10px',
    },
    '& .buttonLabel': {
      height: '18px',
      whiteSpace: 'nowrap',
      '&.truncateSmall': {
        whiteSpace: 'pre',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '16ch',
        display: 'inherit',
      },
      '&.truncateMedium': {
        whiteSpace: 'pre',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '32ch',
        display: 'inherit',
      },
      '&.truncateLarge': {
        whiteSpace: 'pre',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '64ch',
        display: 'inherit',
      },
    },
    '& .iconSizeSmall': {
      fontSize: '14px',
      '&>:first-child': {
        fontSize: '14px',
        width: '14px',
        height: '14px',
      },
    },
    '& .iconSizeMedium': {
      fontSize: '16px',
      '&>:first-child': {
        fontSize: '16px',
        width: '16px',
        height: '16px',
      },
    },
    '& .iconSizeLarge': {
      fontSize: '16px',
      '&>:first-child': {
        fontSize: '16px',
        width: '16px',
        height: '16px',
      },
    },
  },
} as Partial<StyleRules>;
