/**
 *
 * BasicDialogContentWithTitle
 *
 */
import {
  DialogContent,
  DialogContentProps,
  LinearProgress,
} from '@material-ui/core';
import { IconButton } from '../BasicButtons/IconButton';
import { H4 } from '../Typography';
import clsx from 'clsx';
import { DetectIsMobile } from 'utils/mobileDetect';
import BasicTypography from '../Typography/BasicTypography';

export interface TitleProps {
  titles: React.ReactNode;
  subtitle?: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  processing?: boolean;
  disabled?: boolean;
}
export interface BasicDialogContentWithTitleProps extends DialogContentProps {
  children: React.ReactNode;
}
export function BasicDialogContentWithTitle({
  titles,
  subtitle,
  onClose,
  children,
  processing,
  disabled,
}: BasicDialogContentWithTitleProps & TitleProps) {
  const isMobile = DetectIsMobile();
  return (
    <DialogContent
      className={clsx('basic-dialog-content', {
        'basic-dialog-content-mobile': isMobile,
      })}
    >
      <>
        <div className={'basic-dialog-title-conteiner'}>
          {!!onClose ? (
            <IconButton
              className={'basic-dialog-close-button'}
              aria-label="close"
              onClick={onClose}
              disabled={disabled}
              icon="times"
            />
          ) : null}
          {titles && <H4 className={'basic-dialog-title'}>{titles}</H4>}
          {subtitle && (
            <BasicTypography variant="bodyS">{subtitle}</BasicTypography>
          )}
          {processing && (
            <div className={'basic-dialog-processing'}>
              <LinearProgress variant="query" color="primary" />
            </div>
          )}
        </div>
        <div className={'basic-dialog-container'}>{children}</div>
      </>
    </DialogContent>
  );
}
