/**
 *
 * Asynchronously loads the component for CostCentersDetails Page
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CostCentersDetailsPage = lazyLoad(
  () => import('./index'),
  module => module.CostCentersDetailsPage,
);
