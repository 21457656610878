import { ICostCentersDto } from 'api/odata/generated/entities/ICostCentersDto';
import { AsyncDataState, Entity } from 'types/common';
import { IResponseType } from 'types/ResponseType';
import { toEntity } from 'utils/entity-utils';

/* --- STATE --- */
export interface CostCentersState {
  createState: AsyncDataState<CostCentersDetailsState>;
  updateState: AsyncDataState<CostCentersDetailsState>;
  processing: boolean;
}

export interface CostCentersDetailsState {
  Id: number;
  UserGroup?: Entity<string> | null;
  CostCenter?: string | null;
}

export interface CostCentersDetailsQStringParameters {
  id?: string;
  ug?: string;
  cc?: string;
}

export interface CostCentersDetailsResponse extends IResponseType {
  Id?: number;
}

export function ConvertModelToEntityCostCentersDetails(
  entity: ICostCentersDto,
): CostCentersDetailsState | undefined {
  if (!entity) {
    return undefined;
  } else {
    return {
      Id: entity.Id,
      UserGroup: toEntity(entity.UserGroupId, entity.UserGroup),
      CostCenter: entity.CostCenter,
    } as CostCentersDetailsState;
  }
}

export function ConvertEntityToModelCostCentersDetails(
  model: CostCentersDetailsState,
  original: CostCentersDetailsState | undefined,
): ICostCentersDto {
  let entity = {
    Id: original?.Id === undefined ? model.Id : original.Id,
    UserGroupId: model.UserGroup?.Id,
    UserGroup: model.UserGroup?.Name,
    CostCenter: model.CostCenter,
  } as ICostCentersDto;
  return entity;
}
