import { CommentsApi } from 'api/CommentsApi';
import {
  ConvertNewToComment,
  IComment,
  NewComment,
} from 'app/components/CommentsContainer/IComment';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useAsyncGetWithEffect } from 'app/hooks/useAsyncGetOnMount';
import { CommentsContainer } from 'app/components/CommentsContainer';
import { translations } from 'locales/translations';
import { Portal } from '@material-ui/core';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { IArticleDto } from 'api/odata/generated/entities/IArticleDto';
import { useUploadToNewComment } from 'app/components/LargeFilesUpload/Components/useUploadToNewComment';
import { useDispatch } from 'react-redux';
import { useLargeFilesUploadSlice } from 'app/components/LargeFilesUpload/slice';
import { Div100 } from 'app/components/AssetQuickSearch/styled';

export interface ArticleCommentsProps {
  isAdmin?: boolean;
  article: IArticleDto;
  disabled?: boolean;
  commentsFormRef: React.MutableRefObject<any>;
  isEdit?: boolean;
  user?: AuthenticatedUser;
  sectionTitle?: React.ReactNode;
}

export const ArticleComments = (props: ArticleCommentsProps) => {
  const {
    article,
    isAdmin,
    disabled,
    commentsFormRef,
    isEdit,
    user,
    sectionTitle,
  } = props;
  const { t } = useTranslation();
  const { newDate } = useSystemDate();
  const [changed, setchanged] = React.useState(false);
  const dispatch = useDispatch();
  const { actions: largeFileActions } = useLargeFilesUploadSlice();
  const [newSavedComments, setNewSavedComments] = React.useState<NewComment[]>(
    [],
  );
  const [newComments, setNewComments] = React.useState<IComment[]>([]);
  const comments = useAsyncGetWithEffect<IComment[]>(
    () =>
      CommentsApi.GetComments(
        CommentReferenceTypes.Article,
        article.Id > 0 ? article.Id : -1,
      ),
    [],
    [changed],
  );
  const bindSubmitComment = React.useCallback(
    submitForm => {
      commentsFormRef.current = submitForm;
    },
    [commentsFormRef],
  );
  const { onCommentAdded } = useUploadToNewComment();

  React.useEffect(() => {
    dispatch(
      largeFileActions.setCommentProperties({
        commentType: CommentReferenceTypes.Article,
        referenceId: article.Id,
      }),
    );
  }, [article.Id, dispatch, largeFileActions]);

  const portalRef = React.useRef(null);
  return (
    <React.Fragment>
      <Div100 ref={portalRef} />
      <Portal container={portalRef.current}>
        <CommentsContainer
          disabled={disabled}
          Comments={isEdit ? comments : newComments}
          sectionTitle={sectionTitle}
          bindSubmitComment={bindSubmitComment}
          onSubmit={c => {
            if (!disabled) {
              if (isEdit) {
                CommentsApi.SendComment(
                  CommentReferenceTypes.Article,
                  article.Id,
                  c,
                )
                  .then(res => onCommentAdded?.(res))
                  .finally(() => setchanged(prev => !prev));
              } else {
                setNewSavedComments(() => [...newSavedComments, c]);
                setNewComments(() => [
                  ...newComments,
                  ConvertNewToComment(c, newComments.length + 1, newDate(), {
                    Id: user?.Id || '',
                    Name: user?.Name || '',
                  }),
                ]);
              }
            }
          }}
          isAdmin={isAdmin}
          withMentions
          pageName={t(translations.Publications)}
          referenceName={article?.Id?.toString()}
        />
      </Portal>
    </React.Fragment>
  );
};
