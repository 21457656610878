import { createGlobalStyle } from 'styled-components';
import { bookitColors } from './colors/bookitColors';

export const FontsStyle = createGlobalStyle`
  
/* heebo-300 - latin_hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* heebo-regular - latin_hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* heebo-500 - latin_hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* heebo-700 - latin_hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

   /* open-sans-regular - latin */
   @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('${process.env.PUBLIC_URL}/fonts/open-sans-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('${process.env.PUBLIC_URL}/fonts/open-sans-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
/* open-sans-hebrew */
  @font-face {
    font-family: 'Open Sans Hebrew', serif;
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('${process.env.PUBLIC_URL}/fonts/OpenSansHebrew-Regular.woff') format('woff'),
        url('${process.env.PUBLIC_URL}/fonts/OpenSansHebrew-Regular-1.woff') format('woff'), 
        url('${process.env.PUBLIC_URL}/fonts/OpenSansHebrew-Bold.woff') format('woff'),
        url('${process.env.PUBLIC_URL}/fonts/OpenSansHebrew-ExtraBold.woff') format('woff'),
        url('${process.env.PUBLIC_URL}/fonts/OpenSansHebrew-Light.woff') format('woff');
  }
`;
export const LatoStyle = createGlobalStyle`
@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-BoldItalic.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-BlackItalic.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Black.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Bold.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Light.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Regular.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-LightItalic.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Italic.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
`;
export const GlobalStyle = createGlobalStyle`


// CSS variables
:root {
//base colors
  --base-black-c11: ${bookitColors.base.black};
  --base-white-c0: ${bookitColors.base.white};
  //grayscale colors
  --grayscale-black-regular-c10: ${bookitColors.grayscale.blackRegular};
  --grayscale-black-components-c9: ${bookitColors.grayscale.blackComponents};
  --grayscale-gray-subtitles-c8: ${bookitColors.grayscale.graySubtitles};
  --grayscale-gray-components-c7: ${bookitColors.grayscale.grayComponents};
  --grayscale-gray-components-light-c6: ${bookitColors.grayscale.grayComponentsLight};
  --grayscale-gray-borders-c5: ${bookitColors.grayscale.grayBorders};
  --grayscale-gray-selection-c4: ${bookitColors.grayscale.graySelection};
  --grayscale-gray-bg-components-c3: ${bookitColors.grayscale.graybgComponents};
  --grayscale-gray-background-c2: ${bookitColors.grayscale.grayBackground};
  --grayscale-gray-bg-light-c1: ${bookitColors.grayscale.grayBackgroundLight};
  //opacity colors
  --overlay-opacity-black: ${bookitColors.overlays.overlayBlack};
  --overlay-opacity-white: ${bookitColors.overlays.overlayWhite};
  //primary colors
  --primary-dark-text-c10: ${bookitColors.primary.dark};
  --primary-hover-c9: ${bookitColors.primary.hover};
  --primary-regular-c8: ${bookitColors.primary.regular};
  --primary-components-c7: ${bookitColors.primary.components};
  --primary-components-light-c6: ${bookitColors.primary.componentsLight};
  --primary-borders-c5: ${bookitColors.primary.borders};
  --primary-disabled-c4: ${bookitColors.primary.disabled};
  --primary-bg-hover-c3: ${bookitColors.primary.bgHover};
  --primary-background-c2: ${bookitColors.primary.background};
  --primary-bg-light-c1: ${bookitColors.primary.bgLight};
  //secondary colors
  --secondary-violet-dark-text-c10: ${bookitColors.secondary.violet.dark};
  --secondary-violet-hover-c9: ${bookitColors.secondary.violet.hover};
  --secondary-violet-regular-c8: ${bookitColors.secondary.violet.regular};
  --secondary-violet-components-c7: ${bookitColors.secondary.violet.components};
  --secondary-violet-components-light-c6: ${bookitColors.secondary.violet.componentsLight};
  --secondary-violet-borders-c5: ${bookitColors.secondary.violet.borders};
  --secondary-violet-disabled-c4: ${bookitColors.secondary.violet.disabled};
  --secondary-violet-bg-hover-c3: ${bookitColors.secondary.violet.bgHover};
  --secondary-violet-background-c2: ${bookitColors.secondary.violet.background};
  --secondary-violet-bg-light-c1: ${bookitColors.secondary.violet.bgLight};
  //danger colors
  --semantic-danger-dark-text-c10: ${bookitColors.danger.dark};
  --semantic-danger-hover-c9: ${bookitColors.danger.hover};
  --semantic-danger-regular-c8: ${bookitColors.danger.regular};
  --semantic-danger-components-c7: ${bookitColors.danger.components};
  --semantic-danger-components-light-c6: ${bookitColors.danger.componentsLight};
  --semantic-danger-borders-c5: ${bookitColors.danger.borders};
  --semantic-danger-disabled-c4: ${bookitColors.danger.disabled};
  --semantic-danger-bg-hover-c3: ${bookitColors.danger.bgHover};
  --semantic-danger-background-c2: ${bookitColors.danger.background};
  --semantic-danger-bg-light-c1: ${bookitColors.danger.bgLight};
  //success colors
  --semantic-success-dark-text-c10: ${bookitColors.success.dark};
  --semantic-success-hover-c9: ${bookitColors.success.hover};
  --semantic-success-regular-c8: ${bookitColors.success.regular};
  --semantic-success-components-c7: ${bookitColors.success.components};
  --semantic-success-components-light-c6: ${bookitColors.success.componentsLight};
  --semantic-success-borders-c5: ${bookitColors.success.borders};
  --semantic-success-disabled-c4: ${bookitColors.success.disabled};
  --semantic-success-bg-hover-c3: ${bookitColors.success.bgHover};
  --semantic-success-background-c2: ${bookitColors.success.background};
  --semantic-success-bg-light-c1: ${bookitColors.success.bgLight};
 //alert(warning) colors
  --semantic-alert-dark-text-c10: ${bookitColors.warning.dark};
  --semantic-alert-hover-c9: ${bookitColors.warning.hover};
  --semantic-alert-regular-c8: ${bookitColors.warning.regular};
  --semantic-alert-components-c7: ${bookitColors.warning.components};
  --semantic-alert-components-light-c6: ${bookitColors.warning.componentsLight};
  --semantic-alert-borders-c5: ${bookitColors.warning.borders};
  --semantic-alert-disabled-c4: ${bookitColors.warning.disabled};
  --semantic-alert-bg-hover-c3: ${bookitColors.warning.bgHover};
  --semantic-alert-background-c2: ${bookitColors.warning.background};
  --semantic-alert-bg-light-c1: ${bookitColors.warning.bgLight};
   --box-shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.10), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    --box-shadow-s: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
    --box-shadow-m: 0px 4px 4px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    --box-shadow-l: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    --box-shadow-xl: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
    --box-shadow-border-top: 0px 1px 0px 0px #E3E8EF inset;

}
  #root {
    background-color: var(--grayscale-gray-bg-components-c3);
    @media (max-width: 960px) {
      margin-bottom: env(safe-area-inset-bottom, 50px);
    }
    & .info-root {
      cursor: pointer;
      z-index: 1;
      &.tableInfo {
        position: absolute;
        top: 20%;
        left: 0px;
      }
      &.file-info {
        position: absolute;
        top: 12px;
        right: 12px;
      }
  }
  & .base-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
  }
}
  body {
  margin-bottom: env(safe-area-inset-bottom);
}
`;
