/**
 *
 * RadioGroup
 *
 */
import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@material-ui/core';
import * as React from 'react';
import { EntityType } from 'types/common';
import { BasicRadioBox } from '../BasicRadioBox';
import clsx from 'clsx';

export interface BasicRadioGroupProps<T extends EntityType>
  extends Omit<MuiRadioGroupProps, 'row' | 'onChange'> {
  options: Array<T>;
  value?: string;
  className?: string;
  onChange?: (
    value: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  id: string;
  fullWidth?: boolean;
  orientation?: 'horizontal' | 'vertical';
  color?: 'primary' | 'secondary' | 'default';
  //formik
  name?: string;
  error?: string;
  label?: string;
  disabled?: boolean;
  helperText?: string;
  autoWrapLabel?: boolean;
  labelBold?: boolean;
  required?: boolean;
  /**
   * Make a radio component inside a box.
   */
  boxed?: boolean;
  shortLabel?: boolean;
}

export function BasicRadioGroup<T extends EntityType>({
  name,
  id,
  value,
  options,
  orientation,
  boxed,
  color,
  fullWidth,
  onChange,
  error,
  shortLabel,
  autoWrapLabel,
  labelBold,
  required,
  ...props
}: BasicRadioGroupProps<T>) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    if (onChange !== undefined) {
      onChange(value, event);
    }
  };
  return (
    <MuiRadioGroup
      key={id}
      aria-label={id}
      name={name}
      value={value || ''}
      onChange={handleChange}
      className={clsx({
        horizontal: orientation === 'horizontal',
        vertical: orientation !== 'horizontal',
      })}
      row={orientation === 'horizontal' ? true : false}
      {...props}
    >
      {options.map((option, index) => (
        <BasicRadioBox
          key={option.Id}
          value={option.Id}
          required={required}
          label={option.Name}
          disabled={props.disabled || (option as any).disabled}
          info={(option as any).Info}
          boxed={boxed}
          error={error}
          color={color}
          autoWrapLabel={autoWrapLabel}
          labelBold={labelBold}
          fullWidth={orientation === 'vertical' ? fullWidth : undefined}
          shortLabel={shortLabel}
        />
      ))}
    </MuiRadioGroup>
  );
}
