import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import styled from 'styled-components';

const PaginationActions = styled.div`
  display: flex;
  align-items: flex-start;
  padding-inline: 12px;
  gap: 12px;
`;
const TablePaginationActions: React.FC<TablePaginationActionsProps> = props => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <PaginationActions>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        size="xs"
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? (
          <Icon icon="chevron-double-right" />
        ) : (
          <Icon icon="chevron-double-left" />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        size="xs"
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <Icon icon="chevron-right" />
        ) : (
          <Icon icon="chevron-left" />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        size="xs"
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <Icon icon="chevron-left" />
        ) : (
          <Icon icon="chevron-right" />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        size="xs"
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? (
          <Icon icon="chevron-double-left" />
        ) : (
          <Icon icon="chevron-double-right" />
        )}
      </IconButton>
    </PaginationActions>
  );
};

// TablePaginationActions.propTypes = {
//   count: PropTypes.number.isRequired,
//   onChangePage: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired,
// };

export default TablePaginationActions;
