import { Checkbox, CheckboxProps } from '@material-ui/core';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import React from 'react';

export type CheckColor = 'primary';

export interface CheckProps extends Omit<CheckboxProps, 'color'> {
  color?: CheckColor;
  checkicon?: React.ReactNode;
  uncheckicon?: React.ReactNode;
  indetermicon?: React.ReactNode;
  withPadding?: boolean;
  danger?: boolean;
  label?: string;
}

export function Check(props: CheckProps) {
  const {
    color,
    checkicon,
    uncheckicon,
    indetermicon,
    label,
    withPadding = true,
    danger,
    size = 'medium',
    ...other
  } = props;
  const checkSizeClass = React.useMemo(() => {
    return `checkSize${capitalize(size)}`;
  }, [size]);
  return (
    <Checkbox
      {...other}
      disableRipple
      className={clsx('base-check', { 'with-padding': withPadding })}
      checkedIcon={
        !!checkicon ? (
          checkicon
        ) : (
          <span
            className={clsx('base-check-icon', checkSizeClass, 'checked-icon', {
              'base-check-danger': danger,
            })}
          />
        )
      }
      icon={
        !!uncheckicon ? (
          uncheckicon
        ) : (
          <span
            className={clsx('base-check-icon', checkSizeClass, {
              'base-check-danger': danger,
            })}
          />
        )
      }
      indeterminateIcon={
        !!indetermicon ? (
          indetermicon
        ) : (
          <span
            className={clsx(
              'base-check-icon',
              'indeterminate-icon',
              checkSizeClass,
              {
                'base-check-danger': danger,
              },
            )}
          />
        )
      }
      inputProps={{ 'aria-label': !!label ? label : 'decorative checkbox' }}
      size={size}
      color={'default'}
    />
  );
}
