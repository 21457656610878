import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { samplePlatesActions as actions } from '.';
import { appSettingsActions } from 'app/slice';
import { translations } from 'locales/translations';
import i18next from 'i18next';
import { httpClient } from 'api/HttpClient';
import { ISamplePlateDto } from 'api/odata/generated/entities/ISamplePlateDto';
import { TableFilterBuilder } from 'app/components/BasicTable/TableFilterBuilder';
import { selectSamplePlatesTableState } from './selectors';
import { getExportParameters, buildURL } from 'utils/url-utils';
import { AxiosError } from 'axios';
import {
  IBulkSamplePlateCreate,
  ISamplePlateCreate,
  SamplePlatesTableState,
} from './types';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { openExportLink } from 'utils/url-utils';

const baseUrl = `/api/odata/v4/SamplePlates`;
export function getLoadDataParams(currentTableState: SamplePlatesTableState) {
  return {
    $count: true,
    $top: currentTableState.pageSize,
    $skip:
      (currentTableState.pageIndex ?? 0) * (currentTableState.pageSize ?? 5),
    $orderby:
      currentTableState.sortBy
        ?.map(f => `${f.id} ${f.desc ?? false ? 'desc' : 'asc'}`)
        .join(',') || undefined,
    $select: currentTableState.visibleColumns
      ?.concat(
        currentTableState.additionalColumns === undefined
          ? []
          : currentTableState.additionalColumns,
      )
      .join(','),
    $filter: TableFilterBuilder(
      currentTableState.customFilters as Array<
        IFilterSettings<ISamplePlateDto>
      >,
      currentTableState.globalFilter,
      currentTableState.serviceGroups?.length !== 0
        ? currentTableState.serviceGroups
        : undefined,
      [
        'Id',
        'ServiceGroupName',
        'RoomName',
        'LocationTypeName',
        'LocationName',
        'SamplePlateTypeName',
      ],
    ),
  };
}
function* doGet(action: PayloadAction<SamplePlatesTableState>) {
  try {
    const currentTableState = action.payload;
    const someResult = yield call(
      httpClient.get,
      baseUrl,
      getLoadDataParams(currentTableState),
    );
    yield put(actions.get_Success(someResult));
  } catch (error) {
    yield put(
      appSettingsActions.addNotification({
        message: i18next.t(translations.errormessage),
        variant: 'error',
      }),
    );
    yield put(actions.get_Error(Error));
  }

  try {
    const plateTypes = yield call(
      httpClient.get,
      '/api/odata/v4/SamplePlateTypes',
    );
    yield put(actions.getPlateTypes_Success(plateTypes.value));
  } catch (error) {
    console.error(error);
  }
}
function* doExport(action: PayloadAction) {
  try {
    const tableState = yield select(selectSamplePlatesTableState);

    const tableParams = getLoadDataParams({
      ...tableState,
    });
    const entitySetURl = '/api/odata/v4/sampleplates';
    var params = getExportParameters(tableParams);
    const url = buildURL(entitySetURl, params);
    yield call(openExportLink, url);
  } catch (error) {
    console.error('doExport failed', error);
  }
}

function* doRefresh(action: PayloadAction) {
  const tableState = yield select(selectSamplePlatesTableState);
  yield put(actions.get(tableState));
}

/* function* doCreate(action: PayloadAction<ISamplePlateCreate>) {
  const samplePlateId = action.payload.SamplePlateType?.Id ?? null;
  if (samplePlateId === null) {
    yield put(actions.create_Error('SamplePlateId is required'));
    return;
  }
  const httpPayload: Partial<ISamplePlateDto> = {
    Id: action.payload.Id ?? '',
    RoomId: action.payload.Room?.Id ?? null,
    LocationId: action.payload.Location?.Id ?? null,
    SamplePlateTypeId: samplePlateId,
    ServiceGroupId: action.payload.ServiceGroup?.Id ?? null,
    Active: action.payload.Active ?? true,
    IsRack: action.payload.IsRack ?? false,
  };
  try {
    const someResult = yield call(httpClient.post, baseUrl, httpPayload);
    yield put(
      appSettingsActions.addNotification({
        message: i18next.t(translations.Success),
        variant: 'success',
      }),
    );
    yield put(actions.create_Success(someResult));
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'SamplePlatesCreate',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.create_Error(Error));
  }
} */

function genratePlatesFromBulk(
  item: IBulkSamplePlateCreate,
): ISamplePlateCreate[] {
  if (item.LastId === undefined) {
    throw new Error('LastId is required');
  }
  const firstId = item.FirstId;
  if (firstId === undefined) {
    throw new Error('FirstId is required');
  }
  const n = item.LastId - firstId + 1;
  const d = Math.floor(Math.log10(item.LastId)) + 1;
  var result = [...Array(n).keys()]
    .map(i => firstId + i)
    .map(i => i.toString().padStart(d, '0'))
    .map(id => ({
      Id: `${item.Prefix}${id}`,
      Room: item.Room,
      Location: item.Location,
      SamplePlateType: item.SamplePlateType,
      ServiceGroup: item.ServiceGroup,
      Active: item.Active,
      IsRack: item.IsRack,
    }));
  return result;
}

function* doBulkCreate(action: PayloadAction<IBulkSamplePlateCreate>) {
  const plates = genratePlatesFromBulk(action.payload);
  const errors: string[] = [];
  let successCount = 0;

  for (let index = 0; index < plates.length; index++) {
    const plate = plates[index];

    const samplePlateId = plate.SamplePlateType?.Id ?? null;
    if (samplePlateId === null) {
      yield put(actions.create_Error('SamplePlateId is required'));
      return;
    }

    const httpPayload: Partial<ISamplePlateDto> = {
      Id: plate.Id ?? '',
      RoomId: plate.Room?.Id ?? null,
      LocationId: plate.Location?.Id ?? null,
      SamplePlateTypeId: samplePlateId,
      ServiceGroupId: plate.ServiceGroup?.Id ?? null,
      Active: plate.Active ?? true,
      IsRack: plate.IsRack ?? false,
    };
    try {
      yield call(httpClient.post, baseUrl, httpPayload);
      successCount++;
    } catch (error: unknown) {
      const message =
        (error as AxiosError)?.response?.data?.error?.innererror?.message ??
        ((error as AxiosError)?.response?.status === 403
          ? i18next.t(translations.Forbidden)
          : undefined);
      if (message !== undefined) {
        errors.push(message);
      }
    } finally {
      yield put(actions.bulkCreate_SetProgress((100 * index) / plates.length));
    }
  }
  if (errors.length > 0) {
    for (const error of errors) {
      yield put(
        appSettingsActions.addNotification({
          key: 'SamplePlatesCreate',
          message: error,
          variant: 'error',
        }),
      );
    }
    yield put(actions.create_Error(Error));
  }
  if (successCount > 0) {
    yield put(
      appSettingsActions.addNotification({
        message: i18next.t(translations.Success),
        variant: 'success',
      }),
    );
    yield put(actions.bulkCreate_Success());
  }
}

/* function* doUpdate(
  action: PayloadAction<{
    original: ISamplePlateDto;
    current: Partial<ISamplePlateCreate>;
  }>,
) {
  const current = action.payload.current;
  const samplePlateId = current.SamplePlateType?.Id ?? null;
  if (samplePlateId === null) {
    yield put(actions.create_Error('SamplePlateId is required'));
    return;
  }
  const httpPayload: Partial<ISamplePlateDto> = {
    Id: current.Id ?? '',
    RoomId: current.Room?.Id ?? null,
    LocationId: current.Location?.Id ?? null,
    SamplePlateTypeId: samplePlateId,
    ServiceGroupId: current.ServiceGroup?.Id ?? null,
    LocationName: null,
    RoomName: null,
    SamplePlateTypeName: null,
    ServiceGroupName: null,
    Active: current.Active ?? true,
    IsRack: current.IsRack ?? false,
  };
  try {
    const someResult = yield call(
      httpClient.patch,
      `${baseUrl}('${action.payload.original.Id}')`,
      httpPayload,
    );
    yield put(
      appSettingsActions.addNotification({
        message: i18next.t(translations.Success),
        variant: 'success',
      }),
    );
    yield put(actions.update_Success(someResult));
  } catch (error) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.update_Error(Error));
  }
} */

function* doDelete(action: PayloadAction<string>) {
  const messageKey = 'SamplePlatesDelete';
  try {
    const someResult = yield call(
      httpClient.delete,
      `${baseUrl}('${action.payload}')`,
    );
    yield put(
      appSettingsActions.addNotification({
        key: messageKey,
        message: i18next.t(translations.Success),
        variant: 'success',
      }),
    );
    yield put(actions.delete_Success(someResult));
  } catch (error) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: messageKey,
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.delete_Error(Error));
  }
}
function* doDeactivatePlates(action: PayloadAction<ISamplePlateDto[]>) {
  const messageKey = 'SamplePlatesDelete';
  try {
    const payload: Pick<ISamplePlateDto, 'Active'> = { Active: false };
    yield all(
      action.payload
        .map(samplePlate => `${baseUrl}('${samplePlate.Id}')`)
        .map(url => call(httpClient.patch, url, payload)),
    );
    yield put(actions.deactivatePlates_Success());
  } catch (error) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: messageKey,
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.deactivatePlates_Error());
  }
}

function* doDeletePlates(action: PayloadAction<ISamplePlateDto[]>) {
  const messageKey = 'SamplePlatesDelete';
  try {
    yield all(
      action.payload
        .map(samplePlate => `${baseUrl}('${samplePlate.Id}')`)
        .map(url => call(httpClient.delete, url)),
    );
    yield put(actions.deletePlates_Success());
  } catch (error) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: messageKey,
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.deletePlates_Error());
  }
}

export function* samplePlatesSaga() {
  yield takeLatest(actions.get.type, doGet);
  //yield takeLatest(actions.create.type, doCreate);
  //yield takeLatest(actions.create_Success.type, doRefresh);
  yield takeLatest(actions.bulkCreate.type, doBulkCreate);
  yield takeLatest(actions.bulkCreate_Success.type, doRefresh);
  //yield takeLatest(actions.update.type, doUpdate);
  //yield takeLatest(actions.update_Success.type, doRefresh);
  yield takeLatest(actions.delete.type, doDelete);
  yield takeLatest(actions.delete_Success.type, doRefresh);
  yield takeLeading(actions.deactivatePlates.type, doDeactivatePlates);
  yield takeLatest(actions.deactivatePlates_Success.type, doRefresh);
  yield takeLeading(actions.deletePlates.type, doDeletePlates);
  yield takeLatest(actions.deletePlates_Success.type, doRefresh);
  yield takeLeading(actions.export, doExport);
}
