import { getSingleScreenToShowOn } from 'app/components/pickers/StaticOptionsPickers/ScreenToShowOnPicker';
import { AsyncDataState, Entity } from 'types/common';
import { IBillingActionsDto } from '../../../BillingActionsPage/IBillingActionsDto';
import { getSingleInvoiceFundingType } from '../../../../../components/pickers/StaticOptionsPickers/FundingTypeIdPicker/index';
import { getSingleInvoiceStatusWithStaticOptions } from '../../../../../components/pickers/StaticOptionsPickers/InvoiceStatusWithStaticOptionsPicker/index';
import { IResponseType } from 'types/ResponseType';
import { getSinglePaymentMethod } from 'app/components/pickers/StaticOptionsPickers/PaymentMethodEnumPicker';
import { IEmailRecipientTypesDto } from 'app/pages/BillingActions/BillingActionsPage/IEmailRecipientTypesDto';
import { IEmailAttachmentTypesDto } from 'app/pages/BillingActions/BillingActionsPage/IEmailAttachmentTypesDto';
import i18next from 'i18next';

/* --- STATE --- */
export interface BillingActionsState {
  createState: AsyncDataState<BillingActionsDetailsState>;
  updateState: AsyncDataState<BillingActionsDetailsState>;
  processing: boolean;
}

export interface BillingActionsDetailsState {
  Id: number;
  OperationName: string | null;
  ShowOn: Entity<number> | null; //Enum
  FundingType: Entity<number> | null; //Enum
  IsSendEmail: boolean;
  IsSftpUpload: boolean;
  EmailRecipientTypes: Entity<number>[];
  SpecificEmailAddresses: string | null | undefined;
  Body: string | null | undefined;
  Subject: string | null | undefined;
  EmailAttachmentTypes: Entity<number>[];
  ChangeStatusTo: Entity<number> | null; //Enum
  GeneratePayment: Entity<number> | null; //Enum
}

export interface BillingActionsDetailsQStringParameters {
  id?: string; //Id
  opName?: string; //OperationName
  showOn?: string; //ShowOn
  fundingT?: string; //FundingType
  isSendEmail?: string; //IsSendEmail
  isSftpUpload?: string;
  //emailRecTypes?: string;
  specEmailAd?: string; //SpecificEmailAddresses
  body?: string; //Body
  sub?: string; //Subject
  //emailAttTypes?: string;
  chgStatTo?: string; //ChangeStatusTo
  genPay?: string; //Generate Payment
}

export interface BillingActionsDetailsResponse extends IResponseType {
  Id?: number;
}

export function ConvertModelToEntityBillingActionsDetails(
  entity: IBillingActionsDto,
): BillingActionsDetailsState | undefined {
  if (!entity) {
    return undefined;
  } else {
    return {
      Id: entity.Id,
      OperationName: entity.OperationName,
      ShowOn: getSingleScreenToShowOn(entity.ShowOn),
      FundingType:
        entity.FundingType !== null
          ? getSingleInvoiceFundingType(entity.FundingType)
          : null,
      IsSendEmail: entity.IsSendEmail ?? false,
      IsSftpUpload: entity.IsSftpUpload ?? false,
      SpecificEmailAddresses: entity.SpecificEmailAddresses,
      Body: entity.Body,
      Subject: entity.Subject,
      EmailRecipientTypes: entity.EmailRecipientTypes.map(i => {
        return {
          Id: i.Id,
          Name: i18next.t(i.Name) as string,
        } as IEmailRecipientTypesDto;
      }) as IEmailRecipientTypesDto[],
      EmailAttachmentTypes: entity.EmailAttachmentTypes.map(i => {
        return {
          Id: i.Id,
          Name: i18next.t(i.Name) as string,
        } as IEmailAttachmentTypesDto;
      }) as IEmailAttachmentTypesDto[],
      ChangeStatusTo: getSingleInvoiceStatusWithStaticOptions(
        entity.ChangeStatusTo,
      ),
      GeneratePayment: getSinglePaymentMethod(entity.GeneratePayment),
    } as BillingActionsDetailsState;
  }
}

export function ConvertEntityToModelBillingActionsDetails(
  model: BillingActionsDetailsState,
  original: BillingActionsDetailsState | undefined,
): IBillingActionsDto {
  let entity = {
    Id: original?.Id === undefined ? model.Id : original.Id,
    OperationName: model.OperationName,
    ShowOn: model.ShowOn?.Id,
    FundingType: model.FundingType?.Id,
    IsSendEmail: model.IsSendEmail ?? false,
    IsSftpUpload: model.IsSftpUpload ?? false,
    SpecificEmailAddresses: model.SpecificEmailAddresses,
    Body: model.Body,
    Subject: model.Subject,
    EmailRecipientTypes: model.EmailRecipientTypes.map(i => {
      return {
        Id: i.Id,
        Name: i18next.t(i.Name) as string,
      } as IEmailRecipientTypesDto;
    }) as IEmailRecipientTypesDto[],
    EmailAttachmentTypes: model.EmailAttachmentTypes.map(i => {
      return {
        Id: i.Id,
        Name: i18next.t(i.Name) as string,
      } as IEmailAttachmentTypesDto;
    }) as IEmailAttachmentTypesDto[],
    ChangeStatusTo: model.ChangeStatusTo?.Id,
    GeneratePayment: model.GeneratePayment?.Id,
  } as IBillingActionsDto;
  return entity;
}
