import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { translations } from 'locales/translations';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import {
  FormListener,
  SubmittingHandler,
} from 'app/components/Forms/FormRender/FormRenderer';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { IOfflineTypeRefsModel } from './slice/types';
import {
  EntityNumberSchema,
  ServiceTypeSchema,
} from 'app/components/Forms/Schemas';
import { FormServiceShortPicker } from 'app/components/Forms/FormServiceShortPicker';
import { FormOfflineReferenceTypePicker } from 'app/components/Forms/FormOfflineRefernceTypePicker';
import { FormOfflineServiceTypePicker } from 'app/components/Forms/FormOfflineServiceTypePicker';
import { FormMandatoryOfflineTypePicker } from 'app/components/Forms/FormMandatoryOfflineTypePicker';
import { FormNumberField } from 'app/components/Forms/FormNumberField';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { formChangeHandler } from 'app/components/Forms/FormRender/IFormFieldSetting';
import { selectDetailsHasError } from './slice/selectors';
import { OfflineReferenceTypeEnum } from 'enums/OfflineReferenceTypeEnum';
import { quoteODataValue } from 'api/odata/ODataFilter';
import { MandatoryOfflineTypeEnum } from 'enums/MandatoryOfflineTypeEnum';
import { getSingleEnumEntityById } from 'utils/enumKeys';
export interface OfflineTypeRefsFormProps {
  onSubmit: (item: IOfflineTypeRefsModel) => void;
  initialValues: IOfflineTypeRefsModel;
  processing?: boolean;
  bindSubmitForm: any;
  isEdit: boolean;
  isAdmin: boolean;
  user?: AuthenticatedUser;
  innerFormRef: React.RefObject<FormikProps<IOfflineTypeRefsModel>>;
}

export const OfflineTypeRefsForm = React.memo(function OfflineTypeRefsForm({
  onSubmit,
  processing,
  initialValues,
  bindSubmitForm,
  isEdit,
  isAdmin,
  user,
  innerFormRef,
}: OfflineTypeRefsFormProps) {
  const { t } = useTranslation();
  const hasError = useSelector(selectDetailsHasError);
  const readonly = isEdit;
  const [submitting, setSubmitting] = React.useState<boolean | undefined>(
    undefined,
  );
  const resetSubmitting = () => {
    setSubmitting(true);
  };
  const handleSubmit = (values: IOfflineTypeRefsModel) => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(values);
    }
  };
  /// Local State ///
  const [refServiceChanged, setRefServiceChanged] = React.useState(false);
  const [offlineTypeChanged, setOfflineTypeChanged] = React.useState(false);
  const [refTypeChanged, setRefTypeChanged] = React.useState(false);

  const handleFormChange = React.useCallback<
    formChangeHandler<IOfflineTypeRefsModel>
  >(
    (
      values,
      isValid,
      dirty,
      setFieldValue,
      setTouched,
      validateField,
      setError,
      validate,
    ) => {
      if (refServiceChanged) {
        setRefServiceChanged(false);
      }
      if (offlineTypeChanged) {
        setOfflineTypeChanged(false);
        if (values.OfflineServiceType !== null) {
          setFieldValue(
            'DefaultQuantiy',
            (values.OfflineServiceType as IOfflineServiceFilterDto)
              ?.DefaultQuantity,
          );
        }
      }
      if (refTypeChanged) {
        setFieldValue('RefService', null);
        setFieldValue('OfflineServiceType', null);
        setFieldValue(
          'Mandatory',
          getSingleEnumEntityById(
            MandatoryOfflineTypeEnum.Mandatory_Optional,
            MandatoryOfflineTypeEnum,
            t,
          ),
        );
        setRefTypeChanged(false);
      }
    },
    [refServiceChanged, offlineTypeChanged, refTypeChanged, t],
  );

  /// useEffects
  React.useEffect(() => {
    if (innerFormRef.current) {
      innerFormRef.current.validateForm();
    }
  }, [innerFormRef]);

  const computedSchema = React.useMemo(() => {
    const offlineRefsSchema: Yup.SchemaOf<IOfflineTypeRefsModel> = Yup.object({
      Id: Yup.number().notRequired().default(-1),
      OfflineServiceType: ServiceTypeSchema.label(
        t(translations.OfflineServiceType) as string,
      )
        .nullable()
        .required(t(translations.err_OfflineServiceTypeRequired) as string),
      RefService: ServiceTypeSchema.label(
        t(translations.RelatedService) as string,
      )
        .nullable()
        .required(t(translations.RelatedServiceRequired_err) as string),
      RefType: EntityNumberSchema.nullable().required(
        t(translations.RefernceTypeRequired_err) as string,
      ),
      Mandatory: EntityNumberSchema.nullable()
        .label(t(translations.MandatoryOfflineRef) as string)
        .required(t(translations.MandatoryOfflineRef_required) as string),
      DefaultQuantity: Yup.number()
        .nullable()
        .notRequired()
        .default(null)
        .test(
          'integerOnly',
          t(translations.err_IntQuantityOnly) as string,
          (value, context) => {
            let currVal = (context as any).from[0].value.DefaultQuantity;
            let offService = (context as any).from[0].value.OfflineServiceType;
            if (
              !!currVal &&
              currVal !== null &&
              currVal !== '' &&
              !!offService &&
              offService !== null
            ) {
              if (offService.IntQuantityOnly === true) {
                return Number(currVal) % 1 === 0;
              }
            }
            return true;
          },
        ),
    });
    return offlineRefsSchema;
  }, [t]);
  return (
    <>
      <Formik
        validationSchema={computedSchema}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        innerRef={innerFormRef}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          formikHelpers.validateForm(values).then(responseErrors => {
            if (!isEmpty(responseErrors)) {
              formikHelpers.setSubmitting(false);
              console.debug('errors', responseErrors);
            } else {
              console.debug('submitting', values);
              formikHelpers.setSubmitting(true);
              handleSubmit(values);
            }
          });
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          let refPredicates: string[] = [];
          let offlinePredicates: string[] = [];
          if (
            formik.values.RefType?.Id ===
            OfflineReferenceTypeEnum.Offline_ServiceRequest
          ) {
            refPredicates = [`TypeId eq 3`];
          } else {
            refPredicates = [`TypeId eq 1`];
          }
          if (formik.values.OfflineServiceType !== null) {
            refPredicates.push(
              `ServiceGroupId eq ${
                (formik.values.OfflineServiceType as IOfflineServiceFilterDto)
                  ?.ServiceGroupId
              }`,
            );
          }
          if (
            !!formik.values.RefService &&
            !!formik.values.RefService?.ServiceGroupId &&
            formik.values.RefService?.ServiceGroupId !== null
          ) {
            offlinePredicates = [
              `ServiceGroupId eq ${formik.values.RefService?.ServiceGroupId}`,
            ];
          }
          if (
            Number(formik.values.RefType?.Id) > 0 &&
            Number(formik.values.RefService?.Id) > 0
          ) {
            let rid = Number(formik.values.RefService?.Id);
            let rtid = Number(formik.values.RefType?.Id);
            offlinePredicates.push(
              `RelatedReferences/any(g: g/ReferenceTypeId eq ${quoteODataValue(
                rtid,
              )} and g/ReferenceId eq ${quoteODataValue(rid)}) eq false`,
            );
          }
          return (
            <React.Fragment>
              <StyledForm onSubmit={formik.handleSubmit}>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={['RefType', 'RefService', 'OfflineServiceType']}
                />
                <SubmittingHandler
                  value={hasError === true && submitting === undefined}
                  resetSubmitting={resetSubmitting}
                />
                <FormLeftSection>
                  <FormFieldsSection>
                    <FormRow>
                      <FormOfflineReferenceTypePicker
                        fullWidth
                        id="RefTypeID"
                        name="RefType"
                        label={t(translations.ReferenceType)}
                        placeholder={t(translations.SelectAType)}
                        disabled={formik.isSubmitting || readonly}
                        onChange={val => {
                          setRefTypeChanged(true);
                        }}
                      />
                    </FormRow>
                    <FormRow>
                      <FormServiceShortPicker
                        fullWidth
                        id="RefServiceId"
                        name="RefService"
                        label={t(translations.RelatedService)}
                        placeholder={t(translations.PleaseSelectAnItem)}
                        disabled={formik.isSubmitting || readonly}
                        onChange={val => {
                          setRefServiceChanged(true);
                        }}
                        predicates={refPredicates}
                      />
                    </FormRow>
                    <FormRow fullRow={true}>
                      <FormOfflineServiceTypePicker
                        fullWidth
                        id="ServiceTypeID"
                        name="OfflineServiceType"
                        predicates={offlinePredicates}
                        urlType="base"
                        label={t(translations.OfflineServiceType)}
                        placeholder={t(translations.PleaseSelectServiceType)}
                        disabled={formik.isSubmitting || readonly}
                        expandedColumns={'RelatedReferences'}
                        onChange={val => {
                          setOfflineTypeChanged(true);
                        }}
                      />
                    </FormRow>
                    <FormRow>
                      <FormMandatoryOfflineTypePicker
                        fullWidth
                        id="MandatoryId"
                        name="Mandatory"
                        refTypeId={formik.values.RefType?.Id}
                        label={t(translations.MandatoryOfflineRef)}
                        placeholder={t(translations.SelectAnOption)}
                        info={t(translations.MandatoryOfflineRef_info)}
                        disabled={formik.isSubmitting}
                      />
                    </FormRow>
                    <FormRow fullRow>
                      <FormNumberField
                        fullWidth
                        id="DefaultQuantityID"
                        name="DefaultQuantity"
                        noWarnOnNuN={true}
                        inputMode="decimal"
                        placeholder={t(translations.DefaultQuantity)}
                        label={t(translations.DefaultQuantity)}
                        info={t(translations.OfflineDefaultQuantity_info)}
                        disabled={formik.isSubmitting}
                        endLabel={
                          <BasicTypography
                            variant="captionBold"
                            color="secondary"
                          >
                            {
                              (
                                formik.values
                                  ?.OfflineServiceType as IOfflineServiceFilterDto
                              )?.UnitTypeName
                            }
                          </BasicTypography>
                        }
                        inputProps={{
                          step:
                            (
                              formik.values
                                ?.OfflineServiceType as IOfflineServiceFilterDto
                            )?.IntQuantityOnly === true
                              ? 1
                              : 0.01,
                          min:
                            (
                              formik.values
                                ?.OfflineServiceType as IOfflineServiceFilterDto
                            )?.IntQuantityOnly === true
                              ? 1
                              : 0.01,
                          max: 9999,
                          'aria-label': 'Counter',
                        }}
                      />
                    </FormRow>
                  </FormFieldsSection>
                </FormLeftSection>
              </StyledForm>
            </React.Fragment>
          );
        }}
      </Formik>
    </>
  );
});
