import * as React from 'react';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { selectAppSettings, selectglobalSettings } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IInvoiceRow } from './IInvoicRow';
// import { UnconfigurableTable } from '../UnconfigurableTable';
import { getColumns } from './Columns';
import { GetFilters } from '../Filter';
import { CellRenderer } from 'app/components/BasicTable/BasicTableProps';
import {
  RowActionsMenu,
  RowActionsProps,
} from 'app/components/BasicTable/RowActions';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { SelectedRowsActionRenderer } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionRenderer';
import { SelectedRowsActionIconButton } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionIconButton';
import { httpClient } from 'api/HttpClient';
import { IPathAndQuery } from 'utils/url-utils';
import { BudgetPicker } from 'app/components/pickers/AutocompletePickers/BudgetPicker';
import { Entity } from 'types/common';
import { Button } from 'app/components/BasicButtons/Button';
import { BasicTable } from 'app/components/BasicTable';
import { ScreensId } from 'enums/ConfigurableTypes';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { selectInvoice } from '../../Slice/selector';
import { ReactActions } from 'app/components/ReactActions';
import { getDefaultFilter } from './getDefaultFilters';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { filterHandler } from 'app/components/BasicTable/UnconfigurableTable/FilterHandler';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';

export interface InvoiceDetailsTableProps {
  isSidePanel?: boolean;
  id: number;
  setFilters?: (
    appliedFilters: IFilterSettings<IInvoiceRow>[] | undefined,
  ) => void;
  OpenEditRow?: (rowId: number) => void;
}
const url = '/api/odata/v4/InvoiceRows';
const groupedUrl = '/api/odata/v4/GroupedInvoiceRows';
export const DetailsTable = ({
  isSidePanel,
  id,
  setFilters,
  OpenEditRow,
}: InvoiceDetailsTableProps) => {
  const TTable = useMemo(
    () =>
      filterHandler<IInvoiceRow, {}>(BasicTable, GetFilters, {
        isSidePanel,
        setFilters,
        getDefaultFilter,
        reservedQueryStringParameterNames: ['id', 'invoiceId'],
      }),
    [isSidePanel, setFilters],
  );
  const invoice = useSelector(selectInvoice);
  const [budget, setBudget] = React.useState<Entity<number> | null>(null);

  const { t } = useTranslation();
  const settings = useSelector(selectglobalSettings);
  const appSettings = useSelector(selectAppSettings);
  const columns = useMemo(() => {
    return getColumns(t, settings, appSettings, OpenEditRow, isSidePanel);
  }, [OpenEditRow, appSettings, isSidePanel, settings, t]);
  const rowActions: CellRenderer<IInvoiceRow>[] = useMemo<
    CellRenderer<IInvoiceRow>[]
  >(
    () => [
      ({ row }) => {
        const items: RowActionsProps[] = [];
        if (!!OpenEditRow) {
          items.push({
            onClick: () => {
              OpenEditRow(row.original.Id);
            },
            text: t(translations.Edit) as string,
            title: t(translations.EditInvoiceLine) as string,
            icon: 'pencil-alt',
          } as RowActionsProps);
        }

        if (items.length > 0) {
          return (
            <RowActionsMenu
              id={`invoiceRow_RowsActions${row.original.Id}`}
              items={items}
            />
          );
        } else {
          return undefined;
        }
      },
    ],
    [OpenEditRow, t],
  );
  const selectedRowActions: SelectedRowsActionRenderer<IInvoiceRow>[] = useMemo(
    () => [
      (rows, onRefresh) => (
        <SelectedRowsActionIconButton
          rows={rows}
          onRefresh={onRefresh}
          title={t(translations.DeletInvoiceRows)}
          confirm
          validConfirm
          confirmTitle={t(translations.ConfirmDeleting)}
          confirmBody={t(translations.deleteInvoiceConfirmMessage)}
          confirmButtonLabel={t(translations.Delete)}
          action={async () =>
            await httpClient.post(url + '/DeleteMany', {
              invoiceId: id,
              ids: rows.map(v => v.Id),
            })
          }
        />
      ),
      (rows, onRefresh, toggleAll) => (
        <SelectedRowsActionIconButton
          rows={rows}
          onRefresh={onRefresh}
          title={
            invoice?.StatusId === 4
              ? t(translations.InvoicePaid)
              : t(translations.EditBudgetInvoiceRows)
          }
          text={t(translations.EditBudgetInvoiceRows)}
          disabled={invoice?.StatusId === 4}
          confirm
          confirmBody={
            <BudgetPicker
              label={t(translations.PleaseSelectABudgetNumber)}
              value={budget as IBudgetFilterDto}
              onChange={val => setBudget(val)}
              variant="filled"
              fullWidth
              predicates={[
                invoice?.UserGroupId
                  ? new Condition(
                      'UserGroupId',
                      ODataOperators.Equals,
                      invoice.UserGroupId,
                    )
                  : '',
                new Condition('Active', ODataOperators.Equals, true),
                invoice?.ServiceGroups
                  ? '(' +
                    new Condition(
                      'ServiceGroupServicesAllowed',
                      ODataOperators.Equals,
                      true,
                    ).toString() +
                    ' or ' +
                    new Condition(
                      'ServiceGroupIds',
                      ODataOperators.AnyIn,
                      invoice.ServiceGroups,
                    ).toString() +
                    ')'
                  : '',
              ]}
            />
          }
          confirmTitle={
            <>
              {t(translations.EditBudgetInvoiceRows)}&nbsp;
              <Tooltip
                title={t(translations.EditBudgetInvoiceRows_info) as string}
              >
                <Icon color="filters" colorExtend="light" icon="info-circle" />
              </Tooltip>
            </>
          }
          confirmButtonLabel={t(translations.Change)}
          validConfirm={!!budget}
          action={async () => {
            setBudget(null);
            toggleAll(false);
            return await httpClient.post(url + '/UpdateBudget', {
              invoiceId: id,
              ids: rows.map(v => v.Id),
              budgetId: budget?.Id ?? 0,
            });
          }}
        />
      ),
    ],
    [budget, id, invoice, t],
  );
  /**
   * Each row in the table should have a unique Id including the parent and sub rows.
   * InvoiceId (Id) can be used on subrows as they are unique, for parent grouped row the grouping key can be used as a unique id provided the goruping is done on exact the same columns
   */
  const getRowId = React.useCallback(
    (row: IInvoiceRow) =>
      row.Rows !== undefined && row.Rows.length > 1
        ? [
            row.ReferenceId,
            row.User,
            row.UserId,
            row.OnlineService,
            row.OnlineServiceId,
            row.SampleService,
            row.SampleServiceId,
            row.SubscriptionId,
            row.SubscriptionName,
          ].join('.')
        : String(row.Id),
    [],
  );
  const [isGrouped, setIsGrouped] = React.useState(false);
  const api: IPathAndQuery = isGrouped
    ? {
        path: groupedUrl,
        search: {
          Id: id,
          $expand:
            'Rows($select=' +
            [
              ...columns
                ?.filter(c => typeof c.accessor === 'string')
                ?.filter(c => c.accessor !== undefined)
                ?.map(c => c.accessor as keyof IInvoiceRow),
              ...[
                'UserId',
                'SampleService',
                'OfflineServiceType',
                'AccService',
                'SubscriptionName',
                'ServiceRequestMilestoneChargeId',
                'TimeSlotType',
                'ReservationId',
                'OfflineServiceId',
                'ServiceRequestId',
                'UsageId',
                'SubscriptionId',
              ],
            ].join(',') +
            ')',
        },
      }
    : {
        path: url,
        search: {
          Id: id,
        },
      };

  return (
    <TTable
      screenId={ScreensId.InvoiceCharges}
      api={api}
      columns={columns}
      screenName={t(translations.menu_InvoiceCharges)}
      serviceGroups={[]}
      getRowId={getRowId}
      rowActions={rowActions.length > 0 ? rowActions : undefined}
      selectedRowsActions={selectedRowActions}
      pageActions={[
        () => (
          <ReactActions
            id="charges_top_buttons"
            items={[
              <React.Fragment key="group_by_reference">
                <Button
                  onClick={() => setIsGrouped(v => !v)}
                  title={t(translations.GroupByReferenceDescription)}
                  variant="ghost"
                  size="small"
                >
                  {isGrouped
                    ? t(translations.DoNotGroupByReference)
                    : t(translations.GroupByReference)}
                </Button>
              </React.Fragment>,
            ]}
          />
        ),
      ]}
      additionalColumns={[
        'UserId',
        'SampleService',
        'OfflineServiceType',
        'AccService',
        'SubscriptionName',
        'ServiceRequestMilestoneChargeId',
        'TimeSlotType',
        'ReservationId',
        'OfflineServiceId',
        'ServiceRequestId',
        'UsageId',
        'SubscriptionId',
        'InsertedByDisplay',
      ]}
      useRowSelect
      searchColumns={[
        'Budget',
        'OnlineService',
        'OfflineServiceType',
        'SampleService',
        'CatRateType',
        'TimeSlotType',
        'User',
        'cast(ReservationId,Edm.String)',
        'cast(UsageId,Edm.String)',
        'cast(OfflineServiceId,Edm.String)',
        'cast(ServiceRequestId,Edm.String)',
        'cast(Id,Edm.String)',
      ]}
    />
  );
};
