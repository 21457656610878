import { BasicTable, BasicTableProps } from 'app/components/BasicTable';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IReservationWaitingListDto } from './IReservationWaitingListDto';

export function ReservationWaitingListTable<
  TProps extends Omit<
    BasicTableProps<IReservationWaitingListDto>,
    | 'api'
    | 'additionalColumns'
    | 'searchColumns'
    | 'screenName'
    | 'expandedColumns'
    | 'savedListSettings'
  >,
>({ initialState, rowActions, serviceGroups, ...props }: TProps) {
  const { t } = useTranslation();
  const newProps = React.useMemo(() => {
    const p = { ...props };
    Reflect.deleteProperty(p, 'api');
    return p;
  }, [props]);
  const url = '/api/odata/v4/ReservationWaitingList';
  return (
    <>
      <BasicTable
        screenName={t(translations.menu_ReservationWaitingList)}
        api={url}
        initialState={initialState}
        rowActions={rowActions}
        additionalColumns={[
          'Id',
          'EquipmentID',
          'UserDisplayName',
          'UserName',
          'UserEmail',
          'ServiceGroupId',
          'EquipmentName',
          'NotifyOnResCancelation',
          'NotifyOnResTermination',
          'NotifyOnEndUsage',
          'UpcomingReservations',
          'UpcomingTrainingSession',

          'NextReservationHours',
          'NextResTermHours',
          'BeforeResStartHour',
          'BeforeResCancelHour',
          'BeforeTrainStartHour',
        ]}
        searchColumns={[
          'cast(Id,Edm.String)',
          'UserName',
          'UserDisplayName',
          'UserEmail',
          'EquipmentName',
        ]}
        serviceGroups={serviceGroups}
        {...(newProps as TProps)}
      />
    </>
  );
}
