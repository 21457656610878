import { initProjectData } from 'app/components/pickers/AutocompletePickers/ProjectPicker';
import { translations } from 'locales/translations';
import { IFilterSettings } from '../../BasicFilter/IFilterSettings';
import { ODataOperators } from 'api/odata/ODataFilter';
import {
  GetNumberOrUndefined,
  GetPartialEntity,
  GetRawValue,
  initPickerValue,
} from '../../types/FilterParam';
import { AppSettings, IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { ProjectFilter } from './index';

/**
 * Produces standard budgets filter settings
 * Returns undefined if the budgets are disabled in system settings
 * @param params URL search params to fetch the value form
 * @param fieldName aka accessor
 * @param t TFunction for localization
 * @param appSettings AppSettings used to determine if the budgets are enabled
 * @returns configured budgets filter
 */
export function getBudgetsFilterSettings<T>(
  params: URLSearchParams,
  fieldName: keyof T,
  appSettings: AppSettings | null,
  displayNameKey: string | undefined = translations.BudgetNumber,
): IFilterSettings<T> | undefined {
  const id = 'BudgetId';
  if (!IsModuleEnabled(appSettings, KnownModules.Budgets)) {
    return undefined;
  }
  return {
    id: id,
    fieldName: fieldName,
    displayNameKey: displayNameKey,
    operator: ODataOperators.Equals,
    // value: getDefaultFilterValue('bid', params),
    queryStringSerializer: 'bid',
    initData: initPickerValue(initProjectData),
    value: GetPartialEntity(GetNumberOrUndefined(GetRawValue(params, 'bid'))),
    component: ProjectFilter,
    urlKey: 'bid',
  };
}
