/**
 *
 * ReservationByIdWithPartsPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { IReservationByIdFilterDto } from 'api/odata/generated/entities/IReservationByIdFilterDto';

import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { Entity } from 'types/common';

export interface ReservationByIdWithPartsPickerProps
  extends Omit<
    AutocompletePickerProps<IReservationByIdFilterDto>,
    'loadData'
  > {}

const url = '/api/odata/v4/ReservationByIdFilter/WithParts';
export const initReservationByIdWithPartsData = (
  initval: string | undefined,
) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Id desc',
      $filter: '(Id eq ' + id + ')',
      $select:
        'Id,Name,FatherId,ParentAssemblyReservationId,TopAssemblyReservationId,Repeat_Period,Repeat,ReservationParts',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};

export function ReservationByIdWithPartsPicker(
  props: ReservationByIdWithPartsPickerProps,
) {
  const loadData = getAutoCompleteLoadDataFn<IReservationByIdFilterDto>({
    url,
    predicates: props.predicates,
    select: [
      'Id',
      'Name',
      'FatherId',
      'ParentAssemblyReservationId',
      'TopAssemblyReservationId',
      'Repeat_Period',
      'Repeat',
      'ReservationParts',
    ],
  });
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      loadData={loadData}
      id={props.id || 'reservationbyId'}
      {...props}
    />
  );
}
