import { Button } from 'app/components/BasicButtons/Button';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import * as React from 'react';
import { LinearProgress, Popover } from '@material-ui/core';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { IAssetAssemblyStructure } from 'app/pages/AssetPopUp/IAssetAssemblyStructure';
import { IAssemblySnapShotsDto } from '../IAssemblySnapShotsDto';
import styled from 'styled-components';
import {
  AssemblySnapshotFilter,
  AssemblySnapshotPicker,
  initAssemblySnapshotWithParams,
} from 'app/components/pickers/AutocompletePickers/AssemblySnapshotsPicker';
import { AssemblyStructure } from '../AssemblyStructure';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { Div100 } from 'app/components/AssetQuickSearch/styled';

export interface SnapshotLinkProps {
  children: React.ReactNode;
  equipmentId: number;
  userName?: string;
  structure?: IAssemblySnapShotsDto;
  primary?: boolean;
  title?: string;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
  onClick?: () => void;
  showPicker?: boolean;
}
export function SnapshotLink({
  children,
  equipmentId,
  structure,
  primary,
  title,
  userName,
  openPanelWithCover,
  useSidePanel,
  showPicker,
}: SnapshotLinkProps) {
  const { t } = useTranslation();
  const [selectedStructure, setSelectedStructure] = React.useState<
    IAssemblySnapShotsDto | undefined
  >(structure);

  const [snapshotLoading, setSnapshotLoading] = React.useState<
    boolean | undefined
  >(undefined);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleAssemblyChange = React.useCallback(
    (value?: IAssemblySnapShotsDto | null) => {
      setSnapshotLoading(false);
      setSelectedStructure(value ?? undefined);
    },
    [],
  );

  React.useEffect(() => {
    let active =
      open && snapshotLoading === undefined && selectedStructure === undefined;
    if (active) {
      setSnapshotLoading(true);
      initAssemblySnapshotWithParams(equipmentId, userName).then(snapshots => {
        snapshots.length > 0
          ? setSelectedStructure(snapshots[0])
          : setSelectedStructure(undefined);
        setSnapshotLoading(false);
      });
    }
    return () => {
      active = false;
    };
  }, [equipmentId, open, selectedStructure, snapshotLoading, userName]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => !prev);
  };
  return (
    <div>
      <Button
        variant={primary ? 'main' : 'text'}
        size="small"
        title={title ?? (t(translations.ViewAssemblyStructure_info) as string)}
        onClick={handleClick}
        aria-controls={'snapshot_structure'}
        aria-haspopup="true"
      >
        {children}
      </Button>
      <Popover
        onClose={handleClick}
        id={'snapshot_structure'}
        open={open}
        marginThreshold={1}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <AssemblyStructureContent className={'content-root'}>
          <div className="container">
            <div className="item">
              <div className="header">
                <BasicTypography variant="bodyM" bold={true}>
                  {showPicker
                    ? t(translations.AssemblySnapshots)
                    : t(translations.AssemblyStructure)}
                </BasicTypography>
                <IconButton
                  variant="ghost"
                  aria-label="close"
                  size="xs"
                  className="close-button"
                  absolutePosition="small"
                  onClick={() => {
                    setOpen(false);
                    setSelectedStructure(undefined);
                    setSnapshotLoading(undefined);
                  }}
                >
                  <Icon icon="times" />
                </IconButton>
              </div>
            </div>
            {showPicker && (
              <div className="item">
                <React.Fragment>
                  <AssemblySnapshotPicker
                    predicates={AssemblySnapshotFilter(equipmentId, userName)}
                    key="assemblyKey"
                    onChange={value => {
                      handleAssemblyChange(value);
                    }}
                    placeholder={t(translations.PleaseSelectASnapshot)}
                    fullWidth={true}
                    name="snapshots"
                    variant="filled"
                    id="snapshots-id"
                    label={t(translations.AssemblySnapshots)}
                    value={selectedStructure || undefined}
                  />
                </React.Fragment>
              </div>
            )}
            <div className="item">
              {!!selectedStructure ? (
                <AssemblyStructure
                  assemblyStructure={
                    JSON.parse(
                      selectedStructure.SnapshotJson,
                    ) as IAssetAssemblyStructure
                  }
                  openOnNewTab={true}
                  openPanelWithCover={openPanelWithCover}
                  useSidePanel={useSidePanel}
                  showTitle={showPicker}
                />
              ) : snapshotLoading ? (
                <Div100>
                  <LinearProgress variant="query" color="primary" />
                </Div100>
              ) : (
                <>
                  <BasicTypography variant="bodyM" bold={true}>
                    {t(translations.NoSnapshotFoundForAsset) as string}
                  </BasicTypography>
                </>
              )}
            </div>
          </div>
        </AssemblyStructureContent>
      </Popover>
    </div>
  );
}
const AssemblyStructureContent = styled.div`
  &.content-root {
    padding: 16px;
    width: 350px;
    & .container {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      flex-wrap: nowrap;
      width: 100%;
      & .item {
        box-sizing: border-box;
        margin-bottom: 16px;
        & .header {
          display: flex;
          justify-content: space-between;
          & .close-button {
            position: absolute;
            top: 8px;
            right: 8px;
          }
        }
      }
    }
  }
`;
