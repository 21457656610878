import { useDispatch } from 'react-redux';
import { useLargeFilesUploadSlice } from '../slice';
import { useCallback } from 'react';
import { Identifiable } from 'types/common';

export const useUploadToNewComment = () => {
  const dispatch = useDispatch();
  const { actions: largeFileActions } = useLargeFilesUploadSlice();
  const onCommentAdded = useCallback(
    (comment: Identifiable<number>) => {
      dispatch(largeFileActions.setCanUpload(comment.Id));
    },
    [dispatch, largeFileActions],
  );
  return { onCommentAdded };
};
