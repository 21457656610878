import * as React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { SideBar } from 'app/Layout/FrontendLayout/components/SideBar';
import clsx from 'clsx';
import { ClickAwayListener } from '@material-ui/core';
import useExpandedSideBarState from 'app/hooks/useExpandedSideBarState';
import { useSelector } from 'react-redux';
import { selectIsExpandedSideBar } from 'app/slice/selectors';

export interface SideBarDrawerProps {
  hideNavigation: boolean | undefined;
  isMobile: boolean;
  isLandscape?: boolean;
  handleDrawerClose: (event: any, reason: any) => void;
}
export const SideBarDrawer = React.memo(function SideBarDrawer(
  props: SideBarDrawerProps,
) {
  const { isMobile, handleDrawerClose, isLandscape } = props;
  const expanded = useSelector(state =>
    selectIsExpandedSideBar(state, isMobile),
  );
  const { toggle } = useExpandedSideBarState();
  const clickAway = event => {
    console.log('menu target: ', (event.target as any).nodeName);
    if (
      ((event.target as any).nodeName === 'path' ||
        (event.target as any).nodeName === 'svg' ||
        (event.target as any).nodeName === 'BODY') &&
      event.type === 'click'
    ) {
      return;
    }
    if (isMobile) {
      event.stopPropagation();
      event.preventDefault();
      if (expanded) {
        toggle();
      }
    }
  };
  return !props.hideNavigation ? (
    <ClickAwayListener
      onClickAway={
        isMobile
          ? clickAway
          : event => {
              return;
            }
      }
    >
      <Drawer
        tabIndex={0}
        variant={isMobile ? 'temporary' : 'permanent'}
        open={expanded}
        onClose={handleDrawerClose}
        ModalProps={{
          hideBackdrop: true,
          disablePortal: isMobile,
        }}
        className={clsx('sideBarDrawer', {
          sideBarMobile: isMobile,
          sideBarMobileHorizontal: isMobile && isLandscape,
          drawerOpen: expanded,
          drawerClose: !expanded,
        })}
      >
        <SideBar isOpen={expanded} isMobile={isMobile} toggleMenu={toggle} />
      </Drawer>
    </ClickAwayListener>
  ) : null;
});
