import { ITrainingSessionsRow } from 'app/pages/TrainingSessionsPage/ITrainingSessionsRow';
import { ReservationLinkAction } from 'app/pages/TrainingSessionsPage/RowActions/ReservationLink';
import React from 'react';
import { CellProps, Renderer } from 'react-table';
import { dateUtils } from 'utils/date-utils';

type RecordType = ITrainingSessionsRow;
const TrainingStateRenderer: Renderer<CellProps<RecordType>> = ({
  value,
  row,
}) => {
  const hasNextSession =
    dateUtils.dateOrStringToDate(row.original.StartDate ?? new Date()) >
    dateUtils.truncateTime(new Date());
  return (
    <React.Fragment>
      <ReservationLinkAction
        Id={row.original.Id}
        HasNextSession={hasNextSession}
        TrainingState={row.original.TrainingState}
        hasReservation={row.original.hasReservation}
        myReservationId={row.original.myReservationId}
        ServiceId={row.original.ServiceId}
        NextSession={row.original.NextSession}
        EndDate={row.original.EndDate}
        StartDate={row.original.StartDate}
      />
    </React.Fragment>
  );
};
export default TrainingStateRenderer;
