import { IconProp } from 'app/AllIcons';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import useExpandedSideBarState from 'app/hooks/useExpandedSideBarState';

export interface ToggleSideMenuButtonProps {
  icon?: IconProp;
}
export function ToggleSideMenuButton(props: ToggleSideMenuButtonProps) {
  const { toggle } = useExpandedSideBarState();
  const { icon } = props;
  return (
    <IconButton
      aria-label="open drawer"
      onClick={() => {
        toggle();
      }}
    >
      <Icon icon={icon ?? 'chevrons-left'} />
    </IconButton>
  );
}
