import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { Roles } from 'api/odata/generated/enums/Roles';
import { OnlineServiceType } from 'enums/OnlineServiceTypeEnum';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { IUsageDto, UsageSettingsState } from './types';

export function getUsageSettings(
  dto: IUsageDto,
  globalSettings: GlobalSettingsType,
  authenticatedUser?: AuthenticatedUser,
) {
  let isServiceAdmin =
    dto.BaseEquipment !== null &&
    dto.BaseEquipment !== undefined &&
    !!authenticatedUser
      ? authenticatedUser.IsAdminOf({
          ServiceGroupId: dto.BaseEquipment.ServiceGroupId,
          ServiceId: dto.BaseEquipment.Id,
          ServiceTypeId: ServiceType.Online,
        })
      : authenticatedUser?.HasAdminRoles() ?? false;

  let chargesViewAllowed =
    (authenticatedUser && authenticatedUser.Roles.includes(Roles.FinAdmin)) ||
    isServiceAdmin ||
    (authenticatedUser &&
      authenticatedUser.Roles.includes(Roles.InvoicesReader) &&
      authenticatedUser.ActiveUserGroup?.Id === dto?.ADGroup?.Id);
  let settings: UsageSettingsState = {
    BaseEquipment: dto.BaseEquipment,
    IdleTimeout: dto.IdleTimeout,
    TutorName: dto.TutorName,
    UpdatedBy: dto.UpdatedBy,
    UpdatedAt: dto.UpdatedAt,
    AutoEnd: dto.AutoEnd,
    Source: dto.Source,
    UpdatedManually: dto.UpdatedManually,
    HasCharges: dto.HasCharges,
    FundingTypeId: dto.FundingTypeId,
    HasOfflineServices: dto.HasOfflineServices,
    Reservation: dto.Reservation,
    IsGuessLogOffTime: dto.IsGuessLogOffTime,
    ReservationId: dto.ReservationId,
    OngoingUsage: dto.OngoingUsage,
    PastUsage: dto.PastUsage,
    Owner: dto.BookedBy?.Id === authenticatedUser?.Id,
    budgetExperimentVisible:
      globalSettings.budgetExperimentModuleEnabled &&
      dto.BaseEquipment?.BudgetExperimentSettings,
    AllowOneAccSerOnly: dto.BaseEquipment?.AllowOneAccSerOnly ?? true,
    Billable: dto.BaseEquipment?.Billable ?? false,
    HasHideProjects: dto.BaseEquipment?.HideProjects ?? false,
    IsEquipmentTutoring: dto.BaseEquipment?.IsEquipmentTutoring ?? false,
    ForceTutoring:
      dto.BaseEquipment?.ForceTutoring === true &&
      dto.BaseEquipment !== null &&
      dto.BaseEquipment !== undefined &&
      isServiceAdmin === false,
    BudgetVisible:
      globalSettings.budgetModuleEnabled &&
      !dto.BaseEquipment?.HideProjects &&
      dto.BaseEquipment?.BudgetsTurnedOn === true &&
      (globalSettings.priceSheetPricing === true ||
        dto.BaseEquipment?.Billable === true),
    BudgetsTurnedOn: dto.BaseEquipment?.BudgetsTurnedOn,
    NotAllowReservations: dto.BaseEquipment?.NotAllowReservations ?? false,
    DefaultAccServiceId: dto.BaseEquipment?.DefaultAccServiceId,
    HasAccServices: dto.BaseEquipment?.AccServices?.length > 0,
    IsForceTutoring: dto.BaseEquipment?.ForceTutoring,
    OfflineServicesVisible:
      dto.Id > 0 && dto.BaseEquipment?.TypeId !== OnlineServiceType.Room,
    IsLoanDescType: dto.BaseEquipment?.TypeId === OnlineServiceType.LoanDesk,
    IsRoomType: dto.BaseEquipment?.TypeId === OnlineServiceType.Room,
    OfflineUsageEndServices: dto.BaseEquipment?.OfflineUsageEndServices ?? [],
    IsAllAdmin: isServiceAdmin,
    AbruptlyEnded: dto.AbruptlyEnded,
    IsEditable: dto.IsEditable,
    chargesViewAllowed:
      globalSettings.allowUsersToViewTheirCharges || chargesViewAllowed,
    HasErrors: false,
  };

  return settings;
}
