/**
 *
 * RecentSearchTermsList
 *
 */
import * as React from 'react';
import { LinearProgress, ListItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectRecentSearchTerms } from '../slice/selectors';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { RecentItem } from '../RecentItem';
import { Body, Uppercase } from 'app/components/Typography';
import {
  QuickSearchSection,
  QuickSearchSearchesItems,
  QuickSearchSectionTitle,
  QuickSearchEmptyContent,
  Div100,
} from '../styled';

export interface RecentSearchTermsListProps {
  onClick: (searchTerm?: string) => void;
}

export function RecentSearchTermsList(props: RecentSearchTermsListProps) {
  const { t } = useTranslation();
  const recentSearchTermsState = useSelector(selectRecentSearchTerms);
  const { onClick } = props;

  return (
    <>
      <ListItem key="recent-searches" disableGutters id="recent_searches">
        <QuickSearchSection>
          <QuickSearchSectionTitle>
            <Uppercase color="secondary">
              {t(translations.RecentSearches)}
            </Uppercase>
          </QuickSearchSectionTitle>
          <QuickSearchSearchesItems>
            {recentSearchTermsState.status === 'pending' ? (
              <Div100>
                <LinearProgress variant="query" color="primary" />
              </Div100>
            ) : recentSearchTermsState.status === 'rejected' ||
              (recentSearchTermsState.status === 'resolved' &&
                recentSearchTermsState.value?.length === 0) ? (
              <QuickSearchEmptyContent className="empty-search-content">
                <span className="empty-search-content-wrap">
                  <Body size="large" bold className="empty-search-content-text">
                    {t(translations.NoSearchTermFound)}
                  </Body>
                </span>
              </QuickSearchEmptyContent>
            ) : (
              <>
                {recentSearchTermsState.value?.map((item, index) => (
                  <RecentItem
                    key={index}
                    label={item}
                    searchTerm={item}
                    onClick={search => onClick(search)}
                    // link={toRootedURL(`/assets/`, { q: item })}
                  />
                ))}
              </>
            )}
          </QuickSearchSearchesItems>
        </QuickSearchSection>
      </ListItem>
    </>
  );
}
