import { IEquipmentAccServiceDto } from 'api/odata/generated/entities/IAccServiceDto';
import { ITrainingSessionDto } from 'api/odata/generated/entities/ITrainingSessionDto';
import { ReservationStatus } from 'api/odata/generated/enums/ReservationStatus';
import { RepetitiveOptions } from 'enums/RepetitiveOptions';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { dateUtils } from 'utils/date-utils';
import { isNullOrUndefined } from 'utils/typeUtils';
import {
  ReservationGlobalState,
  ReservationEquipment,
  RecurringReservationUpdateOptions,
  ReservationBudgetModel,
  CustomFormValueModel,
  OfflineservicesModel,
  ReservationRepeatModel,
  ReservationInsertModel,
} from './types';

export function ConvertModelToInsertEntity(
  state: ReservationGlobalState,
  user?: AuthenticatedUser,
) {
  const entity = {
    Id: state.details.Id,
    Services: state.details.EquipmentsData.map(item => {
      return {
        EquipmentId: item.Id,
        EquipmentName: item.Name,
        AccServiceIds:
          state.details.AccServices.length > 0
            ? state.details.AccServices.filter(
                a => (a as IEquipmentAccServiceDto).EquipmentId === item.Id,
              ).map(a => a.Id)
            : [],
        AssemblyId: null,
        ReservationId: state.details.Id,
      } as ReservationEquipment;
    }),
    StartTime: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(
        state.details.TrainingSession !== null
          ? (state.details.TrainingSession as ITrainingSessionDto).StartDate ??
              state.details.StartTime
          : state.details.StartTime,
      ),
    ),
    EndTime: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(
        state.details.TrainingSession !== null
          ? (state.details.TrainingSession as ITrainingSessionDto).EndDate ??
              state.details.EndTime
          : state.details.EndTime,
      ),
    ),
    Remarks: state.details.Remarks || null,
    SampleRunId: state.details.SampleRunId,
    BudgetId: state.details.Budget?.Id,
    BudgetExperimentId: state.details.BudgetExperiment?.Id,
    Collaborators: state.details.CollaboratedUsers.map(f => f.Id),
    ExternalCustomerId: state.details.ExternalCustomer?.Id,
    FundingType: isNullOrUndefined(state.details.FundingType)
      ? null
      : state.details.FundingType?.Id,
    InstituteProjectId: state.details.InstituteProject?.Id,
    Name: state.details.DisplayName,
    PartialAttendance: state.details.PartialAttendance,
    PartialAttendanceStart: state.details.PartialAttendanceStart || null,
    PartialAttendanceEnd: state.details.PartialAttendanceEnd || null,
    Tutoring: state.details.Tutoring,
    FullDayReservation: state.details.FullDayReservation,
    Username: state.details.BookedBy?.Id,
    StaffUser: state.details.StaffUser?.Id,
    UserGroupId: state.details.ADGroup?.Id,
    TrainingSignUp: state.details.TrainingSignUp,
    TrainingSessionId: state.details.TrainingSession?.Id,
    PurchaseOrder: state.details.PurchaseOrder,
    Reference: state.details.Reference,
    Status: (state.details.Status?.Id || 1) as ReservationStatus,
    TransferFromId: state.details.TransferFromId,
    UserRequestedByApproval: state.settings.UserRequestedByApproval,
    UserRequestedBackground: false,
    TransferredTo: state.details.TransferredTo?.Id,
    WorkflowBooking: state.details.WorkflowBooking || null,
    RecurringUpdateOption: (state.settings.RecurringUpdateOption ||
      1) as RecurringReservationUpdateOptions,
    AllStartTimes: [],
    BudgetIds:
      !!state.settings.PartReservations &&
      state.settings.PartReservations.length > 0
        ? state.settings.PartReservations.map(f => {
            return {
              id: f.BudgetID,
              text: f.BudgetName,
              ReservationId: f.Id,
            } as ReservationBudgetModel;
          })
        : [],
    TransferInProgress: false,
    WaitingTransferApproval: state.details.WaitingTransferApproval,
    InsertedAt: dateUtils.formatISO(new Date()),
    InsertedBy: user?.Id,
    UpdatedAt: dateUtils.formatISO(new Date()),
    UpdatedBy: user?.Id,
    CancelledAt: null,
    CustomFormValues: state.details.FormValues.map(f => {
      return {
        FieldId: f.FormFieldId,
        Value: f.Value,
        DisplayValue: f.DisplayValue,
      } as CustomFormValueModel;
    }),
    Parts: [],
    OfflineServices: state.offlineServices.map(f => {
      return {
        OfflineServiceTypeId: f.ServiceTypeID,
        ServiceTypeName: f.ServiceType,
        UnitsName: f.Units,
        Factor: f.DiscountFactor,
        Quantity: f.Quantity,
        Remarks: f.Remarks,
        InventoryBatchId: f.InventoryBatchId,
        BudgetId: f.BudgetID,
        BudgetExperimentId: f.BudgetExperimentId,
        UserGroup: f.UserGroup,
      } as OfflineservicesModel;
    }),
    TutorName: state.settings.TutorName,
    AssignToServiceRequestId: state.settings.AssignToServiceRequestId,
    AssignToServiceRequestRowIds: state.settings.AssignToServiceRequestRowIds,
    AssignToServiceRequestSampleIds:
      state.settings.AssignToServiceRequestSampleIds,
    Repeat:
      state?.RepetitiveOptions?.RecurringEvents?.Id &&
      state?.RepetitiveOptions?.RecurringEvents?.Id !==
        (RepetitiveOptions.DoNotRepeat as number)
        ? ({
            RepeatsEveryType: state.RepetitiveOptions?.RepeatsEveryPeriod?.Id,
            RepeatsEveryNum: state.RepetitiveOptions?.RepeatsEveryDay?.Id,
            RepeatEndTime: dateUtils.formatISO(
              dateUtils.dateOrStringToDate(
                state.RepetitiveOptions?.RecurringEndDate ??
                  dateUtils.addDays(new Date(), 7),
              ),
            ),
            RepeatOption: state.RepetitiveOptions?.RecurringEvents?.Id || 0,
            Repeat_Period: state.settings.Repeat_Period,
            RepeatsBy: state.RepetitiveOptions?.RepeatsMonthlyBy?.Id,
            RepeatsOn: state.RepetitiveOptions?.RepeatsOnDays.filter(
              d => d.checked === true,
            ).map(f => f.Id),
          } as ReservationRepeatModel)
        : null,
    Terminated: false,
    TotalCost: state.settings.TotalCost ?? 0,
    SavedForLater: state.settings.SaveForLater,
  } as ReservationInsertModel;
  if (
    !!state.settings.MandatoriesEquipments &&
    state.settings.MandatoriesEquipments.length > 0
  ) {
    entity.Services = [
      ...entity.Services,
      ...state.settings.MandatoriesEquipments.map(item => {
        return {
          EquipmentId: item.Id,
          EquipmentName: item.Name,
          AccServiceIds:
            state.details.AccServices.length > 0
              ? state.details.AccServices.filter(
                  a => (a as IEquipmentAccServiceDto).EquipmentId === item.Id,
                ).map(a => a.Id)
              : [],
        } as ReservationEquipment;
      }),
    ];
  }
  if ((state.details.OptionalsEquipments?.length ?? 0) > 0) {
    if (state.details.OptionalsEquipments.some(f => f.checked === true)) {
      entity.Services = [
        ...entity.Services,
        ...state.details.OptionalsEquipments.filter(
          f => f.checked === true,
        ).map(item => {
          return {
            EquipmentId: item.Id,
            EquipmentName: item.Name,
            AccServiceIds:
              state.details.AccServices.length > 0
                ? state.details.AccServices.filter(
                    a => (a as IEquipmentAccServiceDto).EquipmentId === item.Id,
                  ).map(a => a.Id)
                : [],
          } as ReservationEquipment;
        }),
      ];
    }
  }
  return entity;
}
