/**
 *
 * AssetQuickSearch
 *
 */
import * as React from 'react';
import { ClickAwayListener, Fade, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAssetQuickSearchSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllSearchResults,
  selectAssetQuickSearch,
  selectOpen,
  selectSearchTerm,
  selectTotalSearchCount,
} from './slice/selectors';
import { RecentAssetsList } from './RecentAssetsList';
import { RecentSearchTermsList } from './RecentSearchTermsList';
import { toQueryString, toRootedURL } from 'utils/url-utils';
import { TextControlField } from '../BasicInputs/TextControlField';
import { useRef } from 'react';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useEffect } from 'react';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import { Body } from '../Typography';
import useGlobalDOMEvents from 'app/hooks/useGlobalDomEvents';
import { BasicToggle } from '../BasicToggle';
import { AssetSearchResultsByType } from './AssetSearchResultsByType';
import { Button } from '../BasicButtons/Button';
import { AssetsSelectionProps } from 'app/pages/MyAssetsPage/AssetsSelection/AssetsSelection';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import {
  selectGlobalServiceGroupFilter,
  selectShowAllCores,
} from 'app/slice/selectors';
import { useAppSettingsSlice } from 'app/slice';
import clsx from 'clsx';
import {
  AssetQuickSearchForm,
  AssetQuickSearchPopper,
  SearchContainer,
  AssetQuickSearchPaper,
  SearchList,
  RecentList,
  QuickSearchAllInstrumentsSection,
  QuickSearchMainTitleSection,
} from './styled';
import { Tooltip } from '../BasicTooltips/Tooltip';

export interface AssetQuickSearchProps {
  showInSideBar?: boolean;
}

export const AssetQuickSearch = React.memo(function AssetQuickSearch(
  props: AssetQuickSearchProps,
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useAssetQuickSearchSlice();
  const { actions: appSettingsAction } = useAppSettingsSlice();
  const open = useSelector(selectOpen);
  const isMobile = DetectIsMobile();
  const searchAllCores = useSelector(selectShowAllCores);
  const searchTerm = useSelector(selectSearchTerm);
  const selectedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const totalResults = useSelector(selectTotalSearchCount);
  const allSearchResultsState = useSelector(selectAllSearchResults);

  const theme = useTheme();
  const handleInputChange = e => {
    if (!open) {
      dispatch(actions.setOpen(true));
    }
    dispatch(actions.searchAllAssets(e.target.value));
  };
  const state = useSelector(selectAssetQuickSearch);
  const elementRef = useRef<HTMLDivElement>(null);

  const handleClick = event => {
    dispatch(actions.setOpen(true));
  };

  const handleSearchAllCores = (value: boolean) => {
    dispatch(appSettingsAction.setShowAllCores(value));
    if (!!searchTerm && searchTerm !== '') {
      dispatch(actions.searchAllAssets(searchTerm));
    } else {
      dispatch(actions.getRecentSearchTerms());
      dispatch(actions.getRecentAssets());
    }
  };

  const handleClose = () => {
    if (open) {
      dispatch(actions.setOpen(false));
    }
  };
  const handleHideAssetQuickSearchClick = () => {
    dispatch(actions.showSearchInput(false));
  };
  const handleOpenAssets = (serviceTypeId?: number, searchTerm?: string) => {
    let selParams = {
      useSidePanel: true,
      actionType: 'AssetSearch',
      serviceTypeId: serviceTypeId,
      searchTerm: searchTerm,
      //offTypeIds: services,
    } as AssetsSelectionProps;
    if (isMobile) {
      dispatch(
        appSettingsAction.navigate(
          `/AssetSearch?${toQueryString({
            actionType: 'AssetSearch',
            serviceTypeId: serviceTypeId,
            searchTerm: searchTerm,
          })}`,
        ),
      );
    } else {
      dispatch(
        layoutActions.openSidePanel({
          type: RenderPageType.AssetsSelection,
          props: selParams,
          expanded: true,
        }),
      );
    }
  };
  // manually trigger rerender using a useless usState hook
  // this ensures that the popper is rendered only after the div is rendered and have provided the elementRef value to ancor the popper
  const countState = React.useState(0);
  useEffect(() => {
    countState[1](c => c + 1);
    // setCount (rerender) needs to occur after element elementRef.current value is bound
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current]);
  useGlobalDOMEvents({
    keyup(ev) {
      if ((ev as KeyboardEvent).key === 'Escape') {
        dispatch(actions.setOpen(false));
      }
    },
  });

  return (
    <ClickAwayListener onClickAway={() => handleClose()}>
      <AssetQuickSearchForm
        action={toRootedURL('/AssetSearch', {
          actionType: 'AssetSearch',
          searchTerm: searchTerm,
        })}
        method="get"
      >
        <SearchContainer
          className={'search-container'}
          theme={theme}
          ref={elementRef}
        >
          {isMobile && (
            <IconButton
              onClick={() => handleHideAssetQuickSearchClick()}
              title={t(translations.Close)}
            >
              <Icon icon="times" />
            </IconButton>
          )}
          <TextControlField
            name="q"
            placeholder={t(translations.SearchAnAssets)}
            onChange={e => handleInputChange(e)}
            onFocus={e => handleClick(e)}
            onClick={e => handleClick(e)}
            autoFocus={false}
            size="small"
            value={state.searchTerm || ''}
            className={
              props.showInSideBar
                ? 'asset-search-input-mobile'
                : 'asset-search-input'
            }
            autoComplete="off"
            aria-label="search"
            inputProps={{ 'aria-label': 'search' }}
            endIcon={<FontAwesomeIcon icon="search" />}
            fullWidth
          />
          {elementRef.current !== null && (
            <AssetQuickSearchPopper
              theme={theme}
              open={open}
              anchorEl={elementRef.current}
              className={clsx('asset-quick-search-popper', {
                'popper-mobile': isMobile,
              })}
              placement={'bottom-start'}
              transition
              disablePortal={!isMobile}
              modifiers={{
                keepTogether: { enabled: true },
                flip: { enabled: false },
                preventOverflow: {
                  enabled: false,
                  boundariesElement: 'viewport',
                },
                offset: {
                  offset: '-15,0',
                },
              }}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={333}>
                  <AssetQuickSearchPaper
                    className={clsx('asset-quick-search-paper', {
                      'paper-mobile': isMobile,
                    })}
                  >
                    <QuickSearchMainTitleSection className="quick-search-title">
                      <Body
                        size="large"
                        bold
                        className="quick-search-title-text"
                      >
                        {isMobile
                          ? t(translations.AssetsSearch)
                          : t(translations.InstrumentAssetSearch)}
                      </Body>
                      <Tooltip
                        title={t(translations.TryThis) as string}
                        arrow
                        placement="left"
                        disableHoverListener={true}
                        open={
                          allSearchResultsState.status === 'resolved' &&
                          totalResults === 0 &&
                          searchAllCores === false
                        }
                      >
                        <span>
                          <BasicToggle
                            label={t(translations.SearchAllCores)}
                            checked={searchAllCores}
                            onChange={(value, event) =>
                              handleSearchAllCores(value)
                            }
                          />
                        </span>
                      </Tooltip>
                    </QuickSearchMainTitleSection>
                    {state.searchTerm !== undefined &&
                    state.searchTerm?.length > 0 ? (
                      <SearchList
                        disablePadding
                        className="search-list"
                        dense={true}
                      >
                        <AssetSearchResultsByType
                          onAllAssetsClick={(serviceType, searchTerm) => {
                            handleOpenAssets(serviceType, searchTerm);
                            handleClose();
                          }}
                          selectedServiceGroups={selectedServiceGroups}
                          isMobile={isMobile}
                        />
                      </SearchList>
                    ) : (
                      <RecentList
                        className="recent-list"
                        dense={true}
                        disablePadding
                      >
                        <RecentSearchTermsList
                          onClick={searchTerm => {
                            handleOpenAssets(undefined, searchTerm);
                            handleClose();
                          }}
                        />
                        <RecentAssetsList
                          isMobile={isMobile}
                          selectedServiceGroups={selectedServiceGroups}
                        />
                        <QuickSearchAllInstrumentsSection>
                          <Button
                            variant="text"
                            onClick={() => {
                              handleOpenAssets();
                              handleClose();
                            }}
                          >
                            {t(translations.SeeAllInstruments)}
                          </Button>
                        </QuickSearchAllInstrumentsSection>
                      </RecentList>
                    )}
                  </AssetQuickSearchPaper>
                </Fade>
              )}
            </AssetQuickSearchPopper>
          )}
        </SearchContainer>
      </AssetQuickSearchForm>
    </ClickAwayListener>
  );
});
