/*CustomToolbar*/

import * as React from 'react';
import { DetectIsMobile } from 'utils/mobileDetect';
import styled from 'styled-components';

// export interface ICustomToolbar<TRow extends IRow> extends Record<string, any> {
//   rowsCount: number;
//   rows: Row<TRow>[];
// }
interface CustomToolbarProps {
  Component: React.ReactNode;
  compact?: boolean;
}
interface CustomRootProps {
  isMobile: boolean;
}

const CustomRoot = styled.div<CustomRootProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${props => (props.isMobile ? '8px 8px 16px' : '8px 32px')};
`;

function CustomToolbarFunc(props: CustomToolbarProps) {
  const { Component, compact } = props;
  const isMobile = DetectIsMobile() || compact === true;
  return <CustomRoot isMobile={isMobile}>{Component}</CustomRoot>;
}

export const CustomToolbar = React.memo(CustomToolbarFunc);
