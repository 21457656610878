import * as React from 'react';
import { IBudgetExperimentsFilterDto } from 'api/odata/generated/entities/IBudgetExperimentsFilterDto';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import {
  ReservationBudgetExperimentParams,
  ReservationBudgetExperimentPicker,
  getBudgetExperimentsValidForReservationPathInfo,
} from '../pickers/AutocompletePickers/ReservationBudgetExperimentPicker';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { usePromise } from 'app/hooks/usePromise';
import { IODataQueryResponse } from 'api/odata/IODataQueryResponse';
import { httpClient } from 'api/HttpClient';
import { IWithBudget } from 'app/pages/ReservationDetails/Details/slice/types';

/**
 * Fetches the default budget experiment matching the dependencies
 * @see {@link ReservationBudgetExperimentParams}
 * @param p relevant dependencies
 * @returns default budget experiment if exists, undefined - otherwise
 */
const getDefaultBudgetExperiment = async (
  p: ReservationBudgetExperimentParams,
) => {
  const { path, search } = getBudgetExperimentsValidForReservationPathInfo(p);
  const result = await httpClient.get<
    IODataQueryResponse<IBudgetExperimentsFilterDto>
  >(path, { ...search, ...{ $top: 10 } });
  if (result.value.length === 1) {
    return result.value[0];
  } else if (
    !!p.selectedExpirementId &&
    result.value.length > 0 &&
    result.value.some(f => f.Id === p.selectedExpirementId)
  ) {
    return result.value.filter(f => f.Id === p.selectedExpirementId)[0];
  } else {
    return undefined;
  }
};

export const FormReservationBudgetExperimentPicker = ({
  label,
  isEdit,
  serviceId,
  budgetId,
  userId,
  start,
  end,
  onChange,
  ...props
}: FieldHookConfig<IBudgetExperimentsFilterDto | null> &
  IFieldProps &
  ReservationBudgetExperimentParams & { isEdit: boolean }) => {
  const formik = useFormikContext<IWithBudget>();
  const [field, meta, { setValue }] =
    useField<IBudgetExperimentsFilterDto | null>(props);
  const [fetchDefaultBudgetExperimentState, fetchDefaultBudgetExperiment] =
    usePromise(getDefaultBudgetExperiment);

  // setValue goes nuts inside the Reservation Form and spams if used in useEffect directly
  const setValueRef = React.useRef(setValue);
  React.useEffect(() => {
    setValueRef.current = setValue;
  }, [setValue]);

  /**
   * Handles the user selected budget experiment.
   * The budget can be auto populated when the budget experiment is selected first.
   * Formik values - budget experiment & optionally budget (if not yet selected) are updated in one go to prevent unnecessary re renders.
   */
  const handleChange = React.useCallback(
    value => {
      const v: Record<string, any> = {
        BudgetExperiment: value,
      };
      if (formik.values.Budget === null && value.Budget !== undefined) {
        v.Budget = value.Budget;
      }
      formik.setValues(values => ({ ...values, ...v }));

      onChange?.(value);
    },
    [formik, onChange],
  );

  /**
   * Services array might go wild with array mutations (e.g. when clearing large number of services selected in the reservation service picker)
   * Serialize & then deserializing the service ids is intended to guard the useEffect below from drowning in these mutations
   */
  const serializedServices = React.useMemo(
    () => JSON.stringify(serviceId.map(f => ({ Id: f.Id }))),
    [serviceId],
  );
  const deserializedServices = React.useMemo(
    () => JSON.parse(serializedServices),
    [serializedServices],
  );

  /**
   * Ref is used to skip the first effect below
   */
  const componentDidMount = React.useRef(false);

  // set default budget experiment for the given parent deps (service, budget, start/end, service & user)
  // budget experiment is considered to be default if it's the only matching one between relevant active experiments (given the deps)
  // if there's no default budget experiment - the value is cleared to prevent conflicts and force user to select one
  React.useEffect(() => {
    // first effect needs to be skipped for showing reservation details, in which case the budget/experiment should be shown as is without fetching the default budget experiment
    // here an additional condition might be needed to distinguish between the "reservation create" and "reservation details" cases
    if (componentDidMount.current === false && isEdit) {
      componentDidMount.current = true;
      return;
    }

    /**
     * Skips default budget experiment fetching if:
     * * budget experiment (either default or non-default) has been selected by the user with the empty budget
     * * budget/budget experiment changed but their connection is valid (e.g. budget is present, budget experiment is changed to another experiment within the same budget)
     */
    if (
      field.value?.BudgetTaskBudgetId === budgetId ||
      field.value?.Budget?.Id === budgetId
    ) {
      return;
    }

    // nothing to do if the services weren't selected yet
    if (deserializedServices.length === 0) {
      return;
    }

    // budget has to be selected before the budget experiment
    // if the budget was not yet populated - also nothing to see here
    if (budgetId === null) {
      return;
    }

    /**
     * BudgetId ->
     */
    fetchDefaultBudgetExperiment({
      budgetId: budgetId,
      serviceId: deserializedServices,
      start: start,
      end: end,
      userId: userId,
      selectedExpirementId: field.value?.Id,
    }).then(value => {
      setValueRef.current?.(value ?? null);
      if (!!value) {
        onChange?.(value);
      }
    });

    // field.value is exluded from the deps in order to allow clearing the current value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchDefaultBudgetExperiment,
    budgetId,
    end,
    deserializedServices,
    start,
    userId,
    onChange,
    isEdit,
    // field.value
  ]);

  return (
    <ReservationBudgetExperimentPicker
      predicates={props.predicates}
      name={props.name}
      placeholder={props.placeholder}
      label={label}
      onChange={handleChange}
      onBlur={field?.onBlur}
      value={field.value ?? undefined}
      error={meta?.error !== undefined}
      variant="filled"
      disabled={
        fetchDefaultBudgetExperimentState.status === 'pending' || props.disabled
      }
      helperText={meta.error}
      fullWidth={props.fullWidth}
      info={props.info}
      serviceId={serviceId}
      budgetId={budgetId}
      userId={userId}
      start={start}
      end={end}
    />
  );
};
