import {
  ConvertModelToEntityCostCentersDetails,
  CostCentersDetailsQStringParameters,
  CostCentersDetailsState,
  CostCentersState,
} from './types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { ICostCentersDto } from 'api/odata/generated/entities/ICostCentersDto';
import { costCentersDetailsSaga } from './saga';

export const initialState: CostCentersState = {
  processing: false,
  createState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
  updateState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
};

const slice = createSlice({
  name: 'costCentersDetail',
  initialState,
  reducers: {
    initCostCentersDetails(
      state,
      action: PayloadAction<CostCentersDetailsQStringParameters>,
    ) {
      state.processing = true;
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initUpdateCostCentersDetails(
      state,
      action: PayloadAction<CostCentersDetailsQStringParameters>,
    ) {
      state.processing = true;
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initCostCentersDetails_Success(
      state,
      action: PayloadAction<ICostCentersDto>,
    ) {
      state.processing = false;
      state.createState.data = ConvertModelToEntityCostCentersDetails(
        action.payload,
      );
    },
    initUpdateCostCentersDetails_Success(
      state,
      action: PayloadAction<ICostCentersDto>,
    ) {
      state.processing = false;
      state.updateState.data = ConvertModelToEntityCostCentersDetails(
        action.payload,
      );
    },
    initCostCentersDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    initUpdateCostCentersDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    //====================================================================
    resetUpdateCostCentersDetailsState(state, action: PayloadAction) {
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    resetCreateCostCentersDetailsState(state, action: PayloadAction) {
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    //====================================================================
    updateCostCentersDetails(
      state,
      action: PayloadAction<{
        current: CostCentersDetailsState;
        original: CostCentersDetailsState | undefined;
      }>,
    ) {
      state.updateState.processing = true;
      state.updateState.completed = undefined;
      state.updateState.hasErrors = undefined;
    },
    updateCostCentersDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.updateState.processing = false;
      state.updateState.hasErrors = action.payload.hasErrors;
      state.updateState.completed = true;
    },
    updateCostCentersDetails_Error(state, action: PayloadAction<any>) {
      state.updateState.processing = false;
      state.updateState.completed = true;
      state.updateState.hasErrors = true;
    },
    //====================================================================
    createCostCentersDetails(
      state,
      action: PayloadAction<CostCentersDetailsState>,
    ) {
      state.createState.processing = true;
      state.createState.completed = undefined;
      state.createState.hasErrors = undefined;
    },
    createCostCentersDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.createState.processing = false;
      state.createState.hasErrors = action.payload.hasErrors;
      state.createState.completed = true;
    },
    createCostCentersDetails_Error(state, action: PayloadAction<any>) {
      state.createState.processing = false;
      state.createState.completed = true;
      state.createState.hasErrors = true;
    },
  },
});

export const { actions: costCentersDetailsActions } = slice;

export const useCostCentersDetailsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: costCentersDetailsSaga });
  return { actions: slice.actions };
};
