import { Link } from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Roles } from 'api/odata/generated/enums/Roles';
import { TFunctionResult } from 'i18next';
import { translations } from 'locales/translations';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ChargeProps } from './index';

export const AddChargeButton = ({
  children,
  tooltip,
  ...props
}: ChargeProps & {
  children?: ReactNode;
  tooltip?: React.ReactNode | TFunctionResult;
}) => {
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const { t } = useTranslation();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const singleAdminServiceGroup =
    authenticatedUser?.Roles?.includes(Roles.ServiceGroupBudgetsAdmin) &&
    authenticatedUser.AdminServiceGroups.length === 1
      ? authenticatedUser.AdminServiceGroups[0]
      : null;
  props.serviceGroup = props.serviceGroup ?? singleAdminServiceGroup ?? null;
  const chargesProps: ChargeProps = React.useMemo(() => {
    return {
      budget: props.budget,
      serviceGroup: props.serviceGroup,
      invoiceId: props.invoiceId,
      rowId: props.rowId,
      serviceGroupPredicate: props.serviceGroupPredicate,
      closeCover: props.closeCover,
      coverClosed: props.coverClosed,
      isCover: props.isCover,
    };
  }, [
    props.budget,
    props.closeCover,
    props.coverClosed,
    props.invoiceId,
    props.isCover,
    props.rowId,
    props.serviceGroup,
    props.serviceGroupPredicate,
  ]);

  return (
    <Tooltip title={tooltip ?? t(translations.AddCharge)} arrow>
      <Link
        onClick={() => {
          dispatch(actions.toggleSidePanel(true));
          dispatch(actions.setPageType(RenderPageType.AddCharge));
          dispatch(actions.setPageProps(chargesProps));
        }}
      >
        {children ?? t(translations.AddManualInvoiceLine)}
      </Link>
    </Tooltip>
  );
};
