import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.assetInventory || initialState;

export const selectAssetInventory = createSelector(
  [selectSlice],
  state => state,
);

export const selectRoomSelected = createSelector(
  [selectAssetInventory],
  state => state.selectedRoomState,
);
export const selectLocationSelected = createSelector(
  [selectAssetInventory],
  state => state.selectedLocationState,
);
export const selectSubLocationSelected = createSelector(
  [selectAssetInventory],
  state => state.selectedSubLocationState,
);
export const selectScanProcessing = createSelector(
  [selectAssetInventory],
  state => state.scanProcessing,
);
export const selectNoRoomAccessProcessing = createSelector(
  [selectAssetInventory],
  state => state.noRoomAccessProcessing,
);
export const selectInventoryLogFilters = createSelector(
  [selectAssetInventory],
  state => state.logFilters,
);
