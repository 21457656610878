import * as React from 'react';
import { getLogger } from 'utils/logLevel';
import { usePrevious } from './usePrevious';

/**
 * Debug depenency changes onf React.useEffect.
 * Tracks the dependencies and helps to find why the useEffect fired.
 * To use, repalce the React.useEffect with useDebugEffect and optionally provide dependency names for readability
 * * Example:
 * ```ts
 * React.useEffect(()=> {
 *  ...
 * }, [var1, var2])
 * ```
 *
 * * ```ts
 * useDebugEffect(()=> {
 *  ...
 * }, [var1, var2], ['var1', 'var2'])
 * ```
 *
 * @param effectHook Effect Hook
 * @param dependencies depndencies
 * @param dependencyNames dependency variable names
 */
export const useDebugEffect = (
  effect: React.EffectCallback,
  deps?: React.DependencyList,
  dependencyNames: string[] = [],
) => {
  // keep track of previous values
  const previousDeps = usePrevious(deps);

  if (deps !== undefined) {
    // find change values using shallow comparison
    const changedDeps = compareDeps(deps, previousDeps, dependencyNames);
    if (Object.keys(changedDeps).length) {
      log.debug(changedDeps);
    }
  }

  // otherwise eslint complains on "React Hook useEffect has a missing dependency: 'effectHook'. Either include it or remove the dependency array.eslintreact-hooks/exhaustive-deps"
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(effect, deps);
};

export function compareDeps(
  deps: React.DependencyList,
  previousDeps: React.DependencyList | undefined,
  dependencyNames: string[],
) {
  const changedDeps = deps.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps?.[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps?.[index],
          after: dependency,
        },
      };
    }
    return accum;
  }, {});
  return changedDeps;
}

const log = getLogger('useDebugEffect');
