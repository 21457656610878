import { httpClient } from 'api/HttpClient';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import {
  BaseURLPicker,
  BaseURLPickerProps,
} from 'app/components/BasicPickers/BaseURLPicker';
import { Entity } from 'types/common';
const url = '/api/odata/v4/OfflineServiceFilter';
const filter = 'ServiceTypeId eq 3 and Active eq true';
export interface SampleTypePickerProps
  extends Omit<BaseURLPickerProps<Entity<number>>, 'url'> {}

export const initSampleTypeData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: new ODataFilterBuilder<Entity<number>>({
        predicates: [filter, new Condition('Id', ODataOperators.Equals, id)],
        stringColumns: [],
      }).toString(),
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};

export const SampleTypePicker = (props: SampleTypePickerProps) => {
  const { queryfilter, ...other } = props;
  const predicate =
    queryfilter && queryfilter !== ''
      ? [queryfilter, filter].join(' and ')
      : filter;
  return (
    <BaseURLPicker
      url={url}
      queryfilter={predicate}
      {...other}
      id={props.id || 'sampletypeId'}
    />
  );
};
