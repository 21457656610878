import { PayloadAction } from '@reduxjs/toolkit';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { PricingState } from 'app/pages/ReservationDetails/Details/slice/types';
import {
  ConnectedFiltersInit,
  IConnectedFiltersDto,
} from 'types/IConnectedFiltersDto';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { offlineServiceStateSaga } from './saga';
import {
  ConvertConsumablesToRenewalModel,
  ConvertConsumablesToModel,
  ConvertDtoToConnectedModel,
  ConvertDtoToModel,
  OfflineServiceQueryStringParameters,
  OfflineServiceState,
  OfflineServiceTypeChangeResult,
  OtherServiceDetailsState,
  ReportConsumablesState,
  ServiceChangeStateParameters,
  StockRenewalState,
  StockRenewalParams,
  RenewStockState,
  RenewStockParams,
  ConvertRenewStockToModel,
  InventoryBatchState,
  InventoryBatchParams,
  ConvertInventoryBatchToModel,
  ReportedFrom,
  ConsumableMilestoneData,
} from './types';

export const initialState: OfflineServiceState = {
  detailsState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
    error: undefined,
  },
  processing: false,
  consumablesProcessing: undefined,
  valueHandler: undefined,
  connectedDataState: ConnectedFiltersInit(),
  serviceChangeResultState: undefined,
  creditState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
    error: undefined,
  },
  hasCharges: undefined,
  savedReservationService: undefined,
  consumablesState: {
    data: {
      Services: [],
    },
    completed: undefined,
    dataLength: undefined,
    error: undefined,
    hasErrors: undefined,
    processing: undefined,
  },
  consumablesValueHandler: undefined,
  selectedServiceTypes: [],
  editCreatableService: undefined,
  barcodeScanned: undefined,
  refeshOfflineServices: undefined,
  stockRenewalState: {
    data: undefined,
    completed: undefined,
    dataLength: undefined,
    error: undefined,
    hasErrors: undefined,
    processing: undefined,
  },
  stockRenewalValueHandler: undefined,
  renewStockState: {
    data: undefined,
    completed: undefined,
    dataLength: undefined,
    error: undefined,
    hasErrors: undefined,
    processing: undefined,
  },
  renewStockValueHandler: undefined,
  inventoryBatchState: {
    data: undefined,
    completed: undefined,
    dataLength: undefined,
    error: undefined,
    hasErrors: undefined,
    processing: undefined,
  },
  inventoryBatchValueHandler: undefined,
  isEdit: undefined,
  batchIsEdit: undefined,
  inventoryBatchEditable: undefined,
  getCreatableService: undefined,
};
const slice = createSlice({
  name: 'offlineServiceState',
  initialState,
  reducers: {
    initDetails(
      state,
      action: PayloadAction<{
        query: OfflineServiceQueryStringParameters;
        globalSettings: GlobalSettingsType;
        service?: IOtherServices;
        editCreatable?: boolean;
      }>,
    ) {
      state.processing = true;
      state.detailsState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
      state.valueHandler = undefined;
      if (!action.payload.editCreatable) {
        state.connectedDataState = ConnectedFiltersInit();
      }
      state.serviceChangeResultState = undefined;
    },
    initDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
        hasCharges: boolean;
        data: IOtherServices;
        isEdit: boolean;
        serviceType: IOfflineServiceFilterDto | null;
      }>,
    ) {
      state.processing = false;
      state.detailsState.data = ConvertDtoToModel(
        action.payload.data,
        action.payload.serviceType,
      );
      state.connectedDataState = ConvertDtoToConnectedModel(
        action.payload.data,
      );
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.hasCharges = action.payload.hasCharges;
      state.isEdit = action.payload.isEdit;
    },
    initDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    resetDetailsState(state, action: PayloadAction<boolean | undefined>) {
      state.detailsState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
      state.processing = false;
      state.valueHandler = undefined;
      if (action.payload !== true) {
        state.connectedDataState = ConnectedFiltersInit();
        state.serviceChangeResultState = undefined;
      }
      state.isEdit = undefined;
    },
    createService(
      state,
      action: PayloadAction<{
        model: OtherServiceDetailsState;
        saveCreatable?: boolean;
      }>,
    ) {
      state.detailsState.processing = true;
      state.detailsState.completed = undefined;
      state.detailsState.hasErrors = undefined;
    },
    createService_Success(
      state,
      action: PayloadAction<{ hasErrors: boolean }>,
    ) {
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.detailsState.completed = true;
    },
    createService_Error(state, action: PayloadAction<any>) {
      state.detailsState.processing = false;
      state.detailsState.completed = true;
      state.detailsState.hasErrors = true;
    },
    updateService(
      state,
      action: PayloadAction<{
        current: OtherServiceDetailsState;
        original: OtherServiceDetailsState;
        saveCreatable?: boolean;
      }>,
    ) {
      state.detailsState.processing = true;
      state.detailsState.completed = undefined;
      state.detailsState.hasErrors = undefined;
    },
    updateService_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.detailsState.completed = true;
    },
    updateService_Error(state, action: PayloadAction<any>) {
      state.detailsState.processing = false;
      state.detailsState.completed = true;
      state.detailsState.hasErrors = true;
    },
    setAnyValue(
      state,
      action: PayloadAction<{
        fieldKey: keyof OtherServiceDetailsState;
        fieldValue: any;
      }>,
    ) {},
    setAnyValueSuccess(
      state,
      action: PayloadAction<{
        fieldKey: keyof OtherServiceDetailsState;
        fieldValue: any;
      }>,
    ) {
      state.valueHandler = {
        run: true,
        fieldKey: action.payload.fieldKey,
        fieldValue: action.payload.fieldValue,
        shouldValidate: true,
      };
    },
    resetValueHandler(state, action: PayloadAction) {
      state.valueHandler = undefined;
    },
    initServiceChangeStateData(
      state,
      action: PayloadAction<ServiceChangeStateParameters>,
    ) {
      state.serviceChangeStateLoading = true;
    },
    initServiceChangeStateData_Success(
      state,
      action: PayloadAction<OfflineServiceTypeChangeResult>,
    ) {
      state.serviceChangeResultState = action.payload;
      state.serviceChangeStateLoading = false;
    },
    initServiceChangeStateData_Error(state, action: PayloadAction) {
      state.serviceChangeResultState = undefined;
      state.serviceChangeStateLoading = false;
    },
    resetServiceChangeState(state, action: PayloadAction) {
      state.serviceChangeResultState = undefined;
      state.serviceChangeStateLoading = undefined;
    },
    getCredit(state, action: PayloadAction<OtherServiceDetailsState>) {
      state.creditState.processing = true;
      state.creditState.completed = undefined;
      state.creditState.hasErrors = undefined;
    },
    getCredit_Success(state, action: PayloadAction<PricingState>) {
      state.creditState.processing = false;
      state.creditState.completed = true;
      state.creditState.data = action.payload;
    },
    getCredit_Error(state, action: PayloadAction<any>) {
      state.creditState.processing = false;
      state.creditState.completed = true;
      state.creditState.data = undefined;
    },
    resetCreditState(state, action: PayloadAction) {
      state.creditState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    getTotalCredit(state, action: PayloadAction<IOtherServices[]>) {
      state.creditState.processing = true;
      state.creditState.completed = undefined;
      state.creditState.hasErrors = undefined;
    },
    getTotalCredit_Success(state, action: PayloadAction<PricingState>) {
      state.creditState.processing = false;
      state.creditState.completed = true;
      state.creditState.data = action.payload;
    },
    getTotalCredit_Error(state, action: PayloadAction<any>) {
      state.creditState.processing = false;
      state.creditState.completed = true;
      state.creditState.data = undefined;
    },
    resetTotalCreditState(state, action: PayloadAction) {
      state.creditState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    setReservationService(
      state,
      action: PayloadAction<IOtherServices | undefined>,
    ) {
      state.savedReservationService = action.payload;
    },
    setConsumableValue(
      state,
      action: PayloadAction<{
        fieldKey: keyof ReportConsumablesState;
        fieldValue: any;
      }>,
    ) {},
    setConsumableValueSuccess(
      state,
      action: PayloadAction<{
        fieldKey: keyof ReportConsumablesState;
        fieldValue: any;
      }>,
    ) {
      state.consumablesValueHandler = {
        run: true,
        fieldKey: action.payload.fieldKey,
        fieldValue: action.payload.fieldValue,
        shouldValidate: true,
      };
    },
    resetConsumableValueHandler(state, action: PayloadAction) {
      state.consumablesValueHandler = undefined;
    },
    initConsumables(
      state,
      action: PayloadAction<{
        query: OfflineServiceQueryStringParameters;
        globalSettings: GlobalSettingsType;
        services?: IOtherServices[];
        reportFrom?: ReportedFrom;
      }>,
    ) {
      state.consumablesProcessing = true;
      state.consumablesState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
      state.consumablesValueHandler = undefined;
    },
    initConsumables_Success(
      state,
      action: PayloadAction<{
        data: IOtherServices[];
        connectedData: IConnectedFiltersDto;
      }>,
    ) {
      state.consumablesProcessing = false;
      state.consumablesState.data = ConvertConsumablesToModel(
        action.payload.data,
      );
      state.connectedDataState = action.payload.connectedData;
    },
    initConsumables_Error(state, action: PayloadAction<any>) {
      state.consumablesProcessing = false;
    },
    extendConsumablesData(state, action: PayloadAction<IOtherServices[]>) {
      state.consumablesState.data = { Services: action.payload };
    },
    createConsumables(
      state,
      action: PayloadAction<{
        Services: IOtherServices[];
        DiscountFactor?: number | null;
        Remarks?: string | null;
        reportFrom?: ReportedFrom;
        milestoneData?: ConsumableMilestoneData;
      }>,
    ) {
      state.consumablesState.processing = true;
      state.consumablesState.completed = undefined;
      state.consumablesState.hasErrors = undefined;
    },
    createConsumables_Success(
      state,
      action: PayloadAction<{ hasErrors: boolean }>,
    ) {
      state.consumablesState.processing = false;
      state.consumablesState.hasErrors = action.payload.hasErrors;
      state.consumablesState.completed = true;
    },
    createConsumables_Error(state, action: PayloadAction<any>) {
      state.consumablesState.processing = false;
      state.consumablesState.completed = true;
      state.consumablesState.hasErrors = true;
    },
    resetConsumablesState(state, action: PayloadAction) {
      state.consumablesState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
        error: undefined,
        dataLength: undefined,
      };
      state.consumablesProcessing = false;
      state.consumablesValueHandler = undefined;
      state.connectedDataState = ConnectedFiltersInit();
      state.serviceChangeResultState = undefined;
      state.selectedServiceTypes = [];
      state.barcodeScanned = undefined;
      state.refeshOfflineServices = undefined;
    },
    resetSelectedServiceType(state, action: PayloadAction) {
      state.selectedServiceTypes = [];
    },
    setSelectedServiceType(state, action: PayloadAction<Array<number>>) {
      state.selectedServiceTypes = action.payload;
    },
    setEditCreatableService(
      state,
      action: PayloadAction<IOtherServices | undefined>,
    ) {
      state.editCreatableService = action.payload;
    },
    getCreatableService(
      state,
      action: PayloadAction<IOtherServices | undefined>,
    ) {
      state.getCreatableService = action.payload;
    },
    setBarcodeScanned(state, action: PayloadAction<boolean | undefined>) {},
    setBarcodeScanned_Success(
      state,
      action: PayloadAction<boolean | undefined>,
    ) {
      state.barcodeScanned = action.payload;
    },
    setRefreshConsumableOfflineServices(
      state,
      action: PayloadAction<boolean | undefined>,
    ) {
      state.refeshOfflineServices = action.payload;
    },

    setStockRenewalValue(
      state,
      action: PayloadAction<{
        fieldKey: keyof StockRenewalState;
        fieldValue: any;
      }>,
    ) {},
    setStockRenewalValueSuccess(
      state,
      action: PayloadAction<{
        fieldKey: keyof StockRenewalState;
        fieldValue: any;
      }>,
    ) {
      state.stockRenewalValueHandler = {
        run: true,
        fieldKey: action.payload.fieldKey,
        fieldValue: action.payload.fieldValue,
        shouldValidate: true,
      };
    },
    resetStockRenewalValueHandler(state, action: PayloadAction) {
      state.stockRenewalValueHandler = undefined;
    },
    initStockRenewal(
      state,
      action: PayloadAction<{
        query: StockRenewalParams;
        services?: IOtherServices[];
      }>,
    ) {
      state.stockRenewalState = {
        data: undefined,
        processing: true,
        completed: undefined,
        hasErrors: undefined,
      };
      state.stockRenewalValueHandler = undefined;
    },
    initStockRenewal_Success(
      state,
      action: PayloadAction<{
        data: IOtherServices[];
        date: Date | string;
      }>,
    ) {
      state.stockRenewalState.processing = false;
      state.stockRenewalState.data = ConvertConsumablesToRenewalModel(
        action.payload.data,
        action.payload.date,
      );
    },
    initStockRenewal_Error(state, action: PayloadAction<any>) {
      state.stockRenewalState.processing = false;
    },
    createStockRenewals(state, action: PayloadAction<StockRenewalState>) {
      state.stockRenewalState.processing = true;
      state.stockRenewalState.completed = undefined;
      state.stockRenewalState.hasErrors = undefined;
    },
    createStockRenewals_Success(
      state,
      action: PayloadAction<{ hasErrors: boolean }>,
    ) {
      state.stockRenewalState.processing = false;
      state.stockRenewalState.hasErrors = action.payload.hasErrors;
      state.stockRenewalState.completed = true;
    },
    createStockRenewals_Error(state, action: PayloadAction<any>) {
      state.stockRenewalState.processing = false;
      state.stockRenewalState.completed = true;
      state.stockRenewalState.hasErrors = true;
    },
    resetStockRenewalState(state, action: PayloadAction) {
      state.stockRenewalState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
        error: undefined,
        dataLength: undefined,
      };
      state.processing = false;
      state.stockRenewalValueHandler = undefined;
      state.selectedServiceTypes = [];
      state.barcodeScanned = undefined;
      state.refeshOfflineServices = undefined;
    },

    setRenewStockValue(
      state,
      action: PayloadAction<{
        fieldKey: keyof RenewStockState;
        fieldValue: any;
      }>,
    ) {},
    setRenewStockValueSuccess(
      state,
      action: PayloadAction<{
        fieldKey: keyof RenewStockState;
        fieldValue: any;
      }>,
    ) {
      state.renewStockValueHandler = {
        run: true,
        fieldKey: action.payload.fieldKey,
        fieldValue: action.payload.fieldValue,
        shouldValidate: true,
      };
    },
    resetRenewStockValueHandler(state, action: PayloadAction) {
      state.renewStockValueHandler = undefined;
    },
    initRenewStock(
      state,
      action: PayloadAction<{
        query: RenewStockParams;
        service?: IOtherServices;
      }>,
    ) {
      state.renewStockState = {
        data: undefined,
        processing: true,
        completed: undefined,
        hasErrors: undefined,
        error: undefined,
      };
      state.renewStockValueHandler = undefined;
    },
    initRenewStock_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
        data: IOtherServices;
        isEdit: boolean;
        serviceType: IOfflineServiceFilterDto | null;
      }>,
    ) {
      state.renewStockState.processing = false;
      state.renewStockState.data = ConvertRenewStockToModel(
        action.payload.data,
        action.payload.serviceType,
      );
      state.isEdit = action.payload.isEdit;
    },
    initRenewStock_Error(state, action: PayloadAction<any>) {
      state.renewStockState.processing = false;
    },
    createRenewStock(
      state,
      action: PayloadAction<{
        model: RenewStockState;
        editCreatable: boolean;
        preventRefreshTable?: boolean;
        autoGenerateBatch?: boolean;
        saveCreatable?: boolean;
      }>,
    ) {
      state.renewStockState.processing = true;
      state.renewStockState.completed = undefined;
      state.renewStockState.hasErrors = undefined;
    },
    createRenewStock_Success(
      state,
      action: PayloadAction<{ hasErrors: boolean }>,
    ) {
      state.renewStockState.processing = false;
      state.renewStockState.hasErrors = action.payload.hasErrors;
      state.renewStockState.completed = true;
    },
    createRenewStock_Error(state, action: PayloadAction<any>) {
      state.renewStockState.processing = false;
      state.renewStockState.completed = true;
      state.renewStockState.hasErrors = true;
    },
    updateRenewStock(
      state,
      action: PayloadAction<{
        current: OtherServiceDetailsState;
        original: OtherServiceDetailsState;
        editCreatable: boolean;
        autoGenerateBatch?: boolean;
      }>,
    ) {
      state.renewStockState.processing = true;
      state.renewStockState.completed = undefined;
      state.renewStockState.hasErrors = undefined;
    },
    updateRenewStock_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.renewStockState.processing = false;
      state.renewStockState.hasErrors = action.payload.hasErrors;
      state.renewStockState.completed = true;
    },
    updateRenewStock_Error(state, action: PayloadAction<any>) {
      state.renewStockState.processing = false;
      state.renewStockState.completed = true;
      state.renewStockState.hasErrors = true;
    },
    resetRenewStockState(state, action: PayloadAction) {
      state.renewStockState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
        error: undefined,
        dataLength: undefined,
      };
      state.processing = false;
      state.renewStockValueHandler = undefined;
      state.isEdit = undefined;
    },
    setInventoryBatchValue(
      state,
      action: PayloadAction<{
        fieldKey: keyof InventoryBatchState;
        fieldValue: any;
      }>,
    ) {},
    setInventoryBatchValueSuccess(
      state,
      action: PayloadAction<{
        fieldKey: keyof InventoryBatchState;
        fieldValue: any;
      }>,
    ) {
      state.inventoryBatchValueHandler = {
        run: true,
        fieldKey: action.payload.fieldKey,
        fieldValue: action.payload.fieldValue,
        shouldValidate: true,
      };
    },
    resetInventoryBatchValueHandler(state, action: PayloadAction) {
      state.inventoryBatchValueHandler = undefined;
    },
    initInventoryBatch(
      state,
      action: PayloadAction<{
        query: InventoryBatchParams;
        batch?: IInventoryBatchDto;
      }>,
    ) {
      state.inventoryBatchState = {
        data: undefined,
        processing: true,
        completed: undefined,
        hasErrors: undefined,
        error: undefined,
      };
      state.inventoryBatchValueHandler = undefined;
    },
    initInventoryBatch_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
        data: IInventoryBatchDto;
        isEdit: boolean;
        serviceType: IOfflineServiceFilterDto | null;
        newDate: Date | string;
      }>,
    ) {
      state.inventoryBatchState.processing = false;
      state.inventoryBatchState.data = ConvertInventoryBatchToModel(
        action.payload.data,
        action.payload.serviceType,
        action.payload.newDate,
      );
      state.batchIsEdit = action.payload.isEdit;
    },
    initInventoryBatch_Error(state, action: PayloadAction<any>) {
      state.inventoryBatchState.processing = false;
    },
    createInventoryBatch(
      state,
      action: PayloadAction<{
        model: InventoryBatchState;
        saveCreatable?: boolean;
        preventRefreshTable?: boolean;
      }>,
    ) {
      state.inventoryBatchState.processing = true;
      state.inventoryBatchState.completed = undefined;
      state.inventoryBatchState.hasErrors = undefined;
    },
    createInventoryBatch_Success(
      state,
      action: PayloadAction<{ hasErrors: boolean }>,
    ) {
      state.inventoryBatchState.processing = false;
      state.inventoryBatchState.hasErrors = action.payload.hasErrors;
      state.inventoryBatchState.completed = true;
    },
    createInventoryBatch_Error(state, action: PayloadAction<any>) {
      state.inventoryBatchState.processing = false;
      state.inventoryBatchState.completed = true;
      state.inventoryBatchState.hasErrors = true;
    },
    updateInventoryBatch(
      state,
      action: PayloadAction<{
        current: InventoryBatchState;
        original: InventoryBatchState;
        saveCreatable?: boolean;
        preventRefreshTable?: boolean;
      }>,
    ) {
      state.inventoryBatchState.processing = true;
      state.inventoryBatchState.completed = undefined;
      state.inventoryBatchState.hasErrors = undefined;
    },
    updateInventoryBatch_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.inventoryBatchState.processing = false;
      state.inventoryBatchState.hasErrors = action.payload.hasErrors;
      state.inventoryBatchState.completed = true;
    },
    updateInventoryBatch_Error(state, action: PayloadAction<any>) {
      state.inventoryBatchState.processing = false;
      state.inventoryBatchState.completed = true;
      state.inventoryBatchState.hasErrors = true;
    },
    resetInventoryBatchState(state, action: PayloadAction) {
      state.inventoryBatchState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
        error: undefined,
        dataLength: undefined,
      };
      state.processing = false;
      state.inventoryBatchValueHandler = undefined;
      state.batchIsEdit = undefined;
    },
    setInventoryBatchEditable(
      state,
      action: PayloadAction<IInventoryBatchDto | undefined>,
    ) {
      state.inventoryBatchEditable = action.payload;
    },
  },
});

export const { actions: offlineServiceStateActions } = slice;

export const useOfflineServiceStateSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: offlineServiceStateSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useOfflineServiceStateSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
