import { LocationDetailsProps } from 'app/pages/LocationsPage/Details';
import { AssetPopUpProps } from 'app/pages/AssetPopUp';
import { QuizDetailsProps } from 'app/pages/QuizDetailsPage/QuizDetails';
import { StartQuizProps } from 'app/pages/QuizPage/StartQuiz';
import { TrainingDetailsProps } from 'app/pages/UserTrainings/TrainingDetailsPage/Details';
import { WorkOrderDetailsProps } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import { PaymentProps } from 'app/pages/AddPaymentPage';
import { ChargeProps } from 'app/pages/AddChargePage';
import { BillingActionsDetailsProps } from 'app/pages/BillingActions/BillingActionsDetailsPage/Details';
import { SavedViewDetailsProps } from 'app/pages/SavedViewsPage/SavedViewPage/Details';
import { InstrumentRestrictionsProps } from 'app/pages/InstrumentRestrictionsPage/Details/InstrumentRestrictions';
import { ReservationDetailsProps } from 'app/pages/ReservationDetails/Details';
import { InvoiceTemplatePopUpProps } from 'app/pages/InvoiceTemplates/InvoiceTemplatePopUp';
import { OtherServiceDetailsProps } from 'app/pages/OtherServiceDetails/Details';
import { PricePeriodDetailsProps } from 'app/pages/PriceSheetPeriodDetails/Details';
import { CoreSelectionProps } from 'app/pages/CoreSelectionPage/CoreSelection';
import { AssetsSelectionProps } from 'app/pages/MyAssetsPage/AssetsSelection/AssetsSelection';
import { UserAttributesDetailsProps } from 'app/pages/UserAttributes/UserAttributesDetailsPage/Details';
import { ServiceRequestDetailsProps } from 'app/pages/Samples/RequestSamplesPage';
import { CalendarMessagesDetailsProps } from 'app/pages/CalendarMessages/CalendarMessagesDetails/Details';
import { ReportConsumablesProps } from 'app/pages/OtherServiceDetails/ReportConsumables';
import { ServiceRequestTicketDetailsProps } from 'app/pages/ServiceRequestTicketDetailsPage/ServiceRequestTicketDetails';
import { ServiceRequestMilestoneDetailsProps } from 'app/pages/Samples/components/ServiceRequestMilestoneDetails';
import { ServiceRequestMilestoneChargeDetailsProps } from 'app/pages/Samples/components/ServiceRequestMilestoneChargeDetails';
import { StockRenewalProps } from 'app/pages/OtherServiceDetails/StockRenewal';
import { RenewStockProps } from 'app/pages/OtherServiceDetails/RenewStock';
import { InventoryBatchProps } from 'app/pages/OtherServiceDetails/InventoryBatch';
import { InvoiceBatchesCommentsProps } from 'app/pages/InvoiceBatches/InvoiceBatchesComments';
import { InventoryLogProps } from 'app/pages/AssetsInventoryLog/InventoryLog';
import { IPathAndQuery } from 'utils/url-utils';
import { ArticleAssociationFormProps } from 'app/pages/ArticlesPage/ArticleAssociationForm';
import { RequestStatusesDetailsProps } from 'app/pages/RequestStatuses/RequestStatusesDetailsPage/Details';
import { RegionDetailsProps } from 'app/pages/Regions/RegionsDetailsPage/Details';
import { MultipleReservationsProps } from 'app/pages/ReservationDetails/Details/MultipleReservations';
import { SignUpWaitingListDetailsProps } from 'app/pages/ReservationWaitingListPage/SignUpWaitingListDetails';
import { InvoiceDetailsProps } from 'app/pages/InvoiceDetails';
import { SamplePlatesDetailsProps } from 'app/pages/Samples/SamplePlatesDetailsPage/Details';
import { AssetFileProps } from 'app/pages/AssetFile';
import { UsageDetailsProps } from 'app/pages/UsageDetails/Details';
import { UserDetailsProps } from 'app/pages/UserDetails/Details';
import { SubscribersDetailsProps } from 'app/pages/SubscribersDetailsPage/Details';
import { CostCentersDetailsProps } from 'app/pages/CostCentersDetailsPage/Details';
import { OfflineTypeReferencesDetailsProps } from 'app/pages/OfflineTypeReferencesDetails/Details';
import { OfflineTypeReferencesPopUpProps } from 'app/pages/OfflineTypeReferencesPage/OfflineTypeReferencesPopUp';

export interface LayoutState {
  sidePanelState: SidePanelState;
  confirmState: ConfirmState;
  continueState: ContinueState;
  hasAnyNotSavedChanges: boolean;
  doRefreshTable: boolean;
  hideNavigation?: boolean;
  actionTableProcessing?: ProcessingData;
  upsnackbarIndex?: boolean;
  isMobileView?: boolean;
  adminPanelState: AdminPanelState;
}
export interface SidePanelState {
  sidePanelOpen: boolean;
  pageType?: RenderPageType;
  pageProps?: PageTypeProps;
  expanded: boolean;
  coverOpen?: boolean;
  coverExpanded?: boolean;
}
export interface ConfirmState {
  confirmOpen: boolean;
  approved: boolean;
  rejected: boolean;
  showLocalConfirm: boolean;
  continueToLink?: string;
}
export interface ContinueState {
  pageType?: RenderPageType;
  pageProps?: PageTypeProps;
  expanded?: boolean;
  continueOnApprove: boolean;
}
export interface AdminPanelState {
  adminPanelOpen: boolean;
  url?: string | IPathAndQuery;
}
export interface ProcessingData {
  name: string;
  processing: boolean;
}
export enum RenderPageType {
  TrainingDetails,
  QuizCreate,
  QuizzDetails,
  LocationDetails,
  AssetDetails,
  WorkOrderDetails,
  AddPayment,
  AddCharge,
  InvoiceDetails,
  AddBillingAction,
  SavedViewDetails,
  LabelType,
  LabelTypeEdit,
  OtherServiceDetails,
  RestrictionDetails,
  InvoiceTemplatePopUp,
  ReservationDetails,
  PricePeriodsDetails,
  Article,
  CoreSelection,
  AssetsSelection,
  ArticleNew,
  ArticleEdit,
  UserAttributesDetails,
  ServiceRequestDetails,
  CalendarMessagesDetails,
  ReportConsumables,
  ServiceRequestTicketDetails,
  ServiceRequestMilestone,
  ServiceRequestMilestoneCharge,
  StockRenewal,
  RenewStock,
  InventoryBatch,
  InvoiceBatchesComments,
  AssetInventoryLog,
  ArticleForm,
  RequestStatusesDetails,
  RegionDetails,
  MultiReservations,
  SignUpWaitingListDetails,
  SamplePlatesDetails,
  AssetFile,
  UsageDetails,
  UserProfile,
  SubscribersDetails,
  CostCentersDetails,
  OfflineTypeRefs,
  OfflineTypeRefDetails,
}
export interface SidePanelContentProps {
  useSidePanel: boolean;
}
export type PageTypeProps =
  | TrainingDetailsProps
  | StartQuizProps
  | QuizDetailsProps
  | LocationDetailsProps
  | AssetPopUpProps
  | WorkOrderDetailsProps
  | PaymentProps
  | ChargeProps
  | InvoiceDetailsProps
  | BillingActionsDetailsProps
  | SavedViewDetailsProps
  | InvoiceTemplatePopUpProps
  | OtherServiceDetailsProps
  | InstrumentRestrictionsProps
  | ReservationDetailsProps
  | PricePeriodDetailsProps
  | CoreSelectionProps
  | AssetsSelectionProps
  | UserAttributesDetailsProps
  | ServiceRequestDetailsProps
  | ServiceRequestTicketDetailsProps
  | ReportConsumablesProps
  | CalendarMessagesDetailsProps
  | ServiceRequestMilestoneDetailsProps
  | ServiceRequestMilestoneChargeDetailsProps
  | StockRenewalProps
  | RenewStockProps
  | InventoryBatchProps
  | InvoiceBatchesCommentsProps
  | InventoryLogProps
  | ArticleAssociationFormProps
  | RequestStatusesDetailsProps
  | RegionDetailsProps
  | MultipleReservationsProps
  | SignUpWaitingListDetailsProps
  | SamplePlatesDetailsProps
  | AssetFileProps
  | UsageDetailsProps
  | UserDetailsProps
  | SubscribersDetailsProps
  | CostCentersDetailsProps
  | OfflineTypeReferencesDetailsProps
  | OfflineTypeReferencesPopUpProps;
