/**
 *
 * CustomForm
 *
 */
import * as React from 'react';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CustomFormTypeEnum } from 'enums/CustomFormType';
import {
  serializeValues,
  IFormFileValue,
  useCustomFormSchema,
} from '../../CustomFormUtils';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { CustomFormContent } from '../../CustomFormContent';

export interface CustomFormProps {
  formFields: IFormFieldDto[];
  formType: CustomFormTypeEnum;
  formValues?: Record<string, any>;
  readonly?: boolean;
  onSubmit: (
    values: Record<number, string | null>,
    files: IFormFileValue[],
  ) => void;
  bindSubmitForm?: any;
  isAdmin?: boolean;
  CustomFormId?: number;
  formName?: string;
  formIsValid?: (isValid: boolean) => void;
  disabled?: boolean;
  validationEnabled?: boolean;
}

export interface CustomFormValue extends Record<number, any> {}

export const CustomForm = React.memo(function CustomForm({
  formFields,
  formValues,
  onSubmit,
  readonly,
  bindSubmitForm,
  formName,
  isAdmin,
  disabled,
  formIsValid,
  validationEnabled,
}: CustomFormProps) {
  const schema: Yup.SchemaOf<object> = useCustomFormSchema(
    formFields,
    isAdmin || false,
    formValues,
  );
  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      const { serialized, files } = serializeValues(formFields, value);
      onSubmit(serialized, files);
    }
  };

  const [initialValues, setInitialValues] = React.useState<
    Record<string, any> | undefined
  >(undefined);
  useEffectOnMount(() => {
    setInitialValues(formValues);
    return () => {};
  });

  return initialValues ? (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async (values, formikHelpers) => {
        // call setSubmit to finish submit cycle
        console.debug('submitting', values);
        formikHelpers.setSubmitting(true);
        handleSubmit(values);
      }}
    >
      {formik => {
        bindSubmitForm?.(formik.submitForm);
        // if (!formik.isValid) {
        //   console.log('Custom form errors', formik.errors);
        // }
        if (!!formIsValid) {
          formIsValid(formik.isValid);
        }
        return (
          <form id="customForm" onSubmit={formik.handleSubmit}>
            <CustomFormContent
              title={formName}
              formik={formik}
              schema={schema}
              formFields={formFields}
              validationEnabled={validationEnabled}
              isAdmin={isAdmin}
              readonly={readonly}
              disabled={disabled}
            />
          </form>
        );
      }}
    </Formik>
  ) : (
    <></>
  );
});
