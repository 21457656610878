import { Entity } from 'types/common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectConnectedSettings } from '../slice/selectors';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { isMember } from 'app/pages/ReservationDetails/Details/components/FormFieldListeners/isMember';

export interface UserGroupListenerProps<T> {
  userName: Entity<string> | null;
  services: Entity<number>[];
  userGroup: Entity<string> | null | undefined;
  isEdit: boolean;
  setUser: (value: Entity<string> | null) => void;
  setBudget: (value: Entity<number> | null) => void;
  values?: T;
  forceDisabled?: boolean;
  user: AuthenticatedUser | undefined;
}
export function UserGroupListener<T>({
  userName: selectedUser,
  services,
  userGroup,
  isEdit,
  setUser,
  setBudget,
  forceDisabled,
  user,
}: UserGroupListenerProps<T>) {
  const [initilized, setInitilized] = React.useState(!forceDisabled);
  const serviceSettings = useSelector(selectConnectedSettings);
  React.useEffect(() => {
    if (!initilized) {
      if (userGroup !== null) {
        if (
          serviceSettings.budgetVisible &&
          !isEdit &&
          services.length > 0 &&
          selectedUser !== null
        ) {
          if (!serviceSettings.ReservationUserGroupGroupByBudgetUserGroup) {
            if (!isMember(selectedUser, userGroup)) {
              setUser(null);
              if (serviceSettings.budgetVisible && !isEdit) {
                setBudget(null);
              }
            }
          }
        } else if (!serviceSettings.budgetVisible && !isEdit) {
          if (!isMember(selectedUser, userGroup)) {
            setUser(null);
          }
        }
      } else {
        if (!serviceSettings.ReservationUserGroupGroupByBudgetUserGroup) {
          setUser(null);
          if (serviceSettings.budgetVisible && !isEdit) {
            setBudget(null);
          }
        }
      }
    }
    setInitilized(false);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroup]);

  return null;
}
