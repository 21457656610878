import React from 'react';
import { Entity } from 'types/common';
import { IAsset } from './IAsset';

export interface IAssetRow extends Entity<string> {
  Cell?: (value: AttributeValue<IAsset>) => React.ReactNode | undefined;
  PropName?: keyof IAsset;
  NoHeader?: boolean;
  Header?: (value: AttributeValue<IAsset>) => React.ReactNode;
  dependencies?: string[];
  validToRender?: (value: AttributeValue<IAsset>) => boolean;
  State?: AssetRowState;
}

export interface AttributeValue<T extends object> {
  original: T;
  value: any;
  shortView?: boolean;
}

export enum AssetRowState {
  SectionName,
  Mandatory,
}
