import * as React from 'react';
import { Box, Grid } from '@material-ui/core';
import { ServiceRequestsApi } from 'api/ServiceRequestsApi';
import { DialogConfirm } from 'app/components/DialogConfirm';
import {
  ActiveSampleTypesPicker,
  initActiveSampleTypesData,
} from 'app/components/pickers/AutocompletePickers/ActiveSampleTypesPicker';
import {
  DefaultBudget,
  RelevantBudgetPicker,
} from 'app/components/pickers/AutocompletePickers/RelevantBudgetPicker';
import { UserPicker } from 'app/components/pickers/AutocompletePickers/UserPicker';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { usePromise } from 'app/hooks/usePromise';
import { useAppSettingsSlice } from 'app/slice';
import {
  selectAppSettings,
  selectAuthenticatedUser,
} from 'app/slice/selectors';
import { translations } from 'locales/translations';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Entity } from 'types/common';
import { Alert, Skeleton } from '@material-ui/lab';
import {
  convertAuthToIUserFilterDto,
  IUserFilterDto,
} from 'api/odata/generated/entities/IUserFilterDto';
import { IRequestTypeFilterDto } from 'api/odata/generated/entities/IRequestTypeFilterDto';
import { IsBudgetFieldEnabled, IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { tryParseInt } from 'utils/string-utils';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { IIsAdminOfParams } from 'types/AuthenticatedUser';
import { ServiceRequestDetailsProps } from 'app/pages/Samples/RequestSamplesPage';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { Roles } from 'api/odata/generated/enums/Roles';
import useGlobalSettingsHook from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { BudgetFields } from 'enums/BudgetFields';
import {
  FundingTypeIdPicker,
  getSingleInvoiceFundingType,
} from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { PurchaseOrderOptions } from 'api/odata/generated/enums/PurchaseOrderOptions';
import BaseTextField from 'app/components/BasicInputs/BaseTextField';

export interface CreateNewRequestParams {
  isOpen: boolean;
  setOpen: (val: boolean) => void;
  serviceId?: number;
  error?: string;
}
export const CreateNewRequest = ({
  isOpen,
  setOpen,
  serviceId,
  ...props
}: CreateNewRequestParams) => {
  const { t } = useTranslation();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const appSettings = useSelector(selectAppSettings);
  const [error, setError] = React.useState<string | undefined>(props.error);
  const [chosenUser, setChosenUser] = useState<IUserFilterDto | null>(
    authenticatedUser !== undefined
      ? convertAuthToIUserFilterDto(authenticatedUser)
      : null,
  );
  const [chosenFundingType, setFundingType] =
    React.useState<Entity<number> | null>(null);
  const [chosenPurchaseOrder, setPurchaseOrder] = React.useState<string | null>(
    null,
  );
  const [chosenBudget, setChosenBudget] =
    useAsyncExtendedState<Entity<number> | null>(null);
  const [busy, setBusy] = useState(false);
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const globalSettings = useGlobalSettingsHook();
  const [service, setService] = useAsyncExtendedState<Entity<number> | null>(
    null,
  );

  const [getServiceRequest, getService] = usePromise(
    React.useCallback((serviceId?: number) => {
      return initActiveSampleTypesData(serviceId?.toString()).then(d => d[0]);
    }, []),
  );
  const isAdmin = React.useMemo(() => {
    return (
      authenticatedUser !== undefined &&
      authenticatedUser.IsAdminOf({
        ServiceTypeId: ServiceType.Sample,
        ServiceId: service?.Id,
        ServiceGroupId: (service as IRequestTypeFilterDto)?.ServiceGroupId,
      } as IIsAdminOfParams)
    );
  }, [authenticatedUser, service]);

  const isReadOnlyUser = React.useMemo(() => {
    return (
      authenticatedUser !== undefined &&
      (authenticatedUser.Roles.includes(Roles.CalendarOnly) ||
        authenticatedUser.Roles.includes(Roles.Readonly) ||
        authenticatedUser.Roles.includes(Roles.RoomOnly))
    );
  }, [authenticatedUser]);

  const showProject = React.useMemo(() => {
    return (
      service !== null &&
      service !== undefined &&
      IsModuleEnabled(appSettings, KnownModules.Budgets) &&
      ((IsModuleEnabled(appSettings, KnownModules.DisableBillingEvents) &&
        (service as IRequestTypeFilterDto).HideProjects === false &&
        ((service as IRequestTypeFilterDto).ProjectIsRequired !== false ||
          isAdmin)) ||
        !IsModuleEnabled(appSettings, KnownModules.DisableBillingEvents))
    );
  }, [appSettings, isAdmin, service]);
  const fundingTypeVisible = React.useMemo(() => {
    return (
      IsBudgetFieldEnabled(appSettings, BudgetFields.FundingType) &&
      IsBudgetFieldEnabled(appSettings, BudgetFields.PurchaseOrderOption) &&
      showProject
    );
  }, [appSettings, showProject]);
  const purchaseOrderLength = React.useMemo(() => {
    return globalSettings.purchaseOrderLengthSetting ?? 0;
  }, [globalSettings.purchaseOrderLengthSetting]);

  const purchaseOrderVisible = React.useMemo(() => {
    return (
      globalSettings.purchaseOrderBudgetEnabled &&
      ((chosenBudget as IBudgetFilterDto)?.PurchaseOrderOptionId ??
        PurchaseOrderOptions.Hidden) !== PurchaseOrderOptions.Hidden
    );
  }, [chosenBudget, globalSettings.purchaseOrderBudgetEnabled]);
  const purchaseOrderRequired = React.useMemo(() => {
    return (
      globalSettings.purchaseOrderBudgetEnabled &&
      (chosenBudget as IBudgetFilterDto)?.PurchaseOrderOptionId ===
        PurchaseOrderOptions.Mandatory &&
      chosenPurchaseOrder === null
    );
  }, [
    chosenBudget,
    chosenPurchaseOrder,
    globalSettings.purchaseOrderBudgetEnabled,
  ]);
  const purchaseLengthInvalid = React.useMemo(() => {
    return (
      purchaseOrderVisible &&
      (purchaseOrderRequired ||
        (!purchaseOrderRequired && (chosenPurchaseOrder?.length ?? 0) > 0)) &&
      (chosenPurchaseOrder?.length ?? 0) < purchaseOrderLength
    );
  }, [
    chosenPurchaseOrder?.length,
    purchaseOrderLength,
    purchaseOrderRequired,
    purchaseOrderVisible,
  ]);
  const projectIsRequired = React.useMemo(() => {
    return (
      service !== null &&
      service !== undefined &&
      IsModuleEnabled(appSettings, KnownModules.Budgets) &&
      (chosenBudget === null || chosenBudget === undefined) &&
      ((IsModuleEnabled(appSettings, KnownModules.DisableBillingEvents) &&
        (service as IRequestTypeFilterDto).HideProjects === false &&
        (service as IRequestTypeFilterDto).ProjectIsRequired === null) ||
        !IsModuleEnabled(appSettings, KnownModules.DisableBillingEvents))
    );
  }, [appSettings, chosenBudget, service]);
  const onUserChange = React.useCallback(
    (value: IUserFilterDto | null) => {
      if (showProject) {
        if (value === null || value === undefined) {
          setChosenBudget(null);
        } else {
          setChosenBudget(
            DefaultBudget(
              value.UserGroupId,
              value.Id,
              chosenBudget,
              service?.Id,
              chosenFundingType?.Id ?? undefined,
            ),
          );
        }
      }
      setChosenUser(value);
    },
    [
      chosenBudget,
      chosenFundingType,
      service?.Id,
      setChosenBudget,
      showProject,
    ],
  );
  React.useEffect(() => {
    if (
      fundingTypeVisible &&
      chosenBudget !== null &&
      chosenFundingType === null
    ) {
      setFundingType(
        getSingleInvoiceFundingType(
          (chosenBudget as IBudgetFilterDto).FundingTypeId ?? 0,
        ),
      );
    }
  }, [chosenBudget, chosenFundingType, fundingTypeVisible]);
  React.useEffect(() => {
    if (projectIsRequired && chosenUser !== null) {
      setChosenBudget(
        DefaultBudget(
          chosenUser.UserGroupId,
          chosenUser.Id,
          null,
          service?.Id,
          chosenFundingType?.Id ?? undefined,
        ),
      );
    }
  }, [
    chosenFundingType,
    chosenUser,
    projectIsRequired,
    service?.Id,
    setChosenBudget,
  ]);
  React.useEffect(() => {
    if (
      getServiceRequest.status !== 'pending' &&
      getServiceRequest.status !== 'resolved'
    ) {
      setService(getService(serviceId));
    }
  }, [serviceId, getService, setService, getServiceRequest.status]);

  return (
    // TODO: switch to DialogForm with proper form schema & validation
    <DialogConfirm
      isOpen={isOpen}
      onCancel={() => setOpen(false)}
      onConfirm={e => {
        e.preventDefault();
        setBusy(true);
        setError(undefined);
        //let user = chosenUser; //(isAdmin && chosenUser) || authenticatedUser;
        if (service && chosenUser) {
          ServiceRequestsApi.AddRequest(
            service?.Id,
            chosenBudget === null ? 0 : chosenBudget.Id,
            chosenUser?.Id,
            chosenUser?.UserGroupId ?? undefined,
            chosenFundingType?.Id ?? null,
            chosenPurchaseOrder,
          )
            .then(response => {
              setBusy(false);
              if (response.ErrorMessages.length > 0) {
                response.ErrorMessages.forEach(errmsg =>
                  dispatch(
                    actions.addNotification({
                      variant: 'error',
                      message: errmsg,
                    }),
                  ),
                );
              }
              if (response.SuccessMessages.length > 0) {
                const id = tryParseInt(response.SuccessMessages[0]);
                if (id === undefined) {
                  console.error(
                    'Invalid service request id returned from the server',
                    response,
                  );
                  dispatch(
                    actions.addNotification({
                      variant: 'error',
                      message: `${t(
                        translations.Requests_Created_Failure,
                      )}. (invalid service request id returned from the server).`,
                    }),
                  );
                } else {
                  dispatch(
                    actions.addNotification({
                      variant: 'success',
                      message: t(translations.Requests_Created_Success),
                    }),
                  );
                  setOpen(false);
                  const propsSide: ServiceRequestDetailsProps = {
                    id: id,
                    useSidePanel: true,
                  };
                  dispatch(layoutActions.setRefreshTable(true));
                  dispatch(
                    layoutActions.openSidePanel({
                      type: RenderPageType.ServiceRequestDetails,
                      props: propsSide,
                      expanded: true,
                    }),
                  );
                  setService(null);
                  setChosenBudget(null);
                  //dispatch(push(GetServiceRequestDetailsPath(id)));
                }
              }
            })
            .catch(err => {
              setBusy(false);
              console.error(err);
            });
        }
      }}
      disabled={
        isReadOnlyUser ||
        !service ||
        (showProject && projectIsRequired && chosenBudget === null) ||
        (fundingTypeVisible &&
          projectIsRequired &&
          chosenFundingType === null) ||
        (purchaseOrderVisible && purchaseLengthInvalid) ||
        busy
      }
      body={
        <Box>
          <Box mt={2}>
            <Grid container direction="column" spacing={2}>
              {error !== undefined && (
                <Grid item xs={12}>
                  <Alert color="error">{error}</Alert>{' '}
                </Grid>
              )}
              <Grid item xs={12}>
                {getServiceRequest.status === 'pending' ? (
                  <Skeleton height={48} variant="rect" />
                ) : (
                  <ActiveSampleTypesPicker
                    variant="filled"
                    fullWidth
                    id="sampletypeid"
                    value={service as IRequestTypeFilterDto}
                    onChange={val => {
                      setService(val);
                      setChosenBudget(null);
                    }}
                    label={t(translations.RequiredLabServiceLabel)}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <UserPicker
                  variant="filled"
                  fullWidth
                  id="sampletype_userpisker"
                  value={chosenUser as IUserFilterDto}
                  defaultValue={
                    !!authenticatedUser
                      ? convertAuthToIUserFilterDto(authenticatedUser)
                      : null
                  }
                  onChange={onUserChange}
                  selectArray={[
                    'Id',
                    'Name',
                    'UserGroupId',
                    'UserGroups',
                    'Budgets',
                    'IsAdminPanel',
                    'IsEquipmentAdmin',
                    'IsGroupAdmin',
                    'IsLabTech',
                    //'Groups',
                    'Services',
                  ]}
                  disabled={!isAdmin}
                  label={t(translations.PleaseSelectUser)}
                  placeholder={t(translations.PleaseSelectUser)}
                />
              </Grid>
              {fundingTypeVisible && (
                <Grid item xs={12}>
                  <FundingTypeIdPicker
                    name="FundingType"
                    id="sampletype_fundingtype"
                    label={t(translations.FundingType)}
                    variant="filled"
                    value={chosenFundingType}
                    error={Boolean(
                      fundingTypeVisible &&
                        chosenFundingType === null &&
                        projectIsRequired,
                    )}
                    helperText={
                      fundingTypeVisible &&
                      chosenFundingType === null &&
                      projectIsRequired
                        ? t(translations.FundingTypeRequired)
                        : undefined
                    }
                    onChange={value => {
                      setFundingType(value);
                      if (
                        value?.Id !==
                        (chosenBudget as IBudgetFilterDto)?.FundingTypeId
                      ) {
                        setChosenBudget(null);
                      }
                    }}
                    fullWidth
                  />
                </Grid>
              )}

              {showProject && (
                <Grid item xs={12}>
                  <RelevantBudgetPicker
                    variant="filled"
                    id="sampletype_budget"
                    fullWidth
                    value={chosenBudget}
                    userGroupId={chosenUser?.UserGroupId ?? ''}
                    userName={chosenUser?.Id || ''}
                    serviceId={service?.Id || 0}
                    onChange={val => setChosenBudget(val)}
                    label={t(translations.PleaseSelectABudgetNumber)}
                    placeholder={t(translations.PleaseSelectABudgetNumber)}
                    error={Boolean(projectIsRequired && chosenBudget === null)}
                    helperText={
                      projectIsRequired && chosenBudget === null
                        ? t(translations.BudgetIsRequired)
                        : undefined
                    }
                    fundingTypeId={chosenFundingType?.Id}
                    disabled={!!!service}
                  />
                </Grid>
              )}
              {purchaseOrderVisible && (
                <Grid item xs={12}>
                  <BaseTextField
                    id="requestPurchaseOrder"
                    name="PurchaseOrder"
                    label={t(translations.PurchaseOrder)}
                    fullWidth
                    variant="filled"
                    error={Boolean(
                      purchaseLengthInvalid || purchaseOrderRequired,
                    )}
                    helperText={
                      purchaseLengthInvalid
                        ? (
                            t(
                              translations.Reservation_Error_ShortPurchaseOrderLength,
                            ) as string
                          ).replace('{0}', purchaseOrderLength.toString())
                        : purchaseOrderRequired
                        ? t(
                            translations.Reservation_Error_PurchaseOrderRequired,
                          )
                        : undefined
                    }
                    onChange={ev => {
                      let val = ev.target.value;
                      setPurchaseOrder(val === '' ? null : val);
                    }}
                    value={chosenPurchaseOrder ?? ''}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      }
      title={t(translations.ServiceRequests_Ordering)}
    />
  );
};
