import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import { StatusChip } from 'app/components/BasicChip/StatusChip';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { DescriptionCell } from 'app/components/BasicTable/components/DescriptionCell';
import { UserProfileLink } from 'app/pages/UserDetails/OpenUserProfile';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Column } from 'react-table';
import { AppSettings } from 'types/AppSettings';
import { dateUtils } from 'utils/date-utils';
import { GlobalSettings } from 'utils/globalSettings';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { AssetLink } from '../AssetPopUp/OpenAssetDetails';
import {
  allowedToViewAlert,
  IAlertPermissionCheckRecord,
} from 'app/permissions/WorkOrders/workOrdersPermissionUtils';
import { WorkOrderLink } from '../Actions/GlobalSelectedActions/OpenWorkOrder';
import { AuthenticatedUser } from 'types/AuthenticatedUser';

export const getColumns = (
  t: TFunction,
  appSettings?: AppSettings,
  globalSettings?: GlobalSettings,
  user?: AuthenticatedUser,
  useSidePanel?: boolean,
  openPanel?: (state: SidePanelOpenState) => void,
): Column<IWorkOrderDetailsDto>[] => {
  return [
    {
      Header: t(translations.Id) as string,
      accessor: 'Id',
      //for testing comments component
      //Cell: WorkOrderIdRenderer,
      Cell: ({ value, row }) => {
        return allowedToViewAlert(
          user,
          row.original as IAlertPermissionCheckRecord,
        ) && value !== null ? (
          <WorkOrderLink
            id={value}
            useSidePanel={useSidePanel}
            openPanelWithCover={openPanel}
          >
            {value}
          </WorkOrderLink>
        ) : (
          <>{value}</>
        );
      },
    },
    //Attachment of Comment
    {
      Header: () => (
        <>
          {t(translations.Attachment) as string} <Icon icon="paperclip" />
        </>
      ),
      accessor: 'AttachmentExist',
      Cell: ({ value, row }) => (
        <>{value === true ? <Icon icon="paperclip" /> : <></>}</>
      ),
    },
    {
      Header: t(translations.AssetName) as string,
      accessor: 'AssetName', // AssetName
      Cell: ({ value, row }) =>
        !!row.original.AssetId ? (
          <AssetLink
            id={row.original.AssetId ?? undefined}
            openPanelWithCover={openPanel}
            useSidePanel={useSidePanel}
          >
            {value}
          </AssetLink>
        ) : (
          <>{value}</>
        ),
    },
    {
      Header: t(translations.ServiceGroup) as string,
      accessor: 'ServiceGroup', // Laboratory
    },
    {
      Header: t(translations.AlertDescription) as string,
      accessor: 'AlertDescription', // Description
      Cell: DescriptionCell({ width: 'short' }),
    },
    {
      Header: t(translations.AlertTitle) as string,
      accessor: 'AlertTitle', // Subject
    },
    {
      Header: t(translations.StatusName) as string,
      accessor: 'StatusName', // StatusName
    },
    {
      Header: t(translations.AlertType) as string,
      accessor: 'AlertType', // WorkOrderType
    },
    {
      Header: t(translations.EventDueDate) as string,
      accessor: 'EventStart', // WorkOrderStart
      Cell: ({ value }) => <>{dateUtils.shortDateTimeFormat(value)}</>,
    },
    {
      Header: t(translations.EventEnd) as string,
      accessor: 'EventEnd', // WorkOrderEnd
      Cell: ({ value }) => {
        if (value !== null) {
          return <>{dateUtils.shortDateTimeFormat(value)}</>;
        } else {
          return <></>;
        }
      },
    },
    {
      Header: t(translations.ReportedBy) as string,
      accessor: 'ReportedBy',
      Cell: ({ value, row }) => (
        <>
          <UserProfileLink
            userName={row.original.ReportedBy ?? undefined}
            displayName={row.original.ReportedByDisplayName ?? undefined}
          />
        </>
      ),
    },
    {
      Header: t(translations.AssignedUsers) as string,
      accessor: 'AssignedUsers',
      Cell: ({ value, row }) => {
        if (value !== undefined) {
          let users = value.map((item, i) => {
            return (
              <div key={item.Id}>
                <UserProfileLink userName={item.Id} displayName={item.Name} />
                <br />
              </div>
            );
          });
          return <>{users}</>;
        }
        return t(translations.NA) as string;
      },
    },
    {
      Header: t(translations.LastUpdated) as string,
      accessor: 'LastUpdated', // UpdatedAt
      Cell: ({ value }) => <>{dateUtils.shortDateTimeFormat(value)}</>,
    },
    {
      Header: t(translations.Reservation) as string,
      accessor: 'Reservation', // ReservationId
    },
    {
      Header: t(translations.LastComment) as string,
      accessor: 'LastComment',
      Cell: DescriptionCell({ width: 'medium' }),
    },
    {
      Header: t(translations.LastCommentBy) as string,
      accessor: 'LastCommentBy',
    },
    {
      Header: t(translations.LastCommentAt) as string,
      accessor: 'LastCommentAt',
      Cell: ({ value }) => <>{dateUtils.shortDateTimeFormat(value)}</>,
    },
    {
      Header: t(translations.AlertOfflineTime) as string,
      accessor: 'AlertOfflineTime', // OfflineTime (h)
      Cell: ({ value, row }) => {
        if (value) {
          if (value > 69000000) {
            return (
              <StatusChip
                label={t(translations.UnlimitedDowntime)}
                size="small"
                icon={<Icon icon={['fad', 'infinity']} size="2x" />}
                variant="outlined"
              />
            );
          } else {
            return value;
          }
        } else {
          return <>{'N/A'}</>;
        }
        // let startTime: Date | null = value;
        // let endTime: Date | null = row.original.OfflineEnd;
        // if (startTime !== null && endTime !== null) {
        //   let newStartTime = new Date(startTime);
        //   let newEndTime = new Date(endTime);
        //   let timeDiff =
        //     (newEndTime.getTime() - newStartTime.getTime()) / 36e5;
        //   return <> {roundTwoPlacesAfterDecimalPoint(value)} </>;
        // } else return <></>;
      },
    },
    {
      Header: t(translations.ReasonTypeName) as string,
      accessor: 'ReasonTypeName',
    },
  ];
};
