export enum UserAttributeEnum {
  UserName = 1,
  DisplayName = 2,
  Password = 3,
  UserGroup = 4,
  Mobile = 5,
  OfficeTel = 6,
  Email = 7,
  UserInternalId = 8,
  Degree = 9,
  Locked = 10,
  PoBox = 11,
  Title = 12,
  HomeTel = 13,
  Company = 14,
  PostalCode = 15,
  Province = 16,
  Country = 17,
  Address = 18,
  PostalAddress = 19,
  Discipline = 20,
  AD = 21,
  Department = 22,
  Institute = 23,
  RegisteredToCore = 24,
  UploadImage = 25,
  TempPassword = 26,
  InstituteType = 27,
  UserInternalId2 = 28,
  LastLogin = 29,
  RestrictedMobileNumber = 30,
  LastActivityDate = 31,
  ConfirmPassword = 32,
  OfficeLocation = 33,
  Advisor = 34,
  YearInDegree = 35,
  Image = 36,
  Manager = 37,
  ORCID = 38,
}
