import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { CellProps, Renderer } from 'react-table';

export const BooleanRenderer: Renderer<
  CellProps<Record<string, any>, boolean | null>
> = ({ value }) => {
  const { t } = useTranslation();
  return (
    <>
      {value === true
        ? t(translations.Yes)
        : value === false
        ? t(translations.No)
        : t(translations.NA)}
    </>
  );
};
export default BooleanRenderer;
