import { Condition } from 'api/odata/ODataFilter';
import { DocumentsTable } from 'app/pages/Documents/DocumentsTable';
import { IDocumentsDirectoryRow } from 'app/pages/Documents/IDocumentsDirectoryRow';
//import { useFilters } from './TableFilters';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { IBasicTableState } from 'app/components/BasicTable';
import React from 'react';
import { getColumns } from 'app/pages/Documents/Columns';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { AuthenticatedUser } from 'types/AuthenticatedUser';

const ExcludeAssetDocumentsColumns: string[] = ['AssetName', 'ServiceGroup'];

export interface AssetDetailsFilesProps {
  getFiltersDefinition?: GetPageFilters<IDocumentsDirectoryRow>;
  isMobile?: boolean;
  user?: AuthenticatedUser;
  predicates: (string | Condition<IDocumentsDirectoryRow>)[];
}

export function AssetDetailsFiles(props: AssetDetailsFilesProps) {
  const { t } = useTranslation();
  const documentsColumns = React.useMemo(() => getColumns(t), [t]);
  const { isMobile, predicates } = props;
  const columns = React.useMemo(() => {
    let res: Column<IDocumentsDirectoryRow>[] = documentsColumns.filter(
      f =>
        f.accessor !== undefined &&
        !ExcludeAssetDocumentsColumns.includes(f.accessor as string),
    );
    return res;
  }, [documentsColumns]);

  /*const {
    allfilters,
    appliedFilters,
    handleFilterChange,
    getFilterValue,
    setFilterValue,
  } = useFilters(props.getFiltersDefinition, 'AssetId');*/

  const initialState: IBasicTableState<IDocumentsDirectoryRow> = React.useMemo(
    () => ({
      sortBy: [{ id: 'Id', desc: false }],
    }),
    [],
  );

  return (
    <DocumentsTable
      initialState={initialState}
      predicates={predicates}
      columns={columns}
      /*availableFilters={allfilters}
      appliedFilters={appliedFilters}
      onFilterChange={handleFilterChange}
      getFilterValue={getFilterValue}
      setFilterValue={setFilterValue}*/
      useConfigurable={false}
      useCardsByDefault={isMobile}
      allowCards={isMobile}
      // compactTable={true}
      useExport={false}
      hideMenuOnMobile={true}
      screenNameVariant="section"
      tablePaddingVariant={'dense'}
      compactTable={isMobile}
      serviceGroups={[]}
    />
  );
}
