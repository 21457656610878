import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { OptionsObject, SnackbarProvider, useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMessagesSlice } from './slice';
import { selectMessages, selectMessagesAlreadyExist } from './slice/selector';
import { getDuration, MessageTypeEnum } from './slice/type';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useTheme } from '@material-ui/core';
import { AllowedSettings } from 'utils/globalSettings';
import { Entity } from 'types/common';
import { dashboardKey } from '../Dashboard/slice/types';
import { AnnoncementsRoot } from './styled';
import clsx from 'clsx';
import { SnackBarMessage } from './SnackBarMessage';
import { RenderSnackMessage } from './RenderSnackMessage';
import { Button } from '../BasicButtons/Button';
import {
  selectExpandedSidePanel,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { DetectIsMobile } from 'utils/mobileDetect';

const MaxMessage = 3;
const closeAllKey = 'closeAllMessagesKey';

export type pageNames = dashboardKey | 'calendar' | 'assetDetails';
export const pageToType: Partial<Record<pageNames, MessageTypeEnum[]>> = {
  calendar: [MessageTypeEnum.calendar],
  assetDetails: [
    MessageTypeEnum.reservationStarted,
    MessageTypeEnum.usageReservation,
  ],
};
export interface MessageProps {
  pageName: pageNames;
  selectedEquipment?: Entity<number>[];
  secondaryPage?: boolean;
  darkTheme?: boolean;
  closeOnTouch?: boolean;
}

export const Messages = ({
  secondaryPage,
  darkTheme,
  ...props
}: MessageProps) => {
  const alreadyExists = useSelector(selectMessagesAlreadyExist);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const theme = useTheme();
  const isMobile = DetectIsMobile();
  const [closeOnTouch, setCloseOnTouch] = React.useState(false);
  return (
    <AnnoncementsRoot
      className={'annoncement-root'}
      sideBarOpen={sidePanelOpen && !sidePanelExpanded}
      theme={theme}
    >
      <SnackbarProvider
        hideIconVariant
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        preventDuplicate
        variant="info"
        ClickAwayListenerProps={{
          mouseEvent: false,
          onClickAway: () => {
            if (isMobile) {
              setCloseOnTouch(true);
            }
          },
        }}
        maxSnack={MaxMessage + 1}
        className={clsx('annoncement-item', {
          'annoncement-item-dark': darkTheme,
        })}
      >
        <MessagesChildren
          {...props}
          closeOnTouch={closeOnTouch}
          secondaryPage={secondaryPage && alreadyExists > 1}
        />
      </SnackbarProvider>
    </AnnoncementsRoot>
  );
};

const MessagesChildren = (props: MessageProps) => {
  const { pageName, selectedEquipment, closeOnTouch } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { actions } = useMessagesSlice();
  const allMessages = useSelector(selectMessages);
  const messages = useMemo(() => {
    const types = pageToType[props.pageName];
    if (types === undefined) return allMessages;
    return allMessages.filter(m => types.includes(m.type));
  }, [allMessages, props.pageName]);

  useEffectOnMount(() => {
    if (props.secondaryPage) return;
    dispatch(
      actions.init({
        pageName: pageName,
        selectedEquipment: selectedEquipment,
      }),
    );

    return () => {
      dispatch(actions.clear());
    };
  });
  useEffect(() => {
    dispatch(actions.clear());
    // closeSnackbar();
    dispatch(
      actions.init({
        pageName: pageName,
        selectedEquipment: selectedEquipment,
      }),
    );
  }, [actions, dispatch, pageName, selectedEquipment]);
  useEffect(() => {
    if (props.secondaryPage) {
      closeSnackbar();
      return;
    }
    if (closeOnTouch) {
      closeSnackbar();
      return;
    }
    if (messages.length > 1) {
      const closeAllOptions: OptionsObject = {
        persist: true,
        key: closeAllKey,
      };

      enqueueSnackbar(
        <SnackBarMessage
          onClose={() => {
            closeSnackbar();
            dispatch(actions.clear());
          }}
          messageTitle={t(translations.CloseAll)}
        />,
        closeAllOptions,
      );
    } else {
      closeSnackbar(closeAllKey);
    }
    messages.slice(0, MaxMessage).forEach(m => {
      const close = () => {
        closeSnackbar(m.key);
      };
      const remove = () => dispatch(actions.remove(m.key));
      const options: OptionsObject = {
        autoHideDuration: getDuration(m.props),
        key: m.key,
        onClose: remove,
      };

      enqueueSnackbar(
        <RenderSnackMessage
          message={m}
          onClose={close}
          messageActions={
            m.type === MessageTypeEnum.removableAnnouncment
              ? [
                  () => (
                    <Button
                      size="small"
                      onClick={() => {
                        dispatch(actions.remove(m.key));
                        closeSnackbar(m.key);
                        dispatch(
                          actions.gotIt(
                            AllowedSettings[
                              AllowedSettings.DashboardAnnouncement
                            ],
                          ),
                        );
                      }}
                    >
                      {t(translations.GotIt)}
                    </Button>
                  ),
                ]
              : undefined
          }
        />,
        options,
      );
    });
  }, [
    actions,
    closeOnTouch,
    closeSnackbar,
    dispatch,
    enqueueSnackbar,
    messages,
    props.secondaryPage,
    t,
  ]);
  return null;
};
