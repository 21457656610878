import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useSavedViewSlice } from '../slice';
import {
  selectCreateSavedView,
  selectCreateSavedViewCompleted,
  selectCreateSavedViewHasError,
  selectExtraData,
  selectSavedViewProcessing,
  selectUpdateSavedView,
  selectUpdateSavedViewCompleted,
  selectUpdateSavedViewHasError,
  selectUpdateUserName,
} from '../slice/selectors';
import { SavedViewDetailsState } from '../slice/types';
import * as React from 'react';
//import { ScreensId } from 'enums/ConfigurableTypes';
import { selectHasNotSavedChanges } from 'app/Layout/FrontendLayout/slice/selectors';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import { Roles } from 'api/odata/generated/enums/Roles';
import {
  IAssetDto,
  mapAssetsToServiceTypeFilterDto,
} from 'api/odata/generated/entities/IAssetDto';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { push } from 'connected-react-router';
import { SaveFormButton } from './Actions/SavedViewActions';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { Button } from 'app/components/BasicButtons/Button';
import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
import { buildURL, toQueryString } from 'utils/url-utils';
import { SavedViewForm } from './SavedViewForm';
import { Box } from '@material-ui/core';
import { Beforeunload } from 'react-beforeunload';
import { Progress } from 'app/components/LoadingIndicator';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';
import { useLeftTopActions } from './Actions/RightTopActions';
import { ISavedViewDto } from 'api/odata/generated/entities/ISavedViewDto';
import { AssetPopUp } from 'app/pages/AssetPopUp';

export interface SavedViewDetailsProps extends SidePanelContentProps {
  id?: string;
  eid?: string;
}

export function SavedViewDetails(props: SavedViewDetailsProps) {
  const { id, useSidePanel, eid } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actions } = useSavedViewSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const loading = useSelector(selectSavedViewProcessing);
  const [busy, setBusy] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<boolean>(false);

  //   const [currentScreen, setCurrentScreen] = React.useState<
  //     ScreensId | undefined
  //   >(undefined);
  //   const hasCreateProcessing = useSelector(selectCreateSavedViewProcessing);
  //   const hasUpdateUpdateProcessing = useSelector(
  //     selectUpdateSavedViewProcessing,
  //   );
  //const hasProcessing = hasCreateProcessing || hasUpdateUpdateProcessing;
  const hasCreateError = useSelector(selectCreateSavedViewHasError);
  const hasUpdateError = useSelector(selectUpdateSavedViewHasError);
  const hasError = hasCreateError || hasUpdateError;
  const requestCreateCompleted = useSelector(selectCreateSavedViewCompleted);
  const requestUpdateCompleted = useSelector(selectUpdateSavedViewCompleted);
  const [requestCompleted, setRequestCompleted] = React.useState<
    boolean | undefined
  >(undefined);
  const createModel = useSelector(selectCreateSavedView, shallowEqual);
  const editModel = useSelector(selectUpdateSavedView, shallowEqual);
  const baseModel = createModel || editModel;

  const hasChanges = useSelector(selectHasNotSavedChanges, shallowEqual);

  //const continueToOther = useSelector(selectContinueState);

  //   const isMobile = DetectIsMobile();
  //   const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  //const showShortView = isMobile || (useSidePanel && !sidePanelExpanded);
  const User = useSelector(selectAuthenticatedUser);
  const fullAdmin =
    User !== undefined && User.Roles.includes(Roles.Administrators);
  const IsAdmin = React.useMemo(() => {
    return (
      fullAdmin ||
      User?.IsAllGroupOrLabTechAdmin(
        baseModel?.Assets.map(g => (g as IAssetDto).ServiceGroupId ?? 0),
        mapAssetsToServiceTypeFilterDto(
          (baseModel?.Assets === undefined
            ? []
            : baseModel?.Assets) as IAssetDto[],
        ),
      )
    );
  }, [fullAdmin, User, baseModel]);

  const savedViewUserName = useSelector(selectUpdateUserName);
  const extraData = useSelector(selectExtraData);
  const viewOnly = React.useMemo(() => {
    return edit && !fullAdmin && User?.Id !== savedViewUserName;
  }, [User?.Id, edit, fullAdmin, savedViewUserName]);

  const publicUrl = useSelector(selectPublicUrl);
  useEffectOnMount(() => {
    if (id !== undefined && !isNaN(parseInt(id || ''))) {
      dispatch(actions.initDetails(parseInt(id || '')));
    } else {
      dispatch(actions.initNewDetails(eid));
    }
    setBusy(false);
    setEdit(false);
    setRequestCompleted(undefined);
    return () => {};
  });
  const submitFormRef = React.useRef<any>(null);

  const handleSaveClick = React.useCallback((e: any) => {
    handleSubmitForm(e);
  }, []);
  const handleSubmitForm = (e: any) => {
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmit = React.useCallback(
    (values: SavedViewDetailsState) => {
      setBusy(true);
      if (edit) {
        dispatch(
          actions.updateView({
            current: values,
            original: baseModel,
          }),
        );
      } else {
        dispatch(actions.createView(values));
      }
    },
    [actions, baseModel, dispatch, edit],
  );
  const handleClose = () => {
    if (hasChanges) {
      dispatch(layoutActions.setConfirmOpen(true));
    } else {
      handleCancelClick();
    }
  };
  const handleCancelClick = React.useCallback(() => {
    if (useSidePanel) {
      dispatch(layoutActions.resetSidePanel());
    } else {
      dispatch(push('/SavedViews'));
    }
    if (edit) {
      dispatch(actions.resetUpdateSavedViewState());
    } else {
      dispatch(actions.resetCreateSavedViewState());
    }
  }, [actions, dispatch, edit, layoutActions, useSidePanel]);

  React.useEffect(() => {
    let active = loading === false;
    if (active) {
      if (id !== undefined && !isNaN(parseInt(id))) {
        setEdit(true);
      }
      if (requestCreateCompleted === true || requestUpdateCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
        if (!hasError) {
          handleCancelClick();
        }
      } else if (
        requestCreateCompleted === false ||
        requestUpdateCompleted === false
      ) {
        setRequestCompleted(false);
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [
    baseModel,
    handleCancelClick,
    hasError,
    id,
    loading,
    requestCompleted,
    requestCreateCompleted,
    requestUpdateCompleted,
  ]);

  const leftActions = React.useMemo(() => {
    if (viewOnly) return [];
    return [
      () => (
        <React.Fragment>
          <SaveFormButton
            size="small"
            startIcon={<Icon icon="save" />}
            onClick={handleSaveClick}
            disabled={busy}
            processing={!requestCompleted && busy}
            edit={edit}
            text={t(translations.Save)}
          />
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [busy, edit, handleSaveClick, requestCompleted, t, viewOnly]);
  const rightActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <Button
            variant="ghost"
            size="small"
            startIcon={<Icon icon="times" />}
            onClick={handleCancelClick}
          >
            {t(translations.Cancel)}
          </Button>
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [handleCancelClick, t]);

  const leftTopActions = useLeftTopActions({
    savedView: baseModel as ISavedViewDto,
    isNew: !edit,
    useSidePanel: useSidePanel,
    extraData: extraData,
  });
  const [cover, setCover] = React.useState<React.ReactNode>();
  const closeCover = () => {
    setCover(undefined);
  };

  return (
    <>
      <PageWrapper
        disableExpandToggle={true}
        pageName={
          edit
            ? t(translations.menu_EditSavedView)
            : t(translations.menu_AddNewSavedView)
        }
        titlePage={
          baseModel
            ? edit
              ? `Edit ${baseModel.Name} #${id || 'N/A'}`
              : ` ${t(translations.AddNew)} List`
            : undefined
        }
        loading={loading}
        useSidePanel={useSidePanel}
        closable={true}
        closeSidePanel={handleClose}
        cover={cover}
        closeCover={closeCover}
        children={
          baseModel !== undefined && !loading ? (
            <React.Fragment>
              <SavedViewForm
                initialValues={baseModel || ({} as SavedViewDetailsState)}
                bindSubmitForm={bindSubmitForm}
                onSubmit={handleSubmit}
                isEdit={edit}
                isAdmin={IsAdmin || false}
                user={User}
                ExtraData={extraData}
                viewOnly={viewOnly}
                onEquipmentClicked={asset => {
                  setCover(
                    <AssetPopUp
                      closeCover={closeCover}
                      isCover
                      identifier={{ id: asset.Id }}
                      onClose={closeCover}
                      useSidePanel={true}
                    />,
                  );
                }}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {' '}
              <Box component="div">
                <Progress inProgress={loading} size={80} />
              </Box>
            </React.Fragment>
          )
        }
        pageLink={buildURL(publicUrl + 'mylists/details', { id: id })}
        toPageLink={`mylists/details?${toQueryString({ id: id })}`}
        leftActions={leftActions}
        rightActions={rightActions}
        leftTopActions={leftTopActions}
        maxLeftTopButtonsLength={1}
      />
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </>
  );
}
