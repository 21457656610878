import { Comments, CommentsProps } from 'app/components/Comments';
import {
  selectCommentIsInternal,
  selectIsServiceRequestAdmin,
} from 'app/pages/Samples/RequestSamplesPage/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { GetServiceRequestDetailsPath } from 'app/pages/Samples/RequestSamplesPage/slice/path';
import { IServiceRequestRelated } from '../..';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { IServiceRequestTableRowModel } from 'app/pages/Samples/RequestSamplesPage/slice/types';
import { getServiceRequestRowName } from '../../../ServiceRequestRowEditableCell';
import { Row } from 'react-table';
import { useRequestSamplesSlice } from 'app/pages/Samples/RequestSamplesPage/slice';
import { ServiceRequestsApi } from 'api/ServiceRequestsApi';
import { useUploadToNewComment } from 'app/components/LargeFilesUpload/Components/useUploadToNewComment';

export interface ServiceRequestRowCommentsProps
  extends Pick<
      CommentsProps,
      | 'referenceId'
      | 'commentType'
      | 'disableFileUpload'
      | 'serviceGroups'
      | 'print'
    >,
    IServiceRequestRelated {
  row?: Row<IServiceRequestTableRowModel>;
  canUserEdit?: Boolean;
}
const CommentsWrap = styled('div')`
  padding: 16px;
  width: 100%;
`;
export function ServiceRequestRowComments({
  serviceRequestId,
  canUserEdit,
  disableFileUpload,
  commentType,
  referenceId,
  row,
  serviceGroups,
  print,
}: ServiceRequestRowCommentsProps) {
  const { isAdmin } = useSelector(selectIsServiceRequestAdmin);
  const CommentIsInternal = useSelector(selectCommentIsInternal);
  const formik = useFormikContext<IServiceRequestTableRowModel>();
  const dispatch = useDispatch();
  const { actions } = useRequestSamplesSlice();
  const { onCommentAdded } = useUploadToNewComment();
  const handleCommentAdded = newComment => {
    if (row !== undefined) {
      formik.setFieldValue(
        getServiceRequestRowName(row.index, 'CommentsCount'),
        row.original.CommentsCount + 1,
        false,
      );
    }
    ServiceRequestsApi.updateAdminOnComment(serviceRequestId);
    dispatch(actions.setHasFiles(true));
    onCommentAdded(newComment);
  };
  return (
    <CommentsWrap>
      <Comments
        defaultComment={{
          IsInternal: isAdmin && CommentIsInternal,
        }}
        print={print}
        emailType="RequestComment"
        link={GetServiceRequestDetailsPath(serviceRequestId)}
        isAdmin={isAdmin}
        cannotComment={(!!CommentIsInternal || !canUserEdit) && !isAdmin}
        onCommentAdded={handleCommentAdded}
        disableFileUpload={disableFileUpload}
        referenceId={referenceId}
        commentType={commentType}
        serviceGroups={serviceGroups}
        pageName="Request"
      />
    </CommentsWrap>
  );
}
