/**
 *
 * auto generated interface for Bookit.Data.ServiceType
 *
 */

export enum ServiceType {
  Online = 1,
  Offline = 2,
  Sample = 3,
}
export type ServiceTypeUnion = keyof typeof ServiceType;
export const ServiceTypeUnionArray: Array<ServiceTypeUnion> = [
  'Online',
  'Offline',
  'Sample',
];
