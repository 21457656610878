/**
 *
 * CustomFormField
 *
 */
import React from 'react';
import { CustomFormFieldProps } from 'app/components/CustomForm/CustomFormFields';
import { FormCheckbox } from 'app/components/Forms/FormCheckbox';

export function CustomFormCheckBox({
  formField,
  readonly,
  disabled,
  ...props
}: CustomFormFieldProps) {
  //const title = `${formField.QuizScore} ${t(translations.Points) as string}`;
  return (
    <FormCheckbox
      key={formField.Id}
      disabled={readonly || disabled}
      label={formField.Label}
      info={formField.Description ?? undefined}
      {...props}
    />
  );
}
