import { Card, CardContent, LinearProgress } from '@material-ui/core';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Body } from 'app/components/Typography';
import { translations } from 'locales/translations';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'app/components/BasicButtons/Button';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLargeFilesCanUpload,
  selectLargeFilesUploadTargetId,
} from '../slice/selectoes';
import { UploadLargeFile } from '../Upload';
import { useLargeFilesUploadSlice } from '../slice';
import { useAppSettingsSlice } from 'app/slice';
import clsx from 'clsx';
import BasicTypography from 'app/components/Typography/BasicTypography';

export interface FileSize {
  size: number;
  name: string;
}
export interface FileData {
  name: string;
  path: string;
  size: FileSize;
}
export interface FileCardProps {
  file: File;
  remorFile: (name: string) => void;
}
export const FileCard = ({ file, remorFile }: FileCardProps) => {
  const { t } = useTranslation();
  const canUplpoad = useSelector(selectLargeFilesCanUpload);
  const targetId = useSelector(selectLargeFilesUploadTargetId);
  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<number>(0);
  const [part, setPart] = React.useState<number>(0);
  const [error, setError] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(true);
  const [pause, setPause] = React.useState<boolean>(false);
  const fileSize = getFileSize(file.size);
  const dispatch = useDispatch();
  const { actions: largeFileActions } = useLargeFilesUploadSlice();
  const { actions: notificationActions } = useAppSettingsSlice();
  const fileName = React.useMemo(() => file.name, [file.name]);
  const uploadLargeFile = React.useMemo(() => new UploadLargeFile(), []);
  const closeHandler = useCallback(async () => {
    if (!inProgress) {
      setShow(false);
      await uploadLargeFile.deleteUnfinishedLargeFiles();
      remorFile(file.name);
    } else {
      setPause(true);
      uploadLargeFile.pause = true;
    }
  }, [file.name, inProgress, remorFile, uploadLargeFile]);
  const chunkSendResult = useCallback(
    (res: boolean, pos: number, part: number, error?: string) => {
      if (res) {
        setProgress(pos);
        setPart(part);
      } else {
        setProgress(pos);
        setPart(part);
        setInProgress(false);
        if (uploadLargeFile.pause === false) {
          setError(true);
        }
        if (error !== undefined) {
          dispatch(
            notificationActions.addNotification({
              variant: 'error',
              message: error,
            }),
          );
        }
      }
    },
    [dispatch, notificationActions, uploadLargeFile.pause],
  );
  const CompletionResult = useCallback(
    (value: boolean) => {
      if (!value) {
        setError(true);
        dispatch(
          notificationActions.addNotification({
            variant: 'error',
            message: 'Upload is failed',
          }),
        );
      }
    },
    [dispatch, notificationActions],
  );

  const uploadEnd = useCallback(() => {
    if (pause) {
      setInProgress(false);
    } else {
      setInProgress(false);
      dispatch(largeFileActions.removeFile(fileName));
      setShow(false);
    }
  }, [dispatch, fileName, largeFileActions, pause]);
  useEffect(() => {
    if (canUplpoad) {
      setInProgress(true);
      dispatch(largeFileActions.addFile(fileName));
      uploadLargeFile.upload(
        file,
        chunkSendResult,
        null,
        uploadEnd,
        targetId,
        CompletionResult,
      );
    }
    return () => {
      if (!uploadLargeFile.busy) {
        uploadLargeFile.stopAndCancel();
        uploadLargeFile.deleteUnfinishedLargeFiles();
        dispatch(largeFileActions.removeFile(fileName));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canUplpoad]);
  return (
    <>
      {show === true ? (
        <Card className={clsx('file-card', { 'with-error': error })}>
          <CardContent className={'file-card-content-root'}>
            <div className={'file-card-content'}>
              <div className={'file-card-content-body'}>
                <div>
                  <Icon
                    icon="file"
                    size="1x"
                    color={error ? 'danger' : 'black'}
                  />
                </div>
                <div className={'file-card-text'}>
                  {error === false ? (
                    <>
                      <Tooltip title={file.name}>
                        <span>
                          <BasicTypography
                            bold={true}
                            variant="bodyM"
                            color="textPrimary"
                            truncateSize="15"
                          >
                            {file.name}
                          </BasicTypography>
                        </span>
                      </Tooltip>
                      {inProgress ? (
                        <LinearProgress
                          variant={progress > 0 ? 'determinate' : 'query'}
                          value={
                            progress > 0
                              ? (progress / file.size) * 100
                              : undefined
                          }
                        />
                      ) : (
                        <>
                          {pause === false && (
                            <Body>{fileSize.size + fileSize.name}</Body>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <BasicTypography bold={true} color="error">
                        {file.name}
                      </BasicTypography>
                      <BasicTypography bold={true} color="error">
                        {t(translations.ErrorDueUpload)}
                      </BasicTypography>
                    </>
                  )}
                </div>
                <div>
                  <IconButton
                    onClick={closeHandler}
                    title={t(translations.Close)}
                  >
                    <Icon icon="times" color={error ? 'danger' : 'black'} />
                  </IconButton>
                </div>
              </div>
            </div>
            {(error || pause) && (
              <div>
                <Button
                  variant="ghost"
                  size="small"
                  startIcon={<Icon icon="file-upload" size="xs" />}
                  onClick={() => {
                    if (uploadLargeFile.busy === false) {
                      setPause(false);
                      setInProgress(true);
                      setError(false);
                      uploadLargeFile.upload(
                        file,
                        chunkSendResult,
                        {
                          start: progress,
                          part: part,
                        },
                        uploadEnd,
                        targetId,
                        CompletionResult,
                      );
                    }
                  }}
                >
                  {t(translations.ReUploadFile)}
                </Button>
              </div>
            )}
          </CardContent>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

const getFileSize = (size): FileSize => {
  var string;
  if (size >= 1024 * 1024 * 1024 * 1024) {
    size = size / (1024 * 1024 * 1024 * 1024);
    string = 'TiB';
  } else if (size >= 1024 * 1024 * 1024) {
    size = size / (1024 * 1024 * 1024);
    string = 'GiB';
  } else if (size >= 1024 * 1024) {
    size = size / (1024 * 1024);
    string = 'MiB';
  } else if (size >= 1024) {
    size = size / 1024;
    string = 'KiB';
  } else {
    string = 'b';
  }
  return { size: Math.round(size), name: string };
};
