/**
 *
 * PriorityPicker
 *
 */
import * as React from 'react';
import {
  // Chip,
  // ChipProps,
  Menu,
  MenuItem,
  // Theme,
  // makeStyles,
} from '@material-ui/core';
import {
  ServiceRequestMilestoneStatusesUnionArray,
  ServiceRequestMilestoneStatusesUnion,
} from 'api/odata/generated/enums/ServiceRequestMilestoneStatuses';
import { useTranslation } from 'react-i18next';
// import { TypeColor, getColor } from 'styles/colors/types';
import { StatusLabel } from '../../../basic/StatusLabel';

export interface ServiceRequestMilestoneStatusMenuPickerProps {
  name?: string;
  value: ServiceRequestMilestoneStatusesUnion;
  onChange: (value: ServiceRequestMilestoneStatusesUnion) => void;
}
export function ServiceRequestMilestoneStatusMenuPicker({
  onChange,
  ...props
}: ServiceRequestMilestoneStatusMenuPickerProps) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleChipClick = event => {
    console.debug(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // const getChipColor = (
  //   status: ServiceRequestMilestoneStatusesUnion,
  // ): TypeColor => {
  //   switch (status) {
  //     case 'Completed':
  //       return 'success';
  //     case 'NotStarted':
  //       return 'default';
  //     case 'Started':
  //       return 'info';
  //   }
  // };
  const handleMenuItemClick = (
    status: ServiceRequestMilestoneStatusesUnion,
  ) => {
    onChange(status);
    handleClose();
  };
  return (
    <>
      <StatusLabel
        variant={
          props.value === 'Completed'
            ? 'success'
            : props.value === 'Started'
            ? 'info'
            : undefined
        }
        children={t(props.value)}
        onClick={handleChipClick}
      />
      {/*<StyledChip*/}
      {/*  onClick={handleChipClick}*/}
      {/*  label={t(props.value)}*/}
      {/*  color={getChipColor(props.value)}*/}
      {/*/>*/}
      <Menu
        open={anchorEl !== null}
        keepMounted
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        {ServiceRequestMilestoneStatusesUnionArray.map(status => (
          <MenuItem key={status} onClick={() => handleMenuItemClick(status)}>
            {t(status) ?? status}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
// const StyledChip = ({
//   color,
//   ...props
// }: Omit<ChipProps, 'color'> & { color: TypeColor }) => {
//   const classes = useStyles({ color: color });
//   return <Chip className={classes.root} {...props} />;
// };
// const useStyles = makeStyles<Theme, { color: TypeColor }>(theme => ({
//   root: {
//     backgroundColor: props => getColor(theme, props.color, 'light'),
//     color: props => getColor(theme, props.color, 'dark'),
//     '&:hover, &:focus': {
//       backgroundColor: props => getColor(theme, props.color, 'bgHover'),
//       color: props => getColor(theme, props.color, 'textHover'),
//     },
//   },
// }));
