import { selectAuthenticatedUser } from 'app/slice/selectors';
import { intersection } from 'lodash';
import { useSelector } from 'react-redux';
import { allowedroles } from './allowedroles';
import * as React from 'react';
import { IUserTrainingDto } from 'api/odata/generated/entities/IUserTrainingDto';
import { AuthenticatedUser } from 'types/AuthenticatedUser';

export function useUserTrainingPermissions() {
  const user = useSelector(selectAuthenticatedUser);
  const roles: number[] | undefined = user?.Roles;

  const callback = React.useCallback(
    (row: IUserTrainingPermissionCheckRecord): boolean =>
      allowedToViewUserTraining(user, row),
    [user],
  );
  const hasPermissions: boolean = intersection(roles, allowedroles).length > 0;
  const isAdmin = intersection(roles, allowedroles).length > 0;
  return {
    hasPermissions,
    allowedToView: callback,
    isAdmin,
  };
}

export type IUserTrainingPermissionCheckRecord = Pick<
  IUserTrainingDto,
  'ServiceGroupId' | 'EquipmentId'
>;
export const allowedToViewUserTraining = (
  user: AuthenticatedUser | undefined,
  row: IUserTrainingPermissionCheckRecord,
) => {
  if (!user) return false;
  return (
    user.HasAdminGroupOnlyPermissions(row.ServiceGroupId ?? undefined) ||
    user.HasAdminServiceOnlyPermissions(1, row.EquipmentId ?? undefined) ||
    user.HasLabTechGroupPermissions(row.ServiceGroupId ?? undefined) ||
    user.HasLabTechServicePermissions(1, row.EquipmentId ?? undefined)
  );
};
