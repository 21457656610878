import { PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from 'api/HttpClient';
import { SettingsApi } from 'api/SettingsApi';
import { internalIdLoginActions } from 'app/pages/Animals/InternalIdLoginPage/slice';
import { AxiosError } from 'axios';
import { push, replace } from 'connected-react-router';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import {
  call,
  put,
  takeLatest,
  delay,
  all,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import { AuthenticatedUser, IAuthenticatedUser } from 'types/AuthenticatedUser';
import { Entity } from 'types/common';
import { UserProfileSettings } from 'types/UserProfileSettings';
import { dateUtils, WeekStartsOn } from 'utils/date-utils';
import { UserProfile } from 'utils/userProfileSettings';
import { appSettingsActions as actions } from '.';
import {
  selectAllCoresSelectable,
  selectAllCoresSelectableCount,
  selectAppSettings,
  selectAuthenticatedUser,
  selectBarcodeScanTarget,
  selectGlobalSetting,
  selectGlobalSettingBool,
  selectSystemFirstDayOfWeek,
  selectUserProfileSettings,
} from './selectors';
import {
  LoginTypes,
  parseBarcode,
  SaveUserProfileSettingAction,
  ScanTarget,
  ScanTemplateResult,
} from './types';
import { Roles } from 'api/odata/generated/enums/Roles';
import { Location } from 'history';
import { URLSearchParamsCI } from 'app/components/BasicTable/types/FilterParam';
import { SavedViewSelected } from 'app/pages/SavedViewsPage/SavedViewPage/slice/types';
import { BARCODE_SCANNED } from 'react-usb-barcode-scanner';
import { AppSettings } from 'types/AppSettings';
import { toRootedURL } from 'utils/url-utils';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { IRoomDto } from 'api/odata/generated/entities/IRoomDto';
import { IODataQueryResponse } from 'api/odata/IODataQueryResponse';
import { tryParseInt } from 'utils/string-utils';
import { eventChannel } from 'redux-saga';
import { getLogger } from 'utils/logLevel';
import { AllowedSettings } from 'utils/globalSettings';
import { doPrefetchConfigurableColumns } from './workers/doPrefetchConfigurableColumns';
import { ISettingModel } from 'api/odata/generated/entities/ISettingModel';
import { setCookieLocale } from 'utils/cookies';
import { TermsOfUseAgreementApi } from 'api/TermsOfUseAgreementApi';
import { IResponseType } from 'types/ResponseType';
import { IUsers } from 'app/pages/UsersPage/IUsers';
import { takeLatestByKey } from 'utils/redux-saga';
import { PUBLIC_ASSETS_PATH } from 'app/pages/PublicAssets/PUBLIC_ASSETS_PATH';

export function* getAppSettings(action: PayloadAction<boolean>) {
  const requestURL = '/api/v3/appsettings';
  const noAuthURL = '/api/v3/appsettings/NotAuthenticatedSettings';
  const url = action.payload ? requestURL : noAuthURL;
  try {
    const appSettings: AppSettings = yield call(httpClient.get, url);
    yield put(actions.appSettingsLoaded(appSettings));
    //initialize the locale
    yield put(actions.setLocale(appSettings.Globalization.DefaultLocaleId));
    // For dev purposes - populate supportedLocales variable which is normally populated on the server side
    if (process.env.NODE_ENV === 'development') {
      if (appSettings.Globalization.SupportedLocales) {
        globalThis.supportedLocales =
          appSettings.Globalization.SupportedLocales.map(item => item.Id);
      }
    }

    if (!action.payload) {
      if (
        appSettings.noAuthSettings !== undefined &&
        appSettings.noAuthSettings !== null
      ) {
        if (
          (appSettings.noAuthSettings as ISettingModel[]).some(
            f => f.Id === 'NewUsersSignupForm' && f.Value === 'False',
          ) &&
          window.location.pathname.toLowerCase().indexOf('registeraccount') >= 0
        ) {
          window.location.replace(toRootedURL('/login'));
        }

        yield put(
          actions.loadSystemSettings_Success(appSettings.noAuthSettings),
        );
      }
    }
  } catch (err: unknown) {
    yield put(
      actions.loadError({
        message: 'fail -' + (err as AxiosError)?.response?.status,
      }),
    );
  }
}
function* getAuthenticatedUser(action: PayloadAction<boolean>) {
  const requestURL = '/api/v3/appsettings/authenticateduser';
  try {
    const response = yield call(httpClient.get, requestURL);
    const user = response as IAuthenticatedUser;
    user.LoginType = LoginTypes.Password;
    yield put(actions.getAuthenticatedUser_Success(user));
    if (user.Roles.indexOf(Roles.LargeAnimalsOperatingRoomTechnician) >= 0) {
      yield put(internalIdLoginActions.success(user));
    }
  } catch (err) {
    console.error(err);
    const status = (err as AxiosError)?.response?.status;
    if (status === 401 || !status || action.payload) {
      yield put(actions.setIsAuthenticated(false));
    } else {
      window.location.replace(toRootedURL('/login'));
    }
    yield put(actions.getAuthenticatedUser_Error('auth get fail'));
  }
}
function* doSetIsAuthenticated(action: PayloadAction<boolean>) {
  yield put(actions.IsAuthenticatedChanged(action.payload));
}
function* doChangeLanguage(action: PayloadAction<string | null>) {
  if (action.payload === null) {
    return;
  }
  try {
    const locale: string = action.payload;
    if (locale === undefined) {
      return;
    }

    const systemFirstDayOfWeek: WeekStartsOn = yield select(
      selectSystemFirstDayOfWeek,
    );
    const authenticatedUser: IAuthenticatedUser = yield select(
      selectAuthenticatedUser,
    );
    setCookieLocale(locale);

    yield all([
      call(l => {
        i18next.changeLanguage(l);
      }, locale),
      call(l => dateUtils.setLocale(l, systemFirstDayOfWeek), locale),
      call(
        httpClient.patch,
        `/api/odata/v4/Users/${encodeURIComponent(authenticatedUser.Id)}`,
        {
          localeId: locale,
        },
      ),
    ]);
  } catch (err) {
    console.error('setLanguage', err);
  }
}
function* getSiteMapSource() {
  const requestURL = '/api/v3/siteMapSettings';
  try {
    const siteMap = yield call(httpClient.get, requestURL);
    yield put(actions.loadSiteMapSettings_Success(siteMap));
  } catch (err) {
    yield put(actions.loadSiteMapSettings_Error('site map loding error'));
  }
}
function* doNavigate(action: PayloadAction<string>) {
  if (
    typeof action.payload === 'string' &&
    action.payload.indexOf('.aspx') > 0
  ) {
    window.location.href = toRootedURL(action.payload);
  } else {
    yield put(push(action.payload));
  }
}
function* getEventsCount() {
  const requestURL = '/api/v3/eventsCount';
  try {
    const count = yield call(httpClient.get, requestURL);
    yield put(actions.loadEventsCount_Success(count));
  } catch (err) {
    yield put(actions.loadEventsCount_Error('events count loading error'));
  }
}
function* getUserProfileSettings() {
  yield delay(500);
  const requestURL = '/api/v3/userProfile';
  try {
    const settings = yield call(httpClient.get, requestURL);
    yield put(actions.loadUserProfile_Success(settings));
  } catch (err) {
    yield put(
      actions.loadUserProfile_Error('user profile settings loading error'),
    );
  }
}
function* getSystemSettings() {
  yield delay(500);
  try {
    const settings = yield call(SettingsApi.getAlowedSystemSetting);
    yield put(actions.loadSystemSettings_Success(settings));
  } catch (err) {
    yield put(
      actions.loadSystemSettings_Error('user profile settings loading error'),
    );
  }
}
function* getLoginSystemSettings() {
  try {
    const settings = yield call(SettingsApi.getLoginSystemSettings);
    yield put(actions.loadLoginSystemSettings_Success(settings));
  } catch (err) {
    yield put(
      actions.loadLoginSystemSettings_Error('Login settings loading error'),
    );
  }
}
function* updateUserProfileSettings(
  action: PayloadAction<{
    key: string;
    model: UserProfileSettings;
    withDelay?: boolean;
  }>,
) {
  if (action.payload.withDelay) {
    yield delay(500);
  }
  const sett: UserProfile = yield select(selectUserProfileSettings);
  let item = sett.GetSettingByKeyOrDefault(
    action.payload.key,
  ) as UserProfileSettings;
  const payload = Object.assign({}, item, action.payload.model);
  const requestURL = `/api/v3/userProfile/update?key=${action.payload.key}`;
  try {
    const data = yield call(httpClient.put, requestURL, payload);
    yield put(actions.updateUserProfile_Success(data));
  } catch (err) {
    yield put(
      actions.updateUserProfile_Error('user profile settings loading error'),
    );
  }
}
function* doUpdateExpandedState(action: PayloadAction<boolean>) {
  const sett: UserProfile = yield select(selectUserProfileSettings);
  let item = sett.GetSettingByKeyOrDefault(
    'SideBarExpanded',
  ) as UserProfileSettings;
  const payload = Object.assign({}, item, { Enabled: action.payload });
  const requestURL = `/api/v3/userProfile/update?key=SideBarExpanded`;
  try {
    const data = yield call(httpClient.put, requestURL, payload);
    yield put(actions.updateExpandedState_Success(data));
  } catch (err) {
    yield put(
      actions.updateExpandedState_Error('user profile settings loading error'),
    );
  }
}
// function* doUpdateExpandedState_Local(action: PayloadAction<boolean>) {
//   yield put(actions.setExpandedState_Local(action.payload));
// }
function* doLoadGlobalServiceGroupFilter() {
  try {
    const url = '/api/odata/v4/servicegroups/GetGlobalServiceGroupsFilter';
    const params = { $select: 'Id,Name,SidebarQuickAction' };
    const data = yield call(httpClient.get, url, params);
    yield put(actions.loadGlobalServiceGroupFilter_Success(data.value));
    yield* doLoadSpecificServiceGroupFilter();
  } catch {
    //noop
  }
}
function* doLoadSpecificServiceGroupFilter() {
  try {
    const url = '/api/odata/v4/servicegroups/GetSpecificGroupsFilter';
    const params = { $select: 'Id,Name,SidebarQuickAction' };
    const data = yield call(httpClient.get, url, params);
    yield put(actions.loadSpecificServiceGroupFilter_Success(data.value));
  } catch {
    //noop
  }
}
function* doLoadAllServiceGroupsSelectable() {
  try {
    const url = '/api/odata/v4/servicegroups/GetSelectable';
    const params = { $count: true, $select: 'Id,Name,SidebarQuickAction' };
    const data = yield call(httpClient.get, url, params);
    yield put(actions.loadAllServiceGroupsSelectable_Success(data.value));
  } catch {
    //noop
  }
}
function* doSaveAllGlobalServiceGroupFilter(action: PayloadAction) {
  try {
    let allGroups = yield select(selectAllCoresSelectable);
    if (!allGroups) {
      const url = '/api/odata/v4/servicegroups/GetSelectable';
      const params = { $select: 'Id,Name,SidebarQuickAction' };
      const data = yield call(httpClient.get, url, params);
      allGroups = data.value;
    }

    const saveurl = '/api/odata/v4/servicegroups/SetGlobalServiceGroupsFilter';
    const savedata = { selection: allGroups.map(f => f.Id) };
    yield call(httpClient.post, saveurl, savedata);
    yield put(actions.loadGlobalServiceGroupFilter_Success(allGroups));
    yield put(
      actions.addNotification({
        key: 'topServiceGroups',
        variant: 'success',
        message: `${i18next.t(translations.SwitchedTo)} ${
          allGroups[0].Name
        } and + ${allGroups.length - 1} more`,
      }),
    );
  } catch {
    //noop
  }
}
function* doSaveGlobalServiceGroupFilter(
  action: PayloadAction<Entity<number>[]>,
) {
  const dashSubMenuDisabled: boolean = yield select(state =>
    selectGlobalSettingBool(state, AllowedSettings.HideDashboardSubMenu),
  );
  const allGroupsCount = yield select(selectAllCoresSelectableCount);
  if (!dashSubMenuDisabled && allGroupsCount > action.payload.length) {
    yield put(actions.loadSpecificServiceGroupFilter_Success(action.payload));
  }
  try {
    const url = '/api/odata/v4/servicegroups/SetGlobalServiceGroupsFilter';
    const data = { selection: action.payload.map(f => f.Id) };
    yield call(httpClient.post, url, data);
    yield put(actions.loadGlobalServiceGroupFilter_Success(action.payload));
    // yield* doLoadSpecificServiceGroupFilter();
    if (action.payload.length <= 3) {
      yield put(
        actions.addNotification({
          key: 'topServiceGroups',
          variant: 'success',
          message: `${i18next.t(translations.SwitchedTo)} ${action.payload
            .map(f => f.Name)
            .join(', ')}`,
        }),
      );
    } else {
      yield put(
        actions.addNotification({
          key: 'topServiceGroups',
          variant: 'success',
          message: `${i18next.t(translations.SwitchedTo)} ${
            action.payload[0].Name
          } and + ${action.payload.length - 1} more`,
        }),
      );
    }
  } catch {
    //noop
  }
}
function* doSaveUserProfileSetting(action: SaveUserProfileSettingAction) {
  try {
    // save the filter in the backend
    const url = `/api/v3/userProfile/update`;
    const httpPaload: Partial<UserProfileSettings> = {
      Value: action.payload.Value,
      Enabled: action.payload.Enabled,
    };

    yield call(httpClient.put, url, httpPaload, {
      params: { key: action.payload.Key },
    });
  } catch (err) {
    console.error(err);
  }
}
function* doSaveFilter(
  action: PayloadAction<{
    key: string;
    location: Location<unknown>;
    search: string;
  }>,
) {
  try {
    // push new search to browser history
    const search = action.payload.search;
    const noChangeInURL = new URLSearchParamsCI(search).equals(
      new URLSearchParamsCI(action.payload.location.search),
    );
    if (!noChangeInURL) {
      if (
        action.payload.location.search !== search ||
        (action.payload.location.search === '' && search === '')
      ) {
        yield put(
          actions.navigate({
            search: search,
          }),
        );
      }
    }

    const authenticatedUser: AuthenticatedUser = yield select(
      selectAuthenticatedUser,
    );
    yield* doSaveUserProfileSetting({
      type: action.type,
      payload: {
        Key: action.payload.key,
        Value: search.toString(),
        UserName: authenticatedUser.Id,
        Enabled: true,
      },
    });
  } catch (err) {
    console.error(err);
  }
}

function* doSavePageSize(
  action: PayloadAction<{ key: string; value: number }>,
) {
  try {
    const url = `/api/v3/userProfile/update`;
    const httpPaload: Partial<UserProfileSettings> = {
      Value: action.payload.value.toString(),
    };

    yield call(httpClient.put, url, httpPaload, {
      params: { key: action.payload.key },
    });
  } catch (err) {
    console.error(err);
  }
}
function* doSaveCardsMode(
  action: PayloadAction<{ key: string; value: boolean }>,
) {
  try {
    const url = `/api/v3/userProfile/update`;
    const httpPaload: Partial<UserProfileSettings> = {
      Value: action.payload.value.toString(),
    };

    yield call(httpClient.put, url, httpPaload, {
      params: { key: action.payload.key },
    });
  } catch (err) {
    console.error(err);
  }
}
function* doUpdateMyListSelected(
  action: PayloadAction<SavedViewSelected | undefined>,
) {
  yield put(actions.updateMyList(action.payload));
}

/**
 * Handle barcode scans
 * @param action action raised by barcode scanner middleware
 */
function* doBarcodeScan(action: PayloadAction<{ data: string }>) {
  const MIN_LENGTH_DEFAULT = 6;
  const minBarcodeLengthString: string | undefined = yield select(state =>
    selectGlobalSetting(state, AllowedSettings.MinBarcodeLength),
  );
  const internalUserIdLengthString: string | undefined = yield select(state =>
    selectGlobalSetting(state, AllowedSettings.InternalUserIdFieldLength),
  );
  const MinBarcodeLength =
    tryParseInt(minBarcodeLengthString) ?? MIN_LENGTH_DEFAULT;
  const internalUserIdLength = tryParseInt(internalUserIdLengthString) ?? 0;
  // prevent accidental barcode detection on fast user input
  if (action.payload.data.length < MinBarcodeLength) {
    return;
  }
  const scannTarget: ScanTarget | undefined = yield select(
    selectBarcodeScanTarget,
  );
  if (scannTarget !== undefined && scannTarget !== ScanTarget.All) {
    return;
  }
  const appSettings: AppSettings = yield select(selectAppSettings);
  yield put(
    actions.addNotification({
      variant: 'info',
      message:
        i18next.t(translations.BarcodeScanModalBody) +
        ' ' +
        action.payload.data,
    }),
  );
  try {
    // // in case and the barcode is a valid internal url - it should be opened in the current window
    // const path = parseBarcodeURL(action, appSettings);
    // if (path !== undefined) {
    //   // perform full navigation since not all target pages are react compatible
    //   // replace with yeild put(push(path)) when asset details + search are converted to react
    //   globalThis.window.location.href = toRootedURL(path);
    //   return;
    // }

    // // first lookup room by it's external barcode
    // let roomId = yield call(getRoomByExternalBarcode, action.payload.data);
    // if (roomId !== undefined) {
    //   window.location.href = toRootedURL('/Assets/default.aspx', {
    //     roomid: roomId,
    //   });
    //   return;
    // }
    const internalUserIdScanned =
      internalUserIdLength > 0 &&
      action.payload.data.length === internalUserIdLength;
    if (internalUserIdScanned) {
      yield* doScanUser(action);
      return;
    }
    const scanResult = parseBarcode(action.payload.data, appSettings);
    if (scanResult === undefined) {
      yield put(
        actions.addNotification({
          variant: 'error',
          message: i18next.t(translations.BarcodeNotFoundEx),
        }),
      );
      return;
    } else {
      switch (scanResult.type) {
        case ScanTemplateResult.Asset:
          yield put(push(''));
          yield put(replace(`Assets/Details?id=${scanResult.InternalCode}`));
          return;
        case ScanTemplateResult.Internal:
          let roomId = yield call(
            getRoomByExternalBarcode,
            scanResult.InternalCode ?? action.payload.data,
          );
          if (roomId !== undefined) {
            window.location.href = toRootedURL(PUBLIC_ASSETS_PATH, {
              roomid: roomId,
            });
            return;
          }
          break;
        case ScanTemplateResult.External:
          console.error(
            `${action.type}`,
            action.payload,
            'scanned external URL',
          );
          yield put(
            actions.addNotification({
              variant: 'error',
              message: 'Error: Scanned external URL',
            }),
          );
          return;
      }
    }

    // TODO: add other barcode lookups below

    yield put(
      actions.addNotification({
        variant: 'error',
        message: i18next.t(translations.BarcodeNotFoundEx),
      }),
    );
  } catch (error) {
    console.error(`${action.type}`, action.payload, error);
  }
}
function* doScanUser(action: PayloadAction<{ data: string }>) {
  const barcode = action.payload.data;
  const authenticatedUser: AuthenticatedUser = yield select(
    selectAuthenticatedUser,
  );
  const scannedUser = yield call(getScannedUser, barcode);
  if (scannedUser === undefined) {
    window.location.href = toRootedURL('/Errors/UserInternalIdNotFound.aspx', {
      internalId: barcode,
    });
    return;
  } else {
    if (authenticatedUser?.isLoanDesk()) {
      window.location.href = toRootedURL('/LoanDesk.aspx', {
        userId: scannedUser,
      });
    } else if (authenticatedUser?.isRoomAdmin()) {
      window.location.href = toRootedURL('/InstrumentRoom.aspx', {
        userId: scannedUser,
      });
    } else {
      window.location.href = toRootedURL('/Users/Profile', {
        id: scannedUser,
      });
    }
    return;
  }
}
async function getRoomByExternalBarcode(barcode: string) {
  try {
    var response: IODataQueryResponse<IRoomDto> = await httpClient.get(
      '/api/odata/v4/rooms',
      {
        $top: 1,
        $select: 'Id',
        $filter: new Condition<IRoomDto>(
          'ExternalBarcode',
          ODataOperators.Equals,
          barcode,
        ).toString(),
      },
    );
    var roomId = response.value[0]?.Id;
    return roomId;
  } catch (error) {}
}
async function getScannedUser(barcode: string) {
  try {
    const response: IODataQueryResponse<IUsers> = await httpClient.get(
      '/api/odata/v4/users',
      {
        $top: 1,
        $select: 'Name',
        $filter: new Condition<IUsers>(
          'UserInternalId',
          ODataOperators.Equals,
          barcode,
        ).toString(),
      },
    );
    const username = response.value[0]?.Name;
    return username;
  } catch (error) {}
}

function* handleFullScreenChange() {
  const isFullScreen = document.fullscreenElement !== null;
  if (isFullScreen) {
    yield put(actions.enterFullScreen_Success());
  } else {
    yield put(actions.exitFullScreen_Success());
  }
}
function* doEnterFullScreen(action: PayloadAction<{ selector?: string }>) {
  const log = getLogger('doEnterFullScreen');
  const selector = action.payload.selector;
  const x =
    selector === undefined
      ? document.documentElement
      : document.querySelector(selector);
  const fullScreenElement = (x ?? document.documentElement) as HTMLElement & {
    mozRequestFullScreen(): Promise<void>;
    webkitRequestFullscreen(): Promise<void>;
    msRequestFullscreen(): Promise<void>;
  };
  try {
    yield call;
    if (fullScreenElement.requestFullscreen) {
      fullScreenElement.requestFullscreen();
    }
    if (fullScreenElement.webkitRequestFullscreen) {
      fullScreenElement.webkitRequestFullscreen();
    }
    if (fullScreenElement.mozRequestFullScreen) {
      fullScreenElement.mozRequestFullScreen();
    }
    if (fullScreenElement.msRequestFullscreen) {
      fullScreenElement.msRequestFullscreen();
    }

    yield put(actions.enterFullScreen_Success());
    log.info('Entered FullScreen', fullScreenElement);
  } catch (error) {
    log.info('Error entering full screen mode', error);
    yield put(
      actions.addNotification({
        variant: 'error',
        message: 'Failed to enter full screen mode. ' + String(error),
      }),
    );
  }
}
function* doExitFullScreen() {
  const fullScreenExitFunction = document as Document & {
    mozCancelFullScreen(): Promise<void>;
    webkitExitFullscreen(): Promise<void>;
    msExitFullscreen(): Promise<void>;
  };
  if (document.fullscreenElement !== null) {
    if (fullScreenExitFunction.exitFullscreen) {
      fullScreenExitFunction.exitFullscreen();
    } else if (fullScreenExitFunction.mozCancelFullScreen) {
      /* Firefox */
      fullScreenExitFunction.mozCancelFullScreen();
    } else if (fullScreenExitFunction.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      fullScreenExitFunction.webkitExitFullscreen();
    } else if (fullScreenExitFunction.msExitFullscreen) {
      /* IE/Edge */
      fullScreenExitFunction.msExitFullscreen();
    }
  }
  yield put(actions.exitFullScreen_Success());
}
function* doSetBarcodeScannigTarget(
  action: PayloadAction<ScanTarget | undefined>,
) {
  yield put(actions.barcodeScannigTarget_Success(action.payload));
}
function* doShowAllCores(action: PayloadAction<boolean | undefined>) {
  yield put(actions.setShowAllCores(action.payload));
}
function* doCollapseSubBar(action: PayloadAction<boolean | undefined>) {
  yield put(actions.setCollapseSubBar(action.payload));
}
function* doAgreeTermOfUse(action: PayloadAction<number>) {
  try {
    const result = yield call(
      TermsOfUseAgreementApi.agreeTermsOfUseAgreement,
      action.payload,
    );
    const response = result as IResponseType;
    if (response.ErrorMessages.length > 0) {
      yield put(
        actions.addNotification({
          variant: 'error',
          message: response.ErrorMessages[0],
        }),
      );
    } else {
      yield put(actions.getAuthenticatedUser(false));
      yield put(actions.navigate('/dashboards'));
    }
  } catch (err) {
    console.error(err);
  }
}

export function* getAppSettingsFromSaga() {
  yield takeLatest(actions.loadAppSettings.type, getAppSettings);
  yield takeLatest(actions.getAuthenticatedUser.type, getAuthenticatedUser);
  yield takeLatest(actions.loadSiteMapSettings.type, getSiteMapSource);
  yield takeLatest(actions.setLocale.type, doChangeLanguage);
  yield takeLatest(actions.loadEventsCount.type, getEventsCount);
  yield takeLatest(
    actions.loadUserProfileSettings.type,
    getUserProfileSettings,
  );
  yield takeLatestByKey<{
    key: string;
    model: UserProfileSettings;
    withDelay?: boolean;
  }>(
    actions.updateUserProfileSettings.type,
    p => p.key,
    updateUserProfileSettings,
  );
  yield takeLatest(actions.setIsAuthenticated.type, doSetIsAuthenticated);
  yield takeLatest(actions.updateExpandedState.type, doUpdateExpandedState);
  // yield takeLatest(
  //   actions.updateExpandedState_Local.type,
  //   doUpdateExpandedState_Local,
  // );
  yield takeLatest(actions.navigate.type, doNavigate);
  yield takeLatest(
    actions.loadGlobalServiceGroupFilter.type,
    doLoadGlobalServiceGroupFilter,
  );
  yield takeLatest(
    actions.loadSpecificServiceGroupFilter.type,
    doLoadSpecificServiceGroupFilter,
  );
  yield takeLatest(
    actions.saveGlobalServiceGroupFilter.type,
    doSaveGlobalServiceGroupFilter,
  );
  yield takeLatest(
    actions.saveAllGlobalServiceGroupFilter.type,
    doSaveAllGlobalServiceGroupFilter,
  );

  yield takeLatest(
    actions.loadAllServiceGroupsSelectable.type,
    doLoadAllServiceGroupsSelectable,
  );
  yield takeLatest(
    actions.saveUserProfileSetting.type,
    doSaveUserProfileSetting,
  );
  yield takeLatest(actions.saveFilter.type, doSaveFilter);
  yield takeLatest(actions.loadSystemSettings.type, getSystemSettings);
  yield takeLatest(
    actions.loadLoginSystemSettings.type,
    getLoginSystemSettings,
  );
  // currently prefetch only depends on the modules (appSettings), so it made sense to fire those immediately after the modules availability
  yield takeLatest(
    actions.appSettingsLoaded.type,
    doPrefetchConfigurableColumns,
  );
  yield takeLatest(actions.savePageSize.type, doSavePageSize);
  yield takeLatest(
    actions.updateMyListSelected_Local.type,
    doUpdateMyListSelected,
  );
  yield takeLatest(BARCODE_SCANNED, doBarcodeScan);
  yield takeLatest(actions.manualScanBarcode, doBarcodeScan);
  yield takeLatest(actions.saveCardsMode.type, doSaveCardsMode);
  const fullScreenChannel = eventChannel(emitter => {
    document.addEventListener('fullscreenchange', emitter);
    return () => document.removeEventListener('fullscreenchange', emitter);
  });
  yield takeEvery(fullScreenChannel, handleFullScreenChange);
  yield takeLeading(actions.enterFullScreen, doEnterFullScreen);
  yield takeLeading(actions.exitFullScreen, doExitFullScreen);
  yield takeLatest(
    actions.setBarcodeScannigTarget.type,
    doSetBarcodeScannigTarget,
  );
  yield takeLatest(actions.showAllCores.type, doShowAllCores);
  yield takeLatest(actions.collapseSubBar.type, doCollapseSubBar);
  yield takeLatest(actions.agreeTermOfUse.type, doAgreeTermOfUse);
}
