import { orderBy } from 'lodash';
import { MenuItemEntry, MenuItemEntryL1, MenuItems } from '../types';

/**
 * Find a matching menu entry given current path name
 * @param menuItems
 * @param pathName
 * @returns
 */
export function findSelectedMenuEntry(menuItems: MenuItems, pathName?: string) {
  if (pathName === undefined) {
    return undefined;
  }
  if (menuItems.length === 0) {
    return undefined;
  }
  const loweredPathName = pathName.toLowerCase();
  const matches: Array<
    { level: number; isExact: boolean; item: MenuItemEntry } | undefined
  > = menuItems
    // combine back grouped entries, their primary children as well as admin settings
    .flatMap(f => f)
    .flatMap(f => (f.items ?? []).concat(f))
    .filter(f => f.external !== true)
    .map(item => {
      if (item === undefined) {
        return undefined;
      }
      if (item.link === undefined) {
        return undefined;
      }
      const loweredLink = item.link.toLowerCase();
      const level =
        ((item as MenuItemEntryL1).items?.length ?? 0) === 0 ? 2 : 1;
      const result = {
        item: item,
        level: level,
      };
      if (loweredLink === loweredPathName) {
        return {
          ...result,
          isExact: true,
        };
      } else if (
        loweredLink?.startsWith(loweredPathName) ||
        loweredPathName?.startsWith(loweredLink)
      ) {
        return {
          ...result,
          isExact: false,
        };
      } else {
        return undefined;
      }
    })
    .filter(f => f !== undefined);
  // prioritize second level menus, exact matches
  const sortedMatches = orderBy(
    matches,
    [
      f => f?.level,
      f => (f?.isExact === true ? 0 : 1),
      f => f?.item.link?.length,
    ],
    ['desc', 'asc', 'desc'],
  );
  return sortedMatches[0]?.item;
}
