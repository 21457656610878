import {
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import { Icon } from 'app/components/BasicIcons/FontAwesome';
import {
  selectAppSettings,
  selectAuthenticatedUser,
  selectglobalSettings,
} from 'app/slice/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DetectIsMobile } from 'utils/mobileDetect';
import { Body } from 'app/components/Typography';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { messages } from '../messages';
import UserMenuButton from './UserMenuButton';
import { AllowedSettings } from 'utils/globalSettings';
import { IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { toRootedURL } from 'utils/url-utils';
import { translations } from 'locales/translations';
import { useTheme } from '@material-ui/core/styles';
import { StyledUserMenu } from '../../styled';
import { BasicToggle } from 'app/components/BasicToggle';
import { isInRole } from 'types/AuthenticatedUser';
import { Roles } from 'api/odata/generated/enums/Roles';
import { RouterLink } from 'app/components/BasicLinks/RouterLink';

export interface UserMenuProps {}

export function UserMenu(props: UserMenuProps) {
  const { t } = useTranslation();
  const isMobile = DetectIsMobile();
  const [open, setOpen] = React.useState(false);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const settings = useSelector(selectglobalSettings);
  const appSettings = useSelector(selectAppSettings);
  const googleCalendarModuleEnabled = IsModuleEnabled(
    appSettings,
    KnownModules.GoogleCalendar,
  );
  const multipleAdGroupsModuleEnabled = IsModuleEnabled(
    appSettings,
    KnownModules.MultipleAdGroups,
  );
  const serviceGroupRegistrationModuleEnabled = IsModuleEnabled(
    appSettings,
    KnownModules.ServiceGroupRegistration,
  );
  const isAdmin =
    isInRole(authenticatedUser, Roles.Administrators) ||
    isInRole(authenticatedUser, Roles.GroupAdministrators);
  const googleCalendar = googleCalendarModuleEnabled; // && authenticatedUser?.CalendarType === 0;
  const outlookCalendar = googleCalendarModuleEnabled; // && authenticatedUser?.CalendarType === 1;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const Opt_Out = settings.GetBooleanByKey(AllowedSettings.AllowUsersToOpt_Out);
  const theme = useTheme();
  return (
    <React.Fragment>
      <UserMenuButton
        aria-controls="user-profile-menu"
        aria-haspopup="true"
        onClick={event => handleClick(event)}
        open={open}
        isMobile={isMobile}
        startIcon={
          <Icon
            icon="user-circle"
            fontSize="20px"
            color="black"
            colorExtend="main"
          />
        }
      >
        {isMobile && (
          <Icon
            icon="user-circle"
            fontSize="20px"
            color="black"
            colorExtend="main"
          />
        )}
        {!isMobile && authenticatedUser?.DisplayName}
      </UserMenuButton>
      <StyledUserMenu
        theme={theme}
        marginThreshold={1}
        id="user-profile-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isMobile ? 'right' : 'center',
        }}
        open={Boolean(anchorEl)}
        PaperProps={
          isMobile
            ? {
                style: {
                  width: '100%',
                  height: '100%',
                  maxHeight: 'calc(100% - 32px)',
                  maxWidth: 'calc(100% - 0px)',
                },
              }
            : undefined
        }
        onClose={() => handleClose()}
      >
        {isMobile && (
          <MenuItem dense onClick={() => handleClose()}>
            <ListItemIcon>
              <Icon icon="times" color="default" colorExtend="textHover" />
            </ListItemIcon>
            <ListItemText>
              <Body bold={true}>{authenticatedUser?.DisplayName}</Body>
            </ListItemText>
          </MenuItem>
        )}
        {isMobile && <Divider />}
        <MenuItem
          component={'a'}
          href={toRootedURL(`/Users/Profile?id=${authenticatedUser?.Id}`)}
          target={'_blank'}
          onClick={() => handleClose()}
        >
          <ListItemIcon>
            <Icon icon="pencil" color="default" colorExtend="textHover" />
          </ListItemIcon>
          <ListItemText>{t(messages.userDetails())}</ListItemText>
        </MenuItem>
        {googleCalendar && (
          <MenuItem
            component={'a'}
            target={'_blank'}
            href={toRootedURL('/GoogleCalendarLog.aspx')}
            onClick={() => handleClose()}
          >
            <ListItemIcon>
              <Icon
                icon={['fab', 'google']}
                color="default"
                colorExtend="textHover"
              />
            </ListItemIcon>
            <ListItemText>{t(messages.googleCalendar())}</ListItemText>
          </MenuItem>
        )}
        {outlookCalendar && (
          <MenuItem
            component={'a'}
            href={toRootedURL('/OutlookCalendarLog.aspx')}
            target={'_blank'}
            onClick={() => handleClose()}
          >
            <ListItemIcon>
              <Icon
                icon={['fab', 'windows']}
                color="default"
                colorExtend="textHover"
              />
            </ListItemIcon>
            <ListItemText>{t(messages.outlookCalendar())}</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          component={'a'}
          href={toRootedURL('ChangePassword.aspx')}
          target={'_blank'}
          onClick={() => handleClose()}
        >
          <ListItemIcon>
            <Icon icon="lock" color="default" colorExtend="textHover" />
          </ListItemIcon>
          <ListItemText>{t(messages.changePassword())}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleClose()}
          component={'a'}
          href={toRootedURL('/ActiveUserGroup/shrink')}
          disabled={
            !(
              (authenticatedUser?.UserGroups?.length || 1) > 1 &&
              multipleAdGroupsModuleEnabled
            )
          }
        >
          <ListItemIcon>
            <Icon icon="users" color="default" colorExtend="textHover" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Tooltip
                title={
                  (authenticatedUser?.UserGroups?.length || 1) > 1 &&
                  multipleAdGroupsModuleEnabled
                    ? t(translations.SwitchFromToAnotherUserGroup).replace(
                        '{0}',
                        (authenticatedUser?.ActiveUserGroup?.Name as string) ??
                          (authenticatedUser?.ActiveUserGroup?.Id as string),
                      )
                    : authenticatedUser?.ActiveUserGroup?.Name ??
                      authenticatedUser?.ActiveUserGroup?.Id
                }
              >
                <span>
                  {(authenticatedUser?.UserGroups?.length || 1) > 1 &&
                  multipleAdGroupsModuleEnabled
                    ? t(translations.SwitchFrom).replace(
                        '{0}',
                        (authenticatedUser?.ActiveUserGroup?.Name as string) ??
                          (authenticatedUser?.ActiveUserGroup?.Id as string),
                      )
                    : authenticatedUser?.ActiveUserGroup?.Name ??
                      authenticatedUser?.ActiveUserGroup?.Id}
                </span>
              </Tooltip>
            }
          ></ListItemText>
        </MenuItem>
        {Opt_Out && (
          <MenuItem onClick={() => handleClose()}>
            <BasicToggle
              name="check_optout"
              label={t(messages.optOutNotifications())}
              info={<>t(messages.optOutNotificationsTitle())</>}
            />
          </MenuItem>
        )}
        {(authenticatedUser?.HasRegisterableServiceGroups || false) &&
          serviceGroupRegistrationModuleEnabled && (
            <MenuItem
              onClick={() => handleClose()}
              component={'a'}
              href={toRootedURL('/coreselection')}
              target={'_blank'}
            >
              <ListItemIcon>
                <Icon
                  icon="layer-group"
                  color="default"
                  colorExtend="textHover"
                />
              </ListItemIcon>
              <ListItemText>{t(messages.coreRegistration())}</ListItemText>
            </MenuItem>
          )}
        <MenuItem
          onClick={() => handleClose()}
          component={RouterLink}
          to={'/NotificationSettings'}
        >
          <ListItemIcon>
            <Icon
              icon={authenticatedUser?.HasNotifications ? 'bell' : 'bell-slash'}
              color="default"
              colorExtend="textHover"
            />
          </ListItemIcon>
          <ListItemText>{t(messages.notifications())}</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => handleClose()}
          component={'a'}
          href={toRootedURL(isAdmin ? '/Admin/default.aspx' : '/help.aspx')}
          target={'_blank'}
        >
          <ListItemIcon>
            <Icon
              icon="question-circle"
              color="default"
              colorExtend="textHover"
            />
          </ListItemIcon>
          <ListItemText>{t(messages.help())}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleClose()}
          component={'a'}
          href={toRootedURL('/account/signout')}
        >
          <ListItemIcon>
            <Icon icon="sign-out-alt" color="default" colorExtend="textHover" />
          </ListItemIcon>
          <ListItemText>{t(messages.logout())}</ListItemText>
        </MenuItem>
      </StyledUserMenu>
    </React.Fragment>
  );
}
