import { IRepetitiveDto, RepetitiveInit } from 'types/IRepetitiveDto';
import { RepetitiveForm } from '.';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useRepetitiveSlice } from './slice';
import { Portal } from '@material-ui/core';
import { useRef } from 'react';
import { Skeleton } from '@material-ui/lab';
import { dateUtils } from 'utils/date-utils';
import { MutableRefObject } from 'react';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { RepetitiveFor } from './slice/types';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { Div100 } from 'app/components/AssetQuickSearch/styled';
export interface RepetitiveControlProps {
  startDate?: Date | null;
  saveRepetitive?: (values: IRepetitiveDto) => void;
  submitFormRef: MutableRefObject<any>;
  title?: string;
  type: RepetitiveFor;
  disabled?: boolean;
  formIsValid?: (isValid: boolean) => void;
  withTopSeparator?: boolean;
  withBottomSeparator?: boolean;
}

export function RepetitiveControl(props: RepetitiveControlProps) {
  const {
    saveRepetitive,
    submitFormRef,
    startDate,
    title,
    type,
    disabled,
    formIsValid,
    withTopSeparator,
    withBottomSeparator,
  } = props;
  const dispatch = useDispatch();
  const { actions } = useRepetitiveSlice();
  const [initData, setInitData] = React.useState<IRepetitiveDto | undefined>(
    undefined,
  );
  const { newDate } = useSystemDate();
  useEffectOnMount(() => {
    setInitData(() => RepetitiveInit(startDate ?? newDate()));
    return () => {};
  });

  const portalRef = useRef(null);
  const bindSubmitForm = React.useCallback(
    submitForm => {
      submitFormRef.current = submitForm;
    },
    [submitFormRef],
  );
  const handleSubmit = React.useCallback(
    (values: IRepetitiveDto) => {
      if (saveRepetitive) {
        saveRepetitive(values);
      } else {
        var d = {
          StartDate:
            values.StartDate === undefined
              ? undefined
              : dateUtils.formatISO(
                  dateUtils.dateOrStringToDate(values.StartDate),
                ),
          RecurringEvents: values.RecurringEvents,
          RepeatsOnDays: values.RepeatsOnDays,
          RecurringEndDate:
            values.RecurringEndDate === undefined
              ? undefined
              : dateUtils.formatISO(
                  dateUtils.dateOrStringToDate(values.RecurringEndDate),
                ),
          RepeatsEveryDay: values.RepeatsEveryDay,
          RepeatsEveryPeriod: values.RepeatsEveryPeriod,
          RepeatsMonthlyBy: values.RepeatsMonthlyBy,
        } as IRepetitiveDto;
        dispatch(actions.saveState(d));
      }
    },
    [actions, dispatch, saveRepetitive],
  );
  return initData ? (
    <React.Fragment>
      <Div100 ref={portalRef} />
      <Portal container={portalRef.current}>
        <RepetitiveForm
          onSubmit={handleSubmit}
          disabled={disabled}
          formIsValid={formIsValid}
          RepetitiveData={initData ?? undefined}
          bindSubmitForm={bindSubmitForm}
          title={title}
          type={type}
          withBottomSeparator={withBottomSeparator}
          withTopSeparator={withTopSeparator}
        />
      </Portal>
      {/* <SaveFormButton onClick={handleSubmitForm} edit={false} text="Rep Save" /> */}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Skeleton variant="rect" />
    </React.Fragment>
  );
}
