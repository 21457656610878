/**
 *
 * RoomPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { IRoomDto } from 'api/odata/generated/entities/IRoomDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import {
  selectGlobalServiceGroupFilter,
  selectIsAuthenticated,
} from 'app/slice/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Entity, Identifiable } from 'types/common';

export type RoomsUrl = 'base' | 'assetBase' | 'public' | 'locations';
export interface RoomPickerProps
  extends Omit<AutocompletePickerProps<IRoomDto>, 'loadData'> {
  urlType?: RoomsUrl;
  selectArray?: Array<keyof IRoomDto>;
  withoutServiceGroups?: boolean;
}
export const convertRoomWithBuildingName = (
  Name: string,
  BuildingName: string,
): string => {
  let name = BuildingName !== '' ? `${Name} - ${BuildingName}` : Name;
  return name;
};
export const modifyRoomName = (room: IRoomDto | null): IRoomDto | null => {
  if (room !== null && room !== undefined) {
    return Object.assign(
      {},
      {
        Name: convertRoomWithBuildingName(room.Name, room.BuildingName ?? ''),
      },
      room,
    );
  }
  return null;
};
const url = '/api/odata/v4/Rooms/GetRoomsFilter';
const publicUrl = '/api/odata/v4/Rooms/GetPublicAssetsRoomsFilter';
const assetRoomUrl = '/api/odata/v4/Rooms/GetAssetsRoomsFilter';
const LocationsUrl = '/api/odata/v4/LocationsBuilding';
export const initRoomData = (
  initval: string | undefined,
  sourceUrl?: RoomsUrl,
  withoutServiceGroups?: boolean,
  selectArray?: Array<keyof IRoomDto>,
): Promise<IRoomDto[]> => {
  if (initval === undefined) {
    return new Promise<IRoomDto[]>((resolve, reject) => {
      resolve([] as IRoomDto[]);
    });
  } else {
    if (sourceUrl === 'locations') {
      withoutServiceGroups = true;
    }
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: !!selectArray
        ? selectArray.join(',')
        : (withoutServiceGroups === undefined ||
            withoutServiceGroups === false) &&
          !!sourceUrl &&
          sourceUrl !== 'base'
        ? 'Id,Name,BuildingId,BuildingName,ServiceGroupsIds'
        : 'Id,Name,BuildingId,BuildingName',
      $skip: 0,
      $top: 1,
    };
    let initUrl =
      sourceUrl === 'public'
        ? publicUrl
        : sourceUrl === 'assetBase'
        ? assetRoomUrl
        : sourceUrl === 'locations'
        ? LocationsUrl
        : url;
    return httpClient
      .get(initUrl, params)
      .then(response => response.value as IRoomDto[]);
  }
};
const loadData = (
  predicates: (string | Condition<IRoomDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
  isAuthenticated?: boolean,
  urlType?: RoomsUrl,
  withoutServiceGroups?: boolean,
  selectArray?: Array<keyof IRoomDto>,
) => {
  if (urlType === 'locations') {
    withoutServiceGroups = true;
  }
  const sourceUrl =
    urlType === 'public' || !isAuthenticated
      ? publicUrl
      : urlType === 'assetBase'
      ? assetRoomUrl
      : urlType === 'locations'
      ? LocationsUrl
      : url;
  return getAutoCompleteLoadDataFn<IRoomDto>({
    url: sourceUrl,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select:
      selectArray ??
      ((withoutServiceGroups === undefined || withoutServiceGroups === false) &&
      !!urlType &&
      urlType !== 'base'
        ? ['Id', 'Name', 'BuildingId', 'BuildingName', 'ServiceGroupsIds']
        : ['Id', 'Name', 'BuildingId', 'BuildingName']),
    maxTop: 100,
    globalServiceGroupFilter:
      (withoutServiceGroups === undefined || withoutServiceGroups === false) &&
      !!globalServiceGroupFilter &&
      globalServiceGroupFilter.length > 0 &&
      !!urlType &&
      urlType !== 'base'
        ? [
            new Condition<IRoomDto>(
              'ServiceGroupsIds',
              ODataOperators.HasNoAny,
              globalServiceGroupFilter.map(f => f.Id),
            ),
            new Condition<IRoomDto>(
              'ServiceGroupsIds',
              ODataOperators.AnyIn,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
    isOptionalServiceGroup: true,
  });
};

export function RoomPicker(props: RoomPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const isAuth = useSelector(selectIsAuthenticated);
  const innerLoadData = loadData(
    props.predicates,
    isAuth ? globalServiceGroupFilter || [] : [],
    isAuth,
    props.urlType,
    props.withoutServiceGroups,
    props.selectArray,
  );
  return (
    <AutocompletePicker
      loadData={innerLoadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'roomId'}
      {...props}
    />
  );
}

export const RoomByBuildingFilter = (
  value: FilterValueType,
  fieldName: any,
) => {
  if (value !== undefined && value !== null) {
    if (fieldName === 'BuildingId') {
      return [
        `${new Condition<IRoomDto>(
          'BuildingId',
          ODataOperators.Equals,
          (value as Identifiable<number>).Id,
        ).toString()}`,
      ];
    }
    if (fieldName === 'ServiceGroupId') {
      return [
        `${new Condition<IRoomDto>(
          'ServiceGroupsIds',
          ODataOperators.AnyIn,
          (value as Identifiable<number>).Id,
        ).toString()}`,
      ];
    }
  }
  return [];
};
