import Badge from '@material-ui/core/Badge';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import * as React from 'react';
import { IconButton } from '../IconButton';

export interface FilterIconButtonProps {
  filterLength: number;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
  innerRef?: React.RefObject<HTMLButtonElement>;
  id?: string;
}

export function FilterIconButton({
  filterLength: filterLengs,
  handleClick: onClick,
  id,
  ...props
}: FilterIconButtonProps) {
  return (
    <Badge
      key={0}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      badgeContent={filterLengs}
      showZero={false}
      color="primary"
    >
      <IconButton innerRef={props.innerRef} onClick={onClick} id={id}>
        <Icon
          icon="filter"
          size="sm"
          color={filterLengs > 0 ? 'primary' : 'default'}
          colorExtend={filterLengs > 0 ? 'main' : 'dark'}
        />
      </IconButton>
    </Badge>
  );
}
