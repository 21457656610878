import { useTranslation } from 'react-i18next';
import { SavedViewDetailsState, SavedViewExtraData } from '../slice/types';
import * as React from 'react';
import { translations } from 'locales/translations';
import { FormAssetsPicker } from 'app/components/Forms/FormAssetsPicker';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { Formik } from 'formik';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormSwitch } from 'app/components/Forms/Switch';
import { getsavedViewSchema } from './validationSchema';
import { AssetsFilterForSavedView } from 'app/components/pickers/MultiSelectPickers/AssetsPicker';
import { useSelector } from 'react-redux';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import {
  IAssetDto,
  mapAssetsToServiceTypeFilterDto,
} from 'api/odata/generated/entities/IAssetDto';
import { Entity } from 'types/common';
import { Body } from 'app/components/Typography';
import { dateUtils } from 'utils/date-utils';

export interface SavedViewFormProps {
  onSubmit: (item: SavedViewDetailsState) => void;
  initialValues: SavedViewDetailsState;
  processing?: boolean;
  bindSubmitForm: any;
  isEdit: boolean;
  isAdmin: boolean;
  user?: AuthenticatedUser;
  onEquipmentClicked?: (equipment: Entity<number>) => void;
  viewOnly: boolean;
  ExtraData?: SavedViewExtraData;
}

export const SavedViewForm = React.memo(function SavedViewForm({
  onSubmit,
  processing,
  initialValues,
  bindSubmitForm,
  isEdit,
  isAdmin,
  user,
  viewOnly,
  ExtraData,
  onEquipmentClicked,
}: SavedViewFormProps) {
  const { t } = useTranslation();
  const selectedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(value);
    }
  };
  // const areTheSameServiceGroup = (assets: IAssetDto[]): boolean => {
  //   let groupId = assets.filter(
  //     f => f.ServiceGroupId != null && f.ServiceGroupId !== undefined,
  //   )[0].ServiceGroupId;
  //   return assets.every(
  //     v =>
  //       v.ServiceGroupId === null ||
  //       v.ServiceGroupId === undefined ||
  //       v.ServiceGroupId === groupId,
  //   );
  // };
  const userIsServiceGroupAdmin = (assets: IAssetDto[]): boolean => {
    return (
      user?.IsAllGroupOrLabTechAdmin(
        assets.map(g => g.ServiceGroupId ?? 0),
        mapAssetsToServiceTypeFilterDto(assets),
      ) || false
    );
  };
  const savedViewSchema = React.useMemo(() => getsavedViewSchema(t), [t]);
  return (
    <>
      <Formik
        validationSchema={savedViewSchema}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnBlur={false}
        onSubmit={async (values, formikHelpers) => {
          if (viewOnly) return;
          formikHelpers.setSubmitting(true);
          handleSubmit(values);
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          return (
            <>
              <StyledForm onSubmit={formik.handleSubmit}>
                <FormLeftSection>
                  {isEdit && !!ExtraData && (
                    <FormFieldsSection>
                      <>
                        <FormRow>
                          <Body bold>{t(translations.CreatedBy)}</Body>
                          <Body>{ExtraData.userName}</Body>
                        </FormRow>
                        <FormRow>
                          <Body bold>{t(translations.CreatedAt)}</Body>
                          <Body>
                            {dateUtils.shortDateTimeFormat(ExtraData.createdAt)}
                          </Body>
                        </FormRow>
                        <FormRow>
                          <Body bold>{t(translations.TimesUsed)}</Body>
                          <Body>{ExtraData.usages}</Body>
                        </FormRow>
                      </>
                    </FormFieldsSection>
                  )}
                  <FormFieldsSection>
                    <FormRow>
                      <FormTextField
                        name="Name"
                        label={t(translations.SavedViewName)}
                        placeholder={t(translations.SavedViewName)}
                        disabled={viewOnly || formik.isSubmitting}
                        autoComplete="on"
                        id="SavedViewNameId"
                        fullWidth
                      />
                    </FormRow>
                    <FormRow fullRow={true}>
                      <FormAssetsPicker
                        filter={AssetsFilterForSavedView(selectedServiceGroups)}
                        onChipClick={onEquipmentClicked}
                        name="Assets"
                        label={t(translations.SavedViewAssets)}
                        placeholder={t(translations.SavedViewAssetsPlaceholder)}
                        disabled={viewOnly || formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow fullRow={true}>
                      <FormSwitch
                        boldLebel
                        name="Shared"
                        label={t(translations.SavedViewShared)}
                        disabled={
                          viewOnly ||
                          formik.isSubmitting ||
                          !(
                            formik.values.Assets?.length > 0 &&
                            userIsServiceGroupAdmin(formik.values.Assets)
                          )
                        }
                      />
                    </FormRow>
                    <FormRow fullRow={true}>
                      <FormSwitch
                        boldLebel
                        info={t(translations.SavedViewBookMultiple_info)}
                        name="Multiple"
                        label={t(translations.SavedViewBookMultiple)}
                        disabled={
                          viewOnly ||
                          formik.isSubmitting ||
                          formik.values.Assets?.length < 2
                        }
                      />
                    </FormRow>
                    {/* <FormRow hide={formik.values.Shared !== true}>
                        <FormSharedToPicker
                          name="SharedTo"
                          label={t(translations.SavedViewSharedTo)}
                          disabled={formik.isSubmitting}
                          fullWidth
                        />
                      </FormRow>

                      <FormRow
                        fullRow={true}
                        hide={
                          !(
                            formik.values.Shared &&
                            formik.values.SharedTo?.Id ===
                              SharedWithType.shareWithUsers
                          )
                        }
                      >
                        <FormUsersPicker
                          name="SharedUsers"
                          label={t(translations.SavedViewSharedUsers)}
                          placeholder={t(
                            translations.SavedViewSharedUsersPlaceholder,
                          )}
                          disabled={formik.isSubmitting}
                          fullWidth
                        />
                      </FormRow>
                      <FormRow
                        hide={
                          !(
                            formik.values.Shared &&
                            formik.values.SharedTo?.Id ===
                              SharedWithType.shareWithCores
                          )
                        }
                      >
                        <FormServiceGroupsPicker
                          name="SharedCores"
                          label={t(translations.SavedViewSharedCores)}
                          placeholder={t(
                            translations.SavedViewSharedCoresPlaceholder,
                          )}
                          disabled={formik.isSubmitting}
                          fullWidth
                        />
                      </FormRow> */}
                  </FormFieldsSection>
                </FormLeftSection>
              </StyledForm>
            </>
          );
        }}
      </Formik>
    </>
  );
});
