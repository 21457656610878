import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  selectExpandedSidePanel,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { RightSection } from '../wrappers';

export interface FormRightSectionProps {
  children: React.ReactNode;
  isCover?: boolean;
  useTopSpacing?: boolean;
}
export const FormRightSection = (props: FormRightSectionProps) => {
  const { children, isCover, useTopSpacing = true } = props;
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const showShortView = (sidePanelOpen && !sidePanelExpanded) || isCover;
  const theme = useTheme();
  return (
    <RightSection
      className={clsx('rightsection', {
        shortView: showShortView,
        topSpacing: useTopSpacing,
      })}
      theme={theme}
    >
      {children}
    </RightSection>
  );
};
