import React from 'react';

export interface ISystemDate {
  /**
   * Returns current date and time in the current user's timezone
   */
  newDate: () => Date;
}

export const useSystemDate = (): ISystemDate => {
  const newDate = React.useCallback(() => {
    return new Date();
  }, []);

  return {
    newDate,
  };
};
