/**
 *
 * Asynchronously loads the component for SubscribersPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SubscribersPage = lazyLoad(
  () => import('./index'),
  module => module.Subscribers,
);
