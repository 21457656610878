import { useFormikContext } from 'formik';
import { Entity } from 'types/common';
import * as React from 'react';
import { GlobalSettingsType } from '../useGlobalSettingsHook';

import { AuthenticatedUser } from 'types/AuthenticatedUser';

import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { getWorkFlowName } from '../utils';
import { ReservationDetailsState } from '../../slice/types';
import { isMember } from './isMember';
import { UsageDetailsState } from 'app/pages/UsageDetails/Details/slice/types';

type DetailsState = ReservationDetailsState | UsageDetailsState;
export interface UserNameListenerProps<T extends DetailsState> {
  userName: Entity<string> | null;
  equipments: Entity<number>[];
  globalSettings: GlobalSettingsType;
  user: AuthenticatedUser | undefined;
  isEdit: boolean;
  reservationUserGroupGroupByBudgetUserGroup: boolean;
  isReservations?: boolean;
  //changeHandler: (values: T, changeStatus?: boolean | undefined) => void;
  values: T;
  wait?: boolean;
}
export function UserNameListener<T extends DetailsState>(
  props: UserNameListenerProps<T>,
) {
  const {
    userName,
    equipments,
    globalSettings,
    reservationUserGroupGroupByBudgetUserGroup,
    isReservations,
    wait,
  } = props;

  //const equipmentSettings = useSelector(selectEquipmentsSettings);
  const [initilized, setInitilized] = React.useState(true);
  // const ReservationUserGroupGroupByBudgetUserGroup = React.useMemo(() => {
  //   return !!equipmentSettings
  //     ? globalSettings.reservationUserGroupGroupByBudgetUserGroup &&
  //         !equipmentSettings.HasHideProjects &&
  //         !equipmentSettings.HasConflictProjects &&
  //         equipmentSettings.BudgetsTurnedOn
  //     : globalSettings.reservationUserGroupGroupByBudgetUserGroup;
  // }, [
  //   equipmentSettings,
  //   globalSettings.reservationUserGroupGroupByBudgetUserGroup,
  // ]);

  const { setFieldValue } = useFormikContext<T>();

  React.useEffect(() => {
    if (!initilized && !wait) {
      if (equipments.length > 0) {
        //&& !isEdit
        if (userName !== null) {
          if (!reservationUserGroupGroupByBudgetUserGroup) {
            if (!isMember(userName, props.values.ADGroup))
              setFieldValue('ADGroup', {
                Id: (userName as IUserFilterDto).UserGroupId,
                Name: (userName as IUserFilterDto).UserGroupName,
              });
          }
          if (globalSettings.AllowWorkflowBooking && isReservations) {
            setFieldValue('WorkflowBooking', getWorkFlowName(userName.Id));
          }
        }
      }
    }
    setInitilized(false);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);

  return null;
}
