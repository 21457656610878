/**
 *
 * PriorityPicker
 *
 */

import {
  ServiceRequestMilestoneStatusesUnionArray,
  ServiceRequestMilestoneStatusesUnion,
} from 'api/odata/generated/enums/ServiceRequestMilestoneStatuses';
import {
  EnumPicker,
  EnumPickerProps,
  withEnumOptions,
} from 'app/components/BasicPickers/EnumPicker';

export type ServiceRequestMilestoneStatusPickerProps = Omit<
  EnumPickerProps<ServiceRequestMilestoneStatusesUnion>,
  'EnumNamesArray'
>;
export const ServiceRequestMilestoneStatusPicker =
  withEnumOptions<ServiceRequestMilestoneStatusesUnion>(
    ServiceRequestMilestoneStatusesUnionArray,
  )(EnumPicker);
