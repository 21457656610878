import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { PrintToPDFPopup } from './Components/PrintToPDFPopup';
import { usePrintPDFSlice } from './slice';
import styled from 'styled-components';
import React from 'react';
import {
  selectPrintPDFisLoading,
  selectPrintPDFSections,
} from './slice/selectors';
import { H4 } from '../Typography';

export interface Printing {
  id: string;
  onLoading?: (id: string, loading: boolean) => void;
  printing: boolean;
}
export interface PrintToPDFProps {
  title?: string;
  children: React.ReactNode;
  print: boolean;
  printTitle: string;
  useSections?: boolean;
  saveMarkedSections?: boolean;
  noTitlePrinted?: boolean;
  hideDisabled?: boolean;
  close: () => void;
}
export function PrintToPDF(props: PrintToPDFProps) {
  const dispatch = useDispatch();
  const { actions } = usePrintPDFSlice();
  const printPDFSections = useSelector(selectPrintPDFSections);
  const printPDFFisLoading = useSelector(selectPrintPDFisLoading);
  const [startPrinting, setStartPrinting] = React.useState(false);
  const [printing, setPrinting] = React.useState(false);
  const componentRef = useRef(null);
  const promiseResolveRef = useRef<() => void>();
  const handlePrint = useReactToPrint({
    content: () =>
      componentRef.current !== undefined ? componentRef.current : null,
    documentTitle: props.printTitle,
    onAfterPrint: () => {
      setPrinting(false);
      setStartPrinting(false);
      dispatch(actions.setPrinting(false));
      props.close();
    },
    onBeforeGetContent: () => {
      dispatch(actions.setPrinting(true));
      return new Promise<void>(resolve => {
        dispatch(actions.clearLoading());
        setStartPrinting(true);
        promiseResolveRef.current = resolve;
      });
    },
  });
  useEffect(() => {
    if (startPrinting && !printPDFFisLoading) {
      setStartPrinting(false);
      setPrinting(true);
    }
  }, [startPrinting, printPDFFisLoading]);
  useEffect(() => {
    if (printing && promiseResolveRef.current) {
      //setPrinting(false);
      promiseResolveRef.current();
    }
  }, [printing]);

  const handleSubmit = useCallback(() => {
    if (
      props.saveMarkedSections !== undefined &&
      props.saveMarkedSections === true
    ) {
      dispatch(actions.saveSections());
    }
    handlePrint?.();
  }, [actions, dispatch, handlePrint, props.saveMarkedSections]);
  return (
    <>
      {props.print ? (
        <PrintContent ref={componentRef} className={'print-pdf-content'}>
          {!!props.title && !props.noTitlePrinted && (
            <TitleSection>
              <H4>{props.title}</H4>
            </TitleSection>
          )}
          {props.children}
        </PrintContent>
      ) : (
        <>{props.children}</>
      )}

      {props.print && (
        <PrintToPDFPopup
          title={props.title}
          show={props.print}
          processing={startPrinting}
          preparing={printPDFFisLoading}
          disabled={printPDFFisLoading}
          printTitle={props.printTitle}
          hideDisabled={props.hideDisabled}
          items={
            !!props.useSections && printPDFSections !== undefined
              ? printPDFSections
              : []
          }
          onClose={() => {
            props.close();
          }}
          onSubmit={handleSubmit}
          fullWidth={
            !!props.useSections &&
            printPDFSections !== undefined &&
            printPDFSections.length > 0
          }
        />
      )}
    </>
  );
}
/**
 * without width: 100% the layout of the PageWrapper breaks by overflowing the PageWrapper (service request details)
 */
const PrintContent = styled('div')`
  &.print-pdf-content {
    width: 100%;
    height: 100%;
    padding: 16px 32px;

    @media print {
      html,
      body {
        height: auto;
      }
      & button {
        display: none;
      }
      height: auto;
      &:last-child {
        page-break-after: auto;
      }
    }
  }
`;
const TitleSection = styled('div')`
  display: flex;
  align-items: center;
  paddin: 16px;
`;
