import { IconProp } from 'app/AllIcons';

/**
 * Convert font-awesome names stored in localization/menu to IconProp.
 *
 * e.g.
 * * "fas fa-home" -> "home"
 * * "map-location" -> "map-location"
 * @param icon
 * @returns
 */
export function replaceIcon(
  icon: string | undefined | null,
): IconProp | undefined {
  if (icon === undefined || icon === null) {
    return undefined;
  }
  if (icon.indexOf(' fa-') !== -1) {
    let tmpIcon = icon.substring(icon.indexOf(' fa-') + 4, icon.length);
    return tmpIcon as IconProp;
  }
  return icon as IconProp;
}
