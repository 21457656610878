import { Theme } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';
import {
  DRAWER_WIDTH,
  DRAWER_WIDTH_SHORT,
  SIDE_PANEL_WIDTH,
} from 'styles/constantVariables';
import { verticalScrolling } from 'styles/CssVariables/styledVariables';
// width: 370px;
//     left: calc(100vw - 370px);
// transition: ${transitions.create('width', {
//   easing: transitions.easing.sharp,
//   duration: 500,
// })};
export const LayoutRoot = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  &.layoutroot {
    direction: ltr;
    @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
      display: flex;
      max-width: ${props => props.theme.breakpoints.values.xl}px;
      width: 100%;
      margin-inline: auto;
      flex-direction: column;
      height: 100dvh;
    }
    &.layoutRootMobile {
      display: inline-flex;
      justify-content: flex-end;
      align-items: flex-start;
      width: 100%;
      margin-bottom: env(safe-area-inset-bottom, 50px);
    }
    & .sidePanelRoot {
      height: 0;
      position: absolute;
      top: 0;
      right: 0;
    }
    & .sidePanelMobileRoot {
      height: 0;
      position: absolute;
      top: 50px;
      left: 0;
      max-height: calc(100dvh - 50px);
      width: 100%;
      margin-bottom: env(safe-area-inset-bottom, 50px);
      margin-top: env(safe-area-inset-top);
    }
    & .backdrop {
      position: absolute;
      background: ${bookitColors.overlays.overlayBlack};
      z-index: 1300;
      &.backdrop-open {
        height: 100dvh;
        width: 100dvw;
        &.backdrop-short {
          height: 50px;
          top: -50px;
          left: 0;
          width: 100%;
        }
      }
    }
    & .sidePanelRootExpanded {
      width: 100%;
      transition: ${transitions.create('width', {
        easing: transitions.easing.easeInOut,
        duration: 500,
      })};
    }
    & .sidePanelRootCollapsed {
    }
  }
`;
export const Main = styled.main.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  direction: ltr;
  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    display: flex;
  }

  flex-grow: 1;
  //min-height: 100dvh;
  background: ${bookitColors.grayscale.grayBackground};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 0;
  overflow: hidden;
  height: 100dvh;
  margin-bottom: env(safe-area-inset-bottom, 50px);
  ${verticalScrolling}
  &.shiftLeft {
    margin-right: ${SIDE_PANEL_WIDTH}px;
  }
  &.shiftRightOpen {
    margin-left: ${DRAWER_WIDTH}px;
  }
  &.shiftRightClose {
    margin-left: ${DRAWER_WIDTH_SHORT}px;
  }
  & .appBarSpacer {
    min-height: 60px;
    &.subBarExpanded {
      min-height: 118px;
    }
  }
  & .container {
    padding: 0px;
    position: relative;
    background-color: ${bookitColors.grayscale.grayBackground};
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: env(safe-area-inset-bottom, 50px);
    &.mobileOpen {
      position: relative;
      margin-left: calc(100% - 44px);
    }
    &.useselectable {
      overflow-y: hidden;
    }
  }
  & .footer {
    margin-top: auto;
    & .copyRight {
      padding-top: 8px;
      margin-top: auto;
      margin-inline: '8px';
    }
  }
`;
export const AssetWrapperRoot = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  &.publicLayoutroot {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 24px;
    padding: 32px;
    background-color: ${bookitColors.grayscale.graybgComponents};
    overflow-y: hidden;
    direction: ltr;
    @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
      display: flex;
    }
    &.isAuthPanel {
      padding: 24px;
    }
    &.publicLayoutRootMobile {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 0;
      overflow: hidden;
      background-color: ${bookitColors.grayscale.graybgComponents};

      justify-content: flex-end;
      align-items: flex-start;
      width: 100%;
    }
    & .sidePanelRoot {
      height: 0;
      position: absolute;
      top: 0;
      right: 0;
    }
    & .sidePanelMobileRoot {
      height: 0;
      position: absolute;
      top: 50px;
      left: 0;
      max-height: calc(100dvh - 50px);
      width: 100%;
    }
    & .backdrop {
      position: absolute;
      background: ${bookitColors.overlays.overlayBlack};
      z-index: 1300;
      &.backdrop-open {
        height: 100dvh;
        width: 100dvw;
        &.backdrop-short {
          height: 50px;
          top: -50px;
          left: 0;
          width: 100%;
        }
      }
    }
    & .sidePanelRootExpanded {
      width: 100%;
      transition: ${transitions.create('width', {
        easing: transitions.easing.easeInOut,
        duration: 500,
      })};
    }
    & .sidePanelRootCollapsed {
    }
  }
`;
export const FullHeightBlock = styled.div`
  max-height: 100dvh;
  height: 100%;
`;
