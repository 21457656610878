/**
 *
 * ArticeDatasetlLink
 *
 */
import { ExternalLink } from 'app/components/ExternalLink';

export interface ArticleLinkProps {
  dataset: string | undefined | null;
  externalId?: string | null;
  externalIdSource?: string | null;
}

export function ArticleDatasetLink({
  dataset,
  externalIdSource,
  externalId,
}: ArticleLinkProps) {
  if (dataset !== undefined && dataset !== null) {
    return (
      <>
        <ExternalLink showIcon href={dataset}>
          {dataset}
        </ExternalLink>
      </>
    );
  } else {
    return <></>;
  }
}
