import {
  Input,
  InputBaseProps,
  InputClassKey,
  StandardProps,
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

export interface StandardInputProps
  extends StandardProps<InputBaseProps, InputClassKey> {
  disableUnderline?: boolean;
  className?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  noBorder?: boolean;
  info?: string;
}
// export function StandardInput(
//   {
//     classes,
//     className,
//     fullWidth,
//     inputComponent,
//     multiline,
//     noBorder,
//     type,
//     ...rest
//   }: StandardInputProps,
//   ref: React.Ref<unknown>,
// ) {
//   const innerClasses = styles();
//   fullWidth = false;
//   inputComponent = 'input';
//   multiline = false;
//   noBorder = false;
//   type = 'text';

//   return (
//     <InputBase
//       classes={{
//         ...classes,
//         root: clsx(innerClasses.root, {
//           [innerClasses.inputBorder]: !noBorder,
//         }),
//       }}
//       fullWidth={fullWidth}
//       inputComponent={inputComponent}
//       className={className}
//       multiline={multiline}
//       ref={ref}
//       type={type}
//       {...rest}
//     />
//   );
// }
export const StandardInput = React.forwardRef<
  React.HTMLAttributes<HTMLDivElement>,
  StandardInputProps
>(function StandardInput(props, ref) {
  const {
    disableUnderline = true,
    className,
    fullWidth = false,
    inputComponent = 'input',
    multiline = false,
    type = 'text',
    ...rest
  } = props;
  return (
    <Input
      // classes={{
      //   ...classes,
      //   root: clsx(classes.root, !disableUnderline && classes.underline),
      //   underline: undefined,
      // }}
      fullWidth={fullWidth}
      inputComponent={inputComponent}
      className={clsx('standard', className)}
      multiline={multiline}
      disableUnderline={disableUnderline}
      ref={ref}
      type={type}
      {...rest}
    />
  );
});
export default StandardInput;
