import { IRow } from 'app/components/BasicTable';
import {
  IsEntity,
  IsEntityString,
} from 'app/components/BasicTable/BasicFilter/AppliedFilterComponent';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { Entity } from 'types/common';
import { Condition } from './ODataFilter';

/**
 * Gets the predicates that can be used in ODAta filter from the "filters"
 * @param filters Applied filters
 * @param customParams ???
 * @param cp ???
 * @returns Odata filter predicate conditions
 */
export function getPredicates<TRow extends IRow>(
  filters: IFilterSettings<TRow>[],
  customParams:
    | ((fieldName: string, Id: number, fn: (p: {}) => void) => void)
    | undefined,
  cp: (p: {}) => void,
): (string | Condition<TRow>)[] {
  return filters
    .filter(f => f.value !== null)
    .map(f =>
      f.value === undefined || (Array.isArray(f.value) && f.value.length === 0)
        ? undefined
        : f.customFilter !== undefined
        ? f.customFilter(f.value, f, filters)
        : IsEntityString(f.value)
        ? (f.value as Entity<string>).Id !== ''
          ? new Condition<TRow>(
              f.fieldName,
              f.operator,
              (f.value as Entity<string>).Id,
              f.fieldName1,
              f.isInversed,
            )
          : undefined
        : IsEntity(f.value)
        ? (f.value as Entity<number>).Id >= 0
          ? new Condition<TRow>(
              f.fieldName,
              f.operator,
              (f.value as Entity<number>).Id,
              f.fieldName1,
              f.isInversed,
            )
          : customParams !== undefined
          ? customParams(
              f.fieldName as string,
              (f.value as Entity<number>).Id,
              cp,
            )
          : undefined
        : new Condition<TRow>(
            f.fieldName,
            f.operator,
            f.value,
            f.fieldName1,
            f.isInversed,
          ),
    )
    .filter(f => f !== undefined) as Condition<TRow>[];
}
