import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export const ImageCarousel = styled.div`
  &.img-carousel-root {
    max-width: 400px;
    width: 100%;
    &.img-short-carusel-root {
      max-width: 340px;
    }
    & .carousel-root {
      & .carousel-slider {
        border-radius: 4px;
        & .slider-wrapper.axis-horizontal {
          & .slider {
            & .slide {
              max-height: 300px;
              & .img-wrapper-box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      & .carousel {
        & .thumbs-wrapper.axis-vertical {
          margin: 0;
          & .control-arrow {
            border-radius: 50%;

            background-color: ${bookitColors.grayscale.blackComponents};
            &.control-prev {
              padding: 7px 6px 1px 4px;
            }
            &.control-next {
              padding: 7px 4px 1px 6px;
            }
          }
          & .thumbs {
            margin-top: 10px;
            margin-bottom: 0;
            padding: 0px;
            & .thumb {
              height: 64px;
              border-radius: 4px;
              padding-inline: 2px;
              margin-right: 0px;
              &.selected {
                border: 2px solid ${bookitColors.primary.components};
              }
              &:hover {
                border: 2px solid ${bookitColors.primary.components};
              }
              & .img-wrapper-box {
                display: flex;
                align-items: stretch;
                justify-content: center;
                width: 100%;
                height: 100%;
                position: relative;
                &.img-wrapper-load {
                  align-items: center;
                }
              }
              & .img-close-button {
                position: absolute;
                top: 1px;
                right: 1px;
                padding: 1px 1px 1px 1px;
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
    }
  }
`;
