import { useMemo } from 'react';

function useLocaleUsesAmPm(locale: string): boolean {
  return useMemo(() => {
    const formatter = new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      hour12: true, // Force 12-hour format
    });

    // Format a fixed time and check for "AM" or "PM"
    const testTime = new Date(Date.UTC(2024, 0, 1, 0, 0)); // Fixed time: Midnight UTC
    const formattedTime = formatter.format(testTime);

    return formattedTime.includes('AM') || formattedTime.includes('PM');
  }, [locale]);
}

export default useLocaleUsesAmPm;
