import { IReservationHistoryDto } from 'api/odata/generated/entities/IReservationHistoryDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { PrimaryAction } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { FormSectionTitle } from 'app/components/Forms/FormsLayout/FormSectionTitle';
import { useDebugMemo } from 'app/hooks/useDebugMemo';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { getColumns } from 'app/pages/ReservationHistoryPage/Columns';
import { reservationsPrimaryAction } from 'app/pages/ReservationHistoryPage/PrimaryAction';
import { ReservationHistoryTable } from 'app/pages/ReservationHistoryPage/ReservationHistoryTable';
import { useReservationPermissions } from 'app/permissions/Reservations/reservationPermissions';
import {
  selectAuthenticatedUser,
  selectglobalSettings,
} from 'app/slice/selectors';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';

export interface ServiceRequestReservationsProps {
  serviceRequestId: number;
  isMobile?: boolean;
  useSidePanel?: boolean;
  openPanel: (state: SidePanelOpenState) => void;
  tableContainerAsSection?: boolean;
  onCreateReservation: () => void;
  isAdmin: boolean;
}

export function ServiceRequestReservations(
  props: ServiceRequestReservationsProps,
) {
  const { t } = useTranslation();
  const user = useSelector(selectAuthenticatedUser);
  const settings = useSelector(selectglobalSettings);
  const { allowedToView } = useReservationPermissions();
  const {
    serviceRequestId,
    isMobile,
    useSidePanel,
    openPanel,
    onCreateReservation,
    isAdmin,
    tableContainerAsSection,
  } = props;
  const SRReservationColumns = React.useMemo(
    () => [
      'Id',
      'EquipmentName',
      'StartTime',
      'EndTime',
      'Status',
      'DurationH',
      'Remarks',
      'Charges',
    ],
    [],
  );
  const predicates = React.useMemo(() => {
    return [
      `(${new Condition<IReservationHistoryDto>(
        'ServiceRequestIds',
        ODataOperators.In,
        serviceRequestId,
      ).toString()} or ${new Condition<IReservationHistoryDto>(
        'RowsRequestIds',
        ODataOperators.In,
        serviceRequestId,
      ).toString()})`,
    ];
  }, [serviceRequestId]);
  const reservationsColumns: Column<IReservationHistoryDto>[] = React.useMemo(
    () => getColumns(t, user, settings, allowedToView, useSidePanel, openPanel),
    [t, user, settings, allowedToView, useSidePanel, openPanel],
  );
  const columns = useDebugMemo(
    () => {
      let res: Column<IReservationHistoryDto>[] = [];
      for (const c of SRReservationColumns) {
        const column = reservationsColumns.find(f => f.accessor === c);
        if (column !== undefined) {
          res.push(column);
        }
      }
      return res;
    },
    [SRReservationColumns, reservationsColumns],
    ['SRReservationColumns', 'reservationsColumns'],
  );
  const PageActions: PageActionRenderer[] = [
    () => (
      <TopActionButton
        variant="main"
        size="small"
        icon={'plus'}
        onClick={() => onCreateReservation()}
        text={t(translations.CreateReservation)}
      />
    ),
  ];
  // const initialState: IBasicTableState<IReservationHistoryDto> = React.useMemo(
  //   () => ({
  //     sortBy: [{ id: 'Id', desc: true }],
  //   }),
  //   [],
  // );
  const primaryAction: PrimaryAction<any> = React.useCallback(
    (...args) => {
      reservationsPrimaryAction(
        openPanel,
        args[0],
        args[1],
        args[2],
        useSidePanel,
      );
    },
    [openPanel, useSidePanel],
  );
  return (
    <>
      <ReservationHistoryTable
        columns={columns}
        initialState={{
          sortBy: [{ id: 'Id', desc: true }],
        }}
        predicates={predicates}
        useConfigurable={false}
        useCardsByDefault={isMobile}
        allowCards={isMobile}
        pageActions={isAdmin ? PageActions : undefined}
        useExport={true}
        serviceGroups={[]}
        customScreenName={
          <FormSectionTitle title={t(translations.Reservations)} />
        }
        hideMenuOnMobile={true}
        useRowSelect={false}
        screenNameVariant="section"
        //tablePaddingVariant={'dense'}
        compactTable={isMobile}
        primaryAction={primaryAction}
        tableContainerAsSection={tableContainerAsSection}
      />
    </>
  );
}
