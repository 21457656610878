import { UsersApi } from 'api/UsersApi';
import { Button } from 'app/components/BasicButtons/Button';
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import { FormRow } from 'app/components/Forms/FormsLayout';
import { IUsers } from 'app/pages/UsersPage/IUsers';
import { useAppSettingsSlice } from 'app/slice';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IResponseType } from 'types/ResponseType';
import { AttributeValue } from '../../typeUtils';

export function InternalId2Render(props: AttributeValue<IUsers>) {
  const { original } = props;
  const { t } = useTranslation();
  const { actions } = useAppSettingsSlice();
  const dispatch = useDispatch();
  const [currentInternalId, setInternalId] = React.useState<string | undefined>(
    original.UserInternalId2,
  );
  const [updated, setUpdated] = React.useState<boolean>(false);
  const handleUpdate = React.useCallback(async () => {
    setUpdated(true);
    try {
      const result: IResponseType = await UsersApi.updateInternalId2(
        original.Name,
        currentInternalId,
      );
      if (result.ErrorMessages.length > 0) {
        dispatch(
          actions.addNotification({
            variant: 'error',
            message: result.ErrorMessages[0],
          }),
        );
        setUpdated(false);
      } else {
        dispatch(
          actions.addNotification({
            variant: 'success',
            message: result.SuccessMessages[0],
          }),
        );
        setUpdated(false);
      }
    } catch (e) {
      dispatch(
        actions.addNotification({
          variant: 'error',
          message: 'Default User Group update failed',
        }),
      );
      setUpdated(false);
    }
  }, [actions, currentInternalId, dispatch, original.Name]);

  return (
    <FormRow fullRow>
      <TextControlField
        name="internel_text_id2"
        placeholder={t(translations.UserInternalId2)}
        onChange={e => setInternalId(e.target.value)}
        variant="filled"
        value={currentInternalId || ''}
        label={t(translations.UserInternalId2)}
        disabled={updated}
      />
      <Button
        variant="main"
        size="large"
        disabled={currentInternalId === original.UserInternalId2 || updated}
        processing={updated}
        onClick={handleUpdate}
      >
        {t(translations.Update)}
      </Button>
    </FormRow>
  );
}
