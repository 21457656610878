/**
 *
 * DatesRangeFilter
 *
 */
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { IFilterComponentProps } from 'app/components/BasicTable/Filters/IFilterComponentProps';

import { PresetDatesRange } from 'types/PresetDatesRange';
import {
  getPeriodTypesEntity,
  PeriodTypes,
  PeriodTypesEntity,
} from 'app/components/DatePeriods';
import { DatesRange } from 'types/DatesRange';

import { DatePeriodsPicker } from 'app/components/pickers/DatePeriodsPicker';
import { selectTwoDaysTimelineButtonEnabled } from 'app/pages/CalendarPage/Scheduler/slice/selectors';
import { useSelector } from 'react-redux';

/**
 * Date picker to be used as a BasicTable filter component
 */
export const DatesPeriodFilter = (props: IFilterComponentProps) => {
  const { t } = useTranslation();
  const twoDaysEnabled = useSelector(selectTwoDaysTimelineButtonEnabled);
  const [value, setValue] = React.useState<PresetDatesRange>(
    props.value !== undefined ? props.value : new PresetDatesRange(),
  );
  const handleTypeChange = (value: PeriodTypesEntity | null) => {
    let changedValue = Object.assign(
      new PresetDatesRange(),
      {
        type:
          (value as PeriodTypesEntity) ??
          (getPeriodTypesEntity('CustomDate') as PeriodTypesEntity),
      },
      DatesRange.fromPreset(
        (value as PeriodTypesEntity) ??
          (getPeriodTypesEntity('CustomDate') as PeriodTypesEntity),
        null,
        null,
      ),
    );
    setValue(state => changedValue);
    props.onChange(changedValue);
  };
  return (
    <>
      {value !== undefined && (
        <>
          <DatePeriodsPicker
            isOpen={true}
            onChange={handleTypeChange}
            periods={SchedulerDatePositionArray.filter(
              f => twoDaysEnabled === true || f !== 'TodayTomorrow',
            )}
            value={
              props.value instanceof PresetDatesRange
                ? (props.value.type as PeriodTypesEntity | undefined)
                : (props.value as PeriodTypesEntity | undefined)
            }
            disableClearable={true}
            placeholder={t(translations.Forever)}
            fullWidth={props.fullWidth}
            size="small"
            id="datesrangeid"
            aria-label="Date Periods select"
          />
        </>
      )}
    </>
  );
};
export default DatesPeriodFilter;

export const SchedulerDatePositionArray: Array<SchedulerDatePositionUnion> = [
  'Today',
  'Tomorrow',
  'Yesterday',
  'TodayTomorrow',
  'ThisWeek',
  'NextWeek',
  'ThisMonth',
  'NextMonth',
  'LastWeek',
  'LastMonth',
  'ThisQuarter',
  'LastQuarter',
  'NextQuarter',
  'ThisYear',
  'LastYear',
  'NextYear',
  'CustomDate',
];
type Extends<T, U extends T> = U;
export type SchedulerDatePositionUnion = Extends<
  PeriodTypes,
  | 'Today'
  | 'Tomorrow'
  | 'Yesterday'
  | 'TodayTomorrow'
  | 'ThisWeek'
  | 'NextWeek'
  | 'ThisMonth'
  | 'NextMonth'
  | 'LastWeek'
  | 'LastMonth'
  | 'ThisQuarter'
  | 'LastQuarter'
  | 'NextQuarter'
  | 'ThisYear'
  | 'LastYear'
  | 'NextYear'
  | 'CustomDate'
>;
