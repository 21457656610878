/**
 *
 * AssetSearchResultsByType
 *
 */
import * as React from 'react';
import { LinearProgress, ListItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useSelector } from 'react-redux';
import {
  selectAllSearchResults,
  selectMenuSearchResults,
  selectOfflineSearchResults,
  selectOnlineSearchResults,
  selectRequestSearchResults,
  selectSearchTerm,
  selectTotalSearchCount,
} from '../slice/selectors';
import { AssetListItem } from '../AssetListItem';
import {
  QuickSearchEmptyBody,
  QuickSearchEmptyContent,
  QuickSearchEmptySection,
  QuickSearchRecentItems,
  QuickSearchSection,
  QuickSearchSectionTitle,
  QuickSearchSeeAll,
} from '../styled';
import { Body, Uppercase } from 'app/components/Typography';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { Entity } from 'types/common';
import { MenuSearchResultList } from '../MenuSearchResultList';
import { Div100 } from '../styled';

export interface AssetSearchResultsByTypeProps {
  onAllAssetsClick: (serviceTypeId?: number, searchTerm?: string) => void;
  isMobile?: boolean;
  selectedServiceGroups?: Entity<number>[];
}

export function AssetSearchResultsByType(props: AssetSearchResultsByTypeProps) {
  const { t } = useTranslation();
  const { onAllAssetsClick, isMobile, selectedServiceGroups } = props;
  // const searchTerm = useSelector(selectSearchTerm);
  const state = useSelector(selectAllSearchResults);
  const onlineState = useSelector(selectOnlineSearchResults);
  const offlineState = useSelector(selectOfflineSearchResults);
  const requestState = useSelector(selectRequestSearchResults);
  const totalRresults = useSelector(selectTotalSearchCount);
  const searchTerm = useSelector(selectSearchTerm);
  const menuItemsState = useSelector(selectMenuSearchResults);
  // const resultsLength = React.useMemo(() => {
  //   return state.value?.totalRresults;
  // }, [state.value?.totalRresults]);

  const OnlineServiceLabel = React.useMemo(() => {
    return (t(translations.InstrumentsFound) as string).replace(
      '{0}',
      `(${(onlineState?.TotalResultsLength ?? 0).toString()})`,
    );
  }, [onlineState?.TotalResultsLength, t]);
  const RequestServiceLabel = React.useMemo(() => {
    return (t(translations.RequestsFound) as string).replace(
      '{0}',
      `(${(requestState?.TotalResultsLength ?? 0).toString()})`,
    );
  }, [requestState?.TotalResultsLength, t]);
  const OfflineServiceLabel = React.useMemo(() => {
    return (t(translations.ConsumablesFound) as string).replace(
      '{0}',
      `(${(offlineState?.TotalResultsLength ?? 0).toString()})`,
    );
  }, [offlineState?.TotalResultsLength, t]);

  const showOnline = React.useMemo(() => {
    return (
      (state.status === 'pending' &&
        !!onlineState &&
        onlineState.Results.length > 0) ||
      (state.status === 'resolved' && (totalRresults ?? 0) > 0)
    );
  }, [onlineState, state.status, totalRresults]);

  const showOffline = React.useMemo(() => {
    return (
      (state.status === 'pending' &&
        !!offlineState &&
        offlineState.Results.length > 0) ||
      (state.status === 'resolved' && (totalRresults ?? 0) > 0)
    );
  }, [offlineState, state.status, totalRresults]);
  const showRequests = React.useMemo(() => {
    return (
      (state.status === 'pending' &&
        !!requestState &&
        requestState.Results.length > 0) ||
      (state.status === 'resolved' && (totalRresults ?? 0) > 0)
    );
  }, [requestState, state.status, totalRresults]);
  // const error = React.useMemo(() => {
  //   return state.error !== undefined
  //     ? typeof state.error === 'string'
  //       ? state.error
  //       : undefined
  //     : undefined;
  // }, [state.error]);
  return (
    <>
      {!state.status ||
        (state.status === 'pending' && (
          <Div100>
            <LinearProgress variant="query" color="primary" />
          </Div100>
        ))}
      {state.status === 'resolved' && totalRresults === 0 && (
        <ListItem disableGutters component="div">
          <QuickSearchEmptySection>
            <QuickSearchEmptyContent className="empty-search-content">
              <span className="empty-search-content-wrap">
                <Body size="large" bold className="empty-search-content-text">
                  {t(translations.NothingFound)}
                </Body>
              </span>
              <QuickSearchEmptyBody className="empty-search-body">
                <Body
                  size="medium"
                  color="secondary"
                  className="empty-search-body-text"
                >
                  {`Try to `}
                </Body>
                <Button variant="text" onClick={() => onAllAssetsClick()}>
                  {t(translations.SearchAllCores)}
                </Button>
                <Body
                  size="medium"
                  color="secondary"
                  className="empty-search-last-text"
                >
                  {` or change the query`}
                </Body>
              </QuickSearchEmptyBody>
            </QuickSearchEmptyContent>
          </QuickSearchEmptySection>
        </ListItem>
      )}

      <React.Fragment>
        {showOnline && (
          <QuickSearchSection>
            <QuickSearchSectionTitle>
              <Uppercase color="secondary">{OnlineServiceLabel}</Uppercase>
              <QuickSearchSeeAll>
                <Button
                  variant="text"
                  size="small"
                  endIcon={<Icon icon="chevron-double-right" />}
                  onClick={() =>
                    onAllAssetsClick(ServiceType.Online, searchTerm)
                  }
                >
                  {t(translations.FullResults)}
                </Button>
              </QuickSearchSeeAll>
            </QuickSearchSectionTitle>
            <QuickSearchRecentItems>
              <React.Fragment>
                {onlineState?.Results?.map((item, index) => (
                  <AssetListItem
                    key={index}
                    item={item}
                    isMobile={isMobile}
                    selectedServiceGroups={selectedServiceGroups}
                  />
                ))}
              </React.Fragment>
            </QuickSearchRecentItems>
          </QuickSearchSection>
        )}

        {showOffline && (
          <QuickSearchSection>
            <QuickSearchSectionTitle>
              <Uppercase color="secondary">{OfflineServiceLabel}</Uppercase>
              <QuickSearchSeeAll>
                <Button
                  variant="text"
                  size="small"
                  endIcon={<Icon icon="chevron-double-right" />}
                  onClick={() =>
                    onAllAssetsClick(ServiceType.Offline, searchTerm)
                  }
                >
                  {t(translations.FullResults)}
                </Button>
              </QuickSearchSeeAll>
            </QuickSearchSectionTitle>
            <QuickSearchRecentItems>
              <React.Fragment>
                {offlineState?.Results?.map((item, index) => (
                  <AssetListItem key={index} item={item} isMobile={isMobile} />
                ))}
              </React.Fragment>
            </QuickSearchRecentItems>
          </QuickSearchSection>
        )}

        {showRequests && (
          <QuickSearchSection>
            <QuickSearchSectionTitle>
              <Uppercase color="secondary">{RequestServiceLabel}</Uppercase>
              <QuickSearchSeeAll>
                <Button
                  variant="text"
                  size="small"
                  endIcon={<Icon icon="chevron-double-right" />}
                  onClick={() =>
                    onAllAssetsClick(ServiceType.Sample, searchTerm)
                  }
                >
                  {t(translations.FullResults)}
                </Button>
              </QuickSearchSeeAll>
            </QuickSearchSectionTitle>
            <QuickSearchRecentItems>
              <React.Fragment>
                {requestState?.Results?.map((item, index) => (
                  <AssetListItem key={index} item={item} isMobile={isMobile} />
                ))}
              </React.Fragment>
            </QuickSearchRecentItems>
          </QuickSearchSection>
        )}
        {!!menuItemsState && (
          <QuickSearchSection>
            <MenuSearchResultList />
          </QuickSearchSection>
        )}
      </React.Fragment>
    </>
  );
}
