import clsx from 'clsx';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  CardProps,
  Theme,
  useTheme,
} from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import React, { useState } from 'react';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { defaultImgSrc } from 'app/components/AssetThumbImage';
import { Body } from '../Typography';
import { bookitColors } from 'styles/colors/bookitColors';
import styled from 'styled-components';
import { bookitShadows } from 'styles/shadows/bookitShadow';
import * as rdd from 'react-device-detect';
import { hasStringProperty } from 'utils/typeUtils';
// import useState from 'react-usestateref';

export type CardPosition = 'vertical' | 'horizontal';
export type CardBackground = 'white' | 'gray';
export type CardSize = 'small' | 'medium';
export type StatusType = 'info' | 'success' | 'warning' | 'error' | 'active';
export interface CardButton {
  key: React.Key | null | undefined;
  button: React.ReactNode;
}
export interface CardContentRow {
  key: React.Key | null | undefined;
  label: React.ReactNode;
  text: React.ReactNode;
}
export interface CardTopActions {
  key: React.Key | null | undefined;
  action: React.ReactNode;
}
export interface DemSize {
  width: number;
  height: number;
}
export interface BasicCardProps {
  position?: CardPosition;
  backStyle?: CardBackground;
  cardSize?: CardSize;
  mobileSize?: CardSize;
  useImage?: boolean;
  imageSrc?: string;
  showBullet?: boolean;
  useButtons?: boolean;
  useSelect?: boolean;
  selectVisible?: boolean;
  selected?: boolean;
  title?: React.ReactNode;
  disabled?: boolean;
  primaryAction?: () => void;
  checkBox?: React.ReactNode;
  topActions?: Array<CardTopActions>;
  hasTopActions?: boolean;
  buttons?: Array<CardButton>;
  cardContent?: React.ReactNode;
  cardContentRows?: Array<CardContentRow>;
  iconTitle?: React.ReactNode;
  status?: StatusType;
  maxContentHeight?: number;
  preventInnerClicks?: boolean;
  contentEditable?: boolean;
  loading?: boolean;
  info?: string;
  trimTitle?: boolean;
  showCheckBoxOnTop?: boolean;
  id?: string;
  checkBoxTitle?: string;
  descriptionKey?: string;
  maxContentRowsLength?: number;
}
const StyledCardActions = styled(CardActions)<{ contentEditable?: boolean }>`
  pointer-events: ${props => (props.contentEditable ? 'initial' : undefined)};
  cursor: ${props => (props.contentEditable ? 'pointer' : undefined)};
`;
export const StyledCardContent = styled.div<{ height: number }>`
  &.content {
    height: ${props => props.height}px;
  }
`;
export function BasicCard(props: BasicCardProps) {
  const {
    position,
    cardSize,
    mobileSize,
    useButtons,
    useImage,
    useSelect,
    showBullet,
    selected,
    title,
    imageSrc,
    disabled,
    primaryAction,
    checkBox,
    topActions,
    buttons,
    cardContent,
    cardContentRows,
    selectVisible,
    iconTitle,
    status,
    maxContentHeight,
    preventInnerClicks,
    contentEditable,
    hasTopActions,
    loading,
    trimTitle = true,
    showCheckBoxOnTop,
    id,
    checkBoxTitle,
    descriptionKey,
  } = props;
  const theme = useTheme();
  const [imgError, setImageError] = React.useState<boolean>(false);
  const imgSrc = React.useMemo(() => {
    return useImage
      ? imgError
        ? defaultImgSrc
        : imageSrc !== undefined
        ? imageSrc
        : defaultImgSrc
      : undefined;
  }, [imageSrc, imgError, useImage]);
  const [imageDimensions, setImageDimensions] = useState<DemSize | undefined>(
    undefined,
  );
  React.useEffect(() => {
    if (loading !== undefined) {
      if (loading === true && imageDimensions !== undefined) {
        setImageDimensions(undefined);
      }
    }
  }, [imageDimensions, loading]);
  const loadImage = (setImageDimensions, imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const backgroundSize = React.useMemo(() => {
    if (!!imageDimensions) {
      let horizontal = imageDimensions.width - imageDimensions.height > 0;
      if (imageDimensions.height === imageDimensions.width) {
        return 'contain';
      }
      if (position === 'vertical') {
        if (horizontal) {
          if (
            imageDimensions.height <
            (cardSize === 'medium' ? 180 : 150) / 1.8
          ) {
            return 'contain';
          } else {
            if (imageDimensions.width < imageDimensions.height * 1.33) {
              return 'contain';
            }
            return 'cover';
          }
        } else {
          return 'contain';
        }
      } else {
        if (!horizontal) {
          if (
            imageDimensions.height <
            (cardSize === 'medium' ? 180 : 150) / 1.8
          ) {
            return 'contain';
          } else {
            return 'cover';
          }
        } else {
          if (
            imageDimensions.width <
            (cardSize === 'medium' ? 180 : 150) / 1.8
          ) {
            return 'contain';
          } else {
            return 'cover';
          }
        }
      }
    }
    return undefined;
  }, [cardSize, imageDimensions, position]);
  const [showSelect, setShowSelect] = useState(selectVisible);
  React.useEffect(() => {
    let active =
      imgSrc !== undefined &&
      imageDimensions === undefined &&
      loading === false;
    if (active) {
      loadImage(setImageDimensions, imgSrc);
    }
    return () => {
      active = false;
      //setImageDimensions(undefined);
    };
  }, [imageDimensions, imgSrc, loading]);
  const showHeader = React.useMemo(() => {
    return !!title || !!iconTitle || (useSelect && showSelect) || hasTopActions;
  }, [iconTitle, showSelect, title, hasTopActions, useSelect]);
  // const headerHeight = React.useMemo(() => {
  //   return showHeader ? 25 : 0;
  // }, [showHeader]);
  // const imageHeight = React.useMemo(() => {
  //   return position === 'horizontal' || imgSrc === undefined
  //     ? 0
  //     : cardSize === 'medium'
  //     ? 180
  //     : 150;
  // }, [cardSize, imgSrc, position]);

  // const rowsHeight = 78;

  const contentHeight = React.useMemo(() => {
    let maxHeight = maxContentHeight || 60;
    return cardContent === undefined ? 0 : maxHeight;
  }, [cardContent, maxContentHeight]);
  // const otherHeight = React.useMemo(() => {
  //   //paddings and gaps
  //   return (
  //     (rowsHeight > 0 || maxContentHeight === undefined
  //       ? cardSize === 'medium'
  //         ? 16 * 2
  //         : 8 * 2
  //       : 6) + (useButtons ? 16 : 8)
  //   );
  // }, [cardSize, maxContentHeight, rowsHeight, useButtons]);

  // const buttonsHeight = React.useMemo(() => {
  //   return useButtons ? 32 : 0;
  // }, [useButtons]);
  const [mouseUp, setMouseUp] = React.useState<boolean>(false);
  // const [preventPrimary, setPreventPrimary] = React.useState<boolean>(false);
  // const cardHeight = React.useMemo(() => {
  //   return (
  //     headerHeight + rowsHeight + buttonsHeight + otherHeight + contentHeight
  //   );
  // }, [buttonsHeight, headerHeight, otherHeight, rowsHeight, contentHeight]);

  const trimTitleText = React.useMemo(() => {
    if (!!title && typeof title === 'string' && trimTitle) {
      let trimmedTitle = title as string;
      let trimSize = cardSize === 'medium' ? 48 : 56;
      if (trimmedTitle.length > trimSize) {
        trimmedTitle = trimmedTitle.substring(0, trimSize).trim() + '...';
      }
      return trimmedTitle;
    }
    return title;
  }, [cardSize, title, trimTitle]);

  return (
    <StyledCard
      theme={theme}
      className={clsx('card', {
        cardActive: status === 'active' || selected,
        cardInfo: status === 'info',
        cardSuccess: status === 'success',
        cardWarning: status === 'warning',
        cardError: status === 'error',
        position_vertical: position === 'vertical',
        position_horizontal: position === 'horizontal',
        cardSize_small: cardSize === 'small',
        cardSize_medium: cardSize === 'medium',
        cardImage: imgSrc !== undefined,
      })}
      id={id}
    >
      <CardActionArea
        className={clsx('cardActionArea', {
          position_vertical: position === 'vertical',
          position_horizontal: position === 'horizontal',
          disabled: disabled && !contentEditable,
        })}
        disabled={disabled && !contentEditable}
        component="span"
        onMouseEnter={() => {
          if (useSelect) {
            setShowSelect(true);
          }
        }}
        onMouseLeave={() => {
          if (useSelect) setShowSelect(selectVisible);
        }}
        onMouseUp={event => {
          if ('type' in event.target && event.target.type === 'checkbox') {
            event.preventDefault();
            event.stopPropagation();
          } else if (
            hasStringProperty(event.target, 'className') &&
            event.target.className.indexOf('MuiCardMedia-root') > -1
          ) {
            setMouseUp(true);
            if (!!primaryAction) {
              primaryAction();
            }
          } else if (
            (hasStringProperty(event.target, 'className') &&
              event.target.className.indexOf('MuiLink-root') > -1) ||
            event.target?.parentElement?.nodeName === 'A'
          ) {
            setMouseUp(true);
          } else {
            event.preventDefault();
            event.stopPropagation();
          }
        }}
        onClick={() => {
          if (mouseUp) {
            setMouseUp(false);
            return;
          }
          if (!!primaryAction) {
            primaryAction();
          }
        }}
      >
        {imgSrc !== undefined && (
          <ImageContainer
            theme={theme}
            className={clsx('imgContainer', {
              position_vertical: position === 'vertical',
              position_horizontal: position === 'horizontal',
              cardSize_small: cardSize === 'small',
              cardSize_medium: cardSize === 'medium',
              mobileSizeMedium: mobileSize === 'medium',
              mobileSizeSmall: mobileSize === 'small',
            })}
          >
            <div
              className={clsx('frame', {
                position_vertical: position === 'vertical',
                position_horizontal: position === 'horizontal',
                cardSize_small: cardSize === 'small',
                cardSize_medium: cardSize === 'medium',
                mobileSizeMedium: mobileSize === 'medium',
                mobileSizeSmall: mobileSize === 'small',
              })}
            >
              <CardMedia
                component="div"
                className={clsx('mediaStyles', {
                  position_vertical: position === 'vertical',
                  position_horizontal: position === 'horizontal',
                  cardSize_small: cardSize === 'small',
                  cardSize_medium: cardSize === 'medium',
                  mobileSizeMedium: mobileSize === 'medium',
                  mobileSizeSmall: mobileSize === 'small',
                  backgroundSizeCover: backgroundSize === 'cover',
                  backgroundSizeContain: backgroundSize === 'contain',
                })}
                image={imgSrc}
                onLoadedMetadata={e => {
                  console.log('img event', e);
                }}
                onLoad={e => {
                  console.log('img event', e);
                }}
                src=""
                onError={() => setImageError(true)}
              >
                {useSelect &&
                  showCheckBoxOnTop &&
                  (showSelect || selectVisible) &&
                  position === 'vertical' &&
                  !disabled && <div className="checkTop">{checkBox}</div>}
              </CardMedia>
            </div>
          </ImageContainer>
        )}
        <CardContent
          className={clsx('cardContent', {
            cardSize_small: cardSize === 'small',
            cardSize_medium: cardSize === 'medium',
            position_vertical: position === 'vertical',
            position_horizontal: position === 'horizontal',
            hasImage: imgSrc !== undefined,
          })}
        >
          {showHeader && (
            <div
              className={clsx('cardTitle', {
                'title-full-width': !trimTitle,
                'check-or-action':
                  (useSelect || !!topActions) && !(useSelect && !!topActions),
                'check-and-action': useSelect && !!topActions,
              })}
            >
              <div className={'title'}>
                {!!iconTitle && iconTitle}
                {title !== undefined && (
                  <div
                    className={clsx('titleOverRule', {
                      'full-width': !trimTitle,
                    })}
                  >
                    <Tooltip title={title as string}>
                      <span>
                        <Body
                          bold={true}
                          size={cardSize === 'medium' ? 'large' : 'small'}
                        >
                          <React.Fragment>{trimTitleText}</React.Fragment>
                        </Body>
                      </span>
                    </Tooltip>
                  </div>
                )}
              </div>
              <div className={'checkWithAction'}>
                {!!topActions &&
                  topActions.map(c => {
                    return (
                      <span key={c.key} onClick={e => e.stopPropagation()}>
                        <React.Fragment>{c.action}</React.Fragment>
                      </span>
                    );
                  })}
                {(imgSrc === undefined ||
                  position === 'horizontal' ||
                  !showCheckBoxOnTop) &&
                  useSelect &&
                  (showSelect || selectVisible) &&
                  !disabled && (
                    <React.Fragment>
                      {!!checkBoxTitle ? (
                        <Tooltip title={checkBoxTitle}>
                          <React.Fragment>{checkBox}</React.Fragment>
                        </Tooltip>
                      ) : (
                        <React.Fragment>{checkBox}</React.Fragment>
                      )}
                    </React.Fragment>
                  )}
              </div>
            </div>
          )}
          {!!cardContent && (
            <StyledCardContent className={'content'} height={contentHeight}>
              <React.Fragment>{cardContent}</React.Fragment>
            </StyledCardContent>
          )}
          {!!cardContentRows && cardContentRows.length > 0 && (
            <ul
              className={clsx('cardContentRows', {
                position_horizontal: position === 'horizontal',
                mobileSizeMedium: mobileSize === 'medium',
                mobileSizeSmall: mobileSize === 'small',
                cardImageExist: imgSrc !== undefined,
              })}
            >
              {cardContentRows.map(c => {
                return !!descriptionKey && c.label === descriptionKey ? (
                  <li key={`list-key-${c.key}`} className={'descriptionRow'}>
                    {c.text}
                  </li>
                ) : (
                  <li key={`list-key-${c.key}`} className={'contentRow'}>
                    {showBullet && (
                      <Icon
                        icon="circle"
                        fontSize="6px"
                        color="primary"
                        className={'rowBullet'}
                      />
                    )}

                    <span
                      onClickCapture={e => {
                        if (preventInnerClicks) {
                          e.preventDefault();
                        }
                      }}
                      className={'rowOverRule'}
                    >
                      <Body size="small" className={'rowTitle'}>
                        <span>{c.label}</span>
                      </Body>
                      <Body size="small" className={'rowText'}>
                        <span>{c.text}</span>
                      </Body>
                    </span>
                  </li>
                );
              })}
            </ul>
          )}

          {useButtons && !!buttons && (
            <StyledCardActions
              className={'cardActions'}
              contentEditable={contentEditable}
            >
              <div className={'cardActionsButtons'}>
                {buttons.map(c => {
                  return (
                    <span key={c.key} onClick={e => e.stopPropagation()}>
                      <React.Fragment>{c.button}</React.Fragment>
                    </span>
                  );
                })}
              </div>
              {status !== undefined && (
                <div>
                  <Icon
                    icon={
                      status === 'active'
                        ? 'check'
                        : status === 'success'
                        ? 'check-double'
                        : status === 'info'
                        ? 'info'
                        : status === 'warning'
                        ? 'triangle-exclamation'
                        : 'xmark'
                    }
                    color={
                      status === 'active'
                        ? 'primary'
                        : status === 'success'
                        ? 'success'
                        : status === 'info'
                        ? 'info'
                        : status === 'warning'
                        ? 'warning'
                        : 'danger'
                    }
                    size="lg"
                  />
                </div>
              )}
            </StyledCardActions>
          )}
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
}

export const ImageContainer = styled('div')(({ theme }) => ({
  '&.imgContainer': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '10px',
    alignSelf: 'stretch',
    background: `${bookitColors.base.white}`,
    borderRadius: '12px 12px 0px 0px',
    '&.position_horizontal': {
      borderRadius: '12px 0px 0px 12px',
      '&.mobileSizeMedium, &.mobileSizeSmall': {
        padding: '16px 0px 16px 16px',
      },
    },
    '&.position_vertical': {
      '&.cardSize_medium': {
        height: '180px',
      },
      '&.cardSize_small': {
        height: '150px',
      },
    },
    '& .frame': {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      gap: '10px',
      flex: '1 0 0',
      alignSelf: 'stretch',
      borderRadius: '12px 12px 0px 0px',
      background: `${bookitColors.grayscale.graySelection}`,
      '&.position_horizontal': {
        height: 'auto',
        borderRadius: '12px 0px 0px 12px',
        '&.mobileSizeMedium': {
          alignItems: 'center',
          //padding: '16px 0px',
          borderRadius: '8px',
          height: '80px',
        },
        '&.mobileSizeSmall': {
          alignItems: 'center',
          //padding: '16px 0px',
          borderRadius: '8px',
          height: '64px',
        },
      },
      '& .mediaStyles': {
        imageRendering: '-webkit-optimize-contrast',
        backgroundSize: 'auto',
        width: '100%',
        backgroundBlendMode: 'multiply',
        minWidth: 'unset',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 15,
        // '&.position_vertical': {
        //   height: '100%',
        //   width: '100%',
        // },
        '& .checkTop': {
          position: 'absolute',
          top: 0,
          left: 0,
          padding: '5px',
          borderRadius: '8px',
        },
        '&.position_horizontal': {
          height: '100%',
          minWidth: '64px',
          '&.cardSize_small': {
            width: '150px',
            '&.mobileSizeMedium': {
              width: '80px',
              height: '80px',
            },
            '&.mobileSizeSmall': {
              width: '64px',
              height: '64px',
            },
          },
          '&.cardSize_medium': {
            width: '180px',
            '&.mobileSizeMedium': {
              width: '80px',
              height: '80px',
            },
            '&.mobileSizeSmall': {
              width: '64px',
              height: '64px',
            },
          },
        },
        '&.position_vertical': {
          '&.cardSize_small': {
            height: 0,
            paddingTop: 150,
          },
          '&.cardSize_medium': {
            height: 0,
            paddingTop: 180,
          },
        },
        '&.backgroundSizeCover': {
          backgroundSize: 'cover',
        },
        '&.backgroundSizeContain': {
          backgroundSize: 'contain',
        },
      },
    },
  },
}));
export const StyledCard = styled(
  ({
    theme,
    children,
    ...other
  }: {
    theme: Theme;
    children: React.ReactNode;
  } & CardProps) => <Card {...other}>{children}</Card>,
)({
  '&.card': {
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'flex-start',
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderRadius: '12px',
    padding: 0,
    flex: '1 0 0',
    border: `1px solid ${bookitColors.grayscale.grayBorders}`,
    boxShadow: 'unset',
    height: '100%',
    '&.cardActive': {
      boxShadow: bookitShadows.shadowS,
      border: `1px solid ${bookitColors.primary.components}`,
      background: bookitColors.primary.background,
      '&.cardInfo': {
        border: `1px solid ${bookitColors.primary.borders}`,
        background: bookitColors.primary.bgLight,
      },
      '&.cardSuccess': {
        border: `1px solid ${bookitColors.success.components}`,
        background: bookitColors.success.background,
      },
      '&.cardWarning': {
        border: `1px solid ${bookitColors.warning.components}`,
        background: bookitColors.warning.background,
      },
      '&.cardError': {
        border: `1px solid ${bookitColors.danger.components}`,
        background: bookitColors.danger.background,
      },
      // boxShadow: `2px 1px 1px -2px ${bookitColors.primary.components}, 0px 0px 1px 2px ${bookitColors.primary.components}, 0px 1px 1px 0px ${bookitColors.primary.components}`,
    },

    '& .cardActionArea': {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      alignSelf: 'stretch',
      borderRadius: '12px',
      height: '100%',
      '&.position_horizontal': {
        flexDirection: 'row',
      },
      //border: `1px solid ${bookitColors.grayscale.grayBorders}`,
      '&.disabled': {
        backgroundColor: `${bookitColors.grayscale.grayBorders}`,
      },
      '&:hover': {
        opacity: 1,
        boxShadow: bookitShadows.shadowS,
        // border: `1px solid ${bookitColors.primary.components}`,
        background: bookitColors.primary.background,
        '&.cardInfo': {
          //border: `1px solid ${bookitColors.primary.borders}`,
          background: bookitColors.primary.bgLight,
        },
        '&.cardSuccess': {
          //border: `1px solid ${bookitColors.success.components}`,
          background: bookitColors.success.background,
        },
        '&.cardWarning': {
          //border: `1px solid ${bookitColors.warning.components}`,
          background: bookitColors.warning.background,
        },
        '&.cardError': {
          //border: `1px solid ${bookitColors.danger.components}`,
          background: bookitColors.danger.background,
        },
      },
      '& .cardContent': {
        // height: props => props.cardHeight - props.imageHeight, //isMobile ? 'auto' : `${cardcurrentHeight - 84}px`,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 8,
        alignSelf: 'stretch',
        height: '100%',
        justifyContent: 'space-between',
        '&.cardSize_small': {
          padding: '8px 12px 12px 12px',
          width: '100%',
        },
        '&.cardSize_medium': {
          padding: '16',
        },
        '&.position_vertical': {
          '& .cardTitle': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '25px',
            '& .title': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 8,
              '& .titleOverRule': {
                //display: 'inline-block',
                //whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '28ch',
                display: '-webkit-box',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                wordBreak: 'break-all',

                // [theme.breakpoints.down('sm')]: {
                //   maxWidth: '29ch',
                // },
              },
            },
            '& .checkWithAction': {
              display: 'flex',
              flexDirection: 'row',
              //gap: 8,
              alignItems: 'center',
              justifyContent: 'flex-start',
            },
            '&.title-full-width': {
              '&.check-or-action': {
                '& .title': {
                  width: 'calc(100% - 20px)',
                  '& .titleOverRule': {
                    maxWidth: '100%',
                  },
                },
              },
              '&.check-and-action': {
                '& .title': {
                  width: 'calc(100% - 48px)',
                  '& .titleOverRule': {
                    maxWidth: '100%',
                  },
                },
              },
            },
          },
        },
        '&.position_horizontal': {
          '&.cardSize_small.hasImage': {
            width: '80%',
          },
          '& .cardTitle': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '25px',
            '& .title': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 8,
              '& .titleOverRule': {
                //display: 'inline-block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '28ch',
                display: '-webkit-box',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                wordBreak: 'break-all',

                // [theme.breakpoints.down('sm')]: {
                //   maxWidth: '29ch',
                // },
              },
            },
            '& .checkWithAction': {
              display: 'flex',
              flexDirection: 'row',
              //gap: 8,
              alignItems: 'center',
              justifyContent: 'flex-start',
            },
            '&.title-full-width': {
              '&.check-or-action': {
                '& .title': {
                  width: 'calc(100% - 20px)',
                },
              },
              '&.check-and-action': {
                '& .title': {
                  width: 'calc(100% - 48px)',
                },
              },
            },
          },
        },
        '& .content': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: 0,
          margin: 0,
          gap: '8px',
          flexGrow: 1,
          overflowY: 'auto',
          overflowX: 'clip',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            boxShadow: `inset 0 0 2px ${bookitColors.grayscale.grayBorders}`,
            borderRadius: '6px',
            backgroundColor: bookitColors.grayscale.grayBorders,
          },
        },
        '& .cardContentRows': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: 0,
          margin: 0,
          //gap: '8px',
          gap: '4px',
          flexGrow: 2,
          overflowY: 'clip',
          overflowX: 'clip',
          //minHeight: '78px',
          //height: 'auto',
          height: '92px',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            boxShadow: `inset 0 0 2px ${bookitColors.grayscale.grayBorders}`,
            borderRadius: '6px',
            backgroundColor: bookitColors.grayscale.grayBorders,
          },
          '&.position_horizontal': {
            '&.cardImageExist': {
              '& .contentRow': {
                '& .rowOverRule': {
                  '& .rowTitle': {
                    maxWidth: '14ch',
                  },
                  '& .rowText': {
                    maxWidth: '16ch',
                  },
                },
              },
              '&.mobileSizeMedium, &.mobileSizeSmall': {
                '& .contentRow': {
                  '& .rowOverRule': {
                    '& .rowTitle': {
                      maxWidth: '16ch',
                    },
                    '& .rowText': {
                      maxWidth: '16ch',
                    },
                  },
                },
              },
            },
          },
          '& .contentRow': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            padding: 0,
            gap: 8,
            height: 'auto',
            //height: '20px',
            // width: '100%',
            '& .rowBullet': {
              paddingTop: '8px',
            },
            '& .rowOverRule': {
              display: 'flex',
              width: '100%',
              alignItems: 'flex-start',
              // whiteSpace: 'pre',
              // overflow: 'hidden',
              // textOverflow: 'ellipsis',
              // maxWidth: '36ch',
              '& .rowTitle': {
                color: bookitColors.grayscale.graySubtitles,
                paddingRight: 8,
                display: 'inline',
                whiteSpace: 'pre',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '20ch',
                '.cardImageExist &&': {
                  maxWidth: '16ch',
                },
              },
              '& .rowText': {
                color: bookitColors.grayscale.blackRegular,
                display: 'inline',
                whiteSpace: 'pre',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                //maxWidth: '60%',
                width: '28ch',
                '& > *': {
                  display: 'initial',
                },
              },
            },
          },
          '.descriptionRow': {
            cursor: 'pointer',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': '3',
            '-webkit-box-orient': 'vertical',
            wordBreak: 'break-all',
            height: 'max-content',
            minHeight: 'fit-content',
          },
        },
        '& .cardActions': {
          justifyContent: 'space-between',
          padding: '0px',
          gap: 8,
          width: '100%',
          '& .cardActionsButtons': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 8,
            justifyContent: 'flex-start',
            width: '100%',
          },
        },
      },
    },
  },
});

export const BasicCardWithInfo = (props: BasicCardProps) => {
  const { info, ...other } = props;
  return info ? (
    <Tooltip
      title={info}
      PopperProps={{ disablePortal: rdd.isMacOs }}
      placement="bottom"
    >
      <span>
        <BasicCard {...other} />
      </span>
    </Tooltip>
  ) : (
    <BasicCard {...other} />
  );
};
