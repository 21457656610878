/**
 *
 * QuizCustomForm
 *
 */
import * as React from 'react';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { CustomFormField } from './CustomFormFields';
import { ValidationSchemaProvider } from '../Forms/ValidationSchemaProvider';
import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import {
  getCustomFormInitialValues,
  useCustomFormSchema,
} from './CustomFormUtils';
import { CustomFormSkeleton } from './CustomFormSkeleton';

export interface QuizCustomFormProps {
  formFields: IFormFieldDto[];
  formValues?: IFormValueDto[];
  readonly?: boolean;
  onSubmit: (value: CustomFormValue) => void;
  bindSubmitForm: any;
}

export interface CustomFormValue extends Record<number, any> {}

export function QuizCustomForm({
  formFields,
  formValues,
  onSubmit,
  readonly,
  bindSubmitForm,
}: QuizCustomFormProps) {
  const initialValues = getCustomFormInitialValues(
    formFields,
    formValues,
    true,
  );
  const schema = useCustomFormSchema(formFields, false, initialValues);
  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(value);
    }
  };

  if (initialValues === undefined) {
    // show progress while form values are being fetched
    return <CustomFormSkeleton formFields={formFields} />;
  }
  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          console.debug('submitting', values);
          formikHelpers.setSubmitting(true);
          handleSubmit(values);
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          return (
            <ValidationSchemaProvider schema={schema}>
              <>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2} direction="column">
                    {formFields.map((formField, index) => {
                      return (
                        <React.Fragment key={formField.Id}>
                          <Grid item xs={12}>
                            <CustomFormField
                              name={String(formField.Id)}
                              id={String(formField.Id)}
                              key={index}
                              formField={formField}
                              readonly={readonly}
                            />
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </form>
              </>
            </ValidationSchemaProvider>
          );
        }}
      </Formik>
    </>
  );
}
