export enum AssemblyTypes {
  Normal = 0,
  AssembliesAndNormal = 1,
  AssemblyParts = 2,
  AllEquipments = 3,
}

export enum AssemblyTypesShort {
  Normal = 1,
  AssemblyParts = 2,
}
