import { UlList } from 'app/components/BasicList/StyledList';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { IAssetAssemblyStructure } from 'app/pages/AssetPopUp/IAssetAssemblyStructure';
import { AssetLink } from 'app/pages/AssetPopUp/OpenAssetDetails';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface AssemblyStructureProps {
  assemblyStructure: IAssetAssemblyStructure;
  openOnNewTab?: boolean;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
  showTitle?: boolean;
}

export function AssemblyStructure({
  assemblyStructure,
  openOnNewTab,
  useSidePanel,
  openPanelWithCover,
  showTitle,
}: AssemblyStructureProps) {
  const { t } = useTranslation();
  const renderItem = (item: IAssetAssemblyStructure) => {
    if (item.Children.length > 0) {
      let res: React.ReactNode[] = [];
      for (const v of item.Children) {
        res.push(
          <li key={`${v.Name}-${v.AssetId}-li`}>
            <AssetLink
              id={v.AssetId}
              key={`${v.Name}-${v.AssetId}`}
              title={t(translations.Details)}
              useSidePanel={useSidePanel}
              openPanelWithCover={openPanelWithCover}
              openOnNewTab={openOnNewTab}
            >
              {v.Name}
            </AssetLink>
          </li>,
        );
        let childRes = renderItem(v);
        if (childRes !== undefined && childRes.length > 0) {
          res.push(
            <UlList key={`${v.Name}-${v.AssetId}-ul`}>
              {childRes.map((v, i) => v)}
            </UlList>,
          );
        }
      }
      return res;
    } else return undefined;
  };
  return (
    <AssemblyStructureContent className="structure-root">
      {showTitle && (
        <BasicTypography variant="bodyM" bold={true}>
          {t(translations.AssemblyStructure) as string}
        </BasicTypography>
      )}
      <BasicTypography variant="captionBold" color="secondary">
        {t(translations.SnapshotSelectTitle) as string}
      </BasicTypography>

      <UlList>
        <li key={`${assemblyStructure.Name}-${assemblyStructure.AssetId}-li`}>
          <AssetLink
            id={assemblyStructure.AssetId}
            key={`${assemblyStructure.Name}-${assemblyStructure.AssetId}`}
            title={t(translations.Details)}
            useSidePanel={useSidePanel}
            openPanelWithCover={openPanelWithCover}
            openOnNewTab={openOnNewTab}
          >
            {assemblyStructure.Name}
          </AssetLink>
        </li>
        {assemblyStructure.Children.length > 0 && (
          <UlList
            key={`${assemblyStructure.Name}-${assemblyStructure.AssetId}-ul`}
          >
            {renderItem(assemblyStructure)}
          </UlList>
        )}
      </UlList>
    </AssemblyStructureContent>
  );
}
const AssemblyStructureContent = styled.div`
  &.structure-root {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
`;
