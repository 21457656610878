/**
 *
 * CatRateTypePicker
 *
 */
import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import { ICatRateTypeFilterDto } from 'api/odata/generated/entities/ICatRateTypeFilterDto';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { CatRateTypes } from 'enums/CatRateTypes';
import { Entity } from 'types/common';
import { assertExhaustive } from 'utils/assertExhaustive';
import { useAllowManualChargesOutsideInvoices } from './useAllowManualChargesOutsideInvoices';

export interface CatRateTypeProps
  extends Omit<AutocompletePickerProps<Entity<number>>, 'loadData'> {
  /**
   * Set this to "true" if only "Manual" types need to be displayed
   *
   */
  manual?: boolean;
  bySystemSetting?: boolean;
  invoiceId?: number | null;
}

const url = '/api/odata/v4/CatRateTypeFilter';
const systemUrl = url + '/WithSystemSetting';
export const initCatRateTypeData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const getLoadData =
  (
    manual: boolean,
    bysSystemSetting?: boolean,
    feeTypes?: Array<CatRateTypes>,
  ) =>
  (searchTerm: string | null): Promise<Entity<number>[]> => {
    const params: { $orderby: string; $filter?: string } = {
      $orderby: 'Name asc',
    };

    const predicates: Array<string | Condition<ICatRateTypeFilterDto>> = [
      new Condition<ICatRateTypeFilterDto>(
        'Active',
        ODataOperators.Equals,
        true,
      ),
    ];
    if (manual) {
      predicates.push(
        new Condition<ICatRateTypeFilterDto>(
          'Manual',
          ODataOperators.Equals,
          true,
        ),
      );
    }
    if (feeTypes !== undefined) {
      predicates.push(`(Id in (${feeTypes?.join(',')}))`);
    }
    params.$filter = new ODataFilterBuilder<ICatRateTypeFilterDto>({
      predicates: predicates,
      stringColumns: ['Name'],
      stringSearch: searchTerm ?? undefined,
    }).toString();

    return httpClient
      .get(bysSystemSetting ? systemUrl : url, params)
      .then(response => response.value as Entity<number>[]);
  };
export type AllowManualChargesOutsideInvoice =
  | 'DoNotAllowManualCharges'
  | 'AllowOnlyManualCharges'
  | 'AllowManualChargesAndRefunds';
export function CatRateTypePicker({
  manual,
  bySystemSetting,
  invoiceId,
  ...props
}: CatRateTypeProps) {
  const allowManualChargesOutsideInvoiceSetting: AllowManualChargesOutsideInvoice =
    useAllowManualChargesOutsideInvoices();
  const getX = (isInvoice, x: AllowManualChargesOutsideInvoice) => {
    if (isInvoice) {
      return undefined;
    }
    switch (x) {
      case 'AllowOnlyManualCharges':
        return [CatRateTypes.Other];
      case 'AllowManualChargesAndRefunds':
        return [CatRateTypes.Other, CatRateTypes.Refund, CatRateTypes.Discount];
      case 'DoNotAllowManualCharges':
        return [];
      default:
        assertExhaustive(x);
    }
  };
  const loadData = React.useCallback(
    async (search: string | null) => {
      const x = getX(
        invoiceId !== undefined && invoiceId !== null && !isNaN(invoiceId),
        allowManualChargesOutsideInvoiceSetting,
      );
      const data = await getLoadData(
        manual === true,
        bySystemSetting,
        x,
      )(search);
      return data;
    },
    [
      manual,
      bySystemSetting,
      allowManualChargesOutsideInvoiceSetting,
      invoiceId,
    ],
  );
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'catRateTypeId'}
      loadData={loadData}
      {...props}
    />
  );
}
