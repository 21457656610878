import { IOfflineServiceTypeRefsDto } from 'api/odata/generated/entities/IOfflineServiceTypeRefsDto';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { MandatoryOfflineTypeEnum } from 'enums/MandatoryOfflineTypeEnum';
import { OfflineReferenceTypeEnum } from 'enums/OfflineReferenceTypeEnum';
import { TFunction } from 'i18next';
import { AsyncDataState, Entity } from 'types/common';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { IResponseType } from 'types/ResponseType';
import { toEntity } from 'utils/entity-utils';
import { getSingleEnumEntityById } from 'utils/enumKeys';

/* --- STATE --- */
export interface OfflineTypeRefsState {
  detailsState: AsyncDataState<IOfflineTypeRefsModel>;
  processing?: boolean;
  isEdit?: boolean;
  filters?: Record<string, any>;
}
export interface OfflineTypeRefsQueryParams {
  id?: string;
  refId?: string;
  refTypeId?: string;
}

export interface IOfflineTypeRefsModel {
  Id: number;
  RefService: IServiceTypeFilterDto | null;
  OfflineServiceType: Entity<number> | null;
  RefType: Entity<number> | null;
  Mandatory: Entity<number> | null;
  DefaultQuantity: number | null;
}
export interface IOfflineTypeRefsResponse extends IResponseType {
  Id: number | null;
  data: IOfflineServiceTypeRefsDto | null;
  IsValid: boolean;
}

export const ConvertDtoToModel = (
  data: IOfflineServiceTypeRefsDto,
  offlineType?: IOfflineServiceFilterDto,
  t?: TFunction,
): IOfflineTypeRefsModel => {
  return {
    Id: data.Id,
    RefService: toEntity(data.RefId, data.Name, {
      ServiceGroupId: data.ServiceGroupId,
    }) as IServiceTypeFilterDto,
    OfflineServiceType: !!offlineType
      ? offlineType
      : toEntity(data.OfflineServiceTypeId, data.OfflineServiceTypeName, {
          ServiceGroupId: data.ServiceGroupId,
        }),
    RefType: getSingleEnumEntityById(
      data.RefTypeId,
      OfflineReferenceTypeEnum,
      t,
    ),
    Mandatory: getSingleEnumEntityById(
      data.Mandatory,
      MandatoryOfflineTypeEnum,
      t,
    ),
    DefaultQuantity: data.DefaultQuantity,
  };
};

export const ConverModelToDto = (
  dto: IOfflineTypeRefsModel,
): IOfflineServiceTypeRefsDto => {
  return {
    Id: dto.Id,
    RefId: dto.RefService?.Id ?? 0,
    Name: dto.RefService?.Name ?? '',
    OfflineServiceTypeId: dto.OfflineServiceType?.Id ?? 0,
    OfflineServiceTypeName: dto.OfflineServiceType?.Name ?? '',
    RefTypeId: dto.RefType?.Id ?? 0,
    Mandatory: dto.Mandatory?.Id ?? 0,
    DefaultQuantity:
      Number(dto.DefaultQuantity) === 0 ? null : Number(dto.DefaultQuantity),
    SampleRequestTypeId: null,
    SampleRequestTypeName: null,
    ServiceGroupId: 0,
    OnlineServiceTypeId: null,
    OnlineServiceTypeName: null,
  };
};
export const MergeModelsToDto = (
  dto: IOfflineTypeRefsModel,
  original: IOfflineTypeRefsModel,
): IOfflineServiceTypeRefsDto => {
  return {
    Id: original.Id,
    RefId: dto.RefService?.Id ?? original.RefService?.Id ?? 0,
    Name: dto.RefService?.Name ?? original.RefService?.Name ?? '',
    OfflineServiceTypeId:
      dto.OfflineServiceType?.Id ?? original.OfflineServiceType?.Id ?? 0,
    OfflineServiceTypeName:
      dto.OfflineServiceType?.Name ?? original.OfflineServiceType?.Name ?? '',
    RefTypeId: dto.RefType?.Id ?? original.RefType?.Id ?? 0,
    Mandatory: dto.Mandatory?.Id ?? original.Mandatory?.Id ?? 0,
    DefaultQuantity:
      Number(dto.DefaultQuantity) === 0 ? null : Number(dto.DefaultQuantity),
    SampleRequestTypeId: null,
    SampleRequestTypeName: null,
    ServiceGroupId: 0,
    OnlineServiceTypeId: null,
    OnlineServiceTypeName: null,
  };
};
export const getDefaultModel = (): IOfflineServiceTypeRefsDto => {
  return {
    Id: 0,
    RefId: 0,
    Name: '',
    OfflineServiceTypeId: 0,
    OfflineServiceTypeName: '',
    RefTypeId: 1,
    Mandatory: 0,
    DefaultQuantity: null,
    SampleRequestTypeId: null,
    SampleRequestTypeName: null,
    ServiceGroupId: 0,
    OnlineServiceTypeId: null,
    OnlineServiceTypeName: null,
  } as IOfflineServiceTypeRefsDto;
};
