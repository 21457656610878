import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { IRow } from 'app/components/BasicTable/IRow';
import { Link as MuiLink } from '@material-ui/core';
import { translations } from 'locales/translations';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { PrimaryActionDynamic } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { useSelector } from 'react-redux';
import { selectglobalSettings, selectPublicUrl } from 'app/slice/selectors';
import { buildURL } from 'utils/url-utils';
import { UserDetailsProps } from '../Details';
import { AllowedSettings } from 'utils/globalSettings';
import styled from 'styled-components';
import { useUserPermissions } from 'app/permissions/Users/useUserPermissions';
import { Icon } from 'app/components/BasicIcons/FontAwesome';

export type UserProfileLinkProps = {
  userName?: string;
  displayName?: string;
  children?: React.ReactNode;
  title?: string;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
  openOnNewTab?: boolean;
  twoRows?: boolean;
};
export interface UserNameFormattedProps {
  UserId?: string;
  DisplayName?: string;
  twoRows?: boolean;
}
export const UserNameFormatted = React.forwardRef<any, UserNameFormattedProps>(
  function UserProfileName(props, ref) {
    const { UserId, DisplayName, twoRows = true, ...rest } = props;
    const settings = useSelector(selectglobalSettings);
    const format = React.useMemo(() => {
      return settings.GetString(AllowedSettings.UserFormatResult);
    }, [settings]);

    return (
      <UserNameFormated className="user-formated" {...rest}>
        {!!DisplayName && DisplayName}
        {format === 'DisplayNameUserName' && !!UserId ? (
          <>
            {twoRows && <br />}
            <span>{` (${UserId}) `}</span>
          </>
        ) : (
          <>{!DisplayName && !!UserId && UserId}</>
        )}
      </UserNameFormated>
    );
  },
);
export const UserProfileLink = (props: UserProfileLinkProps) => {
  const { t } = useTranslation();
  const openProfile = useOpenUserProfilePopUp();

  const getUrl = useUserProfileLinkUrl();
  const link = getUrl({
    un: props.userName,
  });
  const open = () => {
    openProfile(
      {
        useSidePanel: props.useSidePanel,
        un: props.userName,
      },
      props.openPanelWithCover,
    );
  };
  const { allowedToView } = useUserPermissions();
  return allowedToView({ Name: props.userName ?? '' }) ? (
    <Tooltip title={props.title ?? t(translations.UserProfile)}>
      <React.Fragment>
        <Icon icon="user" size="sm" color="primary" colorExtend="main" />
        <MuiLink
          onClick={event => {
            if (!!link) {
              if (!event.ctrlKey && !props.openOnNewTab) {
                event.preventDefault();
                open();
              }
            } else {
              open();
            }
          }}
          color="textPrimary"
          target={props.openOnNewTab ? '_blank' : undefined}
          href={link}
        >
          {!!props.children ? (
            <>{props.children}</>
          ) : (
            <UserNameFormatted
              UserId={props.userName}
              DisplayName={props.displayName}
              twoRows={props.twoRows}
            />
          )}
        </MuiLink>
      </React.Fragment>
    </Tooltip>
  ) : (
    <React.Fragment>
      {!!props.children ? (
        <>{props.children}</>
      ) : (
        <UserNameFormatted
          UserId={props.userName}
          DisplayName={props.displayName}
          twoRows={props.twoRows}
        />
      )}
    </React.Fragment>
  );
};

export type UseOpenUserProfilePopUpProps = {
  un?: string;
  useSidePanel?: boolean;
};
export const useOpenUserProfilePopUp = () => {
  const { openPanel } = useSidePanelState();
  const settings = useSelector(selectglobalSettings);
  const openAsSidePanel = React.useMemo(() => {
    return settings.GetBoolean(
      AllowedSettings[AllowedSettings.OpenUserProfileOnSidePanel],
    );
  }, [settings]);
  return React.useCallback(
    (
      props: UseOpenUserProfilePopUpProps,
      openPanelWithCover?: (state: SidePanelOpenState) => void,
    ) => {
      const userName = props.un;
      if (userName !== undefined && userName !== '') {
        const userPopupProps: UserDetailsProps = {
          userName: userName,
          useSidePanel: props.useSidePanel ?? false,
        };
        if (!!openPanelWithCover) {
          openPanelWithCover({
            renderPageType: RenderPageType.UserProfile,
            renderPageProps: userPopupProps,
            expanded: false,
            useSidePanel: props.useSidePanel,
            isCover: props.useSidePanel,
          });
        } else {
          openPanel({
            renderPageType: RenderPageType.UserProfile,
            renderPageProps: userPopupProps,
            expanded: !openAsSidePanel,
            useSidePanel: false,
            isCover: false,
          } as SidePanelOpenState);
        }
      }
    },
    [openAsSidePanel, openPanel],
  );
};
export const useUserProfileLinkUrl = () => {
  const publicUrl = useSelector(selectPublicUrl);
  return React.useCallback(
    (params: { un?: string }) => {
      return buildURL(`${publicUrl}User/Profile`, params);
    },
    [publicUrl],
  );
};

interface PrimaryActionDynamicRow extends IRow {
  Name?: string;
}

//used for Dashboard tadles
export const OpenUserProfilePrimaryAction: PrimaryActionDynamic<
  PrimaryActionDynamicRow
> = (openPanel, row, toggle, user, useSidePanel) => {
  const userName = row.original.Name;
  if (userName === undefined) {
    console.error('no user name found for row ', row);
  } else {
    const props: UserDetailsProps = {
      userName: userName,
      useSidePanel: useSidePanel ?? false,
      screenType: 'UserProfile',
    };
    openPanel({
      renderPageType: RenderPageType.UserProfile,
      renderPageProps: props,
      useSidePanel: useSidePanel,
      expanded: false,
      isCover: useSidePanel,
    });
  }
};
const UserNameFormated = styled.span`
  &.user-formated {
    margin-left: 8px;
  }
`;
