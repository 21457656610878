export enum UserGroupsProviderTypes {
  /// <summary>
  /// user groups are sourced internally
  /// </summary>
  SQL,
  /// <summary>
  /// user groups are taken externally from AD
  /// </summary>
  AD,
  /// <summary>
  /// Same as AD, except:
  /// User will keep his (SQL) group after his (AD) group was removed
  /// </summary>
  Combo,
}

export enum AutoMode {
  /// <summary>
  /// Usage enabled on equipment
  /// </summary>
  LocalAppEnabled = 1,
  /// <summary>
  /// Usage disabled on equipment
  /// </summary>
  LocalAppDisabled = 2,
}
