import { PayloadAction } from '@reduxjs/toolkit';
import { ILocationListDto } from 'api/odata/generated/entities/ILocationListDto';
import { IRoomDto } from 'api/odata/generated/entities/IRoomDto';
import { ISubLocationListDto } from 'api/odata/generated/entities/ISubLocationListDto';
import { appSettingsActions } from 'app/slice';
import {
  selectAppSettings,
  selectBarcodeScanTarget,
  selectGlobalSetting,
} from 'app/slice/selectors';
import { parseBarcode, ScanTarget, ScanTemplateResult } from 'app/slice/types';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { BARCODE_SCANNED } from 'react-usb-barcode-scanner';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AppSettings } from 'types/AppSettings';
import { AllowedSettings } from 'utils/globalSettings';
import { tryParseInt } from 'utils/string-utils';
import { assetInventoryActions as actions } from '.';
import {
  selectLocationSelected,
  selectRoomSelected,
  selectScanProcessing,
  selectSubLocationSelected,
} from './selectors';
import { AssetBarcodeScanParameters, AssetBarcodeScanResult } from './types';
import { BarcodeScanApi as api } from 'api/BarcodeScanApi';

function* doSetSelectedRoom(action: PayloadAction<IRoomDto | null>) {
  yield put(actions.setSelectedRoom_Success(action.payload));
}
function* doSetSelectedLocation(
  action: PayloadAction<ILocationListDto | null>,
) {
  yield put(actions.setSelectedLocation_Success(action.payload));
}
function* doSetSelectedSubLocation(
  action: PayloadAction<ISubLocationListDto | null>,
) {
  yield put(actions.setSelectedSubLocation_Success(action.payload));
}
function* doBarcodeScan(action: PayloadAction<{ data: string }>) {
  // const MIN_LENGTH_DEFAULT = 6;
  // const minBarcodeLengthString: string | undefined = yield select(state =>
  //   selectGlobalSetting(state, AllowedSettings.MinBarcodeLength),
  // );
  const minBarcodeAssetLengthString: string | undefined = yield select(state =>
    selectGlobalSetting(state, AllowedSettings.QRCodeBaseLength),
  );
  // const MinBarcodeLength =
  //   tryParseInt(minBarcodeLengthString) ?? MIN_LENGTH_DEFAULT;
  const MinAssetQRLength = tryParseInt(minBarcodeAssetLengthString) ?? 0;
  // prevent accidental barcode detection on fast user input
  if (action.payload.data.length < MinAssetQRLength) {
    return;
  }
  const scannTarget: ScanTarget | undefined = yield select(
    selectBarcodeScanTarget,
  );
  if (scannTarget === undefined || scannTarget !== ScanTarget.Inventory) {
    return;
  }
  const isScanning = yield select(selectScanProcessing);
  if (isScanning === true) {
    return;
  }
  yield put(actions.setScanProcessing(true));
  const appSettings: AppSettings = yield select(selectAppSettings);
  yield put(
    appSettingsActions.addNotification({
      variant: 'info',
      message:
        i18next.t(translations.BarcodeScanModalBody) +
        ' ' +
        action.payload.data,
    }),
  );
  try {
    const scanResult = parseBarcode(action.payload.data, appSettings);
    if (scanResult === undefined) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'error',
          message: (
            i18next.t(translations.BarcodeNotFoundEx) as string
          ).replace('{{barcode}}', action.payload.data),
        }),
      );
      yield put(actions.scanManualBarcode_error());
    } else {
      const room: IRoomDto | null = yield select(selectRoomSelected);
      const location: ILocationListDto | null = yield select(
        selectLocationSelected,
      );
      const subLocation: ISubLocationListDto | null = yield select(
        selectSubLocationSelected,
      );
      // in case when barcode is entered manually
      if (
        scanResult.type === ScanTemplateResult.Internal ||
        scanResult.type === ScanTemplateResult.Asset
      ) {
        let params = {
          Barcode: scanResult.InternalCode ?? '',
          RoomId: room?.Id,
          LocationId: location?.Id,
          SubLocationId: subLocation?.Id,
        } as AssetBarcodeScanParameters;
        const result = yield call(api.scanBarcode, params);
        let response = result as AssetBarcodeScanResult;
        if (response.SuccessMessages.length > 0) {
          if (!!response.RoomDetails) {
            if (room !== null && room !== undefined) {
              if (room.Id !== response.RoomDetails.Id) {
                yield put(
                  actions.setSelectedRoom_Success(response.RoomDetails),
                );
                yield put(actions.setSelectedLocation_Success(null));
                yield put(actions.setSelectedSubLocation_Success(null));
              }
            } else {
              yield put(actions.setSelectedRoom_Success(response.RoomDetails));
              yield put(actions.setSelectedLocation_Success(null));
              yield put(actions.setSelectedSubLocation_Success(null));
            }
          }
          yield put(
            appSettingsActions.addNotification({
              variant: 'success',
              message: response.SuccessMessages[0],
            }),
          );
          yield put(actions.scanManualBarcode_Success());
        } else {
          yield put(
            appSettingsActions.addNotification({
              variant: 'error',
              message: response.ErrorMessages[0],
            }),
          );
          yield put(actions.scanManualBarcode_error());
        }
      } else if (scanResult.type === ScanTemplateResult.Room) {
        if (
          room !== null &&
          room !== undefined &&
          room.Id === tryParseInt(scanResult.RoomId)
        ) {
          yield put(
            appSettingsActions.addNotification({
              variant: 'warning',
              message: `Room: ${room.Name} already selected`,
            }),
          );
          yield put(actions.scanManualBarcode_error());
        } else {
          // const roomData = yield call(
          //   initRoomData,
          //   scanResult.RoomId,
          //   undefined,
          //   undefined,
          //   [
          //     'Id',
          //     'Name',
          //     'AreaId',
          //     'Areaname',
          //     'Barcode',
          //     'BuildingDescription',
          //     'BuildingId',
          //     'BuildingName',
          //     'Description',
          //     'Floor',
          //     'CampusId',
          //     'CampusName',
          //   ],
          // );
          if (scanResult.RoomId !== undefined) {
            const roomResult = yield call(
              api.logRoomScan,
              parseInt(scanResult.RoomId),
            );
            let response = roomResult as AssetBarcodeScanResult;
            if (response.SuccessMessages.length > 0) {
              if (!!response.RoomDetails) {
                yield put(
                  actions.setSelectedRoom_Success(response.RoomDetails),
                );
                yield put(actions.setSelectedLocation_Success(null));
                yield put(actions.setSelectedSubLocation_Success(null));
              }
              yield put(
                appSettingsActions.addNotification({
                  variant: 'success',
                  message: response.SuccessMessages[0],
                }),
              );
              yield put(actions.scanManualBarcode_Success());
            } else {
              yield put(
                appSettingsActions.addNotification({
                  variant: 'error',
                  message: response.ErrorMessages[0],
                }),
              );
              yield put(actions.scanManualBarcode_error());
            }
            // yield put(actions.setSelectedRoom_Success(roomData[0]));
            // yield put(actions.setSelectedLocation_Success(null));
            // yield put(actions.setSelectedSubLocation_Success(null));
            // yield put(
            //   appSettingsActions.addNotification({
            //     variant: 'success',
            //     message: i18next.t(translations.RoomScanned),
            //   }),
            // );
            // yield put(actions.scanManualBarcode_Success());
          }
        }
      } else if (scanResult.type === ScanTemplateResult.Location) {
        if (
          location !== null &&
          location !== undefined &&
          location.Id === tryParseInt(scanResult.LocationId) &&
          room?.Id === tryParseInt(scanResult.RoomId)
        ) {
          yield put(
            appSettingsActions.addNotification({
              variant: 'warning',
              message: `Location: ${location.Name} already selected`,
            }),
          );
          yield put(actions.scanManualBarcode_error());
        } else {
          if (
            scanResult.LocationId !== undefined &&
            scanResult.RoomId !== undefined
          ) {
            const roomResult = yield call(
              api.logLocationScan,
              parseInt(scanResult.RoomId),
              parseInt(scanResult.LocationId),
            );
            let response = roomResult as AssetBarcodeScanResult;
            if (response.SuccessMessages.length > 0) {
              if (!!response.RoomDetails && !!response.LocationDetails) {
                yield put(
                  actions.setSelectedRoom_Success(response.RoomDetails),
                );
                yield put(
                  actions.setSelectedLocation_Success(response.LocationDetails),
                );
                yield put(actions.setSelectedSubLocation_Success(null));
              }
              yield put(
                appSettingsActions.addNotification({
                  variant: 'success',
                  message: response.SuccessMessages[0],
                }),
              );
              yield put(actions.scanManualBarcode_Success());
            } else {
              yield put(
                appSettingsActions.addNotification({
                  variant: 'error',
                  message: response.ErrorMessages[0],
                }),
              );
              yield put(actions.scanManualBarcode_error());
            }
          } else {
            yield put(
              appSettingsActions.addNotification({
                variant: 'error',
                message: `Scanned Location not found`,
              }),
            );
            yield put(actions.scanManualBarcode_error());
          }
          // const locationData = yield call(
          //   initLocationData,
          //   scanResult.LocationId,
          // );
          // let locationResults = locationData as ILocationListDto[];
          // if (!locationResults || locationResults.length === 0) {
          //   yield put(
          //     appSettingsActions.addNotification({
          //       variant: 'error',
          //       message: `Scanned Location not found`,
          //     }),
          //   );
          //   yield put(actions.scanManualBarcode_error());
          // } else {
          //   let loc = locationResults[0];
          //   if (
          //     room === null ||
          //     room === undefined ||
          //     (loc.RoomId ?? 0) !== room.Id
          //   ) {
          //     if (loc.RoomId !== null && loc.RoomId !== undefined) {
          //       const roomLocationData = yield call(
          //         initRoomData,
          //         loc.RoomId.toString(),
          //         undefined,
          //         undefined,
          //         [
          //           'Id',
          //           'Name',
          //           'AreaId',
          //           'Areaname',
          //           'Barcode',
          //           'BuildingDescription',
          //           'BuildingId',
          //           'BuildingName',
          //           'Description',
          //           'Floor',
          //           'CampusId',
          //           'CampusName',
          //         ],
          //       );
          //       let roomLocData = roomLocationData[0];
          //       if (!roomLocData) {
          //         yield put(
          //           appSettingsActions.addNotification({
          //             variant: 'error',
          //             message: `No related Room found, Please select a Room first`,
          //           }),
          //         );
          //         yield put(actions.scanManualBarcode_error());
          //       } else {
          //         yield put(actions.setSelectedRoom_Success(roomLocData));
          //         yield put(actions.setSelectedLocation_Success(loc));
          //         yield put(actions.setSelectedSubLocation_Success(null));
          //         yield put(
          //           appSettingsActions.addNotification({
          //             variant: 'success',
          //             message: 'Location Scanned',
          //           }),
          //         );
          //         yield put(actions.scanManualBarcode_Success());
          //       }
          //     } else {
          //       yield put(
          //         appSettingsActions.addNotification({
          //           variant: 'error',
          //           message: `No related Room found for scanned Location, Please select a Room first`,
          //         }),
          //       );
          //       yield put(actions.scanManualBarcode_error());
          //     }
          //   } else {
          //     yield put(actions.setSelectedLocation_Success(loc));
          //     yield put(actions.setSelectedSubLocation_Success(null));
          //     yield put(
          //       appSettingsActions.addNotification({
          //         variant: 'success',
          //         message: 'Location Scanned',
          //       }),
          //     );
          //     yield put(actions.scanManualBarcode_Success());
          //   }
          // }
        }
      } else if (scanResult.type === ScanTemplateResult.SubLocation) {
        if (
          subLocation !== null &&
          subLocation !== undefined &&
          subLocation.Id === tryParseInt(scanResult.SubLocationId) &&
          subLocation.LocationListId === tryParseInt(scanResult.LocationId) &&
          subLocation.RoomId === tryParseInt(scanResult.RoomId)
        ) {
          yield put(
            appSettingsActions.addNotification({
              variant: 'warning',
              message: `Sub Location: ${subLocation.Name} already selected`,
            }),
          );
          yield put(actions.scanManualBarcode_error());
        } else {
          if (
            scanResult.SubLocationId !== undefined &&
            scanResult.LocationId !== undefined &&
            scanResult.RoomId !== undefined
          ) {
            const roomResult = yield call(
              api.logSubLocationScan,
              parseInt(scanResult.RoomId),
              parseInt(scanResult.LocationId),
              parseInt(scanResult.SubLocationId),
            );
            let response = roomResult as AssetBarcodeScanResult;
            if (response.SuccessMessages.length > 0) {
              if (
                !!response.RoomDetails &&
                !!response.LocationDetails &&
                !!response.SubLocationDetails
              ) {
                yield put(
                  actions.setSelectedRoom_Success(response.RoomDetails),
                );
                yield put(
                  actions.setSelectedLocation_Success(response.LocationDetails),
                );
                yield put(
                  actions.setSelectedSubLocation_Success(
                    response.SubLocationDetails,
                  ),
                );
              }
              yield put(
                appSettingsActions.addNotification({
                  variant: 'success',
                  message: response.SuccessMessages[0],
                }),
              );
              yield put(actions.scanManualBarcode_Success());
            } else {
              yield put(
                appSettingsActions.addNotification({
                  variant: 'error',
                  message: response.ErrorMessages[0],
                }),
              );
              yield put(actions.scanManualBarcode_error());
            }
          } else {
            yield put(
              appSettingsActions.addNotification({
                variant: 'error',
                message: `Scanned Sub Location not found`,
              }),
            );
            yield put(actions.scanManualBarcode_error());
          }
          // const subLocationData = yield call(
          //   initSubLocationListData,
          //   scanResult.SubLocationId,
          // );
          // let subResults = subLocationData as ISubLocationListDto[];
          // if (!subResults || subResults.length === 0) {
          //   yield put(
          //     appSettingsActions.addNotification({
          //       variant: 'error',
          //       message: `Scanned Sub Location not found`,
          //     }),
          //   );
          //   yield put(actions.scanManualBarcode_error());
          // } else {
          //   let sub = subResults[0];
          //   if (
          //     room === null ||
          //     room === undefined ||
          //     (sub.RoomId ?? 0) !== room.Id
          //   ) {
          //     if (sub.RoomId !== null && sub.RoomId !== undefined) {
          //       const roomSubLocData = yield call(
          //         initRoomData,
          //         sub.RoomId.toString(),
          //         undefined,
          //         undefined,
          //         [
          //           'Id',
          //           'Name',
          //           'AreaId',
          //           'Areaname',
          //           'Barcode',
          //           'BuildingDescription',
          //           'BuildingId',
          //           'BuildingName',
          //           'Description',
          //           'Floor',
          //           'CampusId',
          //           'CampusName',
          //         ],
          //       );
          //       let roomSubData = roomSubLocData[0];
          //       if (!roomSubData) {
          //         yield put(
          //           appSettingsActions.addNotification({
          //             variant: 'error',
          //             message: `No related Room found, Please select a Room first`,
          //           }),
          //         );
          //         yield put(actions.scanManualBarcode_error());
          //       } else {
          //         const locSubData = yield call(
          //           initLocationData,
          //           sub.LocationListId.toString(),
          //         );
          //         yield put(actions.setSelectedRoom_Success(roomSubData));
          //         yield put(actions.setSelectedLocation_Success(locSubData[0]));
          //         yield put(actions.setSelectedSubLocation_Success(sub));
          //         yield put(
          //           appSettingsActions.addNotification({
          //             variant: 'success',
          //             message: 'Sub Location Scanned',
          //           }),
          //         );
          //         yield put(actions.scanManualBarcode_Success());
          //       }
          //     } else {
          //       yield put(
          //         appSettingsActions.addNotification({
          //           variant: 'error',
          //           message: `No related Room found for scanned Sub Location, Please select a Room first`,
          //         }),
          //       );
          //       yield put(actions.scanManualBarcode_error());
          //     }
          //   } else if (location === null || location === undefined) {
          //     const locSubData = yield call(
          //       initLocationData,
          //       sub.LocationListId.toString(),
          //     );
          //     yield put(actions.setSelectedLocation_Success(locSubData[0]));
          //     yield put(actions.setSelectedSubLocation_Success(sub));
          //     yield put(
          //       appSettingsActions.addNotification({
          //         variant: 'success',
          //         message: 'Sub Location Scanned',
          //       }),
          //     );
          //     yield put(actions.scanManualBarcode_Success());
          //   } else {
          //     yield put(actions.setSelectedSubLocation_Success(sub));
          //     yield put(
          //       appSettingsActions.addNotification({
          //         variant: 'success',
          //         message: 'Sub Location Scanned',
          //       }),
          //     );
          //     yield put(actions.scanManualBarcode_Success());
          //   }
          // }
        }
      } else {
        yield put(
          appSettingsActions.addNotification({
            variant: 'error',
            message: (
              i18next.t(translations.BarcodeNotFoundEx) as string
            ).replace('{{barcode}}', action.payload.data),
          }),
        );
        yield put(actions.scanManualBarcode_error());
      }
    }
  } catch (error) {
    console.error(`${action.type}`, action.payload, error);
    yield put(actions.scanManualBarcode_error());
  }
}
function* doSetNoRoomAccess() {
  const roomSelected: IRoomDto | null = yield select(selectRoomSelected);
  if (roomSelected === null || roomSelected === undefined) {
    yield put(
      appSettingsActions.addNotification({
        variant: 'error',
        message: 'Please select a Room first',
      }),
    );
    yield put(actions.setNoRoomAccess_error());
  } else {
    const result = yield call(api.logNoRoomAccess, roomSelected.Id);
    let response = result as AssetBarcodeScanResult;
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'success',
          message: response.SuccessMessages[0],
        }),
      );
      if (response.WarningMessages.length > 0) {
        yield put(
          appSettingsActions.addNotification({
            variant: 'warning',
            message: response.WarningMessages[0],
          }),
        );
      }
      yield put(actions.setNoRoomAccess_success());
    }
    if (response.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'error',
          message: response.ErrorMessages[0],
        }),
      );
      yield put(actions.setNoRoomAccess_error());
    }
  }
}
export function* assetInventoryStateSaga() {
  yield takeLatest(
    actions.setSelectedSubLocation.type,
    doSetSelectedSubLocation,
  );
  yield takeLatest(actions.setSelectedLocation.type, doSetSelectedLocation);
  yield takeLatest(actions.setSelectedRoom.type, doSetSelectedRoom);
  yield takeLatest(BARCODE_SCANNED, doBarcodeScan);
  yield takeLatest(actions.scanManualBarcode, doBarcodeScan);
  yield takeLatest(actions.setNoRoomAccess, doSetNoRoomAccess);
}
