import { PayloadAction } from '@reduxjs/toolkit';
import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import { IServiceRequestDto } from 'api/odata/generated/entities/IServiceRequestDto';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { requestSamplesSaga } from './saga';
import {
  ISample,
  ISampleListTableState,
  IRequestSamplesState,
  IServiceRequestPatchAction,
  IServiceRequestUpdateStatusAction,
  IServiceRequestUpdateBudgetAction,
  ILoadServiceRequestMilestonesAction,
  ILoadServiceRequestMilestonesSuccessAction,
  InsertServiceRequestMilestoneChargeAction,
  UpdateServiceRequestMilestoneChargeAction,
  UpdateServiceRequestRowValueAction,
  IServiceRequestTableRowModel,
  IRequestDetailsModel,
  IServiceRequestDetailsFormModel,
  IServiceRequestUpdatePurchaseOrderAction,
  IServiceRequestUpdateFundingTypeAction,
  IServiceRequestUpdateExternalInternalNumberAction,
  IServiceRequestUpdateGradientAction,
  IServiceRequestUpdateOverheadAction,
  ILoadServiceRequestRowsAction,
  InsertServiceRequestMilestoneChargesAction,
} from './types';
import { IServiceRequestMilestoneChargeDto } from 'api/odata/generated/entities/IServiceRequestMilestoneChargeDto';
import { merge, remove } from 'lodash';
import { IServiceRequestMilestoneDto } from 'api/odata/generated/entities/IServiceRequestMilestoneDto';
import { IOpenSidePanelPayload } from 'app/Layout/FrontendLayout/slice';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';
import { IServiceRequestRowDto } from 'api/odata/generated/entities/IServiceRequestRowDto';
import { IServiceRequestRowStatusDto } from 'api/odata/generated/entities/IServiceRequestRowStatusDto';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';

export const initialState: IRequestSamplesState = {
  RequestSamples: {},
  ServiceRequestStatuses: {},
  ServiceRequestRows: {},
  fieldsState: {},
  SamplesTableState: {
    pageSize: 10,
    pageIndex: 0,
    sortBy: [{ id: 'Id', desc: true }],
    customFilters: [],
    globalFilter: '',
  },
  SamplesSelected: [],
  RequestDetails: {},
  ImportState: {
    open: false,
    processing: false,
  },
};

const slice = createSlice({
  name: 'requestSamples',
  initialState,
  reducers: {
    init(state, action: PayloadAction) {},
    initServiceRequestStatuses_Success(
      state,
      action: PayloadAction<Array<IServiceRequestStatusDto>>,
    ) {
      state.ServiceRequestStatuses = {
        status: 'resolved',
        value: action.payload,
      };
    },
    initServiceRequestStatuses_Error(state, action: PayloadAction) {
      state.ServiceRequestStatuses = {
        status: 'rejected',
      };
    },
    loadServiceRequestRowStatuses_Success(
      state,
      action: PayloadAction<Array<IServiceRequestRowStatusDto>>,
    ) {
      state.ServiceRequestRowStatuses = action.payload;
    },
    loadRequestDetails(state, action: PayloadAction<number>) {
      state.RequestDetails = { status: 'pending' };
      state.serviceRequestId = action.payload;
    },
    loadRequestDetails_Success(
      state,
      action: PayloadAction<IRequestDetailsModel>,
    ) {
      state.RequestDetails = {
        status: 'resolved',
        value: action.payload,
      };
    },
    loadRequestDetails_Error(state, action: PayloadAction<unknown>) {
      state.RequestDetails = {
        status: 'rejected',
        error: action.payload,
      };
    },
    resetRequestDetails(state, action: PayloadAction) {
      state.RequestSamples = {};
      state.ServiceRequestStatuses = {};
      state.ServiceRequestRows = {};
      state.fieldsState = {};
      state.SamplesTableState = {
        pageSize: 10,
        pageIndex: 0,
        sortBy: [{ id: 'Id', desc: true }],
        customFilters: [],
        globalFilter: '',
      };
      state.SamplesSelected = [];
      state.RequestDetails = {};
      state.ImportState = {
        open: false,
        processing: false,
      };
      state.ServiceRequestRowStatuses = [];
      state.serviceRequestId = undefined;
      state.SidePanel = undefined;
      state.createServiceRequestRowStatus = undefined;
      state.milestoneProcessing = undefined;
      state.milestoneChargeProcessing = undefined;
      state.submitting = undefined;
    },
    refreshRequestDetails(state, action: PayloadAction<number>) {
      state.RequestDetails.status = 'pending';
    },
    refreshRequestDetails_Success(
      state,
      action: PayloadAction<IRequestDetailsModel>,
    ) {
      if (state.RequestDetails.value !== undefined) {
        state.RequestDetails.value = {
          ...state.RequestDetails.value,
          ...action.payload,
        };
      }
      state.RequestDetails.status = 'resolved';
    },

    loadFormValues(state, action: PayloadAction<number>) {},
    loadFormValues_Success(
      state,
      action: PayloadAction<Record<string, unknown>>,
    ) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      state.RequestDetails.value.FormValues = action.payload;
    },

    updateStatus(state, action: IServiceRequestUpdateStatusAction) {
      state.fieldsState.Status = { status: 'pending' };
    },
    updateStatus_Success(
      state,
      action: PayloadAction<Partial<IServiceRequestDto>>,
    ) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      merge(state.RequestDetails.value, action.payload);
      if (state.fieldsState.Status !== undefined) {
        state.fieldsState.Status.status = 'resolved';
      }
    },
    updateStatus_Error(state, action: IServiceRequestUpdateStatusAction) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      state.RequestDetails.value.Status = {
        ...state.RequestDetails.value.Status,
      };
      delete state.fieldsState.Status;
    },

    updateBudget(state, action: IServiceRequestUpdateBudgetAction) {
      state.fieldsState.Budget = { status: 'pending' };
    },
    updateBudget_Success(state, action: IServiceRequestUpdateBudgetAction) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      Object.assign(state.RequestDetails.value, action.payload);
      delete state.fieldsState.Budget;
    },
    updateBudget_Error(state, action: IServiceRequestUpdateBudgetAction) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      state.RequestDetails.value.Budget = {
        ...state.RequestDetails.value.Budget,
      };
      delete state.fieldsState.Budget;
    },
    updateGradient(state, action: IServiceRequestUpdateGradientAction) {
      state.fieldsState.Gradient = { status: 'pending' };
    },
    updateGradient_Success(state, action: IServiceRequestUpdateGradientAction) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      Object.assign(state.RequestDetails.value, action.payload);
      delete state.fieldsState.Gradient;
    },
    updateGradient_Error(state, action: IServiceRequestUpdateGradientAction) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      if (state.RequestDetails.value.Gradient !== null) {
        state.RequestDetails.value.Gradient = {
          ...state.RequestDetails.value.Gradient,
        };
      }

      delete state.fieldsState.Gradient;
    },
    updateOverheadMinutes(state, action: IServiceRequestUpdateOverheadAction) {
      state.fieldsState.OverheadMinutes = { status: 'pending' };
    },
    updateOverheadMinutes_Success(
      state,
      action: IServiceRequestUpdateOverheadAction,
    ) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      Object.assign(state.RequestDetails.value, action.payload);
      delete state.fieldsState.OverheadMinutes;
    },
    updateOverheadMinutes_Error(
      state,
      action: IServiceRequestUpdateOverheadAction,
    ) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      delete state.fieldsState.OverheadMinutes;
    },
    updatePurchaseOrder(
      state,
      action: IServiceRequestUpdatePurchaseOrderAction,
    ) {
      state.fieldsState.PurchaseOrder = { status: 'pending' };
    },
    updatePurchaseOrder_Success(
      state,
      action: IServiceRequestUpdatePurchaseOrderAction,
    ) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      Object.assign(state.RequestDetails.value, action.payload);
      delete state.fieldsState.PurchaseOrder;
    },
    updatePurchaseOrder_Error(
      state,
      action: IServiceRequestUpdatePurchaseOrderAction,
    ) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      delete state.fieldsState.PurchaseOrder;
    },
    updateFundingType(state, action: IServiceRequestUpdateFundingTypeAction) {
      state.fieldsState.FundingTypeId = { status: 'pending' };
    },
    updateFundingType_Success(
      state,
      action: IServiceRequestUpdateFundingTypeAction,
    ) {
      if (state.RequestDetails.value === undefined) {
        return;
      }

      Object.assign(state.RequestDetails.value, action.payload);
      delete state.fieldsState.FundingTypeId;
    },
    updateFundingType_Error(
      state,
      action: IServiceRequestUpdateFundingTypeAction,
    ) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      // state.RequestDetails.value.FundingTypeId = {
      //   ...state.RequestDetails.value.FundingTypeId,
      // };
      delete state.fieldsState.FundingTypeId;
    },
    updateExternalInternalNumber(
      state,
      action: IServiceRequestUpdateExternalInternalNumberAction,
    ) {
      state.fieldsState.ExternalInternalNumber = { status: 'pending' };
    },
    updateExternalInternalNumber_Success(
      state,
      action: IServiceRequestUpdateExternalInternalNumberAction,
    ) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      Object.assign(state.RequestDetails.value, action.payload);
      delete state.fieldsState.ExternalInternalNumber;
    },
    updateExternalInternalNumber_Error(
      state,
      action: IServiceRequestUpdateExternalInternalNumberAction,
    ) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      delete state.fieldsState.ExternalInternalNumber;
    },
    submitStep(state, action: PayloadAction<IServiceRequestDetailsFormModel>) {
      state.submitting = true;
    },
    submitStep_success(
      state,
      action: PayloadAction<IServiceRequestDetailsFormModel>,
    ) {
      state.submitting = false;
      if (state.RequestDetails.value !== undefined) {
        Object.assign(state.RequestDetails.value, action.payload);
      }
    },
    submitStep_Error(state, action: PayloadAction) {
      state.submitting = false;
    },
    cancelServiceRequest(state, action: IServiceRequestUpdateStatusAction) {},

    patch(state, action: IServiceRequestPatchAction) {
      // update state to processing of each field being updated
      const fields = (
        Object.keys(action.payload) as Array<keyof typeof action.payload>
      )
        .filter(f => f !== 'Id')
        .reduce((prev, current) => {
          prev[current] = { processing: true };
          return prev;
        }, {});
      Object.assign(state.fieldsState, fields);
    },
    patch_Success(state, action: IServiceRequestPatchAction) {
      if (state.RequestDetails.value === undefined) {
        return;
      }
      // release fields that were being processes
      Object.keys(action.payload).forEach(f => delete state.fieldsState[f]);
      //merge(state.RequestDetails.value, action.payload);
      Object.assign(state.RequestDetails.value, action.payload);
    },
    patch_Error(state, action: IServiceRequestPatchAction) {
      // release fields that were being processes
      Object.keys(action.payload).forEach(f => delete state.fieldsState[f]);
    },

    saveFormValues(
      state,
      action: PayloadAction<
        Pick<IFormValueDto, 'RequestId' | 'FormFieldId' | 'DisplayValue'> &
          Pick<IFormFieldDto, 'Type'> & { Value: any }
      >,
    ) {},
    loadSamples(state, action: PayloadAction<ISampleListTableState>) {
      state.RequestSamples.status = 'pending';
      state.RequestSamples.value = undefined;
      state.SamplesTableState = {
        pageSize: action.payload.pageSize,
        pageIndex: action.payload.pageIndex,
        sortBy: action.payload.sortBy,
        globalFilter: action.payload.globalFilter,
        searchColumns: action.payload.searchColumns,
        customFilters: action.payload.customFilters?.map(filter => ({
          fieldName: filter.fieldName,
          operator: filter.operator,
          value: filter.value,
        })),
      };
    },
    loadSamples_Success(state, action: PayloadAction<any>) {
      state.RequestSamples = {
        status: 'resolved',
        value: {
          data: action.payload.value as ISample[],
          dataLength: action.payload['@odata.count'],
        },
      };
    },
    loadSamples_Error(state, action: PayloadAction<string>) {
      state.RequestSamples.status = 'rejected';
      state.RequestSamples.error = action.payload;
    },

    loadServiceRequestRows(state, action: ILoadServiceRequestRowsAction) {
      state.ServiceRequestRows.status = 'pending';
    },
    loadServiceRequestRows_Success(
      state,
      action: PayloadAction<Array<IServiceRequestTableRowModel>>,
    ) {
      state.ServiceRequestRows = {
        value: action.payload,

        status: 'resolved',
      };

      Object.assign(state.RequestDetails.value, { Rows: action.payload });
    },
    loadServiceRequestRows_Error(state, error: PayloadAction) {
      state.ServiceRequestRows = { error: true };
    },

    deleteServiceRequestRows(
      state,
      action: PayloadAction<Array<IServiceRequestRowDto>>,
    ) {},
    deleteServiceRequestRows_Success(
      state,
      action: PayloadAction<Array<IServiceRequestTableRowModel>>,
    ) {
      const id = action.payload.map(item => item.Id);
      state.ServiceRequestRows.value = state.ServiceRequestRows.value?.filter(
        item => !id.includes(item.Id),
      );
    },

    createServiceRequestRow(
      state,
      action: PayloadAction<Partial<IServiceRequestTableRowModel>>,
    ) {
      //state.RequestServiceRequestRows.processing = true;
      state.createServiceRequestRowStatus = 'pending';
    },
    createServiceRequestRow_Success(
      state,
      action: PayloadAction<IServiceRequestTableRowModel[]>,
    ) {
      state.ServiceRequestRows.value?.push(...action.payload);
      state.createServiceRequestRowStatus = 'resolved';
    },
    createServiceRequestRow_Error(state, action: PayloadAction) {
      state.createServiceRequestRowStatus = 'rejected';
    },

    loadMilestones(state, action: ILoadServiceRequestMilestonesAction) {},
    loadMilestones_Success(
      state,
      action: ILoadServiceRequestMilestonesSuccessAction,
    ) {
      if (state.RequestDetails.value !== undefined) {
        state.RequestDetails.value.Milestones = action.payload;
      }
    },

    insertMilestone(
      state,
      action: PayloadAction<Partial<IServiceRequestMilestoneDto>>,
    ) {
      state.milestoneProcessing = true;
      state.lastInsertedMilestoneId = undefined;
    },
    insertMilestone_Success(
      state,
      action: PayloadAction<IServiceRequestMilestoneDto>,
    ) {
      const serviceRequest = state.RequestDetails.value;
      if (serviceRequest !== undefined) {
        serviceRequest.Milestones.push(action.payload);
        state.lastInsertedMilestoneId = action.payload.Id;
      }
      state.milestoneProcessing = false;
    },
    insertMilestone_Error(state, action: PayloadAction) {
      state.milestoneProcessing = false;
      state.lastInsertedMilestoneId = undefined;
    },
    updateMilestone(
      state,
      action: PayloadAction<Partial<Partial<IServiceRequestMilestoneDto>>>,
    ) {
      state.milestoneProcessing = true;
    },
    updateMilestone_Success(
      state,
      action: PayloadAction<
        Partial<IServiceRequestMilestoneDto> & Pick<IServiceRequestDto, 'Id'>
      >,
    ) {
      const serviceRequest = state.RequestDetails.value;
      if (serviceRequest !== undefined) {
        const milestone = serviceRequest.Milestones.find(
          f => f.Id === action.payload.Id,
        );
        if (milestone !== undefined) {
          Object.assign(milestone, action.payload);
        }
      }
      state.milestoneProcessing = false;
    },
    updateMilestone_Error(state, action: PayloadAction) {
      state.milestoneProcessing = false;
    },
    deleteMilestone(
      state,
      action: PayloadAction<IServiceRequestMilestoneDto>,
    ) {},
    deleteMilestone_Success(
      state,
      action: PayloadAction<IServiceRequestMilestoneDto>,
    ) {
      const serviceRequest = state.RequestDetails.value;
      if (serviceRequest !== undefined) {
        remove(serviceRequest.Milestones, f => f.Id === action.payload.Id);
      }
    },

    insertMilestoneCharge(
      state,
      action: InsertServiceRequestMilestoneChargeAction,
    ) {
      state.milestoneChargeProcessing = true;
    },
    insertMilestoneCharge_Success(
      state,
      action: PayloadAction<IServiceRequestMilestoneChargeDto>,
    ) {
      if (state.RequestDetails.value !== undefined) {
        const milestone = state.RequestDetails.value.Milestones.find(
          f => f.Id === action.payload.ServiceRequestMilestoneId,
        );
        if (milestone !== undefined) {
          milestone.ServiceRequestMilestoneCharges.push(action.payload);
        }
      }
      state.milestoneChargeProcessing = false;
    },
    insertMilestoneCharge_Error(state, action: PayloadAction) {
      state.milestoneChargeProcessing = false;
    },
    insertMilestoneCharges(
      state,
      action: InsertServiceRequestMilestoneChargesAction,
    ) {
      state.milestoneChargeProcessing = true;
    },
    insertMilestoneCharges_Success(
      state,
      action: PayloadAction<Array<IServiceRequestMilestoneChargeDto>>,
    ) {
      if (state.RequestDetails.value !== undefined) {
        const milestone = state.RequestDetails.value.Milestones.find(
          f => f.Id === action.payload[0].ServiceRequestMilestoneId,
        );
        if (milestone !== undefined) {
          action.payload.forEach(charge => {
            milestone.ServiceRequestMilestoneCharges.push(charge);
          });
        }
      }
      state.milestoneChargeProcessing = false;
    },
    insertMilestoneCharges_Error(state, action: PayloadAction) {
      state.milestoneChargeProcessing = false;
    },
    openSidePanel(state, action: PayloadAction<IOpenSidePanelPayload>) {},
    closeSidePanel(state, action: PayloadAction) {},
    setCover(state, action: PayloadAction<IOpenSidePanelPayload | undefined>) {
      state.SidePanel = action.payload;
    },
    updateMilestoneCharge(
      state,
      action: UpdateServiceRequestMilestoneChargeAction,
    ) {
      state.milestoneChargeProcessing = true;
    },
    updateMilestoneCharge_Success(
      state,
      action: PayloadAction<IServiceRequestMilestoneChargeDto>,
    ) {
      if (state.RequestDetails.value !== undefined) {
        const milestone = state.RequestDetails.value.Milestones.find(
          f => f.Id === action.payload.ServiceRequestMilestoneId,
        );
        if (milestone !== undefined) {
          const charge = milestone.ServiceRequestMilestoneCharges.find(
            f => f.Id === action.payload.Id,
          );
          if (charge !== undefined) {
            Object.assign(charge, action.payload);
          }
        }
      }
      state.milestoneChargeProcessing = false;
    },
    updateMilestoneCharge_Error(state, action: PayloadAction) {
      state.milestoneChargeProcessing = false;
    },
    deleteMilestoneCharge(
      state,
      action: PayloadAction<IServiceRequestMilestoneChargeDto>,
    ) {},
    deleteMilestoneCharge_Success(
      state,
      action: PayloadAction<IServiceRequestMilestoneChargeDto>,
    ) {
      if (state.RequestDetails.value !== undefined) {
        const milestone = state.RequestDetails.value.Milestones.find(
          f => f.Id === action.payload.ServiceRequestMilestoneId,
        );
        if (milestone !== undefined) {
          remove(
            milestone.ServiceRequestMilestoneCharges,
            f => f.Id === action.payload.Id,
          );
        }
      }
    },
    setSelected(state, action: PayloadAction<ISample[]>) {
      state.SamplesSelected = action.payload;
    },

    createSample(state, action: PayloadAction<ISample>) {
      state.RequestSamples = Object.assign(state.RequestSamples, {
        status: 'pending',
      });
    },
    createSample_Success(state, action: PayloadAction<ISample>) {
      state.RequestSamples.value?.data?.push(action.payload);
      state.RequestSamples.status = 'resolved';
      if (state.RequestSamples.value !== undefined) {
        state.RequestSamples.value.dataLength =
          (state.RequestSamples.value.dataLength ?? 0) + 1;
      }
    },
    createSample_Error(state, action: PayloadAction) {
      state.RequestSamples.status = 'rejected';
    },

    updateServiceRequestSampleField(
      state,
      action: PayloadAction<Partial<ISample>>,
    ) {
      // update row in samples list
      const item = state.RequestSamples?.value?.data?.find(
        s => s.Id === action.payload.Id,
      );
      if (item !== undefined) {
        Object.assign(item, action.payload);
      }
    },
    updateServiceRequestSampleField_Success(state, action: PayloadAction) {},
    updateServiceRequestSampleField_Error(state, action: PayloadAction) {},

    updateServiceRequestRowValue(
      state,
      action: UpdateServiceRequestRowValueAction,
    ) {},
    updateServiceRequestRowValue_Success(state, action: PayloadAction) {
      // TODO: update internal state
    },
    updateServiceRequestRowValue_Error(state, action: PayloadAction) {
      // TODO: ??
    },

    updateServiceRequestRowStatus(
      state,
      action: PayloadAction<{
        serviceRequestId: number;
        serviceRequestRowId: number;
        status: IServiceRequestRowStatusDto | null;
      }>,
    ) {},
    updateServiceRequestRowStatus_Success(
      state,
      action: PayloadAction<{
        serviceRequestId: number;
        serviceRequestRowId: number;
        status: IServiceRequestRowStatusDto | null;
      }>,
    ) {
      if (action.payload.status !== null) {
        const row = state.ServiceRequestRows.value?.find(
          f => f.Id === action.payload.serviceRequestRowId,
        );
        if (row !== undefined) {
          row.Status = action.payload.status;
          row.StatusId = action.payload.status?.Id;
        }
      }
    },

    toggleImport(state, action: PayloadAction<boolean>) {
      state.ImportState.open = action.payload;
    },

    import(state, action: PayloadAction<any>) {
      state.ImportState.processing = true;
    },
    import_Success(state, action: PayloadAction) {
      state.ImportState.processing = false;
      state.ImportState.open = false;
    },
    import_Error(state, action: PayloadAction) {
      state.ImportState.processing = false;
    },

    setHasFiles(state, action: PayloadAction<boolean>) {
      if (state.RequestDetails.value !== undefined) {
        state.RequestDetails.value.HasFiles = action.payload;
      }
    },

    deleteSamples(state, action: PayloadAction<ISample[]>) {},
    cloneServiceRequest(state, action: PayloadAction<number>) {},
  },
});

export const { actions: requestSamplesActions } = slice;

export const useRequestSamplesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: requestSamplesSaga });
  return { actions: slice.actions };
};
