/**
 *
 * BasicDialogContent
 *
 */
import {
  DialogContent,
  DialogContentProps as MuiDialogContentProps,
} from '@material-ui/core';
export interface BasicDialogContentProps extends MuiDialogContentProps {
  children: React.ReactNode;
}
export function BasicDialogContent(props: BasicDialogContentProps) {
  const { children } = props;
  return <DialogContent className="dialog-content">{children}</DialogContent>;
}
