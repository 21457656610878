/**
 *
 * auto generated interface for Bookit.Data.InternalServiceRequestRowStatus
 *
 */

export enum InternalServiceRequestRowStatus {
  New = 0,
  InWork = 1,
  Done = 2,
  Skip = 3,
}
export type InternalServiceRequestRowStatusUnion =
  keyof typeof InternalServiceRequestRowStatus;
export const InternalServiceRequestRowStatusUnionArray: Array<InternalServiceRequestRowStatusUnion> =
  ['New', 'InWork', 'Done', 'Skip'];
