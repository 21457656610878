import { Box } from '@material-ui/core';
import { Body } from 'app/components/Typography';
import { ActionButton } from 'app/components/BasicButtons/ActionButton';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ICanRaiseBasicTableRefreshEvent } from '../ControlledTable/ControlledTableProps';
import { SelectedRowsActionRenderer } from '../SelectedRowsActionButton/SelectedRowsActionRenderer';
import { ReactActions } from 'app/components/ReactActions';
import styled from 'styled-components';

export interface SelectedActionsProps<TRow>
  extends ICanRaiseBasicTableRefreshEvent {
  actions: SelectedRowsActionRenderer<TRow>[];
  selectedArray: TRow[];
  toggleAllRowsSelected: (value?: boolean) => void;
  totalRows: number;
  shortView?: boolean;
}

export function SelectedActions<TRow>({
  actions,
  onRefresh,
  selectedArray,
  toggleAllRowsSelected,
  totalRows,
  shortView,
  ...props
}: SelectedActionsProps<TRow>) {
  const { t } = useTranslation();
  const clearSelection = () => {
    toggleAllRowsSelected(false);
  };
  const allActions = React.useMemo(() => {
    return actions.filter(f => f !== undefined);
  }, [actions]);
  return shortView ? (
    <>
      <Box component="span" alignSelf="center">
        <Body
          bold={true}
          size="small"
        >{`${selectedArray.length} of ${totalRows} Items`}</Body>
      </Box>
      <>{allActions[0](selectedArray, onRefresh, toggleAllRowsSelected)}</>
      {allActions.length > 1 && (
        <ReactActions
          id="selectedMobileActions"
          size="small"
          icon="list"
          iconVariant="white"
          keepOpen
          items={allActions
            .filter((f, indx) => {
              return indx > 0 && f !== undefined;
            })
            .map((item, index) =>
              item(selectedArray, onRefresh, toggleAllRowsSelected),
            )}
        ></ReactActions>
      )}
      {/* <ActionButton
        onClick={clearSelection}
        title={t(translations.ClearSelection) as string}
        text={t(translations.Clear) as string}
        starticon="times"
        variant="ghost"
      /> */}
    </>
  ) : (
    <>
      <Box component="span" alignSelf="center">
        <Body
          bold={true}
          size="small"
        >{`${selectedArray.length} of ${totalRows} Items`}</Body>
      </Box>

      {allActions.map((item, index) => (
        <InvisibleEmptySpan className={'hidden-if-empty'} key={index}>
          {item(selectedArray, onRefresh, toggleAllRowsSelected)}
        </InvisibleEmptySpan>
      ))}
      <ActionButton
        onClick={clearSelection}
        title={t(translations.ClearSelection) as string}
        text={t(translations.Clear) as string}
        starticon="times"
        variant="ghost"
      />
    </>
  );
}

export const InvisibleEmptySpan = styled('span')`
  &.hidden-if-empty:empty {
    display: none;
  }
`;
