import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { InputLabel, InputValue } from 'app/components/Typography';
import { FlexColumn } from 'app/components/StyledComponents';

export function ReadonlyField(props: {
  title: string;
  value?: React.ReactNode;
}) {
  const { t } = useTranslation();
  return (
    <FlexColumn>
      <InputLabel size="xs" color="secondary">
        {props.title}
      </InputLabel>
      <InputValue size="small">{props.value ?? t(translations.NA)}</InputValue>
    </FlexColumn>
  );
}
