import { Comments } from 'app/components/Comments';
import { useUploadToNewComment } from 'app/components/LargeFilesUpload/Components/useUploadToNewComment';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { FormRow } from 'app/components/Forms/FormsLayout';
import React from 'react';
import { Entity } from 'types/common';
import { ServiceGroupPicker } from 'app/components/pickers/ServiceGroupPicker';
import { IServiceGroupDto } from 'api/odata/generated/entities/IServiceGroupDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { IComment } from 'app/components/CommentsContainer/IComment';
export interface UserCommentsProps {
  userName: string;
  isAdmin: boolean;
  serviceGroupId?: number;
  checkPermissions?: (comment: IComment) => boolean;
  checkEditPermissions?: (comment: IComment) => boolean;
}
export const UserComments = ({
  userName,
  isAdmin,
  checkPermissions,
  checkEditPermissions,
}: UserCommentsProps) => {
  const { t } = useTranslation();
  const [core, setCore] = React.useState<Entity<number> | null | undefined>();
  const ServiceGroupAdminPickerChange = React.useCallback(
    (value: Entity<number> | null | undefined) => {
      setCore(value);
    },
    [],
  );
  const { onCommentAdded } = useUploadToNewComment();
  return (
    <React.Fragment>
      <Comments
        commentType={CommentReferenceTypes.UserServiceGroup}
        referenceId={core?.Id ?? 0}
        referenseStringId={userName}
        isAdmin={isAdmin}
        defaultComment={{ IsInternal: true }}
        deletable={true}
        noTitle
        cannotComment={
          core === null || core === undefined || core.Id === 0 || !isAdmin
        }
        onCommentAdded={comment => {
          onCommentAdded(comment);
          setCore(null);
        }}
        checkEditPermissions={checkEditPermissions}
        checkPermissions={checkPermissions}
        additionalActions={comments => (
          <FormRow key={`user_row-core-comment`}>
            <ServiceGroupPicker
              urlType="admin"
              label={t(translations.ServiceGroup)}
              info={t(translations.OneCommentForLaboratory)}
              id="ServiceGroupAdminId"
              fullWidth
              placeholder={t(translations.ServiceGroup)}
              value={core as IServiceGroupDto}
              variant="filled"
              predicates={
                comments.length > 0
                  ? [
                      `${new Condition<IServiceGroupDto>(
                        'Id',
                        ODataOperators.Excludes,
                        comments.map(f => f.ReferenceId ?? 0),
                      ).toString()}`,
                    ]
                  : undefined
              }
              onChange={ServiceGroupAdminPickerChange}
            />
          </FormRow>
        )}
      />
    </React.Fragment>
  );
};
