import { translations } from 'locales/translations';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { ODataOperators } from 'api/odata/ODataFilter';
import {
  GetNumberOrUndefined,
  GetPartialEntity,
  GetRawValue,
  GetStringOrUndefined,
  initPickerValue,
  URLSearchParamsCI,
} from 'app/components/BasicTable/types/FilterParam';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import UserFilter from 'app/components/BasicTable/Filters/UserFilter';
import { initUserData } from 'app/components/pickers/AutocompletePickers/UserPicker';
import ServiceFilter from 'app/components/BasicTable/Filters/ServiceFilter';
import { IReservationWaitingListDto } from './IReservationWaitingListDto';
import { initOnlineServiceData } from 'app/components/pickers/AutocompletePickers/OnlineServicePicker';

export const GetFilters: GetPageFilters<IReservationWaitingListDto> =
  (t, appSettings, user, settings) => search => {
    const params = new URLSearchParamsCI(search);
    var result: IFilterSettings<IReservationWaitingListDto>[] = [
      {
        fieldName: 'EquipmentId',
        displayNameKey: translations.EquipmentName,
        queryStringSerializer: 'eqid',
        operator: ODataOperators.Equals,
        component: ServiceFilter,
        value: GetPartialEntity(
          GetNumberOrUndefined(GetRawValue(params, 'eqid')),
        ),
        initData: initPickerValue(initOnlineServiceData),
        urlType: 'admin',
        urlKey: 'eqid',
      },
      {
        id: 'UserName',
        fieldName: 'UserName',
        displayNameKey: translations.UserName,
        operator: ODataOperators.Equals,
        queryStringSerializer: 'userName',
        value: GetPartialEntity(
          GetStringOrUndefined(GetRawValue(params, 'userName')),
        ),
        component: UserFilter,
        initData: initPickerValue(initUserData),
      },
    ];
    return result;
  };
