import styled from 'styled-components';

export const AssistedRoot = styled.div`
  &.assisted-root {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
    & .assisted-list {
      padding: 0;
      margin: 0;
      & .assisted-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 8px;
      }
    }
  }
`;
export const StatusText = styled.span`
  .status-success {
    color: var(--primary-regular-c8);
  }
  .status-warning {
    color: var(--semantic-alert-regular-c8);
  }
  .status-danger {
    color: var(--semantic-danger-regular-c8);
  }
`;
export const TrainingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  align-items: flex-start;
  gap: 8px;
  &.short-view: {
    flex-direction: column;
    align-items: stretch;
  }
`;
export const ApprovalRequiredLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
export const RestrictWrapper = styled.div`
  height: 100%;
  padding: 8px 0px;
`;
export const PermissionsWrapper = styled.div`
  &.perm-root {
    display: flex;
    flex-direction: column;
    gap: 8px;
    & .perm-list {
      padding: 0;
      margin: 0;
      & .perm-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 8px;
        & .text-over {
          display: inline-block;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 29ch;
        }
      }
    }
  }
`;
