/**
 *
 * EditArticleDetailsForm
 *
 */
import * as React from 'react';
import { translations } from 'locales/translations';
import * as Yup from 'yup';
import {
  selectArticleDetails,
  selectArticleDetailsBusy,
  selectArticleDetailsForm,
  selectEditArticleAssociationBusy,
} from '../slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { Formik } from 'formik';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormAssetsPicker } from 'app/components/Forms/FormAssetsPicker';
import { useArticlesSlice } from '../slice';
import { IArticleDto } from 'api/odata/generated/entities/IArticleDto';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { Button } from 'app/components/BasicButtons/Button';
import { useTranslation } from 'react-i18next';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { InputLabel } from 'app/components/Typography';
import { ValueLabel } from 'app/components/ValueLabel';
import { Entity } from 'types/common';
import { FormUserPicker } from 'app/components/Forms/FormUserPicker';
import { FormAssetPicker } from 'app/components/Forms/FormAssetPicker';
import { FormListener } from 'app/components/Forms/FormRender/FormRenderer';
import {
  assetSchema,
  assetsSchema,
  GetCalibrationRecordFilter,
  GetInstrumentFilter,
  IArticleNewEditForm,
} from '../utils';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { ArticleStatus } from 'enums/Article';
import { EntityNumberSchema } from 'app/components/Forms/Schemas';
import { FormCustomPicker } from 'app/components/Forms/FormCustomPicker';
import { useAllowedToChangeStatus } from '../Hooks/useAllowedToChangeStatus';
import { ArticleComments } from '../ArticleComments';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { ArticleFormPicker } from '../ArticleFormPickers';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';
import useSidePanelState, {
  SidePanelCloseState,
} from 'app/hooks/useSidePanelOpen';

export interface EditArticleDetailsFormProps
  extends SidePanelContentProps,
    CoverProps {
  value: IArticleDto;
  onClose?: () => void;
  Journal?: string | null;
  PageNumber?: number | null;
}
export function EditArticleDetailsForm(props: EditArticleDetailsFormProps) {
  const { t } = useTranslation();
  const editProcessing = useSelector(selectEditArticleAssociationBusy);
  const detailsLoading = useSelector(selectArticleDetailsBusy);
  const articleDetailsForm = useSelector(selectArticleDetailsForm);
  const articleDetails = useSelector(selectArticleDetails);
  const user = useSelector(selectAuthenticatedUser);
  const { actions: layoutActions } = useLayoutSlice();
  const [canEditStatus, setCanEditStatus] = React.useState<boolean>(false);
  const allowedToChangeStatus = useAllowedToChangeStatus();
  const submitCommentRef = React.useRef<any>(null);
  const dispatch = useDispatch();
  const { actions } = useArticlesSlice();
  const { cover, closePanel, coverClosed, onCloseCover } = useSidePanelState(
    undefined,
    undefined,
    props.isCover,
    props.useSidePanel,
    undefined,
  );

  const handleCloseClick = React.useCallback(() => {
    closePanel({
      isCover: props.isCover,
      useSidePanel: props.useSidePanel,
      showConfirm: false,
      onClose: () => {
        dispatch(layoutActions.setNotSavedChanges(false));
        dispatch(actions.resetArticleAssociationState());
      },
    } as SidePanelCloseState);
  }, [
    actions,
    closePanel,
    dispatch,
    layoutActions,
    props.isCover,
    props.useSidePanel,
  ]);

  React.useEffect(() => {
    if (editProcessing === false) {
      handleCloseClick();
    }
    return () => undefined;
  }, [editProcessing, handleCloseClick]);

  const schema: Yup.SchemaOf<IArticleNewEditForm> = Yup.object().shape(
    {
      Title: Yup.string().required().label(t(translations.Title)).trim(),
      Authors: Yup.string().required().label(t(translations.Authors)).trim(),
      Abstract: Yup.string().required().label(t(translations.Abstract)).trim(),
      Doi: Yup.string()
        .notRequired()
        .nullable()
        .label(t(translations.Doi))
        .trim(),
      Dataset: Yup.string()
        .notRequired()
        .nullable()
        .label(t(translations.ArticleDataset))
        .trim(),
      ISSN: Yup.string()
        .notRequired()
        .nullable()
        .label(t(translations.ISSN))
        .trim(),
      ORCIDs: Yup.string()
        //.required()
        .nullable()
        .label(t(translations.ORCID))
        .trim(),
      Year: Yup.number().required().label(t(translations.Year)).min(1),
      CalibrationRecord: Yup.mixed().nullable().notRequired(),
      RelatedUser: Yup.mixed().nullable().notRequired(),
      /*ServiceGroupAssets: assetsSchema.when(['InstrumentAssets'], {
        is: v => (v === undefined ? true : v.length === 0),
        then: assetsSchema
          .required()
          .label(t(translations.ServiceGroup))
          .min(1)
        otherwise: assetsSchema.notRequired(),
      }),*/
      ServiceGroupAsset: assetSchema.when(['InstrumentAssets'], {
        is: v => (v === undefined ? true : v.length === 0),
        then: assetSchema
          .required()
          .label(t(translations.ServiceGroup))
          .nullable(),
        otherwise: assetSchema.nullable().notRequired(),
      }),
      /*InstrumentAssets: assetsSchema.when(['ServiceGroupAssets'], {
        is: v => (v === undefined ? true : v.length === 0),
        then: assetsSchema.required().label(t(translations.Instruments)).min(1),
        otherwise: assetsSchema.notRequired(),
      }),*/
      InstrumentAssets: assetsSchema.when(['ServiceGroupAsset'], {
        is: v => v === null,
        then: assetsSchema.required().label(t(translations.Instruments)).min(1),
        otherwise: assetsSchema.notRequired(),
      }),
      Status: EntityNumberSchema.required().label(
        t(translations.ArticleStatus),
      ),
      Journal: Yup.string()
        .notRequired()
        .nullable()
        .label(t(translations.Journal))
        .trim(),
      PageNumber: Yup.number().nullable().default(null),
    },
    [['ServiceGroupAsset', 'InstrumentAssets']],
  );
  const GetServiceGroupAsset = React.useCallback(asset => {
    /*if (asset !== undefined && asset !== null) {
        return asset.ServiceGroupId !== null &&
          asset.ServiceGroupId !== undefined &&
          (authenticatedUser?.HasAdminGroupOnlyPermissions(
            asset.ServiceGroupId,
          ) ||
            authenticatedUser?.HasLabTechGroupPermissions(asset.ServiceGroupId))
          ? asset
          : null;
      } else {
        return null;
      }*/
    return asset;
  }, []);
  const initialValues: IArticleNewEditForm = React.useMemo(
    () => ({
      Title:
        articleDetailsForm.Title === null ||
        articleDetailsForm.Title === undefined
          ? ''
          : articleDetailsForm.Title,
      Authors:
        articleDetailsForm.Authors === null ||
        articleDetailsForm.Authors === undefined
          ? ''
          : articleDetailsForm.Authors.join(','),
      Abstract:
        articleDetailsForm.Abstract === null ||
        articleDetailsForm.Abstract === undefined
          ? ''
          : articleDetailsForm?.Abstract?.trim(),
      Doi: articleDetailsForm.Doi,
      Dataset: articleDetailsForm.Dataset,
      ISSN: articleDetailsForm.ISSN,
      ORCIDs:
        articleDetailsForm.ORCIDs === null
          ? null
          : articleDetailsForm.ORCIDs.join(','),
      Year: articleDetailsForm.Year ?? 0,
      Journal: articleDetailsForm.Journal,
      PageNumber: articleDetailsForm.PageNumber,
      RelatedUser: {
        Id: articleDetailsForm.RelatedUser,
        Name: articleDetailsForm.RelatedUserDisplayName,
      } as Entity<string>,
      CalibrationRecord: {
        Id: articleDetailsForm.CalibrationRecord,
        Name: !!articleDetailsForm.CalibrationRecord
          ? '#' +
              articleDetailsForm.CalibrationRecord?.toString() +
              ': ' +
              articleDetailsForm.CalibrationRecordName ?? ''
          : '',
      } as Entity<number>,
      InstrumentAssets:
        articleDetailsForm !== undefined
          ? articleDetailsForm?.InstrumentAssets
          : /*? articleDetailsForm?.InstrumentAssets.filter(
              asset =>
                asset.ServiceId !== null &&
                asset.ServiceId !== undefined &&
                (authenticatedUser?.HasAdminServicePermissions(
                  asset.ServiceTypeId ?? 1,
                  asset.ServiceId,
                ) ||
                  authenticatedUser?.HasLabTechServicePermissions(
                    asset.ServiceTypeId ?? 1,
                    asset.ServiceId,
                  ) ||
                  authenticatedUser?.HasAdminGroupPermissions(
                    asset.ServiceGroupId ?? undefined,
                  ) ||
                  authenticatedUser?.HasLabTechGroupPermissions(
                    asset.ServiceGroupId ?? undefined,
                  )),
            )*/
            [],
      /*ServiceGroupAssets:
        articleDetailsForm !== undefined
          ? articleDetailsForm?.ServiceGroupAssets.filter(
              asset =>
                asset.ServiceGroupId !== null &&
                asset.ServiceGroupId !== undefined &&
                (authenticatedUser?.HasAdminGroupPermissions(
                  asset.ServiceGroupId,
                ) ||
                  authenticatedUser?.HasLabTechGroupPermissions(
                    asset.ServiceGroupId,
                  )),
            )
          : [],*/
      ServiceGroupAsset:
        articleDetailsForm !== undefined
          ? GetServiceGroupAsset(articleDetailsForm?.ServiceGroupAssets[0])
          : null,
      Status:
        articleDetailsForm !== undefined &&
        articleDetailsForm.Status !== undefined
          ? {
              Id: articleDetailsForm.Status,
              Name: t('Article' + ArticleStatus[articleDetailsForm.Status]),
            }
          : { Id: 0, Name: t('Article' + ArticleStatus[0]) },
    }),
    [GetServiceGroupAsset, articleDetailsForm, t],
  );
  const [oldServiceGroups, setOldServiceGroups] = React.useState<IAssetDto[]>(
    //initialValues.ServiceGroupAssets,
    initialValues.ServiceGroupAsset === null
      ? []
      : [initialValues.ServiceGroupAsset],
  );
  const [serviceGroups, setServiceGroups] = React.useState<IAssetDto[]>(
    //initialValues.ServiceGroupAssets,
    initialValues.ServiceGroupAsset === null
      ? []
      : [initialValues.ServiceGroupAsset],
  );
  const [instruments, setInstruments] = React.useState<IAssetDto[]>(
    initialValues.InstrumentAssets,
  );
  const instrumentFilter = React.useCallback(() => {
    return GetInstrumentFilter(serviceGroups);
  }, [serviceGroups]);
  const CalibrationRecordFilter = React.useCallback(() => {
    return GetCalibrationRecordFilter(instruments);
  }, [instruments]);
  const handleFormChange = React.useCallback(
    (
      values: IArticleNewEditForm,
      isValid,
      dirty,
      setValue,
      setTouched,
      validateField,
      setError,
      validate,
    ) => {
      if (
        serviceGroups.length !== oldServiceGroups.length ||
        serviceGroups[0] !== oldServiceGroups[0]
      ) {
        setOldServiceGroups(serviceGroups);
        setValue('InstrumentAssets', []);
        return;
      }
      if (
        values.ServiceGroupAsset === null &&
        values.InstrumentAssets.length > 0
      ) {
        setOldServiceGroups(serviceGroups);
        setValue('ServiceGroupAsset', {
          Id: values.InstrumentAssets[0].ServiceGroupAssetId,
          Name: values.InstrumentAssets[0].ServiceGroupName,
          ServiceGroupId: values.InstrumentAssets[0].ServiceGroupId,
          ServiceGroupName: values.InstrumentAssets[0].ServiceGroupName,
        });
      }
      setCanEditStatus(allowedToChangeStatus(serviceGroups[0]));
    },
    [allowedToChangeStatus, oldServiceGroups, serviceGroups],
  );

  const handleSubmit = React.useCallback(
    (values: IArticleNewEditForm) => {
      if (
        //values.ServiceGroupAssets.length === 0 &&
        (values.ServiceGroupAsset === null &&
          values.InstrumentAssets.length === 0) ||
        values.Status === null
      ) {
        return;
      }
      let payload: IArticleDto = {
        ...props.value,
        Title: values.Title.trim(),
        Authors: values.Authors.trim().split(','),
        Abstract: values.Abstract.trim(),
        Doi:
          values.Doi !== undefined && values.Doi !== null
            ? values.Doi.trim()
            : null,
        Dataset:
          values.Dataset !== undefined && values.Dataset !== null
            ? values.Dataset.trim()
            : null,
        ISSN:
          values.ISSN !== undefined && values.ISSN !== null
            ? values.ISSN.trim()
            : null,
        ORCIDs:
          values.ORCIDs !== undefined &&
          values.ORCIDs !== null &&
          values.ORCIDs.trim() !== ''
            ? values.ORCIDs.trim().split(',')
            : [],
        Year: values.Year,
        Status: values.Status.Id,
        Journal: values.Journal !== undefined ? values.Journal : null,
        PageNumber: values.PageNumber !== undefined ? values.PageNumber : null,
        RelatedUser:
          values.RelatedUser !== null ? values.RelatedUser?.Id : null,
        CalibrationRecord:
          values.CalibrationRecord !== null
            ? values.CalibrationRecord?.Id
            : null,
        ExternalId: articleDetailsForm?.ExternalId ?? null,
        ExternalIdSource: 'Manual Entry',
        ...{
          //Assets: values.ServiceGroupAssets.concat(values.InstrumentAssets),
          Assets:
            values.ServiceGroupAsset !== null
              ? values.InstrumentAssets.concat([values.ServiceGroupAsset])
              : values.InstrumentAssets,
        },
      };
      delete (payload as Partial<IArticleDto>).EnteredAt;
      dispatch(actions.saveArticleAssociation(payload));
    },
    [props.value, articleDetailsForm?.ExternalId, dispatch, actions],
  );

  const statusPickerData: Entity<number>[] = React.useMemo(() => {
    const data = [
      {
        Id: ArticleStatus.AwaitingApproval,
        Name: t('Article' + ArticleStatus[ArticleStatus.AwaitingApproval]),
      },
      {
        Id: ArticleStatus.Approved,
        Name: t('Article' + ArticleStatus[ArticleStatus.Approved]),
      },
    ] as Entity<number>[];
    return data;
  }, [t]);

  return (
    <PageWrapper
      useSidePanel={true}
      closable={true}
      disableExpandToggle={true}
      pageName={t(translations.EditArticle)}
      titlePage={t(translations.EditArticle)}
      titleTooltip={t(translations.EditArticle)}
      closeSidePanel={handleCloseClick}
      topProcessing={editProcessing}
      cover={cover}
      closeCover={!props.isCover ? onCloseCover : props.closeCover}
      isCover={props.isCover}
      coverClosed={coverClosed}
      loading={detailsLoading}
    >
      <div>
        <>
          {!detailsLoading && (
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={schema}
              validateOnMount={true}
              validateOnBlur={false}
            >
              {formik => (
                <StyledForm onSubmit={formik.handleSubmit}>
                  <FormLeftSection>
                    <FormFieldsSection>
                      <FormListener
                        onFormChange={handleFormChange}
                        fields={['ServiceGroupAsset']}
                      />
                      <FormRow>
                        <FormTextField
                          name="Title"
                          id="TitleId"
                          //variant={'filled'}
                          label={t(translations.Title)}
                          multiline={true}
                          //size={'small'}
                          disabled={editProcessing}
                          fullWidth
                        />
                      </FormRow>
                      <FormRow>
                        <FormTextField
                          name="Authors"
                          id="AuthorsId"
                          label={t(translations.Authors)}
                          multiline={true}
                          //size={'small'}
                          disabled={editProcessing}
                          fullWidth
                        />
                      </FormRow>
                      <FormRow>
                        <FormTextField
                          name="Abstract"
                          id="AbstractId"
                          label={t(translations.Abstract)}
                          multiline={true}
                          //size={'small'}
                          disabled={editProcessing}
                          fullWidth
                        />
                      </FormRow>
                      <FormRow>
                        <InputLabel size="xs">
                          {t(translations.Source)}
                        </InputLabel>
                        <ValueLabel value="Manual Entry" />
                      </FormRow>
                      <FormRow>
                        <FormTextField
                          name="Doi"
                          id="DoiId"
                          label={t(translations.Doi)}
                          disabled={editProcessing}
                          fullWidth
                        />
                      </FormRow>
                      <FormRow>
                        <FormTextField
                          name="Dataset"
                          id="DatasetId"
                          label={t(translations.ArticleDataset)}
                          disabled={editProcessing}
                          fullWidth
                        />
                      </FormRow>
                      <FormRow>
                        <FormTextField
                          name="ISSN"
                          id="ISSNId"
                          label={t(translations.ISSN)}
                          disabled={editProcessing}
                          fullWidth
                        />
                      </FormRow>
                      <FormRow>
                        <FormTextField
                          name="ORCIDs"
                          id="ORCIDsId"
                          label={t(translations.ORCID)}
                          disabled={editProcessing}
                          fullWidth
                        />
                      </FormRow>
                      <FormRow>
                        <FormTextField
                          name="Year"
                          id="YearId"
                          label={t(translations.Year)}
                          type="number"
                          disabled={editProcessing}
                          fullWidth
                        />
                      </FormRow>
                      <FormRow>
                        <FormTextField
                          name="Journal"
                          id="JournalId"
                          label={t(translations.Journal)}
                          disabled={editProcessing}
                          fullWidth
                        />
                      </FormRow>
                      <FormRow>
                        <FormTextField
                          name="PageNumber"
                          id="PageNumberId"
                          label={t(translations.ArticlePageNumber)}
                          type="number"
                          disabled={editProcessing}
                          fullWidth
                        />
                      </FormRow>
                      <FormRow>
                        <FormCustomPicker
                          name="Status"
                          label={t(translations.ArticleStatus)}
                          placeholder={t(translations.ArticleStatus)}
                          disabled={formik.isSubmitting || !canEditStatus}
                          fullWidth
                          data={statusPickerData}
                        />
                      </FormRow>
                      {!!articleDetails && (
                        <FormFieldsSection>
                          <ArticleComments
                            commentsFormRef={submitCommentRef}
                            isEdit={true}
                            user={user}
                            article={articleDetails}
                            disabled={false}
                          />
                        </FormFieldsSection>
                      )}

                      <FormRow>
                        <ArticleFormPicker
                          name="CalibrationRecord"
                          label={
                            instruments.length > 0
                              ? t(translations.CalibrationRecord)
                              : t(
                                  translations.ArticlePleaseSelectEquipmentFirst,
                                )
                          }
                          placeholder={
                            instruments.length > 0
                              ? t(translations.PleaseSelectWorkOrder)
                              : t(
                                  translations.ArticlePleaseSelectEquipmentFirst,
                                )
                          }
                          disabled={
                            formik.isSubmitting ||
                            !canEditStatus ||
                            instruments.length === 0
                          }
                          filter={CalibrationRecordFilter()}
                          info={t(translations.CalibrationRecord_info)}
                          fullWidth
                        />
                      </FormRow>
                      <FormRow>
                        <FormUserPicker
                          name="RelatedUser"
                          label={t(translations.RelatedUser)}
                          placeholder={t(translations.PleaseSelectAUser)}
                          disabled={formik.isSubmitting || !canEditStatus}
                          fullWidth
                        />
                      </FormRow>
                      <FormRow>
                        <FormAssetPicker
                          admin={false}
                          showOnlyActiveAssets={true}
                          name="ServiceGroupAsset"
                          restrictByAvType="serviceGroup"
                          label={t(translations.ServiceGroup)}
                          placeholder={t(translations.PleaseSelectAsset)}
                          disabled={editProcessing}
                          fullWidth
                          onChange={(value: any) => {
                            setOldServiceGroups(serviceGroups);
                            setServiceGroups([value]);
                          }}
                        />
                      </FormRow>
                      <FormRow>
                        <FormAssetsPicker
                          admin={false}
                          predicates={instrumentFilter()}
                          showOnlyActiveAssets={true}
                          name="InstrumentAssets"
                          restrictByAvType="bookableAndnotbookable"
                          label={t(translations.Instruments)}
                          placeholder={t(translations.PleaseSelectAsset)}
                          disabled={editProcessing}
                          fullWidth
                          withoutServiceGroups={true}
                          onChange={(value: any) => {
                            setInstruments(value);
                          }}
                        />
                      </FormRow>
                      <FormRow>
                        <Button processing={editProcessing} type="submit">
                          {t(translations.Submit)}
                        </Button>
                      </FormRow>
                    </FormFieldsSection>
                  </FormLeftSection>
                </StyledForm>
              )}
            </Formik>
          )}
        </>
      </div>
    </PageWrapper>
  );
}
