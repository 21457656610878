import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.subscribersDetail || initialState;

export const selectSubscribersDetails = createSelector(
  [selectSlice],
  state => state,
);
//====================================================================
export const selectCreateSubscribersDetails = createSelector(
  [selectSubscribersDetails],
  state => state.createState.data,
);

export const selectCreateSubscribersDetailsCompleted = createSelector(
  [selectSubscribersDetails],
  state => state.createState.completed,
);

export const selectCreateSubscribersDetailsHasError = createSelector(
  [selectSubscribersDetails],
  state => state.createState.hasErrors,
);
//====================================================================
export const selectUpdateSubscribersDetails = createSelector(
  [selectSubscribersDetails],
  state => state.updateState.data,
);

export const selectUpdateSubscribersDetailsCompleted = createSelector(
  [selectSubscribersDetails],
  state => state.updateState.completed,
);
export const selectUpdateSubscribersDetailsHasError = createSelector(
  [selectSubscribersDetails],
  state => state.updateState.hasErrors,
);
//====================================================================
export const selectSubscribersDetailsProcessing = createSelector(
  [selectSubscribersDetails],
  state => state.processing,
);
