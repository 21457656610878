import { httpClient } from 'api/HttpClient';
import { PeriodTypes } from 'app/components/DatePeriods';

export interface UpdateModel {
  Async: boolean;
  Recalculate: boolean;
  Item: {
    ServiceGroupsCsv: string;
    UserGroupsCsv?: string;
    PeriodType?: PeriodTypes;
    Start: Date | string;
    End: Date | string;
    Remarks: string;
    BudgetIds: number[];
    BudgetIncluded?: boolean;
    invoiceBy?: number;
    CoreInvoiceBy: string;
    InvoiceCreatedBy: string;
    InvoiceVat: string;
    FundingTypeId?: number;
    CoreBy: number;
    ShowZeroAmountInvoices?: boolean;
    ShowInvoicePartialEvents: boolean;
  };
}
export class BillingPreviewApi {
  FormView1_UpdateItem = (item: UpdateModel) => {
    return httpClient.post(`/api/odata/v4/BillingPreview`, item);
  };
}
