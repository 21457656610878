import {
  GroupedTab,
  GroupedTabContent,
  TabContent,
  TabType,
  isGroupedTab,
} from './TabContent';
import * as React from 'react';
import { Collapse, Tabs, useTheme } from '@material-ui/core';
import { TabPanel, TabPanelProps } from './TabPanel';
import { BasicTab } from './BasicTab';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import { Divider, SubTabsWrapper, TabsWrapper } from './TabsWrapper';
import { Icon } from '../BasicIcons/FontAwesome';
import { isNullOrUndefined } from 'utils/typeUtils';
import { FlexRow } from '../StyledComponents';
import styled from 'styled-components';

export interface BasicTabsProps {
  tabs: Array<GroupedTabContent>;
  selectedTabIndex?: number;
  tabsId: string;
  tabsPanelId: string;
  textColor?: 'secondary' | 'primary' | 'inherit';
  scrollButtons?: 'auto' | 'desktop' | 'on' | 'off';
  indicatorColor?: 'secondary' | 'primary';
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  selectionFollowsFocus?: boolean;
  centered?: boolean;
  orientation?: 'horizontal' | 'vertical';
  ariaLabel?: string;
  size?: 'small' | 'medium';
  scrollableContent?: boolean;
  className?: string;
  handleTabChange?: (tab: TabContent) => void;
  tabPanels?: Array<React.ReactElement<TabPanelProps>>;
  selectedTabId?: TabType | null;
  withGroups?: boolean;
}

export function BasicTabs(props: BasicTabsProps) {
  const {
    selectedTabIndex,
    tabs,
    tabsId,
    tabsPanelId,
    textColor,
    scrollButtons,
    indicatorColor,
    variant,
    selectionFollowsFocus,
    centered,
    orientation,
    ariaLabel,
    size = 'medium',
    className,
    scrollableContent,
    handleTabChange,
    tabPanels,
    selectedTabId,
    withGroups,
  } = props;
  const theme = useTheme();
  const [tabIndex, setTabIndex] = React.useState<number>(selectedTabIndex ?? 0);
  // used for case when state is managed otside this component
  React.useEffect(() => {
    if (!!handleTabChange && selectedTabIndex !== undefined) {
      setTabIndex(selectedTabIndex ?? 0);
    }
  }, [handleTabChange, selectedTabIndex]);

  const realTabs = React.useMemo(() => {
    return tabs.flatMap(t => (isGroupedTab(t) ? t.tabs : [t]));
  }, [tabs]);
  React.useEffect(() => {
    if (isNullOrUndefined(selectedTabId)) return;
    setTabIndex(realTabs.findIndex(t => t.Id === selectedTabId));
  }, [realTabs, selectedTabId]);

  const handleChange = React.useCallback(
    (newValue: number) => {
      if (!!handleTabChange) {
        handleTabChange(realTabs[newValue]);
      } else {
        setTabIndex(newValue);
      }
    },
    [handleTabChange, realTabs],
  );
  const handleSelectedTabIndexChange = (
    event: React.ChangeEvent<{}>,
    newValue: number,
  ) => {
    //when state is managed outside the index is come from the parrent component
    handleChange(newValue);
  };

  const getTabValue = React.useCallback(
    (tab: TabContent) => realTabs.findIndex(t => t.Id === tab.Id),
    [realTabs],
  );
  const getTabProps = React.useCallback(
    (item: TabContent) => {
      return {
        id: `${tabsId}-${item.Id}`,
        'aria-controls': `${tabsPanelId}-${item.Id}`,
        label: item.Name,
      };
    },
    [tabsId, tabsPanelId],
  );
  return (
    <TabsWrapper
      theme={theme}
      className={clsx('tabsWrapper', {
        tabsVertical: orientation === 'vertical',
        tabsHorizontal: orientation !== 'vertical',
        scrollContent: scrollableContent === true,
      })}
    >
      <Tabs
        className={clsx(className, 'basicTabs', `size${capitalize(size)}`, {
          tabsVertical: orientation === 'vertical',
        })}
        indicatorColor={indicatorColor || 'primary'}
        textColor={textColor || 'inherit'}
        orientation={orientation || 'horizontal'}
        value={withGroups ? false : tabIndex}
        onChange={handleSelectedTabIndexChange}
        variant={variant || 'fullWidth'}
        selectionFollowsFocus={selectionFollowsFocus || true}
        centered={centered}
        scrollButtons={scrollButtons}
        aria-label={ariaLabel || 'basic tabs'}
        TabIndicatorProps={{
          className: clsx('tabIndicator', {
            tabIndicatorVertical: orientation === 'vertical',
            tabIndicatorHorizontal: orientation !== 'vertical',
          }),
        }}
      >
        {tabs.map(item =>
          isGroupedTab(item) ? (
            <GroupedTabComponent
              key={`groupedTab_${item.groupId}`}
              {...item}
              size={size}
              tabsPanelId={tabsPanelId}
              tabsId={tabsId}
              getTabValue={getTabValue}
              setValue={handleChange}
              selectedValue={tabIndex}
              open={item.tabs.some(t => getTabValue(t) === tabIndex)}
            />
          ) : (
            <BasicTab
              className={clsx({
                basicTabSelected: !withGroups && getTabValue(item) === tabIndex,
                groupedHeader: withGroups,
                selected: getTabValue(item) === tabIndex,
              })}
              {...getTabProps(item)}
              startIcon={item.startIcon}
              endIcon={item.endIcon}
              mainIcon={item.icon}
              size={size}
              key={`tab_${item.Id}`}
              value={getTabValue(item)}
              //fullWidth={false}
              //wrapped={false}
            />
          ),
        )}
      </Tabs>
      {!!tabPanels && tabPanels.length > 0 ? (
        <React.Fragment>{tabPanels}</React.Fragment>
      ) : (
        <React.Fragment>
          {realTabs.map((item, index) => (
            <TabPanel
              value={tabIndex}
              index={index}
              tabsId={tabsId}
              tabsPanelId={tabsPanelId}
              scrollableContent={scrollableContent}
              key={`tabpanel_${item.Id}`}
            >
              {item.Content}
            </TabPanel>
          ))}
        </React.Fragment>
      )}
    </TabsWrapper>
  );
}

const GroupedTabComponent = ({
  setValue,
  getTabValue,
  ...props
}: GroupedTab & {
  size: 'small' | 'medium';
  tabsPanelId: string;
  tabsId: string;
  open?: boolean;
  getTabValue: (tab: TabContent) => number;
  setValue: (value: number) => void;
  selectedValue: number;
}) => {
  const [open, setOpen] = React.useState(!!props.open);
  const getTabProps = React.useCallback(
    (item: TabContent) => {
      return {
        id: `${props.tabsId}-${item.Id}`,
        'aria-controls': `${props.tabsPanelId}-${item.Id}`,
        label: item.Name,
      };
    },
    [props.tabsId, props.tabsPanelId],
  );
  const selected = props.tabs.some(i => getTabValue(i) === props.selectedValue);
  const onClick = React.useCallback(() => {
    setOpen(prev => {
      if (!prev && !selected && !!props.tabs[0]) {
        setValue(getTabValue(props.tabs[0]));
      }
      return !prev;
    });
  }, [getTabValue, props.tabs, selected, setValue]);
  return (
    <>
      <GroupTabHeader onClick={onClick}>
        <BasicTab
          label={props.groupName}
          className={clsx('groupedHeader', { selected })}
        />
        <Icon icon={open ? 'chevron-up' : 'chevron-down'} />
      </GroupTabHeader>
      <Collapse in={open}>
        <SubTabsWrapper>
          <Divider />
          <div className="subTabs">
            {props.tabs.map(item => (
              <BasicTab
                className={clsx('subTab', {
                  basicTabSelected: getTabValue(item) === props.selectedValue,
                })}
                {...getTabProps(item)}
                startIcon={item.startIcon}
                endIcon={item.endIcon}
                mainIcon={item.icon}
                size={props.size}
                key={`tab_${item.Id}`}
                value={getTabValue(item)}
                onClick={() => setValue(getTabValue(item))}
              />
            ))}
          </div>
        </SubTabsWrapper>
      </Collapse>
    </>
  );
};

const GroupTabHeader = styled(FlexRow)(() => ({
  width: '100%',
  justifyContent: 'space-between',
}));
