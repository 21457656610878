import { PayloadAction } from '@reduxjs/toolkit';
import { IOfflineServiceTypeRefsDto } from 'api/odata/generated/entities/IOfflineServiceTypeRefsDto';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { offlineTypeRefsSaga } from './saga';
import {
  IOfflineTypeRefsModel,
  OfflineTypeRefsQueryParams,
  OfflineTypeRefsState,
} from './types';

export const initialState: OfflineTypeRefsState = {
  processing: undefined,
  isEdit: false,
  filters: undefined,
  detailsState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
    error: undefined,
  },
};

const slice = createSlice({
  name: 'offlineTypeRefs',
  initialState,
  reducers: {
    initDetails(
      state,
      action: PayloadAction<{
        query: OfflineTypeRefsQueryParams;
        model?: IOfflineServiceTypeRefsDto;
      }>,
    ) {
      state.processing = true;
      state.isEdit = false;
      state.detailsState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
        error: undefined,
      };
    },
    initDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
        data: IOfflineTypeRefsModel;
        isEdit: boolean;
      }>,
    ) {
      state.processing = false;
      state.detailsState.data = action.payload.data;
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.isEdit = action.payload.isEdit;
    },
    initDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    resetDetailsState(state, action: PayloadAction) {
      state.detailsState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
      state.processing = undefined;
    },
    createRefs(state, action: PayloadAction<IOfflineTypeRefsModel>) {
      state.detailsState.processing = true;
      state.detailsState.completed = undefined;
      state.detailsState.hasErrors = undefined;
    },
    createRefs_Success(state, action: PayloadAction<{ hasErrors: boolean }>) {
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.detailsState.completed = true;
    },
    createRefs_Error(state, action: PayloadAction<any>) {
      state.detailsState.processing = false;
      state.detailsState.completed = true;
      state.detailsState.hasErrors = true;
    },
    updateRefs(
      state,
      action: PayloadAction<{
        current: IOfflineTypeRefsModel;
        original: IOfflineTypeRefsModel;
      }>,
    ) {
      state.detailsState.processing = true;
      state.detailsState.completed = undefined;
      state.detailsState.hasErrors = undefined;
    },
    updateRefs_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.detailsState.completed = true;
    },
    updateRefs_Error(state, action: PayloadAction<any>) {
      state.detailsState.processing = false;
      state.detailsState.completed = true;
      state.detailsState.hasErrors = true;
    },
    setFilters(state, action: PayloadAction<Record<string, any> | undefined>) {
      state.filters = action.payload;
    },
    resetFilters(state, action: PayloadAction) {
      state.filters = undefined;
    },
  },
});

export const { actions: offlineTypeRefsActions } = slice;

export const useOfflineTypeRefsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: offlineTypeRefsSaga });
  return { actions: slice.actions };
};
