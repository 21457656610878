import { TrainingProgramsMultiPicker } from 'app/components/pickers/MultiSelectPickers/TrainingProgramsMultiPicker';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export const TrainingProgramsMultiFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<number>[]) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <TrainingProgramsMultiPicker
      onChange={handleOnChange}
      value={props.value as Entity<number>[]}
      isOpen={false}
      placeholder={t(translations.AllValues)}
      size="small"
      ariaLabel={props.label}
      fullWidth={props.fullWidth}
      id={props.id}
    />
  );
};
