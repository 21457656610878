import DateTimeRenderer from 'app/components/BasicTable/CellRenderers/DateTimeRenderer';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Column } from 'react-table';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { UserProfileLink } from '../UserDetails/OpenUserProfile';
import { IAssetSearchHistoryDto } from './IAssetSearchResultsDto';

export const getColumns = (
  t: TFunction,
  user?: AuthenticatedUser,
  useSidePanel?: boolean,
  openPanel?: (state: SidePanelOpenState) => void,
): Column<IAssetSearchHistoryDto>[] => {
  return [
    {
      Header: t(translations.SearchDatetime) as string,
      accessor: 'SearchDatetime',
      Cell: DateTimeRenderer,
    },
    {
      Header: t(translations.UserName) as string,
      accessor: 'UserName',
      Cell: ({ value, row }) => (
        <UserProfileLink
          userName={row.original.UserName ?? undefined}
          displayName={row.original.UserName ?? undefined}
          useSidePanel={useSidePanel}
          openPanelWithCover={openPanel}
        />
      ),
    },
    {
      Header: t(translations.MainText) as string,
      accessor: 'MainText',
    },
    {
      Header: t(translations.ServiceGroup) as string,
      accessor: 'ServiceGroupName',
    },
    {
      Header: t(translations.ServiceName) as string,
      accessor: 'ServiceName',
    },
    {
      Header: t(translations.Tags) as string,
      accessor: 'Tags',
    },
    {
      Header: t(translations.Description) as string,
      accessor: 'Description',
    },
    {
      Header: t(translations.Model) as string,
      accessor: 'ModelNumber',
    },
    {
      Header: t(translations.Building) as string,
      accessor: 'BuildingNumber',
    },
    {
      Header: t(translations.Room) as string,
      accessor: 'RoomNumber',
    },
    {
      Header: t(translations.BarCode) as string,
      accessor: 'Barcode',
    },
    {
      Header: t(translations.AssetCatGroupName) as string,
      accessor: 'AssetCatGroupName',
    },
    {
      Header: t(translations.AssetCatName) as string,
      accessor: 'AssetCatName',
    },
    {
      Header: t(translations.ResultsCount) as string,
      accessor: 'ResultsCount',
    },
  ];
};
