import { httpClient } from 'api/HttpClient';
import { quoteODataValue } from 'api/odata/ODataFilter';
import {
  BaseURLPicker,
  BaseURLPickerProps,
} from 'app/components/BasicPickers/BaseURLPicker';
import { CustomFormTypeEnum } from 'enums/CustomFormType';
import * as React from 'react';
import { Entity } from 'types/common';
export interface CustomFormPickerProps
  extends Omit<BaseURLPickerProps<Entity<number>>, 'url' | 'queryfilter'> {
  formType: CustomFormTypeEnum;
  referenceId?: number;
  filter?: string;
}

export const initCustomFormData = (
  initval: string | undefined,
  type: CustomFormTypeEnum,
) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const url = `/api/odata/v4/CustomForm(${type})`;
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name,FormFields,ServiceGroups,Active,Type',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
export function AlertFormFilter(
  alertTypeId: number,
  assetCatId: number,
): string | undefined {
  let filter = `(WorkOrderFormMatrices/any(m: m/AlertTypeId eq ${quoteODataValue(
    alertTypeId,
  )} and m/AssetCatId eq ${assetCatId}))`;
  return filter;
}
export function CustomFormPicker(props: CustomFormPickerProps) {
  const { filter, formType } = props;
  const url = `/api/odata/v4/CustomForm(${formType})`;
  return (
    <BaseURLPicker
      url={url}
      queryfilter={filter}
      {...props}
      id={props.id || 'customformId'}
    />
  );
}
