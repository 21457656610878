import { IOfflineServiceTypeRefsDto } from 'api/odata/generated/entities/IOfflineServiceTypeRefsDto';
import { BasicTable } from 'app/components/BasicTable';
import { BasicTableProps } from 'app/components/BasicTable/UnconfigurableTable/UnconfigurableTable';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function OfflineTypeReferencesTable<
  TProps extends Omit<
    BasicTableProps<IOfflineServiceTypeRefsDto>,
    | 'api'
    | 'additionalColumns'
    | 'searchColumns'
    | 'screenName'
    | 'expandedColumns'
    | 'savedListSettings'
  >,
>({ initialState, rowActions, serviceGroups, ...props }: TProps) {
  const { t } = useTranslation();
  const newProps = React.useMemo(() => {
    const p = { ...props };
    Reflect.deleteProperty(p, 'api');
    return p;
  }, [props]);
  const addColumns = React.useMemo<(keyof IOfflineServiceTypeRefsDto)[]>(() => {
    const cols: (keyof IOfflineServiceTypeRefsDto)[] = [
      'Id',
      'ServiceGroupId',
      'OfflineServiceTypeId',
      'OnlineServiceTypeId',
      'SampleRequestTypeId',
      'SampleRequestTypeName',
      'OnlineServiceTypeName',
      'RefTypeId',
      'RefId',
    ];
    return cols;
  }, []);
  const url = '/api/odata/v4/OfflineServiceTypeRefs';
  return (
    <>
      <BasicTable
        api={url}
        screenName={t(translations.menu_OfflineTypeRefernces)}
        useExport={true}
        initialState={initialState}
        useRowSelect={true}
        rowActions={rowActions}
        additionalColumns={addColumns}
        searchColumns={[
          'cast(OfflineServiceTypeId,Edm.String)',
          'OfflineServiceTypeName',
          'OnlineServiceTypeName',
          'SampleRequestTypeName',
          'Name',
        ]}
        serviceGroups={serviceGroups}
        {...(newProps as TProps)}
      />
    </>
  );
}
