import { AssetBarcodeScanParameters } from 'app/pages/AssetsInventory/Details/slice/types';
import { httpClient } from './HttpClient';

const url = '/api/odata/v4/BarcodeScanLog';
export const BarcodeScanApi = {
  scanBarcode: (payload: AssetBarcodeScanParameters) => {
    const data = {
      params: payload,
    };
    return httpClient.post(`${url}/BarcodeScan`, data);
  },
  logNoRoomAccess: (payload: number) => {
    const data = {
      roomId: payload,
    };
    return httpClient.post(`${url}/LogNoRoomAccess`, data);
  },
  logRoomScan: (payload: number) => {
    const data = {
      roomId: payload,
    };
    return httpClient.post(`${url}/LogRoomScann`, data);
  },
  logLocationScan: (roomId: number, locationId: number) => {
    const data = {
      roomId: roomId,
      locationId: locationId,
    };
    return httpClient.post(`${url}/LogLocationScann`, data);
  },
  logSubLocationScan: (
    roomId: number,
    locationId: number,
    sublocationId: number,
  ) => {
    const data = {
      roomId: roomId,
      locationId: locationId,
      sublocationId: sublocationId,
    };
    return httpClient.post(`${url}/LogSubLocationScann`, data);
  },
};
