import { CircularProgress } from '@material-ui/core';
import { FrontendLayout_MainContent_ID } from 'app/Layout/FrontendLayout';
import Infinite, {
  Props as InfiniteProps,
} from 'react-infinite-scroll-component';
import styled from 'styled-components';

export interface InfiniteScrollProps
  extends Pick<
    InfiniteProps,
    | 'children'
    | 'next'
    | 'hasMore'
    | 'dataLength'
    | 'className'
    | 'style'
    | 'hasChildren'
    | 'scrollableTarget'
    | 'scrollThreshold'
  > {}
const LoaderHeader = styled.h4`
  display: flex;
  justify-content: center;
`;
export const InfiniteScroll = ({
  children,
  style,
  className,
  scrollableTarget = FrontendLayout_MainContent_ID,
  ...props
}: InfiniteScrollProps) => {
  return (
    <Infinite
      loader={
        <LoaderHeader>
          <CircularProgress size={30} />
        </LoaderHeader>
      }
      className={className}
      scrollableTarget={scrollableTarget}
      style={
        style ??
        (className !== undefined
          ? undefined
          : {
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
            })
      }
      {...props}
    >
      {children}
    </Infinite>
  );
};
