import { IUtils } from '@date-io/core/IUtils';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { BaseKeyboardPickerProps } from '@material-ui/pickers/_shared/hooks/useKeyboardPickerState';
import { toEndDateDisplayValue } from 'app/components/DatePicker/utils/toEndDateDisplayValue';
import { CustomDate } from 'types/CustomDate';
import { dateUtils } from 'utils/date-utils';
import getFormatTokens from 'utils/DateFormattingUtils/getFormatTokens';

export type DateStartWith = 'day' | 'month' | 'year';
export type DatePopupVariants = 'date' | 'datetime' | 'time' | 'separated';
export interface DateTimeMask {
  day: string;
  month: string;
  year: string;
  hour: string;
  minute: string;
  meridiem: string;
  dateSeparator: string;
  dateStartFrom: DateStartWith;
}
export interface FormatedResult {
  value: string;
  stayOnSelection: boolean;
  zeroPress: boolean;
}
export const getInputViewDate = (
  variant: DatePopupVariants,
  value?: Date | string | null,
  endTime?: boolean,
  truncateTime?: boolean,
): string | undefined => {
  if (variant === 'date') {
    return (
      dateUtils.shortDateFormat(
        toEndDateDisplayValue({
          value,
          endTime,
          truncateTime,
          direction: 'toDisplay',
        }),
      ) ?? undefined
    );
  } else if (variant === 'time') {
    return dateUtils.timeFormat(value) ?? undefined;
  } else if (variant === 'datetime') {
    return dateUtils.shortDateTimeFormat(value) ?? undefined;
  } else {
    return dateUtils.shortDateTimeFormat(value) ?? undefined;
  }
};
export const setInputViewDate = (
  variant: DatePopupVariants,
  value?: Date | string | null,
  endTime?: boolean,
  truncateTime?: boolean,
): MaterialUiPickersDate | null => {
  if (value === null) {
    return null;
  }
  if (value === undefined) {
    return null;
  } else if (variant === 'date') {
    let ddd =
      toEndDateDisplayValue({
        value,
        endTime,
        truncateTime,
        direction: 'fromDisplay',
      }) ?? value;
    return new CustomDate(ddd, 'date');
  } else if (variant === 'time') {
    return new CustomDate(value, 'time');
  } else if (variant === 'datetime') {
    return new CustomDate(value, 'complete');
  } else {
    return new CustomDate(value, 'complete');
  }
};
export const getValueDateOrTruncat = (
  variant: DatePopupVariants,
  value?: Date | string | null,
  endTime?: boolean,
  truncateTime?: boolean,
): Date | string | null | undefined => {
  if (value === null || value === undefined) {
    return value;
  }

  if (variant === 'date') {
    return toEndDateDisplayValue({
      value,
      endTime,
      truncateTime,
      direction: 'toDisplay',
    });
  } else {
    return value;
  }
};
export const maskFormats = {
  date: '__/__/____',
  time: '__:__',
  timeMeridiem: '__:__ _M',
  dateTime: '__/__/____ __:__',
  dateTimeMeridiem: '__/__/____ __:__ _M',
};
export const defaultDateTimeMask = (
  format: string,
  variant?: 'date' | 'time',
): DateTimeMask => {
  const tokens = getFormatTokens(format);
  if (tokens === null) {
    let defTokens = getFormatTokens(dateUtils.DateIOFormats.keyboardDateTime); //full fate time
    if (defTokens === null) {
      return {
        day: 'dd',
        month: 'MM',
        year: 'yyyy',
        hour: 'HH',
        minute: 'mm',
        meridiem: 'a',
        dateSeparator: '/',
        dateStartFrom: 'day',
      };
    } else {
      let first = defTokens[0].toLowerCase();
      let dateStartWith = first.startsWith('d')
        ? 'day'
        : first.startsWith('m')
        ? 'month'
        : 'year';
      return {
        day:
          dateStartWith === 'day'
            ? defTokens[0]
            : dateStartWith === 'month'
            ? defTokens[2]
            : defTokens[4],
        month:
          dateStartWith === 'day'
            ? defTokens[2]
            : dateStartWith === 'month'
            ? defTokens[0]
            : defTokens[4],
        year:
          dateStartWith === 'day'
            ? defTokens[4]
            : dateStartWith === 'month'
            ? defTokens[4]
            : defTokens[0],
        hour: defTokens[6],
        minute: defTokens[8],
        meridiem: defTokens.length > 8 ? defTokens[10] : 'aa',
        dateSeparator: defTokens[1],
        dateStartFrom:
          dateStartWith === 'day'
            ? 'day'
            : dateStartWith === 'month'
            ? 'month'
            : 'year',
      };
    }
  } else {
    if (variant === undefined) {
      if (tokens[0].toLowerCase().startsWith('h')) {
        //time
        let dateTokens = getFormatTokens(
          dateUtils.DateIOFormats.keyboardDate,
        ) ?? ['dd', '/', 'MM', '/', 'yyyy']; //full date
        let first = dateTokens[0].toLowerCase();
        let dateStartWith = first.startsWith('d')
          ? 'day'
          : first.startsWith('m')
          ? 'month'
          : 'year';
        return {
          day:
            dateStartWith === 'day'
              ? dateTokens[0]
              : dateStartWith === 'month'
              ? dateTokens[2]
              : dateTokens[4],
          month:
            dateStartWith === 'day'
              ? dateTokens[2]
              : dateStartWith === 'month'
              ? dateTokens[0]
              : dateTokens[4],
          year:
            dateStartWith === 'day'
              ? dateTokens[4]
              : dateStartWith === 'month'
              ? dateTokens[4]
              : dateTokens[0],
          hour: tokens[0],
          minute: tokens[2],
          meridiem: tokens.length > 2 ? tokens[4] : 'aa',
          dateSeparator: dateTokens[1],
          dateStartFrom:
            dateStartWith === 'day'
              ? 'day'
              : dateStartWith === 'month'
              ? 'month'
              : 'year',
        };
      } else {
        //date
        let first = tokens[0].toLowerCase();
        let dateStartWith = first.startsWith('d')
          ? 'day'
          : first.startsWith('m')
          ? 'month'
          : 'year';
        let timeTokens = getFormatTokens(dateUtils.DateIOFormats.fullTime) ?? [
          'HH',
          ':',
          'mm',
        ];
        return {
          day:
            dateStartWith === 'day'
              ? tokens[0]
              : dateStartWith === 'month'
              ? tokens[2]
              : tokens[4],
          month:
            dateStartWith === 'day'
              ? tokens[2]
              : dateStartWith === 'month'
              ? tokens[0]
              : tokens[4],
          year:
            dateStartWith === 'day'
              ? tokens[4]
              : dateStartWith === 'month'
              ? tokens[4]
              : tokens[0],
          hour: timeTokens[0],
          minute: timeTokens[2],
          meridiem: timeTokens.length > 2 ? timeTokens[4] : 'a',
          dateSeparator: tokens[1],
          dateStartFrom:
            dateStartWith === 'day'
              ? 'day'
              : dateStartWith === 'month'
              ? 'month'
              : 'year',
        };
      }
    } else {
      if (variant === 'time') {
        let dateTokens = getFormatTokens(
          dateUtils.DateIOFormats.keyboardDate,
        ) ?? ['dd', '/', 'MM', '/', 'yyyy']; //full date
        let first = dateTokens[0].toLowerCase();
        let dateStartWith = first.startsWith('d')
          ? 'day'
          : first.startsWith('m')
          ? 'month'
          : 'year';
        return {
          day:
            dateStartWith === 'day'
              ? dateTokens[0]
              : dateStartWith === 'month'
              ? dateTokens[2]
              : dateTokens[4],
          month:
            dateStartWith === 'day'
              ? dateTokens[2]
              : dateStartWith === 'month'
              ? dateTokens[0]
              : dateTokens[4],
          year:
            dateStartWith === 'day'
              ? dateTokens[4]
              : dateStartWith === 'month'
              ? dateTokens[4]
              : dateTokens[0],
          hour: tokens[0],
          minute: tokens[2],
          meridiem: tokens.length > 2 ? tokens[4] : 'a',
          dateSeparator: dateTokens[1],
          dateStartFrom:
            dateStartWith === 'day'
              ? 'day'
              : dateStartWith === 'month'
              ? 'month'
              : 'year',
        };
      } else {
        let first = tokens[0].toLowerCase();
        let dateStartWith = first.startsWith('d')
          ? 'day'
          : first.startsWith('m')
          ? 'month'
          : 'year';
        let timeTokens = getFormatTokens(dateUtils.DateIOFormats.fullTime) ?? [
          'HH',
          ':',
          'mm',
        ];
        return {
          day:
            dateStartWith === 'day'
              ? tokens[0]
              : dateStartWith === 'month'
              ? tokens[2]
              : tokens[4],
          month:
            dateStartWith === 'day'
              ? tokens[2]
              : dateStartWith === 'month'
              ? tokens[0]
              : tokens[4],
          year:
            dateStartWith === 'day'
              ? tokens[4]
              : dateStartWith === 'month'
              ? tokens[4]
              : tokens[0],
          hour: timeTokens[0],
          minute: timeTokens[2],
          meridiem: timeTokens.length > 2 ? timeTokens[4] : 'a',
          dateSeparator: tokens[1],
          dateStartFrom:
            dateStartWith === 'day'
              ? 'day'
              : dateStartWith === 'month'
              ? 'month'
              : 'year',
        };
      }
    }
  }
};

export const maskFormat = (
  dateVariant: Omit<DatePopupVariants, 'separate'>,
  ampm: boolean,
): string => {
  switch (dateVariant) {
    case 'date':
      return maskFormats.date;
    case 'time':
      return ampm ? maskFormats.timeMeridiem : maskFormats.time;
    case 'datetime':
      return ampm ? maskFormats.dateTimeMeridiem : maskFormats.dateTime;
    default:
      return maskFormats.date;
  }
};
export function makeMaskFromFormat(format, numberMaskChar) {
  return format.replace(/[a-z]/gi, numberMaskChar);
}
//MUI-4 dateFormatter
export const maskedDateFormatter = function (mask, numberMaskChar, refuse) {
  return function (value) {
    var result = '';
    var parsed = value.replace(refuse, '');
    if (parsed === '') {
      return parsed;
    }
    var i = 0;
    var n = 0;
    while (i < mask.length) {
      var maskChar = mask[i];
      if (maskChar === numberMaskChar && n < parsed.length) {
        var parsedChar = parsed[n];
        result += parsedChar;
        n += 1;
      } else {
        result += maskChar;
      }
      i += 1;
    }
    return result;
  };
};

export const parseDigits = (input: string) =>
  (input.match(/\d+/g) || []).join('');

export const formatDate = (input: string) => {
  const digits = parseDigits(input);
  const chars = digits.split('');
  let res = chars
    .reduce((r, v, index) => {
      return index === 2 || index === 4 ? `${r}/${v}` : `${r}${v}`;
    }, '')
    .substr(0, 10);
  return res;
};

export const formatDateWithAppend = (input: string) => {
  const res = formatDate(input);

  if (input.endsWith('/')) {
    if (res.length === 2) {
      return `${res}/`;
    }

    if (res.length === 5) {
      return `${res}/`;
    }
  }
  return res;
};

export const appendHyphen = v =>
  v.length === 2 || v.length === 5 ? `${v}/` : v;
export const addMask = string => {
  const digits = parseDigits(string);
  const days = digits.slice(0, 2).padEnd(2, '_');
  const months = digits.slice(2, 4).padEnd(2, '_');
  const years = digits.slice(4, 8).padEnd(4, '_');
  return `${days}-${months}-${years}`;
};

export const dateStart: DateStartWith = dateUtils.dateFnsUtils.dateFormat
  .toLowerCase()
  .startsWith('m')
  ? 'month'
  : dateUtils.dateFnsUtils.dateFormat.toLowerCase().startsWith('d')
  ? 'day'
  : dateUtils.dateFnsUtils.dateFormat.toLowerCase().startsWith('y')
  ? 'year'
  : 'day';

export const dateSelection = (
  event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement, MouseEvent>,
  maskFormats: DateTimeMask,
) => {
  const target = event.target as HTMLInputElement;
  let dateStartFrom = maskFormats.dateStartFrom;
  // let selectionDirection = target.selectionDirection;
  let selectionStart = target.selectionStart;
  let selectionEnd = target.selectionEnd;
  if (!!target.value && target.value !== '') {
    if (dateStartFrom === 'year') {
      if (selectionEnd !== null) {
        if (selectionEnd <= 4) {
          if (selectionStart === 0 && selectionEnd === 4) {
            event.preventDefault();
          }
          target.setSelectionRange(0, 4);
        } else if (selectionEnd <= 7) {
          if (selectionStart === 5 && selectionEnd === 7) {
            event.preventDefault();
          }
          target.setSelectionRange(5, 7);
        } else {
          if (selectionStart === 8 && selectionEnd === 10) {
            event.preventDefault();
          }
          target.setSelectionRange(8, 10);
        }
        // target.setRangeText(target.value.substring(0, 2));
      }
    } else {
      if (selectionEnd !== null) {
        if (selectionEnd <= 2) {
          if (selectionStart === 0 && selectionEnd === 2) {
            event.preventDefault();
          }
          target.setSelectionRange(0, 2);
        } else if (selectionEnd <= 5) {
          if (selectionStart === 3 && selectionEnd === 5) {
            event.preventDefault();
          }
          target.setSelectionRange(3, 5);
        } else {
          if (selectionStart === 6 && selectionEnd === 10) {
            event.preventDefault();
          }
          target.setSelectionRange(6, 10);
        }
        // target.setRangeText(target.value.substring(0, 2));
      }
    }
  }
};
export const timeSelection = (
  event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement, MouseEvent>,
  maskFormats: DateTimeMask,
) => {
  const target = event.target as HTMLInputElement;
  // let selectionDirection = target.selectionDirection;
  let selectionStart = target.selectionStart ?? 0;
  let selectionEnd = target.selectionEnd ?? 0;
  let oneLetterHour =
    maskFormats.hour.length === 1 && target.value.split(':')[0].length === 1;

  if (!!target.value && target.value !== '') {
    if (selectionEnd <= (oneLetterHour ? 1 : 2)) {
      if (selectionStart === 0 && selectionEnd === (oneLetterHour ? 1 : 2)) {
        event.preventDefault();
      }
      target.setSelectionRange(0, oneLetterHour ? 1 : 2);
    } else if (selectionEnd <= (oneLetterHour ? 4 : 5)) {
      if (
        selectionStart > (oneLetterHour ? 1 : 2) &&
        selectionEnd === (oneLetterHour ? 4 : 5)
      ) {
        event.preventDefault();
      }
      target.setSelectionRange(oneLetterHour ? 2 : 3, oneLetterHour ? 4 : 5);
    } else {
      if (target.value.length > (oneLetterHour ? 4 : 5)) {
        if (
          selectionStart === (oneLetterHour ? 5 : 6) &&
          selectionEnd === (oneLetterHour ? 7 : 8)
        ) {
          event.preventDefault();
        }
        target.setSelectionRange(oneLetterHour ? 5 : 6, oneLetterHour ? 7 : 8);
      } else {
        target.setSelectionRange(3, 5);
      }
    }
  }
};
export const dateArrowSelection = (
  event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  arrows: 'left' | 'right',
  variant: 'date' | 'time',
  maskFormats: DateTimeMask,
) => {
  const target = event.target as HTMLInputElement;
  let selectionStart = target.selectionStart ?? 0;
  let selectionEnd = target.selectionEnd ?? 0;
  let oneLetterHour =
    variant === 'time' &&
    maskFormats.hour.length === 1 &&
    target.value.split(':')[0].length === 1;
  let dateStartFrom = maskFormats.dateStartFrom;
  if (arrows === 'left') {
    if (!!target.value && target.value !== '') {
      if (variant === 'time') {
        if (selectionStart <= (oneLetterHour ? 2 : 3)) {
          target.setSelectionRange(0, oneLetterHour ? 1 : 2);
        } else if (
          selectionStart <= (oneLetterHour ? 5 : 6) &&
          selectionStart > (oneLetterHour ? 2 : 3)
        ) {
          target.setSelectionRange(
            oneLetterHour ? 2 : 3,
            oneLetterHour ? 4 : 5,
          );
        } else {
          if (target.value.length > (oneLetterHour ? 4 : 5)) {
            target.setSelectionRange(
              oneLetterHour ? 5 : 6,
              oneLetterHour ? 7 : 8,
            );
          } else {
            target.setSelectionRange(3, 5);
          }
        }
      } else {
        if (dateStartFrom === 'year') {
          if (selectionStart <= 6 && selectionStart > 4) {
            target.setSelectionRange(0, 4);
          } else if (selectionStart <= 8 && selectionStart > 5) {
            target.setSelectionRange(5, 7);
          } else {
            target.setSelectionRange(8, 10);
          }
        } else {
          if (selectionStart <= 3) {
            target.setSelectionRange(0, 2);
          } else if (selectionStart <= 6 && selectionStart > 3) {
            target.setSelectionRange(3, 5);
          } else {
            target.setSelectionRange(6, 10);
          }
        }
      }
    }
  }
  if (arrows === 'right') {
    if (!!target.value && target.value !== '') {
      if (variant === 'time') {
        if (
          selectionEnd >= (oneLetterHour ? 1 : 2) &&
          selectionEnd < (oneLetterHour ? 4 : 5)
        ) {
          target.setSelectionRange(
            oneLetterHour ? 2 : 3,
            oneLetterHour ? 4 : 5,
          );
        } else if (selectionEnd >= (oneLetterHour ? 4 : 5)) {
          if (target.value.length > (oneLetterHour ? 4 : 5)) {
            target.setSelectionRange(
              oneLetterHour ? 5 : 6,
              oneLetterHour ? 7 : 8,
            );
          } else {
            target.setSelectionRange(3, 5);
          }
        } else {
          target.setSelectionRange(0, oneLetterHour ? 1 : 2);
        }
      } else {
        if (dateStartFrom === 'year') {
          if (selectionEnd >= 4 && selectionEnd < 7) {
            target.setSelectionRange(5, 7);
          } else if (selectionEnd >= 7) {
            target.setSelectionRange(8, 10);
          } else {
            target.setSelectionRange(0, 2);
          }
        } else {
          if (selectionEnd >= 2 && selectionEnd < 5) {
            target.setSelectionRange(3, 5);
          } else if (selectionEnd >= 5) {
            target.setSelectionRange(6, 10);
          } else {
            target.setSelectionRange(0, 2);
          }
        }
      }
    }
  }
};
export const getMaskedDateValue = (mask: DateTimeMask, value?: string) => {
  let singleValue = !!value && value.length === 1 ? value : undefined;
  return mask.dateStartFrom === 'day'
    ? `${!!singleValue ? singleValue : mask.day}${mask.dateSeparator}${
        mask.month
      }${mask.dateSeparator}${mask.year}`
    : mask.dateStartFrom === 'month'
    ? `${!!singleValue ? singleValue : mask.month}${mask.dateSeparator}${
        mask.day
      }${mask.dateSeparator}${mask.year}`
    : `${!!singleValue ? singleValue : mask.year}${mask.dateSeparator}${
        mask.month
      }${mask.dateSeparator}${mask.day}`;
};
export const getMaskedTimeValue = (
  mask: DateTimeMask,
  ampm?: boolean,
  value?: string,
) => {
  let separator = ':';
  // value only used when is a single character
  let hour = !!value && value.length === 1 ? value : undefined;
  return ampm
    ? `${!!hour ? hour : mask.hour}${separator}${mask.minute} ${mask.meridiem}`
    : `${!!hour ? hour : mask.hour}${separator}${mask.minute}`;
};
export const formatingDate = (
  text: string,
  partChanged: 'day' | 'month' | 'year',
  maskFormat: DateTimeMask,
  prevValue: string,
  increment?: 'increase' | 'decrease',
  isPrevZero?: boolean,
  isSelectionChanged?: boolean,
): FormatedResult => {
  let result = {
    value: prevValue,
    stayOnSelection: false,
    zeroPress: false,
  };
  let stayOnSelection = false;
  let newDate = prevValue;
  let separator = maskFormat.dateSeparator;
  let startedFrom = maskFormat.dateStartFrom;
  let zeroPress = false;
  if (text === '') {
    newDate =
      startedFrom === 'day'
        ? `${maskFormat.day}${separator}${maskFormat.month}${separator}${maskFormat.year}`
        : maskFormat.dateStartFrom === 'month'
        ? `${maskFormat.month}${separator}${maskFormat.day}${separator}${maskFormat.year}`
        : `${maskFormat.year}${separator}${maskFormat.month}${separator}${maskFormat.day}`;
  } else {
    let parts = text.split(separator);
    let prevParts = prevValue.split(separator);
    if (partChanged === 'day') {
      let dayVal =
        startedFrom === 'day'
          ? parts[0]
          : startedFrom === 'month'
          ? parts[1]
          : parts[2];
      let prevDayVal =
        startedFrom === 'day'
          ? prevParts[0]
          : startedFrom === 'month'
          ? prevParts[1]
          : prevParts[2];
      if (dayVal === '') {
        stayOnSelection = true;
        newDate =
          startedFrom === 'day'
            ? `${maskFormat.day}${separator}${parts[1]}${separator}${parts[2]}`
            : startedFrom === 'month'
            ? `${parts[0]}${separator}${maskFormat.day}${separator}${parts[2]}`
            : `${parts[0]}${separator}${parts[1]}${separator}${maskFormat.day}`;
      } else {
        if (
          dayVal.match(/[^\d]+/gi) ||
          (dayVal === '0' &&
            !isNaN(parseInt(prevDayVal)) &&
            (parseInt(prevDayVal) > 3 || isSelectionChanged)) ||
          isNaN(parseInt(dayVal))
        ) {
          stayOnSelection = true;
          zeroPress = dayVal === '0';
          newDate =
            startedFrom === 'day'
              ? `${prevDayVal}${separator}${parts[1]}${separator}${parts[2]}`
              : startedFrom === 'month'
              ? `${parts[0]}${separator}${prevDayVal}${separator}${parts[2]}`
              : `${parts[0]}${separator}${parts[1]}${separator}${prevDayVal}`;
        } else {
          let newVal = '';
          let dayInt = parseInt(dayVal);
          if (!!increment) {
            stayOnSelection = true;
            dayInt = increment === 'increase' ? dayInt + 1 : dayInt - 1;
            dayVal = dayInt.toString();
            if (dayInt <= 0) {
              newDate =
                startedFrom === 'day'
                  ? `${'31'}${separator}${parts[1]}${separator}${parts[2]}`
                  : startedFrom === 'month'
                  ? `${parts[0]}${separator}${'31'}${separator}${parts[2]}`
                  : `${parts[0]}${separator}${parts[1]}${separator}${'31'}`;
            } else if (dayInt > 31) {
              newDate =
                startedFrom === 'day'
                  ? `${'01'}${separator}${parts[1]}${separator}${parts[2]}`
                  : startedFrom === 'month'
                  ? `${parts[0]}${separator}${'01'}${separator}${parts[2]}`
                  : `${parts[0]}${separator}${parts[1]}${separator}${'01'}`;
            } else {
              newVal = dayInt < 10 ? `0${dayVal}` : dayVal;
              newDate =
                startedFrom === 'day'
                  ? `${newVal}${separator}${parts[1]}${separator}${parts[2]}`
                  : startedFrom === 'month'
                  ? `${parts[0]}${separator}${newVal}${separator}${parts[2]}`
                  : `${parts[0]}${separator}${parts[1]}${separator}${newVal}`;
            }
          } else {
            if (prevDayVal === '' || prevDayVal === maskFormat.day) {
              newVal = `0${dayVal}`;
              stayOnSelection = dayInt <= 3;
            } else {
              let prevInt = parseInt(isPrevZero ? '0' : prevDayVal);
              if (prevInt.toString().length > 1) {
                newVal = `0${dayVal}`;
                stayOnSelection = dayInt <= 3;
              } else {
                if (prevInt > 3) {
                  newVal = `0${dayVal}`;
                  stayOnSelection = dayInt <= 3;
                } else {
                  let comb = `${prevInt}${dayInt}`;
                  if (parseInt(comb) > 31) {
                    newVal = `0${dayVal}`;
                    stayOnSelection = dayInt <= 3;
                  } else {
                    newVal = comb;
                  }
                }
              }
            }
            newDate =
              startedFrom === 'day'
                ? `${newVal}${separator}${parts[1]}${separator}${parts[2]}`
                : startedFrom === 'month'
                ? `${parts[0]}${separator}${newVal}${separator}${parts[2]}`
                : `${parts[0]}${separator}${parts[1]}${separator}${newVal}`;
          }
        }
      }
      return {
        value: newDate,
        stayOnSelection: stayOnSelection,
        zeroPress: zeroPress,
      };
    } else if (partChanged === 'month') {
      let monthVal =
        startedFrom === 'day'
          ? parts[1]
          : startedFrom === 'month'
          ? parts[0]
          : parts[1];
      let prevMonthVal =
        startedFrom === 'day'
          ? prevParts[1]
          : startedFrom === 'month'
          ? prevParts[0]
          : prevParts[1];

      // let monthArray = monthEntityArray;
      if (monthVal === '') {
        stayOnSelection = true;
        newDate =
          startedFrom === 'day'
            ? `${parts[0]}${separator}${maskFormat.month}${separator}${parts[2]}`
            : startedFrom === 'month'
            ? `${maskFormat.month}${separator}${parts[1]}${separator}${parts[2]}`
            : `${parts[0]}${separator}${maskFormat.month}${separator}${parts[2]}`;
      } else {
        if (
          monthVal.match(/[^\d]+/gi) ||
          (monthVal === '0' &&
            !isNaN(parseInt(prevMonthVal)) &&
            (parseInt(prevMonthVal) > 1 || isSelectionChanged)) ||
          isNaN(parseInt(monthVal))
        ) {
          stayOnSelection = true;
          zeroPress = monthVal === '0';
          newDate =
            startedFrom === 'day'
              ? `${parts[0]}${separator}${prevMonthVal}${separator}${parts[2]}`
              : startedFrom === 'month'
              ? `${prevMonthVal}${separator}${parts[1]}${separator}${parts[2]}`
              : `${parts[0]}${separator}${prevMonthVal}${separator}${parts[2]}`;
        } else {
          let newVal = '';
          let monthInt = parseInt(monthVal);
          if (!!increment) {
            stayOnSelection = true;
            monthInt = increment === 'increase' ? monthInt + 1 : monthInt - 1;
            monthVal = monthInt.toString();

            if (monthInt <= 0) {
              newDate =
                startedFrom === 'day'
                  ? `${parts[0]}${separator}${'12'}${separator}${parts[2]}`
                  : startedFrom === 'month'
                  ? `${'12'}${separator}${parts[1]}${separator}${parts[2]}`
                  : `${parts[0]}${separator}${'12'}${separator}${parts[2]}`;
            } else if (monthInt > 12) {
              newDate =
                startedFrom === 'day'
                  ? `${parts[0]}${separator}${'01'}${separator}${parts[2]}`
                  : startedFrom === 'month'
                  ? `${'01'}${separator}${parts[1]}${separator}${parts[2]}`
                  : `${parts[0]}${separator}${'01'}${separator}${parts[2]}`;
            } else {
              newVal = monthInt < 10 ? `0${monthVal}` : monthVal;
              newDate =
                startedFrom === 'day'
                  ? `${parts[0]}${separator}${newVal}${separator}${parts[2]}`
                  : startedFrom === 'month'
                  ? `${newVal}${separator}${parts[1]}${separator}${parts[2]}`
                  : `${parts[0]}${separator}${newVal}${separator}${parts[2]}`;
            }
          } else {
            if (prevMonthVal === '' || prevMonthVal === maskFormat.month) {
              newVal = `0${monthVal}`;
              stayOnSelection = monthInt <= 1;
            } else {
              let prevMonthInt = parseInt(isPrevZero ? '0' : prevMonthVal);
              if (prevMonthInt.toString().length > 1) {
                newVal = `0${monthVal}`;
                stayOnSelection = monthInt <= 1;
              } else {
                let comb = `${prevMonthInt}${monthInt}`;
                if (parseInt(comb) > 12) {
                  newVal = `0${monthVal}`;
                  stayOnSelection = monthInt <= 1;
                } else {
                  newVal = comb;
                }
                // if (prevMonthInt === 1) {
                //   let comb = `${prevMonthInt}${monthInt}`;
                //   if (parseInt(comb) > 12) {
                //     newVal = `0${monthVal}`;
                //     stayOnSelection = monthInt <= 1;
                //   } else {
                //     newVal = comb;
                //   }
                // } else {
                //   newVal = `0${monthVal}`;
                //   stayOnSelection = monthInt <= 1;
                // }
              }
            }
            newDate =
              startedFrom === 'day'
                ? `${parts[0]}${separator}${newVal}${separator}${parts[2]}`
                : startedFrom === 'month'
                ? `${newVal}${separator}${parts[1]}${separator}${parts[2]}`
                : `${parts[0]}${separator}${newVal}${separator}${parts[2]}`;
          }
        }
      }
      return {
        value: newDate,
        stayOnSelection: stayOnSelection,
        zeroPress: zeroPress,
      };
    } else if (partChanged === 'year') {
      let yearVal =
        startedFrom === 'day'
          ? parts[2]
          : startedFrom === 'month'
          ? parts[2]
          : parts[0];
      let prevYearVal =
        startedFrom === 'day'
          ? prevParts[2]
          : startedFrom === 'month'
          ? prevParts[2]
          : prevParts[0];
      if (yearVal === '') {
        stayOnSelection = true;
        newDate =
          startedFrom === 'day'
            ? `${parts[0]}${separator}${parts[1]}${separator}${maskFormat.year}`
            : startedFrom === 'month'
            ? `${parts[0]}${separator}${parts[1]}${separator}${maskFormat.year}`
            : `${maskFormat.year}${separator}${parts[1]}${separator}${parts[2]}`;
      } else {
        if (
          yearVal.match(/[^\d]+/gi) ||
          // yearVal === '0' ||
          isNaN(parseInt(yearVal))
        ) {
          stayOnSelection = true;
          newDate =
            startedFrom === 'day'
              ? `${parts[0]}${separator}${parts[1]}${separator}${prevYearVal}`
              : startedFrom === 'month'
              ? `${parts[0]}${separator}${parts[1]}${separator}${prevYearVal}`
              : `${prevYearVal}${separator}${parts[1]}${separator}${parts[2]}`;
        } else {
          let newVal = '';
          let yearInt = parseInt(yearVal);
          if (!!increment) {
            stayOnSelection = true;
            yearInt = increment === 'increase' ? yearInt + 1 : yearInt - 1;
            yearVal = yearInt.toString();
            if (yearInt < 0) {
              newDate =
                startedFrom === 'day'
                  ? `${parts[0]}${separator}${parts[1]}${separator}${'9999'}`
                  : startedFrom === 'month'
                  ? `${parts[0]}${separator}${parts[1]}${separator}${'9999'}`
                  : `${'9999'}${separator}${parts[1]}${separator}${parts[2]}`;
            } else if (yearInt > 9999) {
              newDate =
                startedFrom === 'day'
                  ? `${parts[0]}${separator}${parts[1]}${separator}${'0000'}`
                  : startedFrom === 'month'
                  ? `${parts[0]}${separator}${parts[1]}${separator}${'0000'}`
                  : `${'0000'}${separator}${parts[1]}${separator}${parts[2]}`;
            } else {
              newVal =
                yearInt > 1000
                  ? yearVal
                  : yearInt > 100
                  ? `0${yearVal}`
                  : yearInt > 10
                  ? `00${yearVal}`
                  : `000${yearVal}`;
              newDate =
                startedFrom === 'day'
                  ? `${parts[0]}${separator}${parts[1]}${separator}${newVal}`
                  : startedFrom === 'month'
                  ? `${parts[0]}${separator}${parts[1]}${separator}${newVal}`
                  : `${newVal}${separator}${parts[1]}${separator}${parts[2]}`;
            }
          } else {
            if (prevYearVal === undefined) {
              newVal =
                yearVal.length === 4
                  ? yearVal
                  : yearVal.length === 3
                  ? `0${yearVal}`
                  : yearVal.length === 2
                  ? `00${yearVal}`
                  : `000${yearVal}`;
            } else if (prevYearVal === '' || prevYearVal === maskFormat.year) {
              newVal = `000${yearVal}`;
            } else if (prevYearVal.startsWith('0')) {
              newVal = prevYearVal.padEnd(5, yearVal).slice(1, 5);
            } else {
              newVal = `000${yearVal}`;
            }
            newDate =
              startedFrom === 'day'
                ? `${parts[0]}${separator}${parts[1]}${separator}${newVal}`
                : startedFrom === 'month'
                ? `${parts[0]}${separator}${parts[1]}${separator}${newVal}`
                : `${newVal}${separator}${parts[1]}${separator}${parts[2]}`;
          }
        }
      }
      return {
        value: newDate,
        stayOnSelection: true,
        zeroPress: false,
      };
    } else {
      return result;
    }
  }

  return result;
};
export const formatingTime = (
  text: string,
  partChanged: 'hour' | 'minute' | 'meridiem',
  maskFormat: DateTimeMask,
  prevValue: string,
  increment?: 'increase' | 'decrease',
  ampm?: boolean,
) => {
  let result = {
    value: prevValue,
    stayOnSelection: false,
  };
  let stayOnSelection = false;
  let newTime = prevValue;
  let separator = ':';
  if (text === '') {
    newTime = ampm
      ? `${maskFormat.hour}${separator}${maskFormat.minute} ${maskFormat.meridiem}`
      : `${maskFormat.hour}${separator}${maskFormat.minute}`;
  } else {
    let parts = text.split(separator);
    let hour = parts[0];
    let minute = ampm ? parts[1].split(' ')[0] : parts[1];
    let meridiem = ampm ? parts[1].split(' ')[1] : '';
    let prevParts = prevValue.split(separator);
    let prevHour = prevParts[0];
    let prevMinute = ampm ? prevParts[1].split(' ')[0] : prevParts[1];
    let prevMeridiem = ampm ? prevParts[1].split(' ')[1] : '';
    if (partChanged === 'hour') {
      if (hour === '') {
        stayOnSelection = true;
        newTime = ampm
          ? `${maskFormat.hour}${separator}${minute} ${meridiem}`
          : `${maskFormat.hour}${separator}${minute}`;
      } else {
        if (
          hour.match(/[^\d]+/gi) ||
          (ampm &&
            ((hour === '0' &&
              !isNaN(parseInt(prevHour)) &&
              parseInt(prevHour) > 1) ||
              isNaN(parseInt(hour))))
        ) {
          stayOnSelection = true;
          newTime = ampm
            ? `${prevHour}${separator}${minute} ${meridiem}`
            : `${prevHour}${separator}${minute}`;
        } else {
          let newVal = '';
          let hourInt = parseInt(hour);
          if (!!increment) {
            stayOnSelection = true;
            hourInt = increment === 'increase' ? hourInt + 1 : hourInt - 1;
            hour = hourInt.toString();

            if (hourInt < 0 || (ampm && hourInt === 0)) {
              newTime = ampm
                ? `${'12'}${separator}${minute} ${meridiem}`
                : `${'23'}${separator}${minute}`;
            } else if (hourInt > (ampm ? 12 : 23)) {
              newTime = ampm
                ? `${
                    maskFormat.hour.length === 1 ? '1' : '01'
                  }${separator}${minute} ${meridiem}`
                : `${'00'}${separator}${minute}`;
            } else {
              newVal =
                hourInt < 10
                  ? ampm
                    ? maskFormat.hour.length === 1
                      ? hour
                      : `0${hour}`
                    : `0${hour}`
                  : hour;
              newTime = ampm
                ? `${newVal}${separator}${minute} ${meridiem}`
                : `${newVal}${separator}${minute}`;
            }
          } else {
            if (prevHour === '' || prevHour === maskFormat.hour) {
              newVal = ampm
                ? maskFormat.hour.length === 1
                  ? `${hour}`
                  : `0${hour}`
                : `0${hour}`;
              stayOnSelection = ampm ? hourInt <= 1 : hourInt <= 2;
            } else {
              let prevHourInt = parseInt(prevHour);
              if (prevHourInt.toString().length > 1) {
                newVal = ampm
                  ? maskFormat.hour.length === 1
                    ? `${hour}`
                    : `0${hour}`
                  : `0${hour}`;
                stayOnSelection = ampm ? hourInt <= 1 : hourInt <= 2;
              } else {
                if (
                  (ampm && prevHourInt === 1) ||
                  (!ampm && prevHourInt <= 2)
                ) {
                  let comb = `${prevHourInt}${hourInt}`;
                  if (ampm && parseInt(comb) > 12) {
                    newVal =
                      maskFormat.hour.length === 1 ? `${hour}` : `0${hour}`;
                    stayOnSelection = hourInt <= 1;
                  } else if (!ampm && parseInt(comb) > 23) {
                    newVal = `0${hour}`;
                    stayOnSelection = hourInt <= 2;
                  } else {
                    newVal = comb;
                  }
                } else {
                  newVal = ampm
                    ? maskFormat.hour.length === 1
                      ? `${hour}`
                      : `0${hour}`
                    : `0${hour}`;
                  stayOnSelection = ampm ? hourInt <= 1 : hourInt <= 2;
                }
              }
            }
            newTime = ampm
              ? `${newVal}${separator}${minute} ${meridiem}`
              : `${newVal}${separator}${minute}`;
          }
        }
      }
      return {
        value: newTime,
        stayOnSelection: stayOnSelection,
      };
    }
    if (partChanged === 'minute') {
      if (minute === '') {
        stayOnSelection = true;
        newTime = ampm
          ? `${hour}${separator}${maskFormat.minute} ${meridiem}`
          : `${hour}${separator}${maskFormat.minute}`;
      } else {
        if (minute.match(/[^\d]+/gi)) {
          stayOnSelection = true;
          newTime = ampm
            ? `${hour}${separator}${prevMinute} ${meridiem}`
            : `${hour}${separator}${prevMinute}`;
        } else {
          let newVal = '';
          let minuteInt = parseInt(minute);
          if (!!increment) {
            stayOnSelection = true;
            minuteInt =
              increment === 'increase' ? minuteInt + 1 : minuteInt - 1;
            minute = minuteInt.toString();

            if (minuteInt < 0) {
              newTime = ampm
                ? `${hour}${separator}${'59'} ${meridiem}`
                : `${hour}${separator}${'59'}`;
            } else if (minuteInt > 59) {
              newTime = ampm
                ? `${hour}${separator}${'00'} ${meridiem}`
                : `${hour}${separator}${'00'}`;
            } else {
              newVal = minuteInt < 10 ? `0${minute}` : minute;
              newTime = ampm
                ? `${hour}${separator}${newVal} ${meridiem}`
                : `${hour}${separator}${newVal}`;
            }
          } else {
            if (prevMinute === '' || prevMinute === maskFormat.minute) {
              newVal = `0${minute}`;
              stayOnSelection = minuteInt <= 5;
            } else {
              let prevMinuteInt = parseInt(prevMinute);
              if (prevMinuteInt.toString().length > 1) {
                newVal = `0${minute}`;
                stayOnSelection = minuteInt <= 5;
              } else {
                if (prevMinuteInt <= 5) {
                  let comb = `${prevMinuteInt}${minuteInt}`;
                  if (parseInt(comb) > 59) {
                    newVal = `0${minute}`;
                    stayOnSelection = minuteInt <= 5;
                  } else {
                    newVal = comb;
                  }
                } else {
                  newVal = `0${minute}`;
                  stayOnSelection = minuteInt <= 5;
                }
              }
            }
            newTime = ampm
              ? `${hour}${separator}${newVal} ${meridiem}`
              : `${hour}${separator}${newVal}`;
          }
        }
      }
      return {
        value: newTime,
        stayOnSelection: stayOnSelection,
      };
    }
    if (ampm && partChanged === 'meridiem') {
      if (meridiem === '') {
        stayOnSelection = true;
        newTime = `${hour}${separator}${minute} ${maskFormat.meridiem}`;
      } else {
        if (meridiem.match(/[\dap]+/gi) === null) {
          stayOnSelection = true;
          newTime = `${hour}${separator}${minute} ${prevMeridiem}`;
        } else {
          let newVal = '';
          if (!!increment) {
            stayOnSelection = true;
            newVal = prevMeridiem.toLowerCase().startsWith('p') ? 'AM' : 'PM';
            newTime = `${hour}${separator}${minute} ${newVal}`;
          } else {
            stayOnSelection = true;
            newVal = meridiem.toLowerCase().startsWith('p') ? 'PM' : 'AM';
            newTime = `${hour}${separator}${minute} ${newVal}`;
          }
        }
      }
      return {
        value: newTime,
        stayOnSelection: stayOnSelection,
      };
    }
  }
  return result;
};

export const getDisplayDate = (
  value: ParsableDate,
  format: string,
  utils: IUtils<any>,
  isEmpty: boolean,
  props: Pick<
    BaseKeyboardPickerProps,
    'invalidLabel' | 'emptyLabel' | 'labelFunc'
  >,
) => {
  const { invalidLabel, emptyLabel, labelFunc } = props;
  var date =
    value === null || value === undefined
      ? ''
      : typeof value === 'object'
      ? new Date(value as Date)
      : typeof value === 'number'
      ? new Date(value)
      : dateUtils.dateOrStringToDate(value);
  let t = dateUtils.dateOrStringToDate(date);
  if (labelFunc) {
    return labelFunc(isEmpty ? null : t, invalidLabel || '');
  }
  if (isEmpty) {
    return emptyLabel || '';
  }
  return utils.isValid(date) ? utils.format(t, format) : invalidLabel;
};
export const getParsableDate = (date: ParsableDate): Date | null => {
  var resDate =
    date === null || date === undefined
      ? null
      : typeof date === 'object'
      ? new Date(date as Date)
      : typeof date === 'number'
      ? new Date(date)
      : dateUtils.dateOrStringToDate(date);
  return resDate;
};
