import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { toRootedURL } from 'utils/url-utils';
import { Button } from '../../components/BasicButtons/Button';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { ErrorsPageWrapper } from '../NotAvailablePage';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { NotFoundPageSVG } from '../../components/BasicIcons/SvgIcons';
import { Body } from '../../components/Typography';
export function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t(translations.PageNotFound)}</title>
        <meta name="description" content={t(translations.PageNotFound)} />
      </Helmet>
      <ErrorsPageWrapper id="not_found_page">
        <div className="icon-section">
          <NotFoundPageSVG />
        </div>
        <div className="content-section">
          <BasicTypography variant="h1" id="page-not-found-heading">
            {t(translations.PageNotFound)}
          </BasicTypography>
          <span>
            <Body noWrap={false} component="p">
              {/*{t(translations.PageNotFoundDescription)}*/}
              <p>
                Unfortunately the page you a looking for doesn’t exist <br />
                (anymore) or there was an error in the link that you <br />
                followed or typed.
              </p>
            </Body>
          </span>
          <span>
            {' '}
            <Button
              fullWidth={true}
              variant="main"
              onClick={() => window.open(toRootedURL('/dashboards'), '_self')}
            >
              {t(translations.GoBackToHome)}
            </Button>
          </span>
        </div>
      </ErrorsPageWrapper>
    </>
  );
}
