import { Link } from 'app/components/ExternalLink';
import { Roles } from 'api/odata/generated/enums/Roles';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { IAuthenticatedUser } from 'types/AuthenticatedUser';
import { GlobalSettings } from 'utils/globalSettings';
import { IReservationHistoryDto } from 'api/odata/generated/entities/IReservationHistoryDto';
import { Column } from 'react-table';
import DurationRenderer from 'app/components/BasicTable/CellRenderers/DurationRenderer';
import AssetRenderer from 'app/components/BasicTable/CellRenderers/AssetRenderer';
import BookedByRenderer from 'app/components/BasicTable/CellRenderers/BookedByRenderer';
import BooleanRenderer from 'app/components/BasicTable/CellRenderers/BooleanRenderer';
import { Fragment } from 'react';
import { Guid } from 'guid-typescript';
import { DateLabel } from 'app/components/DateLabel.tsx';
import { roundTwoPlacesAfterDecimalPoint } from 'utils/number-utils';
import DateTimeRenderer from 'app/components/BasicTable/CellRenderers/DateTimeRenderer';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { ReservationLink } from '../Actions/GlobalSelectedActions/OpenReservation';
import React from 'react';
import { toRootedURL } from 'utils/url-utils';
import { ReferenceType } from 'enums/ReferenceType';
import ReservationStatusRenderer from 'app/components/BasicTable/CellRenderers/ReservationStatusRenderer';

export function getColumns(
  t: TFunction,
  user: IAuthenticatedUser | undefined,
  settings: GlobalSettings,
  allowedToView: (row: IReservationHistoryDto) => boolean,
  useSidePanel?: boolean,
  openSidePanel?: (state: SidePanelOpenState) => void,
) {
  if (user === undefined) {
    return [];
  }

  const result: Column<IReservationHistoryDto>[] = [
    {
      Header: t(translations.Id) as string,
      accessor: 'Id',
      Cell: ({ value, row: { original } }) =>
        allowedToView(original) ? (
          <React.Fragment>
            {useSidePanel && openSidePanel ? (
              <ReservationLink
                id={value}
                openPanelWithCover={openSidePanel}
                useSidePanel={useSidePanel}
              >
                {value}
              </ReservationLink>
            ) : (
              <ReservationLink id={value}>{value}</ReservationLink>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>{value}</React.Fragment>
        ),
    },
    {
      Header: t(translations.EquipmentName) as string,
      accessor: 'EquipmentName',
      Cell: AssetRenderer,
    },
    {
      Header: t(translations.AvailabilityType) as string,
      accessor: 'AvailabilityType',
    },
    {
      Header: t(translations.StartTime) as string,
      accessor: 'StartTime',
      Cell: DateTimeRenderer,
    },
    {
      Header: t(translations.EndTime) as string,
      accessor: 'EndTime',
      Cell: DateTimeRenderer,
    },
    {
      Header: t(translations.UsageStartTime) as string,
      accessor: 'UsageStartTime',
      Cell: DateTimeRenderer,
    },
    {
      Header: t(translations.UsageEndTime) as string,
      accessor: 'UsageEndTime',
      Cell: DateTimeRenderer,
    },
    {
      Header: t(translations.OngoingUsage) as string,
      accessor: 'OngoingUsage',
      Cell: BooleanRenderer,
    },
    {
      Header: t(translations.Status) as string,
      accessor: 'Status',
      Cell: ReservationStatusRenderer,
    },
    {
      Header: t(translations.DurationH) as string,
      accessor: 'DurationH',
      Cell: ({ value }) => {
        if (value) return <> {roundTwoPlacesAfterDecimalPoint(value)} </>;
        return <></>;
      },
    },
    {
      Header: t(translations.DurationT) as string,
      accessor: 'DurationT',
      Cell: DurationRenderer,
    },
    {
      Header: t(translations.AccService) as string,
      accessor: 'AccService',
    },
    {
      Header: t(translations.RecordDate) as string,
      accessor: 'RecordDate',
      Cell: DateTimeRenderer,
    },
    {
      Header: t(translations.BudgetNumber) as string,
      accessor: 'BudgetNumber',
    },
    {
      Header: t(translations.CancelledBefore) as string,
      accessor: 'CancelledBefore',
      Cell: DurationRenderer,
      /*       Cell: ({ value }) =>
        value === undefined
          ? 'N/A'
          : dateUtils.HoursMinutesSecondsFormat(value), */
    },
    {
      Header: t(translations.BudgetTaskName) as string,
      accessor: 'BudgetTaskName',
    },
    {
      Header: t(translations.UserGroup) as string,
      accessor: 'ADGroupDisplayName',
      Cell: ({ value, row }) => {
        if (user.Roles.includes(Roles.Administrators)) {
          return (
            <>
              <Link
                title={t(translations.ADGroup)}
                target="_blank"
                href={'/AllowedAdGroup.aspx?ID=' + row.original.ADGroup}
              >
                {value}
              </Link>
            </>
          );
        } else {
          return <> {value} </>;
        }
      },
    },
    {
      Header: t(translations.BookedBy) as string,
      accessor: 'BookedBy',
      Cell: BookedByRenderer,
    },
    {
      Header: t(translations.BudgetExperiment) as string,
      accessor: 'BudgetExperimentName',
    },
    {
      Header: t(translations.Remarks) as string,
      accessor: 'Remarks',
      // Cell: DescriptonCell({ width: 30 }),
    },
    {
      Header: t(translations.NumberOfUsages) as string,
      accessor: 'NumberOfUsages',
    },
    {
      Header: t(translations.LastModifiedAt) as string,
      accessor: 'LastModifiedAt',
      Cell: DateTimeRenderer,
    },
    {
      Header: t(translations.WorkflowBookingName) as string,
      accessor: 'WorkflowBookingName',
    },
    {
      Header: t(translations.ServiceGroup) as string,
      accessor: 'ServiceGroup',
    },
    {
      Header: t(translations.BudgetInternalId) as string,
      accessor: 'BudgetInternalId',
    },
    {
      Header: t(translations.RemainingDurationD) as string,
      accessor: 'RemainingDurationD',
    },
    {
      Header: t(translations.BookedById) as string,
      accessor: 'BookedById',
    },
    {
      Header: t(translations.FullDayReservation) as string,
      accessor: 'FullDayReservation',
      Cell: BooleanRenderer,
    },
    {
      Header: t(translations.ADGroup) as string,
      accessor: 'ADGroup',
    },
    {
      Header: t(translations.Tutoring) as string,
      accessor: 'Tutoring',
      Cell: BooleanRenderer,
    },
    {
      Header: t(translations.UserEmail) as string,
      accessor: 'UserEmail',
    },
    {
      Header: t(translations.Loanable) as string,
      accessor: 'Loanable',
      Cell: BooleanRenderer,
    },
    {
      Header: t(translations.TrainingSignUp) as string,
      accessor: 'TrainingSignUp',
      Cell: BooleanRenderer,
    },
    {
      Header: t(translations.BudgetRemarks) as string,
      accessor: 'BudgetRemarks',
    },
    {
      Header: t(translations.UserGroupMail) as string,
      accessor: 'UserGroupMail',
    },
    {
      Header: t(translations.InsertedBy) as string,
      accessor: 'InsertedBy',
    },
    {
      Header: t(translations.LastUpdateAdmin) as string,
      accessor: 'LastUpdateAdmin',
    },
    {
      Header: t(translations.DivisionDescription) as string,
      accessor: 'DivisionDescription',
    },
    {
      Header: t(translations.Division) as string,
      accessor: 'Division',
    },
    {
      Header: t(translations.AssetId) as string,
      accessor: 'AssetId',
    },
    {
      Header: t(translations.BudgetFund) as string,
      accessor: 'BudgetFund',
    },
    {
      Header: t(translations.AssetInternalCode) as string,
      accessor: 'AssetInternalCode',
    },
    {
      Header: t(translations.AssetRoom) as string,
      accessor: 'AssetRoom',
    },
    {
      Header: t(translations.AssetDescription) as string,
      accessor: 'AssetDescription',
    },
    {
      Header: t(translations.AssetModel) as string,
      accessor: 'AssetModel',
    },
    {
      Header: t(translations.AssetModelNumber) as string,
      accessor: 'AssetModelNumber',
    },
    {
      Header: t(translations.TotalReservations) as string,
      accessor: 'TotalReservations',
    },
    {
      Header: t(translations.ServiceType) as string,
      accessor: 'ServiceType',
    },
    {
      Header: t(translations.Institute) as string,
      accessor: 'Institute',
    },
    {
      Header: t(translations.InstituteType) as string,
      accessor: 'InstituteType',
    },
    {
      Header: t(translations.Department) as string,
      accessor: 'Department',
    },
    {
      Header: t(translations.Units) as string,
      accessor: 'Units',
    },
    {
      Header: t(translations.BudgetInitialCost) as string,
      accessor: 'BudgetInitialCost',
      Cell: ({ value }) => {
        if (value) return <> {roundTwoPlacesAfterDecimalPoint(value)} </>;
        return <></>;
      },
    },
    {
      Header: t(translations.BudgetLeader) as string,
      accessor: 'BudgetLeader',
    },
    {
      Header: t(translations.BudgetExternalUpdatedAt) as string,
      accessor: 'BudgetExternalUpdatedAt',
    },
    {
      Header: t(translations.BudgetExternalEndDate) as string,
      accessor: 'BudgetExternalEndDate',
    },
    {
      Header: t(translations.BudgetIsOnline) as string,
      accessor: 'BudgetIsOnline',
      Cell: BooleanRenderer,
    },
    {
      Header: t(translations.BudgetEndDate) as string,
      accessor: 'BudgetEndDate',
      Cell: DateTimeRenderer,
    },
    {
      Header: t(translations.BudgetStartDate) as string,
      accessor: 'BudgetStartDate',
      Cell: DateTimeRenderer,
    },
    {
      Header: t(translations.BudgetHideExperiments) as string,
      accessor: 'BudgetHideExperiments',
      Cell: BooleanRenderer,
    },
    {
      Header: t(translations.BudgetManager) as string,
      accessor: 'BudgetManager',
    },
    {
      Header: t(translations.BudgetSubsProfCostCenter) as string,
      accessor: 'BudgetSubsProfCostCenter',
    },
    {
      Header: t(translations.BudgetSubsProfProjectNumber) as string,
      accessor: 'BudgetSubsProfProjectNumber',
    },
    {
      Header: t(translations.FacilityProject) as string,
      accessor: 'FacilityProject',
    },
    {
      Header: t(translations.AssetCampus) as string,
      accessor: 'AssetCampus',
    },
    {
      Header: t(translations.PartialAttendanceStart) as string,
      accessor: 'PartialAttendanceStart',
    },
    {
      Header: t(translations.PartialAttendanceEnd) as string,
      accessor: 'PartialAttendanceEnd',
    },
    {
      Header: t(translations.BudgetSubsProfActivity) as string,
      accessor: 'BudgetSubsProfActivity',
    },
    {
      Header: t(translations.PartialAttendanceDuration) as string,
      accessor: 'PartialAttendanceDuration',
    },
    {
      Header: t(translations.AssetCategory) as string,
      accessor: 'AssetCategory',
    },
    {
      Header: t(translations.AssetCategoryGroup) as string,
      accessor: 'AssetCategoryGroup',
    },
    {
      Header: t(translations.PartialAttendance) as string,
      accessor: 'PartialAttendance',
      Cell: BooleanRenderer,
    },
    {
      Header: t(translations.BudgetHardLimit) as string,
      accessor: 'BudgetHardLimit',
      Cell: ({ value }) => {
        if (value) return <> {roundTwoPlacesAfterDecimalPoint(value)} </>;
        return <></>;
      },
    },
    {
      Header: t(translations.BudgetSoftLimit) as string,
      accessor: 'BudgetSoftLimit',
      Cell: ({ value }) => {
        if (value) return <> {roundTwoPlacesAfterDecimalPoint(value)} </>;
        return <></>;
      },
    },
    {
      Header: t(translations.BudgetAmount) as string,
      accessor: 'BudgetAmount',
      Cell: ({ value }) => {
        if (value) return <> {roundTwoPlacesAfterDecimalPoint(value)} </>;
        return <></>;
      },
    },
    {
      Header: t(translations.AssetBuilding) as string,
      accessor: 'AssetBuilding',
    },
    {
      Header: t(translations.StaffUser) as string,
      accessor: 'StaffUser',
    },
    {
      Header: t(translations.ServiceActivityCode) as string,
      accessor: 'ServiceActivityCode',
    },
    {
      Header: t(translations.AssetBrandCode) as string,
      accessor: 'AssetBrandCode',
    },
    {
      Header: t(translations.Year) as string,
      accessor: 'Year',
    },
    {
      Header: t(translations.Month) as string,
      accessor: 'Month',
    },
    {
      Header: t(translations.WeekNumber) as string,
      accessor: 'WeekNumber',
    },
    {
      Header: t(translations.UserDiscipline) as string,
      accessor: 'UserDiscipline',
    },
    {
      Header: t(translations.ServiceGroupAdminEmail) as string,
      accessor: 'ServiceGroupAdminEmail',
    },
    {
      Header: t(translations.CollaboratorUsers) as string,
      accessor: 'CollaboratorUsers',
      Cell: ({ value }) => {
        if (value) {
          let result = value.map(item => {
            return (
              <Fragment key={`CollaboratorUser_${Guid.create()}`}>
                <span>{item}</span>
                <br />
              </Fragment>
            );
          });
          if (result) return <>{result}</>;
        }
        return t(translations.NA) as string;
      },
    },
    {
      Header: t(translations.BudgetBudget) as string,
      accessor: 'BudgetBudget',
    },
    {
      Header: t(translations.StartDate) as string,
      accessor: 'StartDate',
      Cell: ({ value }) => <DateLabel value={value} />,
    },
    {
      Header: t(translations.EndDate) as string,
      accessor: 'EndDate',
      Cell: ({ value }) => <DateLabel value={value} />,
    },
    {
      Header: t(translations.BudgetPercent) as string,
      accessor: 'BudgetPercent',
    },
    {
      Header: t(translations.Charges) as string,
      accessor: 'Charges',
      Cell: ({ value, row }) =>
        !!value && value !== null ? (
          <Link
            title={t(translations.Charges)}
            target="_blank"
            href={toRootedURL('/Charges', {
              refType: ReferenceType.Reservation as number,
              refId: row.original.Id,
            })}
          >
            {roundTwoPlacesAfterDecimalPoint(value)}
          </Link>
        ) : null,
    },
  ];
  return result;
}
