import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { IRow } from 'app/components/BasicTable/IRow';
import { Link as MuiLink } from '@material-ui/core';
import { translations } from 'locales/translations';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import {
  AssetIdentifier,
  AssetIdIdentifier,
  AssetServiceIdentifier,
} from 'app/pages/AssetPopUp/components/Utils';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { PrimaryActionDynamic } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { ServiceTypeUnion } from 'api/odata/generated/enums/ServiceType';
import { useSelector } from 'react-redux';
import { selectglobalSettings, selectPublicUrl } from 'app/slice/selectors';
import { buildURL } from 'utils/url-utils';
import { Row } from 'react-table';
import { AssetPopUpProps } from 'app/pages/AssetPopUp/index';
import { pick } from 'lodash';
import { AllowedSettings } from 'utils/globalSettings';

interface AssetLinkIdentifierProps {
  id?: number;
  serviceId?: number;
  serviceType?: ServiceTypeUnion;
}
export type AssetLinkProps = AssetIdentifier & {
  children: React.ReactNode;
  title?: string;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
  openOnNewTab?: boolean;
  expanded?: boolean;
};

export const AssetLink = (props: AssetLinkProps) => {
  const { t } = useTranslation();
  const openAsset = useOpenAssetPopUp();

  const getUrl = useAssetLinkUrl();
  const link = getUrl({
    id: (props as AssetIdIdentifier).id,
    serviceId: (props as AssetServiceIdentifier).serviceId,
    serviceType: (props as AssetServiceIdentifier).serviceType,
  });
  const open = () => {
    openAsset(
      {
        useSidePanel: props.useSidePanel,
        expanded: props.expanded,
        ...(pick(props, 'id', 'serviceId', 'serviceType') as AssetIdentifier),
      },
      props.openPanelWithCover,
    );
  };
  return (
    <Tooltip title={props.title ?? t(translations.ShowAssetSidePanel)}>
      <MuiLink
        onClick={event => {
          if (!!link) {
            if (!event.ctrlKey && !props.openOnNewTab) {
              event.preventDefault();
              open();
            }
          } else {
            open();
          }
        }}
        color="textPrimary"
        target={props.openOnNewTab ? '_blank' : undefined}
        href={link}
      >
        {props.children}
      </MuiLink>
    </Tooltip>
  );
};

export type UseOpenAssetPopUpProps = AssetIdentifier & {
  useSidePanel?: boolean;
  expanded?: boolean;
};
export const useOpenAssetPopUp = () => {
  const { openPanel } = useSidePanelState();
  const globalSettings = useSelector(selectglobalSettings);
  const OpenAssetsInSidePanel = globalSettings?.GetBoolean(
    AllowedSettings[AllowedSettings.OpenAssetsInSidePanel],
  );

  return React.useCallback(
    (
      props: UseOpenAssetPopUpProps,
      openPanelWithCover?: (state: SidePanelOpenState) => void,
    ) => {
      const identifier = props;
      if (identifier !== undefined) {
        const assetPopupProps: AssetPopUpProps = {
          identifier: identifier,
          useSidePanel: props.useSidePanel ?? false,
        };
        if (!!openPanelWithCover) {
          openPanelWithCover({
            renderPageType: RenderPageType.AssetDetails,
            renderPageProps: assetPopupProps,
            expanded: !OpenAssetsInSidePanel,
            useSidePanel: props.useSidePanel,
            isCover: props.useSidePanel,
          });
        } else {
          openPanel({
            renderPageType: RenderPageType.AssetDetails,
            renderPageProps: assetPopupProps,
            expanded:
              props.expanded !== undefined
                ? props.expanded
                : !OpenAssetsInSidePanel,
            useSidePanel: false,
            isCover: false,
          } as SidePanelOpenState);
        }
      }
    },
    [OpenAssetsInSidePanel, openPanel],
  );
};
export const useAssetLinkUrl = () => {
  const publicUrl = useSelector(selectPublicUrl);
  return React.useCallback(
    (params: {
      id?: number;
      serviceId?: number;
      serviceType?: ServiceTypeUnion;
    }) => {
      return buildURL(`${publicUrl}Assets/Details`, params);
    },
    [publicUrl],
  );
};
interface PrimaryActionDynamicRow extends IRow {
  Id?: number;
  serviceId?: number;
  serviceType?: ServiceTypeUnion;
}

//used for Dashboard tadles
export const OpenAssetPrimaryAction: PrimaryActionDynamic<
  PrimaryActionDynamicRow
> = (openPanel, row, toggle, user, useSidePanel) => {
  const identifier = getAssetIdentifier(row);
  if (identifier === undefined) {
    console.error('no asset identifier found for row ', row);
  } else {
    const props: AssetPopUpProps = {
      identifier: identifier,
      useSidePanel: useSidePanel ?? false,
    };
    openPanel({
      renderPageType: RenderPageType.AssetDetails,
      renderPageProps: props,
      useSidePanel: useSidePanel,
      expanded: false,
      isCover: useSidePanel,
    });
  }
};
function getAssetIdentifier(
  row: Row<PrimaryActionDynamicRow>,
): AssetIdentifier | undefined {
  return getAssetLinkIdentifier({
    id: row.original.Id,
    serviceId: row.original.serviceId,
    serviceType: row.original.serviceType,
  });
}

function getAssetLinkIdentifier(
  row: AssetLinkIdentifierProps,
): AssetIdentifier | undefined {
  if (row.id !== undefined) {
    return {
      id: row.id,
    };
  } else if (row.serviceId !== undefined && row.serviceType !== undefined) {
    return {
      serviceId: row.serviceId,
      serviceType: row.serviceType,
    };
  }
}
