import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { TrainingDetailsProps } from 'app/pages/UserTrainings/TrainingDetailsPage/Details';
import { useLocation } from 'react-router-dom';
import { GetRawValue } from 'app/components/BasicTable/types/FilterParam';
import { toRootedURL } from 'utils/url-utils';
export interface GetActionsProps {
  isAdmin?: boolean;
  userName?: string;
  shortView?: boolean;
}

export function GetActions(props: GetActionsProps): PageActionRenderer[] {
  const pageActions: PageActionRenderer[] = [];
  const location = useLocation();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const { t } = useTranslation();
  if (props.isAdmin) {
    pageActions.push((dataCount, refresh) => (
      <TopActionButton
        text={t(translations.AddTraining) as string}
        title={t(translations.menu_AddNewUserTraining) as string}
        icon="plus"
        startIcon="plus"
        isIconButton={props.shortView}
        onClick={() => {
          let params = {
            equId: GetRawValue(new URLSearchParams(location.search), 'eid'),
            fatherId: undefined,
            rid: undefined,
            date: undefined,
            user:
              GetRawValue(new URLSearchParams(location.search), 'un') ??
              props.userName,
            useSidePanel: true,
          } as TrainingDetailsProps;
          dispatch(
            actions.openSidePanel({
              type: RenderPageType.TrainingDetails,
              props: params,
            }),
          );
        }}
      />
    ));
    pageActions.push((dataCount, refresh) => (
      <TopActionButton
        title={t(translations.UserTrainingModificationHistory) as string}
        icon="history"
        startIcon="history"
        variant="white"
        size="small"
        isIconButton={props.shortView}
        href={toRootedURL('/UserTrainingHistory')}
        text={t(translations.UserTrainingModificationHistory) as string}
      />
    ));
  }
  return pageActions;
}
