/**
 *
 * ReservationByIdWithPartsFilter
 *
 */

import { ReservationByIdWithPartsPicker } from 'app/components/pickers/AutocompletePickers/ReservationByIdWithPartsPicker';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export const ReservationByIdWithPartsFilter = (
  props: IFilterComponentProps,
) => {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  return (
    <ReservationByIdWithPartsPicker
      value={props.value}
      onChange={handleOnChange}
      isOpen={props.open}
      ariaLabel={props.label}
      size="small"
      id={props.id}
      predicates={props.predicates}
      fullWidth={props.fullWidth}
      onPickerOpen={props.onOpen}
    />
  );
};
export default ReservationByIdWithPartsFilter;
