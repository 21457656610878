import { UsageReportLink } from 'app/pages/Actions/GlobalSelectedActions/OpenUsageDetails';
import { AssetLink } from 'app/pages/AssetPopUp/OpenAssetDetails';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClosableMessageProps, UsageReservation } from '../slice/type';

export const UsageReservationMessage = (
  props: UsageReservation & ClosableMessageProps,
) => {
  const { t } = useTranslation();
  const { close, Id, Name } = props;
  const assets = (
    <React.Fragment key="asset_space_link">
      <AssetLink serviceId={Id} serviceType="Online">
        {Name}
      </AssetLink>
    </React.Fragment>
  );
  const message = t(translations.IpRelay_reservationTimeForInstrument);
  const messages = message
    .split('{0}')
    .map<React.ReactNode>((f, i) => (
      <span key={'UsageReservation' + i}> {f} </span>
    ))
    .reduce((prev, curr) => [prev, assets, curr]);
  return (
    <>
      {messages}
      <UsageReportLink
        title={t(translations.StartUsage)}
        sid={Id}
        manual={false}
        onClick={close}
      >
        {t(translations.IpRelay_LinkToUsage)}
      </UsageReportLink>
    </>
  );
};
