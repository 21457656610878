import { PayloadAction } from '@reduxjs/toolkit';
import { SubscribersApi } from 'api/SubscribersApi';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ConvertEntityToModelSubscribersDetails,
  SubscribersDetailsQStringParameters,
  SubscribersDetailsResponse,
  SubscribersDetailsState,
} from './types';
import { subscribersDetailsActions as actions } from '.';
import { ISubscribersDto } from 'api/odata/generated/entities/ISubscribersDto';
import { appSettingsActions } from 'app/slice';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { AxiosError } from 'axios';
import { SubscribersDetailsProps } from '..';

function* doInitCreate(
  action: PayloadAction<SubscribersDetailsQStringParameters>,
) {
  try {
    const data = yield call(SubscribersApi.initCreateData, action.payload);
    yield put(actions.initSubscribersDetails_Success(data));
  } catch (error: unknown) {
    yield put(actions.initSubscribersDetails_Error(error));
  }
}

function* doInitUpdate(
  action: PayloadAction<SubscribersDetailsQStringParameters>,
) {
  try {
    const data = yield call(SubscribersApi.initUpdateData, action.payload);
    yield put(actions.initUpdateSubscribersDetails_Success(data));
  } catch (error: unknown) {
    yield put(actions.initUpdateSubscribersDetails_Error(Error));
  }
}

function* doCreate(action: PayloadAction<SubscribersDetailsState>) {
  const httpPayloads: ISubscribersDto = ConvertEntityToModelSubscribersDetails(
    action.payload,
    undefined,
  );
  try {
    const result = yield call(
      SubscribersApi.insertSubscribersDetails,
      httpPayloads,
    );
    let response: SubscribersDetailsResponse =
      result as SubscribersDetailsResponse;

    if (response.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.ErrorMessages.map(item => {
            return {
              key: 'subscribersInsert',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'subscribersInsert',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      if (response.Id) {
        yield put(
          appSettingsActions.addNotification({
            key: 'subscribersInsert',
            message: response.SuccessMessages[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id,
              created: true,
              itemName: i18next.t(translations.menu_Subscribers),
              detailsType: RenderPageType.SubscribersDetails,
              detailsTypeProps: {
                queryParams: {
                  id: '' + response.Id,
                } as SubscribersDetailsQStringParameters,
                useSidePanel: true,
              } as SubscribersDetailsProps,
            },
            variant: 'success',
          }),
        );
        yield put(layoutActions.setRefreshTable(true));
      } else {
        yield put(
          appSettingsActions.addNotification({
            key: 'subscribersInsertSuccess',
            message: response.SuccessMessages[0],
            variant: 'success',
          }),
        );
        yield put(layoutActions.setRefreshTable(true));
      }
    }

    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.createSubscribersDetails_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'subscribersInsert',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createSubscribersDetails_Error(Error));
  }
}

function* doUpdate(
  action: PayloadAction<{
    current: SubscribersDetailsState;
    original: SubscribersDetailsState | undefined;
  }>,
) {
  const httpPayloads: ISubscribersDto = ConvertEntityToModelSubscribersDetails(
    action.payload.current,
    action.payload.original,
  );
  try {
    const results = yield call(
      SubscribersApi.updateSubscribersDetails,
      httpPayloads,
    );
    let response = results as SubscribersDetailsResponse;
    let respErrors = response.ErrorMessages;
    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: 'subscribersUpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'subscribersUpdateWarn',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotification({
          key: 'subscribersUpdate',
          message: response.SuccessMessages[0],
          messageType: SnackBarMessageType.openSidepanelDetails,
          messageTypeProps: {
            Id: response.Id,
            created: false,
            itemName: i18next.t(translations.menu_Subscribers),
            detailsType: RenderPageType.SubscribersDetails,
            detailsTypeProps: {
              queryParams: {
                id: '' + response.Id,
              } as SubscribersDetailsQStringParameters,
              useSidePanel: true,
            } as SubscribersDetailsProps,
          },
          variant: 'success',
        }),
      );
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.updateSubscribersDetails_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'subscribersUpdateErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateSubscribersDetails_Error(Error));
  }
}

export function* subscribersDetailsSaga() {
  yield takeLatest(actions.initSubscribersDetails.type, doInitCreate);
  yield takeLatest(actions.initUpdateSubscribersDetails.type, doInitUpdate);
  yield takeLatest(actions.createSubscribersDetails.type, doCreate);
  yield takeLatest(actions.updateSubscribersDetails.type, doUpdate);
}
