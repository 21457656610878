import { useAssetQuickSearchSlice } from 'app/components/AssetQuickSearch/slice';
import { selectShowSearchInSidebar } from 'app/components/AssetQuickSearch/slice/selectors';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EventsCount } from '../../TopBar/ProfileMenu/EventsCount';
import { SwitchSites } from '../../TopBar/ProfileMenu/SwitchSites';
import { UserMenu } from '../../TopBar/ProfileMenu/UserMenu';
import React from 'react';

export interface UserProfileMobileProps {}
export function UserProfileMobile(props: UserProfileMobileProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions: assetSearchActions } = useAssetQuickSearchSlice();
  const showSearchInSidebar = useSelector(selectShowSearchInSidebar);
  return (
    <React.Fragment>
      <IconButton
        aria-label="search"
        onClick={() => {
          dispatch(assetSearchActions.showSearchInput(!showSearchInSidebar));
        }}
        title={t(translations.SearchAssets)}
      >
        <Icon icon="search" fontSize="20px" />
      </IconButton>
      <EventsCount />
      <SwitchSites />
      <UserMenu />
    </React.Fragment>
  );
}
