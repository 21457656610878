import { Comments } from 'app/components/Comments';
import { useUploadToNewComment } from 'app/components/LargeFilesUpload/Components/useUploadToNewComment';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import styled from 'styled-components';

export interface RunCommentsprops {
  runId: number;
  isAdmin: boolean;
}
export const RunComments = ({ runId, isAdmin }: RunCommentsprops) => {
  const { onCommentAdded } = useUploadToNewComment();
  return (
    <CommentsWrap>
      <Comments
        commentType={CommentReferenceTypes.SampleRun}
        referenceId={runId}
        isAdmin={isAdmin}
        noTitle
        onCommentAdded={onCommentAdded}
      />
    </CommentsWrap>
  );
};
const CommentsWrap = styled('div')`
  padding: 16px;
`;
