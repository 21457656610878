/**
 *
 * LocaleSelector
 *
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Entity } from 'types/common';
import { ListItemText, MenuItem } from '@material-ui/core';
import { appSettingsActions } from 'app/slice';
import {
  selectCurrentLocale,
  selectSupportedLocales,
} from 'app/slice/selectors';
import { useIsMobile } from 'app/hooks/useIsMobile';
import UserMenuButton from 'app/Layout/FrontendLayout/components/TopBar/ProfileMenu/UserMenu/UserMenuButton';
import { StyledUserMenu } from 'app/Layout/FrontendLayout/components/TopBar/styled';
import { useTheme } from '@material-ui/core/styles';
import { Icon } from '../BasicIcons/FontAwesome';

interface LocaleSelectStateProps {
  current?: string;
  options?: Array<Entity<string>>;
}
interface LocaleSelectDispatchProps {
  onClick: (
    event: React.MouseEvent<HTMLLIElement>,
    value: Entity<string>,
  ) => void;
}
interface LocaleSelectorProps
  extends LocaleSelectStateProps,
    LocaleSelectDispatchProps {}
export const LocaleSelector = connect(
  state => ({
    current: selectCurrentLocale(state),
    options: selectSupportedLocales(state),
  }),

  dispatch => ({
    onClick: (event, value) => {
      dispatch(appSettingsActions.setLocale(value.Id));
    },
  }),
)(function LocaleSelector(props: LocaleSelectorProps) {
  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const { isMobile } = useIsMobile();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  if (props.options === undefined) {
    return <></>;
  }
  if (props.options.length === 1) {
    return <></>;
  }

  return (
    <>
      <UserMenuButton
        aria-controls="user-profile-menu"
        aria-haspopup="true"
        onClick={event => handleClick(event)}
        open={open}
        isMobile={false}
        startIcon={<Icon icon="globe" />}
      >
        {props.current}
      </UserMenuButton>
      <StyledUserMenu
        theme={theme}
        marginThreshold={1}
        id="user-profile-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isMobile ? 'right' : 'center',
        }}
        open={Boolean(anchorEl)}
        PaperProps={
          isMobile
            ? {
                style: {
                  width: '100%',
                  height: '100%',
                  maxHeight: 'calc(100% - 32px)',
                  maxWidth: 'calc(100% - 0px)',
                },
              }
            : undefined
        }
        onClose={() => handleClose()}
      >
        {props.options?.map(item => (
          <MenuItem key={item.Id} onClick={event => props.onClick(event, item)}>
            <ListItemText>{item.Name}</ListItemText>
          </MenuItem>
        ))}
      </StyledUserMenu>
    </>
  );
});
