import * as React from 'react';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { RadioGroup, RadioGroupProps } from '../RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText, FormLabel } from '@material-ui/core';
import { useIsRequired } from './ValidationSchemaProvider';
import BasicTypography from '../Typography/BasicTypography';
import clsx from 'clsx';
import { InfoIcon } from '../BasicIcons/InfoIcon';

export type FormRadioGroupProps = FieldHookConfig<string> & RadioGroupProps;
export const FormRadioGroup = ({
  id,
  label,
  onChange,
  value,
  name,
  error,
  title,
  helperText,
  info,
  required,
  ...props
}: FieldHookConfig<string> & RadioGroupProps) => {
  const [field, meta] = useField<string>({ name, ...props });
  const handleChange = (value, event) => {
    if (onChange !== undefined) {
      onChange(value, event);
    }
    field.onChange(event);
  };
  const { submitCount } = useFormikContext();
  const isRequired = useIsRequired(name);
  const hasError = meta?.error !== undefined;
  const showError = hasError && (meta.touched || submitCount > 0);
  return (
    <FormControl
      className={'radio-group-base'}
      required={isRequired || required}
      error={showError}
    >
      <FormLabel
        className={clsx('radio-group-base-label', { withTitle: !!title })}
        required={isRequired || required}
      >
        {label} {info && <InfoIcon title={info} />}
      </FormLabel>
      {title && (
        <BasicTypography variant="bodyS" italic>
          {title}
        </BasicTypography>
      )}
      <RadioGroup
        id={id}
        name={name}
        label={label}
        onChange={handleChange}
        value={field.value ?? ''}
        disabled={props.disabled}
        {...props}
      />
      <FormHelperText required={isRequired || required} error={showError}>
        <span>{showError ? meta.error : helperText}</span>
      </FormHelperText>
    </FormControl>
  );
};
