import * as React from 'react';
import { CircularProgress, CircularProgressProps } from '@material-ui/core';

export interface ProgressProps extends CircularProgressProps {
  inProgress: boolean;
}
export function Progress({ inProgress, size, ...props }: ProgressProps) {
  return (
    <React.Fragment>
      {inProgress ? (
        <CircularProgress
          color="inherit"
          size={size}
          className="base-progress"
          {...props}
        />
      ) : null}
    </React.Fragment>
  );
}
