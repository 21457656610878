import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Column } from 'react-table';
import { AppSettings } from 'types/AppSettings';
import { IDocumentsDirectoryRow } from './IDocumentsDirectoryRow';
import { GlobalSettings } from 'utils/globalSettings';
import AssetRenderer from 'app/components/BasicTable/CellRenderers/AssetRenderer';
import FileNameRenderer from 'app/components/BasicTable/CellRenderers/FileNameRenderer';

const Available = val => {
  if (val === true) {
    return translations.StaffUsersFiles;
  }
  if (val === false) {
    return translations.StaffOnlyFiles;
  }
  return translations.PublicFiles;
};

export const getColumns = (
  t: TFunction,
  appSettings?: AppSettings,
  globalSettings?: GlobalSettings,
): Column<IDocumentsDirectoryRow>[] => {
  return [
    {
      Header: t(translations.FileName) as string,
      accessor: 'FileName',
      //check if the link should be avalibale or not
      //logick not finale
      Cell: FileNameRenderer,
    },
    {
      Header: t(translations.Title) as string,
      accessor: 'Title',
    },
    {
      Header: t(translations.Description) as string,
      accessor: 'Description',
    },
    {
      Header: t(translations.DocumentType) as string,
      accessor: 'DocumentType',
    },
    {
      Header: t(translations.FileType) as string,
      accessor: 'FileType',
    },
    {
      Header: t(translations.ServiceGroup) as string,
      accessor: 'ServiceGroup',
    },
    {
      Header: t(translations.AssetName) as string,
      accessor: 'AssetName',
      Cell: AssetRenderer,
    },
    {
      Header: t(translations.AvailableToUsers) as string,
      accessor: 'AvailableToUsers',

      Cell: ({ row }) => <>{t(Available(row.original.AvailableToUsers))}</>,
    },
  ];
};
