import { PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from 'api/HttpClient';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { internalIdLoginActions as actions } from '.';
import { IAuthenticatedUser } from 'types/AuthenticatedUser';
import { LoginTypes } from 'app/slice/types';
import { Roles } from 'api/odata/generated/enums/Roles';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { isNullOrUndefined } from 'utils/typeUtils';

const SESSIONSTORAGE_KEY = 'internalIdLogin';
function storeInternalIdLogin(item: IAuthenticatedUser | null): void {
  if (item === null) {
    sessionStorage.removeItem(SESSIONSTORAGE_KEY);
  } else {
    sessionStorage.setItem(SESSIONSTORAGE_KEY, JSON.stringify(item));
  }
}
function loadInternalIdLogin(): IAuthenticatedUser | null {
  const s = sessionStorage.getItem(SESSIONSTORAGE_KEY);
  if (s === null) {
    return null;
  }
  const d = JSON.parse(s);
  const f = d as IAuthenticatedUser;
  return f;
}
function* doInit(action: PayloadAction<{ isLargeAnimals: boolean }>) {
  // /try get identity stored in sessionstorage (saved during internalid entry)
  try {
    const storedIdentity: IAuthenticatedUser | null = yield call(
      loadInternalIdLogin,
    );
    if (storedIdentity !== null) {
      yield put(actions.success(storedIdentity));
      yield put(actions.init_success());
      return;
    }
  } catch (error: unknown) {
    console.error('ap init error', error);
  }

  // simulate internalid login for technicians so that they'll be able to enter the Kiosk cases list/details
  const user = yield select(selectAuthenticatedUser);
  if (!isNullOrUndefined(user)) {
    if (
      action.payload.isLargeAnimals &&
      user.Roles.indexOf(Roles.LargeAnimalsOperatingRoomTechnician) >= 0
    ) {
      yield put(actions.success(user));
      yield put(actions.init_success());
      return;
    }
  }

  yield put(actions.init_success());
}
function* doLogin(
  action: PayloadAction<{ internalId: string; General: boolean }>,
) {
  const httpPayload = {
    internalId: action.payload.internalId,
    General: action.payload.General,
  };
  try {
    // send authentication request
    const requestURL = '/api/account/internalidlogin';
    const result = yield call(httpClient.post, requestURL, httpPayload);
    result.LoginType = LoginTypes.InternalId;
    //store authentication result in session storage
    yield call(storeInternalIdLogin, result);
    yield put(actions.success(result));
  } catch (err) {
    yield put(actions.error(err));
  }
}
function* doRedirectToLogin() {
  yield call(storeInternalIdLogin, null);
  yield put(push('/internalidlogin'));
}
export function* internalIdLoginSaga() {
  yield takeLatest(actions.login.type, doLogin);
  yield takeLeading(actions.redirectToLogin, doRedirectToLogin);
  yield takeLeading(actions.logout, doRedirectToLogin);
  yield takeLatest(actions.init.type, doInit);
}
