import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { PrimaryActionDynamic } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { IArticleDto } from 'api/odata/generated/entities/IArticleDto';
import { ArticleDetailsProps } from './ArticleDetails';

export const articlePrimaryAction: PrimaryActionDynamic<IArticleDto> = (
  openPanel,
  row,
  toggle,
  user,
  useSidePanel,
) => {
  openPanel({
    renderPageType: RenderPageType.Article,
    renderPageProps: {
      value: row.original,
      Id: row.original.Id,
      externalId: row.original.ExternalId,
      externalIdSource: row.original.ExternalIdSource,
      useSidePanel: true,
    } as ArticleDetailsProps,
    useSidePanel: useSidePanel,
    expanded: false,
    isCover: useSidePanel,
  });
};

export default articlePrimaryAction;
