import { useMemo } from 'react';
import { Entity } from 'types/common';
import { toRootedURL } from 'utils/url-utils';
import { Body } from '../Typography';
import { Avatar as MuiAvatar } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

export interface UserAvatarProps {
  user?: Entity<string> | null;
}
export const UserAvatar = ({ user }: UserAvatarProps) => {
  const { t } = useTranslation();
  const initials = useMemo(() => {
    const defaultText = t(translations.NA);
    if (user === null) {
      return defaultText;
    }
    if (user === undefined) {
      return defaultText;
    }
    const split = user.Name.split(' ');
    return (split[0][0] + (split[1] ? split[1][0] : '')).toUpperCase();
  }, [t, user]);

  const Id = user?.Id ?? undefined;
  return (
    <MuiAvatar
      className={clsx('user-avatar', {
        pointer: Id !== undefined,
      })}
    >
      <span
        onClick={() => {
          if (Id !== undefined) {
            window.open(toRootedURL('/Users/Profile?id=' + Id), '_blank');
          }
        }}
      >
        <Body
          bold={true}
          //className={clsx({ [classes.withPointer]: Id !== undefined })}
          color="textSecondary"
        >
          {initials}
        </Body>
      </span>
    </MuiAvatar>
  );
};
