export enum KnownModules {
  Default = 0,
  Assets = 1,
  BudgetsSyncDoNotDeactivateMissingBudgets = 2,
  AccServices = 3,
  Quizzes = 4,
  AllowOngoingReservationModification = 5,
  AssetBarcodePrinting = 6,
  AssetDetailsDisplayContactsRelatedOnly = 7,
  AutomatedEndTimeUpdate = 8,
  AutomaticEmailSending = 9,
  AutomaticMonthlyPaymentLetter = 10,
  Billing = 11,
  Blitz = 12,
  WebRelayTm = 13,
  WebRelayX600m = 14,
  NiDAQmx = 15,
  KmTronicRelay = 16,
  Budgets = 17,
  BudgetCats = 18,
  BudgetLimits = 19,
  Cages = 20,
  Colonies = 21,
  ConfigurableColumns = 22,
  CoreWizard = 23,
  CustmerBranding = 25,
  CreditByQuarter = 26,
  CustomFormLinks = 27,
  CustomRoles = 28,
  Dashboards = 29,
  DashboardAnnouncement = 30,
  DataStorage = 31,
  DataStoreCharge = 32,
  Departments = 33,
  Divisions = 35,
  Documents = 36,
  DoorAccess = 37,
  DoorAccessTibboController = 38,
  DoorAccessImport = 39,
  Eln /*Projects/Experiments*/ = 40,
  Experiment = 41,
  EmailCommunicationWithUsers = 42,
  EquipmentAssemblies = 43,
  EventsBillingByEndDate = 44,
  EventsBillingByStartDate = 45,
  Expenses = 46,
  Factors = 47,
  FactorsByInstTypes = 48,
  FactorsByUserGroups = 49,
  FinAdminRole = 50,
  FixedRate = 51,
  ForgotPassword = 52,
  Genomics = 53,
  GroupPermissions = 54,
  GroupTrainingBookings = 55,
  GoogleCalendar = 56,
  HideInstrumentsFromUntrainedUsers = 57,
  HideNonDefaultBudgets = 58,
  HeaderStyle = 59,
  IdleTimeOut = 60,
  InstituteCoordRole = 61,
  Instruments = 62,
  Invoices = 63,
  IpRelay = 64,
  JoinAdGroup = 65,
  //JoinAdGroupText = 66,
  JoinAdGroupGenerate = 67,
  LabTechRole = 68,
  LargeAnimals = 69,
  LocalApp = 70,
  LocationTracking = 71,
  MaintenanceAssets = 72,
  Messages = 73,
  MultipleAdGroups = 74,
  Newsletter = 75,
  OfflineFactors = 76,
  OfflineServiceWithReservation = 77,
  OfflineServices = 78,
  OnlineBudgets = 79,
  PaymentsByBudgets = 80,
  PaymentsManagement = 81,
  Pricing = 84,
  ProjectQuotes = 85,
  Projects = 86,
  QuickbooksExport = 87,
  Quotes = 88,
  Relay = 89,
  RepAdminRole = 90,
  RequestedServiceLinesInwokradditionalstatuses = 91,
  RequireAssetRoomAndBuilding = 93,
  ResCancelNotifications = 94,
  ReservationNotification = 95,
  ReservationShrink = 96,
  ReservationTakeOver = 97,
  ReservationsCustomForm = 99,
  RetroReservations = 101,
  RetroReservationsAdminAsUser = 102,
  SAP_Billing = 103,
  SAP_NRC_Billing = 104,
  SAP_SFTP = 105,
  SampleManagementAutoProcessRequests = 106,
  SampleManagementTables = 107,
  SampleProcessing = 108,
  SampleRequestPrintLabels = 109,
  SampleRequestQuote = 110,
  SampleRequestResultFiles = 111,
  Sensors = 112,
  ServiceRequests = 113,
  RequestMilestones = 114,
  ShibbolethAuthentication = 115,
  ShibbolethExternalBudgets = 116,
  ShowLicenseScreen = 117,
  SkipPendingReservationCharge = 118,
  SkipReservationBudgetValidation = 119,
  SystemSettings = 120,
  OAuth = 121,
  Suppliers = 122,
  TempUsers = 123,
  TimeZone = 124,
  Timeline = 125,
  TrainingExpiration = 126,
  Tutoring = 127,
  TutoringHours = 128,
  Undergraduate = 129,
  UsageForm = 130,
  UsageTutoring = 131,
  UserBudgets = 132,
  UserCanExpire = 133,
  UserGroupCoordRole = 134,
  UserGroupSelfAssign = 135,
  UserGroupsAssignment = 136,
  UserPermission = 137,
  WS_Billing = 138,
  WeeklyCalendar = 139,
  WindowsAuthentication = 140,
  ShowAvailableNotBookableAssetsOnDashboard = 142,
  EnableWorkflowExperiments = 143,
  MultipleInstruments_AddRemoveOnEditReservation = 144,
  PriceSheetPricing = 145,
  PriceSheetFeeTypes = 146,
  BudgetExperiments = 147,
  Location = 148,
  ServiceGroupRegistration = 149,
  ReportsGenerator = 150,
  AssetDecommission = 151,
  //ShowBudgetsScreenFromUsers = 152,
  CloningInstrumentsAssets = 153,
  InstituteProjects = 154,
  OfflineServiceBookedBy = 155,
  TermsOfUseAgreement = 156,
  TrainingLevelPermissions = 157,
  TrainingPrograms = 158,
  SourceAndLabelSampleProccessing = 159,
  LocalAppRemoteControl = 160,
  LoanDesk = 161,
  VisualLogBook = 162,
  BiWeeklyWorkingHoursPeriods = 163,
  ReservationsImport = 164,
  AllowSGAToControlSingleEquipment = 166,
  MultipleCustomForms = 167,
  UserInquiry = 168,
  ProjectJoinRequests = 169,
  DTUStudentCardIntergration = 170,
  AssetModels = 171,
  PriceSheetUserGroups = 172,
  PeopleSoftBilling = 173,
  DisableBillingEvents = 174,
  InstrumetServiceProjectCode = 175,
  RaspberryPi = 176,
  SamplesAutomation = 177,
}
