import { ConfigurableColumns } from 'app/components/BasicTable/ConfigurableColumns';
import { ImportExportApi } from './Api';
import {
  ImportAdditionalData,
  ImportData,
  ImportReservationData,
  TemplateColumns,
} from './Types';

const Api = new ImportExportApi();
export const ExportImportActions = {
  Export: (url: string, data?: any) => {
    return Api.export(url, data);
  },
  Import: (url: string, file, data?: ImportData) => {
    return Api.import(url, file, data);
  },
  TemplateColumns: (
    screenId: number,
    Iad: ImportAdditionalData = ImportAdditionalData.none,
    data: ImportReservationData,
  ) => {
    return Api.TemplateColumns<TemplateColumns>(screenId, Iad, data);
  },
  ConfigurableColumns: (
    url: string,
    listId: number,
    configurableType: number,
  ) => {
    return Api.ConfigurableColumns<ConfigurableColumns>(
      url,
      listId,
      configurableType,
    );
  },
  ImportTemplate: (
    screenId: number,
    ext: string,
    Iad: ImportAdditionalData = ImportAdditionalData.none,
    data: ImportReservationData,
  ) => {
    return Api.ImportTemplate(screenId, ext, Iad, data);
  },
  ExportForImport: (
    ScreenId: number,
    Ids: number[],
    Iad: ImportAdditionalData = ImportAdditionalData.none,
  ) => {
    return Api.ExportForImport(ScreenId, Ids, Iad);
  },
  ImportImages: (files: File[], ScreenId: number) => {
    return Api.importImages(files, ScreenId);
  },
};
