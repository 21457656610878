/**
 *
 * PaymentMethodEnumPicker
 *
 */

import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import { PaymentMethods } from 'app/pages/OnlineBudgetsStatusPage/slice/types';
import { Entity } from 'types/common';
import { enumToEntityArray } from 'utils/enumKeys';

export interface PaymentMethodEnumPickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {}

export const getSinglePaymentMethod = (val: number): Entity<number> => {
  return enumToEntityArray(PaymentMethods).filter(item => {
    return item.Id === val;
  })[0];
};

export const initPaymentMethodData = (
  initval: number | undefined,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([getSinglePaymentMethod(initval)]);
    });
  }
};

const getOptions = (searchTerm: string | null) => {
  let items = enumToEntityArray(PaymentMethods);
  if (searchTerm !== null && searchTerm !== '') {
    return items.filter(
      f => f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
    );
  }
  return items;
};

export function PaymentMethodEnumPicker(props: PaymentMethodEnumPickerProps) {
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      value={props.value}
      {...props}
    />
  );
}
