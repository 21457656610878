import { IOfflineServiceTypeRefsDto } from 'api/odata/generated/entities/IOfflineServiceTypeRefsDto';
import {
  IOfflineTypeRefsModel,
  OfflineTypeRefsQueryParams,
} from './slice/types';

import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Progress } from 'app/components/LoadingIndicator';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { selectHasNotSavedChanges } from 'app/Layout/FrontendLayout/slice/selectors';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import { Roles } from 'api/odata/generated/enums/Roles';
import { push } from 'connected-react-router';
import { FormikProps } from 'formik';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { buildURL, toQueryString } from 'utils/url-utils';
import {
  selectDetails,
  selectDetailsCompleted,
  selectDetailsHasError,
  selectIsEdit,
  selectProcessing,
} from './slice/selectors';
import { Beforeunload } from 'react-beforeunload';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';
import { useOfflineTypeRefsSlice } from './slice';
import useSidePanelState, {
  SidePanelCloseState,
} from 'app/hooks/useSidePanelOpen';
import { Div100 } from 'app/components/DownloadFileButton';
import { OfflineTypeRefsForm } from './OfflineTypeRefsForm';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';

export interface OfflineTypeReferencesDetailsProps
  extends SidePanelContentProps,
    CoverProps {
  queryParams: OfflineTypeRefsQueryParams;
  initialDetails?: IOfflineServiceTypeRefsDto;
  onCloseRefs?: () => void;
}

export const OfflineTypeReferencesDetails = React.memo(
  function OfflineTypeReferencesDetails(
    props: OfflineTypeReferencesDetailsProps,
  ) {
    const {
      queryParams,
      useSidePanel,
      initialDetails,
      closeCover,
      isCover,
      onCloseRefs,
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { actions } = useOfflineTypeRefsSlice();
    const { actions: layoutActions } = useLayoutSlice();

    /// Selectors ///
    const processing = useSelector(selectProcessing);
    const refsDetails = useSelector(selectDetails);
    const User = useSelector(selectAuthenticatedUser);
    const hasChanges = useSelector(selectHasNotSavedChanges);
    const edit = useSelector(selectIsEdit);
    const publicUrl = useSelector(selectPublicUrl);
    const actionCompleted = useSelector(selectDetailsCompleted);
    const hasError = useSelector(selectDetailsHasError);
    /// Refs ///
    const submitFormRef = React.useRef<any>(null);
    const innerFormRef = React.useRef<FormikProps<IOfflineTypeRefsModel>>(null);

    const { cover, closePanel, coverClosed, onCloseCover } = useSidePanelState(
      () => {
        dispatch(actions.resetDetailsState());
        if (!!onCloseRefs) {
          return false;
        }
        return true;
      },
      undefined,
      useSidePanel,
      isCover,
    );
    const [firstLoad, setFirstLoad] = React.useState<boolean | undefined>(true);
    const [busy, setBusy] = React.useState<boolean | undefined>(false);

    const [requestCompleted, setRequestCompleted] = React.useState<
      boolean | undefined
    >(undefined);
    const fullAdmin = React.useMemo(() => {
      return User !== undefined && User.Roles.includes(Roles.Administrators);
    }, [User]);
    const IsAdmin = React.useMemo(() => {
      return (
        fullAdmin ||
        User?.HasAdminGroupPermissions(
          refsDetails?.data?.RefService?.ServiceGroupId ?? undefined,
        )
      );
    }, [fullAdmin, User, refsDetails?.data?.RefService?.ServiceGroupId]);

    React.useEffect(() => {
      let active = firstLoad;
      if (active) {
        setFirstLoad(false);
        dispatch(
          actions.initDetails({
            query: queryParams,
            model: initialDetails,
          }),
        );
      }
      return () => {
        active = false;
      };
    }, [actions, dispatch, firstLoad, initialDetails, queryParams]);
    /// functions, callbacks ///

    const handleCloselClick = React.useCallback(() => {
      if (useSidePanel) {
        if (!!onCloseRefs) {
          onCloseRefs();
          dispatch(actions.resetDetailsState());
        } else {
          closePanel({
            isCover: isCover || !!cover,
            useSidePanel: useSidePanel,
            showConfirm: hasChanges,
            onClose: () => {
              dispatch(actions.resetDetailsState());
            },
          } as SidePanelCloseState);
        }
      } else {
        dispatch(push('/ServiceRelatedRefs'));
      }
    }, [
      actions,
      closePanel,
      cover,
      dispatch,
      hasChanges,
      isCover,
      onCloseRefs,
      useSidePanel,
    ]);

    const handleSaveClick = React.useCallback((e: any) => {
      handleSubmitForm(e);
    }, []);

    const handleSubmitForm = (e: any) => {
      if (submitFormRef.current) {
        submitFormRef.current(e);
      }
    };
    const bindSubmitForm = React.useCallback(submitForm => {
      submitFormRef.current = submitForm;
    }, []);

    const handleSubmit = React.useCallback(
      (values: IOfflineTypeRefsModel) => {
        setBusy(true);
        if (edit) {
          dispatch(
            actions.updateRefs({
              current: values,
              original: refsDetails.data || values,
            }),
          );
        } else {
          dispatch(actions.createRefs(values));
        }
      },
      [actions, dispatch, edit, refsDetails.data],
    );
    /// Use Effects ///
    React.useEffect(() => {
      let active = processing === false;
      if (active) {
        // if (queryParams.id !== undefined && !isNaN(parseInt(queryParams.id))) {
        //   setEdit(true);
        // } else {
        //   setEdit(false);
        // }
        if (actionCompleted === true) {
          setRequestCompleted(true);
          setBusy(false);
          if (!hasError) {
            dispatch(layoutActions.setNotSavedChanges(false));
            handleCloselClick();
          }
        } else if (actionCompleted === false) {
          setRequestCompleted(undefined);
          setBusy(false);
        }
      }
      return () => {
        active = false;
      };
    }, [
      actionCompleted,
      dispatch,
      handleCloselClick,
      hasError,
      layoutActions,
      processing,
      queryParams.id,
    ]);

    const leftActions = React.useMemo(() => {
      let actions: ActionRenderer[] = [];
      actions.push(() => (
        <Button
          size="small"
          startIcon={<Icon icon="save" />}
          onClick={handleSaveClick}
          disabled={busy || !IsAdmin}
          processing={!requestCompleted && busy}
        >
          {t(translations.Save)}
        </Button>
      ));
      return actions;
    }, [IsAdmin, busy, handleSaveClick, requestCompleted, t]);
    const rightActions = React.useMemo(() => {
      return [
        () => (
          <React.Fragment>
            <Button
              variant="ghost"
              size="small"
              startIcon={<Icon icon="times" />}
              onClick={handleCloselClick}
            >
              {t(translations.Close)}
            </Button>
          </React.Fragment>
        ),
      ] as ActionRenderer[];
    }, [handleCloselClick, t]);
    const titlePage = React.useMemo(() => {
      return refsDetails?.data
        ? ` ${t(translations.RelatedService)} to ${
            refsDetails?.data.RefType?.Name ||
            innerFormRef?.current?.values?.RefType?.Name
          }`
        : undefined;
    }, [refsDetails?.data, t]);
    return (
      <React.Fragment>
        <PageWrapper
          pageName={
            edit
              ? t(translations.menu_OfflineTypeRefernces)
              : t(translations.menu_OfflineTypeRefernces)
          }
          titlePage={titlePage}
          loading={processing}
          useSidePanel={useSidePanel}
          closable={true}
          disableExpandToggle={isCover}
          closeSidePanel={handleCloselClick}
          //leftTopActions={topButtons}
          //leftTopActions={topActions}
          //topProcessing={equipmentChangeCompleted === false}
          leftActions={leftActions}
          children={
            refsDetails?.data !== undefined && processing === false ? (
              <OfflineTypeRefsForm
                initialValues={
                  refsDetails?.data || ({} as IOfflineTypeRefsModel)
                }
                bindSubmitForm={bindSubmitForm}
                onSubmit={handleSubmit}
                isEdit={edit || false}
                isAdmin={IsAdmin || false}
                user={User}
                innerFormRef={innerFormRef}
              />
            ) : (
              <React.Fragment>
                <Div100>
                  <Progress inProgress={processing || false} size={80} />
                </Div100>
              </React.Fragment>
            )
          }
          pageLink={buildURL(
            publicUrl + 'ServiceRelatedRefs/details',
            queryParams,
          )}
          toPageLink={`ServiceRelatedRefs/details?${toQueryString(
            queryParams,
          )}`}
          rightActions={rightActions}
          isCover={isCover}
          cover={cover}
          closeCover={!isCover ? onCloseCover : closeCover}
          coverClosed={coverClosed}
        />
        {hasChanges && (
          <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
        )}
      </React.Fragment>
    );
  },
);
