import { ILabelTypeDto } from 'api/odata/generated/entities/ILabelTypeDto';
import { IFileResult, ILabelPrinter } from '.';
import { LabelPrinter } from '../slice/types';
import { getQrUrl } from './getQrUrl';

const generateLabel = (labelXml, assetData, AssetBarcodeUrlTemplate) => {
  var label = globalThis.dymo.label.framework.openLabelXml(labelXml);
  var objectMapping = getObjectMapping(label, AssetBarcodeUrlTemplate);
  Object.keys(objectMapping).forEach(function (objectName) {
    var writeData = objectMapping[objectName](assetData);
    if (objectName === 'Name') {
      var splittedData = writeData.split('-');
      writeData = splittedData[0];
      if (splittedData.length > 1) {
        writeData += '-' + splittedData[1];
      }
    }
    if (objectName === 'Room') {
      if (assetData.LocationName) writeData += ', ' + assetData.LocationName;
      if (assetData.SubLocationName)
        writeData += ', ' + assetData.SubLocationName;
    }
    console.log('writeData', writeData);
    label.setObjectText(objectName, writeData);
  });
  return label;
};
const getObjectMapping = (label, AssetBarcodeUrlTemplate) => {
  var staticObjectNames = ['CompanyLogo', 'StaticText'];
  return label.getObjectNames().reduce(function (previousValue, currentValue) {
    var nameParts = currentValue.split('_');
    var assetPropertyName = nameParts[0];
    if (staticObjectNames.indexOf(assetPropertyName) >= 0) {
      return previousValue;
    }
    if (assetPropertyName.toLowerCase() === 'qr') {
      if (nameParts.length > 1 && nameParts[1].toLowerCase() === 'text') {
        previousValue[currentValue] = function (assetData) {
          return getQrUrl(assetData, 'Asset', AssetBarcodeUrlTemplate);
        };
      }
    } else {
      previousValue[currentValue] = function (assetData) {
        var value = getValue(assetData, currentValue);
        if (value === null || value === undefined) {
          return 'N/A';
        } else {
          return value.toString();
        }
      };
    }
    return previousValue;
  }, {});
};
function getValue(obj, path) {
  var properties = path.split(/[.[\]]+/);
  while (properties.length) {
    var property = properties.shift();
    obj = obj[property];
    if (obj === null || obj === undefined) {
      return null;
    }
  }
  return obj;
}

export const Dymo: ILabelPrinter = {
  getPrinters: () => {
    const dymoLabelPrinterOption = printer => ({
      Id: printer.name,
      Name: `Dymo - ${printer.name}`,
      Type: 'Dymo',
      Device: printer,
    });
    return initDymoFramework()
      .then(p => {
        return globalThis.dymo.label.framework.getLabelWriterPrintersAsync();
      })
      .then(dymoPrinters => {
        const result = dymoPrinters.map(dymoLabelPrinterOption);
        return result;
      });
  },

  preview: (
    printer: LabelPrinter,
    labelType: ILabelTypeDto,
    data: any[],
    options,
  ) => {
    return new Promise((resolve, reject) => {
      const result: IFileResult[] = [];
      try {
        for (const assetData of data) {
          var label = generateLabel(
            labelType.Xml,
            assetData,
            options.AssetBarcodeUrlTemplate,
          );
          var labelPreview = 'data:image/png;base64,' + label.render();
          var blob = dataURItoBlob(labelPreview);
          result.push({
            fileName: `${assetData.Name}.png`,
            content: blob,
          });
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  },
  download: (
    printer: LabelPrinter,
    labelType: ILabelTypeDto,
    data: any[],
    options,
  ) => {
    return new Promise((resolve, reject) => {
      try {
        const result: any[] = [];
        for (const assetData of data) {
          var label = generateLabel(
            labelType.Xml,
            assetData,
            options.AssetBarcodeUrlTemplate,
          );
          var blob = new Blob([label.getLabelXml()], { type: 'text/xml' });

          result.push({
            fileName: `${assetData.Name}.label`,
            content: blob,
          });
        }
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  },
  print: (
    printer: LabelPrinter,
    labelType: ILabelTypeDto,
    data: any[],
    options,
  ) => {
    return new Promise((resolve, reject) => {
      try {
        var printParams =
          globalThis.dymo.label.framework.createLabelWriterPrintParamsXml({
            twinTurboRoll:
              globalThis.dymo.label.framework.TwinTurboRoll[
                labelType.twinTurboRoll ?? 'Left'
              ],
          });

        for (var index = 0; index < data.length; index++) {
          var assetData = data[index];
          var label = generateLabel(
            labelType.Xml,
            assetData,
            options.AssetBarcodeUrlTemplate,
          );
          label.print(printer.Id, printParams);
        }
        resolve(null);
      } catch (err) {
        reject(err);
      }
    });
  },
};
const initDymoFramework: () => Promise<any> = () => {
  return new Promise((resolve, reject) => {
    const framework = globalThis.dymo.label.framework;

    framework.init(p => {
      if (!!p.errorDetails) {
        reject(p.errorDetails);
      } else {
        resolve(framework);
      }
    });
  });
};

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}
