/**
 *
 * ExternalLink
 *
 */
import * as React from 'react';
import {
  Link as MuiLink,
  LinkBaseProps as MuiLinkProps,
} from '@material-ui/core';
import { IPathAndQuery, toQueryString, toRootedURL } from 'utils/url-utils';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import { useDispatch } from 'react-redux';
import { useAppSettingsSlice } from 'app/slice';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Tooltip } from '../BasicTooltips/Tooltip';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { publicNS } from 'locales/i18n';

export interface ExternalLinkProps extends Omit<MuiLinkProps, 'href'> {
  href: string | IPathAndQuery;
  /**
   * Show external URL icon with copy url functionality
   */
  showIcon?: boolean;
  /**
   * used in case when need to use URL without adding root of the installation so to avoid toRootedURL() method.
   */
  useInlineUrl?: boolean;
  to?: string | IPathAndQuery;
}

/**
 * External link - opens in a new tab/window
 * This should be used for all external links and should be clear that it leads to a resource outside of the system
 * @param props
 * @returns
 */
export const ExternalLink = React.forwardRef(function ExternalLink(
  props: ExternalLinkProps,
  ref,
) {
  return <LinkBehavior ref={ref} {...props} target="_blank" />;
});

/**
 * Link to a resource inside the larger system but external to the React app
 * Renders <a href="path" ...
 * @param param0
 * @returns
 */
export function Link(props: ExternalLinkProps) {
  return <LinkBehavior {...props} />;
}

const ExternalLinkRoot = styled.span`
  &.external-link-root {
    white-space: nowrap;
  }
`;
/**
 * Link that can accept forwarded ref prop. Usefull in the "override component" in the material ui
 * Usage example: <ListItem component={LinkBehavior} href={'/Reports/OperatingRoomProceduresSummary.aspx'} ...
 */
export const LinkBehavior = React.forwardRef<any, ExternalLinkProps>(
  function LinkBehavior(props, ref) {
    const { href, to, useInlineUrl, ...rest } = props;
    const history = useHistory();
    const emptyLink = href === '';
    const absolutePath =
      typeof href === 'string'
        ? useInlineUrl === true
          ? href
          : toRootedURL(href)
        : toRootedURL(
            (href as IPathAndQuery).path,
            (href as IPathAndQuery).search,
          );
    const localPath =
      to !== undefined
        ? typeof to === 'string'
          ? to
          : (to as IPathAndQuery).path +
            '?' +
            toQueryString((to as IPathAndQuery).search)
        : to;
    const { t } = useTranslation(publicNS);
    const dispatch = useDispatch();
    const { actions } = useAppSettingsSlice();
    const handleLocalPathClick = React.useCallback(() => {
      if (localPath !== undefined) {
        history.push('');
        history.replace(localPath);
      }
    }, [history, localPath]);
    const handleIconClick = () => {
      clipboardWrite(absolutePath).then(() =>
        dispatch(
          actions.addNotification({
            variant: 'success',
            message: `${t(translations.URLCopied)} - ${absolutePath}`,
          }),
        ),
      );
    };
    if (props.showIcon === true) {
      return (
        <ExternalLinkRoot className={'external-link-root'}>
          <MuiLink
            className={'base-link'}
            ref={ref}
            href={
              to === undefined
                ? emptyLink
                  ? undefined
                  : absolutePath
                : undefined
            }
            // href={
            //   onClick ? undefined : to === undefined ? absolutePath : undefined
            // }
            onClick={event => {
              if (to !== undefined) {
                handleLocalPathClick();
              }
              if (!!props.onClick) {
                props.onClick(event);
              }
            }}
            {...rest}
          />
          <Tooltip title={t(translations.CopyURL)}>
            <span>
              <IconButton onClick={handleIconClick}>
                <Icon icon="external-link" />
              </IconButton>
            </span>
          </Tooltip>
        </ExternalLinkRoot>
      );
    } else {
      return (
        <MuiLink
          className={'base-link'}
          ref={ref}
          href={
            to === undefined
              ? emptyLink
                ? undefined
                : absolutePath
              : undefined
          }
          onClick={event => {
            if (to !== undefined) {
              handleLocalPathClick();
            }
            if (!!props.onClick) {
              props.onClick(event);
            }
          }}
          {...rest}
        />
      );
    }
  },
);

const permissionName = 'clipboard-write' as PermissionName;
const clipboardWriteEnabled = async () => {
  const result = await navigator.permissions.query({ name: permissionName });
  return result.state === 'granted' || result.state === 'prompt';
};
const clipboardWrite = async (text: string) => {
  const enabled = await clipboardWriteEnabled();
  if (enabled) {
    const copyResult = await navigator.clipboard.writeText(text);
    return copyResult;
  }
};
