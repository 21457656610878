import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { FileView } from './FileView';
import * as React from 'react';

export interface FilesPreviewProps {
  files: IFormFileValue[] | null;
  multiple?: boolean;
  onRemove?: (value: any) => void;
}
export function FilesPreview(props: FilesPreviewProps) {
  const { files, onRemove } = props;
  const handleRemove = (value: any) => {
    if (onRemove) onRemove(value);
  };
  return (
    <React.Fragment>
      {files !== null &&
        files.length > 0 &&
        files.map((file, index) => (
          <FileView
            file={file}
            key={`file_key_${(file.Size ?? 1) + index}`}
            onRemove={handleRemove}
          />
        ))}
    </React.Fragment>
  );
}
