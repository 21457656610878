import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  styled,
} from '@material-ui/core';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { ServiceRequestKanbanItemType } from '../..';
import { useDispatch } from 'react-redux';
import { PrioritiesUnion } from 'api/odata/generated/enums/Priorities';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { assertExhaustive } from 'utils/assertExhaustive';
import { TypeColor } from 'styles/colors/types';
import { Button } from 'app/components/BasicButtons/Button';
import { UserAvatar } from 'app/components/UserAvatar';
import { useServiceRequestsKanbanSlice } from '../../slice';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { getServiceRequestTicketTitle } from '../getServiceRequestTicketTitle';
import { ParentTicketDetailsButton } from '../ParentTicketDetailsButton';
import { IconName } from 'app/AllIcons';

export interface IServiceRequestCardProps {
  item: ServiceRequestKanbanItemType;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}
export function ServiceRequestCard({
  item,
  ...props
}: IServiceRequestCardProps) {
  const { t } = useTranslation();
  const title = getServiceRequestTicketTitle(item);
  const dispatch = useDispatch();
  const { actions } = useServiceRequestsKanbanSlice();
  const handleDetailsClick = () => {
    dispatch(actions.showEdit(item));
  };

  return (
    <Card>
      <CardHeader
        title={
          <>
            {item.ParentTicketId !== null && (
              <>
                <Icon icon="code-branch" />
                &emsp;
              </>
            )}
            {title}
          </>
        }
        {...props.dragHandleProps}
      />
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <StyledCardContentRow>
              {item.ServiceRequest.Service?.Name}
            </StyledCardContentRow>
            <StyledCardContentRow>
              {item.ServiceRequest.Budget?.Name}
            </StyledCardContentRow>
            <StyledCardContentRow>
              {item.TotalSamplesCount}
            </StyledCardContentRow>
          </Grid>
          <StyledFoo item xs={6}>
            <PriorityIcon priority={item.Priority} />
            <UserAvatar user={item.AssignedTo} />
          </StyledFoo>
        </Grid>
      </CardContent>
      <CardActions>
        <Button variant="white" onClick={handleDetailsClick}>
          {t(translations.Details)}
        </Button>
        <ParentTicketDetailsButton item={item} />
      </CardActions>
    </Card>
  );
}

function PriorityIcon({ priority = 'Medium' }: { priority?: PrioritiesUnion }) {
  const getIcon = (priority: PrioritiesUnion): IconName => {
    switch (priority) {
      case 'Highest':
        return 'chevrons-up';
      case 'High':
        return 'chevron-up';
      case 'Low':
        return 'chevron-down';
      case 'Lowest':
        return 'chevrons-down';
      case 'Medium':
        return 'equals';
      default:
        assertExhaustive(priority);
    }
  };
  const getIconColor = (priority: PrioritiesUnion): TypeColor => {
    switch (priority) {
      case 'Highest':
      case 'High':
        return 'danger';

      case 'Low':
      case 'Lowest':
        return 'primary';
      case 'Medium':
        return 'primary';
      default:
        assertExhaustive(priority);
    }
  };
  return <Icon icon={getIcon(priority)} color={getIconColor(priority)} />;
}

const StyledCardContentRow = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

const StyledFoo = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(1),
  alignItems: 'center',
}));
