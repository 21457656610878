/**
 *
 * SamplePlatePositionPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { ISamplePlateDto } from 'api/odata/generated/entities/ISamplePlateDto';
import { SamplePlatePositionNamingTypes } from 'api/odata/generated/enums/SamplePlatePositionNamingTypes';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { EditorProps } from 'app/pages/Samples/EditableTable/EditableCell';
import {
  ISample,
  ISampleRun,
} from 'app/pages/Samples/SamplesListPage/slice/types';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';

export interface SamplePlatePositionPickerProps
  extends Omit<
      AutocompletePickerProps<Entity<string | null>>,
      'loadData' | 'onChange' | 'onBlur' | 'value' | 'name' | 'error'
    >,
    EditorProps<string | null> {
  serviceGroupId?: number;
  placeholder?: string;
  helperText?: string;
  values?: ISample | ISampleRun;
}

function range(x) {
  return Array.apply(null, Array(x)).map(function (x, i) {
    return i;
  });
}
const loadData =
  (plateId?: string | null) =>
  async (searchTerm: string | null): Promise<Entity<string>[]> => {
    const plate = await httpClient.get<ISamplePlateDto>(
      `/api/odata/v4/sampleplates('${plateId}')`,
    );

    const rowsCount = plate.SamplePlateTypeRows;
    const columnsCount = plate.SamplePlateTypeColumns;
    const rows = range(columnsCount)
      .map(i =>
        plate.SamplePlateTypeColumnsNaming ===
        SamplePlatePositionNamingTypes.Alpha
          ? String.fromCharCode(65 + i)
          : i + 1,
      )
      .flatMap(column =>
        range(rowsCount)
          .map(i =>
            plate.SamplePlateTypeRowsNaming ===
            SamplePlatePositionNamingTypes.Alpha
              ? String.fromCharCode(65 + i)
              : i + 1,
          )
          .map(row => `${column}${row}`),
      );
    const options = rows.map(a => ({ Id: a, Name: a }));
    if (searchTerm !== null && searchTerm !== '') {
      return options.filter(
        f =>
          f.Name.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
          f.Name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1,
      );
    }
    return options;
  };

export interface ConnectedSamplePlatePositionPickerProps
  extends SamplePlatePositionPickerProps {
  samplePlateId: string | null;
}
export function SamplePlatePositionPicker({
  serviceGroupId,
  samplePlateId,
  value,
  values,
  onChange,
  ...props
}: ConnectedSamplePlatePositionPickerProps) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadDatax = React.useCallback(loadData(samplePlateId), [
    samplePlateId,
    serviceGroupId,
  ]);
  const S2Value = value === null ? null : { Id: value, Name: value };
  return (
    <AutocompletePicker
      loadData={loadDatax}
      size={props.size}
      id={props.id || 'sampleplateposId'}
      onChange={value => onChange(value?.Id ?? null)}
      value={S2Value}
      {...props}
    />
  );
}
export function TargetSamplePlatePositionPicker({
  values,
  ...props
}: SamplePlatePositionPickerProps) {
  const samplePlateId = (values as ISampleRun)?.TargetPlateId ?? null;
  return <SamplePlatePositionPicker samplePlateId={samplePlateId} {...props} />;
}

export function SourceSamplePlatePositionPicker({
  values,
  ...props
}: SamplePlatePositionPickerProps) {
  const samplePlateId = (values as ISample)?.SamplePlateId ?? null;
  const { t } = useTranslation();
  React.useEffect(() => {
    if (samplePlateId === null && props.value !== null) {
      props.onChange(null);
    }
  }, [props, samplePlateId]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <SamplePlatePositionPicker
      samplePlateId={samplePlateId}
      info={
        samplePlateId === null
          ? `Please select a ${t(translations.SamplePlateId)} before.`
          : props.info
      }
      {...props}
    />
  );
}
