import {
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  useTheme,
} from '@material-ui/core';
import { TabContent } from 'app/components/BasicTabs/TabContent';
import styled from 'styled-components';
import * as React from 'react';
import { DropDown } from 'app/components/DropDown';
import { TabPanel, TabPanelProps } from 'app/components/BasicTabs/TabPanel';

export interface MobileDropDownTabsProps {
  tabs: TabContent[];
  tabsId: string;
  tabsPanelId: string;
  isMobile?: boolean;
  handleTabChange: (tab: TabContent) => void;
  selectedTab: TabContent;
  tabPanels?: Array<React.ReactElement<TabPanelProps>>;
}
export const DropDownTabsWrapper = styled('div')(({ theme }) => ({
  '&.drpTabsWrapper': {
    display: 'flex',
    padding: '0px 8px 0px 16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    gap: '16px',
    width: '100%',
    overflow: 'hidden',
    '& .asset-mobile-tabs': {
      '& .asset-mobile-tabs-paper': {
        width: '100%',
      },
    },
  },
}));

export function MobileDropDownTabs(props: MobileDropDownTabsProps) {
  const {
    tabs,
    tabsId,
    tabsPanelId,
    isMobile,
    handleTabChange,
    selectedTab,
    tabPanels,
  } = props;
  const theme = useTheme();
  // const [selectedOption, setSelectedOption] = React.useState<TabContent>(
  //   tabs[0],
  // );
  const handleSelectedTabIndexChange = (newValue: TabContent) => {
    handleTabChange(newValue);
  };
  const getTabProps = React.useCallback(
    (item: TabContent) => {
      return {
        id: `${tabsId}-${item.Id}`,
        'aria-controls': `${tabsPanelId}-${item.Id}`,
        label: item.Name,
      };
    },
    [tabsId, tabsPanelId],
  );
  return (
    <DropDownTabsWrapper theme={theme} className={'drpTabsWrapper'}>
      <DropDown
        variant="gray"
        size="small"
        fullWidth
        id={'asset-mobile-tabs-ddl'}
        endIcon={!!selectedTab.endIcon ? selectedTab.endIcon : undefined}
        menuChildren={close =>
          tabs.map(tab => (
            <MenuItem
              button
              key={tab.Id}
              onClick={() => {
                handleSelectedTabIndexChange(tab);
                close();
              }}
              selected={selectedTab.Id === tab.Id}
              {...getTabProps(tab)}
            >
              <ListItemText primary={tab.Name} />
              {!!tab.endIcon && (
                <ListItemSecondaryAction>{tab.endIcon}</ListItemSecondaryAction>
              )}
            </MenuItem>
          ))
        }
        menuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          className: 'asset-mobile-tabs',
          PaperProps: {
            style: { width: isMobile ? '100%' : '340px' },
            className: 'asset-mobile-tabs-paper',
          },
        }}
        addChevron
      >
        {selectedTab.Name}
      </DropDown>
      {!!tabPanels && tabPanels.length > 0 ? (
        <React.Fragment>{tabPanels}</React.Fragment>
      ) : (
        <React.Fragment>
          {tabs.map((item, index) => (
            <TabPanel
              value={selectedTab.Id}
              index={item.Id}
              tabsId={tabsId}
              tabsPanelId={tabsPanelId}
              scrollableContent={true}
            >
              {item.Content}
            </TabPanel>
          ))}
        </React.Fragment>
      )}
      {/* {tabs.map((item, index) => (
        <TabPanel
          value={selectedTab.Id}
          index={item.Id}
          tabsId={tabsId}
          tabsPanelId={tabsPanelId}
          scrollableContent={true}
        >
          {item.Content}
        </TabPanel>
      ))} */}
    </DropDownTabsWrapper>
  );
}
