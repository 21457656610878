import { IRow } from 'app/components/BasicTable';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { SearchColumnType } from 'app/components/BasicTable/BasicTableProps';
import { isEmpty } from 'lodash';
import { SortingRule } from 'react-table';
import { Entity } from 'types/common';
import { getPredicates } from './getPredicates';
import { Condition, ODataFilterBuilder, ODataOperators } from './ODataFilter';

export const buildParams = <TRow extends IRow>(
  state: {
    pageSize: number;
    pageIndex: number;
    sortBy?: SortingRule<TRow>[] | null;
    globalFilterValue?: string;
    customFilter: IFilterSettings<TRow>[];
    select: Array<keyof TRow>;
    additionalColumns?: Array<keyof TRow>;
    serviceGroups: Entity<number>[];
    isOptionalServiceGroup: boolean;
    expandedColumns?: Array<keyof TRow>;
    predicates?: Array<Condition<TRow> | string>;
    useCustomSearch?: boolean;
  },
  searchColumns?: Array<SearchColumnType<TRow>>,
  customParams?: (fieldName: string, Id: number, fn: (p: {}) => void) => void,
  isExport?: boolean,
) => {
  const params: {
    $skip: number;
    $top: number;
    $count: boolean;
    $select: string;
    $orderby?: string;
    $filter?: string;
    $expand?: string;
  } = {
    $skip: state.pageIndex * state.pageSize,
    $top: state.pageSize,
    // adds inline count of all records
    $count: true,
    // definition of columns required
    $select: isExport
      ? state.select.join(',')
      : [
          ...new Set([...state.select, ...(state.additionalColumns ?? [])]),
        ].join(','),
  };
  const expaneded = state.expandedColumns?.filter(
    c => state.select.includes(c) || state.additionalColumns?.includes(c),
  );

  if (expaneded && expaneded.length > 0) {
    params.$expand = expaneded.join(',');
  }
  const customparams = {};
  function cp(p: {}) {
    Object.assign(customparams, p);
  }

  params.$filter = new ODataFilterBuilder<TRow>({
    predicates: [
      ...(state.predicates ?? []),
      ...getPredicates<TRow>(state.customFilter, customParams, cp),
    ],
    globalServiceGroupFilter:
      state.serviceGroups === undefined || state.serviceGroups.length === 0
        ? undefined
        : state.additionalColumns?.find(f => f === 'ServiceGroupId') !==
            undefined ||
          state.select.find(f => f === 'ServiceGroupId') !== undefined
        ? [
            new Condition<TRow>(
              'ServiceGroupId',
              ODataOperators.Includes,
              state.serviceGroups,
            ),
          ]
        : state.additionalColumns?.find(f => f === 'ServiceGroups') !==
            undefined ||
          state.select.find(f => f === 'ServiceGroups') !== undefined
        ? state.isOptionalServiceGroup
          ? [
              new Condition<TRow>(
                'ServiceGroups',
                ODataOperators.HasNoAny,
                state.serviceGroups,
              ),
              new Condition<TRow>(
                'ServiceGroups',
                ODataOperators.AnyIn,
                state.serviceGroups,
                'Id',
              ),
            ]
          : [
              new Condition<TRow>(
                'ServiceGroups',
                ODataOperators.AnyIn,
                state.serviceGroups,
                'Id',
              ),
            ]
        : undefined,
    stringColumns: state.useCustomSearch ? [] : searchColumns || [],
    stringSearch: state.useCustomSearch ? undefined : state.globalFilterValue,
    isOptionalServiceGroup: state.isOptionalServiceGroup,
  }).toString();

  // sort parameter should be present only if sorting is defined
  // empty $orderby is considered a bad request
  if (!isEmpty(state?.sortBy)) {
    params.$orderby = state.sortBy
      ?.map(f => `${f.id} ${f.desc ?? false ? 'desc' : 'asc'}`)
      .join(',');
  }
  Object.assign(params, customparams);
  return params;
};
