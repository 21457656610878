import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { CellProps, Renderer } from 'react-table';

export const DurationRenderer: Renderer<CellProps<Record<string, any>>> = ({
  value,
}) => {
  const { t } = useTranslation();
  if (value === null || value === undefined) {
    return t(translations.NA);
  }
  let totalSeconds = parseInt(value);
  if (isNaN(totalSeconds)) {
    return t(translations.NA);
  }
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds -= hours * 3600;
  const minutes = Math.floor(totalSeconds / 60);
  totalSeconds -= minutes * 60;
  const seconds = totalSeconds;

  return [hours, minutes, seconds]
    .map(f => String(f).padStart(2, '0'))
    .join(':');
};
export default DurationRenderer;
