import { RequestStatusesDetailsQStringParameters } from 'app/pages/RequestStatuses/RequestStatusesDetailsPage/Details/slice/types';
import { IRequestStatusesDto } from 'app/pages/RequestStatuses/RequestStatusesPage/IRequestStatusesDto';
import { httpClient } from './HttpClient';

export const RequestStatusesApi = {
  initUpdateData: (payload: RequestStatusesDetailsQStringParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/RequestStatuses/InitRequestStatuses';
    return httpClient.post(url, data);
  },
  updateRequestStatusesDetails: (payload: IRequestStatusesDto) => {
    const url = `/api/odata/v4/RequestStatuses(${payload.Id})`; //PUT action in Controller
    return httpClient.put(url, payload);
  },
};
