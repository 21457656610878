import { ISettingModel } from 'api/odata/generated/entities/ISettingModel';
import { EnumType } from 'typescript';
import { AllowedSettings } from './AllowedSettings';

export class GlobalSettings {
  settings: ISettingModel[];
  constructor(sett: ISettingModel[]) {
    this.settings = sett;
  }
  public TryGet(key: string): string | null {
    if (this.settings.length > 0) {
      let obj = this.settings.find((value, index) => value.Id === key);
      return obj === undefined ? null : obj.Value;
    } else {
      return null;
    }
  }
  //please dont forget to add key of the setting to the allowed list of the enum AllowedSettings
  public GetString(key: AllowedSettings): string {
    let keyValue = AllowedSettings[key];
    let res = this.TryGet(keyValue.toString());
    return res === null ? '' : res;
  }
  public GetNullableByKey(key: AllowedSettings): string | null {
    let keyValue = AllowedSettings[key];
    return this.TryGet(keyValue.toString());
  }
  public GetNullableString(key: string): string | null {
    return this.TryGet(key);
  }
  public GetBooleanByKey(key: AllowedSettings): boolean {
    let res = this.GetString(key);
    return res === '' ? false : res.toLowerCase() === 'true';
  }
  public GetBoolean(key: string): boolean {
    let res = this.TryGet(key);
    return res === null ? false : res.toLowerCase() === 'true';
  }
  public GetNullableBoolean(key: string): boolean | null {
    let res = this.TryGet(key);
    return res === null ? null : res.toLowerCase() === 'true';
  }
  public GetEnum(key: string, typeEnum: EnumType): EnumType {
    let res = this.TryGet(key);
    return res === null ? typeEnum.getDefault() : typeEnum[res];
  }
  public GetNullableEnum(key: string, typeEnum: EnumType): EnumType | null {
    let res = this.TryGet(key);
    return res === null ? null : typeEnum[res];
  }
}
