import { isEqual } from 'lodash';
import { IFilterSettings } from './IFilterSettings';

export function areSameFilter<TRow>({
  f,
  item,
}: {
  f: IFilterSettings<TRow> | undefined;
  item: IFilterSettings<TRow> | undefined;
}): boolean {
  return (
    f !== undefined &&
    item !== undefined &&
    isEqual(filterKey(f), filterKey(item))
  );
}

export const filterKey = <TRow>(f: IFilterSettings<TRow>) =>
  f.id !== undefined
    ? f.id
    : {
        fieldName: f.fieldName,
        fieldName1: f.fieldName1,
        displayNameKey: f.displayNameKey,
      };

export const filtersInQuery = <TRow>(
  filters: IFilterSettings<TRow>[],
  query: string,
) => {
  return filters.filter(f => f.value !== undefined);
};
