import * as React from 'react';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useReservationSlice } from '../slice';
import { translations } from 'locales/translations';
import useSidePanelState, {
  SidePanelCloseState,
} from 'app/hooks/useSidePanelOpen';
import { useSchedulerSlice } from 'app/pages/CalendarPage/Scheduler/slice';
import { selectSelectedReservationEvents } from 'app/pages/CalendarPage/Scheduler/slice/selectors';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Button } from 'app/components/BasicButtons/Button';
import { selectMultipleProcessing } from '../slice/selectors';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import { ReservationEvent } from './ReservationEvent';
import { ReservationMultiEvent } from '../slice/types';
import { IEditEvent } from 'app/pages/CalendarPage/Scheduler/slice/types';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { selectHasNotSavedChanges } from 'app/Layout/FrontendLayout/slice/selectors';
import { Beforeunload } from 'react-beforeunload';

export interface MultipleReservationsProps
  extends SidePanelContentProps,
    CoverProps {
  initilizedEvents: IEditEvent[];
  onClose?: () => void;
}
const mapEventsToReservations = (events: IEditEvent[]) => {
  return events.map(f => {
    return {
      Id: f.reservation_id ?? 0,
      EquipmentId: f.original.EquipmentId,
      StartTime: f.start_date,
      EndTime: f.end_date,
      EquipmentChanged: false,
      BookedBy: (f.original as ICalendarReservationDto).BookedBy,
      BookedById: f.original.BookedById,
    } as ReservationMultiEvent;
  });
};

export const MultipleReservations = React.memo(function MultipleReservations(
  props: MultipleReservationsProps,
) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actions } = useReservationSlice();
  const { actions: schedulerActions } = useSchedulerSlice();
  const { useSidePanel, closeCover, isCover, onClose } = props;
  const selectedEvents = useSelector(selectSelectedReservationEvents);
  const processing = useSelector(selectMultipleProcessing);
  const hasChanges = useSelector(selectHasNotSavedChanges);
  const { cover, closePanel, coverClosed, onCloseCover } = useSidePanelState(
    () => {
      return true;
    },
    undefined,
    useSidePanel,
    isCover,
    () => {
      dispatch(schedulerActions.setSelectedReservationEvents([]));
      dispatch(actions.setMultipleClosed(true));
    },
  );
  const toSaveEvents = React.useMemo(() => {
    return mapEventsToReservations(selectedEvents);
  }, [selectedEvents]);
  // React.useEffect(() => {
  //   const savm = mapEventsToReservations(selectedEvents);
  //   setToSaveEvents(savm);
  //   // if (
  //   //   selectedEvents.some(
  //   //     f => !toSaveEvents.some(s => s.Id === f.reservation_id),
  //   //   )
  //   // ) {
  //   //   setToSaveEvents([
  //   //     ...toSaveEvents,
  //   //     ...mapEventsToReservations(
  //   //       selectedEvents.filter(
  //   //         f => !toSaveEvents.some(s => s.Id === f.reservation_id),
  //   //       ),
  //   //     ),
  //   //   ]);
  //   // } else if (
  //   //   toSaveEvents.some(
  //   //     f => !selectedEvents.some(s => s.reservation_id === f.Id),
  //   //   )
  //   // ) {
  //   //   setToSaveEvents(
  //   //     toSaveEvents.filter(
  //   //       f => !selectedEvents.some(s => s.reservation_id === f.Id),
  //   //     ),
  //   //   );
  //   // } else {
  //   //   if (
  //   //     selectedEvents.some(hasEventDateChanges) &&
  //   //     toSaveEvents.some(f =>
  //   //       selectedEvents.some(
  //   //         s =>
  //   //           s.reservation_id === f.Id &&
  //   //           (s.start_date !== f.StartTime || s.end_date !== f.EndTime),
  //   //       ),
  //   //     )
  //   //   ) {
  //   //     const updatedEvents: ReservationMultiEvent[] = [];
  //   //     selectedEvents.forEach((event, index) => {
  //   //       if (hasEventDateChanges(event)) {
  //   //         const saved = toSaveEvents.find(f => f.Id === event.reservation_id);
  //   //         if (!!saved) {
  //   //           let updated = Object.assign({}, saved, {
  //   //             StartTime: event.start_date,
  //   //             EndTime: event.end_date,
  //   //           });
  //   //           updatedEvents.push(updated);
  //   //         }
  //   //       } else {
  //   //         const finded = toSaveEvents.find(
  //   //           f => f.Id === event.reservation_id,
  //   //         );
  //   //         if (!!finded) {
  //   //           updatedEvents.push(finded);
  //   //         }
  //   //       }
  //   //     });
  //   //     setToSaveEvents(updatedEvents);
  //   //   }
  //   // }
  // }, [selectedEvents, toSaveEvents]);

  const handleCloselClick = React.useCallback(() => {
    closePanel({
      isCover: isCover || !!cover,
      useSidePanel: useSidePanel,
      showConfirm: hasChanges,
      onClose: () => {
        dispatch(schedulerActions.setSelectedReservationEvents([]));
        dispatch(actions.setMultipleClosed(true));
        !!onClose && onClose();
      },
    } as SidePanelCloseState);
  }, [
    actions,
    closePanel,
    cover,
    dispatch,
    hasChanges,
    isCover,
    onClose,
    schedulerActions,
    useSidePanel,
  ]);

  const handleSaveClick = React.useCallback(
    (e: any) => {
      if (toSaveEvents.length > 0) {
        dispatch(
          actions.updateMultiReservations({
            reservations: toSaveEvents,
          }),
        );
      }
    },
    [actions, dispatch, toSaveEvents],
  );
  React.useEffect(() => {
    let active = processing === false;
    if (active) {
      handleCloselClick();
      dispatch(actions.setMultipleProcessing(undefined));
    }
    return () => {
      active = false;
    };
  }, [actions, dispatch, handleCloselClick, processing]);
  const leftActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <Button
            size="small"
            startIcon={<Icon icon="save" />}
            processing={processing === true}
            disabled={processing === true}
            onClick={e => {
              handleSaveClick(e);
            }}
          >
            {t(translations.UpdateReservations)}
          </Button>
        </React.Fragment>
      ),
    ];
  }, [handleSaveClick, processing, t]);
  const rightActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <Button
            variant="white"
            size="small"
            startIcon={<Icon icon="times" />}
            title={t(translations.Cancel)}
            disabled={processing === true}
            onClick={handleCloselClick}
          >
            {t(translations.Cancel)}
          </Button>
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [handleCloselClick, processing, t]);

  return (
    <React.Fragment>
      <PageWrapper
        pageName={t(translations.EditTimelineReservationsTitle) as string}
        titlePage={t(translations.EditTimelineReservationsTitle) as string}
        //loading={processing}
        useSidePanel={useSidePanel}
        closable={true}
        closeSidePanel={handleCloselClick}
        disableExpandToggle
        //leftTopActions={topActions}
        topProcessing={processing}
        leftActions={leftActions}
        rightActions={rightActions}
        isCover={isCover}
        //confirm={}
        cover={cover}
        closeCover={!isCover ? onCloseCover : closeCover}
        coverClosed={coverClosed}
      >
        {/* <React.Fragment>
          <BasicAlert
            content={{
              closable: true,
              type: 'warning',
              message: t(translations.QuickMultiReservationsAlertExt) as string,
            }}
          />
        </React.Fragment> */}

        <StyledFormFieldsContainer>
          <FormLeftSection
            warningNodes={[
              {
                index: 'warn',
                node: (
                  <>
                    <BasicTypography variant="captionBold">
                      {t(translations.QuickMultiReservationsAlertExt) as string}
                    </BasicTypography>
                  </>
                ),
                showMore: false,
              },
            ]}
          >
            <FormFieldsSection
              titleSection={t(translations.ReservationsBeingEdited) as string}
            >
              {selectedEvents.map(reservation => (
                <FormRow fullRow={true} minRow key={reservation.id}>
                  <ReservationEvent
                    event={reservation}
                    key={`event_${reservation.id}`}
                  />
                </FormRow>
              ))}
            </FormFieldsSection>
          </FormLeftSection>
        </StyledFormFieldsContainer>
      </PageWrapper>
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </React.Fragment>
  );
});
