import { IEquipmentAccServiceDto } from 'api/odata/generated/entities/IAccServiceDto';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { ICustomFormDto } from 'api/odata/generated/entities/ICustomFormDto';
import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import { ITrainingSessionDto } from 'api/odata/generated/entities/ITrainingSessionDto';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { SqlDayOfWeek } from 'api/odata/generated/enums/SqlDayOfWeek';
import { CheckEntity } from 'app/components/CheckBoxGroup';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { FieldHandlerProps } from 'app/components/Forms/FormRender/FormRenderer';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { RepetitiveOptions } from 'enums/RepetitiveOptions';
import { AsyncDataState, Entity } from 'types/common';
import { IRepetitiveDto } from 'types/IRepetitiveDto';
import { IResponseType, IResponseTypeBase } from 'types/ResponseType';
import { enumToEntityArray } from 'utils/enumKeys';
import { IReservationAssemblyPartDto } from 'api/odata/generated/entities/IReservationAssemblyPartDto';
import { ReservationStatus } from 'api/odata/generated/enums/ReservationStatus';
import { IModelStateDictionary } from 'types/Modelstate';

/* --- STATE --- */
export interface ReservationState {
  detailsState: AsyncDataState<ReservationDetailsState>;
  reservationSettingsState?: ReservationSettingsState;
  equipmentsSettingsState?: ServiceSettingsState;
  confirmAssistedReservations?: boolean;
  trainingsData: UserTrainingReservationData | null;
  processing: boolean;
  valueHandler?: FieldHandlerProps<ReservationDetailsState>;
  requestedValueFor?: keyof ReservationDetailsState;
  requestedValue?: any;
  equipmentChangeResultState?: ReservationEquipmentsChangeResult;
  isChangeCompleted?: boolean;
  transferState: TransferState;
  creditState: AsyncDataState<PricingState>;
  hasCharges?: boolean;
  savedOfflineServices: IOtherServices[];
  removedOfflineServices: IOtherServices[];
  refeshOfflineService?: boolean;
  terminationState: TerminationState;
  cloneReservation?: boolean;
  reservationDtoState?: IReservationDto;
  isEdit: boolean;
  asyncChangeLoading?: boolean;
  submitCredit?: boolean;
  ByApprovalErrors?: Array<string> | null;
  MaxReservationComplexityValidationResult?: string | null;
  userCanRequestApproval?: boolean;
  refreshWorkOrderLink?: boolean;
  notFound?: boolean;
  multipleProcessing?: boolean;
  multiPanelClosed?: boolean;
  userRequestedBackground: boolean;
}
export interface ReservationQueryStringParameters {
  uid?: string;
  id?: string;
  selectedIds?: string;
  Start?: string;
  End?: string;
  proj?: string;
  tsid?: string;
  remarks?: string;
  accid?: string;
  singleClick?: string;
  duration?: string;
  ug?: string;
  un?: string;
  ft?: string;
  /**
   * Service Request ID
   */
  srid?: string;
  /**
   * ServiceRequest row ID
   */
  srrid?: string;
  /**
   * Service request sample IDs (comma separated)
   */
  srsid?: string;
  /**
   * Service request samples run ID
   */
  sampleRunId?: string;
  Edit?: string;
  defaultMulti?: string;
  resIdTransferredFrom?: string;
  source?: string;
  UpdateToNow?: string;
  terminated?: string;
  Shrink?: string;
  resourceChanged?: string;
  staffEquipmentId?: string;
  staffUser?: string;
  bid?: string;
}
// Model to Save Reservation - mutch Server Model
export interface ReservationEquipment {
  EquipmentId: number;
  EquipmentName: string;
  AccServiceIds: number[];
  AssemblyId: number | null;

  ReservationId: number | null;
}
export interface ReservationBudgetModel {
  id: number;
  text: string;
  percent: number | null;
  ReservationId: number | null;
}
export interface CustomFormValueModel {
  FieldId: number;
  Value?: string;
  DisplayValue?: string;
}
export interface ReservationPartModel {
  StarTime: Date | string;
  EquipmentId: number;
  Id: number;
  BudgetId: number | null;
}
export enum FundingTypes {
  Internal = 0,
  External = 1,
}
export enum RecurringReservationUpdateOptions {
  CurrentReservation = 1,
  Following = 2,
  All = 3,
}
export enum ReservationRepeatPeriods {
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
}
export enum ReservationRepeatMonthlyTypes {
  SameDate = 1,
  SameWeekDay = 2,
}
export interface ReservationRepeatModel {
  RepeatsEveryNum: number | null;
  RepeatsEveryType: ReservationRepeatPeriods | null;
  RepeatsOn: SqlDayOfWeek[];
  RepeatsBy: ReservationRepeatMonthlyTypes;
  RepeatOption: RepetitiveOptions;
  RepeatEndTime: Date | string;
  Repeat_Period: number | null;
}

export interface ReservationModel {
  Id: number;
  Services: ReservationEquipment[];
  StartTime: Date | string;
  EndTime: Date | string;
  Username: string;
  UserGroupId: string;
  Collaborators: string[];
  BudgetId: number | null;
  BudgetExperimentId: number | null;
  InstituteProjectId: number | null;
  WorkflowBooking: Entity<number> | null;
  ExternalCustomerId: number | null;
  FundingType: FundingTypes | null;
  StaffUser: string | null;
  PurchaseOrder: string | null;
  Reference: string | null;
  Tutoring: boolean;
  TrainingSignUp: boolean;
  TrainingSessionId: number | null;
  Status: ReservationStatus;
  AllStartTimes: (Date | string)[];
  InsertedAt: Date | string | null;
  InsertedBy: string | null;
  UpdatedAt: Date | string | null;
  UpdatedBy: string | null;
  CancelledAt: Date | string | null;
  UserRequestedByApproval: boolean;
  UserRequestedBackground: boolean;
  Remarks: string | null;
  TutorName: string | null;
  PartialAttendance: boolean;
  PartialAttendanceStart: number | null;
  PartialAttendanceEnd: number | null;
  CustomFormValues: CustomFormValueModel[];
  Name: string;
  TransferFromId: number | null;
  TransferInProgress: boolean;
  TransferredTo: string | null;
  WaitingTransferApproval: boolean;
  Parts: ReservationPartModel[];
  RecurringUpdateOption: RecurringReservationUpdateOptions;
  BudgetIds: ReservationBudgetModel[];
  OfflineServices: OfflineservicesModel[];
  Terminated: boolean;
  TotalCost: number;
  SavedForLater: boolean;
  FullDayReservation: boolean;
  SampleRunId: number | null;
}
export interface ReservationInsertModel extends ReservationModel {
  Repeat: ReservationRepeatModel | null;
  AssignToServiceRequestId: number | null;
  AssignToServiceRequestRowIds: number[];
  AssignToServiceRequestSampleIds: number[];
}
export interface ReservationUpdateModel extends ReservationModel {}
export interface OfflineservicesModel {
  ServiceTypeName: string;
  Quantity: number;
  UnitsName: string;
  Remarks: string;
  Factor: number;
  OfflineServiceTypeId: number;
  InventoryBatchId: number | null;
  BudgetId: number | null;
  BudgetExperimentId: number | null;
  UserGroup: string | null;
}
// react side Model

export interface IMandatoryEquipmentsDto extends CheckEntity {
  Id: number;
  ParentName: string;
  Name: string;
  AssetId: number | null;
  AccServices: IEquipmentAccServiceDto[];
  AssemblyId: number | null;
  HasAssemblyParts: boolean;
  HideProjects: boolean;
  AllowPartialProjectCharges: boolean;
  AllowPartialSingleProjectCharges: boolean;
  TrainingSignUpApproval: number;
  HideTrainingSession: boolean;
  HasAttendance: boolean;
  ForceTutoring: boolean;
  IsEquipmentTutoring: boolean;
  NotAllowReservations: boolean;
  AllowOneAccSerOnly: boolean;
  AllowReservationCollaborators: number;
  ForceFullDayReservations: boolean;
  IsAdmin: boolean;
  Color: string;
  ServiceGroupId: number;
  ServiceGroupName: string;
  ReservationOfflineServicesEnabled: boolean | null;
  RestrictReservationToTimeSlots: boolean;
  MinOrderHours: number;
  DefaultReservationDuration: number;
  DefaultAccServiceId: number | null;
  BudgetsTurnedOn: boolean;
  Billable: boolean;
  OfflineServices: IEquipmentOfflineServiceTypeDto[];
  FirstCustomFormId: number | null;
  CustomFormId: number | null;
  CustomForms: ICustomFormDto[];
  LoanTypeId: number;
  AllowModification: boolean;
  AllowExtendReservation: boolean;
  AllowUsersToShortenReservations: boolean;
  AllowedToTransfer: boolean;
  AllowedToTransferAll: boolean;
  EnforceReservationRestrictions: boolean;
  LimitlessReservationAllowed: boolean | null;
  checked: boolean;
  disabled: boolean;
  MaxOrderHours: number;
  MinOrderGap: number;
  RestrictDurationUnitsAmount: number | null;
  RestrictDurationUnitType: number | null;
  StaffUserSelectionVisible: boolean | null;
  StaffUserSelectionRequired: boolean | null;
  StaffUserSelection: IUserFilterDto[];
  RequireUserGroupCoordApproval: boolean;
  DraftReservationsTypeId: number;
}
export interface IReservationEquipmentDto extends Entity<number> {
  Id: number;
  Name: string;
  AssetId: number | null;
  AccServices: IEquipmentAccServiceDto[];
  AssemblyId: number | null;
  HasAssemblyParts: boolean;
  ReservationId?: number;
  HideProjects: boolean;
  AllowPartialProjectCharges: boolean;
  AllowPartialSingleProjectCharges: boolean;
  TrainingSignUpApproval: number;
  HideTrainingSession: boolean;
  HasAttendance: boolean;
  ForceTutoring: boolean;
  IsEquipmentTutoring: boolean;
  NotAllowReservations: boolean;
  AllowOneAccSerOnly: boolean;
  AllowReservationCollaborators: number;
  ForceFullDayReservations: boolean;
  BudgetExperimentSettings: boolean;
  Color: string;
  ServiceGroupId: number;
  ServiceGroupName: string;
  ReservationOfflineServicesEnabled: boolean | null;
  RestrictReservationToTimeSlots: boolean;
  MinOrderHours: number;
  DefaultReservationDuration: number;
  DefaultAccServiceId: number | null;
  BudgetsTurnedOn: boolean;
  Billable: boolean;
  MandatoriesEquipments: IMandatoryEquipmentsDto[];
  OptionalsEquipments: IMandatoryEquipmentsDto[];
  OfflineServices: IEquipmentOfflineServiceTypeDto[];
  FirstCustomFormId: number | null;
  CustomFormId: number | null;
  CustomForms: ICustomFormDto[];
  LoanTypeId: number;
  AllowModification: boolean;
  AllowExtendReservation: boolean;
  AllowUsersToShortenReservations: boolean;
  AllowedToTransfer: boolean;
  AllowedToTransferAll: boolean;
  EnforceReservationRestrictions: boolean;
  LimitlessReservationAllowed: boolean | null;
  MaxOrderHours: number;
  MinOrderGap: number;
  RestrictDurationUnitsAmount: number | null;
  RestrictDurationUnitType: number | null;
  StaffUserSelectionVisible: boolean | null;
  StaffUserSelectionRequired: boolean | null;
  StaffUserSelection: IUserFilterDto[];
  RequireUserGroupCoordApproval: boolean;
  DraftReservationsTypeId: number;
  AutoMode: number;
}
export interface IEquipmentOfflineServiceTypeDto extends Entity<number> {
  Id: number;
  Name: string;
  EquipmentId: number;
  Mandatory: boolean;
  HideProject: boolean;
}
/**
 * Represents an entity connected to a budget (e.g. Reservation)
 */
export interface IWithBudget {
  Budget: Entity<number> | null; //IBudgetFilterDto | null;
}
export interface ReservationDetailsState extends IWithBudget {
  Id: number;
  EquipmentsData: Entity<number>[];
  FatherId?: number | null;
  TrainingSession: ITrainingSessionDto | null;
  StaffUser: Entity<string> | null; //IUserFilterDto | null;
  BookedBy: Entity<string> | null; //IUserFilterDto | null;
  Remarks?: string | null;
  StartTime: Date | string;
  EndTime: Date | string;
  Tutoring: boolean;
  Status?: Entity<number> | null;
  TrainingSignUp: boolean;
  ADGroup?: Entity<string> | null;
  DisplayName?: string | null;
  InstituteProject: Entity<number> | null;
  WorkflowBooking?: Entity<number> | null;
  ExternalCustomer?: Entity<number> | null;
  FundingType?: Entity<number> | null;
  PurchaseOrder?: string | null;
  Reference?: string | null;
  BudgetExperiment?: Entity<number> | null;
  SampleIds: number[];
  PartialAttendance: boolean;
  PartialAttendanceStart?: number | null;
  PartialAttendanceEnd?: number | null;
  TransferredTo: Entity<string> | null;
  WaitingTransferApproval: boolean;
  TransferFromId: number | null;
  SampleRunId?: number | null;
  AccServices: Entity<number>[]; //IAccServiceDto[];
  FormValues: IFormValueDto[];
  CollaboratedUsers: Entity<string>[]; //IUserFilterDto[];
  OptionalsEquipments: CheckEntity[];
  FullDayReservation: boolean;
  AssemblyParts?: IReservationAssemblyPartDto[] | null;
}
export interface ReservationSettingsState {
  BaseEquipment: IReservationEquipmentDto | null;
  FatherId: number | null;
  Repeat_Period: number | null;
  TrainingSessionOnly: boolean;
  SessionId: number | null;
  RecordDate: Date | string | null;
  LastUpdateDate: Date | string | null;
  Entered: Date | string | null;
  Cancelled: Date | string | null;
  LastUpdateAdmin: Entity<string> | null;
  RepeatEndTime: Date | string | null;
  Repeat: boolean;
  HasCharges: boolean;
  FundingTypeId: number | null;
  ParentAssemblyReservationId: number | null;
  TopAssemblyReservationId: number | null;
  TransferInProgress: boolean;
  HasOfflineServices: boolean;
  HasTrainingSession: boolean;
  NextReservationTime: string | null;
  PartReservations: IReservationPartDto[];
  AssignToServiceRequestId: number | null;
  AssignToServiceRequestRowIds: number[];
  AssignToServiceRequestSampleIds: number[];
  TutorName?: string | null;
  CustomForms: ICustomFormDto[];
  MandatoriesEquipments: IMandatoryEquipmentsDto[];
  OptionalsEquipments: IMandatoryEquipmentsDto[];
  FirstInitEquipments: number[];
  OfflineMandatory: IOtherServices[];
  OfflineOptional: IOtherServices[];
  //localSettings
  UserRequestedByApproval: boolean;
  RecurringUpdateOption: number | null;
  TerminateReservation: boolean;
  Ongoing: boolean;
  FutureReservation: boolean;
  PastReservation: boolean;
  IsForceTutoring: boolean;
  ForceFullDayReservations: boolean;
  StaffMandatoryEquipment: IReservationEquipmentDto | null;
  TotalCost?: number;
  SaveForLater: boolean;
}
export interface IReservationDto {
  Id: number;
  EquipmentsData: IReservationEquipmentDto[];
  Budget: IBudgetFilterDto | null; //IBudgetFilterDto | null;
  TrainingSession: ITrainingSessionDto | null; //ITrainingSessionDto | null;
  StaffUser: IUserFilterDto | null; //IUserFilterDto | null;
  BookedBy: IUserFilterDto | null; //IUserFilterDto | null;
  Remarks?: string | null;
  StartTime: Date | string;
  EndTime: Date | string;
  Tutoring: boolean;
  FullDayReservation: boolean;
  TutorName?: string | null;
  StatusId: number;
  TrainingSignUp: boolean;
  ADGroup?: Entity<string> | null;
  DisplayName?: string | null;
  InstituteProject: Entity<number> | null;
  WorkflowBooking?: Entity<number> | null;
  ExternalCustomer?: Entity<number> | null;
  PurchaseOrder?: string | null;
  Reference?: string | null;
  BudgetExperiment?: Entity<number> | null;
  TransferFromId: number | null;
  PartialAttendance: boolean;
  PartialAttendanceStart?: number | null;
  PartialAttendanceEnd?: number | null;
  TransferredTo: Entity<string> | null;
  SampleRunId?: number | null;
  AccServiceId: number | null;
  AccSerIds: string | null;
  AccServices: IEquipmentAccServiceDto[]; //IAccServiceDto[];
  FormValues: IFormValueDto[];
  CollaboratedUsers: IUserFilterDto[]; //IUserFilterDto[];
  SampleIds: number[];
  PartReservations: IReservationPartDto[];
  BaseEquipment: IReservationEquipmentDto | null;
  FatherId: number | null;
  Repeat_Period: number | null;
  TrainingSessionOnly: boolean;
  SessionId: number | null;
  RecordDate: Date | string | null;
  LastUpdateDate: Date | string | null;
  Entered: Date | string | null;
  Cancelled: Date | string | null;
  LastUpdateAdmin: IUserFilterDto | null;
  RepeatEndTime: Date | string | null;
  Repeat: boolean;
  HasCharges: boolean;

  /**
   * This property is used to read the funding type from the server (initReservation)
   */
  FundingTypeId: number | null;
  ParentAssemblyReservationId: number | null;
  TopAssemblyReservationId: number | null;
  WaitingTransferApproval: boolean;
  TransferInProgress: boolean;
  HasOfflineServices: boolean;
  HasTrainingSession: boolean;
  NextReservationTime: string | null;
  AssignToServiceRequestId: number | null;
  AssignToServiceRequestRowIds: number[];
  AssignToServiceRequestSampleIds: number[];
  MandatoriesEquipments: IMandatoryEquipmentsDto[];
  OptionalsEquipments: IMandatoryEquipmentsDto[];
  TerminateReservation: boolean;
  FirstInitEquipments: number[];
  OfflineMandatory: IOtherServices[];
  IsForceTutoring: boolean;
  ForceFullDayReservations: boolean;
  ForceReservationSettingsState: boolean;
  IsFuture: boolean;
  IsPast: boolean;
  IsOngoing: boolean;
  StaffMandatoryEquipment: IReservationEquipmentDto | null;
  AssemblyParts: IReservationAssemblyPartDto[] | null;
}
export interface IReservationPartDto {
  Id: number;
  Equipment: IReservationEquipmentDto;
  BudgetID: number | null;
  BudgetName: string | null;
  StartTime: Date | string;
  ParentAssemblyReservationId: number | null;
  TopAssemblyReservationId: number | null;
  AccServiceId: number | null;
  AccSerIds: string | null;
  Repeat_Period: number | null;
}
export interface ReservationDetailsResponseModel extends IResponseTypeBase {
  Id: number | null;
  IsValid: boolean;
  model: IReservationDto;
  insertedModel: ReservationInsertModel;
  warnOnTrainingSession: boolean;
  ReservationIds: number[];
  FirstEquipmentId: number;
  userTrainingsApprovalData: UserTrainingReservationData | null;
  EquipmentChangeResult: ReservationEquipmentsChangeResult | null;
  credit: number | null;
  balance: number | null;
  MaxReservationComplexityValidationResult?: string | null;
  ErrorMessages: IModelStateDictionary;
}
export interface MultiReservationResponseModel extends IResponseType {
  Model: ReservationMultiEvent;
  IsValid: boolean;
  Recurrent: boolean;
  RecurrentCount: number;
}
export interface ReservationGlobalState {
  details: ReservationDetailsState;
  settings: ReservationSettingsState;
  customFormFiles: IFormFileValue[];
  RepetitiveOptions: IRepetitiveDto | null;
  offlineServices: IOtherServices[];
}
export interface ServiceSettingsState {
  HasHideProjects: boolean;
  HasConflictProjects: boolean;
  HasMultipleProjects: boolean;
  HasPartialSingleProject: boolean;
  TrainingSignUpApproval?: number;
  HideTrainingSession: boolean;
  HasAttendance: boolean;
  ForceTutoring: boolean;
  ForceFullDayReservations: boolean;
  IsEquipmentTutoring: boolean;
  NotAllowReservations: boolean;
  AllowOneAccSerOnly: boolean;
  AllowReservationCollaborators: boolean;
  IsAllAdmin: boolean;
  AllOffServicesAllowed: boolean | null;

  RestrictReservationToTimeSlots: boolean;
  MinOrderHours: number;
  DefaultReservationDuration: number;
  DefaultAccServiceId: number | null;
  BudgetsTurnedOn: boolean;
  Billable: boolean;
  CustomForms: ICustomFormDto[];
  IsLoanDesc: boolean;
  AllowModification: boolean;
  AllowExtendReservation: boolean;
  AllowUsersToShortenReservations: boolean;
  AllowedToTransfer: boolean;
  AllowedToTransferAll: boolean;
  StartSlots: Date | string | null;
  EndSlots: Date | string | null;
  BudgetVisible: boolean;
  OfflineServicesVisible: boolean;
  Groups: number[];
  HasAccServices: boolean;
  MaxOrderHours: number;
  MinOrderGap: number;
  RestrictDurationUnitsAmount: number | null;
  RestrictDurationUnitType: number | null;
  StaffUserVisibleAllways: boolean;
  StaffUserVisibleOnPending: boolean;
  StaffUserRequiredAlwais: boolean;
  StaffUserRequiredOnPending: boolean;
  StaffUserSelection: IUserFilterDto[];
  RequireUserGroupCoordApproval: boolean;
  DraftForAll: boolean;
  DraftApprovalRequred: boolean;
  DraftSaveForLater: boolean;
  budgetExperimentVisible: boolean;
  EnforceReservationRestrictions: boolean;
  LimitlessReservationAllowed: boolean;
  OfflineServiceTypes: IEquipmentOfflineServiceTypeDto[];
}
export interface EquipmentsChangeStateParameters {
  Equipments: number[];
  AccServices: number[];
  ReservationId: number | null;
  Start: Date | string;
  End: Date | string;
  BookedBy: string | null;
  ADGroup: string | null;
  Tutoring: boolean;
  FullDayReservation: boolean;
  Status: number;
  TrainingSignUp: boolean;
  FundingTypeId: number | null;
  TrainingSessionId: number | null;
  BudgetId: number | null;
  Remarks: string | null;
  CheckStatus: boolean;
  CheckSession: boolean;
  PurchaseOrder: string | null;
}
export interface EquipmentSlotParameters {
  EquipmentId: number;
  Start: Date | string;
  End: Date | string;
}

export interface ReservationEquipmentsChangeResult {
  CustomForms?: CustomFormsResult;
  ErrorMessages: string[];
  WarningMessages: string[];
  DefaultBudget: IBudgetFilterDto | null;
  StatusChange: StatusChangeResult | null;
  TrainingSessionData: TrainingSessionResult | null;
}
export interface ReservationValidationResult {
  ErrorMessages: IModelStateDictionary;
  WarningMessages: string[];
  PermissionErrors: IModelStateDictionary;
  PrerequisiteTrainings?: PrerequisitsResult;
  UserCanRequestApproval?: boolean;
}
export interface AsyncEquipmentsChangeResult {
  StartSlots: Date | string | null;
  EndSlots: Date | string | null;
}
export interface StatusChangeResult {
  Status: number;
  Tutoring: boolean;
  IsForceTutoring: boolean;
  ForceFullDayReservations: boolean;
  FullDayReservation: boolean;
}
export interface TrainingSessionResult {
  TrainingSessionId: number | null;
  Start: Date | string;
  End: Date | string;
  HasTrainingSession: boolean;
  StaffUser: string | null;
}
export interface PrerequisitsResult {
  PrerequisiteTrainings: Entity<number>[];
  ErrorMessage: string;
  IsValid: boolean;
}
export interface CustomFormsResult {
  CustomForms: ICustomFormDto[];
  ErrorMessages: string[];
  IsValid: boolean;
}
export interface UserTrainingEquipments {
  ReservationId: number | null;
  EquipmentId: number;
  EquipmentName: string;
  TrainingRecordId: number | null;
  RequiresAdminApproval: boolean;
  NotShowPopup: boolean;
}
export interface UserTrainingReservationData {
  UserName: string;
  StartTime: Date | string;
  EndTime: Date | string;
  Equipments: UserTrainingEquipments[];
  FatherId: number | null;
  ReservationId: number | null;
  Tutoring: boolean;
}
export interface HasUsageEndRresult {
  HasUsage: boolean;
  OfflineServiceTypeIds: number[];
}
export interface TransferToReservation {
  User: Entity<string>;
  ReservtionId: number;
}
export interface TransferState {
  User: Entity<string> | null;
  ReservtionId?: number;
  WaitingTransferApproval?: boolean;
  transferProcessing?: boolean;
  transferStepCompleted?: boolean;
}
export interface RequestReservations {
  RequestId: number | null;
  TotalRowsCount: number | null;
  AssociatedRowsCount: number | null;
}
export interface TerminationState {
  terminatProcessing?: boolean;
  terminateCompleted?: boolean;
}
export interface ReservationMultiEvent {
  Id: number;
  EquipmentId: number;
  StartTime: Date | string;
  EndTime: Date | string;
  EquipmentChanged: boolean;
  BookedBy: string;
  BookedById: string;
}
export const getSingleReservationStatus = (val: number): Entity<number> => {
  return enumToEntityArray(ReservationStatus).filter(item => {
    return item.Id === val;
  })[0];
};
export type createReservation_Success_Payload = {
  hasErrors: boolean;
  reservationIds: number[];
  insertModel?: ReservationInsertModel;
} & Pick<
  ReservationDetailsResponseModel,
  'MaxReservationComplexityValidationResult'
>;
export interface updateReservationPayload {
  current: ReservationDetailsState;
  original: ReservationDetailsState;
  currentCustomFormFiles: IFormFileValue[];
  originalCustomFormFiles: IFormFileValue[];
  offlineServices: IOtherServices[];
  settings: ReservationSettingsState | undefined;
  terminate?: boolean;
}

export interface updateReservation_SuccessPayload
  extends Pick<
    ReservationDetailsResponseModel,
    'MaxReservationComplexityValidationResult'
  > {
  hasErrors: boolean;
  userTrainigData: UserTrainingReservationData | null;
  insertModel: ReservationInsertModel;
}
export interface PricingState {
  credit: number;
  balance: number | null;
}
