/**
 *
 * InvoiceStatusWithStaticOptionsPicker
 *
 */

import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import { InvoiceStatuses } from 'enums/InvoiceStatuses';
import { Entity } from 'types/common';
import { enumToEntityArray } from 'utils/enumKeys';

export interface InvoiceStatusWithStaticOptionsPickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {}

export const getSingleInvoiceStatusWithStaticOptions = (
  val: number,
): Entity<number> => {
  return enumToEntityArray(InvoiceStatuses).filter(item => {
    return item.Id === val;
  })[0];
};

export const initInvoiceStatusWithStaticOptionsData = (
  initval: number | undefined,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([getSingleInvoiceStatusWithStaticOptions(initval)]);
    });
  }
};

const getOptions = (searchTerm: string | null) => {
  let items = enumToEntityArray(InvoiceStatuses);
  if (searchTerm !== null && searchTerm !== '') {
    return items.filter(
      f => f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
    );
  }
  return items;
};

export function InvoiceStatusWithStaticOptionsPicker(
  props: InvoiceStatusWithStaticOptionsPickerProps,
) {
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      value={props.value}
      {...props}
    />
  );
}
