import { ReservationStatus } from 'api/odata/generated/enums/ReservationStatus';
import { StatusLabel } from 'app/components/basic/StatusLabel';
import { CellProps, Renderer } from 'react-table';

type RecordType = Record<string, any>;
const ReservationStatusRenderer: Renderer<CellProps<RecordType>> = ({
  value,
  row,
}) => {
  const reservationStatusLabelVariant = value => {
    switch (value) {
      case ReservationStatus[ReservationStatus.Approved]:
      case ReservationStatus[ReservationStatus.Done]:
      case ReservationStatus[ReservationStatus.DoneWOBooking]:
        return 'success';
      case ReservationStatus[ReservationStatus.Pending]:
      case ReservationStatus[ReservationStatus.ApprovedOnce]:
      case ReservationStatus[ReservationStatus.PendingCoord]:
        return 'warning';
      case ReservationStatus[ReservationStatus.Cancelled]:
      case ReservationStatus[ReservationStatus.CancelledByAdmin]:
      case ReservationStatus[ReservationStatus.NotDone]:
      case ReservationStatus[ReservationStatus.NA]:
        return 'error';
      case ReservationStatus[ReservationStatus.AllStatus]:
      case ReservationStatus[ReservationStatus.Tentative]:
      case ReservationStatus[ReservationStatus.Draft]:
        return 'info';
      default:
        return 'error';
    }
  };
  //RENDER
  return (
    <>
      <StatusLabel variant={reservationStatusLabelVariant(value)}>
        {value}
      </StatusLabel>
    </>
  );
};
export default ReservationStatusRenderer;
