import { useTheme, FormHelperText, InputAdornment } from '@material-ui/core';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';

import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import BaseTextField from 'app/components/BasicInputs/BaseTextField';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AuthenticatedUser, IIsAdminOfParams } from 'types/AuthenticatedUser';
import { dateUtils } from 'utils/date-utils';
import { ErrorServices } from '../slice/types';
import { InventoryBatchChip } from './InventoryBatchChip';

export interface QuickServiceItemProps {
  service: IOtherServices;
  handleRemove: (item: IOtherServices) => Promise<any>;
  handleChange: (item: IOtherServices) => Promise<any>;
  onServiceClick?: (item: IOtherServices) => void;
  mandatory?: boolean;
  isEditMode?: boolean;
  createdEditable?: boolean;
  count: number | null;
  minStep: number;
  step: number;
  maxStep: number;
  disabled?: boolean;
  renewStock?: boolean;
  user?: AuthenticatedUser;
  onAddBatchClick?: (
    batch: IInventoryBatchDto | null,
    serviceTypeId: number,
  ) => void;
  onRenewStockClick?: (service: IOtherServices) => void;
  setError?: (error: ErrorServices) => void;
  focused?: boolean;
  shortView?: boolean;
}
const QuickItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.common.white,
  boxShadow: 'inset 0px -1px 0px #E3E8EF',
  '& .errorsRoot': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  '& .textandImageShort': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 12px 0px 12px',
    gap: '12px',
    width: '100%',
    flexGrow: 1,
  },
  '& .contentRoot': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px 12px',
    gap: '8px',
    '& .textandImage': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px',
      gap: '12px',
      width: '100%',
      flexGrow: 1,
    },
    '& .quantity': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px',
      gap: '8px',
      flexGrow: 0,
    },
    '& .adjustment': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '0px 0px 0px 8px',
      gap: '10px',
      marginLeft: 'auto',
    },
  },
  '& .bottomRoot': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    padding: '0px 8px 8px 8px',
  },
}));

export const QuickServiceItem = React.memo(function QuickServiceItem(
  props: QuickServiceItemProps,
) {
  const { t } = useTranslation();
  const {
    service,
    mandatory,
    handleChange,
    handleRemove,
    onServiceClick,
    isEditMode,
    createdEditable,
    count,
    minStep,
    step,
    maxStep,
    disabled,
    renewStock,
    onAddBatchClick,
    onRenewStockClick,
    user,
    setError,
    focused,
    shortView,
  } = props;
  const theme = useTheme();
  const inputProps = {
    step: step,
    min: minStep,
    max: maxStep,
    'aria-label': 'Counter',
  };
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined,
  );
  const handleAddError = React.useCallback(
    (error: string | undefined) => {
      setErrorMessage(error);
      if (!!setError) {
        setError({
          id: service.ServiceTypeID,
          error: !!error ? `${service.ServiceType}: ${error}` : undefined,
        } as ErrorServices);
      }
    },
    [service.ServiceType, service.ServiceTypeID, setError],
  );
  const [quantity, setQuantity] = React.useState<number | null>(count);
  React.useEffect(() => {
    setQuantity(count);
  }, [count]);
  React.useEffect(() => {
    if (quantity === null || quantity === undefined || quantity === 0) {
      handleAddError(t(translations.err_Quantity_required) as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, t]);

  // const IsGroupAdmin = React.useMemo(() => {
  //   return user?.HasAdminGroupPermissions(service?.ServiceGroupId ?? undefined);
  // }, [service?.ServiceGroupId, user]);
  const IsAdminOfService = React.useMemo(() => {
    return user?.IsAdminOf({
      ServiceGroupId: service?.ServiceGroupId,
      ServiceId: service?.Id,
      ServiceTypeId: ServiceType.Offline,
    } as IIsAdminOfParams);
  }, [service?.Id, service?.ServiceGroupId, user]);
  const [counterUpdated, setCounterUpdated] = React.useState<boolean>(false);
  const arrowTitle = React.useMemo(() => {
    if (service.TrackInventory && service.RenewStockInventory) {
      return t(translations.RenewStockArrow_info) as string;
    } else {
      return isEditMode && service.Id > 0
        ? (t(translations.ReservationOfflineServiceEditArrow_info) as string)
        : createdEditable
        ? (t(translations.ConsumableCreateArrow_info) as string)
        : (t(translations.ReservationOfflineServiceCreateArrow_info) as string);
    }
  }, [
    createdEditable,
    isEditMode,
    service.Id,
    service.RenewStockInventory,
    service.TrackInventory,
    t,
  ]);
  const serviceChange = React.useCallback(
    (change: number | null, item: IOtherServices, withError?: boolean) => {
      return new Promise(async resolve => {
        const precision = service.IntQuantityOnly === true ? 0 : 2;
        try {
          await handleChange(
            Object.assign({}, item, {
              Quantity: change === null ? null : +change.toFixed(precision),
              DefaultQuantity: change === null ? null : item.DefaultQuantity,
              ServiceDate: dateUtils.formatISO(
                dateUtils.dateOrStringToDate(item.ServiceDate),
              ),
            }),
          );

          setQuantity(change === null ? null : +change.toFixed(precision));
          setCounterUpdated(false);
          if (!withError) {
            handleAddError(undefined);
          }
        } catch (error) {
          handleAddError(error as string);
          setQuantity(change === null ? null : +change.toFixed(precision));
          setCounterUpdated(false);
        }
      });
    },
    [handleAddError, handleChange, service.IntQuantityOnly],
  );
  const remove = (service: IOtherServices) => {
    return new Promise(async resolve => {
      try {
        await handleRemove(service);
        resolve(undefined);
        setCounterUpdated(false);
        handleAddError(undefined);
      } catch (error) {
        handleAddError(error as string);
        setCounterUpdated(false);
      }
    });
  };
  const onInputChange = React.useCallback(
    event => {
      const precision = service.IntQuantityOnly === true ? 0 : 2;
      if (event.target.value === '') {
        //setCounterUpdated(true);
        setQuantity(null);
        serviceChange(null, service, true);
        handleAddError(
          (t(translations.err_LowQuantity) as string).replace(
            '{0}',
            minStep.toString(),
          ),
        );
        return;
      } else {
        const value = +(+event.target.value).toFixed(precision);
        if (value < minStep) {
          // event.preventDefault();
          // event.stopPropagation();
          setCounterUpdated(true);
          serviceChange(value, service, true);
          handleAddError(
            (t(translations.err_LowQuantity) as string).replace(
              '{0}',
              minStep.toString(),
            ),
          );
        } else if (value > 0 && value > maxStep) {
          event.preventDefault();
          event.stopPropagation();
          handleAddError(
            (t(translations.err_HighQuantity) as string).replace(
              '{0}',
              maxStep.toString(),
            ),
          );
        } else {
          handleAddError(undefined);
          // setCounterUpdated(true);
          serviceChange(value, service);
        }
      }
    },
    [handleAddError, maxStep, minStep, service, serviceChange, t],
  );
  return (
    <QuickItem theme={theme} id={`quick-service_${service.ServiceTypeID}_item`}>
      {shortView && (
        <div className={'textandImageShort'}>
          <BasicTypography display="inline">{`${service.ServiceType}`}</BasicTypography>
        </div>
      )}
      <div className={'contentRoot'}>
        {!shortView && (
          <div className={'textandImage'}>
            <BasicTypography display="inline">{`${service.ServiceType}`}</BasicTypography>
          </div>
        )}

        <div className={'quantity'}>
          <IconButton
            variant="white"
            size="small"
            shape="square"
            id={`ServiceCount_minus`}
            title={
              mandatory
                ? (t(translations.OfflineServiceIsMandatory_info) as string)
                : undefined
            }
            disabled={disabled || counterUpdated}
            onClick={() => {
              let valid = mandatory
                ? (quantity ?? 0) - step > 0
                : (quantity ?? 0) - step >= 0;
              if (
                !mandatory &&
                !valid &&
                (quantity === null || quantity <= 0)
              ) {
                setCounterUpdated(true);
                remove(service);
                return;
              }
              if (valid) {
                handleAddError(undefined);
                if (!mandatory && (quantity ?? 0) - step === 0) {
                  setCounterUpdated(true);
                  remove(service);
                } else {
                  setCounterUpdated(true);
                  serviceChange((quantity ?? 0) - step, service);
                }
              } else {
                if (mandatory) {
                  setQuantity(null);
                  serviceChange(null, service, true);
                  handleAddError(
                    (
                      t(translations.OfflineServiceMandatory_err) as string
                    ).replace('{0}', minStep.toString()),
                  );
                } else {
                  setQuantity(null);
                  serviceChange(null, service, true);
                  handleAddError(
                    (t(translations.err_LowQuantity) as string).replace(
                      '{0}',
                      minStep.toString(),
                    ),
                  );
                }
              }
            }}
          >
            {(quantity ?? 0) <= step ? (
              <Icon icon="trash" />
            ) : (
              <Icon icon="minus" />
            )}
          </IconButton>
          <StyledBaseTextField
            error={errorMessage !== undefined}
            type="number"
            variant="standard"
            id={`ServiceCount_text`}
            inputProps={inputProps}
            size="small"
            value={quantity ?? ''}
            disabled={counterUpdated}
            autoFocus={focused}
            required
            //helperText={errorMessage}
            onChange={onInputChange}
            className={'with-end-label'}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  disableTypography
                  className="text-end"
                >
                  <BasicTypography variant="captionBold" color="secondary">
                    {service.Units}
                  </BasicTypography>
                </InputAdornment>
              ),
            }}
          />
          <IconButton
            variant="white"
            size="small"
            shape="square"
            disabled={counterUpdated}
            id={`ServiceCount_plus`}
            onClick={() => {
              let valid = (quantity ?? 0) + step < maxStep;
              if (valid) {
                let minvalid = mandatory
                  ? (quantity ?? 0) + step > 0
                  : (quantity ?? 0) + step >= 0;
                if (minvalid) {
                  setCounterUpdated(true);
                  serviceChange((quantity ?? 0) + step, service);
                  handleAddError(undefined);
                } else {
                  setCounterUpdated(true);
                  serviceChange((quantity ?? 0) + step, service, true);
                  handleAddError(
                    (t(translations.err_LowQuantity) as string).replace(
                      '{0}',
                      minStep.toString(),
                    ),
                  );
                }
              } else {
                handleAddError(
                  (t(translations.err_HighQuantity) as string).replace(
                    '{0}',
                    maxStep.toString(),
                  ),
                );
              }
            }}
          >
            <Icon icon="plus" />
          </IconButton>
        </div>

        <div className={'adjustment'}>
          {counterUpdated ? (
            <IconButton variant="ghost" size="xs" shape="square">
              <Icon icon="spinner" spin={true} />
            </IconButton>
          ) : (
            <React.Fragment>
              {!!onServiceClick && (
                <IconButton
                  variant="ghost"
                  size="xs"
                  shape="square"
                  id={`ServiceCount_arrow`}
                  disabled={
                    (!isEditMode && !createdEditable) ||
                    (isEditMode && service.Id < 1)
                  }
                  title={arrowTitle}
                  onClick={() => !!onServiceClick && onServiceClick(service)}
                >
                  <Icon icon="chevron-right" />
                </IconButton>
              )}
            </React.Fragment>
          )}
        </div>
      </div>

      {((service.InventoryBatchesEnabled && IsAdminOfService) ||
        !!errorMessage) && (
        <div className={'bottomRoot'}>
          {!!errorMessage && (
            <FormHelperText error={errorMessage !== undefined}>
              {errorMessage}
            </FormHelperText>
          )}
          {service.InventoryBatchesEnabled && IsAdminOfService && (
            <InventoryBatchChip
              renderService={service}
              onServiceClick={onServiceClick}
              onAddBatchClick={onAddBatchClick}
              renewStock={renewStock}
              isAdmin={IsAdminOfService}
              handleServiceChange={handleChange}
              disabled={isEditMode && service.Id > 0}
              onRenewStockClick={onRenewStockClick}
            />
          )}
        </div>
      )}
    </QuickItem>
  );
});
const StyledBaseTextField = styled(BaseTextField)(({ theme }) => ({
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  //width: '56px',
  '& input': {
    maxWidth: '4ch',
    minWidth: 0,
    width: 'auto',
  },
}));
