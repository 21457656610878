import { IOfflineServiceTypeDto } from 'api/odata/generated/entities/IOfflineServiceTypeDto';
import { IAsset } from 'app/pages/AssetPopUp/IAsset';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Roles } from 'api/odata/generated/enums/Roles';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { intersection } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { ICoresDirectoryRow } from 'types/ICoresDirectoryRow';

export function useAdminPermissions() {
  const user = useSelector(selectAuthenticatedUser);
  const roles: number[] | undefined = user?.Roles;

  const allowedToEdit = React.useCallback(
    (
      row: Pick<IAsset, 'ServiceGroupId' | 'ServiceType' | 'ServiceId'>,
    ): boolean => {
      if (!user) return false;
      return (
        user.HasAdminGroupPermissions(row.ServiceGroupId ?? undefined) ||
        (row.ServiceType !== null &&
          row.ServiceId !== null &&
          user.HasAdminServicePermissions(
            ServiceType[row.ServiceType],
            row.ServiceId,
          ))
      );
    },
    [user],
  );
  const allowedToAdd = React.useCallback(
    (row: Pick<IAsset, 'ServiceGroupId'>): boolean => {
      if (!user) return false;
      return (
        user.HasCreateAdminGroupPermissions(row.ServiceGroupId ?? undefined) ||
        user.HasAdminGroupPermissions(row.ServiceGroupId ?? undefined)
      );
    },
    [user],
  );
  const allowedToEditOffline = React.useCallback(
    (row: Pick<IOfflineServiceTypeDto, 'ServiceGroupId' | 'Id'>): boolean => {
      if (!user) return false;
      return (
        user.HasAdminGroupPermissions(row.ServiceGroupId) ||
        user.HasAdminServicePermissions(ServiceType.Offline, row.Id)
      );
    },
    [user],
  );
  const allowedToAddOffline = React.useCallback(
    (row: Pick<IOfflineServiceTypeDto, 'ServiceGroupId'>): boolean => {
      if (!user) return false;
      return (
        user.HasCreateAdminGroupPermissions(row.ServiceGroupId) ||
        user.HasAdminGroupPermissions(row.ServiceGroupId)
      );
    },
    [user],
  );
  const allowedToEditBatch = React.useCallback(
    (row: Pick<IOfflineServiceTypeDto, 'ServiceGroupId' | 'Id'>): boolean => {
      if (!user) return false;
      return (
        user.HasAdminGroupPermissions(row.ServiceGroupId) ||
        user.HasAdminServicePermissions(ServiceType.Offline, row.Id) ||
        user.HasLabTechGroupPermissions(row.ServiceGroupId) ||
        user.HasLabTechServicePermissions(ServiceType.Offline, row.Id)
      );
    },
    [user],
  );
  const allowedToEditGroup = React.useCallback(
    (row: Pick<ICoresDirectoryRow, 'Id'>): boolean => {
      if (!user) return false;
      return user.HasAdminGroupOnlyPermissions(row.Id);
    },
    [user],
  );
  const allowedRoles = [
    Roles.Administrators,
    Roles.EquipmentAdministrator,
    Roles.GroupAdministrators,
    Roles.CreateNewAssetsAdmin,
  ];
  const hasRolePermissions: boolean =
    intersection(roles, allowedRoles).length > 0;
  return {
    hasRolePermissions,
    allowedToEdit,
    allowedToAdd,
    allowedToEditOffline,
    allowedToAddOffline,
    allowedToEditBatch,
    allowedToEditGroup,
  };
}
