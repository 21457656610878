import { useDispatch, useSelector } from 'react-redux';
import { useRequestSamplesSlice } from '../../RequestSamplesPage/slice';
import { selectServiceRequestDetailsFieldState } from '../../RequestSamplesPage/slice/selectors';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';
import { IServiceRequestMilestoneDto } from 'api/odata/generated/entities/IServiceRequestMilestoneDto';

export interface IUseServiceRequestStatusUpdateProps {
  serviceRequestStatusId: number;
}
export function useServiceRequestStatusUpdate(
  props: IUseServiceRequestStatusUpdateProps,
) {
  const { actions } = useRequestSamplesSlice();
  const dispatch = useDispatch();
  const changeStatus = (value: {
    status: IServiceRequestStatusDto;
    reason?: string;
  }) => {
    value !== null &&
      dispatch(
        actions.updateStatus({
          Id: props.serviceRequestStatusId,
          Status: value.status,
          Reason: value.reason,
        }),
      );
  };
  const fieldState = useSelector(state =>
    selectServiceRequestDetailsFieldState(state, 'Status'),
  );
  return { changeStatus, fieldState };
}
export const getNewMilestone = (
  serviceRequestId: number,
  milestones?: Array<IServiceRequestMilestoneDto>,
): IServiceRequestMilestoneDto => {
  const newMilestone: IServiceRequestMilestoneDto = {
    Name: '1',
    ServiceRequestId: serviceRequestId,
    Id: 0,
    Status: 'NotStarted',
    Charged: false,
    Assignees: [],
    ServiceRequestMilestoneCharges: [],
    CompletionDate: null,
    Description: null,
    TargetDate: null,
    InvoiceId: null,
    // Add other properties of the milestone here
  };
  if (!!milestones && milestones.length > 0) {
    // Reorder milestones by Id in ascending order
    const ordered = [...milestones];
    ordered.sort((a, b) => a.Id! - b.Id!);

    const latestMilestone = ordered[ordered.length - 1];
    const name = latestMilestone.Name ?? '';
    const numberRegex = /\d+/;
    const match = name.match(numberRegex);
    if (match) {
      const number = parseInt(match[0]);
      newMilestone.Name = name.replace(numberRegex, (number + 1).toString());
    } else {
      newMilestone.Name = `${latestMilestone.Name} 2`;
    }
  }
  return newMilestone;
};
