import { IAssetDetails2Dto } from 'api/odata/generated/entities/IAssetDetails2Dto';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { Entity } from 'types/common';
import { IMyAssetsRow } from '../MyAssetsPage/IMyAssetsRow';

export type IAsset = IAssetDetails2Dto;

export interface AlertInfo {
  show: boolean;
  hasActive: boolean;
  hasDownActive: boolean;
  noAlerts: boolean;
  assetId?: number;
  alertId?: number;
  isAdmin: boolean;
}

export interface AssetUser extends Entity<number> {
  DisplayName: string;
  Email: string;
  OfficeTel: string;
}
export interface IAssistedReservationHoursDto {
  Id: number;
  EquipmentId: number;
  DayOfWeek?: number;
  StartTime: Date;
  EndTime: Date;
  TrainerName: string;
  TrainerDisplayName: string;
}
export interface IRestrictions {
  Id: number;
  Name: string;
  AssetId: number | null;
  ServiceGroupId: number;
  LimitlessReservationAllowed: boolean | null;
  PrerequisiteEquipments: IAssetDto[];
  ForceTutoring: boolean;
  RestrictionsOverview: string | null;
  ReservationRestrictions: string | null;
  MinReservationOrder: number | null;
  MaxReservationOrder: number | null;
  ReservationMinimumGap: number | null;
  MinTimeAheadPeriodValue: number | null;
  MinTimeAheadPeriodType: number | null;
  MinTimeAheadMinutesSinceMidnight: number | null;
  BypassQuotas: boolean;
  BypassUserQuotasAndGaps: boolean;
  BypassMinResDurationD: boolean;
  BypassMinResDurationT: number | null;
  BypassUserQuotasAndGapsD: number | null;
  MinReservationOrderGap: number;
  UserReservationGap: number;
  UserGroupReservationGap: number;
  InstituteReservationGap: number;
  CarryOverTimeSlotGap: number | null;
  CarryOverTimeSlotTypeId: number | null;
  CarryOverTimeSlotTypeName: string | null;
  RestrictToTutoringHours: boolean;
  IsEquipmentTutoring: boolean;
  AssistedReservationHours: IAssistedReservationHoursDto[];
}
export const mapToAssetDto = (myAsset: IMyAssetsRow): IAssetDto => {
  return {
    Id: myAsset.Id,
    Name: myAsset.Name,
    AssetCatId: myAsset.AssetCatId,
    Availability: {
      Id: myAsset.AvailabilityTypeId,
      Name: myAsset.AvailabilityTypeName,
    },
    ServiceGroupId: myAsset.ServiceGroupId,
    ServiceGroupName: myAsset.ServiceGroupName,
    ServiceId: myAsset.ServiceId,
    ServiceTypeId: myAsset.ServiceTypeId,
    ServiceGroupAssetId: myAsset.ServiceGroupAssetId,
  } as IAssetDto;
};
