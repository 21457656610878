import { httpClient } from 'api/HttpClient';
import { IPermissionLevelDto } from 'api/odata/generated/entities/IPermissionLevelDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import * as React from 'react';
import { Entity } from 'types/common';

export interface PermissionLevelPickerProps
  extends Omit<AutocompletePickerProps<IPermissionLevelDto>, 'loadData'> {}

const url = '/api/odata/v4/PermissionLevelFilter';
export const initPermissionLevelData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (
  predicates: (string | Condition<IPermissionLevelDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
) =>
  getAutoCompleteLoadDataFn<IPermissionLevelDto>({
    url: url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: ['Id', 'Name', 'ServiceGroupId'],
    globalServiceGroupFilter:
      !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
        ? [
            new Condition<IPermissionLevelDto>(
              'ServiceGroupId',
              ODataOperators.Includes,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
    isOptionalServiceGroup: true,
  });
export const loadPermissionLevels = loadData;
export function PermissionLevelPicker(props: PermissionLevelPickerProps) {
  const innerLoadData = loadData(props.predicates, []);
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'PermissionLevelPickerId'}
      loadData={innerLoadData}
      {...props}
    />
  );
}
// export function TrainingLevelFilter(
//   equipments: IServiceFilterDto[],
//   trainingLevelPermissionsEnabled,
//   userPermissionsEnabled,
// ): (string | Condition<ITrainingLevelFilterDto>)[] {
//   const predicates: (string | Condition<ITrainingLevelFilterDto>)[] = [];
//   if (!!equipments && equipments.length > 0) {
//     if (trainingLevelPermissionsEnabled) {
//       predicates.push(
//         equipments
//           .map(eq => {
//             if (eq.UseServiceGroupTrainingLevels) {
//               return `(ServiceGroupId eq null or ServiceGroupId eq ${quoteODataValue(
//                 eq.ServiceGroupId,
//               )})`;
//             } else {
//               return `LevelEquipments/any(g: g eq ${quoteODataValue(eq.Id)})`;
//             }
//           })
//           .join(' or '),
//       );
//     } else if (userPermissionsEnabled) {
//       // noop - clients could not select training level that was not part of user or group permissions on selected equipment they needed on uchicago
//       // should be properly resolved in https://prog4biz.atlassian.net/browse/BKT-8190
//     }
//     if (predicates.length > 0) {
//       return predicates;
//     }
//     return [];
//   } else {
//     return [];
//   }
// }
