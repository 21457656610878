/**
 *
 * TopBar
 *
 */
import { AppBarProps, useTheme } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { TopToolbar } from './TopToolbar';
import { StyledTopBar } from './styled';
import { SIDE_PANEL_WIDTH } from 'styles/constantVariables';

import { DetectIsMobile } from 'utils/mobileDetect';
import { SubTopToolbar } from './SubTopToolbar';
export interface StyleTopBarProps {
  isOpen: boolean;
  drawerWidth: number;
  sidePanelCollapsed?: boolean;
}
export interface TopBarProps extends AppBarProps {
  isOpen: boolean;
  drawerWidth: number;
  sidePanelCollapsed?: boolean;
}

export const TopBar = React.memo(function TopBar(props: TopBarProps) {
  const { children, isOpen, drawerWidth, sidePanelCollapsed, ...rest } = props;
  const isMobile = DetectIsMobile();
  const theme = useTheme();
  return (
    <>
      <StyledTopBar
        theme={theme}
        drawerWidth={drawerWidth}
        sidePanelWidth={SIDE_PANEL_WIDTH}
        color="transparent"
        position="static"
        variant="elevation"
        elevation={0}
        className={clsx('appBar', {
          appBarShift: isOpen,
          appBarShiftShort: !isOpen && !isMobile,
          sidePanelShift: sidePanelCollapsed,
        })}
        {...rest}
      >
        <TopToolbar />
        {!isMobile && <SubTopToolbar />}
      </StyledTopBar>
    </>
  );
});
