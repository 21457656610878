/**
 *
 * LoginLayout
 *
 */
import { Copyright } from 'app/components/Copyright';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, useTheme } from '@material-ui/core/styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  AppBar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
} from '@material-ui/core';
import { BookitlabLogo } from 'app/components/BookitlabLogo';
import { toRootedURL } from 'utils/url-utils';
import { translations } from 'locales/translations';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { DetectIsMobile } from 'utils/mobileDetect';
import styled from 'styled-components';
import { StyledUserMenuExtended } from '../FrontendLayout/components/TopBar/styled';

export interface LoginLayoutProps {
  children: React.ReactNode;
}

export function LoginLayout({ children }: LoginLayoutProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <LoginLayoutWrapper>
      <AppBar color="default" elevation={0} position="relative">
        <Toolbar>
          <div className={'logo-div'} onClick={event => handleClick(event)}>
            <BookitlabLogo />
          </div>
          <LogoMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
            theme={theme}
          />
        </Toolbar>
      </AppBar>
      <div className={'container'}>
        <div className={'main'}>
          <div className={'styled-div'}>{children}</div>
        </div>
      </div>
      <div className={'footer-div'}>
        <Copyright />
      </div>
    </LoginLayoutWrapper>
  );
}

const LoginLayoutWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  & .container {
    flex-grow: 1;
    & .main {
      height: 80dvh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
        & .styled-div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-width: 40%;
            & p, & h1 {
                padding: 8px
            },
        }
    }
  }
  & .logo-div {
    width: 100%;
  }
  & .footer-div {
    width: 100%;
    display: block;
    justify-content: center;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 1280px) {
      max-width: 1280px;
    }
    @media (min-width: 600px) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
`;

export interface LogoMenuProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  theme: Theme;
}
const LogoMenu = React.memo(function LogoMenuFunc(props: LogoMenuProps) {
  const { t } = useTranslation();
  const isMobile = DetectIsMobile();
  return (
    <>
      <StyledUserMenuExtended
        theme={props.theme}
        marginThreshold={1}
        id="user-profile-menu"
        anchorEl={props.anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(props.anchorEl)}
        isMobile={isMobile}
        onClose={() => props.handleClose()}
      >
        <MenuItem
          onClick={() => props.handleClose()}
          component={'a'}
          href={toRootedURL('/account/signout')}
        >
          <ListItemIcon>
            <Icon icon="sign-out-alt" color="default" colorExtend="textHover" />
          </ListItemIcon>
          <ListItemText>
            {t(translations.main_master_LogOutLinkText)}
          </ListItemText>
        </MenuItem>
      </StyledUserMenuExtended>
    </>
  );
});
