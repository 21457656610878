/**
 *
 * AsyncPicker
 *
 */
import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import {
  RoomPicker,
  RoomPickerProps,
  RoomsUrl,
} from '../pickers/AutocompletePickers/RoomPicker';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { IRoomDto } from 'api/odata/generated/entities/IRoomDto';

export const FormRoomPicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<number> | null> &
  IFieldProps & { variant?: 'standard' | 'filled' | 'outlined' } & {
    urlType?: RoomsUrl;
    withoutServiceGroups?: boolean;
    error?: string;
    getOptionSelected?: RoomPickerProps['getOptionSelected'];
    getValue?: (val) => Entity<number> | null;
    setValue?: (val: Entity<number> | null) => any;
  }) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  const handleChange = val => {
    const value = !!props.setValue ? props.setValue(val) : val;
    helpers.setValue(value, true);
    props.onChange?.(value);
  };
  return (
    <>
      <RoomPicker
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        onChange={handleChange}
        onBlur={field?.onBlur}
        value={
          (!!props.getValue
            ? props.getValue(field.value)
            : field.value) as IRoomDto
        }
        error={meta?.error !== undefined || props.error !== undefined}
        helperText={meta.error ?? props.error}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        info={props.info}
        variant={props.variant}
        urlType={props.urlType}
        withoutServiceGroups={props.withoutServiceGroups}
        getOptionSelected={props.getOptionSelected}
      />
    </>
  );
};
