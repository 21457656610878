import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { Entity } from 'types/common';
import * as Yup from 'yup';

export interface IArticleNewEditForm {
  Title: string;
  Authors: string;
  Abstract: string;
  Doi?: string | null;
  Dataset?: string | null;
  ISSN?: string | null;
  ORCIDs?: string | null;
  Year: number;
  RelatedUser: Entity<string> | null;
  ServiceGroupAsset: IAssetDto | null;
  Journal?: string | null;
  PageNumber?: number | null;
  //ServiceGroupAssets: Array<IAssetDto>;
  InstrumentAssets: Array<IAssetDto>;
  Status: Entity<number>;
  CalibrationRecord: Entity<number> | null;
}

export const assetSchema = Yup.mixed();
export const assetsSchema = Yup.array().of(
  Yup.mixed() as Yup.SchemaOf<IAssetDto>,
);

export const GetInstrumentFilter = (serviceGroups: IAssetDto[]) => {
  const sv = serviceGroups.filter(f => f !== null && f !== undefined);
  if (sv.length > 0) {
    const f = [
      `(ServiceGroupId in (${sv.map(f => f.ServiceGroupId).join(',')}))`,
    ];
    return f;
  } else {
    return [];
  }
};
export const GetCalibrationRecordFilter = (assets: IAssetDto[]) => {
  const services = assets.filter(f => f !== null && f !== undefined);
  if (services.length > 0) {
    const test = services.map(f => f.Id);
    const test_s = test.join(',');
    const f = [`(AssetId in (${test_s}))`];
    return f;
  } else {
    return [];
  }
};
