/**
 *
 * RegionFilter
 *
 */

import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from 'app/slice/selectors';
import { RegionPicker } from 'app/components/pickers/AutocompletePickers/RegionPicker';

export const RegionFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  const isAuth = useSelector(selectIsAuthenticated);
  return (
    <RegionPicker
      onChange={handleOnChange}
      urlType={isAuth === true ? props.urlType ?? 'base' : 'public'}
      value={props.value}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
      fullWidth={props.fullWidth}
      ariaLabel={props.label}
      id={props.id}
      predicates={props.predicates}
      size="small"
      onPickerOpen={props.onOpen}
    />
  );
};
export default RegionFilter;
