import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { selectPublicUrl } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { buildURL } from 'utils/url-utils';
import { Link as MuiLink } from '@material-ui/core';
import { InvoiceDetailsProps } from 'app/pages/InvoiceDetails';
import { DetectIsMobile } from 'utils/mobileDetect';
import { selectActiveFilters } from 'app/pages/InvoiceDetails/Slice/selector';

export const InvoiceDetailsLink = (props: {
  id: number;
  children: React.ReactNode;
  title?: string;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
}) => {
  const { t } = useTranslation();
  const openDetails = useOpenInvoiceDetailsPopUp();
  const getLink = useInvoiceLinkUrl();
  const filters = useSelector(selectActiveFilters);

  const link = getLink(props.id, filters);
  const open = () => {
    openDetails(
      {
        id: props.id,
        useSidePanel: props.useSidePanel,
      },
      props.openPanelWithCover,
    );
  };
  return (
    <Tooltip title={props.title ?? t(translations.InvoiceDetails)}>
      <MuiLink
        onClick={event => {
          if (!!link) {
            if (!event.ctrlKey) {
              event.preventDefault();
              open();
            }
          } else {
            open();
          }
        }}
        color="textPrimary"
        href={link}
      >
        {props.children}
      </MuiLink>
    </Tooltip>
  );
};

export const useOpenInvoiceDetailsPopUp = () => {
  const { openPanel } = useSidePanelState();
  const isMobile = DetectIsMobile();
  return React.useCallback(
    (
      props: { id: number; useSidePanel?: boolean },
      openPanelWithCover?: (state: SidePanelOpenState) => void,
    ) => {
      let resProps = {
        id: props.id,
        useSidePanel: true,
      } as InvoiceDetailsProps;

      const panelState = {
        renderPageType: RenderPageType.InvoiceDetails,
        renderPageProps: resProps,
        expanded: !isMobile && !props.useSidePanel,
        useSidePanel: props.useSidePanel,
        isCover: props.useSidePanel,
      } as SidePanelOpenState;
      if (!!openPanelWithCover) {
        openPanelWithCover(panelState);
      } else {
        openPanel({
          renderPageType: RenderPageType.InvoiceDetails,
          renderPageProps: resProps,
          expanded: !isMobile && !props.useSidePanel,
          useSidePanel: false,
          isCover: false,
        } as SidePanelOpenState);
      }
    },
    [isMobile, openPanel],
  );
};
export const useInvoiceLinkUrl = () => {
  const publicUrl = useSelector(selectPublicUrl);
  return React.useCallback(
    (id: number, params?: Record<string, any>) => {
      return buildURL(publicUrl + `invoices/details/${id}`, params ?? {});
    },
    [publicUrl],
  );
};
