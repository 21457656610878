import { toRootedURL } from 'utils/url-utils';
import { DemSize } from '../BasicCards';
export type ImageSize =
  | 'full'
  | 'fullLarge'
  | 'thumb'
  | 'thumbSmall'
  | 'thumbLarge'
  | 'horizontal'
  | 'horizontalLarge'
  | 'vertical'
  | 'verticalLarge'
  | 'Large';

export function getAssetImageThumbSrc(
  imageName: string | null,
  imageSize?: ImageSize,
  imageExist?: boolean,
  defaultImage?: string,
): string | undefined {
  if (imageName === null || imageExist === false) {
    return toRootedURL(defaultImage ?? getDefaultImage(imageSize));
  }
  switch (imageSize) {
    case 'full':
    case 'fullLarge':
      return toRootedURL(`/uploads/assets/${imageName}`);
    case 'horizontal':
      return toRootedURL(`/uploads/assets/cardhorizontal/${imageName}`);
    case 'horizontalLarge':
      return toRootedURL(`/uploads/assets/cardhorizontallarge/${imageName}`);
    case 'vertical':
      return toRootedURL(`/uploads/assets/cardvertical/${imageName}`);
    case 'verticalLarge':
      return toRootedURL(`/uploads/assets/cardverticallarge/${imageName}`);
    case 'thumbSmall':
      return toRootedURL(`/uploads/assets/thumbsSmall/${imageName}`);
    case 'thumbLarge':
      return toRootedURL(`/uploads/assets/thumbsLarge/${imageName}`);
    case 'Large':
      return toRootedURL(`/uploads/assets/large/${imageName}`);
    case 'thumb':
    default:
      return toRootedURL(`/uploads/assets/thumbs/${imageName}`);
  }
}
export function getDefaultImage(size?: ImageSize) {
  switch (size) {
    case 'full':
      return `/images/200x150.png`;
    case 'fullLarge':
      return `/images/400x300.png`;
    case 'thumb':
      return `/images/64x64.png`;
    case 'thumbSmall':
      return `/images/64x38.png`;
    case 'thumbLarge':
      return `/images/80x80.png`;
    case 'vertical':
      return `/images/278x174.png`;
    case 'verticalLarge':
      return `/images/457x240.png`;
    case 'horizontal':
      return `/images/180x201.png`;
    case 'horizontalLarge':
      return `/images/207x201.png`;
    case 'Large':
      return `/images/1200x800.png`;
    case undefined:
    default:
      return `/images/64x64.png`;
  }
}
export const getDefaultImgSize = (size?: ImageSize): DemSize => {
  switch (size) {
    case 'full':
      return { height: 150, width: 200 };
    case 'fullLarge':
      return { height: 300, width: 400 };
    case 'thumb':
      return { height: 64, width: 64 };
    case 'thumbSmall':
      return { height: 64, width: 38 };
    case 'thumbLarge':
      return { height: 80, width: 80 };
    case 'vertical':
      return { height: 278, width: 174 };
    case 'verticalLarge':
      return { height: 457, width: 240 };
    case 'horizontal':
      return { height: 180, width: 201 };
    case 'horizontalLarge':
      return { height: 207, width: 201 };
    case 'Large':
      return { height: 800, width: 1200 };
    case undefined:
    default:
      return { height: 64, width: 64 };
  }
};
export function getAssetImageSrc(
  imageName: string | null,
  imageExist?: boolean,
  defaultImage?: string,
): string | undefined {
  if (imageName === null || imageExist === false) {
    return toRootedURL(defaultImage ?? `/images/200x150.png`);
  }
  return toRootedURL(`/uploads/assets/${imageName}`);
}
