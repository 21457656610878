import {
  IComment,
  NewComment,
} from 'app/components/CommentsContainer/IComment';
import { IResponseType } from 'types/ResponseType';
import { httpClient } from './HttpClient';
const url = '/api/odata/v4/Comments';
export const CommentsApi = {
  GetComments: (
    type: number,
    reference: number,
    Id?: number,
    referenceString?: string,
  ) => {
    return httpClient
      .get(url, {
        reference,
        type,
        referenceString: referenceString ?? '',
        $filter: Id !== undefined ? 'Id eq ' + Id?.toString() : undefined,
        $expand: ['Files', 'CreatedBy'].join(','),
        $orderBy: 'CreatedAt desc',
      })
      .then(v => v.value as IComment[]);
  },
  SendComment: (
    typeId: number,
    referenceId: number,
    { Files, Text, IsInternal }: Omit<NewComment, 'Users'>,
    refernceString?: string,
    commentId?: number,
  ) => {
    const form = new FormData();
    for (let i = 0; !!Files && i < Files?.length; i++) {
      let item = Files?.item(i);
      if (!!item) {
        form.append('file' + i, item);
      }
    }
    form.append('TypeId', typeId as any);
    form.append('ReferenceId', referenceId as any);
    form.append('ReferenceStringId', refernceString as any);
    form.append('Text', Text ?? '');
    form.append('IsInternal', !!IsInternal as any);
    form.append('CommentId', commentId as any);

    return httpClient.post(url, form);
  },
  removeFile: (id: string) => {
    const delteUrl = `/odata.svc/Files(guid'${id}')`;
    return httpClient.delete(delteUrl);
  },
  DeleteComment: (id: number): Promise<IResponseType> => {
    const delteUrl = `/api/odata/v4/Comments(${id})`;
    return httpClient.delete(delteUrl);
  },
};
