/**
 *
 * BarcodeLogStatusFilter
 *
 */
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { BarcodeLogStatusPicker } from 'app/components/pickers/StaticOptionsPickers/BarcodeLogStatusPicker';

export const BarcodeLogStatusFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<string> | null) => {
    props.onChange(value);
  };

  const { t } = useTranslation();
  return (
    <BarcodeLogStatusPicker
      value={props.value}
      onChange={handleOnChange}
      isOpen={props.open}
      placeholder={t(translations.AllStatuses)}
      ariaLabel={props.label}
      id={props.id}
      size="small"
      predicates={props.predicates}
      onPickerOpen={props.onOpen}
      fullWidth={props.fullWidth}
    />
  );
};
export default BarcodeLogStatusFilter;
