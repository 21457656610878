/**
 *
 * auto generated interface for Default.ReservationStatus
 *
 */

export enum ReservationStatus {
  Approved = 1,
  Pending = 2,
  Cancelled = 3,
  AllStatus = 4,
  CancelledByAdmin = 5,
  Done = 6,
  NotDone = 7,
  DoneWOBooking = 8,
  ApprovedOnce = 9,
  PendingCoord = 10,
  Tentative = 11,
  Draft = 12,
  NA = 13,
}
export type ReservationStatusUnion = keyof typeof ReservationStatus;
export const ReservationStatusUnionArray: Array<ReservationStatusUnion> = [
  'Approved',
  'Pending',
  'Cancelled',
  'AllStatus',
  'CancelledByAdmin',
  'Done',
  'NotDone',
  'DoneWOBooking',
  'ApprovedOnce',
  'PendingCoord',
  'Tentative',
  'Draft',
  'NA',
];
