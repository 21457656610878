import { IUtils } from '@date-io/core/IUtils';
import { validate } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DateDropDownValidationProps } from 'app/components/BookitDatePicker/DateDropDown';
import { dateUtils } from 'utils/date-utils';

export const validateError = (
  date: MaterialUiPickersDate | ParsableDate,
  propss: any,
  utils: IUtils<MaterialUiPickersDate>,
) => {
  let error = validate(date, utils, propss);
  if (error === '') {
    return undefined;
  }
  return error;
};
export const validateDate = (
  day: number,
  month: number,
  year: number,
  props: DateDropDownValidationProps,
  utils: IUtils<MaterialUiPickersDate>,
  currentDate?: Date | string | null,
): React.ReactNode | undefined => {
  if (currentDate !== null && currentDate !== undefined) {
    let date = dateUtils.truncateTime(
      dateUtils.dateOrStringToDate(currentDate),
    );
    let opDate = dateUtils.set(date, {
      year: year,
      month: month,
    });
    let maxMonthDays = dateUtils.getDaysInMonth(opDate);
    if (day > maxMonthDays) {
      return `Invalid Date: The day can not be longer than ${maxMonthDays}.`;
    } else {
      let finalDate = dateUtils.set(opDate, { date: day });
      return validateError(
        finalDate,
        {
          maxDate: props.maxDate,
          minDate: props.minDate,
          disablePast: props.disablePast,
          disableFuture: props.disableFuture,
          maxDateMessage: props.maxDateMessage,
          minDateMessage: props.minDateMessage,
          invalidDateMessage: props.invalidDateMessage,
          strictCompareDates: props.strictCompareDates,
        },
        utils,
      );
    }
  } else {
    return undefined;
  }
};
export const validateTime = (
  hours: number,
  minutes: number,
  props: DateDropDownValidationProps,
  utils: IUtils<MaterialUiPickersDate>,
  currentDate?: Date | string | null,
  ampm?: boolean,
): React.ReactNode | undefined => {
  if (currentDate !== null && currentDate !== undefined) {
    let date = dateUtils.dateOrStringToDate(currentDate);
    let opDate = dateUtils.set(date, {
      hours: hours,
      minutes: minutes,
    });
    let finalDate = dateUtils.convertToMeridiem(
      opDate,
      dateUtils.getMeridiem(date),
      ampm ?? false,
    );
    return validateError(
      finalDate,
      {
        maxDate: props.maxDate,
        minDate: props.minDate,
        disablePast: props.disablePast,
        disableFuture: props.disableFuture,
        maxDateMessage: props.maxDateMessage,
        minDateMessage: props.minDateMessage,
        invalidDateMessage: props.invalidDateMessage,
        strictCompareDates: props.strictCompareDates,
      },
      utils,
    );
  } else {
    return undefined;
  }
};
export const checkDateInput = (
  dateStart: 'day' | 'month' | 'year',
  val?: string | null,
) => {
  if (val === null || val === undefined) {
    return true;
  }
  //eslint-disable-next-line
  const regexDDMMYYYY = RegExp(
    //eslint-disable-next-line
    '^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$',
  );
  const regexYYYYMMdd = RegExp(
    //eslint-disable-next-line
    '^([0-9]{4}[-/]?((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00)[-/]?02[-/]?29)$',
  );
  const regexMMDDYYYY = RegExp(
    //eslint-disable-next-line
    `^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$`,
  );
  let res =
    dateStart === 'day'
      ? regexDDMMYYYY.test(val)
      : dateStart === 'month'
      ? regexMMDDYYYY.test(val)
      : regexYYYYMMdd.test(val);
  return res;
};
