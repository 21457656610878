/**
 *
 * BudgetPicker
 *
 */
import { IServiceBaseDto } from 'api/odata/generated/entities/IServiceBaseDto';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import {
  BudgetPicker,
  BudgetPickerProps,
  restrictedBudgetFilter,
} from 'app/components/pickers/AutocompletePickers/BudgetPicker';
import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';
import useGlobalSettingsHook from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import * as React from 'react';
import { useSelector } from 'react-redux';

export interface EventBudgetPickerProps extends BudgetPickerProps {
  service: IServiceBaseDto;
  userGroupId: string;
  userId: string;
  isAdmin: boolean;
  fundingType: number | null;
}

/**
 * Budget picker that can be used to select a budget applicable to bookable events like reservations, offline services, requests, etc.
 * @param param0
 * @returns
 */
export function EventBudgetPicker({
  predicates,
  ...props
}: EventBudgetPickerProps) {
  const globalSettings = useGlobalSettingsHook();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const ReservationUserGroupGroupByBudgetUserGroup = React.useMemo(() => {
    return (
      globalSettings.reservationUserGroupGroupByBudgetUserGroup &&
      !props.service.HideProjects
    );
  }, [
    props.service,
    globalSettings.reservationUserGroupGroupByBudgetUserGroup,
  ]);
  const eventPredicates = React.useMemo(() => {
    const result = restrictedBudgetFilter({
      budgetLimitedByUsers: globalSettings.budgetLimitedByUsers,
      isBudgetsWithoutUserGroup: globalSettings.isBudgetsWithoutUserGroup,
      userGroupId: ReservationUserGroupGroupByBudgetUserGroup
        ? undefined
        : props.userGroupId,
      serviceGroupId: undefined,
      services: [props.service].map(f => {
        return {
          Id: f.Id,
          Name: f.Name,
          ServiceTypeId: ServiceType[f.ServiceType],
          ServiceGroupId: f.ServiceGroup.Id,
          HideProject: f.HideProjects,
        } as IServiceTypeFilterDto;
      }),
      startTime: undefined,
      endTime: undefined,
      user: props.isAdmin
        ? props.userId === null
          ? undefined
          : props.userId
        : authenticatedUser?.Id,
      defaultBudgetEnabled: globalSettings.defaultBudgetEnabled,
      userDefaultBudgetEnabled: globalSettings.userDefaultBudgetEnabled,
      hideNonDefaultBudgets: globalSettings.hideNonDefaultBudgets,
      // funding type is set to Internal by default even if it's not in use
      fundingType:
        props.fundingType === null
          ? null
          : getSingleInvoiceFundingType(props.fundingType),
    });
    return result.concat(predicates ?? []);
  }, [
    globalSettings.budgetLimitedByUsers,
    globalSettings.isBudgetsWithoutUserGroup,
    globalSettings.defaultBudgetEnabled,
    globalSettings.userDefaultBudgetEnabled,
    globalSettings.hideNonDefaultBudgets,
    ReservationUserGroupGroupByBudgetUserGroup,
    props.userGroupId,
    props.service,
    props.isAdmin,
    props.userId,
    props.fundingType,
    authenticatedUser?.Id,
    predicates,
  ]);
  return <BudgetPicker predicates={eventPredicates} {...props} />;
}
