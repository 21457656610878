import { BaseTextFieldProps, capitalize } from '@material-ui/core';
import { BaseDatePickerProps, useUtils } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FormControl from 'app/components/Forms/FormControls/FormControl';
import FormHelperText from 'app/components/Forms/FormControls/FormHelperText';
import { InputLabel } from '@material-ui/core';
import {
  DaysByMonthPicker,
  defaultDayValue,
  getDayEntityFromDate,
} from 'app/components/pickers/StaticOptionsPickers/DaysByMonthPicker';
import {
  defaultMonthValue,
  getMonthEntityFromDate,
  MonthOfYearPicker,
} from 'app/components/pickers/StaticOptionsPickers/MonthOfYearPicker';
import {
  defaultYearValue,
  getYearEntityFromDate,
  YearsPicker,
} from 'app/components/pickers/StaticOptionsPickers/YearsPicker';
import * as React from 'react';

import clsx from 'clsx';
import { Entity } from 'types/common';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { dateUtils } from 'utils/date-utils';
import { DateValidationProps } from '@material-ui/pickers/_helpers/text-field-helper';
import { validateDate } from 'app/components/DatePicker/utils/pickerDateValidate';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

export interface DateDropDownValidationProps
  extends DateValidationProps,
    Pick<
      BaseDatePickerProps,
      | 'minDate'
      | 'maxDate'
      | 'disablePast'
      | 'disableFuture'
      | 'strictCompareDates'
    > {}
export interface DateDropDownProps
  extends DateDropDownValidationProps,
    Pick<
      BaseTextFieldProps,
      | 'fullWidth'
      | 'helperText'
      | 'required'
      | 'disabled'
      | 'name'
      | 'id'
      | 'label'
      | 'error'
      | 'size'
    > {
  value?: Date | string | null;
  onChange: (
    date: MaterialUiPickersDate | null,
    withCompletion?: boolean,
  ) => void;
  inputVariant?: 'filled' | 'standard' | 'outlined';
  info?: string;
  className?: string;
  dayDisabled?: boolean;
  monthDisabled?: boolean;
  yearDisabled?: boolean;
  onError?: (
    error: React.ReactNode,
    value: MaterialUiPickersDate | ParsableDate,
  ) => void;
}

export function DateDropDown(props: DateDropDownProps) {
  const {
    value,
    onChange,
    inputVariant,
    id,
    name,
    fullWidth,
    helperText,
    disabled,
    label,
    error,
    size,
    dayDisabled,
    monthDisabled,
    yearDisabled,
    onError,
  } = props;
  const { newDate } = useSystemDate();
  const utils = useUtils();
  const inputLabelId = label && id ? `${id}-label` : undefined;
  const helperTextId = id ? `${id}-helper-text` : undefined;
  const sizeClass = `inputSize${capitalize(size ?? 'large')}`;
  const dateTimeValue = React.useMemo(() => {
    return value;
  }, [value]);
  const [dateSelected, setDateSelected] = React.useState<
    Date | string | null | undefined
  >(value);
  const [daySelected, setDaySelected] = React.useState(
    getDayEntityFromDate(dateTimeValue),
  );
  const [monthSelected, setMonthSelected] = React.useState(
    getMonthEntityFromDate(dateTimeValue),
  );
  const [yearSelected, setYearSelected] = React.useState(
    getYearEntityFromDate(dateTimeValue),
  );
  const [innerError, setInnerError] = React.useState<
    React.ReactNode | undefined
  >(undefined);
  const [isYearOpen, setIsYearOpen] = React.useState<boolean>(false);
  const [isMonthOpen, setIsMonthOpen] = React.useState<boolean>(false);
  const [isDayOpen, setIsDayOpen] = React.useState<boolean>(false);
  const someOpen = React.useMemo(() => {
    return isYearOpen || isMonthOpen || isDayOpen;
  }, [isDayOpen, isMonthOpen, isYearOpen]);
  // const dateStart = React.useMemo(() => {
  //   return utils.dateFormat.toLowerCase().startsWith('m')
  //     ? 'fromMonth'
  //     : utils.dateFormat.toLowerCase().startsWith('d')
  //     ? 'fromDay'
  //     : utils.dateFormat.toLowerCase().startsWith('y')
  //     ? 'fromYear'
  //     : 'fromDay';
  // }, [utils.dateFormat]);
  React.useEffect(() => {
    setDaySelected(() => getDayEntityFromDate(dateTimeValue));
    setMonthSelected(() => getMonthEntityFromDate(dateTimeValue));
    setYearSelected(() => getYearEntityFromDate(dateTimeValue));
  }, [dateTimeValue]);

  React.useEffect(() => {
    if (dateSelected !== null && dateSelected !== undefined) {
      let error = validateDate(
        daySelected.Id,
        monthSelected.Id,
        yearSelected.Id,
        {
          maxDate: props.maxDate,
          minDate: props.minDate,
          disablePast: props.disablePast,
          disableFuture: props.disableFuture,
          maxDateMessage: props.maxDateMessage,
          minDateMessage: props.minDateMessage,
          invalidDateMessage: props.invalidDateMessage,
          strictCompareDates: props.strictCompareDates,
        },
        utils,
        dateSelected,
      );
      if (!!error) {
        setInnerError(error);
        if (onError) {
          onError(error, dateSelected);
        }
      } else {
        setInnerError('');
        if (onError) {
          onError('', dateSelected);
        }
      }
    } else {
      setInnerError('');
      if (onError) {
        onError('', null);
      }
    }
  }, [
    dateSelected,
    daySelected.Id,
    monthSelected.Id,
    onError,
    props.disableFuture,
    props.disablePast,
    props.invalidDateMessage,
    props.maxDate,
    props.maxDateMessage,
    props.minDate,
    props.minDateMessage,
    props.strictCompareDates,
    utils,
    yearSelected.Id,
  ]);
  const handleChange = React.useCallback(
    (day: number, month: number, year: number, withCompletion?: boolean) => {
      if (dateSelected !== null && dateSelected !== undefined) {
        let date = dateUtils.dateOrStringToDate(dateSelected);
        let finalDate = dateUtils.set(date, {
          year: year,
          month: month,
          date: day,
        });
        let hasError = validateDate(
          day,
          month,
          year,
          {
            maxDate: props.maxDate,
            minDate: props.minDate,
            disablePast: props.disablePast,
            disableFuture: props.disableFuture,
            maxDateMessage: props.maxDateMessage,
            minDateMessage: props.minDateMessage,
            invalidDateMessage: props.invalidDateMessage,
            strictCompareDates: props.strictCompareDates,
          },
          utils,
          finalDate,
        );
        if (!hasError) {
          onChange(finalDate, withCompletion);
        }
      } else {
        let finalDate = dateUtils.set(newDate(), {
          year: year,
          month: month,
          date: day,
        });
        setDateSelected(finalDate);
        let hasError = validateDate(
          day,
          month,
          year,
          {
            maxDate: props.maxDate,
            minDate: props.minDate,
            disablePast: props.disablePast,
            disableFuture: props.disableFuture,
            maxDateMessage: props.maxDateMessage,
            minDateMessage: props.minDateMessage,
            invalidDateMessage: props.invalidDateMessage,
            strictCompareDates: props.strictCompareDates,
          },
          utils,
          finalDate,
        );
        if (!hasError) {
          onChange(finalDate, withCompletion);
        }
      }
    },
    [
      dateSelected,
      newDate,
      onChange,
      props.disableFuture,
      props.disablePast,
      props.invalidDateMessage,
      props.maxDate,
      props.maxDateMessage,
      props.minDate,
      props.minDateMessage,
      props.strictCompareDates,
      utils,
    ],
  );
  const handleDayChange = React.useCallback(
    (val: Entity<number>) => {
      setDaySelected(val);
      handleChange(val.Id, monthSelected.Id, yearSelected.Id);
    },
    [handleChange, monthSelected.Id, yearSelected.Id],
  );
  const handleMonthChange = React.useCallback(
    (val: Entity<number>) => {
      setMonthSelected(val);
      handleChange(daySelected.Id, val.Id, yearSelected.Id);
    },
    [daySelected.Id, handleChange, yearSelected.Id],
  );
  const handleYearChange = React.useCallback(
    (val: Entity<number>) => {
      setYearSelected(val);
      handleChange(daySelected.Id, monthSelected.Id, val.Id);
    },
    [daySelected.Id, handleChange, monthSelected.Id],
  );
  return (
    <React.Fragment>
      <FormControl
        className={clsx('date-dropdown-picker', sizeClass, {
          withLabel: !!label,
        })}
        disabled={disabled}
        error={error || Boolean(innerError)}
        fullWidth={fullWidth}
        required={props.required}
        variant={inputVariant || 'standard'}
        size={'medium'}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
          const target = event.target as any; // as HTMLInputElement;

          if (event.key === 'Enter') {
            if (!!target && target.nodeName === 'INPUT') {
              if (!someOpen && !innerError) {
                handleChange(
                  daySelected.Id,
                  monthSelected.Id,
                  yearSelected.Id,
                  true,
                );
              }
            }
          }
        }}
      >
        {label && (
          <InputLabel
            htmlFor={id}
            id={inputLabelId}
            className={clsx('date-dropdown-picker', sizeClass)}
            required={props.required}
            error={error || Boolean(innerError)}
            disabled={props.disabled}
          >
            {label}
          </InputLabel>
        )}
        <div className={'date-dropdown-picker-inputs'}>
          <DaysByMonthPicker
            month={monthSelected.Id}
            placeholder={'DD'}
            defaultValue={defaultDayValue}
            value={daySelected}
            onChange={day => handleDayChange(day ?? defaultDayValue)}
            id={`${id ?? 'dayId'}_day`}
            name={`${name ?? 'dayName'}_day`}
            variant={inputVariant ?? 'standard'}
            disabled={disabled || dayDisabled}
            ariaLabel={`${label ?? 'day'} select`}
            error={error}
            size={size || 'small'}
            disableClearable
            autoFocus={true}
            setSomeOpen={setIsDayOpen}
          />
          <MonthOfYearPicker
            defaultValue={defaultMonthValue}
            value={monthSelected}
            placeholder={'MMM'}
            onChange={month => handleMonthChange(month ?? defaultMonthValue)}
            id={`${id ?? 'monthId'}_month`}
            name={`${name ?? 'monthName'}_month`}
            variant={inputVariant ?? 'standard'}
            disabled={disabled || monthDisabled}
            ariaLabel={`${label ?? 'month'} select`}
            error={error}
            size={size || 'small'}
            disableClearable
            setSomeOpen={setIsMonthOpen}
            //autoFocus={dateStart === 'fromMonth'}
          />
          <YearsPicker
            defaultValue={defaultYearValue}
            value={yearSelected}
            placeholder={'YYYY'}
            onChange={year => handleYearChange(year ?? defaultYearValue)}
            id={`${id ?? 'yearId'}_year`}
            name={`${name ?? 'yearName'}_year`}
            variant={inputVariant ?? 'standard'}
            disabled={disabled || yearDisabled}
            ariaLabel={`${label ?? 'year'} select`}
            error={error}
            size={size || 'small'}
            disableClearable
            setSomeOpen={setIsYearOpen}
            //autoFocus={dateStart === 'fromYear'}
          />
        </div>
        {/* <div className={'date-dropdown-picker-inputs'}>
          {dateStart === 'fromYear' ? (
            <React.Fragment>
              <YearsPicker
                defaultValue={defaultYearValue}
                value={yearSelected}
                placeholder={'YYYY'}
                onChange={year => handleYearChange(year ?? defaultYearValue)}
                id={`${id ?? 'yearId'}_year`}
                name={`${name ?? 'yearName'}_year`}
                variant={inputVariant ?? 'standard'}
                disabled={disabled || yearDisabled}
                ariaLabel={`${label ?? 'year'} select`}
                error={error}
                size={size || 'small'}
                disableClearable
                autoFocus={true}
              />
              <MonthOfYearPicker
                defaultValue={defaultMonthValue}
                value={monthSelected}
                placeholder={'MMM'}
                onChange={month =>
                  handleMonthChange(month ?? defaultMonthValue)
                }
                id={`${id ?? 'monthId'}_month`}
                name={`${name ?? 'monthName'}_month`}
                variant={inputVariant ?? 'standard'}
                disabled={disabled || monthDisabled}
                ariaLabel={`${label ?? 'month'} select`}
                error={error}
                size={size || 'small'}
                disableClearable
              />
              <DaysByMonthPicker
                month={monthSelected.Id}
                placeholder={'DD'}
                defaultValue={defaultDayValue}
                value={daySelected}
                onChange={day => handleDayChange(day ?? defaultDayValue)}
                id={`${id ?? 'dayId'}_day`}
                name={`${name ?? 'dayName'}_day`}
                variant={inputVariant ?? 'standard'}
                disabled={disabled || dayDisabled}
                ariaLabel={`${label ?? 'day'} select`}
                error={error}
                size={size || 'small'}
                disableClearable
              />
            </React.Fragment>
          ) : dateStart === 'fromMonth' ? (
            <React.Fragment>
              <MonthOfYearPicker
                defaultValue={defaultMonthValue}
                value={monthSelected}
                placeholder={'MMM'}
                onChange={month =>
                  handleMonthChange(month ?? defaultMonthValue)
                }
                id={`${id ?? 'monthId'}_month`}
                name={`${name ?? 'monthName'}_month`}
                variant={inputVariant ?? 'standard'}
                disabled={disabled || monthDisabled}
                ariaLabel={`${label ?? 'month'} select`}
                error={error}
                size={size || 'small'}
                disableClearable
                autoFocus={true}
              />
              <DaysByMonthPicker
                month={monthSelected.Id}
                placeholder={'DD'}
                defaultValue={defaultDayValue}
                value={daySelected}
                onChange={day => handleDayChange(day ?? defaultDayValue)}
                id={`${id ?? 'dayId'}_day`}
                name={`${name ?? 'dayName'}_day`}
                variant={inputVariant ?? 'standard'}
                disabled={disabled || dayDisabled}
                ariaLabel={`${label ?? 'day'} select`}
                error={error}
                size={size || 'small'}
                disableClearable
              />
              <YearsPicker
                defaultValue={defaultYearValue}
                value={yearSelected}
                placeholder={'YYYY'}
                onChange={year => handleYearChange(year ?? defaultYearValue)}
                id={`${id ?? 'yearId'}_year`}
                name={`${name ?? 'yearName'}_year`}
                variant={inputVariant ?? 'standard'}
                disabled={disabled || yearDisabled}
                ariaLabel={`${label ?? 'year'} select`}
                error={error}
                size={size || 'small'}
                disableClearable
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <DaysByMonthPicker
                month={monthSelected.Id}
                placeholder={'DD'}
                defaultValue={defaultDayValue}
                value={daySelected}
                onChange={day => handleDayChange(day ?? defaultDayValue)}
                id={`${id ?? 'dayId'}_day`}
                name={`${name ?? 'dayName'}_day`}
                variant={inputVariant ?? 'standard'}
                disabled={disabled || dayDisabled}
                ariaLabel={`${label ?? 'day'} select`}
                error={error}
                size={size || 'small'}
                disableClearable
                autoFocus={true}
              />
              <MonthOfYearPicker
                defaultValue={defaultMonthValue}
                value={monthSelected}
                placeholder={'MMM'}
                onChange={month =>
                  handleMonthChange(month ?? defaultMonthValue)
                }
                id={`${id ?? 'monthId'}_month`}
                name={`${name ?? 'monthName'}_month`}
                variant={inputVariant ?? 'standard'}
                disabled={disabled || monthDisabled}
                ariaLabel={`${label ?? 'month'} select`}
                error={error}
                size={size || 'small'}
                disableClearable
              />
              <YearsPicker
                defaultValue={defaultYearValue}
                value={yearSelected}
                placeholder={'YYYY'}
                onChange={year => handleYearChange(year ?? defaultYearValue)}
                id={`${id ?? 'yearId'}_year`}
                name={`${name ?? 'yearName'}_year`}
                variant={inputVariant ?? 'standard'}
                disabled={disabled || yearDisabled}
                ariaLabel={`${label ?? 'year'} select`}
                error={error}
                size={size || 'small'}
                disableClearable
              />
            </React.Fragment>
          )}
        </div> */}
        {(!!helperText || Boolean(innerError)) && (
          <FormHelperText
            id={helperTextId}
            className={clsx('date-dropdown-picker', sizeClass)}
            disabled={disabled}
            error={error || Boolean(innerError)}
            required={props.required}
          >
            {helperText || innerError}
          </FormHelperText>
        )}
      </FormControl>
    </React.Fragment>
  );
}
