import { ConvertedToObjectType, TranslationJsonType } from './types';

/**
 * This file is seperate from the './i18n.ts' simply to make the Hot Module Replacement work seamlessly.
 * Your components can import this file in 'messages.ts' files which would ruin the HMR if this isn't a separate module
 **/

/**
 * translations object - primarily provides intellisence on resource/localization keys during compilation time.
 * At a later stage the json keys file is ditched entirely in favor of backend localization resources. At this stage this object only needs to return it's keys and nothing more.
 * Otherwise the whole resource file can end up being bundled in the main/vendors chunk which adds around 1MB to the file size.
 */
export const translations: ConvertedToObjectType<TranslationJsonType> =
  new Proxy(
    {},
    {
      get(target, key) {
        return key;
      },
    },
  ) as any;
