/**
 *
 * RepeatPeriodPicker
 *
 */

import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import { RepeatPeriod } from 'enums/RepetitiveOptions';
import { Entity } from 'types/common';
import { enumToEntityArray } from 'utils/enumKeys';

export interface RepeatPeriodPickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {}

export const initRemindBeforeData = (
  initval: number | string | undefined,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve(
        enumToEntityArray(RepeatPeriod).filter(item => {
          return item.Id.toString() === initval;
        }),
      );
    });
  }
};
const getOptions = (searchTerm: string | null) => {
  let items = enumToEntityArray(RepeatPeriod);

  if (searchTerm !== null && searchTerm !== '') {
    return items.filter(
      f => f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
    );
  }
  return items;
};
export function RepeatPeriodPicker(props: RepeatPeriodPickerProps) {
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      id={props.id || 'RepeatPeriodId'}
      value={props.value}
      {...props}
    />
  );
}
