import * as React from 'react';
import {
  BaseOptionsMultiPicker,
  BaseOptionsMultiPickerProps,
} from 'app/components/BasicPickers/BaseOptionsMultiPicker';
import { ReservationStatus } from 'api/odata/generated/enums/ReservationStatus';
import { selectReservationStatuses } from 'app/slice/selectors';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export interface ReservationStatusMultiProps
  extends Omit<BaseOptionsMultiPickerProps<number>, 'getOptions'> {}

export const initReservationStatusMultiData =
  (t: TFunction) => (initialValue: string | undefined) => {
    const id =
      initialValue
        ?.split('|')
        ?.map(id => parseInt(id))
        ?.filter(id => !isNaN(id) && ReservationStatus[id] !== undefined)
        ?.map(id => ({
          Id: id,
          Name: t(translations[ReservationStatus[id]]),
        })) ?? [];
    return Promise.resolve(id);
  };

export function ReservationStatusMultiPicker(
  props: ReservationStatusMultiProps,
) {
  const { t } = useTranslation();
  const options = useSelector(state => selectReservationStatuses(state, t));
  const getOptions = React.useCallback(() => options, [options]);
  return (
    <BaseOptionsMultiPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      id={props.id || 'reservation-status-id'}
      ariaLabel="Reservation Status"
      {...props}
    />
  );
}
