import { SelectedRowsActionIconButton } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionIconButton';
import { SelectedRowsActionRenderer } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionRenderer';
import { GetResponseMessages } from 'app/pages/UsersPage/PageActions/Actions';
import { useAdminPermissions } from 'app/permissions/Asset/assetPermissions';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import React from 'react';
import { useMemo } from 'react';
import { IResponseType } from 'types/ResponseType';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { ICoresDirectoryRow } from 'types/ICoresDirectoryRow';
import { httpClient } from 'api/HttpClient';
import { UpdateUserGroupPermissions } from './UpdateUserGroupPermissions';
import { ScreensId } from 'enums/ConfigurableTypes';
import { ExportImportActions } from 'app/components/PopupImportExport/Actions';
import fileDownload from 'js-file-download';

const SelectedActions = {
  Delete: (rows: ICoresDirectoryRow[]): Promise<any> => {
    const groupIds: number[] = rows.map(item => item.Id);
    const data = { groupIds };
    const url = '/api/odata/v4/ServiceGroups/DeleteGroups';
    return httpClient.post(url, data);
  },
  Deactivate: (rows: ICoresDirectoryRow[]): Promise<any> => {
    const groupIds: number[] = rows.map(item => item.Id);
    const data = { groupIds };
    const url = '/api/odata/v4/ServiceGroups/DeactivateGroups';
    return httpClient.post(url, data);
  },
};
const notUndefined = (rows: ICoresDirectoryRow[]) => rows.filter(f => !!f);
export function GetSelectedActions(
  t: TFunction,
  user?: AuthenticatedUser,
): SelectedRowsActionRenderer<ICoresDirectoryRow>[] {
  const { allowedToEditGroup } = useAdminPermissions();
  const SelectedRowActions: SelectedRowsActionRenderer<ICoresDirectoryRow>[] =
    useMemo(
      () => [
        (rows, refresh) =>
          notUndefined(rows).length > 0 &&
          notUndefined(rows).every(allowedToEditGroup) && (
            <SelectedRowsActionIconButton
              key="export-forimport-cores"
              title={t(translations.ExportForImport)}
              text={t(translations.ExportForImport)}
              confirm={false}
              //validConfirm={validUpdate}
              rows={notUndefined(rows)}
              onRefresh={refresh}
              variantButton="button"
              action={() =>
                new Promise<IResponseType>(async (resolve, reject) => {
                  const selectedRows = notUndefined(rows).map(item => item.Id);
                  const res = await ExportImportActions.ExportForImport(
                    ScreensId.CoresDirectory,
                    selectedRows,
                  );
                  const cd = res.headers['content-disposition'];
                  const filename =
                    cd !== undefined
                      ? cd.split('filename=')[1]
                      : 'ExportForImport_serviceGroups.xlsx';
                  fileDownload(res.data, filename);
                  let messages: IResponseType = {
                    SuccessMessages: [],
                    ErrorMessages: [],
                    WarningMessages: [],
                  };
                  resolve(messages);
                })
              }
            />
          ),
        (rows, onRefresh) =>
          notUndefined(rows).length > 0 &&
          notUndefined(rows).every(allowedToEditGroup) && (
            <SelectedRowsActionIconButton
              key="delete"
              text={t(translations.DeleteSelectedGroups)}
              title={t(translations.DeleteSelectedGroups)}
              confirm={true}
              validConfirm={true}
              confirmBody={
                t(translations.SelectedServiceGroupsWillBeDeleted) as string
              }
              rows={notUndefined(rows)}
              confirmButtonLabel={t(translations.Delete)}
              onRefresh={onRefresh}
              variantButton="button"
              action={() =>
                new Promise<IResponseType>((resolve, reject) => {
                  //here make request to DeleteOtherServices Action in Controller
                  return SelectedActions.Delete(notUndefined(rows))
                    .then(response => {
                      //GetResponseMessages is from UsersPage
                      const messages: IResponseType = GetResponseMessages(
                        response,
                        t(translations.Success) as string,
                      );
                      resolve(messages);
                    })
                    .catch(error => {
                      console.log('status error', error);
                      let err = t(
                        translations.err_ServiceGroupCanNotBeDeleted,
                      ) as string;
                      reject(err);
                    });
                })
              }
            />
          ),
        (rows, onRefresh) =>
          notUndefined(rows).length > 0 &&
          notUndefined(rows).every(allowedToEditGroup) && (
            <SelectedRowsActionIconButton
              key="deactivate"
              text={t(translations.DeactivateServiceGroup)}
              title={t(translations.DeactivateServiceGroup)}
              confirm={true}
              validConfirm={true}
              confirmBody={t(translations.ConfirmDeactivate) as string}
              rows={notUndefined(rows)}
              confirmButtonLabel={t(translations.Deactivate)}
              onRefresh={onRefresh}
              variantButton="button"
              action={() =>
                new Promise<IResponseType>((resolve, reject) => {
                  return SelectedActions.Deactivate(notUndefined(rows))
                    .then(response => {
                      const messages: IResponseType = GetResponseMessages(
                        response,
                        t(translations.Success) as string,
                      );
                      resolve(messages);
                    })
                    .catch(error => {
                      console.log('status error', error);
                      let err = t(
                        translations.err_ServiceGroupCanNotBeDeactivated,
                      ) as string;
                      reject(err);
                    });
                })
              }
            />
          ),
        rows =>
          notUndefined(rows).length > 0 &&
          notUndefined(rows).every(allowedToEditGroup) && (
            <UpdateUserGroupPermissions rows={notUndefined(rows)} />
          ),
      ],
      [allowedToEditGroup, t],
    );
  return SelectedRowActions;
}
