/*
 * Asynchronously loads the component for NotAllowedPage
 */

import { lazyLoad } from 'utils/loadable';

export const SsoIntegrationPage = lazyLoad(
  () => import('./index'),
  module => module.SsoIntegration,
);
