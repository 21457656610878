/**
 *
 * LinearProgress
 *
 */
import * as React from 'react';
import {
  LinearProgress as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
} from '@material-ui/core';
import styled from 'styled-components';

export interface LinearProgressProps
  extends MuiLinearProgressProps,
    AdditionalProps {}
interface AdditionalProps {
  /**
   * Show/hide the progress bar preventing layout shift.
   */
  visible: boolean | undefined;
}

function LinearProgress1({ visible = false, ...props }: LinearProgressProps) {
  return <MuiLinearProgress {...props} />;
}

/**
 * MUI's LinearProgress extension that helps to prevent layout shift when component is added/removed from the tree.
 */
export const LinearProgress = styled(LinearProgress1)<AdditionalProps>`
  visibility: ${props => (props.visible === true ? 'visible' : 'hidden')};
`;
