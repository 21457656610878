import { ISubscribersDto } from 'api/odata/generated/entities/ISubscribersDto';
import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';
import { AsyncDataState, Entity } from 'types/common';
import { IResponseType } from 'types/ResponseType';
import { dateUtils } from 'utils/date-utils';
import { toEntity } from 'utils/entity-utils';

/* --- STATE --- */
export interface SubscribersState {
  createState: AsyncDataState<SubscribersDetailsState>;
  updateState: AsyncDataState<SubscribersDetailsState>;
  processing: boolean;
}

export interface SubscribersDetailsState {
  Id: number;
  Username?: Entity<string> | null;
  SubscriptionType: Entity<number> | null;
  Budget: Entity<number> | null;
  StartDate: Date | string;
  EndDate: Date | string;
  UserGroup?: Entity<string> | null;
  Cost: number;
  SendExpirationNotificationDays?: number | null;
  NotDeletable: boolean;
  FundingType?: Entity<number> | null;
  PurchaseOrder?: string | null;
  SubscriptionFor?: string | null;
}

export interface SubscribersDetailsQStringParameters {
  id?: string;
  usrname?: string;
  subsctype?: string;
  bdgt?: string;
  start?: string;
  end?: string;
  usrgr?: string;
  cost?: string;
  sendExNotifDays?: string;
  notDel?: string;
  funType?: string;
  purcOrder?: string;
  subscFor?: string;
}

export interface SubscribersDetailsResponse extends IResponseType {
  Id?: number;
}

export function ConvertModelToEntitySubscribersDetails(
  entity: ISubscribersDto,
): SubscribersDetailsState | undefined {
  if (!entity) {
    return undefined;
  } else {
    return {
      Id: entity.Id,
      Username: toEntity(entity.Username, entity.User),
      SubscriptionType: toEntity(
        entity.SubscriptionTypeId,
        entity.SubscriptionType,
      ),
      Budget: toEntity(entity.BudgetId, entity.Budget),
      StartDate: dateUtils.dateOrStringToDate(entity.StartDate),
      EndDate: dateUtils.dateOrStringToDate(entity.EndDate),
      UserGroup: toEntity(entity.UserGroupId, entity.UserGroup),
      Cost: entity.Cost,
      SendExpirationNotificationDays: entity.SendExpirationNotificationDays,
      NotDeletable: entity.NotDeletable,
      FundingType:
        entity.FundingTypeId !== null && entity.FundingTypeId !== undefined
          ? getSingleInvoiceFundingType(entity.FundingTypeId)
          : null,
      PurchaseOrder: entity.PurchaseOrder,
      SubscriptionFor: entity.SubscriptionFor ?? '',
    } as SubscribersDetailsState;
  }
}

export function ConvertEntityToModelSubscribersDetails(
  model: SubscribersDetailsState,
  original: SubscribersDetailsState | undefined,
): ISubscribersDto {
  let entity = {
    Id: original?.Id === undefined ? model.Id : original.Id,
    Username: model.Username?.Id,
    User: model.Username?.Name,
    SubscriptionTypeId: model.SubscriptionType?.Id,
    SubscriptionType: model.SubscriptionType?.Name,
    BudgetId: model.Budget?.Id,
    Budget: model.Budget?.Name,
    StartDate: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(model.StartDate),
    ),
    EndDate: dateUtils.formatISO(dateUtils.dateOrStringToDate(model.EndDate)),
    UserGroupId: model.UserGroup?.Id,
    UserGroup: model.UserGroup?.Name,
    Cost: model.Cost,
    SendExpirationNotificationDays: model.SendExpirationNotificationDays,
    NotDeletable: model.NotDeletable,
    FundingTypeId: model.FundingType?.Id,
    PurchaseOrder: model.PurchaseOrder,
    SubscriptionFor: model.SubscriptionFor ?? '',
  } as ISubscribersDto;
  return entity;
}
