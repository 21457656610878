import { CommentsApi } from 'api/CommentsApi';
import { IComment } from 'app/components/CommentsContainer/IComment';
import { selectPublicUrl } from 'app/slice/selectors';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useAsyncGetWithEffect } from 'app/hooks/useAsyncGetOnMount';
import { CommentsContainer } from 'app/components/CommentsContainer';
import { translations } from 'locales/translations';
import { buildURL } from 'utils/url-utils';
import { Portal } from '@material-ui/core';
import { TrainingDetailsState } from '../../slice/types';
import { useUploadToNewComment } from 'app/components/LargeFilesUpload/Components/useUploadToNewComment';
import { useLargeFilesUploadSlice } from 'app/components/LargeFilesUpload/slice';

export interface UserTrainingCommentsProps {
  isAdmin?: boolean;
  training: TrainingDetailsState;
  disabled?: boolean;
  commentsFormRef: React.MutableRefObject<any>;
}

export const UserTrainingComments = (props: UserTrainingCommentsProps) => {
  const { training, isAdmin, disabled, commentsFormRef } = props;
  const publicUrl = useSelector(selectPublicUrl);
  const { t } = useTranslation();
  const [changed, setchanged] = React.useState(false);
  const dispatch = useDispatch();
  const { actions: largeFileActions } = useLargeFilesUploadSlice();
  const comments = useAsyncGetWithEffect<IComment[]>(
    () =>
      CommentsApi.GetComments(
        CommentReferenceTypes.UserTraining,
        training.Id ?? 0,
      ),
    [],
    [changed],
  );
  const bindSubmitComment = React.useCallback(
    submitForm => {
      commentsFormRef.current = submitForm;
    },
    [commentsFormRef],
  );
  const { onCommentAdded } = useUploadToNewComment();
  const portalRef = React.useRef(null);
  React.useEffect(() => {
    dispatch(
      largeFileActions.setCommentProperties({
        commentType: CommentReferenceTypes.UserTraining,
        referenceId: training.Id ?? 0,
      }),
    );
  }, [dispatch, largeFileActions, training.Id]);
  return (
    <React.Fragment>
      <div ref={portalRef} style={{ width: '100%' }} />
      <Portal container={portalRef.current}>
        <CommentsContainer
          disabled={disabled}
          Comments={comments}
          bindSubmitComment={bindSubmitComment}
          onSubmit={c => {
            CommentsApi.SendComment(
              CommentReferenceTypes.UserTraining,
              training.Id ?? 0,
              c,
            )
              .then(res => onCommentAdded?.(res))
              .finally(() => setchanged(prev => !prev));
          }}
          isAdmin={isAdmin}
          withMentions
          pageName={t(translations.menu_EditUserTraining)}
          referenceName={(training.Id ?? 0).toString()}
          linkUrl={buildURL(
            `${publicUrl}usertraining/details/${training.Id ?? 0}`,
            {},
          )}
          emailType={'Alert'}
        />
      </Portal>
    </React.Fragment>
  );
};
