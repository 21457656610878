import * as React from 'react';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import {
  selectAppSettings,
  selectAssetPopupSettings,
  selectAuthenticatedUser,
  selectGlobalServiceGroupFilter,
  selectGlobalSettingBool,
  selectglobalSettings,
  selectIsAuthenticated,
  selectPublicUrl,
} from 'app/slice/selectors';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AllowedSettings } from 'utils/globalSettings';

import { IAsset } from './IAsset';
import { IAssetRow } from './IAssetRow';
import { getRows } from './Rows';
import { httpClient } from 'api/HttpClient';
import { translations } from 'locales/translations';
import { buildURL } from 'utils/url-utils';
import { useAppSettingsSlice } from 'app/slice';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { AvailabilityTypes } from 'api/odata/generated/enums/AvailabilityTypes';
import { IAssetDetails2Dto } from 'api/odata/generated/entities/IAssetDetails2Dto';
import { selectExpandedSidePanel } from 'app/Layout/FrontendLayout/slice/selectors';
import { AssetDetailsTabContent } from './components/AssetDetailsTabContent';
import { DetectIsMobile } from 'utils/mobileDetect';
import { AssetBookingRules } from './components/AssetBookingRules';
import {
  AssetTrainingSessions,
  GetTrainingSessionsTableCount,
} from './components/AssetTrainingSessions';
import {
  AssetArticlesTable,
  GetArticlesTableCount,
} from './components/AssetArcticlesTable';
import {
  AssetWorkOrders,
  GetWorkOrdersTableCount,
} from './components/AssetWorkOrders';
import {
  GetAlertsEnabled,
  GetReservationEnabled,
  addArrayItemByName,
  isBookable,
  moveArrayItem,
  fetchAssetBy,
  fetchAssetAdditionalData,
  moveArrayItemByName,
  isOnlineService,
  AssetIdentifier,
  AssetIdIdentifier,
  AssetServiceIdentifier,
  AssetInternalCodeIdentifier,
} from './components/Utils';
import { TabContent } from 'app/components/BasicTabs/TabContent';
import { BadgeCounter } from './components/BadgeCounter';
import { EndUsageConfirm, EndUsageData } from 'app/components/EndUsageConfirm';
import { AssetDetailsApi } from 'api/AssetDetailsApi';
import { AssetDetailsTabs } from './components/AssetDetailsTabs';
import { MismatchCore } from './components/MismatchCoreMessage';
import {
  AssetDetailsAdditionalData,
  CountersForTabs,
  DefaultAttributes,
  DefaultRows,
  ExpandableRows,
} from './components/types';
import {
  AssetTypes,
  GetAssetTypeFrom,
  IsAssetFieldEnabled,
  IsAssetFieldIsPublic,
} from 'types/AppSettings';
import { AssetAttributes } from 'enums/AssetAttributes';
import useSidePanelState, {
  SidePanelCloseState,
} from 'app/hooks/useSidePanelOpen';
import { useHistory, useLocation } from 'react-router-dom';
import { Messages } from 'app/components/Messages';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { NotFoundPage } from '../NotFoundPage';
import { Roles } from 'api/odata/generated/enums/Roles';
import { selectUsageStarted } from '../UsageDetails/Details/slice/selectors';
import { usageStateActions } from '../UsageDetails/Details/slice';
import { AssetImagePopup } from './AttributeRenderers/ShowImage/AssetImagePopup';
import { useShowAssetImageSlice } from './AttributeRenderers/ShowImage/slice';
import {
  AssetWaitingList,
  GetWaitingListTableCount,
} from './components/AssetWaitingList';
import { dateUtils } from 'utils/date-utils';
import { useAssetActions } from './AssetActions';
import { useAssetsSelectionSlice } from '../MyAssetsPage/AssetsSelection/slice';
import { selectLoadingAdditionalData } from '../MyAssetsPage/AssetsSelection/slice/selectors';

const maxLeftTopButtonsLength = 2;
const countersForTabs = new CountersForTabs();

export interface AssetPopUpProps extends CoverProps {
  identifier: AssetIdentifier;
  onClose?: () => void;
  showAllCores?: boolean;
  useSidePanel: boolean;
  useRedirectToDash?: boolean;
  addFromCalendar?: boolean;
}

export const AssetPopUp = ({
  identifier,
  onClose,
  showAllCores,
  useSidePanel = true,
  useRedirectToDash,
  isCover,
  addFromCalendar = true,

  closeCover,
  ...props
}: AssetPopUpProps) => {
  const { t } = useTranslation();
  const globalSettings = useSelector(selectglobalSettings);
  const history = useHistory();
  const { actions: layoutActions } = useLayoutSlice();
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const { actions: assetStateActions } = useAssetsSelectionSlice();
  const [rows, setRows] = useState<IAssetRow[]>([]);
  const [selectColumns, setSelectColumns] = useState<string>('');
  const [expandColumns, setExpandColumns] = useState<string>('');
  const [data, setData] = useState<IAsset | undefined>();
  const [buttonsData, setButtonsData] = useState<
    AssetDetailsAdditionalData | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const [idChanged, setIdChanged] = useState<boolean>(false);
  const loadingAdditionalData = useSelector(selectLoadingAdditionalData);
  const setLoadingAdditionalData = React.useCallback(
    (value: boolean) => {
      dispatch(assetStateActions.setLoadingAdditionalData(value));
    },
    [assetStateActions, dispatch],
  );
  const [loadingButtonsAdditionalData, setLoadingButtonsAdditionalData] =
    useState<boolean | undefined>(undefined);
  const [openEndUsage, setOpenEndUsage] = useState(false);
  const [endUsageProcessing, setEndUsageProcessing] = useState(false);
  const publicUrl = useSelector(selectPublicUrl);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const {
    cover: assetCover,
    openPanel,
    closePanel,
    coverClosed,
    onCloseCover,
  } = useSidePanelState();
  const selectedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const usageStarted = useSelector(selectUsageStarted);
  const topServiceGroupsIsHidden = useSelector(state =>
    selectGlobalSettingBool(state, AllowedSettings.HideTopServiceGroupFilter),
  );
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const [counters, setCounters] = useState<CountersForTabs>(countersForTabs);
  const location = useLocation();
  const isMobile = DetectIsMobile();
  const appSettings = useSelector(selectAppSettings);
  const assetProps = useSelector(selectAssetPopupSettings);
  const { actions: showAssetImageActions } = useShowAssetImageSlice();
  const [showAssetImage, setShowAssetImage] = React.useState<boolean>(false);
  const [imagePath, setImagePath] = React.useState<string>();
  const shortView = React.useMemo(() => {
    return isMobile || (useSidePanel === true && !sidePanelExpanded) || isCover;
  }, [isCover, isMobile, sidePanelExpanded, useSidePanel]);
  const isAdmin = React.useMemo(() => {
    if (data === undefined) {
      return false;
    }
    const serviceGroupId = data?.ServiceGroup?.Id ?? undefined;
    const serviceId = data?.ServiceId ?? undefined;
    const serviceTypeId =
      data?.ServiceType === null
        ? undefined
        : ServiceType[data.ServiceType] ?? undefined;
    const result =
      authenticatedUser?.HasAdminGroupOnlyPermissions(serviceGroupId) ||
      authenticatedUser?.HasAdminServiceOnlyPermissions(
        serviceTypeId !== undefined ? serviceTypeId : 1,
        serviceId,
      );
    console.debug('foo', result);
    return result ?? false;
  }, [authenticatedUser, data]);
  const readOnlyUser = React.useMemo(() => {
    if (!authenticatedUser) return true;
    return (
      authenticatedUser.Roles.includes(Roles.Readonly) ||
      authenticatedUser.Roles.includes(Roles.CalendarOnly)
    );
  }, [authenticatedUser]);
  const isDashboardCore = React.useMemo(() => {
    var path = location.pathname;
    return path.toLowerCase() === '/dashboards/core/' + data?.ServiceGroup?.Id;
  }, [data?.ServiceGroup?.Id, location.pathname]);

  const reservationsEnabled = React.useMemo(() => {
    if (data === undefined) {
      return false;
    }
    if (data?.AvailabilityType === undefined) return false;

    return GetReservationEnabled(
      AvailabilityTypes[data.AvailabilityType.Id],
      data.Active,
      data.NotAllowReservations ?? false,
    );
  }, [data]);
  const hideTrainingSessionsSettings = globalSettings?.GetBoolean(
    AllowedSettings[AllowedSettings.HideTrainingSessionsSettings],
  );
  const hidePublications = globalSettings?.GetBoolean(
    AllowedSettings[AllowedSettings.DisablePublicationsModule],
  );

  const assetFieldEnabled = React.useCallback(
    (
      AssetField: AssetAttributes,
      AssetAvailabilityType?: AssetTypes,
    ): boolean => {
      if (isAuthenticated) {
        return IsAssetFieldEnabled(
          appSettings,
          AssetField,
          AssetAvailabilityType,
        );
      } else {
        return (
          IsAssetFieldEnabled(appSettings, AssetField, AssetAvailabilityType) &&
          IsAssetFieldIsPublic(appSettings, AssetField, AssetAvailabilityType)
        );
      }
    },
    [appSettings, isAuthenticated],
  );
  const updateCounters = useCallback(
    (name: string, count: number) => {
      const tempCounters = Object.assign({}, counters);
      tempCounters[name].Count = count;
      setCounters(tempCounters);
    },
    [counters],
  );
  const updateCountersEnable = useCallback(
    (name: string, enabled: boolean) => {
      const tempCounters = counters;
      tempCounters[name].Enabled = enabled;
      setCounters(tempCounters);
    },
    [counters],
  );
  const clickImage = React.useCallback(
    (path?: string) => {
      if (!sidePanelExpanded) {
        dispatch(layoutActions.setExpanded(true));
      } else {
        if (path !== undefined) {
          dispatch(
            showAssetImageActions.setShowImage({ show: true, image: path }),
          );
          setImagePath(path);
          setShowAssetImage(true);
        }
      }
    },
    [dispatch, layoutActions, showAssetImageActions, sidePanelExpanded],
  );

  const handleImageClose = React.useCallback(() => {
    setShowAssetImage(false);
  }, []);
  const dict: Record<string, IAssetRow> = useMemo(() => {
    return getRows(
      t,
      globalSettings,
      openPanel,
      publicUrl,
      isCover,
      useSidePanel,
      isAdmin,
      shortView,
      isAuthenticated,
      readOnlyUser,
      clickImage,
    ).reduce((res, current) => {
      res[current.Id] = current;
      return res;
    }, {});
  }, [
    t,
    globalSettings,
    openPanel,
    publicUrl,
    isCover,
    useSidePanel,
    isAdmin,
    shortView,
    isAuthenticated,
    readOnlyUser,
    clickImage,
  ]);

  const mismatchCore = useMemo(() => {
    return MismatchCore(
      data,
      isAuthenticated,
      topServiceGroupsIsHidden,
      selectedServiceGroups,
    );
  }, [data, isAuthenticated, selectedServiceGroups, topServiceGroupsIsHidden]);
  const loadAdditionalData = useCallback(
    async (data: IAssetDetails2Dto) => {
      const res = Object.assign({}, data);
      if (!!res) {
        setLoadingAdditionalData(true);
        try {
          const ad: AssetDetailsAdditionalData | undefined =
            await fetchAssetAdditionalData({
              Id: res.Id,
              typeId: AvailabilityTypes[res.AvailabilityType.Id] as number,
              isAuth: isAuthenticated,
              buttonsData: false,
            });
          if (!!ad) {
            res.ApprovalRequired = ad.ApprovalRequired;
            res.Status = ad.Status;
            res.StatusId = ad.StatusId;
            res.AssetAssemblyStructure = ad.AssemblyStructure;
            res.HideTrainingSession = ad.HideTrainingSession;
            res.Range = ad.Range;
            res.ForceTutoring = ad.ForceTutoring;
            res.Allowed_ByTraining = ad.Allowed_ByTraining;
            res.EndUsageIds = ad.EndUsageIds;
            if (res.ServiceGroup !== undefined && res.ServiceGroup !== null) {
              res.ServiceGroup.CanRequestAccess = ad.CanRequestAccess;
              res.ServiceGroup.ApprovalStatusId = ad.ApprovalStatusId;
            }
            res.CanBeCreatedFromLinkOnly = ad.CanBeCreatedFromLinkOnly;
            res.NotAllowReservations = ad.NotAllowReservations;
            res.ReservationEnabled = GetReservationEnabled(
              AvailabilityTypes[res.AvailabilityType.Id],
              res.Active,
              res.NotAllowReservations ?? false,
            );
            res.NotActiveSince = ad.NotActiveSince;
            res.AssetImageNames = ad.AssetImageNames;
            res.InternalId = ad.InternalId;
            setData(res);
          }
        } finally {
          setLoadingAdditionalData(false);
        }
      }
    },
    [isAuthenticated, setLoadingAdditionalData],
  );
  const loadButtonsAdditionalData = useCallback(
    async (data: IAssetDetails2Dto) => {
      if (!!data && !loadingButtonsAdditionalData) {
        setLoadingButtonsAdditionalData(true);
        try {
          const ad: AssetDetailsAdditionalData | undefined =
            await fetchAssetAdditionalData({
              Id: data.Id,
              typeId: AvailabilityTypes[data.AvailabilityType.Id] as number,
              isAuth: isAuthenticated,
              buttonsData: true,
            });
          setButtonsData(ad);
        } finally {
          setLoadingButtonsAdditionalData(false);
        }
      }
    },
    [isAuthenticated, loadingButtonsAdditionalData],
  );

  const loadCounters = useCallback(
    (data: IAssetDetails2Dto) => {
      if (data.Id !== undefined) {
        if (counters.TrainingSessions.Enabled) {
          GetTrainingSessionsTableCount(
            data.Id,
            'TrainingSessions',
            updateCounters,
            data.ServiceGroup?.Id,
            data.AvailabilityType.Id,
          );
        }
        GetWorkOrdersTableCount(
          data.Id,
          false,
          'WorkOrders',
          updateCounters,
          data.ServiceGroup?.Id,
          data.AvailabilityType.Id,
        );
        GetWorkOrdersTableCount(
          data.Id,
          true,
          'Calibrations',
          updateCounters,
          data.ServiceGroup?.Id,
          data.AvailabilityType.Id,
        );
        if (counters.Publications.Enabled && !hidePublications) {
          GetArticlesTableCount(
            data.Id,
            data.ServiceGroup?.Id,
            'Publications',
            updateCounters,
            data.AvailabilityType.Id,
          );
        }
        if (
          data.ServiceId !== null &&
          (data.ServiceType === 'Online' ||
            isOnlineService(data.AvailabilityType?.Id))
        ) {
          GetWaitingListTableCount(
            data.ServiceId,
            'WaitingList',
            updateCounters,
          );
        }
      }
    },
    [
      counters.Publications.Enabled,
      counters.TrainingSessions.Enabled,
      hidePublications,
      updateCounters,
    ],
  );

  const enabledAssetProps = React.useMemo(() => {
    const id = data?.AvailabilityType?.Id;
    if (id !== undefined) {
      const res = assetProps.filter(a =>
        assetFieldEnabled(
          AssetAttributes[a.Id],
          GetAssetTypeFrom(AvailabilityTypes[id]),
        ),
      );
      return res;
    } else {
      return assetProps;
    }
  }, [assetFieldEnabled, assetProps, data?.AvailabilityType?.Id]);
  const columnNamesFilter = React.useCallback((r: IAssetRow) => {
    return (
      r.State === undefined &&
      [
        'AssetAssemblyStructure',
        'Status',
        'Division',
        'AssetCatGroup',
        'Range',
        'InternalId',
      ].includes(r.PropName ?? r.Id) === false &&
      r.Id !== 'ForceTutoring'
    );
  }, []);
  const setRequestParams = React.useCallback(
    (rows: IAssetRow[]) => {
      if (rows.length > 0) {
        let columns = [
          ...rows
            .filter(r => columnNamesFilter(r))
            .map(v => v.PropName ?? v.Id),
          ...DefaultAttributes,
        ];
        setSelectColumns('Active,' + columns.join(','));
        setExpandColumns(
          columns
            .filter(column => ExpandableRows.includes(column))
            //.concat('ServiceGroup')
            .join(','),
        );
      }
    },
    [columnNamesFilter],
  );
  useEffect(() => {
    if (!!data) {
      if ((identifier as AssetIdIdentifier).id !== undefined) {
        if (data.Id !== (identifier as AssetIdIdentifier).id) {
          setIdChanged(true);
        }
      } else if (
        (identifier as AssetServiceIdentifier).serviceId !== undefined &&
        (identifier as AssetServiceIdentifier).serviceType !== undefined
      ) {
        if (
          data.ServiceId !== (identifier as AssetServiceIdentifier).serviceId
        ) {
          setIdChanged(true);
        }
      } else if (
        (identifier as AssetInternalCodeIdentifier).internalCode !== undefined
      ) {
        if (
          data.InternalCode !==
          (identifier as AssetInternalCodeIdentifier).internalCode
        ) {
          setIdChanged(true);
        }
      }
    }
  }, [data, identifier]);
  useEffect(() => {
    moveArrayItem(enabledAssetProps, 'ImageName', 0);
    addArrayItemByName(
      enabledAssetProps,
      'AssetAssemblyStructure',
      'ImageName',
    );
    moveArrayItemByName(enabledAssetProps, 'Status', 'ServiceGroup');
    const rs = [
      ...DefaultRows.map(r => dict[r]),
      ...enabledAssetProps.flatMap(assetProp => {
        let val = dict[assetProp.Id];
        if (!!val) {
          val.Name = assetProp.DisplayName ?? val.Name;
          if (val.dependencies && !!val.dependencies.length) {
            return [val, ...val.dependencies.map(v => dict[v])];
          }
        }
        return val;
      }),
    ].filter(v => !!v);
    setRequestParams(rs);
    setRows(rs);
  }, [assetProps, dict, enabledAssetProps, setRequestParams]);
  useEffect(() => {
    if (rows.length === 0 || (data !== undefined && idChanged === false))
      return;
    setLoading(true);

    let active = true;
    const cancelToken = httpClient.cancelToken.source();
    async function fetchData() {
      try {
        const params = {
          $select: selectColumns,
          $expand: expandColumns,
        };

        const res = await fetchAssetBy({
          id: identifier,
          params,
          isAuth: isAuthenticated,
        });

        if (active && res !== undefined) {
          res.Division = res.ServiceGroup?.DivisionName;
          res.AssetCatGroup =
            res.AssetCat !== null &&
            !!res.AssetCat &&
            res.AssetCat.GroupId &&
            res.AssetCat.AssetCatGroupName
              ? {
                  Id: res.AssetCat.GroupId,
                  Name: res.AssetCat.AssetCatGroupName,
                  ServiceGroups: [],
                }
              : null;
          res.Status = ' ';
          res.AlertsEnabled = isAuthenticated
            ? GetAlertsEnabled(
                AvailabilityTypes[res.AvailabilityType.Id],
                res.Active,
              )
            : false;
          setData(res);
          loadButtonsAdditionalData(res);
          loadAdditionalData(res);
          if (isAuthenticated) {
            loadCounters(res);
          }
        }
        setLoading(false);
        setIdChanged(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }

    fetchData();

    return () => {
      active = false;
      setLoading(false);
      cancelToken.cancel('cancelled');
    };
  }, [
    data,
    expandColumns,
    idChanged,
    identifier,
    isAuthenticated,
    loadAdditionalData,
    loadButtonsAdditionalData,
    loadCounters,
    rows.length,
    selectColumns,
  ]);
  useEffect(() => {
    if (usageStarted) {
      dispatch(usageStateActions.setUsageStarted(false));
      if (data !== undefined) {
        loadButtonsAdditionalData(data);
      }
    }
  }, [data, dispatch, loadButtonsAdditionalData, usageStarted]);
  const handleEndUsage = React.useCallback(
    (usageData: EndUsageData) => {
      if (buttonsData?.UsageId) {
        setEndUsageProcessing(true);
        AssetDetailsApi.endUsage(buttonsData?.UsageId, usageData)
          .then(response => {
            setEndUsageProcessing(false);

            if (response.ErrorMessages.length > 0) {
              response.ErrorMessages.forEach(errmsg =>
                dispatch(
                  actions.addNotification({
                    variant: 'error',
                    message: errmsg,
                  }),
                ),
              );
            } else {
              setOpenEndUsage(false);
              if (data !== undefined) {
                loadButtonsAdditionalData(data);
              }
              if (response.SuccessMessages.length > 0) {
                response.SuccessMessages.forEach(msg =>
                  dispatch(
                    actions.addNotification({
                      variant: 'success',
                      message: msg,
                    }),
                  ),
                );
              }
              if (response.WarningMessages.length > 0) {
                response.WarningMessages.forEach(wrnmsg =>
                  dispatch(
                    actions.addNotification({
                      variant: 'warning',
                      message: wrnmsg,
                    }),
                  ),
                );
              }
            }
          })
          .catch(e => console.error(e))
          .finally(() => setEndUsageProcessing(false));
      }
    },
    [actions, buttonsData?.UsageId, data, dispatch, loadButtonsAdditionalData],
  );

  const assetActions = useAssetActions({
    useSidePanel,
    openPanel,
  });
  const topActions: ActionRenderer[] = useMemo(() => {
    let buttonActions = [] as ActionRenderer[];
    if (
      data !== undefined &&
      buttonsData !== undefined &&
      data.AvailabilityType !== undefined &&
      loading === false &&
      loadingButtonsAdditionalData === false
    ) {
      return assetActions({
        Name: data.Name,
        assetId: data.Id,
        serviceId: data.ServiceId,
        serviceTypeId:
          data.ServiceType === null ? null : ServiceType[data.ServiceType],
        setOpenEndUsage: setOpenEndUsage,
        active: data.Active,
        allowToUser: data.AllowToUser ?? undefined,
        buttonsData: buttonsData,
        availabilityId: AvailabilityTypes[data.AvailabilityType.Id] as number,
        canBeCreatedFromLinkOnly: data.CanBeCreatedFromLinkOnly,
        canRequestAccessToCore: data.ServiceGroup?.CanRequestAccess,
        coreApprovalStatusId: data.ServiceGroup?.ApprovalStatusId ?? undefined,
        addFromCalendar: addFromCalendar,
        authenticatedUser: authenticatedUser,
        divisionId: data.ServiceGroup?.DivisionId ?? undefined,
        isAuthenticated: isAuthenticated ?? false,
        isDashboardCore: isDashboardCore,
        mismatchCore: mismatchCore,
        saveCore: () => {
          dispatch(
            actions.saveGlobalServiceGroupFilter([
              {
                Id: data.ServiceGroup?.Id,
                Name: data.ServiceGroup?.Name,
              },
            ]),
          );
          dispatch(layoutActions.resetSidePanel());
          history.push('/dashboards/core/' + data.ServiceGroup?.Id);
        },
        serviceGroupId: data.ServiceGroup?.Id,
        serviceGroupName: data.ServiceGroup?.Name,
      });
    }
    return buttonActions;
  }, [
    actions,
    addFromCalendar,
    assetActions,
    authenticatedUser,
    buttonsData,
    data,
    dispatch,
    history,
    isAuthenticated,
    isDashboardCore,
    layoutActions,
    loading,
    loadingButtonsAdditionalData,
    mismatchCore,
  ]);

  const tabs = useMemo(() => {
    if (data !== undefined) {
      const res: TabContent[] = [];
      res.push({
        Name: t(translations.AssetDetailsTab_Details),
        Id: 'details',
        Content: (
          <AssetDetailsTabContent
            rows={rows}
            data={data}
            shortView={shortView}
            loadingAdditionalData={loadingAdditionalData}
            isAdmin={isAdmin === true}
            isCover={isCover}
            commentsForStaff={!!data.CommentsAreStaffonly}
            info={
              data.Active
                ? undefined
                : data.NotActiveSince === undefined ||
                  data.NotActiveSince === null
                ? t(translations.AssetNoLongerActiveWarning)
                : t(translations.AssetNoLongerActiveWarning) +
                  ' as of ' +
                  dateUtils.shortDateFormat(data.NotActiveSince)
            }
          />
        ),
      });
      if (
        isBookable(data.AvailabilityType?.Id) &&
        isAuthenticated &&
        !readOnlyUser
      ) {
        res.push({
          Name: t(translations.BookingRules),
          Id: 'BookingRules',
          Content: (
            <AssetBookingRules
              serviceId={data.ServiceId ?? undefined}
              serviceGroupId={data.ServiceGroup?.Id ?? undefined}
              useShortView={shortView}
              useSidePanel={useSidePanel}
            />
          ),
        });
      }
      if (
        !hideTrainingSessionsSettings &&
        isBookable(data.AvailabilityType?.Id) &&
        isAuthenticated &&
        !readOnlyUser
      ) {
        if (
          data.AvailabilityType?.Id !== 'LectureRoom' ||
          (!data.HideTrainingSession && data.Active)
        ) {
          const serviceType =
            data.ServiceType !== null
              ? ServiceType[data.ServiceType]
              : undefined;
          res.push({
            Name: t(translations.TrainingSessions),
            Id: 'trainingSessions',
            Content: (
              <AssetTrainingSessions
                assetId={data.Id}
                serviceId={data.ServiceId}
                serviceTypeId={serviceType}
                isMobile={shortView}
                serviceGroupId={data.ServiceGroup?.Id}
                availabilityType={data.AvailabilityType?.Id}
                openPanel={openPanel}
                useSidePanel={useSidePanel}
              />
            ),
            endIcon: <BadgeCounter count={counters.TrainingSessions.Count} />,
          });
          updateCountersEnable('TrainingSessions', true);
        } else {
          updateCountersEnable('TrainingSessions', false);
        }
      }
      if (
        isAuthenticated &&
        !readOnlyUser &&
        data.AvailabilityType?.Id !== 'RequestRelated'
      ) {
        res.push({
          Name: t(translations.AssetDetailsTab_WorkOrders),
          Id: 'workOrders',
          Content: (
            <AssetWorkOrders
              assetId={data.Id}
              assetName={data.Name}
              isMobile={shortView}
              allowToAddNew={isOnlineService(data.AvailabilityType?.Id)}
              openPanel={openPanel}
              useSidePanel={useSidePanel}
              serviceGroupId={data.ServiceGroup?.Id}
              availabilityType={data.AvailabilityType?.Id}
            />
          ),
          endIcon: <BadgeCounter count={counters.WorkOrders.Count} />,
        });
      }
      if (
        isAuthenticated &&
        !readOnlyUser &&
        data.AvailabilityType?.Id !== 'RequestRelated'
      ) {
        res.push({
          Name: t(translations.Calibrations),
          Id: 'calibrations',
          Content: (
            <AssetWorkOrders
              assetId={data.Id}
              assetName={data.Name}
              calibration={true}
              isMobile={shortView}
              openPanel={openPanel}
              useSidePanel={useSidePanel}
              allowToAddNew={isOnlineService(data.AvailabilityType?.Id)}
              serviceGroupId={data.ServiceGroup?.Id}
              availabilityType={data.AvailabilityType?.Id}
            />
          ),
          endIcon: <BadgeCounter count={counters.Calibrations.Count} />,
        });
      }
      if (
        isAuthenticated &&
        !readOnlyUser &&
        !hidePublications &&
        data.AvailabilityType?.Id !== 'RequestRelated'
      ) {
        res.push({
          Name: t(translations.Publications),
          Id: 'publications',
          Content: (
            <AssetArticlesTable
              assetId={data.Id}
              serviceGroupId={data.ServiceGroup?.Id}
              isMobile={shortView}
              openPanel={openPanel}
              availabilityType={data.AvailabilityType?.Id}
              useSidePanel={useSidePanel}
            />
          ),
          endIcon: <BadgeCounter count={counters.Publications.Count} />,
        });
        updateCountersEnable('Publications', true);
      } else {
        updateCountersEnable('Publications', false);
      }
      if (
        isAuthenticated &&
        data.ServiceId !== null &&
        (data.ServiceType === 'Online' ||
          isOnlineService(data.AvailabilityType?.Id)) &&
        !readOnlyUser
      ) {
        res.push({
          Name: t(translations.menu_ReservationWaitingList),
          Id: 'waitinglist',
          Content: (
            <AssetWaitingList
              serviceId={data.ServiceId}
              assetName={data.Name}
              isMobile={shortView}
              openPanel={openPanel}
              useSidePanel={useSidePanel}
            />
          ),
          endIcon: <BadgeCounter count={counters.WaitingList.Count} />,
        });
      }
      /*res.push({
          Name:
            t(translations.AssetDetailsTab_MyReservations) +
            ` (${counters.Reservations.Count})`,
          Id: 'my_reservations',
          Content: <>reservations</>,
        });*/
      /*res.push({
          Name: t(translations.AssetDetailsTab_Schedule),
          Id: 'schedule',
          Content: <>schedule</>,
        });*/
      return res;
    } else return [];
  }, [
    counters.Calibrations.Count,
    counters.Publications.Count,
    counters.TrainingSessions.Count,
    counters.WaitingList.Count,
    counters.WorkOrders.Count,
    data,
    hidePublications,
    hideTrainingSessionsSettings,
    isAdmin,
    isAuthenticated,
    isCover,
    loadingAdditionalData,
    openPanel,
    readOnlyUser,
    rows,
    shortView,
    t,
    updateCountersEnable,
    useSidePanel,
  ]);

  const handleCloselClick = React.useCallback(() => {
    closePanel({
      isCover: isCover,
      useSidePanel: useSidePanel,
      onClose: () => {
        !!onClose && onClose();
      },
    } as SidePanelCloseState);
  }, [closePanel, isCover, onClose, useSidePanel]);

  const pageName = useMemo(() => {
    if (data !== undefined && data.AvailabilityType !== undefined) {
      if (
        AvailabilityTypes[data.AvailabilityType?.Id] ===
        AvailabilityTypes.ServiceGroup
      ) {
        return t(translations.ServiceGroup);
      } else {
        if (data.ServiceType !== null) {
          switch (data.ServiceType) {
            case 'Online':
              return t(translations.Instrument);
            case 'Offline':
              return t(translations.ConsumableType);
            case 'Sample':
              return t(translations.RequestType);
            default:
              return '';
          }
        } else {
          return t(translations.Instrument);
        }
      }
    } else {
      return '';
    }
  }, [data, t]);
  // more direct loading/fetch state indicator will be more appropriate here to determine that fetch operations are finished and no asset details were found
  const dataFound = data && tabs !== undefined && tabs.length > 0;
  if (!loading && !dataFound && data === undefined) {
    return <NotFoundPage />;
  }

  return (
    <>
      {showAssetImage ? (
        <AssetImagePopup
          title=""
          show={showAssetImage}
          imagePath={imagePath !== undefined ? imagePath : ''}
          close={handleImageClose}
        />
      ) : (
        <></>
      )}
      <PageWrapper
        id="asset_details"
        useSidePanel={useSidePanel ?? false}
        hideMenu={!isAuthenticated}
        closable={useSidePanel === true}
        loading={loading || rows.length === 0}
        pageLink={
          data === undefined
            ? undefined
            : buildURL(`${publicUrl}Assets/Details`, { id: data?.Id })
        }
        toPageLink={
          data === undefined ? undefined : `Assets/Details?id=${data?.Id}`
        }
        pageName={pageName}
        titlePage={data?.Name ?? undefined}
        titleTooltip={data?.Name + t(translations.ShowAssetFull)}
        children={
          <Fragment>
            {dataFound ? (
              <React.Fragment>
                <AssetDetailsTabs
                  tabs={tabs}
                  isMobile={isMobile}
                  shortView={shortView ?? false}
                />
                {isAuthenticated && buttonsData?.UsageId && (
                  <React.Fragment>
                    <EndUsageConfirm
                      ids={data.EndUsageIds}
                      usageId={buttonsData?.UsageId}
                      isOpen={openEndUsage}
                      usageProcessing={endUsageProcessing}
                      onClose={() => setOpenEndUsage(false)}
                      onConfirm={handleEndUsage}
                    />
                  </React.Fragment>
                )}
                <Messages pageName="assetDetails" />
              </React.Fragment>
            ) : (
              <>{t(translations.err_Equipment_NotFound)}</>
            )}
          </Fragment>
        }
        closeSidePanel={handleCloselClick}
        rightTopActions={!shortView ? topActions : undefined}
        rightActionsLoading={!shortView && loadingButtonsAdditionalData}
        leftTopActions={shortView ? topActions : undefined}
        leftActionsLoading={shortView && loadingButtonsAdditionalData}
        maxLeftTopButtonsLength={
          reservationsEnabled ? maxLeftTopButtonsLength : 1
        }
        rightTopActionsLength={reservationsEnabled ? 2 : 1}
        isCover={isCover}
        closeCover={!isCover ? onCloseCover : closeCover}
        cover={assetCover}
        coverClosed={coverClosed}
      />
    </>
  );
};
