import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { toQueryString, toRootedURL } from 'utils/url-utils';
import { dateUtils } from 'utils/date-utils';
import { WorkOrderQueryStringParameters } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details/slice/types';
import { WorkOrderDetailsProps } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { getPeriodTypesEntity } from 'app/components/DatePeriods';
import { DetectIsMobile } from 'utils/mobileDetect';
import { selectGlobalDbSetting_EnableLegacyScheduler } from 'app/slice/globalDbSettingsSelectors';
import { useOfflineServiceStateSlice } from 'app/pages/OtherServiceDetails/Details/slice';
import { UsageDetailsProps } from 'app/pages/UsageDetails/Details';
import { GetSchedulerPagePath } from 'app/pages/CalendarPage/GetCalendarPagePath';

export function useAddNewActions() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { newDate } = useSystemDate();
  const isMobile = DetectIsMobile();
  const { actions: layoutActions } = useLayoutSlice();
  const { actions: offActions } = useOfflineServiceStateSlice();
  const EnableLegacyScheduler = useSelector(
    selectGlobalDbSetting_EnableLegacyScheduler,
  );

  const createReservation = React.useCallback(
    (services: number[], multiple?: boolean) => {
      if (EnableLegacyScheduler) {
        window.open(
          toRootedURL('/Reservation.aspx', {
            id: services.join(','),
          }),
          '_blank',
        );
        return;
      }
      history.push(
        `${GetSchedulerPagePath('calendar')}?${toQueryString({
          eid: services.join(','),
          period: getPeriodTypesEntity(
            isMobile ? 'Today' : 'ThisWeek',
          ).Id.toString(),
          openToBook: true,
          defaultMulti: multiple,
        })}`,
      );
    },
    [EnableLegacyScheduler, history, isMobile],
  );
  const createWorkOrder = React.useCallback(
    (assetIds: number[], useSidePanel?: boolean) => {
      let p = {
        queryParams: {
          eqid: undefined,
          down: undefined,
          offH: undefined,
          eStart: dateUtils.formatQueryStringDate(newDate(), {
            representation: 'complete',
          }),
          offStart: undefined,
          offEnd: undefined,
          defaultMulti: undefined,
          source: undefined,
          id: undefined,
          aType: undefined,
          title: undefined,
          sympt: undefined,
          aStatus: undefined,
          aStart: undefined,
          desc: undefined,
          assetId: assetIds.join(','),
          reason: undefined,
          sid: undefined,
          Up: undefined,
          continueTo: 'edit',
        } as WorkOrderQueryStringParameters,
        useSidePanel: true,
        continueTo: 'edit',
      } as WorkOrderDetailsProps;
      if (useSidePanel) {
        dispatch(
          layoutActions.openSidePanel({
            type: RenderPageType.WorkOrderDetails,
            props: p,
          }),
        );
      } else {
        history.push(`/workorders/details?${toQueryString(p.queryParams)}`);
      }
    },
    [dispatch, history, layoutActions, newDate],
  );
  // const createOffline = React.useCallback(
  //   (serviceTypeId: number, useSidePanel?: boolean) => {
  //     let params = {
  //       useSidePanel: true,
  //       queryParams: {
  //         stid: serviceTypeId.toString(),
  //         user: authuser?.Id,
  //       },
  //     } as OtherServiceDetailsProps;

  //     if (useSidePanel) {
  //       dispatch(
  //         layoutActions.openSidePanel({
  //           type: RenderPageType.OtherServiceDetails,
  //           props: params,
  //         }),
  //       );
  //     }
  //     history.push(
  //       `/otherServices/details?${toQueryString(params.queryParams)}`,
  //     );
  //   },
  //   [authuser?.Id, dispatch, history, layoutActions],
  // );
  const createOffline = React.useCallback(
    (services: number[], multiple: boolean) => {
      dispatch(offActions.setSelectedServiceType(services));
      if (!multiple) {
        dispatch(offActions.setBarcodeScanned(true));
      }
    },
    [dispatch, offActions],
  );
  const addTrainingSession = React.useCallback((eqid: number) => {
    window.open(
      toRootedURL('/TrainingSession/AddNew.aspx', {
        eqid: eqid,
      }),
      '_blank',
    );
  }, []);
  const startUsage = React.useCallback(
    (serviceId: number, useSidePanel?: boolean) => {
      let p: UsageDetailsProps = {
        queryParams: {
          sid: serviceId.toString(),
        },
        useSidePanel: true,
      };
      if (useSidePanel) {
        dispatch(
          layoutActions.openSidePanel({
            type: RenderPageType.UsageDetails,
            props: p,
          }),
        );
      } else {
        history.push(`/usage/details?${toQueryString(p.queryParams)}`);
      }
    },
    [dispatch, history, layoutActions],
  );
  return {
    createReservation,
    createWorkOrder,
    createOffline,
    startUsage,
    addTrainingSession,
  };
}
