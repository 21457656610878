/**
 *
 * AssemblySnapshotPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { IAssemblySnapShotsDto } from 'app/pages/AssemblySnapshots/IAssemblySnapShotsDto';
import { dateUtils } from 'utils/date-utils';

export interface AssemblySnapshotPickerProps
  extends Omit<AutocompletePickerProps<IAssemblySnapShotsDto>, 'loadData'> {}

const url = '/api/odata/v4/AssemblyShots';
export const generateAssemblySnapshotName = (
  dto: IAssemblySnapShotsDto,
): IAssemblySnapShotsDto => {
  let name = `#${dto.Id} - ${dateUtils.shortDateTimeFormat(
    dateUtils.dateOrStringToDate(dto.Date),
  )}`;
  return Object.assign({}, dto, { Name: name });
};
export const convertAssemblySnapshotName = (
  Id: number,
  Date: Date | string,
): string => {
  let name = `#${Id} - ${dateUtils.shortDateTimeFormat(
    dateUtils.dateOrStringToDate(Date),
  )}`;
  return name;
};
export const initAssemblySnapshot = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<IAssemblySnapShotsDto[]>((resolve, reject) => {
      resolve([] as IAssemblySnapShotsDto[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Id desc',
      $filter: '(Id eq ' + id + ')',
      $select:
        'Id,Name,Date,TakenBy,ServiceGroupId,AssetId,EquipmentId,EquipmentName,SnapshotJson,TakenByName',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response =>
        !!response.value && response.value.length > 0
          ? response.value.map(f => generateAssemblySnapshotName(f))
          : [],
      );
  }
};
export const initAssemblySnapshotWithParams = (
  equipmentId?: number,
  userName?: string,
) => {
  const predicates = AssemblySnapshotFilter(equipmentId, userName);
  if (predicates.length < 1) {
    return new Promise<IAssemblySnapShotsDto[]>((resolve, reject) => {
      resolve([] as IAssemblySnapShotsDto[]);
    });
  } else {
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Id desc',
      $filter: predicates.map(f => f.toString()).join(' and '),
      $select:
        'Id,Name,Date,TakenBy,ServiceGroupId,AssetId,EquipmentId,EquipmentName,SnapshotJson,TakenByName',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response =>
        !!response.value && response.value.length > 0
          ? response.value.map(f => generateAssemblySnapshotName(f))
          : [],
      );
  }
};

const loadBaseData = (
  predicates: (string | Condition<IAssemblySnapShotsDto>)[] | undefined,
  select?: (keyof IAssemblySnapShotsDto)[],
) =>
  getAutoCompleteLoadDataFn<IAssemblySnapShotsDto>({
    url,
    predicates: predicates,
    //transformModel: generateInventoryBatchName,
    // TODO: narrow down used properties list
    select:
      !!select && select.length > 0
        ? select
        : [
            'Id',
            'Name',
            'Date',
            'TakenBy',
            'TakenByName',
            'EquipmentName',
            'EquipmentId',
            'AssetId',
            'ServiceGroupId',
            'SnapshotJson',
          ],
    globalServiceGroupFilter: undefined,
    orderBy: 'Id desc',
  });

export function AssemblySnapshotPicker(props: AssemblySnapshotPickerProps) {
  const innerLoadData = loadBaseData(props.predicates);
  return (
    <AutocompletePicker
      loadData={innerLoadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      additionalItem={props.additionalItem}
      id={props.id || 'assemblySnapshotId'}
      getOptionLabelName={option =>
        convertAssemblySnapshotName(
          (option as IAssemblySnapShotsDto).Id,
          (option as IAssemblySnapShotsDto).Date,
        )
      }
      renderOptionName={option =>
        convertAssemblySnapshotName(
          (option as IAssemblySnapShotsDto).Id,
          (option as IAssemblySnapShotsDto).Date,
        )
      }
      {...props}
    />
  );
}
export const loadAssemblySnapshotsData = loadBaseData;
export function AssemblySnapshotFilter(
  equipmentId?: number,
  userName?: string,
): (string | Condition<IAssemblySnapShotsDto>)[] {
  const predicates: (string | Condition<IAssemblySnapShotsDto>)[] = [];
  if (!!equipmentId) {
    predicates.push(
      new Condition<IAssemblySnapShotsDto>(
        'EquipmentId',
        ODataOperators.Equals,
        equipmentId,
      ),
    );
  }
  if (!!userName) {
    predicates.push(
      new Condition<IAssemblySnapShotsDto>(
        'TakenBy',
        ODataOperators.Equals,
        userName,
      ),
    );
  }
  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}
