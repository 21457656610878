import { Theme } from '@material-ui/core';
import styled from 'styled-components';

export const RequestContentWrapper = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  &.request-wrapper {
    //padding: 32px 0px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    &.request-print {
      gap: 16px;
      @media (max-width: 960px) and (min-width: 600px) {
        & .print-section {
          & .formsection {
            flex-direction: row;
            & .leftsection {
              flex: auto;
            }
            & .rightsection {
              width: 40%;
            }
          }
        }
      }
    }
  }
`;
