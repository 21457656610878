import { Body } from 'app/components/Typography';
import { selectglobalSettings } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dateUtils } from 'utils/date-utils';
import { AllowedSettings } from 'utils/globalSettings';
import { isNullOrUndefined } from 'utils/typeUtils';
import { WorkOrderReadonlyState } from '../slice/types';
import { ReservationLink } from 'app/pages/Actions/GlobalSelectedActions/OpenReservation';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import styled from 'styled-components';

export interface CreationDetailsProps {
  details: WorkOrderReadonlyState;
  openPanel?: (state: SidePanelOpenState) => void;
  useSidePanel?: boolean;
}

export function CreationDetails(props: CreationDetailsProps) {
  const { details, openPanel, useSidePanel } = props;
  const globalSettings = useSelector(selectglobalSettings);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <DetailsRoot className={'details-root'}>
        <Body bold={true}>{t(translations.WorkOrderAdditionalDetails)}</Body>
        <div className={'details-content'}>
          <div className={'details-row'}>
            <Body size="small">{t(translations.CreatedAt)}</Body>
            <Body size="small" bold={true}>
              {details.ReportedByDisplayName}
            </Body>
          </div>
          <div className={'details-row'}>
            <Body size="small">{t(translations.CreatedBy)}</Body>
            <Body size="small" bold={true}>
              {dateUtils.longDateTimeFormat(details.CreateDate)}
            </Body>
          </div>
          <div className={'details-row'}>
            <Body size="small">{t(translations.LastUpdated)}</Body>
            <Body size="small" bold={true}>
              {dateUtils.longDateTimeFormat(details.LastUpdated) ?? 'N/A'}
            </Body>
          </div>
          <div className={'details-row'}>
            <Body size="small">{t(translations.WorkOrderTypeType)}</Body>
            <Body size="small" bold={true}>
              {details.AlertType ?? 'N/A'}
            </Body>
          </div>
          {/* <div className={'details-row'}>
            <Body size="small">{t(translations.EventTypeStatus)}</Body>
            <Body size="small" bold={true}>
              {details.StatusName ?? 'N/A'}
            </Body>
          </div> */}
          {details.DoneDate && details.DoneDate !== null && (
            <div className={'details-row'}>
              <Body size="small">{t(translations.ClosedAt)}</Body>
              <Body size="small" bold={true}>
                {dateUtils.longDateTimeFormat(details.DoneDate)}
              </Body>
            </div>
          )}
          {details.DoneBy && details.DoneBy !== null && (
            <div className={'details-row'}>
              <Body size="small">{t(translations.ClosedBy)}</Body>
              <Body size="small" bold={true}>
                {details.DoneBy ?? 'N/A'}
              </Body>
            </div>
          )}
          {details.Reservation !== null && (
            <div className={'details-row'}>
              <Body size="small">{t(translations.Reservation)}</Body>
              <ReservationLink
                id={details.Reservation}
                useSidePanel={useSidePanel}
                openPanelWithCover={openPanel}
              >
                {`# ${details.Reservation}`}
              </ReservationLink>
            </div>
          )}
          {globalSettings.GetBooleanByKey(AllowedSettings.AssetCatEnabled) &&
            !isNullOrUndefined(details.AssetCatName) && (
              <div className={'details-row'}>
                <Body size="small">{t(translations.AssetCatName)}</Body>
                <Body size="small" bold={true}>{`${details.AssetCatName} - ${
                  details.InternalServiceAvaliable
                    ? t(translations.InternalServiceAvaliable)
                    : ''
                }`}</Body>
              </div>
            )}
        </div>
      </DetailsRoot>
    </React.Fragment>
  );
}
export const DetailsRoot = styled.div`
  &.details-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding: 0;
    width: 100%;
    & .details-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      padding: 16px 0px;
      width: 100%;
      & .details-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        width: inherit;
      }
    }
  }
`;
