import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import {
  IRequestDetailsModel,
  IServiceRequestDetailsFormModel,
} from '../../slice/types';
import { FormikProps } from 'formik';
import { Button } from 'app/components/BasicButtons/Button';
import { DialogConfirm } from 'app/components/DialogConfirm';

export interface ISubmitServiceRequestButtonProps {
  serviceRequest?: IRequestDetailsModel;
  getRowsErrors?: (hasRowErrors: boolean) => void;
  disabled?: boolean;
  formRef: React.MutableRefObject<FormikProps<IServiceRequestDetailsFormModel> | null>;
}
export function SubmitServiceRequestButton(
  props: ISubmitServiceRequestButtonProps,
) {
  const { t } = useTranslation();
  const { serviceRequest, getRowsErrors, formRef } = props;
  // const formik = useFormikContext<IRequestDetailsModel>();
  const submitEnabled = React.useMemo(() => {
    return (
      serviceRequest?.Status.InternalStatusId === 'Draft' ||
      serviceRequest?.Status.InternalStatusId === 'ReturnedToUser'
    );
  }, [serviceRequest?.Status.InternalStatusId]);

  const handleSubmit = React.useCallback(async () => {
    if (!formRef.current?.isValid) {
      return;
    }
    if (serviceRequest !== undefined) {
      await formRef.current?.submitForm();
      setOpen(false);
    }
  }, [formRef, serviceRequest]);

  const [open, setOpen] = React.useState(false);

  const title = React.useMemo(() => {
    return submitEnabled
      ? t(translations.ServiceRequestSubmitButtonTooltip_Enabled)
      : t(translations.ServiceRequestSubmitButtonTooltip_Disabled);
  }, [submitEnabled, t]);
  const handleClick = React.useCallback(async () => {
    // TODO: move to the page level into formik submit handler
    const x = await formRef.current?.validateForm();
    if (!!x) {
      const isValid = Object.keys(x).length === 0;
      if (isValid) {
        setOpen(true);
      } else {
        if (!!getRowsErrors) {
          getRowsErrors(Object.entries(x).some(f => f[0] === 'Rows'));
        }
      }
    }
  }, [formRef, getRowsErrors]);
  return (
    <>
      <Button
        onClick={handleClick}
        title={title}
        size="small"
        startIcon={<Icon icon={'save'} />}
        disabled={!submitEnabled || props.disabled}
        aria-label={t(translations.Submit)}
      >
        {t(translations.Submit)}
      </Button>
      <DialogConfirm
        isOpen={open}
        title={title}
        body={t(translations.ServiceRequest_SubmitStepAlert)}
        onCancel={() => setOpen(false)}
        onConfirm={handleSubmit}
      />
    </>
  );
}
