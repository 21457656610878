import * as React from 'react';
import {
  selectglobalSettings,
  selectIsAuthenticated,
  selectLoading,
  selectSiteMapProvider,
} from 'app/slice/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
//import { AppSettings } from 'types/AppSettings';
import { NoAuthNodes } from 'types/SiteMapSettings';
import { AllowedSettings } from 'utils/globalSettings';
import { toLowerCaseSafely } from 'utils/typeUtils';
import { useFavicon } from './useFavicon';
import { useTitle } from './useTitle';
import { defaultNS, publicNS } from 'locales/i18n';

export interface TabTitleProps {
  isDispatcherLoading: boolean;
  i18Language: string;
}

/**
 * Custom hook for managing the tab title in the frontend layout.
 * @returns An array containing the current page title, a function to set the page title, and a function to set the page icon.
 */
export const useTabTitle = (): [
  string | undefined, //pageTitle:
  (title: string | undefined) => void, //setPageTitle:
  (icon: string | undefined) => void, //setPageIcon:
] => {
  const location = useLocation();
  const selectorLoading = useSelector(selectLoading);
  const siteMapProvider = useSelector(selectSiteMapProvider);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { t } = useTranslation(isAuthenticated ? defaultNS : publicNS);
  const globalSettings = useSelector(selectglobalSettings);
  const favIconImg: string | undefined = globalSettings.GetString(
    AllowedSettings.FavIconImg,
  );
  const favIconTitle: string | undefined = globalSettings.GetString(
    AllowedSettings.FavIconTitle,
  );
  const [favIcon, handleFavIconChange] = useFavicon('/favicon.png');
  const [title, handleTitleChange] = useTitle('BookitLab!');
  React.useEffect(() => {
    let active = !selectorLoading;
    if (active && !!favIconImg && favIconImg !== '' && favIconImg !== null) {
      if (favIconImg !== favIcon) {
        handleFavIconChange(favIconImg);
      }
    }
  }, [favIcon, favIconImg, handleFavIconChange, selectorLoading]);
  React.useEffect(() => {
    let active = !selectorLoading;
    if (active) {
      if (isAuthenticated === true) {
        if (!!siteMapProvider && siteMapProvider !== null) {
          let node = siteMapProvider.findNode(location.pathname);
          if (
            node !== undefined &&
            node !== null &&
            !!favIconTitle &&
            `${favIconTitle} - ${t(node.Title)}` !== title
          ) {
            handleTitleChange(`${favIconTitle} - ${t(node.Title)}`);
          }
        }
      } else {
        let node = NoAuthNodes(t).find(element => {
          if (element !== undefined) {
            const reactUrl = toLowerCaseSafely(element.reactUrl);
            if (reactUrl !== null) {
              let rpath = reactUrl.split('?')[0] ?? reactUrl;
              return location.pathname.toLowerCase().indexOf(rpath) > -1;
            } else {
              return (
                element.Url.toLowerCase().indexOf(
                  location.pathname.toLowerCase(),
                ) > -1
              );
            }
          }
          return undefined;
        });
        if (
          node !== undefined &&
          node !== null &&
          !!favIconTitle &&
          `${favIconTitle} - ${t(node.Title)}` !== title
        ) {
          handleTitleChange(`${favIconTitle} - ${t(node.Title)}`);
        }
      }
    }
    return () => {
      active = false;
    };
  }, [
    favIconTitle,
    handleTitleChange,
    isAuthenticated,
    location.pathname,
    selectorLoading,
    siteMapProvider,
    t,
    title,
  ]);
  return [title, handleFavIconChange, handleTitleChange];
};
