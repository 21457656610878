/**
 *
 * CampusPickerFilter
 *
 */
import { CampusPicker } from 'app/components/pickers/AutocompletePickers/CampusPicker';
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export const CampusPickerFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <CampusPicker
      onChange={handleOnChange}
      value={props.value}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
      ariaLabel={props.label}
      size="small"
      id={props.id}
      predicates={props.predicates}
      onPickerOpen={props.onOpen}
      fullWidth={props.fullWidth}
      urlType={props.urlType}
    />
  );
};
export default CampusPickerFilter;
