import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { regionsActions as actions } from '.';
import { appSettingsActions } from 'app/slice';
import { translations } from 'locales/translations';
import i18next from 'i18next';
import { IRegionDto } from 'app/pages/Regions/IRegionDto';
import { RegionsApi as api } from 'api/RegionsApi';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { RegionDetailsProps } from '..';
import { AxiosError } from 'axios';
import { IResponseTypeWithId } from 'types/ResponseType';

function* doInitRegion(
  action: PayloadAction<{
    id: number;
    initial?: IRegionDto;
  }>,
) {
  try {
    let data: IRegionDto | undefined = action.payload.initial;
    if (data === undefined) {
      const result = yield call(api.getRegion, { id: action.payload.id });
      console.debug('region result: ', result);
      data = result.value[0];
    }
    yield put(
      actions.initRegion_Success({
        hasErrors: data === undefined,
        data: data ?? { Id: 0, Name: '' },
        isEdit: action.payload.id > 0,
      }),
    );
  } catch (error: unknown) {
    yield put(actions.initRegion_Error(error));
  }
}
function* doCreateRegion(action: PayloadAction<{ model: IRegionDto }>) {
  try {
    const result = yield call(api.insertRegion, action.payload.model);
    let response = result as IResponseTypeWithId;
    let responseErrors = response.ErrorMessages;
    let responseWarnings = response.WarningMessages;
    let responseSuccess = response.SuccessMessages;
    if (responseErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          responseErrors.map(item => {
            return {
              key: 'regionInsertError',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    } else {
      if (responseSuccess.length > 0) {
        yield put(
          appSettingsActions.addNotification({
            key: 'regionInsertSuccess',
            message: responseSuccess[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id ?? undefined,
              created: true,
              itemName: i18next.t(translations.Region),
              detailsType: RenderPageType.RegionDetails,
              detailsTypeProps: {
                useSidePanel: true,
                id: '' + response.Id ?? -1,
                initialModel: {
                  Id: response.Id ?? -1,
                  Name: action.payload.model.Name,
                },
              } as RegionDetailsProps,
            },
            variant: 'success',
          }),
        );
      }
      if (responseWarnings.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            responseWarnings.map(item => {
              return {
                key: 'regionInsertWarning',
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = responseErrors.length > 0;
    yield put(
      actions.createRegion_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'regionInsert',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createRegion_Error(Error));
  }
}
function* doUpdateRegion(
  action: PayloadAction<{
    current: IRegionDto;
    original: IRegionDto;
  }>,
) {
  try {
    const results = yield call(
      api.updateRegion,
      action.payload.current,
      action.payload.current.Id,
    );
    let response = results as IResponseTypeWithId;
    let respErrors = response.ErrorMessages;
    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: 'regionUpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    } else {
      if (response.SuccessMessages.length > 0) {
        yield put(
          appSettingsActions.addNotification({
            key: 'regionUpdateSuccess',
            message: response.SuccessMessages[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id ?? undefined,
              created: false,
              itemName: i18next.t(translations.Region),
              detailsType: RenderPageType.RegionDetails,
              detailsTypeProps: {
                useSidePanel: true,
                queryParams: {
                  id: '' + response.Id ?? -1,
                },
                initialModel: action.payload.current,
              } as RegionDetailsProps,
            },
            variant: 'success',
          }),
        );
      }
      if (response.WarningMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.WarningMessages.map(item => {
              return {
                key: 'regionUpdateWarn',
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.updateRegion_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'regionUpdateErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateRegion_Error(Error));
  }
}

export function* regionsSaga() {
  yield takeLatest(actions.initRegion.type, doInitRegion);
  yield takeLatest(actions.createRegion.type, doCreateRegion);
  yield takeLatest(actions.updateRegion.type, doUpdateRegion);
}
