import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { useLocation } from 'react-router-dom';
import { GetRawValue } from 'app/components/BasicTable/types/FilterParam';

import { AssetsSelectionProps } from 'app/pages/MyAssetsPage/AssetsSelection/AssetsSelection';
import { DetectIsMobile } from 'utils/mobileDetect';
import { PageTopActionsProps } from '../type';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';

export function AddReservation({
  size,
  text,
  variant,
  icon,
  isIconButton,
  useSidePanel,
  isExpanded,
  compact = true,
  ...props
}: PageTopActionsProps) {
  const location = useLocation();
  const { openPanel } = useSidePanelState();
  const isMobile = DetectIsMobile();
  const { t } = useTranslation();

  return (
    <TopActionButton
      text={text || (t(translations.AddReservation) as string)}
      icon={icon || 'plus'}
      startIcon={icon || 'plus'}
      variant={
        isMobile && compact && !isIconButton ? 'ghost' : variant || 'ghost'
      }
      size={size || 'small'}
      noChangeOnMobile={!compact}
      isIconButton={isIconButton}
      onClick={() => {
        let selParams = {
          useSidePanel: !isMobile && !useSidePanel,
          actionType: 'Reservation',
          serviceIds: GetRawValue(
            new URLSearchParams(location.search),
            'selectedIds',
          ),
        } as AssetsSelectionProps;
        openPanel({
          renderPageType: RenderPageType.AssetsSelection,
          renderPageProps: selParams,
          expanded: isExpanded,
          useSidePanel: useSidePanel,
          isCover: useSidePanel,
        } as SidePanelOpenState);
      }}
      {...props}
    />
  );
}
