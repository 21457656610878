/**
 *
 * BasicDialogActions
 *
 */
import { DialogActions as MuiDialogActions } from '@material-ui/core';
import { DialogActionsRenderer } from './DialogActionsRender';
export interface BasicDialogActionsProps {
  leftActions: DialogActionsRenderer[];
  rightActions: DialogActionsRenderer[];
}

export function BasicDialogActions(props: BasicDialogActionsProps) {
  const { leftActions, rightActions } = props;
  return (
    <MuiDialogActions className={'basic-dialog-actions'}>
      {leftActions.length > 0 && (
        <div className={'actions-left'}>
          {leftActions.map((item, index) => (
            <span key={index}>{item()}</span>
          ))}
        </div>
      )}
      <div className={'actions-right'}>
        {rightActions.length > 0 &&
          rightActions.map((item, index) => <span key={index}>{item()}</span>)}
      </div>
    </MuiDialogActions>
  );
}
