import {
  BaseTextFieldProps,
  InputAdornment,
  InputAdornmentProps,
  TextFieldProps,
} from '@material-ui/core';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import BaseTextField from 'app/components/BasicInputs/BaseTextField';
import React from 'react';
import { isNullOrUndefined } from 'utils/typeUtils';
import {
  dateArrowSelection,
  DatePopupVariants,
  dateSelection,
  DateTimeMask,
  defaultDateTimeMask,
  getMaskedDateValue,
  getMaskedTimeValue,
  timeSelection,
} from '../utils/types';
import { useDateTimeChange } from '../utils/useDateTimeChange';

export interface KeyboardRifmInputProps
  extends Pick<
    BaseTextFieldProps,
    | 'autoFocus'
    | 'fullWidth'
    | 'helperText'
    | 'placeholder'
    | 'required'
    | 'disabled'
    | 'name'
    | 'id'
    | 'label'
    | 'margin'
    | 'error'
    | 'size'
  > {
  format: string;
  onChange: (value: string | null, withCompletion?: boolean) => void;
  openPicker: () => void;
  validationError?: React.ReactNode;
  inputValue: string;
  inputProps?: TextFieldProps['inputProps'];
  InputProps?: TextFieldProps['InputProps'];
  onBlur?: TextFieldProps['onBlur'];
  onFocus?: TextFieldProps['onFocus'];
  InputAdornmentProps?: Partial<InputAdornmentProps>;
  /** Override input component */
  TextFieldComponent?: React.ComponentType<TextFieldProps>;
  /** Icon displaying for open picker button */
  keyboardIcon?: React.ReactNode;
  /** Pass material-ui text field variant down, bypass internal variant prop */
  inputVariant?: TextFieldProps['variant'];
  info?: string;
  ariaLabel?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  togglePicker: () => void;
  variant: Omit<DatePopupVariants, 'separate'>;
  ampm?: boolean;
  isPickerOpen?: boolean;
  maskIfEmpty?: boolean;
  clearable?: boolean;
}

export function KeyboardRifmInput({
  inputValue,
  openPicker,
  InputProps,
  TextFieldComponent,
  inputProps,
  inputVariant,
  togglePicker,
  info,
  ariaLabel,
  startIcon,
  endIcon,
  variant,
  format,
  ampm,
  onChange,
  isPickerOpen,
  maskIfEmpty,
  // refuse = /[^\d]+/gi, //props.ampm ? /[^\dap]+/gi : /[^\d]+/gi
  // maskChar = '_',
  ...props
}: KeyboardRifmInputProps) {
  const TextComponent = !!TextFieldComponent
    ? TextFieldComponent
    : BaseTextField;

  // const keyDownRef = React.useRef({
  //   selectionStart: 0,
  //   selectionEnd: 0,
  // });
  // used to show mask for each part of the Date - e.g. MM, DD, YYYY
  const dateTimeMask: DateTimeMask = React.useMemo(() => {
    return defaultDateTimeMask(format, variant === 'time' ? 'time' : 'date');
  }, [format, variant]);

  // //indicator of start date based on dateUtils dateFrmat definition per local time zone - is date is start form day/month/year;
  // const dateStartWith = React.useMemo(() => {
  //   return dateStartFrom ?? dateStart;
  // }, [dateStartFrom]);

  //generated mask for date, so will be replaced when value is null
  const inputMask = React.useMemo(() => {
    return variant === 'time'
      ? getMaskedTimeValue(dateTimeMask, ampm)
      : getMaskedDateValue(dateTimeMask);
  }, [ampm, dateTimeMask, variant]);
  const [hasError, setHasError] = React.useState(
    props.error || Boolean(props.validationError),
  );

  const [errorMessage, setErrorMessage] = React.useState(
    hasError ? props.validationError || props.helperText : undefined,
  );
  React.useEffect(() => {
    setHasError(props.error || Boolean(props.validationError));
    setErrorMessage(props.validationError || props.helperText);
  }, [props.error, props.helperText, props.validationError]);
  const [selectionChanged, setSelectionChanged] = React.useState(false);
  const handleChange = (text: string, withCompletion?: boolean) => {
    let finalString =
      text === '' || text === inputMask
        ? maskIfEmpty
          ? inputMask
          : null
        : text;
    onChange(finalString, withCompletion);
  };
  const dateChange = useDateTimeChange({
    mask: true,
    // format: val => val,
    value: inputValue || (maskIfEmpty ? inputMask : ''),
    onChange: handleChange,
    variant: variant === 'time' ? 'time' : 'date',
    maskFormats: dateTimeMask,
    ampm: ampm,
    inputMask: inputMask,
    maskIfEmpty: maskIfEmpty,
    onSelectionChange: () => selectionChanged,
  });
  return (
    <TextComponent
      inputProps={{
        ...inputProps,
        'aria-label': props.label
          ? undefined
          : `${ariaLabel ? ariaLabel : 'date input'}`,
        // style: { minWidth: 80 },
        onMouseUp: event => {
          if (variant === 'time') {
            timeSelection(event, dateTimeMask);
          } else {
            dateSelection(event, dateTimeMask);
          }
          setSelectionChanged(true);
        },
        onMouseDown: event => {
          const target = event.target as HTMLInputElement;
          if (!!target.value && target.value !== '') {
            target.selectionStart = target.selectionEnd = target.value.length;
          }
        },
        onKeyUp: event => {
          if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            dateArrowSelection(
              event,
              event.key === 'ArrowLeft' ? 'left' : 'right',
              variant === 'time' ? 'time' : 'date',
              dateTimeMask,
            );
            setSelectionChanged(true);
            console.log(event.key, {
              selStart: (event.target as any).selectionStart,
              selEnd: (event.target as any).selectionEnd,
            });
          }
          if (event.key === 'ArrowUp') {
            // keyDownRef.current = {
            //   selectionStart: (event.target as any).selectionStart,
            //   selectionEnd: (event.target as any).selectionEnd,
            // };
            dateChange.onChange(event as any, 'increase');
            console.log('ArrowUp', {
              selectionStart: (event.target as any).selectionStart,
              selectionEnd: (event.target as any).selectionEnd,
            });
          }
          if (event.key === 'ArrowDown') {
            dateChange.onChange(event as any, 'decrease');
            // keyDownRef.current = {
            //   selectionStart: (event.target as any).selectionStart,
            //   selectionEnd: (event.target as any).selectionEnd,
            // };
            console.log('ArrowUp', {
              selStart: (event.target as any).selectionStart,
              selEnd: (event.target as any).selectionEnd,
            });
          }
          if (event.key === 'Enter' || event.key === 'Tab') {
            handleChange(dateChange.value, true);
          }
        },
        onKeyDown: event => {
          switch (event.key) {
            case 'ArrowLeft':
            case 'ArrowRight':
            case 'ArrowUp':
            case 'ArrowDown':
              event.preventDefault();
              event.nativeEvent.stopImmediatePropagation();
          }
        },
      }}
      error={hasError}
      onChange={event => {
        dateChange.onChange(event);
      }}
      InputProps={{
        ...InputProps,
        disableUnderline: inputVariant === 'standard',
        autoComplete: 'off',
        // startAdornment: (
        //   <InputAdornment position="start">
        //     <Icon icon="calendar-alt" color="default" colorExtend="textHover" />
        //   </InputAdornment>
        // ),
        endAdornment: (
          <>
            {(info && <InfoIcon title={info} error={hasError} />) ||
              (endIcon && (
                <InputAdornment position="end" className={'date-picker'}>
                  {endIcon}
                </InputAdornment>
              ))}
            <InputAdornment position="end" className={'date-picker'}>
              <IconButton
                onClick={e => togglePicker()}
                disabled={props.disabled}
                size="xs"
              >
                <Icon
                  icon={variant === 'time' ? 'clock' : 'calendar-alt'}
                  color="default"
                  colorExtend="textHover"
                />
              </IconButton>
            </InputAdornment>
            {props.clearable &&
              Boolean(inputValue || (maskIfEmpty ? inputMask : '')) && (
                <InputAdornment position="end" className={'date-picker'}>
                  <IconButton
                    size="xs"
                    shape="circle"
                    onClick={e => handleChange('', true)}
                  >
                    <Icon
                      icon="circle-xmark"
                      color="default"
                      colorExtend="textHover"
                      fontSize={13}
                    />
                  </IconButton>
                </InputAdornment>
              )}
          </>
        ),
        // style: { minWidth: 80 },
      }}
      InputLabelProps={{
        shrink:
          inputVariant === 'filled'
            ? !isNullOrUndefined(inputValue)
              ? true
              : false
            : undefined,
      }}
      helperText={errorMessage || props.helperText}
      onClick={ev => {
        if (!isPickerOpen) {
          const target = ev.target as HTMLInputElement;
          if (!!target && target.nodeName === 'INPUT') openPicker();
        }
      }}
      onMouseDown={ev => {
        if (!isPickerOpen) {
          const target = ev.target as HTMLInputElement;
          if (!!target && target.nodeName === 'INPUT') openPicker();
        }
      }}
      value={dateChange.value}
      variant={inputVariant}
      // onKeyUp={(event: React.KeyboardEvent<HTMLDivElement>) => {
      //   if (event.key === 'Enter') {
      //     event.stopPropagation();
      //     openPicker();
      //   }
      // }}
      autoFocus={props.autoFocus}
      size={props.size}
      fullWidth={props.fullWidth}
      placeholder={props.placeholder}
      required={props.required}
      disabled={props.disabled}
      name={props.name}
      id={props.id}
      label={props.label}
      margin={props.margin}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    />
  );
}
