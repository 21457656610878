import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@material-ui/core';
import { translations } from 'locales/translations';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { toQueryString, toRootedURL } from 'utils/url-utils';
import { DropDown } from 'app/components/DropDown';
import { IAlertTypesFilterDto } from 'api/odata/generated/entities/IAlertTypesFilterDto';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { usePromise } from 'app/hooks/usePromise';
import { IODataQueryResponse } from 'api/odata/IODataQueryResponse';
import { httpClient } from 'api/HttpClient';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { WorkOrderDetailsProps } from '../WorkOrders/WorkOrderDetailsPage/Details';

export function CreateAnAssetRelatedEvent(props: {
  assetId: number;
  downtime?: boolean;
  mismatchCore: boolean;
  useSidePanel?: boolean;
  openPanel?: (state: SidePanelOpenState) => void;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const [state, setState] = useAsyncExtendedState<IAlertTypesFilterDto[]>([]);
  const [fetchAlertTypesState, fetchAlertTypes] = usePromise(
    fetchAlertTypesPromise,
  );
  React.useEffect(() => {
    setState(fetchAlertTypes(props.assetId));
  }, [fetchAlertTypes, props.assetId, setState]);
  const downtimeProps = React.useMemo(() => {
    return props.downtime ? { offH: true, down: true } : {};
  }, [props.downtime]);
  return (
    <DropDown
      size="small"
      startIcon={<Icon icon="plus" />}
      title={t(translations.CreateAnAssetRelatedEvent)}
      processing={fetchAlertTypesState.status === 'pending'}
      color="primary"
      variant={/*!props.bookable ? 'main' : */ 'white'}
      disabled={props.mismatchCore || props.disabled === true}
      addChevron
      menuChildren={onClose => [
        <MenuItem key={'title for this menu'} disabled>
          {t(translations.PleaseSelectAnEventType)}
        </MenuItem>,

        ...state.map(
          a =>
            a.Name !== null && (
              <MenuItem
                component={!!props.openPanel ? 'span' : 'a'}
                key={a.Id + a.Name + 'key'}
                href={
                  !!props.openPanel
                    ? undefined
                    : toRootedURL(
                        `/workorders/details?${toQueryString({
                          aType: a.Id,
                          assetId: props.assetId,
                          ...downtimeProps,
                        })}`,
                      )
                }
                onClick={() => {
                  !!props.openPanel &&
                    props.openPanel({
                      renderPageType: RenderPageType.WorkOrderDetails,
                      renderPageProps: {
                        queryParams: {
                          aType: a.Id.toString(),
                          assetId: props.assetId.toString(),
                          down: !!downtimeProps.down
                            ? downtimeProps.down.toString()
                            : undefined,
                          offH: !!downtimeProps.offH
                            ? downtimeProps.offH.toString()
                            : undefined,
                        },
                        useSidePanel: true,
                      } as WorkOrderDetailsProps,
                      expanded: false,
                      useSidePanel: props.useSidePanel,
                      isCover: props.useSidePanel,
                    });
                  onClose();
                }}
              >
                {a.Name}
              </MenuItem>
            ),
        ),
      ]}
      menuProps={{}}
    >
      {t(translations.Event)}
    </DropDown>
  );
}
export const fetchAlertTypesPromise = async (assetId: number) => {
  const response: IODataQueryResponse<IAlertTypesFilterDto> =
    await httpClient.get(`/api/odata/v4/AssetDetails2(${assetId})/AlertTypes`);
  return response.value;
};
