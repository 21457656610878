/**
 *
 * ActionButton
 *
 */
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import * as React from 'react';
import { Button, ButtonsVariant } from '../Button';
import { IPathAndQuery } from 'utils/url-utils';
import { IconProp } from 'app/AllIcons';
import { PropTypes } from '@material-ui/core';

export interface ActionButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  starticon?: IconProp;
  endicon?: IconProp;
  text: string;
  title?: string;
  variant?: ButtonsVariant;
  size?: 'large' | 'medium' | 'small';
  autoFocus?: boolean;
  processing?: boolean;
  type?: 'button' | 'submit' | 'reset';
  target?: string;
  id?: string;
  href?: string | IPathAndQuery;
  color?: PropTypes.Color;
  fullWidth?: boolean;
}
export function ActionButton({
  disabled,
  onClick,
  title,
  starticon,
  endicon,
  text,
  variant,
  size,
  processing,
  fullWidth,
  id,
  ...other
}: ActionButtonProps) {
  return (
    <Button
      aria-label={text}
      variant={variant || 'white'}
      size={size || 'small'}
      disabled={disabled}
      onClick={onClick}
      startIcon={starticon && <Icon icon={starticon} />}
      endIcon={endicon && <Icon icon={endicon} />}
      id={id}
      fullWidth={fullWidth}
      processing={processing}
      title={title}
      {...other}
    >
      <>{text}</>
    </Button>
  );
}
