/**
 *
 * UsageDetails
 *
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IUsageEquipmentDto,
  UsageDetailsState,
  UsageQueryStringParameters,
} from './slice/types';
import { useUsageStateSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import {
  selectCreateManually,
  selectCredit,
  selectCreditCompleted,
  selectCreditProcessing,
  selectIsEdit,
  selectSavedOfflineServices,
  selectShowEndUsageDialog,
  selectStopUsageProcessing,
  selectUsageCompleted,
  selectUsageHasError,
  selectUsageNotFound,
  selectUsageProcessing,
  selectUsageSettings,
  selectUsageStateData,
  selectUsageStateProcessing,
} from './slice/selectors';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { translations } from 'locales/translations';
import { selectHasNotSavedChanges } from 'app/Layout/FrontendLayout/slice/selectors';
import { push } from 'connected-react-router';
import { buildURL, toQueryString, toRootedURL } from 'utils/url-utils';
import { Progress } from 'app/components/LoadingIndicator';
import { Beforeunload } from 'react-beforeunload';

import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';

import { ServiceType } from 'api/odata/generated/enums/ServiceType';

import { FormikProps } from 'formik';
import { UsageModifications } from './components/Modifications';
import { H3 } from 'app/components/Typography';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import { Entity } from 'types/common';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { tryParseInt } from 'utils/string-utils';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { ErrorServices } from 'app/pages/OtherServiceDetails/Details/slice/types';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import useSidePanelState, {
  SidePanelCloseState,
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { IsReadOnlyUser } from 'app/permissions/WorkOrders/workOrdersPermissionUtils';
import { AssetPopUpProps } from 'app/pages/AssetPopUp';
import useGlobalSettingsHook from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { CalculatePricing } from 'app/pages/ReservationDetails/Details/components/CalculatePricing';
import { UsageForm } from './components/UsageForm';
import { EndUsageConfirm, EndUsageData } from 'app/components/EndUsageConfirm';
import { dateUtils } from 'utils/date-utils';
import { BasicToggle } from 'app/components/BasicToggle';
import { ReferenceType } from 'enums/ReferenceType';
import { NotAllowedContent } from '../../ReservationDetails/Details';
import { Div100 } from '../../../components/AssetQuickSearch/styled';

export interface UsageDetailsProps extends SidePanelContentProps, CoverProps {
  queryParams: UsageQueryStringParameters;
}

export const UsageDetails = React.memo(function UsageDetails(
  props: UsageDetailsProps,
) {
  const { queryParams, useSidePanel, closeCover, isCover } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actions } = useUsageStateSlice();

  const { actions: layoutActions } = useLayoutSlice();
  // const isMobile = DetectIsMobile();

  /// Refs ///
  const submitFormRef = React.useRef<any>(null);

  const innerFormRef = React.useRef<FormikProps<UsageDetailsState> | null>(
    null,
  );

  /// selectors ///
  const processing = useSelector(selectUsageStateProcessing);
  const usageData = useSelector(selectUsageStateData);
  const usageId = usageData?.Id ?? tryParseInt(queryParams.id);
  const usageSettings = useSelector(selectUsageSettings);
  const User = useSelector(selectAuthenticatedUser);
  const hasChanges = useSelector(selectHasNotSavedChanges);
  const publicUrl = useSelector(selectPublicUrl);
  const actionCompleted = useSelector(selectUsageCompleted);

  const hasError = useSelector(selectUsageHasError);
  const usageProcessing = useSelector(selectUsageProcessing);
  const stopUsageProcessing = useSelector(selectStopUsageProcessing);
  const showEndUsage = useSelector(selectShowEndUsageDialog);
  const savedOfflineServices = useSelector(selectSavedOfflineServices);
  const createMnually = useSelector(selectCreateManually);
  const creditProcessing = useSelector(selectCreditProcessing);
  const credit = useSelector(selectCredit);
  const creditCompleted = useSelector(selectCreditCompleted);

  const edit = useSelector(selectIsEdit);

  //const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const usageNotFound = useSelector(selectUsageNotFound);
  // const showShortView = React.useMemo(() => {
  //   return isMobile || (useSidePanel && !sidePanelExpanded);
  // }, [isMobile, sidePanelExpanded, useSidePanel]);

  const { cover, openPanel, closePanel, coverClosed, onCloseCover } =
    useSidePanelState(
      () => {
        dispatch(actions.resetDetailsState());
        return true;
      },
      undefined,
      useSidePanel,
      isCover,
    );
  /// Settings ///
  const globalSettings = useGlobalSettingsHook();
  /// Local State ///
  const [busy, setBusy] = React.useState<boolean | undefined>(false);

  const [firstLoad, setFirstLoad] = React.useState<boolean | undefined>(true);
  const [requestCompleted, setRequestCompleted] = React.useState<
    boolean | undefined
  >(undefined);

  const [showCalculateCredit, setShowCalculateCredit] = React.useState<
    boolean | undefined
  >(undefined);

  const [openModifications, setOpenModifications] =
    React.useState<boolean>(false);
  const [resetUsage, setResetUsage] = React.useState<boolean>(false);
  const setShowEndUsage = React.useCallback(
    (value?: boolean) => {
      dispatch(actions.setShowEndUsageDialog(value));
    },
    [actions, dispatch],
  );
  // const { newDate } = useSystemDate();

  const userReadOnly = IsReadOnlyUser(User);
  // const isAdminRoles = React.useMemo(() => {
  //   return User?.HasAdminRoles() || User?.IsKioskAdminUser();
  // }, [User]);
  // const missingData = React.useMemo(() => {
  //   return (
  //     !isAdminRoles &&
  //     !tryParseInt(queryParams.id) &&
  //     !tryParseInt(queryParams.sid)
  //   );
  // }, [isAdminRoles, queryParams.id, queryParams.sid]);
  const calculateButtonVisible = React.useMemo(() => {
    return globalSettings.showCalculatePrice &&
      globalSettings.budgetModuleEnabled
      ? usageSettings?.BudgetsTurnedOn
      : true;
  }, [
    globalSettings.budgetModuleEnabled,
    globalSettings.showCalculatePrice,
    usageSettings?.BudgetsTurnedOn,
  ]);

  const IsAdmin = React.useMemo(() => {
    let equipment = usageData?.BaseEquipment as IUsageEquipmentDto;
    return (
      usageSettings?.IsAllAdmin ||
      (!!equipment &&
        equipment !== null &&
        User?.IsAdminOf({
          ServiceGroupId: equipment.ServiceGroupId,
          ServiceId: equipment.Id,
          ServiceTypeId: ServiceType.Online,
        }))
    );
  }, [User, usageData?.BaseEquipment, usageSettings?.IsAllAdmin]);
  const resetButtonVisible = React.useMemo(() => {
    return (
      edit && usageSettings?.PastUsage && usageSettings?.IsEditable && IsAdmin
    );
  }, [IsAdmin, edit, usageSettings?.IsEditable, usageSettings?.PastUsage]);
  const isOwner = React.useMemo(() => {
    return usageData?.BookedBy?.Id === User?.Id;
  }, [User?.Id, usageData?.BookedBy?.Id]);

  const editable = React.useMemo(() => {
    return edit ? usageSettings?.IsEditable && IsAdmin : isOwner || IsAdmin;
  }, [IsAdmin, edit, isOwner, usageSettings?.IsEditable]);

  const not_restricted = React.useMemo(() => {
    if (edit) {
      let budget =
        usageData?.Budget !== null && usageData?.Budget !== undefined
          ? usageData.Budget
          : null;

      let confidential =
        ((!!usageData?.BudgetExperiment &&
          usageData?.BudgetExperiment !== null) ||
          globalSettings.confidentialBudgetsEnabled) &&
        !!usageData?.Budget &&
        (budget === null
          ? false
          : (budget as IBudgetFilterDto).Confidential ?? false);
      let noBudgetRestricted = confidential ? IsAdmin || isOwner : true;

      return noBudgetRestricted;
    }

    return true;
  }, [
    IsAdmin,
    edit,
    globalSettings.confidentialBudgetsEnabled,
    isOwner,
    usageData?.Budget,
    usageData?.BudgetExperiment,
  ]);
  const usageEndMessage = React.useMemo(() => {
    if (!!usageSettings) {
      if (usageSettings.AbruptlyEnded) {
        if (
          usageSettings.Reservation === null ||
          (usageSettings?.Reservation != null &&
            !usageSettings?.Reservation.IsOngoing)
        ) {
          return t(translations.AbruptlyEndedAt_info).replace(
            '{0}',
            dateUtils.shortDateTimeFormat(usageData?.EndTime) ?? '',
          );
        }
      }
    }
    return undefined;
  }, [t, usageData?.EndTime, usageSettings]);
  React.useEffect(() => {
    let active = firstLoad && !userReadOnly;
    if (globalSettings.loadCompleted && active) {
      setFirstLoad(false);
      dispatch(
        actions.initUsage({
          query: queryParams,
          globalSettings: globalSettings,
        }),
      );
    }
    return () => {
      active = false;
    };
  }, [actions, dispatch, firstLoad, globalSettings, queryParams, userReadOnly]);
  React.useEffect(() => {
    if (edit) {
      if (
        savedOfflineServices.length > 0 &&
        savedOfflineServices.some(f => f.Id < 1)
      ) {
        dispatch(layoutActions.setNotSavedChanges(true));
      }
    }
  }, [dispatch, edit, layoutActions, savedOfflineServices]);
  const handleCreateManually = React.useCallback(
    (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(actions.setCreateManully(checked));
      dispatch(
        actions.setAnyValue({
          fieldKey: 'EndTime',
          fieldValue: undefined,
        }),
      );
    },
    [actions, dispatch],
  );
  const handleCloselClick = React.useCallback(() => {
    if (useSidePanel) {
      closePanel({
        isCover: isCover || !!cover,
        useSidePanel: useSidePanel,
        showConfirm: hasChanges,
        onClose: () => {
          dispatch(actions.resetDetailsState());
        },
      } as SidePanelCloseState);
    } else {
      dispatch(push('/usagehistory'));
    }
  }, [actions, closePanel, cover, dispatch, hasChanges, isCover, useSidePanel]);

  const handleSaveClick = React.useCallback((e: any) => {
    handleSubmitForm(e);
  }, []);

  const handleSubmitForm = (e: any) => {
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmit = React.useCallback(
    (values: UsageDetailsState) => {
      setBusy(true);
      if (edit) {
        dispatch(
          actions.updateUsage({
            current: values,
            offlineServices: savedOfflineServices,
            settings: usageSettings,
            reset: resetUsage,
          }),
        );
        if (resetUsage) {
          setResetUsage(false);
        }
      } else {
        dispatch(
          actions.startUsage({
            details: values,
            settings: usageSettings,
            offlineServices: savedOfflineServices,
            manual: createMnually,
          }),
        );
      }
    },
    [
      actions,
      createMnually,
      dispatch,
      edit,
      resetUsage,
      savedOfflineServices,
      usageSettings,
    ],
  );
  const [offlineServicesErrors, setOfflineServicesErrors] = React.useState<
    ErrorServices[]
  >([]);
  const handleStopUsage = React.useCallback(
    (usageData: EndUsageData) => {
      dispatch(
        actions.stopUsage({
          usage: usageData,
          globalSettings: globalSettings,
          isAdmin: IsAdmin || false,
        }),
      );
    },
    [IsAdmin, actions, dispatch, globalSettings],
  );
  const handleSetError = React.useCallback(
    (error: ErrorServices) => {
      if (error.error !== undefined) {
        setOfflineServicesErrors([
          ...offlineServicesErrors.filter(f => f.id !== error.id),
          error,
        ]);
      } else {
        setOfflineServicesErrors([
          ...offlineServicesErrors.filter(f => f.id !== error.id),
        ]);
      }
    },
    [offlineServicesErrors],
  );

  const handleEquipmentClick = React.useCallback(
    (equipment: Entity<number>) => {
      const assetProps: AssetPopUpProps = {
        identifier: {
          serviceType: 'Online',
          serviceId: equipment.Id,
        },
        useSidePanel: true,
      };
      openPanel({
        renderPageType: RenderPageType.AssetDetails,
        renderPageProps: assetProps,
        expanded: false,
        useSidePanel: true,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [openPanel, useSidePanel],
  );
  const handleRestrictionsClick = React.useCallback(
    (equipment: Entity<number>) => {
      let restrictProps = {
        serviceId: equipment.Id,
        showTable: IsAdmin,
        serviceGroupId: (equipment as IUsageEquipmentDto).ServiceGroupId,
      };
      openPanel({
        renderPageType: RenderPageType.RestrictionDetails,
        renderPageProps: restrictProps,
        expanded: false,
        useSidePanel: true,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [IsAdmin, useSidePanel, openPanel],
  );
  const handleOfflineServiceClick = React.useCallback(
    (service: IOtherServices) => {
      if (edit) {
        let otherProps = {
          initialService: service,
          saveCreatable: true,
          queryParams: {
            id: String(Number(service.Id) ?? -1),
          },
        };
        openPanel({
          renderPageType: RenderPageType.OtherServiceDetails,
          renderPageProps: otherProps,
          expanded: false,
          useSidePanel: true,
          isCover: useSidePanel,
        } as SidePanelOpenState);
      }
    },
    [edit, openPanel, useSidePanel],
  );
  const handleAddBatchClick = React.useCallback(
    (batch: IInventoryBatchDto | null, serviceTypeId: number) => {
      let batchProps = {
        initialBatch: batch ?? undefined,
        queryParams: {
          id: '' + (batch?.Id ?? -1),
          stid: '' + serviceTypeId,
        },
        saveCreatable: useSidePanel,
      };
      openPanel({
        renderPageType: RenderPageType.InventoryBatch,
        renderPageProps: batchProps,
        expanded: false,
        useSidePanel: true,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [openPanel, useSidePanel],
  );
  const handleCalculateCredit = React.useCallback(() => {
    if (!!innerFormRef?.current) {
      setShowCalculateCredit(true);
      dispatch(actions.calculateCredit(innerFormRef.current.values));
    }
  }, [actions, dispatch]);
  const handleCalculateClose = React.useCallback(() => {
    setShowCalculateCredit(false);
    dispatch(actions.resetCreditState());
  }, [actions, dispatch]);
  React.useEffect(() => {
    let active = processing === false;
    if (active) {
      if (actionCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
        if (!hasError) {
          handleCloselClick();
        }
      } else if (actionCompleted === false) {
        setRequestCompleted(undefined);
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [
    actionCompleted,
    actions,
    dispatch,
    handleCloselClick,
    hasError,
    processing,
  ]);
  React.useEffect(() => {
    let active = creditProcessing === false;
    if (active) {
      if (creditCompleted === true && !showCalculateCredit) {
        dispatch(actions.resetCreditState());
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [
    actions,
    creditCompleted,
    creditProcessing,
    dispatch,
    showCalculateCredit,
  ]);
  const topActions = React.useMemo(() => {
    let actions = [] as ActionRenderer[];
    if (!edit && IsAdmin) {
      actions.push(() => (
        <BasicToggle
          label={t(translations.DocumentPreviousUsageRecord)}
          name="createusage"
          onChange={handleCreateManually}
          checked={createMnually}
          size="small"
          boxed
        />
      ));
    }
    return actions;
  }, [IsAdmin, createMnually, edit, handleCreateManually, t]);

  /// actions ///
  const leftActions = React.useMemo(() => {
    let mactions = [] as ActionRenderer[];
    //save button for admin
    if (
      IsAdmin &&
      ((!edit && createMnually) ||
        (edit && usageSettings?.PastUsage && usageSettings?.IsEditable))
    ) {
      mactions.push(() => (
        <React.Fragment>
          <Button
            size="small"
            onClick={e => {
              handleSaveClick(e);
            }}
            disabled={busy}
            processing={!requestCompleted && busy}
          >
            {edit ? t(translations.UpdateUsage) : t(translations.CreateUsage)}
          </Button>
        </React.Fragment>
      ));
    }
    // start usage button for owner and admin
    if (
      !edit &&
      (isOwner || IsAdmin) &&
      !!usageSettings &&
      usageSettings.BaseEquipment !== null &&
      usageSettings.BaseEquipment !== undefined &&
      !createMnually
    ) {
      mactions.push(() => (
        <React.Fragment>
          <Button
            size="small"
            id="StartUsageButtonId"
            startIcon={<Icon icon={'play'} />}
            onClick={e => {
              handleSaveClick(e);
            }}
            disabled={busy || usageSettings.HasErrors}
            processing={!requestCompleted && busy}
          >
            {t(translations.StartUsage)}
          </Button>
        </React.Fragment>
      ));
    }
    if (
      (isOwner || IsAdmin) &&
      edit &&
      usageSettings?.OngoingUsage &&
      !usageSettings?.IsLoanDescType
    ) {
      mactions.push(() => (
        <React.Fragment>
          <Button
            size="small"
            id="EndUsageButtonId"
            startIcon={<Icon icon="pause" />}
            onClick={e => {
              setShowEndUsage(true);
            }}
            disabled={busy || !(isOwner || IsAdmin) || stopUsageProcessing}
            processing={stopUsageProcessing || busy}
          >
            {t(translations.StopUsage)}
          </Button>
        </React.Fragment>
      ));
    }
    if (resetButtonVisible) {
      mactions.push(() => (
        <Button
          variant="danger"
          size="small"
          startIcon={<Icon icon="ban" />}
          aria-label="reset"
          id="ResetUsageButtonId"
          onClick={e => {
            setResetUsage(true);
            dispatch(
              actions.setAnyValue({
                fieldKey: 'Remarks',
                fieldValue: 'Reset',
              }),
            );
            handleSaveClick(e);
          }}
        >
          {t(translations.Reset)}
        </Button>
      ));
    }
    if (edit && IsAdmin && usageSettings?.PastUsage) {
      mactions.push(() => (
        <Button
          id="ModificationsHistoryButtonId"
          variant="white"
          size="small"
          startIcon={<Icon icon="square" />}
          aria-label="history"
          onClick={() => setOpenModifications(true)}
        >
          {t(translations.ModificationsHistory)}
        </Button>
      ));
    }

    if (calculateButtonVisible) {
      mactions.push(() => (
        <Button
          id="CalculateCreditPopupButtonId"
          variant="white"
          size="small"
          startIcon={<Icon icon="money-bill" />}
          onClick={handleCalculateCredit}
          title={
            offlineServicesErrors.length > 0
              ? (t(translations.OfflineServicesError_info) as string)
              : (t(translations.CalculateUsagePricing_info) as string)
          }
          disabled={creditProcessing || offlineServicesErrors.length > 0}
          processing={creditProcessing}
        >
          {t(translations.CalculatePricing)}
        </Button>
      ));
    }
    if (
      usageSettings?.chargesViewAllowed &&
      edit &&
      usageSettings?.HasCharges
    ) {
      mactions.push(() => (
        <Button
          id="UsageChargesId"
          variant="white"
          size="small"
          startIcon={<Icon icon="dollar-sign" />}
          href={toRootedURL(`/Charges`, {
            refId: usageId,
            refType: ReferenceType.Usage as number,
          })}
          target="_blank"
        >
          {t(translations.UsageCharges)}
        </Button>
      ));
    }
    return mactions;
  }, [
    IsAdmin,
    actions,
    busy,
    calculateButtonVisible,
    createMnually,
    creditProcessing,
    dispatch,
    edit,
    handleCalculateCredit,
    handleSaveClick,
    isOwner,
    offlineServicesErrors.length,
    requestCompleted,
    resetButtonVisible,
    setShowEndUsage,
    stopUsageProcessing,
    t,
    usageId,
    usageSettings,
  ]);

  const rightActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <IconButton
            variant="ghost"
            size="small"
            title={t(translations.Close)}
            onClick={handleCloselClick}
            id="CloseUsageButtonId"
          >
            <Icon icon="times" />
          </IconButton>
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [handleCloselClick, t]);

  const pageTitle = usageData
    ? edit
      ? ` ${t(translations.Usage)} #${usageId || 'N/A'}`
      : createMnually
      ? `${t(translations.menu_CreateUsage)}`
      : ` ${t(translations.StartUsage)}`
    : undefined;
  const pageName = edit
    ? t(translations.menu_UsageDetails)
    : t(translations.menu_Usage);
  /// render ///
  return (
    <React.Fragment>
      <PageWrapper
        pageName={pageName}
        titlePage={pageTitle}
        loading={processing}
        useSidePanel={useSidePanel}
        closable={true}
        closeSidePanel={handleCloselClick}
        //leftTopActions={topActions}
        // topProcessing={
        //   equipmentChangeCompleted === false || asyncChangeLoading === true
        // }
        leftTopActions={topActions}
        leftActions={not_restricted ? leftActions : []}
        leftActionsMaxLength={resetButtonVisible ? 2 : 1}
        pageLink={buildURL(publicUrl + 'usage/details', props.queryParams)}
        toPageLink={`usage/details?${toQueryString(props.queryParams)}`}
        rightActions={rightActions}
        isCover={isCover}
        // rightTopActions={not_restricted ? rightTopActions : undefined}
        //rightTopActionsAsMenu
        confirm={
          showCalculateCredit ? (
            <CalculatePricing
              open={showCalculateCredit}
              title={t(translations.CalculatePricing)}
              onClose={handleCalculateClose}
              edit={edit}
              credit={credit}
              creditCompleted={creditCompleted}
              creditProcessing={creditProcessing}
              pricingType={'Usage'}
            />
          ) : !!openModifications ? (
            <UsageModifications
              open={openModifications}
              setOpen={value => setOpenModifications(value)}
              usageId={usageData?.Id ?? 0}
            />
          ) : (
            <></>
          )
        }
        cover={cover}
        closeCover={!isCover ? onCloseCover : closeCover}
        coverClosed={coverClosed}
      >
        {usageData !== undefined && !processing ? (
          not_restricted ? (
            <UsageForm
              initialValues={usageData || ({} as UsageDetailsState)}
              bindSubmitForm={bindSubmitForm}
              onSubmit={handleSubmit}
              isEdit={edit || false}
              formRef={innerFormRef}
              isAdmin={IsAdmin || false}
              user={User}
              useSidePanel={useSidePanel}
              onEquipmentClicked={handleEquipmentClick}
              onRestrictionsClick={handleRestrictionsClick}
              onOfflineServiceClick={handleOfflineServiceClick}
              onAddBatchClick={handleAddBatchClick}
              globalSettings={globalSettings}
              editable={editable}
              offlineServicesErrors={offlineServicesErrors}
              setError={handleSetError}
              isCover={isCover}
              openPanelWithCover={openPanel}
            />
          ) : (
            <NotAllowedContent>
              <div className="columns">
                <H3>{t(translations.UsageRestrictedNotAllowed) as string}</H3>
                <Button
                  variant="ghost"
                  size="medium"
                  startIcon={<Icon icon="times" />}
                  onClick={handleCloselClick}
                >
                  {t(translations.Continue)}
                </Button>
              </div>
            </NotAllowedContent>
          )
        ) : //</PageWrapper> missingData ? (
        //   <FlexRowDiv style={{ alignSelf: 'center', justifySelf: 'center' }}>
        //     <Box
        //       style={{
        //         display: 'flex',
        //         flexDirection: 'column',
        //         gap: 16,
        //         paddingInline: 16,
        //       }}
        //     >
        //       <H3>{t(translations.UsageNotAllowedCommand) as string}</H3>
        //       <Button
        //         variant="ghost"
        //         size="medium"
        //         startIcon={<Icon icon="times" />}
        //         onClick={handleCloselClick}
        //       >
        //         {t(translations.Continue)}
        //       </Button>
        //     </Box>
        //   </FlexRowDiv>
        // ) :
        usageData === undefined &&
          !processing &&
          (usageNotFound || userReadOnly) ? (
          <NotAllowedContent>
            <div className="columns">
              <H3>{t(translations.UsageRestrictedNotAllowed) as string}</H3>
              <Button
                variant="ghost"
                size="medium"
                startIcon={<Icon icon="times" />}
                onClick={handleCloselClick}
              >
                {t(translations.Continue)}
              </Button>
            </div>
          </NotAllowedContent>
        ) : (
          <React.Fragment>
            {' '}
            <Div100>
              <Progress inProgress={processing} size={80} />
            </Div100>
          </React.Fragment>
        )}
      </PageWrapper>
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
      {showEndUsage && !!usageData && (
        <EndUsageConfirm
          ids={usageSettings?.OfflineUsageEndServices ?? []}
          isOpen={showEndUsage}
          equipmentId={usageData?.BaseEquipment?.Id ?? 0}
          usageProcessing={usageProcessing || stopUsageProcessing}
          onClose={() => {
            setShowEndUsage(false);
          }}
          onConfirm={handleStopUsage}
          message={usageEndMessage}
          usageId={usageData.Id}
        />
      )}
    </React.Fragment>
  );
});
