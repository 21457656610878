import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AssetDetailsAdditionalData } from '../components/types';
import {
  GetAlertsEnabled,
  GetIsBookable,
  GetNotificationAvailable,
  GetReservationEnabled,
  GetUsageEnabled,
} from '../components/Utils';
import { getAvailabilityUnion } from 'api/odata/generated/enums/AvailabilityTypes';
import { DetectIsMobile } from 'utils/mobileDetect';
import { selectAppSettings, selectglobalSettings } from 'app/slice/selectors';
import { AllowedSettings } from 'utils/globalSettings';
import { CreateAnAssetRelatedEvent } from '../CreateAnAssetRelatedEvent';
import { ReservationCreateButton } from 'app/components/ReservationCreateButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { Button } from 'app/components/BasicButtons/Button';
import { toRootedURL } from 'utils/url-utils';
import { CalendarButton } from 'app/components/CalendarButton';
import { useReportUsageDetailsPopUp } from 'app/pages/Actions/GlobalSelectedActions/OpenUsageDetails';
import { DropDown } from 'app/components/DropDown';
import { Badge, MenuItem } from '@material-ui/core';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { WorkOrderDetailsProps } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import { ReportConsumablesProps } from 'app/pages/OtherServiceDetails/ReportConsumables';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { Entity } from 'types/common';
import { ServiceGroupRegistrationStatus } from 'enums/ServiceGroupRegistrationStatus';
import { useAssetsPermissions } from 'app/pages/Actions/GlobalSelectedActions/ValidToRender/useAssetsPermissionsUtils';
import { getAssetEditLink } from '../getAssetEditLink';
import { MenuItemLinkProps } from 'app/components/Menu/MenuItemLink';
import { ExportQRCodesButton } from 'app/components/QrCodeExport';
import { RenderTypes } from 'app/pages/Actions/GlobalSelectedActions/ValidToRender/renderTypes';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { PrintSelectedAssetLabelsButton } from 'app/components/LabelPrint/PrintSelectedAssetLabelsButton';
import { IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';

export interface AssetActionsProps {
  assetId: number;
  Name: string;
  availabilityId?: number;
  buttonsData?: AssetDetailsAdditionalData;
  serviceId: number | null;
  serviceTypeId: number | null;
  serviceGroupId?: number;
  divisionId?: number;
  serviceGroupName?: string;
  active?: boolean;
  mismatchCore?: boolean;
  isAuthenticated: boolean;
  authenticatedUser?: AuthenticatedUser;
  canBeCreatedFromLinkOnly?: boolean;
  coreApprovalStatusId?: number;
  canRequestAccessToCore?: boolean;
  addFromCalendar?: boolean;
  isDashboardCore?: boolean;
  allowToUser?: boolean;
  saveCore?: (core: Entity<number>[]) => void;
  setOpenEndUsage: (open: boolean) => void;
}
const ButtonOrLink = (props: MenuItemLinkProps) => {
  const { icon, target, text, disabled, ...rest } = props;
  return (
    <Button
      startIcon={!icon ? undefined : <Icon icon={icon} />}
      size={'small'}
      variant="white"
      component={'a'}
      disabled={disabled}
      target={!!target ? '_blank' : '_self'}
      {...rest}
    >
      {text}
    </Button>
  );
};

export const useAssetActions = ({
  useSidePanel,
  openPanel,
}: {
  useSidePanel?: boolean;
  openPanel: (state: SidePanelOpenState) => void;
}) => {
  const { t } = useTranslation();
  const appSettings = useSelector(selectAppSettings);
  const globalSettings = useSelector(selectglobalSettings);
  const isMobile = DetectIsMobile();
  const hideTrainingSessionsSettings = globalSettings?.GetBoolean(
    AllowedSettings[AllowedSettings.HideTrainingSessionsSettings],
  );
  const startUsage = useReportUsageDetailsPopUp();
  const { allowedToView } = useAssetsPermissions();

  const getActions = React.useCallback(
    ({
      assetId,
      Name,
      availabilityId,
      buttonsData,
      serviceId,
      serviceTypeId,
      serviceGroupId,
      divisionId,
      serviceGroupName,
      active,
      mismatchCore,
      isAuthenticated,
      authenticatedUser,
      canBeCreatedFromLinkOnly,
      coreApprovalStatusId,
      canRequestAccessToCore,
      addFromCalendar,
      isDashboardCore,
      allowToUser,
      saveCore,
      setOpenEndUsage,
    }: AssetActionsProps): ActionRenderer[] => {
      let buttonActions = [] as ActionRenderer[];
      if (authenticatedUser?.IsReadOnlyUser()) {
        return buttonActions;
      }
      const isAdmin =
        authenticatedUser?.HasAdminGroupOnlyPermissions(serviceGroupId) ||
        (serviceTypeId !== null &&
          serviceId !== null &&
          authenticatedUser?.HasAdminServiceOnlyPermissions(
            serviceTypeId,
            serviceId,
          ));
      const quizzEnabled =
        IsModuleEnabled(appSettings, KnownModules.Quizzes) && isAdmin;
      const AssetCheck: Pick<
        IAssetDto,
        'ServiceGroupId' | 'ServiceTypeId' | 'ServiceId' | 'Availability'
      > = {
        ServiceGroupId: serviceGroupId,
        ServiceTypeId: serviceTypeId,
        ServiceId: serviceId,
        Availability:
          availabilityId === undefined
            ? undefined
            : { Id: availabilityId, Name: null },
      };
      const editLink = getAssetEditLink(
        availabilityId === undefined
          ? undefined
          : getAvailabilityUnion(availabilityId),
        assetId,
        serviceId ?? undefined,
        serviceGroupId ?? undefined,
        divisionId ?? undefined,
      );

      if (buttonsData !== undefined && availabilityId !== undefined) {
        const bookable = GetIsBookable(availabilityId);
        const reservationsEnabled = GetReservationEnabled(
          availabilityId,
          active ?? false,
          buttonsData.NotAllowReservations ?? false,
        );
        const usageEnabled = GetUsageEnabled(
          availabilityId,
          active ?? false,
          buttonsData.AutoMode,
        );
        const alertsEnabled = GetAlertsEnabled(availabilityId, active ?? false);
        const addWorkOrder = () => {
          if (alertsEnabled && isAuthenticated) {
            buttonActions.push(() => (
              <CreateAnAssetRelatedEvent
                assetId={assetId}
                mismatchCore={mismatchCore ?? false}
                useSidePanel={useSidePanel}
                openPanel={openPanel}
                disabled={active === false}
              />
            ));
          }
        };
        const addReservation = () => {
          if (!buttonsData.AssemblyId && reservationsEnabled) {
            buttonActions.push(() => (
              <ReservationCreateButton
                size="small"
                color="primary"
                startIcon={<Icon icon="plus" />}
                variant={bookable ? 'main' : 'white'}
                title={t(translations.PlaceAReservation)}
                disabled={mismatchCore || active === false}
                instruments={serviceId === null ? [] : [serviceId]}
                useSidePanel={useSidePanel}
                openPanelOrCover={openPanel}
              >
                {t(translations.Reservation)}
              </ReservationCreateButton>
            ));
          }
        };
        const addServiceRequest = () => {
          if (
            !!serviceTypeId &&
            serviceTypeId !== null &&
            serviceTypeId === ServiceType.Sample &&
            canBeCreatedFromLinkOnly === false &&
            isAuthenticated &&
            active
          ) {
            buttonActions.push(() => (
              <Button
                key="workflow-step"
                href={toRootedURL(
                  `/servicerequests?action=create&ostid=${serviceId}`,
                )}
                title={t(translations.CreateNewSampleRequest)}
                startIcon={<Icon icon={'bolt'} />}
                disabled={mismatchCore}
              >
                {t(translations.CreateNewSampleRequest)}
              </Button>
            ));
          }
        };
        const addReservationFromCalendar = () => {
          if (!buttonsData.AssemblyId && reservationsEnabled) {
            buttonActions.push(() => (
              <CalendarButton
                size="small"
                color="primary"
                variant={bookable ? 'main' : 'white'}
                startIcon={<Icon icon="plus" />}
                title={t(translations.PlaceAReservation)}
                equipments={serviceId === null ? [] : [serviceId]}
                disabled={mismatchCore || active === false}
                openToBook={true}
                target={isAuthenticated ? '_self' : '_blank'}
              >
                {t(translations.Reservation)}
              </CalendarButton>
            ));
          }
        };
        const addUsage = () => {
          if (usageEnabled) {
            if (
              buttonsData.CanStartUsage &&
              !buttonsData.AssemblyId &&
              active
            ) {
              buttonActions.push(() => (
                <Button
                  size="small"
                  variant="white"
                  startIcon={<Icon icon="play" />}
                  onClick={() => {
                    if (serviceId !== null) {
                      startUsage(
                        {
                          sid: serviceId,
                          useSidePanel: useSidePanel,
                        },
                        openPanel,
                      );
                    }
                  }}
                  title={t(translations.StartUsage)}
                  disabled={mismatchCore || !active}
                >
                  {t(translations.Use)}
                </Button>
              ));
            }
            if (!!buttonsData.CanEndUsage && !!buttonsData.UsageId && active) {
              buttonActions.push(() => (
                <Button
                  size="small"
                  variant="white"
                  startIcon={<Icon icon="pause" />}
                  title={t(translations.StopUsage)}
                  onClick={() => setOpenEndUsage(true)}
                  disabled={mismatchCore}
                >
                  {t(translations.Stop)}
                </Button>
              ));
            }
          }
        };
        const addTakeDown = () => {
          if (
            bookable &&
            buttonsData.AlertInfo !== undefined &&
            !!buttonsData.PlanedEventsEnabled &&
            !!buttonsData.AlertInfo.show &&
            isAuthenticated
          ) {
            if (!buttonsData.AlertInfo.hasDownActive) {
              if (
                !!buttonsData.AlertInfo.isAdmin &&
                !!buttonsData.AlertInfo.assetId &&
                active
              ) {
                buttonActions.push(() => (
                  <DropDown
                    size="small"
                    color="primary"
                    variant="white"
                    disabled={mismatchCore || !active}
                    startIcon={<Icon icon="power-off" color="danger" />}
                    title={t(translations.HasNoAlertsAdmin_info)}
                    menuChildren={() => {
                      let res = [
                        <MenuItem key={'title for this menu'} disabled>
                          {t(translations.PleaseSelectAnEventType)}
                        </MenuItem>,
                      ];
                      if (buttonsData.Alerts !== undefined) {
                        res.push(
                          ...buttonsData.Alerts.map(a => (
                            <MenuItem
                              onClick={() =>
                                openPanel({
                                  renderPageType:
                                    RenderPageType.WorkOrderDetails,
                                  renderPageProps: {
                                    queryParams: {
                                      aType: a.Id.toString(),
                                      assetId:
                                        !!buttonsData.AlertInfo &&
                                        !!buttonsData.AlertInfo.assetId
                                          ? buttonsData.AlertInfo.assetId.toString()
                                          : undefined,
                                      down: true.toString(),

                                      offH: true.toString(),
                                    },
                                    useSidePanel: true,
                                  } as WorkOrderDetailsProps,
                                  expanded: false,
                                  useSidePanel: useSidePanel,
                                  isCover: useSidePanel,
                                })
                              }
                              key={a.Id + a.Name + 'key'}
                            >
                              {a.Name}
                            </MenuItem>
                          )),
                        );
                      }
                      return res;
                    }}
                  >
                    {t(translations.TakeDown)}
                  </DropDown>
                ));
              }
            } else if (
              !!buttonsData.AlertInfo.noAlerts ||
              !!buttonsData.AlertInfo.hasActive
            ) {
              if (
                !!buttonsData.AlertInfo.isAdmin &&
                !!buttonsData.AlertInfo.alertId &&
                active
              ) {
                buttonActions.push(() => (
                  <Button
                    size="small"
                    variant="white"
                    disabled={mismatchCore || !active}
                    title={t(translations.HasActiveDownAlertsAdmin_info)}
                    onClick={() =>
                      openPanel({
                        renderPageType: RenderPageType.WorkOrderDetails,
                        renderPageProps: {
                          queryParams: {
                            id: (
                              buttonsData.AlertInfo?.alertId ?? -1
                            ).toString(),
                            Up: true.toString(),
                          },
                          useSidePanel: true,
                        } as WorkOrderDetailsProps,
                        expanded: false,
                        useSidePanel: useSidePanel,
                        isCover: useSidePanel,
                      } as SidePanelOpenState)
                    }
                  >
                    <Icon icon="plug" color="success" />
                  </Button>
                ));
              }
            }
          }
        };

        const addConsumable = () => {
          if (
            !!serviceTypeId &&
            serviceTypeId !== null &&
            serviceTypeId === ServiceType.Offline &&
            isAuthenticated &&
            active
          ) {
            buttonActions.push(() => (
              <Button
                size="small"
                variant="main"
                disabled={mismatchCore || !active}
                startIcon={<Icon icon="plus" />}
                title={t(translations.ReportAConsumable)}
                onClick={() => {
                  let params = {
                    useSidePanel: true,
                    queryParams: {
                      stid: serviceId?.toString(),
                      user: authenticatedUser?.Id,
                    },
                  } as ReportConsumablesProps;
                  openPanel({
                    renderPageType: RenderPageType.ReportConsumables,
                    renderPageProps: params,
                    expanded: false,
                    useSidePanel: useSidePanel,
                    isCover: useSidePanel,
                  } as SidePanelOpenState);
                }}
                //href={toRootedURL(`/reportConsumables?stid=${data.ServiceId}`)}
              >
                {t(translations.LogConsumables)}
              </Button>
            ));
          }
        };

        const addCoreBoard = () => {
          if (
            !isDashboardCore &&
            isAuthenticated &&
            active &&
            serviceGroupId &&
            serviceGroupName
          ) {
            buttonActions.push(() => (
              <Button
                size="small"
                variant="white"
                disabled={mismatchCore}
                title={t(translations.SwitchToCoreOpenItsBoard)}
                onClick={() => {
                  !!saveCore &&
                    saveCore([
                      {
                        Id: serviceGroupId,
                        Name: serviceGroupName,
                      },
                    ]);
                  // dispatch(
                  //   actions.saveGlobalServiceGroupFilter(),
                  // );
                  // dispatch(layoutActions.resetSidePanel());
                  // history.push('/dashboards/core/' + data.ServiceGroup?.Id ?? '');
                }}
              >
                {t(translations.CoreBoard)}
              </Button>
            ));
          }
        };
        const addRequestAccess = () => {
          if (
            !isAdmin &&
            isAuthenticated &&
            serviceGroupId &&
            !!coreApprovalStatusId
          ) {
            const approvalStatus =
              coreApprovalStatusId === ServiceGroupRegistrationStatus.Approved;
            if (canRequestAccessToCore && !approvalStatus && active) {
              buttonActions.push(() => (
                <Button
                  size="small"
                  variant="main"
                  disabled={!active}
                  onClick={() => {
                    window.open(
                      toRootedURL(
                        `/ServiceGroupRegister.aspx?sgid=${serviceGroupId}`,
                      ),
                      '_blank',
                    );
                  }}
                >
                  {t(translations.RequestAccess)}
                </Button>
              ));
            } else {
              buttonActions.push(() => (
                <Badge>
                  {t(
                    translations[
                      ServiceGroupRegistrationStatus[coreApprovalStatusId]
                    ],
                  )}
                </Badge>
              ));
            }
          }
        };
        if (
          serviceTypeId === ServiceType.Sample &&
          canBeCreatedFromLinkOnly === false
        ) {
          addServiceRequest();
        }
        if (bookable) {
          if (addFromCalendar) {
            addReservationFromCalendar();
          } else {
            addReservation();
          }

          addUsage();
          addWorkOrder();
          addTakeDown();
        } else {
          addWorkOrder();
          addTakeDown();
          addUsage();
          addReservation();
          addConsumable();
        }
        addCoreBoard();
        addRequestAccess();
        if (isAuthenticated) {
          if (isAdmin && editLink !== undefined) {
            buttonActions.push(() => (
              <ButtonOrLink
                key="edit-service"
                href={editLink}
                title={t(translations.EditSettings)}
                text={t(translations.Settings)}
                icon="pencil-alt"
                target={'_blank'}
              />
            ));
          }
          if (
            active &&
            !hideTrainingSessionsSettings === true &&
            serviceId !== null &&
            reservationsEnabled &&
            (authenticatedUser?.isAdmin() ||
              authenticatedUser?.isGroupAdmin() ||
              authenticatedUser?.isLabTech())
          ) {
            buttonActions.push(() => (
              <ButtonOrLink
                key="new-session"
                href={toRootedURL(
                  '/TrainingSession/AddNew.aspx?eqid=' + serviceId,
                )}
                title={t(translations.menu_AddNewTrainingSessions)}
                disabled={mismatchCore || !active}
                icon="plus"
                target="_blank"
                text={t(translations.NewTrainingSession)}
              />
            ));
          }
          if (
            active &&
            reservationsEnabled &&
            serviceTypeId !== null &&
            serviceTypeId === ServiceType.Online &&
            bookable
          ) {
            buttonActions.push(() => (
              <CalendarButton
                key="calendar-open"
                equipments={serviceId !== null ? [serviceId] : undefined}
                openToBook={true}
                disabled={mismatchCore}
                title={
                  buttonsData.Allowed_ByTraining === false
                    ? t(translations.UntrainedToolTip)
                    : 'Calendar'
                }
                startIcon={<Icon icon="calendar-alt" />}
                variant="white"
                component={'a'}
                size={'small'}
              >
                {t(translations.Calendar)}
              </CalendarButton>
            ));
          }
          if (
            active &&
            allowedToView([AssetCheck], RenderTypes.ExportQRCodes) &&
            !isMobile
          ) {
            buttonActions.push(() => (
              <ExportQRCodesButton
                url="/api/QrCodes/assets"
                rows={[{ Id: assetId }]}
              />
            ));
          }
          if (
            active &&
            allowedToView([AssetCheck], RenderTypes.PrintAssetLabels) &&
            !isMobile
          ) {
            buttonActions.push(() => (
              <PrintSelectedAssetLabelsButton
                key="printlabels"
                rows={[{ Id: assetId }]}
                title={t(translations.PrintLabels)}
              />
            ));
          }
          if (active && reservationsEnabled && bookable) {
            buttonActions.push(() => (
              <CalendarButton
                key="timeline-open"
                equipments={[serviceId ?? undefined]}
                openToBook={true}
                viewType="timeline"
                disabled={mismatchCore}
                title={t(translations.Timeline)}
                variant="white"
                component={'a'}
                startIcon={<Icon icon="stream" />}
                size={'small'}
              >
                {t(translations.Timeline)}
              </CalendarButton>
            ));
            if (active && bookable) {
              buttonActions.push(() => (
                <ButtonOrLink
                  key="res-history"
                  href={toRootedURL('/reservations?eid=' + serviceId)}
                  title={t(translations.menu_ReservationsHistory)}
                  icon="calendar-alt"
                  text={t(translations.menu_ReservationsHistory)}
                  disabled={mismatchCore}
                />
              ));
            }
          }
          if (active && usageEnabled) {
            buttonActions.push(() => (
              <ButtonOrLink
                key="usage-history"
                href={toRootedURL('/usagehistory.aspx?sid=' + serviceId)}
                title={t(translations.UsageHistory)}
                icon="ruler-triangle"
                text={t(translations.UsageHistory)}
                disabled={mismatchCore}
              />
            ));
          }
          if (
            active &&
            serviceTypeId !== null &&
            serviceTypeId === ServiceType.Offline &&
            allowToUser
          ) {
            buttonActions.push(() => (
              <ButtonOrLink
                key="offline-services"
                href={toRootedURL('/otherservices?sid=' + serviceId)}
                title={t(translations.OfflineService)}
                icon="th-list"
                text={t(translations.OfflineService)}
                disabled={mismatchCore}
              />
            ));
          }
          if (
            active &&
            serviceTypeId !== null &&
            serviceTypeId === ServiceType.Online &&
            quizzEnabled
          ) {
            buttonActions.push(() => (
              <ButtonOrLink
                key="quizzes"
                href={toRootedURL('/quizzes?eid=' + serviceId)}
                title={t(translations.TrainingQuiz)}
                icon="plus"
                text={t(translations.TrainingQuiz)}
                disabled={mismatchCore}
              />
            ));
          }
          if (alertsEnabled) {
            buttonActions.push(() => (
              <ButtonOrLink
                key="workorders"
                href={toRootedURL(`/workorders?aid=${assetId}`)}
                title={t(translations.Alerts)}
                icon="comment-alt-dots"
                text={t(translations.AlertsIcon)}
                disabled={mismatchCore}
              />
            ));
          }
          if (active && buttonsData.PlanedEventsEnabled) {
            buttonActions.push(() => (
              <ButtonOrLink
                key="incident-track"
                href={toRootedURL(
                  '/Alerts/IncidentAssetsTracking.aspx?assetIds=' + assetId,
                )}
                title={t(translations.ScheduledEvents)}
                icon="clipboard-list-check"
                text={t(translations.ScheduledEvents)}
                target={'_blank'}
                disabled={mismatchCore}
              />
            ));
          }
          if (
            active &&
            serviceTypeId !== null &&
            serviceTypeId === ServiceType.Offline &&
            allowToUser
          ) {
            buttonActions.push(() => (
              <ButtonOrLink
                key="request-otherservice"
                href={toRootedURL('/OfflineServices.aspx?sid=' + serviceId)}
                title={t(translations.RequestAnOtherService)}
                icon="history"
                text={t(translations.RequestAnOtherService)}
                disabled={mismatchCore}
              />
            ));
          }
          if (
            active &&
            serviceTypeId !== null &&
            serviceTypeId === ServiceType.Sample &&
            canBeCreatedFromLinkOnly === false
          ) {
            buttonActions.push(() => (
              <ButtonOrLink
                key="service-request"
                href={`/ServiceRequests?OfflineServiceTypeId=${serviceId}&Active=true`}
                //   onClick={() => {
                //     history.push(
                //       '/ServiceRequests?OfflineServiceTypeId=' +
                //         data.ServiceId +
                //         '&Active=true',
                //     );
                //   }}
                title={t(translations.RequestsList)}
                icon={'history'}
                text={t(translations.RequestsList)}
                disabled={mismatchCore || !active}
              />
            ));
          }
          if (
            active &&
            bookable &&
            serviceTypeId !== null &&
            serviceTypeId === ServiceType.Online &&
            reservationsEnabled &&
            (IsModuleEnabled(
              appSettings,
              KnownModules.ResCancelNotifications,
            ) ||
              IsModuleEnabled(
                appSettings,
                KnownModules.ReservationNotification,
              )) &&
            GetNotificationAvailable(availabilityId)
          ) {
            buttonActions.push(() => (
              <ButtonOrLink
                key="notifications"
                href={toRootedURL(
                  `/NotificationSettings?userName=${authenticatedUser?.Id}`,
                )}
                title={t(
                  buttonsData.NotifyExists
                    ? translations.NotifyToolTip
                    : translations.NoNotifyToolTip,
                )}
                icon="bell"
                target={'_blank'}
                text={t(translations.Notifications)}
              />
            ));
          }
          if (
            active &&
            buttonsData.ShowFeed &&
            globalSettings.GetBoolean('CalendarPublicIntegrationFeed')
          ) {
            buttonActions.push(() => (
              <ButtonOrLink
                key="calendar-feed"
                title={t(translations.CalendarPublicIntegrationFeed_Icon)}
                href={toRootedURL(
                  '/api/ICalController/import?EquipmentName=' + Name,
                )}
                icon="calendar-plus"
                text={t(translations.OutlookGoogle)}
              />
            ));
          }
        }
      }
      return buttonActions;
    },
    [
      allowedToView,
      appSettings,
      globalSettings,
      hideTrainingSessionsSettings,
      isMobile,
      openPanel,
      startUsage,
      t,
      useSidePanel,
    ],
  );
  return getActions;
};
