import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.regions || initialState;

export const selectRegions = createSelector([selectSlice], state => state);

export const selectRegionsProcessing = createSelector(
  [selectRegions],
  state => state.processing,
);

export const selectDataProcessing = createSelector(
  [selectRegions],
  state => state.detailsState.processing,
);

export const selectRegionsData = createSelector(
  [selectRegions],
  state => state.detailsState.data,
);
export const selectRegionsHasErrors = createSelector(
  [selectRegions],
  state => state.detailsState.hasErrors,
);
export const selectDetailsCompleted = createSelector(
  [selectRegions],
  state => state.detailsState.completed,
);
