import React from 'react';
import { BasicTable, BasicTableProps } from 'app/components/BasicTable';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { IUserTrainingDto } from 'api/odata/generated/entities/IUserTrainingDto';

type RowType = IUserTrainingDto;

export function TrainingsTable<
  TProps extends Omit<
    BasicTableProps<RowType>,
    'api' | 'additionalColumns' | 'searchColumns' | 'screenName'
  >,
>({
  initialState,
  rowActions,
  serviceGroups,
  savedListSettings,
  ...props
}: TProps) {
  const { t } = useTranslation();
  const newProps = React.useMemo(() => {
    const p = { ...props };
    Reflect.deleteProperty(p, 'api');
    return p;
  }, [props]);
  const url = '/api/odata/v4/usertrainings';
  return (
    <>
      <BasicTable
        api={url}
        initialState={initialState}
        additionalColumns={[
          'AttachmentExist',
          'Id',
          'EquipmentId',
          'AssetCategoryId',
          'AssetCategoryGroupId',
          'CampusId',
          'UserGroupId',
          'InstituteId',
          'AssetId',
          'UserDisplayName',
          'UserId',
          'ServiceGroupId',
          'ExpiredDate',
        ]}
        rowActions={rowActions}
        useRowSelect={true}
        searchColumns={[
          'UserId',
          'UserDisplayName',
          'ServiceGroupName',
          'EquipmentName',
          'TrainerName',
          'TrainingLevelName',
          'Remarks',
          'TrainingLevelName',
          'AssetCatGroupName',
          'AssetCatName',
          'UserGroupName',
          'InstituteName',
          'CampusName',
        ]}
        screenName={t(translations.menu_UserTraining)}
        serviceGroups={serviceGroups}
        savedListSettings={{
          enableSavedLists: true,
          savedListRelatedPickerName: 'EquipmentId',
          savedListSerializedKey: 'eid',
        }}
        {...(newProps as TProps)}
      />
    </>
  );
}
