import { IUsers } from 'app/pages/UsersPage/IUsers';
import React from 'react';
import styled from 'styled-components';
import { AttributeValue } from '../../typeUtils';

const StyledByteImg = styled('img')`
  &.img-root {
    max-height: 300px;
    width: 100%;
    object-fit: contain;
    margin-inline: -12px;
  }
`;
function UserImageRender(props: AttributeValue<IUsers>) {
  const { value, shortView } = props;
  const defaultImgSrc = process.env.PUBLIC_URL + `/images/400x300.png`;
  const defaultImgMobileSrc = process.env.PUBLIC_URL + `/images/200x150.png`;
  const defaultImg = React.useMemo(() => {
    return shortView ? defaultImgMobileSrc : defaultImgSrc;
  }, [defaultImgMobileSrc, defaultImgSrc, shortView]);
  return (
    <>
      {!!value && !!value.Bytes ? (
        <StyledByteImg
          className="img-root"
          src={'data:image/png;base64, ' + value.Bytes}
          alt=""
        />
      ) : (
        <StyledByteImg className="img-root" src={defaultImg} alt="" />
      )}
    </>
  );
}
export default UserImageRender;
