/**
 *
 * ServiceGroupsPicker
 *
 */
import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import { Entity, Identifiable } from 'types/common';
import {
  AutocompleteMultiPickerEx,
  AutocompleteMultiPickerExProps,
} from 'app/components/BasicPickers/AutocompleteMultiPickerEx';
import { InputBaseComponentProps } from '@material-ui/core';
import { IServiceGroupDto } from 'api/odata/generated/entities/IServiceGroupDto';
import { GroupUrl } from '../../ServiceGroupPicker';
import { splitFilterValue } from 'app/components/BasicTable/types/FilterParam';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from 'app/slice/selectors';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';

export interface ServiceGroupsPickerProps
  extends Omit<AutocompleteMultiPickerExProps<IServiceGroupDto>, 'loadData'> {
  inputProps?: InputBaseComponentProps;
  selectArray?: Array<keyof IServiceGroupDto>;
  urlType?: GroupUrl;
  separator?: string;
  useSearchOrPredicate?: boolean;
}
const url = '/api/odata/v4/servicegroups';
const adminUrl = '/api/odata/v4/servicegroups/GetAdminServiceGroups';
const publicUrl = '/api/odata/v4/servicegroups/GetPublicServiceGroups';
const regPublicUrl = '/api/odata/v4/servicegroups/GetRegistrationServiceGroups';

export const initServiceGroupsMultiData = (
  initval: string | undefined,
  sourceUrl?: GroupUrl,
) => {
  if (initval === undefined) {
    return new Promise<IServiceGroupDto[]>((resolve, reject) => {
      resolve([] as IServiceGroupDto[]);
    });
  } else {
    let ids = splitFilterValue(initval).map(item => {
      return { Id: parseInt(item) };
    }) as Identifiable<number>[];
    let filters: string | undefined = undefined;
    if (ids.length > 0) {
      const predicates: (string | Condition<IServiceGroupDto>)[] = [];
      predicates.push(new Condition('Id', ODataOperators.Includes, ids));
      filters = predicates.map(f => f.toString()).join(' and ');
    }
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
    } = {
      $orderby: 'Name asc',
      $filter: filters,
      $select: 'Id,Name,DivisionId,DivisionName',
    };
    let initUrl =
      sourceUrl === undefined
        ? url
        : sourceUrl === 'public'
        ? publicUrl
        : sourceUrl === 'regpublic'
        ? regPublicUrl
        : sourceUrl === 'admin'
        ? adminUrl
        : url;
    return httpClient
      .get(initUrl, params)
      .then(response => response.value as IServiceGroupDto[]);
  }
};

export const initFiltersServiceGroupsData = (filter: string | undefined) => {
  const params: {
    $orderby: string;
    $filter?: string;
  } = {
    $orderby: 'Name asc',
    $filter: filter,
  };
  return httpClient
    .get(url, params)
    .then(response => response.value as Entity<number>[]);
};

const loadData = (
  predicates: (string | Condition<IServiceGroupDto>)[] | undefined,
  isAuthenticated?: boolean,
  urlType?: GroupUrl,
  useSearchOrPredicate?: boolean,
) => {
  const sourceUrl =
    urlType === 'public' || !isAuthenticated
      ? publicUrl
      : urlType === 'regpublic'
      ? regPublicUrl
      : urlType === 'admin'
      ? adminUrl
      : url;
  let filters: (string | Condition<IServiceGroupDto>)[] = [];
  if (sourceUrl === url) {
    if (!!predicates && predicates.length > 0) {
      filters = [...predicates, ...['Active eq true']];
    } else {
      filters = ['Active eq true'];
    }
  }

  return getAutoCompleteLoadDataFn<IServiceGroupDto>({
    url: sourceUrl,
    predicates: filters,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: ['Id', 'Name', 'DivisionId', 'DivisionName'],
    useSearchOrPredicate: useSearchOrPredicate,
  });
};
export const ServiceGroupByDivisionsFilter = (value: FilterValueType) => {
  if (value !== undefined && value !== null && Array.isArray(value)) {
    return [
      `${new Condition<IServiceGroupDto>(
        'DivisionId',
        ODataOperators.Includes,
        (value as Identifiable<number>[]).map(f => f.Id),
      ).toString()}`,
    ];
  }
  return [];
};
export function ServiceGroupsPicker(props: ServiceGroupsPickerProps) {
  const isAuth = useSelector(selectIsAuthenticated);
  const { predicates, urlType, useSearchOrPredicate, ...other } = props;
  const innerLoadData = loadData(
    predicates,
    isAuth,
    urlType,
    useSearchOrPredicate,
  );
  return (
    <AutocompleteMultiPickerEx
      mini={props.mini ? true : undefined}
      size={props.size}
      ariaLabel="Service Group search"
      id={props.id || 'servicegroupsid'}
      loadData={innerLoadData}
      {...other}
    />
  );
}
