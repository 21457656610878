import { useTranslation } from 'react-i18next';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { ICoresDirectoryRow } from 'types/ICoresDirectoryRow';
import * as React from 'react';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { translations } from 'locales/translations';
import { UpdateGroupPermissionsForm } from './UpdateGroupPermissions';
import { Entity } from 'types/common';
import { httpClient } from 'api/HttpClient';
import { useDispatch } from 'react-redux';
import { useAppSettingsSlice } from 'app/slice';
import { IResponseType } from 'types/ResponseType';
import { ActionButton } from 'app/components/BasicButtons/ActionButton';

export interface UpdateUserGroupPermissionsProps {
  rows: ICoresDirectoryRow[];
  user?: AuthenticatedUser;
}
export interface GroupPermissionsModel {
  AdGroups: Entity<string>[];
  PermissionLevel?: Entity<number>;
  TimeSlotType?: Entity<number>;
}
export interface GroupPermissionsUpdate {
  ServiceGroups: string;
  AdGroups: string;
  PermissionLevelId: number;
  TimeSlotTypeId: number;
}
export function UpdateUserGroupPermissions(
  props: UpdateUserGroupPermissionsProps,
) {
  const { rows } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const [updtPermissionOpen, setUpdtPermissionOpen] = React.useState(false);
  const [submitted, setSubmitted] = React.useState<boolean | undefined>(
    undefined,
  );
  const submitFormRef = React.useRef<any>();
  const bindSubmitForm = React.useCallback(value => {
    submitFormRef.current = value;
  }, []);
  const updatePermission = React.useCallback(
    (model: GroupPermissionsModel, groups: ICoresDirectoryRow[]) => {
      setSubmitted(true);
      const params = {
        ServiceGroups: groups.map(f => f.Id).join(','),
        AdGroups: model.AdGroups.map(f => f.Id).join(','),
        PermissionLevelId: model.PermissionLevel?.Id ?? -1,
        TimeSlotTypeId: model.TimeSlotType?.Id ?? -1,
      } as GroupPermissionsUpdate;
      const url =
        '/api/odata/v4/RestrictedUserPermissions/UpdateGroupPermissions';
      httpClient
        .post<IResponseType>(url, { params: params })
        .then(v => {
          setSubmitted(undefined);
          if (v.ErrorMessages.length > 0) {
            dispatch(
              actions.addNotifications(
                v.ErrorMessages.map(e => ({ message: e, variant: 'error' })),
              ),
            );
          }
          if (v.WarningMessages.length > 0) {
            dispatch(
              actions.addNotifications(
                v.WarningMessages.map(e => ({
                  message: e,
                  variant: 'warning',
                })),
              ),
            );
          }
          if (v.SuccessMessages.length > 0) {
            dispatch(
              actions.addNotifications(
                v.SuccessMessages.map(e => ({
                  message: e,
                  variant: 'success',
                })),
              ),
            );
            setUpdtPermissionOpen(false);
          }
        })
        .catch(e => {
          console.error(e);
          setSubmitted(undefined);
        })
        .finally(() => {
          setSubmitted(undefined);
        });
    },
    [actions, dispatch],
  );
  return (
    <React.Fragment>
      {updtPermissionOpen && (
        <DialogConfirm
          maxWidth="sm"
          isOpen={updtPermissionOpen}
          onConfirm={() => submitFormRef.current()}
          confirmButtonLabel={t(translations.CreateUpdateGroupPermissions)}
          processing={submitted}
          onCancel={() => {
            setUpdtPermissionOpen(false);
          }}
          onClose={() => {
            setUpdtPermissionOpen(false);
          }}
          body={
            <div>
              <UpdateGroupPermissionsForm
                groups={rows}
                handleSubmitForm={bindSubmitForm}
                onSubmit={group => {
                  updatePermission(group, rows);
                }}
              />
            </div>
          }
        />
      )}
      <ActionButton
        onClick={() => setUpdtPermissionOpen(true)}
        title={t(translations.SetPermissionsOnSelectedServiceGroups)}
        text={t(translations.SetPermissions) as string}
        processing={submitted}
      />
    </React.Fragment>
  );
}
