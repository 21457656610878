import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import * as React from 'react';

export interface UserMenuButtonProps extends ButtonProps {
  children: React.ReactNode;
  open: boolean;
  isMobile?: boolean;
}

export default function UserMenuButton(props: UserMenuButtonProps) {
  const { children, isMobile, open, ...other } = props;
  return isMobile ? (
    <IconButton disableRipple variant="ghost" size="small" {...other}>
      {children}
    </IconButton>
  ) : (
    <Button
      disableRipple
      variant="ghost"
      color="default"
      size="small"
      endIcon={<Icon icon={open ? 'chevron-up' : 'chevron-down'} />}
      startIcon={<Icon icon="user-circle" color="black" colorExtend="main" />}
      {...other}
    >
      {children}
    </Button>
  );
}
