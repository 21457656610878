import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import * as React from 'react';
import { AddReservation } from '../../Actions/PageTopActions/AddReservation';
export interface GetActionsProps {
  shortView?: boolean;
}

export function GetActions(props: GetActionsProps): PageActionRenderer[] {
  const PageActions = [
    (dataCount, refresh) => (
      <AddReservation variant="main" isIconButton={props.shortView} />
    ),
    // (dataCount, refresh) => <SetStartTimeButton />,
    // (dataCount, refresh) => <SetEndTimeButton />,
  ];
  return PageActions;
}
