import { PayloadAction } from '@reduxjs/toolkit';
import { IRow } from 'app/components/BasicTable/IRow';
import { BasicTableRefreshEventHandler } from 'app/components/BasicTable/ControlledTable/ControlledTableProps';
import { Identifiable } from 'types/common';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { RunTypesUnion } from '../../components/RunTypePicker';
import { RUN_TYPES } from '../../components/RunTypePicker';
import { ISampleCreateBase } from '../../SamplePlatesPage/slice/types';
import { samplesListSaga } from './saga';
import {
  IRunDetails,
  ISample,
  ISampleListTableState,
  ISampleRun,
  ISampleRunUpdate,
  ISampleRunUpdateResponse,
  ISamplesListState,
  ISampleTarget,
} from './types';

export const initialState: ISamplesListState = {
  SamplesList: {
    processing: false,
  },
  SamplesListTableState: {
    pageSize: 10,
    pageIndex: 0,
    sortBy: [{ id: 'Id', desc: true }],
    customFilters: [],
    globalFilter: '',
  },
  EditMode: false,
  SamplesSelected: [],
  ShowRunDialog: false,
  ImportStarted: false,
  ImportFinished: false,
  Completion: false,
};

const slice = createSlice({
  name: 'samplesList',
  initialState,
  reducers: {
    loadSamplesList(state, action: PayloadAction<ISampleListTableState>) {
      state.SamplesList.processing = true;
      state.SamplesListTableState = {
        pageSize: action.payload.pageSize,
        pageIndex: action.payload.pageIndex,
        sortBy: action.payload.sortBy,
        globalFilter: action.payload.globalFilter,
        searchColumns: action.payload.searchColumns,
        customFilters: action.payload.customFilters?.map(filter => ({
          fieldName: filter.fieldName,
          operator: filter.operator,
          value: filter.value,
        })),
        serviceGroups:
          action.payload.serviceGroups?.length !== 0
            ? action.payload.serviceGroups
            : undefined,
      };
    },
    export(state, action: PayloadAction<ISampleListTableState>) {},
    loadSamplesList_Success(state, action: PayloadAction<any>) {
      state.SamplesList.processing = false;
      state.SamplesList.error = undefined;
      state.SamplesList.data = action.payload.value as ISample[];
      state.SamplesList.dataLength = action.payload['@odata.count'];
    },
    loadSamplesList_Error(state, action: PayloadAction<string>) {
      state.SamplesList.processing = false;
      state.SamplesList.error = action.payload;
    },
    setSelectedSamples(state, action: PayloadAction<any>) {
      state.SamplesSelected = action.payload;
    },
    loadAllFilteredSamples(state, action: PayloadAction<any>) {},
    loadAllFilteredSamples_Success(state, action: PayloadAction<any>) {},

    deleteSamples(
      state,
      action: PayloadAction<{
        selectedRows: ISample[];
        refresh: BasicTableRefreshEventHandler | undefined;
        toggleAllRowsSelected: (f: boolean) => void;
      }>,
    ) {},

    updateField(state, action: PayloadAction<Partial<ISample>>) {
      // update row in samples list
      const item = state.SamplesList?.data?.find(
        s => s.Id === action.payload.Id,
      );
      if (item !== undefined) {
        Object.assign(item, action.payload);
      }
    },
    updateField_Success(state, action: PayloadAction) {},
    updateField_Error(state, action: PayloadAction) {},

    loadRunDetqails<TRow extends IRow>(
      state,
      action: PayloadAction<{
        id: number;
        columns: Array<keyof TRow> | undefined;
      }>,
    ) {},
    loadRunDetqails_Success(state, action: PayloadAction<IRunDetails>) {
      state.RunDetails = action.payload;
    },

    runSelectedSamples(
      state,
      action: PayloadAction<{
        runType?: RunTypesUnion;
        samples: ISample[] | undefined;
      }>,
    ) {
      state.SelectedRunType = action.payload.runType;
    },
    runSelectedSamples_Success(
      state,
      action: PayloadAction<ISampleTarget[]>,
    ) {},
    updateRunSampleValue(state, action: PayloadAction<Partial<ISampleTarget>>) {
      const item = state.RunDetails?.Samples?.find(
        s => s.Id === action.payload.Id,
      );
      if (item !== undefined) {
        // set updated properties on original row
        Object.assign(item, action.payload);

        // in case of the Labeling run all of the Target* properties should be the same
        // for all of the samples with the same ServiceRequest and same TMT_Set
        // as these are combined into a singel sample upon run completionc
        const rt = Object.keys(RUN_TYPES).find(k => k === 'Labeling');
        if (state.RunDetails?.RunType === rt) {
          const props: (keyof ISampleTarget)[] = [
            'TargetConcentration',
            'TargetPlateId',
            'TargetPosition',
            'TargetVolume',
            'TargetTubeLabel',
            'TargetSampleType',
          ];
          props.forEach(prop => {
            if (action.payload[prop] !== undefined) {
              const payloadWithoutId = {};
              payloadWithoutId[prop] = item[prop];
              state.RunDetails?.Samples?.filter(
                s =>
                  s.TMT_Set === item.TMT_Set &&
                  s.ServiceRequestId === item.ServiceRequestId,
              ).forEach(s => Object.assign(s, payloadWithoutId));
            }
          });
        }
      }
    },

    setShowRunDialog(state, action: PayloadAction<boolean>) {
      state.ShowRunDialog = action.payload;
    },
    setRunType(state, action: PayloadAction<RunTypesUnion | undefined>) {
      state.SelectedRunType = action.payload;
      if (action.payload === undefined) {
        return;
      }
      const runType = RUN_TYPES[action.payload];
      switch (runType) {
        case RUN_TYPES.StandardRun:
          break;
        case RUN_TYPES.MassSpec:
          break;
        case RUN_TYPES.Labeling:
          break;
      }
    },
    setShowImportDialog(state, action: PayloadAction<boolean>) {
      state.ImportStarted = action.payload;
      state.ImportFinished = false;
    },
    readImportFile(
      state,
      action: PayloadAction<{ file: File; plate: ISampleCreateBase | null }>,
    ) {},
    readImportFile_Success(
      state,
      action: PayloadAction<Array<Identifiable<number> & ISampleTarget>>,
    ) {
      action.payload.forEach(record => {
        const sample = state.RunDetails?.Samples?.find(
          sample => sample.Id === record.Id,
        );
        if (sample !== undefined) {
          sample.TargetPosition = record.TargetPosition;
          sample.TargetPlateId = record.TargetPlateId;

          sample.TargetTubeLabel = record.TargetTubeLabel;
          sample.TargetSampleType = record.TargetSampleType;

          sample.TargetConcentration = record.TargetConcentration;
          sample.TargetVolume = record.TargetVolume;
        }
      });
      state.ImportStarted = false;
      state.ImportFinished = true;
    },

    completeRun(state, aciton: PayloadAction<Partial<IRunDetails>>) {
      state.Completion = true;
    },
    completeRun_Success(state, aciton: PayloadAction) {
      state.Completion = false;
    },
    completeRun_Error(state, aciton: PayloadAction) {
      state.Completion = false;
    },
    updateRun(state, aciton: PayloadAction<ISampleRunUpdate>) {},
    updateRun_Success(
      state,
      aciton: PayloadAction<ISampleRunUpdateResponse | undefined>,
    ) {
      if (state.RunDetails !== undefined && aciton.payload !== undefined) {
        state.RunDetails.Gradient = aciton.payload.Gradient;
        state.RunDetails.Instrument = aciton.payload.Instrument;
        state.RunDetails.SecondaryInstrument =
          aciton.payload.SecondaryInstrument;
        state.RunDetails.ACQUISITION_TYPE = aciton.payload.ACQUISITION_TYPE;
      }
    },
    updateRun_Error(state, aciton: PayloadAction) {},

    exportRun(
      state,
      action: PayloadAction<{ Id: number | undefined; data: ISampleRun[] }>,
    ) {},
    toggleEditMode(state, action: PayloadAction<boolean>) {
      state.EditMode = action.payload;
    },
  },
});

export const { actions: samplesListActions } = slice;

export const useSamplesListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: samplesListSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSamplesListSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
