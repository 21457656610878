import * as React from 'react';
import { translations } from 'locales/translations';
import { CellProps, Column, Row } from 'react-table';
import { getCustomFormTableColumns } from './getCustomFormTableColumns';
import {
  IServiceRequestTableColumnModel,
  IServiceRequestTableRowModel,
} from '../../RequestSamplesPage/slice/types';
import { ServiceREquestRowStatusCell as ServiceRequestRowStatusCell } from '../ServiceRequestRowStatusPicker';
import { httpClient } from 'api/HttpClient';
import { useTranslation } from 'react-i18next';
import { ServiceRequestTableColumnsCollection } from '../../RequestSamplesPage/slice/utils/ServiceRequestTableColumn';
import { IServiceRequestTableFormComponent } from '.';
import { AddNewCustomTableRow } from './components/AddServiceRequestRowButton';
import { selectServiceRequestCustomFormColumns } from '../../RequestSamplesPage/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { appSettingsActions } from 'app/slice';
import { getServiceRequestRowName } from '../ServiceRequestRowEditableCell';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import styled from 'styled-components';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { ReservationLink } from 'app/pages/Actions/GlobalSelectedActions/OpenReservation';

export interface ColumnTypeExtension {
  name?: (row: Row<IServiceRequestTableRowModel>) => string;
  column?: IServiceRequestTableColumnModel;
  /**
   * Enables show / hide column through columns selection
   */
  enableHide?: boolean;
}
const RowGap = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export type ColumnType = Column<IServiceRequestTableRowModel> &
  ColumnTypeExtension;
export function getTableColumns(
  customFormColumns: ServiceRequestTableColumnsCollection,
  isEditable: boolean,
  statusColumnEditable: boolean,
  statusColumnVisible: boolean,
  hideRowIdColumn: boolean,
  t,
  serviceRequestId: number,
  reservationEnabled?: boolean,
  useSidePanel?: boolean,
  openSidePanel?: (state: SidePanelOpenState) => void,
  print?: boolean,
) {
  const dynamicColumns: Array<ColumnType> = getCustomFormTableColumns({
    columns: customFormColumns,
    isEditable: isEditable && !print,
  });
  var staticColumns: Array<ColumnType> = [];
  if (!hideRowIdColumn) {
    staticColumns.push({
      id: 'Id',
      Header: t(translations.RowId) as string,
      accessor: 'Id',
    });
  }
  if (reservationEnabled) {
    staticColumns.push({
      id: 'ReservationId',
      Header: t(translations.ReservationId) as string,
      accessor: 'ReservationId',
      Cell: ({ row, value, fieldProps }) => (
        <>
          {value !== null ? (
            <>
              {useSidePanel && openSidePanel ? (
                <ReservationLink
                  id={value}
                  openPanelWithCover={openSidePanel}
                  useSidePanel={useSidePanel}
                >
                  {value}
                </ReservationLink>
              ) : (
                <ReservationLink id={value}>{value}</ReservationLink>
              )}
            </>
          ) : null}
        </>
      ),
    });
  }
  if (statusColumnVisible) {
    if (print) {
      staticColumns.push({
        Header: t(translations.LineStatus) as string,
        accessor: 'Status',
        Cell: ({ row, value, fieldProps }) => <>{value.Name}</>,
      });
    } else {
      staticColumns.push({
        Header: t(translations.LineStatus) as string,
        accessor: 'Status',
        name: (row: Row<IServiceRequestTableRowModel>) =>
          getServiceRequestRowName(row.index, 'Status'),
        Cell: ({ row, value, fieldProps }) => (
          <>
            <ServiceRequestRowStatusCell
              value={fieldProps?.field?.value ?? null}
              isEditable={statusColumnEditable}
              serviceRequestId={serviceRequestId}
              serviceRequestRowId={row.original.Id}
              fieldProps={fieldProps}
            />
          </>
        ),
      });
    }
  }

  var result: Array<ColumnType> = [...staticColumns, ...dynamicColumns];
  if (!print) {
    result.push({
      Header: t(translations.Comments),
      id: 'comments',
      accessor: 'CommentsCount',
      disableSortBy: true,
      Cell: (props: CellProps<IServiceRequestTableRowModel>) => (
        <RowGap>
          {props.value}
          <IconButton
            size="small"
            variant="ghost"
            icon={props.row.isExpanded ? 'chevron-up' : 'chevron-down'}
            {...props.row.getToggleRowExpandedProps()}
          />
        </RowGap>
      ),
    });
  }

  if (isEditable && !print) {
    result.push(
      ...[
        {
          id: 'actions',
          Cell: (cellProps: CellProps<IServiceRequestTableRowModel>) => (
            <ActionsCell
              arrayHelpers={cellProps.arrayHelpers}
              rowIndex={cellProps.row.index}
              pageSize={cellProps.state.pageSize}
              original={cellProps.row.original}
              setPageSize={cellProps.setPageSize}
              updateMyData={cellProps.updateMyData}
            />
          ),
        },
      ],
    );
  }
  return result;
}

interface ActionsCellProps extends IServiceRequestTableFormComponent {
  rowIndex: number;
  original: IServiceRequestTableRowModel;
  pageSize: number;
  setPageSize;
}
const ActionsCell = React.memo(function ActionsCell(props: ActionsCellProps) {
  return (
    <RowGap key="foo">
      <DeleteButton
        arrayHelpers={props.arrayHelpers}
        rowId={props.original.Id}
        rowIndex={props.rowIndex}
      />
      <CloneButton {...props} />
    </RowGap>
  );
});
interface DeleteButtonProps
  extends Pick<ActionsCellProps, 'arrayHelpers' | 'rowIndex'> {
  rowId: number;
}
function DeleteButton(props: DeleteButtonProps) {
  const handleClick = React.useCallback(() => {
    httpClient.delete(`/api/ServiceRequestRows/${props.rowId}`).then(() => {
      props.arrayHelpers.remove(props.rowIndex);
    });
  }, [props.rowId, props.arrayHelpers, props.rowIndex]);

  return (
    <IconButton
      variant="ghost"
      key="delete"
      size="small"
      icon="trash"
      onClick={handleClick}
      //autoFocus={true}
    />
  );
}

function CloneButton(props: ActionsCellProps) {
  const { t } = useTranslation();
  const columns = useSelector(selectServiceRequestCustomFormColumns);
  const dispatch = useDispatch();
  const [processing, setProcessing] = React.useState(false);
  const handleCloneClick = React.useCallback(async () => {
    if (columns === null) {
      console.warn('no columns accessible for current user');
      return;
    }
    setProcessing(true);
    try {
      await AddNewCustomTableRow({
        serviceRequestId: props.original.ServiceRequestId,
        columns,
        model: props.original,
        arrayHelpers: props.arrayHelpers,
        index: props.rowIndex + 1,
      });
      dispatch(
        appSettingsActions.addNotification({
          variant: 'success',
          message: t(translations.ServiceRequestRow_InsertSuccess),
        }),
      );
      props.setPageSize(props.pageSize + 1);
      setProcessing(false);
    } catch (error) {
      dispatch(
        appSettingsActions.addNotification({
          variant: 'error',
          message: t(translations.ServiceRequestRow_InsertError),
        }),
      );
      setProcessing(false);
    }
  }, [columns, dispatch, props, t]);
  return (
    <IconButton
      key="clone"
      variant="ghost"
      size="small"
      onClick={handleCloneClick}
      //autoFocus={true}
    >
      <Icon icon={processing ? 'spinner' : 'plus'} spin={processing} />
    </IconButton>
  );
}
