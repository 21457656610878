import React from 'react';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { Theme, useTheme } from '@material-ui/core';
export interface IMobileDetector {
  checkIsMobile: () => boolean;
  orientation: 'portrait' | 'landscape';
  isLandscape: boolean;
  isPortrait: boolean;
  isMobile: boolean;
  currentPoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  pointWidth: number;
}
export const useIsMobile = (): IMobileDetector => {
  const theme: Theme = useTheme();
  const { orientation, isLandscape, isPortrait } = useMobileOrientation();
  //const [mobile, setMobile] = React.useState<boolean>(false);

  const checkIsMobile = React.useCallback((): boolean => {
    return (
      (isMobile && window.innerWidth < theme.breakpoints.values.md) ||
      window.innerWidth < theme.breakpoints.values.md
    );
  }, [theme.breakpoints.values.md]);
  const point = React.useMemo(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      return 'xs';
    } else if (window.innerWidth < theme.breakpoints.values.md) {
      return 'sm';
    } else if (window.innerWidth < theme.breakpoints.values.lg) {
      return 'md';
    } else if (window.innerWidth < theme.breakpoints.values.xl) {
      return 'lg';
    } else {
      return 'xl';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  // React.useEffect(() => {
  //   console.log('deviceDetect: ', data);
  //   setMobile(() => {
  //     return (
  //       (isMobile && window.innerWidth < theme.breakpoints.values.sm) ||
  //       window.innerWidth < theme.breakpoints.values.sm
  //     );
  //   });
  // }, [data, theme.breakpoints.values.sm]);
  return {
    checkIsMobile,
    orientation,
    isLandscape,
    isPortrait,
    isMobile,
    currentPoint: point,
    pointWidth: theme.breakpoints.values[point],
  };
};
