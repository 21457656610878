import { LinearProgress } from '@material-ui/core';
import { RestrictionsApi } from 'api/RestrictionsApi';
import { BooleanRender } from 'app/components/BasicTable/components/BooleanRender';
import { FormLabelText } from 'app/components/Forms/FormLabelText';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { usePromise } from 'app/hooks/usePromise';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';
import { useAppSettingsSlice } from 'app/slice';
import { MinTimeAheadPeriodTypes } from 'enums/MinTimeAheadPeriodTypes';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { dateUtils } from 'utils/date-utils';
import { enumToEntityArray } from 'utils/enumKeys';
import { IRestrictions } from '../../AssetPopUp/IAsset';
import {
  selectAppSettings,
  selectAuthenticatedUser,
} from 'app/slice/selectors';
import { UserOwnPermissions } from './components/UserOwnPermissions';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { Entity } from 'types/common';
import { IUserRestrictionsResult } from 'api/odata/generated/entities/IRestrictedUserPermissionResult';
import { Body } from 'app/components/Typography';
import { TrainingStatus } from 'enums/TrainingStatus';
import { UserPermissionsApi } from 'api/UserPermissionsApi';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import {
  convertAuthToIUserFilterDto,
  IUserFilterDto,
} from 'api/odata/generated/entities/IUserFilterDto';
import { initAssetsData } from 'app/components/pickers/MultiSelectPickers/AssetsPicker';
import { AssetPicker } from 'app/components/pickers/AutocompletePickers/AssetPicker';
import { BasicCard } from 'app/components/BasicCards';
import { getAssetImageThumbSrc } from 'app/components/AssetThumbImage/getAssetImageSrc';
import { DetectIsMobile } from 'utils/mobileDetect';
import { initUserData } from 'app/components/pickers/AutocompletePickers/UserPicker';
import { IsAssetImageExist } from 'types/AppSettings';
import clsx from 'clsx';
import { useOpenAssetPopUp } from 'app/pages/AssetPopUp/OpenAssetDetails';
import useSidePanelState, {
  SidePanelCloseState,
} from 'app/hooks/useSidePanelOpen';
import {
  ApprovalRequiredLabel,
  AssistedRoot,
  RestrictWrapper,
  StatusText,
  TrainingsWrapper,
} from './components/styled';
import { SimpleList } from 'app/components/BasicList/StyledList';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { Div100 } from 'app/components/AssetQuickSearch/styled';

export interface InstrumentRestrictionsProps
  extends CoverProps,
    SidePanelContentProps {
  serviceId?: number;
  serviceGroupId?: number;
  initUser?: string;
  onClose?: () => void;
  showTable?: boolean;
  assetPickerDisabled?: boolean;
  withNoPadding?: boolean;
  useShortView?: boolean;
  hideEquipmentFormTitle?: boolean;
  withNoWrapper?: boolean;
}
export interface Prerequisites {
  Id: number;
  Name: string;
  ServiceId: number;
  StatusName?: string;
  StatusId?: number;
  ImageName?: string;
}
export function InstrumentRestrictions(props: InstrumentRestrictionsProps) {
  const {
    serviceId,
    serviceGroupId,
    useSidePanel,
    initUser,
    assetPickerDisabled,
    hideEquipmentFormTitle,
    withNoPadding,
    useShortView,
    isCover,
    closeCover,
    withNoWrapper,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const authuser = useSelector(selectAuthenticatedUser);
  const appSettings = useSelector(selectAppSettings);
  //const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const isMobile = DetectIsMobile();
  const [equipmentId, setEquipmentId] = React.useState<number | undefined>(
    serviceId,
  );
  const [equipmentGroupId, setEquipmentGroupId] = React.useState<
    number | undefined
  >(serviceGroupId);
  const [selectedService, setSelectedService] =
    useAsyncExtendedState<IAssetDto | null>(null);
  const isAdmin = React.useMemo(() => {
    return (
      authuser !== undefined &&
      (authuser.HasAdminServicePermissions(ServiceType.Online, equipmentId) ||
        authuser.HasLabTechServicePermissions(
          ServiceType.Online,
          equipmentId,
        ) ||
        authuser?.HasAdminGroupPermissions(equipmentGroupId) ||
        authuser?.HasLabTechGroupPermissions(equipmentGroupId))
    );
  }, [equipmentGroupId, equipmentId, authuser]);
  const [selectedUser, setSelectedUser] = React.useState<IUserFilterDto | null>(
    !!authuser && !isAdmin
      ? convertAuthToIUserFilterDto(authuser)
      : !!initUser
      ? ({ Id: initUser, Name: initUser } as IUserFilterDto)
      : null,
  );
  const { cover, openPanel, closePanel, coverClosed, onCloseCover } =
    useSidePanelState();
  const fetchRestrictions = async (
    serviceId?: number,
  ): Promise<IRestrictions> => {
    return RestrictionsApi.getRestrictionByService(serviceId);
  };
  const [restrictions, setRestriction] = useAsyncExtendedState<
    IRestrictions | undefined
  >(undefined);
  const [fetchRestrictionsState, fetchRestrictionsPromise] =
    usePromise(fetchRestrictions);

  const [userRestrictions, setUserRestrictions] = useAsyncExtendedState<
    IUserRestrictionsResult | undefined
  >(undefined);

  const fetchUserRestrictions = async (
    user: Entity<string>,
    serviceId: number,
  ): Promise<IUserRestrictionsResult> => {
    return UserPermissionsApi.getUserSlotPermissions({
      serviceId: serviceId,
      username: user.Id,
    });
  };
  const [fetchUserRestrictionsState, fetchUserRestrictionsPromise] = usePromise(
    fetchUserRestrictions,
  );
  const fetchAsset = async (serviceId: number): Promise<IAssetDto> => {
    return initAssetsData(serviceId.toString(), true, undefined, true).then(
      result => result[0],
    );
  };
  const [fetchAssetState, fetchAssetPromise] = usePromise(fetchAsset);
  const openAsset = useOpenAssetPopUp();
  useEffectOnMount(() => {
    setRestriction(fetchRestrictionsPromise(equipmentId));
    if (isAdmin) {
      if (!!initUser) {
        (async () => {
          try {
            const data = await initUserData(initUser, 'full');
            if (!!data && data.length > 0) {
              let selected = (data[0] ?? {
                Id: initUser,
                Name: initUser,
              }) as IUserFilterDto;
              setSelectedUser(selected);
              setUserRestrictions(
                fetchUserRestrictionsPromise(selected, equipmentId || -1),
              );
            }
          } catch {}
        })();
      } else {
        setSelectedUser(null);
      }
    } else {
      if (selectedUser !== null) {
        setUserRestrictions(
          fetchUserRestrictionsPromise(selectedUser, equipmentId || -1),
        );
      }
    }
    if (isAdmin) {
      setSelectedService(fetchAssetPromise(equipmentId || -1));
    }

    return () => {};
  });
  React.useEffect(() => {
    const messageKey = 'QuizLoad';
    if (fetchRestrictionsState.error) {
      dispatch(
        actions.addNotification({
          variant: 'error',
          message: 'Something went wrong.',
          key: messageKey,
        }),
      );
    }
  }, [fetchRestrictionsState.error, actions, dispatch]);
  const minTimeAhead = (rest: IRestrictions): string => {
    if (rest.MinTimeAheadPeriodValue !== null) {
      return `${t(translations.Before)}: ${rest.MinTimeAheadPeriodValue.toFixed(
        2,
      )}
       ${(t(translations.AdvancedReservation) as string).replace(
         '{0}',
         enumToEntityArray(MinTimeAheadPeriodTypes).filter(
           f => f.Id === rest.MinTimeAheadPeriodType,
         )[0].Name,
       )}`;
    } else if (rest.MinTimeAheadMinutesSinceMidnight !== null) {
      const x = dateUtils.format(
        new Date(0, 0, 0, 0, rest.MinTimeAheadMinutesSinceMidnight),
        dateUtils.DateIOFormats.fullTime,
      );
      return `${t(translations.OnTheSameDay)}: ${(
        t(translations.ToBeBookedBy) as string
      ).replace('{0}', x ?? '')}`;
    } else {
      return '';
    }
  };
  const getStatus = React.useCallback(
    (asset: IAssetDto) => {
      let validRender =
        !!userRestrictions &&
        userRestrictions.PrerequisiteTrainingsIndicators.length > 0 &&
        userRestrictions.PrerequisiteTrainingsIndicators.some(
          f => f.Id === asset.ServiceId,
        );
      if (!!userRestrictions && validRender) {
        let found = userRestrictions.PrerequisiteTrainingsIndicators.find(
          f => f.Id === asset.ServiceId,
        );
        let status = enumToEntityArray(TrainingStatus).find(
          f => f.Id === found?.Status,
        )?.Name;
        let statusName = `${status} ${
          asset.PrerequisiteFor === 1
            ? ' - ( ' + t(translations.CoreWideRequirement) + ' )'
            : ''
        }`;
        return {
          StatusName: statusName,
          Status: found?.Status,
        };
      }
      return undefined;
    },
    [t, userRestrictions],
  );
  const prerequisites: Prerequisites[] = React.useMemo(() => {
    let results: Prerequisites[] = [];
    if (!!restrictions && restrictions.PrerequisiteEquipments.length > 0) {
      results = restrictions.PrerequisiteEquipments.map(preq => {
        let usrrest = getStatus(preq);
        return {
          Id: preq.Id,
          Name: preq.Name,
          ServiceId: preq.ServiceId,
          ImageName: preq.Image,
          StatusId: usrrest?.Status,
          StatusName: usrrest?.StatusName,
        } as Prerequisites;
      });
    }
    return results;
  }, [getStatus, restrictions]);

  // const PrerequisiteWithStatus = ({ asset }: { asset: IAssetDto }) => {
  //   let validRender =
  //     !!userRestrictions &&
  //     userRestrictions.PrerequisiteTrainingsIndicators.length > 0 &&
  //     userRestrictions.PrerequisiteTrainingsIndicators.some(
  //       f => f.Id === asset.ServiceId,
  //     );

  //   if (!!userRestrictions && validRender) {
  //     let found = userRestrictions.PrerequisiteTrainingsIndicators.find(
  //       f => f.Id === asset.ServiceId,
  //     );
  //     let status = enumToEntityArray(TrainingStatus).find(
  //       f => f.Id === found?.Status,
  //     )?.Name;
  //     let statusName = `${status} - ${
  //       asset.PrerequisiteFor === 1
  //         ? '( ' + t(translations.CoreWideRequirement) + ' )'
  //         : ''
  //     }`;
  //     return (
  //       <Body size="small" bold={true}
  //         color={found?.Status === TrainingStatus.Passed ? 'success' : 'danger'}
  //       >
  //         {cliTruncate(statusName, 25, { position: 'end' })}
  //       </Body>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // };
  const handleCloselClick = React.useCallback(() => {
    closePanel({
      isCover: isCover,
      useSidePanel: useSidePanel,
    } as SidePanelCloseState);
  }, [closePanel, isCover, useSidePanel]);
  const userOnChange = (value: Entity<string> | null) => {
    setSelectedUser(value as IUserFilterDto);
    if (value === null) {
      setUserRestrictions(undefined);
    } else {
      setUserRestrictions(
        fetchUserRestrictionsPromise(value, equipmentId || -1),
      );
    }
  };
  const assetOnChange = (value: IAssetDto | null) => {
    setSelectedService(value);
    setRestriction(fetchRestrictionsPromise(value?.ServiceId ?? undefined));
    setSelectedUser(null);
    setUserRestrictions(undefined);
  };
  React.useEffect(() => {
    if (fetchAssetState.status !== 'pending') {
      setEquipmentId(selectedService?.ServiceId ?? undefined);
      setEquipmentGroupId(selectedService?.ServiceGroupId ?? undefined);
    }
    return () => {};
  }, [
    fetchAssetState.status,
    selectedService?.ServiceGroupId,
    selectedService?.ServiceId,
  ]);
  // const minReservationGap = (rest: IRestrictions): string => {
  //   let gaps = [
  //     rest.MinReservationOrderGap,
  //     rest.UserReservationGap,
  //     rest.UserGroupReservationGap,
  //     rest.InstituteReservationGap,
  //   ];
  //   if (gaps.filter(g => g > 0).length > 0) {
  //     return Math.min(...gaps.filter(g => g > 0)).toString();
  //   }
  //   return '';
  // };
  const RenderContent = () => {
    return fetchRestrictionsState.status === 'resolved' && !!restrictions ? (
      <StyledFormFieldsContainer noPadding={withNoPadding}>
        <FormLeftSection showShortView={useShortView}>
          <FormFieldsSection
            titleSection={
              withNoWrapper ? t(translations.InstrumentRestrictions) : undefined
            }
          >
            {isAdmin && (
              <React.Fragment>
                <FormRow>
                  <AssetPicker
                    ariaLabel={t(translations.Resource)}
                    label={t(translations.Resource)}
                    id="available-resources"
                    onChange={assetOnChange}
                    admin={true}
                    variant="filled"
                    fullWidth={true}
                    showOnlyActiveAssets={true}
                    value={selectedService}
                    disabled={assetPickerDisabled}
                  />
                </FormRow>
                <UserOwnPermissions
                  user={selectedUser}
                  isAdmin={true}
                  onUserChange={userOnChange}
                  permissions={
                    !!userRestrictions ? userRestrictions.Permissions : []
                  }
                  permLoading={fetchUserRestrictionsState.status === 'pending'}
                  restrictedOption={restrictions.LimitlessReservationAllowed}
                  EquipmentName={restrictions.Name}
                  serviceGroupId={
                    equipmentGroupId || restrictions.ServiceGroupId
                  }
                  serviceId={equipmentId || restrictions.Id}
                />
              </React.Fragment>
            )}
            {prerequisites.length > 0 && (
              <FormRow>
                <FormLabelText
                  name="PrerequisiteTrainings"
                  html={
                    <React.Fragment>
                      <Body size="small" bold={true}>
                        {t(translations.PrerequisiteTrainings)}
                      </Body>

                      <TrainingsWrapper
                        className={
                          props.isCover || isMobile ? 'short-view' : undefined
                        }
                      >
                        {prerequisites.map(item => {
                          return (
                            <BasicCard
                              key={item.ServiceId}
                              position="horizontal"
                              cardSize="small"
                              title={item.Name}
                              cardContent={
                                item.StatusId === undefined ? undefined : (
                                  <StatusText
                                    className={clsx({
                                      'status-success':
                                        item.StatusId === TrainingStatus.Passed,
                                      'status-warning':
                                        item.StatusId ===
                                        TrainingStatus.Expired,
                                      'status-danger':
                                        item.StatusId ===
                                          TrainingStatus.Missing ||
                                        !item.StatusId,
                                    })}
                                  >
                                    <BasicTypography
                                      variant="boldS"
                                      color="inherit"
                                    >
                                      {item.StatusName}
                                    </BasicTypography>
                                  </StatusText>
                                  //<PrerequisiteWithStatus asset={item} />
                                )
                              }
                              imageSrc={getAssetImageThumbSrc(
                                item.ImageName || null,
                                'horizontal',
                                IsAssetImageExist(
                                  appSettings,
                                  item.ImageName || null,
                                  'horizontal',
                                ),
                              )}
                              useImage={true}
                              maxContentHeight={40}
                              useButtons={false}
                              primaryAction={() =>
                                openAsset(
                                  {
                                    id: item.Id,
                                    serviceId: item.ServiceId,
                                    serviceType: 'Online',
                                    useSidePanel: useSidePanel,
                                  },
                                  openPanel,
                                )
                              }
                              backStyle="white"
                            />
                            // <Box
                            //   style={{
                            //     display: 'flex',
                            //     flexDirection: 'row',
                            //     alignItems: 'center',
                            //     gap: '8px',
                            //   }}
                            //   key={item.ServiceId}
                            // >
                            //   <BookitChip
                            //     onClick={() => openAsset(item)}
                            //     variant="default"
                            //     size="small"
                            //     label={item.Name}
                            //     key={item.Id}
                            //   />
                            //   <PrerequisiteWithStatus asset={item} />
                            // </Box>
                          );
                        })}
                      </TrainingsWrapper>
                    </React.Fragment>
                  }
                />
              </FormRow>
            )}

            {restrictions.ForceTutoring === true && (
              <FormRow>
                <FormLabelText
                  name="ApprovalRequired"
                  label={t(translations.TrainingMandatory)}
                  text={
                    !userRestrictions ||
                    (!!userRestrictions && !userRestrictions.PreApproved) ? (
                      <>{t(translations.ApprovalRequired)}</>
                    ) : (
                      <React.Fragment>
                        <ApprovalRequiredLabel>
                          <Icon icon="check" color="success" />
                          {t(translations.PreApproved) as string}
                          <InfoIcon
                            title={t(translations.PreApproved_info) as string}
                          />
                        </ApprovalRequiredLabel>
                      </React.Fragment>
                    )
                  }
                />
              </FormRow>
            )}
            {restrictions.RestrictToTutoringHours &&
              restrictions.AssistedReservationHours.length > 0 && (
                <FormRow>
                  <FormLabelText
                    name="assistedHours"
                    label={t(
                      translations.AssistedReservationsAllowedOnlyDuringSpecificHours,
                    )}
                    html={
                      <AssistedRoot className={'assisted-root'}>
                        {restrictions.AssistedReservationHours.filter(
                          f =>
                            f.DayOfWeek !== undefined && f.DayOfWeek !== null,
                        ).length > 0 && (
                          <React.Fragment>
                            <Body size="small" bold={true}>
                              {`${t(translations.WeeklyOn)}:`}
                            </Body>
                            <SimpleList
                              key={'weeklyHours'}
                              className={'assisted-list'}
                            >
                              {restrictions.AssistedReservationHours.filter(
                                f =>
                                  f.DayOfWeek !== undefined &&
                                  f.DayOfWeek !== null,
                              ).map(item => (
                                <li
                                  key={`assisted-${item.Id}`}
                                  className={'assisted-list-item'}
                                >
                                  <React.Fragment>
                                    <Icon
                                      icon="circle"
                                      fontSize="6px"
                                      color="primary"
                                    />
                                    <span className={'assisted-over-rule'}>
                                      <Body
                                        size="small"
                                        key={`body-${item.Id}`}
                                      >{`${
                                        dateUtils.getLongDaysArray()[
                                          item.DayOfWeek ?? 0
                                        ]
                                      } - ${dateUtils.timeFormat(
                                        item.StartTime,
                                      )} - ${dateUtils.timeFormat(
                                        item.EndTime,
                                      )}`}</Body>
                                    </span>
                                  </React.Fragment>
                                </li>
                              ))}
                            </SimpleList>
                          </React.Fragment>
                        )}
                        {restrictions.AssistedReservationHours.filter(
                          f =>
                            f.DayOfWeek === undefined || f.DayOfWeek === null,
                        ).length > 0 && (
                          <React.Fragment>
                            <Body size="small" bold={true}>
                              {`${t(translations.SpecificDateOn)}:`}
                            </Body>
                            <SimpleList
                              key={'specificHours'}
                              className={'assisted-list'}
                            >
                              {restrictions.AssistedReservationHours.filter(
                                f =>
                                  f.DayOfWeek === undefined ||
                                  f.DayOfWeek === null,
                              ).map(item => (
                                <li
                                  key={`assisted-${item.Id}`}
                                  className={'assisted-list-item'}
                                >
                                  <React.Fragment>
                                    <Icon
                                      icon="circle"
                                      fontSize="6px"
                                      color="primary"
                                    />
                                    <span className={'assisted-over-rule'}>
                                      <Body
                                        size="small"
                                        key={`body-${item.Id}`}
                                      >{`${dateUtils.shortDateTimeFormat(
                                        item.StartTime,
                                      )} - ${dateUtils.shortDateTimeFormat(
                                        item.EndTime,
                                      )}`}</Body>
                                    </span>
                                  </React.Fragment>
                                </li>
                              ))}
                            </SimpleList>
                          </React.Fragment>
                        )}
                      </AssistedRoot>
                    }
                  />
                </FormRow>
              )}
            {restrictions.MinReservationOrder !== null &&
              restrictions.MinReservationOrder > 0 && (
                <FormRow>
                  <FormLabelText
                    name="MinReservationOrder"
                    label={t(translations.MinimumReservationDurationH)}
                    text={restrictions.MinReservationOrder}
                  />
                </FormRow>
              )}

            {restrictions.MaxReservationOrder !== null &&
              restrictions.MaxReservationOrder > 0 && (
                <FormRow>
                  <FormLabelText
                    name="MaxReservationOrder"
                    label={t(translations.MaximumReservationDurationH)}
                    text={restrictions.MaxReservationOrder}
                  />
                </FormRow>
              )}

            {restrictions.ReservationMinimumGap !== null &&
              restrictions.ReservationMinimumGap > 0 && (
                <FormRow>
                  <FormLabelText
                    name="ReservationMinimumGap"
                    label={t(translations.ReservationMinimumGap)}
                    text={restrictions.ReservationMinimumGap}
                  />
                </FormRow>
              )}
            {restrictions.CarryOverTimeSlotTypeId !== null &&
              restrictions.CarryOverTimeSlotGap != null && (
                <FormRow>
                  <FormLabelText
                    name="CarryOverTimeSlotTypeId"
                    label={t(translations.CarryOverTimeSlotGap)}
                    text={`${restrictions.CarryOverTimeSlotGap} hours over timeslot ${restrictions.CarryOverTimeSlotTypeName}`}
                  />
                </FormRow>
              )}
            {(restrictions.MinTimeAheadPeriodValue !== null ||
              restrictions.MinTimeAheadMinutesSinceMidnight != null) && (
              <FormRow>
                <FormLabelText
                  name="MinimumTimeAhead"
                  label={t(translations.MinimumTimeAhead)}
                  info={
                    restrictions.MinTimeAheadPeriodValue !== null
                      ? t(translations.AdvancedReservation_info)
                      : t(translations.ToBeBookedBy_info)
                  }
                  text={minTimeAhead(restrictions)}
                />
              </FormRow>
            )}
            {restrictions.RestrictionsOverview !== null &&
              restrictions.RestrictionsOverview !== '' && (
                <FormRow>
                  <FormLabelText
                    name="RestrictionsOverview"
                    label={t(translations.RestrictionsOverview)}
                    text={restrictions.RestrictionsOverview}
                  />
                </FormRow>
              )}
            {restrictions.BypassQuotas === true && (
              <FormRow>
                <FormLabelText
                  name="BypassQuotasForReservations"
                  label={t(translations.BypassQuotasForReservations)}
                  text={<BooleanRender value={restrictions.BypassQuotas} />}
                />
              </FormRow>
            )}
            {restrictions.BypassUserQuotasAndGaps &&
              restrictions.BypassUserQuotasAndGapsD !== null &&
              restrictions.BypassUserQuotasAndGapsD > 0 && (
                <FormRow>
                  <FormLabelText
                    name="BypassUserQuotasAndGapsD"
                    label={t(translations.BypassUserQuotasAndGapsD_Description)}
                    text={`${restrictions.BypassUserQuotasAndGapsD} ${t(
                      translations.BypassUserQuotasAndGapsD_DescriptionCont,
                    )}`}
                  />
                </FormRow>
              )}
            {restrictions.BypassMinResDurationD &&
              restrictions.BypassMinResDurationT !== null &&
              restrictions.BypassMinResDurationT > 0 && (
                <FormRow>
                  <FormLabelText
                    name="BypassMinResDurationT"
                    label={t(translations.BypassMinResDurationT)}
                    text={`${restrictions.BypassMinResDurationT} ${t(
                      translations.BypassMinResDurationD_DescriptionCont,
                    )}`}
                  />
                </FormRow>
              )}
          </FormFieldsSection>
          {!isAdmin && restrictions.LimitlessReservationAllowed !== true && (
            <FormFieldsSection
              titleSection={t(translations.AdditionalRestrictions)}
              titleLink={
                <InfoIcon
                  title={
                    t(translations.SpecificUserSlotPermissions_info) as string
                  }
                />
              }
            >
              <UserOwnPermissions
                user={!!authuser ? convertAuthToIUserFilterDto(authuser) : null}
                isAdmin={false}
                onUserChange={userOnChange}
                permissions={
                  !!userRestrictions ? userRestrictions.Permissions : []
                }
                permLoading={fetchUserRestrictionsState.status === 'pending'}
                restrictedOption={restrictions.LimitlessReservationAllowed}
                EquipmentName={restrictions.Name}
                serviceGroupId={equipmentGroupId || restrictions.ServiceGroupId}
                serviceId={equipmentId || restrictions.Id}
              />
            </FormFieldsSection>
          )}
        </FormLeftSection>
        {/* this functionallity hidden by the task https://prog4biz.atlassian.net/browse/BKT-6141  */}
        {/* {(!showTable || (useSidePanel && !sidePanelExpanded)) && (
                <FormFieldsSection
                  titleSection={t(translations.ViewAllInstrumentPermissions)}
                  titleLink={
                    <RestrictionsLink
                      serviceId={serviceId || -1}
                      title={t(translations.ShowRestrictionsFull)}
                      showTable={true}
                      useSidePanel={true}
                    >
                      <Icon icon={['fal', 'table']} size="lg" />
                    </RestrictionsLink>
                  }
                >
                  {' '}
                </FormFieldsSection>
              )}
              {showTable && (sidePanelExpanded || !useSidePanel) && (
                <UserPermissionsTable
                  serviceId={serviceId || -1}
                  isSidePanel={useSidePanel || false}
                  byUserFilter={false}
                />
              )} */}
      </StyledFormFieldsContainer>
    ) : fetchRestrictionsState.status === 'pending' ? (
      <Div100>
        <LinearProgress />
      </Div100>
    ) : (
      <BasicTypography variant="buttonM">
        {t(translations.err_Equipment_NotFound)}
      </BasicTypography>
    );
  };
  return withNoWrapper ? (
    <RenderContent />
  ) : (
    <PageWrapper
      useSidePanel={useSidePanel}
      closable={useSidePanel || isCover}
      disableExpandToggle={isCover}
      loading={
        fetchRestrictionsState.status === 'pending' &&
        !!fetchRestrictionsState.status
      } //loading || rows.length === 0
      pageLink={`/instrumentRestrictions?eid=${equipmentId}`}
      toPageLink={`instrumentRestrictions?eid=${equipmentId}`}
      pageName={t(translations.InstrumentRestrictions)}
      useShortView={useShortView}
      cover={cover}
      hideMenu={useShortView}
      coverClosed={coverClosed}
      titlePage={
        restrictions
          ? hideEquipmentFormTitle
            ? t(translations.ReservationRestrictions)
            : `${restrictions.Name} - ${t(
                translations.ReservationRestrictions,
              )}`
          : ''
      }
      titleTooltip={
        restrictions
          ? `${restrictions.Name} - ${t(translations.ReservationRestrictions)}`
          : ''
      }
      children={
        <RestrictWrapper>
          <RenderContent />
        </RestrictWrapper>
      }
      closeSidePanel={handleCloselClick}
      //rightTopActions={data?.AmAdmin ? [EditButton] : undefined}
      isCover={isCover} //&& !showTable
      closeCover={!isCover ? onCloseCover : closeCover}
    />
  );
}
