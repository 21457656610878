import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { GetRawValue } from 'app/components/BasicTable/types/FilterParam';

import { DetectIsMobile } from 'utils/mobileDetect';
import { PageTopActionsProps } from '../type';
import { useOpenOfflineRefsPopUp } from '../../GlobalSelectedActions/OpenOfflineTypeRefs';
import React from 'react';
import { isNullOrUndefined } from 'utils/typeUtils';

export function AddOfflineRefs(
  props: PageTopActionsProps & { refId?: number; refTypeId?: number },
) {
  const {
    refId,
    refTypeId,
    openPanelWithCover,
    onCllose,
    size,
    text,
    variant,
    icon,
    isIconButton,
    useSidePanel,
    isExpanded,
    compact = true,
    infoTitle,
    ...other
  } = props;
  const location = useLocation();
  const isMobile = DetectIsMobile();
  const { t } = useTranslation();
  const openPanel = useOpenOfflineRefsPopUp();

  const referId = React.useMemo(() => {
    let tmp = GetRawValue(new URLSearchParams(location.search), 'refId');
    return refId || (!isNullOrUndefined(tmp) ? Number(tmp) : undefined);
  }, [location.search, refId]);
  const referTypeId = React.useMemo(() => {
    let tmp = GetRawValue(new URLSearchParams(location.search), 'refTypeId');
    return refTypeId || (!isNullOrUndefined(tmp) ? Number(tmp) : undefined);
  }, [location.search, refTypeId]);
  const open = React.useCallback(() => {
    openPanel(
      {
        id: undefined,
        useSidePanel: useSidePanel,
        refId: referId,
        refTypeId: referTypeId,
      },
      openPanelWithCover,
      onCllose,
    );
  }, [
    openPanel,
    onCllose,
    openPanelWithCover,
    referId,
    referTypeId,
    useSidePanel,
  ]);
  return (
    <TopActionButton
      text={text || (t(translations.RelatedService) as string)}
      icon={icon || 'plus'}
      startIcon={icon || 'plus'}
      title={infoTitle}
      variant={
        isMobile && compact && !isIconButton ? 'ghost' : variant || 'ghost'
      }
      size={size || 'small'}
      noChangeOnMobile={!compact}
      isIconButton={isIconButton}
      onClick={() => open()}
      {...other}
    />
  );
}
