import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import {
  selectServiceRequestCustomFormColumns,
  selectServiceRequestRowInsertStatus,
} from 'app/pages/Samples/RequestSamplesPage/slice/selectors';
import {
  IServiceRequestRelated,
  IServiceRequestTableFormComponent,
} from '../..';
import {
  prepareServiceRequestRowModel,
  serviceRequestModelToDto,
} from 'app/pages/Samples/RequestSamplesPage/slice/prepareServiceRequestRowModel';
import { IServiceRequestRowDto } from 'api/odata/generated/entities/IServiceRequestRowDto';
import { httpClient } from 'api/HttpClient';
import { IServiceRequestTableRowModel } from 'app/pages/Samples/RequestSamplesPage/slice/types';
import { ArrayHelpers } from 'formik';
import { omit } from 'lodash';
import { appSettingsActions } from 'app/slice';
import { ServiceRequestTableColumnsCollection } from 'app/pages/Samples/RequestSamplesPage/slice/utils/ServiceRequestTableColumn';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';

export interface AddServiceRequestRowButtonProps
  extends IServiceRequestTableFormComponent,
    IServiceRequestRelated {
  /**
   * Should be connected to service request's edit-ability state
   */
  disabled: boolean;
}
export function AddServiceRequestRowButton({
  serviceRequestId,
  ...props
}: AddServiceRequestRowButtonProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const processing = useSelector(selectServiceRequestRowInsertStatus);
  const columns = useSelector(selectServiceRequestCustomFormColumns);
  const handleCreateButtonClick: React.MouseEventHandler<HTMLButtonElement> =
    React.useCallback(
      async e => {
        if (serviceRequestId === undefined) {
          return;
        }
        if (columns === null) {
          return;
        }
        try {
          await AddNewCustomTableRow({
            index: 0,
            serviceRequestId,
            columns,
            model: {
              ServiceRequestId: serviceRequestId,
            },
            arrayHelpers: props.arrayHelpers,
          });
          dispatch(
            appSettingsActions.addNotification({
              variant: 'success',
              message: t(translations.ServiceRequestRow_InsertSuccess),
            }),
          );
        } catch (error) {
          dispatch(
            appSettingsActions.addNotification({
              variant: 'error',
              message: t(translations.ServiceRequestRow_InsertError),
            }),
          );
        }
      },
      [columns, dispatch, props.arrayHelpers, serviceRequestId, t],
    );

  return (
    <TopActionButton
      variant="main"
      onClick={handleCreateButtonClick}
      icon={'plus'}
      size="small"
      text={t(translations.AddRows)}
      disabled={props.disabled}
      processing={processing === 'pending'}
    />
  );
}
export async function AddNewCustomTableRow(props: {
  serviceRequestId: number;
  columns: ServiceRequestTableColumnsCollection;
  arrayHelpers: ArrayHelpers;
  model: Partial<IServiceRequestTableRowModel>;
  index: number | undefined;
}) {
  const url = `api/odata/v4/ServiceRequests(${props.serviceRequestId})/Rows`;
  const dto = serviceRequestModelToDto(props.model, props.columns);
  const response: IServiceRequestRowDto = await httpClient.post(
    url,
    omit(dto, '@odata.context'),
    {
      params: { $expand: 'Status' },
    },
  );
  const models = prepareServiceRequestRowModel([response], props.columns);
  models.forEach(item => {
    props.arrayHelpers.insert(props.index ?? 0, item);
  });
}
