import { ODataOperators } from 'api/odata/ODataFilter';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { AssetPickerFilter } from 'app/components/BasicTable/Filters/AssetFilter';
import { DatesRangeFilter } from 'app/components/BasicTable/Filters/DatesRangeFilter';
import { UserFilter } from 'app/components/BasicTable/Filters/UserFilter';
import {
  getDefaultFilterDatePeriod,
  GetNumberOrUndefined,
  GetPartialEntity,
  GetRawValue,
  GetStringOrUndefined,
  getStringUnionOrUndefined,
  initPickerValue,
  presetDatesRangeToQueryString,
  URLSearchParamsCI,
} from 'app/components/BasicTable/types/FilterParam';
import { getPeriodTypesEntity } from 'app/components/DatePeriods';
import { initAssetData } from 'app/components/pickers/AutocompletePickers/AssetPicker';
import { initUserData } from 'app/components/pickers/AutocompletePickers/UserPicker';
import { translations } from 'locales/translations';
import { PresetDatesRange } from 'types/PresetDatesRange';
import { IEmailNotificationsRow } from './IEmailNotificationsRow';
import { EmailTypeUnionArray } from 'api/odata/generated/enums/EmailType';
import EmailTypeFilter from 'app/components/BasicTable/Filters/EmailTypeFilter';
import NumberFieldFilter from 'app/components/BasicTable/Filters/NumberFieldFilter';

export const GetFilters: GetPageFilters<IEmailNotificationsRow> =
  (t, appSettings, user, settings) => search => {
    const params = new URLSearchParamsCI(search);
    var result: IFilterSettings<IEmailNotificationsRow>[] = [
      {
        fieldName: 'Recipient',
        displayNameKey: translations.Recipient,
        operator: ODataOperators.Equals,
        queryStringSerializer: 'recipient',
        value: GetPartialEntity(
          GetStringOrUndefined(GetRawValue(params, 'recipient')),
        ),
        component: UserFilter,
        initData: initPickerValue(initUserData),
      },
      {
        fieldName: 'AssetId',
        queryStringSerializer: 'aid',
        displayNameKey: translations.Asset,
        operator: ODataOperators.Equals,
        value: GetPartialEntity(
          GetNumberOrUndefined(GetRawValue(params, 'aid')),
        ),
        initData: initPickerValue(initAssetData),
        component: AssetPickerFilter,
      },
      {
        fieldName: 'CreatedBy',
        displayNameKey: translations.CreatedBy,
        operator: ODataOperators.Equals,
        queryStringSerializer: 'createdby',
        value: GetPartialEntity(
          GetStringOrUndefined(GetRawValue(params, 'createdby')),
        ),
        component: UserFilter,
        initData: initPickerValue(initUserData),
      },
      {
        fieldName: 'Timestamp',
        displayNameKey: translations.Timestamp,
        operator: ODataOperators.Between,
        defaultValue: PresetDatesRange.fromPreset(
          getPeriodTypesEntity('ThisMonth'),
        ),
        queryStringSerializer: presetDatesRangeToQueryString(
          'period',
          'st',
          'et',
        ),
        value: getDefaultFilterDatePeriod('period', 'st', 'et', params),
        component: DatesRangeFilter,
        urlKey: 'period',
      },
      {
        fieldName: 'NotificationTypeId',
        displayNameKey: translations.NotificationType,
        operator: ODataOperators.Equals,
        queryStringSerializer: 'NotificationTypeId',
        value: getStringUnionOrUndefined(
          GetRawValue(params, 'NotificationTypeId'),
          EmailTypeUnionArray,
        ),
        component: EmailTypeFilter,
      },
      {
        fieldName: 'ReferenceId',
        displayNameKey: translations.Reference,
        operator: ODataOperators.Equals,
        queryStringSerializer: 'ReferenceId',
        value: GetNumberOrUndefined(GetRawValue(params, 'ReferenceId')),
        component: NumberFieldFilter,
      },
    ];
    return result;
  };
