import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Column, Row } from 'react-table';
import { AssetLink } from 'app/pages/AssetPopUp/OpenAssetDetails';
import { ICoresDirectoryRow } from 'types/ICoresDirectoryRow';
import { DescriptionCell } from 'app/components/BasicTable/components/DescriptionCell';

import ImageRenderer from 'app/components/BasicTable/CellRenderers/ImageRenderer';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { AllowedSettings, GlobalSettings } from 'utils/globalSettings';
import { BooleanRender } from 'app/components/BasicTable/components/BooleanRender';
import { Roles } from 'api/odata/generated/enums/Roles';
import { ExternalLink } from 'app/components/ExternalLink';
import { toRootedURL } from 'utils/url-utils';
import { tryParseBool } from 'utils/string-utils';
import { RegionLink } from 'app/pages/Regions/RegionLink';
import { AppSettings, IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { StyledLimitLabel } from 'app/components/StyledComponents';

export const getColumns = (
  t: TFunction,
  isRowSelectable: (row: Row<ICoresDirectoryRow>) => boolean,
  user: AuthenticatedUser | undefined,
  settings: GlobalSettings | null,
  appSettings: AppSettings | null,
  useSidePanel?: boolean,
  isCard?: boolean,
) => {
  const isAdmin = !!user && user.Roles.includes(Roles.Administrators);
  const isGroupAdmin = !!user && user.Roles.includes(Roles.GroupAdministrators);
  const cols: (Column<ICoresDirectoryRow> & { invisible?: boolean })[] = [
    {
      Header: t(translations.Name) as string,
      accessor: 'Name',
      Cell: ({ value, row }) => {
        const id = row.original.AssetId ?? undefined;
        if (id !== undefined) {
          return (
            //isRowSelectable(row) ? (
            <AssetLink id={id} useSidePanel={useSidePanel}>
              {value}
            </AssetLink>
          );
        } else {
          return <>{value}</>;
        }
      },
    },
    {
      Header: t(translations.Image) as string,
      accessor: 'ImageName',
      Cell: ImageRenderer,
    },
    {
      Header: t(translations.Description) as string,
      accessor: 'Description',
      Cell: DescriptionCell({ width: isCard ? 'auto' : 'long' }),
    },
  ];
  if (isAdmin || isGroupAdmin) {
    cols.push({
      Header: t(translations.HashSymbol) as string,
      accessor: 'Id',
      Cell: ({ value, row }) => {
        if (row.original.OwnedByMe) {
          return (
            <ExternalLink
              href={toRootedURL(`/newgroup.aspx?Edit=${row.original.Id}`)}
            >
              {value}
            </ExternalLink>
          );
        } else {
          return <>{value}</>;
        }
      },
    });
    cols.push({
      Header: t(translations.Active) as string,
      accessor: 'Active',
      Cell: ({ value }) => <BooleanRender value={value} />,
    });
    cols.push({
      Header: t(translations.AdminEmail) as string,
      accessor: 'AdminEmail',
    });
    if (IsModuleEnabled(appSettings, KnownModules.Divisions)) {
      cols.push({
        Header: t(translations.Division) as string,
        accessor: 'DivisionName',
        Cell: ({ value, row }) => {
          if (isAdmin) {
            if (!row.original.DivisionId || row.original.DivisionId === null) {
              return <>{t(translations.NA)}</>;
            } else {
              return (
                <>
                  <ExternalLink
                    href={toRootedURL(
                      `/newsupergroup.aspx?edit=${row.original.DivisionId}`,
                    )}
                  >
                    {value}
                  </ExternalLink>
                </>
              );
            }
          } else {
            return <>{value ?? t(translations.NA)}</>;
          }
        },
      });
      cols.push({
        Header: t(translations.Region) as string,
        accessor: 'RegionName',
        Cell: ({ value, row }) => {
          if (isAdmin) {
            if (!row.original.RegionId || row.original.RegionId === null) {
              return <>{t(translations.NA)}</>;
            } else {
              return (
                <RegionLink
                  id={row.original.RegionId}
                  initialModel={{
                    Id: row.original.RegionId,
                    Name: row.original.RegionName ?? value,
                  }}
                  useSidePanel={true}
                >
                  {value}
                </RegionLink>
              );
            }
          } else {
            return <>{value ?? t(translations.NA)}</>;
          }
        },
      });
    }
    cols.push({
      Header: t(translations.OnlineServicesCount) as string,
      accessor: 'OnlineServicesCount',
      Cell: ({ value }) =>
        !!value && value !== null ? <>{value}</> : <>{t(translations.NA)}</>,
    });
    cols.push({
      Header: t(translations.OfflineServicesCount) as string,
      accessor: 'OfflineServicesCount',
      Cell: ({ value }) =>
        !!value && value !== null ? <>{value}</> : <>{t(translations.NA)}</>,
    });
    cols.push({
      Header: t(translations.RequestTypesCount) as string,
      accessor: 'RequestTypesCount',
      Cell: ({ value }) =>
        !!value && value !== null ? <>{value}</> : <>{t(translations.NA)}</>,
    });
    if (
      tryParseBool(
        settings?.GetString(AllowedSettings.LimitServiceGroupLicenses),
      ) === true
    ) {
      cols.push({
        Header: t(translations.ServiceGroupAdminsLimit) as string,
        accessor: 'AdminsLimit',
        Cell: ({ value, row }) => {
          return (
            <StyledLimitLabel className="limit-label">
              {`${row.original.AdminsTotal}  / `}
              {value === undefined || value === null || value === 0 ? (
                <span className="infinity-icon">&#8734;</span>
              ) : (
                value
              )}
            </StyledLimitLabel>
          );
        },
      });
      cols.push({
        Header: t(translations.LabTechsLimit) as string,
        accessor: 'LabTechLimit',
        Cell: ({ value, row }) => {
          return (
            <StyledLimitLabel className="limit-label">
              {`${row.original.LabTechTotal}  / `}
              {value === undefined || value === null || value === 0 ? (
                <span className="infinity-icon">&#8734;</span>
              ) : (
                value
              )}
            </StyledLimitLabel>
          );
        },
      });
    }
  }
  return cols.filter(v => !v.invisible) as Column<ICoresDirectoryRow>[];
};
