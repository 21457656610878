/**
 *
 * ImportButton
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Menu } from '@material-ui/core';

export interface ImportButtonProps {
  onClick: () => void;
  importLink?: string;
  importHandler?: () => void;
  menuItems?: (onClose: () => void) => React.ReactNode[] | undefined;
}

export function ImportButton(props: ImportButtonProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleImport = async () => {
    handleClose();
    props.onClick();
  };
  const menuItems = React.useMemo(() => {
    const result = props.menuItems ? props.menuItems(handleClose) : undefined;
    return result;
  }, [props]);
  const showSingleImportButton =
    menuItems === undefined || menuItems.length === 0;

  return (
    <React.Fragment>
      <Button
        id="import-button"
        aria-controls="import-menu-button"
        aria-haspopup="true"
        variant="white"
        size="small"
        startIcon={<Icon icon="file-import" />}
        onClick={showSingleImportButton ? handleImport : handleClick}
      >
        {t(translations.Import)}
      </Button>
      {!showSingleImportButton && (
        <>
          <Menu
            id="import-menu-button"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{ dense: true }}
            getContentAnchorEl={null}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          >
            {menuItems}
          </Menu>
        </>
      )}
    </React.Fragment>
  );
}
