import { FormControl, FormLabel } from '@material-ui/core';
import { FieldHookConfig } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { InfoIcon } from '../BasicIcons/InfoIcon';

export const FormParagraph = ({
  label,
  html,
  ...props
}: FieldHookConfig<string> & IFieldProps) => {
  return (
    <FormControl
      variant="outlined"
      fullWidth={true}
      className="paragraph-control"
    >
      <FormLabel className="paragraph">
        {label}
        {props.info && <InfoIcon title={props.info} />}
      </FormLabel>
      <span className="paragraph-text">{html}</span>
    </FormControl>
  );
};
