import { IAsset } from '../IAsset';
import { Section, SectionsProps } from './types';
import { AttributeValue } from '../IAssetRow';
import React from 'react';
import { FormFieldsSection, FormRow } from 'app/components/Forms/FormsLayout';
import { GhostInput } from 'app/components/BasicInputs/GhostInput';

export interface SectionRowsProps {
  sections: Section[];
  data?: IAsset;
  noLabel?: boolean;
}
export const notFullWidth = ['Status', 'DOI'];
export const noLabelRendersArray = ['ImageName', 'Comments'];
export const asHtmlRenderArray = ['Comments', 'ImageName'];
export const DefautltCell = ({ value }: AttributeValue<IAsset>) => (
  <React.Fragment>{value}</React.Fragment>
);
export function RenderRows({
  sections,
  data,
  noLabel,
  ...props
}: SectionRowsProps) {
  // const rows = React.useCallback(
  //   (rows: IAssetRow[]) => {
  //     if (!!data) {
  //       return (
  //         <>
  //           {rows
  //             .filter(f => {
  //               return (
  //                 f.validToRender === undefined ||
  //                 f.validToRender({
  //                   value: data![f.PropName ?? f.Id],
  //                   original: data,
  //                 })
  //               );
  //             })
  //             .map(r => {
  //               let cell = r.Cell ?? DefautltCell;
  //               let cellData = {
  //                 original: data,
  //                 value: data![r.PropName ?? r.Id],
  //               };
  //               return (
  //                 <FormRow key={`asset_row-${r.Id}`}>
  //                   <GhostInput
  //                     key={`input-${r.Id}`}
  //                     label={
  //                       noLabel === true
  //                         ? undefined
  //                         : noLabelRendersArray.includes(r.Id)
  //                         ? undefined
  //                         : r.Name
  //                     }
  //                     valueHtml={
  //                       asHtmlRenderArray.includes(r.Id)
  //                         ? cell(cellData)
  //                         : undefined
  //                     }
  //                     valueText={
  //                       !asHtmlRenderArray.includes(r.Id)
  //                         ? cell(cellData)
  //                         : undefined
  //                     }
  //                     fullWidth={notFullWidth.includes(r.Id) ? false : true}
  //                   />
  //                 </FormRow>
  //               );
  //             })}
  //         </>
  //       );
  //     } else {
  //       return <> </>;
  //     }
  //   },
  //   [data, noLabel],
  // );
  return !!data ? (
    <React.Fragment>
      {sections.map(s => {
        return (
          <FormFieldsSection titleSection={s.row.Name} key={`section_${s.Id}`}>
            {s.rows
              .filter(f => {
                return (
                  f.validToRender === undefined ||
                  f.validToRender({
                    value: data![f.PropName ?? f.Id],
                    original: data,
                  })
                );
              })
              .map(r => {
                let cell = r.Cell ?? DefautltCell;
                let cellData = {
                  original: data,
                  value: data![r.PropName ?? r.Id],
                };
                return (
                  <FormRow key={`asset_row-${r.Id}`}>
                    <GhostInput
                      key={`input-${r.Id}`}
                      labelBolder
                      label={
                        noLabel === true
                          ? undefined
                          : noLabelRendersArray.includes(r.Id)
                          ? undefined
                          : r.Name
                      }
                      valueHtml={
                        asHtmlRenderArray.includes(r.Id)
                          ? cell(cellData)
                          : undefined
                      }
                      valueText={
                        !asHtmlRenderArray.includes(r.Id)
                          ? cell(cellData)
                          : undefined
                      }
                      fullWidth={notFullWidth.includes(r.Id) ? false : true}
                    />
                  </FormRow>
                );
              })}
          </FormFieldsSection>
        );
      })}
    </React.Fragment>
  ) : null;
}
export const RenderLeftSection = React.memo(function RenderSection({
  sections,
  data,
  ...props
}: SectionsProps) {
  return sections.length > 0 ? (
    <RenderRows key={'leftsection'} sections={sections} data={data} />
  ) : null;
});
export const RenderRightSection = React.memo(function RenderSection({
  sections,
  data,
}: SectionsProps) {
  return sections.length > 0 ? (
    <RenderRows
      key={'rightsection'}
      sections={sections}
      data={data}
      noLabel={true}
    />
  ) : null;
});
