/**
 *
 * Asynchronously loads the component for ProjectMembersPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ProjectMembersPage = lazyLoad(
  () => import('./index'),
  module => module.ProjectMembersPage,
);
