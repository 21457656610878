import { MainTabsUnion, SiteMapNode } from 'types/SiteMapSettings';
import { groupBy, values } from 'lodash';
import { MenuItemEntry, MenuItemEntryL1, MenuItems } from '../types';
import { isNullOrUndefined } from 'utils/typeUtils';
import { toMenuItemEntry } from './toMenuItemEntry';
import { filterVisible } from './filterVisible';

export function prepareMenu(menuItems: SiteMapNode[]) {
  const items = menuItems.filter(filterVisible).map((item, index) => {
    /**
     * Child nodes split into regular items shown as a main menu and admin/settings as they are shown separately
     */
    const childNodes = groupBy(item.ChildNodes.filter(filterVisible), item =>
      item.IsAdminSettings ? 'adminSettings' : 'items',
    );

    const menuEntry: MenuItemEntryL1 = {
      ...toMenuItemEntry(item, index, menuItems),
      items: childNodes['items']?.map<MenuItemEntry>(toMenuItemEntry),
      adminItems: childNodes['adminSettings']
        ?.filter(f =>
          item.MainTab === 'Budgets'
            ? f.SubTab !== 'BudgetReportTabSetting'
            : true,
        )
        ?.map(toMenuItemEntry),
      mainTab: !!item.MainTab ? (item.MainTab as MainTabsUnion) : undefined,
      groupTab: item.GroupTab,
      selected: false,
      closeOnClick:
        !isNullOrUndefined(item.LinkWithChildren) || item.ChildNodes.length < 1,
    };

    return menuEntry;
  });
  const result: MenuItems = values(groupBy(items, f => f.groupTab));
  return result;
}
