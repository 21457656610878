import { Tab, TabProps } from '@material-ui/core';
import * as React from 'react';
import clsx from 'clsx';
import { capitalize } from 'lodash';

export interface BasicTabProps extends TabProps {
  mainIcon?: React.ReactElement;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  processing?: boolean;
  size?: 'small' | 'medium';
}

export const BasicTab = React.forwardRef<any, BasicTabProps>(function TabButton(
  {
    disabled,
    processing,
    size = 'medium',
    mainIcon,
    startIcon,
    endIcon,
    label,
    className,
    ...props
  },
  ref,
) {
  const startIconComponent = startIcon && (
    <span className={clsx('startIcon', `iconSize${capitalize(size)}`)}>
      {startIcon}
    </span>
  );
  const endIconComponent = endIcon && (
    <span className={clsx('endIcon', `iconSize${capitalize(size)}`)}>
      {endIcon}
    </span>
  );
  return (
    <Tab
      disabled={(processing || disabled) ?? false}
      ref={ref}
      className={clsx(className, `tabSize${capitalize(size)}`, {
        startIcon: startIcon !== undefined,
        endIcon: endIcon !== undefined,
      })}
      value={props.value}
      fullWidth={props.fullWidth}
      icon={
        !!mainIcon ? (
          <span className={clsx('mainIcon', `iconSize${capitalize(size)}`)}>
            {mainIcon}
          </span>
        ) : undefined
      }
      disableRipple
      label={
        <React.Fragment>
          {!!startIcon || !!endIcon ? (
            <React.Fragment>
              {!!startIconComponent && startIconComponent}
              <span className={'buttonLabel'}>{label}</span>
              {!!endIconComponent && endIconComponent}
            </React.Fragment>
          ) : (
            <span className={'buttonLabel'}>{label}</span>
          )}
        </React.Fragment>
      }
      {...props}
    />
  );
});
