import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { printPDFSaga } from './saga';
import { PrintPDFItem, PrintPDFState } from './types';

export const initialState: PrintPDFState = {
  printing: false,
  isLoading: undefined,
  useSections: false,
};

const slice = createSlice({
  name: 'printPDF',
  initialState,
  reducers: {
    setPrinting(state, action: PayloadAction<boolean>) {
      state.printing = action.payload;
    },
    setUseSections(state, action: PayloadAction<boolean>) {
      state.useSections = action.payload;
    },
    updateSections(state, action: PayloadAction<PrintPDFItem[] | undefined>) {
      state.Sections = action.payload;
    },
    updateSection(
      state,
      action: PayloadAction<{
        id: string;
        checked: boolean;
      }>,
    ) {
      if (state.Sections !== undefined) {
        const section = state.Sections.find(f => f.id === action.payload.id);
        if (section !== undefined) {
          section.checked = action.payload.checked;
        }
      }
    },
    saveSections(state, action: PayloadAction) {},
    clearLoading(state, action: PayloadAction) {
      state.tables = undefined;
    },
    setLoading(state, action: PayloadAction<{ id: string; loading: boolean }>) {
      if (state.tables === undefined) {
        state.tables = [action.payload];
        state.isLoading = action.payload.loading;
      } else {
        const table = state.tables.find(t => t.id === action.payload.id);
        if (table !== undefined) {
          table.loading = action.payload.loading;
        } else {
          state.tables.push(action.payload);
        }
        state.isLoading = state.tables.some(t => t.loading === true);
      }
    },
  },
});

export const { actions: printPDFActions } = slice;

export const usePrintPDFSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: printPDFSaga });
  return { actions: slice.actions };
};
