import { BoxProps } from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { selectSnackBarIndex } from '../../slice/selectors';
import styled from 'styled-components';

export interface SnackWrapperProps extends BoxProps {
  children: React.ReactNode;
}

interface StyledDivProps {
  upSnackbarIndex?: boolean;
}

const StyledDiv = styled.div<StyledDivProps>`
  ${props => props.upSnackbarIndex && `z-index: 1301;`}
`;

export function SnackWrapper(props: SnackWrapperProps) {
  const upSnackbarIndex = useSelector(selectSnackBarIndex);
  return (
    <StyledDiv upSnackbarIndex={upSnackbarIndex}>{props.children}</StyledDiv>
  );
}
