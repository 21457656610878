import { IModificationDto } from 'api/odata/generated/entities/IModificationDto';
import { DateTimeLabel } from 'app/components/DateTimeLabel';
import { CellProps, Renderer } from 'react-table';
import { dateUtils } from 'utils/date-utils';

/**
 * Processes the old/new value in modifications tables:
 * * For date-ish values - tries to parse the ISO and render in client's tz/locale
 * @param param0
 * @returns
 */
const ModificationsValueRenderer: Renderer<
  CellProps<IModificationDto, string>
> = ({ value }: CellProps<IModificationDto, string>) => {
  if (value === null) {
    return null;
  }
  const date = dateUtils.parseISO(value);
  if (date.toString() !== 'Invalid Date' && date !== null) {
    return <DateTimeLabel value={date} />;
  } else {
    return value;
  }
};

export default ModificationsValueRenderer;
