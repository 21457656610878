import { BaseOptionsMultiPicker } from 'app/components/BasicPickers/BaseOptionsMultiPicker';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '..';

const getOptions: (t: TFunction) => Entity<number>[] = (t: TFunction) => {
  return [
    {
      Id: 1,
      Name: t(translations.Future),
    },
    {
      Id: 2,
      Name: t(translations.Ongoing),
    },
  ];
};

export const getInitUpcomingReservationfilterMultiData =
  (t: TFunction) => (initval: string | undefined) => {
    if (initval === undefined) {
      return Promise.resolve([]);
    }
    return Promise.resolve(
      getOptions(t).filter(f =>
        initval
          .split('|')
          .map(s => parseInt(s))
          .includes(f.Id),
      ),
    );
  };

export const UpcomingReservationfilter = (props: IFilterComponentProps) => {
  const { t } = useTranslation();
  const handleChange = (value: Entity<number>[]) => {
    props.onChange(value);
  };
  return (
    <BaseOptionsMultiPicker
      getOptions={() => getOptions(t)}
      onChange={handleChange}
      value={props.value}
      fullWidth={props.fullWidth}
      placeholder={t(translations.AllReservations)}
      size="small"
      id={props.id}
    />
  );
};
