/**
 *
 * OnlineServicePicker
 *
 */
import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import { Entity, Identifiable } from 'types/common';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import { InputBaseComponentProps } from '@material-ui/core';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { useSelector } from 'react-redux';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
export type ServiceUrl = 'base' | 'admin' | 'reserved';
export interface OnlineServicePickerProps
  extends Omit<AutocompletePickerProps<IServiceFilterDto>, 'loadData'> {
  inputProps?: InputBaseComponentProps;
  urlType?: ServiceUrl;
  serviceGroups?: Entity<number>[];
}

const url = '/api/odata/v4/ServiceFilter';
const adminUrl = '/api/odata/v4/ServiceFilter/AdminEquipments';
const reservableUrl = '/api/odata/v4/ServiceFilter/ReservableEquipments';

export const initOnlineServiceData = (
  initval: string | undefined,
  sourceurl?: ServiceUrl,
  serviceGroups?: Identifiable<number>[],
) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    let foo: string | undefined = undefined;
    const id = parseInt(initval);
    const predicates: string | Condition<IServiceFilterDto>[] = [];
    if (!isNaN(id)) {
      predicates.push(new Condition('Id', ODataOperators.Equals, id));
    }
    // apply only global service group filter related items filter
    // items not related to one of the selected cores are discarded
    if (serviceGroups !== undefined && serviceGroups?.length > 0) {
      predicates.push(
        new Condition(
          'ServiceGroupId',
          ODataOperators.Includes,
          serviceGroups.map(f => f.Id),
        ),
      );
    }
    foo = predicates.map(f => f.toString()).join(' and ');

    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
      $expand: string;
    } = {
      $orderby: 'Name asc',
      $filter: foo,
      $skip: 0,
      $top: 10,
      $expand: 'WorkingHours',
    };
    let initUrl =
      sourceurl === undefined
        ? url
        : sourceurl === 'reserved'
        ? reservableUrl
        : sourceurl === 'admin'
        ? adminUrl
        : url;
    return httpClient
      .get(initUrl, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (
  predicates: (string | Condition<IServiceFilterDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
  urlType: ServiceUrl | undefined,
) =>
  getAutoCompleteLoadDataFn<IServiceFilterDto>({
    url:
      urlType === 'reserved'
        ? reservableUrl
        : urlType === 'admin'
        ? adminUrl
        : url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: [
      'Id',
      'Name',
      'ServiceGroupId',
      'ServiceTypeId',
      'UseServiceGroupTrainingLevels',
      'Color',
      'ImageName',
      'ServiceGroupName',
      'RestrictDurationUnitsAmount',
      'RestrictDurationUnitType',
      'DefaultDuration',
      'ForceFullDayReservations',
      'AssemblyId',
      'TopAssemblyId',
      'AssetCatId',
      'AssetCatName',
      'RestrictReservationToTimeSlots',
      'MinOrderHours',
      'WorkingHours',
    ],
    expand: 'WorkingHours',
    globalServiceGroupFilter:
      !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
        ? [
            new Condition<IServiceFilterDto>(
              'ServiceGroupId',
              ODataOperators.Includes,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
  });

export function OnlineServicePicker({
  label,
  defaultValue,
  placeholder,
  className,
  required,
  helperText,
  onChange,
  variant,
  info,
  id,
  value,
  predicates,
  disabled,
  inputProps,
  urlType,
  serviceGroups,
  ...props
}: OnlineServicePickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const groups = serviceGroups?.length
    ? serviceGroups
    : globalServiceGroupFilter;
  const innerLoadData = loadData(predicates, groups || [], urlType);
  return (
    <AutocompletePicker
      loadData={innerLoadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      variant={variant}
      onChange={onChange}
      disabled={disabled}
      info={info}
      id={id || 'online-service-picker'}
      helperText={helperText}
      required={required}
      className={className}
      onBlur={props.onBlur}
      placeholder={placeholder}
      defaultValue={defaultValue}
      label={label}
      value={value}
      ariaLabel={'Equipment search'}
      {...props}
      error={props.error}
    />
  );
}
