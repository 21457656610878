import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { IAuthenticatedUser } from 'types/AuthenticatedUser';
import { Column } from 'react-table';
import { roundTwoPlacesAfterDecimalPoint } from 'utils/number-utils';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { IOfflineServiceTypeRefsDto } from 'api/odata/generated/entities/IOfflineServiceTypeRefsDto';
import { AssetLink } from '../AssetPopUp/OpenAssetDetails';
import { OfflineReferenceTypeEnum } from 'enums/OfflineReferenceTypeEnum';
import { ServiceTypeUnion } from 'api/odata/generated/enums/ServiceType';
import { getSingleEnumEntityById } from 'utils/enumKeys';
import { MandatoryOfflineTypeEnum } from 'enums/MandatoryOfflineTypeEnum';
import { OfflineTypeRefsLink } from '../Actions/GlobalSelectedActions/OpenOfflineTypeRefs';

export function getColumns(
  t: TFunction,
  allowedToEdit: (row: IOfflineServiceTypeRefsDto) => boolean,
  user: IAuthenticatedUser | undefined,
  useSidePanel?: boolean,
  openSidePanel?: (state: SidePanelOpenState) => void,
  onCloseRefs?: () => void,
) {
  if (user === undefined) {
    return [];
  }

  const result: Column<IOfflineServiceTypeRefsDto>[] = [
    {
      Header: t(translations.HashSymbol) as string,
      accessor: 'Id',
      Cell: ({ value, row }) =>
        allowedToEdit(row.original) ? (
          <OfflineTypeRefsLink
            openPanelWithCover={openSidePanel}
            useSidePanel={useSidePanel}
            id={value}
            onCloseRefs={onCloseRefs}
          >
            {value}
          </OfflineTypeRefsLink>
        ) : (
          <>{value}</>
        ),
    },
    {
      Header: t(translations.RelatedService) as string,
      accessor: 'Name',
      Cell: ({ value, row }) => {
        let serviceId =
          row.original.RefTypeId ===
          OfflineReferenceTypeEnum.Offline_ServiceRequest
            ? row.original.SampleRequestTypeId ?? 0
            : row.original.OnlineServiceTypeId ?? 0;
        let serviceTypeId: ServiceTypeUnion =
          row.original.RefTypeId ===
          OfflineReferenceTypeEnum.Offline_ServiceRequest
            ? 'Sample'
            : 'Online';
        return (
          <AssetLink
            serviceId={serviceId}
            serviceType={serviceTypeId}
            useSidePanel={useSidePanel}
            openPanelWithCover={openSidePanel}
          >
            {value}
          </AssetLink>
        );
      },
    },
    {
      Header: t(translations.OfflineServiceType) as string,
      accessor: 'OfflineServiceTypeName',
      Cell: ({ value, row }) => {
        return (
          <AssetLink
            serviceId={row.original.OfflineServiceTypeId}
            serviceType={'Offline'}
            useSidePanel={useSidePanel}
            openPanelWithCover={openSidePanel}
          >
            {value}
          </AssetLink>
        );
      },
    },

    {
      Header: t(translations.ReferenceType) as string,
      accessor: 'RefTypeId',
      Cell: ({ value, row }) => {
        let name =
          getSingleEnumEntityById(
            row.original.RefTypeId,
            OfflineReferenceTypeEnum,
            t,
          )?.Name ?? '';
        return <> {`Service Related to ${name}`} </>;
      },
    },
    {
      Header: t(translations.MandatoryOfflineRef) as string,
      accessor: 'Mandatory',
      Cell: ({ value, row }) => {
        let name =
          getSingleEnumEntityById(
            row.original.Mandatory,
            MandatoryOfflineTypeEnum,
            t,
          )?.Name ?? '';
        return <> {name} </>;
      },
    },
    {
      Header: t(translations.DefaultQuantity) as string,
      accessor: 'DefaultQuantity',
      Cell: ({ value }) => {
        if (!!value && value !== null)
          return <> {roundTwoPlacesAfterDecimalPoint(value)} </>;
        return <></>;
      },
    },
  ];
  return result;
}
