import {
  IconLookup,
  IconPrefix,
  library,
} from '@fortawesome/fontawesome-svg-core';
/*
It is important to declare icons in the following way:
import { faName } from '@fortawesome/pro-solid-svg-icons/faName';
This prevents the entire icon pack from loading and only loads one specific icon.
*/
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faSignOutAlt } from '@fortawesome/pro-solid-svg-icons/faSignOutAlt';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons/faLayerGroup';
import { faBookmark } from '@fortawesome/pro-solid-svg-icons/faBookmark';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import { faDumbbell } from '@fortawesome/pro-solid-svg-icons/faDumbbell';
import { faCommentAltDots } from '@fortawesome/pro-solid-svg-icons/faCommentAltDots';
import { faBolt } from '@fortawesome/pro-solid-svg-icons/faBolt';
import { faListUl } from '@fortawesome/pro-solid-svg-icons/faListUl';
import { faChartBar } from '@fortawesome/pro-solid-svg-icons/faChartBar';
import { faChartColumn } from '@fortawesome/pro-solid-svg-icons/faChartColumn';
import { faChartLine } from '@fortawesome/pro-solid-svg-icons/faChartLine';
import { faChartPie } from '@fortawesome/pro-solid-svg-icons/faChartPie';
import { faChartPieAlt } from '@fortawesome/pro-solid-svg-icons/faChartPieAlt';
import { faMegaphone } from '@fortawesome/pro-solid-svg-icons/faMegaphone';
import { faFileInvoiceDollar } from '@fortawesome/pro-solid-svg-icons/faFileInvoiceDollar';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faCircle as farCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons/faCircleSmall';
import { faCircleSmall as farCircleSmall } from '@fortawesome/pro-regular-svg-icons/faCircleSmall';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faPrint } from '@fortawesome/pro-solid-svg-icons/faPrint';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faChevronDoubleUp } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleUp';
import { faChevronDoubleDown } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleDown';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCheckDouble } from '@fortawesome/pro-solid-svg-icons/faCheckDouble';
import { faInfo } from '@fortawesome/pro-solid-svg-icons/faInfo';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faTable } from '@fortawesome/pro-solid-svg-icons/faTable';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faFileImport } from '@fortawesome/pro-solid-svg-icons/faFileImport';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe';
import { faLockOpen } from '@fortawesome/pro-solid-svg-icons/faLockOpen';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons/faGripVertical';
import { faBellSlash } from '@fortawesome/pro-solid-svg-icons/faBellSlash';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faTools } from '@fortawesome/pro-solid-svg-icons/faTools';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faHistory } from '@fortawesome/pro-solid-svg-icons/faHistory';
import { faAdd } from '@fortawesome/pro-solid-svg-icons/faAdd';
import { faStream } from '@fortawesome/pro-solid-svg-icons/faStream';
import { faRulerTriangle } from '@fortawesome/pro-solid-svg-icons/faRulerTriangle';
import { faThList } from '@fortawesome/pro-solid-svg-icons/faThList';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencilAlt';
import { faCalendarPlus } from '@fortawesome/pro-solid-svg-icons/faCalendarPlus';
import { faPause } from '@fortawesome/pro-solid-svg-icons/faPause';
import { faSave } from '@fortawesome/pro-solid-svg-icons/faSave';
import { faCalendars } from '@fortawesome/pro-solid-svg-icons/faCalendars';
import { faListTimeline } from '@fortawesome/pro-solid-svg-icons/faListTimeline';
import { faCode } from '@fortawesome/pro-solid-svg-icons/faCode';
import { faBan } from '@fortawesome/pro-solid-svg-icons/faBan';
import { faFloppyDiskCircleArrowRight } from '@fortawesome/pro-solid-svg-icons/faFloppyDiskCircleArrowRight';
import { faCalculator } from '@fortawesome/pro-solid-svg-icons/faCalculator';
import { faRefresh } from '@fortawesome/pro-solid-svg-icons/faRefresh';
import { faSquare } from '@fortawesome/pro-solid-svg-icons/faSquare';
import { faClone } from '@fortawesome/pro-solid-svg-icons/faClone';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faClipboardList } from '@fortawesome/pro-solid-svg-icons/faClipboardList';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleRight';
import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleLeft';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronsLeft } from '@fortawesome/pro-solid-svg-icons/faChevronsLeft';
import { faChevronsRight } from '@fortawesome/pro-solid-svg-icons/faChevronsRight';
import { faList } from '@fortawesome/pro-solid-svg-icons/faList';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faGripHorizontal } from '@fortawesome/pro-solid-svg-icons/faGripHorizontal';
import { faArrowFromLeft } from '@fortawesome/pro-solid-svg-icons/faArrowFromLeft';
import { faVial } from '@fortawesome/pro-solid-svg-icons/faVial';
import { faStopwatch } from '@fortawesome/pro-solid-svg-icons/faStopwatch';
import { faWrench } from '@fortawesome/pro-solid-svg-icons/faWrench';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons/faExternalLinkAlt';
import { faClose } from '@fortawesome/pro-solid-svg-icons/faClose';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { faBold } from '@fortawesome/pro-solid-svg-icons/faBold';
import { faItalic } from '@fortawesome/pro-solid-svg-icons/faItalic';
import { faUnderline } from '@fortawesome/pro-solid-svg-icons/faUnderline';
import { faH1 } from '@fortawesome/pro-solid-svg-icons/faH1';
import { faH2 } from '@fortawesome/pro-solid-svg-icons/faH2';
import { faQuoteRight } from '@fortawesome/pro-solid-svg-icons/faQuoteRight';
import { faListOl } from '@fortawesome/pro-solid-svg-icons/faListOl';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import { faAt } from '@fortawesome/pro-solid-svg-icons/faAt';
import { faBarcode } from '@fortawesome/pro-solid-svg-icons/faBarcode';
import { faChain } from '@fortawesome/pro-solid-svg-icons/faChain';
import { faPowerOff } from '@fortawesome/pro-solid-svg-icons/faPowerOff';
import { faPlug } from '@fortawesome/pro-solid-svg-icons/faPlug';
import { faMicroscope } from '@fortawesome/pro-solid-svg-icons/faMicroscope';
import { faCalendarCheck } from '@fortawesome/pro-solid-svg-icons/faCalendarCheck';
import { faCalendarExclamation } from '@fortawesome/pro-solid-svg-icons/faCalendarExclamation';
import { faCalendarTimes } from '@fortawesome/pro-solid-svg-icons/faCalendarTimes';
import { faFileUpload } from '@fortawesome/pro-solid-svg-icons/faFileUpload';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faMoneyCheckEdit } from '@fortawesome/pro-solid-svg-icons/faMoneyCheckEdit';
import { faLevelUpAlt } from '@fortawesome/pro-solid-svg-icons/faLevelUpAlt';
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons/faPaperclip';
import { faAbacus } from '@fortawesome/pro-solid-svg-icons/faAbacus';
import { faAlbum } from '@fortawesome/pro-solid-svg-icons/faAlbum';
import { faFile } from '@fortawesome/pro-solid-svg-icons/faFile';
import { faSync } from '@fortawesome/pro-solid-svg-icons/faSync';
import { faRectangleVerticalHistory } from '@fortawesome/pro-solid-svg-icons/faRectangleVerticalHistory';
import { faUniversity } from '@fortawesome/pro-solid-svg-icons/faUniversity';
import { faShopSlash } from '@fortawesome/pro-solid-svg-icons/faShopSlash';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign';
import { faExchange } from '@fortawesome/pro-solid-svg-icons/faExchange';
import { faClipboardListCheck } from '@fortawesome/pro-solid-svg-icons/faClipboardListCheck';
import { faSms } from '@fortawesome/pro-solid-svg-icons/faSms';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import { faUserShield } from '@fortawesome/pro-solid-svg-icons/faUserShield';
import { faWarning } from '@fortawesome/pro-solid-svg-icons/faWarning';
import { faLocationCrosshairs } from '@fortawesome/pro-solid-svg-icons/faLocationCrosshairs';
import { faCircleDollarToSlot } from '@fortawesome/pro-solid-svg-icons/faCircleDollarToSlot';
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { faCodeBranch } from '@fortawesome/pro-solid-svg-icons/faCodeBranch';
import { faChevronsUp } from '@fortawesome/pro-solid-svg-icons/faChevronsUp';
import { faChevronsDown } from '@fortawesome/pro-solid-svg-icons/faChevronsDown';
import { faEquals } from '@fortawesome/pro-solid-svg-icons/faEquals';
import { faFileSpreadsheet } from '@fortawesome/pro-solid-svg-icons/faFileSpreadsheet';
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons/faFilePdf';
import { faFileImage } from '@fortawesome/pro-solid-svg-icons/faFileImage';

import { faCircleCheck as falCircleCheck } from '@fortawesome/pro-light-svg-icons/faCircleCheck';
import { faTriangleExclamation as falTriangleExclamation } from '@fortawesome/pro-light-svg-icons/faTriangleExclamation';
import { faCircleQuarters as falCircleQuarters } from '@fortawesome/pro-light-svg-icons/faCircleQuarters';
import { faCircleExclamation as falCircleExclamation } from '@fortawesome/pro-light-svg-icons/faCircleExclamation';
import { faMinimize as falMinimize } from '@fortawesome/pro-light-svg-icons/faMinimize';
import { faMaximize as falMaximize } from '@fortawesome/pro-light-svg-icons/faMaximize';
import { faTable as falTable } from '@fortawesome/pro-light-svg-icons/faTable';
import { faCalendars as falCalendars } from '@fortawesome/pro-light-svg-icons/faCalendars';
import { faPencil as falPencil } from '@fortawesome/pro-light-svg-icons/faPencil';

import { faTelescope as fadTelescope } from '@fortawesome/pro-duotone-svg-icons/faTelescope';
import { faInfinity as fadInfinity } from '@fortawesome/pro-duotone-svg-icons/faInfinity';

import { faCalendarCheck as fadCalendarCheck } from '@fortawesome/pro-duotone-svg-icons/faCalendarCheck';
import { faCalendarExclamation as fadCalendarExclamation } from '@fortawesome/pro-duotone-svg-icons/faCalendarExclamation';
import { faCalendarTimes as fadCalendarTimes } from '@fortawesome/pro-duotone-svg-icons/faCalendarTimes';
import { faCalendarPlus as fadCalendarPlus } from '@fortawesome/pro-duotone-svg-icons/faCalendarPlus';

import { faMaximize as farMaximize } from '@fortawesome/pro-regular-svg-icons/faMaximize';
import { faCommentQuestion as farCommentQuestion } from '@fortawesome/pro-regular-svg-icons/faCommentQuestion';
import { faCircleExclamation as farcircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation';
import { faCircleCheck as farCircleCheck } from '@fortawesome/pro-regular-svg-icons/faCircleCheck';
import { faPencil as farPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faFileAlt as farFileAlt } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faEye as farEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faArrowsToEye as farArrowsToEye } from '@fortawesome/pro-regular-svg-icons/faArrowsToEye';

import { faGoogle as fabGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faWindows as fabWindows } from '@fortawesome/free-brands-svg-icons/faWindows';
import { faNewspaper } from '@fortawesome/pro-solid-svg-icons/faNewspaper';
import { faFlag } from '@fortawesome/pro-solid-svg-icons/faFlag';
import { faBarcodeScan } from '@fortawesome/pro-solid-svg-icons/faBarcodeScan';
import { faMap } from '@fortawesome/pro-solid-svg-icons/faMap';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons/faMagnifyingGlass';
import { faGrid2 } from '@fortawesome/pro-solid-svg-icons/faGrid2';
import { faScrewdriver } from '@fortawesome/pro-solid-svg-icons/faScrewdriver';
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons/faBullhorn';
import { faMoneyBill } from '@fortawesome/pro-solid-svg-icons/faMoneyBill';
import { faFileArrowUp } from '@fortawesome/pro-solid-svg-icons/faFileArrowUp';
import { faEarthEurope } from '@fortawesome/pro-solid-svg-icons/faEarthEurope';
import { faShare } from '@fortawesome/pro-solid-svg-icons/faShare';
import { faScannerKeyboard } from '@fortawesome/pro-solid-svg-icons/faScannerKeyboard';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch';
import { faMapLocation } from '@fortawesome/pro-solid-svg-icons/faMapLocation';
import { faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons/faScrewdriverWrench';
import { faBandage } from '@fortawesome/pro-solid-svg-icons/faBandage';
import { faHourglassHalf } from '@fortawesome/pro-solid-svg-icons/faHourglassHalf';
import { faInfinity as fasInfinity } from '@fortawesome/pro-solid-svg-icons/faInfinity';
import { faGear } from '@fortawesome/pro-solid-svg-icons/faGear';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowUp';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown';
import { faSigma } from '@fortawesome/pro-solid-svg-icons/faSigma';
import { faSquareMinus } from '@fortawesome/pro-solid-svg-icons/faSquareMinus';
import { faSquarePlus } from '@fortawesome/pro-solid-svg-icons/faSquarePlus';
import { faArrowsToEye } from '@fortawesome/pro-solid-svg-icons/faArrowsToEye';
import { faSquareChevronUp } from '@fortawesome/pro-solid-svg-icons/faSquareChevronUp';
import { faToggleOn } from '@fortawesome/pro-solid-svg-icons/faToggleOn';
import { faToggleOff } from '@fortawesome/pro-solid-svg-icons/faToggleOff';
import { faToggleLargeOn } from '@fortawesome/pro-solid-svg-icons/faToggleLargeOn';
import { faToggleLargeOff } from '@fortawesome/pro-solid-svg-icons/faToggleLargeOff';
import { faToggleOn as farToggleOn } from '@fortawesome/pro-regular-svg-icons/faToggleOn';
import { faToggleOff as farToggleOff } from '@fortawesome/pro-regular-svg-icons/faToggleOff';
import { faToggleLargeOn as farToggleLargeOn } from '@fortawesome/pro-regular-svg-icons/faToggleLargeOn';
import { faToggleLargeOff as farToggleLargeOff } from '@fortawesome/pro-regular-svg-icons/faToggleLargeOff';
import { faSquareChevronUp as farSquareChevronUp } from '@fortawesome/pro-regular-svg-icons/faSquareChevronUp';
import { faCircleDashed } from '@fortawesome/pro-solid-svg-icons/faCircleDashed';
import { faCircleDashed as farCircleDashed } from '@fortawesome/pro-regular-svg-icons/faCircleDashed';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { Entity } from 'types/common';

export type IconName =
  | 'circle-user'
  | 'user-circle'
  | 'chevron-up'
  | 'pencil'
  | 'lock'
  | 'users'
  | 'bell'
  | 'question-circle'
  | 'sign-out-alt'
  | 'home'
  | 'layer-group'
  | 'bookmark'
  | 'calendar-alt'
  | 'dumbbell'
  | 'comment-alt-dots'
  | 'bolt'
  | 'list-ul'
  | 'chart-bar'
  | 'chart-column'
  | 'chart-line'
  | 'chart-pie'
  | 'chart-pie-alt'
  | 'megaphone'
  | 'file-invoice-dollar'
  | 'cog'
  | 'times'
  | 'circle'
  | 'circle-small'
  | 'circle-check'
  | 'print'
  | 'search'
  | 'chevron-double-up'
  | 'chevron-double-down'
  | 'check'
  | 'check-double'
  | 'info'
  | 'triangle-exclamation'
  | 'xmark'
  | 'chevron-down'
  | 'circle-xmark'
  | 'ellipsis-v'
  | 'plus'
  | 'filter'
  | 'table'
  | 'minus'
  | 'file-import'
  | 'globe'
  | 'lock-open'
  | 'edit'
  | 'trash'
  | 'info-circle'
  | 'grip-vertical'
  | 'bell-slash'
  | 'exclamation-circle'
  | 'times-circle'
  | 'arrow-left'
  | 'tools'
  | 'play'
  | 'history'
  | 'add'
  | 'stream'
  | 'ruler-triangle'
  | 'th-list'
  | 'clipboard-list-check'
  | 'pencil-alt'
  | 'calendar-plus'
  | 'pause'
  | 'save'
  | 'calendars'
  | 'list-timeline'
  | 'code'
  | 'ban'
  | 'floppy-disk-circle-arrow-right'
  | 'calculator'
  | 'refresh'
  | 'square'
  | 'clone'
  | 'clock'
  | 'chevron-right'
  | 'clipboard-list'
  | 'envelope'
  | 'calendar'
  | 'check-circle'
  | 'link'
  | 'chevron-double-right'
  | 'chevron-double-left'
  | 'chevron-left'
  | 'chevrons-left'
  | 'chevrons-right'
  | 'list'
  | 'user'
  | 'grip-horizontal'
  | 'arrow-from-left'
  | 'vial'
  | 'stopwatch'
  | 'wrench'
  | 'arrow-up-right-from-square'
  | 'external-link'
  | 'external-link-alt'
  | 'close'
  | 'download'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'h1'
  | 'h2'
  | 'quote-right'
  | 'list-ol'
  | 'file-alt'
  | 'at'
  | 'barcode'
  | 'chain'
  | 'power-off'
  | 'plug'
  | 'microscope'
  | 'calendar-check'
  | 'calendar-exclamation'
  | 'calendar-times'
  | 'file-upload'
  | 'spinner'
  | 'money-check-edit'
  | 'level-up-alt'
  | 'paperclip'
  | 'abacus'
  | 'album'
  | 'file'
  | 'sync'
  | 'rectangle-vertical-history'
  | 'university'
  | 'shop-slash'
  | 'dollar-sign'
  | 'exchange'
  | 'sms'
  | 'circle-check'
  | 'triangle-exclamation'
  | 'circle-quarters'
  | 'circle-exclamation'
  | 'minimize'
  | 'maximize'
  | 'table'
  | 'calendars'
  | 'telescope'
  | 'infinity'
  | 'calendar-check'
  | 'calendar-exclamation'
  | 'calendar-times'
  | 'calendar-plus'
  | 'maximize'
  | 'comment-question'
  | 'circle-exclamation'
  | 'circle-check'
  | 'google'
  | 'user-plus'
  | 'user-shield'
  | 'warning'
  | 'windows'
  | 'location-crosshairs'
  | 'circle-dollar-to-slot'
  | 'code-branch'
  | 'copy'
  | 'chevrons-up'
  | 'chevrons-down'
  | 'equals'
  | 'file-spreadsheet'
  | 'file-pdf'
  | 'newspaper'
  | 'file-image'
  | 'flag'
  | 'barcode-scan'
  | 'map'
  | 'bars'
  | 'magnifying-glass'
  | 'grid-2'
  | 'screwdriver'
  | 'bullhorn'
  | 'money-bill'
  | 'file-arrow-up'
  | 'earth-europe'
  | 'share'
  | 'scanner-keyboard'
  | 'circle-notch'
  | 'map-location'
  | 'screwdriver-wrench'
  | 'bandage'
  | 'hourglass-half'
  | 'gear'
  | 'arrow-up'
  | 'arrow-down'
  | 'sigma'
  | 'square-minus'
  | 'square-plus'
  | 'arrows-to-eye'
  | 'square-chevron-up'
  | 'eye'
  | 'toggle-on'
  | 'toggle-off'
  | 'toggle-large-on'
  | 'toggle-large-off'
  | 'circle-dashed';

//library.add(fas, fad, far, fal, faGoogle, faWindows);
library.add(
  //fas
  faUserCircle,
  faChevronUp,
  faPencil,
  faLock,
  faUsers,
  faBell,
  faQuestionCircle,
  faSignOutAlt,
  faHome,
  faLayerGroup,
  faBookmark,
  faCalendarAlt,
  faDumbbell,
  faCommentAltDots,
  faBolt,
  faListUl,
  faChartBar,
  faChartColumn,
  faChartLine,
  faChartPie,
  faChartPieAlt,
  faMegaphone,
  faFileInvoiceDollar,
  faCog,
  faTimes,
  faCircle,
  faCircleSmall,
  faCircleCheck,
  faPrint,
  faSearch,
  faChevronDoubleUp,
  faChevronDoubleDown,
  faCheck,
  faCheckDouble,
  faInfo,
  faTriangleExclamation,
  faXmark,
  faChevronDown,
  faCircleXmark,
  faEllipsisV,
  faPlus,
  faFilter,
  faTable,
  faMinus,
  faFileImport,
  faGlobe,
  faLockOpen,
  faEdit,
  faTrash,
  faInfoCircle,
  faGripVertical,
  faBellSlash,
  faExclamationCircle,
  faTimesCircle,
  faArrowLeft,
  faTools,
  faPlay,
  faHistory,
  faAdd,
  faStream,
  faRulerTriangle,
  faThList,
  faClipboardListCheck,
  faPencilAlt,
  faCalendarPlus,
  faPause,
  faSave,
  faCalendars,
  faListTimeline,
  faCode,
  faBan,
  faFloppyDiskCircleArrowRight,
  faCalculator,
  faRefresh,
  faSquare,
  faClone,
  faClock,
  faChevronRight,
  faClipboardList,
  faEnvelope,
  faCalendar,
  faCheckCircle,
  faLink,
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faChevronLeft,
  faList,
  faUser,
  faGripHorizontal,
  faArrowFromLeft,
  faVial,
  faStopwatch,
  faWrench,
  faArrowUpRightFromSquare,
  faExternalLink,
  faExternalLinkAlt,
  faClose,
  faDownload,
  faBold,
  faItalic,
  faUnderline,
  faH1,
  faH2,
  faQuoteRight,
  faListOl,
  faFileAlt,
  faAt,
  faBarcode,
  faChain,
  faPowerOff,
  faPlug,
  faMicroscope,
  faCalendarCheck,
  faCalendarExclamation,
  faCalendarTimes,
  faFileUpload,
  faSpinner,
  faMoneyCheckEdit,
  faLevelUpAlt,
  faPaperclip,
  faAbacus,
  faAlbum,
  faFile,
  faSync,
  faRectangleVerticalHistory,
  faUniversity,
  faShopSlash,
  faDollarSign,
  faExchange,
  faSms,
  faUserPlus,
  faUserShield,
  faWarning,
  faLocationCrosshairs,
  faCircleDollarToSlot,
  faCopy,
  faCodeBranch,
  faChevronsUp,
  faChevronsDown,
  faEquals,
  faFileSpreadsheet,
  faFilePdf,
  faNewspaper,
  faFileImage,
  faFlag,
  faBarcodeScan,
  faMap,
  faBars,
  faMagnifyingGlass,
  faGrid2,
  faScrewdriver,
  faBullhorn,
  faMoneyBill,
  faFileArrowUp,
  faEarthEurope,
  faScannerKeyboard,
  faChevronsLeft,
  faChevronsRight,
  faCircleNotch,
  faMapLocation,
  faScrewdriverWrench,
  faBandage,
  faHourglassHalf,
  fasInfinity,
  faSquareMinus,
  faSquarePlus,
  faArrowsToEye,
  faSquareChevronUp,
  faToggleOn,
  faToggleOff,
  faToggleLargeOn,
  faToggleLargeOff,
  faCircleDashed,
  //fal
  falCircleCheck,
  falTriangleExclamation,
  falCircleQuarters,
  falCircleExclamation,
  falMinimize,
  falMaximize,
  falTable,
  falCalendars,
  falPencil,
  //fad
  fadTelescope,
  fadInfinity,
  fadCalendarCheck,
  fadCalendarExclamation,
  fadCalendarTimes,
  fadCalendarPlus,
  //far
  farMaximize,
  farCommentQuestion,
  farcircleExclamation,
  farCircleCheck,
  farCircle,
  farCircleSmall,
  farSquareChevronUp,
  farToggleOn,
  farToggleOff,
  farToggleLargeOn,
  farToggleLargeOff,
  farPencil,
  farFileAlt,
  farEye,
  farArrowsToEye,
  farCircleDashed,
  //fab
  fabGoogle,
  fabWindows,
  faShare,
  faGear,
  faArrowUp,
  faArrowDown,
  faSigma,
  faEye,
);
export type IconProp = IconName | [IconPrefix, IconName] | IconLookup;

export const IconNames: string[] = [
  'circle-user',
  'user-circle',
  'chevron-up',
  'pencil',
  'lock',
  'users',
  'bell',
  'question-circle',
  'sign-out-alt',
  'home',
  'layer-group',
  'bookmark',
  'calendar-alt',
  'dumbbell',
  'comment-alt-dots',
  'bolt',
  'list-ul',
  'chart-bar',
  'chart-column',
  'chart-line',
  'chart-pie',
  'chart-pie-alt',
  'megaphone',
  'file-invoice-dollar',
  'cog',
  'times',
  'circle',
  'circle-small',
  'circle-check',
  'print',
  'search',
  'chevron-double-up',
  'chevron-double-down',
  'check',
  'check-double',
  'info',
  'triangle-exclamation',
  'xmark',
  'chevron-down',
  'circle-xmark',
  'ellipsis-v',
  'plus',
  'filter',
  'table',
  'minus',
  'file-import',
  'globe',
  'lock-open',
  'edit',
  'trash',
  'info-circle',
  'grip-vertical',
  'bell-slash',
  'exclamation-circle',
  'times-circle',
  'arrow-left',
  'tools',
  'play',
  'history',
  'add',
  'stream',
  'ruler-triangle',
  'th-list',
  'clipboard-list-check',
  'pencil-alt',
  'calendar-plus',
  'pause',
  'save',
  'calendars',
  'list-timeline',
  'code',
  'ban',
  'floppy-disk-circle-arrow-right',
  'calculator',
  'refresh',
  'square',
  'clone',
  'clock',
  'chevron-right',
  'chevrons-right',
  'clipboard-list',
  'envelope',
  'calendar',
  'check-circle',
  'link',
  'chevron-double-right',
  'chevron-double-left',
  'chevron-left',
  'chevrons-left',
  'list',
  'user',
  'grip-horizontal',
  'arrow-from-left',
  'vial',
  'stopwatch',
  'wrench',
  'arrow-up-right-from-square',
  'external-link',
  'external-link-alt',
  'close',
  'download',
  'bold',
  'italic',
  'underline',
  'h1',
  'h2',
  'quote-right',
  'list-ol',
  'file-alt',
  'at',
  'barcode',
  'chain',
  'power-off',
  'plug',
  'microscope',
  'calendar-check',
  'calendar-exclamation',
  'calendar-times',
  'file-upload',
  'spinner',
  'money-check-edit',
  'level-up-alt',
  'paperclip',
  'abacus',
  'album',
  'file',
  'sync',
  'rectangle-vertical-history',
  'university',
  'shop-slash',
  'dollar-sign',
  'exchange',
  'sms',
  'circle-check',
  'triangle-exclamation',
  'circle-quarters',
  'circle-exclamation',
  'minimize',
  'maximize',
  'table',
  'calendars',
  'telescope',
  'infinity',
  'calendar-check',
  'calendar-exclamation',
  'calendar-times',
  'calendar-plus',
  'maximize',
  'comment-question',
  'circle-exclamation',
  'circle-check',
  'google',
  'user-plus',
  'user-shield',
  'warning',
  'windows',
  'location-crosshairs',
  'circle-dollar-to-slot',
  'code-branch',
  'copy',
  'chevrons-up',
  'chevrons-down',
  'equals',
  'file-spreadsheet',
  'file-pdf',
  'newspaper',
  'flag',
  'barcode-scan',
  'map',
  'bars',
  'magnifying-glass',
  'grid-2',
  'screwdriver',
  'bullhorn',
  'money-bill',
  'file-arrow-up',
  'earth-europe',
  'share',
  'scanner-keyboard',
  'circle-notch',
  'map-location',
  'screwdriver-wrench',
  'bandage',
  'hourglass-half',
  'square-minus',
  'square-plus',
  'arrows-to-eye',
  'square-chevron-up',
  'eye',
  'toggle-on',
  'toggle-off',
  'toggle-large-on',
  'toggle-large-off',
  'circle-dashed',
];
export const getIconNames = (): Entity<string>[] => {
  const iconst = IconNames.map(icon => {
    return {
      Id: icon
        .split('-')
        .map((item, i) => {
          if (i === 0) {
            return item.toLowerCase();
          }
          return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
        })
        .join(''),
      Name: icon,
    };
  });
  return iconst;
};
export const getIconNamesObject = () => {
  const iconst = IconNames.map(icon => {
    return {
      [icon
        .split('-')
        .map((item, i) => {
          if (i === 0) {
            return item.toLowerCase();
          }
          return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
        })
        .join('')]: icon,
    };
  });
  return iconst;
};
