import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { translations } from 'locales/translations';
import i18next from 'i18next';
import { Formik } from 'formik';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { RequestStatusesDetailsState } from '../slice/types';
import { FormTextField } from 'app/components/Forms/FormTextField';

export interface RequestStatusesFormProps {
  initialValues: RequestStatusesDetailsState;
  onSubmit: (item: RequestStatusesDetailsState) => void;
  isEdit: boolean;
  isAdmin: boolean;
  bindSubmitForm: any;
}

export const RequestStatusesForm = React.memo(function RequestStatusesForm({
  initialValues,
  onSubmit,
  isEdit,
  isAdmin,
  bindSubmitForm,
}: RequestStatusesFormProps): JSX.Element {
  //SETTINGS
  const { t } = useTranslation();

  //FUNCTIONS
  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(value);
    }
  };

  //SCHEMA
  const requestStatusesDetailsSchema: Yup.SchemaOf<RequestStatusesDetailsState> =
    Yup.object({
      Id: Yup.number().notRequired().default(-1),
      Name: Yup.string()
        .default('')
        .nullable()
        .required()
        .label(i18next.t(translations.Name)),
    });

  //RENDER
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={requestStatusesDetailsSchema}
        validateOnMount={true}
        validateOnBlur={false}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          console.debug('Submitting: ', values);
          formikHelpers.setSubmitting(true);
          handleSubmit(values);
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            //console.log('Request Statuses Details Errors: ', formik.errors);
          }
          return (
            <>
              <StyledForm onSubmit={formik.handleSubmit}>
                <FormLeftSection>
                  <FormFieldsSection
                    titleSection={
                      t(translations.RequestStatusDetails) as string
                    }
                  >
                    <FormRow>
                      <FormTextField
                        id="NameID"
                        name="Name"
                        label={t(translations.Name)}
                        placeholder={t(translations.Name)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                  </FormFieldsSection>
                </FormLeftSection>
              </StyledForm>
            </>
          );
        }}
      </Formik>
    </>
  );
});
