//base colors
export const base_black_c11 = '#121926';
export const base_white_c0 = '#FFFFFF';
//grayscale colors
export const grayscale_black_regular_c10 = '#202939';
export const grayscale_black_components_c9 = '#364152';
export const grayscale_gray_subtitles_c8 = '#4B5565';
export const grayscale_gray_components_c7 = '#697586';
export const grayscale_gray_components_light_c6 = '#9AA3B2';
export const grayscale_gray_borders_c5 = '#CDD5DF';
export const grayscale_gray_selection_c4 = '#E3E8EF';
export const grayscale_gray_bg_components_c3 = '#EEF2F6';
export const grayscale_gray_background_c2 = '#F8FAFC';
export const grayscale_gray_bg_light_c1 = '#FCFCFD';

//opacity colors
export const overlay_opacity_black = 'rgba(0, 5, 10, 0.70)';
export const overlay_opacity_white = 'rgba(255, 255, 255, 0.95)';

//primary colors
export const primary_dark_text_c10 = '#0B4A6F';
export const primary_hover_c9 = '#065986';
export const primary_regular_c8 = '#026AA2';
export const primary_components_c7 = '#0086C9';
export const primary_components_light_c6 = '#0BA5EC';
export const primary_borders_c5 = '#36BFFA';
export const primary_disabled_c4 = '#C5EAFE';
export const primary_bg_hover_c3 = '#E0F2FE';
export const primary_background_c2 = '#F0F9FF';
export const primary_bg_light_c1 = '#F5FBFF';

//secondary colors
export const secondary_violet_dark_text_c10 = '#283493';
export const secondary_violet_hover_c9 = '#404FB7';
export const secondary_violet_regular_c8 = '#5C6FDB';
export const secondary_violet_components_c7 = '#7F94FF';
export const secondary_violet_components_light_c6 = '#9FB0FF';
export const secondary_violet_borders_c5 = '#B2C1FF';
export const secondary_violet_disabled_c4 = '#CBD6FF';
export const secondary_violet_bg_hover_c3 = '#E5EBFF';
export const secondary_violet_background_c2 = '#F2F5FF';
export const secondary_violet_bg_light_c1 = '#F9FAFF';

//danger colors
export const semantic_danger_dark_text_c10 = '#841444';
export const semantic_danger_hover_c9 = '#A41F4C';
export const semantic_danger_regular_c8 = '#C42E52';
export const semantic_danger_components_c7 = '#EF6D74';
export const semantic_danger_components_light_c6 = '#EF6D74';
export const semantic_danger_borders_c5 = '#F78F8B';
export const semantic_danger_disabled_c4 = '#FCBCB3';
export const semantic_danger_bg_hover_c3 = '#FDE1D8';
export const semantic_danger_background_c2 = '#FEF0EC';
export const semantic_danger_bg_light_c1 = '#FFF8F5';

//success colors
export const semantic_success_dark_text_c10 = '#076F4F';
export const semantic_success_hover_c9 = '#0C8654';
export const semantic_success_regular_c8 = '#13A75C';
export const semantic_success_components_c7 = '#1CC760';
export const semantic_success_components_light_c6 = '#27E861';
export const semantic_success_borders_c5 = '#7CF889';
export const semantic_success_disabled_c4 = '#A8FCA9';
export const semantic_success_bg_hover_c3 = '#D7FDD3';
export const semantic_success_background_c2 = '#EBFEE9';
export const semantic_success_bg_light_c1 = '#F5FFF4';

//alert(warning) colors
export const semantic_alert_dark_text_c10 = '#7F5000';
export const semantic_alert_hover_c9 = '#9D6800';
export const semantic_alert_regular_c8 = '#BC8200';
export const semantic_alert_components_c7 = '#DB9E00';
export const semantic_alert_components_light_c6 = '#E9BD3A';
export const semantic_alert_borders_c5 = '#F4D361';
export const semantic_alert_disabled_c4 = '#FBE896';
export const semantic_alert_bg_hover_c3 = '#FDF4CA';
export const semantic_alert_background_c2 = '#FEFAE4';
export const semantic_alert_bg_light_c1 = '#FFFCF2';

export const bookitColors = {
  base: {
    black: base_black_c11,
    white: base_white_c0,
  },
  grayscale: {
    blackRegular: grayscale_black_regular_c10,
    blackComponents: grayscale_black_components_c9,
    graySubtitles: grayscale_gray_subtitles_c8,
    grayComponents: grayscale_gray_components_c7,
    grayComponentsLight: grayscale_gray_components_light_c6,
    grayBorders: grayscale_gray_borders_c5,
    graySelection: grayscale_gray_selection_c4,
    graybgComponents: grayscale_gray_bg_components_c3,
    grayBackground: grayscale_gray_background_c2,
    grayBackgroundLight: grayscale_gray_bg_light_c1,
  },
  overlays: {
    overlayBlack: overlay_opacity_black,
    overlayWhite: overlay_opacity_white,
  },
  primary: {
    dark: primary_dark_text_c10,
    hover: primary_hover_c9,
    regular: primary_regular_c8,
    components: primary_components_c7,
    componentsLight: primary_components_light_c6,
    borders: primary_borders_c5,
    disabled: primary_disabled_c4,
    bgHover: primary_bg_hover_c3,
    background: primary_background_c2,
    bgLight: primary_bg_light_c1,
  },
  secondary: {
    violet: {
      dark: secondary_violet_dark_text_c10,
      hover: secondary_violet_hover_c9,
      regular: secondary_violet_regular_c8,
      components: secondary_violet_components_c7,
      componentsLight: secondary_violet_components_light_c6,
      borders: secondary_violet_borders_c5,
      disabled: secondary_violet_disabled_c4,
      bgHover: secondary_violet_bg_hover_c3,
      background: secondary_violet_background_c2,
      bgLight: secondary_violet_bg_light_c1,
    },
  },
  danger: {
    dark: semantic_danger_dark_text_c10,
    hover: semantic_danger_hover_c9,
    regular: semantic_danger_regular_c8,
    components: semantic_danger_components_c7,
    componentsLight: semantic_danger_components_light_c6,
    borders: semantic_danger_borders_c5,
    disabled: semantic_danger_disabled_c4,
    bgHover: semantic_danger_bg_hover_c3,
    background: semantic_danger_background_c2,
    bgLight: semantic_danger_bg_light_c1,
  },
  success: {
    dark: semantic_success_dark_text_c10,
    hover: semantic_success_hover_c9,
    regular: semantic_success_regular_c8,
    components: semantic_success_components_c7,
    componentsLight: semantic_success_components_light_c6,
    borders: semantic_success_borders_c5,
    disabled: semantic_success_disabled_c4,
    bgHover: semantic_success_bg_hover_c3,
    background: semantic_success_background_c2,
    bgLight: semantic_success_bg_light_c1,
  },
  warning: {
    dark: semantic_alert_dark_text_c10,
    hover: semantic_alert_hover_c9,
    regular: semantic_alert_regular_c8,
    components: semantic_alert_components_c7,
    componentsLight: semantic_alert_components_light_c6,
    borders: semantic_alert_borders_c5,
    disabled: semantic_alert_disabled_c4,
    bgHover: semantic_alert_bg_hover_c3,
    background: semantic_alert_background_c2,
    bgLight: semantic_alert_bg_light_c1,
  },
};
export interface ColorsMeta {
  Name: string;
  SemanticMeta: ColorSemanticMeta[];
}
export interface ColorSemanticMeta {
  Name: string;
  Title: string;
  Colors: ColorDescriptionMeta[];
}
export interface ColorDescriptionMeta {
  color: string;
  colorName: string;
  colorCode: string;
  colorVariable: string;
}
export const baseColorMeta: ColorSemanticMeta = {
  Name: 'Base',
  Title:
    'Black Palette for titles, copyrighting and components. Brand black color',
  Colors: [
    {
      color: bookitColors.base.black,
      colorName: 'Black',
      colorCode: 'c11',
      colorVariable: 'base_black_c11',
    },
    {
      color: bookitColors.base.white,
      colorName: 'White',
      colorCode: 'c0',
      colorVariable: 'base_white_c0',
    },
  ],
};
export const overlaysColorMeta: ColorSemanticMeta = {
  Name: 'Overlays',
  Title:
    'Black and white overlays for popups, popovers and transparent elements',
  Colors: [
    {
      color: bookitColors.overlays.overlayBlack,
      colorName: 'Black Popup Overlay',
      colorCode: 'opacity-black',
      colorVariable: 'overlay_opacity_black',
    },
    {
      color: bookitColors.overlays.overlayWhite,
      colorName: 'White Popup Overlay',
      colorCode: 'opacity-white',
      colorVariable: 'overlay_opacity_white',
    },
  ],
};
export const grayscaleColorMeta: ColorSemanticMeta = {
  Name: 'Grayscale',
  Title:
    'Black Palette for titles, copyrighting and components. Brand black color',
  Colors: [
    {
      color: bookitColors.grayscale.blackRegular,
      colorName: 'Regular',
      colorCode: 'c10',
      colorVariable: 'grayscale_black_regular_c10',
    },
    {
      color: bookitColors.grayscale.blackComponents,
      colorName: 'Black Components',
      colorCode: 'c9',
      colorVariable: 'grayscale_black_components_c9',
    },
    {
      color: bookitColors.grayscale.graySubtitles,
      colorName: 'Gray Subtitles',
      colorCode: 'c8',
      colorVariable: 'grayscale_gray_subtitles_c8',
    },
    {
      color: bookitColors.grayscale.grayComponents,
      colorName: 'Gray Components',
      colorCode: 'c7',
      colorVariable: 'grayscale_gray_components_c7',
    },
    {
      color: bookitColors.grayscale.grayComponentsLight,
      colorName: 'Gray Components Light',
      colorCode: 'c6',
      colorVariable: 'grayscale_gray_components_light_c6',
    },
    {
      color: bookitColors.grayscale.grayBorders,
      colorName: 'Gray Borders',
      colorCode: 'c5',
      colorVariable: 'grayscale_gray_borders_c5',
    },
    {
      color: bookitColors.grayscale.graySelection,
      colorName: 'Gray Selection',
      colorCode: 'c4',
      colorVariable: 'grayscale_gray_selection_c4',
    },
    {
      color: bookitColors.grayscale.graybgComponents,
      colorName: 'Gray Bg Components',
      colorCode: 'c3',
      colorVariable: 'grayscale_gray_bg_components_c3',
    },
    {
      color: bookitColors.grayscale.grayBackground,
      colorName: 'Gray Background',
      colorCode: 'c2',
      colorVariable: 'grayscale_gray_background_c2',
    },
    {
      color: bookitColors.grayscale.grayBackgroundLight,
      colorName: 'Gray Background Light',
      colorCode: 'c1',
      colorVariable: 'grayscale_gray_bg_light_c1',
    },
  ],
};
export const primaryColorMeta: ColorSemanticMeta = {
  Name: 'Primary',
  Title: 'Primary brand blue color',
  Colors: [
    {
      color: bookitColors.primary.dark,
      colorName: 'Dark Text',
      colorCode: 'c10',
      colorVariable: 'primary_dark_text_c10',
    },
    {
      color: bookitColors.primary.hover,
      colorName: 'Hover',
      colorCode: 'c9',
      colorVariable: 'primary_hover_c9',
    },
    {
      color: bookitColors.primary.regular,
      colorName: 'Regular',
      colorCode: 'c8',
      colorVariable: 'primary_regular_c8',
    },
    {
      color: bookitColors.primary.components,
      colorName: 'Components',
      colorCode: 'c7',
      colorVariable: 'primary_components_c7',
    },
    {
      color: bookitColors.primary.componentsLight,
      colorName: 'Components Light',
      colorCode: 'c6',
      colorVariable: 'primary_components_light_c6',
    },
    {
      color: bookitColors.primary.borders,
      colorName: 'Borders',
      colorCode: 'c5',
      colorVariable: 'primary_borders_c5',
    },
    {
      color: bookitColors.primary.disabled,
      colorName: 'Disabled',
      colorCode: 'c4',
      colorVariable: 'primary_disabled_c4',
    },
    {
      color: bookitColors.primary.bgHover,
      colorName: 'Background Hover',
      colorCode: 'c3',
      colorVariable: 'primary_bg_hover_c3',
    },
    {
      color: bookitColors.primary.background,
      colorName: 'Background',
      colorCode: 'c2',
      colorVariable: 'primary_background_c2',
    },
    {
      color: bookitColors.primary.bgLight,
      colorName: 'Background Light',
      colorCode: 'c1',
      colorVariable: 'primary_bg_light_c1',
    },
  ],
};
export const successColorMeta: ColorSemanticMeta = {
  Name: 'Success',
  Title: 'Main confirmation messages',
  Colors: [
    {
      color: bookitColors.success.dark,
      colorName: 'Dark Text',
      colorCode: 'c10',
      colorVariable: 'success_dark_text_c10',
    },
    {
      color: bookitColors.success.hover,
      colorName: 'Hover',
      colorCode: 'c9',
      colorVariable: 'success_hover_c9',
    },
    {
      color: bookitColors.success.regular,
      colorName: 'Regular',
      colorCode: 'c8',
      colorVariable: 'success_regular_c8',
    },
    {
      color: bookitColors.success.components,
      colorName: 'Components',
      colorCode: 'c7',
      colorVariable: 'success_components_c7',
    },
    {
      color: bookitColors.success.componentsLight,
      colorName: 'Components Light',
      colorCode: 'c6',
      colorVariable: 'success_components_light_c6',
    },
    {
      color: bookitColors.success.borders,
      colorName: 'Borders',
      colorCode: 'c5',
      colorVariable: 'success_borders_c5',
    },
    {
      color: bookitColors.success.disabled,
      colorName: 'Disabled',
      colorCode: 'c4',
      colorVariable: 'success_disabled_c4',
    },
    {
      color: bookitColors.success.bgHover,
      colorName: 'Background Hover',
      colorCode: 'c3',
      colorVariable: 'success_bg_hover_c3',
    },
    {
      color: bookitColors.success.background,
      colorName: 'Background',
      colorCode: 'c2',
      colorVariable: 'success_background_c2',
    },
    {
      color: bookitColors.success.bgLight,
      colorName: 'Background Light',
      colorCode: 'c1',
      colorVariable: 'success_bg_light_c1',
    },
  ],
};
export const dangerColorMeta: ColorSemanticMeta = {
  Name: 'Danger',
  Title: 'For main danger buttons and indication',
  Colors: [
    {
      color: bookitColors.danger.dark,
      colorName: 'Dark Text',
      colorCode: 'c10',
      colorVariable: 'danger_dark_text_c10',
    },
    {
      color: bookitColors.danger.hover,
      colorName: 'Hover',
      colorCode: 'c9',
      colorVariable: 'danger_hover_c9',
    },
    {
      color: bookitColors.danger.regular,
      colorName: 'Regular',
      colorCode: 'c8',
      colorVariable: 'danger_regular_c8',
    },
    {
      color: bookitColors.danger.components,
      colorName: 'Components',
      colorCode: 'c7',
      colorVariable: 'danger_components_c7',
    },
    {
      color: bookitColors.danger.componentsLight,
      colorName: 'Components Light',
      colorCode: 'c6',
      colorVariable: 'danger_components_light_c6',
    },
    {
      color: bookitColors.danger.borders,
      colorName: 'Borders',
      colorCode: 'c5',
      colorVariable: 'danger_borders_c5',
    },
    {
      color: bookitColors.danger.disabled,
      colorName: 'Disabled',
      colorCode: 'c4',
      colorVariable: 'danger_disabled_c4',
    },
    {
      color: bookitColors.danger.bgHover,
      colorName: 'Background Hover',
      colorCode: 'c3',
      colorVariable: 'danger_bg_hover_c3',
    },
    {
      color: bookitColors.danger.background,
      colorName: 'Background',
      colorCode: 'c2',
      colorVariable: 'danger_background_c2',
    },
    {
      color: bookitColors.danger.bgLight,
      colorName: 'Background Light',
      colorCode: 'c1',
      colorVariable: 'danger_bg_light_c1',
    },
  ],
};
export const alertColorMeta: ColorSemanticMeta = {
  Name: 'alert',
  Title: 'For main alert buttons and indication',
  Colors: [
    {
      color: bookitColors.warning.dark,
      colorName: 'Dark Text',
      colorCode: 'c10',
      colorVariable: 'alert_dark_text_c10',
    },
    {
      color: bookitColors.warning.hover,
      colorName: 'Hover',
      colorCode: 'c9',
      colorVariable: 'alert_hover_c9',
    },
    {
      color: bookitColors.warning.regular,
      colorName: 'Regular',
      colorCode: 'c8',
      colorVariable: 'alert_regular_c8',
    },
    {
      color: bookitColors.warning.components,
      colorName: 'Components',
      colorCode: 'c7',
      colorVariable: 'alert_components_c7',
    },
    {
      color: bookitColors.warning.componentsLight,
      colorName: 'Components Light',
      colorCode: 'c6',
      colorVariable: 'alert_components_light_c6',
    },
    {
      color: bookitColors.warning.borders,
      colorName: 'Borders',
      colorCode: 'c5',
      colorVariable: 'alert_borders_c5',
    },
    {
      color: bookitColors.warning.disabled,
      colorName: 'Disabled',
      colorCode: 'c4',
      colorVariable: 'alert_disabled_c4',
    },
    {
      color: bookitColors.warning.bgHover,
      colorName: 'Background Hover',
      colorCode: 'c3',
      colorVariable: 'alert_bg_hover_c3',
    },
    {
      color: bookitColors.warning.background,
      colorName: 'Background',
      colorCode: 'c2',
      colorVariable: 'alert_background_c2',
    },
    {
      color: bookitColors.warning.bgLight,
      colorName: 'Background Light',
      colorCode: 'c1',
      colorVariable: 'alert_bg_light_c1',
    },
  ],
};
export const violetColorMeta: ColorSemanticMeta = {
  Name: 'Violet',
  Title: 'Secondary',
  Colors: [
    {
      color: bookitColors.secondary.violet.dark,
      colorName: 'Dark Text',
      colorCode: 'c10',
      colorVariable: 'secondary_violet_dark_text_c10',
    },
    {
      color: bookitColors.secondary.violet.hover,
      colorName: 'Hover',
      colorCode: 'c9',
      colorVariable: 'secondary_violet_hover_c9',
    },
    {
      color: bookitColors.secondary.violet.regular,
      colorName: 'Regular',
      colorCode: 'c8',
      colorVariable: 'secondary_violet_regular_c8',
    },
    {
      color: bookitColors.secondary.violet.components,
      colorName: 'Components',
      colorCode: 'c7',
      colorVariable: 'secondary_violet_components_c7',
    },
    {
      color: bookitColors.secondary.violet.componentsLight,
      colorName: 'Components Light',
      colorCode: 'c6',
      colorVariable: 'secondary_violet_components_light_c6',
    },
    {
      color: bookitColors.secondary.violet.borders,
      colorName: 'Borders',
      colorCode: 'c5',
      colorVariable: 'secondary_violet_borders_c5',
    },
    {
      color: bookitColors.secondary.violet.disabled,
      colorName: 'Disabled',
      colorCode: 'c4',
      colorVariable: 'secondary_violet_disabled_c4',
    },
    {
      color: bookitColors.secondary.violet.bgHover,
      colorName: 'Background Hover',
      colorCode: 'c3',
      colorVariable: 'secondary_violet_bg_hover_c3',
    },
    {
      color: bookitColors.secondary.violet.background,
      colorName: 'Background',
      colorCode: 'c2',
      colorVariable: 'secondary_violet_background_c2',
    },
    {
      color: bookitColors.secondary.violet.bgLight,
      colorName: 'Background Light',
      colorCode: 'c1',
      colorVariable: 'secondary_violet_bg_light_c1',
    },
  ],
};
