/**
 *
 * PublicAssetsLayout
 *
 */
// import { useIsMobile } from 'app/hooks/useIsMobile';
import * as React from 'react';
import styled from 'styled-components';

export interface PublicAssetsLayoutProps {
  children: React.ReactNode;
}
const PablicLayoutWrapper = styled.div`
  &.public-layout {
    display: flex;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100dvh;
  }
`;
export function PublicAssetsLayout(props: PublicAssetsLayoutProps) {
  const { children } = props;
  // const { checkIsMobile } = useIsMobile();
  // const isMobile = checkIsMobile();
  return (
    <PablicLayoutWrapper className="public-layout">
      {children}
    </PablicLayoutWrapper>
  );
}
