import { useTheme } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { CoreSelectionButton } from 'app/components/GlobalServiceGroupFilter/CoreSelectionButton';
import { AssetQuickSearch } from 'app/components/AssetQuickSearch';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthenticatedUser,
  selectGlobalServiceGroupFilter,
  selectIsSubBarCollapsed,
  selectShowGlobalServiceGroupFilter,
} from 'app/slice/selectors';
import { useAppSettingsSlice } from 'app/slice';
import { SubTopBar } from '../styled';
import { Button } from 'app/components/BasicButtons/Button';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

export interface SubTopToolbarProps {}

export function SubTopToolbar(props: SubTopToolbarProps) {
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const { t } = useTranslation();
  const savedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const showGroups = useSelector(selectShowGlobalServiceGroupFilter);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const collapsedBar = useSelector(selectIsSubBarCollapsed);
  const serviceGroupsCount = React.useMemo(() => {
    return savedServiceGroups !== undefined && savedServiceGroups?.length > 1
      ? `+${savedServiceGroups?.length - 1}`
      : undefined;
  }, [savedServiceGroups]);
  const firstServiceGroupName = React.useMemo(() => {
    return savedServiceGroups !== undefined && savedServiceGroups?.length > 0
      ? savedServiceGroups[0]?.Name
      : undefined;
  }, [savedServiceGroups]);
  const singleCore = React.useMemo(() => {
    return savedServiceGroups !== undefined && savedServiceGroups?.length === 1;
  }, [savedServiceGroups]);

  const isAdminOfSingle = React.useMemo(() => {
    return (
      singleCore &&
      !!savedServiceGroups &&
      authenticatedUser?.HasAdminGroupPermissions(savedServiceGroups[0].Id)
    );
  }, [authenticatedUser, savedServiceGroups, singleCore]);

  const handleCollapse = React.useCallback(() => {
    dispatch(actions.collapseSubBar(!collapsedBar));
  }, [actions, collapsedBar, dispatch]);
  const theme = useTheme();

  return showGroups ? (
    <React.Fragment>
      <IconButton
        variant="gray"
        size="xs"
        aria-label="collapse"
        className={clsx('collapseButton', { collapsedBar: collapsedBar })}
        onClick={() => {
          handleCollapse();
        }}
        shape="circle"
      >
        <React.Fragment>
          {collapsedBar && !!firstServiceGroupName && (
            <React.Fragment>
              <Icon
                icon="magnifying-glass"
                className="collapseIcon"
                color="black"
                colorExtend="main"
              />
              {`${firstServiceGroupName} ${serviceGroupsCount ?? ''}`}
            </React.Fragment>
          )}
          <Icon
            icon={collapsedBar ? 'chevron-down' : 'chevron-up'}
            className="collapseIcon"
            color="black"
            colorExtend="main"
          />
        </React.Fragment>
      </IconButton>
      <SubTopBar
        theme={theme}
        className={clsx('subBar', { subBarCollapsed: collapsedBar })}
      >
        <div className="frame">
          <div className="controls">
            <CoreSelectionButton />
            <AssetQuickSearch />
          </div>
          {isAdminOfSingle && !!savedServiceGroups && (
            <Button
              variant="ghost"
              size="small"
              startIcon={<Icon icon="external-link-alt" />}
              href={{
                search: { Edit: savedServiceGroups[0].Id },
                path: '/newGroup.aspx',
              }}
              target="_blank"
            >
              {t(translations.OpenCore)}
            </Button>
          )}
        </div>
      </SubTopBar>
    </React.Fragment>
  ) : null;
}
