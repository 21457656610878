import { ODataOperators } from 'api/odata/ODataFilter';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import DatesRangeFilter from 'app/components/BasicTable/Filters/DatesRangeFilter';
import NumberFieldFilter from 'app/components/BasicTable/Filters/NumberFieldFilter';
import {
  getDefaultFilterDatePeriod,
  GetNumberOrUndefined,
  GetRawValue,
  presetDatesRangeToQueryString,
} from 'app/components/BasicTable/types/FilterParam';
import { getPeriodTypesEntity } from 'app/components/DatePeriods';
import { translations } from 'locales/translations';
import { PresetDatesRange } from 'types/PresetDatesRange';
import { IPayHistory } from './IPayHistory';

export const GetFilters: GetPageFilters<IPayHistory> =
  (t, appSettings, user, settings) => search => {
    const params = new URLSearchParams(search);
    const res: IFilterSettings<IPayHistory>[] = [
      {
        fieldName: 'UpdatedAt',
        displayNameKey: translations.Period,
        operator: ODataOperators.Between,
        defaultValue: PresetDatesRange.fromPreset(
          getPeriodTypesEntity('ThisMonth'),
        ),
        value: getDefaultFilterDatePeriod('period', 'st', 'et', params),
        queryStringSerializer: presetDatesRangeToQueryString(
          'period',
          'st',
          'et',
        ),
        component: DatesRangeFilter,
        urlKey: 'period',
      },
      {
        fieldName: 'InvoicePaymentId',
        queryStringSerializer: 'payId',
        displayNameKey: translations.InvoiceRowId,
        operator: ODataOperators.Equals,
        value: GetNumberOrUndefined(GetRawValue(params, 'payId')),
        component: NumberFieldFilter,
      },
    ];
    return res;
  };
