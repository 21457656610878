import { Roles } from 'api/odata/generated/enums/Roles';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { Entity, Identifiable } from 'types/common';
import { IFixedRoleDto } from './IFixedRolesDto';
import { IServiceTypeFilterDto } from './IServiceTypeFilterDto';
import { IUserGroupDto } from './IUserGroupDto';

/**
 *
 * auto generated interface for bkt_webApp.Api.OData.V4.Dto.UserFilterDto
 *
 */
export interface IUserFilterDto extends Identifiable<string> {
  Id: string;
  Name: string;
  UserGroupId: string | null;
  UserGroupName: string | null;
  UserGroups: IUserGroupDto[];
  Budgets: number[];
  UserRoles: IFixedRoleDto[];
  CustomRoles: Entity<number>[];
  Services: IServiceTypeFilterDto[];
  ServiceGroupIds: number[];
  IsAdminPanel: boolean;
  IsGroupAdmin: boolean;
  IsEquipmentAdmin: boolean;
  IsLabTech: boolean;
  AssetIds: number[];
  InstituteId: number | null;
  StaffEquipmentId: number | null;
  DefaultBudgetId: number | null;
}
export const convertAuthToIUserFilterDto = (
  user: AuthenticatedUser,
): IUserFilterDto => {
  return {
    Id: user.Id,
    Name: user.Name,
    UserGroupId: user.ActiveUserGroup?.Id,
    UserGroupName: user.ActiveUserGroup?.Name,
    Services: user.AdminEquipments.map(f => {
      return {
        Id: f.Id,
        Name: f.Name,
        ServiceTypeId: f.ServiceTypeId,
      } as IServiceTypeFilterDto;
    }),
    ServiceGroupIds: user.AdminServiceGroups.map(f => f.Id),
    IsAdminPanel: user.Roles.includes(Roles.Administrators),
  } as IUserFilterDto;
};
