/**
 *
 * RoomTSPicker
 *
 */

import { httpClient } from 'api/HttpClient';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePickerProps,
  AutocompletePicker,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';

export interface RoomTSPickerProps
  extends Omit<AutocompletePickerProps<Entity<string>>, 'loadData'> {}

const url = '/api/odata/v4/RoomFilter';
export const initRoomTSData = (id: string) => {
  const params: {
    $orderby: string;
    $filter?: string;
    $select: string;
    $top: number;
    $skip: number;
  } = {
    $orderby: 'Name asc',
    $filter: new Condition('Id', ODataOperators.Equals, id).toString(),
    $select: 'Id,Name',
    $skip: 0,
    $top: 1,
  };
  return httpClient
    .get(url, params)
    .then(response => response.value as Entity<string>[]);
};

/* const loadData = (searchTerm: string | null) => {
  const params: {
    $orderby: string;
    $filter?: string;
    $select: string;
    $top: number;
  } = {
    $orderby: 'Name asc',
    $select: 'Id,Name',
    $top: 1000,
  };
  if (searchTerm !== null) {
    params.$filter = new ODataFilterBuilder<Entity<string>>({
      predicates: [],
      stringColumns: ['Name'],
      stringSearch: searchTerm,
    }).toString();
  }

  return httpClient
    .get(url, params)
    .then(response => response.value as Entity<string>[]);
}; */

const loadData = getAutoCompleteLoadDataFn<Entity<string>>({ url });

export function RoomTSPicker(props: RoomTSPickerProps) {
  const { t } = useTranslation();
  return (
    <AutocompletePicker
      loadData={loadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'roomId'}
      placeholder={t(translations.AllUsers)}
      {...props}
    />
  );
}
