/**
 *
 * Popper
 *
 */
import * as React from 'react';
import {
  ClickAwayListener,
  PopperPlacementType,
  Theme,
  useTheme,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  Popper as MuiPopper,
  PopperProps as MuiPopperProps,
} from '@material-ui/core';
import { PopperTitle } from './PopperTitle';
import clsx from 'clsx';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { bookitColors } from 'styles/colors/bookitColors';

export interface PopperStyleSharedProps {
  minWidth?: string | number;
  maxWidth?: string | number;
}
export interface PopperProps extends PopperStyleSharedProps {
  id: string;
  title: string;
  open: boolean;
  anchorEl?: EventTarget & HTMLDivElement;
  children: React.ReactNode;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  closeTitle?: string;
  clickAway?: boolean;
  clickAwayFunc?: (e: any) => void;
}

export const StyledPopper = styled(
  ({
    theme,
    children,
    ...other
  }: {
    theme: Theme;
    children:
      | React.ReactNode
      | ((props: {
          placement: PopperPlacementType;
          TransitionProps?: {
            in: boolean;
            onEnter: () => {};
            onExited: () => {};
          };
        }) => React.ReactNode);
  } & MuiPopperProps) => <MuiPopper {...other}>{children}</MuiPopper>,
)`
  &.popper-root {
    z-index: ${props => props.theme.zIndex.modal};
    &.popper-root-mobile {
      width: 100vw;
      height: 100%;
      overflow: auto;
    }
    &.popper-root-horizontal {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
`;
const StyledPaper = styled('div')<{ minWidth: string; maxWidth: string }>`
  &.popper-paper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;
    box-shadow: 0px 2px 18px rgba(8, 24, 41, 0.16);
    max-height: 100vh;
    overflow: hidden;
    border-radius: 8px;
    background-color: ${bookitColors.base.white};
    min-width: ${props => props.minWidth};
    max-width: ${props => props.maxWidth};
    & .popper-content {
      width: 100%;
      height: 100%;
    }
  }
`;
/**
 * A Popper with consistent title + close interface
 */
export const Popper = React.forwardRef<PopperProps, any>(function Popper(
  props,
  ref,
) {
  const {
    id,
    title,
    open,
    anchorEl,
    children,
    onClose,
    className,
    closeTitle,
    clickAway,
    clickAwayFunc,
    minWidth,
    maxWidth,
    ...other
  } = props;
  const { checkIsMobile, isLandscape } = useIsMobile();
  const isMobile = checkIsMobile();
  const theme = useTheme();
  const handleClickAway = e => {
    if (clickAway) {
      if (e.target.nodeName === 'BODY' && e.type === 'click') {
        return;
      }
      if (!!clickAwayFunc) {
        clickAwayFunc(e);
      } else {
        onClose(e);
      }
    }
  };

  return (
    <StyledPopper
      theme={theme}
      id={id ?? 'poperId'}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      ref={ref}
      container={document.fullscreenElement ?? document.body}
      // placement={isMobile ? 'bottom' : 'bottom-start'}
      className={clsx('popper-root', className, {
        'popper-root-mobile': isMobile && !isLandscape,
        'popper-root-horizontal': isMobile && isLandscape,
      })}
      {...other}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <StyledPaper
          className={'popper-paper'}
          minWidth={
            !!minWidth
              ? typeof minWidth === 'string'
                ? minWidth
                : `${minWidth}px`
              : isMobile
              ? '100%'
              : '500px'
          }
          maxWidth={
            !!maxWidth
              ? typeof maxWidth === 'string'
                ? maxWidth
                : `${maxWidth}px`
              : isMobile
              ? '100%'
              : '700px'
          }
        >
          <PopperTitle
            title={props.title}
            closeButtonTitle={props.closeTitle}
            onClose={props.onClose}
          />
          <div className="popper-content">{props.children}</div>
        </StyledPaper>
      </ClickAwayListener>
    </StyledPopper>
  );
});
