/**
 *
 * ServiceRequestAddons
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Column } from 'react-table';
import { IServiceRequestMilestoneChargeDto } from 'api/odata/generated/entities/IServiceRequestMilestoneChargeDto';
import DateRenderer from 'app/components/BasicTable/CellRenderers/DateRenderer';
import { IConsumableServiceDto } from 'api/odata/generated/entities/IConsumableServiceDto';
import { ControlledTable } from 'app/components/BasicTable/ControlledTable';
import { useDispatch, useSelector } from 'react-redux';
import { CellProps } from 'react-table';
import { IUserBaseDto } from 'api/odata/generated/entities/IUserBaseDto';
import { useRequestSamplesSlice } from '../../RequestSamplesPage/slice';
import { IServiceRequestMilestoneDto } from 'api/odata/generated/entities/IServiceRequestMilestoneDto';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { AddonTypesUnion } from 'api/odata/generated/enums/AddonTypes';
import { InternalServiceRequestStatusesUnion } from 'enums/InternalServiceRequestStatuses';
import {
  ServiceRequestMilestoneChargeDetailsProps,
  ServiceRequestMilestoneChargeType,
} from '../ServiceRequestMilestoneChargeDetails';
import {
  ConsumableTypeToService,
  getConsumableIsMandatory,
  showAddonCharge as showAddonServiceCharge,
  showManualCharge,
} from './utils';
import { RowActionsMenu } from 'app/components/BasicTable/RowActions';
import { DescriptionCell } from 'app/components/BasicTable/components/DescriptionCell';
import { ColumnWithStrictAccessor } from 'react-table';
import { IUnitTypeDto } from 'api/odata/generated/entities/IUnitTypeDto';
import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { Printing } from 'app/components/PrintToPDF';
import { DetectIsMobile } from 'utils/mobileDetect';
import { InvoiceDetailsLink } from 'app/pages/Actions/GlobalSelectedActions/OpenInvoiceDetails';
import { Entity } from 'types/common';
import { roundTwoPlacesAfterDecimalPoint } from 'utils/number-utils';
import { ReportConsumablesProps } from 'app/pages/OtherServiceDetails/ReportConsumables';
import { selectRequestDetailsData } from '../../RequestSamplesPage/slice/selectors';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { IMyAssetsRow } from 'app/pages/MyAssetsPage/IMyAssetsRow';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { MandatoryOfflineTypeEnum } from 'enums/MandatoryOfflineTypeEnum';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import clsx from 'clsx';
import styled from 'styled-components';
import { Button } from 'app/components/BasicButtons/Button';

export interface ServiceRequestAddonsProps {
  charges: IServiceRequestMilestoneChargeDto[];
  milestone?: IServiceRequestMilestoneDto;
  isAdmin: boolean;
  AddonType: AddonTypesUnion;
  InternalStatus: InternalServiceRequestStatusesUnion;
  pageName?: string;
  tableContainerAsSection?: boolean;
  // indicates if this component is opened through side panel
  printing?: Printing;
  useSidePanel: boolean;
  UnitType?: Entity<number>;
}

export function ServiceRequestAddons(props: ServiceRequestAddonsProps) {
  const { t } = useTranslation();
  const isMobile = DetectIsMobile();

  const dispatch = useDispatch();
  const { actions } = useRequestSamplesSlice();
  const requestDetailsData = useSelector(selectRequestDetailsData);
  const authenticatedUser = useSelector(selectAuthenticatedUser);

  const show_newAddonServiceCharge = React.useMemo(() => {
    const result = showAddonServiceCharge({
      Charged: props.milestone?.Charged,
      isAdmin: props.isAdmin,
      ServiceRequestAddonTypes: props.AddonType,
      ServiceRequestInternalStatus: props.InternalStatus,
    });
    return result;
  }, [
    props.AddonType,
    props.InternalStatus,
    props.isAdmin,
    props.milestone?.Charged,
  ]);

  const show_newManualCharge = React.useMemo(() => {
    const result = showManualCharge({
      Charged: props.milestone?.Charged,
      isAdmin: props.isAdmin,
      ServiceRequestAddonTypes: props.AddonType,
      ServiceRequestInternalStatus: props.InternalStatus,
    });
    return result;
  }, [
    props.AddonType,
    props.InternalStatus,
    props.isAdmin,
    props.milestone?.Charged,
  ]);
  const handleAddServiceClick = React.useCallback(() => {
    if (props.milestone === undefined) {
      return;
    }
    // const payload: ServiceRequestMilestoneChargeDetailsProps = {
    //   serviceRequestId: props.milestone?.ServiceRequestId,
    //   serviceRequestMilestoneId: props.milestone.Id,
    //   type: 'service',
    //   enableAddonCharge: show_newAddonCharge,
    //   enableManualCharge: show_newManualCharge,
    // };
    // dispatch(
    //   actions.openSidePanel({
    //     type: RenderPageType.ServiceRequestMilestoneCharge,
    //     props: payload,
    //   }),
    // );
    const offServiceFilter: (string | Condition<IOfflineServiceFilterDto>)[] =
      [];
    const assetFilter: (string | Condition<IMyAssetsRow>)[] = [];
    if (
      !!requestDetailsData?.ConsumableServices &&
      requestDetailsData?.ConsumableServices.filter(
        f =>
          f.Mandatory !== MandatoryOfflineTypeEnum.Mandatory_OnCompletion ||
          (f.Mandatory === MandatoryOfflineTypeEnum.Mandatory_OnCompletion &&
            props.isAdmin),
      ).length > 0
    ) {
      assetFilter.push(
        `(${new Condition<IMyAssetsRow>(
          'ServiceId',
          ODataOperators.Includes,
          requestDetailsData?.ConsumableServices.map(x => x.Id),
        ).toString()} and ServiceTypeId eq 2)`,
      );
      offServiceFilter.push(
        new Condition<IOfflineServiceFilterDto>(
          'Id',
          ODataOperators.Includes,
          requestDetailsData?.ConsumableServices.filter(
            f =>
              f.Mandatory !== MandatoryOfflineTypeEnum.Mandatory_OnCompletion ||
              (f.Mandatory ===
                MandatoryOfflineTypeEnum.Mandatory_OnCompletion &&
                props.isAdmin),
          ).map(x => x.Id),
        ),
      );
    } else {
      assetFilter.push(
        new Condition(
          'ServiceGroupId',
          ODataOperators.Equals,
          requestDetailsData?.Service.ServiceGroup.Id,
        ),
      );
      offServiceFilter.push(
        new Condition<IOfflineServiceFilterDto>(
          'ServiceGroupId',
          ODataOperators.Equals,
          requestDetailsData?.Service.ServiceGroup.Id,
        ),
      );
    }
    if (
      props.charges &&
      props.charges.filter(
        f => !!f.OfflineServiceTypeId && f.OfflineServiceTypeId !== null,
      ).length > 0
    ) {
      assetFilter.push(
        `(${new Condition<IMyAssetsRow>(
          'ServiceId',
          ODataOperators.Excludes,
          props.charges
            .filter(
              f => !!f.OfflineServiceTypeId && f.OfflineServiceTypeId !== null,
            )
            .map(x => x.OfflineServiceTypeId ?? 0),
        ).toString()} and ServiceTypeId eq 2)`,
      );
      offServiceFilter.push(
        new Condition<IOfflineServiceFilterDto>(
          'Id',
          ODataOperators.Excludes,
          props.charges
            .filter(
              f => !!f.OfflineServiceTypeId && f.OfflineServiceTypeId !== null,
            )
            .map(x => x.OfflineServiceTypeId ?? 0),
        ),
      );
    }
    let params = {
      useSidePanel: true,
      queryParams: {
        user:
          requestDetailsData?.CreatedFor?.Id ??
          requestDetailsData?.CreatedBy?.Id ??
          authenticatedUser?.Id,
        bid: String(requestDetailsData?.Budget?.Id),
        adg: requestDetailsData?.UserGroup?.Id,
        po: requestDetailsData?.PurchaseOrder,
      },
      reportFrom: 'milestone',
      assetFilter: assetFilter,
      offlineTypeFilter: offServiceFilter,
      serviceRequestId: props.milestone?.ServiceRequestId,
      serviceRequestMilestoneId: props.milestone.Id,
      initialServices: !!requestDetailsData?.ConsumableServices
        ? requestDetailsData?.ConsumableServices.filter(
            f =>
              f.Mandatory === MandatoryOfflineTypeEnum.Mandatory_Default ||
              getConsumableIsMandatory(f, props.isAdmin, props.InternalStatus),
          )
            .filter(
              ext =>
                !props.charges ||
                props.charges.length === 0 ||
                (props.charges &&
                  props.charges.length > 0 &&
                  !props.charges.some(f => f.OfflineServiceTypeId === ext.Id)),
            )
            .map(item =>
              ConsumableTypeToService(
                item,
                requestDetailsData,
                authenticatedUser,
                props.isAdmin,
              ),
            ) ?? []
        : [],
    } as ReportConsumablesProps;
    dispatch(
      actions.openSidePanel({
        type: RenderPageType.ReportConsumables,
        props: params,
        expanded: true,
      }),
    );
  }, [
    actions,
    authenticatedUser,
    dispatch,
    props.InternalStatus,
    props.charges,
    props.isAdmin,
    props.milestone,
    requestDetailsData,
  ]);
  const showOfflineServicesError = React.useMemo(() => {
    let mandatory =
      !!requestDetailsData?.ConsumableServices &&
      requestDetailsData?.ConsumableServices.length > 0
        ? requestDetailsData?.ConsumableServices.filter(f =>
            getConsumableIsMandatory(f, props.isAdmin, props.InternalStatus),
          )
        : [];

    return (
      mandatory.length > 0 &&
      mandatory.filter(
        ext =>
          !props.charges ||
          props.charges.length === 0 ||
          (props.charges &&
            props.charges.length > 0 &&
            !props.charges.some(f => f.OfflineServiceTypeId === ext.Id)),
      ).length > 0
    );
  }, [
    props.InternalStatus,
    props.charges,
    props.isAdmin,
    requestDetailsData?.ConsumableServices,
  ]);
  // const handleManageOfflineRefsClick = React.useCallback(() => {
  //   let sidePanelProps: OfflineTypeReferencesPopUpProps = {
  //     useSidePanel: true,
  //     refId: requestDetailsData?.Service.Id,
  //     refTypeId: OfflineReferenceTypeEnum.Offline_ServiceRequest,
  //   };

  //   dispatch(
  //     actions.openSidePanel({
  //       type: RenderPageType.OfflineTypeRefs,
  //       props: sidePanelProps,
  //       expanded: true,
  //     }),
  //   );
  // }, [actions, dispatch, requestDetailsData?.Service.Id]);
  const handleAddChargeClick = React.useCallback(() => {
    if (props.milestone === undefined) {
      return;
    }
    const payload: ServiceRequestMilestoneChargeDetailsProps = {
      serviceRequestId: props.milestone?.ServiceRequestId,
      serviceRequestMilestoneId: props.milestone.Id,
      unitType: props.UnitType,
      type: 'charge',
      enableAddonCharge: show_newAddonServiceCharge,
      enableManualCharge: show_newManualCharge,
    };
    dispatch(
      actions.openSidePanel({
        type: RenderPageType.ServiceRequestMilestoneCharge,
        props: payload,
      }),
    );
  }, [
    actions,
    dispatch,
    props.UnitType,
    props.milestone,
    show_newAddonServiceCharge,
    show_newManualCharge,
  ]);

  const handleEditClick = React.useCallback(
    (value: IServiceRequestMilestoneChargeDto) => {
      if (props.milestone === undefined) {
        return;
      }
      const type: ServiceRequestMilestoneChargeType =
        value.OfflineServiceTypeId === null ? 'charge' : 'service';
      const payload: ServiceRequestMilestoneChargeDetailsProps = {
        serviceRequestId: props.milestone?.ServiceRequestId,
        serviceRequestMilestoneId: props.milestone.Id,
        type: type,
        enableAddonCharge: show_newAddonServiceCharge,
        enableManualCharge: show_newManualCharge,
        value: value,
      };
      dispatch(
        actions.openSidePanel({
          type: RenderPageType.ServiceRequestMilestoneCharge,
          props: payload,
        }),
      );
    },
    [
      actions,
      dispatch,
      props.milestone,
      show_newAddonServiceCharge,
      show_newManualCharge,
    ],
  );

  const handleDeleteClick = React.useCallback(
    (value: IServiceRequestMilestoneChargeDto) => {
      dispatch(actions.deleteMilestoneCharge(value));
    },
    [actions, dispatch],
  );
  const rowActions = React.useMemo(() => {
    if (show_newAddonServiceCharge) {
      return [
        ({ value, row, updateMyData }) =>
          show_newAddonServiceCharge && (
            <RowActionsMenu
              id={`row-actions-menu_${row.original.Id}`}
              items={[
                {
                  icon: 'pencil-alt',
                  text: t(translations.Edit),
                  title: t(translations.Edit),
                  onClick: () => Promise.resolve(handleEditClick(row.original)),
                },
                {
                  icon: 'times',
                  text: t(translations.Delete),
                  title: t(translations.Delete),
                  onClick: () =>
                    Promise.resolve(handleDeleteClick(row.original)),
                },
              ]}
            />
          ),
      ];
    }
  }, [handleDeleteClick, handleEditClick, show_newAddonServiceCharge, t]);
  const pageActions: PageActionRenderer[] = React.useMemo(() => {
    const result: PageActionRenderer[] = [];
    if (show_newManualCharge) {
      result.push(() => (
        <TopActionButton
          size="small"
          variant={show_newAddonServiceCharge ? 'white' : 'main'}
          startIcon="circle-dollar-to-slot"
          icon="circle-dollar-to-slot"
          key={'show_newAddonCharge'}
          onClick={handleAddChargeClick}
          text={t(translations.AddManualCharge) as string}
        />
      ));
    }
    if (
      show_newAddonServiceCharge &&
      ((props.charges ?? []).length > 0 ||
        (requestDetailsData?.ConsumableServices?.length ?? 0) > 0)
    ) {
      result.push(() => (
        <TopActionButton
          size="small"
          variant={'main'}
          startIcon="plus"
          icon="plus"
          key={'show_newAddonCharge'}
          onClick={handleAddServiceClick}
          text={t(translations.AddService) as string}
        />
      ));
      // if (props.isAdmin && !show_newManualCharge) {
      //   result.push(() => (
      //     <TopActionButton
      //       variant="white"
      //       size="small"
      //       startIcon="cog"
      //       icon="cog"
      //       text={t(translations.ManageRelatedService)}
      //       onClick={handleManageOfflineRefsClick}
      //     />
      //   ));
      // }
    }
    return result;
  }, [
    handleAddChargeClick,
    handleAddServiceClick,
    props.charges,
    requestDetailsData?.ConsumableServices?.length,
    show_newAddonServiceCharge,
    show_newManualCharge,
    t,
  ]);
  const columns: Array<
    ColumnWithStrictAccessor<IServiceRequestMilestoneChargeDto>
  > = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'Id',
        Cell: ({ value, row }) => {
          return show_newAddonServiceCharge ? (
            <Button
              variant="text"
              size="small"
              title={t(translations.Edit)}
              onClick={() => handleEditClick(row.original)}
            >
              {row.original.Id}
            </Button>
          ) : (
            <>{value}</>
          );
        },
      },
      {
        Header: t(translations.Date) as string,
        accessor: 'Date',

        Cell: DateRenderer,
      },
      {
        Header: t(translations.ServiceOrCharge) as string,
        accessor: 'Service',
        Cell: ({
          value,
          row,
        }: CellProps<
          IServiceRequestMilestoneChargeDto,
          IConsumableServiceDto
        >) => value?.Name ?? row.original.Name ?? t(translations.NA),
      },
      { Header: t(translations.Quantity) as string, accessor: 'Quantity' },
      {
        Header: t(translations.Unit) as string,
        accessor: 'UnitType',
        Cell: ({
          value,
          row,
        }: CellProps<IServiceRequestMilestoneChargeDto, IUnitTypeDto>) =>
          (value ?? row.original.Service?.UnitType)?.Name ?? t(translations.NA),
      },
      {
        Header: t(translations.Rate) as string,
        accessor: 'Rate',
        Cell: ({ value, row }) => {
          return (
            <>
              {roundTwoPlacesAfterDecimalPoint(
                value ?? row.original.Service?.Rate,
              )}
            </>
          );
        },
      },
      {
        Header: t(translations.Amount) as string,
        accessor: 'Amount',
        Cell: ({ value }) => {
          return <>{roundTwoPlacesAfterDecimalPoint(value)}</>;
        },
      },
      {
        Header: t(translations.InvoiceId) as string,
        accessor: 'InvoiceId',
        Cell: ({ value, row }) =>
          value !== null && value !== undefined && typeof value === 'number' ? (
            <InvoiceDetailsLink id={value} useSidePanel={props.useSidePanel}>
              {value}
            </InvoiceDetailsLink>
          ) : (
            <>{value}</>
          ),
      },
      {
        Header: t(translations.Remarks) as string,
        accessor: 'Remarks',
        Cell: DescriptionCell({ width: 'medium' }),
      },
      {
        Header: t(translations.UpdatedBy) as string,
        accessor: 'UpdatedBy',
        Cell: ({
          value,
        }: CellProps<IServiceRequestMilestoneChargeDto, IUserBaseDto>) =>
          value?.Name ?? t(translations.NA),
      },
    ],
    [handleEditClick, props.useSidePanel, show_newAddonServiceCharge, t],
  );
  return (
    <Container>
      {showOfflineServicesError && (
        <span
          className={clsx('off-error', {
            'off-error-milestone': show_newManualCharge,
          })}
        >
          <BasicTypography variant="captionBold" color="error">
            {t(translations.RequestOfflineServicesRequired) as string}
          </BasicTypography>
          {show_newAddonServiceCharge && (
            <React.Fragment>
              <TopActionButton
                size="small"
                variant={'text'}
                icon="plus"
                key={'show_newAddonCharge_err'}
                onClick={handleAddServiceClick}
                text={t(translations.ClickHere) as string}
              />
              <BasicTypography variant="captionBold" color="error">
                {'to add services.'}
              </BasicTypography>
            </React.Fragment>
          )}
        </span>
      )}
      <ControlledTable<IServiceRequestMilestoneChargeDto>
        columns={columns as Array<Column<IServiceRequestMilestoneChargeDto>>}
        //withToolBar={false}
        data={props.charges ?? []}
        useRowSelect={false}
        hideMenuOnMobile={true}
        screenName={props.pageName ?? t(translations.AddOnServices)}
        useGlobalFilter={false}
        api={'foo'}
        customEmptyTableSettings={
          (requestDetailsData?.ConsumableServices?.length ?? 0) === 0 &&
          show_newAddonServiceCharge &&
          props.isAdmin
            ? {
                customContent: (
                  <React.Fragment>
                    <BasicTypography variant="h3">
                      {t(translations.Addon_Empty_AddEnabled) as string}
                    </BasicTypography>
                    <p>{''}</p>
                    <TopActionButton
                      variant="main"
                      size="large"
                      startIcon="plus"
                      icon="plus"
                      text={t(translations.AddRelatedServices)}
                      onClick={handleAddServiceClick}
                    />
                  </React.Fragment>
                ),
              }
            : {
                headTitle: t(translations.dash_EmptyWidgetTitle),
                title:
                  show_newAddonServiceCharge || show_newManualCharge
                    ? t(translations.AddonCharges_Empty_AddEnabled)
                    : undefined,
              }
        }
        manualPagination={false}
        manualSorting={false}
        compactTable={false}
        enablePagination={false}
        compact={isMobile}
        screenNameVariant="section"
        dataLength={props.charges?.length ?? 0}
        loading={props.charges === undefined}
        onChangeState={() => void 0}
        onExport={() => Promise.resolve()}
        useExport={false}
        pageCount={123}
        initialPageSize={1000}
        serviceGroups={[]}
        rowActions={rowActions}
        pageActions={pageActions}
        tableContainerAsSection={props.tableContainerAsSection}
        printing={props.printing}
      />
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: inherit;
  & .off-error {
    display: flex;
    flex-direction: row;
    gap: 8px;
    &.off-error-milestone {
      padding: 12px 0px 0px 32px;
    }
  }
`;
// const ButtonsContainer = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   gap: theme.spacing(1),
//   marginTop: theme.spacing(1),
// }));
