import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.largeFilesUpload || initialState;

export const selectLargeFilesUpload = createSelector(
  [selectSlice],
  state => state,
);
export const selectLargeFilesCanUpload = createSelector(
  [selectLargeFilesUpload],
  state => state.canUpload,
);
export const selectLargeFilesUploading = createSelector(
  [selectLargeFilesUpload],
  state => state.uploading,
);
export const selectLargeFilesStartedToUpload = createSelector(
  [selectLargeFilesUpload],
  state => state.startedToUpload,
);
export const selectLargeFilesUploadTargetId = createSelector(
  [selectLargeFilesUpload],
  state => state.targetId,
);
export const selectLargeFilesUploadFilesNumber = createSelector(
  [selectLargeFilesUpload],
  state => state.numberOfUploadedFiles,
);
export const selectLargeFilesUploadSelectedFiles = createSelector(
  [selectLargeFilesUpload],
  state => state.selectedFiles,
);
