import { IBudgetExperimentsFilterDto } from 'api/odata/generated/entities/IBudgetExperimentsFilterDto';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { FieldHandlerProps } from 'app/components/Forms/FormRender/FormRenderer';
import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { AsyncDataState, Entity } from 'types/common';
import { IConnectedFiltersDto } from 'types/IConnectedFiltersDto';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { IResponseType } from 'types/ResponseType';
import { dateUtils } from 'utils/date-utils';
import { toEntity } from 'utils/entity-utils';
import { PricingState } from 'app/pages/ReservationDetails/Details/slice/types';
import { IRequestMilestoneManualChargeModel } from 'app/pages/Samples/RequestSamplesPage/slice/types';
import { isEmptyOrWhitespace, isNullOrUndefined } from 'utils/typeUtils';

/* --- STATE --- */

export interface OfflineServiceState {
  detailsState: AsyncDataState<OtherServiceDetailsState>;
  connectedDataState: IConnectedFiltersDto;
  processing: boolean;
  consumablesProcessing?: boolean;
  valueHandler?: FieldHandlerProps<OtherServiceDetailsState>;
  serviceChangeResultState?: OfflineServiceTypeChangeResult;
  serviceChangeStateLoading?: boolean;
  creditState: AsyncDataState<PricingState>;
  hasCharges?: boolean;
  savedReservationService?: IOtherServices;
  consumablesState: AsyncDataState<ReportConsumablesState>;
  consumablesValueHandler?: FieldHandlerProps<ReportConsumablesState>;
  selectedServiceTypes: Array<number>;
  editCreatableService?: IOtherServices;
  barcodeScanned?: boolean;
  refeshOfflineServices?: boolean;
  stockRenewalState: AsyncDataState<StockRenewalState>;
  stockRenewalValueHandler?: FieldHandlerProps<StockRenewalState>;
  renewStockState: AsyncDataState<RenewStockState>;
  renewStockValueHandler?: FieldHandlerProps<RenewStockState>;
  inventoryBatchState: AsyncDataState<InventoryBatchState>;
  inventoryBatchValueHandler?: FieldHandlerProps<InventoryBatchState>;
  inventoryBatchEditable?: IInventoryBatchDto;
  isEdit?: boolean;
  batchIsEdit?: boolean;
  getCreatableService?: IOtherServices;
}
export interface OfflineServiceQueryStringParameters {
  id?: string;
  ipid?: string;
  user?: string;
  bid?: string;
  be?: string;
  ecid?: string;
  po?: string;
  reference?: string;
  remarks?: string;
  factor?: string;
  amount?: string;
  adg?: string;
  stid?: string;
  ReservationId?: string;
  UsageId?: string;
  servicedate?: string;
  batchId?: string;
}
export interface StockRenewalParams {
  stid?: string;
  servicedate?: string;
}
export interface RenewStockParams {
  id?: string;
  stid?: string;
  servicedate?: string;
  remarks?: string;
  amount?: string;
  batchId?: string;
}
export interface InventoryBatchParams {
  id?: string;
  name?: string;
  stid?: string;
  purchasedate?: string;
  lot?: string;
  inventory?: string;
}
export interface OtherServiceDetailsState {
  Id: number;
  ServiceType: IOfflineServiceFilterDto | null;
  DiscountFactor?: number | null;
  Quantity?: number | null;
  ReservationId?: number | null;
  UsageId?: number | null;
  Remarks?: string | null;
  SyncReservation?: boolean | null;
  SyncUsage?: boolean | null;
  InventoryBatch?: IInventoryBatchDto | null;

  // ServiceDate: Date | string;
  // BookedBy: Entity<string> | null;
  // UserGroup: Entity<string> | null;
  // Budget: Entity<number> | null;
  // InstituteProject: Entity<number> | null;
  // ExternalCustomer?: Entity<number> | null;
  // FundingType?: Entity<number> | null;
  // PurchaseOrder?: string | null;
  // Reference?: string | null;
  // BudgetExperiment?: Entity<number> | null;
}
export interface ReportConsumablesState {
  Services: IOtherServices[];
}
export interface RenewStockState {
  Id: number;
  ServiceType: IOfflineServiceFilterDto | null;
  DateReceived: Date | string;
  InventoryBatch?: IInventoryBatchDto | null;
  Quantity?: number | null;
  Remarks?: string | null;
}
export interface InventoryBatchState {
  Id: number;
  Name: string;
  ServiceType: IOfflineServiceFilterDto | null;
  PurchaseDate?: Date | string | null;
  ExpirationDate?: Date | string | null;
  Inventory: number;
  Lot?: string | null;
  PurchasePricePerUnit: number | null;
}
export interface StockRenewalState {
  Services: IOtherServices[];
  DateReceived: Date | string;
  AutoGenerateNewBatches?: boolean | null;
}
export interface OfflineServicesResponse extends IResponseType {
  Id: number | null;
  data: IOtherServices | null;
  serviceType: Entity<number>;
  IsValid: boolean;
}
export interface InventoryBatchResponse extends IResponseType {
  Id: number | null;
  data: IInventoryBatchDto | null;
  serviceType: Entity<number>;
  IsValid: boolean;
}
export interface ConsumableServicesResponse extends IResponseType {
  Id: number[];
  data: IOtherServices[];
  IsValid: boolean;
  consumableInitData?: ConsumableInitData;
}

export interface ServiceChangeStateParameters {
  ServiceTypes: number[];
  ReservationId: number | null;
  UsageId: number | null;
  Start: Date | string;
  BookedBy: string | null;
  ADGroup: string | null;
  BudgetId: number | null;
  FundingTypeId: number | null;
}
export interface ErrorServices {
  id: number;
  error?: string;
}
export interface OfflineServiceTypeChangeResult {
  ErrorMessages: string[];
  DefaultBudget: IBudgetFilterDto | null;
  DefaultExperiment: IBudgetExperimentsFilterDto | null;
}
export type ReportedFrom = 'milestone' | 'reservation' | 'other';
export interface ConsumableMilestoneData {
  serviceRequestId: number;
  serviceRequestMilestoneId: number;
}

export interface ConsumableInitData {
  StartTime: Date | string;
  UserName?: string;
  DisplayName?: string;
  UserGroupName?: string;
  UserGroup?: string;
  BudgetId?: number;
  BudgetExperimentId?: number;
  BudgetName?: string;
  BudgetExperimentName?: string;
  FundingTypeId?: number;
  PurchaseOrder?: string;
  Reference?: string;
}
export const ConvertDtoToModel = (
  service: IOtherServices,
  serviceType: IOfflineServiceFilterDto | null,
): OtherServiceDetailsState => {
  let model = {
    Id: service.Id,
    ServiceType: serviceType,
    ServiceTypeId: ServiceType.Offline,
    DiscountFactor: service.DiscountFactor,
    Quantity: service.Quantity ?? service.DefaultQuantity,
    Remarks: service.Remarks,
    ReservationId: service.ReservationId,
    UsageId: service.UsageId,
    InventoryBatch:
      service.InventoryBatchId === null
        ? null
        : ({
            Id: service.InventoryBatchId,
            Name: service.InventoryBatchName,
            Inventory: service.InventoryBatchAmount,
          } as IInventoryBatchDto),
  } as OtherServiceDetailsState;
  return model;
};
export const ConvertConsumablesToModel = (
  services: IOtherServices[],
): ReportConsumablesState => {
  return {
    Services: services,
    DiscountFactor: 1,
    Remarks: null,
  } as ReportConsumablesState;
};
export const ConvertConsumablesToRenewalModel = (
  services: IOtherServices[],
  date: Date | string,
): StockRenewalState => {
  return {
    Services: services,
    DateReceived: dateUtils.formatISO(dateUtils.dateOrStringToDate(date)),
    AutoGenerateNewBatches: true,
  } as StockRenewalState;
};
export const ConvertRenewStockToModel = (
  service: IOtherServices,
  serviceType: IOfflineServiceFilterDto | null,
): RenewStockState => {
  let model = {
    Id: service.Id,
    ServiceType: serviceType,
    // service.ServiceTypeID > 0
    //   ? ({
    //       Id: service.ServiceTypeID,
    //       Name: service.ServiceType,
    //       Active: true,
    //       AllowToUser: service.AllowToUser,
    //       DefaultQuantity: service.DefaultQuantity,
    //       HideProjects: service.HideProjects,
    //       IntQuantityOnly: service.IntQuantityOnly,
    //       Inventory: service.Inventory,
    //       ReservationOverlappingInventory:
    //         service.ReservationOverlappingInventory,
    //       ServiceGroupId: service.ServiceGroupId,
    //       ServiceTypeId: 2,
    //       StaffOnly: service.StaffOnly,
    //       UnitTypeID: service.UnitTypeId,
    //       UnitTypeName: service.Units,
    //       NotLessThanZero: service.NotLessThanZero,
    //       TrackInventory: service.TrackInventory,
    //       InventoryBatchesEnabled: service.InventoryBatchesEnabled,
    //     } as IOfflineServiceFilterDto)
    //   : null,
    Quantity: service.Quantity ?? service.DefaultQuantity,
    Remarks: service.Remarks,
    InventoryBatch:
      service.InventoryBatchId === null
        ? null
        : ({
            Id: service.InventoryBatchId,
            Name: service.InventoryBatchName,
            Inventory: service.InventoryBatchAmount,
          } as IInventoryBatchDto),
    DateReceived: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(service.ServiceDate),
    ),
  } as RenewStockState;
  return model;
};
export const ConvertInventoryBatchToModel = (
  batch: IInventoryBatchDto,
  serviceType: IOfflineServiceFilterDto | null,
  newDate: Date | string,
): InventoryBatchState => {
  let model = {
    Id: batch.Id,
    ServiceType: serviceType,
    Inventory: batch.Inventory,
    Name: batch.Name,
    PurchasePricePerUnit: batch.PurchasePricePerUnit,
    ExpirationDate: batch.ExpirationDate,
    Lot: batch.Lot,
    PurchaseDate: dateUtils.formatQueryStringDate(
      dateUtils.dateOrStringToDate(batch.PurchaseDate || newDate),
    ),
  } as InventoryBatchState;
  return model;
};
export const ConvertDtoToConnectedModel = (
  service: IOtherServices,
): IConnectedFiltersDto => {
  let model = {
    User: toEntity(service.BookedBy, service.UserDisplayName),
    UserGroup: toEntity(service.UserGroup, service.UserGroupName),
    Budget: toEntity(service.BudgetID, service.Budget),
    InstituteProject: toEntity(
      service.InstituteProjectId,
      service.InstituteProjectName,
    ),
    StartTime: dateUtils.formatQueryStringDate(
      dateUtils.dateOrStringToDate(service.ServiceDate),
    ),
    BudgetExperiment: toEntity(
      service.BudgetExperimentId,
      service.BudgetExperimentName,
    ),
    ExternalCustomer: toEntity(
      service.ExternalCustomerId,
      service.ExternalCustomerName,
    ),
    FundingType:
      service.FundingType !== null
        ? getSingleInvoiceFundingType(service.FundingType ?? 0)
        : null,
    PurchaseOrder: service.PurchaseOrder,
    Reference: service.Reference,
  } as IConnectedFiltersDto;
  return model;
};
export const ConvertInitDataToConnectedModel = (
  data: ConsumableInitData,
): IConnectedFiltersDto => {
  let model = {
    User: toEntity(data.UserName, data.DisplayName),
    UserGroup: toEntity(data.UserGroup, data.UserGroupName),
    Budget: toEntity(data.BudgetId, data.BudgetName),
    InstituteProject: null,
    StartTime: dateUtils.formatQueryStringDate(
      dateUtils.dateOrStringToDate(data.StartTime),
    ),
    BudgetExperiment: toEntity(
      data.BudgetExperimentId,
      data.BudgetExperimentName,
    ),
    ExternalCustomer: null,
    FundingType:
      data.FundingTypeId !== null && data.FundingTypeId !== undefined
        ? getSingleInvoiceFundingType(data.FundingTypeId ?? 0)
        : null,
    PurchaseOrder: data.PurchaseOrder,
    Reference: data.Reference,
  } as IConnectedFiltersDto;
  return model;
};
export const ConvertModelToInsertEntity = (
  model: OtherServiceDetailsState,
  connectedModel: IConnectedFiltersDto,
) => {
  let data = {
    Id: model.Id,
    BudgetID: connectedModel.Budget?.Id || null,
    Budget: connectedModel.Budget?.Name,
    UserGroup: connectedModel.UserGroup?.Id || null,
    UserGroupName: connectedModel.UserGroup?.Name,
    BookedBy: connectedModel.User?.Id,
    UserDisplayName: connectedModel.User?.Name,
    ServiceTypeID: model.ServiceType?.Id,
    ServiceType: model.ServiceType?.Name,
    ServiceDate: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(connectedModel.StartTime),
    ),
    Quantity: Number(model.Quantity),
    DiscountFactor: Number(model.DiscountFactor ?? 1),
    Remarks: model.Remarks,
    FundingType: connectedModel.FundingType?.Id,
    BudgetExperimentId: connectedModel.BudgetExperiment?.Id,
    BudgetExperimentName: connectedModel.BudgetExperiment?.Name,
    ReservationId: model.ReservationId,
    UsageId: model.UsageId,
    ExternalCustomerId: connectedModel.ExternalCustomer?.Id,
    ExternalCustomerName: connectedModel.ExternalCustomer?.Name,
    SyncReservation: false,
    SyncUsage: false,
    StaffOnly: model.ServiceType?.StaffOnly || false,
    InstituteProjectId: connectedModel.InstituteProject?.Id,
    InstituteProjectName: connectedModel.InstituteProject?.Name,
    PurchaseOrder: connectedModel.PurchaseOrder,
    Reference: connectedModel.Reference,
    BudgetsTurnedOn: model.ServiceType?.BudgetsTurnedOn,
    DefaultQuantity: model.ServiceType?.DefaultQuantity,
    HideProjects: model.ServiceType?.HideProjects,
    IntQuantityOnly: model.ServiceType?.IntQuantityOnly,
    ServiceGroupId: model.ServiceType?.ServiceGroupId,
    AllowToUser: model.ServiceType?.AllowToUser,
    UnitTypeId: model.ServiceType?.UnitTypeID,
    Units: model.ServiceType?.UnitTypeName,
    TrackInventory: model.ServiceType?.TrackInventory,
    InventoryBatchesEnabled: model.ServiceType?.InventoryBatchesEnabled,
    InventoryBatchId: model.InventoryBatch?.Id,
    InventoryBatchName: model.InventoryBatch?.Name,
    InventoryBatchAmount: model.InventoryBatch?.Inventory,
    NotLessThanZero: model.ServiceType?.NotLessThanZero,
  } as IOtherServices;
  return data;
};
export const ConvertModelToUpdateEntity = (
  model: OtherServiceDetailsState,
  original: OtherServiceDetailsState,
  connectedModel: IConnectedFiltersDto,
) => {
  let data = {
    Id: original.Id,
    BudgetID: connectedModel.Budget?.Id || null,
    Budget: connectedModel.Budget?.Name,
    UserGroup: connectedModel.UserGroup?.Id || null,
    UserGroupName: connectedModel.UserGroup?.Name,
    BookedBy: connectedModel.User?.Id,
    UserDisplayName: connectedModel.User?.Name,
    ServiceTypeID: model.ServiceType?.Id,
    ServiceType: model.ServiceType?.Name,
    ServiceDate: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(connectedModel.StartTime),
    ),
    Quantity: Number(model.Quantity),
    DiscountFactor: Number(model.DiscountFactor ?? 1),
    Remarks: model.Remarks,
    FundingType: connectedModel.FundingType?.Id,
    BudgetExperimentId: connectedModel.BudgetExperiment?.Id,
    BudgetExperimentName: connectedModel.BudgetExperiment?.Name,
    ReservationId: model.ReservationId,
    UsageId: model.UsageId,
    ExternalCustomerId: connectedModel.ExternalCustomer?.Id,
    ExternalCustomerName: connectedModel.ExternalCustomer?.Name,
    SyncReservation: false,
    SyncUsage: false,
    StaffOnly: model.ServiceType?.StaffOnly || false,
    InstituteProjectId: connectedModel.InstituteProject?.Id,
    InstituteProjectName: connectedModel.InstituteProject?.Name,
    PurchaseOrder: connectedModel.PurchaseOrder,
    Reference: connectedModel.Reference,
    BudgetsTurnedOn: model.ServiceType?.BudgetsTurnedOn,
    DefaultQuantity: model.ServiceType?.DefaultQuantity,
    HideProjects: model.ServiceType?.HideProjects,
    IntQuantityOnly: model.ServiceType?.IntQuantityOnly,
    ServiceGroupId: model.ServiceType?.ServiceGroupId,
    AllowToUser: model.ServiceType?.AllowToUser,
    UnitTypeId: model.ServiceType?.UnitTypeID,
    Units: model.ServiceType?.UnitTypeName,
    TrackInventory: model.ServiceType?.TrackInventory,
    InventoryBatchesEnabled: model.ServiceType?.InventoryBatchesEnabled,
    InventoryBatchId: model.InventoryBatch?.Id,
    InventoryBatchName: model.InventoryBatch?.Name,
    InventoryBatchAmount: model.InventoryBatch?.Inventory,
    NotLessThanZero: model.ServiceType?.NotLessThanZero,
  } as IOtherServices;
  return data;
};
export const ConvertModelToUpdateRenewStockEntity = (
  model: RenewStockState,
  original: RenewStockState,
  autoGenerateBatch?: boolean,
) => {
  let data = {
    Id: original.Id,
    ServiceTypeID: model.ServiceType?.Id,
    ServiceType: model.ServiceType?.Name,
    ServiceDate: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(model.DateReceived),
    ),
    Quantity: Number(model.Quantity),
    Remarks: model.Remarks,
    DefaultQuantity: model.ServiceType?.DefaultQuantity,
    IntQuantityOnly: model.ServiceType?.IntQuantityOnly,
    ServiceGroupId: model.ServiceType?.ServiceGroupId,
    InventoryBatchId: model.InventoryBatch?.Id,
    InventoryBatchName: model.InventoryBatch?.Name,
    InventoryBatchesEnabled: model.ServiceType?.InventoryBatchesEnabled,
    RenewStockInventory: true,
    TrackInventory: model.ServiceType?.TrackInventory,
    NotLessThanZero: model.ServiceType?.NotLessThanZero,
    AutoGenerateNewBatches: autoGenerateBatch,
  } as IOtherServices;
  return data;
};
export const getDefaultService = (
  user: AuthenticatedUser | undefined,
  date: Date | string,
) => {
  return {
    Id: 0,
    BookedBy: user?.Id,
    UserDisplayName: user?.Name,
    BudgetID: null,
    Budget: null,
    BudgetExperimentId: null,
    BudgetExperimentName: null,
    AllowToUser: true,
    DiscountFactor: 1,
    Quantity: null,
    DefaultQuantity: null,
    ExternalCustomerId: null,
    ExternalCustomerName: null,
    FundingType: null,
    HideProjects: false,
    InstituteProjectId: null,
    InstituteProjectName: null,
    IntQuantityOnly: true,
    Mandatory: false,
    Remarks: null,
    ReservationId: null,
    ServiceDate: date,
    ServiceTypeID: 0,
    ServiceType: null,
    ServiceGroupId: 0,
    Units: null,
    UnitTypeId: null,
    UserGroup: null,
    UserGroupName: null,
    StaffOnly: false,
    Reference: null,
    PurchaseOrder: null,
    UsageId: null,
    HasCharges: false,
    TrackInventory: false,
    InventoryBatchesEnabled: false,
    NotLessThanZero: true,
    ReservationOverlappingInventory: null,
    BudgetsTurnedOn: false,
    InventoryBatchId: null,
    InventoryBatchName: null,
    ServiceGroup: null,
  } as IOtherServices;
};
export const getDefaultBatch = (date: Date | string) => {
  return {
    Id: 0,
    Name: dateUtils.shortDateFormat(date) ?? '',
    ExpirationDate: null,
    Inventory: 0,
    Lot: null,
    OfflineServiceTypeId: 0,
    OfflineServiceTypeName: '',
    PurchaseDate: date,
    PurchasePricePerUnit: null,
  } as IInventoryBatchDto;
};
export const ConvertConsumablesToInsertEntity = (
  services: IOtherServices[],
  connectedModel: IConnectedFiltersDto,
  discountFactor?: number | null,
  remarks?: string | null,
) => {
  return services.map(f => {
    let savedData: IOtherServices = {
      Id: f.Id,
      BudgetID: connectedModel.Budget?.Id || null,
      Budget: connectedModel.Budget?.Name ?? null,
      UserGroup: connectedModel.UserGroup?.Id || null,
      UserGroupName: connectedModel.UserGroup?.Name ?? null,
      BookedBy: connectedModel.User?.Id ?? null,
      UserDisplayName: connectedModel.User?.Name ?? null,
      ServiceTypeID: f.ServiceTypeID,
      ServiceType: f.ServiceType,
      ServiceDate: dateUtils.formatISO(
        dateUtils.dateOrStringToDate(connectedModel.StartTime),
      ),
      Quantity: Number(f.Quantity),
      DiscountFactor: Number(f.DiscountFactor),
      Remarks: f.Remarks,
      FundingType: connectedModel.FundingType?.Id ?? null,
      BudgetExperimentId: connectedModel.BudgetExperiment?.Id ?? null,
      BudgetExperimentName: connectedModel.BudgetExperiment?.Name ?? null,
      ReservationId: f.ReservationId,
      UsageId: f.UsageId,
      ExternalCustomerId: connectedModel.ExternalCustomer?.Id ?? null,
      ExternalCustomerName: connectedModel.ExternalCustomer?.Name ?? null,
      SyncReservation: false,
      SyncUsage: false,
      StaffOnly: f.StaffOnly || false,
      InstituteProjectId: connectedModel.InstituteProject?.Id ?? null,
      InstituteProjectName: connectedModel.InstituteProject?.Name ?? null,
      PurchaseOrder: connectedModel.PurchaseOrder ?? null,
      Reference: connectedModel.Reference ?? null,
      BudgetsTurnedOn: f.BudgetsTurnedOn,
      DefaultQuantity: f.DefaultQuantity,
      HideProjects: f.HideProjects,
      IntQuantityOnly: f.IntQuantityOnly,
      ServiceGroupId: f.ServiceGroupId,
      AllowToUser: f.AllowToUser,
      UnitTypeId: f.UnitTypeId,
      Units: f.Units,
      TrackInventory: f.TrackInventory,
      InventoryBatchesEnabled: f.InventoryBatchesEnabled,
      InventoryBatchId: f.InventoryBatchId,
      InventoryBatchName: f.InventoryBatchName,
      InventoryBatchAmount: f.InventoryBatchAmount,
      NotLessThanZero: f.NotLessThanZero,
      AutoGenerateNewBatches: false,
      InsertedAt: null,
      ServiceGroup: null,
      ServiceRate: 0,
      DepartmentId: null,
      HasCharges: false,
      InsertedBy: null,
      Institute: null,
      InstituteId: null,
      InstituteTypeId: null,
      Inventory: -1,
      Mandatory: false,
      RenewStockInventory: false,
      ReservationOverlappingInventory: null,
    };
    if (!isNullOrUndefined(discountFactor)) {
      let globalFactor = Number(discountFactor);
      let serviceFactor = Number(f.DiscountFactor);
      if (globalFactor !== serviceFactor) {
        if (serviceFactor === 0 || serviceFactor === 1) {
          savedData.DiscountFactor = globalFactor;
        }
      }
    }
    if (!isEmptyOrWhitespace(remarks)) {
      if (isEmptyOrWhitespace(f.Remarks)) {
        savedData.Remarks = remarks ?? null;
      }
    }

    return savedData;
  });
};
export const ConvertConsumablesToMilestoneCharge = (
  services: IOtherServices[],
  connectedModel: IConnectedFiltersDto,
  milestoneData: ConsumableMilestoneData,
  remarks?: string | null,
) => {
  return services.map(f => {
    return {
      ServiceRequestId: milestoneData.serviceRequestId,
      ServiceRequestMilestoneId: milestoneData.serviceRequestMilestoneId,
      OfflineServiceType: {
        Id: f.ServiceTypeID,
        Name: f.ServiceType,
      },
      Quantity: Number(f.Quantity),
      Remarks: isEmptyOrWhitespace(f.Remarks) ? remarks : f.Remarks,
      Date: dateUtils.formatISO(
        dateUtils.dateOrStringToDate(connectedModel.StartTime),
      ),
    };
  }) as Array<
    Partial<IRequestMilestoneManualChargeModel> & {
      ServiceRequestId: number;
      ServiceRequestMilestoneId: number;
    }
  >;
};
export const ConvertRenewStockToInsertEntity = (
  model: RenewStockState,
  autoGenerateBatch?: boolean,
) => {
  let data = {
    Id: model.Id,
    ServiceTypeID: model.ServiceType?.Id,
    ServiceType: model.ServiceType?.Name,
    ServiceDate: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(model.DateReceived),
    ),
    Quantity: Number(model.Quantity),
    Remarks: model.Remarks,
    InventoryBatchesEnabled: model.ServiceType?.InventoryBatchesEnabled,
    InventoryBatchId: model.InventoryBatch?.Id,
    InventoryBatchName: model.InventoryBatch?.Name,
    DefaultQuantity: model.ServiceType?.DefaultQuantity,
    IntQuantityOnly: model.ServiceType?.IntQuantityOnly,
    ServiceGroupId: model.ServiceType?.ServiceGroupId,
    TrackInventory: model.ServiceType?.TrackInventory,
    NotLessThanZero: model.ServiceType?.NotLessThanZero,
    AutoGenerateNewBatches: autoGenerateBatch,
    RenewStockInventory: true,
  } as IOtherServices;
  return data;
};
export const ConvertInventoryBatchToEntity = (model: InventoryBatchState) => {
  let data = {
    Id: model.Id,
    Name: model.Name,
    OfflineServiceTypeId: model.ServiceType?.Id,
    OfflineServiceTypeName: model.ServiceType?.Name,
    PurchaseDate: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(model.PurchaseDate ?? new Date()),
    ),
    ExpirationDate:
      model.ExpirationDate === null || model.ExpirationDate === undefined
        ? null
        : dateUtils.formatISO(
            dateUtils.dateOrStringToDate(model.ExpirationDate),
          ),
    Inventory: 0, //  model.Inventory,
    Lot: model.Lot,
    PurchasePricePerUnit: !!model.PurchasePricePerUnit
      ? Number(model.PurchasePricePerUnit)
      : null,
  } as IInventoryBatchDto;
  return data;
};
