import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import BooleanPickerFilter from 'app/components/BasicTable/Filters/BooleanPickerFilter';
import DatesRangeFilter from 'app/components/BasicTable/Filters/DatesRangeFilter';
import { SampleTypeFilter } from 'app/components/BasicTable/Filters/SampleTypeFilter';
import ServiceFilter from 'app/components/BasicTable/Filters/ServiceFilter';
import ServiceGroupFilter from 'app/components/BasicTable/Filters/ServiceGroupFilter';
import ServiceTypeFilter from 'app/components/BasicTable/Filters/ServiceTypeFilter';
import UserFilter from 'app/components/BasicTable/Filters/UserFilter';
import {
  GetBooleanEntity,
  GetBooleanOrUndefined,
  getDefaultFilterDatePeriod,
  GetNumberOrUndefined,
  GetPartialEntity,
  GetRawValue,
  GetStringOrUndefined,
  initExPickerValue,
  initPickerValue,
  presetDatesRangeToQueryString,
} from 'app/components/BasicTable/types/FilterParam';
import { getPeriodTypesEntity } from 'app/components/DatePeriods';
import { initOnlineServiceData } from 'app/components/pickers/AutocompletePickers/OnlineServicePicker';
import { initSampleTypeData } from 'app/components/pickers/AutocompletePickers/SampleTypePicker.index';
import { initServiceTypeData } from 'app/components/pickers/AutocompletePickers/ServiceTypePicker';
import { initUserData } from 'app/components/pickers/AutocompletePickers/UserPicker';
import { initServiceGroupsData } from 'app/components/pickers/ServiceGroupPicker';
import { translations } from 'locales/translations';
import { PresetDatesRange } from 'types/PresetDatesRange';
import { IInvoiceRow } from './Details/IInvoicRow';

export const GetFilters: GetPageFilters<IInvoiceRow> =
  (t, appSettings, user, settings) => search => {
    const params = new URLSearchParams(search);
    const res: IFilterSettings<IInvoiceRow>[] = [
      {
        displayNameKey: translations.ServiceGroup,
        queryStringSerializer: 'sgid',
        fieldName: 'ServiceGroupId',
        operator: ODataOperators.Equals,
        value: GetPartialEntity(
          GetNumberOrUndefined(GetRawValue(params, 'sgid')),
        ),
        component: ServiceGroupFilter,
        initData: initExPickerValue(initServiceGroupsData),
      },
      {
        fieldName: 'OnlineServiceId',
        queryStringSerializer: 'osid',
        displayNameKey: translations.OnlineService,
        operator: ODataOperators.Equals,
        component: ServiceFilter,
        value: GetPartialEntity(
          GetNumberOrUndefined(GetRawValue(params, 'osid')),
        ),
        initData: initPickerValue(initOnlineServiceData),
        urlKey: 'osid',
      },
      {
        fieldName: 'OfflineServiceTypeId',
        queryStringSerializer: 'ostid',
        displayNameKey: translations.OfflineServiceType,
        operator: ODataOperators.Equals,
        value: GetPartialEntity(
          GetNumberOrUndefined(GetRawValue(params, 'ostid')),
        ),
        component: ServiceTypeFilter,
        initData: initPickerValue(initServiceTypeData),
      },
      {
        fieldName: 'SampleServiceId',
        queryStringSerializer: 'ssid',
        displayNameKey: translations.SampleType,
        operator: ODataOperators.Equals,
        value: GetPartialEntity(
          GetNumberOrUndefined(GetRawValue(params, 'ssid')),
        ),
        component: SampleTypeFilter,
        initData: initPickerValue(initSampleTypeData),
      },
      {
        fieldName: 'UserId',
        queryStringSerializer: 'user',
        displayNameKey: translations.User,
        operator: ODataOperators.Equals,
        value: GetPartialEntity(
          GetStringOrUndefined(GetRawValue(params, 'user')),
        ),
        component: UserFilter,
        initData: initPickerValue(initUserData),
      },
      {
        fieldName: 'Date',
        displayNameKey: translations.RG_DatePeriod,
        operator: ODataOperators.Between,
        defaultValue: PresetDatesRange.fromPreset(
          getPeriodTypesEntity('ThisMonth'),
        ),
        value: getDefaultFilterDatePeriod('period', 'st', 'et', params),
        queryStringSerializer: presetDatesRangeToQueryString(
          'period',
          'st',
          'et',
        ),
        component: DatesRangeFilter,
        urlKey: 'period',
      },
      {
        fieldName: 'Amount',
        displayNameKey: translations.ShowZeroAmountRows,
        queryStringSerializer: 'showZeroAmountRows',
        operator: ODataOperators.Equals,
        value: GetBooleanEntity(
          GetBooleanOrUndefined(GetRawValue(params, 'showZeroAmountRows')),
          t,
        ),
        component: BooleanPickerFilter,
        customFilter: (value, setting) => {
          if (!!value && value['Id'] === true) {
            return 'true';
          }
          return new Condition(
            setting.fieldName,
            ODataOperators.NotEquals,
            0,
          ).toString();
        },
      },
    ];
    return res;
  };
