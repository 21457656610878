import { IUserAttributesDto } from 'api/odata/generated/entities/IUserAttributesDto';
import { AsyncDataState } from 'types/common';
import { IResponseType } from 'types/ResponseType';

/* --- STATE --- */
export interface UserAttributesState {
  createState: AsyncDataState<UserAttributesDetailsState>;
  updateState: AsyncDataState<UserAttributesDetailsState>;
  processing: boolean;
  SsoAttrSyncedWithUser?: boolean;
  UserAttrSyncedWithSso?: boolean;
}

export interface UserAttributesDetailsState {
  Id: number;
  Attribute?: string | null;
  AttributeLocalized: string;
  Disabled?: boolean | null;
  SSO?: boolean | null;
  SSOAttribute?: string | null;
  Mandatory?: boolean | null;
  RegistrationForm?: boolean | null;
  UserDetails?: boolean | null;
  UserSidePanel?: boolean | null;
  UserProfile?: boolean | null;
  Order?: number | null;
}

export interface UserAttributesDetailsQStringParameters {
  id?: string;
  attr?: string;
  attrL?: string;
  disabled?: string;
  sso?: string;
  ssoAttr?: string;
  mandatory?: string;
  regForm?: string;
  usrDetails?: string;
  usrSidePanel?: string;
  usrProfile?: string;
  order?: string;
  ssoAttrSync?: string;
  userAttrSync?: string;
}

export interface UserAttributesDetailsResponse extends IResponseType {
  Id?: number;
}
export type SsoAttrState =
  | 'NA'
  | 'Pass'
  | 'UserAttrMissing'
  | 'SsoAttrMissing'
  | 'AttrNotMutch';
export function ConvertModelToEntityUserAttributesDetails(
  entity: IUserAttributesDto,
): UserAttributesDetailsState | undefined {
  if (!entity) {
    return undefined;
  } else {
    return {
      Id: entity.Id,
      Attribute: entity.Attribute,
      AttributeLocalized: entity.AttributeLocalized,
      Disabled: entity.Disabled,
      SSO: entity.SSO,
      SSOAttribute: entity.SSOAttribute,
      Mandatory: entity.Mandatory,
      RegistrationForm: entity.RegistrationForm,
      UserDetails: entity.UserDetails,
      UserSidePanel: entity.UserSidePanel,
      UserProfile: entity.UserProfile,
      Order: entity.Order,
    } as UserAttributesDetailsState;
  }
}

export function ConvertEntityToModelUserAttributesDetails(
  model: UserAttributesDetailsState,
  original: UserAttributesDetailsState | undefined,
): IUserAttributesDto {
  let entity = {
    Id: original?.Id === undefined ? model.Id : original.Id,
    Attribute: model.Attribute,
    AttributeLocalized: model.AttributeLocalized,
    Disabled: model.Disabled,
    SSO: model.SSO,
    SSOAttribute: model.SSOAttribute,
    Mandatory: model.Mandatory,
    RegistrationForm: model.RegistrationForm,
    UserDetails: model.UserDetails,
    UserSidePanel: model.UserSidePanel,
    UserProfile: model.UserProfile,
    Order: model.Order,
  } as IUserAttributesDto;
  return entity;
}
