import * as log from 'loglevel';
import * as prefix from 'loglevel-plugin-prefix';

log.enableAll();

prefix.apply(log, {
  template: '[%t] %l (%n):',
  levelFormatter(level) {
    return level.toUpperCase();
  },
  nameFormatter(name) {
    return name || 'global';
  },
  timestampFormatter(date) {
    return date.toISOString();
  },
});

log.setDefaultLevel(process.env.NODE_ENV === 'development' ? 'DEBUG' : 'INFO');
log.info('%s prefix', 'template');

globalThis.logLevel = log;

export const getLogger = log.getLogger;
