/**
 *
 * NotAvailable
 *
 */
// import { Box, Grid, makeStyles, Theme, createStyles } from '@material-ui/core';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { Button } from 'app/components/BasicButtons/Button';
import { NotAvailableSVG } from 'app/components/BasicIcons/SvgIcons';
import { Body } from 'app/components/Typography';
import { translations } from 'locales/translations';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { toRootedURL } from 'utils/url-utils';
// import { ErrorsPageWrapper } from '../NotAllowedPage';

export interface NotAvailableProps {}

export function NotAvailable(props: NotAvailableProps) {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t(translations.NotAvailablePage)}</title>
        <meta name="description" content={t(translations.NotAvailablePage)} />
      </Helmet>
      <ErrorsPageWrapper>
        <div className="icon-section">
          <NotAvailableSVG />
        </div>

        <div className="content-section">
          <BasicTypography variant="h1">
            {' '}
            {t(translations.NotAvailablePage)}
          </BasicTypography>
          <span>
            <Body noWrap={false} component="p">
              {t(translations.NotAvailablePageDescription)}
            </Body>
          </span>

          <span>
            {' '}
            <Button
              variant="main"
              fullWidth={true}
              onClick={() => window.open(toRootedURL(`/`), '_self')}
            >
              {t(translations.GoBackToHome)}
            </Button>
          </span>
        </div>
      </ErrorsPageWrapper>
    </>
  );
}

export const ErrorsPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  @media (min-width: 600px) {
    flex-direction: row;
    gap: 64px;
  }
  & .icon-section {
    font-size: 200px;
    @media (min-width: 600px) {
      font-size: 400px;
      padding-right: 32px;
    }
  }
  & .content-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 320px;
    @media (min-width: 600px) {
      max-width: 420px;
    }
  }
`;
