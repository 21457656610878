import { ISavedViewDto } from 'api/odata/generated/entities/ISavedViewDto';
import { savedViewsApi } from 'api/savedViewsApi';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { SelectedRowsActionIconButton } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionIconButton';
import { ReservationCreateButton } from 'app/components/ReservationCreateButton';
import { AddWorkOrder } from 'app/pages/Actions/GlobalSelectedActions/AddWorkOrder';
import { BringBackUp } from 'app/pages/Actions/GlobalSelectedActions/BringBackUp';
import { DownWorkOrder } from 'app/pages/Actions/GlobalSelectedActions/DownWorkOrder';
import { OpenCalendar } from 'app/pages/Actions/GlobalSelectedActions/OpenCalendar';
import { OpenTimeline } from 'app/pages/Actions/GlobalSelectedActions/OpenTimeline';
import { OpenWorkOrders } from 'app/pages/Actions/GlobalSelectedActions/OpenWrokOrders';
import { RenderTypes } from 'app/pages/Actions/GlobalSelectedActions/ValidToRender/renderTypes';
import { useAssetsPermissions } from 'app/pages/Actions/GlobalSelectedActions/ValidToRender/useAssetsPermissionsUtils';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Roles } from 'api/odata/generated/enums/Roles';
import { translations } from 'locales/translations';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GetResponseMessages, IResponseType } from 'types/ResponseType';
import { useSavedViewSlice } from '../../slice';
import { SavedViewExtraData } from '../../slice/types';

export interface GetLeftTopActionsProps {
  savedView?: ISavedViewDto;
  isNew: boolean;
  useSidePanel?: boolean;
  extraData?: SavedViewExtraData;
}

type filterableArray<T> = Array<{ notShown?: boolean; value: T }>;

export const useLeftTopActions: (
  props: GetLeftTopActionsProps,
) => ActionRenderer[] = ({
  savedView,
  isNew,
  useSidePanel,
  extraData,
}: GetLeftTopActionsProps) => {
  const { t } = useTranslation();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const isAdmin = useMemo(() => {
    return authenticatedUser?.Roles.includes(Roles.Administrators);
  }, [authenticatedUser]);
  const isServiceGroupAdmin = useMemo(() => {
    return authenticatedUser?.Roles.includes(Roles.GroupAdministrators);
  }, [authenticatedUser]);
  const { allowedToView } = useAssetsPermissions();
  const rows = useMemo(
    () => (savedView === undefined ? [] : [savedView]),
    [savedView],
  );
  const dispatch = useDispatch();
  const { actions } = useSavedViewSlice();

  const useView = useCallback(() => {
    dispatch(actions.useSavedView(savedView?.Id));
  }, [actions, dispatch, savedView?.Id]);

  const result = useMemo(() => {
    if (savedView === undefined || isNew) return [];
    const res: filterableArray<ActionRenderer> = [
      {
        notShown:
          !allowedToView(savedView.Assets, RenderTypes.OpenCalendar) ||
          !savedView.Assets.some(a => a.BookableAsset),
        value: () => (
          <OpenCalendar
            rows={rows}
            getEquipmentIds={rows =>
              rows.flatMap(r =>
                r.Assets.map(a => a.ServiceId ?? 0).filter(f => f > 0),
              ) || []
            }
            startIcon={<Icon icon="add" />}
            primary
            bookMultiple={savedView.Multiple}
            onClick={useView}
          />
        ),
      },
      {
        notShown:
          !(isAdmin || isServiceGroupAdmin) ||
          !allowedToView(savedView.Assets, RenderTypes.AddWorkOrder),
        value: () => (
          <AddWorkOrder
            rows={rows}
            getAssets={rows =>
              new Promise((resolve, reject) => {
                resolve(rows.flatMap(a => a.Assets) || []);
              })
            }
            buttonName={t(translations.AddEvent)}
            primary={!savedView.Assets.some(a => a.BookableAsset)}
            useSidePanel={useSidePanel}
            onClick={useView}
          />
        ),
      },
      {
        value: submit => (
          <SelectedRowsActionIconButton
            key="delete_savedview"
            title={t(translations.Delete)}
            text={t(translations.Delete)}
            confirm={true}
            validConfirm={true}
            confirmTitle={t(translations.Delete) as string}
            rows={rows}
            onRefresh={() => submit?.()}
            variantButton="button"
            action={() =>
              new Promise<IResponseType>((resolve, reject) => {
                return savedViewsApi
                  .deleteSavedViews(rows)
                  .then(response => {
                    const messages: IResponseType = GetResponseMessages(
                      response,
                      t(translations.Success) as string,
                    );
                    resolve(messages);
                  })
                  .catch(error => {
                    console.log('Error: ', error);
                    let err = t(translations.Failed) as string;
                    reject(err);
                  });
              })
            }
          />
        ),
      },
      {
        notShown: !allowedToView(savedView.Assets, RenderTypes.OpenTimeline),
        value: () => (
          <OpenTimeline
            rows={rows}
            getEquipmentIds={rows =>
              rows.flatMap(r =>
                r.Assets.map(a => a.ServiceId ?? 0).filter(f => f > 0),
              ) || []
            }
          />
        ),
      },
      {
        notShown: !allowedToView(savedView.Assets, RenderTypes.NewReservation),
        value: () => (
          <ReservationCreateButton
            useSidePanel={useSidePanel}
            instruments={
              savedView.Assets.map(a => a.ServiceId ?? 0).filter(f => f > 0) ||
              []
            }
          >
            {t(translations.AddReservation)}
          </ReservationCreateButton>
        ),
      },
      {
        notShown:
          !(isAdmin || isServiceGroupAdmin) ||
          !allowedToView(savedView.Assets, RenderTypes.OpenWorkOrders),
        value: () => (
          <OpenWorkOrders
            rows={rows}
            getAssetIds={r => r.flatMap(a => a.Assets).map(a => a.Id) || []}
          />
        ),
      },
      {
        notShown:
          !(isAdmin || isServiceGroupAdmin) ||
          !allowedToView(savedView.Assets, RenderTypes.DownWorkOrder),
        value: () => (
          <DownWorkOrder
            rows={rows}
            getAssets={rows =>
              new Promise((resolve, reject) => {
                resolve(rows.flatMap(a => a.Assets) || []);
              })
            }
            buttonName={t(translations.TakeDown)}
            useSidePanel={useSidePanel}
          />
        ),
      },
      {
        notShown:
          !(isAdmin || isServiceGroupAdmin) ||
          !allowedToView(savedView.Assets, RenderTypes.BringBackUp),
        value: () => (
          <BringBackUp
            rows={rows}
            useSidePanel={useSidePanel}
            getAssetIds={r => r.flatMap(a => a.Assets).map(a => a.Id) || []}
            getEquipmentIds={rows =>
              rows.flatMap(r =>
                r.Assets.map(a => a.ServiceId ?? 0).filter(f => f > 0),
              ) || []
            }
          />
        ),
      },
    ];
    return res.filter(f => !f.notShown).map(f => f.value);
  }, [
    allowedToView,
    isAdmin,
    isNew,
    isServiceGroupAdmin,
    rows,
    savedView,
    t,
    useSidePanel,
    useView,
  ]);
  return result;
};
