/**
 *
 * OnlineBudgetsStatusPage
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { translations } from 'locales/translations';
import { useDispatch } from 'react-redux';
import { useOnlineBudgetsStatusSlice } from './slice';
import { useLocation } from 'react-router';
import { dateUtils } from 'utils/date-utils';
import { useColumns } from './columns';
import { usePageActions } from './pageActions';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import { ExternalLink } from 'app/components/ExternalLink';
import { useRowActions } from './useRowActions';
import { BasicTable } from 'app/components/BasicTable';
import { ScreensId } from 'enums/ConfigurableTypes';
import { withSavedHistoryT } from 'app/components/BasicTable/withSavedHistory';
import { GetFilters } from './Filter';
import { IBasePageProps } from '../IBasePageProps';
import { IInvoiceBudgetBalanceDto } from 'api/odata/generated/entities/IInvoiceBudgetBalanceDto';
import { getDefaultFilter } from './getDefaultFitlers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { push } from 'connected-react-router';
import { defaults } from 'lodash';
import { DetectIsMobile } from 'utils/mobileDetect';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { BookitDatePicker } from 'app/components/BookitDatePicker';

export interface OnlineBudgetsStatusPageParams {
  endDate: string;
}
export interface OnlineBudgetsStatusPageProps
  extends IBasePageProps<OnlineBudgetsStatusPageParams> {}

export const InvoiceBudgetBalanceKeyAccessor = (
  row: IInvoiceBudgetBalanceDto,
) => {
  return JSON.stringify({
    serviceGroupId: row.ServiceGroupId,
    budgetId: row.BudgetId,
  });
};
export function OnlineBudgetsStatusPage(props: OnlineBudgetsStatusPageProps) {
  const { t } = useTranslation();
  const params = defaults(props.match.params, {
    endDate: dateUtils.formatISO(new Date()),
  });
  const location = useLocation();
  const dispatch = useDispatch();
  const { actions } = useOnlineBudgetsStatusSlice();

  const endDate = React.useMemo(
    () => dateUtils.parseISO(params.endDate),
    [params.endDate],
  );
  const columns = useColumns(endDate);
  const rowActions = useRowActions();
  const endDateString = endDate == null ? null : dateUtils.formatISO(endDate);
  const memoedColumns = React.useMemo(() => columns, [columns]);
  const isMobile = DetectIsMobile();
  const pageActions = usePageActions({
    endDate: endDate as Date | null,
    isMobile,
  });

  React.useEffect(() => {
    dispatch(actions.setEndDate(endDateString));
  }, [actions, dispatch, endDateString]);

  const handleEndDateChange = React.useCallback(
    (value: MaterialUiPickersDate) => {
      if (value != null) {
        dispatch(
          push({
            pathname:
              '/OnlineBudgetsStatus/' +
              dateUtils.formatISO(value, {
                representation: 'date',
              }),
            search: location.search,
          }),
        );
      }
    },
    [dispatch, location.search],
  );
  const subHeader = (
    <>
      <BookitDatePicker
        value={endDate}
        onChange={handleEndDateChange}
        label={t(translations.EndDate)}
        id="billingDateId"
        ariaLabel="End Date"
      />
      <BasicTypography>
        {t(translations.OnlineBudgetsBillingUnfinishedUsagesRemark0)}
      </BasicTypography>
      <ExternalLink href="/usagehistory.aspx?isUsageOpen=true">
        {t(translations.OnlineBudgetsBillingUnfinishedUsagesRemark1)}
      </ExternalLink>
      <ExternalLink href="/EventsRaw.aspx?unfinishedUsages=true&charged=false">
        {t(translations.OnlineBudgetsBillingUnfinishedUsagesRemark2)}
      </ExternalLink>
      <InfoIcon
        title={
          t(translations.OnlineBudgetsBillingUnfinishedUsagesRemark4) as string
        }
      />
      <BasicTypography>
        {t(translations.OnlineBudgetsBillingUnfinishedUsagesRemark3)}
      </BasicTypography>
    </>
  );
  const rowAccessor = React.useCallback(InvoiceBudgetBalanceKeyAccessor, []);
  if (endDate == null) {
    return null;
  }
  const apiURL = '/api/odata/v4/InvoiceBudgetBalances';
  return (
    <>
      <OnlineBudgetsStatusTable
        api={{
          path: apiURL,
          search: {
            endDate: dateUtils.formatISO(endDate, {
              representation: 'date',
            }),
          },
        }}
        columns={memoedColumns}
        getRowId={rowAccessor}
        screenName={t(translations.BillingByProjectsOrCores)}
        screenId={ScreensId.OnlineBudgetsStatus}
        serviceGroups={props.serviceGroups}
        useRowSelect={true}
        pageActions={pageActions}
        rowActions={rowActions}
        subHeader={subHeader}
        onSelectedRowsChange={selected =>
          dispatch(actions.setSelected(selected))
        }
        additionalColumns={[
          'UserGroupId',
          'ServiceGroupId',
          'BudgetId',
          'Invoices',
        ]}
        searchColumns={[
          'ServiceGroup',
          'UserGroup',
          'UserGroupId',
          'UsergroupIDNO',
          'Budget',
          'HostBudgetNumber',
          'Department',
          'Institute',
          'InstituteType',
        ]}
      />
    </>
  );
}

const OnlineBudgetsStatusTable = withSavedHistoryT<IInvoiceBudgetBalanceDto>(
  BasicTable,
  '/OnlineBudgetStatus',
  GetFilters,
  getDefaultFilter,
  ['endDate'],
);
