import {
  IViewState,
  ViewLengths,
  ViewLengthsArray,
  ViewTypes,
} from '../slice/types';
import { assertExhaustive } from 'utils/assertExhaustive';
import {
  SchedulerDatePositionArray,
  SchedulerDatePositionUnion,
} from 'app/components/BasicTable/Filters/DatesPeriodFilter';
import { DatesRange } from 'types/DatesRange';
import { Timestamp } from 'types/Timestamp';
import { dateUtils } from 'utils/date-utils';

export type SchedulerPosition = SchedulerDatePositionUnion | ViewLengths;

export type SchedulerPositionState = Pick<
  IViewState,
  'viewLength' | 'date' | 'preset' | 'viewType'
>;

export const parsePosition = (
  s?: SchedulerPosition,
  d?: Timestamp,
  vt?: ViewTypes,
): SchedulerPositionState => {
  const result: SchedulerPositionState = {
    preset: 'ThisWeek',
    viewLength: 'week',
    date: dateUtils.formatISO(dateUtils.startOfWeek(new Date())),
    viewType: vt ?? 'calendar',
  };
  if (d !== undefined) {
    const parsedDate = dateUtils.tryParse(d);
    if (parsedDate !== null) {
      result.date = dateUtils.formatISO(parsedDate);
    }
  }

  if (s !== undefined) {
    // try parse preset period/position like nextweek
    const presetPosition: SchedulerDatePositionUnion | undefined =
      SchedulerDatePositionArray.find(f => f.toLowerCase() === s.toLowerCase());
    if (presetPosition !== undefined) {
      const preset = DatesRange.fromPreset(
        { Id: presetPosition, Name: 'dummy' },
        null,
        null,
      );
      if (preset.start !== null) {
        result.date = dateUtils.formatISO(preset.start);
      }
      result.viewLength = presetPeriodToViewLength(
        presetPosition,
        result.viewType,
      );
    }
    const viewLength: ViewLengths | undefined = ViewLengthsArray.find(
      f => f.toLowerCase() === s.toLowerCase(),
    );
    if (viewLength !== undefined) {
      result.viewLength = viewLength;
    }
  }
  return result;
};

export function presetPeriodToViewLength(
  preset: SchedulerDatePositionUnion,
  viewType: ViewTypes,
): ViewLengths {
  switch (preset) {
    case 'Today':
    case 'Yesterday':
    case 'Tomorrow':
      return 'day';
    case 'TodayTomorrow':
      return 'twodays';
    case 'LastWeek':
    case 'ThisWeek':
    case 'NextWeek':
      return 'week';
    case 'LastMonth':
    case 'ThisMonth':
    case 'NextMonth':
      return 'month';
    case 'LastQuarter':
    case 'ThisQuarter':
    case 'NextQuarter':
      return viewType === 'timeline' ? 'quarter' : 'week';
    case 'LastYear':
    case 'ThisYear':
    case 'NextYear':
      return viewType === 'timeline' ? 'year' : 'week';
    case 'CustomDate':
      return 'customdays';
    default:
      assertExhaustive(preset);
  }
}
