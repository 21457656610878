import { Dialog } from '@material-ui/core';
import { ImageRender } from 'app/components/AssetImagesGallery/ImageRender';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { H3 } from 'app/components/Typography';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useShowAssetImageSlice } from './slice';

export const ImagePopupWrapper = styled.div`
  &.image-root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 32px;
    & .image-header {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 16px 20px 0px 32px;
      & .image-header-content {
        display: flex;
        justify-content: space-between;
      }
    }
    & .image-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      overflow: auto;
    }
  }
`;

export interface AssetImagePopupProps {
  title: string;
  show: boolean;
  imagePath?: string;
  close: () => void;
}
export function AssetImagePopup(props: AssetImagePopupProps) {
  const dispatch = useDispatch();
  const { actions: showAssetImageActions } = useShowAssetImageSlice();
  const handleClose = React.useCallback(() => {
    dispatch(showAssetImageActions.setShow(false));
    props.close();
  }, [dispatch, showAssetImageActions, props]);
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={props.show}
      aria-labelledby={'image-dialog-popup'}
      fullScreen={true}
      fullWidth={true}
    >
      <ImagePopupWrapper className="image-root">
        <div className={'image-header'}>
          <div className={'image-header-content'}>
            <H3>{props.title}</H3>
            <IconButton aria-label="close" onClick={handleClose}>
              <Icon icon="times" />
            </IconButton>
          </div>
        </div>
        {props.imagePath !== undefined && props.imagePath !== '' && (
          <div className="image-content">
            <ImageRender imgPath={props.imagePath} size={'Large'} />
          </div>
        )}
      </ImagePopupWrapper>
    </Dialog>
  );
}

export const ShowImage = styled('div')(({ theme }) => ({
  '& .frame': {
    display: 'flex',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    '& .mediaStyle': {
      imageRendering: '-webkit-optimize-contrast',
      backgroundSize: 'auto',
      width: '100%',
      backgroundBlendMode: 'multiply',
      minWidth: 'unset',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));
