/**
 *
 * RichTextEditor
 *
 */
import * as React from 'react';
import { Entity, Identifiable } from 'types/common';
import { StartRichEditor } from './RichEditor';
import { useSelector } from 'react-redux';
import { selectglobalSettings } from 'app/slice/selectors';
import { AllowedSettings } from 'utils/globalSettings';

export interface RichTextEditorProps {
  value?: string | undefined;
  onChange: (value: string | undefined) => void;
  placeholder?: string;
  autofocus?: boolean;
  hasMentions?: boolean;
  onChangeMentions?: (mentions: Entity<string>[]) => void;
  additionalButtons?: ((classname, key) => React.ReactNode)[];
  disabled?: boolean;
  isInvoiceTemplateEditor?: boolean;
  extendedMode?: boolean;
  labelOnTop?: boolean;
  info?: NonNullable<React.ReactNode>;
  setControlsVisible?: (visible: boolean) => void;
  serviceGroups?: Identifiable<number>[];
}

export function RichTextEditor(props: RichTextEditorProps) {
  const globalSettings = useSelector(selectglobalSettings);
  const hasMentions = React.useMemo(() => {
    return (
      props.hasMentions &&
      !globalSettings.GetBooleanByKey(
        AllowedSettings.HideCommentSharingForAllUsers,
      )
    );
  }, [globalSettings, props.hasMentions]);
  return (
    <StartRichEditor
      initialValue={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      autoFocus={props.autofocus}
      hasMentions={hasMentions}
      onChangeMentions={props.onChangeMentions}
      additionalButtons={props.additionalButtons}
      disabled={props.disabled}
      isInvoiceTemplateEditor={props.isInvoiceTemplateEditor}
      extendedMode={props.extendedMode}
      info={props.info}
      labelOnTop={props.labelOnTop}
      setControlsVisible={props.setControlsVisible}
      serviceGroups={props.serviceGroups}
    />
  );
}
