import * as React from 'react';
import { FieldHookConfig, useField } from 'formik';
import { FormControl, FormGroup } from '@material-ui/core';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { HexColorPicker } from 'react-colorful';
import { InfoIcon } from '../BasicIcons/InfoIcon';
import { TextField, TextFieldProps } from '../TextField';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

export const FormColorPicker = ({
  label,
  name,
  onBlur,
  onChange,
  value,
  ...props
}: TextFieldProps & FieldHookConfig<string> & IFieldProps) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField<string>({ name, ...props });

  const handleChange = e => {
    if (onChange !== undefined) {
      onChange(e);
    }
    field.onChange(e);
  };

  const handleColorPickerChange = (e: string) => {
    helpers.setValue(e);
  };

  //RENDER
  return (
    <>
      <FormControl
        className={props.className}
        fullWidth={props.fullWidth}
        variant="outlined"
      >
        <FormGroup>
          <TextField
            name={name}
            label={label ?? t(translations[name])}
            fullWidth={props.fullWidth}
            variant="filled"
            onChange={handleChange}
            onBlur={field?.onBlur}
            value={field.value}
            error={meta?.error !== undefined}
            helperText={meta.error}
            id={props.id || 'textfieldId'}
            inputProps={{
              maxLength: 7,
              ...props.inputProps,
              'aria-label': `${label ? undefined : 'text input'}`,
            }}
            color="primary"
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.info ? <InfoIcon title={props.info} /> : null}
                  {props.InputProps?.endAdornment}
                </React.Fragment>
              ),
            }}
            {...props}
          />
          <HexColorPicker
            color={field.value}
            onChange={handleColorPickerChange}
          />
        </FormGroup>
      </FormControl>
    </>
  );
};

export const getRandomColor = () => {
  let options = '0123456789ABCDEF';
  let color = '#';
  for (let index = 0; index < 6; index++) {
    color += options[Math.floor(Math.random() * options.length)];
  }
  return color;
};
