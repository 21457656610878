import { FormLabel } from '@material-ui/core';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import FormControl from 'app/components/Forms/FormControls/FormControl';
import BasicTypography from 'app/components/Typography/BasicTypography';
import clsx from 'clsx';

export interface GhostInputProps {
  label?: React.ReactNode;
  valueHtml?: React.ReactNode;
  valueText?: React.ReactNode;
  id?: string;
  fullWidth?: boolean;
  info?: string;
  labelBolder?: boolean;
}
export function GhostInput(props: GhostInputProps) {
  const {
    label,
    valueHtml,
    valueText,
    info,
    labelBolder,
    fullWidth = true,
  } = props;

  return (
    <FormControl className={'ghostInput'} fullWidth={fullWidth}>
      <span className={'ghosInputText'}>
        {label && (
          <FormLabel
            className={clsx('ghosInputLabel', {
              ghosInputLabelBold: labelBolder,
            })}
          >
            {label} {info && <InfoIcon title={info} />}
          </FormLabel>
        )}
        {valueText && (
          <BasicTypography component={'span'} variant={'bodyS'}>
            {valueText}
          </BasicTypography>
        )}
        {valueHtml}
      </span>
    </FormControl>
  );
}
