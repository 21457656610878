import * as Cookies from 'js-cookie';

/**
 * Must match .net's counterpart in globalization middleware
 */
export const LOCALE_COOKIE_NAME = 'i18next';

export type LocaleId = string;

export function setCookieLocale(localeId: LocaleId) {
  Cookies.set(LOCALE_COOKIE_NAME, localeId);
}

export function getCookieLocale() {
  // running in test environment
  if (Cookies.get === undefined) {
    return undefined;
  }
  return Cookies.get(LOCALE_COOKIE_NAME);
}
