/**
 *
 * RemindBeforPicker
 *
 */

import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import { RemindBefore } from 'enums/RemindBefore';
import { Entity } from 'types/common';
import { enumToEntityArray } from 'utils/enumKeys';

export interface RemindBeforePickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {}
export const getSingleReminder = (val: number): Entity<number> => {
  return enumToEntityArray(RemindBefore).filter(item => {
    return item.Id === val;
  })[0];
};
export const initRemindBeforeData = (
  initval: number | undefined,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([getSingleReminder(initval)]);
    });
  }
};
const getOptions = (searchTerm: string | null) => {
  let items = enumToEntityArray(RemindBefore);

  if (searchTerm !== null && searchTerm !== '') {
    return items.filter(
      f => f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
    );
  }
  return items;
};
export function RemindBeforePicker(props: RemindBeforePickerProps) {
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      value={props.value}
      id={props.id || 'RemindBeforeId'}
      {...props}
    />
  );
}
