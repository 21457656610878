/**
 *
 * ServiceRequestMilestones
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { AddonTypesUnion } from 'api/odata/generated/enums/AddonTypes';
import { IServiceRequestMilestoneDto } from 'api/odata/generated/entities/IServiceRequestMilestoneDto';
import { InternalServiceRequestStatusesUnion } from 'enums/InternalServiceRequestStatuses';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import produce from 'immer';
import { useDispatch, useSelector } from 'react-redux';
import { useRequestSamplesSlice } from '../../RequestSamplesPage/slice';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { ServiceRequestMilestoneDetailsProps } from '../ServiceRequestMilestoneDetails';
import { sum } from 'lodash';
import { ServiceRequestAddons } from '../ServiceRequestAddons';
import { ServiceRequestMilestoneStatusesUnion } from 'api/odata/generated/enums/ServiceRequestMilestoneStatuses';
import { dateUtils } from 'utils/date-utils';
import { TruncatedDescriptionText } from 'app/components/Description';
import { ServiceRequestMilestoneStatusMenuPicker } from 'app/components/pickers/StaticOptionsPickers/ServiceRequestMilestoneStatusMenuPicker';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { TableToolbar } from 'app/components/BasicTable/BasicFilter/TableToolbar';
import clsx from 'clsx';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { Body } from 'app/components/Typography';
import { Printing } from 'app/components/PrintToPDF';
import { InvoiceDetailsLink } from 'app/pages/Actions/GlobalSelectedActions/OpenInvoiceDetails';
import { roundTwoPlacesAfterDecimalPoint } from 'utils/number-utils';
import { TableRoot } from 'app/components/BasicTable/styled';
import { Entity } from 'types/common';
import { getNewMilestone } from '../Utils';
import { FlexRow8 } from 'app/components/StyledComponents';
import {
  selectLastInsertedMilestoneId,
  selectRequestDetailsData,
  selectServiceRequestMilestoneProcessing,
} from '../../RequestSamplesPage/slice/selectors';
import { getConsumableIsMandatory } from '../ServiceRequestAddons/utils';
import BasicTypography from 'app/components/Typography/BasicTypography';

export interface ServiceRequestMilestonesProps {
  milestones?: Array<IServiceRequestMilestoneDto>;
  isAdmin: boolean;
  AddonType: AddonTypesUnion;
  InternalStatus: InternalServiceRequestStatusesUnion;
  ServiceRequestId: number;
  pageName?: string;
  tableContainerAsSection?: boolean;
  // indicates if this component is opened through side panel
  printing?: Printing;
  useSidePanel: boolean;
  UnitType?: Entity<number>;
  ServiceTypeId: number;
}

interface IState {
  expanded: Record<number, boolean>;
}

export function ServiceRequestMilestones({
  milestones,
  pageName,
  tableContainerAsSection,
  useSidePanel,
  ServiceTypeId,
  ...props
}: ServiceRequestMilestonesProps) {
  const { t } = useTranslation();
  const [state, , extendState] = useAsyncExtendedState<IState>({
    expanded: {},
  });
  const { checkIsMobile } = useIsMobile();
  const isMobile = checkIsMobile();
  const dispatch = useDispatch();
  const { actions } = useRequestSamplesSlice();
  const lastInsertedMilestone = useSelector(selectLastInsertedMilestoneId);
  const milestoneInserting = useSelector(
    selectServiceRequestMilestoneProcessing,
  );
  const requestDetailsData = useSelector(selectRequestDetailsData);
  const [insertMilestone, setInsertMilestone] = React.useState(false);
  const showOfflineServicesError = React.useMemo(() => {
    let mandatory =
      !!requestDetailsData?.ConsumableServices &&
      requestDetailsData?.ConsumableServices.length > 0
        ? requestDetailsData?.ConsumableServices.filter(f =>
            getConsumableIsMandatory(f, props.isAdmin, props.InternalStatus),
          )
        : [];

    return (
      mandatory.length > 0 &&
      !!milestones &&
      milestones.length > 0 &&
      milestones.filter(
        m =>
          mandatory.filter(
            ext =>
              !m.ServiceRequestMilestoneCharges ||
              m.ServiceRequestMilestoneCharges.length === 0 ||
              (!!m.ServiceRequestMilestoneCharges &&
                m.ServiceRequestMilestoneCharges.length > 0 &&
                !m.ServiceRequestMilestoneCharges.some(
                  f => f.OfflineServiceTypeId === ext.Id,
                )),
          ).length > 0,
      ).length > 0
    );
  }, [
    milestones,
    props.InternalStatus,
    props.isAdmin,
    requestDetailsData?.ConsumableServices,
  ]);
  const handleToggleClick = React.useCallback(
    (id: number, expand: boolean) => {
      extendState(state => {
        const n = produce(expanded => {
          expanded[id] = expand;
        })(state.expanded);

        return { expanded: n };
      });
    },
    [extendState],
  );
  // const handleAddMilestoneClick = React.useCallback(() => {
  //   const sidePanelProps: ServiceRequestMilestoneDetailsProps = {
  //     serviceRequestId: props.ServiceRequestId,
  //     type: 'Insert',
  //   };
  //   dispatch(
  //     actions.openSidePanel({
  //       type: RenderPageType.ServiceRequestMilestone,
  //       props: sidePanelProps,
  //     }),
  //   );
  // }, [actions, dispatch, props.ServiceRequestId]);
  React.useEffect(() => {
    if (lastInsertedMilestone !== undefined && insertMilestone) {
      const milestone = milestones?.find(f => f.Id === lastInsertedMilestone);
      if (milestone !== undefined) {
        handleToggleClick(milestone.Id, true);
        milestones
          ?.filter(f => f.Id !== lastInsertedMilestone)
          .forEach(f => {
            handleToggleClick(f.Id, false);
          });
      }
      setInsertMilestone(false);
    }
  }, [handleToggleClick, insertMilestone, lastInsertedMilestone, milestones]);

  // Insert new milestone with default values and a new name
  const handleInsertNewMileastone = React.useCallback(() => {
    const newMilestone = getNewMilestone(props.ServiceRequestId, milestones);
    dispatch(actions.insertMilestone(newMilestone));
    setInsertMilestone(true);
  }, [actions, dispatch, milestones, props.ServiceRequestId]);

  const handleEditClick = React.useCallback(
    (milestone: IServiceRequestMilestoneDto) => {
      const sidePanelProps: ServiceRequestMilestoneDetailsProps = {
        serviceRequestId: props.ServiceRequestId,
        type: 'Update',
        value: milestone,
      };
      dispatch(
        actions.openSidePanel({
          type: RenderPageType.ServiceRequestMilestone,
          props: sidePanelProps,
        }),
      );
    },
    [actions, dispatch, props.ServiceRequestId],
  );
  // const handleManageOfflineRefsClick = React.useCallback(() => {
  //   let sidePanelProps: OfflineTypeReferencesPopUpProps = {
  //     useSidePanel: true,
  //     refId: ServiceTypeId,
  //     refTypeId: OfflineReferenceTypeEnum.Offline_ServiceRequest,
  //   };

  //   dispatch(
  //     actions.openSidePanel({
  //       type: RenderPageType.OfflineTypeRefs,
  //       props: sidePanelProps,
  //       expanded: true,
  //     }),
  //   );
  // }, [ServiceTypeId, actions, dispatch]);
  const handleDeleteClick = React.useCallback(
    (milestone: IServiceRequestMilestoneDto) => {
      dispatch(actions.deleteMilestone(milestone));
    },
    [actions, dispatch],
  );
  const columnsCount = 9;
  const showActionsColumn = React.useMemo(
    () =>
      props.isAdmin &&
      milestones?.some(milestone => (milestone.InvoiceId ?? 0) === 0),
    [milestones, props.isAdmin],
  );
  const colSpan = React.useMemo(
    () => columnsCount + (showActionsColumn ? 1 : 0),
    [showActionsColumn],
  );
  const handleStatusChange = (
    milestone: IServiceRequestMilestoneDto,
    value: ServiceRequestMilestoneStatusesUnion | null,
  ) => {
    if (value !== null) {
      dispatch(
        actions.updateMilestone({
          ...milestone,
          ...{ Status: value },
        }),
      );
    }
  };
  const print = React.useMemo(() => {
    return props.printing !== undefined && props.printing.printing === true;
  }, [props.printing]);
  return (
    <TableRoot
      className={clsx('table-root', {
        'section-table': tableContainerAsSection,
      })}
      key="milestone-table-container"
      id={`milestone_table_container`}
    >
      <TableToolbar
        screenName={pageName ?? t(translations.Milestones)}
        screenNameVariant={'section'}
        showAlert={undefined}
        alertContent={undefined}
        showMenu={false}
        compact={undefined}
        titleAsColumn={true}
        titleExtended={
          <React.Fragment>
            <Body bold size="small">
              {milestones?.length} {t(translations.Records)}
            </Body>
            {showOfflineServicesError && (
              <BasicTypography variant="captionBold" color="error">
                {t(translations.RequestOfflineServicesRequired) as string}
              </BasicTypography>
            )}
          </React.Fragment>
        }
      >
        {print === false ? (
          <>
            {props.isAdmin && (
              <React.Fragment>
                <span>
                  <TopActionButton
                    variant="main"
                    size="small"
                    startIcon="plus"
                    icon="plus"
                    processing={milestoneInserting}
                    onClick={handleInsertNewMileastone}
                    text={t(translations.Milestone)}
                  />
                </span>
                {/* <span>
                  <TopActionButton
                    variant="white"
                    size="small"
                    startIcon="cog"
                    icon="cog"
                    text={t(translations.ManageRelatedService)}
                    onClick={handleManageOfflineRefsClick}
                  />
                </span> */}
              </React.Fragment>
            )}
          </>
        ) : (
          <></>
        )}
      </TableToolbar>
      <div
        id={`milestone_content`}
        className={clsx('table-content', {
          'table-content-short': isMobile,
        })}
      >
        <div className={'table-scrolling'}>
          <Table
            id={'milestone_table'}
            className={'table'}
            role="table"
            aria-label={'milestones'}
          >
            <TableHead>
              <TableRow>
                <TableCell component="th"></TableCell>
                <TableCell component="th">
                  {t(translations.Milestone)}
                </TableCell>
                <TableCell component="th">
                  {t(translations.TargetDate)}
                </TableCell>
                <TableCell component="th">{t(translations.Total)}</TableCell>
                <TableCell component="th">
                  {t(translations.AssignedTo)}
                </TableCell>
                <TableCell component="th">
                  {t(translations.CompletionDate)}
                </TableCell>
                <TableCell component="th">
                  {t(translations.Description)}
                </TableCell>
                <TableCell component="th">
                  {t(translations.InvoiceId)}
                </TableCell>
                <TableCell component="th">
                  {t(translations.MilestoneStatus)}
                </TableCell>
                {showActionsColumn && (
                  <TableCell component="th">
                    {t(translations.Actions)}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {milestones?.map(milestone => {
                const isExpanded =
                  state.expanded[milestone.Id] === true || print;

                return (
                  <React.Fragment key={`milestone-${milestone.Id}`}>
                    <TableRow key={`milestone-${milestone.Id}`}>
                      {!print && (
                        <TableCell>
                          <IconButton
                            variant="ghost"
                            size="small"
                            onClick={() =>
                              handleToggleClick(milestone.Id, !isExpanded)
                            }
                          >
                            <Icon
                              icon={isExpanded ? 'chevron-up' : 'chevron-right'}
                            />
                          </IconButton>
                        </TableCell>
                      )}

                      <TableCell>{milestone.Name}</TableCell>
                      <TableCell>
                        {dateUtils.shortDateFormat(milestone.TargetDate)}
                      </TableCell>
                      <TableCell>
                        {roundTwoPlacesAfterDecimalPoint(
                          sum(
                            milestone.ServiceRequestMilestoneCharges.map(
                              f => f.Amount ?? 0,
                            ),
                          ),
                        )}
                      </TableCell>
                      <TableCell>
                        {milestone.Assignees?.map(f => f.Name)?.join(', ')}
                      </TableCell>
                      <TableCell>
                        {dateUtils.shortDateFormat(milestone.CompletionDate)}
                      </TableCell>
                      <TableCell>
                        <TruncatedDescriptionText
                          value={milestone.Description}
                        />
                      </TableCell>
                      <TableCell>
                        {milestone.InvoiceId !== null && (
                          <InvoiceDetailsLink
                            id={milestone.InvoiceId}
                            useSidePanel={useSidePanel}
                          >
                            {milestone.InvoiceId}
                          </InvoiceDetailsLink>
                        )}
                      </TableCell>
                      <TableCell>
                        {milestone.Status !== null &&
                          (props.isAdmin &&
                          milestone.InvoiceId === null &&
                          !print ? (
                            <ServiceRequestMilestoneStatusMenuPicker
                              value={milestone.Status}
                              onChange={value =>
                                handleStatusChange(milestone, value)
                              }
                            />
                          ) : (
                            t(milestone.Status)
                          ))}
                      </TableCell>

                      {showActionsColumn && !print && (
                        <TableCell>
                          <FlexRow8>
                            <IconButton
                              variant="ghost"
                              key={`edit-milestone-${milestone.Id}`}
                              onClick={() => handleEditClick(milestone)}
                            >
                              <Icon icon="pencil-alt" />
                            </IconButton>
                            <IconButton
                              variant="ghost"
                              key={`delete-milestone-${milestone.Id}`}
                              onClick={() => handleDeleteClick(milestone)}
                            >
                              <Icon icon="trash" />
                            </IconButton>
                          </FlexRow8>
                        </TableCell>
                      )}
                    </TableRow>
                    {isExpanded &&
                      milestone &&
                      milestone.ServiceRequestMilestoneCharges &&
                      (!print ||
                        (print &&
                          milestone.ServiceRequestMilestoneCharges.length >
                            0)) && (
                        <TableRow key={`milestone-charges-row-${milestone.Id}`}>
                          <TableCell
                            className="services-cell"
                            colSpan={colSpan}
                          >
                            <ServiceRequestAddons
                              isAdmin={props.isAdmin}
                              milestone={milestone}
                              AddonType={props.AddonType}
                              InternalStatus={props.InternalStatus}
                              charges={milestone.ServiceRequestMilestoneCharges}
                              useSidePanel={useSidePanel}
                              UnitType={props.UnitType}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </TableRoot>
  );
}
