import { IEquipmentAccServiceDto } from 'api/odata/generated/entities/IAccServiceDto';
import { DraftReservationTypes } from 'enums/DraftReservationTypes';
import { GlobalSettingsType } from '../components/useGlobalSettingsHook';
import {
  IMandatoryEquipmentsDto,
  IReservationEquipmentDto,
  ServiceSettingsState,
} from './types';
import { combineOfflineServiceTypes } from './combineOfflineServiceTypes';
import { AllowReservationCollaboratorsTypes } from 'api/odata/generated/enums/AllowReservationCollaboratorsTypes';
import { getBudgetVisible } from './getBudgetVisible';
import { groupBy, values } from 'lodash';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';

export function mapSettingsFromServices(
  equipments: IReservationEquipmentDto[],
  accServices: IEquipmentAccServiceDto[],
  globalSettings: GlobalSettingsType,
  authenticatedUser: AuthenticatedUser,
  settings?: ServiceSettingsState,
): ServiceSettingsState {
  const p: Partial<ServiceSettingsState> = {
    HasHideProjects: equipments.every(f => f.HideProjects),
    HasConflictProjects:
      equipments.some(f => f.HideProjects === true) &&
      equipments.some(f => f.HideProjects === false),
    HasMultipleProjects:
      globalSettings.allowPartialProjectCharges &&
      equipments.every(f => f.AllowPartialProjectCharges === true),
    HasPartialSingleProject:
      globalSettings.allowPartialSingleProjectCharges &&
      equipments.every(f => f.AllowPartialSingleProjectCharges === true),
    AllOffServicesAllowed: equipments.every(
      f => f.ReservationOfflineServicesEnabled === null,
    ),
    OfflineServicesVisible: equipments.every(
      f =>
        f.ReservationOfflineServicesEnabled === true ||
        f.ReservationOfflineServicesEnabled === null,
    ),
    AllowReservationCollaborators:
      globalSettings.hideReservationCollaborators !== true &&
      equipments.every(f =>
        [
          AllowReservationCollaboratorsTypes.AllowedToStartUsage,
          AllowReservationCollaboratorsTypes.NotAllowedToStartUsage,
        ].includes(f.AllowReservationCollaborators),
      ),
    budgetExperimentVisible:
      globalSettings.budgetExperimentModuleEnabled &&
      equipments.some(f => f.BudgetExperimentSettings),
    TrainingSignUpApproval:
      equipments.length === 1
        ? equipments[0].TrainingSignUpApproval ?? 1
        : undefined,
    HideTrainingSession: equipments.every(f => f.HideTrainingSession === true),
    HasAttendance: equipments.every(f => f.HasAttendance === true),
    ForceTutoring: equipments.some(
      f =>
        f.ForceTutoring === true &&
        authenticatedUser.IsAdminOf({
          ServiceGroupId: f.ServiceGroupId,
          ServiceId: f.Id,
          ServiceTypeId: ServiceType.Online,
        }) === false,
    ),
    ForceFullDayReservations: equipments.some(f => f.ForceFullDayReservations),
    IsEquipmentTutoring: equipments.some(f => f.IsEquipmentTutoring === true),
    NotAllowReservations: equipments.some(f => f.NotAllowReservations === true),
    AllowOneAccSerOnly: equipments.some(f => f.AllowOneAccSerOnly === true),
    IsAllAdmin: equipments.every(
      f =>
        authenticatedUser.IsAdminOf({
          ServiceGroupId: f.ServiceGroupId,
          ServiceId: f.Id,
          ServiceTypeId: ServiceType.Online,
        }) === true,
    ),
    RestrictReservationToTimeSlots: equipments.every(
      f => f.RestrictReservationToTimeSlots === true,
    ),
    DefaultReservationDuration:
      equipments.length > 0
        ? equipments
            .map(f => f.DefaultReservationDuration)
            .sort((a, b) => a - b)[0]
        : 1,
    MinOrderHours:
      equipments.length > 0
        ? equipments.map(f => f.MinOrderHours).sort((a, b) => a - b)[
            equipments.length - 1
          ]
        : 1,
    MaxOrderHours:
      equipments.length > 0
        ? accServices.length > 0 &&
          accServices.some(f => f.MaxOrderDuration !== null)
          ? accServices
              .map(f => f.MaxOrderDuration ?? 0)
              .sort((a, b) => a - b)[0]
          : equipments.map(f => f.MaxOrderHours).sort((a, b) => a - b)[0]
        : equipments.map(f => f.MaxOrderHours).sort((a, b) => a - b)[0],

    MinOrderGap:
      equipments.length > 0
        ? accServices.length > 0 &&
          accServices.some(f => f.MinOrderGap !== null)
          ? accServices.map(f => f.MinOrderGap ?? 0).sort((a, b) => a - b)[0]
          : equipments.map(f => f.MinOrderGap).sort((a, b) => a - b)[0]
        : equipments.map(f => f.MinOrderGap).sort((a, b) => a - b)[0],
    RestrictDurationUnitsAmount:
      equipments.length > 0
        ? equipments
            .map(f => f.RestrictDurationUnitsAmount ?? 0)
            .filter(f => f !== 0)
            .sort((a, b) => a - b)[equipments.length - 1]
        : null,
    RestrictDurationUnitType:
      equipments.length > 0
        ? equipments
            .map(f => f.RestrictDurationUnitType ?? 0)
            .filter(f => f !== 0)
            .sort((a, b) => a - b)[equipments.length - 1]
        : null,
    DefaultAccServiceId:
      equipments.length > 0
        ? equipments.every(
            f =>
              f.DefaultAccServiceId === equipments[0].DefaultAccServiceId ?? -1,
          )
          ? equipments[0].DefaultAccServiceId || null
          : null
        : null,
    BudgetsTurnedOn: equipments.some(f => f.BudgetsTurnedOn === true),
    Billable: equipments.some(f => f.Billable === true),
    IsLoanDesc: equipments.some(f => f.LoanTypeId === 1),
    AllowModification: globalSettings.allowOngoingReservationModificationEnabled
      ? equipments
          .filter(f => f.NotAllowReservations === false)
          .every(f => f.AllowModification === true)
      : false,
    AllowExtendReservation:
      globalSettings.allowOngoingReservationModificationEnabled
        ? equipments
            .filter(f => f.NotAllowReservations === false)
            .every(f => f.AllowExtendReservation === true)
        : false,
    AllowUsersToShortenReservations: equipments.every(
      f => f.AllowUsersToShortenReservations === true,
    ),
    AllowedToTransfer: equipments.every(f => f.AllowedToTransfer === true),
    AllowedToTransferAll: equipments.every(
      f => f.AllowedToTransferAll === true,
    ),
    EnforceReservationRestrictions: equipments.every(
      f => f.EnforceReservationRestrictions === true,
    ),
    LimitlessReservationAllowed: equipments.every(
      f => f.LimitlessReservationAllowed === true,
    ),
    BudgetVisible: getBudgetVisible(
      globalSettings,
      equipments.map(service => ({
        Billable: service.Billable,
        BudgetsTurnedOn: service.BudgetsTurnedOn,
        HideProjects: service.HideProjects,
        ServiceTypeId: ServiceType.Online,
      })),
    ),
    OfflineServiceTypes: combineOfflineServiceTypes(equipments),

    Groups:
      equipments.length > 0
        ? equipments
            .filter((item, index, arr) => {
              return (
                arr.findIndex(t => t.ServiceGroupId === item.ServiceGroupId) ===
                index
              );
            })
            .map(f => f.ServiceGroupId)
        : [],
    HasAccServices:
      equipments.length > 0 && equipments.some(f => f.AccServices?.length > 0),
    StaffUserVisibleAllways:
      equipments.length > 0 &&
      equipments.some(f => f.StaffUserSelectionVisible === true),
    StaffUserVisibleOnPending:
      equipments.length > 0 &&
      equipments.some(f => f.StaffUserSelectionVisible === false),
    StaffUserRequiredAlwais:
      equipments.length > 0 &&
      equipments.some(f => f.StaffUserSelectionRequired === true),
    StaffUserRequiredOnPending:
      equipments.length > 0 &&
      equipments.some(f => f.StaffUserSelectionRequired === false),
    StaffUserSelection:
      equipments.length > 0 &&
      equipments.some(f => f.StaffUserSelection?.length > 0)
        ? equipments
            .filter(d => d.StaffUserSelection?.length > 0)
            .reduce((acc, next) => {
              return acc.concat(
                next.StaffUserSelection?.filter(
                  f => !acc.some(c => c.Id === f.Id),
                ),
              );
            }, equipments.filter(d => d.StaffUserSelection?.length > 0)[0].StaffUserSelection)
        : [],
    RequireUserGroupCoordApproval:
      equipments.length > 0 &&
      equipments.some(f => f.RequireUserGroupCoordApproval === true),
    DraftForAll:
      equipments.length > 0 &&
      equipments.every(
        f =>
          f.DraftReservationsTypeId ===
          DraftReservationTypes.DraftReservationTypes_AllUser,
      ),
    DraftApprovalRequred:
      equipments.length > 0 &&
      equipments.every(
        f =>
          f.DraftReservationsTypeId ===
          DraftReservationTypes.DraftReservationTypes_ApprovalRequired,
      ),
    DraftSaveForLater:
      equipments.length > 0 &&
      equipments.every(
        f =>
          f.DraftReservationsTypeId ===
          DraftReservationTypes.DraftReservationTypes_SaveForLater,
      ),
  };
  const result = Object.assign({}, settings, p);
  return result;
}
export function combineOptionalEquipments(
  services: IMandatoryEquipmentsDto[] | undefined,
): IMandatoryEquipmentsDto[] | undefined {
  if (services === undefined) {
    return undefined;
  }
  return values(groupBy(services, f => f.Id)).map(f => {
    return f[0];
  });
}
