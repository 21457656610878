import { ICharge } from 'app/pages/AddChargePage/ICharge';
import { IResponseType } from 'types/ResponseType';
import { httpClient } from './HttpClient';
const url = '/api/odata/v4/Charge';
export const ChargeApi = {
  AddCharge: (charge: ICharge) => {
    const data = {
      Id: charge.Id,
      Username: charge.User?.Id,
      BudgetId: charge.Budget?.Id,
      EquipmentId: charge.Resource?.Id,
      OfflineServiceTypeId: charge.OtherService?.Id,
      Quantity: charge.Quantity,
      BaseRate: charge.Rate,
      CatRateTypeId: charge.FeeType?.Id,
      Date: charge.Date,
      SampleTypeId: charge.SampleType?.Id,
      ServiceGroupId: charge.ServiceGroup?.Id,
      UserGroupId: charge.UserGroup?.Id,
      Remarks: charge.Remarks,
      PurchaseOrder: charge.PurchaseOrder,
    };
    return httpClient.post(url, data);
  },
  UpdateCharge: (charge: ICharge, rowId: number) => {
    const data = {
      Id: charge.Id,
      Username: charge.User?.Id,
      BudgetId: charge.Budget?.Id,
      EquipmentId: charge.Resource?.Id,
      OfflineServiceTypeId: charge.OtherService?.Id,
      Quantity: charge.Quantity,
      BaseRate: charge.Rate,
      CatRateTypeId: charge.FeeType?.Id,
      Date: charge.Date,
      SampleTypeId: charge.SampleType?.Id,
      ServiceGroupId: charge.ServiceGroup?.Id,
      ActualRate: charge.ActualRate,
      UserGroupId: charge.UserGroup?.Id,
      Remarks: charge.Remarks,
      PurchaseOrder: charge.PurchaseOrder,
    };
    return httpClient.put<any, IResponseType>(url + '(' + rowId + ')', data);
  },
};
