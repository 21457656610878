import * as React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SubscribersDetailsState } from '../slice/types';
import { translations } from 'locales/translations';
import { useSystemDate } from 'app/hooks/useSystemDate';
import {
  StyledForm,
  FormLeftSection,
  FormFieldsSection,
  FormRightSection,
  FormRow,
} from 'app/components/Forms/FormsLayout';
import { Formik, FormikProps } from 'formik';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { FormBookitDatePicker } from 'app/components/Forms/FormBookitDatePicker';
import { FormFundingTypePicker } from 'app/components/Forms/FormFundingTypePicker';
import { FormBudgetPicker } from 'app/components/Forms/FormBudgetPicker';
import { FormSubscriptionTypesPicker } from 'app/components/Forms/FormSubscriptionTypesPicker';
import { FormNumberField } from 'app/components/Forms/FormNumberField';
import { FormServiceUserGroupPicker } from 'app/components/Forms/FormServiceUserGroupPicker';
import { FormUserPicker } from 'app/components/Forms/FormUserPicker';
import {
  SubscriptionForOption,
  SubscriptionForOptions,
} from './SubscriptionForOptions';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { FormLabel } from '@material-ui/core';
import { FormTextField } from 'app/components/Forms/FormTextField';
import useGlobalSettingsHook from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { restrictedBudgetFilter } from 'app/components/pickers/AutocompletePickers/BudgetPicker';
import { dateUtils } from 'utils/date-utils';
import { PurchaseOrderOptions } from 'api/odata/generated/enums/PurchaseOrderOptions';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';
import { isEmpty } from 'lodash';
import { ISubscriptionTypesFilterDto } from 'api/odata/generated/entities/ISubscriptionTypesFilterDto';
import { FlexRow } from 'app/components/StyledComponents';
import { UserProfileLink } from 'app/pages/UserDetails/OpenUserProfile';
import { FormLabelText } from 'app/components/Forms/FormLabelText';

export interface SubscribersFormProps {
  initialValues: SubscribersDetailsState;
  onSubmit: (item: SubscribersDetailsState) => void;
  isEdit: boolean;
  isAdmin: boolean;
  bindSubmitForm: any;
  useSidePanel: boolean;
  processing?: boolean;
  isCover?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
  formRef: React.MutableRefObject<FormikProps<SubscribersDetailsState> | null>;
}

export const SubscribersForm = React.memo(function SubscribersForm({
  initialValues,
  onSubmit,
  isEdit,
  isAdmin,
  bindSubmitForm,
  useSidePanel,
  processing,
  isCover,
  openPanelWithCover,
  formRef,
}: SubscribersFormProps): JSX.Element {
  //SETTINGS
  const { t } = useTranslation();
  const { newDate } = useSystemDate();
  const globalSettings = useGlobalSettingsHook();

  const budgetFieldFundingTypeEnabled = React.useMemo(() => {
    return (
      globalSettings.fundingTypeBudgetEnabled &&
      globalSettings.purchaseOrderBudgetEnabled &&
      globalSettings.budgetModuleEnabled
    );
  }, [globalSettings]);

  //PROPS

  //STATES
  const [radioSubscriptionForValue, setRadioSubscriptionForValue] =
    React.useState<SubscriptionForOption | undefined>(undefined);

  //FUNCTIONS
  const handleRadioSubscriptionForChange = event => {
    setRadioSubscriptionForValue(event);
  };

  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(value);
    }
  };

  //USE EFFECT ON MOUNT
  useEffectOnMount(() => {
    if (initialValues.SubscriptionFor === 'usergroup') {
      setRadioSubscriptionForValue('usergroup');
    } else {
      setRadioSubscriptionForValue('');
    }
  });
  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.validateForm();
    }
  }, [formRef]);
  //SCHEMA
  const computedSchema = React.useMemo(() => {
    const subscribersDetailsSchema: Yup.SchemaOf<SubscribersDetailsState> =
      Yup.object({
        Id: Yup.number().notRequired().default(-1),
        Username: Yup.mixed()
          .label(t(translations.User) as string)
          .nullable(true),
        SubscriptionType: Yup.mixed()
          .label(t(translations.SubscriptionType) as string)
          .required(
            t(translations.SubscriptionSubscriptionTypeRequired) as string,
          ),
        Budget: Yup.mixed()
          .label(t(translations.Budget) as string)
          .nullable(true),
        StartDate: Yup.date()
          .default(newDate())
          .label(t(translations.StartDate) as string)
          .required(t(translations.StartDateIsRequired) as string),
        EndDate: Yup.date()
          .label(t(translations.EndDate) as string)
          .required(t(translations.EndDateIsRequired) as string)
          .test(
            'min',
            t(translations.err_EndTimeLowerThanStartTime) as string,
            (value, context) => {
              let start = (context as any).from[0].value.StartDate;
              let end = (context as any).from[0].value.EndDate;
              if (
                start === null ||
                start === undefined ||
                value === null ||
                value === undefined
              ) {
                return true;
              }
              let s = dateUtils.dateOrStringToDate(start);
              let e = dateUtils.dateOrStringToDate(end);
              if (e.valueOf() < s.valueOf()) {
                return false;
              }

              return true;
            },
          ),
        UserGroup: Yup.mixed()
          .label(t(translations.UserGroup) as string)
          .nullable(true),
        Cost: Yup.number()
          .label(t(translations.Cost) as string)
          .min(0)
          .required(t(translations.SubscriptionCostRequired) as string),
        SendExpirationNotificationDays: Yup.number()
          .label(t(translations.SendExpirationNotificationDays) as string)
          .nullable(true)
          .notRequired(),
        NotDeletable: Yup.boolean().default(false).notRequired(),
        FundingType: Yup.mixed()
          .label(t(translations.FundingType) as string)
          .nullable(true),
        PurchaseOrder: Yup.string()
          .label(t(translations.PurchaseOrder) as string)
          .nullable(true)
          .min(globalSettings.purchaseOrderLengthSetting ?? 0)
          .when('Budget', {
            is: budget => {
              const result =
                globalSettings.purchaseOrderBudgetEnabled &&
                (budget as IBudgetFilterDto)?.PurchaseOrderOptionId ===
                  PurchaseOrderOptions.Mandatory;
              return result;
            },
            then: schema =>
              schema.required(
                t(
                  translations.Reservation_Error_PurchaseOrderRequired,
                ) as string,
              ),
          }),
        SubscriptionFor: Yup.string()
          .label(t(translations.Subscription) as string)
          .nullable()
          .notRequired(),
      });
    if (globalSettings.budgetModuleEnabled) {
      subscribersDetailsSchema.fields.Budget.withMutation(schema =>
        schema.required(t(translations.SubscriptionBudgetRequired) as string),
      );
    }
    if (budgetFieldFundingTypeEnabled) {
      subscribersDetailsSchema.fields.FundingType.withMutation(schema =>
        schema.required(t(translations.FundingTypeRequired) as string),
      );
    }
    if (radioSubscriptionForValue === '') {
      subscribersDetailsSchema.fields.Username.withMutation(schema =>
        schema.required(t(translations.err_UserNameRequired) as string),
      );
    }
    if (radioSubscriptionForValue === 'usergroup') {
      subscribersDetailsSchema.fields.UserGroup.withMutation(schema =>
        schema.required(
          t(translations.SubscriptionUserGroupRequired) as string,
        ),
      );
    }
    return subscribersDetailsSchema;
  }, [
    budgetFieldFundingTypeEnabled,
    globalSettings.budgetModuleEnabled,
    globalSettings.purchaseOrderBudgetEnabled,
    globalSettings.purchaseOrderLengthSetting,
    newDate,
    radioSubscriptionForValue,
    t,
  ]);
  //RENDER
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={computedSchema}
        validateOnMount={true}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        innerRef={formRef}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          // console.debug('Submitting: ', values);
          // formikHelpers.setSubmitting(true);
          // handleSubmit(values);
          formikHelpers.validateForm(values).then(responseErrors => {
            if (!isEmpty(responseErrors)) {
              formikHelpers.setSubmitting(false);
              console.debug('errors', responseErrors);
            } else {
              console.debug('submitting', values);
              formikHelpers.setSubmitting(true);
              handleSubmit(values);
            }
          });
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            console.log('Subscribers Details Errors: ', formik.errors);
          }
          return (
            <StyledForm onSubmit={formik.handleSubmit}>
              {/* <FormListener
                    onFormChange={handleFormChange}
                    fields={['Disabled', 'Mandatory']}
                  /> */}
              <FormLeftSection>
                <FormFieldsSection
                  titleSection={t(translations.SubscribersDetails) as string}
                >
                  <FormRow>
                    <FormSubscriptionTypesPicker
                      id="SubscriptionTypeID"
                      name="SubscriptionType"
                      label={t(translations.SubscriptionSubscriptionType)}
                      info={t(
                        translations.SubscriptionSubscriptionTypeDescription,
                      )}
                      disabled={formik.isSubmitting}
                      onChange={val => {
                        formik.setFieldValue('SubscriptionType', val);
                        if (val !== null) {
                          let st = val as ISubscriptionTypesFilterDto;
                          let start = dateUtils.startOfDay(newDate());
                          let end = dateUtils.startOfDay(newDate());
                          let hasDates = false;
                          if (!!st.DurationDays) {
                            end = dateUtils.addDays(start, st.DurationDays);
                            hasDates = true;
                          } else if (!!st.DurationMonths) {
                            end = dateUtils.addMonths(start, st.DurationMonths);
                            hasDates = true;
                          } else if (!!st.DurationStart && !!st.DurationEnd) {
                            start = dateUtils.dateOrStringToDate(
                              st.DurationStart,
                            );
                            end = dateUtils.dateOrStringToDate(st.DurationEnd);
                            hasDates = true;
                          }
                          if (hasDates) {
                            formik.setFieldValue(
                              'StartDate',
                              dateUtils.formatISO(
                                dateUtils.dateOrStringToDate(start),
                              ),
                            );
                            formik.setFieldValue(
                              'EndDate',
                              dateUtils.formatISO(
                                dateUtils.dateOrStringToDate(end),
                              ),
                            );
                          }
                          if (!!st.Cost) {
                            formik.setFieldValue('Cost', st.Cost);
                          }
                        }
                      }}
                      fullWidth
                    />
                  </FormRow>
                  {!isEdit && (
                    <React.Fragment>
                      <FormRow>
                        <FormLabel
                          className={
                            'radio-group-base-label boldLabel withLabel'
                          }
                        >
                          {t(translations.CreateSubscriptionBy)}
                        </FormLabel>
                      </FormRow>
                      <FormRow>
                        <SubscriptionForOptions
                          onChange={e => {
                            handleRadioSubscriptionForChange(e);
                            if (e === 'usergroup') {
                              formik.setFieldValue(
                                'SubscriptionFor',
                                'usergroup',
                                false,
                              );
                            } else {
                              formik.setFieldValue(
                                'SubscriptionFor',
                                '',
                                false,
                              );
                            }
                          }}
                          value={radioSubscriptionForValue}
                        />
                      </FormRow>
                    </React.Fragment>
                  )}

                  {radioSubscriptionForValue === '' && (
                    <FormRow>
                      {isEdit ? (
                        <FormLabelText
                          id="UsernameID"
                          name="Username"
                          label={t(translations.SubscriptionUser) as string}
                          html={
                            <FlexRow>
                              {formik.values.Username !== null && (
                                <UserProfileLink
                                  userName={formik.values.Username?.Id}
                                  displayName={formik.values.Username?.Name}
                                  twoRows={false}
                                />
                              )}
                            </FlexRow>
                          }
                        />
                      ) : (
                        <FormUserPicker
                          id="UsernameID"
                          name="Username"
                          label={t(translations.SubscriptionUser)}
                          placeholder={t(translations.SubscriptionUser)}
                          disabled={formik.isSubmitting}
                          fullWidth
                        />
                      )}
                    </FormRow>
                  )}
                  {radioSubscriptionForValue === 'usergroup' && (
                    <FormRow>
                      <FormServiceUserGroupPicker
                        id="UserGroupID"
                        name="UserGroup"
                        label={t(translations.SubscriptionUserGroup)}
                        placeholder={t(translations.SubscriptionUserGroup)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                  )}
                  <FormRow hide={true}>
                    <FormTextField
                      name="SubscriptionFor"
                      value={
                        radioSubscriptionForValue === 'usergroup'
                          ? 'usergroup'
                          : ''
                      }
                      fullWidth
                    />
                  </FormRow>
                  {budgetFieldFundingTypeEnabled && (
                    <FormRow>
                      <FormFundingTypePicker
                        id="FundingTypeID"
                        name="FundingType"
                        label={t(translations.FundingType)}
                        info={t(translations.BudgetFundingType_info)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                  )}

                  <FormRow>
                    <FormBudgetPicker
                      id="BudgetID"
                      name="Budget"
                      label={t(translations.SubscriptionBudget)}
                      info={t(translations.SubscriptionBudgetDescription)}
                      disabled={formik.isSubmitting}
                      onChange={val => {
                        if (budgetFieldFundingTypeEnabled) {
                          if (val === null) {
                            formik.setFieldValue('FundingType', null);
                          } else {
                            formik.setFieldValue(
                              'FundingType',
                              (val as IBudgetFilterDto).FundingTypeId === null
                                ? null
                                : getSingleInvoiceFundingType(
                                    (val as IBudgetFilterDto).FundingTypeId ??
                                      0,
                                  ),
                            );
                          }
                        }
                      }}
                      fullWidth
                      predicates={restrictedBudgetFilter({
                        budgetLimitedByUsers:
                          globalSettings.budgetLimitedByUsers,
                        isBudgetsWithoutUserGroup:
                          globalSettings.isBudgetsWithoutUserGroup,
                        userGroupId:
                          radioSubscriptionForValue === 'usergroup' &&
                          !!formik.values.UserGroup &&
                          globalSettings.reservationUserGroupGroupByBudgetUserGroup
                            ? undefined
                            : formik.values.UserGroup?.Id,
                        serviceGroupId: undefined,
                        startTime: !!formik.values.StartDate
                          ? dateUtils.dateOrStringToDate(
                              formik.values.StartDate,
                            )
                          : undefined,
                        endTime: !!formik.values.EndDate
                          ? dateUtils.dateOrStringToDate(formik.values.EndDate)
                          : undefined,
                        user:
                          radioSubscriptionForValue === '' &&
                          !!formik.values.Username
                            ? formik.values.Username?.Id
                            : undefined,
                        defaultBudgetEnabled:
                          globalSettings.defaultBudgetEnabled,
                        userDefaultBudgetEnabled:
                          globalSettings.userDefaultBudgetEnabled,
                        hideNonDefaultBudgets:
                          globalSettings.hideNonDefaultBudgets,
                        // funding type is set to Internal by default even if it's not in use
                        fundingType: budgetFieldFundingTypeEnabled
                          ? formik.values.FundingType
                          : null,
                      })}
                    />
                  </FormRow>
                  {budgetFieldFundingTypeEnabled &&
                    ((formik.values.Budget as IBudgetFilterDto)
                      ?.PurchaseOrderOptionId ??
                      PurchaseOrderOptions.Hidden) !==
                      PurchaseOrderOptions.Hidden && (
                      <FormRow>
                        <FormTextField
                          name="PurchaseOrder"
                          id="subscriber_PurchaseOrder"
                          label={t(translations.PurchaseOrder)}
                          disabled={formik.isSubmitting}
                          fullWidth
                        />
                      </FormRow>
                    )}
                </FormFieldsSection>
              </FormLeftSection>
              <FormRightSection>
                <FormFieldsSection>
                  <FormRow>
                    <FormBookitDatePicker
                      id="StartDateID"
                      name="StartDate"
                      label={t(translations.SubscriptionStartDate)}
                      info={t(translations.SubscriptionStartDateDescription)}
                      disabled={formik.isSubmitting}
                      fullWidth
                    />
                  </FormRow>
                  <FormRow>
                    <FormBookitDatePicker
                      id="EndDateID"
                      name="EndDate"
                      label={t(translations.SubscriptionEndDate)}
                      info={t(translations.SubscriptionEndDateDescription)}
                      disabled={formik.isSubmitting}
                      fullWidth
                    />
                  </FormRow>
                  <FormRow>
                    <FormNumberField
                      id="CostID"
                      name="Cost"
                      inputMode="decimal"
                      label={t(translations.SubscriptionCost)}
                      info={t(translations.SubscriptionCostDescription)}
                      placeholder={t(translations.SubscriptionCost)}
                      disabled={formik.isSubmitting}
                      fullWidth
                    />
                  </FormRow>
                  <FormRow>
                    <FormNumberField
                      id="SendExpirationNotificationDaysID"
                      name="SendExpirationNotificationDays"
                      inputMode="numeric"
                      label={t(translations.SendExpirationNotificationDays)}
                      info={t(translations.SendExpirationNotificationDays_info)}
                      placeholder={t(
                        translations.SendExpirationNotificationDays,
                      )}
                      disabled={formik.isSubmitting}
                      fullWidth
                    />
                  </FormRow>
                </FormFieldsSection>
              </FormRightSection>
            </StyledForm>
          );
        }}
      </Formik>
    </>
  );
});
