/**
 *
 * Asynchronously loads the component for Floors Page
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Floors = lazyLoad(
  () => import('./index'),
  module => module.Floors,
);
