import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { IRow } from 'app/components/BasicTable';
import { Popover, PopoverPaddingWrapper } from 'app/components/Popover';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Column, TableState, UseTableInstanceProps } from 'react-table';
import { ColumnTypeExtension } from '../../getTableColumns';
export interface ColumnsSelectProps<T extends IRow>
  extends Pick<UseTableInstanceProps<T>, 'allColumns' | 'toggleHideColumn'>,
    Pick<TableState<T>, 'hiddenColumns'> {}

export function ColumnsSelect<T extends IRow>(props: ColumnsSelectProps<T>) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(undefined);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleToggle = (column: Column<T>) => {
    if (column.id !== undefined) {
      props.toggleHideColumn(column.id);
    }
  };
  const columns = React.useMemo(
    () => getHidableColumns(props.allColumns),
    [props.allColumns],
  );
  return (
    <>
      <TopActionButton
        variant="white"
        size="small"
        onClick={handleOpen}
        icon={'table'}
        title={t(translations.colvis_buttontext)}
        text={t(translations.Columns)}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        title={t(translations.colvis_buttontext)}
      >
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <PopoverPaddingWrapper>
          <List>
            {columns.map(column => {
              const labelId = `checkbox-list-label-${column.id}`;
              return (
                column.id !== undefined && (
                  <ListItem
                    key={column.id}
                    role={undefined}
                    dense
                    button
                    onClick={() => handleToggle(column)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={
                          props.hiddenColumns?.includes(column.id) !== true
                        }
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={column.Header} />
                  </ListItem>
                )
              );
            })}
          </List>
        </PopoverPaddingWrapper>
      </Popover>
    </>
  );
}
function getHidableColumns<T extends IRow>(columns: Array<Column<T>>) {
  return columns
    .filter(column => column.id !== undefined)
    .filter(column => (column as ColumnTypeExtension)?.enableHide === true);
}
function getHiddenColumnsKey(serviceId: number) {
  return `hidden_columns_${serviceId}`;
}
export function getHiddenColumns<T extends IRow>(
  serviceId: number,
  allColumns: Array<Column<T> & ColumnTypeExtension>,
) {
  try {
    const s = localStorage.getItem(getHiddenColumnsKey(serviceId));
    if (s === null) {
      return [];
    }
    const x = JSON.parse(s);
    if (Array.isArray(x) && x.every(item => typeof item === 'string')) {
      const columnIds = getHidableColumns(allColumns).map(column => column.id);

      return x.filter(id => columnIds.includes(id));
    }
    return [];
  } catch (error) {
    return [];
  }
}
export function setHiddenColumns(serviceId: number, value?: Array<string>) {
  try {
    const key = getHiddenColumnsKey(serviceId);
    if (value === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    //noop
  }
}
