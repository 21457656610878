/**
 *
 * SamplesTable
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { EditableCell } from '../../EditableTable/EditableCell';
import { TmtType } from 'app/pages/Samples/components/TmtType';
import { NumberField as CellNumberField } from '../../EditableTable/Fields/NumberField';
import { TextField as CellTextField } from '../../EditableTable/Fields/TextField';

import { useDispatch, useSelector } from 'react-redux';
import { ISample } from '../../SamplesListPage/slice/types';
import { MouseEventHandler } from 'react';
import { ControlledTable } from 'app/components/BasicTable/ControlledTable';
import { useRequestSamplesSlice } from 'app/pages/Samples/RequestSamplesPage/slice';
import { SamplePlatePicker } from 'app/components/pickers/AutocompletePickers/SamplePlatePicker';
import { SourceSamplePlatePositionPicker } from 'app/components/pickers/AutocompletePickers/SamplePlatePositionPicker';
import { toRootedURL } from 'utils/url-utils';
import { ButtonConfirm } from 'app/components/ButtonConfirm';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import {
  selectSamplesData,
  selectSamplesTableState,
  selectSelectedSamples,
} from '../../RequestSamplesPage/slice/selectors';
import { useConfigurableColumnsAdvanced } from 'app/components/BasicTable/useConfigurableColumnsAdvanced';
import { ScreensId } from 'enums/ConfigurableTypes';

import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { DropDown } from 'app/components/DropDown';
import { openExportLink } from 'utils/url-utils';
import { Printing } from 'app/components/PrintToPDF';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { ImportButton as SamplesImportButton } from '../../SamplesListPage/ImportButton';
import { IsModuleEnabled } from 'types/AppSettings';
import { selectAppSettings } from 'app/slice/selectors';
import { KnownModules } from 'types/KnownModules';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { useHistory } from 'react-router-dom';
import { FormSectionTitle } from 'app/components/Forms/FormsLayout/FormSectionTitle';
import { Column } from 'react-table';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';

export interface SamplesTableProps {
  serviceRequestId?: number;
  processing: boolean;
  isEditable: boolean;
  isAdmin: boolean;
  pageSize?: number;
  printing?: Printing;
  tableContainerAsSection?: boolean;
  serviceGroupId?: number;
}

const READONLY_COLUMNS = ['Status'];

export const SamplesTable = React.memo(function SamplesTable({
  serviceRequestId,
  isAdmin,
  isEditable,
  pageSize,
  printing,
  tableContainerAsSection,
  serviceGroupId,
  ...props
}: SamplesTableProps) {
  const samplesTableState = useSelector(selectSamplesTableState);
  const samples = useSelector(selectSamplesData);
  const selectedSamples = useSelector(selectSelectedSamples);
  const appSettings = useSelector(selectAppSettings);
  const { actions: requestactions } = useRequestSamplesSlice();
  const history = useHistory();
  const { actions } = useRequestSamplesSlice();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions: layoutActions } = useLayoutSlice();

  const columns: Column<ISample>[] = React.useMemo(() => {
    var result = [
      {
        Header: t(translations.SampleId),
        accessor: 'Id', // accessor is the "key" in the data
      },
      {
        Header: 'Request ID',
        accessor: 'ServiceRequestId',
      },
      {
        Header: 'Status',
        accessor: 'StatusName',
        Cell: ({ row, value }) => {
          /*switch (value) {
              case 0:
                return 'New';
              case 1:
                return 'In Work';
              case 2:
                return 'Done';
              default:
                return 'N/A';
            }*/
          return row.original.StatusName;
        },
      },
      {
        Header: t(translations.AnalyticalSampleId),
        accessor: 'AnalyticalSampleId',
        Cell: !isEditable ? undefined : EditableCell(CellTextField),
      },
      {
        Header: t(translations.BiologicalSampleId),
        accessor: 'BiologicalSampleId',
        Cell: !isEditable ? undefined : EditableCell(CellTextField),
      },
      {
        Header: t(translations.SubjectId),
        accessor: 'SubjectId',
        Cell: !isEditable ? undefined : EditableCell(CellTextField),
      },
      {
        Header: t(translations.SampleType),
        accessor: 'SampleType',
        Cell: !isEditable ? undefined : EditableCell(CellTextField),
      },
      {
        Header: t(translations.TaxaId),
        accessor: 'TaxaId',
        Cell: !isEditable ? undefined : EditableCell(CellTextField),
      },
      {
        Header: t(translations.Grouping),
        accessor: 'Grouping',
        Cell: !isEditable ? undefined : EditableCell(CellTextField),
      },
      /*{
        Header: 'Date Received',
        accessor: 'DateReceived',
        Cell: !isEditable ? DateLabel : EditableCell(CellDateField),
      },*/
      {
        Header: 'Tube label',
        accessor: 'TubeLabel',
        Cell: !isEditable ? undefined : EditableCell(CellTextField),
      },
      {
        Header: t(translations.TMT_Type),
        accessor: 'TMT_Type',
        Cell: !isEditable ? undefined : EditableCell(TmtType),
      },
      {
        Header: t(translations.TMT_Label),
        accessor: 'TMT_Label',
        Cell: !isEditable ? undefined : React.memo(EditableCell(CellTextField)),
      },
      {
        Header: t(translations.TMT_Set),
        accessor: 'TMT_Set',
        Cell: !isEditable ? undefined : React.memo(EditableCell(CellTextField)),
      },
      {
        Header: t(translations.SamplePlateId),
        accessor: 'SamplePlateId',
        Cell: !isEditable
          ? undefined
          : React.memo(EditableCell(SamplePlatePicker)),
      },
      {
        Header: t(translations.PlatePosition),
        accessor: 'Position',
        Cell: !isEditable
          ? undefined
          : React.memo(EditableCell(SourceSamplePlatePositionPicker)),
      },
      {
        Header: t(translations.Volume),
        accessor: 'Volume',
        Cell: !isEditable
          ? undefined
          : React.memo(EditableCell(CellNumberField)),
      },
      {
        Header: t(translations.Concentration),
        accessor: 'Concentration',
        Cell: !isEditable
          ? undefined
          : React.memo(EditableCell(CellNumberField)),
      },
      {
        Header: t(translations.ServiceGroup),
        accessor: 'ServiceGroup',
      },
    ];
    /**
     * { Cell: undefined } causes "Renderer Error"
     */
    result
      .filter(column => column.Cell === undefined)
      .forEach(column => delete column.Cell);
    return result;
  }, [isEditable, t]);

  const {
    configurableColumns,
    columnAccessors,
    exportConfigurableColumns,
    handleConfigurableColumns,
    configCompleted,
  } = useConfigurableColumnsAdvanced<ISample>(
    ScreensId.SampleRequests,
    columns,
    READONLY_COLUMNS,
  );
  const pageCount = React.useMemo(() => {
    return Math.ceil(
      (samples.value?.dataLength ?? 0) / (samplesTableState.pageSize ?? 10),
    );
  }, [samples.value?.dataLength, samplesTableState.pageSize]);
  const customPageSize = React.useMemo(() => {
    return printing !== undefined && printing.printing === true
      ? 10000
      : pageSize ?? 10;
  }, [pageSize, printing]);
  // const dispatch = useDispatch();
  // const { actions } = useSamplesListSlice();
  const updateMyData = item => {
    // We also turn on the flag to not reset the page
    // setSkipPageReset(true);
    dispatch(actions.updateServiceRequestSampleField(item));
  };

  const handleCreateButtonClick: MouseEventHandler<HTMLButtonElement> = e => {
    if (serviceRequestId === undefined) {
      return;
    }
    const data: ISample = {
      Id: 0,
      ServiceRequestId: serviceRequestId,
      BudgetId: null,
      BudgetName: null,
      SourceRunIds: [],
      TargetRunIds: [],
      Status: 0,
      StatusName: null,
      AnalyticalSampleId: null,
      BiologicalSampleId: null,
      TMT_Type: null,
      TMT_Label: null,
      TMT_Set: null,
      SubjectId: null,
      Concentration: null,
      Volume: null,
      SamplePlateId: null,
      Position: null,
      SampleType: null,
      TaxaId: null,
      ServiceGroupId: serviceGroupId,
    };
    dispatch(actions.createSample(data));
  };

  const handleChangeState = React.useCallback(
    state => {
      if (!!columnAccessors) {
        dispatch(
          actions.loadSamples({ ...state, ...{ columns: columnAccessors } }),
        );
      }
    },
    [actions, columnAccessors, dispatch],
  );

  const openExportUrl = React.useCallback(
    (readWriteOnly: boolean): Promise<void> => {
      if (
        exportConfigurableColumns !== undefined &&
        exportConfigurableColumns.length > 0
      ) {
        const exportColumns = exportConfigurableColumns;
        if (readWriteOnly) exportColumns.concat(READONLY_COLUMNS);
        const downloadTemplateUrl = toRootedURL('/api/odata/v4/samples', {
          $format: 'application/xlsx',
          $filter:
            '(not SourceRunIds/any()) and ' +
            (selectedSamples?.length > 0
              ? `Id in (${selectedSamples.map(f => f.Id).join(',')})`
              : `ServiceRequestId eq ${serviceRequestId}`),
          $select: exportColumns.join(','),
        });
        return openExportLink(downloadTemplateUrl);
      } else
        return new Promise<void>((resolve, reject) => {
          resolve();
        });
    },
    [exportConfigurableColumns, selectedSamples, serviceRequestId],
  );
  const handleExportClick = (): Promise<void> => openExportUrl(false);
  /*const handleExportForImportClick = React.useCallback(
      () => openExportUrl(true),
      [openExportUrl],
    );*/
  const handleDownloadTemplateClick = React.useCallback((): Promise<void> => {
    if (
      exportConfigurableColumns !== undefined &&
      exportConfigurableColumns.length > 0
    ) {
      if (serviceRequestId === undefined) {
        return new Promise<void>((resolve, reject) => {
          resolve();
        });
      }
      const downloadTemplateUrl = toRootedURL('/api/odata/v4/samples', {
        $format: 'application/xlsx',
        $filter: `ServiceRequestId eq ${serviceRequestId}`,
        $top: 0,
        $select: exportConfigurableColumns.join(','),
      });
      return openExportLink(downloadTemplateUrl);
    } else
      return new Promise<void>((resolve, reject) => {
        resolve();
      });
  }, [exportConfigurableColumns, serviceRequestId]);
  const goToSamplesList = React.useCallback(() => {
    dispatch(layoutActions.resetSidePanel());
    history.push(`/samples?ServiceRequestId=${serviceRequestId}`);
  }, [dispatch, history, layoutActions, serviceRequestId]);
  const handelDeleteSamplesClick = (
    selectedRows: ISample[],
    toggleAllRowsSelected,
  ) => {
    dispatch(actions.deleteSamples(selectedRows));
    toggleAllRowsSelected(false);
  };
  const handleSelectedRowsChange = React.useCallback(
    rows => {
      dispatch(actions.setSelected(rows));
    },
    [actions, dispatch],
  );
  const menuItems: React.ReactNode[] = React.useMemo(() => {
    let items: React.ReactNode[] = [];

    /*items.push(
        <MenuItem key="goTo-SamplesList" onClick={goToSamplesList}>
          <ImportButton
            variant="text"
            size="small"
            disabled={!isEditable}
            onDownloadTemplateButtonClick={handleDownloadTemplateClick}
            serviceRequestId={serviceRequestId}
          />
        </MenuItem>,
      );
      /*items.push(
        <MenuItem
          key="ExportForImport-SamplesList"
          onClick={handleExportForImportClick}
        >
          {t(translations.ExportForImport)}
        </MenuItem>,
      );*/
    return items;
  }, []);

  const getActions = (): PageActionRenderer[] => {
    const result: PageActionRenderer[] = [
      /*() => (
          <TopActionButton
            size="small"
            variant="white"
            startIcon="arrow-up-right-from-square"
            icon="arrow-up-right-from-square"
            key={'OpenInSamplesList'}
            href={toRootedURL(`/samples?ServiceRequestId=${serviceRequestId}`)}
            text={t(translations.OpenInSamplesList) as string}
          />
        ),*/
      () => (
        <TopActionButton
          size="small"
          variant="main"
          key={'AddSample'}
          onClick={handleCreateButtonClick}
          startIcon="plus"
          icon="plus"
          disabled={!isEditable}
          text={t(translations.AddSample) as string}
        />
      ),
    ];
    if (isAdmin && menuItems.length > 0) {
      result.push(() => (
        <DropDown
          size="small"
          variant="ghost"
          title={t(translations.MoreActions)}
          menuProps={{}}
          menuChildren={() => [...menuItems]}
        >
          <Icon icon="ellipsis-v" />
        </DropDown>
      ));
    }
    return result;
  };
  const importHandler = () => {
    dispatch(requestactions.toggleImport(true));
  };
  const customScreenName: React.ReactNode = React.useMemo(() => {
    const result = (
      <>
        <FormSectionTitle title={'Request Samples'} />
        <IconButton
          aria-label="link"
          variant="ghost"
          shape="square"
          size="small"
          title={t(translations.OpenInSamplesList) as string}
          onClick={goToSamplesList}
        >
          <Icon icon="arrow-up-right-from-square" />
        </IconButton>
      </>
    );
    return result;
  }, [goToSamplesList, t]);
  return (
    <>
      <SamplesImportButton
        onDownloadTemplateButtonClick={
          IsModuleEnabled(appSettings, KnownModules.SamplesAutomation)
            ? handleDownloadTemplateClick
            : undefined
        }
        hideImportButton={true}
        serviceRequestId={serviceRequestId}
      />
      <ControlledTable
        api={'delete-me'}
        screenId={ScreensId.SampleRequests}
        columns={configurableColumns !== undefined ? configurableColumns : []}
        useConfigurable={true}
        onConfigurableColumns={handleConfigurableColumns}
        useImportLink={true}
        allowCards={false}
        importHandler={importHandler}
        onExport={handleExportClick}
        initialState={{
          pageIndex: 0,
          pageSize: customPageSize,
          sortBy: [{ id: 'Id', desc: true }],
        }}
        screenName={t(translations.RequestSamples)}
        screenNameVariant="section"
        customScreenName={customScreenName}
        data={samples.value?.data || []}
        dataLength={samples.value?.dataLength ?? 0}
        pageCount={pageCount}
        onSelectedRowsChange={handleSelectedRowsChange}
        hideMenuOnMobile
        loading={
          props.processing || !configCompleted || samples.status === 'pending'
        } //|| samples.processing
        serviceGroups={[]}
        onChangeState={handleChangeState}
        onRefresh={handleChangeState}
        // onFilterChange={handleControlledTableFilterChange}
        // appliedFilters={appliedFilters}
        useRowSelect={true}
        // availableFilters={availableFilters}
        updateMyData={updateMyData}
        searchColumns={[
          'cast(Id,Edm.String)',
          "cast(ServiceRequestId , 'Edm.String')",
          'BudgetName',
          "cast(BudgetId , 'Edm.String')",
          // SourceRunIds: Array<number> | null;
          // TargetRunIds: Array<number> | null;
          //"cast(Status , 'Edm.String')",
          'AnalyticalSampleId',
          'BiologicalSampleId',
          'TMT_Type',
          'TMT_Label',
          'TMT_Set',
          'SubjectId',
          "cast(Concentration , 'Edm.String')",
          "cast(Volume , 'Edm.String')",
          'SamplePlateId',
          'Position',
        ]}
        compact={false}
        // updateMyData={updateMyData}
        selectedRowsActions={[
          (rows: ISample[], _refresh, toggleAllRowsSelected) => (
            <>
              <ButtonConfirm
                disabled={!isEditable}
                size="small"
                variant="white"
                onClick={() =>
                  handelDeleteSamplesClick(rows, toggleAllRowsSelected)
                }
                confirmationDialogBody={t(
                  translations.ThisCannotBeUndoneAreYouSure,
                )}
              >
                {t(translations.Delete)} ({rows.length})
              </ButtonConfirm>
            </>
          ),
        ]}
        pageActions={getActions()}
        printing={printing}
        tableContainerAsSection={tableContainerAsSection}
      />
    </>
  );
});
