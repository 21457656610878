import { H3 } from 'app/components/Typography';
import { H4 } from 'app/components/Typography';
import React from 'react';
import BasicTypography from 'app/components/Typography/BasicTypography';
import styled from 'styled-components';

export interface CustomEmptyTableProps {
  id?: string;
  headTitle?: string;
  title?: string;
  shortView?: boolean;
  customContent?: React.ReactNode;
}

export function CustomEmptyTable(props: CustomEmptyTableProps) {
  const { headTitle, title, customContent } = props;
  return (
    <EmptyTableWrapper className="empty-table">
      <div className="empty-table-content">
        {!!customContent ? (
          <React.Fragment>{customContent}</React.Fragment>
        ) : (
          <React.Fragment>
            {props.shortView ? (
              <React.Fragment>
                {headTitle && (
                  <BasicTypography variant={'boldM'}>
                    {headTitle}
                  </BasicTypography>
                )}
                {title && (
                  <BasicTypography variant={'boldS'}>{title}</BasicTypography>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {headTitle && <H3>{headTitle}</H3>}
                {title && <H4>{title}</H4>}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </EmptyTableWrapper>
  );
}
const EmptyTableWrapper = styled.div`
  &.empty-table {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & .empty-table-content {
      text-align: center;
      margin-top: 3%;
      margin-bottom: 3%;
    }
  }
`;
