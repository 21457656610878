import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { ExternalLink } from 'app/components/ExternalLink';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cliTruncate from 'cli-truncate';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useSelector } from 'react-redux';
import { selectExpandedSidePanel } from 'app/Layout/FrontendLayout/slice/selectors';
import { Body, H3 } from 'app/components/Typography';
import { OpenMenuButton } from '../../TopBar/components/openMenuButton';
import { useAbsolute } from 'utils/url-utils';
import styled from 'styled-components';

export interface TitlePageProps {
  // title of the current screen
  title: string;
  // link to open the details page as its own url on a new browser tab
  pageLink?: string;
  toPageLink?: string;
  useSidePanel?: boolean;
  buttonsLenth: number;
  expandPanel?: () => void;
  tooltip?: string;
  isCover?: boolean;
  toExpandPanel?: () => void;
  useShortView?: boolean;
  hideMenu?: boolean;
}
const TitleSection = styled('div')`
  &.title-section {
    display: flex;
    gap: 4px;
    align-items: center;
    & .copy-url {
      display: inline;
      position: relative;
      align-self: flex-start;
    }
  }
`;
const ShortTitle = styled('span')`
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;
export function TitlePage(props: TitlePageProps) {
  const { t } = useTranslation();
  const {
    title,
    pageLink,
    toPageLink,
    useSidePanel,
    buttonsLenth,
    expandPanel,
    tooltip,
    isCover,
    toExpandPanel,
    useShortView,
    hideMenu,
  } = props;
  const [showCopy, setShowCopy] = React.useState<boolean>(false);
  const [copied, setCopied] = React.useState<boolean>(false);
  const isMobile = DetectIsMobile();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const showShortView = React.useMemo(() => {
    return (
      isMobile ||
      (useSidePanel && !sidePanelExpanded) ||
      isCover ||
      useShortView
    );
  }, [isCover, isMobile, sidePanelExpanded, useShortView, useSidePanel]);
  const truncatedTitle = React.useMemo(() => {
    return showCopy
      ? cliTruncate(
          title,
          buttonsLenth === 1
            ? isCover
              ? 22
              : 24
            : buttonsLenth === 2
            ? isCover
              ? 18
              : 18
            : isCover
            ? 14
            : 16,
          { position: 'middle' },
        )
      : cliTruncate(
          title,
          buttonsLenth === 1
            ? isCover
              ? 28
              : 30
            : buttonsLenth === 2
            ? isCover
              ? 22
              : 24
            : isCover
            ? 18
            : 20,
          { position: 'end' },
        );
  }, [buttonsLenth, isCover, showCopy, title]);
  const absolutePageLink = useAbsolute(pageLink, toPageLink);
  return pageLink || toPageLink ? (
    <TitleSection
      className="title-section"
      onMouseEnter={() => setShowCopy(true)}
      onMouseLeave={() => setShowCopy(false)}
    >
      {isMobile && !useSidePanel && !hideMenu && (
        <OpenMenuButton iconSize="small" />
      )}
      {expandPanel && (
        <IconButton aria-label="minimize" onClick={expandPanel}>
          <Icon
            icon={['fas', 'arrow-left']}
            color="default"
            colorExtend="textHover"
          />
        </IconButton>
      )}
      {isMobile ? (
        <Body bold={true}>
          <Tooltip title={tooltip ?? title}>
            {<ShortTitle>{title.replace(/-/g, '‑')}</ShortTitle>}
          </Tooltip>
        </Body>
      ) : (
        <ExternalLink
          href={pageLink !== undefined ? pageLink : ''}
          to={toPageLink}
          onClick={expandPanel ? expandPanel : toExpandPanel}
        >
          {showShortView ? (
            <Body bold={true}>
              <Tooltip title={tooltip ?? title}>
                {<ShortTitle>{title.replace(/-/g, '‑')}</ShortTitle>}
              </Tooltip>
            </Body>
          ) : (
            <H3>{title}</H3>
          )}
        </ExternalLink>
      )}

      {showCopy && absolutePageLink !== undefined && !isMobile && (
        <IconButton
          className="copy-url"
          onClick={() => {
            navigator.clipboard.writeText(absolutePageLink);
            setCopied(true);
          }}
        >
          <Tooltip
            title={
              copied
                ? (t(translations.URLCopied) as string)
                : (t(translations.CopyURL) as string)
            }
            arrow
          >
            <span>
              <Icon icon="chain" />
            </span>
          </Tooltip>
        </IconButton>
      )}
    </TitleSection>
  ) : (
    <React.Fragment>
      {showShortView ? (
        <Body bold={true}>
          <Tooltip title={tooltip ?? title}>
            {<span>{truncatedTitle}</span>}
          </Tooltip>
        </Body>
      ) : (
        <H3>{title}</H3>
      )}
    </React.Fragment>
  );
}
