import {
  LinearProgress,
  Popper,
  PopperProps,
  Theme,
  useTheme,
} from '@material-ui/core';
import styled from 'styled-components';
import { IPathAndQuery, toRootedURL } from 'utils/url-utils';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAdminPanelOpen,
  selectAdminPanelUrl,
} from '../../slice/selectors';
import { DRAWER_WIDTH } from 'styles/constantVariables';
import { useLayoutSlice } from '../../slice';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { selectIsExpandedSideBar } from 'app/slice/selectors';
import { DetectIsMobile } from 'utils/mobileDetect';
import { Div100 } from 'app/components/AssetQuickSearch/styled';
import { FullHeightBlock } from '../../styled';
// import Frame from 'react-frame-component';

export interface AdminPanelProps {}
export const AdminPanelPopper = styled(
  ({
    sideBarOpen,
    theme,
    children,
    ...other
  }: {
    sideBarOpen: boolean;
    theme: Theme;
    children: React.ReactNode;
  } & PopperProps) => <Popper {...other}>{children}</Popper>,
)`
  &.admin-panel {
    z-index: ${props => props.theme.zIndex.modal};
    height: 100dvh;
    width: 100%;
    margin-left: ${props => (props.sideBarOpen ? DRAWER_WIDTH : 56)}px;
    & .admin-panel-paper {
      background: ${props => props.theme.palette.common.white};
      border-radius: 0;
      height: 100%;
      width: calc(100% - ${props => (props.sideBarOpen ? DRAWER_WIDTH : 56)}px);
      margin: 0;
      box-shadow: unset;
      & .admin-panel-header {
        display: flex;
        justify-content: flex-end;
        padding: 8px;
      }
    }
  }
`;
export function AdminPanel(props: AdminPanelProps) {
  const adminPanelOpen = useSelector(selectAdminPanelOpen);
  const adminUrl = useSelector(selectAdminPanelUrl);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actions: layoutActions } = useLayoutSlice();
  const isMobile = DetectIsMobile();
  const sideBarOpen = useSelector(state =>
    selectIsExpandedSideBar(state, isMobile),
  );
  const [iframeLoaded, setIframeLoaded] = React.useState<boolean>(false);
  const elementRef = React.useRef<HTMLDivElement>(null);

  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const absolutePath = React.useMemo(() => {
    return adminUrl !== undefined
      ? typeof adminUrl === 'string'
        ? toRootedURL(adminUrl)
        : toRootedURL(
            (adminUrl as IPathAndQuery).path,
            (adminUrl as IPathAndQuery).search,
          )
      : undefined;
  }, [adminUrl]);
  const theme = useTheme();
  const countState = React.useState(0);
  React.useEffect(() => {
    countState[1](c => c + 1);
    // setCount (rerender) needs to occur after element elementRef.current value is bound
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current]);
  const closeAdminPanel = React.useCallback(() => {
    dispatch(layoutActions.resetAdminPanel());
  }, [dispatch, layoutActions]);
  const handleIfarameLoad = () => {
    setIframeLoaded(true);
  };
  // React.useEffect(() => {
  //   if (!!iframeRef.current) {
  //     let doc = iframeRef.current.contentWindow;
  //   }
  //   return () => {};
  // }, []);
  return (
    <AdminPanelPopper
      sideBarOpen={sideBarOpen}
      className={'admin-panel'}
      open={adminPanelOpen}
      theme={theme}
      anchorEl={elementRef.current}
      placement={'left-start'}
      transition
      modifiers={{
        keepTogether: { enabled: true },
        preventOverflow: {
          enabled: true,
          // altAxis: true,
          // altBoundary: true,
          // tether: false,
          // rootBoundary: 'document',
          boundariesElement: 'window', //scrollParent, window, viewport
          padding: 0,
        },
        flip: { enabled: false },
        // offset: {
        //   offset: '0,-10',
        // },
      }}
    >
      <div className={'admin-panel-paper'}>
        <React.Fragment>
          {adminPanelOpen && !!absolutePath ? (
            <React.Fragment>
              <div className={'admin-panel-header'}>
                <IconButton
                  aria-label="close"
                  onClick={closeAdminPanel}
                  title={t(translations.Close)}
                >
                  <Icon
                    icon="times"
                    size="lg"
                    color="default"
                    colorExtend="textHover"
                  />
                </IconButton>
              </div>
              {!iframeLoaded && (
                <Div100>
                  <LinearProgress variant="query" color="primary" />
                </Div100>
              )}
              <iframe
                title="adminpanel"
                ref={iframeRef}
                src={absolutePath}
                width="100%"
                height="100%"
                onLoad={handleIfarameLoad}
                id="adminPanelIframeId"
              ></iframe>
            </React.Fragment>
          ) : (
            <FullHeightBlock />
          )}
        </React.Fragment>
      </div>
    </AdminPanelPopper>
  );
}
