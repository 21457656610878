import { IRegionDto } from 'app/pages/Regions/IRegionDto';
import { IResponseType, IResponseTypeWithId } from 'types/ResponseType';
import { httpClient } from './HttpClient';

export const RegionsApi = {
  getRegion: (payload: { id: number }) => {
    const url = '/api/odata/v4/Regions';
    const params: {
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $filter: '(Id eq ' + payload.id + ')',
      $select: 'Id,Name',
      $skip: 0,
      $top: 1,
    };
    return httpClient.get(url, params);
  },
  getRegions: () => {
    const url = '/api/odata/v4/Regions';
    const params: {
      $select: string;
    } = {
      $select: 'Id,Name',
    };
    return httpClient.get(url, params);
  },
  insertRegion: (data: IRegionDto) => {
    const url = '/api/odata/v4/Regions';
    return httpClient.post(url, data);
  },
  updateRegion: (data: IRegionDto, rowId: number) => {
    const url = '/api/odata/v4/Regions';
    return httpClient.put<any, IResponseTypeWithId>(
      url + '(' + rowId + ')',
      data,
    );
  },
  deleteRegion: (Id: number) => {
    const url = `/api/odata/v4/Regions/${Id}`;
    return httpClient.deleteCustom<IResponseType>(url);
  },
  deleteSelectedRows: (payload: number[]) => {
    const url = `/api/odata/v4/Regions/DeleteIds`;
    const data = { ids: payload };
    return httpClient.post(url, data);
  },
};
