import { IEditEvent } from 'app/pages/CalendarPage/Scheduler/slice/types';
import cliTruncate from 'cli-truncate';
import { dateUtils } from 'utils/date-utils';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import styled from 'styled-components';
import BasicTypography from 'app/components/Typography/BasicTypography';

export interface ReservationEventProps {
  event: IEditEvent;
}
const EventContent = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--primary-background-c2);
  width: 100%;
  & .content-columns {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    gap: 4px;
    width: 100%;
  }
`;
export function ReservationEvent(props: ReservationEventProps) {
  return (
    <EventContent>
      <div className="content-columns">
        <BasicTypography variant="captionBold" component={'span'}>
          {cliTruncate(props.event.original.EquipmentName, 50, {
            position: 'end',
          })}
          {`  by  `}
          {(props.event.original as ICalendarReservationDto).BookedBy ??
            props.event.original.BookedById}
        </BasicTypography>
        <BasicTypography
          variant="captionRegular"
          component={'span'}
          color={'secondary'}
        >
          {`New Time: ${dateUtils.format(
            dateUtils.dateOrStringToDate(props.event.start_date),
            'dd MMM hh:mm',
          )} - ${dateUtils.format(
            dateUtils.dateOrStringToDate(props.event.end_date),
            'dd MMM hh:mm',
          )}`}
        </BasicTypography>
        <BasicTypography
          variant="tipsRegular"
          component={'span'}
          color={'secondary'}
        >
          {`Before: ${dateUtils.format(
            dateUtils.dateOrStringToDate(props.event.original.StartTime),
            'dd MMM hh:mm',
          )} - ${dateUtils.format(
            dateUtils.dateOrStringToDate(props.event.original.EndTime),
            'dd MMM hh:mm',
          )}`}
        </BasicTypography>
      </div>
    </EventContent>
  );
}
