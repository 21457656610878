/**
 *
 * Asynchronously loads the component for OfflineTypeReferences Page
 *
 */
import { lazyLoad } from 'utils/loadable';

export const OfflineTypeReferencesPage = lazyLoad(
  () => import('./index'),
  module => module.OfflineTypeReferences,
);
