import { Button } from 'app/components/BasicButtons/Button';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toQueryString } from 'utils/url-utils';

export interface OpenTimelineProps<TRow> {
  rows: TRow[];
  getEquipmentIds: (rows: TRow[]) => number[];
}

export function OpenTimeline<TRow>({
  rows,
  getEquipmentIds,
}: OpenTimelineProps<TRow>) {
  const { t } = useTranslation();

  const link = React.useMemo(() => {
    return `/Timeline.aspx?${toQueryString({
      services: getEquipmentIds(rows).join(','),
    })}`;
  }, [getEquipmentIds, rows]);
  return (
    <Button variant="white" size="small" href={link} target="_blank">
      {t(translations.Timeline)}
    </Button>
  );
}
