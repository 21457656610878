import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.requestStatusesDetail || initialState;

export const selectRequestStatusesDetails = createSelector(
  [selectSlice],
  state => state,
);
//====================================================================
export const selectCreateRequestStatusesDetails = createSelector(
  [selectRequestStatusesDetails],
  state => state.createState.data,
);

export const selectCreateRequestStatusesDetailsCompleted = createSelector(
  [selectRequestStatusesDetails],
  state => state.createState.completed,
);

export const selectCreateRequestStatusesDetailsHasError = createSelector(
  [selectRequestStatusesDetails],
  state => state.createState.hasErrors,
);
//====================================================================
export const selectUpdateRequestStatusesDetails = createSelector(
  [selectRequestStatusesDetails],
  state => state.updateState.data,
);

export const selectUpdateRequestStatusesDetailsCompleted = createSelector(
  [selectRequestStatusesDetails],
  state => state.updateState.completed,
);
export const selectUpdateRequestStatusesDetailsHasError = createSelector(
  [selectRequestStatusesDetails],
  state => state.updateState.hasErrors,
);
//====================================================================
export const selectRequestStatusesDetailsProcessing = createSelector(
  [selectRequestStatusesDetails],
  state => state.processing,
);
