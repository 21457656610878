import React from 'react';
import clsx from 'clsx';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export interface BulletedListItem {
  key: React.Key | null | undefined;
  node: React.ReactNode;
}
export interface BulletedListProps {
  items: BulletedListItem[];
  showBullet?: boolean;
}
const StyledUlList = styled('ul')`
  &.ul-root {
    & {
      list-style: none;
      padding-inline-start: 20px;
      margin: 0;
    }
    & li:before {
      content: '•';
      color: ${bookitColors.primary.regular};
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
    & li {
      margin-bottom: 4px;
    }
  }
`;
const StyledSimpleList = styled('ul')`
  &.simple-root {
    & {
      list-style: none;
    }
  }
`;
const StyledBulletList = styled('ul')`
  &.bullet-root {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    gap: 8px;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: clip;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 2px ${bookitColors.grayscale.grayBorders};
      border-radius: 6px;
      background-color: ${bookitColors.grayscale.grayBorders};
    }
    height: auto;
    max-height: 200px;
    & .bullet-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      gap: 8px;
      height: 25px;
    }
    & .bullet-row-over-rule {
      display: flex;
      width: 100%;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 23ch;
    }
  }
`;
export const UlList = ({
  children,
  className,
}: React.PropsWithChildren<{
  className?: string;
}>) => {
  return (
    <StyledUlList className={clsx('ul-root', className)}>
      {children}
    </StyledUlList>
  );
};
export const SimpleList = ({
  children,
  className,
}: React.PropsWithChildren<{
  className?: string;
  style?: React.CSSProperties;
}>) => {
  return (
    <StyledSimpleList className={clsx('simple-root', className)}>
      {children}
    </StyledSimpleList>
  );
};
export const BulletedList = (props: BulletedListProps) => {
  const { items, showBullet = true } = props;
  return (
    <StyledBulletList className={'bullet-root'}>
      {items.map(c => {
        return (
          <li key={`list-key-${c.key}`} className={'bullet-row'}>
            {showBullet && (
              <Icon icon="circle" fontSize="6px" color="primary" />
            )}
            <span className={'bullet-row-over-rule'}>{c.node}</span>
          </li>
        );
      })}
    </StyledBulletList>
  );
};
