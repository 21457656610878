import { fade, ThemeOptions } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
// import convertLength from 'convert-css-length';
import { grey, green } from '@material-ui/core/colors';
import { bookitColors } from './colors/bookitColors';
import infoColors from './colors/info';
import {
  bodyS,
  boldS,
  bookitTypography,
  buttonM,
  inputLabelXS,
  inputValueS,
  tipsRegular,
} from './typography/bookitTypography';
import {
  MuiButtonOverride,
  MuiIconButtonOverride,
  MuiToggleButtonGroupOverride,
  MuiToggleButtonOverride,
} from './MUIExtend/themeOverrides';
import transitions from '@material-ui/core/styles/transitions';
import {
  COVER_WIDTH,
  //DRAWER_MOBILE_WIDTH,
  DRAWER_WIDTH,
  MAX_COVER_EXPANDED_WIDTH,
} from './constantVariables';
import {
  box_shadow_l,
  box_shadow_m,
  box_shadow_s,
} from './shadows/bookitShadow';
import { verticalScrolling } from './CssVariables/variables';

const breakpoints = createBreakpoints({});
// xs: 0,
// sm: 600,
// md: 960,
// lg: 1280,
// xl: 1920
const lato_Light = {
  fontFamily: `'Lato', sans-serif`,
  fontStyle: 'normal',
  fontWeight: 300,
  src: `
    local(''),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Light.woff') format('woff'),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Light.woff2') format('woff2'),
  `,
};
const lato_Regular = {
  fontFamily: `'Lato', sans-serif`,
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local(''),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Regular.woff') format('woff'),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Regular.woff2') format('woff2'),
  `,
};
const lato_Bold = {
  fontFamily: `'Lato', sans-serif`,
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local(''),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Bold.woff') format('woff'),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Bold.woff2') format('woff2'),
  `,
};
const lato_Black = {
  fontFamily: `'Lato', sans-serif`,
  fontStyle: 'normal',
  fontWeight: 900,
  src: `
    local(''),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Black.woff') format('woff'),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Black.woff2') format('woff2'),
  `,
};
const lato = {
  fontFamily: `'Lato', sans-serif`,
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local(''),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Regular.woff') format('woff'),
    url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Regular.woff2') format('woff2'),
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
//const convert = convertLength('16px');
export type BaseTheme = 'default' | 'animals' | 'bookit' | 'dark' | undefined;
// customizes the mui theme defaults/overrides
// colors: https://material-ui.com/customization/color/#color
export const DefaultThemeSettings: ThemeOptions = {
  typography: {
    fontFamily: `'Lato', sans-serif`,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [lato],
      },
    },
  },
};

export const BookitThemeSettings: ThemeOptions = {
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  palette: {
    primary: {
      dark: bookitColors.primary.dark,
      main: bookitColors.primary.regular,
      light: bookitColors.primary.background,
      textHover: bookitColors.primary.hover,
      bgHover: bookitColors.primary.bgHover,
      contrastText: bookitColors.base.white,
    },

    secondary: {
      dark: '#BFA44A',
      main: '#FFDB63',
      light: '#F4ECCE',
      textHover: '#E0BC40',
      bgHover: '#FFEBA9',
      contrastText: '#000',
    },
    info: {
      dark: infoColors.colorInfoDark,
      main: infoColors.colorInfoRegular,
      light: infoColors.colorInfoBg,
      textHover: infoColors.colorInfoBgHover,
      bgHover: infoColors.colorInfoHover,
      contrastText: bookitColors.base.white,
    },
    accent: {
      dark: bookitColors.secondary.violet.dark,
      main: bookitColors.secondary.violet.regular,
      light: bookitColors.secondary.violet.background,
      bgHover: bookitColors.secondary.violet.bgHover,
      textHover: bookitColors.secondary.violet.hover,
      contrastText: bookitColors.base.white,
    },
    danger: {
      dark: bookitColors.danger.dark,
      main: bookitColors.danger.regular,
      light: bookitColors.danger.background,
      textHover: bookitColors.danger.hover,
      bgHover: bookitColors.danger.bgHover,
      contrastText: bookitColors.base.white,
    },
    success: {
      dark: bookitColors.success.dark,
      main: bookitColors.success.regular,
      light: bookitColors.success.background,
      textHover: bookitColors.success.hover,
      bgHover: bookitColors.success.bgHover,
      contrastText: bookitColors.base.white,
    },
    error: {
      dark: bookitColors.danger.dark,
      main: bookitColors.danger.regular,
      light: bookitColors.danger.background,
      bgHover: bookitColors.danger.bgHover,
      textHover: bookitColors.danger.hover,
      contrastText: bookitColors.base.white,
    },
    default: {
      dark: bookitColors.grayscale.blackComponents,
      main: bookitColors.grayscale.graybgComponents,
      light: bookitColors.grayscale.grayBackground,
      bgHover: bookitColors.base.white,
      textHover: bookitColors.grayscale.graySubtitles,
      contrastText: bookitColors.base.black,
    },
    filters: {
      dark: bookitColors.primary.dark,
      main: bookitColors.grayscale.graySelection,
      textHover: bookitColors.primary.dark,
      bgHover: bookitColors.primary.bgHover,
      light: bookitColors.grayscale.grayComponents,
      contrastText: bookitColors.grayscale.blackRegular,
    },
    common: {
      black: bookitColors.base.black,
      blackRegular: bookitColors.grayscale.blackRegular,
      blackComponent: bookitColors.grayscale.blackComponents,
      graySubtitle: bookitColors.grayscale.graySubtitles,
      grayComponents: bookitColors.grayscale.grayComponents,
      grayComponentsLight: bookitColors.grayscale.grayComponentsLight,
      background: bookitColors.grayscale.grayBackground,
      backgroundHover: bookitColors.grayscale.graybgComponents,
      grayBorders: bookitColors.grayscale.grayBorders,
      grayDividers: bookitColors.grayscale.graySelection,
      white: bookitColors.base.white,
    },
    background: {
      default: bookitColors.grayscale.grayBackground,
      paper: bookitColors.base.white,
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    h1: bookitTypography.h1,
    h2: bookitTypography.h2,
    h3: bookitTypography.h3,
    h4: bookitTypography.h4,
    bodyL: bookitTypography.bodyL,
    bodyM: bookitTypography.bodyM,
    bodyS: bookitTypography.bodyS,
    boldL: bookitTypography.boldL,
    boldM: bookitTypography.boldM,
    boldS: bookitTypography.boldS,
    buttonM: bookitTypography.buttonM,
    buttonS: bookitTypography.buttonS,
    captionRegular: bookitTypography.captionRegular,
    captionBold: bookitTypography.captionBold,

    subtitle1: bookitTypography.subtitle1,
    subtitle2: bookitTypography.subtitle2,
    tipsRegular: bookitTypography.tipsRegular,
    tipsBold: bookitTypography.tipsBold,
    inputLabelM: bookitTypography.inputLabelM,
    inputLabelXS: bookitTypography.inputLabelXS,
    inputValueM: bookitTypography.inputValueM,
    inputValueS: bookitTypography.inputValueS,
    uppercase: bookitTypography.uppercase,
  },
  overrides: {
    MuiTypography: {
      root: {
        '&.variantH1': {
          ...bookitTypography.h1,
        },
        '&.variantH2': {
          ...bookitTypography.h2,
        },
        '&.variantH3': {
          ...bookitTypography.h3,
        },
        '&.variantH4': {
          ...bookitTypography.h4,
        },
        '&.variantSubtitle1': {
          ...bookitTypography.subtitle1,
        },
        '&.variantSubtitle2': {
          ...bookitTypography.subtitle2,
        },
        '&.variantBodyl': {
          ...bookitTypography.bodyL,
        },
        '&.variantBodym': {
          ...bookitTypography.bodyM,
        },
        '&.variantBodys': {
          ...bookitTypography.bodyS,
        },
        '&.variantBoldl': {
          ...bookitTypography.boldL,
        },
        '&.variantBoldm': {
          ...bookitTypography.boldM,
        },
        '&.variantBolds': {
          ...bookitTypography.boldS,
        },
        '&.variantCaptionbold': {
          ...bookitTypography.captionBold,
        },
        '&.variantCaptionregular': {
          ...bookitTypography.captionRegular,
        },
        '&.variantUppercase': {
          ...bookitTypography.uppercase,
        },
        '&.variantTipsbold': {
          ...bookitTypography.tipsBold,
        },
        '&.variantTipsregular': {
          ...bookitTypography.tipsRegular,
        },
        '&.variantInputlabelm': {
          ...bookitTypography.inputLabelM,
        },
        '&.variantInputlabelxs': {
          ...bookitTypography.inputLabelXS,
        },
        '&.variantInputvaluem': {
          ...bookitTypography.inputValueM,
        },
        '&.variantInputvalues': {
          ...bookitTypography.inputValueS,
        },
        '&.variantButtonm': {
          ...bookitTypography.buttonM,
        },
        '&.variantButtons': {
          ...bookitTypography.buttonS,
        },
        '&.italic': {
          fontStyle: 'italic',
        },
        '&.bold': {
          fontWeight: 700,
        },
        '&.uppercase': {
          textTransform: 'uppercase',
        },
        '&.truncate10': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '10ch',
        },
        '&.truncate15': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '15ch',
        },
        '&.truncate20': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '20ch',
        },
        '&.truncate25': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '25ch',
        },
        '&.truncate30': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '30ch',
        },
        '&.truncate40': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '40ch',
        },
        '&.truncate50': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '50ch',
        },
      },
      colorError: {
        color: bookitColors.danger.regular,
      },
      colorPrimary: {
        color: bookitColors.grayscale.blackRegular,
      },
      colorSecondary: { color: bookitColors.grayscale.grayComponents },
      colorTextPrimary: { color: bookitColors.primary.regular },
      colorTextSecondary: { color: bookitColors.success.regular },
    },
    MuiPaper: {
      root: {
        '&.PopUpListItemsPaper': {
          padding: '16px',
          backgroundColor: bookitColors.grayscale.grayBackground,
          border: '1px solid #E3E3E3',
        },
      },
    },
    MuiAppBar: {
      colorDefault: { backgroundColor: bookitColors.grayscale.grayBackground },
      root: {
        '.asset-wrapper-root &': {
          width: '100%',
          backgroundColor: bookitColors.base.white,
          color: bookitColors.base.black,
          boxShadow: box_shadow_s,
          borderRadius: 8,
          border: `1px solid ${bookitColors.grayscale.graySelection}`,
          padding: '20px 24px',
          gap: 24,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          '&.wrapper-root-auth': {
            padding: '8px 24px',
          },
          '& .app-bar-title': {
            width: '100%',
          },
        },
      },
    },
    MuiLink: {
      root: {
        color: bookitColors.primary.regular,
        '&:hover': {
          textDecoration: 'underline',
        },
        '&:focus': {
          textDecoration: 'underline',
        },
        cursor: 'pointer',
        '&.base-link': {
          cursor: 'pointer',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [lato_Light, lato_Regular, lato_Bold, lato_Black],
        ':root': {
          '--drawer-width': 240,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiInputBase: {
      root: {
        ...inputValueS,
        color: bookitColors.grayscale.blackRegular,
        '&.text-box-width-xs': {
          width: '32px',
          '&.number-field': {
            width: `${32 + 16}px`,
          },
        },
        '&.text-box-width-small': {
          width: '48px',
          '&.number-field': {
            width: `${48 + 16}px`,
          },
        },
        '&.text-box-width-medium': {
          width: '56px',
          '&.number-field': {
            width: `${56 + 16}px`,
          },
        },
        '&.text-box-width-large': {
          width: '72px',
          '&.number-field': {
            width: `${72 + 16}px`,
          },
        },
        '&.text-box-width-default': {
          width: '300px',
          '&.number-field': {
            width: `${300 + 16}px`,
          },
        },
        '&.file-input-hidden': {
          display: 'none',
        },
      },
      adornedStart: {
        paddingLeft: 12,
      },
      adornedEnd: {
        paddingRight: 12,
      },
      input: {
        '.textTable &': {
          minWidth: '80px',
        },
        '&.min-width-xs': {
          minWidth: '32px',
          paddingRight: '6px',
        },
        '&.min-width-small': {
          minWidth: '38px',
          paddingRight: '6px',
        },
        '&.min-width-medium': {
          minWidth: '56px',
          paddingRight: '6px',
        },
        '&.min-width-large': {
          minWidth: '72px',
          paddingRight: '6px',
        },
        '&.min-width-default': {
          minWidth: '300px',
        },
      },
      // marginDense: {
      //   width: '100px',
      // }
    },
    MuiInput: {
      root: {
        '&.standard': {
          position: 'relative',
          height: 40,
          //backgroundColor: theme.palette.common.white,
          border: `1px solid ${bookitColors.grayscale.grayBorders}`,
          gap: 4,
          borderRadius: 8,
          '&.inputSizeSmall': {
            gap: 0,
            paddingRight: 8,
            height: 32,
          },
          '&.inputSizeMedium': {
            height: 40,
          },
          '&.inputSizeLarge': {
            height: 48,
          },
          '&.inputSizeXl': {
            height: 56,
          },
          'label + &': {
            marginTop: '0px',
          },
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.10)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'rgba(255, 255, 255, 0.90)',
            },
          },
          '&$focused': {
            backgroundColor: 'rgba(255, 255, 255, 0.10)',
          },
          '&$disabled': {
            backgroundColor: 'rgba(255, 255, 255, 0.70)',
          },
          '&.with-end-label': {
            backgroundColor: bookitColors.grayscale.graybgComponents,
          },
          // '&$error': {

          // }
        },
      },
      formControl: {
        'label + &': {
          marginTop: '21px',
        },
      },
      colorSecondary: {
        '&$underline:after': {
          borderBottomColor: bookitColors.primary.borders,
        },
      },
      underline: {
        '&.standard': {
          '&:after': {
            borderBottom: `1px solid ${bookitColors.primary.regular}`,
            left: 0,
            bottom: -1,
            // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
            content: '""',
            position: 'absolute',
            right: 0,
            transform: 'scaleX(0)',
            pointerEvents: 'none', // Transparent to the hover style.
            transition: transitions.create('transform', {
              duration: transitions.duration.shorter,
              easing: transitions.easing.easeOut,
            }),
            marginInline: 8,
          },
          '&$focused:after': {
            transform: 'scaleX(1)',
          },
          '&$error:after': {
            borderBottomColor: bookitColors.danger.regular,
            transform: 'scaleX(1)', // error is always underlined in red
          },
          '&:before': {
            borderBottom: 'none',
            left: 0,
            bottom: -1,
            // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
            content: '"\\00a0"',
            // content: '""',
            position: 'absolute',
            right: 0,
            transition: transitions.create('border-bottom-color', {
              duration: transitions.duration.shorter,
            }),
            pointerEvents: 'none', // Transparent to the hover style.
            marginInline: 8,
          },
          '&:hover:before': {
            borderBottom: 'none',
          },
          '&$disabled:before': {
            borderBottomStyle: 'none',
          },
          '&$focused:before': {
            borderBottom: `1px solid ${bookitColors.primary.regular}`,
            transform: 'scaleX(1)',
          },
          '&$error:before': {
            borderBottom: `1px solid ${bookitColors.danger.regular}`,
            transform: 'scaleX(1)',
          },
          '&:hover:not($disabled):before': {
            borderBottom: `1px solid ${bookitColors.primary.regular}`,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              borderBottom: `1px solid ${bookitColors.primary.regular}`,
            },
          },
        },
      },
      // error: {
      //   border: `1px solid ${bookitColors.danger.borders}`,
      // },
      marginDense: {
        borderRadius: 8,
        height: 24,
      },
      multiline: {
        padding: '6px 12px',
        '&$marginDense': {
          paddingTop: 1,
          paddingBottom: 1,
        },
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          boxShadow: `inset 0 0 2px ${bookitColors.primary.borders}`,
          borderRadius: '6px',
          backgroundColor: bookitColors.primary.borders,
        },
      },
      input: {
        //minWidth: '48px',
        minWidth: '80px',
        padding: '6px 12px',
        height: '20px',
        borderRadius: 8,

        // '&:-webkit-autofill': {
        //   WebkitBoxShadow:
        //     theme.palette.type === 'light' ? null : '0 0 0 100px #266798 inset',
        //   WebkitTextFillColor: theme.palette.type === 'light' ? null : '#fff',
        //   caretColor: theme.palette.type === 'light' ? null : '#fff',
        // },
        '&&.inputSizeSmall &': {
          padding: '6px 8px',
        },
        '.standard &': {
          '.inputSizeLarge &': {
            paddingTop: 22,
            paddingBottom: 4,
          },
          '.inputSizeLarge.noLabel &': {
            paddingTop: 14,
            paddingBottom: 12,
          },
          '.inputSizeMedium &': {
            paddingTop: 9,
            paddingBottom: 9,
          },
          '.inputSizeSmall &': {
            paddingTop: 5,
            paddingBottom: 5,
          },
          '.inputSizeXl &': {
            padding: '26px 12px 8px',
          },
          '.with-end-label &': {
            backgroundColor: bookitColors.base.white,
          },
        },
      },
      inputMarginDense: {
        paddingTop: 1,
        paddingBottom: 1,
      },
      inputMultiline: {
        padding: 0,
        height: 'auto',
      },
      inputTypeSearch: {},
    },
    MuiFilledInput: {
      root: {
        border: `1px solid ${bookitColors.grayscale.grayBorders}`,
        backgroundColor: bookitColors.base.white,
        borderRadius: 8,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        '&:hover': {
          backgroundColor: bookitColors.base.white,
          '@media (hover: none)': {
            backgroundColor: 'rgba(255, 255, 255, 0.90)',
          },
        },
        '&$focused': {
          backgroundColor: bookitColors.base.white,
        },
        '&$disabled': {
          backgroundColor: bookitColors.grayscale.grayBackground,
        },
      },
      /* Styles applied to the root element if color secondary. */
      colorSecondary: {
        '&$underline:after': {
          borderBottomColor: bookitColors.danger.regular,
        },
      },
      underline: {
        '&:after': {
          borderBottom: `1px solid ${bookitColors.primary.regular}`,
          left: 0,
          bottom: -1,
          // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
          content: '""',
          position: 'absolute',
          right: 0,
          transform: 'scaleX(0)',
          pointerEvents: 'none', // Transparent to the hover style.
          transition: transitions.create('transform', {
            duration: transitions.duration.shorter,
            easing: transitions.easing.easeOut,
          }),
          marginInline: 8,
        },
        '&$focused:after': {
          transform: 'scaleX(1)',
        },
        '&$error:after': {
          borderBottomColor: bookitColors.danger.regular,
          transform: 'scaleX(1)', // error is always underlined in red
        },
        '&:before': {
          borderBottom: 'none',
          left: 0,
          bottom: -1,
          // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
          content: '"\\00a0"',
          // content: '""',
          position: 'absolute',
          right: 0,
          transition: transitions.create('border-bottom-color', {
            duration: transitions.duration.shorter,
          }),
          pointerEvents: 'none', // Transparent to the hover style.
          marginInline: 8,
        },
        '&:hover:before': {
          borderBottom: 'none',
        },
        '&$disabled:before': {
          borderBottomStyle: 'none',
        },
        '&$focused:before': {
          borderBottom: `1px solid ${bookitColors.primary.regular}`,
          transform: 'scaleX(1)',
        },
        '&$error:before': {
          borderBottom: `1px solid ${bookitColors.danger.regular}`,
          transform: 'scaleX(1)',
        },
      },
      /* Pseudo-class applied to the root element if the component is focused. */
      focused: {},

      /* Pseudo-class applied to the root element if `disabled={true}`. */
      disabled: {},

      /* Styles applied to the root element if `startAdornment` is provided. */
      adornedStart: {
        paddingLeft: 12,
      },

      /* Styles applied to the root element if `endAdornment` is provided. */
      adornedEnd: {
        paddingRight: 12,
      },

      /* Pseudo-class applied to the root element if `error={true}`. */
      error: {},

      /* Styles applied to the `input` element if `margin="dense"`. */
      marginDense: {},

      /* Styles applied to the root element if `multiline={true}`. */
      multiline: {
        padding: '4px 12px 4px',
        '&$marginDense': {
          paddingTop: 23,
          paddingBottom: 6,
        },
      },
      // input: {
      //   height: '20px',
      //   padding: '22px 12px 4px',
      // },
      // inputMarginDense: {
      //   paddingTop: '16px',
      //   paddingBottom: '6px',
      // },
      /* Styles applied to the `input` element. */
      input: {
        height: '20px',
        padding: '26px 12px 8px',
        '&:-webkit-autofill': {
          // WebkitBoxShadow:
          //   theme.palette.type === 'light' ? null : '0 0 0 100px #266798 inset',
          // WebkitTextFillColor: theme.palette.type === 'light' ? null : '#fff',
          // caretColor: theme.palette.type === 'light' ? null : '#fff',
          borderTopLeftRadius: 'inherit',
          borderTopRightRadius: 'inherit',
        },
        '.inputSizeLarge &': {
          paddingTop: 22,
          paddingBottom: 4,
        },
        '.inputSizeLarge.noLabel &': {
          paddingTop: 14,
          paddingBottom: 12,
        },
        '.inputSizeMedium &': {
          paddingTop: 9,
          paddingBottom: 9,
        },
        '.inputSizeSmall &': {
          paddingTop: 5,
          paddingBottom: 5,
        },
        '.inputSizeXl &': {
          padding: '26px 12px 8px',
        },
      },

      /* Styles applied to the `input` element if `margin="dense"`. */
      inputMarginDense: {
        paddingTop: 22,
        paddingBottom: 4,
      },

      /* Styles applied to the `input` if in `<FormControl hiddenLabel />`. */
      inputHiddenLabel: {
        paddingTop: 17,
        paddingBottom: 17,
        '&$inputMarginDense': {
          paddingTop: 9,
          paddingBottom: 9,
        },
      },

      /* Styles applied to the `input` element if `multiline={true}`. */
      inputMultiline: {
        padding: 0,
        '.inputSizeLarge &': {
          paddingTop: 14,
          paddingBottom: 4,
        },
        height: 'auto',
      },

      /* Styles applied to the `input` element if `startAdornment` is provided. */
      inputAdornedStart: {
        paddingLeft: 0,
      },

      /* Styles applied to the `input` element if `endAdornment` is provided. */
      inputAdornedEnd: {
        paddingRight: 0,
      },
    },
    MuiFormHelperText: {
      /* Styles applied to the root element. */
      root: {
        display: 'flex',
        paddingLeft: '4px',
        alignItems: 'center',
        gap: 0,
        alignSelf: 'stretch',
        color: bookitColors.grayscale.blackRegular,
        ...tipsRegular,
        textAlign: 'left',
        marginTop: 0,
        margin: 0,
        '&$disabled': {
          color: bookitColors.grayscale.graySelection,
        },
        '&$error': {
          color: bookitColors.danger.regular,
        },
        '&.boxed': {
          gap: 0,
        },
        '&.shiftRight': {
          gap: 28,
        },
        // '&.separate-picker': {
        //   position: 'absolute',
        //   top: 0,
        //   left: 0,
        //   '&.inputSizeLarge': {
        //     transform: 'translate(0px, 54px) scale(1)',
        //   },
        //   '&.inputSizeMedium': {
        //     transform: 'translate(0px, 46px) scale(1)',
        //   },
        //   '&.inputSizeSmall': {
        //     transform: 'translate(0px, 38px) scale(1)',
        //   },
        // },
        // empty helperText results in zero element height. So when field is validated the appearance of the
        // error message in the helperText causes the form element to increase in height and forces a redraw
        // empty char \00a0 causes the FormHelperText to always occupy one line height and prevents reflows
        '&::before': {
          content: '"\\00a0"',
        },
        // marginLeft: '6px',
        // ...tipsRegular,
      },

      /* Pseudo-class applied to the root element if `error={true}`. */
      error: {},

      /* Pseudo-class applied to the root element if `disabled={true}`. */
      disabled: {},

      /* Styles applied to the root element if `margin="dense"`. */
      marginDense: {
        marginTop: 4,
      },

      /* Styles applied to the root element if `variant="filled"` or `variant="outlined"`. */
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },

      /* Pseudo-class applied to the root element if `focused={true}`. */
      focused: {},

      /* Pseudo-class applied to the root element if `filled={true}`. */
      filled: {},

      /* Pseudo-class applied to the root element if `required={true}`. */
      required: {},
    },
    MuiFormGroup: {
      /* Styles applied to the root element. */
      root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        '&.shortLabel': {
          flexWrap: 'initial',
        },
        gap: 8,
        '&.boxed': {
          display: 'flex',
          height: 'auto',
          minHeight: '56px',
          //justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          alignSelf: 'stretch',
          padding: '16px',
          borderRadius: '8px',
          border: `1px solid ${bookitColors.grayscale.grayBorders}`,
          background: bookitColors.base.white,
          '&.toggleSizeSmall': {
            minHeight: '48px',
            padding: '12px',
          },
          '&.boxedError': {
            border: `1px solid ${bookitColors.danger.borders}`,
          },
          '&.boxedChecked': {
            border: `1px solid ${bookitColors.primary.borders}`,
            background: bookitColors.primary.background,
            //   '&.boxedSecondary': {
            //     border: `1px solid ${bookitColors.success.borders}`,
            //     background: bookitColors.success.background,
            //   },
            '&.boxedError': {
              border: `1px solid ${bookitColors.danger.borders}`,
              background: bookitColors.danger.background,
            },
          },
        },
        '&.horizontal': {
          gap: '16px',
        },
        '&.vertical': {
          gap: '16px',
          alignItems: 'flex-start',
          width: '100%',
        },
        '&.chip-form-group': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          gap: '6px',
        },
      },
      /* Styles applied to the root element if `row={true}`. */
      row: {
        flexDirection: 'row',
        '&.boxed': {
          justifyContent: 'space-between',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: bookitColors.grayscale.graySubtitles,
        ...bodyS,
        '&$focused': {
          color: bookitColors.grayscale.graySubtitles,
        },
        '&.ghosInputLabel': {
          display: 'flex',
          height: '17px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignSelf: 'stretch',
          color: bookitColors.grayscale.graySubtitles,
          ...bookitTypography.captionRegular,
          '&.ghosInputLabelBold': {
            fontWeight: '900',
          },
        },
        '&.chip-label': {
          color: bookitColors.grayscale.graySubtitles,
          ...inputLabelXS,
          '.Mui-focused &': {
            color: bookitColors.grayscale.graySubtitles,
          },
        },
        '&.file-label': {
          color: bookitColors.grayscale.blackRegular,
          ...buttonM,
          '.Mui-focused &': {
            color: bookitColors.grayscale.blackRegular,
          },
          marginBottom: '8px',
        },
        '&.radio-group-base-label': {
          marginBottom: '24px',
          color: bookitColors.grayscale.blackRegular,
          ...buttonM,
          '&[class*="Mui-focused"]': {
            color: bookitColors.grayscale.blackRegular,
          },
          '&.withLabel': {
            marginBottom: '8px',
          },
          '&.boldLabel': {
            fontWeight: 700,
          },
        },
        '&.paragraph': {
          ...inputLabelXS,
          color: bookitColors.grayscale.grayComponents,
        },
      },
      // focused: {
      //   color: bookitColors.grayscale.graySubtitles,
      // },
      asterisk: {
        color: bookitColors.danger.regular,
      },
    },
    MuiInputLabel: {
      root: {
        color: bookitColors.grayscale.grayComponents,
        ...inputValueS,
        display: 'block',
        transformOrigin: 'top left',
        '&.datetime-picker': {
          position: 'relative',
          transform: 'unset',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          width: '13ch',
          display: 'inline-block',
        },
        '&.shrinkFile': {
          transform: 'translate(12px, 4px) scale(0.85)',
        },
      },
      /* Pseudo-class applied to the root element if `focused={true}`. */
      focused: {},

      /* Pseudo-class applied to the root element if `disabled={true}`. */
      disabled: {},

      /* Pseudo-class applied to the root element if `error={true}`. */
      error: {},

      /* Pseudo-class applied to the root element if `required={true}`. */
      required: {},

      /* Pseudo-class applied to the asterisk element. */
      asterisk: {},
      /* Styles applied to the root element if the component is a descendant of `FormControl`. */
      formControl: {
        position: 'absolute',
        left: 0,
        top: 0,
        // slight alteration to spec spacing to match visual spec result
        transform: 'translate(0, 24px) scale(1)',
        '&.standard': {
          transform: 'translate(12px, 10px) scale(1)',
          '&.inputSizeSmall': {
            transform: 'translate(12px, 5px) scale(1)',
          },
          '&.inputSizeLarge': {
            transform: 'translate(12px, 14px) scale(1)',
          },
          '&.inputSizeXl': {
            transform: 'translate(12px, 18px) scale(1)',
          },
          '&.inputSizeMedium': {
            transform: 'translate(12px, 10px) scale(1)',
          },
        },
        '&.date-dropdown-picker': {
          transform: 'translate(0px, -20px) scale(1)',
        },
        '&.time-options-picker': {
          transform: 'translate(0px, -26px) scale(1)',
        },
        '&.noLabelInline': {
          transform: 'translate(0px, 0px) scale(1)',
        },
      },
      /* Styles applied to the root element if `margin="dense"`. */
      marginDense: {
        // Compensation for the `Input.inputDense` style.
        transform: 'translate(0, 21px) scale(1)',
      },
      /* Styles applied to the `input` element if `disableAnimation={false}`. */
      animated: {
        transition: transitions.create(['color', 'transform'], {
          duration: transitions.duration.shorter,
          easing: transitions.easing.easeOut,
        }),
      },
      /* Styles applied to the `input` element if `shrink={true}`. */
      shrink: {
        transform: 'translate(0, -2.5px) scale(1.1)',
        //transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
        ...inputLabelXS,
        '&.standard': {
          transform: 'translate(12px, 8px) scale(1)',
          '&.inputSizeSmall': {
            transform: 'translate(0px, -20px) scale(1)',
          },
          '&.inputSizeLarge': {
            transform: 'translate(12px, 6px) scale(1)',
          },
          '&.inputSizeMedium': {
            transform: 'translate(12px, 2px) scale(1)',
          },
          '&.inputSizeXl': {
            transform: 'translate(12px, 8px) scale(1)',
          },
        },
      },
      /* Styles applied to the root element if `variant="filled"`. */
      filled: {
        // Chrome's autofill feature gives the input field a yellow background.
        // Since the input field is behind the label in the HTML tree,
        // the input field is drawn last and hides the label with an opaque background color.
        // zIndex: 1 will raise the label above opaque background-colors of input.
        zIndex: 1,
        pointerEvents: 'none',
        height: '20px',
        transform: 'translate(12px, 18px) scale(1)',
        width: 'calc(100% - 24px)',
        overflow: 'hidden',
        paddingTop: 0,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '&.inputSizeLarge': {
          transform: 'translate(12px, 14px) scale(1)',
          '&.separate-picker': {
            transform: 'translate(0px, -20px) scale(1)',
          },
          '&.date-dropdown-picker': {
            transform: 'translate(0px, -20px) scale(1)',
          },
          '&.time-options-picker': {
            transform: 'translate(0px, -26px) scale(1)',
          },
        },
        '&.inputSizeMedium': {
          transform: 'translate(12px, 10px) scale(1)',
          '&.separate-picker': {
            transform: 'translate(0px, -20px) scale(1)',
          },
          '&.date-dropdown-picker': {
            transform: 'translate(0px, -20px) scale(1)',
          },
          '&.time-options-picker': {
            transform: 'translate(0px, -26px) scale(1)',
          },
        },
        '&.inputSizeSmall': {
          transform: 'translate(12px, 6px) scale(1)',
          '&.separate-picker': {
            transform: 'translate(0px, -20px) scale(1)',
          },
          '&.date-dropdown-picker': {
            transform: 'translate(0px, -20px) scale(1)',
          },
          '&.time-options-picker': {
            transform: 'translate(0px, -26px) scale(1)',
          },
        },
        '&$marginDense': {
          transform: 'translate(12px, 8px) scale(1)',
        },
        '&$shrink': {
          // letterSpacing: '0.2px',
          ...inputLabelXS,
          '&.inputSizeLarge': {
            transform: 'translate(12px, 6px) scale(1)',
          },
          '&.inputSizeMedium': {
            transform: 'translate(12px, 2px) scale(1)',
          },
          '&.inputSizeSmall': {
            transform: 'translate(12px, -18px) scale(1)',
          },
          transform: 'translate(12px, 6px) scale(1)',
          '&$marginDense': {
            transform: 'translate(12px, 4px) scale(0.75)',
          },
        },
        '&.datetime-picker': {
          transform: 'unset',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          width: '13ch',
          display: 'inline-block',
        },
        '&.rich-text-input-label': {
          transform: 'translate(9px, 15px) scale(1)',
          '&.rich-text-input-label-focused': {
            transform: 'translate(9px, 38px) scale(0.8)',
          },
        },
        // transform: 'translate(12px, 20px) scale(1)',
        // '&$marginDense': {
        //   transform: 'translate(12px, 17px) scale(1)',
        // },
        // '&$shrink': {
        //   transform: 'translate(12px, 10px) scale(0.75)',
        //   '&$marginDense': {
        //     transform: 'translate(12px, 7px) scale(0.75)',
        //   },
        // },
      },
      /* Styles applied to the root element if `variant="outlined"`. */
      outlined: {
        // see comment above on filled.zIndex
        zIndex: 1,
        pointerEvents: 'none',
        transform: 'translate(14px, 20px) scale(1)',
        '&$marginDense': {
          transform: 'translate(14px, 12px) scale(1)',
        },
        '&$shrink': {
          transform: 'translate(14px, -6px) scale(0.75)',
        },
      },
    },
    MuiInputAdornment: {
      positionStart: {
        cursor: 'pointer',
        '& svg': {
          width: '16px',
          height: '16px',
        },
      },
      positionEnd: {
        '&.date-picker': {
          marginLeft: 0,
        },
      },
      filled: {
        '&$positionStart:not($hiddenLabel)': {
          marginTop: 0,
        },
        width: '18px',
        height: '20px',
        cursor: 'pointer',
        '& svg': {
          height: '16px',
          width: '16px',
        },
        '&.text-end': {
          width: 'auto',
        },
      },
      marginDense: {
        '& svg': {
          fontSize: '0.8rem',
          cursor: 'pointer',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        cursor: 'auto',
      },
      fontSizeSmall: {
        fontSize: '0.8rem',
      },
    },
    MuiList: {
      root: {
        '&.side-bar-second-menu': {
          '&.side-bar-second-menu-open': {
            padding: '12px 0px 12px 0px',
            '& .side-bar-second-menu-item': {
              height: '32px',
              padding: '0px 8px 0px 16px',
              '& .side-bar-second-item-text': {
                '& [class*="MuiListItemText-primary"]': {
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '18ch',
                  ...bookitTypography.bodyS,
                },
              },
              '&:hover': {
                background: bookitColors.primary.bgHover,
              },
              '&:active, &:focus': {
                background: bookitColors.primary.bgHover,
                '& .side-bar-second-item-text': {
                  '& [class*="MuiListItemText-primary"]': {
                    fontWeight: 600,
                  },
                },
              },
              '&.Mui-selected': {
                background: bookitColors.primary.bgHover,
                '& .side-bar-second-item-text': {
                  '& [class*="MuiListItemText-primary"]': {
                    fontWeight: 600,
                  },
                },
              },
            },
          },
        },
        '&.sideBarList': {
          '& .sideBarListGroup': {
            display: 'flex',
            padding: '12px 0px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            borderBottom: `1px solid ${bookitColors.grayscale.graySelection}`,
          },
          '&.sideBarCollapsed': {
            alignSelf: 'stretch',
          },
          '&.sideBarExpanded': {
            width: '240px',
            '&.sideBarListMobile': {
              width: '100%',
            },
          },
        },
        '&.secondMenuItem': {
          display: 'flex',
          paddingBottom: '12px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          background: bookitColors.grayscale.graybgComponents,
          '.sideBarListMobile &': {
            width: '100%',
          },
          '& .menuItem': {
            display: 'flex',
            width: '240px',
            height: '32px',
            padding: '0px 8px 0px 16px',
            alignItems: 'center',
            gap: '8px',
            '.sideBarListMobile &': {
              width: '100%',
            },
            '&:hover, &:focus': {
              background: bookitColors.primary.bgHover,
              '& .menuItemContent': {
                '& .menuItemText': {
                  ...bookitTypography.bodyS,
                },
              },
            },
            '&:active': {
              background: bookitColors.primary.bgHover,
              '& .menuItemContent': {
                '& .menuItemText': {
                  ...bookitTypography.bodyS,
                },
              },
            },
            '&.Mui-selected': {
              background: bookitColors.primary.bgHover,
              '& .menuItemContent': {
                '& .menuItemText': {
                  ...bookitTypography.bodyS,
                  fontWeight: 600,
                },
              },
            },
            '& .menuItemContent': {
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '8px',
              gap: '8px',
              flex: '1 0 0',
              '& .menuItemIcon': {
                display: 'flex',
                alignItems: 'flex-start',
                gap: '7.5px',
                width: 'auto',
                height: 12,
                color: bookitColors.grayscale.grayComponents,
                '& > svg': {
                  width: '12px',
                },
              },
              '& .menuItemText': {
                flex: '1 0 0',
                color: bookitColors.grayscale.blackRegular,
                ...bookitTypography.bodyS,
                cursor: 'pointer',
                whiteSpace: 'inherit',
              },
            },
          },
        },
        '&.view-type-length-dropdown': {
          minWidth: '240px',
        },
        '&.batch-list': {
          maxHeight: '210px',
          overflowY: 'auto',
        },
        '&.browse-by-unit-root': {
          width: '100%',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            boxShadow: `inset 0 0 2px ${bookitColors.grayscale.grayBorders}`,
            borderRadius: '6px',
            backgroundColor: bookitColors.grayscale.grayBorders,
          },
        },
        '&.invoiceTemplateRightSectionRoot': {
          width: '100%',
          backgroundColor: bookitColors.grayscale.grayBackground,
          position: 'relative',
          overflow: 'auto',
          maxHeight: '63vh',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            boxShadow: `inset 0 0 2px ${bookitColors.grayscale.grayBorders}`,
            borderRadius: '6px',
            backgroundColor: bookitColors.grayscale.grayBorders,
          },
        },
      },
    },
    MuiListItem: {
      root: {
        gap: 12,
        '&.assetListItemLink': {
          display: 'flex',
          width: '100%',
          padding: '4px 8px',
          alignItems: 'center',
          gap: '12px',

          borderRadius: '4px',
          background: bookitColors.base.white,
          '&:hover, &:focus': {
            backgroundColor: bookitColors.grayscale.grayBackground,
          },
          '&:active': {
            backgroundColor: bookitColors.grayscale.graybgComponents,
          },
        },
        '&.side-bar-menu-item': {
          '&:hover': {
            backgroundColor: bookitColors.grayscale.graybgComponents,
          },
          '&.side-bar-menu-item-short': {
            justifyContent: 'center',
            '& .menuItemIcon': {
              justifyContent: 'center',
            },
          },
        },
        '&.menuItem': {
          padding: 0,
          '&.mainTabMenu': {
            display: 'flex',
            height: '40px',
            padding: '0px 16px',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            backgroundColor: 'transparent',
            '& .menuItemContent': {
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              flex: '1 0 0',
              '& .menuItemIcon': {
                display: 'flex',
                paddingBottom: '1px',
                alignItems: 'flex-start',
                gap: '10px',
                width: 'auto',
                height: 20,
                color: bookitColors.grayscale.grayComponents,
                '& > svg': {
                  width: '19px',
                },
              },
              '& .menuItemText': {
                flex: '1 0 0',
                color: bookitColors.grayscale.blackRegular,
                ...bookitTypography.buttonS,
                cursor: 'pointer',
                whiteSpace: 'initial',
              },
            },
            '&:hover, &:focus': {
              backgroundColor: bookitColors.grayscale.graybgComponents,
            },
            '&:active': {
              backgroundColor: bookitColors.grayscale.graybgComponents,
            },
            '&.mainTabExpanded': {
              '& .menuItemContent': {
                '& .menuItemIcon': {
                  minWidth: 'initial',
                },
              },
            },
            '&.mainTabShort': {
              '& .menuItemContent': {
                '& .menuItemIcon': {
                  minWidth: '60px',
                },
              },
            },
          },
        },
        '.hoverItem &': {
          display: 'flex',
          width: '60px',
          height: '40px',
          padding: '0px 16px',
          alignItems: 'center',
          gap: '8px',
          backgroundColor: 'transparent',
          '& .menuItemHoverContent': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            flex: '1 0 0',
            '& .menuItemIcon': {
              display: 'flex',
              paddingBottom: '1px',
              alignItems: 'flex-start',
              gap: '10px',
              width: 'auto',
              height: 20,
              color: bookitColors.grayscale.grayComponents,
              '& > svg': {
                width: '19px',
              },
            },
          },
          '&:hover, &:focus': {
            backgroundColor: bookitColors.grayscale.graySelection,
            '& .menuItemHoverContent': {
              '& .menuItemIcon': {
                color: bookitColors.grayscale.grayComponents,
              },
            },
          },
          '&:focus': {
            backgroundColor: bookitColors.primary.bgHover,
            '& .menuItemHoverContent': {
              '& .menuItemIcon': {
                color: bookitColors.primary.components,
              },
            },
          },
          '&:active': {
            backgroundColor: bookitColors.primary.bgHover,
            '& .menuItemHoverContent': {
              '& .menuItemIcon': {
                color: bookitColors.primary.components,
              },
            },
          },
          '&.Mui-selected': {
            backgroundColor: bookitColors.primary.bgHover,
            '& .menuItemHoverContent': {
              '& .menuItemIcon': {
                color: bookitColors.primary.components,
              },
            },
          },
        },
        '&::before': {
          // fixes conflict between the sanitize.css nav>li::before (safari reader fix) and Mui's menu items - causing each menu item in nave to occupy 2 lines
          content: 'none',
        },
        '&.actions-admin': {
          '& .actions': {
            display: 'flex',
            gap: '8px',
          },
        },
        '&.browse-by-unit-division-root': {
          paddingInline: 23,
          cursor: 'pointer',
        },
        '&.units-root-cursor': {
          cursor: 'pointer',
        },
        '&.units-paddingInline-cursor': {
          cursor: 'pointer',
          paddingInline: 31,
        },
      },
      container: {
        '&::before': {
          // fixes conflict between the sanitize.css nav>li::before (safari reader fix) and Mui's menu items - causing each menu item in nave to occupy 2 lines
          content: 'none',
        },
      },
    },
    MuiListItemText: {
      root: {
        '.assetListItemLink &': {
          display: 'flex',
          alignItems: 'flex-start',
          gap: '8px',
          flex: '1 0 0',
          margin: 0,
        },
      },
      primary: {
        '.assetListItemLink &': {
          display: 'inline-block',
          maxWidth: '50%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          ...boldS,
        },
        // '&.publicAssetItemTextOpen': {
        //   fontWeight: 'bold',
        // },
        // '&.publicAssetItemTextSelected': {
        //   fontWeight: 'bold',
        //   color: bookitColors.primary.regular,
        // },
      },
      secondary: {
        '.assetListItemLink &': {
          display: 'flex',
          height: '20px',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: '1 0 0',
          color: bookitColors.grayscale.blackRegular,
          ...bodyS,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 20,
        minHeight: 20,
        '.assetListItemLink &': {
          display: 'flex',
          width: '32px',
          height: '32px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          flexShrink: 0,
          borderRadius: '4px',
        },
      },
    },
    MuiDivider: {
      root: {
        '&.side-bar-menu-divider': {
          marginTop: '12px',
          marginBottom: '12px',
        },
      },
    },
    MuiAvatar: {
      root: {
        '.assetListItemLink &': {
          color: bookitColors.grayscale.grayComponentsLight,
          backgroundColor: bookitColors.grayscale.graySelection,
          width: '32px',
          height: '32px',
          borderRadius: '4px',
        },
        '&.customer-logo': {
          '&>img': {
            objectFit: 'contain',
          },
          '&.defaultLogoSize': {
            height: '36px',
            width: 'auto',
          },
          '&.emptyPageLogoSize': {
            height: '40px',
            width: 'auto',
          },
          '&.sidebarLogoSize': {
            height: '24px',
            width: '90px',
          },
        },
        '&.user-avatar': {
          width: '48px',
          height: '48px',
          backgroundColor: bookitColors.success.bgLight, //'#DBF9ED',
          color: bookitColors.success.regular, //'#10BD79',
          '&.pointer': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiTableContainer: {
      root: {
        overflowX: 'unset',
        height: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
      },
    },
    MuiTable: {
      root: {
        //flex test -----
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // flex: '1 1 auto',
        //---------------
        padding: 0,
        borderRadius: '8px',
        boxShadow: `inset 0px 0px 1px 1px ${bookitColors.grayscale.grayBorders}`,
        // [breakpoints.down('md')]: {
        //   display: 'block',
        //   overflowX: 'auto',
        //   height: '100%',
        // },
      },
    },
    MuiTableHead: {
      root: {
        //flex test -----
        // display: 'flex',
        // flexFlow: 'row nowrap',
        // alignItems: 'flex-start',
        //width: 'inherit',
        //---------------
        padding: 0,
      },
    },
    MuiTableBody: {
      root: {
        //flex test -----
        // display: 'flex',
        // flexFlow: 'row wrap',
        // width: 'inherit',
        //---------------
        padding: 0,
      },
    },
    MuiTableRow: {
      root: {
        //flex test -----
        // display: 'flex',
        // flex: '1 1 auto',
        // width: 'inherit',
        //---------------
        '& td:first-child ': {
          boxShadow: `0px 1px 0px 0px ${bookitColors.grayscale.grayBorders}`,
        },
        '& th:first-child ': {
          boxShadow: `0px 1px 0px 0px ${bookitColors.grayscale.grayBorders}`,
        },
      },
    },
    MuiTableCell: {
      root: {
        //flex test -----
        // display: 'flex',
        // flexFlow: 'row nowrap',
        // flexGrow: 1,
        // flexBasis: 0,
        //---------------
        //height: '32px',
        ...bodyS,
        boxShadow: `-1px 1px 0px ${bookitColors.grayscale.grayBorders}`,
        borderBottom: 'unset',
        height: '56px',
        color: bookitColors.grayscale.blackRegular,
        [breakpoints.down('md')]: {
          whiteSpace: 'nowrap',
        },
        '&.services-cell': {
          // setting the background color on the cell causes it's borders to disappear
          backgroundColor: bookitColors.grayscale.grayBackground,
          backgroundClip: 'content-box',
          // unsets 56px cell height set on TableCell
          height: 'unset',
          padding: '1px',
          '& .table-root': {
            paddingBottom: '24px',
          },
        },
        '&.cell-grouped': {
          fontWeight: 'bold',
        },
      },
      head: {
        lineHeight: '164%',
        fontWeight: 'bold',
        padding: '4px 12px',
        gap: '10px',
        whiteSpace: 'nowrap',
      },
      body: {
        fontWeight: 'normal',
        lineHeight: '141%',
        padding: '8px 12px',
        gap: '10px',
        '& > span a': {
          color: bookitColors.primary.regular, // accentColors.colorAccentRegular,
        },
        '& > span > button': {
          color: bookitColors.primary.regular, //accentColors.colorAccentRegular,
        },
        '& > a': {
          color: bookitColors.primary.regular, // accentColors.colorAccentRegular,
        },
        '& > button': {
          color: bookitColors.primary.regular, // accentColors.colorAccentRegular,
        },
        // '& > span': {
        //   display: 'inline-block',
        //   whiteSpace: 'break-spaces',
        //   maxWidth: '25ch',
        //   minWidth: '6ch',
        //   //overflow: 'hidden',
        //   //textOverflow: 'ellipsis',
        // },
      },
      sizeSmall: {
        height: '40px',
        padding: '0px 12px',
        gap: '10px',
      },
      footer: {
        lineHeight: '164%',
        fontWeight: 'bold',
        padding: '12px',
      },
    },
    MuiTableSortLabel: {
      root: {
        cursor: 'pointer',
        '&$active': {
          // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
          '&& $icon': {
            color: bookitColors.grayscale.blackRegular,
          },
        },
        '&:focus': {
          '&& $icon': {
            fontSize: 16,
            opacity: 0.5,
          },
        },
      },
      icon: {
        fontSize: '12px',
        cursor: 'pointer',
        color: bookitColors.grayscale.blackRegular,
      },
    },
    MuiTablePagination: {
      root: {
        fontSize: '14px',
        color: bookitColors.grayscale.blackRegular,
        backgroundColor: bookitColors.base.white,
        '.table-cards &': {
          backgroundColor: bookitColors.grayscale.grayBackground,
        },
      },
      caption: {
        fontSize: '14px', // convert('14px', 'em'),
        fontWeight: 'bold',
        letterSpacing: '0.1px',
        lineHeight: '151%',
      },
      spacer: {
        flex: 'none',
      },
      input: {
        marginRight: 'auto',
        marginLeft: 0,
      },
      selectRoot: {
        fontWeight: 'bold',
      },
      select: {
        '&:focus': {
          border: `1px solid ${bookitColors.primary.hover}`,
          background: bookitColors.primary.background,
          borderRadius: '6px',
          fontWeight: 'bold',
          color: bookitColors.primary.dark,
        },
      },
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
        gap: '16px',
        fontWeight: 'bold',
        fontSize: '14px',
        minHeight: '40px',
        '.table-content-short &': {
          gap: '0px',
        },
      },
      menuItem: {
        padding: '10px 16px',
        gap: '12px',
        fontSize: '14px',
        color: bookitColors.grayscale.blackRegular,
      },
    },
    MuiTextField: {
      root: {
        '&.startIcon': {
          '& > label': {
            marginLeft: '24px',
          },
        },
        '&.invoiceTemplateHtmlTextBox': {
          width: '100%',
          minWidth: 500,
          backgroundColor: bookitColors.base.white,
          maxHeight: '63vh',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            boxShadow: `inset 0 0 2px ${bookitColors.grayscale.grayBorders}`,
            borderRadius: '6px',
            backgroundColor: bookitColors.grayscale.grayBorders,
          },
        },
      },
    },
    MuiPopover: {
      root: {
        '&.root-popover': {
          zIndex: 1300,
        },
        '&.root-mobile': {
          width: '100vw',
          height: 'calc(100% - 90px)',
          overflow: 'auto',
        },
      },
      paper: {
        '.full-height &': {
          height: '100%',
        },
      },
    },
    MuiChip: {
      root: {
        '&.recentItem': {
          display: 'flex',
          height: '32px',
          padding: '0px 8px',
          alignItems: 'center',
          gap: '12px',
          flexShrink: 0,
          justifyContent: 'flex-start',
          ...bodyS,
          borderRadius: '4px',
          background: bookitColors.base.white,
          '& [class*="MuiChip-avatar"]': {
            margin: 0,
            width: 16,
            height: 16,
            color: bookitColors.grayscale.grayComponents,
          },
          '&$disabled': {
            color: bookitColors.grayscale.grayComponentsLight,
            opacity: 1,
          },
          '&:hover, &:focus': {
            backgroundColor: bookitColors.grayscale.grayBackground,
          },
          '&:active': {
            backgroundColor: bookitColors.grayscale.graybgComponents,
            boxShadow: 'none',
          },
          '& [class*="MuiChip-label"]': {
            flex: '1 0 0',
            padding: 0,
          },
        },
      },
      clickable: {
        '&:focus': {
          backgroundColor: bookitColors.grayscale.grayComponentsLight,
          color: bookitColors.base.black,
        },
        '&:hover': {
          backgroundColor: bookitColors.grayscale.grayComponentsLight,
          color: bookitColors.base.black,
        },
      },
    },
    MuiButton: MuiButtonOverride,
    MuiIconButton: MuiIconButtonOverride,
    MuiToggleButtonGroup: MuiToggleButtonGroupOverride,
    MuiToggleButton: MuiToggleButtonOverride,
    MuiSwitch: {
      //TO DO: set a padding: '0px' after Toggle component will replace a Switch component
      /* Styles applied to the root element. */
      root: {
        display: 'inline-flex',
        width: 36,
        height: 20,
        overflow: 'hidden',
        padding: '0px',
        boxSizing: 'border-box',
        position: 'relative',
        flexShrink: 0,
        zIndex: 0,
        // Reset the stacking context.
        verticalAlign: 'middle',
        // For correct alignment with the text.
        '@media print': {
          colorAdjust: 'exact',
        },
        // '&>[class*="PrivateSwitchBase-root"]': {
        //   padding: 2,
        // },
      },
      /* Styles applied to the internal `SwitchBase` component's `root` class. */
      switchBase: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        // Render above the focus ripple.
        color: bookitColors.grayscale.grayComponents,
        transition: transitions.create(['left', 'transform'], {
          duration: transitions.duration.shortest,
        }),
        padding: '2px',
        border: 0,
        '&$checked': {
          transform: 'translateX(16px)',
        },
        '&$disabled': {
          color: bookitColors.grayscale.grayComponentsLight,
        },
        '&$checked + $track': {
          opacity: 'unset',
        },
        '&$disabled + $track': {
          opacity: 'unset',
        },
      },
      /* Pseudo-class applied to the internal `SwitchBase` component's `checked` class. */
      checked: {},

      /* Pseudo-class applied to the internal SwitchBase component's disabled class. */
      disabled: {},

      /* Styles applied to the internal SwitchBase component's input element. */
      input: {
        left: '-100%',
        width: '300%',
      },
      /* Styles applied to the internal SwitchBase component's root element if `color="primary"`. */
      colorPrimary: {
        '&$checked': {
          color: bookitColors.primary.components,
          '&:hover': {
            //backgroundColor: bookitColors.primary.componentsLight,
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        '&$disabled': {
          color: bookitColors.grayscale.graySelection,
          //backgroundColor: bookitColors.grayscale.graySelection,
        },
        '&$checked + $track': {
          backgroundColor: bookitColors.primary.components,
        },
        '&.Mui-checked.Mui-disabled + $track': {
          backgroundColor: bookitColors.primary.disabled,
        },
        '&$disabled + $track': {
          backgroundColor: bookitColors.grayscale.graySelection,
        },
      },
      /* Styles applied to the internal SwitchBase component's root element if `color="secondary"`. */
      colorSecondary: {
        '&$checked': {
          color: bookitColors.danger.regular,
          '&:hover': {
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        '&$disabled': {
          color: bookitColors.danger.disabled,
        },
        '&$checked + $track': {
          backgroundColor: bookitColors.danger.regular,
        },
        '&.Mui-checked.Mui-disabled + $track': {
          backgroundColor: bookitColors.danger.disabled,
        },
        '&$disabled + $track': {
          backgroundColor: bookitColors.grayscale.graySelection,
          border: `1px solid ${bookitColors.danger.borders}`,
        },
      },
      /* Styles applied to the root element if `size="small"`. */
      sizeSmall: {
        width: 36,
        height: 20,
        padding: 0,
        '& $thumb': {
          width: 16,
          height: 16,
        },
        '& $switchBase': {
          padding: 2,
          '&$checked': {
            transform: 'translateX(16px)',
          },
        },
      },
      /* Styles used to create the thumb passed to the internal `SwitchBase` component `icon` prop. */
      thumb: {
        backgroundColor: bookitColors.base.white,
        borderRadius: '8px',
        height: '16px',
        position: 'relative',
        width: '16px',
        boxShadow: box_shadow_s,
      },
      /* Styles applied to the track element. */
      track: {
        borderRadius: '100px',
        padding: '0px',
        height: '100%',
        width: '100%',
        zIndex: -1,
        transition: 'unset',
        backgroundColor: bookitColors.grayscale.grayComponentsLight,
        opacity: 'unset',
      },
    },
    MuiFormControl: {
      /* Styles applied to the root element. */
      root: {
        display: 'inline-flex',
        flexDirection: 'column',
        position: 'relative',
        // Reset fieldset default style.
        minWidth: 0,
        padding: 0,
        margin: 0,
        border: 0,
        verticalAlign: 'top', // Fix alignment issue on Safari.

        minHeight: '20px',
        // alignItems: 'center',
        gap: '8px',
        flexShrink: 0,
        '&.boxed': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '8px',
        },
        '&.inputSizeMedium.withLabel': {
          marginTop: '16px',
        },
        '&.inputSizeSmall.withLabel': {
          marginTop: '16px',
        },
        '&.separate-picker': {
          // flexDirection: 'row',
          // flexShrink: 1,
          // marginBottom: '18px',
          '& .separate-picker-inputs': {
            display: 'flex',
            flexDirection: 'row',
            flex: '1 1 auto',
            alignSelf: 'stretch',
            alignItems: 'center',
            gap: 8,
          },
          '&.withLabel': {
            marginTop: '16px',
          },
        },
        '&.separate-time-picker': {
          flexShrink: 1,
        },
        '&.separate-date-picker': {
          flexShrink: 1,
        },
        '&.datetime-picker': {
          // flexDirection: 'row',
          // flexShrink: 1,
          // marginBottom: '18px',
          '& .datetime-picker-inputs': {
            display: 'flex',
            flexDirection: 'row',
            flex: '1 1 auto',
            alignSelf: 'stretch',
            alignItems: 'center',
            gap: 8,
          },
          // '&.withLabel': {
          //   marginTop: '16px',
          // },
        },
        '&.datetime-time-picker': {
          flexShrink: 1,
        },
        '&.datetime-date-picker': {
          flexShrink: 1,
        },
        '&.date-dropdown-picker': {
          // flexDirection: 'row',
          // flexShrink: 1,
          // marginBottom: '18px',
          '& .date-dropdown-picker-inputs': {
            display: 'flex',
            flexDirection: 'row',
            flex: '1 1 auto',
            alignSelf: 'stretch',
            alignItems: 'center',
            gap: 8,
          },
          '&.withLabel': {
            marginTop: '16px',
          },
        },
        '&.time-options-picker': {
          marginTop: '20px',
          '&.withLabel': {
            marginTop: '20px',
          },
        },
        '&.formLabelText': {
          gap: 0,
        },
        '&.ghostInput': {
          display: 'flex',
          minHeight: '48px',
          padding: '5px 0px',
          alignItems: 'center',
          gap: '10px',
          flexShrink: 0,
          borderRadius: '8px',
          '& .ghosInputText': {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 0',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            width: '100%',
          },
        },
        '&.radio-group-base': {
          marginBottom: 0,
        },
        '&.base-text-field': {
          '&.startIcon': {
            '& > label': {
              marginLeft: '24px',
            },
          },
          '&.asset-search-input-mobile': {
            color: 'inherit',
            flexGrow: 1,
            flexShrink: 1,
          },
          '&.asset-search-input': {
            color: 'inherit',
            flexGrow: 1,
          },
        },
        '&.chip-form-control': {
          border: '1px solid #CDD5DF',
          borderRadius: '6px',
          minHeight: '48px',
          backgroundColor: '#ffffffcc',
          paddingBlock: '8px',
          paddingInline: '6px',
          width: '100%',
          maxWidth: 'calc(100% - 26px)',
        },
        '&.text-box-width-xs': {
          width: '32px',
          '&.number-field': {
            width: `${32 + 16}px`,
          },
        },
        '&.text-box-width-small': {
          width: '48px',
          '&.number-field': {
            width: `${48 + 16}px`,
          },
        },
        '&.text-box-width-medium': {
          width: '56px',
          '&.number-field': {
            width: `${56 + 16}px`,
          },
        },
        '&.text-box-width-large': {
          width: '72px',
          '&.number-field': {
            width: `${72 + 16}px`,
          },
        },
        '&.text-box-width-default': {
          width: '300px',
          '&.number-field': {
            width: `${300 + 16}px`,
          },
        },
        '&.text-box-width-xl': {
          width: '120px',
          '&.number-field': {
            width: `${120 + 16}px`,
          },
        },
        '&.paragraph-control': {
          '& .paragraph-text': {
            ...bodyS,
            '& > *': {
              margin: 0,
            },
          },
        },
      },

      /* Styles applied to the root element if `margin="normal"`. */
      marginNormal: {
        marginTop: 16,
        marginBottom: 8,
      },

      /* Styles applied to the root element if `margin="dense"`. */
      marginDense: {
        marginTop: 8,
        marginBottom: 4,
      },

      /* Styles applied to the root element if `fullWidth={true}`. */
      fullWidth: {
        width: '100%',
      },
    },
    MuiFormControlLabel: {
      /* Styles applied to the root element. */
      root: {
        display: 'inline-flex',
        alignItems: 'center',
        cursor: 'pointer',
        // For correct alignment with the text.
        verticalAlign: 'middle',
        WebkitTapHighlightColor: 'transparent',
        marginLeft: 0,
        marginRight: 0,
        gap: 8,
        // used for row presentation of radio/checkbox
        '&$disabled': {
          cursor: 'default',
        },
        '&.boldLabel': {
          '& $label': {
            ...boldS,
            '&$disabled': {
              color: bookitColors.grayscale.graySubtitles,
            },
          },
        },
        '&.chip-form-label': {
          width: '260px',
          margin: 0,
        },
        '&.autoWrap': {
          '& $label': {
            whiteSpace: 'normal',
            overflow: 'visible',
            textOverflow: 'unset',
          },
        },
      },
      /* Styles applied to the root element if `labelPlacement="start"`. */
      labelPlacementStart: {
        flexDirection: 'row-reverse',
        marginLeft: 16,
        // used for row presentation of radio/checkbox
        marginRight: -11,
      },

      /* Styles applied to the root element if `labelPlacement="top"`. */
      labelPlacementTop: {
        flexDirection: 'column-reverse',
        marginLeft: 0,
        marginRight: 2,
      },

      /* Styles applied to the root element if `labelPlacement="bottom"`. */
      labelPlacementBottom: {
        flexDirection: 'column',
        marginLeft: 16,
      },

      /* Pseudo-class applied to the root element if `disabled={true}`. */
      disabled: {},

      /* Styles applied to the label's Typography component. */
      label: {
        ...bodyS,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // maxWidth: '25ch',
        '&$disabled': {
          color: bookitColors.grayscale.graySubtitles,
        },
        '&.boldLabel': {
          ...boldS,
          '&$disabled': {
            color: bookitColors.grayscale.graySubtitles,
          },
        },
        '.checkbox-label &': {
          whiteSpace: 'pre-wrap',
          maxWidth: '300px',
        },
        '.shortLabel &': {
          whiteSpace: 'pre-wrap',
          maxWidth: '300px',
        },
        '.shortLabel.withInput &': {
          display: 'block',
        },
        '.withInput &': {
          display: 'contents',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: bookitColors.grayscale.graySelection,
        '&.base-check': {
          '&[class*="PrivateSwitchBase-root"]': {
            '&.with-padding': {
              padding: '9px',
            },
            padding: 0,
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '& .base-check-icon': {
            borderRadius: 4,
            backgroundColor: bookitColors.base.white,
            backgroundImage:
              'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            width: '20px',
            height: '20px',
            '&.checkSizeSmall': {
              width: '16px',
              height: '16px',
            },
            boxShadow: `inset 0 0 0 1px ${bookitColors.grayscale.grayBorders}, inset 0 -1px 0 ${bookitColors.grayscale.grayBorders}`,
            '&.base-check-danger': {
              boxShadow: `inset 0 0 0 1px ${bookitColors.danger.borders}, inset 0 -1px 0 ${bookitColors.danger.borders}`,
            },

            '$root.Mui-focusVisible &': {
              // outline: `1px auto ${blackGray.colorGrayComponentsLight}`,
              // outlineOffset: 1,
              boxShadow: `inset 0 0 0 2px ${bookitColors.grayscale.grayComponentsLight}, inset 0 -1px 0 ${bookitColors.grayscale.grayComponentsLight}`,
              '&.base-check-danger': {
                boxShadow: `inset 0 0 0 2px ${bookitColors.danger.componentsLight}, inset 0 -1px 0 ${bookitColors.danger.componentsLight}`,
              },
            },
            '&:hover': {
              boxShadow: `inset 0 0 0 1px ${bookitColors.grayscale.grayBorders}, inset 0 -1px 0 ${bookitColors.grayscale.grayBorders}`,
              '&.base-check-danger': {
                boxShadow: `inset 0 0 0 1px ${bookitColors.danger.borders}, inset 0 -1px 0 ${bookitColors.danger.borders}`,
              },
            },
            'input:hover ~ &': {
              // backgroundColor: '#ebf1f5',
              //border: `1px solid ${bookitColors.grayscale.grayBorders}`,
              boxShadow: `inset 0 0 0 1px ${bookitColors.grayscale.grayBorders}, inset 0 -1px 0 ${bookitColors.grayscale.grayBorders}`,
              '&.base-check-danger': {
                boxShadow: `inset 0 0 0 1px ${bookitColors.danger.borders}, inset 0 -1px 0 ${bookitColors.danger.borders}`,
              },
            },
            'input:disabled ~ &': {
              boxShadow: `inset 0 0 0 1px ${bookitColors.grayscale.graySelection}, inset 0 -1px 0 ${bookitColors.grayscale.graySelection}`,
              background: bookitColors.grayscale.grayBackground,
              '&.base-check-danger': {
                boxShadow: `inset 0 0 0 1px ${bookitColors.danger.disabled}, inset 0 -1px 0 ${bookitColors.danger.disabled}`,
                background: bookitColors.danger.background,
              },
            },
            '&.checked-icon': {
              boxShadow: 'unset',
              backgroundColor: bookitColors.primary.regular,

              '&.base-check-danger': {
                backgroundColor: bookitColors.danger.regular,
              },
              backgroundImage:
                'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
              '&:before': {
                display: 'block',
                width: '20px',
                height: '20px',
                '&.checkSizeSmall': {
                  width: '16px',
                  height: '16px',
                },
                backgroundImage:
                  "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                  " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                  "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
              },
              '&:hover': {
                backgroundColor: bookitColors.primary.hover,
                boxShadow: 'unset',
                '&.base-check-danger': {
                  backgroundColor: bookitColors.danger.hover,
                },
              },
              'input:hover ~ &': {
                backgroundColor: bookitColors.primary.hover,
                boxShadow: 'unset',
                '&.base-check-danger': {
                  backgroundColor: bookitColors.danger.hover,
                },
              },
              'input:disabled ~ &': {
                boxShadow: 'unset', //`inset 0 0 0 1px ${blackGray.colorDividers}, inset 0 -1px 0 ${blackGray.colorDividers}`,
                background: bookitColors.primary.disabled,
                '&.base-check-danger': {
                  background: bookitColors.danger.disabled,
                },
              },
            },
            '&.indeterminate-icon': {
              boxShadow: 'unset',
              backgroundColor: bookitColors.primary.regular,
              backgroundImage:
                'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
              '&.base-check-danger': {
                backgroundColor: bookitColors.danger.regular,
              },
              '&:before': {
                display: 'block',
                width: '20px',
                height: '20px',
                '&.checkSizeSmall': {
                  width: '16px',
                  height: '16px',
                },
                backgroundImage:
                  "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-54 -24 516 560'%3E%3Cpath" +
                  " fill-rule='evenodd' clip-rule='evenodd' d='M400 288h-352c-17.69 " +
                  "0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
              },
              '&:hover': {
                backgroundColor: bookitColors.primary.hover,
                boxShadow: 'unset',
                '&.base-check-danger': {
                  backgroundColor: bookitColors.danger.hover,
                },
              },
              'input:hover ~ &': {
                backgroundColor: bookitColors.primary.hover,
                boxShadow: 'unset',
                '&.base-check-danger': {
                  backgroundColor: bookitColors.danger.hover,
                },
              },
              'input:disabled ~ &': {
                boxShadow: 'unset', //`inset 0 0 0 1px ${blackGray.colorDividers}, inset 0 -1px 0 ${blackGray.colorDividers}`,
                background: bookitColors.primary.disabled,
                '&.base-check-danger': {
                  background: bookitColors.danger.disabled,
                },
              },
            },
          },
          '&[class*="Mui-disabled"]': {
            '& .base-check-icon': {
              boxShadow: `inset 0 0 0 1px ${bookitColors.grayscale.graySelection}, inset 0 -1px 0 ${bookitColors.grayscale.graySelection}`,
              background: bookitColors.grayscale.grayBackground,
              '&.base-check-danger': {
                boxShadow: `inset 0 0 0 1px ${bookitColors.danger.disabled}, inset 0 -1px 0 ${bookitColors.danger.disabled}`,
                background: bookitColors.danger.background,
              },
              '&.checked-icon': {
                boxShadow: 'unset', //`inset 0 0 0 1px ${blackGray.colorDividers}, inset 0 -1px 0 ${blackGray.colorDividers}`,
                background: bookitColors.primary.disabled,
                '&.base-check-danger': {
                  background: bookitColors.danger.disabled,
                },
              },
              '&.indeterminate-icon': {
                boxShadow: 'unset', //`inset 0 0 0 1px ${blackGray.colorDividers}, inset 0 -1px 0 ${blackGray.colorDividers}`,
                background: bookitColors.primary.disabled,
                '&.base-check-danger': {
                  background: bookitColors.danger.disabled,
                },
              },
            },
          },
        },
        // '&[class*="PrivateSwitchBase-root"]': {
        //   padding: 0,
        // },
      },
      colorPrimary: {
        color: bookitColors.primary.borders,
        '&$checked': {
          color: bookitColors.primary.regular,
          '&:hover': {
            backgroundColor: bookitColors.base.white,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        '&$disabled': {
          color: bookitColors.primary.disabled,
        },
      },
      /* Styles applied to the root element if `color="secondary"`. */
      colorSecondary: {
        color: bookitColors.danger.borders,
        '&$checked': {
          color: bookitColors.danger.regular,
          '&:hover': {
            backgroundColor: bookitColors.base.white,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        '&$disabled': {
          color: bookitColors.danger.disabled,
        },
      },
    },
    MuiRadio: {
      /* Styles applied to the root element. */
      root: {
        color: bookitColors.base.white,
      },

      /* Pseudo-class applied to the root element if `checked={true}`. */
      checked: {},

      /* Pseudo-class applied to the root element if `disabled={true}`. */
      disabled: {},

      /* Styles applied to the root element if `color="primary"`. */
      colorPrimary: {
        '&$checked': {
          color: bookitColors.primary.components,
          '&:hover': {
            backgroundColor: bookitColors.primary.regular,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        '&$disabled': {
          color: bookitColors.grayscale.grayBorders,
        },
      },

      /* Styles applied to the root element if `color="secondary"`. */
      colorSecondary: {
        '&$checked': {
          color: bookitColors.danger.components,
          '&:hover': {
            backgroundColor: bookitColors.danger.regular,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        '&$disabled': {
          color: bookitColors.danger.disabled,
        },
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: 0,
      },
      checked: {},
      disabled: {},
      input: {
        cursor: 'inherit',
        position: 'absolute',
        opacity: 0,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        margin: 0,
        padding: 0,
        zIndex: 1,
      },
    },
    MuiBadge: {
      badge: {
        whiteSpace: 'nowrap',
        height: '14px',
        padding: '2px 0px',
        fontSize: '8px',
        minWidth: '14px',
        lineHeight: '168%',
        letterSpacing: '0.8px',
      },
    },
    MUIDataTableBodyCell: {
      root: {
        padding: '0px 8px 0px 8px',
        //textAlign: 'center',
      },
      // stackedCommon: {
      //   display: 'flex',
      //   // alignItems: 'center',
      //   // flexWrap: 'nowrap',
      //   // justifyContent: 'center',
      // },
    },
    MUIDataTableBody: {
      root: {
        border: '1px solid #ccc',
      },
    },
    MUIDataTableHeadCell: {
      root: {
        border: '1px solid #ccc',
      },
      contentWrapper: {
        //justifyContent: 'center',
      },
      data: {
        fontWeight: 'bold',
      },
      fixedHeader: {
        //textAlign: 'center',
        fontWeight: 'bold',
        border: '1px solid #ccc',
      },
      toolButton: {
        whiteSpace: 'nowrap',
      },
    },
    MUIDataTableToolbarSelect: {
      title: {
        paddingRight: '26px',
        paddingLe: '26px',
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: '1.5rem',
      },
    },
    MuiDialog: {
      root: {},
      paper: {
        //width: '100%',
        borderRadius: '12px',
        '.coverRoot &': {
          margin: '0px 4px',
          overflowY: 'hidden',
          borderRadius: '12px 12px 0px 0px',
        },
        '.coverRoot.coverExpandedRoot &': {
          margin: '4px 4px',
          overflowY: 'hidden',
          borderRadius: '12px',
        },
        '.rtl &&': {
          direction: 'rtl',
        },
        '.basic-dialog &&': {
          borderRadius: '12px',
        },
        '.basic-dialog-mobile &&': {
          margin: '0px 4px',
          borderRadius: '12px 12px 0px 0px',
        },
      },
      container: {
        '.basic-dialog-mobile &': {
          alignItems: 'flex-end',
        },
      },
      paperWidthXs: {
        maxWidth: '480px',
      },
      scrollPaper: {
        '.coverRoot &': {
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        },
        '.coverRoot.coverExpandedRoot &': {
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      paperWidthFalse: {
        '.coverRoot &': {
          maxWidth: COVER_WIDTH,
          width: '100%',
        },
        '.coverRoot.coverExpandedRoot &': {
          maxWidth: MAX_COVER_EXPANDED_WIDTH,
          width: '100%',
        },
        '.coverRoot.coverShortRoot &': {
          maxWidth: '100%',
          width: '100%',
        },
      },
      paperScrollPaper: {
        '.coverRoot &': {
          maxHeight: 'calc(100% - 50px)',
          // height: '100vh',
        },
      },
      paperFullWidth: {
        '.coverRoot &': {
          width: '100%',
        },
      },
    },
    MuiDialogContent: {
      root: {
        '&.basic-dialog-content': {
          padding: 32,
          borderTop: 'unset',
          '&:first-child': {
            paddingTop: 32,
          },
          '&.basic-dialog-content-mobile': {
            padding: 16,
            '&:first-child': {
              paddingTop: 32,
            },
          },
          '& .basic-dialog-title-conteiner': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '0px',
            '& .basic-dialog-close-button': {
              position: 'absolute',
              right: 12,
              top: 12,
              color: bookitColors.grayscale.graySubtitles,
            },
            '& .basic-dialog-title': {
              margin: 0,
              display: 'flex',
              alignItems: 'center',
            },
            '& .basic-dialog-processing': {
              width: '100%',
            },
          },
        },
        '&.dialog-content': {
          padding: '16px 24px',
          borderTop: 'unset',
        },
      },
    },
    MuiDialogActions: {
      root: {
        '&.basic-dialog-actions': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          margin: 0,
          padding: '24px 32px',
          boxShadow: 'inset 0px 1px 0px #E0E5EC',
          '& .actions-left': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 0,
            flexWrap: 'wrap',
            gap: 8,
          },
          '& .actions-right': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 16px',
            flexWrap: 'wrap',
            gap: 8,
          },
        },
      },
    },
    MuiDialogTitle: {
      root: {
        '&.basic-dialog-title': {
          margin: 0,
          padding: '24px 24px 8px 32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          background: bookitColors.grayscale.grayBackground,
          '& .title-container': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '0px',
            '& .title-body': {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0px',
              '& .title-body-subtitle': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px',
              },
            },
            '& .close-button': {
              position: 'absolute',
              right: '8px',
              top: '8px',
              color: bookitColors.grayscale.graySubtitles,
            },
          },
        },
      },
    },
    MuiBackdrop: {
      root: {
        '&.sidePanelBackdrop': {
          zIndex: 1201,
        },
      },
    },
    MuiAlert: {
      root: {
        padding: '0px 12px',
        '&.showMore': {
          alignItems: 'center',
        },
      },
      action: {
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    },
    MuiCard: {
      root: {
        '&.file-card': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: bookitColors.grayscale.grayBackground,
          borderRadius: '8px',
          marginTop: '8px',
          border: `2px solid ${bookitColors.grayscale.grayBorders}`,
          '&.with-error': {
            backgroundColor: bookitColors.danger.background,
            border: `2px solid ${bookitColors.danger.borders}`,
          },
        },
      },
    },
    MuiCardContent: {
      root: {
        '&.file-card-content-root': {
          divSizing: 'border-div',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          '& .file-card-content': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            width: '100%',
            '& .file-card-content-body': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '5px',
              width: '100%',
              '& .file-card-text': {
                width: '80%',
              },
            },
          },
        },
      },
    },
    MuiCircularProgress: {
      root: {
        '&.autocomplete': {
          position: 'absolute',
          right: 32,
          top: 'calc(50% - 8px)',
        },
      },
    },
    MuiMenu: {
      paper: {
        '.hoverChildMenu &': {
          boxShadow: box_shadow_m,
          background: bookitColors.base.white,
          borderRadius: '8px',
        },
      },
      list: {
        '.hoverChildMenu &': {
          display: 'flex',
          padding: '8px 0px 12px 0px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          '& .menuItemName': {
            display: 'flex',
            padding: '4px 12px 8px 16px',
            alignItems: 'flex-start',
            gap: '10px',
            alignSelf: 'stretch',
            cursor: 'unset',
            color: bookitColors.grayscale.blackRegular,
            '&:hover': {
              background: 'inherit',
            },
          },
          '& .menuItem': {
            display: 'flex',
            width: '240px',
            height: '32px',
            padding: '0px 8px',
            alignItems: 'center',
            gap: '8px',
            '& .menuItemContent': {
              display: 'flex',
              paddingLeft: '8px',
              alignItems: 'center',
              gap: '8px',
              flex: '1 0 0',
              '& .menuItemIcon': {
                display: 'flex',
                alignItems: 'flex-start',
                gap: '7.5px',
                width: 'auto',
                height: 16,
                color: bookitColors.grayscale.grayComponents,
                '& > svg': {
                  width: '16px',
                },
              },
              '& .menuItemText': {
                flex: '1 0 0',
                color: bookitColors.grayscale.blackRegular,
                ...bookitTypography.bodyS,
                cursor: 'pointer',
                whiteSpace: 'initial',
              },
            },
            '&:hover, &:focus': {
              background: bookitColors.primary.bgHover,
              '& .menuItemContent': {
                '& .menuItemText': {
                  ...bookitTypography.bodyS,
                },
              },
            },
            '&:active': {
              background: bookitColors.primary.bgHover,
              '& .menuItemContent': {
                '& .menuItemText': {
                  ...bookitTypography.bodyS,
                },
              },
            },
            '&.Mui-selected': {
              background: bookitColors.primary.bgHover,
              '& .menuItemContent': {
                '& .menuItemText': {
                  ...bookitTypography.bodyS,
                  fontWeight: 600,
                },
              },
            },
          },
        },
      },
    },
    MuiMenuItem: {
      root: {
        ...bookitTypography.buttonS,
        '&.wrap-text': {
          whiteSpace: 'pre-wrap',
        },
        gap: 12,
        '.hoverChildMenu &': {
          width: '100%',
        },
        // [breakpoints.down('md')]: {
        //   gap: 0,
        // },
      },
      dense: {
        paddingTop: '2px',
        paddingBottom: '2px',
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: '8px',
      },
      toolbarLandscape: {
        padding: 16,
      },
      dateLandscape: {
        marginRight: 16,
      },
    },
    MuiPickerDTToolbar: {
      toolbar: {
        paddingLeft: 16,
        paddingRight: 16,
        justifyContent: 'space-around',
      },
      separator: {
        margin: '0 4px 0 2px',
        cursor: 'default',
        ...buttonM,
      },
    },
    MuiDrawer: {
      root: {
        '&.sideBarDrawer': {
          gap: 8,
          width: DRAWER_WIDTH,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          backgroundColor: bookitColors.base.white,
          zIndex: 1200,
          position: 'fixed',
          height: '100dvh',
          '&.sideBarMobile': {
            width: 'calc(100% - 48px)', //DRAWER_MOBILE_WIDTH,
          },
          '&.drawerOpen': {
            width: DRAWER_WIDTH,
            transition: transitions.create('width', {
              easing: transitions.easing.sharp,
              duration: transitions.duration.enteringScreen,
            }),
            '&.sideBarMobile': {
              width: 'calc(100% - 48px)', //DRAWER_MOBILE_WIDTH,
            },
          },
          '&.drawerClose': {
            transition: transitions.create('width', {
              easing: transitions.easing.sharp,
              duration: transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: 0,
            [breakpoints.up('sm')]: {
              width: '60px',
            },
          },
        },
        '&.table-layout-drawer': {
          width: 300,
          flexShrink: 0,
        },
        '&.asset-drawer': {
          '&.asset-drawer-long': {
            width: '20%',
            borderRadius: 8,
            flexShrink: 0,
          },
          '&.asset-drawer-short': {
            display: 'none',
          },
          '&.asset-drawer-short-open': {
            width: '50%', //props => `${props.screenWidth / 2 - 20}px`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: '1 0 0',
            height: 'inherit',
          },
        },
      },
      paper: {
        '.asset-drawer.asset-drawer-long &': {
          minWidth: '200px',
          width: 'auto', //mainDrawerWidth,
          padding: '24px 16px',
          // backgroundColor: theme.palette.secondary.light,
          position: 'static',
          gap: 16,
          borderRadius: 8,
          backgroundColor: bookitColors.base.white,
          border: `1px solid ${bookitColors.grayscale.graySelection}`,
          overflow: 'hidden',
        },
        '.asset-drawer.asset-drawer-short &': {
          minWidth: '200px',
          width: '100%', //mainDrawerWidth,
          padding: '24px 16px',
          // backgroundColor: theme.palette.secondary.light,
          position: 'static',
          gap: 16,
          backgroundColor: bookitColors.base.white,
          overflow: 'hidden',
        },
        '.asset-drawer.asset-drawer-short-open &': {
          width: '100%',
          padding: '24px 16px',
          // backgroundColor: theme.palette.secondary.light,
          position: 'static',
          gap: 16,
          backgroundColor: bookitColors.base.white,
          overflow: 'hidden',
        },
        '.sideBarDrawer &': {
          background: bookitColors.base.white,
          //position: 'fixed',
          position: 'relative',
          whiteSpace: 'nowrap',
          width: DRAWER_WIDTH,
          transition: transitions.create('width', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen,
          }),
          gap: '16px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingTop: '12px',
          '& .sideBarHeader': {
            // minHeight: '48px',
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'space-between',
            // alignItems: 'center',
            // padding: props => (props.isOpen ? '0px 16px' : '0px 8px'),
            // gap: 16,
            display: 'flex',
            padding: '0px 8px 0px 16px',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
            height: '32px',
            '&.sideBarHeaderMobile': {},
            '& .headerButtons': {
              display: 'flex',
              alignItems: 'center',
            },
            '& .headerButtonsMobile': {
              gap: '4px',
            },
          },
          '& .sideBarQickAction': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            padding: '0px 16px',
            gap: '10px',
            '&.sideBarQickActionMobile': {
              padding: '16px 16px 8px 16px',
              gap: '12px',
            },
          },
        },
        '.sideBarDrawer.sideBarMobile &': {
          width: 'calc(100% - 48px)', // DRAWER_MOBILE_WIDTH,
          gap: 0,
        },
        '.sideBarDrawer.drawerClose &': {
          overflowX: 'hidden',
          transition: transitions.create('width', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.leavingScreen,
          }),
          width: '60px',
          '& .sideBarHeader': {
            padding: '0px 8px',
          },
          '& .sideBarQickAction': {
            justifyContent: 'center',
            alignItems: 'center',
          },
          // [breakpoints.up('sm')]: {
          //   width: 7 * 8 + 1,
          // },
        },
        '.table-layout-drawer &': {
          width: 300,
          padding: 8,
          backgroundColor: bookitColors.base.white,
        },
      },
      paperAnchorDockedLeft: {
        '.sideBarDrawer &': {
          border: `1px solid ${bookitColors.grayscale.grayBorders}`,
        },
      },
    },
    // MuiPickerDTTabs: {
    //   tabs: {
    //     color: theme.palette.getContrastText(tabsBackground),
    //     backgroundColor: tabsBackground,
    // },
    // },
    MuiPickersToolbar: {
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 100,
        backgroundColor: bookitColors.primary.regular,
        // theme.palette.type === 'light'
        //   ? theme.palette.primary.main
        //   : theme.palette.background.default,
      },
      toolbarLandscape: {
        height: 'auto',
        maxWidth: 150,
        padding: 8,
        justifyContent: 'flex-start',
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        padding: 0,
        minWidth: '32px',
        textTransform: 'none',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: fade(bookitColors.base.white, 0.54),
        ...buttonM,
      },
      toolbarBtnSelected: {
        color: bookitColors.base.white,
        // color: theme.palette.type === 'light'
        // ? theme.palette.primary.contrastText
        // : theme.palette.getContrastText(theme.palette.background.default);,
      },
    },
    MuiAutocomplete: {
      root: {
        '&.autocomplete': {
          '&$focused $clearIndicatorDirty': {
            visibility: 'visible',
          },

          /* Avoid double tap issue on iOS */
          '@media (pointer: fine)': {
            '&:hover $clearIndicatorDirty': {
              visibility: 'visible',
            },
            '&:hover [class*="infoWidthPlus"]': {
              right: '48px',
            },
          },
          '&>[class*="MuiFormControl-root"]': {
            backgroundColor: bookitColors.base.white,
            '&>[class*="multiSelect"]': {
              // paddingTop: '6px',
              // paddingBottom: '6px',
              '&>[class*="shortView"]': {
                maxWidth: 360,
              },
              // '&.hasLabel': {
              //   '& > $tag:first-child': {
              //     marginTop: '6px',
              //     //marginBottom: '6px',
              //   },
              // },
              // [breakpoints.up('sm')]: {
              //   maxWidth: '100%',
              //   '&>[class*="shortView"]': {
              //     maxWidth: '100%',
              //   },
              // },
            },
            '&>[class*="MuiInputLabel-filled"]': {
              //transform: 'translate(12px, 14px) scale(1)',
              // long placeholder/label might break in 2+ lines - show only single line here
              // setting up width to minimum assuming there might be clear as well as other adornments just in case
              // 12px is the left translate value, the other sum equals to max paddingRight of filled inputRoot
              width: 'calc(100% - 12px - (52px + 4px + 9px))',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              paddingTop: 0,
              paddingBottom: 0,
              '&.adornedStart-label': {
                transform: 'translate(41px, 14px) scale(0.85)',
              },
              // '&.inputSizeSmall': {
              //   transform: 'translate(12px, 6px) scale(1)',
              // },
              '&[class*="MuiInputLabel-shrink"]': {
                letterSpacing: '0.2px',
                //transform: 'translate(12px, 8px) scale(1)',

                '&.adornedStart-label': {
                  transform: 'translate(41px, 27px) scale(0.85)',
                },
              },
              '&[class*="multiSelect"][class*="MuiInputLabel-shrink"]': {
                letterSpacing: '0.2px',
                //transform: 'translate(12px, 4px) scale(1)',

                '&.adornedStart-label': {
                  transform: 'translate(41px, 27px) scale(0.85)',
                },
              },
            },
          },
          '&>[class*="MuiInputLabel-shrink"]': {
            transform: 'translate(0, -2.5px) scale(1.1)',
          },
          '&.Mui-focused [class*="infoWidthPlus"]': {
            right: '48px',
          },
          '& .info': {
            padding: '4px',
            top: 'calc(50% - 14px)',
            position: 'absolute',
            cursor: 'auto',
            zIndex: 4,
            '& > svg': {
              width: '12px',
              height: '12px',
            },
            '&.infoWidthPlus': {
              right: '48px',
            },
            '&.infoWidthMinus': {
              right: '26px',
            },
          },
        },
      },
      fullWidth: {
        '&.autocomplete': {
          width: '100%',
          '&>[class*="MuiFormControl-root"]': {
            '&>[class*="multiSelect"]': {
              maxWidth: '100%',
            },
            '&>[class*="shortView"]': {
              maxWidth: 360,
            },
            // [breakpoints.up('sm')]: {
            //   maxWidth: '100%',
            //   '&>[class*="shortView"]': {
            //     maxWidth: '100%',
            //   },
            // },
          },
        },
      },
      tag: {
        '.autocomplete &': {
          margin: 0,
          marginTop: 4,
          marginRight: 4,
        },
      },
      tagSizeSmall: {
        '.autocomplete &': {
          margin: 0,
          maxWidth: 'calc(100% - 4px)',
        },
      },
      /* Styles applied when the popup icon is rendered. */
      hasPopupIcon: {},
      /* Styles applied when the clear icon is rendered. */
      hasClearIcon: {
        '&.autocomplete': {
          '& > * > * > [class*="infoWidthPlus"]': {
            right: '48px',
          },
        },
      },
      inputRoot: {
        flexWrap: 'wrap',
        '.atable &': {
          flexWrap: 'nowrap',
        },
        '& $input': {
          // width: 'auto',
          // minWidth: 56,
          minWidth: '80px',
        },
        '.date-dropdown-picker-inputs &': {
          '& $input': {
            width: '100%',
            minWidth: 30,
          },
        },
        '&.autocompleteInputRoot': {
          flexWrap: 'wrap',
          border: `1px solid ${bookitColors.grayscale.grayBorders}`,
          borderRadius: 6,
          cursor: 'auto',
          minHeight: 32,
          gap: 4,
          '$hasPopupIcon &, $hasClearIcon &': {
            paddingRight: 56, //26 + 4,
          },
          '$hasPopupIcon$hasClearIcon &': {
            paddingRight: 56, //24 + 4,
          },
          //gap: 4,
          '& $input': {
            width: 'auto',
            minWidth: 30,
            //padding: '6px 12px',
          },
          '&[class*="MuiInput-root"]': {
            paddingBottom: 2,
            paddingTop: 2,
            paddingLeft: 8,
            minHeight: 40,
            height: 'auto',
            '& $input': {
              padding: 4,
              width: 'auto',
            },
            '&.multiSelect.hasSelected': {
              paddingTop: 20,
              '& .autocompleteInput': {
                //padding: 0,
                width: 'auto',
              },
            },
            '& $input:first-child': {
              padding: '6px 12px',
            },
            '.inputSizeLarge &': {
              paddingBottom: 2,
              paddingTop: 2,
              minHeight: 40,
              height: 'auto',
              '&.multiSelect.hasSelected': {
                paddingTop: 20,
                '& .autocompleteInput': {
                  padding: '2px 0px',
                  width: 'auto',
                },
              },
            },
            '.inputSizeSmall &': {
              paddingTop: 0,
              paddingBottom: 0,
              minHeight: 32,
              '&.multiSelect.hasSelected': {
                paddingTop: 2,
                paddingBottom: 2,
              },
              // '& .autocompleteInput': {
              //   padding: 0,
              // },
            },
            '.inputSizeMedium &': {
              paddingTop: 6,
              paddingBottom: 2,
              minHeight: 40,
              '& .autocompleteInput': {
                padding: '12px 4px 0px',
              },
              '&.multiSelect.hasSelected': {
                '& .autocompleteInput': {
                  padding: '4px 0px',
                },
              },
            },
            '.inputSizeXl &': {
              paddingTop: 16,
              paddingBottom: 8,
              minHeight: 56,
              '& .autocompleteInput': {
                padding: '12px 4px 0px',
              },
              '&.multiSelect.hasSelected': {
                '& .autocompleteInput': {
                  padding: '4px 0px',
                },
              },
            },
            '$hasPopupIcon &, $hasClearIcon &': {
              paddingRight: 26 + 4 + 9,
            },
            '$hasPopupIcon$hasClearIcon &': {
              paddingRight: 52 + 4 + 9,
            },
            '& $endAdornment': {
              right: 4,
            },
          },
          '&[class*="MuiInput-root"][class*="MuiInput-marginDense"]': {
            // '& $input': {
            //   padding: '4px 4px 5px',
            // },
            // '& $input:first-child': {
            //   padding: '4px 4px 5px', //'3px 0 6px',
            // },
            borderRadius: 6,
            '& $input': {
              padding: '4px 4px 5px',
            },
            '& $input:first-child': {
              padding: '1px 0px 1px 7px',
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.10)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'rgba(255, 255, 255, 0.90)',
            },
          },
          '&[class*="Mui-focused"]': {
            backgroundColor: 'rgba(255, 255, 255, 0.10)',
            //border: `1px solid ${bookitColors.primary.regular}`,
          },
          '&[class*="Mui-disabled"]': {
            backgroundColor: bookitColors.grayscale.grayBackground,
          },
          '&[class*="MuiOutlinedInput-root"]': {
            padding: 9,
            '$hasPopupIcon &, $hasClearIcon &': {
              paddingRight: 26 + 4 + 9,
            },
            '$hasPopupIcon$hasClearIcon &': {
              paddingRight: 52 + 4 + 9,
            },
            '& $input': {
              padding: '9.5px 4px',
            },
            '& $input:first-child': {
              paddingLeft: 6,
            },
            '& $endAdornment': {
              right: 9,
            },
          },
          '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]':
            {
              padding: 6,
              '& $input': {
                padding: '4.5px 4px',
              },
            },
          '&[class*="MuiFilledInput-root"]': {
            paddingTop: 12,
            paddingLeft: 8,
            paddingBottom: 4,
            minHeight: 40,
            height: 'auto',
            '$hasPopupIcon &, $hasClearIcon &': {
              paddingRight: 26 + 4 + 9,
            },
            '$hasPopupIcon$hasClearIcon &': {
              paddingRight: 52 + 4 + 9,
            },
            '& .autocompleteInput': {
              padding: '10px 4px 0px',
            },
            '&.multiSelect.hasSelected': {
              paddingTop: 20,
              '& .autocompleteInput': {
                padding: 0,
                width: 'auto',
              },
            },
            // '& $input': {
            //   padding: '6px 4px 4px',
            // },
            '& $endAdornment': {
              right: 4,
            },
            '.inputSizeMedium &': {
              paddingTop: 6,
              paddingBottom: 2,
              minHeight: 40,
              '& .autocompleteInput': {
                padding: '12px 4px 0px',
              },
            },
            '.inputSizeSmall &': {
              paddingTop: 0,
              paddingBottom: 0,
              minHeight: 32,
              '&.multiSelect.hasSelected': {
                paddingTop: 2,
                paddingBottom: 2,
              },
              '& .autocompleteInput': {
                padding: 0,
              },
            },
            '.inputSizeXl &': {
              paddingTop: 16,
              paddingBottom: 8,
              minHeight: 56,
            },
            '&[class*="MuiFilledInput-adornedStart"]': {
              //paddingLeft: '36px',
              '&>.adornedStart': {
                cursor: 'auto',
                position: 'absolute',
                left: 12,
                top: 'calc(50% - 3px)',
              },
            },
          },
          '&[class*="MuiFilledInput-root"][class*="MuiFilledInput-marginDense"]':
            {
              paddingTop: 14,
              paddingBottom: 0,
              '& $input': {
                padding: '4.5px 4px',
              },
            },
        },
      },
      /* Styles applied to the input element. */
      input: {
        '&.autocompleteInput': {
          flexGrow: 1,
          textOverflow: 'ellipsis',
          opacity: 0,
          padding: '6px 12px',
        },
      },
      /* Styles applied to the input element if tag focused. */
      inputFocused: {
        '&.autocompleteInput': {
          opacity: 1,
        },
      },
      /* Styles applied to the endAdornment element. */
      endAdornment: {
        '.autocompleteInputRoot &': {
          // We use a position absolute to support wrapping tags.
          position: 'absolute',
          right: 8,
          top: 'calc(50% - 11px)', // Center vertically
        },
      },
      /* Styles applied to the clear indicator. */
      clearIndicator: {
        '.autocompleteInputRoot &': {
          marginRight: 2,
          padding: 4,
          '& > * > svg': {
            width: 12,
            height: 12,
            cursor: 'pointer',
          },
          visibility: 'hidden',
          //display: 'none',
        },
      },
      clearIndicatorDirty: {
        '.autocompleteInputRoot &': {
          visibility: 'visible',
          display: 'inline-flex',
        },
      },
      /* Styles applied to the popup indicator. */
      popupIndicator: {
        '.autocompleteInputRoot &': {
          padding: 4,
          marginRight: 0,
          '& > * > svg': {
            width: 12,
            height: 12,
          },
        },
      },

      /* Styles applied to the popup indicator if the popup is open. */
      popupIndicatorOpen: {
        '.autocompleteInputRoot &': {
          transform: 'rotate(180deg)',
        },
      },
      paper: {
        ...bookitTypography.buttonS,
        overflow: 'hidden',
        margin: '0px',
        boxShadow: box_shadow_l,
        padding: '4px 0px',
        '& .autocompleteList': {
          listStyle: 'none',
          margin: 0,
          padding: '4px 0',
          maxHeight: '40vh',
          overflow: 'auto',
          ...verticalScrolling,
        },
      },
      listbox: {
        listStyle: 'none',
        margin: 0,
        padding: '8px 0',
        maxHeight: '40vh',
        overflow: 'auto',
      },
      loading: {
        color: bookitColors.grayscale.grayComponents,
        padding: '14px 16px',
      },
      noOptions: {
        color: bookitColors.grayscale.grayComponents,
        padding: '14px 16px',
      },
      /* Styles applied to the option elements. */
      option: {
        minHeight: 40,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
        paddingTop: 6,
        boxSizing: 'border-box',
        outline: '0',
        WebkitTapHighlightColor: 'transparent',
        paddingBottom: 6,
        paddingLeft: 16,
        paddingRight: 16,
        height: 'auto',
        backgroundColor: bookitColors.base.white,
        [breakpoints.up('sm')]: {
          minHeight: 40,
          '.listSizeMedium &': {
            minHeight: 44,
          },
          '.listSizeLarge &': {
            minHeight: 64,
          },
        },
        '&[aria-selected="true"]': {
          backgroundColor: bookitColors.grayscale.graybgComponents,
        },
        '&[data-focus="true"]': {
          backgroundColor: bookitColors.grayscale.grayBackground,
        },
        '&:active': {
          backgroundColor: bookitColors.grayscale.grayBackground,
        },
        '&[aria-disabled="true"]': {
          opacity: 0.7,
          pointerEvents: 'none',
        },
      },
      /* Styles applied to the group's label elements. */
      groupLabel: {
        backgroundColor: bookitColors.base.white,
        top: -8,
      },
      /* Styles applied to the group's ul elements. */
      groupUl: {
        padding: 0,
        '& $option': {
          paddingLeft: 24,
        },
      },
    },
    MuiTabs: {
      root: {
        minHeight: '40px',
        '&.sizeSmall': {
          minHeight: '32px',
        },
      },
      flexContainer: {
        // '.tabsHorizontal &': {
        //   gap: 24,
        // },
        // '.sizeSmall &': {
        //   '.tabsHorizontal &': {
        //     gap: 20,
        //   },
        // },
      },
      scrollable: {},
      indicator: {
        display: 'flex',
        justifyContent: 'flex-start',
        backgroundColor: bookitColors.primary.regular,
        // '& > span': {
        //   maxWidth: 40,
        //   width: '100%',
        //   backgroundColor: '#635ee7',
        // },
      },
    },
    MuiLinearProgress: {
      root: {
        '&.import-progress': {
          height: 8,
          borderRadius: 6,
        },
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        ...bookitTypography.boldM,
        '.tabsHorizontal &': {
          padding: '8px 12px 0px',
          minHeight: '40px',
          alignItems: 'flex-start',
          gap: '4px',
          '&.tabSizeSmall': {
            minHeight: '32px',
            padding: '4px 10px 0px',
            ...bookitTypography.boldS,
          },
          [breakpoints.up('sm')]: {
            minWidth: 'auto',
          },
        },
      },
      wrapper: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        '.startIcon &': {
          flexDirection: 'row',
          gap: '8px',
        },
        '.endIcon &': {
          flexDirection: 'row',
          gap: '8px',
        },
      },
      textColorInherit: {
        color: bookitColors.grayscale.graySubtitles,
        opacity: 1,
        '&$selected': {
          color: bookitColors.grayscale.blackRegular,
        },
        '&$disabled': {
          color: bookitColors.grayscale.grayComponentsLight,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        display: 'flex',
        padding: 12,
        flexDirection: 'column',
        alignItems: 'flex-start',
        color: bookitColors.base.white,
        backgroundColor: bookitColors.overlays.overlayBlack,
        flex: '1 0 0',
        ...bookitTypography.bodyS,
        '.colorDark &': {
          color: bookitColors.base.white,
          backgroundColor: bookitColors.overlays.overlayBlack,
        },
        '.colorLight &': {
          color: bookitColors.base.black,
          backgroundColor: bookitColors.base.white,
          boxShadow: box_shadow_s,
        },
        '& .content': {
          maxHeight: 360,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            boxShadow: `inset 0 0 2px ${bookitColors.grayscale.grayBorders}`,
            borderRadius: '6px',
            backgroundColor: bookitColors.grayscale.grayBorders,
            '.colorDark &': {
              backgroundColor: bookitColors.overlays.overlayBlack,
            },
          },
        },
      },
      arrow: {
        color: bookitColors.overlays.overlayBlack,
        '.colorDark &': {
          color: bookitColors.overlays.overlayBlack,
        },
        '.colorLight &': {
          color: bookitColors.base.white,
          '&::before': {
            border: `1px solid ${bookitColors.grayscale.graySelection}`,
          },
        },
      },
    },
    MuiCollapse: {
      container: {
        '& .collapse-container': {
          width: '100%',
          padding: '0px 32px',
        },
        '&. units-collapse-container': {
          marginLeft: 8,
        },
      },
    },
    MuiGrid: {
      root: {
        '&.grid-item-draggable': {
          position: 'relative',
        },
      },
    },
    MuiTreeView: {
      root: {
        backgroundColor: bookitColors.base.white,
        padding: '0px 24px 0px 24px',
        '&.short-view': {
          padding: 0,
        },
      },
    },
    MuiTreeItem: {
      root: {
        '&.compact': {
          '&>[class*="MuiCollapse-container"]': {
            '&>[class*="MuiCollapse-wrapper"]': {
              '&>[class*="MuiCollapse-wrapperInner"]': {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                columnGap: '12px',
                '&>li': {
                  gridColumn: 'span 1',
                },
              },
            },
          },
        },
      },
      content: {
        gap: '8px',
        marginBottom: '12px',
      },
      group: {
        marginLeft: '27px',
        '.dash-content.short-view &': {
          marginLeft: '8px',
        },
      },
      label: {
        border: `1px solid ${bookitColors.grayscale.grayBorders}`,
        backgroundColor: bookitColors.grayscale.grayBackground,
        borderRadius: '4px',
        height: '56px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        paddingLeft: 0,
        '&:hover': {
          backgroundColor: bookitColors.grayscale.graybgComponents,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
          '& .tree-label-content': {
            '& .hover-over': {
              display: 'flex',
            },
          },
        },
        '& .tree-label-img': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: bookitColors.grayscale.graybgComponents,
          width: '54px',
          //height: '54px',
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
          '& img': {
            maxHeight: '54px',
            maxWidth: '54px',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
          },
        },
        '& .tree-label-content': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          paddingLeft: '12px',
          paddingRight: '12px',
          '&.has-img': {
            paddingLeft: '0px',
          },
          '& .tree-label-text': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '4px',
          },
          '& .tree-label-actions': {
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            '&.hover-over': {
              display: 'none',
            },
          },
        },
      },
    },
  },
};
export const darkTheme: ThemeOptions = {
  palette: {
    background: { default: grey[400], paper: grey[100] },
    primary: { main: green[100] },
    secondary: {
      main: '#4a4a4a',
    },
    text: {
      primary: '#e1dada',
      secondary: 'rgba(255, 255, 255, 0.54)',
      disabled: '#757575',
    },
  },
};
export const animalsThemeSettings: ThemeOptions = {
  palette: {
    primary: {
      main: '#ffdc64',
      dark: '#c9ab33',
      light: '#ffff95',
      contrastText: '#000000',
    },
    secondary: {
      main: '#3247e1',
      dark: '#001fae',
      light: '#7673ff',
      contrastText: '#ffffff',
    },
    error: {
      main: '#E53F58',
      dark: '#AC2F42',
      light: '#F195A3',
      contrastText: '#ffffff',
    },
    background: {
      default: '#F3F3F3',
      paper: '#FFF',
    },
    default: {
      main: '#ffdc64',
      dark: '#c9ab33',
      light: '#ffff95',
      contrastText: '#000000',
    },
  },
  shape: {
    borderRadius: 12,
  },

  typography: {
    fontFamily: ["'Open Sans Hebrew'", 'system-ui'].join(','),
    h1: {
      //styleName: Bkt/Header 1;
      fontSize: '2.5rem',
      fontWeight: 700,
      lineHeight: '3rem',
    },
    h2: {
      //styleName: Bkt/Header 2;
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '2.875rem', //46/16
    },
    h3: {
      //styleName: Bkt/Header 3;
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '2.375rem', //46/16
    },
    h4: {
      //styleName: Bkt/Header 4:
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '2rem',
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1.375rem',
    },
    subtitle1: {
      fontSize: '1.25rem',
      lineHeight: '2rem',
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: '1.25rem',
      lineHeight: '2rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
      fontWeight: 400,
      //color: grey[600],
    },
  },
  overrides: {
    MuiTextField: {},
    MuiAppBar: {},
    MuiDrawer: {
      root: {
        '&.table-layout-drawer': {
          width: 300,
          flexShrink: 0,
        },
        '&.table-layout-secondary-drawer': {
          width: 300,
          flexShrink: 0,
          '&.secondary-drawer-width-zero': {
            width: 0,
          },
        },
      },
      paper: {
        '.table-layout-drawer &': {
          width: 300,
          padding: 8,
          backgroundColor: bookitColors.base.white,
        },
        '.table-layout-secondary-drawer &': {
          width: 300,
          padding: 8,
        },
      },
    },
  },
};

export const CreateTheme = (direction, baseTheme: BaseTheme) =>
  createMuiTheme(getBaseTheme(baseTheme), { direction: direction }); //DefaultThemeSettings

const getBaseTheme = (type: BaseTheme) => {
  switch (type) {
    case 'bookit':
      return BookitThemeSettings;
    case 'animals':
      return animalsThemeSettings;
    case 'dark':
      return darkTheme;
    case 'default':
    default:
      return DefaultThemeSettings;
  }
};
