/**
 *
 * LocationPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { ILocationListDto } from 'api/odata/generated/entities/ILocationListDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Entity } from 'types/common';

export interface LocationPickerProps
  extends Omit<AutocompletePickerProps<ILocationListDto>, 'loadData'> {
  withoutServiceGroups?: boolean;
}

const url = '/api/odata/v4/LocationList';
export const initLocationData = (
  initval: string | undefined,
): Promise<ILocationListDto[]> => {
  if (initval === undefined) {
    return new Promise<ILocationListDto[]>((resolve, reject) => {
      resolve([] as ILocationListDto[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select:
        'Id,Name,LocationTypeId,LocationType,RoomId,RoomName,SubLocationListCount,ServiceGroups',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as ILocationListDto[]);
  }
};

const loadData = (
  predicates: (string | Condition<ILocationListDto>)[] | undefined,
  globalServiceGroupFilter?: Entity<number>[],
) =>
  getAutoCompleteLoadDataFn<ILocationListDto>({
    url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: [
      'Id',
      'Name',
      'LocationType',
      'LocationTypeId',
      'RoomId',
      'RoomName',
      'SubLocationListCount',
      'ServiceGroups',
    ],
    globalServiceGroupFilter:
      globalServiceGroupFilter?.map(
        serviceGroup =>
          new Condition<ILocationListDto>(
            'ServiceGroups',
            ODataOperators.AnyIn,
            serviceGroup.Id,
            'Id',
          ),
      ) ?? [],
    isOptionalServiceGroup: true,
  });
export function LocationPicker(props: LocationPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const innerLoadData = loadData(
    props.predicates,
    !props.withoutServiceGroups
      ? globalServiceGroupFilter || undefined
      : undefined,
  );
  return (
    <AutocompletePicker
      loadData={innerLoadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'locationId'}
      {...props}
    />
  );
}
