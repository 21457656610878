import { FormControl, FormLabel, styled } from '@material-ui/core';
import { FieldHookConfig } from 'formik';
import * as React from 'react';
import { Body } from '../Typography';
import { InfoIcon } from '../BasicIcons/InfoIcon';
import { IFieldProps } from './FormRender/IFormFieldSetting';

export interface FormLabelTextProps {
  text?: React.ReactNode;
  id?: string;
}
export function FormLabelText({
  label,
  text,
  html,
  info,
  fullWidth = true,
  ...props
}: FieldHookConfig<string> & IFieldProps & FormLabelTextProps) {
  const StyledInfo = styled(InfoIcon)(({ theme }) => ({
    marginLeft: theme.spacing(1),
  }));
  return (
    <FormControl className={'formLabelText'} fullWidth={fullWidth}>
      {label && (
        <FormLabel>
          <>
            {label} {info && <StyledInfo title={info} />}
          </>
        </FormLabel>
      )}
      {text && (
        <Body bold={true} size="small">
          {text}
        </Body>
      )}
      {html}
    </FormControl>
  );
}
