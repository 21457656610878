/**
 *
 * EveryDaysPicker
 *
 */
import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';

import { Entity } from 'types/common';

export interface EveryDaysPickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {}

export const initEveryDaysData = (
  initval: number | string | undefined,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve(
        Array.from(Array(100))
          .map((e, i) => {
            return { Id: i, Name: i.toString() };
          })
          .filter(item => {
            return item.Id.toString() === initval;
          }),
      );
    });
  }
};
const getOptions = (searchTerm: string | null) => {
  let items = Array.from(Array(100))
    .map((e, i) => {
      return { Id: i, Name: i.toString() };
    })
    .filter(d => d.Id !== 0) as Entity<number>[];

  if (searchTerm !== null && searchTerm !== '') {
    return items.filter(
      f => f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
    );
  }
  return items;
};
export function EveryDaysPicker(props: EveryDaysPickerProps) {
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      id={props.id || 'everyDaysId'}
      value={props.value}
      {...props}
    />
  );
}
