import { BasicTable, BasicTableProps } from 'app/components/BasicTable';
import { ScreensId } from 'enums/ConfigurableTypes';
import { IDocumentsDirectoryRow } from './IDocumentsDirectoryRow';
import React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';

export function DocumentsTable<
  TProps extends Omit<
    BasicTableProps<IDocumentsDirectoryRow>,
    'api' | 'additionalColumns' | 'searchColumns' | 'screenName'
  >,
>({ initialState, rowActions, screenId, serviceGroups, ...props }: TProps) {
  const { t } = useTranslation();
  const newProps = React.useMemo(() => {
    const p = { ...props };
    Reflect.deleteProperty(p, 'api');
    return p;
  }, [props]);
  const url = '/api/odata/v4/Documents';
  return (
    <>
      <BasicTable
        api={url}
        screenId={ScreensId.Documents}
        initialState={initialState}
        rowActions={rowActions}
        additionalColumns={[
          'Id',
          'UniqueId',
          'ServiceId',
          'ServiceGroupId',
          'ServiceTypeId',
          'AssetId',
          'DocumentTypeId',
          'FileUpdateTime',
          'LinkedAssetId',
          'IsAdmin',
        ]}
        searchColumns={[
          'FileName',
          'Title',
          'Description',
          'DocumentType',
          'FileType',
          'AssetName',
          'ServiceGroup',
        ]}
        screenName={t(translations.menu_Documents)}
        serviceGroups={serviceGroups}
        {...(newProps as TProps)}
      />
    </>
  );
}
