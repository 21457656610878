import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { Body } from 'app/components/Typography';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { translations } from 'locales/translations';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { AssetsFilterForCalibrationWorkOrder } from 'app/components/pickers/MultiSelectPickers/AssetsPicker';
import { AssetPicker } from 'app/components/pickers/AutocompletePickers/AssetPicker';
import { Divider } from '@material-ui/core';
import {
  filteredWorkOrderTypes,
  initWOTypeData,
  WorkOrderTypePicker,
} from 'app/components/pickers/AutocompletePickers/WorkOrderTypePicker';
import {
  WorkOrderPicker,
  WorkOrdersCalibrationFilter,
} from 'app/components/pickers/AutocompletePickers/WorkOrdersPicker';
import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import { Button } from 'app/components/BasicButtons/Button';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';

export interface AddCalibrationWorkOrderProps {
  saveLinkedOrders: (order: IWorkOrderDetailsDto) => void;
  workOrederTypeId?: number;
  workOrederIds?: number[];
  showProcessing?: boolean;
}

export function AddCalibrationWorkOrder(props: AddCalibrationWorkOrderProps) {
  const { t } = useTranslation();

  const { workOrederIds, workOrederTypeId, saveLinkedOrders, showProcessing } =
    props;
  const [asset, setAsset] = useAsyncExtendedState<IAssetDto | null>(null);

  const [workOrder, setWorkOrder] = React.useState<IWorkOrderDetailsDto | null>(
    null,
  );
  const [workOrderType, setWorkOrderType] =
    useAsyncExtendedState<IWorkOrderTypeDto | null>(null);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'workOrderTypeSelection',
  });
  const fetchWorkOrderType = async (woId?: number) =>
    initWOTypeData(woId === undefined ? undefined : '' + woId).then(
      data => (data[0] as IWorkOrderTypeDto) ?? null,
    );
  useEffectOnMount(() => {
    if (workOrederTypeId) {
      setWorkOrderType(fetchWorkOrderType(workOrederTypeId));
    }
    return () => {};
  });
  const handleAssetChange = React.useCallback(
    (value: IAssetDto | null) => {
      setAsset(value);
    },
    [setAsset],
  );
  const handleWorkTypeChange = React.useCallback(
    (value: IWorkOrderTypeDto | null) => {
      setWorkOrderType(value);
    },
    [setWorkOrderType],
  );
  const handleWorkOrderChange = React.useCallback(
    (value: IWorkOrderDetailsDto | null) => {
      setWorkOrder(value);
    },
    [],
  );
  const handleSaveLinked = (value: IWorkOrderDetailsDto | null) => {
    if (value !== null) {
      saveLinkedOrders(value);
      popupState.close();
    }
  };
  return (
    <div>
      <TopActionButton
        text={t(translations.LinkWorkOrder) as string}
        title={'Add link to another work order'}
        icon="link"
        startIcon="link"
        size="small"
        variant="white"
        endIcon="chevron-down"
        css={{ marginLeft: '8px' }}
        disabled={showProcessing === true}
        noChangeOnMobile={true}
        {...bindTrigger(popupState)}
      />
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableAutoFocus
        PaperProps={{ style: { width: '360px' } }}
      >
        <div style={{ padding: 16 }}>
          {/* <StyledFormFieldsContainer>
              <FormLeftSection>
                <FormFieldsSection>
                    
                </FormFieldsSection>
                </FormLeftSection>
                </StyledFormFieldsContainer> */}
          <Grid container spacing={2} wrap="nowrap" direction="column">
            <Grid item key="title-content">
              <Box display="row" justifyContent="space-between">
                <Body size="small" bold={true}>
                  {t(translations.AddCalibrationReference)}
                </Body>
                <IconButton
                  variant="ghost"
                  aria-label="close"
                  size="xs"
                  style={{ position: 'absolute', right: 8, top: 8 }}
                  onClick={() => {
                    popupState.close();
                    setAsset(null);
                    setWorkOrderType(null);
                  }}
                >
                  <Icon icon="times" />
                </IconButton>
              </Box>
            </Grid>
            <Divider variant="fullWidth" />
            <Grid item key="workordertype-picker">
              <WorkOrderTypePicker
                predicates={filteredWorkOrderTypes(
                  asset !== null ? [asset] : [],
                  undefined,
                  undefined,
                )}
                name={'workOrderTypeFilter'}
                variant="filled"
                placeholder={t(translations.PleaseSelectAnAlertType)}
                label={t(translations.AlertType)}
                onChange={value => {
                  handleWorkTypeChange(value as IWorkOrderTypeDto);
                }}
                fullWidth={true}
                id="wotype-id"
                value={workOrderType}
              />
            </Grid>
            <Grid item key="asset-picker">
              <AssetPicker
                predicates={AssetsFilterForCalibrationWorkOrder(
                  workOrderType ?? undefined,
                  [],
                )}
                key="assetKey"
                restrictByAvType="serviceRelated"
                onChange={value => {
                  handleAssetChange(value);
                }}
                placeholder={t(translations.QuickWorkOrderPleaseSelectAssets)}
                fullWidth={true}
                name="assets"
                variant="filled"
                id="asset-id"
                showOnlyActiveAssets={true}
                label={t(translations.Asset)}
                value={asset || undefined}
              />
            </Grid>

            <Grid item key="workorder-picker">
              <WorkOrderPicker
                name={'workOrderName'}
                variant="filled"
                placeholder={t(translations.PleaseSelectACalibration)}
                label={t(translations.Alerts)}
                onChange={value => {
                  handleWorkOrderChange(value);
                }}
                predicates={WorkOrdersCalibrationFilter(
                  asset?.Id,
                  workOrderType?.Id,
                  workOrederIds,
                )}
                fullWidth={true}
                id="wo-id"
              />
            </Grid>
            <Divider variant="fullWidth" />
            <Grid item key="save-button">
              <Button
                variant="main"
                size="small"
                disabled={workOrder === null}
                onClick={() => handleSaveLinked(workOrder)}
              >
                {t(translations.LinkWorkOrder)}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </div>
  );
}
