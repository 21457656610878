import styled from 'styled-components';
import { Theme, Toolbar, ToolbarProps } from '@material-ui/core';
import { bookitColors } from 'styles/colors/bookitColors';
// margin: ${props =>
//   props.variant === 'dense' ? props.theme.spacing(1, 1, 0) : '0px'};
export const StyledToolbar = styled(
  ({
    theme,
    children,
    ...other
  }: {
    theme: Theme;
    children: React.ReactNode;
  } & ToolbarProps) => <Toolbar {...other}>{children}</Toolbar>,
)`
  box-shadow: unset;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
  padding: ${props =>
    props.variant === 'dense' ? '0px' : props.theme.spacing(3, 4, 2)};
  margin: 0;

  &.shortview {
    box-shadow: inset 0px -1px 0px #e0e5ec;
    gap: ${props => props.theme.spacing(1)}px;
    padding: ${props =>
      props.variant === 'dense' ? '0px' : props.theme.spacing(0, 2)};
    & .actionsContainer {
      gap: ${props => props.theme.spacing(1)}px;
    }
    & .titleSection {
      & .title {
        & .titleText {
          max-width: 26ch;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline;
        }
      }
    }
  }
  &.toptoolbar {
    background: ${bookitColors.base.white};
    margin: 0px;
    padding: 12px 32px;
    &.isExpanded {
      padding: 12px 32px 12px 24px;
    }
  }
  & .titleSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: ${props => props.theme.spacing(1)}px;
    width: 100%;
    &.titleAndIconMobile {
      flex-grow: 1;
    }
    &.titleAsColumn {
      flex-direction: column;
      align-items: flex-start;
    }
    & .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      width: 100%;
      gap: ${props => props.theme.spacing(1)}px;
      &.title-with-link {
        align-items: flex-start;
      }
      & .titleText {
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 30ch;
        display: inherit;
      }
      & .longTitleText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 45ch;
        display: initial;
      }
    }
  }
  & .messageContainer {
    margin-inline: ${props => props.theme.spacing(6)}px;
    flex-grow: 1;
  }
  & .actionsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: ${props => props.theme.spacing(2)}px;
    padding: 0px;
    & span > a {
      & > svg {
        font-size: 20px;
      }
    }
  }
`;
export const DashboardToolbar = styled(
  ({
    theme,
    children,
    ...other
  }: {
    theme: Theme;
    children: React.ReactNode;
  } & ToolbarProps) => <Toolbar {...other}>{children}</Toolbar>,
)`
  &.dash-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;
    padding: 0px 16px 0px 32px;
    &.short-view {
      padding: 0px 8px 0px 16px;
    }
    & .dash-left-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
    & .dash-right-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }
`;
export const DashboardContent = styled.div`
  &.dash-content {
    padding: 0px 32px;
    &.short-view {
      padding: 0px 16px;
    }
  }
`;
