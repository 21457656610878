import {
  MenuItemEntryL2,
  MenuItems,
} from 'app/Layout/FrontendLayout/components/SideBar/slice/types';
import { useAppSettingsSlice } from 'app/slice';
import {
  selectGlobalSettingBool,
  selectSpecificServiceGroupFilter,
} from 'app/slice/selectors';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Entity } from 'types/common';
import { AllowedSettings } from 'utils/globalSettings';

interface SpecificCoreChange {
  doChange?: boolean;
  menuItem?: MenuItemEntryL2;
  name?: string;
  groupsCount?: number;
  selected?: boolean;
  onAllCoresClick?: (menuItem: MenuItemEntryL2) => void;
  onSpecificCoresClick?: (menuItem: MenuItemEntryL2) => void;
}
export const useSpecificCoreChanged = (
  menuItems: MenuItems,
): SpecificCoreChange => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const topServiceGroupsIsHidden = useSelector(state =>
    selectGlobalSettingBool(state, AllowedSettings.HideTopServiceGroupFilter),
  );
  const hideDashboardSubMenu = useSelector(state =>
    selectGlobalSettingBool(state, AllowedSettings.HideDashboardSubMenu),
  );
  const specificServiceGroups: Array<Entity<number>> | undefined = useSelector(
    selectSpecificServiceGroupFilter,
  );
  // const allCoresSelected = useSelector(selectIsAllServiceGroupsSelected);
  const specMenuItem = React.useMemo(() => {
    return menuItems.length > 0 &&
      menuItems[0][0] !== undefined &&
      menuItems[0][0].items !== undefined &&
      menuItems[0][0].items.some(f => f.id === '/speccoresdash')
      ? menuItems[0][0].items.find(f => f.id === '/speccoresdash')
      : undefined;
  }, [menuItems]);
  const newMenuName = React.useMemo(() => {
    if (!topServiceGroupsIsHidden && !hideDashboardSubMenu && !!specMenuItem) {
      if (!!specificServiceGroups && specificServiceGroups.length > 0) {
        return specificServiceGroups[0].Name;
      } else {
        return t(translations.menu_SpecificCore);
      }
    }
    return undefined;
  }, [
    hideDashboardSubMenu,
    specMenuItem,
    specificServiceGroups,
    t,
    topServiceGroupsIsHidden,
  ]);
  const newGroupsCount = React.useMemo(() => {
    return !!specificServiceGroups && specificServiceGroups.length > 0
      ? specificServiceGroups.length
      : undefined;
  }, [specificServiceGroups]);
  const doChangeName = React.useMemo(() => {
    return !hideDashboardSubMenu && !!specMenuItem && !!newMenuName
      ? specMenuItem.name !== newMenuName
      : undefined;
  }, [hideDashboardSubMenu, newMenuName, specMenuItem]);
  const doAllCoresClick = React.useCallback(
    (item: MenuItemEntryL2) => {
      if (item.id === '/allcoresdash') {
        dispatch(actions.saveAllGlobalServiceGroupFilter());
      }
    },
    [actions, dispatch],
  );
  const doSpecificCoresClick = React.useCallback(
    (item: MenuItemEntryL2) => {
      if (item.id === '/speccoresdash') {
        if (!!specificServiceGroups && specificServiceGroups.length > 0) {
          dispatch(actions.saveGlobalServiceGroupFilter(specificServiceGroups));
        }
      }
    },
    [actions, dispatch, specificServiceGroups],
  );
  return {
    doChange: doChangeName,
    menuItem: specMenuItem,
    name: newMenuName,
    groupsCount: newGroupsCount,
    selected: specMenuItem?.selected,
    onAllCoresClick: doAllCoresClick,
    onSpecificCoresClick: doSpecificCoresClick,
  };
};
