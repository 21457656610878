import { SidePanelRenderer } from 'app/Layout/FrontendLayout/components/SidePanel/SidePanelRenderer';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import {
  selectConfirmApproved,
  selectConfirmRejected,
  selectContinueState,
  selectContinueToLink,
} from 'app/Layout/FrontendLayout/slice/selectors';
import {
  PageTypeProps,
  RenderPageType,
} from 'app/Layout/FrontendLayout/slice/type';
import { useAppSettingsSlice } from 'app/slice';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export interface SidePanelOpenState {
  renderPageType?: RenderPageType;
  renderPageProps?: PageTypeProps;
  useSidePanel?: boolean;
  isCover?: boolean;
  expanded?: boolean;
}
export interface SidePanelCloseState {
  useSidePanel?: boolean;
  isCover?: boolean;
  onClose?: () => void;
  showConfirm?: boolean;
}

function useSidePanelState(
  onConfirm?: () => boolean,
  onReject?: () => void,
  isCover?: boolean,
  useSidePanel?: boolean,
  onClose?: () => void,
) {
  const dispatch = useDispatch();
  const { actions: layoutActions } = useLayoutSlice();
  const { actions: appSettingsActions } = useAppSettingsSlice();
  const confirmRejected = useSelector(selectConfirmRejected);
  const confirmApproved = useSelector(selectConfirmApproved);
  const continueToOther = useSelector(selectContinueState);
  const continueLink = useSelector(selectContinueToLink);
  const [cover, setCover] = React.useState<React.ReactNode | undefined>(
    undefined,
  );
  const [coverClosed, setCoverClosed] = React.useState<boolean | undefined>(
    undefined,
  );
  const closeCover = () => {
    setCover(undefined);
  };
  const onCloseCover = React.useCallback(() => {
    return closeCover();
  }, []);
  const closePanel = React.useCallback(
    (state: SidePanelCloseState) => {
      if (state.showConfirm) {
        dispatch(layoutActions.setConfirmOpen(true));
      } else {
        if (state.isCover) {
          setCover(undefined);
          setCoverClosed(true);
        } else {
          dispatch(layoutActions.resetSidePanel());
        }
        if (!!state.onClose) {
          state.onClose();
        }
      }
    },
    [dispatch, layoutActions],
  );
  const openPanel = React.useCallback(
    (state: SidePanelOpenState) => {
      state.useSidePanel || state.isCover
        ? setCover(
            <SidePanelRenderer
              renderPageType={state.renderPageType}
              renderPageProps={Object.assign({}, state.renderPageProps, {
                closeCover: closeCover,
                onClose: closeCover,
                isCover: true,
                useSidePanel: true,
              })}
            />,
          )
        : dispatch(
            layoutActions.openSidePanel({
              type: state.renderPageType,
              props: Object.assign({}, state.renderPageProps, {
                useSidePanel: true,
              }),
              expanded: state.expanded,
            }),
          );
    },
    [dispatch, layoutActions],
  );
  React.useEffect(() => {
    if (confirmApproved) {
      if (!!onConfirm) {
        let confirmed = onConfirm();
        if (confirmed) {
          if (continueToOther.continueOnApprove) {
            if (isCover) {
              setCover(undefined);
            } else {
              dispatch(layoutActions.resetSidePanel());
            }
            if (!!onClose) {
              onClose();
            }
            setTimeout(() => {
              useSidePanel || isCover
                ? setCover(
                    <SidePanelRenderer
                      renderPageType={continueToOther.pageType}
                      renderPageProps={Object.assign(
                        {},
                        continueToOther.pageProps,
                        {
                          closeCover: onClose,
                          onClose: onClose,
                          isCover: true,
                        },
                      )}
                    />,
                  )
                : dispatch(
                    layoutActions.openSidePanel({
                      type: continueToOther.pageType,
                      props: continueToOther.pageProps,
                      expanded: continueToOther.expanded,
                    }),
                  );
              dispatch(layoutActions.resetContinueState());
            }, 100);
          } else if (continueLink !== undefined) {
            dispatch(appSettingsActions.navigate(continueLink));
            if (isCover) {
              setCover(undefined);
            } else {
              dispatch(layoutActions.resetSidePanel());
            }
            if (!!onClose) {
              onClose();
            }
          } else {
            if (isCover) {
              setCover(undefined);
            } else {
              dispatch(layoutActions.resetSidePanel());
            }
            if (!!onClose) {
              onClose();
            }
          }
        } else {
          dispatch(layoutActions.resetConfirmState());
        }
      } else {
        if (continueToOther.continueOnApprove) {
          if (isCover) {
            setCover(undefined);
          } else {
            dispatch(layoutActions.resetSidePanel());
          }
          if (!!onClose) {
            onClose();
          }
          setTimeout(() => {
            useSidePanel || isCover
              ? setCover(
                  <SidePanelRenderer
                    renderPageType={continueToOther.pageType}
                    renderPageProps={Object.assign(
                      {},
                      continueToOther.pageProps,
                      {
                        closeCover: onClose,
                        onClose: onClose,
                        isCover: true,
                      },
                    )}
                  />,
                )
              : dispatch(
                  layoutActions.openSidePanel({
                    type: continueToOther.pageType,
                    props: continueToOther.pageProps,
                    expanded: continueToOther.expanded,
                  }),
                );
            dispatch(layoutActions.resetContinueState());
          }, 100);
        } else if (continueLink !== undefined) {
          dispatch(appSettingsActions.navigate(continueLink));
          if (isCover) {
            setCover(undefined);
          } else {
            dispatch(layoutActions.resetSidePanel());
          }
          if (!!onClose) {
            onClose();
          }
        } else {
          if (isCover) {
            setCover(undefined);
          } else {
            dispatch(layoutActions.resetSidePanel());
          }
          if (!!onClose) {
            onClose();
          }
        }
      }
      dispatch(layoutActions.resetConfirmState());
    }
    if (confirmRejected) {
      dispatch(layoutActions.resetConfirmState());
      if (!!onReject) {
        onReject();
      }
    }

    return () => undefined;
  }, [
    appSettingsActions,
    confirmApproved,
    confirmRejected,
    continueLink,
    continueToOther.continueOnApprove,
    continueToOther.expanded,
    continueToOther.pageProps,
    continueToOther.pageType,
    dispatch,
    isCover,
    layoutActions,
    onClose,
    onConfirm,
    onReject,
    useSidePanel,
  ]);

  return { cover, openPanel, closePanel, coverClosed, onCloseCover };
}
export default useSidePanelState;
