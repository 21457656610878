import axios from 'axios';
import { CancelTokenSource } from 'axios';
import { UploadLargeFileApi } from './Api';
import { CancelToken } from 'axios';
import { Guid } from 'guid-typescript';

export interface AddFileResponse {
  Result: boolean;
  FileId: Guid | null;
  UploadId: string | null;
  ETag: string | null;
  PartNumber: number | null;
  Message: string | null;
  NeedCompleteMultipartUpload: boolean;
}
export interface S3DS_UploadRespunse {
  ETag: string;
  PartNumber: number;
}
//  Each part must be at least 5 MB in size, except the last part.
// https://docs.aws.amazon.com/AmazonS3/latest/API/API_CompleteMultipartUpload.html
export const minChunkSize = 5 * 1024 * 1024;
export class UploadLargeFile {
  private cancelTokenSource?: CancelTokenSource;
  private cancelToken?: any;
  private _pause: boolean = false;
  private _busy: boolean = false;
  public finished: boolean = false;
  public fileId: Guid | null = null;
  public uploadId: string | null = null;
  public NeedCompleteMultipartUpload: boolean = false;
  private uploadResponses: S3DS_UploadRespunse[] = [];
  public get pause() {
    return this._pause;
  }
  public set pause(value: boolean) {
    if (this._pause !== value) {
      this._pause = value;
      if (value) {
        this.cancel();
      }
    }
  }
  public get busy() {
    return this._busy;
  }
  public set busy(value: boolean) {
    this._busy = value;
  }
  public cancel() {
    this.cancelTokenSource?.cancel();
  }
  public stopAndCancel() {
    this.cancelTokenSource?.cancel();
    this.pause = true;
  }
  conscreateCancelToken() {
    this.cancelTokenSource = axios.CancelToken.source();
    this.cancelToken = this.cancelTokenSource.token;
  }
  public async deleteUnfinishedLargeFiles() {
    if (!this.finished && this.fileId !== null) {
      await DeleteUnfinishedLargeFiles([this.fileId], this.uploadId);
      this.fileId = null;
      this.uploadId = null;
      this.uploadResponses = [];
    }
  }
  private finish() {
    this.finished = true;
    this.fileId = null;
    this.uploadId = null;
  }
  async upload(
    file: File,
    chunkSendResult: (
      res: boolean,
      pos: number,
      part: number,
      error?: string,
    ) => void,
    continueFrom: { start: number; part: number } | null,
    uploadEnd: () => void,
    targetId: number,
    CompletionResult: (value: boolean) => void,
  ) {
    if (this.finished === false && this.busy === false && targetId > 0) {
      this.busy = true;
      let start = 0;
      let part = 0;
      this.conscreateCancelToken();
      try {
        this.pause = false;
        if (file !== undefined && file.size > 0) {
          const size = Math.floor(file.size / 100);
          const chunkSize: number = size < minChunkSize ? minChunkSize : size;
          if (continueFrom !== null) {
            start = continueFrom.start;
            part = continueFrom.part;
          } else {
            this.fileId = null;
            this.uploadResponses = [];
          }
          while (start < file.size) {
            const res = await uploadChunk(
              file,
              file.slice(start, start + chunkSize),
              start,
              part,
              targetId,
              this.fileId,
              this.uploadId,
              this.cancelToken,
            );
            if (res.Result) {
              this.fileId = res.FileId;
              this.uploadId = res.UploadId;
              this.NeedCompleteMultipartUpload =
                res.NeedCompleteMultipartUpload;
              if (
                this.uploadId !== null &&
                this.uploadId !== '' &&
                res.ETag !== null &&
                res.PartNumber !== null &&
                res.NeedCompleteMultipartUpload
              ) {
                this.uploadResponses.push({
                  ETag: res.ETag,
                  PartNumber: res.PartNumber,
                });
              }
              start += chunkSize;
              part++;
              chunkSendResult(true, start, part);
              if (this.pause) {
                uploadEnd();
                return;
              }
            } else {
              chunkSendResult(false, start, part, res.Message ?? undefined);
              return;
            }
          }
        }
      } finally {
        if (start >= file.size) {
          if (
            this.uploadId !== null &&
            this.uploadResponses.length > 0 &&
            this.NeedCompleteMultipartUpload
          ) {
            const res = await CompleteMultipartUpload(
              this.fileId,
              this.uploadId,
              this.uploadResponses,
            );
            if (res) {
              this.finish();
            } else {
              CompletionResult(false);
            }
          } else {
            this.finish();
          }
        }
        uploadEnd();
        this.busy = false;
      }
    }
  }
}

const Api = new UploadLargeFileApi();
async function uploadChunk(
  file: File,
  chunk: Blob,
  pos: number,
  part: number,
  targetId: number,
  fileId: Guid | null,
  uploadId: string | null,
  cancel?: CancelToken,
): Promise<AddFileResponse> {
  try {
    const res = await Api.uploadChunck<AddFileResponse>(
      //'api/LargeFilesUpload/Upload',
      '/api/odata/v4/Comments/AddFile',
      file.name,
      file.size,
      chunk,
      pos,
      part,
      targetId,
      fileId,
      uploadId,
      cancel,
    );
    return Promise.resolve(res.data);
  } catch (error: unknown) {
    return Promise.resolve({
      Result: false,
      FileId: null,
      UploadId: null,
      ETag: null,
      PartNumber: null,
      Message: null,
      NeedCompleteMultipartUpload: false,
    });
  }
}

export async function CompleteMultipartUpload(
  fileId: Guid | null,
  uploadId: string | null,
  uploadResponses: S3DS_UploadRespunse[],
): Promise<boolean> {
  const url = '/api/odata/v4/Comments/CompleteMultipartUpload';
  try {
    await Api.CompleteMultipartUpload(url, fileId, uploadId, uploadResponses);
    return Promise.resolve(true);
  } catch {
    return Promise.resolve(false);
  }
}

export async function DeleteUnfinishedLargeFiles(
  ids: Guid[],
  uploadId: string | null,
): Promise<any> {
  const url = '/api/odata/v4/Comments/DeleteUnfinishedLargeFileIds';
  try {
    await Api.DeleteUnfinishedLargeFiles(url, ids, uploadId);
    return Promise.resolve(true);
  } catch {
    return Promise.resolve(false);
  }
}
