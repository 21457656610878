import { IViewState, ViewTypes } from './Scheduler/slice/types';
import { generatePath } from 'react-router';
import { calendarPageFullPath } from './calendarPageDefaultPath';
import { toQueryString } from 'utils/url-utils';
import { dateUtils } from 'utils/date-utils';

/**
 * Generates path of the scheduler (calendar/timeline) page
 * @param params
 * @returns
 */
export const GetCalendarPagePath = (params: IViewState) =>
  GetSchedulerPagePath(params.viewType);

export const GetSchedulerPagePath = (viewType: ViewTypes) =>
  generatePath(calendarPageFullPath, {
    type: viewType,
  });

type useCalendarLinkProps = {
  equipments?: Array<number | undefined>;
  start?: string | Date;
  openToBook?: boolean;
  viewType: ViewTypes;
  defaultMulti?: boolean;
};

export const useCalendarLink = (props?: useCalendarLinkProps): string => {
  const EnableLegacyScheduler = false;
  return getCalendarLink({
    EnableLegacyScheduler,
    viewType: 'calendar',
    ...props,
  });
};
export function getCalendarLink({
  EnableLegacyScheduler,
  ...props
}: {
  EnableLegacyScheduler: boolean;
} & useCalendarLinkProps) {
  if (EnableLegacyScheduler) {
    switch (props?.viewType) {
      case 'calendar':
        const params = {
          id: props?.equipments
            ?.filter(f => f !== undefined)
            ?.map(r => `os${r}`)
            ?.join(','),
          Start:
            props?.start === undefined
              ? undefined
              : dateUtils.formatQueryStringDate(
                  dateUtils.parseISO(props?.start),
                ),
          openToBook: props?.openToBook,
        };

        return `/Weekly.aspx?${toQueryString(params)}`;
      case 'timeline':
        return `/Timeline.aspx?${toQueryString({
          services: props?.equipments?.filter(f => f !== undefined)?.join(','),
          Start:
            props?.start === undefined
              ? undefined
              : dateUtils.formatQueryStringDate(
                  dateUtils.parseISO(props?.start),
                ),
          openToBook: props?.openToBook,
        })}`;
    }
  }
  const path = GetSchedulerPagePath(props.viewType ?? 'calendar');
  const query = {
    eid: props?.equipments
      ?.filter(f => f !== undefined)
      ?.map(r => `${r}`)
      ?.join(','),
    Start:
      props?.start === undefined
        ? undefined
        : dateUtils.formatQueryStringDate(dateUtils.parseISO(props?.start)),
    openToBook: props?.openToBook,
    defaultMulti: props?.defaultMulti,
  };

  return `${path}?${toQueryString(query)}`;
}
