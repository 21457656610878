import { httpClient } from 'api/HttpClient';
import axios from 'axios';
import {
  ImportAdditionalData,
  ImportData,
  ImportReservationData,
} from './Types';

export class ImportExportApi {
  export = (url: string, data?: any) => {
    return axios.post(url, data, {
      responseType: 'blob',
      timeout: undefined,
    });
  };
  import = async (url: string, file, data?: ImportData) => {
    const formData = new FormData();
    formData.append('File', file);
    if (data !== undefined) {
      formData.append('importData', JSON.stringify(data));
    }
    try {
      const response = await axios.post(url, formData, {
        responseType: 'blob',
        timeout: undefined,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error: unknown) {
      throw error;
    }
  };
  ConfigurableColumns = <T>(
    url: string,
    listId: number,
    configurableType: number,
  ) => {
    return httpClient.get<T>(url, {
      ListID: listId,
      ConfigurableType: configurableType,
    });
  };
  TemplateColumns = <T>(
    ScreenId: number,
    Iad: ImportAdditionalData = ImportAdditionalData.none,
    data: ImportReservationData,
  ) => {
    const url = 'api/GenericImport/TemplateNames';
    return axios.get<T>(url, {
      params: {
        screenId: ScreenId,
        iad: Iad,
        equipmentId: data.EquipmentId ?? 0,
        accServiceId: data.AccServiceId ?? 0,
        customFormId: data.CustomFormId ?? 0,
      },
    });
  };
  ImportTemplate = (
    ScreenId: number,
    Ext: string,
    Iad: ImportAdditionalData = ImportAdditionalData.none,
    data: ImportReservationData,
  ) => {
    const url = 'api/GenericImport/ImportTemplate';
    return axios.get(url, {
      responseType: 'blob',
      timeout: undefined,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        screenId: ScreenId,
        ext: Ext,
        iad: Iad,
        equipmentId: data.EquipmentId ?? 0,
        accServiceId: data.AccServiceId ?? 0,
        customFormId: data.CustomFormId ?? 0,
      },
    });
  };
  // Ids count may be too large.
  // Therefore, Ids are sent in the request body.
  ExportForImport = (
    ScreenId: number,
    Ids: number[],
    Iad: ImportAdditionalData = ImportAdditionalData.none,
  ) => {
    const data = { ScreenId: ScreenId, Ids: Ids, Iad: Iad };
    const formData = new FormData();
    formData.append('importData', JSON.stringify(data));
    const url = 'api/GenericImport/ExportForImport';
    return axios.post(url, formData, {
      responseType: 'blob',
      timeout: undefined,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
  importImages = (files: File[], ScreenId: number) => {
    const url = 'api/GenericImport/ImportImages';
    const formData = new FormData();
    for (const file of files) {
      formData.append(file.name, file);
    }
    return axios.post(url, formData, {
      responseType: 'blob',
      timeout: undefined,
      maxContentLength: 50000000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: { screenId: ScreenId },
    });
  };
}
