import ToggleButton from '@material-ui/lab/ToggleButton';
import { httpClient } from 'api/HttpClient';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import isHotkey from 'is-hotkey';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  createEditor,
  Descendant,
  Editor,
  Range,
  Transforms,
  Point,
  Element as SlateElement,
} from 'slate';
import { withHistory } from 'slate-history';
import {
  Editable,
  ReactEditor,
  Slate,
  useFocused,
  useSelected,
  useSlate,
  withReact,
} from 'slate-react';
import { Entity, Identifiable } from 'types/common';
import { Icon } from '../BasicIcons/FontAwesome';
import {
  deserialize,
  deserializeHtmlString,
  serializeHtmlString,
} from './RichEditorSerializer';
import { CustomEditor, MentionElement } from 'types/Slate';
import ReactDOM from 'react-dom';
import {
  relevantUrl,
  UserPicker,
} from '../pickers/AutocompletePickers/UserPicker';
import { DropDown } from '../DropDown';
import { MenuItem, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { debounce } from 'lodash';

import imageExtensions from 'image-extensions';
import isUrl from 'is-url';
import { MentionsWrapper, RichEditorRoot } from './styled';
import { InfoIcon } from '../BasicIcons/InfoIcon';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import {
  selectAuthenticatedUser,
  selectGlobalServiceGroupFilter,
} from 'app/slice/selectors';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};
const RICH_EDITOR_BUTTON_CLASS = 'rich-editor-header-button';
export interface RichEditorProps {
  value: Descendant[];
  setValue: (value: Descendant[]) => void;
  onChangeMentions?: (mentions: Entity<string>[]) => void;
  placeholder?: string;
  info?: NonNullable<React.ReactNode>;
  autoFocus?: boolean;
  borders?: boolean;
  compact?: boolean;
  readonly?: boolean;
  hasMentions?: boolean;
  additionalButtons?: ((className, key) => ReactNode)[];
  disabled?: boolean;
  useCodeButton?: boolean;
  isInvoiceTemplateEditor?: boolean;
  extendedMode?: boolean;
  setControlsVisible?: (visible: boolean) => void;
  labelOnTop?: boolean;
  serviceGroups?: Identifiable<number>[];
}

const RichTextWithMentions = (props: RichEditorProps) => {
  const {
    value,
    setValue,
    placeholder,
    info,
    autoFocus,
    hasMentions,
    onChangeMentions,
    additionalButtons,
    disabled,
    useCodeButton,
    isInvoiceTemplateEditor,
    setControlsVisible,
    labelOnTop,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    extendedMode,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => {
    return withMentions(
      withImages(withTables(withHtml(withHistory(withReact(createEditor()))))),
    );
  }, []);
  const [initValue, setInitValue] = useState(value);
  const ref = useRef<HTMLDivElement>(null);
  const [target, setTarget] = useState<Range | undefined>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState<Entity<string>[]>([]);
  const [mention, setMention] = useState<any>();
  const [mentions, setMentions] = useState<Entity<string>[]>([]);
  const showControlPanel = React.useMemo(() => {
    let stringVal = serializeHtmlString(initValue);
    return stringVal !== '' && stringVal !== undefined && stringVal !== null;
  }, [initValue]);
  const servicegroups = useSelector(selectGlobalServiceGroupFilter);
  const serviceGroups = useMemo(
    () => props.serviceGroups ?? servicegroups,
    [props.serviceGroups, servicegroups],
  );
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const withoutServiceGroups =
    authenticatedUser?.isLabTech() || authenticatedUser?.isGroupAdmin();
  useEffect(() => {
    if (setControlsVisible) {
      setControlsVisible(showControlPanel);
    }
  }, [setControlsVisible, showControlPanel]);
  useEffect(() => {
    if (!hasMentions) return;
    let unmounted = false;
    (search => {
      const url = relevantUrl;
      const params: {
        $orderby: string;
        $top: number;
        $filter?: string;
        $select?: string;
      } = {
        $orderby: 'Name asc',
        $select: 'Id,Name',
        $top: 10,
      };
      if (search !== null) {
        params.$filter = new ODataFilterBuilder<Entity<string>>({
          predicates: [],
          stringColumns: ['Name'],
          stringSearch: search,
          globalServiceGroupFilter:
            !serviceGroups?.length || withoutServiceGroups
              ? undefined
              : [
                  new Condition<any>(
                    'ServiceGroups',
                    ODataOperators.AnyIn,
                    serviceGroups,
                    'Id',
                  ),
                ],
        }).toString();
      }

      httpClient
        .get(url, params)
        .then(response => response.value as Entity<string>[])
        .then(v => {
          if (!unmounted) {
            setUsers(v);
          }
        })
        .catch(e => console.error(e));
    })(search);
    return () => {
      unmounted = true;
    };
  }, [search, hasMentions, serviceGroups, withoutServiceGroups]);

  useEffect(() => {
    if (target && mention && hasMentions) {
      Transforms.insertNodes(
        editor,
        [
          { text: '', plain: true } as any,
          mention as MentionElement,
          { text: ' ', plain: true } as any,
        ],
        {
          at: target,
        },
      );
      Transforms.move(editor, { distance: 2 });
      setTarget(undefined);
      setMention(undefined);
    }
  }, [target, mention, editor, hasMentions]);

  const onKeyDown = useCallback(
    event => {
      if (hasMentions && target && users.length) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const prevIndex = index >= users.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case 'ArrowUp':
            event.preventDefault();
            const nextIndex = index <= 0 ? users.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case 'Tab':
          case 'Enter':
            event.preventDefault();
            setMention({
              type: 'mention',
              user: { ...users[index] },
              children: [{ text: '' }],
            });
            break;
          case 'Escape':
            event.preventDefault();
            setTarget(undefined);
            break;
        }
      }
    },
    [hasMentions, index, target, users],
  );
  useEffect(() => {
    if (target) {
      const el = ref.current;
      if (!el) return;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [editor, target]);
  const onMentionsChange = () => {
    if (!hasMentions) return;
    const newMentions = getMentions(editor);
    if (newMentions.length !== mentions.length) {
      setMentions(newMentions);
      onChangeMentions?.(newMentions);
      return;
    }
    if (mentions.every(m => newMentions.map(v => v.Id).includes(m.Id))) return;
    setMentions(newMentions);
    onChangeMentions?.(newMentions);
  };

  //const fileUploadRef = useRef(null);

  /*   const onChangeUpload = e => {
    fnRemoteImage(editor, e.target.files);
  }; */

  const debounceValue = React.useCallback(
    value =>
      debounce(value => {
        setValue(value);
      }, 50)(value),
    [setValue],
  );
  return (
    <RichEditorRoot className={'rich-editor-root'} theme={theme}>
      <Slate
        editor={editor}
        value={initValue}
        onChange={value => {
          const { selection } = editor;
          if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection);
            const wordBefore = Editor.before(editor, start, { unit: 'word' });
            const before = wordBefore && Editor.before(editor, wordBefore);
            const beforeRange = before && Editor.range(editor, before, start);
            const beforeText =
              beforeRange && Editor.string(editor, beforeRange);
            const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
            const after = Editor.after(editor, start);
            const afterRange = Editor.range(editor, start, after);
            const afterText = Editor.string(editor, afterRange);
            const afterMatch = afterText.match(/^(\s|$)/);
            if (beforeMatch && afterMatch) {
              setTarget(beforeRange);
              setSearch(beforeMatch[1]);
              setIndex(0);
            } else {
              setTarget(undefined);
            }
          }
          debounceValue(value);
          setInitValue(value);
          onMentionsChange();
        }}
      >
        {showControlPanel && (
          <div className={'rich-editor-header'}>
            <div className={'rich-editor-header-controls'}>
              <MarkButton
                format="bold"
                className={RICH_EDITOR_BUTTON_CLASS}
                aria-label="bold"
              >
                <Icon icon="bold" fixedWidth />
              </MarkButton>
              <MarkButton
                format="italic"
                className={RICH_EDITOR_BUTTON_CLASS}
                aria-label="italic"
              >
                <Icon icon="italic" fixedWidth />
              </MarkButton>
              <MarkButton
                format="underline"
                className={RICH_EDITOR_BUTTON_CLASS}
                aria-label="underline"
              >
                <Icon icon="underline" fixedWidth />
              </MarkButton>
              {useCodeButton && (
                <MarkButton
                  format="code"
                  className={RICH_EDITOR_BUTTON_CLASS}
                  aria-label="code"
                >
                  <Icon icon="code" fixedWidth />
                </MarkButton>
              )}
              <BlockButton
                format="heading-one"
                className={RICH_EDITOR_BUTTON_CLASS}
                aria-label="heading-one"
              >
                {/* <LooksOneIcon /> */}
                <Icon icon="h1" fixedWidth />
              </BlockButton>
              <BlockButton
                format="heading-two"
                className={RICH_EDITOR_BUTTON_CLASS}
                aria-label="heading-two"
              >
                {/* <LooksTwoIcon /> */}
                <Icon icon="h2" fixedWidth />
              </BlockButton>
              <BlockButton
                format="block-quote"
                className={RICH_EDITOR_BUTTON_CLASS}
                aria-label="block-quote"
              >
                <Icon icon="quote-right" fixedWidth />
              </BlockButton>
              <BlockButton
                format="numbered-list"
                className={RICH_EDITOR_BUTTON_CLASS}
                aria-label="numbered-list"
              >
                <Icon icon="list-ol" fixedWidth />
              </BlockButton>
              <BlockButton
                format="bulleted-list"
                className={RICH_EDITOR_BUTTON_CLASS}
                aria-label="bulleted-list"
              >
                <Icon icon="list-ul" fixedWidth />
              </BlockButton>
              {/*             <BlockButton
              format="file-alt"
              className={RICH_EDITOR_BUTTON_CLASS}
              aria-label="file-alt"
              onMouseDown={event => {
                event.preventDefault();
                if (!!fileUploadRef.current) {
                  (fileUploadRef.current as any).click();
                }
              }}
            >
              <Icon icon="file-alt" fixedWidth />
            </BlockButton> */}
              {/*             <input
              type="file"
              onChange={onChangeUpload}
              id="chooseFile"
              className="invisible"
              ref={fileUploadRef}
            /> */}
              {hasMentions && (
                <>
                  <DropDown
                    size="small"
                    className={RICH_EDITOR_BUTTON_CLASS}
                    color="default"
                    variant="ghost"
                    onMouseDown={e => {
                      e.preventDefault();
                      const end = Editor.end(editor, []);
                      const { selection } = editor;
                      setTarget(selection ?? { anchor: end, focus: end });
                      setUsers([]);
                    }}
                    menuChildren={close => [
                      <MenuItem key="userPickerDropDown">
                        <UserPicker
                          value={null}
                          urlType="relevant"
                          placeholder={t(translations.EnterAUser)}
                          withGroupFilter={!withoutServiceGroups}
                          serviceGroups={props.serviceGroups}
                          selectArray={['Id', 'Name']}
                          onChange={v => {
                            if (!v) return;
                            setMention({
                              type: 'mention',
                              user: v,
                              children: [{ text: '' }],
                            });
                            close();
                          }}
                        />
                      </MenuItem>,
                    ]}
                  >
                    <Icon icon="at" fixedWidth />
                  </DropDown>
                </>
              )}
              {additionalButtons &&
                additionalButtons.map((b, i) =>
                  b(RICH_EDITOR_BUTTON_CLASS, 'richtextbutton' + i),
                )}
            </div>
          </div>
        )}
        <div
          className={clsx({
            'rich-editor-text': !isInvoiceTemplateEditor,
            'rich-editor-body': isInvoiceTemplateEditor,
            'text-with-info': !isInvoiceTemplateEditor && !!info,
            'text-with-controls': showControlPanel,
          })}
        >
          <Editable
            readOnly={disabled}
            // className={
            //   isInvoiceTemplateEditor === true ? 'rich-editor-body' : undefined
            // }
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            disabled={disabled}
            spellCheck
            autoFocus={autoFocus}
            onKeyDown={event => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event.nativeEvent)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
              onKeyDown(event);
            }}
          />
          {!!info && (
            <span
              className={clsx('info-icon', {
                'info-icon-top-label': labelOnTop,
                'info-icon-controls': showControlPanel,
                'info-icon-top-controls': showControlPanel && labelOnTop,
              })}
            >
              <InfoIcon title={info} />
            </span>
          )}

          {hasMentions && target && users.length > 0 && search !== '' && (
            <Portal>
              <MentionsWrapper
                className={'mentions-root'}
                ref={ref}
                data-cy="mentions-portal"
              >
                {users.map((char, i) => (
                  <div
                    key={char.Id + i}
                    className={clsx('mentions-item', {
                      'entions-item-active': i === index,
                    })}
                    onMouseEnter={() => {
                      setIndex(i);
                    }}
                    onMouseDown={() =>
                      setMention({
                        type: 'mention',
                        user: char,
                        children: [{ text: '' }],
                      })
                    }
                  >
                    {char.Name}
                  </div>
                ))}
              </MentionsWrapper>
            </Portal>
          )}
        </div>
      </Slate>
    </RichEditorRoot>
  );
};

const toggleMark = (editor: CustomEditor, format) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const MarkButton = ({ format, children, className, ...props }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      size="small"
      value={format}
      className={className}
      selected={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      {...props}
    >
      {children}
    </ToggleButton>
  );
};

const BlockButton = ({ format, children, className, ...props }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      size="small"
      value={format}
      selected={isBlockActive(editor, format)}
      className={className}
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      {...props}
    >
      {children}
    </ToggleButton>
  );
};

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  });
  return !!match;
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const isMarkActive = (editor: CustomEditor, format) => {
  const [match] = Editor.nodes(editor, {
    match(node, path) {
      return node[format] === true;
    },
  });
  return !!match;
};

export const StartRichEditor: React.FC<{
  initialValue: string | undefined;
  onChange: (value: string | undefined) => void;
  placeholder?: string;
  autoFocus?: boolean;
  hasMentions?: boolean;
  onChangeMentions?: (mentions: Entity<string>[]) => void;
  additionalButtons?: ((className, key) => ReactNode)[];
  disabled?: boolean;
  isInvoiceTemplateEditor?: boolean;
  extendedMode?: boolean;
  info?: NonNullable<React.ReactNode>;
  setControlsVisible?: (visible: boolean) => void;
  labelOnTop?: boolean;
  serviceGroups?: Identifiable<number>[];
}> = ({
  initialValue,
  onChange,
  placeholder,
  autoFocus,
  hasMentions,
  onChangeMentions,
  additionalButtons,
  disabled,
  isInvoiceTemplateEditor,
  extendedMode,
  info,
  setControlsVisible,
  labelOnTop,
  serviceGroups,
}) => {
  const setValue = value => {
    onChange(serializeHtmlString(value));
  };

  return (
    <RichTextWithMentions
      value={deserializeHtmlString(initialValue)}
      setValue={setValue}
      placeholder={placeholder}
      autoFocus={autoFocus}
      hasMentions={hasMentions}
      onChangeMentions={onChangeMentions}
      additionalButtons={additionalButtons}
      info={info}
      disabled={disabled}
      isInvoiceTemplateEditor={isInvoiceTemplateEditor}
      extendedMode={extendedMode}
      setControlsVisible={setControlsVisible}
      labelOnTop={labelOnTop}
      serviceGroups={serviceGroups}
    />
  );
};

const withMentions = (editor: CustomEditor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element);
  };

  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element);
  };

  return editor;
};

const getMentions = editor => {
  const mentions = Editor.nodes(editor, {
    match: node => node.type === 'mention',
    at: [],
  });
  const set = new Set<string>();
  const res: Entity<string>[] = [];
  for (const [node] of mentions) {
    if (node.type === 'mention') {
      const user = (node as MentionElement).user;
      if (!set.has(user.Id)) {
        res.push(user);
        set.add(user.Id);
      }
    }
  }
  return res;
};

const Portal = ({ children }) => {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

const fnRemoteImage = (editor, files) => {
  for (const file of files) {
    const [mime] = file.type.split('/');
    if (mime === 'image') {
      const formData = new FormData();
      formData.append('image', file);
      //Need fixing - currently this route is commented on server
      fetch('/api/odata/v4/Files/InsertFile', {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(result => {
          console.log(result);
          insertImage(
            editor,
            result.data
              .uri /* 'file:///C:/bookitlab/web-ui/public/favicon.png' */,
          );
        })
        .catch(error => {
          alert('Error:' + error);
        });
    }
  }
};

const withImages = editor => {
  const { insertData, isVoid } = editor;

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element);
  };

  editor.insertData = data => {
    const text = data.getData('text/plain');
    const { files } = data;
    console.log('insertData:', files);
    if (files && files.length > 0) {
      fnRemoteImage(editor, files);
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const isImageUrl = url => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split('.').pop();
  return imageExtensions.includes(ext as string);
};

const insertImage = (editor, url) => {
  const text = { text: '' };
  const image = { type: 'image', url, children: [text] };
  Transforms.insertNodes(editor, image as any);
};

const withTables = editor => {
  const { deleteBackward, deleteForward, insertBreak } = editor;

  editor.deleteBackward = unit => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === 'table-cell',
      });

      if (cell) {
        const [, cellPath] = cell;
        const start = Editor.start(editor, cellPath);

        if (Point.equals(selection.anchor, start)) {
          return;
        }
      }
    }

    deleteBackward(unit);
  };

  editor.deleteForward = unit => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === 'table-cell',
      });

      if (cell) {
        const [, cellPath] = cell;
        const end = Editor.end(editor, cellPath);

        if (Point.equals(selection.anchor, end)) {
          return;
        }
      }
    }

    deleteForward(unit);
  };

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === 'table',
      });

      if (table) {
        return;
      }
    }

    insertBreak();
  };

  return editor;
};

const withHtml = editor => {
  const { insertData, isInline, isVoid } = editor;

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element);
  };

  editor.insertData = data => {
    const html = data.getData('text/html');

    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html');
      const fragment = deserialize(parsed.body);
      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  };

  return editor;
};

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'thead':
      return <thead {...attributes}>{children}</thead>;
    case 'tbody':
      return <tbody {...attributes}>{children}</tbody>;
    case 'table':
      return <table {...attributes}>{children}</table>;
    case 'table-row':
      //return <tr {...attributes}>{children}</tr>;
      return (
        <TrStyled
          attributes={attributes}
          children={children}
          element={element}
        />
      );
    case 'table-cell':
      //return <td {...attributes}>{children}</td>;
      return (
        <TdStyled
          attributes={attributes}
          children={children}
          element={element}
        />
      );
    case 'table-cell-header':
      return <th {...attributes}>{children}</th>;
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>;
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>;
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>;
    case 'heading-six':
      return <h6 {...attributes}>{children}</h6>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'link':
      return (
        <a href={element.url} {...attributes}>
          {children}
        </a>
      );
    case 'mention':
      return (
        <Mention
          attributes={attributes}
          children={children}
          element={element}
        />
      );
    case 'image':
      return (
        <ImageElement
          attributes={attributes}
          children={children}
          element={element}
        />
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.plain) {
    children = <data>{children}</data>;
  }

  return <span {...attributes}>{children}</span>;
};

export const ImageElement = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div {...attributes}>
      {children}
      <img
        alt="Image1"
        src={element.url}
        className={clsx('image-element', {
          'image-element-selected': selected && focused,
        })}
      />
    </div>
  );
};

export const TrStyled = ({ attributes, children, element }) => {
  return (
    <tr {...attributes} className="tr-row">
      {children}
    </tr>
  );
};

export const TdStyled = ({ attributes, children, element }) => {
  return (
    <td {...attributes} className="td-row">
      {children}
    </td>
  );
};

export const Mention = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <span
      {...attributes}
      contentEditable={false}
      className={clsx('mention-element', {
        'mention-element-selected': selected && focused,
      })}
    >
      @{element.user.Name}
      {children}
    </span>
  );
};
