import * as React from 'react';
import { IBudgetExperimentsFilterDto } from 'api/odata/generated/entities/IBudgetExperimentsFilterDto';
import { IBudgetDto } from 'api/odata/generated/entities/IBudgetDto';
import {
  Condition,
  ODataOperators,
  quoteODataValue,
} from 'api/odata/ODataFilter';
import {
  AutocompletePickerProps,
  AutocompletePicker,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { BudgetExperimentStatuses } from 'enums/BudgetExperimentStatuses';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { Timestamp } from 'types/Timestamp';
import { dateUtils } from 'utils/date-utils';
import { isNullOrUndefined } from 'utils/typeUtils';
import { IPathAndQuery } from 'utils/url-utils';
import { Identifiable } from 'types/common';

export function budgetExperimentsFilter(
  serviceTypes: number[],
  serviceGroups: number[],
  serviceType: ServiceType | undefined,
  budgetId: number | undefined,
  resStart: Date | string | null | undefined,
  resEnd: Date | string | null | undefined,
): (string | Condition<IBudgetExperimentsFilterDto>)[] {
  const predicates: (string | Condition<IBudgetExperimentsFilterDto>)[] = [];

  predicates.push(
    new Condition<IBudgetExperimentsFilterDto>(
      'Active',
      ODataOperators.Equals,
      true,
    ),
  );

  predicates.push(
    new Condition<IBudgetExperimentsFilterDto>(
      'StatusId',
      ODataOperators.Equals,
      BudgetExperimentStatuses.Approved,
    ),
  );

  predicates.push(
    new Condition<IBudgetExperimentsFilterDto>(
      'BudgetTaskActive',
      ODataOperators.Equals,
      true,
    ),
  );

  if (!isNullOrUndefined(budgetId)) {
    predicates.push(
      new Condition<IBudgetExperimentsFilterDto>(
        'BudgetTaskBudgetId',
        ODataOperators.Equals,
        budgetId,
      ),
    );
  }

  if (serviceGroups.length > 0) {
    let ids = serviceGroups.filter(f => f !== null);
    if (ids.length > 0) {
      predicates.push(
        `(${new Condition<IBudgetExperimentsFilterDto>(
          'BudgetTaskAllCoresAllowed',
          ODataOperators.Equals,
          true,
        )} or ${new Condition<IBudgetExperimentsFilterDto>(
          'BudgetTaskServiceGroupIds',
          ODataOperators.AnyIn,
          ids,
        )})`,
      );
      predicates.push(
        `(BudgetExperimentServiceGroups/any(s: s/ServiceGroupId in ${quoteODataValue(
          ids,
        )} and s/StatusId eq ${quoteODataValue(
          BudgetExperimentStatuses.Approved,
        )}))`,
      );
    }
  }

  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}
export interface ReservationBudgetExperimentParams {
  serviceId: Array<Identifiable<number>>;
  userId: string | null;
  budgetId: number | null;
  start: Timestamp | Date;
  end: Timestamp | Date;
  selectedExpirementId?: number;
}
export interface ReservationBudgetExperimentPickerProps
  extends ReservationBudgetExperimentParams,
    Omit<AutocompletePickerProps<IBudgetExperimentsFilterDto>, 'loadData'> {
  name?: string;
  placeholder?: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  error?: boolean;
  helperText?: string;
}

const budgetDtoFields: Array<keyof IBudgetDto> = [
  'Id',
  'Name',
  'UserGroupId',
  'UserGroupName',
  'Active',
  'IsDefault',
  'HideExperiments',
  'ServiceGroupServicesAllowed',
  'StartDate',
  'EndDate',
  'Type',
];
const budgetExperimentDtoFields: Array<keyof IBudgetExperimentsFilterDto> = [
  'Id',
  'Name',
  'Active',
  'StatusId',
  'BudgetTaskBudgetId',
];
export const getLoadData = (p: ReservationBudgetExperimentParams) => {
  const { path, search } = getBudgetExperimentsValidForReservationPathInfo(p);
  return getAutoCompleteLoadDataFn<IBudgetExperimentsFilterDto>({
    url: path,
    queryString: search,
    select: budgetExperimentDtoFields,
    // expand is needed here for the use on reservation details to auto select the related budget
    // otherwise, when the budget is already preselected, expand will just slow things down
    expand: getBudgetExpandClause(p),
  });
};

function getBudgetExpandClause(
  p: ReservationBudgetExperimentParams,
): string | undefined {
  return p.budgetId === null
    ? `Budget($select=${budgetDtoFields.join(',')})`
    : undefined;
}

/**
 * Prepares parameters to fetch budget experiments usable to book something (e.g. reservation, offline service, etc.)
 * @param p dependencies used to determine relevant budget experiments
 * @returns path/search to fetch the budget experiments
 */
export function getBudgetExperimentsValidForReservationPathInfo(
  p: ReservationBudgetExperimentParams,
): IPathAndQuery {
  const pp: Record<
    keyof Omit<ReservationBudgetExperimentParams, 'selectedExpirementId'>,
    string | null
  > = {
    serviceId: JSON.stringify(p.serviceId.map(f => f.Id)),
    userId: "'" + p.userId?.replaceAll("'", "''") + "'",
    budgetId: JSON.stringify(p.budgetId),
    start: '@start',
    end: '@end',
  };
  const BudgetExperimentsValidForReservationURL = `/api/odata/v4/BudgetExperimentsFilter/BudgetExperimentsValidForReservation(serviceId=${pp.serviceId}, budgetId=${pp.budgetId}, userId=${pp.userId}, start=${pp.start}, end=${pp.end})`;

  // start/end parameters are extracted into QueryString to avoid triggering "A potentially dangerous Request.Path value (:)..." issue with .net path validation
  const params = {
    '@start': dateUtils.formatISO(dateUtils.parseISO(p.start)),
    '@end': dateUtils.formatISO(dateUtils.parseISO(p.end)),
    $select: budgetExperimentDtoFields.join(','),
    $expand: getBudgetExpandClause(p),
  };
  return { path: BudgetExperimentsValidForReservationURL, search: params };
}

export function ReservationBudgetExperimentPicker({
  serviceId,
  budgetId,
  userId,
  start,
  end,
  ...props
}: ReservationBudgetExperimentPickerProps) {
  const innerLoadData = React.useMemo(
    () =>
      getLoadData({
        serviceId,
        budgetId,
        userId,
        start,
        end,
      }),
    [budgetId, end, serviceId, start, userId],
  );

  return (
    <AutocompletePicker
      loadData={innerLoadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'budgetExperiment'}
      {...props}
    />
  );
}
export const loadBudgetExperiments = getLoadData;
