import { createSlice } from 'utils/@reduxjs/toolkit';
import { LargeFilesUploadState } from './types';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { largeFilesUploadSaga } from './saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';

export const initialState: LargeFilesUploadState = {
  selectedFiles: 0,
  canUpload: false,
  uploading: false,
  targetId: 0,
  startedToUpload: false,
  files: [],
  currentNumberOfUploadedFiles: 0,
  numberOfUploadedFiles: 0,
  commentType: undefined,
  referenceId: undefined,
  refernceStringId: undefined,
};

const slice = createSlice({
  name: 'largeFilesUpload',
  initialState,
  reducers: {
    setSeletedFiles(state, action: PayloadAction<number>) {
      state.selectedFiles = action.payload;
    },
    setCommentProperties(
      state,
      action: PayloadAction<{
        commentType: CommentReferenceTypes;
        referenceId: number;
        refernceStringId?: string;
      }>,
    ) {
      state.commentType = action.payload.commentType;
      state.referenceId = action.payload.referenceId;
      state.refernceStringId = action.payload.refernceStringId;
    },
    setCanUpload(state, action: PayloadAction<number>) {
      if (action.payload > 0) {
        state.targetId = action.payload;
        state.canUpload = true;
        state.startedToUpload = false;
        state.uploading = false;
        state.currentNumberOfUploadedFiles = 0;
        state.numberOfUploadedFiles = 0;
      } else {
        state.canUpload = false;
        state.startedToUpload = false;
        state.uploading = false;
        state.targetId = 0;
        state.files = [];
        state.currentNumberOfUploadedFiles = 0;
        state.numberOfUploadedFiles = 0;
      }
    },
    addFile(state, action: PayloadAction<string>) {
      state.files.push(action.payload);
      if (state.canUpload && state.files.length === 1) {
        state.startedToUpload = true;
        state.currentNumberOfUploadedFiles = 0;
        state.numberOfUploadedFiles = 0;
        state.selectedFiles = 0;
      }
      state.uploading = state.canUpload && state.files.length > 0;
    },
    removeFile(state, action: PayloadAction<string>) {
      const index = state.files.findIndex(item => item === action.payload);
      if (index >= 0) {
        state.files.splice(index, 1);
        state.currentNumberOfUploadedFiles++;
      }
      if (state.files.length === 0 && state.uploading) {
        state.numberOfUploadedFiles = state.currentNumberOfUploadedFiles;
        state.currentNumberOfUploadedFiles = 0;
        state.uploading = false;
      }
    },
    resetNumberOfUploadedFiles(state) {
      state.selectedFiles = 0;
      state.numberOfUploadedFiles = 0;
    },
  },
});

export const { actions: largeFilesUploadActions } = slice;

export const useLargeFilesUploadSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: largeFilesUploadSaga });
  return { actions: slice.actions };
};
