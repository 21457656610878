import * as React from 'react';
import { translations } from 'locales/translations';
import { dateUtils } from 'utils/date-utils';
import { httpClient } from 'api/HttpClient';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { usePromise } from 'app/hooks/usePromise';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { IAssetBarcodeScanLogDto } from 'api/odata/generated/entities/IAssetBarcodeScanLogDto';
import { IODataQueryResponse } from 'api/odata/IODataQueryResponse';
import { useTranslation } from 'react-i18next';

/**
 * Fetches & shows last location update details (user/date) for a given asset
 */

export function AssetLocationLastUpdate(props: { assetId: number }) {
  const f = React.useCallback(async (assetId: number) => {
    const url = `/api/odata/v4/AssetDetails2/${assetId}/BarcodeScanLog`;
    const result: IODataQueryResponse<IState> = await httpClient.get(url, {
      $select: 'Date,User',
      $expand: 'User($select=Id,Name)',
      $top: 1,
      $orderBy: 'Date desc',
    });
    return result.value[0];
  }, []);
  type IState = Pick<IAssetBarcodeScanLogDto, 'Date' | 'User'>;

  const [fetchState, fetch] = usePromise(f);
  const [state, setState] = useAsyncExtendedState<IState | undefined>(
    undefined,
  );
  useEffectOnMount(() => setState(fetch(props.assetId)));
  const { t } = useTranslation();

  if (fetchState.status === 'resolved' && state !== undefined) {
    return (
      <>
        &emsp;{t(translations.Updated)}:{' '}
        {dateUtils.shortDateFormat(state?.Date)} {state?.User?.Name}
      </>
    );
  } else {
    return null;
  }
}
