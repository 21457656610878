import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export const TableRoot = styled.div`
  &.table-root {
    width: 100%;
    height: inherit;
    display: flex;
    overflow-x: unset;
    overflow-y: hidden;
    flex-direction: column;
    padding-bottom: 0px;
    &.fitContent {
      height: fit-content;
    }
    &.section-table {
      background: ${bookitColors.base.white};
      border: 1px solid ${bookitColors.grayscale.grayBorders};
      border-radius: 12px;
      padding-bottom: 24px;
    }
    & .table-sub-header {
      padding: 0px 32px 16px;
    }
    & .table-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px 32px 16px;
      gap: 12px;
      flex-wrap: wrap;
      &.table-buttons-short {
        flex-direction: column;
        align-items: normal;
        padding: 8px 16px 16px;
      }
      &.table-padding-dense {
        padding: 8px 0px 16px;
      }
      & .table-buttons-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 12px;
        flex-wrap: wrap;
        &.table-buttons-left-short {
          justify-content: space-between;
        }
        &.table-buttons-left-wrap {
          flex-wrap: wrap;
        }
        &.table-buttons-left-no-wrap {
          flex-wrap: nowrap;
        }
      }
      & .table-buttons-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 8px;
        margin-left: auto;
        &.table-buttons-right-short {
          justify-content: space-between;
        }
      }
    }
    & .table-filter-display-root {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0px 32px 16px;
      gap: 16px;
      flex-wrap: wrap;
      &.table-filter-display-short {
        flex-direction: column;
        align-items: normal;
      }
    }
    & .table-content {
      padding: 0px 16px 0px 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: hidden;
      &.table-content-short {
        padding: 0px 8px 0px 16px;
        height: 100%;
        width: 100%;
      }
      &.table-padding-dense {
        padding: 0;
      }
      & .table-scrolling {
        width: 100%;
        height: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 2px ${bookitColors.grayscale.grayBorders};
          border-radius: 6px;
          background-color: ${bookitColors.grayscale.grayBorders};
        }
        & .table {
          background-color: ${bookitColors.base.white};
          white-space: pre;
          &.with-footer {
            border-radius: 8px 8px 0px 0px;
          }
        }
        & .table-footer {
          width: 100%;
          background-color: ${bookitColors.base.white};
          min-height: 42px;
          overflow-x: auto;
          &::-webkit-scrollbar {
            height: 6px;
          }
          &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 2px ${bookitColors.grayscale.grayBorders};
            border-radius: 6px;
            background-color: ${bookitColors.grayscale.grayBorders};
          }
          .tableCards & {
            background-color: ${bookitColors.grayscale.grayBackground};
          }
          &.table-footer-border {
            border: 1px solid ${bookitColors.grayscale.graySelection};
            border-top: 1px solid ${bookitColors.grayscale.grayBorders};
            border-radius: 5px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
          }
        }
      }
    }
  }
`;
export const TableContent = styled.div`
  &.table-content {
    padding: 0px 16px 0px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: hidden;
    &.table-content-short {
      padding: 0px 8px 0px 16px;
      height: 100%;
      width: 100%;
    }
    &.table-padding-dense {
      padding: 0;
    }
    & .table-scrolling {
      width: 100%;
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 2px ${bookitColors.grayscale.grayBorders};
        border-radius: 6px;
        background-color: ${bookitColors.grayscale.grayBorders};
      }
      & .table {
        background-color: ${bookitColors.base.white};
        white-space: pre;
        &.with-footer {
          border-radius: 8px 8px 0px 0px;
        }
      }
      & .table-footer {
        width: 100%;
        background-color: ${bookitColors.base.white};
        min-height: 42px;
        overflow-x: auto;
        &::-webkit-scrollbar {
          height: 6px;
        }
        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 2px ${bookitColors.grayscale.grayBorders};
          border-radius: 6px;
          background-color: ${bookitColors.grayscale.grayBorders};
        }
        .tableCards & {
          background-color: ${bookitColors.grayscale.grayBackground};
        }
        &.table-footer-border {
          border: 1px solid ${bookitColors.grayscale.graySelection};
          border-top: 1px solid ${bookitColors.grayscale.grayBorders};
          border-radius: 5px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      }
    }
  }
`;
