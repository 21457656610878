/**
 *
 * RepairCodesPicker
 *
 */
import { InputBaseComponentProps } from '@material-ui/core';
import { httpClient } from 'api/HttpClient';
import { IRepairCodeDto } from 'api/odata/generated/entities/IRepairCodeDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompleteMultiPickerEx,
  AutocompleteMultiPickerExProps,
} from 'app/components/BasicPickers/AutocompleteMultiPickerEx';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { Entity, Identifiable } from 'types/common';
export interface RepairCodesPickerProps
  extends Omit<AutocompleteMultiPickerExProps<IRepairCodeDto>, 'loadData'> {
  inputProps?: InputBaseComponentProps;
  separator?: string;
}

const url = '/api/odata/v4/RepairCodes';
export const initRepairCodesData = (
  initval: string | undefined,
  separator?: string,
) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    let ids = (
      initval.indexOf(separator || '|') !== -1
        ? initval.split(separator || '|')
        : [initval]
    ).map(item => {
      return { Id: parseInt(item) };
    }) as Identifiable<number>[];

    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id in (' + ids.join(',') + '))',
      $select: `Id,Name,Active,AssetCatId,AssetCatName`,
    };

    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};

const loadData = (
  predicates: (string | Condition<IRepairCodeDto>)[] | undefined,
) => {
  return getAutoCompleteLoadDataFn<IRepairCodeDto>({
    url: url,
    predicates: predicates,
    select: ['Id', 'Name', 'Active', 'AssetCatId', 'AssetCatName'],
  });
};
export function RepairCodesPicker(props: RepairCodesPickerProps) {
  const innerLoadData = loadData(props.predicates);
  return (
    <AutocompleteMultiPickerEx
      mini={props.mini ? true : undefined}
      size={props.size}
      loadData={innerLoadData}
      id={props.id || 'repaircodesId'}
      freeSolo={props.freeSolo}
      {...props}
    />
  );
}
export const RepairCodesAssetCatFilter = (
  assetCats: number[] | undefined,
): (string | Condition<IRepairCodeDto>)[] => {
  if (assetCats !== undefined && assetCats.length > 0) {
    return [
      new Condition<IRepairCodeDto>(
        'AssetCatId',
        ODataOperators.Includes,
        assetCats,
      ),
    ];
  }
  return [];
};
