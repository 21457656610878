import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useRequestSamplesSlice } from '../../slice';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonConfirm } from 'app/components/ButtonConfirm';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { selectServiceRequestStatusesByInternalId } from '../../slice/selectors';

export interface ICancelServiceRequestButtonProps {
  serviceRequestId?: number;
}
export function CancelServiceRequestButton(
  props: ICancelServiceRequestButtonProps,
) {
  const { t } = useTranslation();
  const { actions } = useRequestSamplesSlice();
  const dispatch = useDispatch();
  const statuses = useSelector(state =>
    selectServiceRequestStatusesByInternalId(state, 'Cancelled'),
  );
  const handleClick = () => {
    const status = statuses?.[0];
    if (props.serviceRequestId !== undefined && status !== undefined) {
      dispatch(
        actions.cancelServiceRequest({
          Id: props.serviceRequestId,
          Status: status,
          Reason: 'Canceled',
        }),
      );
    }
  };
  return (
    <ButtonConfirm
      size="small"
      startIcon={<Icon icon="times" />}
      aria-label="history"
      onClick={handleClick}
      variant="white"
      title={t(translations.CancelRequest)}
      confirmationDialogBody={t(translations.AreYouSure)}
    >
      {t(translations.CancelRequest)}
    </ButtonConfirm>
  );
}
