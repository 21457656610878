import * as React from 'react';
import { useRowSelect, PluginHook, Row } from 'react-table';
import { IRow } from 'app/components/BasicTable/IRow';
import { Icon } from 'app/components//BasicIcons/FontAwesome';
import { Check } from 'app/components/BasicInputs/Check';
import {
  getConditionalSelectColumnCheckboxProps,
  getConditionalSelectHeaderCheckboxProps,
} from './ConditionalSelectHeaderCheckbox';
import { defaultCheckIfRowIsSelectable } from './index';
import styled from 'styled-components';

export const CheckWrapper = styled('div')`
  &.check-root {
    padding: 3px;
  }
`;
const ExpandWrapper = styled('div')`
  &.expand-root {
    padding: 10px;
  }
`;
/**
 * Adds useRowSelect with the checkboxes in the first column
 * @param tablePlugins table plugins array passed into useTable
 * @param isRowSelectable controls row select-ability
 * @param onSelectedChange
 */
export function addRowSelect<TRow extends IRow>(
  tablePlugins: PluginHook<TRow>[],
  isRowSelectable?: (row: Row<TRow>) => boolean,
  onSelectedChange?: (row: TRow, checked: boolean) => void,
) {
  tablePlugins.push(useRowSelect);
  tablePlugins.push(hooks => {
    hooks.visibleColumns.push(columns => [
      // Let's make a column for selection
      {
        id: 'selection',
        disableSortBy: true,
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: props => {
          const checkboxProps = React.useMemo(() => {
            return getConditionalSelectHeaderCheckboxProps<TRow>({
              headerProps: props,
              checkIfRowIsSelectable:
                isRowSelectable || defaultCheckIfRowIsSelectable,
              onSelectedChange: onSelectedChange,
              shouldSelectPage: false,
            });
          }, [props]);
          return (
            <CheckWrapper className="check-root" aria-label="Check All">
              <Check
                {...checkboxProps}
                inputProps={{ 'aria-label': 'Select All' }}
                withPadding={false}
              />
            </CheckWrapper>
          );
        },
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => {
          if (!row.original.Rows || row.original.Rows.length <= 1) {
            const checkProps = getConditionalSelectColumnCheckboxProps<TRow>({
              row: row,
              checkIfRowIsSelectable:
                isRowSelectable || defaultCheckIfRowIsSelectable,
              onSelectedChange: onSelectedChange,
            });

            if (!!isRowSelectable && !isRowSelectable(row)) {
              return <></>;
            } else {
              return (
                <CheckWrapper
                  className="check-root"
                  onClick={e => e.stopPropagation()}
                >
                  <Check
                    {...checkProps}
                    inputProps={{ 'aria-label': 'Select' }}
                    withPadding={false}
                  />
                </CheckWrapper>
              );
            }
          }
          return (
            <ExpandWrapper className="expand-root">
              <Icon
                {...row.getToggleRowExpandedProps()}
                icon={row.isExpanded ? 'minus' : 'plus'}
              />
            </ExpandWrapper>
          );
        },
      },
      ...columns,
    ]);
  });
}
