/**
 *
 * ChipCheckBoxGroup
 *
 */
import {
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@material-ui/core';
import * as React from 'react';
import { BookitChipWithCheck } from '.';
import { CheckEntity } from '../CheckBoxGroup';
import FormControlLabel from '../Forms/FormControls/FormControlLabel';

export interface ChipCheckBoxGroupProps {
  options: CheckEntity[];
  value?: CheckEntity[];
  className?: string;
  onChange?: (
    value: CheckEntity,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  id: string;
  //formik
  name?: string;
  error?: boolean;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  info?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  margin?: 'none' | 'dense' | 'normal';
  isRow?: boolean;
  style?: React.CSSProperties;
}

export function ChipCheckBoxGroup({
  name,
  id,
  value,
  options,
  onChange,
  label,
  required,
  helperText,
  disabled,
  fullWidth,
  margin,
  error,
  size,
  isRow,
  ...props
}: ChipCheckBoxGroupProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = {
      Id: parseInt(event.target.id),
      Name: event.target.name,
      checked: event.target.checked,
      disabled: event.target.disabled,
    } as CheckEntity;
    if (onChange !== undefined) {
      onChange(value, event);
    }
  };
  return (
    <FormControl
      required={required}
      error={error}
      component="fieldset"
      name={name}
      margin={margin}
      id={id}
      fullWidth={fullWidth}
      className="chip-form-control"
    >
      <FormLabel className={'chip-label'}>{label}</FormLabel>
      <FormGroup row={isRow} className="chip-form-group">
        {options.map((item, index) => (
          <FormControlLabel
            className="chip-form-label"
            value={item}
            key={`control_${item.Id}`}
            disabled={disabled || item.disabled}
            label=""
            control={
              <BookitChipWithCheck
                key={`chip_${item.Id}`}
                checkid={item.Id.toString()}
                label={item.Name}
                checked={item.checked}
                checkname={item.Name}
                disabled={item.disabled}
                info={item.Name.length > 24 ? item.Name : undefined}
                onChange={handleChange}
                customsize="small"
                size="small"
                color="primary"
              />
            }
          />
        ))}
      </FormGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
