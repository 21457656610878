import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { Condition } from 'api/odata/ODataFilter';
import { FormFieldsSection } from 'app/components/Forms/FormsLayout';
import {
  loadOfflineServiceData,
  UsageRelatedFilter,
} from 'app/components/pickers/AutocompletePickers/OfflineServiceTypePicker';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { BookQuickServices } from 'app/pages/OtherServiceDetails/Details/components/BookQuickServices';
import { ErrorServices } from 'app/pages/OtherServiceDetails/Details/slice/types';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { IUsageHistory } from 'app/pages/UsageHistoryPage/IUsageHistory';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { dateUtils } from 'utils/date-utils';

export interface EndUsageOfflineServicesProps {
  title: string;
  offlineIds: number[];
  usageId: number;
  onChange: (services: IOtherServices[]) => void;
  isProcessing: boolean;
  setError?: (error: ErrorServices) => void;
  reset?: boolean;
  usageData?: IUsageHistory;
}
export function EndUsageOfflineServices(props: EndUsageOfflineServicesProps) {
  const { title, setError, offlineIds, usageId, onChange, usageData } = props;
  const { t } = useTranslation();
  //const dispatch = useDispatch();
  const { newDate } = useSystemDate();
  //const { actions } = useOfflineServiceStateSlice();
  //const { actions: layoutActions } = useLayoutSlice();
  const [servicesSelected, setServicesSelected] = useAsyncExtendedState<
    IOtherServices[]
  >([]);
  //const { openPanel } = useSidePanelState(undefined, undefined, false, false);
  React.useEffect(() => {
    if (props.reset === true) {
      setServicesSelected([]);
    }
  }, [props.reset, setServicesSelected]);
  const changeOfflineServices = React.useCallback(
    (services: IOtherServices[], operation: 'add' | 'remove' | 'update') => {
      if (operation === 'add') {
        let addedServ = services.filter(
          f => !servicesSelected.some(s => s.ServiceTypeID === f.ServiceTypeID),
        );
        setServicesSelected([...servicesSelected, ...addedServ]);
        onChange([...servicesSelected, ...addedServ]);
      }
      if (operation === 'remove') {
        let otherServices = servicesSelected.filter(f => {
          return !services.some(a => a.ServiceTypeID === f.ServiceTypeID);
        });
        setServicesSelected(otherServices);
        onChange(otherServices);
      }
      if (operation === 'update') {
        const updated = servicesSelected.map(f => {
          let service = services.find(s => s.ServiceTypeID === f.ServiceTypeID);
          if (!!service && service !== null) {
            return Object.assign({}, f, service);
          } else {
            return f;
          }
        });
        setServicesSelected(updated);
        onChange(updated);
      }
    },
    [onChange, servicesSelected, setServicesSelected],
  );
  const initServices = async (): Promise<IOtherServices[]> => {
    return new Promise(async resolve => {
      resolve(servicesSelected);
    });
  };
  const fetchServiceType = async (
    searchTerm: string | null,
    predicates: (string | Condition<IOfflineServiceFilterDto>)[] | undefined,
  ) => {
    try {
      const data = await loadOfflineServiceData(
        [
          ...UsageRelatedFilter(
            offlineIds,
            servicesSelected.map(f => f.Id),
          ),
          ...(predicates ?? []),
        ],
        'base',
        [],
        undefined,
        'InventoryBatches',
      )(searchTerm, undefined);
      return data.value;
    } catch {
      return [];
    }
  };
  const prepareService = (serviceType: IOfflineServiceFilterDto) => {
    let batch = serviceType.InventoryBatchesEnabled
      ? !!serviceType.InventoryBatches &&
        serviceType.InventoryBatches.length > 0
        ? serviceType.InventoryBatches.filter(
            f =>
              (f.ExpirationDate === null || f.ExpirationDate > newDate()) &&
              (serviceType.NotLessThanZero ? f.Inventory > 0 : true) &&
              f.StokRenewed === true,
          ).sort((a: IInventoryBatchDto, b: IInventoryBatchDto) => {
            return b.Id - a.Id;
          })[0]
        : null
      : null;
    let data = {
      Id: 0,
      BookedBy: usageData?.UserName,
      UserDisplayName: usageData?.UserDisplayName,
      BudgetID: usageData?.BudgetId,
      Budget: usageData?.BudgetNumber,
      BudgetExperimentId: usageData?.BudgetExperimentId,
      BudgetExperimentName: usageData?.BudgetExperimentName,
      Quantity: serviceType.DefaultQuantity,
      DefaultQuantity: serviceType.DefaultQuantity,
      ExternalCustomerId: null,
      ExternalCustomerName: null,
      FundingType: null,
      IntQuantityOnly: serviceType.IntQuantityOnly,
      DiscountFactor: 1,
      InstituteProjectId: null,
      InstituteProjectName: null,
      PurchaseOrder: null,
      Reference: null,
      Mandatory: false,
      Remarks: (t(translations.UsageOfflineServiceDefaultRemarks) as string)
        .replace('{0}', String(usageId))
        .replace('{1}', ''),
      ReservationId: null,
      UsageId: usageId,
      ServiceDate: dateUtils.formatISO(dateUtils.dateOrStringToDate(newDate())),
      ServiceTypeID: serviceType.Id,
      ServiceType: serviceType.Name,
      ServiceGroupId: serviceType.ServiceGroupId,
      Units: serviceType.UnitTypeName,
      UserGroup: usageData?.UserGroup,
      UserGroupName: usageData?.UserGroupName,
      AllowToUser: serviceType.AllowToUser,
      BudgetsTurnedOn: serviceType.BudgetsTurnedOn,
      StaffOnly: serviceType.StaffOnly,
      TrackInventory: serviceType.TrackInventory,
      NotLessThanZero: serviceType.NotLessThanZero,
      InventoryBatchesEnabled: serviceType.InventoryBatchesEnabled,
      InventoryBatchId: batch === null ? null : batch.Id,
      InventoryBatchName: batch === null ? null : batch.Name,
      InventoryBatchAmount: batch === null ? null : batch.Inventory,
    } as IOtherServices;
    return data;
  };
  const createService = async (
    serviceType: IOfflineServiceFilterDto,
  ): Promise<IOtherServices | undefined> => {
    return new Promise(async (resolve, reject) => {
      let staticService = prepareService(serviceType);
      changeOfflineServices([staticService], 'add');
      resolve(staticService);
    });
  };
  const onRemoveService = async (service: IOtherServices) => {
    return new Promise(async (resolve, reject) => {
      changeOfflineServices([service], 'remove');
      resolve(service);
    });
  };
  const onUpdateService = (service: IOtherServices) => {
    return new Promise(async (resolve, reject) => {
      changeOfflineServices([service], 'update');
      resolve(service);
    });
  };
  // const handleOfflineServiceClick = React.useCallback(
  //   (service: IOtherServices) => {
  //     if (edit) {
  //       let otherProps = {
  //         initialService: service,
  //         saveCreatable: true,
  //         queryParams: {
  //           id: '' + service.Id ?? -1,
  //         },
  //       };
  //       openPanel({
  //         renderPageType: RenderPageType.OtherServiceDetails,
  //         renderPageProps: otherProps,
  //         expanded: false,
  //         useSidePanel: useSidePanel,
  //         isCover: useSidePanel,
  //       } as SidePanelOpenState);
  //     }
  //   },
  //   [edit, openPanel, useSidePanel],
  // );
  // const onOfflineServiceClick = React.useCallback(
  //   (service: IOtherServices) => {
  //     let otherProps = {
  //       initialService: Object.assign({}, service, {
  //         UserGroup: usageData?.UserGroup ?? null,
  //         UserGroupName: usageData?.UserGroupName ?? null,
  //         Budget: usageData?.BudgetNumber ?? null,
  //         BudgetID: usageData?.BudgetId ?? null,
  //         UserDisplayName: usageData?.UserDisplayName ?? null,
  //         BookedBy: usageData?.UserName ?? null,
  //       }),
  //       editCreatable: true,
  //       queryParams: {
  //         id: '' + service.Id ?? -1,
  //       },
  //     };
  //     dispatch(layoutActions.setNotSavedChanges(false));
  //     openPanel({
  //       renderPageType: RenderPageType.OtherServiceDetails,
  //       renderPageProps: otherProps,
  //       expanded: false,
  //       useSidePanel: false,
  //       isCover: false,
  //     } as SidePanelOpenState);
  //   },
  //   [
  //     dispatch,
  //     layoutActions,
  //     openPanel,
  //     usageData?.BudgetId,
  //     usageData?.BudgetNumber,
  //     usageData?.UserDisplayName,
  //     usageData?.UserGroup,
  //     usageData?.UserGroupName,
  //     usageData?.UserName,
  //   ],
  // );
  return (
    <FormFieldsSection titleSection={title} titleVariant="boldS">
      <BookQuickServices
        loadSeviceTypes={fetchServiceType}
        createServiceFromServiceType={createService}
        initServicesFunc={initServices}
        onServiceRemove={onRemoveService}
        onServiceUpdate={onUpdateService}
        //onServiceClick={onOfflineServiceClick}
        isEditMode={false}
        disabled={false}
        createdEditable={true}
        //onAddBatchClick={onAddBatchClick}
        setError={setError}
      />
    </FormFieldsSection>
  );
}
