import * as React from 'react';
import { Link } from 'app/components/BasicLinks/Link';
import { useTranslation } from 'react-i18next';
import { getReservationsLink } from '../../ReservationForm/getReservationsLink';
import { EditTrainingSessionLink } from 'app/pages/TrainingSessionsPage/components/EditTrainingSessionsLink';
import { translations } from 'locales/translations';
import { httpClient } from 'api/HttpClient';
import { ITrainingSessionSlotDto } from 'api/odata/generated/entities/ITrainingSessionSlotDto';
import { useAsyncGetWithEffect } from 'app/hooks/useAsyncGetOnMount';
import { Body } from 'app/components/Typography';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

export interface TrainingSignUpAdminAlertContentProps {
  trainingSessionId: number;
}
const SignUpContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & .link-content {
    display: flex;
    gap: 8px;
  }
`;
export function TrainingSignUpAdminAlertContent(
  props: TrainingSignUpAdminAlertContentProps,
) {
  const { trainingSessionId } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const attendees = useAsyncGetWithEffect<TrainingSessionInfo>(
    () => getSessionSlots(trainingSessionId),
    {
      Attendees: 0,
    },
    [trainingSessionId],
    setLoading,
  );

  // const [state, setState] = useAsyncExtendedState<
  //   TrainingSessionInfo | undefined
  // >(undefined);
  // const [fetchState, fetch] = usePromise(getSessionSlots);
  // const show = React.useMemo(() => {
  //   return (
  //     isAdmin && trainingSessionId !== undefined && trainingSessionId !== null
  //   );
  // }, [isAdmin, trainingSessionId]);

  // const bar = React.useMemo(() => {
  //   if (trainingSessionId === null) {
  //     return null;
  //   }
  //   if (trainingSessionId === undefined) {
  //     return null;
  //   }
  //   if (!show) {
  //     return null;
  //   }
  //   return trainingSessionId;
  // }, [show, trainingSessionId]);
  // React.useEffect(() => {
  //   if (trainingSessionId !== undefined && show) {
  //     setState(fetch(trainingSessionId));
  //   }
  // });

  // if (bar === undefined) {
  //   return null;
  // }

  return (
    <React.Fragment>
      <SignUpContent>
        <Body size="small" bold={true}>
          {t(translations.TrainingSessionsCountMessage, {
            count: attendees.Attendees,
          })}
        </Body>

        {/* <Caption>{t(translations.NotUsingThisReservation)}</Caption> */}
        <div className={'link-content'}>
          {loading === true && <CircularProgress size={14} />}
          {attendees.Attendees > 0 && (
            <Link
              to={getReservationsLink({
                TrainingSessionId: trainingSessionId,
              })}
            >
              {t(translations.ManageSignUps)}
            </Link>
          )}

          <EditTrainingSessionLink trainingSessionId={trainingSessionId}>
            {t(translations.EditTrainingSession)}
          </EditTrainingSessionLink>
        </div>
      </SignUpContent>
    </React.Fragment>
  );
}

type TrainingSessionInfo = Pick<ITrainingSessionSlotDto, 'Attendees'>;
async function getSessionSlots(trainingSesisonId: number) {
  const url = `/api/odata/v4/TrainingSessionSlots`;
  //const select: Array<keyof ITrainingSessionSlotDto> = ['Attendees'];
  try {
    const x = await httpClient.get(url, {
      $filter: `Id eq ${trainingSesisonId}`,
    });
    return { Attendees: x.value[0].Attendees } as TrainingSessionInfo;
  } catch (err) {
    return { Attendees: 0 } as TrainingSessionInfo;
  }
}
