/**
 *
 * InvoiceRowReferenceLinks
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../BasicTooltips/Tooltip';
import { Link } from 'app/components/ExternalLink';
import { ReservationLink } from 'app/pages/Actions/GlobalSelectedActions/OpenReservation';
import { ConsumableLink } from 'app/pages/Actions/GlobalSelectedActions/OpenConsumable';
import { UsageDetailsLink } from 'app/pages/Actions/GlobalSelectedActions/OpenUsageDetails';
import { SubscriberLink } from 'app/pages/Actions/GlobalSelectedActions/OpenSubscriber';
import { RequestLink } from 'app/pages/Actions/GlobalSelectedActions/OpenRequestDetails';

export interface InvoiceRowReferenceLinksProps {
  ReservationId: number | null;
  UsageId: number | null;
  OfflineServiceId: number | null;
  ServiceRequestId: number | null;
  SubscriptionId: number | null;
  useSidePanel: boolean;
}

export function InvoiceRowReferenceLinks(props: InvoiceRowReferenceLinksProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  let references: Array<{
    id: number | null;
    link: string;
    title: string;
    target?: string;
  }> = [];

  // if (props.ServiceRequestId)
  //   references.push({
  //     id: props.ServiceRequestId,
  //     link: GetServiceRequestDetailsPath(props.ServiceRequestId),
  //     title: t(translations.ServiceRequestDetails) as string,
  //     target: undefined,
  //   });

  // if (props.SubscriptionId)
  //   references.push({
  //     id: props.SubscriptionId,
  //     link: '/Invoices/Subscriptions/Edit.aspx?id=' + props.SubscriptionId,
  //     title: t(translations.SubscriptionDetails) as string,
  //     target: '_blank',
  //   });

  let result = references.map((item, i) => {
    return (
      <React.Fragment key={i}>
        <Tooltip title={item.title} arrow>
          <span>
            <Link href={item.link} target={item.target}>
              {item.id}
            </Link>
          </span>
        </Tooltip>
        <br />
      </React.Fragment>
    );
  });
  return (
    <>
      {props.UsageId !== null && (
        <UsageDetailsLink id={props.UsageId}>{props.UsageId}</UsageDetailsLink>
      )}
      {props.ReservationId !== null && (
        <ReservationLink
          id={props.ReservationId}
          useSidePanel={props.useSidePanel}
        >
          {props.ReservationId}
        </ReservationLink>
      )}
      {props.OfflineServiceId !== null && (
        <ConsumableLink
          id={props.OfflineServiceId}
          useSidePanel={props.useSidePanel}
        >
          {props.OfflineServiceId}
        </ConsumableLink>
      )}
      {props.SubscriptionId !== null && (
        <SubscriberLink
          id={props.SubscriptionId}
          useSidePanel={props.useSidePanel}
        >
          {props.SubscriptionId}
        </SubscriberLink>
      )}
      {props.ServiceRequestId !== null && (
        <RequestLink
          id={props.ServiceRequestId}
          useSidePanel={props.useSidePanel}
        >
          {props.ServiceRequestId}
        </RequestLink>
      )}
      {result}
    </>
  );
}
