import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Column } from 'react-table';
import { AppSettings } from 'types/AppSettings';
import { GlobalSettings } from 'utils/globalSettings';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { AssetLink } from '../AssetPopUp/OpenAssetDetails';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { IReservationWaitingListDto } from './IReservationWaitingListDto';
import UserNotificationsStateRenderer from 'app/components/BasicTable/CellRenderers/UserNotificationsStateRenderer';
import { UserProfileLink } from 'app/pages/UserDetails/OpenUserProfile';
import { getIsRowSimpleMode } from './SignUpWaitingListDetails/utils';
import { WaitingListSignUpLink } from '../Actions/GlobalSelectedActions/OpenWaitingList';
import React from 'react';

export const getColumns = (
  t: TFunction,
  appSettings?: AppSettings,
  globalSettings?: GlobalSettings,
  user?: AuthenticatedUser,
  usageEnabled?: boolean,
  useSidePanel?: boolean,
  openPanel?: (state: SidePanelOpenState) => void,
): Column<IReservationWaitingListDto>[] => {
  const columns = [
    {
      Header: t(translations.Id) as string,
      accessor: 'Id',
      Cell: useSidePanel
        ? ({ value, row: { original } }) => {
            const state = getIsRowSimpleMode(original, usageEnabled ?? false);
            return (
              <React.Fragment>
                {state.isSimpleMode ? (
                  <WaitingListSignUpLink
                    model={original}
                    useSidePanel={true}
                    openPanelWithCover={openPanel}
                  >
                    {(
                      t(translations.WhenToNotify_SimpleMode) as string
                    ).replace('{0}', state.hoursCount.toString())}
                  </WaitingListSignUpLink>
                ) : (
                  <WaitingListSignUpLink
                    model={original}
                    useSidePanel={true}
                    openPanelWithCover={openPanel}
                  >
                    {(
                      t(translations.WhenToNotify_AdvancedMode) as string
                    ).replace('{0}', state.notificationsLength.toString())}
                  </WaitingListSignUpLink>
                )}
              </React.Fragment>
            );
          }
        : UserNotificationsStateRenderer,
    },
    {
      Header: t(translations.WhenToNotify) as string,
      accessor: 'WhenToNotify',
      Cell: useSidePanel
        ? ({ value, row: { original } }) => {
            const state = getIsRowSimpleMode(original, usageEnabled ?? false);
            return (
              <React.Fragment>
                {state.isSimpleMode ? (
                  <WaitingListSignUpLink
                    model={original}
                    useSidePanel={true}
                    openPanelWithCover={openPanel}
                  >
                    {(
                      t(translations.WhenToNotify_SimpleMode) as string
                    ).replace('{0}', state.hoursCount.toString())}
                  </WaitingListSignUpLink>
                ) : (
                  <WaitingListSignUpLink
                    model={original}
                    useSidePanel={true}
                    openPanelWithCover={openPanel}
                  >
                    {(
                      t(translations.WhenToNotify_AdvancedMode) as string
                    ).replace('{0}', state.notificationsLength.toString())}
                  </WaitingListSignUpLink>
                )}
              </React.Fragment>
            );
          }
        : UserNotificationsStateRenderer,
    },
    {
      Header: t(translations.EquipmentName) as string,
      accessor: 'EquipmentName', // AssetName
      Cell: ({ value, row }) => (
        <AssetLink
          id={row.original.AssetId ?? undefined}
          serviceId={row.original.EquipmentID}
          serviceType={'Online'}
          openPanelWithCover={openPanel}
          useSidePanel={useSidePanel}
        >
          {value}
        </AssetLink>
      ),
    },
  ];
  if (user?.isAdmin() || user?.isGroupAdmin() || user?.isLabTech()) {
    columns.push({
      Header: t(translations.UserName) as string,
      accessor: 'UserDisplayName',
      Cell: ({ value, row }) => (
        <>
          <UserProfileLink
            userName={row.original.UserName}
            displayName={row.original.UserDisplayName}
          />
        </>
      ),
    });
  }
  return columns;
};
