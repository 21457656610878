import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { translations } from 'locales/translations';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import {
  FormListener,
  SubmittingHandler,
} from 'app/components/Forms/FormRender/FormRenderer';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { PricePeriodsDetailsState } from '../slice/types';
import { selectDetailsHasError } from '../slice/selectors';

import i18next from 'i18next';
import { FormServiceGroupPicker } from 'app/components/Forms/FormServiceGroupPicker';
import { FormPriceSheetMethodPicker } from 'app/components/Forms/FormPriceSheetMethodPicker';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { FormBookitDatePicker } from 'app/components/Forms/FormBookitDatePicker';
export interface PricePeriodsFormProps {
  onSubmit: (item: PricePeriodsDetailsState) => void;
  initialValues: PricePeriodsDetailsState;
  processing?: boolean;
  bindSubmitForm: any;
  isEdit: boolean;
  isAdmin: boolean;
  user?: AuthenticatedUser;
  innerFormRef: React.RefObject<FormikProps<PricePeriodsDetailsState>>;
  globalPriceSheetEnabled: boolean;
  priceEnabled: boolean;
}

export const PricePeriodsForm = React.memo(function PricePeriodsForm({
  onSubmit,
  processing,
  initialValues,
  bindSubmitForm,
  isEdit,
  isAdmin,
  user,
  globalPriceSheetEnabled,
  innerFormRef,
  priceEnabled,
}: PricePeriodsFormProps) {
  const { t } = useTranslation();
  const hasError = useSelector(selectDetailsHasError);
  const readonly = !isAdmin && isEdit;
  const [submitting, setSubmitting] = React.useState<boolean | undefined>(
    undefined,
  );
  const resetSubmitting = () => {
    setSubmitting(true);
  };
  const handleSubmit = (values: PricePeriodsDetailsState) => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(values);
    }
  };
  const { newDate } = useSystemDate();
  /// Local State ///
  const [serviceGroupChanged, setServiceGroupChanged] = React.useState(false);
  const [startChanged, setStartChanged] = React.useState(false);
  const [endChanged, setEndChanged] = React.useState(false);

  const handleFormChange = React.useCallback(
    (values: PricePeriodsDetailsState) => {
      if (serviceGroupChanged) {
        setServiceGroupChanged(false);
      }
      if (endChanged) {
        setEndChanged(false);
      }
      if (startChanged) {
        setStartChanged(false);
      }
    },
    [serviceGroupChanged, endChanged, startChanged],
  );

  /// useEffects
  React.useEffect(() => {
    if (innerFormRef.current) {
      innerFormRef.current.validateForm();
    }
  }, [innerFormRef]);

  const computedSchema = React.useMemo(() => {
    const pricPeriodsSchema: Yup.SchemaOf<PricePeriodsDetailsState> =
      Yup.object({
        Id: Yup.number().notRequired().default(-1),
        StartDate: Yup.date()
          .default(newDate())
          .label(i18next.t(translations.StartDate) as string)
          .required(i18next.t(translations._errStartTimeRequired) as string),
        EndDate: Yup.date()
          .nullable()
          .default(null)
          .label(i18next.t(translations.EndDate) as string)
          .notRequired()
          .min(
            Yup.ref('StartDate'),
            i18next.t(translations.err_EndTimeLowerThanStartTime) as string,
          ),
        ServiceGroup: Yup.mixed().nullable(),
        Method: Yup.mixed()
          .label(t(translations.PriceSheetMethod) as string)
          .nullable(),
      });
    if (!globalPriceSheetEnabled) {
      pricPeriodsSchema.fields.ServiceGroup.withMutation(schema =>
        schema.required(t(translations.err_ServiceGroupRequired) as string),
      );
    }
    if (!priceEnabled) {
      pricPeriodsSchema.fields.Method.withMutation(schema =>
        schema.required(
          t(translations.err_PriceSheetMethod_isrequired) as string,
        ),
      );
    }
    return pricPeriodsSchema;
  }, [globalPriceSheetEnabled, newDate, priceEnabled, t]);
  return (
    <>
      <Formik
        validationSchema={computedSchema}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        innerRef={innerFormRef}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          formikHelpers.validateForm(values).then(responseErrors => {
            if (!isEmpty(responseErrors)) {
              formikHelpers.setSubmitting(false);
              console.debug('errors', responseErrors);
            } else {
              console.debug('submitting', values);
              formikHelpers.setSubmitting(true);
              handleSubmit(values);
            }
          });
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          return (
            <React.Fragment>
              <StyledForm onSubmit={formik.handleSubmit}>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={['ServiceGroup']}
                />
                <SubmittingHandler
                  value={hasError === true && submitting === undefined}
                  resetSubmitting={resetSubmitting}
                />
                <FormLeftSection
                // warningNodes={warningMessages}
                // infoNodes={infoMessages}
                // errors={errorMessages}
                // successNodes={successMessages}
                >
                  <FormFieldsSection
                    titleSection={t(translations.PricePeriodDetails) as string}
                  >
                    {!globalPriceSheetEnabled && (
                      <FormRow fullRow={true}>
                        <FormServiceGroupPicker
                          fullWidth
                          id="ServiceGroupId"
                          name="ServiceGroup"
                          urlType="billableAdminUrl"
                          label={t(translations.ServiceGroup)}
                          placeholder={t(translations.PleaseSelectServiceGroup)}
                          disabled={formik.isSubmitting || readonly}
                          onChange={val => {
                            setServiceGroupChanged(true);
                          }}
                        />
                      </FormRow>
                    )}

                    <FormRow>
                      <FormBookitDatePicker
                        fullWidth
                        id="StartDateID"
                        name="StartDate"
                        clearable={false}
                        label={t(translations.StartDate) as string}
                        onChange={() => setStartChanged(true)}
                        disabled={formik.isSubmitting || readonly}
                      />
                    </FormRow>
                    <FormRow>
                      <FormBookitDatePicker
                        fullWidth
                        id="EndDateID"
                        name="EndDate"
                        info={
                          t(translations.PricePeriodsFormEndDate_info) as string
                        }
                        label={t(translations.EndDate) as string}
                        onChange={() => setEndChanged(true)}
                        disabled={formik.isSubmitting || readonly}
                        disableClearable={true}
                      />
                    </FormRow>
                    <FormRow fullRow>
                      <FormPriceSheetMethodPicker
                        fullWidth
                        id="MethodID"
                        name="Method"
                        label={t(translations.PriceSheetMethod)}
                        info={t(translations.PriceSheetMethod_info)}
                        disabled={
                          formik.isSubmitting || readonly || priceEnabled
                        }
                      />
                    </FormRow>
                  </FormFieldsSection>
                </FormLeftSection>
              </StyledForm>
            </React.Fragment>
          );
        }}
      </Formik>
    </>
  );
});
