import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.costCentersDetail || initialState;

export const selectCostCentersDetails = createSelector(
  [selectSlice],
  state => state,
);
//====================================================================
export const selectCreateCostCentersDetails = createSelector(
  [selectCostCentersDetails],
  state => state.createState.data,
);

export const selectCreateCostCentersDetailsCompleted = createSelector(
  [selectCostCentersDetails],
  state => state.createState.completed,
);

export const selectCreateCostCentersDetailsHasError = createSelector(
  [selectCostCentersDetails],
  state => state.createState.hasErrors,
);
//====================================================================
export const selectUpdateCostCentersDetails = createSelector(
  [selectCostCentersDetails],
  state => state.updateState.data,
);

export const selectUpdateCostCentersDetailsCompleted = createSelector(
  [selectCostCentersDetails],
  state => state.updateState.completed,
);
export const selectUpdateCostCentersDetailsHasError = createSelector(
  [selectCostCentersDetails],
  state => state.updateState.hasErrors,
);
//====================================================================
export const selectCostCentersDetailsProcessing = createSelector(
  [selectCostCentersDetails],
  state => state.processing,
);
