import { SiteMapNode } from 'types/SiteMapSettings';
import { MenuItemEntry } from '../types';
import { replaceIcon } from './replaceIcon';
import { isExternal } from './isExternal';
import { getLink } from './getLink';

/**
 * Converts SiteMapNode to MenuItemEntry used in the sidebar/menu
 * @param item
 * @param index
 * @param array
 * @returns
 */
export function toMenuItemEntry(
  item: SiteMapNode,
  index: number,
  array: Array<SiteMapNode>,
): MenuItemEntry {
  const link = getLink(item);
  return {
    id: item.Key ?? undefined,
    name: item.Title,
    link: link,
    icon: replaceIcon(item.icon),
    external: isExternal(link),
    selected: false,
    index: index,
  };
}
