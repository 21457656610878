import * as React from 'react';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { AllowedToRender, RenderTypes } from './renderTypes';

export function useAssetsPermissions() {
  const user = useSelector(selectAuthenticatedUser);

  const allowedToView = React.useCallback(
    (
      rows: Pick<
        IAssetDto,
        'ServiceGroupId' | 'ServiceTypeId' | 'ServiceId' | 'Availability'
      >[],
      render: RenderTypes,
    ): boolean => {
      if (!user) return false;
      return rows.every(f => AllowedToRender(user, render, f));
    },
    [user],
  );
  return {
    allowedToView,
  };
}
