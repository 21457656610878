/**
 *
 * AssetsPicker
 *
 */
import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import {
  AssetsPicker,
  avTypes,
} from '../pickers/MultiSelectPickers/AssetsPicker/index';

export const FormAssetsPicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<number>[] | null> &
  IFieldProps & {
    restrictByAvType?: avTypes;
    admin?: boolean;
    showOnlyActiveAssets?: boolean;
    withoutServiceGroups?: boolean;
  }) => {
  const [field, meta, helpers] = useField<Entity<number>[] | null>(props);
  return (
    <AssetsPicker
      predicates={props.predicates}
      name={props.name}
      onChipClick={props.onChipClick}
      placeholder={props.placeholder}
      label={label}
      onChange={value => {
        helpers.setValue(value, true);
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      onBlur={field?.onBlur}
      value={field.value ?? undefined}
      error={meta?.error !== undefined}
      variant="filled"
      restrictByAvType={props.restrictByAvType}
      admin={props.admin}
      showOnlyActiveAssets={props.showOnlyActiveAssets}
      disabled={props.disabled}
      helperText={meta.error}
      fullWidth={props.fullWidth}
      info={props.info}
      required={props.required}
      withoutServiceGroups={props.withoutServiceGroups}
    />
  );
};
