import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { onlineBudgetsStatusActions as actions } from '.';
import { appSettingsActions } from 'app/slice';
import { translations } from 'locales/translations';
import i18next from 'i18next';
import { httpClient } from 'api/HttpClient';
import { IInvoiceBudgetBalanceDto } from 'api/odata/generated/entities/IInvoiceBudgetBalanceDto';
import { IODataQueryResponse } from 'api/odata/IODataQueryResponse';
import { AxiosError } from 'axios';
import { selectEndDate } from './selectors';
import { SnackBarActionType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import { IPaymentPayload, PaymentApi } from 'api/PaymentApi';
import {
  ChargeOnlineBudgetResponse,
  IPayActionPayload,
  PaymentMethods,
} from './types';
import { toEntity } from 'utils/entity-utils';
import { IResponseType } from 'types/ResponseType';
import {
  BillingPreviewApi,
  UpdateModel,
} from 'app/pages/BillingPreviewPage/Actions/Apis';
import { getPeriodTypesEntity } from 'app/components/DatePeriods';
import { dateUtils } from 'utils/date-utils';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';

function* doGetTableData(action: PayloadAction<{ endDate: Date }>) {
  try {
    const response: IODataQueryResponse<IInvoiceBudgetBalanceDto> = yield call(
      httpClient.get,
      '/api/odata/v4/InvoiceBudgetBalances',
      {
        endDate: action.payload.endDate,
      },
    );
    yield put(actions.getTableData_Success(response.value));
  } catch (error: unknown) {
    yield put(
      appSettingsActions.addNotification({
        message: i18next.t(translations.errormessage),
        variant: 'error',
      }),
    );
    yield put(actions.getTableData_Error(Error));
  }
}

function* doGenerateInvoices(action: PayloadAction<{ endDate: Date }>) {
  try {
    const api = new BillingPreviewApi();
    const payload: UpdateModel = {
      Item: {
        ServiceGroupsCsv: '',
        UserGroupsCsv: '',
        PeriodType: getPeriodTypesEntity('CustomDate')?.Id,
        Start: dateUtils.formatISO(
          new Date(dateUtils.addYears(action.payload.endDate, -2)),
        ),
        End: dateUtils.formatISO(new Date(action.payload.endDate)),
        Remarks: '',
        BudgetIds: [],
        BudgetIncluded: true,
        invoiceBy: undefined,
        CoreInvoiceBy: '',
        InvoiceCreatedBy: '',
        InvoiceVat: '',
        FundingTypeId: undefined,
        CoreBy: 0,
        ShowZeroAmountInvoices: false,
        ShowInvoicePartialEvents: false,
      },
      Async: false,
      Recalculate: false,
    };

    const result: IResponseType = yield call(api.FormView1_UpdateItem, payload);
    for (const message of result.SuccessMessages) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'success',
          message: message,
        }),
      );
    }
    for (const message of result.WarningMessages) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'warning',
          message: message,
        }),
      );
    }
    for (const message of result.ErrorMessages) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'error',
          message: message,
        }),
      );
    }

    yield put(actions.generateInvoices_Success());
    yield put(actions.getTableData({ endDate: action.payload.endDate }));
  } catch {
    yield put(actions.generateInvoices_Error());
  }
}

function* doChargeOnlineBudgets(
  action: PayloadAction<IInvoiceBudgetBalanceDto[]>,
) {
  const endDate = yield select(selectEndDate);
  if (endDate == null) {
    return;
  }
  const url = '/api/odata/v4/InvoiceBudgetBalances';
  const processed: IInvoiceBudgetBalanceDto[] = [];
  const errorMessages: string[] = [];
  const messageKey = 'chargeOnlineBudgets';
  for (let index = 0; index < action.payload.length; index++) {
    const charge = action.payload[index];
    yield put(actions.chargeOnlineBudgets_SetCurrentRecord(charge));
    yield put(
      actions.chargeOnlineBudgets_SetStatus(
        (100 * (index + 1)) / action.payload.length,
      ),
    );
    try {
      const response: ChargeOnlineBudgetResponse = yield call(
        httpClient.post,
        url,
        charge,
        { params: { endDate: endDate } },
      );
      let success = response.SuccessTransactionsCount === 1;
      if (success) {
        processed.push(charge);
        yield put(
          appSettingsActions.addNotification({
            variant: 'success',
            key: messageKey,
            //todo: add to gres

            message: `Successfully processed ${charge.HostBudgetNumber} ${charge.ServiceGroup}`,
          }),
        );
      }

      for (const message of response.Messages) {
        errorMessages.push(
          `${charge.UserGroup} ${charge.Budget} ${charge.ServiceGroup}: ${message}`,
        );
        yield put(
          appSettingsActions.addNotification({
            variant: 'error',
            key: messageKey,
            message: message,
          }),
        );
      }
    } catch (error) {
      //todo: extract error message from error response
      const errorMessage = (error as AxiosError)?.response?.data?.error.message;
      yield put(actions.chargeOnlineBudgets_Error([errorMessage]));
      return;
    }
  }
  yield put(actions.chargeOnlineBudgets_SetCurrentRecord(undefined));
  yield put(actions.chargeOnlineBudgets_Success(processed));
  yield put(
    appSettingsActions.addNotification({
      key: messageKey,
      variant:
        processed.length === action.payload.length
          ? 'success'
          : processed.length === 0
          ? 'error'
          : 'warning',
      message: `Processed successfully ${processed.length} out of ${action.payload.length}`,
    }),
  );
  if (errorMessages.length === 0) {
    yield put(actions.chargeOnlineBudgets_SetDialogOpen(false));
  }
  if (errorMessages.length > 0) {
    yield put(actions.chargeOnlineBudgets_Error(errorMessages));
  }
  // refresh table only if any of the rows were processed successfully
  if (processed.length > 0) {
    // yield put(actions.getTableData({ endDate: endDate }));

    yield put(
      appSettingsActions.addNotification({
        key: 'updatedSuccess',
        message: i18next.t(translations.ProcessingFinished),
        variant: 'success',
      }),
    );
    yield put(layoutActions.setRefreshTable(true));
  }
}
function* doDownloadChargeOnlineBudgetsErrors(
  action: PayloadAction<string[] | undefined>,
) {
  try {
    if (action.payload !== undefined) {
      var fields = ['Errors'];
      const ExportToExcel = yield call(() => import('utils/ExportToExcel'));
      const x = new ExportToExcel.ExportToExcel('Errors');
      x.AddWorkSheetHeader(fields);
      x.AddWorkSheetData(action.payload.map(err => ({ errors: err })));
      yield call(x.ExportToExcel, 'errors', x.book);
    }
  } catch (error) {
    console.error(error);
  }
}
function* doPay(action: PayloadAction<IPayActionPayload>) {
  const paymentDate = new Date();
  const payments = action.payload.Rows.map(balanceRow =>
    JSON.parse(balanceRow.Invoices ?? '[]').map(invoice => {
      const payment: IPaymentPayload = {
        InvoiceId: invoice.Id ?? undefined,
        Method: {
          Id: action.payload.PaymentMethod,
          Name: PaymentMethods[action.payload.PaymentMethod],
        },
        Amount: invoice.Charges - invoice.Payments,
        Budget: toEntity(balanceRow.BudgetId, balanceRow.Budget),
        Date: paymentDate,
        ServiceGroup: toEntity(
          balanceRow.ServiceGroupId,
          balanceRow.ServiceGroup,
        ),
        ShouldCloseInvoice: true,
      };
      return payment;
    }),
  )
    .reduce((accumulator, value) => accumulator?.concat(value ?? []), [])
    ?.filter(payment => payment.Amount !== undefined && payment.Amount > 0);
  if (payments === undefined) {
    yield put(
      appSettingsActions.addNotification({
        variant: 'error',
        message: i18next.t(translations.AnErrorOccurred),
        actionType: SnackBarActionType.refreshTable,
      }),
    );
    return;
  }

  const messages: IResponseType[] = [];
  for (const [index, payment] of payments.entries()) {
    try {
      const result: IResponseType = yield call(PaymentApi.AddPayment, payment);
      yield put(actions.pay_setStatus((100 * (index + 1)) / payments.length));
      messages.push(result);
    } catch (error) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'error',
          message: (error as AxiosError).response?.data?.error?.message,
          actionType: SnackBarActionType.refreshTable,
        }),
      );
      yield put(actions.pay_Finish([]));
      return;
    }
  }
  yield put(actions.pay_Finish(messages));
  const success =
    messages.filter(
      message =>
        message.SuccessMessages.length > 0 &&
        message.ErrorMessages.length === 0,
    ).length === payments.length;
  if (success) {
    yield put(
      appSettingsActions.addNotification({
        variant: 'success',
        message: i18next.t(translations.PaymentCreatedSuccess),
      }),
    );
    yield put(layoutActions.setRefreshTable(true));
  }
}

function* doGenerateSelectedInvoices(
  action: PayloadAction<{
    endDate: Date;
    selected: IInvoiceBudgetBalanceDto[];
    chargeOnlineBudgets: boolean;
  }>,
) {
  const result: IResponseType = {
    SuccessMessages: [],
    ErrorMessages: [],
    WarningMessages: [],
  };
  try {
    for (const [index, item] of action.payload.selected.entries()) {
      yield put(
        actions.GenerateInvoicesAndChargeOnlineBudgets_SetCurrentRecord(item),
      );
      yield put(
        actions.GenerateInvoicesAndChargeOnlineBudgets_SetProgress(
          Math.floor((100 * index) / action.payload.selected.length),
        ),
      );
      const api = new BillingPreviewApi();
      const payload: UpdateModel = {
        Item: {
          ServiceGroupsCsv: item.ServiceGroupId.toString(),
          PeriodType: getPeriodTypesEntity('CustomDate')?.Id,
          Start: dateUtils.formatISO(
            new Date(dateUtils.addYears(action.payload.endDate, -2)),
          ),
          End: dateUtils.formatISO(new Date(action.payload.endDate)),
          Remarks: '',
          BudgetIds: [item.BudgetId],
          BudgetIncluded: true,
          invoiceBy: undefined,
          CoreInvoiceBy: '',
          InvoiceCreatedBy: '',
          InvoiceVat: '',
          FundingTypeId: undefined,
          CoreBy: 0,
          ShowZeroAmountInvoices: false,
          ShowInvoicePartialEvents: false,
        },
        Async: false,
        Recalculate: false,
      };

      const response: IResponseType = yield call(
        api.FormView1_UpdateItem,
        payload,
      );
      Object.keys(result).forEach(key =>
        response[key].forEach(message => result[key].push(message)),
      );
    }
  } catch {
    yield put(
      appSettingsActions.addNotification({
        variant: 'error',
        message: 'An error occurred',
      }),
    );
    yield put(actions.GenerateSelectedInvoices_Error());
    return;
  }
  yield put(actions.GenerateSelectedInvoices_Success());
  if (action.payload.chargeOnlineBudgets) {
    yield put(actions.chargeOnlineBudgets(action.payload.selected));
  }
}

export function* onlineBudgetsStatusSaga() {
  yield takeLatest(actions.getTableData.type, doGetTableData);
  yield takeLeading(actions.generateInvoices.type, doGenerateInvoices);
  yield takeLeading(actions.chargeOnlineBudgets.type, doChargeOnlineBudgets);
  yield takeLeading(actions.pay.type, doPay);
  yield takeLeading(
    actions.chargeOnlineBudgets_DownloadErrors,
    doDownloadChargeOnlineBudgetsErrors,
  );
  yield takeLeading(
    actions.GenerateInvoicesAndChargeOnlineBudgets.type,
    doGenerateSelectedInvoices,
  );
}
