import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';
import { SIDE_PANEL_WIDTH } from 'styles/constantVariables';

export const DropBoxWrapper = styled.div`
  &.drop-box-wrapper {
    width: ${SIDE_PANEL_WIDTH}px;
    &.short-view {
      width: 100%;
    }
    &.full-width {
      width: 100%;
    }
    &.flex-box {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    & .flex-box-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
    & .drop-box-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: ${bookitColors.grayscale.grayBorders};
      border-style: dashed;
      background-color: ${bookitColors.base.white};
      color: #bdbdbd;
      outline: none;
      transition: border 0.24s ease-in-out;
    }
    & .drop-box-content {
      overflow-y: auto;
    }
  }
`;
