import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { ISamplePlateDto } from 'api/odata/generated/entities/ISamplePlateDto';
import {
  ConvertModelToEntitySamplePlatesDetails,
  SamplePlatesDetailsQStringParameters,
  SamplePlatesDetailsState,
  SamplePlatesSidePanelState,
} from './types';
import { samplePlatesDetailsSaga } from './saga';

export const initialState: SamplePlatesSidePanelState = {
  processing: false,
  createState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
  updateState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
};

const slice = createSlice({
  name: 'samplePlatesDetail',
  initialState,
  reducers: {
    initSamplePlatesDetails(
      state,
      action: PayloadAction<SamplePlatesDetailsQStringParameters>,
    ) {
      state.processing = true;
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initUpdateSamplePlatesDetails(
      state,
      action: PayloadAction<SamplePlatesDetailsQStringParameters>,
    ) {
      state.processing = true;
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initSamplePlatesDetails_Success(
      state,
      action: PayloadAction<ISamplePlateDto>,
    ) {
      state.processing = false;
      state.createState.data = ConvertModelToEntitySamplePlatesDetails(
        action.payload,
      );
    },
    initUpdateSamplePlatesDetails_Success(
      state,
      action: PayloadAction<ISamplePlateDto>,
    ) {
      state.processing = false;
      state.updateState.data = ConvertModelToEntitySamplePlatesDetails(
        action.payload,
      );
    },
    initSamplePlatesDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    initUpdateSamplePlatesDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    //====================================================================
    resetUpdateSamplePlatesDetailsState(state, action: PayloadAction) {
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    resetCreateSamplePlatesDetailsState(state, action: PayloadAction) {
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    //====================================================================
    updateSamplePlatesDetails(
      state,
      action: PayloadAction<{
        current: SamplePlatesDetailsState;
        original: SamplePlatesDetailsState | undefined;
      }>,
    ) {
      state.updateState.processing = true;
      state.updateState.completed = undefined;
      state.updateState.hasErrors = undefined;
    },
    updateSamplePlatesDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.updateState.processing = false;
      state.updateState.hasErrors = action.payload.hasErrors;
      state.updateState.completed = true;
    },
    updateSamplePlatesDetails_Error(state, action: PayloadAction<any>) {
      state.updateState.processing = false;
      state.updateState.completed = true;
      state.updateState.hasErrors = true;
    },
    //====================================================================
    createSamplePlatesDetails(
      state,
      action: PayloadAction<SamplePlatesDetailsState>,
    ) {
      state.createState.processing = true;
      state.createState.completed = undefined;
      state.createState.hasErrors = undefined;
    },
    createSamplePlatesDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.createState.processing = false;
      state.createState.hasErrors = action.payload.hasErrors;
      state.createState.completed = true;
    },
    createSamplePlatesDetails_Error(state, action: PayloadAction<any>) {
      state.createState.processing = false;
      state.createState.completed = true;
      state.createState.hasErrors = true;
    },
  },
});

export const { actions: samplePlatesDetailsActions } = slice;

export const useSamplePlatesDetailsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: samplePlatesDetailsSaga });
  return { actions: slice.actions };
};
