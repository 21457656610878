/**
 *
 * AssetsPicker
 *
 */
import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import { Entity, Identifiable } from 'types/common';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { InputBaseComponentProps } from '@material-ui/core';
import {
  AutocompleteMultiPickerEx,
  AutocompleteMultiPickerExProps,
} from 'app/components/BasicPickers/AutocompleteMultiPickerEx';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { useSelector } from 'react-redux';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { AvailabilityTypes } from 'api/odata/generated/enums/AvailabilityTypes';
import { assertExhaustive } from 'utils/assertExhaustive';

export type avTypes =
  | 'bookable'
  | 'serviceRelated'
  | 'serviceGroup'
  | 'bookableAndnotbookable'
  | 'workOrderRelated'
  | 'all';

export interface AssetsPickerProps
  extends Omit<AutocompleteMultiPickerExProps<IAssetDto>, 'loadData'> {
  inputProps?: InputBaseComponentProps;
  restrictByAvType?: avTypes;
  /**
   * Option to filter admin permissions. If set to true will return only the assets authenticated user is an admin of
   */
  admin?: boolean;
  /**
   * Fetches only Active assets/instruments if this property is set to true
   */
  showOnlyActiveAssets?: boolean;
  withoutServiceGroups?: boolean;
}
export const bookable: Identifiable<number>[] = [
  //{ Id: AvailabilityTypes.ApprovalRequiredBillable as number },
  { Id: AvailabilityTypes.BookableWApproval as number },
  { Id: AvailabilityTypes.FreelyBookable as number },
  //{ Id: AvailabilityTypes.FreelyBookableBillable as number },
  { Id: AvailabilityTypes.LectureRoom as number },
  { Id: AvailabilityTypes.LoanableEquipment as number },
  { Id: AvailabilityTypes.TrainingProgram as number },
];
export const bookableAndnotbookable: Identifiable<number>[] = bookable.concat([
  { Id: AvailabilityTypes.AvailableNotBookable as number },
]);
export const servicesRelated: Identifiable<number>[] = [
  //{ Id: AvailabilityTypes.ApprovalRequiredBillable as number },
  { Id: AvailabilityTypes.BookableWApproval as number },
  { Id: AvailabilityTypes.FreelyBookable as number },
  //{ Id: AvailabilityTypes.FreelyBookableBillable as number },
  { Id: AvailabilityTypes.LectureRoom as number },
  { Id: AvailabilityTypes.LoanableEquipment as number },
  { Id: AvailabilityTypes.TrainingProgram as number },
  { Id: AvailabilityTypes.AvailableNotBookable as number },
  { Id: AvailabilityTypes.NotAvailable as number },
  { Id: AvailabilityTypes.RequestRelated as number },
  { Id: AvailabilityTypes.ServiceRelated as number },
];
export const initAssetsData = (
  initval: string | undefined,
  byService?: boolean,
  serviceTypes?: Identifiable<number>[],
  admin?: boolean,
) => {
  const url =
    admin === true
      ? '/api/odata/v4/AssetNames/AdminAssets'
      : '/api/odata/v4/AssetNames';
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    let ids = (
      initval.indexOf(',') !== -1
        ? initval.split(',')
        : initval.indexOf('|') !== -1
        ? initval.split('|')
        : [initval]
    ).map(item => {
      return { Id: parseInt(item) };
    }) as Identifiable<number>[];
    let filters: string | undefined = undefined;
    if (ids.length > 0) {
      const predicates: string | Condition<IAssetDto>[] = [];

      if (byService) {
        predicates.push(
          new Condition('ServiceId', ODataOperators.Includes, ids),
        );
        let types =
          !!serviceTypes && serviceTypes.length > 0
            ? serviceTypes
            : [{ Id: 1 }];

        predicates.push(
          new Condition('ServiceTypeId', ODataOperators.Includes, types),
        );
      } else {
        predicates.push(new Condition('Id', ODataOperators.Includes, ids));
      }
      filters = predicates.map(f => f.toString()).join(' and ');
    }

    const params: {
      $orderby: string;
      $filter?: string;
    } = {
      $orderby: 'Name asc',
      $filter: filters,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};

export function AssetsFilterForWorkOrder(
  orederType: IWorkOrderTypeDto | undefined,
): Condition<IAssetDto>[] {
  const predicates: Condition<IAssetDto>[] = [];
  if (orederType !== undefined) {
    if (orederType.Groups && orederType.Groups.length > 0) {
      predicates.push(
        new Condition<IAssetDto>(
          'ServiceGroupId',
          ODataOperators.Includes,
          orederType.Groups.map(f => f.Id),
        ),
      );
    }
    if (orederType.AssetCats && orederType.AssetCats.length > 0) {
      predicates.push(
        new Condition(
          'AssetCatId',
          ODataOperators.Includes,
          orederType.AssetCats.map(f => f.Id),
        ),
      );
    }
  }

  predicates.push(
    new Condition('ServiceTypeId', ODataOperators.Includes, [
      { Id: 1 },
      { Id: 2 },
      // { Id: 3 },
    ]),
  );
  if (predicates.length > 0) {
    return predicates;
  } else {
    return [];
  }
}
export function AssetsFilterForCalibrationWorkOrder(
  orederType: IWorkOrderTypeDto | undefined,
  selectedIds: number[],
): Condition<IAssetDto>[] {
  const predicates: Condition<IAssetDto>[] = [];
  if (orederType !== undefined) {
    if (orederType.Groups && orederType.Groups.length > 0) {
      predicates.push(
        new Condition<IAssetDto>(
          'ServiceGroupId',
          ODataOperators.Includes,
          orederType.Groups,
        ),
      );
    }
    if (orederType.AssetCats && orederType.AssetCats.length > 0) {
      predicates.push(
        new Condition(
          'AssetCatId',
          ODataOperators.Includes,
          orederType.AssetCats,
        ),
      );
    }
  }
  if (selectedIds.length > 0) {
    predicates.push(
      new Condition<IAssetDto>('Id', ODataOperators.Excludes, selectedIds),
    );
  }

  predicates.push(
    new Condition('ServiceTypeId', ODataOperators.Includes, [
      { Id: 1 },
      { Id: 2 },
      { Id: 3 },
    ]),
  );
  if (predicates.length > 0) {
    return predicates;
  } else {
    return [];
  }
}
export function AssetsFilterForSavedView(
  topServiceGroups: Entity<number>[] | undefined,
): Condition<IAssetDto>[] {
  const predicates: Condition<IAssetDto>[] = [];
  if (topServiceGroups !== undefined) {
    if (topServiceGroups.length > 0) {
      predicates.push(
        new Condition<IAssetDto>(
          'ServiceGroupId',
          ODataOperators.Includes,
          topServiceGroups,
        ),
      );
    }
  }
  predicates.push(
    new Condition('ServiceTypeId', ODataOperators.Includes, [
      { Id: 1 },
      //{ Id: 2 },
      //{ Id: 3 },
    ]),
  );
  if (predicates.length > 0) {
    return predicates;
  } else {
    return [];
  }
}
const loadData = (
  predicates: (string | Condition<IAssetDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
  admin?: boolean,
  withoutServiceGroups?: boolean,
) => {
  const url =
    admin === true
      ? '/api/odata/v4/AssetNames/AdminAssets'
      : '/api/odata/v4/AssetNames';
  return getAutoCompleteLoadDataFn<IAssetDto>({
    url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: [
      'Id',
      'Name',
      'AssetCatId',
      'Availability',
      // BookableAsset is not mapped (AssetNamesController)
      //      'BookableAsset',
      'Image',
      'ServiceGroupId',
      'ServiceId',
      'ServiceTypeId',
      'ServiceGroupName',
      'ServiceGroupAssetId',
      'Color',
    ],
    globalServiceGroupFilter:
      (withoutServiceGroups === undefined || withoutServiceGroups === false) &&
      !!globalServiceGroupFilter &&
      globalServiceGroupFilter.length > 0
        ? [
            new Condition<IAssetDto>(
              'ServiceGroupId',
              ODataOperators.Includes,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
  });
};
export function AssetsPicker({
  label,
  defaultValue,
  placeholder,
  className,
  required,
  helperText,
  onChange,
  variant,
  info,
  id,
  value,
  predicates,
  disabled,
  inputProps,
  restrictByAvType,
  showOnlyActiveAssets,
  admin,
  withoutServiceGroups,
  ...props
}: AssetsPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);

  let avFilter =
    restrictByAvType === undefined ? undefined : getAvFilter(restrictByAvType);

  let filters =
    avFilter !== undefined
      ? predicates !== undefined
        ? [...predicates, avFilter]
        : [avFilter]
      : predicates;
  if (showOnlyActiveAssets === true) {
    filters?.push(
      new Condition<IAssetDto>('Active', ODataOperators.Equals, true),
    );
  }
  const innerLoadData = loadData(
    filters,
    globalServiceGroupFilter || [],
    admin,
    withoutServiceGroups,
  );

  return (
    <AutocompleteMultiPickerEx
      variant={variant}
      loadData={innerLoadData}
      onChange={onChange}
      disabled={disabled}
      info={info}
      id={id || 'assets-multi-picker'}
      helperText={helperText}
      required={required}
      className={className}
      onBlur={props.onBlur}
      placeholder={placeholder}
      defaultValue={defaultValue ?? []}
      label={label}
      value={value ?? []}
      ariaLabel={'Assets search'}
      {...props}
      error={props.error}
    />
  );
}
export function ServiceGroupAssetsPicker(
  props: Omit<AssetsPickerProps, 'restrictByAvType'>,
) {
  return <AssetsPicker restrictByAvType="serviceGroup" {...props} />;
}
export function InstrumentAssetsPicker(
  props: Omit<AssetsPickerProps, 'restrictByAvType'>,
) {
  return <AssetsPicker restrictByAvType="bookable" {...props} />;
}
export function getAvFilter(restrictByAvType: avTypes) {
  switch (restrictByAvType) {
    case 'bookable':
      return `(${new Condition(
        'Availability/Id',
        ODataOperators.Includes,
        bookable,
      ).toString()})`;
    case 'bookableAndnotbookable':
      return `(${new Condition(
        'Availability/Id',
        ODataOperators.Includes,
        bookableAndnotbookable,
      ).toString()})`;
    case 'serviceRelated':
      return `(${new Condition(
        'Availability/Id',
        ODataOperators.Includes,
        servicesRelated,
      ).toString()})`;
    case 'workOrderRelated':
      return `(${new Condition(
        'Availability/Id',
        ODataOperators.Includes,
        servicesRelated.filter(
          f => f.Id !== (AvailabilityTypes.RequestRelated as number),
        ),
      ).toString()})`;
    case 'serviceGroup':
      return `(${new Condition(
        'Availability/Id',
        ODataOperators.Equals,
        AvailabilityTypes.ServiceGroup,
      ).toString()})`;
    case 'all':
      return undefined;
    default:
      assertExhaustive(restrictByAvType);
  }
}
