import { ODataOperators } from 'api/odata/ODataFilter';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import {
  URLSearchParamsCI,
  arrayToQueryString,
  getNumberEntityArray,
  initMultiPickerValue,
  GetRawValue,
  getStringUnionArrayOrUndefined,
} from 'app/components/BasicTable/types/FilterParam';
import {
  ServiceRequestStatusMultiPicker,
  initServiceRequestStatusMultiDataBySelectOrder,
} from 'app/components/pickers/AutocompletePickers/ServiceRequestStatusMultiPicker';
import { translations } from 'locales/translations';
import { PrioritiesUnionArray } from 'api/odata/generated/enums/Priorities';
import { PriorityMultiFilter } from 'app/components/BasicTable/Filters/PriorityFilter';
import { IServiceRequestTicketDto } from 'api/odata/generated/entities/IServiceRequestTicketDto';
//import { IServiceRequestDto } from 'api/odata/generated/entities/IServiceRequestDto';

export const StatusFilterId = 'Status';
export const PriorityFilterId = 'Priority';
export const GetFilters: GetPageFilters<
  IServiceRequestTicketDto /* IServiceRequestDto */
> = (t, appSettings, user, settings) => search => {
  const params = new URLSearchParamsCI(search);
  const result: IFilterSettings<IServiceRequestTicketDto /* IServiceRequestDto */>[] =
    [
      {
        id: StatusFilterId,
        fieldName: 'Status',
        queryStringSerializer: arrayToQueryString('RequestStatusInclude'),
        displayNameKey: translations.IncludeStatus,
        operator: ODataOperators.Includes,
        value: getNumberEntityArray(params, 'RequestStatusInclude'),
        component: ServiceRequestStatusMultiPicker,
        initData: initMultiPickerValue(
          initServiceRequestStatusMultiDataBySelectOrder,
        ),
        urlKey: 'RequestStatusInclude',
      },
      {
        id: PriorityFilterId,
        fieldName: 'Priority',
        displayNameKey: translations.Priority,
        operator: ODataOperators.Includes,
        queryStringSerializer: arrayToQueryString('Priority'),
        value: getStringUnionArrayOrUndefined(
          GetRawValue(params, 'Priority'),
          PrioritiesUnionArray,
        ),
        component: PriorityMultiFilter,
        urlKey: 'Priority',
      },
    ];
  return result;
};
