import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ConvertEntityToModelCalendarMessagesDetails,
  CalendarMessagesDetailsQStringParameters,
  CalendarMessagesDetailsResponse,
  CalendarMessagesDetailsState,
} from './types';
import { calendarMessagesDetailsActions as actions } from '.';
import { appSettingsActions } from 'app/slice';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { AxiosError } from 'axios';
import { ICalendarMessagesDto } from 'app/pages/CalendarMessages/CalendarMessagesPage/ICalendarMessagesDto';
import { CalendarMessagesApi } from 'api/CalendarMessagesApi';
import { CalendarMessagesDetailsProps } from '..';

function* doInitCreate(
  action: PayloadAction<CalendarMessagesDetailsQStringParameters>,
) {
  try {
    const data = yield call(CalendarMessagesApi.initCreateData, action.payload);
    yield put(actions.initCalendarMessagesDetails_Success(data));
  } catch (error: unknown) {
    yield put(actions.initCalendarMessagesDetails_Error(error));
  }
}

function* doInitUpdate(
  action: PayloadAction<CalendarMessagesDetailsQStringParameters>,
) {
  try {
    const data = yield call(CalendarMessagesApi.initUpdateData, action.payload);
    yield put(actions.initUpdateCalendarMessagesDetails_Success(data));
  } catch (error: unknown) {
    yield put(actions.initUpdateCalendarMessagesDetails_Error(Error));
  }
}

function* doCreate(action: PayloadAction<CalendarMessagesDetailsState>) {
  const httpPayloads: ICalendarMessagesDto =
    ConvertEntityToModelCalendarMessagesDetails(action.payload, undefined);
  try {
    const result = yield call(
      CalendarMessagesApi.insertCalendarMessagesDetails,
      httpPayloads,
    );
    let response: CalendarMessagesDetailsResponse =
      result as CalendarMessagesDetailsResponse;

    if (response.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.ErrorMessages.map(item => {
            return {
              key: 'calendarMessagesInsert',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'calendarMessagesInsert',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      if (response.Id) {
        yield put(
          appSettingsActions.addNotification({
            key: 'calendarMessagesInsert',
            message: response.SuccessMessages[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id,
              created: true,
              itemName: i18next.t(translations.menu_CalendarMessages),
              detailsType: RenderPageType.CalendarMessagesDetails,
              detailsTypeProps: {
                queryParams: {
                  id: '' + response.Id,
                } as CalendarMessagesDetailsQStringParameters,
                useSidePanel: true,
              } as CalendarMessagesDetailsProps,
            },
            variant: 'success',
          }),
        );
        yield put(layoutActions.setRefreshTable(true));
      } else {
        yield put(
          appSettingsActions.addNotification({
            key: 'calendarMessagesInsertSuccess',
            message: response.SuccessMessages[0],
            variant: 'success',
          }),
        );
        yield put(layoutActions.setRefreshTable(true));
      }
    }

    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.createCalendarMessagesDetails_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'calendarMessagesInsert',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createCalendarMessagesDetails_Error(Error));
  }
}

function* doUpdate(
  action: PayloadAction<{
    current: CalendarMessagesDetailsState;
    original: CalendarMessagesDetailsState | undefined;
  }>,
) {
  const httpPayloads: ICalendarMessagesDto =
    ConvertEntityToModelCalendarMessagesDetails(
      action.payload.current,
      action.payload.original,
    );
  try {
    const results = yield call(
      CalendarMessagesApi.updateCalendarMessagesDetails,
      httpPayloads,
    );
    let response = results as CalendarMessagesDetailsResponse;
    let respErrors = response.ErrorMessages;
    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: 'calendarMessagesUpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'calendarMessagesUpdateWarn',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotification({
          key: 'calendarMessagesUpdate',
          message: response.SuccessMessages[0],
          messageType: SnackBarMessageType.openSidepanelDetails,
          messageTypeProps: {
            Id: response.Id,
            created: false,
            itemName: i18next.t(translations.menu_CalendarMessages),
            detailsType: RenderPageType.CalendarMessagesDetails,
            detailsTypeProps: {
              queryParams: {
                id: '' + response.Id,
              } as CalendarMessagesDetailsQStringParameters,
              useSidePanel: true,
            } as CalendarMessagesDetailsProps,
          },
          variant: 'success',
        }),
      );
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.updateCalendarMessagesDetails_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'calendarMessagesUpdateErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateCalendarMessagesDetails_Error(Error));
  }
}

export function* calendarMessagesDetailsSaga() {
  yield takeLatest(actions.initCalendarMessagesDetails.type, doInitCreate);
  yield takeLatest(
    actions.initUpdateCalendarMessagesDetails.type,
    doInitUpdate,
  );
  yield takeLatest(actions.createCalendarMessagesDetails.type, doCreate);
  yield takeLatest(actions.updateCalendarMessagesDetails.type, doUpdate);
}
