import { httpClient } from './HttpClient';
import { ISettingModel } from 'api/odata/generated/entities/ISettingModel';

const SYSTEM_SETTINGS_URL = '/api/odata/v4/SystemSettings';
export const SettingsApi = {
  getAlowedSystemSetting: () => {
    return httpClient.get(SYSTEM_SETTINGS_URL);
  },
  getLoginSystemSettings: () => {
    const url = '/api/v3/appsettings/GetLoginSettings';
    return httpClient.get(url);
  },
  getSingleSystemSetting: (id: number) => {
    return httpClient.get(SYSTEM_SETTINGS_URL, { key: id });
  },
  setSystemSetting: (setting: ISettingModel) => {
    return httpClient.post(SYSTEM_SETTINGS_URL, setting);
  },
};
