/**
 *
 * PriorityFilter
 *
 */

import { PrioritiesUnion } from 'api/odata/generated/enums/Priorities';
import {
  PriorityMultiPicker,
  PriorityPicker,
} from 'app/components/pickers/StaticOptionsPickers/PriorityPicker';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { IFilterComponentProps } from '..';

export const PriorityFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: PrioritiesUnion | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <PriorityPicker
      id={props.id}
      name={props.name}
      label={props.label}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
      variant="filled"
      onChange={handleOnChange}
      value={props.value}
      fullWidth={props.fullWidth}
    />
  );
};

export const PriorityMultiFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: PrioritiesUnion[]) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <PriorityMultiPicker
      id={props.id}
      name={props.name}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
      size="small"
      onChange={handleOnChange}
      value={props.value}
      fullWidth={props.fullWidth}
    />
  );
};
