import * as React from 'react';
import { useTheme } from '@material-ui/core';
import { ReactActions } from 'app/components/ReactActions';
import { ActionRenderer } from '../PageActions/ActionRender';
import styled from 'styled-components';
import { Skeleton } from '@material-ui/lab';
import { bookitColors } from 'styles/colors/bookitColors';

export interface TopButtonsPanelProps {
  rightTopActions?: ActionRenderer[];
  leftTopActions?: ActionRenderer[];
  maxLeftTopButtonsLength?: number;
  showShortView: boolean;
  onSubmit?: () => void;
  expandPanel: () => void;
  leftActionsLoading?: boolean;
}

const TopButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingInline: theme.spacing(2, 0),
  '.leftButtons': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    gap: theme.spacing(1),
    '& .skeleton': {
      backgroundColor: bookitColors.grayscale.graySelection,
    },
  },
}));
export function TopButtonsPanel(props: TopButtonsPanelProps) {
  const {
    leftTopActions,
    showShortView,
    onSubmit,
    maxLeftTopButtonsLength = 2,
    leftActionsLoading,
  } = props;
  const theme = useTheme();
  return (
    <TopButtonsContainer theme={theme}>
      <div className={'leftButtons'}>
        <React.Fragment>
          {leftActionsLoading &&
            (!leftTopActions ||
              (!!leftTopActions && leftTopActions?.length === 0)) && (
              <React.Fragment>
                <Skeleton width={'80px'} height={'40px'} className="skeleton" />
                <Skeleton width={'80px'} height={'40px'} className="skeleton" />
                <Skeleton width={'40px'} height={'40px'} className="skeleton" />
              </React.Fragment>
            )}
          {!!leftTopActions && leftTopActions?.length > 0 && (
            <React.Fragment>
              {showShortView ? (
                <React.Fragment>
                  {leftTopActions.length > maxLeftTopButtonsLength ? (
                    <React.Fragment>
                      {leftTopActions
                        .filter((f, i) => i < maxLeftTopButtonsLength)
                        .map((f, i) => (
                          <span key={'leftAction' + i}>{f(onSubmit)}</span>
                        ))}
                      {/* <span key="firstAct">{leftTopActions[0](onSubmit)}</span>
                      <span key="secondAct">{leftTopActions[1](onSubmit)}</span> */}
                      <ReactActions
                        id={`left_top_actions`}
                        iconVariant="white"
                        size="small"
                        keepOpen={false}
                        items={leftTopActions
                          .filter(
                            (item, index) => index >= maxLeftTopButtonsLength,
                          )
                          .map((item, index) => (
                            <span key={index}>{item(onSubmit)}</span>
                          ))}
                      ></ReactActions>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {leftTopActions?.map((item, index) => (
                        <span key={index}>{item(onSubmit)}</span>
                      ))}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {leftTopActions?.map((item, index) => (
                    <span key={index}>{item(onSubmit)}</span>
                  ))}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
    </TopButtonsContainer>
  );
}
