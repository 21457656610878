import {
  InputAdornment,
  InputBaseComponentProps,
  SelectProps as MuiSelectProps,
} from '@material-ui/core';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import React from 'react';

import BaseTextField from '../BaseTextField';

export interface SelectProps {
  fullWidth?: boolean;
  info?: string;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  autoFocus?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  label?: React.ReactNode;
  SelectProps?: MuiSelectProps;
  className?: string;
  children?: React.ReactNode;
  inputProps?: InputBaseComponentProps;
  id?: string;
  value?: unknown;
  multiple?: boolean;
  placeholder?: string;
  ariaLabel?: string;
  renderValue?: (value: MuiSelectProps['value']) => React.ReactNode;
  size?: 'small' | 'medium' | 'large' | 'xl';
}

export function Select({
  info,
  startIcon,
  endIcon,
  onBlur,
  onChange,
  onFocus,
  autoFocus,
  variant,
  inputProps,
  className,
  SelectProps,
  children,
  id,
  placeholder,
  ariaLabel,
  multiple,
  label,
  value,
  renderValue,
  size,
  ...props
}: SelectProps) {
  const startIconComponent = startIcon && (
    <InputAdornment position="start">{startIcon}</InputAdornment>
  );
  const classNames: string[] = [];
  if (className) classNames.push(className);
  if (startIcon) classNames.push('startIcon');
  if (endIcon) classNames.push('endIcon');

  const endIconComponent = (
    <InputAdornment position="end">{endIcon}</InputAdornment>
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = e => {
    setAnchorEl(e.currentTarget);
  };
  const ITEM_HEIGHT = 56;
  const ITEM_PADDING_TOP = 16;
  return (
    <BaseTextField
      select={true}
      onClick={e => {
        if (!Boolean(anchorEl)) {
          handleOpen(e);
        }
      }}
      size={size}
      SelectProps={{
        multiple: multiple,
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          autoFocus: false,
          getContentAnchorEl: null,
          anchorEl: anchorEl,
          open: Boolean(anchorEl),
          // disablePortal: true,
          onClose: (ev, r) => handleClose(),
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
            },
          },
        },
        displayEmpty: true,
        ...SelectProps,
      }}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      autoFocus={autoFocus}
      variant={variant || 'standard'}
      placeholder={placeholder}
      id={id || 'simple-select'}
      inputProps={{
        ...inputProps,
        'aria-label': `${
          label ? undefined : ariaLabel ? ariaLabel : 'search input'
        }`,
      }}
      InputProps={{
        startAdornment: startIcon && startIconComponent,
        endAdornment:
          (info && <InfoIcon title={info} />) || (endIcon && endIconComponent),
      }}
      className={classNames.length > 0 ? classNames.join(' ') : undefined}
      {...props}
    >
      {/* {placeholder && (
        <MenuItem disabled value="">
          <SmallBody1>{placeholder}</SmallBody1>
        </MenuItem>
      )} */}
      {children}
    </BaseTextField>
  );

  //   return (
  //     <FormControl
  //       variant={props.variant || 'standard'}
  //       fullWidth={props.fullWidth}
  //     >
  //       {/* {props.label && (
  //         <InputLabel id={props.labelId ? props.labelId : 'simple-select'}>
  //           {props.label}
  //         </InputLabel>
  //       )} */}

  //       <MuiSelect
  //         input={
  //           props.variant === 'filled' ? (
  //             <FilledInput fullWidth={props.fullWidth} />
  //           ) : (
  //             <StandardInput fullWidth={props.fullWidth} />
  //           )
  //         }
  //         labelId={props.labelId}
  //         label={props.label}
  //         MenuProps={{
  //           anchorOrigin: {
  //             vertical: 'bottom',
  //             horizontal: 'left',
  //           },
  //           transformOrigin: {
  //             vertical: 'top',
  //             horizontal: 'left',
  //           },
  //           getContentAnchorEl: null,
  //           ...props.MenuProps,
  //         }}
  //         {...props}
  //       >
  //         {props.children}
  //       </MuiSelect>
  //     </FormControl>
  //   );
}
