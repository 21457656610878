import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { selectPublicUrl } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { buildURL } from 'utils/url-utils';
import { Link as MuiLink } from '@material-ui/core';
import { OfflineTypeRefsQueryParams } from 'app/pages/OfflineTypeReferencesDetails/Details/slice/types';
import { OfflineTypeReferencesDetailsProps } from 'app/pages/OfflineTypeReferencesDetails/Details';

export const OfflineTypeRefsLink = (props: {
  id?: number;
  children: React.ReactNode;
  refId?: number;
  refTypeId?: number;
  title?: string;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
  onCloseRefs?: () => void;
}) => {
  const { t } = useTranslation();
  const openDetails = useOpenOfflineRefsPopUp();
  const getLink = useOfflineRefsLinkUrl();
  const link = getLink({
    id: String(props.id),
    refId: String(props.refId),
    refTypeId: String(props.refTypeId),
  });
  const open = React.useCallback(() => {
    openDetails(
      {
        id: props.id,
        useSidePanel: props.useSidePanel,
        refId: props.refId,
        refTypeId: props.refTypeId,
      },
      props.openPanelWithCover,
      props.onCloseRefs,
    );
  }, [
    openDetails,
    props.id,
    props.onCloseRefs,
    props.openPanelWithCover,
    props.refId,
    props.refTypeId,
    props.useSidePanel,
  ]);
  return (
    <Tooltip
      title={
        props.title ??
        (!!props.id
          ? t(translations.ClickToSeeDetails)
          : t(translations.ClickToCreateNewRecord))
      }
    >
      <MuiLink
        onClick={event => {
          if (!!link) {
            if (!event.ctrlKey) {
              event.preventDefault();
              open();
            }
          } else {
            open();
          }
        }}
        color="textPrimary"
        href={link}
      >
        {props.children}
      </MuiLink>
    </Tooltip>
  );
};

export const useOpenOfflineRefsPopUp = () => {
  const { openPanel } = useSidePanelState();
  return React.useCallback(
    (
      props: {
        id?: number;
        useSidePanel?: boolean;
        refId?: number;
        refTypeId?: number;
      },
      openPanelWithCover?: (state: SidePanelOpenState) => void,
      onCloseRefs?: () => void,
    ) => {
      const params = {
        id: String(props.id),
        refId: String(props.refId),
        refTypeId: String(props.refTypeId),
      } as OfflineTypeRefsQueryParams;
      let resProps = {
        queryParams: params,
        useSidePanel: true,
        onCloseRefs: onCloseRefs,
      } as OfflineTypeReferencesDetailsProps;

      const panelState = {
        renderPageType: RenderPageType.OfflineTypeRefDetails,
        renderPageProps: resProps,
        expanded: false,
        useSidePanel: props.useSidePanel,
        isCover: props.useSidePanel,
      } as SidePanelOpenState;
      if (!!openPanelWithCover) {
        openPanelWithCover(panelState);
      } else {
        openPanel({
          renderPageType: RenderPageType.OfflineTypeRefDetails,
          renderPageProps: resProps,
          expanded: false,
          useSidePanel: false,
          isCover: false,
        } as SidePanelOpenState);
      }
    },
    [openPanel],
  );
};
export const useOfflineRefsLinkUrl = () => {
  const publicUrl = useSelector(selectPublicUrl);

  return React.useCallback(
    (params: OfflineTypeRefsQueryParams) => {
      return buildURL(publicUrl + 'ServiceRelatedRefs/details', params);
    },
    [publicUrl],
  );
};
