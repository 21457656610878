import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';
import clsx from 'clsx';
import React from 'react';

export interface BadgeCounterProps {
  count?: number;
  customCount?: React.ReactNode;
}
const Badge = styled('div')(({ theme }) => ({
  '&.badgeRoot': {
    display: 'flex',
    minWidth: '16px',
    width: 'auto',
    height: '16px',
    //padding: '1.5px 0px 2px 0px',
    padding: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    background: bookitColors.grayscale.grayComponentsLight,
    '&.hasValue': {
      background: bookitColors.primary.components,
    },
    '& .badgeText': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: '1 0 0',
      alignSelf: 'stretch',
      color: bookitColors.base.white,
      textAlign: 'center',
      /* Bkt/Tips Regular */
      fontFamily: `'Lato', sans-serif`,
      fontSize: '10px',
      fontStyle: 'normal',
      lineHeight: '141%' /* 19.2px */,
      fontWeight: 400,
      letterSpacing: '0.2px',
      paddingInline: '4px',
    },
  },
}));
export function BadgeCounter(props: BadgeCounterProps) {
  const { count, customCount } = props;
  const [value, setValue] = React.useState<number | undefined>(count);
  React.useEffect(() => {
    setValue(count);
  }, [count]);
  return (
    <Badge
      className={clsx('badgeRoot', {
        hasValue: (!!value && value > 0) || !!customCount,
      })}
    >
      <span className="badgeText">
        {!!customCount ? customCount : value ?? 0}
      </span>
    </Badge>
  );
}
