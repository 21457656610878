import { EditorProps } from '../../EditableCell';
import { Timestamp } from 'types/Timestamp';
import { dateUtils } from 'utils/date-utils';
import { omit } from 'lodash';
import { TextField } from 'app/components/TextField';
import { BookitDatePicker } from 'app/components/BookitDatePicker';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';

/* Date */
// 'Date':
export function DateField({
  value,
  onBlur,
  parentValue,
  onChange,
  info,
  autoFocus,
  ...props
}: EditorProps<Timestamp | null>) {
  const handleChange = value => {
    if (value === null || value === undefined || value === '') {
      onChange?.(null);
    }
    onChange?.(dateUtils.formatISO(value, { representation: 'date' }));
  };
  return (
    <>
      <BookitDatePicker
        TextFieldComponent={TextField}
        inputVariant={'standard'}
        value={dateUtils.tryParse(value) ?? null}
        onChange={handleChange}
        helperText={undefined}
        info={info}
        variant="date"
        autoFocus={autoFocus}
        endIcon={
          props.error && props.helperText ? (
            <InfoIcon title={props.helperText || ''} error={props.error} />
          ) : undefined
        }
        error={props.error}
        fullWidth
        {...omit(props, 'helperText')}
      />
    </>
  );
}
