import { SvgIcon } from '@material-ui/core';
import * as React from 'react';
import { ReactComponent as IcursorIcon } from './I-cursor.svg';
import { ReactComponent as CheckIcon } from './check.svg';
import { ReactComponent as TimesIcon } from './times.svg';
import { ReactComponent as ChevronDownIcon } from './chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from './chevron-up.svg';
import { ReactComponent as NotAvailableIcon } from './notavailable.svg';
import { ReactComponent as NotAllowedIcon } from './notAllowed.svg';

import { ReactComponent as CheckedIcon } from './checked.svg';
import { ReactComponent as UncheckedIcon } from './unchecked.svg';
import { ReactComponent as UndeterminateIcon } from './undeterminate.svg';
import { ReactComponent as NotFoundPageIcon } from './notFoundPage.svg';

export function Search(props) {
  return (
    <SvgIcon viewBox="0 0 24 16" {...props}>
      <path
        d="M14.4842 12.861L11.8881 10.2649C11.771 10.1477 11.6121 10.0826 11.4455 10.0826H11.021C11.7397 9.16346 12.1667 8.00732 12.1667 6.74963C12.1667 3.75774 9.74251 1.3335 6.75061 1.3335C3.75872 1.3335 1.33447 3.75774 1.33447 6.74963C1.33447 9.74153 3.75872 12.1658 6.75061 12.1658C8.0083 12.1658 9.16444 11.7387 10.0836 11.0201V11.4445C10.0836 11.6111 10.1487 11.77 10.2659 11.8872L12.862 14.4833C13.1068 14.728 13.5026 14.728 13.7447 14.4833L14.4816 13.7463C14.7264 13.5016 14.7264 13.1058 14.4842 12.861ZM6.75061 10.0826C4.90964 10.0826 3.4176 8.5932 3.4176 6.74963C3.4176 4.90867 4.90704 3.41663 6.75061 3.41663C8.59158 3.41663 10.0836 4.90606 10.0836 6.74963C10.0836 8.5906 8.59418 10.0826 6.75061 10.0826Z"
        fill="#98A4B1"
      />
    </SvgIcon>
  );
}
export function ICursor(props) {
  return <SvgIcon viewBox="0 0 256 512" component={IcursorIcon} {...props} />;
}
export function Check(props) {
  return <SvgIcon viewBox="0 0 512 512" component={CheckIcon} {...props} />;
}
export function Times(props) {
  return <SvgIcon viewBox="0 0 352 512" component={TimesIcon} {...props} />;
}
export function ChevronDown(props) {
  return (
    <SvgIcon viewBox="0 0 448 512" component={ChevronDownIcon} {...props} />
  );
}
export function ChevronUp(props) {
  return <SvgIcon viewBox="0 0 448 512" component={ChevronUpIcon} {...props} />;
}
export function TableHeaderIcon(props) {
  return <SvgIcon viewBox="0 0 448 512" component={ChevronUpIcon} {...props} />;
}
export function NotAvailableSVG(props) {
  return (
    <SvgIcon
      viewBox="0 0 270 251"
      width="270"
      height="251"
      fontSize="inherit"
      htmlColor="#FFFFFF"
      component={NotAvailableIcon}
      {...props}
    />
  );
}

export function NotAllowedSVG(props) {
  return (
    <SvgIcon
      viewBox="0 0 270 313"
      width="270"
      height="313"
      fontSize="inherit"
      htmlColor="#FFFFFF"
      component={NotAllowedIcon}
      {...props}
    />
  );
}

export function NotFoundPageSVG(props) {
  return (
    <SvgIcon
      width="270"
      height="276"
      viewBox="0 0 270 276"
      fill="none"
      fontSize="inherit"
      htmlColor="#FFFFFF"
      component={NotFoundPageIcon}
      {...props}
    />
  );
}
export function Checked(props) {
  return <SvgIcon component={CheckedIcon} {...props} />;
}
export function Unchecked(props) {
  return <SvgIcon component={UncheckedIcon} {...props} />;
}
export function Indeterminate(props) {
  return <SvgIcon component={UndeterminateIcon} {...props} />;
}
