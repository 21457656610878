import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { toRootedURL } from 'utils/url-utils';
import {
  getDefaultImage,
  getDefaultImgSize,
  ImageSize,
} from '../AssetThumbImage/getAssetImageSrc';
import { DemSize } from '../BasicCards';
import { Tooltip } from '../BasicTooltips/Tooltip';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';

export interface ImageRenderProps {
  size: ImageSize;
  imgPath?: string;
  tooltip?: string;
  onClose?: (index: number) => void;
  deletIndex?: number;
}
export const ImageRender = ({
  size,
  imgPath,
  tooltip,
  onClose,
  deletIndex,
}: ImageRenderProps) => {
  const defaultImg = React.useMemo(() => {
    return toRootedURL(getDefaultImage(size));
  }, [size]);

  const [pathSrc, setPathSrc] = React.useState<string | undefined>(defaultImg);

  const [loadingImg, setLoadingImg] = React.useState<boolean | undefined>(
    undefined,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imageDimensions, setImageDimensions] = React.useState<
    DemSize | undefined
  >(undefined);

  const checkImage = React.useCallback(
    async (path: any) =>
      new Promise<{
        path?: string;
        status: string;
        height: number;
        width: number;
      }>(resolve => {
        const img = new Image();
        img.src = path ?? '';
        img.onload = () =>
          resolve({ path, status: 'ok', height: img.height, width: img.width });
        img.onerror = () =>
          resolve({
            path,
            status: 'error',
            height: getDefaultImgSize(size).height,
            width: getDefaultImgSize(size).width,
          });
      }),
    [size],
  );
  React.useEffect(() => {
    let active = imgPath !== undefined && loadingImg === undefined;
    if (active) {
      if (loadingImg) return;
      setLoadingImg(true);
      (async () => {
        try {
          const res = await checkImage(imgPath);
          if (res.status === 'ok') {
            setPathSrc(res.path);
            setImageDimensions({
              height: res.height,
              width: res.width,
            });
            setLoadingImg(false);
          } else {
            setPathSrc(defaultImg);
            setImageDimensions({
              height: res.height,
              width: res.width,
            });
            setLoadingImg(false);
          }
        } catch {
          setPathSrc(defaultImg);
          setImageDimensions({
            height: getDefaultImgSize(size).height,
            width: getDefaultImgSize(size).width,
          });
          setLoadingImg(false);
        }
      })();
    }
    return () => {
      active = false;
    };
  }, [checkImage, defaultImg, imgPath, loadingImg, size]);

  return loadingImg === true ? (
    <div className="img-wrapper-box img-wrapper-load">
      <CircularProgress size={30} />
    </div>
  ) : !!pathSrc && loadingImg === false ? (
    <React.Fragment>
      {!!tooltip ? (
        <Tooltip title={tooltip}>
          <div className="img-wrapper-box">
            <img src={pathSrc} alt="" />
          </div>
        </Tooltip>
      ) : (
        <div className="img-wrapper-box">
          <img src={pathSrc} alt="" />
          {deletIndex !== undefined && (
            <IconButton
              size="small"
              className="img-close-button"
              onClick={() => {
                if (onClose !== undefined) {
                  onClose(deletIndex);
                }
              }}
            >
              <Icon icon="times" />
            </IconButton>
          )}
        </div>
      )}
    </React.Fragment>
  ) : null;
};
