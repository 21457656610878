import { ExternalLink } from 'app/components/ExternalLink';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { ITrainingSessionsRow } from 'app/pages/TrainingSessionsPage/ITrainingSessionsRow';
import { CellProps, Renderer } from 'react-table';
import { dateUtils } from 'utils/date-utils';

type RecordType = ITrainingSessionsRow;
const UsedSlotsRenderer: Renderer<CellProps<RecordType>> = ({
  value,
  row: { original },
}) => {
  const { newDate } = useSystemDate();
  return (
    <ExternalLink
      href={{
        path: '/reservations',
        search: {
          period: 'CustomDate',
          st: dateUtils.formatQueryStringDate(
            dateUtils.dateOrStringToDate(original.StartDate ?? newDate()),
          ),
          et: dateUtils.formatQueryStringDate(
            dateUtils.dateOrStringToDate(original.EndDate ?? newDate()),
          ),
          eid: original.ServiceId,
          TrainingSignUp: true,
        },
      }}
    >
      {value}
    </ExternalLink>
  );
};
export default UsedSlotsRenderer;
