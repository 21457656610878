import { MenuItem, MenuItemProps } from '@material-ui/core';
import styled from 'styled-components';
import * as React from 'react';

// export const StyledMenuItem = withStyles({
//   root: {
//     '&:hover': {
//       backgroundColor: 'transparent',
//     },
//   },
// })(MenuItem);
// export const StyledMenuItem = styled(
//   ({
//     button,
//     theme,
//     children,
//     ...other
//   }: {
//     button: true | false;
//     theme: Theme;
//     children: React.ReactNode;
//   } & MenuItemProps) => (
//     <MenuItem disableRipple {...other}>
//       {children}
//     </MenuItem>
//   ),
// )`
//   &:hover {
//     background-color: 'transparent';
//   }
//   &.hidden-if-empty:empty {
//     display: none;
//   }
// `;

const CustMenuItem = styled(MenuItem)`
  &:hover {
    background-color: transparent;
  }
  &.hidden-if-empty:empty {
    display: none;
  }
`;
export const StyledMenuItem = React.forwardRef<
  any,
  Omit<MenuItemProps, 'button'>
>(function StyledMenuItem(props, ref) {
  return (
    <CustMenuItem ref={ref} {...props}>
      {props.children}
    </CustMenuItem>
  );
});
const StyledRoot = styled.div`
  &.clickable-actions-root {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &.hover-actions-root {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const ActionsRoot = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(function ActionRoot(props, ref) {
  return (
    <StyledRoot ref={ref} {...props}>
      {props.children}
    </StyledRoot>
  );
});
