import { AssemblyTypeShortMultiPicker } from 'app/components/pickers/StaticOptionsPickers/AssemblyTypeshortMultiPicker';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Entity } from 'types/common';

export const AssemblyTypeShortMultiFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<number>[]) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <AssemblyTypeShortMultiPicker
      id={props.id}
      name={props.name}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
      size="small"
      onChange={handleOnChange}
      value={props.value}
      fullWidth={props.fullWidth}
    />
  );
};
