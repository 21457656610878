import { useAdminPermissions } from 'app/permissions/Asset/assetPermissions';
import { CellProps, Renderer } from 'react-table';
import { DownloadFileButon } from 'app/components/DownloadFileButton';

type RecordType = Record<string, any>;
const FileNameRenderer: Renderer<CellProps<RecordType>> = ({ value, row }) => {
  const { allowedToEdit } = useAdminPermissions();
  return (
    <>
      {row.original.AvailableToUsers === false &&
      !allowedToEdit({
        ServiceGroupId: row.original.ServiceGroupId,
        ServiceType: row.original.ServiceTypeId,
        ServiceId: row.original.ServiceId,
      }) ? (
        <>{value}</>
      ) : (
        <DownloadFileButon id={row.original.Id} name={value} />
      )}
    </>
  );
};
export default FileNameRenderer;
