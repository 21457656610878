import { useTheme } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { CardPosition } from 'app/components/BasicCards';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export interface TableCardGridProps {
  id?: string;
  children: React.ReactNodeArray;
  isMobile?: boolean;
  cardPosition?: CardPosition;
  compact?: boolean;
  minCardHeight?: 'md' | 'lg';
}

export const TableCardGrid = ({
  id,
  children,
  isMobile,
  cardPosition,
  compact,
  minCardHeight,
}: TableCardGridProps) => {
  const theme = useTheme();
  const sperItems = React.useMemo(() => {
    let sperLength = children.length > 0 ? 5 - (children.length % 5) : 0;
    return Array.from(Array(sperLength));
  }, [children.length]);
  return (
    <CardGrid
      theme={theme}
      id={id}
      className={clsx('gridContainer', {
        isMobile: isMobile || compact,
        cardVertical: cardPosition === 'vertical',
      })}
    >
      {children
        .filter(f => !!f)
        .map((c, ind) => (
          <div
            key={`content-key-${ind}`}
            className={clsx('cardItem', {
              isMobile: isMobile || compact,
              cardHorizontal: cardPosition === 'horizontal',
              cardVertical: cardPosition === 'vertical',
              minCardHeightMd: minCardHeight === 'md',
              minCardHeightLg: minCardHeight === 'lg',
            })}
          >
            {c}
          </div>
        ))}
      {!isMobile &&
        !compact &&
        sperItems.map((c, ind) => (
          <div
            key={`sper-key-${ind}`}
            className={clsx('cardItem', {
              isMobile: isMobile || compact,
              cardHorizontal: cardPosition === 'horizontal',
              cardVertical: cardPosition === 'vertical',
              minCardHeightMd: minCardHeight === 'md',
              minCardHeightLg: minCardHeight === 'lg',
            })}
          ></div>
        ))}
      {(isMobile || compact) && children.length > 3 && (
        <div className="compact-height"></div>
      )}
    </CardGrid>
  );
};
const CardGrid = styled('div')(({ theme }) => ({
  '&.gridContainer': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    height: 'auto',
    gap: 32,
    overflowY: 'auto',
    padding: '8px 2px',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      boxShadow: `inset 0 0 2px ${bookitColors.grayscale.grayBorders}`,
      borderRadius: '6px',
      backgroundColor: theme.palette.common.grayBorders,
    },
    '&.isMobile': {
      gap: '16px',
    },
    '&.cardVertical': {
      // gap: 16,
      padding: 0,
    },
    '&.compact-height': {
      flex: '1 1 100%',
      width: '100%',
      height: 120,
    },
    '& .cardItem': {
      //flexBasis: isMobile ? '100%' : '20%',
      flex: '1 1 20%',
      borderRadius: 8,
      width: 0,
      //width: 256,
      minWidth: '256px',
      maxWidth: '360px',
      '&.cardHorizontal': {
        flex: '1 1 25%',
        minWidth: '400px',
        maxWidth: '460px',
      },
      '&.cardVertical': {
        flex: '1 1 16%',
      },
      '&.isMobile': {
        flex: '1 1 100%',
        maxWidth: '100%',
        minWidth: 'unset',
      },
      '&.minCardHeightMd': {
        minHeight: 80,
      },
      '&.minCardHeightLg': {
        minHeight: 100,
      },
    },
  },
}));
