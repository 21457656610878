/**
 *
 * AssetThumbImage
 *
 */
import * as React from 'react';
import { Avatar, Theme, useTheme } from '@material-ui/core';
import { getAssetImageThumbSrc, getDefaultImage } from './getAssetImageSrc';
import { useSelector } from 'react-redux';
import { selectImageExist } from 'app/slice/selectors';
import { bookitColors } from 'styles/colors/bookitColors';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import clsx from 'clsx';

export interface AssetThumbImageProps {
  size?: 'small' | 'medium' | 'large';
  imageName: string | null;
}

export const defaultImgSrc =
  process.env.PUBLIC_URL + `/images/DefaultImage.png`;

export function AssetThumbImage(props: AssetThumbImageProps) {
  const { imageName, size = 'medium' } = props;
  const [hasImgeBroken, setHasImgeBroken] = React.useState<boolean | undefined>(
    undefined,
  );
  const handleImgError = event => {
    setHasImgeBroken(true);
  };
  const imageExist = useSelector(state => selectImageExist(state, imageName));
  const thumbSizeClass = React.useMemo(() => {
    return `thumbSize${capitalize(size)}`;
  }, [size]);
  const theme = useTheme();
  const src = imageExist
    ? getAssetImageThumbSrc(
        imageName,
        size === 'large' ? 'thumbLarge' : 'thumb',
        undefined,
        getDefaultImage(size === 'large' ? 'thumbLarge' : 'thumb'),
      )
    : null;

  return (
    <ThumbRoot theme={theme} className={clsx('thumb-root', thumbSizeClass)}>
      {src === null || hasImgeBroken ? (
        <Avatar variant="square" className={'thumb-avatar'}>
          {'A'}
        </Avatar>
      ) : (
        <img
          onError={handleImgError}
          src={src}
          alt="asset"
          className={thumbSizeClass}
        />
      )}
    </ThumbRoot>
  );
}
const ThumbRoot = styled.div<{ theme: Theme }>`
  &.thumb-root {
    display: flex;
    justify-content: center;
    align-items: center;

    &.thumbSizeSmall {
      max-width: 32px;
      max-height: 32px;
    }
    &.thumbSizeMedium {
      max-width: 64px;
      max-height: 64px;
    }
    &.thumbSizeLarge {
      max-width: 80px;
      max-height: 80px;
    }
    & img {
      border-radius: 4px;
      &.thumbSizeSmall {
        max-width: 32px;
        max-height: 32px;
      }
      &.thumbSizeMedium {
        max-width: 64px;
        max-height: 64px;
      }
      &.thumbSizeLarge {
        max-width: 80px;
        max-height: 80px;
      }
    }
    & .thumb-avatar {
      color: ${props =>
        props.theme.palette.getContrastText(
          bookitColors.grayscale.grayBorders,
        )};
      background-color: ${bookitColors.grayscale.grayBackground};
      width: 32px;
      height: 32px;
      border-radius: 4px;
    }
  }
`;
