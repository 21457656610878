import { select } from 'redux-saga/effects';
import { selectAppSettings, selectIsAuthenticated } from '../selectors';
import { AppSettings } from 'types/AppSettings';
import { ConfigurableTypes, ScreensId } from 'enums/ConfigurableTypes';
import { GetCachedConfigurableColumnsData } from 'app/components/BasicTable/ConfigurableColumns';
import { KnownModules } from 'types/KnownModules';

/**
 * Does prefetch for configurable columns for selected table based pages. These should include mostly used/mostly visited ones
 */
export function* doPrefetchConfigurableColumns() {
  const settings: AppSettings = yield select(selectAppSettings);
  const isAuthenticated: boolean | undefined = yield select(
    selectIsAuthenticated,
  );
  // Ids of the pages (ScreenId enum) and respective required modules
  // Some of the pages can be disabled and are not shown so not all of the configurable columns might be needed
  const authSet: Array<{ s: ScreensId; m?: KnownModules }> = [
    // service groups aka cores directory src\app\pages\CoresDirectoryPage\index.tsx
    { s: ScreensId.CoresDirectory },
    // reservation history - C:\Users\mikea\Projects\bookitlab\web-ui\src\app\pages\ReservationHistoryPage\index.tsx
    { s: ScreensId.ReservationHistory },
    // user training - src\app\pages\UserTrainingsPage\index.tsx
    { s: ScreensId.UserTraining, m: KnownModules.Instruments },
    // training sessions - src\app\pages\TrainingSessionsPage\index.tsx
    {
      s: ScreensId.UpcomingTrainingSessions,
      m: KnownModules.Instruments,
    },
    // service requests - src\app\pages\ServiceRequestListPage\index.tsx
    { s: ScreensId.ServiceRequests, m: KnownModules.ServiceRequests },
    // my assets / instruments - src\app\pages\MyAssetsPage\index.tsx
    { s: ScreensId.MyAssets },
    // work orders aka events aka alerts - src\app\pages\WorkOrdersPage\index.tsx
    { s: ScreensId.Alerts, m: KnownModules.MaintenanceAssets },
  ];
  const noAuthSet: Array<{ s: ScreensId; m?: KnownModules }> = [
    // public assets src\app\pages\PublicAssets\index.tsx
    { s: ScreensId.PublicAssets },
  ];
  const screens: Array<ScreensId> = (isAuthenticated ? authSet : noAuthSet)
    .filter(item => item.m === undefined || settings.Modules.includes(item.m))
    .map(item => item.s);
  // preloads reservations history display columns to get faster to the table data
  // does not return anything here, but the fetched data will be already prefetch for the later use elsewhere
  screens.map(screenId => {
    return GetCachedConfigurableColumnsData(
      screenId,
      ConfigurableTypes.ColumnScreenConfig,
      isAuthenticated,
      undefined,
      true,
    );
  });
}
