/**
 *
 * AppliedFiltersContainer
 *
 */
import * as React from 'react';
import { Directions } from '../FilterBox';
import { useIsMobile } from 'app/hooks/useIsMobile';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core';
import { StyledFiltersContainer } from '../styled';

export interface AppliedFiltersContainerProps {
  direction: Directions;
}

export function AppliedFiltersContainer({
  children,
  direction,
}: React.PropsWithChildren<AppliedFiltersContainerProps>) {
  const { checkIsMobile, isLandscape } = useIsMobile();
  const isMobile = checkIsMobile();
  const theme = useTheme();
  return (
    <StyledFiltersContainer
      theme={theme}
      className={clsx('filters-container', {
        horizontal: direction === 'horizontal',
        mobileRoot: isMobile && !isLandscape,
        mobileHorizontalRoot: isMobile && isLandscape,
      })}
    >
      {children}
    </StyledFiltersContainer>
  );
}
