/**
 *
 * AssetPickerFilter
 *
 */
import { AssetPicker } from 'app/components/pickers/AutocompletePickers/AssetPicker';
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';

export const AssetPickerFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <AssetPicker
      ariaLabel={props.label}
      id={props.id}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
      onChange={handleOnChange}
      value={props.value as IAssetDto | undefined}
      fullWidth={props.fullWidth}
      size="small"
    />
  );
};
export default AssetPickerFilter;
