import { List, ListItem } from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface ListRendererProps {
  value: string[];
  localized?: boolean;
  notLocalizedValue?: string[];
  row?: any;
}
const StyledItem = styled('span')`
  &.title-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
`;
export const ListRenderer = (props: ListRendererProps) => {
  const { value, localized, notLocalizedValue } = props;
  const { t } = useTranslation();
  const onlyone =
    (value.length === 1 &&
      (notLocalizedValue === undefined || notLocalizedValue.length === 0)) ||
    (value.length === 0 &&
      notLocalizedValue !== undefined &&
      notLocalizedValue.length === 1);

  const title = (
    <React.Fragment>
      {value.length > 0 ? (
        <>{localized ? (t(value[0].trim()) as string) : value[0]}</>
      ) : notLocalizedValue !== undefined && notLocalizedValue.length > 0 ? (
        <>{notLocalizedValue[0]}</>
      ) : (
        <></>
      )}
    </React.Fragment>
  );

  const tooltipTitle = (
    <React.Fragment>
      <List dense={true}>
        {value.map((item, index) => {
          return (
            <ListItem key={index}>
              {localized ? (t(item.trim()) as string) : item}
            </ListItem>
          );
        })}
        {notLocalizedValue !== undefined && notLocalizedValue.length > 0
          ? notLocalizedValue.map((item, index) => {
              return <ListItem key={index}>{item}</ListItem>;
            })
          : ''}
      </List>
    </React.Fragment>
  );

  const listOfItems =
    value.length > 0 ||
    (notLocalizedValue !== undefined && notLocalizedValue.length > 0) ? (
      onlyone ? (
        <>{title}</>
      ) : (
        <StyledItem>
          <span>{title}</span>
          <Tooltip data-html="true" title={tooltipTitle} arrow>
            <Icon icon="list" />
          </Tooltip>
        </StyledItem>
      )
    ) : (
      <>{''}</>
    );
  return listOfItems;
};
