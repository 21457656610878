import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormSectionTitle } from 'app/components/Forms/FormsLayout/FormSectionTitle';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { Column } from 'react-table';
import { IBasicTableState, IRow } from 'app/components/BasicTable';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { PrimaryAction } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { GettableCount } from 'app/components/BasicTable/components/GetTableCount';
import { IUserTrainingDto } from 'api/odata/generated/entities/IUserTrainingDto';
import { getColumns } from 'app/pages/UserTrainingsPage/Columns';
import { GetActions } from 'app/pages/UserTrainingsPage/Actions/GetActions';
import { useUserTrainingPermissions } from 'app/permissions/UserTrainings/userTrainingPermissionUtils';
import userTrainingPrimaryAction from 'app/pages/UserTrainingsPage/PrimaryAction';
import { TrainingsTable } from 'app/pages/UserTrainingsPage/TrainingsTable';

const UserTrainingColumns: string[] = [
  'Id',
  'ServiceGroupName',
  'EquipmentName',
  'TrainingDate',
  'TrainingLevelName',
  'ReservationId',
  'RequiresAdminApproval',
  'ExpiredDate',
  'Remarks',
  'Active',
];
const url = '/api/odata/v4/usertrainings';

const GetPredicates = (
  userName: string,
): Array<Condition<IUserTrainingDto> | string> => {
  const res: Array<Condition<IUserTrainingDto> | string> = [];
  res.push(
    new Condition<IUserTrainingDto>('UserId', ODataOperators.Equals, userName),
  );
  return res;
};
export async function GetTrainingsTableCount(
  userName: string,
  name: string,
  updateCounters: (name: string, count: number) => void,
) {
  const res = await GettableCount({
    api: url,
    predicates: GetPredicates(userName) as Array<Condition<IRow> | string>,
    columns: ['Id'],
    globalFilterValue: '',
    customFilter: [],
    serviceGroups: [],
    isOptionalServiceGroup: true,
  });
  updateCounters(name, res);
}
export interface UserTrainingsTableProps {
  userName: string;
  getFiltersDefinition?: GetPageFilters<IUserTrainingDto>;
  isMobile?: boolean;
  useSidePanel?: boolean;
  openPanel: (state: SidePanelOpenState) => void;
}
export function UserTrainingsTable(props: UserTrainingsTableProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions } = useLayoutSlice();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const trainingColumns = React.useMemo(
    () => getColumns(t, authenticatedUser, props.useSidePanel, props.openPanel),
    [authenticatedUser, props.openPanel, props.useSidePanel, t],
  );
  const { isAdmin } = useUserTrainingPermissions();
  const columns = React.useMemo(() => {
    let res: Column<IUserTrainingDto>[] = [];
    for (const c of UserTrainingColumns) {
      const column = trainingColumns.find(f => f.accessor === c);
      if (column !== undefined) {
        res.push(column);
      }
    }
    return res;
  }, [trainingColumns]);
  const PageActions: PageActionRenderer[] = GetActions({
    isAdmin: isAdmin,
    shortView: props.isMobile,
    userName: props.userName,
  });
  const primaryAction: PrimaryAction<IUserTrainingDto> = React.useCallback(
    (...args) => {
      userTrainingPrimaryAction(
        props.openPanel,
        args[0],
        args[1],
        args[2],
        props.useSidePanel,
      );
    },
    [props.openPanel, props.useSidePanel],
  );
  const goToTrainings = React.useCallback(() => {
    dispatch(actions.toggleSidePanel(false));
    history.push('/usertraining?un=' + props.userName);
  }, [actions, dispatch, history, props.userName]);

  const predicates = React.useMemo(() => {
    return GetPredicates(props.userName);
  }, [props.userName]);
  const initialState: IBasicTableState<IUserTrainingDto> = React.useMemo(
    () => ({
      sortBy: [{ id: 'Id', desc: true }],
    }),
    [],
  );
  return (
    <TrainingsTable
      columns={columns}
      initialState={initialState}
      predicates={predicates}
      pageActions={PageActions}
      useConfigurable={false}
      useCardsByDefault={props.isMobile}
      allowCards={props.isMobile}
      useExport={false}
      serviceGroups={[]}
      primaryAction={primaryAction}
      customScreenName={
        <React.Fragment>
          <FormSectionTitle title={t(translations.menu_UserTraining)} />
          <IconButton aria-label="link" onClick={goToTrainings}>
            <Icon icon="arrow-up-right-from-square" />
          </IconButton>
        </React.Fragment>
      }
      hideMenuOnMobile={true}
      screenNameVariant="section"
      tablePaddingVariant={'dense'}
      compactTable={props.isMobile}
    />
  );
}
