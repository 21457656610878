import { Divider } from '@material-ui/core';
import { BookitlabShortLogo } from 'app/components/BookitlabLogo/BookitlabShortLogo';
import { Link } from 'app/components/ExternalLink';
import { selectWebVersion } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import { useStyles } from '../styles';
import styled from 'styled-components';

export interface SideBarFooterProps {
  // isOpen?: boolean;
}

const StyledSideBarFooter = styled.div`
  min-height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  font-size: 12px;
  gap: 16px;
`;

export const SideBarFooter = React.memo(function SideBarFooter(
  props: SideBarFooterProps,
) {
  // const classes = useStyles({ isOpen: props.isOpen ?? false });
  const { t } = useTranslation();
  const webVersion = useSelector(selectWebVersion);
  return (
    <React.Fragment>
      <Divider />
      <StyledSideBarFooter>
        <BookitlabShortLogo />
        <div>{`V: ${webVersion} `}</div>
        <div>
          <Link href="/TermsOfUseAgreement" target="_blank">
            {t(translations.TermsOfUse)}
          </Link>
        </div>
      </StyledSideBarFooter>
    </React.Fragment>
  );
});
