import * as React from 'react';
import { IInvoiceBudgetBalanceDto } from 'api/odata/generated/entities/IInvoiceBudgetBalanceDto';
import { CellRenderer } from 'app/components/BasicTable/BasicTableProps';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash';
import { toEntity } from 'utils/entity-utils';
import { AddChargeButton } from '../AddChargePage/AddChargeButton';
import { AddPaymentButton } from '../AddPaymentPage/AddPaymentButton';
import { IInvoiceBalanceDto } from 'api/odata/generated/complexTypes/IInvoiceBalanceDto';
import { dateUtils } from 'utils/date-utils';
import { useSelector } from 'react-redux';
import { selectglobalSettings } from 'app/slice/selectors';
import { AllowedSettings } from 'utils/globalSettings';
import { ReactActions } from 'app/components/ReactActions';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { InvoiceDetailsLink } from '../Actions/GlobalSelectedActions/OpenInvoiceDetails';
import styled from 'styled-components';

const tryParseInvoicesData = (s: string | null): IInvoiceBalanceDto[] => {
  try {
    const invoices = JSON.parse(s ?? '[]') as IInvoiceBalanceDto[];
    return invoices;
  } catch {
    return [] as IInvoiceBalanceDto[];
  }
};
const LinkContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 14px;
`;
export const useRowActions = () => {
  const { t } = useTranslation();
  const settings = useSelector(selectglobalSettings);
  const allowedFees = settings.GetString(AllowedSettings.AllowedFeeTypes);
  const accumulateInvoices = settings.GetBoolean(
    AllowedSettings[AllowedSettings.AccumulateNewChargesToExistingInvoice],
  );
  const isChargesAllowed = allowedFees && allowedFees.trim() !== '';
  const rowActions = React.useMemo<CellRenderer<IInvoiceBudgetBalanceDto>[]>(
    () => [
      ({ row }) => {
        const invoices = tryParseInvoicesData(row.original.Invoices);
        const actions: JSX.Element[] = [];
        if (invoices.length === 0) {
          // add charge/payment by service group & budget
          if (isChargesAllowed && accumulateInvoices) {
            actions.push(
              <AddChargeButton
                budget={getBudget(row.original)}
                serviceGroup={getServiceGroup(row.original)}
              >
                <LinkContent>
                  <Icon icon="plus" />
                  {t(translations.AddACharge)}
                </LinkContent>
              </AddChargeButton>,
            );
          }
          if (accumulateInvoices) {
            actions.push(
              <AddPaymentButton
                budget={getBudget(row.original)}
                serviceGroup={getServiceGroup(row.original)}
              >
                <LinkContent>
                  <Icon icon="plus" />
                  {t(translations.AddPayment)}
                </LinkContent>
              </AddPaymentButton>,
            );
          }
        } else if (invoices.length === 1) {
          // combine all differnet actions into single list for single invoice row
          actions.push(
            <InvoiceDetailsLink
              id={invoices[0].Id ?? 0}
              useSidePanel={false}
              title={t(
                translations.SeeFullDetailsOfBalanceAndTransferChargesToAnotherBudget,
              )}
            >
              <LinkContent>
                <Icon icon="file-alt" />
                {t(translations.BudgetDetailsBudgetTransfer)}
              </LinkContent>
            </InvoiceDetailsLink>,
          );
          if (isChargesAllowed && accumulateInvoices) {
            actions.push(
              <AddChargeButton
                invoiceId={invoices[0].Id ?? undefined}
                budget={getBudget(row.original)}
                serviceGroup={getServiceGroup(row.original)}
              >
                <LinkContent>
                  <Icon icon="plus" />
                  {t(translations.AddACharge)}
                </LinkContent>
              </AddChargeButton>,
            );
          }
          if (accumulateInvoices) {
            actions.push(
              <AddPaymentButton
                amount={getPaymentAmount(invoices[0])}
                invoiceId={invoices[0].Id ?? undefined}
                budget={getBudget(row.original)}
                serviceGroup={getServiceGroup(row.original)}
              >
                <LinkContent>
                  <Icon icon="plus" />
                  {t(translations.AddPayment)}
                </LinkContent>
              </AddPaymentButton>,
            );
          }
        } else if (invoices?.length ?? 0 > 1) {
          // show nested actions per each invoice row:
          // invoice label + all actions behind the "ReactActions"

          invoices.forEach(invoice => {
            let subActions: JSX.Element[] = [];
            subActions.push(
              <InvoiceDetailsLink
                id={invoice.Id ?? 0}
                useSidePanel={false}
                title={t(
                  translations.SeeFullDetailsOfBalanceAndTransferChargesToAnotherBudget,
                )}
              >
                <LinkContent>
                  <Icon icon="file-alt" />
                  {t(translations.BudgetDetailsBudgetTransfer)}
                </LinkContent>
              </InvoiceDetailsLink>,
            );
            if (isChargesAllowed && accumulateInvoices) {
              subActions.push(
                <AddChargeButton
                  invoiceId={invoice.Id ?? undefined}
                  budget={getBudget(row.original)}
                  serviceGroup={getServiceGroup(row.original)}
                >
                  <LinkContent>
                    <Icon icon="plus" />
                    {t(translations.AddACharge)}
                  </LinkContent>
                </AddChargeButton>,
              );
            }
            if (accumulateInvoices) {
              subActions.push(
                <AddPaymentButton
                  amount={getPaymentAmount(invoice)}
                  invoiceId={invoice.Id ?? undefined}
                  budget={getBudget(row.original)}
                  serviceGroup={getServiceGroup(row.original)}
                >
                  <LinkContent>
                    <Icon icon="plus" />
                    {t(translations.AddPayment)}
                  </LinkContent>
                </AddPaymentButton>,
              );
            }
            actions.push(
              <>
                <LinkContent>
                  {`#${invoice.Id} ${dateUtils.shortDateFormat(
                    invoice.InvoiceStart,
                  )} - ${dateUtils.shortDateFormat(invoice.InvoiceEnd)}`}
                </LinkContent>
                <ReactActions
                  id="addCharge_addPayment"
                  hoverMenu={true}
                  size="small"
                  items={subActions}
                />
              </>,
            );
          });
        }
        if (actions.length === 0) return <></>;
        return <ReactActions id={uniqueId()} items={actions} size="small" />;
      },
    ],
    [accumulateInvoices, isChargesAllowed, t],
  );
  return rowActions;
};

function getPaymentAmount(invoice: IInvoiceBalanceDto) {
  const balance = invoice.Charges - invoice.Payments;
  if (balance > 0) {
    return balance;
  } else {
    return undefined;
  }
}

interface IRowWithFlatBudget {
  BudgetId: number | null;
  Budget: string | null;
}
const getBudget = (row: IRowWithFlatBudget) => {
  return toEntity(row.BudgetId, row.Budget);
};
interface IRowWithFlatServiceGroup {
  ServiceGroupId: number | null;
  ServiceGroup: string | null;
}
const getServiceGroup = (row: IRowWithFlatServiceGroup) => {
  return toEntity(row.ServiceGroupId, row.ServiceGroup);
};
