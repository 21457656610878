import { MenuItem } from '@material-ui/core';
import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import { WorkOrderApi } from 'api/WorkOrderApi';
import { DropDown } from 'app/components/DropDown';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { usePromise } from 'app/hooks/usePromise';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { useCalendarLink } from 'app/pages/CalendarPage/GetCalendarPagePath';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { dateUtils } from 'utils/date-utils';
import { toQueryString, toRootedURL } from 'utils/url-utils';
import { OpenWorkOrderButton } from './OpenWorkOrder';

export interface BringBackUpProps<TRow> {
  rows: TRow[];
  getAssetIds: (rows: TRow[]) => number[];
  getEquipmentIds: (rows: TRow[]) => number[];
  useSidePanel?: boolean;
}

export function BringBackUp<TRow>({
  rows,
  getAssetIds,
  getEquipmentIds,
  useSidePanel,
}: BringBackUpProps<TRow>) {
  const { t } = useTranslation();
  const { newDate } = useSystemDate();
  const fetchDownOrders = async (
    ids: number[],
  ): Promise<IWorkOrderDetailsDto[]> => {
    return WorkOrderApi.DowntimeOrders(ids).then(data => {
      return data;
    });
  };
  const [downOrders, setDownOrders] = useAsyncExtendedState<
    IWorkOrderDetailsDto[]
  >([]);
  const [fetchOrdersState, fetchOrdersPromise] = usePromise(fetchDownOrders);

  React.useEffect(() => {
    if (
      !fetchOrdersState.status ||
      (fetchOrdersState.status !== 'pending' &&
        fetchOrdersState.status !== 'resolved')
    ) {
      setDownOrders(fetchOrdersPromise(getAssetIds(rows)));
    }
  }, [
    fetchOrdersPromise,
    fetchOrdersState.status,
    getAssetIds,
    rows,
    setDownOrders,
  ]);

  const calendarLinkParams = React.useMemo(() => {
    if (fetchOrdersState.status === 'resolved' && downOrders.length > 0) {
      let date =
        downOrders.filter(f => f.OfflineEventStart !== null)[0]
          .OfflineEventStart ?? newDate();

      return {
        equipments: getEquipmentIds(rows),
        Start: dateUtils.formatQueryStringDate(dateUtils.parseISO(date)),
      };
    } else {
      return undefined;
    }
  }, [downOrders, fetchOrdersState.status, getEquipmentIds, newDate, rows]);
  const calendarLink = useCalendarLink({
    ...calendarLinkParams,
    ...{ viewType: 'calendar' },
  });
  const alertslink = React.useMemo(() => {
    if (fetchOrdersState.status === 'resolved' && downOrders.length > 0) {
      return `/workorders?${toQueryString({
        aid: getAssetIds(rows)
          .map(r => r)
          .join('|'),
        downTime: 'true',
      })}`;
    } else {
      return undefined;
    }
  }, [downOrders.length, fetchOrdersState.status, getAssetIds, rows]);

  return fetchOrdersState.status === 'resolved' && downOrders.length > 0 ? (
    <React.Fragment>
      {downOrders.length === 1 ? (
        <OpenWorkOrderButton
          buttonName={t(translations.BringBackUp)}
          orderId={downOrders[0].Id}
          bringBackUp={true}
          useSidePanel={useSidePanel}
        />
      ) : (
        <DropDown
          variant="white"
          size="small"
          menuChildren={onClose => [
            <MenuItem
              key="workOrders_link"
              component="a"
              href={toRootedURL(alertslink || '/workorders')}
            >
              {t(translations.BringBackUpOnWorkOrders)}
            </MenuItem>,
            <MenuItem
              key="calendar_link"
              component="a"
              href={toRootedURL(calendarLink)}
            >
              {t(translations.BringBackUpOnCalendar)}
            </MenuItem>,
          ]}
          menuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          addChevron
        >
          {t(translations.BringBackUp)}
        </DropDown>
      )}
    </React.Fragment>
  ) : (
    <React.Fragment></React.Fragment>
  );
}
