/**
 *
 * AsyncPicker
 *
 */
import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import { LocationPicker } from '../pickers/AutocompletePickers/LocationPicker';
import { ILocationListDto } from 'api/odata/generated/entities/ILocationListDto';
import { IFieldProps } from './FormRender/IFormFieldSetting';

export const FormLocationPicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<number> | null> &
  IFieldProps & {
    label?: string;
    fullWidth: boolean;
    autoFocus?: boolean;
    name: string;
    placeholder: string;
    disabled?: boolean;
    variant?: 'standard' | 'filled' | 'outlined';
  }) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  return (
    <>
      <LocationPicker
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        onChange={value => {
          props.onChange?.(value);
          helpers.setValue(value, true);
        }}
        onBlur={field?.onBlur}
        value={field.value as ILocationListDto}
        error={meta?.error !== undefined}
        helperText={meta.error}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
        variant={props.variant}
        predicates={props.predicates}
        info={props.info}
      />
    </>
  );
};
