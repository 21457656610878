import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.userAttributesDetail || initialState;

export const selectUserAttributesDetails = createSelector(
  [selectSlice],
  state => state,
);
//====================================================================
export const selectCreateUserAttributesDetails = createSelector(
  [selectUserAttributesDetails],
  state => state.createState.data,
);

export const selectCreateUserAttributesDetailsCompleted = createSelector(
  [selectUserAttributesDetails],
  state => state.createState.completed,
);

export const selectCreateUserAttributesDetailsHasError = createSelector(
  [selectUserAttributesDetails],
  state => state.createState.hasErrors,
);
//====================================================================
export const selectUpdateUserAttributesDetails = createSelector(
  [selectUserAttributesDetails],
  state => state.updateState.data,
);

export const selectUpdateUserAttributesDetailsCompleted = createSelector(
  [selectUserAttributesDetails],
  state => state.updateState.completed,
);
export const selectUpdateUserAttributesDetailsHasError = createSelector(
  [selectUserAttributesDetails],
  state => state.updateState.hasErrors,
);
//====================================================================
export const selectUserAttributesDetailsProcessing = createSelector(
  [selectUserAttributesDetails],
  state => state.processing,
);
export const selectSsoAttrSyncedWithUser = createSelector(
  [selectUserAttributesDetails],
  state => state.SsoAttrSyncedWithUser,
);
export const selectUserAttrSyncedWithSso = createSelector(
  [selectUserAttributesDetails],
  state => state.UserAttrSyncedWithSso,
);
