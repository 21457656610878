export enum MandatoryOfflineTypeEnum {
  Mandatory_Optional = 0,
  Mandatory_Default = 1,
  Mandatory_Users = 2,
  Mandatory_OnCompletion = 3,
}

export type MandatoryOfflineTypeUnion = keyof typeof MandatoryOfflineTypeEnum;
export const MandatoryOfflineTypeUnionArray: Array<MandatoryOfflineTypeUnion> =
  [
    'Mandatory_Optional',
    'Mandatory_Default',
    'Mandatory_Users',
    'Mandatory_OnCompletion',
  ];
