/**
 *
 * SubscriptionTypesPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { ISubscriptionTypesFilterDto } from 'api/odata/generated/entities/ISubscriptionTypesFilterDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Entity } from 'types/common';

export interface SubscriptionTypesPickerProps
  extends Omit<
    AutocompletePickerProps<ISubscriptionTypesFilterDto>,
    'loadData'
  > {}

const url = '/api/odata/v4/SubscriptionTypesFilter';
export const initSubscriptionTypesData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<ISubscriptionTypesFilterDto[]>((resolve, reject) => {
      resolve([] as ISubscriptionTypesFilterDto[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Id asc',
      $filter: '(Id eq ' + id + ')',
      $select:
        'Id,Name,Cost,DurationDays,DurationMonths,DurationStart,DurationEnd,ServiceGroupId,EntireCore,OnlineServices,OfflineServiceTypes,SampleTypes',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as ISubscriptionTypesFilterDto[]);
  }
};
const loadData = (
  predicates: (string | Condition<ISubscriptionTypesFilterDto>)[] | undefined,
  globalServiceGroupFilter?: Entity<number>[],
) => {
  return getAutoCompleteLoadDataFn<ISubscriptionTypesFilterDto>({
    url: url,
    predicates: predicates,
    select: [
      'Id',
      'Name',
      'Cost',
      'DurationDays',
      'DurationMonths',
      'DurationStart',
      'DurationEnd',
      'ServiceGroupId',
      'EntireCore',
      'OnlineServices',
      'OfflineServiceTypes',
      'SampleTypes',
    ],
    globalServiceGroupFilter:
      !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
        ? [
            new Condition<ISubscriptionTypesFilterDto>(
              'ServiceGroupId',
              ODataOperators.Includes,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
  });
};

export function SubscriptionTypesPicker(props: SubscriptionTypesPickerProps) {
  const { t } = useTranslation();
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const innerLoadData = loadData(props.predicates, globalServiceGroupFilter);
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      loadData={innerLoadData}
      id={props.id || 'subscriptiontypespickerId'}
      placeholder={t(translations.AllServices)}
      {...props}
    />
  );
}
