import { useFormikContext } from 'formik';
import { Entity } from 'types/common';
import * as React from 'react';
import { GlobalSettingsType } from '../useGlobalSettingsHook';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { IReservationEquipmentDto } from '../../slice/types';
import { getBudgetVisible } from '../../slice/getBudgetVisible';
import { isMember } from './isMember';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';

export interface UserGroupListenerProps<T> {
  userName: Entity<string> | null;
  equipments: Entity<number>[];
  userGroup: Entity<string> | null | undefined;
  globalSettings: GlobalSettingsType;
  user: AuthenticatedUser | undefined;
  isEdit: boolean;
  settings?: {
    HasHideProjects: boolean;
    HasConflictProjects: boolean;
    BudgetsTurnedOn: boolean;
  };
  values?: T;
  wait?: boolean;
}
export function UserGroupListener<T>(props: UserGroupListenerProps<T>) {
  const {
    userName,
    equipments,
    userGroup,
    globalSettings,
    isEdit,
    settings,
    wait,
  } = props;
  const [initilized, setInitilized] = React.useState(true);
  const budgetVisible = React.useMemo(() => {
    return getBudgetVisible(
      globalSettings,
      (equipments as IReservationEquipmentDto[]).map(service => ({
        Billable: service.Billable,
        BudgetsTurnedOn: service.BudgetsTurnedOn,
        HideProjects: service.HideProjects,
        ServiceTypeId: ServiceType.Online,
      })),
    );
  }, [equipments, globalSettings]);

  const ReservationUserGroupGroupByBudgetUserGroup = React.useMemo(() => {
    return !!settings
      ? globalSettings.reservationUserGroupGroupByBudgetUserGroup &&
          !settings.HasHideProjects &&
          !settings.HasConflictProjects &&
          settings.BudgetsTurnedOn
      : globalSettings.reservationUserGroupGroupByBudgetUserGroup;
  }, [settings, globalSettings.reservationUserGroupGroupByBudgetUserGroup]);
  const { setFieldValue } = useFormikContext<T>();
  React.useEffect(() => {
    if (!initilized && !wait) {
      if (userGroup !== null) {
        if (
          budgetVisible &&
          !isEdit &&
          equipments.length > 0 &&
          userName !== null
        ) {
          if (!ReservationUserGroupGroupByBudgetUserGroup) {
            setFieldValue('Budget', null);
          }
        } else {
          if (!ReservationUserGroupGroupByBudgetUserGroup) {
            if (!isMember(userName, userGroup)) {
              setFieldValue('BookedBy', null);
            }

            if (budgetVisible && !isEdit) {
              setFieldValue('Budget', null);
            }
          }
        }
      } else {
        if (!ReservationUserGroupGroupByBudgetUserGroup) {
          setFieldValue('BookedBy', null);
          if (budgetVisible && !isEdit) {
            setFieldValue('Budget', null);
          }
        }
      }
    }
    setInitilized(false);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroup]);

  return null;
}
