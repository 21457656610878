import { Menu, MenuProps } from '@material-ui/core';
import { ReactNode, useState } from 'react';
import { Button, ButtonProps } from '../BasicButtons/Button';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import * as React from 'react';
import styled from 'styled-components';

export interface DropDownProps extends ButtonProps {
  menuChildren: (onClose: () => void) => ReactNode | ReactNode[];
  addChevron?: boolean;
  menuProps?: Omit<MenuProps, 'children' | 'anchorEl' | 'open'>;
  id?: string;
  onOpen?: () => void;
  onHandleClose?: () => void;
  iconButton?: boolean;
  disablePortal?: boolean;
}

const IconsContainer = styled.div`
  &.icons-container {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }
`;

export const DropDown = ({
  onClick,
  onMouseDown,
  children,
  menuChildren,
  menuProps,
  addChevron,
  id,
  title,
  onOpen,
  onHandleClose,
  iconButton,
  disablePortal,
  endIcon,
  ...other
}: DropDownProps) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { marginThreshold, anchorOrigin, onClose, ...rest } = menuProps ?? {};
  const handleOpen = e => {
    setAnchorEl(e.currentTarget);
    setOpen(!open);
    if (!!onOpen) {
      onOpen();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    if (onHandleClose) {
      onHandleClose();
    }
  };
  return (
    <>
      <React.Fragment>
        {iconButton ? (
          <IconButton
            title={title}
            aria-controls={id || 'custom-menu-button'}
            onClick={e => {
              handleOpen(e);
              onClick?.(e);
            }}
            onMouseDown={e => {
              handleOpen(e);
              onMouseDown?.(e);
            }}
            aria-haspopup="true"
            {...other}
          >
            {children}
          </IconButton>
        ) : (
          <Button
            title={title}
            aria-controls={id || 'custom-menu-button'}
            onClick={e => {
              handleOpen(e);
              onClick?.(e);
            }}
            onMouseDown={e => {
              handleOpen(e);
              onMouseDown?.(e);
            }}
            aria-haspopup="true"
            endIcon={
              <React.Fragment>
                {addChevron && !!endIcon ? (
                  <IconsContainer className="icons-container">
                    <Icon icon={open ? 'chevron-up' : 'chevron-down'} />
                    {endIcon}
                  </IconsContainer>
                ) : (
                  <React.Fragment>
                    {addChevron && (
                      <Icon icon={open ? 'chevron-up' : 'chevron-down'} />
                    )}
                    {!!endIcon && endIcon}
                  </React.Fragment>
                )}
              </React.Fragment>
            }
            {...other}
          >
            {children}
          </Button>
        )}
      </React.Fragment>

      <Menu
        id={id || 'custom-menu-button'}
        marginThreshold={marginThreshold ?? 1}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        MenuListProps={{ dense: true }}
        open={Boolean(anchorEl)}
        container={document.fullscreenElement ?? document.body}
        disablePortal={disablePortal}
        onClose={(e, r) => {
          handleClose();
          onClose && onClose(e, r);
        }}
        anchorOrigin={
          anchorOrigin ?? {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        {...rest}
      >
        {open && menuChildren(handleClose)}
      </Menu>
    </>
  );
};
