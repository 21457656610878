import { UserGroupMultiPicker } from 'app/components/pickers/AutocompletePickers/UserGroupMultiPicker';
import { FieldHookConfig, useField } from 'formik';
import { Entity } from 'types/common';
import { IFieldProps } from '../FormRender/IFormFieldSetting';

export const FormUserGroupsPicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<string>[] | null> & IFieldProps) => {
  const [field, meta, helpers] = useField<Entity<string>[] | null>(props);
  return (
    <UserGroupMultiPicker
      name={props.name}
      placeholder={props.placeholder}
      label={label}
      onChange={value => {
        helpers.setValue(value, true);
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      onChipClick={props.onChipClick}
      onBlur={field?.onBlur}
      value={field.value ?? undefined}
      error={meta?.error !== undefined}
      variant="filled"
      helperText={meta.error}
      fullWidth={props.fullWidth}
      info={props.info}
      queryfilter={
        !!props.predicates
          ? props.predicates.map(f => f.toString()).join(' and ')
          : undefined
      }
      disabled={props.disabled}
    />
  );
};
