import * as React from 'react';
import { LinearProgress } from 'app/components/LinearProgress';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Body } from 'app/components/Typography';
import { TitlePage } from './TitlePage';
import { Skeleton } from '@material-ui/lab';
import { ReactActions } from 'app/components/ReactActions';
import { ActionRenderer } from '../PageActions/ActionRender';
import {
  SwitchActions,
  SwitchActionsProps,
} from '../PageActions/SwitchActions';
import { SkeletonIfLoading } from './SkeletonIfLoading';
import { useTheme } from '@material-ui/core';
import styled from 'styled-components';
import clsx from 'clsx';
import { Button } from 'app/components/BasicButtons/Button';
import { bookitColors } from 'styles/colors/bookitColors';
import { Div100 } from 'app/components/AssetQuickSearch/styled';

export interface HeadProps {
  closable: boolean | undefined;
  useSidePanel: boolean;
  closePanel: () => void;
  pageName: React.ReactNode;
  titlePage: string | undefined;
  titlePageLoading?: boolean;
  pageLink: string | undefined;
  toPageLink?: string;
  titleTooltip: string | undefined;
  isMobile: boolean;
  disableExpandToggle: boolean | undefined;
  expandPanel: () => void;
  subTitlePage?: React.ReactNode;
  leftTopActions?: ActionRenderer[];
  rightTopActions?: ActionRenderer[];
  onSubmit?: () => void;
  switchState?: SwitchActionsProps;
  useSwithActions?: boolean;
  showProcessing?: boolean;
  loading: boolean;
  isCover?: boolean;
  rightTopActionsLength?: number;
  rightActionsLoading?: boolean;
}
const TitleRoot = styled('div')(({ theme }) => ({
  '&.page-wrapper-title-root': {
    paddingTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '24px',
    '.topIcons': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      alignSelf: 'end',
      padding: 0,
      gap: theme.spacing(1.25),
      '.topRightIcons': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        position: 'absolute',
        padding: 0,
        gap: '12px',
        top: theme.spacing(2),
      },
    },
    '.titleContainer': {
      display: 'flex',
      flexDirection: 'row',
      //justifyContent: 'space-between',
      //alignItems: 'flex-end',
      alignItems: 'center',
      gap: '24px',
      padding: '0px 32px',
      width: '100%',
      alignSelf: 'stretch',
      //gap: theme.spacing(4.5),
      '.titleSubtitle': {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        flex: '1 0 0',
        // flexDirection: 'column',
        // alignItems: 'flex-start',
        // padding: 0,
        '& .titleAndDescription': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '4px',
          flex: '1 0 0',
          '& .titleContent': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '8px',
            alignSelf: 'stretch',
            '& .iconAdjustment': {
              display: 'flex',
              paddingTop: '2px',
              alignItems: 'flex-start',
              gap: '10px',
              '& .textButton': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '10px',
              },
            },
            '& .pageDescription': {
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            },
            '& .subPageDescription': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '4px',
              flex: '1 0 0',
            },
          },
        },
      },
      '.rightControls': {
        display: 'flex',
        //flexDirection: 'row',
        alignItems: 'center',
        //padding: 0,
        gap: '8px',
        '& .skeleton': {
          backgroundColor: bookitColors.grayscale.graySelection,
        },
      },
    },
  },
}));
export function Head(props: HeadProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    closable,
    useSidePanel,
    closePanel,
    pageName,
    titlePage,
    titlePageLoading,
    pageLink,
    titleTooltip,
    isMobile,
    disableExpandToggle,
    expandPanel,
    subTitlePage,
    leftTopActions,
    rightTopActions,
    onSubmit,
    showProcessing,
    loading,
    useSwithActions,
    switchState,
    isCover,
    rightTopActionsLength,
    rightActionsLoading,
  } = props;
  const actionsLength = React.useMemo(() => {
    if (rightTopActions !== undefined && rightTopActions.length > 0) {
      let res = rightTopActionsLength !== undefined ? rightTopActionsLength : 1;
      if (res > rightTopActions.length) {
        res = rightTopActions.length;
      }
      return res;
    } else return 0;
  }, [rightTopActions, rightTopActionsLength]);
  return (
    <SkeletonIfLoading loading={loading}>
      <TitleRoot
        theme={theme}
        className={clsx('page-wrapper-title-root', { cover: isCover })}
      >
        <div className={'titleContainer'}>
          <div className={'titleSubtitle'}>
            <div className={'titleAndDescription'}>
              <div className={'titleContent'}>
                {pageName && (
                  <div className={'iconAdjustment'}>
                    <div className={'textButton'}>
                      <Button
                        variant="textGray"
                        size="small"
                        aria-label="minimize"
                        title={
                          !isMobile && useSidePanel && !disableExpandToggle
                            ? t(translations.ShowLess)
                            : undefined
                        }
                        startIcon={
                          !isMobile && useSidePanel && !disableExpandToggle ? (
                            <Icon icon={['fas', 'arrow-left']} />
                          ) : undefined
                        }
                        onClick={() =>
                          !isMobile &&
                          useSidePanel &&
                          !disableExpandToggle &&
                          expandPanel()
                        }
                      >
                        {pageName}
                      </Button>
                      {/* <Body bold={true} color="secondary">
                         {pageName}
                      </Body> */}
                    </div>
                  </div>
                )}
                {(titlePageLoading || titlePage) && (
                  <div className={'pageDescription'}>
                    {titlePage && (
                      <TitlePage
                        title={titlePage}
                        pageLink={pageLink}
                        useSidePanel={useSidePanel}
                        buttonsLenth={0}
                        tooltip={titleTooltip}
                        expandPanel={
                          !isMobile &&
                          useSidePanel &&
                          !disableExpandToggle &&
                          !pageName
                            ? expandPanel
                            : undefined
                        }
                      />
                    )}
                    {titlePageLoading && (
                      <Skeleton variant="text" width="20ch" />
                    )}
                  </div>
                )}

                {subTitlePage && (
                  <div className={'subPageDescription'}>
                    <Body size="small">{subTitlePage}</Body>
                  </div>
                )}
              </div>
            </div>
            <div className={'rightControls'}>
              <>
                {!!leftTopActions &&
                  leftTopActions?.length > 0 &&
                  leftTopActions?.map((item, index) => (
                    <span key={index}>{item(onSubmit)}</span>
                  ))}
                {rightActionsLoading &&
                  (!rightTopActions ||
                    (!!rightTopActions && rightTopActions?.length === 0)) && (
                    <React.Fragment>
                      <Skeleton
                        width={'80px'}
                        height={'40px'}
                        className={'skeleton'}
                      />
                      <Skeleton
                        width={'80px'}
                        height={'40px'}
                        className={'skeleton'}
                      />
                      <Skeleton
                        width={'40px'}
                        height={'40px'}
                        className={'skeleton'}
                      />
                    </React.Fragment>
                  )}
                {!!rightTopActions && rightTopActions?.length > 0 && (
                  <React.Fragment>
                    {rightTopActions
                      .filter((t, index) => index < actionsLength)
                      .map(f => (
                        <span>{f(onSubmit)}</span>
                      ))}
                    <ReactActions
                      id={`sidepanel_top_actions`}
                      size="small"
                      items={rightTopActions
                        .filter((ot, ind) => ind >= actionsLength)
                        .map((item, index) => (
                          <span key={index}>{item(onSubmit)}</span>
                        ))}
                      keepOpen={false}
                    ></ReactActions>
                  </React.Fragment>
                )}
                {useSwithActions && switchState && (
                  <React.Fragment>
                    <SwitchActions
                      target={switchState.target}
                      getProps={switchState.getProps}
                    />
                  </React.Fragment>
                )}
                {closable && useSidePanel && (
                  <IconButton
                    aria-label="close"
                    title={t(translations.Close)}
                    onClick={closePanel}
                  >
                    <Icon
                      icon="times"
                      size="lg"
                      color="default"
                      colorExtend="textHover"
                    />
                  </IconButton>
                )}
              </>
            </div>
          </div>
        </div>
        {showProcessing && (
          <Div100>
            <LinearProgress
              variant="query"
              color="primary"
              visible={showProcessing}
            />
          </Div100>
        )}
      </TitleRoot>
    </SkeletonIfLoading>
  );
}
