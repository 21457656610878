/**
 *
 * LoanableFilter
 *
 */
import { LoanablePicker } from 'app/components/pickers/AutocompletePickers/LoanablePicker';
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export const LoanableFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <LoanablePicker
      onChange={handleOnChange}
      value={props.value as Entity<number> | undefined}
      isOpen={props.open}
      fullWidth={props.fullWidth}
      placeholder={t(translations.AllValues)}
      size="small"
      ariaLabel={props.label}
      id={props.id}
    />
  );
};
export default LoanableFilter;
