import { Box } from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Progress } from 'app/components/LoadingIndicator';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import {
  selectConfirmApproved,
  selectConfirmRejected,
  selectContinueState,
  selectContinueToLink,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { selectPublicUrl } from 'app/slice/selectors';
import { push } from 'connected-react-router';
import { Formik, FormikProps } from 'formik';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { buildURL, toQueryString } from 'utils/url-utils';
import { useAppSettingsSlice } from 'app/slice';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';
import { IRegionDto } from '../../IRegionDto';
import { useRegionsSlice } from './slice';
import {
  selectDetailsCompleted,
  selectRegionsData,
  selectRegionsHasErrors,
  selectRegionsProcessing,
} from './slice/selectors';
import { tryParseInt } from 'utils/string-utils';
import { isEmpty } from 'lodash';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormTextField } from 'app/components/Forms/FormTextField';
import * as Yup from 'yup';

export interface RegionDetailsProps extends CoverProps, SidePanelContentProps {
  id?: string;
  initialModel?: IRegionDto;
  onClose?: () => void;
}

export const RegionDetails = React.memo(function RegionDetails(
  props: RegionDetailsProps,
) {
  const { useSidePanel, id, initialModel, onClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useRegionsSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const { actions: appSettingsActions } = useAppSettingsSlice();

  /// Selectors ///
  const processing = useSelector(selectRegionsProcessing);
  const regionData = useSelector(selectRegionsData);
  const continueLink = useSelector(selectContinueToLink);
  const confirmApproved = useSelector(selectConfirmApproved);
  const confirmRejected = useSelector(selectConfirmRejected);
  const continueToOther = useSelector(selectContinueState);
  const publicUrl = useSelector(selectPublicUrl);
  const actionCompleted = useSelector(selectDetailsCompleted);
  const hasError = useSelector(selectRegionsHasErrors);
  /// Refs ///
  const submitFormRef = React.useRef<any>(null);
  const innerFormRef = React.useRef<FormikProps<IRegionDto>>(null);
  //cover
  const [cover, setCover] = React.useState<React.ReactNode>();
  const [firstLoad, setFirstLoad] = React.useState<boolean | undefined>(true);
  const [busy, setBusy] = React.useState<boolean | undefined>(false);
  const [edit, setEdit] = React.useState<boolean | undefined>(false);

  const [requestCompleted, setRequestCompleted] = React.useState<
    boolean | undefined
  >(undefined);

  React.useEffect(() => {
    let active = firstLoad;
    if (active) {
      setFirstLoad(false);
      dispatch(
        actions.initRegion({
          id: tryParseInt(id) ?? -1,
          initial: initialModel,
        }),
      );
    }
    return () => {
      active = false;
    };
  }, [actions, dispatch, firstLoad, initialModel, id]);
  /// functions, callbacks ///
  const closeCover = () => {
    setCover(undefined);
  };
  const handleClose = () => {
    handleCloselClick();
  };
  const handleCloselClick = React.useCallback(() => {
    if (!!onClose) {
      onClose();
    } else {
      if (useSidePanel) {
        dispatch(layoutActions.resetSidePanel());
      } else {
        dispatch(push('/Regions'));
      }
    }
    dispatch(actions.resetRegionState());
  }, [actions, dispatch, layoutActions, onClose, useSidePanel]);
  const handleSaveClick = React.useCallback((e: any) => {
    handleSubmitForm(e);
  }, []);

  const handleSubmitForm = (e: any) => {
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmit = React.useCallback(
    (values: IRegionDto) => {
      setBusy(true);
      if (edit) {
        dispatch(
          actions.updateRegion({
            current: values,
            original: regionData || values,
          }),
        );
      } else {
        dispatch(actions.createRegion({ model: values }));
      }
    },
    [actions, dispatch, edit, regionData],
  );
  /// Use Effects ///
  React.useEffect(() => {
    let active = processing === false;
    if (active) {
      if (id !== undefined && !isNaN(parseInt(id))) {
        setEdit(true);
      } else {
        setEdit(false);
      }
      if (actionCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
        if (!hasError) {
          handleCloselClick();
        }
      } else if (actionCompleted === false) {
        setRequestCompleted(undefined);
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [actionCompleted, handleCloselClick, hasError, processing, id]);
  React.useEffect(() => {
    if (confirmApproved) {
      dispatch(actions.resetRegionState());
      if (continueToOther.continueOnApprove) {
        dispatch(layoutActions.resetSidePanel());
        setTimeout(() => {
          dispatch(
            layoutActions.openSidePanel({
              type: continueToOther.pageType,
              props: continueToOther.pageProps,
              expanded: continueToOther.expanded,
            }),
          );
          dispatch(layoutActions.resetContinueState());
        }, 200);
      } else if (continueLink !== undefined) {
        dispatch(appSettingsActions.navigate(continueLink));
        dispatch(layoutActions.resetSidePanel());
      } else {
        dispatch(layoutActions.resetSidePanel());
      }
      //dispatch(actions.setChangedState(false));
    }
    if (confirmRejected) {
      dispatch(layoutActions.resetConfirmState());
    }

    return () => undefined;
  }, [
    actions,
    appSettingsActions,
    confirmApproved,
    confirmRejected,
    continueLink,
    continueToOther,
    dispatch,
    edit,
    layoutActions,
  ]);
  const regionSchema: Yup.SchemaOf<IRegionDto> = Yup.object({
    Id: Yup.number().notRequired().default(-1),
    Name: Yup.string().required(t(translations.RegionNameIsRequired)),
  });
  const leftActions = React.useMemo(() => {
    let actions: ActionRenderer[] = [];
    actions.push(() => (
      <Button
        size="small"
        startIcon={<Icon icon="save" />}
        onClick={handleSaveClick}
        disabled={busy}
        processing={!requestCompleted && busy}
      >
        {t(translations.Save)}
      </Button>
    ));
    return actions;
  }, [busy, handleSaveClick, requestCompleted, t]);
  const rightActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <Button
            variant="ghost"
            size="small"
            startIcon={<Icon icon="times" />}
            onClick={handleCloselClick}
          >
            {t(translations.Close)}
          </Button>
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [handleCloselClick, t]);
  return (
    <React.Fragment>
      <PageWrapper
        pageName={
          edit
            ? t(translations.menu_EditRegion)
            : t(translations.menu_AddNewRegion)
        }
        titlePage={
          regionData
            ? edit
              ? ` ${t(translations.Region)} #${id || 'N/A'}`
              : ` ${t(translations.AddRegion)}`
            : undefined
        }
        loading={processing}
        useSidePanel={useSidePanel}
        closable={useSidePanel || props.isCover}
        disableExpandToggle={props.isCover}
        closeSidePanel={props.isCover ? onClose : handleClose}
        leftActions={leftActions}
        children={
          regionData !== undefined && !processing ? (
            <Formik
              validationSchema={regionSchema}
              initialValues={regionData}
              validateOnMount={true}
              validateOnBlur={false}
              validateOnChange={true}
              enableReinitialize
              innerRef={innerFormRef}
              onSubmit={async (values, formikHelpers) => {
                // call setSubmit to finish submit cycle
                formikHelpers.validateForm(values).then(responseErrors => {
                  if (!isEmpty(responseErrors)) {
                    formikHelpers.setSubmitting(false);
                    console.debug('errors', responseErrors);
                  } else {
                    console.debug('submitting', values);
                    formikHelpers.setSubmitting(true);
                    handleSubmit(values);
                  }
                });
              }}
            >
              {formik => {
                bindSubmitForm(formik.submitForm);
                return (
                  <React.Fragment>
                    <StyledForm onSubmit={formik.handleSubmit}>
                      <FormLeftSection>
                        <FormFieldsSection
                          titleSection={t(translations.RegionDetails) as string}
                        >
                          <FormRow fullRow>
                            <FormTextField
                              name="Name"
                              label={t(translations.RegionName)}
                              disabled={formik.isSubmitting}
                              fullWidth
                            />
                          </FormRow>
                        </FormFieldsSection>
                      </FormLeftSection>
                    </StyledForm>
                  </React.Fragment>
                );
              }}
            </Formik>
          ) : (
            <React.Fragment>
              {' '}
              <Box component="div">
                <Progress inProgress={processing} size={80} />
              </Box>
            </React.Fragment>
          )
        }
        pageLink={buildURL(publicUrl + 'regions/details', { id: id })}
        toPageLink={`regions/details?${toQueryString({ id: id })}`}
        rightActions={rightActions}
        isCover={props.isCover}
        cover={cover}
        closeCover={props.closeCover || closeCover}
      />
    </React.Fragment>
  );
});
