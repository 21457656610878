import styled from 'styled-components';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuItemProps,
} from '@material-ui/core';
import { IconProp } from 'app/AllIcons';
import { TFunctionResult } from 'i18next';
import { translations } from 'locales/translations';
import {
  bindMenu,
  usePopupState,
  bindTrigger,
} from 'material-ui-popup-state/hooks';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PluginHook } from 'react-table';
import { IPathAndQuery, toRootedURL } from 'utils/url-utils';
import { IRow } from '.';
import { RouterLink } from '../BasicLinks/RouterLink';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import { Tooltip } from '../BasicTooltips/Tooltip';
import { CellRenderer } from './BasicTableProps';
import { ButtonsVariant } from '../BasicButtons/Button';
import BasicTypography from '../Typography/BasicTypography';

export interface RowActionsProps {
  href?: string | IPathAndQuery;
  to?: string;
  text: string;
  icon: IconProp;
  title?: string;
  target?: string;
  renderAsTitle?: boolean;
  onClick?: () => Promise<void>;
}
export interface RowActionsMenuProps {
  id: string;
  items: RowActionsProps[];
  icon?: IconProp;
  tooltip?: React.ReactNode | TFunctionResult;
  variant?: ButtonsVariant;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  getIsOpen?: () => boolean;
}

const StyledItemIcon = styled(ListItemIcon)`
  min-width: unset;
  min-height: unset;
`;
const ActionsRoot = styled.div`
  &.actions-wrapper {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;
const RowMenuActions = React.forwardRef<any, RowActionsMenuProps>(
  function RowMenuActions(
    { id, items, variant, icon, tooltip, ...props },
    ref,
  ) {
    const { t } = useTranslation();

    const popupState = usePopupState({
      variant: 'popper',
      popupId: id,
    });

    const { onClick, ...popupProps } = {
      ...bindTrigger(popupState),
    };
    const handleClick = event => {
      onClick(event);
      props.onClick?.(event);
    };

    return (
      // TODO: ActionsRoot gets out of alignment in ListItemSecondaryAction. why is it needed here?
      <ActionsRoot className={'actions-wrapper'}>
        <Tooltip title={popupState.isOpen ? undefined : tooltip}>
          <IconButton
            variant={variant || 'ghost'}
            aria-label={t(translations.OpenMenu)}
            size="xs"
            onClick={handleClick}
            {...popupProps}
          >
            <Icon icon={icon ?? 'ellipsis-v'} />
          </IconButton>
        </Tooltip>

        <Menu
          {...bindMenu(popupState)}
          ref={ref}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
        >
          {items.map((item, index) => {
            return (
              <RowActionMenuItem
                item={item}
                key={index}
                ref={ref}
                onClick={popupState.close}
              />
            );
          })}
        </Menu>
      </ActionsRoot>
    );
  },
);

export interface RowActionMenuItemProps
  extends Pick<MenuItemProps, 'key' | 'dense' | 'onClick'> {
  item: RowActionsProps;
}
export const RowActionMenuItem = React.forwardRef<
  HTMLLIElement,
  RowActionMenuItemProps
>(function RowActionMenuItem(
  { item, onClick, ...props }: RowActionMenuItemProps,
  ref,
) {
  const toAbsolutePath = (href: string | IPathAndQuery) => {
    return typeof href === 'string'
      ? toRootedURL(href)
      : toRootedURL(
          (href as IPathAndQuery).path,
          (href as IPathAndQuery).search,
        );
  };
  return item.renderAsTitle ? (
    <MenuItem component={'li'} ref={ref} {...props}>
      <BasicTypography variant="boldS">{item.text}</BasicTypography>
    </MenuItem>
  ) : item.href ? (
    <MenuItem
      component={'li'}
      ref={ref}
      onClick={e => {
        if (item.href !== undefined) {
          let url = toAbsolutePath(item.href);
          window.open(url, '_blank');
        }
        onClick?.(e);
      }}
    >
      <StyledItemIcon>
        <Icon icon={item.icon} />
      </StyledItemIcon>
      <ListItemText>{item.text}</ListItemText>
    </MenuItem>
  ) : item.to ? (
    <MenuItem
      component={RouterLink}
      to={item.to}
      ref={ref}
      onClick={onClick}
      {...props}
    >
      <StyledItemIcon>
        <Icon icon={item.icon} />
      </StyledItemIcon>
      <ListItemText>{item.text}</ListItemText>
    </MenuItem>
  ) : (
    <MenuItem
      ref={ref}
      {...props}
      onClick={async e => {
        onClick?.(e);
        if (item.onClick) {
          await item.onClick();
        }
      }}
    >
      <StyledItemIcon>
        <Icon icon={item.icon} />
      </StyledItemIcon>
      <ListItemText>{item.text}</ListItemText>
    </MenuItem>
  );
});
export function RowActionsMenu(props: RowActionsMenuProps) {
  return <RowMenuActions {...props} />;
}
export function useRowActionsColumn<TRow extends IRow>(
  rowActions: CellRenderer<TRow>[] | undefined,
  tablePlugins: PluginHook<TRow>[],
) {
  const { t } = useTranslation();
  if (rowActions !== undefined) {
    if (rowActions.length > 0) {
      const actionColumns = rowActions?.map((column, index) => ({
        id: `ActionColumn_${index}`,
        Header: t(translations.Action),
        disableSortBy: true,
        Cell: column,
      }));
      tablePlugins.push(hooks => {
        hooks.visibleColumns.push(columns => [...columns, ...actionColumns]);
      });
    }
  }
}
export function useInlineRowActionsColumn<TRow extends IRow>(
  inlineRowActions: CellRenderer<TRow>[] | undefined,
  tablePlugins: PluginHook<TRow>[],
) {
  const { t } = useTranslation();
  if (inlineRowActions !== undefined) {
    if (inlineRowActions.length > 0) {
      const actionColumns = inlineRowActions?.map((column, index) => ({
        id: `InlineActionColumn_${index}`,
        Header: t(translations.Action),
        disableSortBy: true,
        Cell: column,
      }));
      tablePlugins.push(hooks => {
        hooks.visibleColumns.push(columns => [...columns, ...actionColumns]);
      });
    }
  }
}
