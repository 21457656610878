import { httpClient } from 'api/HttpClient';
import { IBudgetExperimentsFilterDto } from 'api/odata/generated/entities/IBudgetExperimentsFilterDto';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import {
  Condition,
  ODataOperators,
  quoteODataValue,
} from 'api/odata/ODataFilter';
import {
  AutocompletePickerProps,
  AutocompletePicker,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { BudgetExperimentStatuses } from 'enums/BudgetExperimentStatuses';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { useSelector } from 'react-redux';
import { Entity, Identifiable } from 'types/common';
import { isNullOrUndefined } from 'utils/typeUtils';

export function budgetExperimentsFilter(
  serviceTypes: IServiceTypeFilterDto[],
  serviceType: ServiceType | undefined,
  budgetId: number | undefined,
  resStart: Date | string | null | undefined,
  resEnd: Date | string | null | undefined,
): (string | Condition<IBudgetExperimentsFilterDto>)[] {
  const predicates: (string | Condition<IBudgetExperimentsFilterDto>)[] = [];

  predicates.push(
    new Condition<IBudgetExperimentsFilterDto>(
      'Active',
      ODataOperators.Equals,
      true,
    ),
  );

  predicates.push(
    new Condition<IBudgetExperimentsFilterDto>(
      'StatusId',
      ODataOperators.Equals,
      BudgetExperimentStatuses.Approved,
    ),
  );

  predicates.push(
    new Condition<IBudgetExperimentsFilterDto>(
      'BudgetTaskActive',
      ODataOperators.Equals,
      true,
    ),
  );

  if (!isNullOrUndefined(budgetId)) {
    predicates.push(
      new Condition<IBudgetExperimentsFilterDto>(
        'BudgetTaskBudgetId',
        ODataOperators.Equals,
        budgetId,
      ),
    );
  }

  if (serviceTypes.length > 0) {
    let ids = serviceTypes
      .filter(f => f.ServiceGroupId !== null)
      .map(f => {
        return { Id: f.ServiceGroupId } as Identifiable<number>;
      });
    if (ids.length > 0) {
      predicates.push(
        `(${new Condition<IBudgetExperimentsFilterDto>(
          'BudgetTaskAllCoresAllowed',
          ODataOperators.Equals,
          true,
        )} or ${new Condition<IBudgetExperimentsFilterDto>(
          'BudgetTaskServiceGroupIds',
          ODataOperators.AnyIn,
          ids,
        )})`,
      );
      predicates.push(
        `(BudgetExperimentServiceGroups/any(s: s/ServiceGroupId in ${quoteODataValue(
          ids,
        )} and s/StatusId eq ${quoteODataValue(
          BudgetExperimentStatuses.Approved,
        )}))`,
      );
    }
  }

  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}

export interface BudgetExperimentsPickerProps
  extends Omit<
    AutocompletePickerProps<IBudgetExperimentsFilterDto>,
    'loadData'
  > {
  username?: string;
  name?: string;
  placeholder?: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  error?: boolean;
  helperText?: string;
}
const url = '/api/odata/v4/BudgetExperimentsFilter';
export const initBudgetExperimentData = (
  initval: string | undefined,
  username: string,
) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(`${url}?username=${username}`, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (
  predicates: (string | Condition<IBudgetExperimentsFilterDto>)[] | undefined,
  username: string,
) =>
  getAutoCompleteLoadDataFn<IBudgetExperimentsFilterDto>({
    url: `${url}?username=${username}`,
    predicates: predicates,
    select: ['Id', 'Name', 'Active', 'StatusId'],
  });
export function BudgetExperimentsPicker(props: BudgetExperimentsPickerProps) {
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const { username } = props;
  const innerLoadData = loadData(
    props.predicates,
    username || authenticatedUser?.Id || '',
  );

  return (
    <AutocompletePicker
      loadData={innerLoadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'budgetexperimentsfilterId'}
      {...props}
    />
  );
}
export const loadBudgetExperiments = loadData;
