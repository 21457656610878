/**
 *
 * RegionDetailsPage
 *
 */
import { GetRawValue } from 'app/components/BasicTable/types/FilterParam';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { RegionDetails } from './Details';

export interface RegionDetailsPageProps
  extends RouteComponentProps<{ id: string }> {}
export function RegionDetailsPage(props: RegionDetailsPageProps) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const regionId =
    props.match.params['id'] || (GetRawValue(params, 'id') ?? undefined);
  return <RegionDetails id={regionId} useSidePanel={false} />;
}
