import { useAppSettingsSlice } from 'app/slice';
import { selectIsExpandedSideBar } from 'app/slice/selectors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from 'utils/userProfileSettings';
import { useIsMobile } from './useIsMobile';
interface ExpandedType {
  expand: (value: boolean) => void;
  expandLocal: (value: boolean) => void;
  toggle: () => void;
}
export function userProfileComparer(
  prev: readonly [UserProfile],
  next: readonly [UserProfile],
) {
  if (!!prev[0] && !!prev[0].savedSettings) {
    if (prev[0].savedSettings.length === next[0].savedSettings.length) {
      let prevExpanded = prev[0].GetSideBarExpanded('SideBarExpanded');
      let nextExpanded = next[0].GetSideBarExpanded('SideBarExpanded');
      if (prevExpanded !== nextExpanded) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  return false;
}
function useExpandedSideBarState(defaultValue?: boolean): ExpandedType {
  const { checkIsMobile } = useIsMobile();
  const isMobile = checkIsMobile();
  const isExpanded = useSelector(state =>
    selectIsExpandedSideBar(state, isMobile),
  );
  //const userProfileSett = useSelector(selectUserProfileSettings);
  // const [expanded, setExpanded] = React.useState<boolean>(
  //   !!defaultValue ? defaultValue : isExpanded,
  // );
  // const [lastExpandedTime, setLastExpandedTime] = React.useState<
  //   number | undefined
  // >(undefined);
  // const userProfileSettCompared = useCustomCompareMemo(
  //   () => {
  //     return userProfileSett;
  //   },
  //   [userProfileSett],
  //   userProfileComparer,
  // );
  // const userProfExpanded = React.useMemo<boolean | undefined>(() => {
  //   return userProfileSettCompared?.GetSideBarExpanded('SideBarExpanded');
  // }, [userProfileSettCompared]);
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  // useEffectOnMount(() => {
  //   let defaultval = isMobile
  //     ? !!defaultValue
  //       ? defaultValue
  //       : isExpanded
  //     : userProfExpanded ?? true;
  //   dispatch(actions.updateExpandedState_Local(defaultval));
  //   // if (isMobile) {
  //   //   setExpanded(isExpanded);
  //   // } else {
  //   //   setExpanded(userProfExpanded ?? true);
  //   // }
  // });
  // React.useEffect(() => {
  //   if (isMobile) {
  //     setExpanded(isExpanded);
  //   } else {
  //     setExpanded(userProfExpanded ?? true);
  //   }
  // }, [isExpanded, isMobile, userProfExpanded]);

  const expand = React.useCallback(
    (value: boolean) => {
      dispatch(actions.updateExpandedState_Local(value));
      if (!isMobile) {
        dispatch(actions.updateExpandedState(value));
      }
      // if (value === true) {
      //   setLastExpandedTime(new Date().getTime());
      // }
    },
    [actions, dispatch, isMobile],
  );
  const expandLocal = React.useCallback(
    (value: boolean) => {
      dispatch(actions.updateExpandedState_Local(value));
      // if (value === true) {
      //   setLastExpandedTime(new Date().getTime());
      // }
    },
    [actions, dispatch],
  );
  const toggle = React.useCallback(() => {
    dispatch(actions.updateExpandedState_Local(!isExpanded));
    if (!isMobile) {
      dispatch(actions.updateExpandedState(!isExpanded));
    }
    // if (!lastExpandedTime) {
    //   dispatch(actions.updateExpandedState_Local(!isExpanded));
    //   if (!isMobile) {
    //     dispatch(actions.updateExpandedState(!isExpanded));
    //   }
    //   if (isExpanded === true) {
    //     setLastExpandedTime(new Date().getTime());
    //   }
    // } else {
    //   if (!!lastExpandedTime && new Date().getTime() - lastExpandedTime > 500) {
    //     dispatch(actions.updateExpandedState_Local(!isExpanded));
    //     if (!isMobile) {
    //       dispatch(actions.updateExpandedState(!isExpanded));
    //     }
    //   }
    // }
  }, [actions, dispatch, isExpanded, isMobile]);

  return { expand, expandLocal, toggle };
}

export default useExpandedSideBarState;
