import { SiteMapNode } from 'types/SiteMapSettings';
import { convertUrl } from './convertUrl';

/**
 * Extracts link from SiteMapNode.
 * The link can be stored either in Url, reactUrl or LinkWithChildren properties.
 * @param item SiteMapNode
 * @returns link
 */

export function getLink(item: SiteMapNode) {
  if (item.ChildNodes?.length > 0) {
    return item.LinkWithChildren ?? undefined;
  }
  return item.reactUrl ?? convertUrl(item.Url) ?? undefined;
}
