import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { RequestStatusesApi } from 'api/RequestStatusesApi';
import { IRequestStatusesDto } from 'app/pages/RequestStatuses/RequestStatusesPage/IRequestStatusesDto';
import { appSettingsActions } from 'app/slice';
import {
  ConvertEntityToModelRequestStatusesDetails,
  RequestStatusesDetailsQStringParameters,
  RequestStatusesDetailsResponse,
  RequestStatusesDetailsState,
} from './types';
import { requestStatusesDetailsActions as actions } from '.';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import { translations } from 'locales/translations';
import i18next from 'i18next';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { RequestStatusesDetailsProps } from '..';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { AxiosError } from 'axios';

function* doInitUpdate(
  action: PayloadAction<RequestStatusesDetailsQStringParameters>,
) {
  try {
    const data = yield call(RequestStatusesApi.initUpdateData, action.payload);
    yield put(actions.initUpdateRequestStatusesDetails_Success(data));
  } catch (error: unknown) {
    yield put(actions.initUpdateRequestStatusesDetails_Error(Error));
  }
}

function* doUpdate(
  action: PayloadAction<{
    current: RequestStatusesDetailsState;
    original: RequestStatusesDetailsState | undefined;
  }>,
) {
  const httpPayloads: IRequestStatusesDto =
    ConvertEntityToModelRequestStatusesDetails(
      action.payload.current,
      action.payload.original,
    );
  try {
    const results = yield call(
      RequestStatusesApi.updateRequestStatusesDetails,
      httpPayloads,
    );
    let response = results as RequestStatusesDetailsResponse;
    let respErrors = response.ErrorMessages;
    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: 'RequestStatusesUpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'RequestStatusesUpdateWarn',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotification({
          key: 'RequestStatusesUpdate',
          message: response.SuccessMessages[0],
          messageType: SnackBarMessageType.openSidepanelDetails,
          messageTypeProps: {
            Id: response.Id,
            created: false,
            itemName: i18next.t(translations.menu_RequestStatuses),
            detailsType: RenderPageType.RequestStatusesDetails,
            detailsTypeProps: {
              queryParams: {
                id: '' + response.Id,
              } as RequestStatusesDetailsQStringParameters,
              useSidePanel: true,
            } as RequestStatusesDetailsProps,
          },
          variant: 'success',
        }),
      );
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.updateRequestStatusesDetails_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'RequestStatusesUpdateErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateRequestStatusesDetails_Error(Error));
  }
}

export function* requestStatusesDetailsSaga() {
  yield takeLatest(actions.initUpdateRequestStatusesDetails.type, doInitUpdate);
  yield takeLatest(actions.updateRequestStatusesDetails.type, doUpdate);
}
