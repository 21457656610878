/**
 *
 * BasicDialogTitle
 *
 */
import { DialogTitle as MuiDialogTitle } from '@material-ui/core';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import BasicTypography from '../Typography/BasicTypography';
export interface BasicDialogTitleProps {
  id?: string;
  children: React.ReactNode;
  subtitle?: React.ReactNode;
  onClose?: () => void;
}
export function BasicDialogTitle(props: BasicDialogTitleProps) {
  const { id, children, subtitle, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={'basic-dialog-title'}
      {...other}
    >
      <div className={'title-container'}>
        <div className={'title-body'}>
          <BasicTypography variant="h3" bold={true}>
            {children}
          </BasicTypography>
        </div>
        {subtitle && (
          <div className={'title-body-subtitle'}>
            <BasicTypography variant="bodyM">{subtitle}</BasicTypography>
          </div>
        )}

        {onClose ? (
          <IconButton
            aria-label="close"
            className={'close-button'}
            onClick={onClose}
          >
            <Icon icon="xmark" />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
}
