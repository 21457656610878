/**
 *
 * GradientsPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { IGradientsDto } from 'api/odata/IGradientsDto';
import { Condition } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { Entity } from 'types/common';

export interface GradientsPickerProps
  extends Omit<AutocompletePickerProps<Entity<number>>, 'loadData'> {}

const url = '/api/odata/v4/Gradients';
export const initGradientsData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select:
        'Id,Name,ServiceGroupId,ServiceGroupName,EstimatedRuntimeMinutes',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (
  predicates: (string | Condition<IGradientsDto>)[] | undefined,
) => {
  return getAutoCompleteLoadDataFn<IGradientsDto>({
    url: url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: [
      'Id',
      'Name',
      'ServiceGroupId',
      'ServiceGroupName',
      'EstimatedRuntimeMinutes',
    ],
    globalServiceGroupFilter: [],
    isOptionalServiceGroup: true,
  });
};
export function GradientsPicker(props: GradientsPickerProps) {
  const innerLoadData = loadData(props.predicates);
  return (
    <AutocompletePicker
      size={props.size}
      loadData={innerLoadData}
      {...props}
      id={props.id || 'gradientsId'}
    />
  );
}
