import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { PurchaseOrderOptions } from 'api/odata/generated/enums/PurchaseOrderOptions';
import {
  FeeTypeEntitySchema,
  OfflineServiceTypeEntitySchema,
  OnlineServiceEntitySchema,
  UserEntitySchema,
  UserGroupEntitySchema,
} from 'app/components/Forms/Schemas';
import { InvoiceChildRowBaseSchema } from 'app/components/Forms/Schemas/InvoiceChildRowBaseSchema';
import { CatRateTypes } from 'enums/CatRateTypes';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Entity } from 'types/common';
import * as Yup from 'yup';
import { ICharge } from '../AddChargePage/ICharge';
import { GlobalSettingsType } from '../ReservationDetails/Details/components/useGlobalSettingsHook';

export function GetChargeSchema(
  t: TFunction,
  isEdit: boolean,
  globalSettings: GlobalSettingsType,
): Yup.SchemaOf<ICharge> {
  const result = InvoiceChildRowBaseSchema(t).concat(
    Yup.object({
      User: UserEntitySchema.nullable().notRequired(),
      Resource: OnlineServiceEntitySchema.notRequired().nullable(),
      OtherService: OfflineServiceTypeEntitySchema.notRequired().nullable(),
      UserGroup: UserGroupEntitySchema.nullable().when(
        'User',
        (_, schema: Yup.SchemaOf<Entity<string>>) =>
          isEdit ? schema.notRequired() : schema.required(),
      ),
      SampleType: OfflineServiceTypeEntitySchema.notRequired().nullable(),
      FeeType: FeeTypeEntitySchema.required().nullable(),
      Quantity: Yup.number().defined(),
      Rate: Yup.number()
        .defined()
        .when('FeeType', (catRateType, schema: Yup.NumberSchema) => {
          return (catRateType as Entity<number>)?.Id === CatRateTypes.Refund ||
            (catRateType as Entity<number>)?.Id === CatRateTypes.Discount
            ? schema.negative().max(0)
            : schema.positive().min(0);
        }),
      Amount: Yup.number()
        .defined()
        .when('FeeType', (catRateType, schema: Yup.NumberSchema) => {
          return (catRateType as Entity<number>)?.Id === CatRateTypes.Refund ||
            (catRateType as Entity<number>)?.Id === CatRateTypes.Discount
            ? schema.negative().max(0)
            : schema.positive().min(0);
        }),
      Manual: Yup.bool().notRequired(),
      ActualRate: Yup.number().notRequired(),
      Remarks: Yup.string().nullable().default('').notRequired(),
      FundingType: Yup.mixed().label(t(translations.FundingType) as string),
      PurchaseOrder: Yup.string()
        .label(t(translations.PurchaseOrder) as string)
        .nullable(true)
        .min(globalSettings.purchaseOrderLengthSetting ?? 0)
        .when('Budget', {
          is: budget => {
            const result =
              globalSettings.purchaseOrderBudgetEnabled &&
              (budget as IBudgetFilterDto)?.PurchaseOrderOptionId ===
                PurchaseOrderOptions.Mandatory;
            return result;
          },
          then: schema => schema.required(),
        }),
    }),
  );
  return result;
}
