import { InstrumentRestrictions } from 'app/pages/InstrumentRestrictionsPage/Details/InstrumentRestrictions';

export interface AssetBookingRulesProps {
  serviceId?: number;
  serviceGroupId?: number;
  user?: string;
  useShortView?: boolean;
  useSidePanel?: boolean;
}

export function AssetBookingRules(props: AssetBookingRulesProps) {
  return (
    <InstrumentRestrictions
      serviceId={props.serviceId}
      serviceGroupId={props.serviceGroupId}
      initUser={props.user}
      useSidePanel={props.useSidePanel ?? false}
      showTable={false}
      assetPickerDisabled={true}
      withNoPadding={true}
      hideEquipmentFormTitle={true}
      useShortView={props.useShortView}
      withNoWrapper
    />
  );
}
