import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'utils/url-utils';
import { SelectedRowsActionIconButton } from '../BasicTable/SelectedRowsActionButton/SelectedRowsActionIconButton';
import { Identifiable } from 'types/common';

export interface ExportQRCodesButtonProps {
  url: string;
  rows: Array<Identifiable<number>>;
  toggleAllRowsSelected?: (value?: boolean) => void;
  disabled?: boolean;
}

export function ExportQRCodesButton({
  url,
  rows,
  toggleAllRowsSelected,
  disabled,
}: ExportQRCodesButtonProps) {
  const { t } = useTranslation();
  return (
    <SelectedRowsActionIconButton
      key="downloadQR"
      text={t(translations.ExportQR)}
      title={t(translations.ExportQR)}
      confirm={false}
      validConfirm={true}
      confirmTitle={t(translations.ExportQR) as string}
      rows={rows}
      toggleAllRowsSelected={toggleAllRowsSelected}
      variantButton="button"
      disabled={disabled === true}
      action={() =>
        new Promise(async resolve => {
          let ids = rows.map(row => row.Id).join(',');
          let path = url + `?id=${ids}`;
          try {
            await downloadFile({ path: path });
            resolve({
              ErrorMessages: [],
              SuccessMessages: [],
              WarningMessages: [],
            });
          } catch (err) {
            console.debug(err);
            resolve({
              ErrorMessages: [
                'Some thing went wrong, please check the Name or Qr Code of the Asset!',
              ],
              SuccessMessages: [],
              WarningMessages: [],
            });
          }
        })
      }
    />
  );
}
