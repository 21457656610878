import { IModificationsDto } from 'api/odata/generated/entities/IModificationsDto';
import DateTimeRenderer from 'app/components/BasicTable/CellRenderers/DateTimeRenderer';
import ModificationsValueRenderer from 'app/components/BasicTable/CellRenderers/ModificationsValueRenderer';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Column } from 'react-table';

export const getModificationsColumns = (t: TFunction) => {
  const res: Column<IModificationsDto>[] = [
    {
      accessor: 'Field',
      Header: t(translations.Field) as string,
    },
    {
      accessor: 'OldValue',
      Header: t(translations.OldValue) as string,
      Cell: ModificationsValueRenderer,
    },
    {
      accessor: 'NewValue',
      Header: t(translations.NewValue) as string,
      Cell: ModificationsValueRenderer,
    },
    {
      accessor: 'UserDisplayName',
      Header: t(translations.User) as string,
    },
    {
      accessor: 'Date',
      Header: t(translations.Date) as string,
      Cell: DateTimeRenderer,
    },
  ];
  return res;
};
