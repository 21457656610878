import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { FormRow } from 'app/components/Forms/FormsLayout';
import { UserPicker } from 'app/components/pickers/AutocompletePickers/UserPicker';
import { IRestrictedUserPermissionResult } from 'api/odata/generated/entities/IRestrictedUserPermissionResult';
import { Entity } from 'types/common';

import { translations } from 'locales/translations';
import { FormLabelText } from 'app/components/Forms/FormLabelText';
import { Body } from 'app/components/Typography';
import { Skeleton } from '@material-ui/lab';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { BasicAlert } from 'app/components/BasicAlert';
import { SimpleList } from 'app/components/BasicList/StyledList';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { PermissionsWrapper } from './styled';

export interface UserOwnPermissionsProps {
  user: Entity<string> | null;
  isAdmin?: boolean;
  onUserChange: (value: Entity<string> | null) => void;
  permissions: IRestrictedUserPermissionResult[];
  permLoading: boolean;
  restrictedOption: boolean | null;
  EquipmentName: string;
  serviceGroupId: number;
  serviceId: number;
}

export const UserOwnPermissions = React.memo(function UserOwnPermissions(
  props: UserOwnPermissionsProps,
) {
  const {
    user,
    isAdmin,
    onUserChange,
    permissions,
    permLoading,
    restrictedOption,
    EquipmentName,
    serviceGroupId,
    serviceId,
  } = props;
  const { t } = useTranslation();
  const handleUserChange = React.useCallback(
    (value: Entity<string> | null) => {
      onUserChange(value);
    },
    [onUserChange],
  );
  const userIsAdmin = React.useMemo(() => {
    if (user === null) {
      return false;
    } else {
      return (
        (user as IUserFilterDto)?.ServiceGroupIds?.some(
          f => f === serviceGroupId,
        ) ||
        (user as IUserFilterDto)?.Services?.some(f => f.Id === serviceId) ||
        (user as IUserFilterDto)?.IsAdminPanel
      );
    }
  }, [serviceGroupId, serviceId, user]);
  return (
    <React.Fragment>
      {isAdmin && (
        <FormRow>
          <UserPicker
            label={t(translations.ShowRestrictionsFor)}
            placeholder={t(translations.PleaseSelectAUser)}
            id="specificPermissions"
            name="userPermissions"
            disableClearable={false}
            clearOnBlur={false}
            variant="filled"
            fullWidth={true}
            value={user as IUserFilterDto}
            onChange={value => handleUserChange(value)}
            selectArray={[
              'Id',
              'Name',
              'UserGroups',
              'Budgets',
              'IsAdminPanel',
              'IsEquipmentAdmin',
              'IsGroupAdmin',
              'IsLabTech',
              'Services',
              'ServiceGroupIds',
            ]}
          />
        </FormRow>
      )}

      {user !== null && (
        <FormRow>
          <FormLabelText
            name="permissions"
            html={
              <PermissionsWrapper className={'perm-root'}>
                {permLoading ? (
                  <PermissionsWrapper key="loading" className={'perm-root'}>
                    <Skeleton key={'first'} width="100%" variant="text" />
                    <Skeleton key={'last'} width="100%" variant="text" />
                  </PermissionsWrapper>
                ) : restrictedOption === null && !userIsAdmin ? (
                  <BasicAlert
                    variant="standard"
                    content={{
                      type: 'warning',
                      message: (
                        t(translations.res_err_Permissions_StaffOnly) as string
                      ).replace('{0}', EquipmentName),
                      closable: false,
                    }}
                  />
                ) : permissions.length > 0 ? (
                  <div>
                    {permissions.filter(x => x.Valid === true).length > 0 && (
                      <React.Fragment>
                        <Body size="small" bold={true}>
                          {'Allowed on:'}
                        </Body>
                        <SimpleList key={'allowedon'} className={'perm-list'}>
                          {permissions
                            .filter(x => x.Valid === true)
                            .map(item => (
                              <li
                                key={`allowed-${item.TimeSlotTypeId}`}
                                className={'perm-list-item'}
                              >
                                <React.Fragment>
                                  <Icon
                                    icon="circle"
                                    fontSize="6px"
                                    color="primary"
                                  />
                                  <span className={'text-over'}>
                                    <Body
                                      size="small"
                                      key={`body-${item.TimeSlotTypeId}`}
                                    >{`${item.PermissionName} - ${item.TimeSlotTypeName}`}</Body>
                                  </span>
                                </React.Fragment>
                              </li>
                            ))}
                        </SimpleList>
                      </React.Fragment>
                    )}
                    {permissions.filter(x => x.Valid === false).length > 0 && (
                      <React.Fragment>
                        <Body size="small" bold={true}>
                          {'Not allowed on:'}
                        </Body>
                        <SimpleList
                          key={'notallowedon'}
                          className={'perm-list'}
                        >
                          {permissions
                            .filter(x => x.Valid === false)
                            .map((item, index) => (
                              <li
                                key={`notallowed-${item.TimeSlotTypeName}`}
                                className={'perm-list-item'}
                              >
                                <React.Fragment>
                                  <Icon
                                    icon="circle"
                                    fontSize="6px"
                                    color="primary"
                                  />
                                  <span className={'text-over'}>
                                    <Body
                                      size="small"
                                      key={`body-${item.TimeSlotTypeName}`}
                                    >{`${item.TimeSlotTypeName}`}</Body>
                                  </span>
                                </React.Fragment>
                              </li>
                            ))}
                        </SimpleList>
                      </React.Fragment>
                    )}
                  </div>
                ) : (
                  <Body size="small" bold={true}>
                    {t(translations.NoAdditionalRestrictionsFound)}
                  </Body>
                )}
              </PermissionsWrapper>
            }
          />
        </FormRow>
      )}
    </React.Fragment>
  );
});
