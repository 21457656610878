import * as React from 'react';
import { usePrevious } from './usePrevious';
import { compareDeps } from './useDebugEffect';
import { getLogger } from 'utils/logLevel';

/**
 * Debug helper to track dependency changes in React.useMemo hook
 * Example:
 * ```
 * useDebugMemo(()=>...,[dependencies])
 * ```
 * @param factory
 * @param deps
 * @param dependencyNames optinal dependency names arg
 * @returns
 */

export function useDebugMemo<T>(
  factory: () => T,
  deps: React.DependencyList | undefined,
  dependencyNames: string[] = [],
): T {
  // keep track of previous values
  const previousDeps = usePrevious(deps);

  if (deps !== undefined) {
    // find change values using shallow comparison
    const changedDeps = compareDeps(deps, previousDeps, dependencyNames);
    if (Object.keys(changedDeps).length) {
      log.debug(changedDeps);
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(factory, deps);
}

export const log = getLogger('useDebugMemo');
