/**
 * Handy little function to filter out empty elements in array (null/undefined) while preserving type checking
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * Example:
 * ```ts
 *   const array: (string | null)[] = [1, 2, undefined, 3];
 *   const filteredArray: string[] = array.filter(notEmpty);
 * ```
 *
 * ```
 * const array: (number | undefined)[] = [1, 2, undefined, 3];
 * const filteredArray: number[] = array.filter(x => x !== undefined);
 *  Type '(number | undefined)[]' is not assignable to type 'number[]'.
 *    Type 'number | undefined' is not assignable to type 'number'.
 *      Type 'undefined' is not assignable to type 'number'.ts(2322) *
 * ```
 * @param value
 * @returns
 */

export function isDefined<TValue>(value: TValue | undefined): value is TValue {
  return value !== undefined;
}
