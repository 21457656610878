import { Badge, styled } from '@material-ui/core';
import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';

export interface TopGroupButtonProps extends ButtonProps {
  savedServiceGroups: Entity<number>[] | undefined;
  firstServiceGroupName: string | undefined;
  fullWidth?: boolean;
}

export default function TopGroupButton(props: TopGroupButtonProps) {
  const {
    children,
    savedServiceGroups,
    firstServiceGroupName,
    fullWidth,
    ...other
  } = props;
  const { t } = useTranslation();
  return (
    <Tooltip
      title={
        savedServiceGroups !== undefined && savedServiceGroups?.length === 1
          ? `${firstServiceGroupName}`
          : savedServiceGroups !== undefined && savedServiceGroups?.length > 1
          ? `${firstServiceGroupName} +${savedServiceGroups?.length - 1}`
          : undefined
      }
      arrow
      placement="top"
    >
      <span>
        <Button
          disableRipple
          variant="white"
          size="small"
          fullWidth={fullWidth}
          {...other}
        >
          {savedServiceGroups === undefined ||
          savedServiceGroups.length === 0 ? (
            <React.Fragment>{t(translations.AllServiceGroups)}</React.Fragment>
          ) : savedServiceGroups.length === 1 ? (
            <TruncatedLabel>{firstServiceGroupName}</TruncatedLabel>
          ) : (
            <Badge
              badgeContent={
                savedServiceGroups !== undefined &&
                savedServiceGroups?.length > 1
                  ? `+${savedServiceGroups?.length - 1}`
                  : undefined
              }
            >
              <TruncatedLabel>{firstServiceGroupName}</TruncatedLabel>
            </Badge>
          )}
        </Button>
      </span>
    </Tooltip>
  );
}
const TruncatedLabel = styled('span')({
  //width: '10ch',
  display: 'inline-block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
