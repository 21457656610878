import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { AvailabilityTypes } from 'api/odata/generated/enums/AvailabilityTypes';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { AuthenticatedUser } from 'types/AuthenticatedUser';

export enum RenderTypes {
  AddToList = 0,
  AddWorkOrder,
  DownWorkOrder,
  BringBackUp,
  OpenCalendar,
  OpenTimeline,
  OpenWorkOrder,
  OpenWorkOrders,
  NewReservation,
  CloneEquipments,
  DownloadImages,
  ExportQRCodes,
  PrintAssetLabels,
  ExportForImport,
  UpgradeLocalApp,
}

export const AllowedToRender = (
  user: AuthenticatedUser | undefined,
  renderType: RenderTypes,
  asset: Pick<
    IAssetDto,
    'ServiceGroupId' | 'ServiceTypeId' | 'ServiceId' | 'Availability'
  >,
) => {
  return (
    AllowedByRolePermissions(user, renderType, asset) &&
    AvailableByServiceType(asset.ServiceTypeId ?? -1, renderType) &&
    AvailableByAvailabilityType(
      asset.Availability !== undefined ? asset.Availability.Id : -1,
      renderType,
    )
  );
};
export const AllowedByRolePermissions = (
  user: AuthenticatedUser | undefined,
  renderType: RenderTypes,
  asset: Pick<
    IAssetDto,
    'ServiceGroupId' | 'ServiceTypeId' | 'ServiceId' | 'Availability'
  >,
) => {
  switch (renderType) {
    case RenderTypes.AddToList:
    case RenderTypes.AddWorkOrder:
    case RenderTypes.OpenCalendar:
    case RenderTypes.OpenTimeline:
    case RenderTypes.OpenWorkOrder:
    case RenderTypes.OpenWorkOrders:
    case RenderTypes.NewReservation:
      return user?.IsReadOnlyUser() === false;
    case RenderTypes.DownWorkOrder:
    case RenderTypes.BringBackUp:
    case RenderTypes.ExportQRCodes:
    case RenderTypes.PrintAssetLabels:
    case RenderTypes.UpgradeLocalApp:
      if (!user) {
        return false;
      }
      return (
        user.HasAdminGroupPermissions(asset.ServiceGroupId ?? undefined) ||
        user.HasAdminServicePermissions(
          asset.ServiceTypeId ?? 0,
          asset.ServiceId ?? undefined,
        ) ||
        user.HasLabTechGroupPermissions(asset.ServiceGroupId ?? undefined)
      );
    case RenderTypes.CloneEquipments:
    case RenderTypes.DownloadImages:
    case RenderTypes.ExportForImport:
      if (!user) {
        return false;
      }
      return (
        user.HasAdminGroupPermissions(asset.ServiceGroupId ?? undefined) ||
        user.HasAdminServicePermissions(
          asset.ServiceTypeId ?? 0,
          asset.ServiceId ?? 0,
        )
      );
    default:
      return false;
  }
};

export const AvailableByServiceType = (
  serviceType: number,
  renderType: RenderTypes,
) => {
  if (serviceType === -1) {
    return false;
  }
  let serType = serviceType as ServiceType;
  switch (renderType) {
    case RenderTypes.AddToList:
    case RenderTypes.AddWorkOrder:
    case RenderTypes.BringBackUp:
    case RenderTypes.OpenCalendar:
    case RenderTypes.OpenTimeline:
    case RenderTypes.OpenWorkOrder:
    case RenderTypes.OpenWorkOrders:
    case RenderTypes.DownWorkOrder:
    case RenderTypes.NewReservation:
    case RenderTypes.CloneEquipments:
      return serType === ServiceType.Online;
    case RenderTypes.DownloadImages:
    case RenderTypes.ExportForImport:
      return (
        serType === ServiceType.Online ||
        serType === ServiceType.Offline ||
        serType === ServiceType.Sample
      );
    case RenderTypes.ExportQRCodes:
    case RenderTypes.PrintAssetLabels:
      return serType === ServiceType.Online || serType === ServiceType.Offline;
    default:
      return false;
  }
};
export const AvailableByAvailabilityType = (
  availabilityType: number,
  renderType: RenderTypes,
) => {
  if (availabilityType === -1) {
    return false;
  }
  let avType = availabilityType as AvailabilityTypes;
  switch (renderType) {
    case RenderTypes.AddToList:
    case RenderTypes.AddWorkOrder:
    case RenderTypes.BringBackUp:
    case RenderTypes.OpenWorkOrder:
    case RenderTypes.OpenWorkOrders:
    case RenderTypes.DownWorkOrder:
    case RenderTypes.ExportQRCodes:
    case RenderTypes.PrintAssetLabels:
      return [
        //AvailabilityTypes.FreelyBookableBillable,
        //AvailabilityTypes.ApprovalRequiredBillable,
        AvailabilityTypes.FreelyBookable,
        AvailabilityTypes.BookableWApproval,
        AvailabilityTypes.TrainingProgram,
        AvailabilityTypes.LoanableEquipment,
        AvailabilityTypes.LectureRoom,
        AvailabilityTypes.AvailableNotBookable,
        AvailabilityTypes.NotAvailable,
      ].includes(avType);
    case RenderTypes.OpenCalendar:
    case RenderTypes.OpenTimeline:
    case RenderTypes.NewReservation:
    case RenderTypes.CloneEquipments:
      return [
        //AvailabilityTypes.FreelyBookableBillable,
        //AvailabilityTypes.ApprovalRequiredBillable,
        AvailabilityTypes.FreelyBookable,
        AvailabilityTypes.BookableWApproval,
        AvailabilityTypes.TrainingProgram,
        AvailabilityTypes.LoanableEquipment,
        AvailabilityTypes.LectureRoom,
      ].includes(avType);
    case RenderTypes.DownloadImages:
      return true;
    case RenderTypes.ExportForImport:
      return [
        //AvailabilityTypes.FreelyBookableBillable,
        //AvailabilityTypes.ApprovalRequiredBillable,
        AvailabilityTypes.FreelyBookable,
        AvailabilityTypes.BookableWApproval,
        AvailabilityTypes.TrainingProgram,
        AvailabilityTypes.LoanableEquipment,
        AvailabilityTypes.LectureRoom,
        AvailabilityTypes.AvailableNotBookable,
        AvailabilityTypes.NotAvailable,
        AvailabilityTypes.ServiceRelated,
        AvailabilityTypes.RequestRelated,
      ].includes(avType);
    default:
      return false;
  }
};
