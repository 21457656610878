/**
 *
 * DatePickerFilter
 *
 */
import * as React from 'react';
import { IFilterComponentProps } from 'app/components/BasicTable/Filters/IFilterComponentProps';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { CustomDate } from 'types/CustomDate';
import { BookitDatePicker } from 'app/components/BookitDatePicker';

/**
 * Date picker to be used as a BasicTable filter component
 */
export const DatePickerFilter = (props: IFilterComponentProps) => {
  const [value, setValue] = React.useState<MaterialUiPickersDate | null>(
    props.value,
  );
  const handleOnChange = (value: MaterialUiPickersDate | null) => {
    props.onChange(value == null ? null : new CustomDate(value, 'date'));
    setValue(value);
  };

  return (
    <BookitDatePicker
      ariaLabel={props.label || 'Date Filter'}
      id={props.id}
      size="small"
      value={value}
      onChange={handleOnChange}
      fullWidth={props.fullWidth}
      inputVariant="standard"
      clearable={true}
    />
  );
};

export default DatePickerFilter;
