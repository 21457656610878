import {
  BaseURLMultiPicker,
  BaseURLMultiPickerProps,
} from 'app/components/BasicPickers/BaseURLMultiPicker';
import {
  BaseURLPicker,
  BaseURLPickerProps,
} from 'app/components/BasicPickers/BaseURLPicker';
import { Entity } from 'types/common';
const url = '/api/odata/v4/OfflineServiceFilter';
const filter = 'ServiceTypeId eq 2 and Active eq true';
export interface OfflineServicePickerProps
  extends Omit<BaseURLPickerProps<Entity<number>>, 'url'> {}
// this picker is redundand, instead use OfflineServiceTyePicker
export const OfflineServicePicker = (props: OfflineServicePickerProps) => {
  const { queryfilter, ...other } = props;
  const predicate =
    queryfilter && queryfilter !== ''
      ? [queryfilter, filter].join(' and ')
      : filter;
  return (
    <BaseURLPicker
      url={url}
      queryfilter={predicate}
      {...other}
      id={props.id || 'offserviceId'}
    />
  );
};

export interface OfflineServiceMultiPickerProps
  extends Omit<BaseURLMultiPickerProps<number>, 'url'> {}

export const OfflineServiceMultiPicker = (
  props: OfflineServiceMultiPickerProps,
) => {
  const { queryfilter, ...other } = props;
  const predicate =
    queryfilter && queryfilter !== ''
      ? [queryfilter, filter].join(' and ')
      : filter;
  return (
    <BaseURLMultiPicker
      url={url}
      queryfilter={predicate}
      {...other}
      id={props.id || 'offserviceId'}
    />
  );
};
