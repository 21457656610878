import * as React from 'react';
import { MenuItem } from '@material-ui/core';
import { IFilterSettings } from './IFilterSettings';
import { Button } from 'app/components/BasicButtons/Button';
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Popper } from 'app/components/Popper';
import { useIsMobile } from 'app/hooks/useIsMobile';
import clsx from 'clsx';
import { AddFilterBoxRoot, StyledMenuList } from './styled';

export interface AddFilterButtonProps<TRow> {
  availableFilters: IFilterSettings<TRow>[];
  onChange: (value: IFilterSettings<TRow>) => void;
  filtersSelectionOpen: boolean;
  title: string;
  disabled?: boolean;
  disabledTitle?: string;
}

export function AddFilterButton<TRow>({
  filtersSelectionOpen,
  ...props
}: AddFilterButtonProps<TRow>) {
  const [open, setOpen] = React.useState(filtersSelectionOpen);
  const { checkIsMobile, isLandscape } = useIsMobile();
  const isMobile = checkIsMobile();
  React.useEffect(() => setOpen(filtersSelectionOpen), [filtersSelectionOpen]);

  const anchorRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<
    EventTarget & HTMLDivElement
  >();
  React.useEffect(() => {
    setAnchorEl(anchorRef.current ?? undefined);
  }, [anchorRef]);
  const handleAddFilterClick = e => {
    setOpen(c => !c);
    setAnchorEl(e.currentTarget);
  };
  const { t } = useTranslation();
  // close popover on click anywhere outside the menu

  // control field search text value
  const [input, setInnput] = React.useState<string>();

  // manually filter the items and take first 10
  const options = props.availableFilters.filter((item, index) =>
    t(item.displayNameKey)
      .toLowerCase()
      .includes((input || '').toLowerCase()),
  );

  // handle selected filter event
  const handleFilterSelect = item => {
    props.onChange(item);
    setOpen(false);
    setInnput(undefined);
  };

  return (
    <AddFilterBoxRoot
      className={clsx('add-filter-box-root', {
        'add-filter-box-mobile': isMobile,
      })}
    >
      {/* TODO: user localization on button */}
      <Button
        variant="white"
        size="small"
        onClick={handleAddFilterClick}
        ref={anchorRef}
        startIcon={<Icon icon="plus" />}
        disabled={props.disabled}
        title={props.disabled ? props.disabledTitle : undefined}
      >
        {props.title}
      </Button>
      {anchorEl !== undefined && (
        <>
          <Popper
            id={'add_filter_box_popper'}
            open={open}
            anchorEl={anchorEl}
            onClose={() => setOpen(false)}
            title={t(translations.SelectFieldToFilterBy)}
            minWidth="auto"
          >
            <TextControlField
              placeholder={t(translations.SearchFilter)}
              size="small"
              startIcon={
                <Icon icon="search" color="filters" colorExtend="light" />
              }
              value={input || ''}
              aria-label={t(translations.SearchFilter)}
              inputProps={{ 'aria-label': t(translations.SearchFilter) }}
              onChange={e => setInnput(e.target.value)}
              autoFocus
              fullWidth
            />
            <StyledMenuList
              className={clsx('add-filter-select', {
                mobHorizontal: isMobile && isLandscape,
                mobVertical: isMobile && !isLandscape,
              })}
            >
              {options.map((item, index) => (
                <MenuItem
                  key={index}
                  tabIndex={index}
                  button
                  onClick={e => handleFilterSelect(item)}
                >
                  {t(item.displayNameKey)}
                </MenuItem>
              ))}
            </StyledMenuList>
          </Popper>
        </>
      )}
    </AddFilterBoxRoot>
  );
}
