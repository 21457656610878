import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.usageState || initialState;

export const selectUsageState = createSelector([selectSlice], state => state);

export const selectUsageStateProcessing = createSelector(
  [selectUsageState],
  state => state.processing,
);
export const selectUsageDtoState = createSelector(
  [selectUsageState],
  state => state.usageDtoState,
);
export const selectUsageDetailsState = createSelector(
  [selectUsageState],
  state => state.detailsState,
);
export const selectUsageStateError = createSelector(
  [selectUsageDetailsState],
  state => state.error,
);
export const selectUsageHasError = createSelector(
  [selectUsageDetailsState],
  state => state.hasErrors,
);
export const selectUsageStateData = createSelector(
  [selectUsageDetailsState],
  state => state?.data,
);
export const selectUsageProcessing = createSelector(
  [selectUsageDetailsState],
  state => state.processing,
);
export const selectUsageCompleted = createSelector(
  [selectUsageDetailsState],
  state => state.completed,
);
export const selectUsageSettings = createSelector(
  [selectUsageState],
  state => state.usageSettingsState,
);
export const selectSavedOfflineServices = createSelector(
  [selectUsageState],
  state => state.savedOfflineServices,
);
export const selectIsEdit = createSelector(
  [selectUsageState],
  state => state.isEdit,
);
export const selectCreditState = createSelector(
  [selectUsageState],
  state => state.creditState,
);
export const selectCredit = createSelector(
  [selectCreditState],
  state => state.data,
);
export const selectCreditProcessing = createSelector(
  [selectCreditState],
  state => state.processing,
);
export const selectCreditCompleted = createSelector(
  [selectCreditState],
  state => state.completed,
);
export const selectHasCharges = createSelector(
  [selectUsageState],
  state => state.hasCharges,
);
export const selectUsageNotFound = createSelector(
  [selectUsageState],
  state => state.notFound,
);
export const selectFieldHandler = createSelector(
  [selectUsageState],
  state => state.valueHandler,
);
export const selectRequestedValueFor = createSelector(
  [selectUsageState],
  state => state.requestedValueFor,
);
export const selectUsageChangesState = createSelector(
  [selectUsageState],
  state => state.usageChangesState,
);
export const selectAsyncChangeLoading = createSelector(
  [selectUsageState],
  state => state.asyncChangeLoading,
);
export const selectStopUsageProcessing = createSelector(
  [selectUsageState],
  state => state.stopUsageProcessing,
);
export const selectShowEndUsageDialog = createSelector(
  [selectUsageState],
  state => state.showEndUsageDialog,
);
export const selectCreateManually = createSelector(
  [selectUsageState],
  state => state.createManully,
);
export const selectUsageStarted = createSelector(
  [selectUsageState],
  state => state.usageStarted,
);

// export const selectCurrentCommand = createSelector(
//   [selectUsageState],
//   state => state.currentCommand,
// );
