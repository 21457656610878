/**
 *
 * TopToolbar
 *
 */
import * as React from 'react';
import { ToolbarProps } from '@material-ui/core/Toolbar';
import { CustomerLogo } from '../../CustomerLogo';
import { ProfileMenu } from '../ProfileMenu';
import styled from 'styled-components';
import { StyledToolbar } from 'app/components/BasicTable/BasicFilter/TableToolbar/styled';
import { useTheme } from '@material-ui/core/styles';
import { ToggleSideMenuButton } from '../components/ToggleSideMenuButton';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { selectIsExpandedSideBar } from 'app/slice/selectors';
import { DetectIsMobile } from 'utils/mobileDetect';

export interface TopToolbarProps extends ToolbarProps {}
const Separator = styled('div')`
  flex-grow: 1;
`;
export const TopToolbar = React.memo(function TopToolbar(
  props: TopToolbarProps,
) {
  const isMobile = DetectIsMobile();

  const expanded = useSelector(state =>
    selectIsExpandedSideBar(state, isMobile),
  );

  const theme = useTheme();
  return (
    <React.Fragment>
      <StyledToolbar
        variant="dense"
        theme={theme}
        className={clsx('toptoolbar', { isExpanded: expanded })}
      >
        <div className="titleSection">
          {!expanded && <ToggleSideMenuButton icon={'chevrons-right'} />}
          <CustomerLogo />
        </div>
        {/* acts as a separator between visually left and right components of the side bar */}
        <Separator />
        <ProfileMenu />
      </StyledToolbar>
    </React.Fragment>
  );
});
