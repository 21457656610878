import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useTabTitle } from './useTabTitle';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { publicNS } from 'locales/i18n';

export interface HeadTitleProps {
  isDispatcherLoading: boolean;
  i18Language: string;
}

export function HeadTitle() {
  const { i18n } = useTranslation(publicNS);

  const [title, handleFavIconChange, handleTitleChange] = useTabTitle();
  useEffectOnMount(() => {
    handleFavIconChange(undefined);
    handleTitleChange(undefined);
  });

  return (
    <Helmet
      titleTemplate="%s - BookitLab!"
      defaultTitle={title || 'BookitLab!'}
      htmlAttributes={{ lang: i18n.language }}
    >
      <meta name="description" content="{process.env.PUBLIC_URL}" />
    </Helmet>
  );
}
