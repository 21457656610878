import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ShowAssetImageState } from './types';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { showAssetImageSaga } from './saga';

export const initialState: ShowAssetImageState = {
  show: false,
  isLoading: false,
};

const slice = createSlice({
  name: 'ShowAssetImage',
  initialState,
  reducers: {
    setImage(state, action: PayloadAction<string>) {
      state.image = action.payload;
    },
    setShow(state, action: PayloadAction<boolean>) {
      state.show = action.payload;
    },
    setShowImage(
      state,
      action: PayloadAction<{ image: string; show: boolean }>,
    ) {
      state.image = action.payload.image;
      state.show = action.payload.show;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const { actions: showAssetImageActions } = slice;

export const useShowAssetImageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: showAssetImageSaga });
  return { actions: slice.actions };
};
