import styled from 'styled-components';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { Theme, useTheme } from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';
import { IRequestDetailsModel } from '../../RequestSamplesPage/slice/types';
import { StyledFormFieldsContainer } from 'app/components/Forms/FormsLayout';
import { dateUtils } from 'utils/date-utils';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
export interface ShortRequestDetailsProps {
  print: boolean;
  onFullDetailsChange: (value?: boolean) => void;
  requestDetails: IRequestDetailsModel;
  fullDetails: boolean;
}

export function ShortRequestDetails(props: ShortRequestDetailsProps) {
  const { requestDetails, print, onFullDetailsChange, fullDetails } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <StyledFormFieldsContainer>
      <ShortRequestContent className="short-request-details" theme={theme}>
        <span className="row-item">
          <Icon icon="circle-dashed" color="primary" />
          <BasicTypography variant="boldS">
            {requestDetails.Status.Name}
          </BasicTypography>
        </span>
        <span className="row-item">
          <Icon icon="user" color="primary" />
          <BasicTypography variant="bodyS">{`${requestDetails.CreatedFor?.Name} (${requestDetails.UserGroup?.Name})`}</BasicTypography>
        </span>
        {requestDetails.SubmittedAt !== null && (
          <span className="row-item">
            <Icon icon="calendar" color="primary" />
            <BasicTypography variant="bodyS">
              {dateUtils.shortDateFormat(requestDetails.SubmittedAt)}
            </BasicTypography>
          </span>
        )}
        {requestDetails.Budget !== null &&
          requestDetails.Budget !== undefined && (
            <span className="row-item">
              <Icon icon="flag" color="primary" />
              <BasicTypography variant="bodyS">
                {requestDetails.Budget.Name}
              </BasicTypography>
            </span>
          )}
        {!print && (
          <span className="row-item">
            <Button
              variant="text"
              endIcon={
                <Icon icon={fullDetails ? 'chevron-up' : 'chevron-down'} />
              }
              onClick={() => onFullDetailsChange()}
            >
              {t(translations.MoreEdit)}
            </Button>
          </span>
        )}
      </ShortRequestContent>
    </StyledFormFieldsContainer>
  );
}
const ShortRequestContent = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  &.short-request-details {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    & .row-item {
      margin-right: 24px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }
    &.shortView {
      flex-wrap: wrap;
    }
    @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
      flex-wrap: wrap;
    }
  }
`;
