/**
 *
 * Popover
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Popover as MuiPopover } from '@material-ui/core';
import { DetectIsMobile } from 'utils/mobileDetect';
import { PopperTitle } from '../Popper/PopperTitle';
import styled from 'styled-components';

export interface PopperProps {
  id?: string;
  title: React.ReactNode;
  open: boolean;
  anchorEl?: EventTarget & HTMLDivElement;
  children: React.ReactNode;
  onClose: React.MouseEventHandler<HTMLButtonElement | Document>;
  className?: string;
  closeTitle?: string;
}

export function Popover(props: PopperProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const isMobile = DetectIsMobile();
  // const classes = useStyles({ isMobile: isMobile });

  return (
    <MuiPopover
      id={props.id}
      open={props.open}
      onClose={props.onClose}
      anchorEl={props.anchorEl}
      container={document.fullscreenElement ?? document.body}
      className={isMobile ? 'root-mobile' : 'root-popover'}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <StyledDiv>
        <div className={'select-title'}>
          <PopperTitle
            title={props.title}
            closeButtonTitle={props.closeTitle}
            onClose={props.onClose}
          />
        </div>
        <div className={'select-root'}>{props.children}</div>
      </StyledDiv>
    </MuiPopover>
  );
}

const StyledDiv = styled.div`
  height: 100%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  & .select-root {
    padding: 0 0 16px 0;
  }
  & .select-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 24px 0 8px;
    justify-content: space-between;
    width: 100%;
  }
`;

export const PopoverPaddingWrapper = styled.div`
  padding: 0 16px 0 16px;
`;
