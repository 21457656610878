import React from 'react';
import { toRootedURL } from 'utils/url-utils';

/**
 * Custom hook for managing the favicon of the document's tab.
 * @param defaultFavicon - The path to the default favicon image.
 * @returns A tuple containing the current favicon path and a function to update the favicon.
 */
export const useFavicon = (
  defaultFavicon: string,
): [string | undefined, (favIcon: string | undefined) => void] => {
  const refFavIcon = React.useRef<string | undefined>(defaultFavicon);

  /**
   * Updates the favicon of the document's tab based on the provided `favIcon`.
   * If `favIcon` is not provided, it falls back to the default favicon.
   * @param favIcon - The path to the new favicon image.
   */
  const handleFavIconChange = (favIcon: string | undefined) => {
    if (document && document.getElementById('tabFavIcon')) {
      let favLink = document.getElementById('tabFavIcon') as HTMLLinkElement;
      if (favLink !== null && !!favLink) {
        if (!!favIcon) {
          favLink.href = toRootedURL('/uploads/Branding/' + favIcon);
          refFavIcon.current = favIcon;
        } else {
          favLink.href = process.env.PUBLIC_URL + defaultFavicon;
          refFavIcon.current = defaultFavicon;
        }
      }
    }
  };

  return [refFavIcon.current, handleFavIconChange];
};
