import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import { CheckEntity } from 'app/components/CheckBoxGroup';
import {
  RepeatMonthlyBy,
  RepeatPeriod,
  RepetitiveOptions,
} from 'enums/RepetitiveOptions';
import { WorkWeekDays } from 'enums/WorkWeekDays';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { dateUtils, WeekStartsOn } from 'utils/date-utils';
import { enumToEntityArray } from 'utils/enumKeys';
import { Entity } from './common';
import { Timestamp } from './Timestamp';

export interface IRepetitiveDto {
  StartDate?: Date | string;
  RecurringEvents?: Entity<number> | null;
  RecurringEndDate?: Date | string;
  RepeatsEveryDay?: Entity<number> | null;
  RepeatsEveryPeriod?: Entity<number> | null;
  RepeatsMonthlyBy?: Entity<number> | null;
  RepeatsOnDays: CheckEntity[];
}
export interface RepetitiveIntervals {
  start: Date;
  end: Date;
}
export const ShortWeekDays = (): CheckEntity[] => {
  return dateUtils.getShortDaysArray().map((day, index) => {
    return {
      Id: index + 1,
      Name: day,
      checked: false,
    } as CheckEntity;
  });
};
export const LongWeekDays = (): CheckEntity[] => {
  return dateUtils.getLongDaysArray().map((day, index) => {
    return {
      Id: index + 1,
      Name: day,
      checked: false,
    } as CheckEntity;
  });
};
export const RepetitiveInit = (
  date?: Date | string,
  option?: number,
): IRepetitiveDto => {
  const startDate = dateUtils.dateOrStringToDate(date || new Date());
  const untilDate = dateUtils.dateFnsUtils.addDays(startDate, 7);
  return {
    StartDate: dateUtils.formatISO(dateUtils.dateOrStringToDate(startDate)),
    RecurringEvents: enumToEntityArray(RepetitiveOptions)[option ?? 0],
    RepeatsOnDays: ShortWeekDays().map(f => {
      if (f.Id === 1) f.checked = true;
      return f;
    }),
    RecurringEndDate: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(untilDate),
    ),
    RepeatsEveryDay: { Id: 1, Name: '1' } as Entity<number>,
    RepeatsEveryPeriod: enumToEntityArray(RepeatPeriod)[1],
    RepeatsMonthlyBy: enumToEntityArray(RepeatMonthlyBy)[0],
  };
};
export const GetAutoCreateTitle = (
  type: IWorkOrderTypeDto,
  eventStart: Date | Timestamp | null,
  firstDayOfWeek: WeekStartsOn,
): string | undefined => {
  let title: string | undefined = undefined;
  if (type.AutoCreateEvent && type.AutoCreateFor !== 0) {
    if (type.RepetitiveOptions > 0) {
      switch (type.RepetitiveOptions) {
        case RepetitiveOptions.RepeatCustom:
          let periodName = enumToEntityArray(RepeatPeriod).filter(
            f => f.Id === type.RepeatsEveryType,
          )[0].Name;
          if (type.RepeatsEveryType === RepeatPeriod.Days) {
            title = `Repeats every ${type.RepeatsEveryNum} - ${periodName}`;
          } else if (type.RepeatsEveryType === RepeatPeriod.Weeks) {
            title = `Repeats every ${type.RepeatsEveryNum} - ${periodName} on ${
              LongWeekDays().filter(f => f.Id.toString() === type.RepeatsOn)[0]
                .Name
            }`;
          } else {
            title = `Repeats every ${type.RepeatsEveryNum} - ${periodName} by ${
              enumToEntityArray(RepeatMonthlyBy).filter(
                f => f.Id === type.RepeatsBy,
              )[0].Name
            }`;
          }
          break;
        case RepetitiveOptions.RepeatDaily:
          title = 'Repeats Daily';
          break;
        case RepetitiveOptions.RepeatWeeklyOn:
          title = `Repeats ${i18next
            .t(translations.RepeatWeeklyOn)
            .replace(
              '{0}',
              dateUtils.getDayText(
                dateUtils.parseISOOrNull(eventStart) ?? new Date(),
              ),
            )}`;
          break;
        case RepetitiveOptions.RepeatMonthlyOn:
          let weekName = i18next.t(
            enumToEntityArray(WorkWeekDays).filter(
              f =>
                dateUtils.getWeekNumberOfMonth(
                  dateUtils.parseISOOrNull(eventStart) ?? new Date(),
                  firstDayOfWeek,
                ) === f.Id,
            )[0].Name,
          );
          title = `Repeats ${i18next
            .t(translations.RepeatMonthlyOn)
            .replace('{0}', weekName)
            .replace(
              '{1}',
              dateUtils.getDayText(
                dateUtils.parseISOOrNull(eventStart) ?? new Date(),
              ),
            )}`;
          break;
        case RepetitiveOptions.RepeatEveryWeekDay:
          let weekDays = `${
            dateUtils.getLongDaysArray()[firstDayOfWeek as number]
          } - ${
            dateUtils.getLongDaysArray()[
              dateUtils.getLongDaysArray().length - 2
            ]
          }`;
          title = `Repeats ${i18next
            .t(translations.RepeatEveryWeekDay)
            .replace('{0}', weekDays)}`;
          break;
      }
    }
  }
  return title;
};
export const GetIntervals = (
  start: Date,
  data: IRepetitiveDto,
  firstDayOfWeek?: number,
): Date[] => {
  let dates: Date[] = [];
  if (start && data.RecurringEndDate) {
    let originStart = dateUtils.dateOrStringToDate(start);
    let originalEnd = dateUtils.dateOrStringToDate(data.RecurringEndDate);
    if (data.RecurringEvents && data.RecurringEvents !== null) {
      if (
        data.RecurringEvents.Id !== (RepetitiveOptions.DoNotRepeat as number)
      ) {
        switch (data.RecurringEvents.Id) {
          case RepetitiveOptions.RepeatDaily as number:
            let days = dateUtils.differenceInCalendarDays(
              originalEnd,
              originStart,
            );
            dates = [...Array(days + 1).keys()].map(i =>
              dateUtils.addDays(originStart, i),
            );
            break;
          case RepetitiveOptions.RepeatWeeklyOn as number:
            dates.push(originStart);
            let nextWeek = dateUtils.addWeeks(originStart, 1);
            while (nextWeek < originalEnd) {
              dates.push(nextWeek);
              nextWeek = dateUtils.addWeeks(nextWeek, 1);
            }
            break;
          case RepetitiveOptions.RepeatMonthlyOn as number:
            // let week = dateUtils.getWeekNumberOfMonth(
            //   originStart,
            //   firstDayOfWeek,
            // );
            dates.push(originStart);
            let dayWeek = dateUtils.getDay(originStart);
            let newMonthDate = dateUtils.addMonths(originStart, 1);
            let newDayWeek = dateUtils.getDay(newMonthDate);
            let calcDay =
              dayWeek >= newDayWeek
                ? dayWeek - newDayWeek
                : 7 - (newDayWeek - dayWeek);
            let checkDate = dateUtils.addDays(newMonthDate, calcDay);
            if (checkDate.getMonth() > newMonthDate.getMonth()) {
              newMonthDate = dateUtils.addDays(newMonthDate, calcDay - 7);
            } else if (checkDate.getMonth() === newMonthDate.getMonth()) {
              if (
                checkDate.getDate() > newMonthDate.getDate() &&
                (dateUtils.addDays(newMonthDate, calcDay - 7).getFullYear() ===
                checkDate.getFullYear()
                  ? !(
                      dateUtils.addDays(newMonthDate, calcDay - 7).getMonth() <
                      checkDate.getMonth()
                    )
                  : dateUtils.addDays(newMonthDate, calcDay - 7).getMonth() <
                    checkDate.getMonth())
              ) {
                newMonthDate = dateUtils.addDays(newMonthDate, calcDay - 7);
              } else {
                newMonthDate = checkDate;
              }
            } else {
              newMonthDate = checkDate;
            }
            //newMonth = dateUtils.setWeek(newMonth, week, firstDayOfWeek);
            //newMonth = dateUtils.setDay(newMonth, dayWeek, firstDayOfWeek);
            let counter = 1;
            while (newMonthDate <= originalEnd) {
              dates.push(newMonthDate);
              counter++;
              newMonthDate = dateUtils.addMonths(originStart, counter);
              let newDayWeek = dateUtils.getDay(newMonthDate);
              let calcDay =
                dayWeek >= newDayWeek
                  ? dayWeek - newDayWeek
                  : 7 - (newDayWeek - dayWeek);
              let checkDate = dateUtils.addDays(newMonthDate, calcDay);
              if (checkDate.getMonth() > newMonthDate.getMonth()) {
                newMonthDate = dateUtils.addDays(newMonthDate, calcDay - 7);
              } else if (checkDate.getMonth() === newMonthDate.getMonth()) {
                if (
                  checkDate.getDate() > newMonthDate.getDate() &&
                  (dateUtils
                    .addDays(newMonthDate, calcDay - 7)
                    .getFullYear() === checkDate.getFullYear()
                    ? !(
                        dateUtils
                          .addDays(newMonthDate, calcDay - 7)
                          .getMonth() < checkDate.getMonth()
                      )
                    : dateUtils.addDays(newMonthDate, calcDay - 7).getMonth() <
                      checkDate.getMonth())
                ) {
                  newMonthDate = dateUtils.addDays(newMonthDate, calcDay - 7);
                } else {
                  newMonthDate = checkDate;
                }
              } else {
                newMonthDate = checkDate;
              }
            }
            break;
          case RepetitiveOptions.RepeatEveryWeekDay as number:
            dates.push(originStart);
            let theDay = dateUtils.getDay(originStart);
            let firstDay = dateUtils.getDay(
              dateUtils.startOfWeek(originStart, firstDayOfWeek),
            );
            let lastDay = firstDay + 4;

            let day =
              (theDay > firstDay && theDay < lastDay) || theDay === firstDay
                ? 1
                : 3;
            let newDay = dateUtils.addDays(originStart, day);
            while (newDay <= originalEnd) {
              dates.push(newDay);
              let theDay = dateUtils.getDay(newDay);
              let day =
                (theDay > firstDay && theDay < lastDay) || theDay === firstDay
                  ? 1
                  : 3;
              newDay = dateUtils.addDays(newDay, day);
            }
            break;
          case RepetitiveOptions.RepeatCustom as number:
            if (
              data.RepeatsEveryDay !== null &&
              data.RepeatsEveryPeriod !== null
            ) {
              let period =
                (data.RepeatsEveryPeriod?.Id as RepeatPeriod) ||
                RepeatPeriod.Weeks;
              let everyDayNum = data.RepeatsEveryDay?.Id || 1;
              switch (period) {
                case RepeatPeriod.Days:
                  dates.push(originStart);
                  let newDay = dateUtils.addDays(originStart, everyDayNum);
                  while (newDay <= originalEnd) {
                    dates.push(newDay);
                    newDay = dateUtils.addDays(newDay, everyDayNum);
                  }
                  break;
                case RepeatPeriod.Weeks:
                  dates.push(originStart);
                  let days = data.RepeatsOnDays.filter(f => f.checked === true)
                    .map(m => m.Id - 1)
                    .sort((a, b) => a - b);
                  let theDay = dateUtils.getDay(originStart);
                  let nextDay =
                    days.filter(f => f > theDay).length > 0
                      ? days.filter(f => f > theDay)[0]
                      : days[0];
                  let daysToAdd =
                    nextDay >= theDay
                      ? nextDay - theDay === 0
                        ? 7 * everyDayNum
                        : nextDay - theDay
                      : 7 * everyDayNum - (theDay - nextDay);

                  let newDayDate = dateUtils.addDays(originStart, daysToAdd);
                  while (newDayDate <= originalEnd) {
                    dates.push(newDayDate);
                    let mtheDay = dateUtils.getDay(newDayDate);
                    let mnextDay =
                      days.filter(f => f > mtheDay).length > 0
                        ? days.filter(f => f > mtheDay)[0]
                        : days[0];
                    let mdaysToAdd =
                      mnextDay >= mtheDay
                        ? mnextDay - mtheDay === 0
                          ? 7 * everyDayNum
                          : mnextDay - mtheDay
                        : 7 * everyDayNum - (mtheDay - mnextDay);

                    newDayDate = dateUtils.addDays(newDayDate, mdaysToAdd);
                  }
                  break;
                case RepeatPeriod.Months:
                  let monthlyBy =
                    (data.RepeatsMonthlyBy?.Id as RepeatMonthlyBy) ||
                    RepeatMonthlyBy.RepeatsBy_DayOfMonth;
                  switch (monthlyBy) {
                    case RepeatMonthlyBy.RepeatsBy_DayOfMonth:
                      dates.push(originStart);
                      let monthDay = dateUtils.getDate(originStart);
                      let newMonthDate = dateUtils.addMonths(
                        originStart,
                        everyDayNum,
                      );
                      dateUtils.setDate(newMonthDate, monthDay);
                      while (newMonthDate <= originalEnd) {
                        dates.push(newMonthDate);
                        newMonthDate = dateUtils.addMonths(
                          newMonthDate,
                          everyDayNum,
                        );
                        dateUtils.setDate(newMonthDate, monthDay);
                      }
                      break;
                    case RepeatMonthlyBy.RepeatsBy_DayOfWeek:
                      dates.push(originStart);
                      let dayWeek = dateUtils.getDay(originStart);
                      let newMonth = dateUtils.addMonths(
                        originStart,
                        everyDayNum,
                      );
                      let newDayWeek = dateUtils.getDay(newMonth);
                      let calcDay =
                        dayWeek >= newDayWeek
                          ? dayWeek - newDayWeek
                          : 7 - (newDayWeek - dayWeek);
                      let checkDate = dateUtils.addDays(newMonth, calcDay);
                      if (checkDate.getMonth() > newMonth.getMonth()) {
                        newMonth = dateUtils.addDays(newMonth, calcDay - 7);
                      } else if (checkDate.getMonth() === newMonth.getMonth()) {
                        if (
                          checkDate.getDate() > newMonth.getDate() &&
                          (dateUtils
                            .addDays(newMonth, calcDay - 7)
                            .getFullYear() === checkDate.getFullYear()
                            ? !(
                                dateUtils
                                  .addDays(newMonth, calcDay - 7)
                                  .getMonth() < checkDate.getMonth()
                              )
                            : dateUtils
                                .addDays(newMonth, calcDay - 7)
                                .getMonth() < checkDate.getMonth())
                        ) {
                          newMonth = dateUtils.addDays(newMonth, calcDay - 7);
                        } else {
                          newMonth = checkDate;
                        }
                      } else {
                        newMonth = checkDate;
                      }
                      let counter = everyDayNum;
                      while (newMonth <= originalEnd) {
                        dates.push(newMonth);
                        counter += everyDayNum;
                        newMonth = dateUtils.addMonths(originStart, counter);
                        let newDayWeek = dateUtils.getDay(newMonth);
                        let calcDay =
                          dayWeek >= newDayWeek
                            ? dayWeek - newDayWeek
                            : 7 - (newDayWeek - dayWeek);
                        let checkDate = dateUtils.addDays(newMonth, calcDay);
                        if (checkDate.getMonth() > newMonth.getMonth()) {
                          newMonth = dateUtils.addDays(newMonth, calcDay - 7);
                        } else if (
                          checkDate.getMonth() === newMonth.getMonth()
                        ) {
                          if (
                            checkDate.getDate() > newMonth.getDate() &&
                            (dateUtils
                              .addDays(newMonth, calcDay - 7)
                              .getFullYear() === checkDate.getFullYear()
                              ? !(
                                  dateUtils
                                    .addDays(newMonth, calcDay - 7)
                                    .getMonth() < checkDate.getMonth()
                                )
                              : dateUtils
                                  .addDays(newMonth, calcDay - 7)
                                  .getMonth() < checkDate.getMonth())
                          ) {
                            newMonth = dateUtils.addDays(newMonth, calcDay - 7);
                          } else {
                            newMonth = checkDate;
                          }
                        } else {
                          newMonth = checkDate;
                        }
                      }
                      break;
                  }
              }
            }
        }
      }
    }
  }
  return dates;
};
