import * as React from 'react';
import { Button } from 'app/components/BasicButtons/Button';
import { FilterIconButton } from 'app/components/BasicButtons/FilterIconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { DetectIsMobile } from 'utils/mobileDetect';
import { bookitColors } from 'styles/colors/bookitColors';
import styled from 'styled-components';

export interface FiltersButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  appliedFiltersLength?: number;
  allFiltersLength?: number;
  innerRef?: React.RefObject<HTMLButtonElement>;
  ns?: string;
  id?: string;
}
const CounterIcon = styled.span`
  &.layers-root {
    & .icon-layers-text {
      font-size: 9px;
      color: ${bookitColors.primary.bgLight};
    }
  }
`;
export function FiltersButton({
  onClick,
  appliedFiltersLength,
  allFiltersLength,
  ns,
  id,
  ...props
}: FiltersButtonProps) {
  const { t } = useTranslation(ns);
  const isMobile = DetectIsMobile();
  if (isMobile) {
    return (
      <FilterIconButton
        filterLength={allFiltersLength ?? 0}
        handleClick={onClick}
        id={id}
      />
    );
  }
  return (
    <Button
      aria-label={t(translations.Filters)}
      variant="white"
      role="button"
      size="small"
      innerRef={props.innerRef}
      id={id}
      onClick={onClick}
      startIcon={<Icon icon="filter" aria-label="Filter" role="button" />}
      endIcon={
        !!appliedFiltersLength && (
          <CounterIcon className="fa-layers fa-fw fa-lg layers-root">
            <Icon icon={['fas', 'circle']} color="primary" colorExtend="main" />
            <span className="fa-layers-text fa-inverse icon-layers-text">
              {appliedFiltersLength}
            </span>
          </CounterIcon>
        )
      }
    >
      {t(translations.Filters)}
    </Button>
  );
}
