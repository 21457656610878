import { IAuthenticatedUser } from 'types/AuthenticatedUser';
import { isEmptyOrWhitespace } from 'utils/typeUtils';
import { toQueryString } from 'utils/url-utils';

export const getDefaultFilter = (onlyMyCores: boolean) => {
  const result = (user: IAuthenticatedUser) =>
    toQueryString({
      ownedbyme: onlyMyCores === true ? true : undefined,
    });
  return result;
};
export function getRequiredFilter(search: string) {
  const result = isEmptyOrWhitespace(search)
    ? new URLSearchParams()
    : new URLSearchParams(search);
  if (
    result.has('Active') === false ||
    result.get('Active') === null ||
    result.get('Active') === ''
  ) {
    result.set('Active', true.toString());
  }
  const res = result.toString();
  return isEmptyOrWhitespace(res) ? undefined : res;
}
