import { EndUsageData } from 'app/components/EndUsageConfirm';
import {
  IUsageDto,
  UsageChangeStateParameters,
  UsageQueryStringParameters,
} from 'app/pages/UsageDetails/Details/slice/types';
import { IResponseType } from 'types/ResponseType';
import { httpClient } from './HttpClient';
export const UsageApi = {
  initUsage: (payload: UsageQueryStringParameters) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/Usage/InitUsage`;
    return httpClient.post(url, data);
  },
  // userCanStartUsage: (payload: number) => {
  //   const data = {
  //     equipmentid: payload,
  //   };
  //   const url = `/api/odata/v4/Usage/UserCanStartUsage`;
  //   return httpClient.post(url, data);
  // },
  startUsage: (payload: IUsageDto) => {
    const url = `/api/odata/v4/Usage`;
    return httpClient.post(url, payload);
  },
  updateUsage: (payload: IUsageDto) => {
    const url = `/api/odata/v4/Usage`;
    return httpClient.put(url + '(' + payload.Id + ')', payload);
  },
  calculateCredit: (payload: UsageChangeStateParameters) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/Usage/CalculateCredit`;
    return httpClient.post(url, data);
  },
  getUsage: (id: number, params: object) => {
    const url = `/api/odata/v4/Usage/${id}`;
    return httpClient.get<IUsageDto>(url, params);
  },
  getUsageChangeState: (payload: UsageChangeStateParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/Usage/GetUsageChangeState';
    return httpClient.post(url, data);
  },
  getValidationState: (payload: UsageChangeStateParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/Usage/GetUsageValidationState';
    return httpClient.post(url, data);
  },
  stopUsage: (prop: {
    id: number;
    data: EndUsageData;
  }): Promise<IResponseType> => {
    const url = `/api/odata/v4/Usage/StopUsage`;
    const params = {
      id: prop.id,
      remarks: prop.data.remarks ?? '',
      sids:
        prop.data.services
          .map(s => s.service?.Id + ':' + s.quantity)
          .join(',') ?? '',
    };
    return httpClient.post(url, params);
  },
};
