/**
 *
 * AsyncPicker
 *
 */
import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import { GroupUrl, ServiceGroupPicker } from '../pickers/ServiceGroupPicker';
import { Condition } from 'api/odata/ODataFilter';
import { IServiceGroupDto } from 'api/odata/generated/entities/IServiceGroupDto';

export const FormServiceGroupPicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<number> | null> & {
  label?: string;
  fullWidth?: boolean;
  urlType: GroupUrl;
  autoFocus?: boolean;
  name: string;
  placeholder: string;
  disabled?: boolean;
  predicates?: (string | Condition<Entity<number>>)[];
  variant?: 'standard' | 'filled' | 'outlined';
  entityOnly?: boolean;
  info?: string;
}) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  return (
    <ServiceGroupPicker
      name={props.name}
      urlType={props.urlType}
      placeholder={props.placeholder}
      disabled={props.disabled}
      label={label}
      onChange={value => {
        const newValue =
          value === null
            ? null
            : props.entityOnly
            ? { Id: value.Id, Name: value.Name }
            : value;
        helpers.setValue(newValue, true);
        if (props.onChange) {
          props.onChange(props.value);
        }
      }}
      onBlur={field?.onBlur}
      value={field.value as IServiceGroupDto}
      error={meta?.error !== undefined}
      helperText={meta.error}
      fullWidth={props.fullWidth}
      predicates={props.predicates}
      variant={props.variant === undefined ? 'filled' : props.variant}
      info={props.info}
    />
  );
};
