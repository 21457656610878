import { IUsers } from 'app/pages/UsersPage/IUsers';
import { IUserRow } from '../IUserRow';
import * as React from 'react';
import {
  FormLeftSection,
  FormRightSection,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import { GetUserRowsSections } from '../typeUtils';
import { RenderLeftSection, RenderRightSection } from './RenderRows';

export interface UserProfileTabContentProps {
  rows: IUserRow[];
  data: IUsers;
  shortView?: boolean;
  isCover?: boolean;
  info?: string;
}
export function UserProfileTabContent(props: UserProfileTabContentProps) {
  const { rows, data, shortView, isCover, info } = props;
  const sections = React.useMemo(() => {
    return GetUserRowsSections(rows, data, shortView);
  }, [data, shortView, rows]);
  const leftSections = React.useMemo(() => {
    return sections.leftSections;
  }, [sections]);
  const rightSections = React.useMemo(() => {
    return sections.rightSections;
  }, [sections]);
  return (
    <>
      <StyledFormFieldsContainer noPadding isCover={isCover}>
        <FormLeftSection isCover={isCover} info={info}>
          <RenderLeftSection sections={leftSections} data={data} />
        </FormLeftSection>
        <FormRightSection isCover={isCover}>
          <RenderRightSection sections={rightSections} data={data} />
        </FormRightSection>
      </StyledFormFieldsContainer>
    </>
  );
}
