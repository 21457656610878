import {
  OnlineServiceEntitySchema,
  UserEntitySchema,
} from 'app/components/Forms/Schemas';
import { TFunction } from 'i18next';
import { ISignUpModel, ISimpleSignUpModel } from './ISignUpModel';
import * as Yup from 'yup';
import { translations } from 'locales/translations';

export function GetSignUpSchema(
  t: TFunction,
  isEdit: boolean,
): Yup.SchemaOf<ISignUpModel> {
  const result: Yup.SchemaOf<ISignUpModel> = Yup.object({
    Id: Yup.number().notRequired().default(0),
    Equipment: OnlineServiceEntitySchema.nullable().required(
      t(translations.err_OnlineServiceFieldRequired),
    ),
    User: UserEntitySchema.nullable().notRequired().optional(),
    NextReservationHours: Yup.number()
      .notRequired()
      .optional()
      .when('NotifyOnEndUsage', (val: string, schema: Yup.NumberSchema) => {
        return val === 'true'
          ? schema
              .required(t(translations.err_NotificationHoursNegativeOrZero))
              .min(1, t(translations.err_NotificationHoursNegativeOrZero))
          : schema.notRequired().optional();
      }),
    NextResTermHours: Yup.number()
      .notRequired()
      .optional()
      .when(
        'NotifyOnResTermination',
        (val: string, schema: Yup.NumberSchema) => {
          return val === 'true'
            ? schema
                .required(t(translations.err_NotificationHoursNegativeOrZero))
                .min(1, t(translations.err_NotificationHoursNegativeOrZero))
            : schema.notRequired().optional();
        },
      ),
    //BeforeResStartHour: Yup.number().notRequired().optional(),
    //BeforeTrainStartHour: Yup.number().notRequired().optional(),
    BeforeResCancelHour: Yup.number()
      .notRequired()
      .optional()
      .when(
        'NotifyOnResCancelation',
        (val: string, schema: Yup.NumberSchema) => {
          return val === 'true'
            ? schema
                .required(t(translations.err_NotificationHoursNegativeOrZero))
                .min(1, t(translations.err_NotificationHoursNegativeOrZero))
            : schema.notRequired().optional();
        },
      ),
    NotifyOnResCancelation: Yup.string().defined().default('false'),

    NotifyOnResTermination: Yup.string().defined().default(''),

    NotifyOnEndUsage: Yup.string().defined().default(''),

    //UpcomingReservations: Yup.string().defined().default('false'),

    //UpcomingTrainingSession: Yup.string().defined().default('false'),
    // UserGroup: UserGroupEntitySchema.nullable().when(
    //   'User',
    //   (_, schema: Yup.SchemaOf<Entity<string>>) =>
    //     isEdit ? schema.notRequired() : schema.required(),
    // ),
    // SampleType: OfflineServiceTypeEntitySchema.notRequired().nullable(),
    // FeeType: FeeTypeEntitySchema.required().nullable(),
    // Quantity: Yup.number().defined(),
    // Rate: Yup.number()
    //   .defined()
    //   .when('FeeType', (catRateType, schema: Yup.NumberSchema) => {
    //     return (catRateType as Entity<number>)?.Id === CatRateTypes.Refund
    //       ? schema.negative().max(0)
    //       : schema.positive().min(0);
    //   }),
    // Amount: Yup.number()
    //   .defined()
    //   .when('FeeType', (catRateType, schema: Yup.NumberSchema) => {
    //     return (catRateType as Entity<number>)?.Id === CatRateTypes.Refund
    //       ? schema.negative().max(0)
    //       : schema.positive().min(0);
    //   }),
    // Manual: Yup.bool().notRequired(),
    // ActualRate: Yup.number().notRequired(),
    // Remarks: Yup.string().nullable().default('').notRequired(),
  });
  return result;
}
export function GetSimpleSignUpSchema(
  t: TFunction,
): Yup.SchemaOf<ISimpleSignUpModel> {
  const result: Yup.SchemaOf<ISimpleSignUpModel> = Yup.object({
    Id: Yup.number().notRequired().default(0),
    Equipment: OnlineServiceEntitySchema.nullable().required(
      t(translations.err_OnlineServiceFieldRequired),
    ),
    User: UserEntitySchema.nullable().notRequired().optional(),
    WindowHours: Yup.number()
      .required(t(translations.err_NotificationHoursNegativeOrZero))
      .min(1, t(translations.err_NotificationHoursNegativeOrZero))
      .defined(),
  });
  return result;
}
