import { Entity } from 'types/common';

export interface IComment {
  Id: number;
  Text: string;
  CreatedAt: Date | string;
  CreatedBy: Entity<string>;
  Files: CommentFile[];
  IsInternal?: boolean | null;
  ReferenceId?: number;
  ServiceGroupName?: string;
}

export interface NewComment {
  Text?: string;
  Files?: FileList;
  Users: Entity<string>[];
  IsInternal?: boolean | null;
}

export interface CommentFile {
  Id: string;
  Name: string;
  Size?: number;
}
export const ConvertNewToComment = (
  newcomment: NewComment,
  Id: number,
  CreatedAt: Date | string,
  CreatedBy: Entity<string>,
) => {
  let newFiles: CommentFile[] = [];
  if (!!newcomment.Files && newcomment.Files.length > 0) {
    for (
      let i: number = 0;
      !!newcomment.Files && i < newcomment.Files.length;
      i++
    ) {
      let item = newcomment.Files.item(i);
      if (!!item) {
        newFiles.push({
          Id: i.toString(),
          Name: item.name,
          Size: item.size,
        } as CommentFile);
      }
    }
  }
  return {
    Id: Id,
    Text: newcomment.Text || '',
    CreatedAt: CreatedAt,
    CreatedBy: CreatedBy,
    Files: newFiles,
    IsInternal: newcomment.IsInternal,
  } as IComment;
};
