import React, { ReactNode } from 'react';
import { AssetUser, IAsset } from '../IAsset';
import { AttributeValue } from '../IAssetRow';
import parse from 'html-react-parser';
import { dateUtils } from 'utils/date-utils';
import { Link } from 'app/components/ExternalLink';
import styled from 'styled-components';
import { Button } from 'app/components/BasicButtons/Button';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import {
  List,
  ListItem,
  ListItemText,
  Theme,
  useTheme,
} from '@material-ui/core';
import BasicTypography from 'app/components/Typography/BasicTypography';
import clsx from 'clsx';
import { DemSize } from 'app/components/BasicCards';
import { FlexRow8 } from 'app/components/StyledComponents';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const DateRenderer = (
  props: AttributeValue<IAsset>,
): React.ReactNode => {
  let date = props.value as Date;
  return <>{dateUtils.shortDateFormat(date)}</>;
};
const ShortText = styled('span')`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  * {
    margin: 0px;
  }
`;
const FullText = styled('span')`
  * {
    margin: 0px;
  }
`;
export const ImageGallery = styled.div<{
  theme: Theme;
  path: string;
}>`
  &.imageSlider {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
    height: auto;
    align-self: stretch;
    & .imgSliderCenter {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      & .cardImage {
        display: flex;
        flex-direction: column;
        height: 340px;
        align-items: flex-start;
        align-self: stretch;
        &.cardImageMobile {
          height: 220px;
        }
        & .frame {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;
          flex: 1 0 0;
          border-radius: 4px;
          & .mediaStyle {
            image-rendering: -webkit-optimize-contrast;
            width: 100%;
            background-blend-mode: multiply;
            min-width: unset;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;
            background-image: url(${props => props.path});
            background-size: auto;
            background-repeat: no-repeat;
            background-position: center;
            padding-top: 340px;
            &.backgroundSizeCover {
              background-size: cover;
            }
            &.backgroundSizeContain {
              background-size: contain;
            }
            &.mediaStyleShort {
              padding-top: 220px;
            }
          }
        }
      }
    }
  }
`;

export const HtmlRenderer = (props: AttributeValue<IAsset>): ReactNode => {
  const { t } = useTranslation();
  const [fullText, setFullText] = React.useState<boolean>(false);
  const [needButton, setNeedButton] = React.useState<boolean>(false);
  let html = props.value as string;
  const text = parse(html);
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (ref.current !== null) {
      if (
        ref.current.clientHeight === ref.current.scrollHeight &&
        ref.current.clientWidth === ref.current.scrollWidth
      ) {
        setNeedButton(false);
      } else {
        setNeedButton(true);
      }
    }
  }, []);
  return (
    <>
      <div>
        {fullText ? (
          <FullText>{text}</FullText>
        ) : (
          <ShortText ref={ref}>{text}</ShortText>
        )}
        {needButton && (
          <div>
            <Button
              variant="text"
              size="small"
              onClick={() => setFullText(!fullText)}
              endIcon={<Icon icon={fullText ? 'chevron-up' : 'chevron-down'} />}
            >
              {fullText ? t(translations.ShowLess) : t(translations.ShowMore)}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export function ArrayRenderer<T>(
  callback: (value: T, original: IAsset | undefined) => ReactNode | undefined,
) {
  const mapper = (props: AttributeValue<IAsset>) => {
    if (!!props.value && !!props.value.length) {
      return (
        <List dense disablePadding key={`array_list_${props.original.Id}`}>
          {props.value.map((v, indx) => (
            <ListItem
              dense
              disableGutters
              key={`array_listItem_${props.original.Id}_${indx}`}
            >
              <ListItemText
                key={`array_listItemText_${props.original.Id}_${indx}`}
              >
                {callback(v, props.original)}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      );
    }
  };
  return mapper;
}
export const AssetDetailsImageRender = (
  props: AttributeValue<IAsset> & {
    clickImage?: (path?: string) => void;
    gallery?: boolean;
  },
) => {
  const theme = useTheme();
  const defaultImgSrc = process.env.PUBLIC_URL + `/images/400x300.png`;
  const defaultImgMobileSrc = process.env.PUBLIC_URL + `/images/200x150.png`;
  const defaultImg = React.useMemo(() => {
    return props.shortView ? defaultImgMobileSrc : defaultImgSrc;
  }, [defaultImgMobileSrc, defaultImgSrc, props.shortView]);

  const [pathSrc, setPathSrc] = React.useState<string | undefined>(defaultImg);

  const [loadingImg, setLoadingImg] = React.useState<boolean | undefined>(
    undefined,
  );
  const [imageDimensions, setImageDimensions] = React.useState<
    DemSize | undefined
  >(undefined);

  const checkImage = React.useCallback(
    async (path: any) =>
      new Promise<{
        path?: string;
        status: string;
        height: number;
        width: number;
      }>(resolve => {
        const img = new Image();
        img.src = props.value;
        img.onload = () =>
          resolve({ path, status: 'ok', height: img.height, width: img.width });
        img.onerror = () =>
          resolve({
            path,
            status: 'error',
            height: props.shortView ? 150 : 300,
            width: props.shortView ? 200 : 400,
          });
      }),
    [props.shortView, props.value],
  );
  React.useEffect(() => {
    let active = props.value !== undefined && loadingImg === undefined;
    if (active) {
      if (loadingImg) return;
      setLoadingImg(true);
      (async () => {
        try {
          const res = await checkImage(props.value);
          if (res.status === 'ok') {
            setPathSrc(res.path);
            setImageDimensions({
              height: res.height,
              width: res.width,
            });
            setLoadingImg(false);
          } else {
            setPathSrc(defaultImg);
            setImageDimensions({
              height: res.height,
              width: res.width,
            });
            setLoadingImg(false);
          }
        } catch {
          setPathSrc(defaultImg);
          setImageDimensions({
            height: props.shortView ? 150 : 300,
            width: props.shortView ? 200 : 400,
          });
          setLoadingImg(false);
        }
      })();
    }
    return () => {
      active = false;
    };
  }, [checkImage, defaultImg, loadingImg, props.shortView, props.value]);
  const backgroundSize = React.useMemo(() => {
    if (!!imageDimensions) {
      let horizontal = imageDimensions.width - imageDimensions.height > 0;
      if (
        imageDimensions.height === imageDimensions.width ||
        (horizontal && imageDimensions.width / 1.1 < imageDimensions.height) ||
        (!horizontal && imageDimensions.height / 1.1 < imageDimensions.width)
      ) {
        return 'contain';
      }
      if (!horizontal) {
        if (imageDimensions.height < (props.shortView ? 220 : 340) / 1.8) {
          return 'contain';
        } else {
          return imageDimensions.height / imageDimensions.width > 1.8
            ? 'cover'
            : 'contain';
        }
      } else {
        if (imageDimensions.width < (props.shortView ? 220 : 340) / 1.8) {
          return 'contain';
        } else {
          return imageDimensions.width / imageDimensions.height < 1.8
            ? 'cover'
            : 'contain';
        }
      }
    }
    return undefined;
  }, [imageDimensions, props.shortView]);
  const backClass = React.useMemo(() => {
    if (backgroundSize === undefined) return '';
    if (backgroundSize === 'cover') return 'backgroundSizeCover';
    if (backgroundSize === 'contain') return 'backgroundSizeContain';
    return '';
  }, [backgroundSize]);
  return !!pathSrc && loadingImg === false ? (
    props.gallery ? (
      <div key={props.original.Id}>
        <img src={pathSrc} alt="" />
      </div>
    ) : (
      <ImageGallery className={'imageSlider'} theme={theme} path={pathSrc}>
        <div className={'imgSliderCenter'}>
          <div
            className={clsx('cardImage', {
              cardImageMobile: props.shortView,
            })}
          >
            <div className={'frame'}>
              <div
                className={clsx('mediaStyle', backClass, {
                  mediaStyleShort: props.shortView,
                })}
                onClick={() => {
                  if (props.clickImage !== undefined) {
                    props.clickImage(props.value);
                  }
                }}
              ></div>
            </div>
          </div>
        </div>
      </ImageGallery>
    )
  ) : null;
};
// export const AssetDetailsImageCarouselRender = ({
//   value,
//   clickImage,
//   values,
//   setting,
//   shortView,
//   original,
// }: AttributeValue<IAsset> & {
//   clickImage?: (path?: string) => void;
//   values?: string[];
//   setting: GlobalSettings;
// }) => {
//   const mainImg: string = React.useMemo(() => {
//     return (
//       getAssetImageSrc(
//         value,
//         undefined,
//         (setting.GetNullableByKey(AllowedSettings.AssetImageSizeType) ??
//           '2') === '1' && !shortView
//           ? '/images/400x300.png'
//           : '/images/200x150.png',
//       ) ??
//       toRootedURL(!shortView ? '/images/400x300.png' : '/images/200x150.png')
//     );
//   }, [setting, shortView, value]);
//   const allImages: Entity<number>[] = React.useMemo(() => {
//     let vals = values === undefined ? [value] : [value].concat(values);
//     return vals.map((v, ind) => {
//       const v1 = getAssetImageSrc(
//         v,
//         undefined,
//         (setting.GetNullableByKey(AllowedSettings.AssetImageSizeType) ??
//           '2') === '1' && !shortView
//           ? '/images/400x300.png'
//           : '/images/200x150.png',
//       );
//       return {
//         Id: ind,
//         Name:
//           v1 !== undefined
//             ? v1
//             : toRootedURL(
//                 !shortView ? '/images/400x300.png' : '/images/200x150.png',
//               ),
//       };
//     });
//   }, [setting, shortView, value, values]);
//   const [selectedImg, setSelectedImg] = React.useState<Entity<number>>(
//     allImages[0],
//   );
//   const customRenderThumb = (children: React.ReactChild[]) =>
//     children.map((item, index) => {
//       return <img src={allImages[index].Name} alt="" />;
//     });

//   return (
//     <ImageCarousel className={'img-carousel-root'}>
//       <Carousel
//         autoPlay={false}
//         autoFocus={true}
//         showIndicators={false}
//         thumbWidth={80}
//         showThumbs={allImages.length > 1}
//         swipeable={false}
//         selectedItem={selectedImg?.Id}
//         renderThumbs={customRenderThumb}
//         onChange={index => setSelectedImg(allImages[index])}
//         onClickItem={() => !!clickImage && clickImage(selectedImg?.Name)}
//       >
//         {allImages.map(img => (
//           <AssetDetailsImageRender
//             value={img.Name}
//             original={original}
//             shortView={shortView}
//             clickImage={clickImage}
//             gallery={true}
//           />
//         ))}
//       </Carousel>
//     </ImageCarousel>
//   );
// };
export function UsersRenderer() {
  return ArrayRenderer<AssetUser>(v => (
    <FlexRow8 key={v.Name}>
      <BasicTypography component={'span'} variant={'bodyS'}>
        {v.Name}
      </BasicTypography>
      {!!v.Email && (
        <Link href={'mailto:' + v.Email} useInlineUrl={true}>
          {v.Email}
        </Link>
      )}

      {!!v.OfficeTel && (
        <BasicTypography component={'span'} variant={'bodyS'}>
          {v.OfficeTel}
        </BasicTypography>
      )}
    </FlexRow8>
  ));
}
