import { SelectedRowsActionIconButton } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionIconButton';
import { SelectedRowsActionRenderer } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionRenderer';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import React from 'react';
import { IResponseType } from 'types/ResponseType';
import { ISampleRun } from '../../SamplesListPage/slice/types';
import { ExportForImportRunDetailsSamples } from '../Actions';

export interface GetSelectedActionsProps {}
export function GetSelectedActions(
  t: TFunction,
  id: number,
  exportConfigurableColumns: Array<keyof ISampleRun> | undefined,
  props: GetSelectedActionsProps,
): SelectedRowsActionRenderer<ISampleRun>[] {
  const [validUpdate] = React.useState<boolean | undefined>(false);
  const SelectedActions: SelectedRowsActionRenderer<ISampleRun>[] =
    React.useMemo(
      () => [
        (rows, onRefresh, toggleAllRowsSelected) => (
          <SelectedRowsActionIconButton
            key="export-forimport-budgets"
            title={t(translations.ExportForImport)}
            text={t(translations.ExportForImport)}
            confirm={false}
            validConfirm={validUpdate}
            rows={rows}
            onRefresh={() => {
              toggleAllRowsSelected(false);
            }}
            variantButton="button"
            action={() =>
              new Promise<IResponseType>(async (resolve, reject) => {
                await ExportForImportRunDetailsSamples(
                  id,
                  rows,
                  exportConfigurableColumns,
                );
                let messages: IResponseType = {
                  SuccessMessages: [],
                  ErrorMessages: [],
                  WarningMessages: [],
                };
                resolve(messages);
              })
            }
          />
        ),
      ],
      [exportConfigurableColumns, id, t, validUpdate],
    );
  return SelectedActions;
}
