/**
 *
 * MandatoryOfflineTypePicker
 *
 */
import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import { MandatoryOfflineTypeEnum } from 'enums/MandatoryOfflineTypeEnum';
import { OfflineReferenceTypeEnum } from 'enums/OfflineReferenceTypeEnum';
import { Entity } from 'types/common';
import { enumToEntityArray } from 'utils/enumKeys';

export interface MandatoryOfflineTypePickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {
  refTypeId?: number;
}

export const initMandatoryOfflineTypeData = (
  initval: number | string | undefined,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve(
        enumToEntityArray(MandatoryOfflineTypeEnum).filter(item => {
          return item.Id.toString() === initval || item.Name === initval;
        }),
      );
    });
  }
};
const getOptions = (refTypeId?: number) => (searchTerm: string | null) => {
  let items = enumToEntityArray(MandatoryOfflineTypeEnum);
  if (refTypeId !== OfflineReferenceTypeEnum.Offline_ServiceRequest) {
    items = items.filter(
      item => item.Id !== MandatoryOfflineTypeEnum.Mandatory_OnCompletion,
    );
  }
  if (searchTerm !== null && searchTerm !== '') {
    return items.filter(
      f => f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
    );
  }
  return items;
};
export function MandatoryOfflineTypePicker(
  props: MandatoryOfflineTypePickerProps,
) {
  const { refTypeId, mini, size, value, isOpen, id, ...other } = props;
  const getInnerOptions = getOptions(refTypeId);
  return (
    <BaseOptionsPicker
      mini={mini ? true : undefined}
      size={size}
      getOptions={getInnerOptions}
      value={value}
      isOpen={isOpen}
      id={id || 'mandatoryofflineTypeId'}
      {...other}
    />
  );
}
