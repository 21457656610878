/**
 *
 * ProfileMenu
 *
 */
import React from 'react';
import { EventsCount } from './EventsCount';
import { AssetQuickSearch } from 'app/components/AssetQuickSearch';
import { UserMenu } from './UserMenu';
import { useTheme } from '@material-ui/core/styles';
import { ProfileSection } from '../styled';
import { SwitchSites } from './SwitchSites';
import { useSelector } from 'react-redux';
import { selectShowGlobalServiceGroupFilter } from 'app/slice/selectors';
import { LocaleSelector } from 'app/components/LocaleSelector';

export const ProfileMenu = React.memo(function ProfileMenu() {
  const theme = useTheme();
  const showGroups = useSelector(selectShowGlobalServiceGroupFilter);
  return (
    <ProfileSection theme={theme} className={'userProfile'}>
      {!showGroups && <AssetQuickSearch />}
      <EventsCount />
      <SwitchSites />
      <UserMenu />
      <LocaleSelector />
    </ProfileSection>
  );
});
