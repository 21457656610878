import { FieldHookConfig, useField } from 'formik';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import {
  OfflineServiceTypePicker,
  OfflineServiceTypePickerProps,
} from '../pickers/AutocompletePickers/OfflineServiceTypePicker';

export const FormOfflineServiceTypePicker = (
  props: FieldHookConfig<IOfflineServiceFilterDto | null> &
    Omit<OfflineServiceTypePickerProps, 'onChange'>,
) => {
  const [field, meta, helpers] = useField<IOfflineServiceFilterDto | null>(
    props,
  );
  const handleChange = val => {
    props.onChange?.(val);
    helpers.setValue(val, true);
  };
  return (
    <OfflineServiceTypePicker
      predicates={props.predicates}
      name={props.name}
      placeholder={props.placeholder}
      disabled={props.disabled}
      label={props.label}
      onChange={handleChange}
      onBlur={field?.onBlur}
      value={field.value}
      error={meta?.error !== undefined}
      helperText={meta.error}
      fullWidth={props.fullWidth}
      expandedColumns={props.expandedColumns}
      variant="filled"
      urlType={props.urlType}
    />
  );
};
