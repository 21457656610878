import React from 'react';

/**
 * Custom hook for managing the document title.
 *
 * @param defaultTitle The default title to be used if no title is provided.
 * @returns A tuple containing the current title and a function to update the title.
 */
export const useTitle = (
  defaultTitle: string,
): [string | undefined, (string) => void] => {
  const refTitle = React.useRef<string | undefined>(defaultTitle);

  /**
   * Sets the document title and updates the reference title.
   * If the provided title is undefined, the default title will be used.
   * @param title - The new title for the document.
   */
  const handleTitleChange = (title: string | undefined) => {
    document.title = title || defaultTitle;
    refTitle.current = title || defaultTitle;
  };

  return [refTitle.current, handleTitleChange];
};
