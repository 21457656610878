/**
 *
 * MultiSelectBasicPicker
 *
 */
import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import { Entity } from 'types/common';
import {
  AutocompleteMultiPicker,
  AutocompleteMultiPickerProps,
} from 'app/components/BasicPickers/AutocompleteMultiPicker';
import { ODataFilterBuilder } from 'api/odata/ODataFilter';
import { undefinedIfIsEmpty } from 'app/components/BasicTable/useProfileSetting/parseProfileUrl';

export interface BaseURLMultiPickerProps<T>
  extends Omit<AutocompleteMultiPickerProps<T>, 'loadData'> {
  url: string;
  queryfilter?: string | undefined;
  select?: string[];
}

export function BaseURLMultiPicker<T>(props: BaseURLMultiPickerProps<T>) {
  const { url, queryfilter, select, ...other } = props;
  const loadData = async (searchTerm: string | null) => {
    const params: {
      $orderby: string;
      $top: number;
      $filter?: string;
      $select?: string;
    } = {
      $orderby: 'Name asc',
      $select: undefinedIfIsEmpty(select?.join(',')),
      $top: 100,
    };
    const predicates: string[] = [];
    if (queryfilter !== undefined && queryfilter !== '') {
      predicates.push(queryfilter);
    }

    params.$filter = new ODataFilterBuilder<Entity<number>>({
      predicates: predicates,
      stringColumns: ['Name'],
      stringSearch: searchTerm !== null ? searchTerm : undefined,
    }).toString();

    const response = await httpClient.get(url, params);
    return response.value;
  };
  return <AutocompleteMultiPicker loadData={loadData} {...other} />;
}
