import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { IUserRow } from './IUserRow';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { dateUtils } from 'utils/date-utils';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { UserRowState } from './typeUtils';
import { Degree } from 'enums/Degree';
import UserImageRender from './Components/UserImageRender';
import { UserComments } from './Components/UserComments';
import { DefaultUserGroup } from './Components/DefaultUserGroup';
import { DefaultBudget } from './Components/DefaultBudget';
import { InternalIdRender } from './Components/InternalIdRender';
import { InternalId2Render } from './Components/InternalId2Render';

export const getUserRows = (
  t: TFunction,
  globalSettings: GlobalSettingsType,
  openPanel: (state: SidePanelOpenState) => void,
  authenticatedUser?: AuthenticatedUser,
  isCover?: boolean,
  useSidePanel?: boolean,
  isMobile?: boolean,
  currentUserName?: string,
): IUserRow[] => {
  const defaultBudgetVisible =
    !!authenticatedUser &&
    (authenticatedUser.isAdmin() ||
      authenticatedUser.isHeadOfLab() ||
      authenticatedUser.isInvoiceAdmin() ||
      authenticatedUser?.Id === currentUserName) &&
    globalSettings.budgetModuleEnabled &&
    globalSettings.userDefaultBudgetEnabled;
  const defaultUserGroupVisible =
    !!authenticatedUser &&
    (authenticatedUser.isAdmin() ||
      authenticatedUser.isHeadOfLab() ||
      authenticatedUser.isInvoiceAdmin() ||
      authenticatedUser?.Id === currentUserName) &&
    globalSettings.ShowDefaultUserGroupOnUserProfileEnabled;
  const internalIdVisible =
    !!authenticatedUser &&
    (authenticatedUser.isAdmin() ||
      authenticatedUser.isGroupAdmin() ||
      authenticatedUser.isLabTech()) &&
    globalSettings.LoanDeskEnabled;
  const internalId2Visible =
    !!authenticatedUser &&
    (authenticatedUser.isAdmin() ||
      authenticatedUser.isGroupAdmin() ||
      authenticatedUser.isLabTech());
  const commentsVisible =
    !!authenticatedUser &&
    (authenticatedUser.isAdmin() ||
      (authenticatedUser.isGroupAdmin() &&
        globalSettings.UserServiceGroupCommentsEnabled) ||
      (authenticatedUser.isLabTech() &&
        globalSettings.UserLabTechCommentsEnabled));
  return [
    {
      Id: 'Image',
      PropName: 'Image',
      Name: t(translations.Image) as string,
      Cell: ({ value, original }) => (
        <UserImageRender
          value={value}
          original={original}
          shortView={isMobile}
        />
      ),
      NoHeader: true,
      validToRender: ({ value, original }) =>
        !!value && value !== null && !!value.Bytes && value.Bytes.length > 0,
      section: 'Image',
      //validToRender: ({ value }) => !!value && value !== null && !!value.Bytes,
    },
    {
      Id: 'UserName',
      PropName: 'Name',
      Name: t(translations.UserName) as string,
      section: 'Overview',
    },
    {
      Id: 'DisplayName',
      Name: t(translations.DisplayName) as string,
      section: 'Overview',
    },
    {
      Id: 'UserGroup',
      Name: t(translations.UserGroup),
      section: 'Overview',
    },
    {
      Id: 'DefaultUserGroup',
      Name: t(translations.DefaultUserGroup),
      section: 'EditableDetails',
      State: defaultUserGroupVisible ? UserRowState.Mandatory : undefined,
      validToRender: ({ value, original }) => defaultUserGroupVisible,
      Cell: ({ value, original }) => (
        <DefaultUserGroup
          value={value}
          original={original}
          shortView={isMobile}
        />
      ),
    },
    {
      Id: 'DefaultBudget',
      State: defaultBudgetVisible ? UserRowState.Mandatory : undefined,
      Name: t(translations.DefaultBudget) as string,
      validToRender: ({ value, original }) => defaultBudgetVisible,
      section: 'EditableDetails',
      Cell: ({ value, original }) => (
        <DefaultBudget value={value} original={original} shortView={isMobile} />
      ),
    },
    {
      Id: 'UserInternalId',
      Name: t(translations.UserInternalId) as string,
      State: internalIdVisible ? UserRowState.Mandatory : undefined,
      validToRender: ({ value, original }) => internalIdVisible,
      section: 'EditableDetails',
      Cell: ({ value, original }) => (
        <InternalIdRender
          value={value}
          original={original}
          shortView={isMobile}
        />
      ),
    },
    {
      Id: 'UserInternalId2',
      Name: t(translations.UserInternalId2) as string,
      State: internalId2Visible ? UserRowState.Mandatory : undefined,
      validToRender: ({ value, original }) => internalId2Visible,
      section: 'EditableDetails',
      Cell: ({ value, original }) => (
        <InternalId2Render value={value} original={original} />
      ),
    },
    {
      Id: 'Title',
      Name: t(translations.Title) as string,
      section: 'Overview',
    },
    {
      Id: 'Email',
      Name: t(translations.Email) as string,
      section: 'ContactDetails',
    },
    {
      Id: 'Discipline',
      PropName: 'DisciplineName',
      Name: t(translations.Discipline) as string,
      section: 'Overview',
    },
    {
      Id: 'Mobile',
      PropName: 'MobileTel',
      Name: t(translations.MobileTel) as string,
      section: 'ContactDetails',
    },
    {
      Id: 'OfficeTel',
      Name: t(translations.OfficeTel) as string,
      section: 'ContactDetails',
    },
    {
      Id: 'HomeTel',
      Name: t(translations.HomeTel) as string,
      section: 'ContactDetails',
    },
    {
      Id: 'Degree',
      Name: t(translations.Degree) as string,
      section: 'Overview',
      validToRender: ({ value, original }) => !!value && value !== null,
      Cell: ({ value, original }) => <> {t(Degree[value] as string)} </>,
    },
    {
      Id: 'YearInDegree',
      Name: t(translations.YearInDegree),
      section: 'Overview',
    },
    {
      Id: 'OfficeLocation',
      Name: t(translations.LabLocation) as string,
      section: 'ContactDetails',
    },
    {
      Id: 'Advisor',
      Name: t(translations.Advisor) as string,
      section: 'Overview',
    },
    {
      Id: 'Manager',
      Name: t(translations.Manager) as string,
      section: 'CompanyDetails',
    },
    {
      Id: 'Department',
      Name: t(translations.Department) as string,
      section: 'CompanyDetails',
    },
    {
      Id: 'Company',
      Name: t(translations.Company) as string,
      section: 'CompanyDetails',
    },
    {
      Id: 'Address',
      Name: t(translations.Address) as string,
      section: 'ContactDetails',
    },
    {
      Id: 'PoBox',
      Name: t(translations.PoBox) as string,
      section: 'ContactDetails',
    },
    {
      Id: 'PostalCode',
      Name: t(translations.PostalCode) as string,
      section: 'ContactDetails',
    },
    {
      Id: 'PostalAddress',
      Name: t(translations.PostalAddress) as string,
      section: 'ContactDetails',
    },
    {
      Id: 'Province',
      Name: t(translations.Province) as string,
      section: 'CompanyDetails',
    },
    {
      Id: 'Country',
      Name: t(translations.Country) as string,
      section: 'CompanyDetails',
    },
    {
      Id: 'LicenseNo',
      Name: t(translations.LicenseNo) as string,
      section: 'Overview',
    },
    {
      Id: 'LastLogin',
      Name: t(translations.LastLogin) as string,
      validToRender: ({ value, original }) => !!value && value !== null,
      Cell: ({ value, original }) => <>{dateUtils.shortDateFormat(value)}</>,
      section: 'AdditionalDetails',
    },
    {
      Id: 'LastActivityDate',
      Name: t(translations.LastActivityDate) as string,
      validToRender: ({ value, original }) => !!value && value !== null,
      Cell: ({ value, original }) => <>{dateUtils.shortDateFormat(value)}</>,
      section: 'AdditionalDetails',
    },
    {
      Id: 'RestrictedMobileNumber',
      Name: t(translations.RestrictedMobileNumber) as string,
      section: 'AdditionalDetails',
    },
    {
      Id: 'Comments',
      Name: t(translations.StaffComments) as string,
      validToRender: value => commentsVisible,
      State: commentsVisible ? UserRowState.Mandatory : UserRowState.Hidden,
      section: 'Comments',
      Cell(value) {
        return (
          <UserComments
            isAdmin={
              !!authenticatedUser &&
              (authenticatedUser.isAdmin() || authenticatedUser.isGroupAdmin())
            }
            userName={value.original.Name}
            checkPermissions={comment =>
              !!authenticatedUser &&
              (authenticatedUser.isAdmin() ||
                authenticatedUser.HasAdminGroupOnlyPermissions(
                  comment.ReferenceId ?? 0,
                ))
            }
            checkEditPermissions={comment =>
              !!authenticatedUser &&
              authenticatedUser.HasAdminGroupOnlyPermissions(
                comment.ReferenceId ?? 0,
              )
            }
          />
        );
      },
    },
    {
      Id: 'Overview',
      Name: t(translations.UserOverview) as string,
      State: UserRowState.SectionName,
      Cell: ({ value, original }) => (
        <BasicTypography variant="h4" bold>
          {t(translations.UserOverview)}
        </BasicTypography>
      ),
      section: 'Overview',
      sectionOrder: 0,
    },
    {
      Id: 'ContactDetails',
      Name: t(translations.UserContactDetails) as string,
      State: UserRowState.SectionName,
      Cell: ({ value, original }) => (
        <BasicTypography variant="h4" bold>
          {t(translations.UserContactDetails)}
        </BasicTypography>
      ),
      section: 'ContactDetails',
      sectionOrder: 1,
    },
    {
      Id: 'CompanyDetails',
      Name: t(translations.UserCompanyDetails) as string,
      State: UserRowState.SectionName,
      Cell: ({ value, original }) => (
        <BasicTypography variant="h4" bold>
          {t(translations.UserCompanyDetails)}
        </BasicTypography>
      ),
      section: 'CompanyDetails',
      sectionOrder: 2,
    },
    {
      Id: 'AdditionalDetails',
      Name: t(translations.UserAdditionalDetails) as string,
      State: UserRowState.SectionName,
      Cell: ({ value, original }) => (
        <BasicTypography variant="h4" bold>
          {t(translations.UserAdditionalDetails)}
        </BasicTypography>
      ),
      section: 'AdditionalDetails',
      sectionOrder: 3,
    },
    {
      Id: 'EditableDetails',
      Name: t(translations.EditableUserSettings) as string,
      State: UserRowState.SectionName,
      Cell: ({ value, original }) => (
        <BasicTypography variant="h4" bold>
          {t(translations.EditableUserSettings)}
        </BasicTypography>
      ),
      section: 'EditableDetails',
      sectionOrder: 1,
    },
  ];
};
type getRowsType = typeof getUserRows;

type ElementOf<T> = T extends Array<infer U> ? U : never;

export const rowsAsDict = (
  ...props: Parameters<getRowsType>
): Record<string, ElementOf<ReturnType<getRowsType>>> => {
  return getUserRows(...props).reduce((res, current) => {
    res[current.Id] = current;
    return res;
  }, {});
};
