/**
 *
 * AssetCatGroupPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { IWorkOrderTypeStatusDto } from 'api/odata/generated/entities/IWorkOrderTypeStatusDto';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';

import * as React from 'react';

import { Entity } from 'types/common';
export interface IWorkOrderTypeStatusParamsPicker {
  typeId?: number;
  onlyNew?: boolean;
}
export interface WorkOrderTypeStatusPickerProps
  extends Omit<AutocompletePickerProps<Entity<number>>, 'loadData'>,
    IWorkOrderTypeStatusParamsPicker {}

const url = '/api/odata/v4/WorkOrderStatus';
export const initWOTypeData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Order asc',
      $filter: '(Id eq ' + id + ')',
      $select:
        'Id,Name,Order,New,Closed,StatusId,WorkOrderTypeId,StatusName,Status',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
export function withParamsFilter(
  typeId: number | undefined,
  onlyNew: boolean | undefined,
) {
  if (typeId === undefined) {
    return (searchTerm: string | null) =>
      new Promise<Entity<number>[]>((resolve, reject) => {
        resolve([] as Entity<number>[]);
      });
  } else {
    return (searchTerm: string | null) => {
      const params: { $orderby: string; $filter?: string; $select?: string } = {
        $orderby: 'Order asc',
        $select:
          'Id,Name,Order,New,Closed,StatusId,WorkOrderTypeId,StatusName,Status',
      };
      const predicates: (string | Condition<IWorkOrderTypeStatusDto>)[] = [];
      predicates.push(
        new Condition('WorkOrderTypeId', ODataOperators.Equals, typeId),
      );
      if (onlyNew !== undefined) {
        predicates.push(new Condition('New', ODataOperators.Equals, true));
      }
      params.$filter = new ODataFilterBuilder<IWorkOrderTypeStatusDto>({
        predicates: predicates,
        stringColumns: ['Name'],
        stringSearch: searchTerm === null ? undefined : searchTerm,
        globalServiceGroupFilter: [],
      }).toString();
      return httpClient
        .get(url, params)
        .then(
          response =>
            response.value as IWorkOrderTypeStatusDto[] as Entity<number>[],
        );
    };
  }
}
export function WorkOrderTypeStatusPicker(
  props: WorkOrderTypeStatusPickerProps,
) {
  const loadData = withParamsFilter(props.typeId, props.onlyNew);
  return (
    <AutocompletePicker
      size={props.size}
      loadData={loadData}
      id={props.id || 'workordertypestatusId'}
      {...props}
    />
  );
}
