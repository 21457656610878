/**
 *
 * auto generated interface for Default.Roles
 *
 */

export enum Roles {
  Administrators = 1,
  GroupAdministrators = 2,
  InstituteCoord = 3,
  HeadOfLab = 4,
  CagesAdministrators = 5,
  LabTech = 6,
  FinAdmin = 7,
  RepAdmin = 8,
  InventoryAdmin = 9,
  UserGroupCoord = 10,
  InstrumentEventsAdmin = 11,
  GroupBudgetsAdmin = 12,
  CalendarOnly = 13,
  Readonly = 14,
  LoanDeskManager = 15,
  EquipmentAdministrator = 16,
  UserInquiryAdmin = 17,
  ModelAdmin = 18,
  RoomOnly = 19,
  InvoicesReader = 20,
  KioskAdmin = 21,
  CustomRole = 22,
  BudgetAdmin = 23,
  GroupAdminAffiliated = 24,
  AnimalAdmin = 25,
  LargeAnimalsOperatingRoomUser = 26,
  LargeAnimalsOperatingRoomTechnician = 27,
  LargeAnimalsOperatingRoomVeterinarian = 28,
  CoreBudgetsAdmin = 29,
  ConsumableKiosk = 30,
  CreateNewAssetsAdmin = 31,
  ServiceGroupBudgetsAdmin = 32,
}
export type RolesUnion = keyof typeof Roles;
export const RolesUnionArray: Array<RolesUnion> = [
  'Administrators',
  'GroupAdministrators',
  'InstituteCoord',
  'HeadOfLab',
  'CagesAdministrators',
  'LabTech',
  'FinAdmin',
  'RepAdmin',
  'InventoryAdmin',
  'UserGroupCoord',
  'InstrumentEventsAdmin',
  'GroupBudgetsAdmin',
  'CalendarOnly',
  'Readonly',
  'LoanDeskManager',
  'EquipmentAdministrator',
  'UserInquiryAdmin',
  'ModelAdmin',
  'RoomOnly',
  'InvoicesReader',
  'KioskAdmin',
  'CustomRole',
  'BudgetAdmin',
  'GroupAdminAffiliated',
  'AnimalAdmin',
  'LargeAnimalsOperatingRoomUser',
  'LargeAnimalsOperatingRoomTechnician',
  'LargeAnimalsOperatingRoomVeterinarian',
  'CoreBudgetsAdmin',
  'ConsumableKiosk',
  'CreateNewAssetsAdmin',
  'ServiceGroupBudgetsAdmin',
];
