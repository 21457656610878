import { H3 } from 'app/components/Typography';
import { H4 } from 'app/components/Typography';
import {
  FlexRowDiv,
  FlexRowNoContent,
} from 'app/components/basic/Wrappers/FlexWrappers/flexRow';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import React from 'react';
import BasicTypography from 'app/components/Typography/BasicTypography';

export interface NoTableDataProps {
  id?: string;
  shortView?: boolean;
}
export function NoTableData(props: NoTableDataProps) {
  const { t } = useTranslation();
  return (
    <FlexRowDiv id={props.id}>
      <FlexRowNoContent>
        {props.shortView ? (
          <React.Fragment>
            <BasicTypography variant={'boldM'}>
              {t(translations.dash_EmptyWidgetTitle) as string}
            </BasicTypography>
            <BasicTypography variant={'boldS'}>
              {t(translations.dash_EmptyWidgetBody) as string}
            </BasicTypography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <H3>{t(translations.dash_EmptyWidgetTitle) as string}</H3>
            <H4>{t(translations.dash_EmptyWidgetBody) as string}</H4>
          </React.Fragment>
        )}
      </FlexRowNoContent>
    </FlexRowDiv>
  );
}
