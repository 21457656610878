import { AssetLink } from 'app/pages/AssetPopUp/OpenAssetDetails';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { CellProps, Renderer } from 'react-table';

/**
 * Renders an Asset Link (Popup/sidepanel) for an AssetId
 *
 * @param param0 CellProps render parameters
 * @returns
 */
const AssetIdRenderer: Renderer<CellProps<Record<string, any>>> = ({
  row: { original },
  value,
}) => {
  const { t } = useTranslation();
  return value === null || !original.Id ? (
    <>{value}</>
  ) : (
    <AssetLink title={value + t(translations.ShowAssetFull)} id={original.Id}>
      {value}
    </AssetLink>
  );
};

export default AssetIdRenderer;
