/**
 *
 * AreasFilter
 *
 */
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { AreasPicker } from 'app/components/pickers/AutocompletePickers/AreassPicker';

export const AreasFilter = (props: IFilterComponentProps) => {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <>
      <AreasPicker
        ariaLabel={props.label}
        id={props.id}
        value={props.value as Entity<number> | undefined}
        size="small"
        onChange={handleOnChange}
        isOpen={props.open}
        fullWidth={props.fullWidth}
        placeholder={t(translations.AllValues)}
      />
    </>
  );
};
export default AreasFilter;
