import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { selectPublicUrl } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { buildURL } from 'utils/url-utils';
import { Link as MuiLink } from '@material-ui/core';
import { OfflineServiceQueryStringParameters } from 'app/pages/OtherServiceDetails/Details/slice/types';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { OtherServiceDetailsProps } from 'app/pages/OtherServiceDetails/Details';

export const ConsumableLink = (props: {
  id: number;
  children: React.ReactNode;
  title?: string;
  service?: IOtherServices;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
}) => {
  const { t } = useTranslation();
  const openDetails = useOpenConsumablePopUp();
  const getLink = useConsumableLinkUrl();
  const link = getLink({ id: String(props.id) });
  const open = () => {
    openDetails(
      {
        params: { id: String(props.id) },
        service: props.service,
        useSidePanel: props.useSidePanel,
      },
      props.openPanelWithCover,
    );
  };
  return (
    <Tooltip title={props.title ?? t(translations.EditOfflineServiceTitle)}>
      <MuiLink
        onClick={event => {
          if (!!link) {
            if (!event.ctrlKey) {
              event.preventDefault();
              open();
            }
          } else {
            open();
          }
        }}
        color="textPrimary"
        href={link}
      >
        {props.children}
      </MuiLink>
    </Tooltip>
  );
};

export const useOpenConsumablePopUp = () => {
  const { openPanel } = useSidePanelState();
  return React.useCallback(
    (
      props: {
        params: OfflineServiceQueryStringParameters;
        useSidePanel?: boolean;
        service?: IOtherServices;
      },
      openPanelWithCover?: (state: SidePanelOpenState) => void,
    ) => {
      let resProps = {
        queryParams: props.params,
        useSidePanel: true,
        initialService: props.service,
      } as OtherServiceDetailsProps;

      const panelState = {
        renderPageType: RenderPageType.OtherServiceDetails,
        renderPageProps: resProps,
        expanded: false,
        useSidePanel: props.useSidePanel,
        isCover: props.useSidePanel,
      } as SidePanelOpenState;
      if (!!openPanelWithCover) {
        openPanelWithCover(panelState);
      } else {
        openPanel({
          renderPageType: RenderPageType.OtherServiceDetails,
          renderPageProps: resProps,
          expanded: false,
          useSidePanel: false,
          isCover: false,
        } as SidePanelOpenState);
      }
    },
    [openPanel],
  );
};
export const useConsumableLinkUrl = () => {
  const publicUrl = useSelector(selectPublicUrl);

  return React.useCallback(
    (params: OfflineServiceQueryStringParameters) => {
      return buildURL(publicUrl + 'otherServices/details', params);
    },
    [publicUrl],
  );
};
