import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { assetQuickSearchSaga } from './saga';
import {
  AssetQuickSearchDetails,
  AssetQuickSearchState,
  IMenuItemsSearchResult,
  IQuickAssetsSearchResult,
  IQuickSearchResults,
} from './types';

export const initialState: AssetQuickSearchState = {
  open: false,
  showSearchInSidebar: false,
  showSearchResults: false,
  recentAssets: { status: undefined },
  recentSearchTerms: { status: undefined },
  searchResults: { status: undefined },
  allSearchResults: { status: undefined },
  onlineSearchResults: undefined,
  offlineSearchResults: undefined,
  requestSearchResults: undefined,
  totalSearchCount: undefined,
};

const slice = createSlice({
  name: 'assetQuickSearch',
  initialState,
  reducers: {
    showSearchInput(state, action: PayloadAction<boolean>) {
      state.showSearchInSidebar = action.payload;
      state.searchTerm = undefined;
    },
    setOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
    },
    searchAssets(state: AssetQuickSearchState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.showSearchResults = action.payload?.length > 0;
      state.searchResults.status = 'pending';
      state.searchResults.error = undefined;
    },
    searchAssets_Success(
      state,
      action: PayloadAction<IQuickAssetsSearchResult>,
    ) {
      state.searchResults.status = 'resolved';
      state.searchResults.error = undefined;
      state.searchResults.value = action.payload;
    },
    searchAssets_Error(state, action: PayloadAction<{ error: unknown }>) {
      state.searchResults.status = 'rejected';
      state.searchResults.error = action.payload;
      state.searchResults.value = undefined;
    },
    getRecentAssets(state, action: PayloadAction) {
      state.recentAssets.status = 'pending';
      state.recentAssets.error = undefined;
    },
    getRecentAssets_Success(
      state,
      action: PayloadAction<AssetQuickSearchDetails[]>,
    ) {
      state.recentAssets.status = 'resolved';
      state.recentAssets.error = undefined;
      state.recentAssets.value = action.payload;
    },
    getRecentAssets_Error(state, action: PayloadAction<{ error: unknown }>) {
      state.recentAssets.status = 'rejected';
      state.recentAssets.error = action.payload;
      state.recentAssets.value = [];
    },
    getRecentSearchTerms(state, action: PayloadAction) {
      state.recentSearchTerms.status = 'pending';
      state.recentSearchTerms.error = undefined;
    },
    getRecentSearchTerms_Success(state, action: PayloadAction<string[]>) {
      state.recentSearchTerms.status = 'resolved';
      state.recentSearchTerms.error = undefined;
      state.recentSearchTerms.value = action.payload;
    },
    getRecentSearchTerms_Error(
      state,
      action: PayloadAction<{ error: unknown }>,
    ) {
      state.recentSearchTerms.status = 'rejected';
      state.recentSearchTerms.error = action.payload;
      state.recentSearchTerms.value = [];
    },
    searchAllAssets(
      state: AssetQuickSearchState,
      action: PayloadAction<string>,
    ) {
      state.searchTerm = action.payload;
      state.showSearchResults = action.payload?.length > 0;
      state.allSearchResults.status = 'pending';
      state.allSearchResults.error = undefined;
    },
    searchAllAssets_Success(state, action: PayloadAction<IQuickSearchResults>) {
      state.allSearchResults.status = 'resolved';
      state.allSearchResults.error = undefined;
      state.allSearchResults.value = action.payload;
    },
    searchAllAssets_Error(state, action: PayloadAction<{ error: unknown }>) {
      state.allSearchResults.status = 'rejected';
      state.allSearchResults.error = action.payload;
      state.allSearchResults.value = undefined;
    },
    setOnlineSearchResults(
      state,
      action: PayloadAction<IQuickAssetsSearchResult | undefined>,
    ) {
      state.onlineSearchResults = action.payload;
    },
    setOfflineSearchResults(
      state,
      action: PayloadAction<IQuickAssetsSearchResult | undefined>,
    ) {
      state.offlineSearchResults = action.payload;
    },
    setRequestSearchResults(
      state,
      action: PayloadAction<IQuickAssetsSearchResult | undefined>,
    ) {
      state.requestSearchResults = action.payload;
    },
    setTotalSearchCount(state, action: PayloadAction<number | undefined>) {
      state.totalSearchCount = action.payload;
    },
    setMenuSearchResults(
      state,
      action: PayloadAction<IMenuItemsSearchResult | undefined>,
    ) {
      state.menuItemsSearchResult = action.payload;
    },
  },
});

export const { actions: assetQuickSearchActions } = slice;

export const useAssetQuickSearchSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: assetQuickSearchSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAssetQuickSearchSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
