import * as React from 'react';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import * as Yup from 'yup';
import { FormikProps } from 'formik';
import { CustomFormField } from '../CustomFormFields';
import { getFieldsVisibilities } from '../CustomFormUtils';
import { ValidationSchemaProvider } from 'app/components/Forms/ValidationSchemaProvider';
import { FormFieldsSection, FormRow } from 'app/components/Forms/FormsLayout';
import { sortBy } from 'lodash';

export interface CustomFormContentProps {
  title?: string;
  formik: FormikProps<Record<string, unknown>>;
  schema: Yup.SchemaOf<object>;
  formFields: IFormFieldDto[];
  validationEnabled?: boolean;
  isAdmin?: boolean;
  readonly?: boolean;
  disabled?: boolean;
}
export function CustomFormContent({
  title,
  formik,
  schema,
  formFields,
  validationEnabled,
  isAdmin,
  readonly,
  disabled,
}: CustomFormContentProps) {
  const isVisibleField = (
    field: IFormFieldDto,
    isAdmin: boolean,
    values: Record<string, any> | undefined,
    visibleOnCompletion?: () => boolean,
  ): boolean => {
    let visibilities = getFieldsVisibilities(
      formFields,
      isAdmin,
      values,
      visibleOnCompletion,
    );
    if (visibilities) {
      return visibilities[field.Id];
    }
    return true;
  };
  const sortedActiveFormFields = React.useMemo(
    () =>
      sortBy(
        formFields.filter(f => f.Active === true),
        f => f.Index,
      ),
    [formFields],
  );
  return (
    <ValidationSchemaProvider
      schema={schema}
      skipValidation={!validationEnabled}
    >
      <FormFieldsSection titleSection={title}>
        {sortedActiveFormFields.map((formField, index) => {
          // paragraphs & form sections deserve to start from the new line in 2 column layout (full screen)
          const fullRow =
            formField.Type !== null &&
            ['Paragraph', 'FormSection'].includes(formField.Type);
          return (
            <FormRow
              key={formField.Id}
              hide={
                isVisibleField(
                  formField,
                  isAdmin || false,
                  formik.values,
                  undefined,
                ) === false
              }
              fullRow={fullRow}
            >
              <CustomFormField
                name={String(formField.Id)}
                id={String(formField.Id)}
                key={index}
                formField={formField}
                readonly={readonly}
                disabled={readonly || formik.isSubmitting || disabled}
              />
            </FormRow>
          );
        })}
      </FormFieldsSection>
    </ValidationSchemaProvider>
  );
}
