import { PayloadAction } from '@reduxjs/toolkit';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import { ITrainingSessionSlotDto } from 'api/odata/generated/entities/ITrainingSessionSlotDto';
import { IWorkingHoursDto } from 'api/odata/generated/entities/IWorkingHoursDto';
import { IEquipmentTimeSlot } from 'api/odata/generated/complexTypes/IEquipmentTimeSlot';
import { TimelineGroupByUnion } from 'api/odata/generated/enums/TimelineGroupBy';
import { EmptySlotAdminClick } from 'enums/EmptySlotAdminClick';
import { Timestamp } from 'types/Timestamp';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { applyStateSearchParams, schedulerSaga } from './saga';

import {
  SchedulerState,
  IViewState,
  INewEvent,
  ChangeServicesAction,
  ChangeViewModeAction,
  ISchedulerService,
  GetServicesAction,
  SetFilterAction,
  IEditEvent,
} from './types';

export const initialState: SchedulerState = {
  events: {},
  offlineHours: {},
  trainingSessions: {},
  emptySlotAdminClick: EmptySlotAdminClick.ReservationsOnly,
  selectedReservationEvents: [],
  TimelineGroupBy: 'NoTimelineGrouped',
  exportToPDF: false,
};

const slice = createSlice({
  name: 'scheduler',
  initialState,
  reducers: {
    init(state, action: PayloadAction<IViewState | undefined>) {
      const result = { ...initialState, ...{ viewState: action.payload } };
      return result;
    },
    changeViewModePartial(state, action: PayloadAction<Partial<IViewState>>) {
      state.viewState = Object.assign({}, state.viewState, action.payload);
      state.viewState.search = applyStateSearchParams(state.viewState);
    },
    changeViewMode(state, action: ChangeViewModeAction) {
      state.notUseLocation = action.payload.withNoChangeLocation;
      state.viewState = Object.assign({}, state.viewState, {
        preset: action.payload.current.preset,
        viewType: action.payload.current.viewType,
        viewLength: action.payload.current.viewLength,
        date: action.payload.current.date,
        search: applyStateSearchParams(action.payload.current),
        customDays: action.payload.current.customDays,
        customStart: action.payload.current.customStart,
      });
    },
    setPreviousViewState(state, action: PayloadAction<IViewState>) {
      state.previousViewState = action.payload;
    },
    changeGroupBy(state, action: PayloadAction<TimelineGroupByUnion>) {},
    setDate(state, action: PayloadAction<Timestamp>) {
      if (state.viewState !== undefined) {
        state.viewState.date = action.payload;
      }
    },

    getServices(state, action: GetServicesAction) {
      state.selectedServices = undefined;
    },
    getServices_Success(
      state,
      action: PayloadAction<Array<ISchedulerService>>,
    ) {
      state.selectedServices = action.payload;
    },

    getDerivedServices(state, action: ChangeServicesAction) {},
    getDerivedServices_Success(
      state,
      action: PayloadAction<Array<ISchedulerService>>,
    ) {
      state.derivedServices = action.payload;
    },

    getEvents(state, action: PayloadAction) {
      state.loadingEvents = true;
    },
    getEvents_Success(
      state,
      action: PayloadAction<{
        reservations: Array<ICalendarReservationDto>;
        trainingSessions: Array<ITrainingSessionSlotDto>;
        offlineHours: Array<IWorkingHoursDto>;
      }>,
    ) {
      if (state?.viewState?.date !== undefined) {
        state.events[state.viewState.date] = action.payload.reservations;
        state.trainingSessions[state.viewState.date] =
          action.payload.trainingSessions;
        state.offlineHours[state.viewState.date] = action.payload.offlineHours;
      }
      state.loadingEvents = false;
    },
    getEvents_Error(state, action: PayloadAction<any>) {},

    getOfflineHours_Success(
      state,
      action: PayloadAction<Array<IWorkingHoursDto>>,
    ) {
      if (state?.viewState?.date !== undefined) {
        state.offlineHours[state.viewState.date] = action.payload;
      }
    },
    getNonWorkingHours_Success(
      state,
      action: PayloadAction<Array<IEquipmentTimeSlot>>,
    ) {
      state.nonWorkingHours = action.payload;
    },
    getTutoringWorkingHours_Success(
      state,
      action: PayloadAction<Array<IEquipmentTimeSlot>>,
    ) {
      state.tutoringWorkingHours = action.payload;
    },
    getTrainingSessions_Success(
      state,
      action: PayloadAction<Array<ITrainingSessionSlotDto>>,
    ) {
      if (state?.viewState?.date !== undefined) {
        state.trainingSessions[state.viewState.date] = action.payload;
      }
    },

    setFilters(state, action: SetFilterAction) {
      // reset the events
      state.events = {};
      state.offlineHours = {};
      state.trainingSessions = {};
      state.loadingEvents = true;
      state.filters = action.payload;
    },

    edit(state, action: PayloadAction<IEditEvent>) {
      switch (action.payload.type) {
        case 'offline':
          // store the updated start/end of the offline event in the state
          // this might prevent event jumping back after it's been dragged for the first time after the scheduler has been loaded
          const entity = Object.values(state.offlineHours)
            ?.flat()
            ?.find(f => f.AlertId === action.payload.alert_id);
          if (entity !== undefined) {
            entity.StartTime = action.payload.start_date;
            entity.EndTime = action.payload.end_date;
          }
      }
    },
    create(state, action: PayloadAction<INewEvent | undefined>) {
      state.tempReservations = action.payload;
    },
    updateMultiMode(state, action: PayloadAction<boolean | undefined>) {},
    updateMultiMode_Success(state, action: PayloadAction<boolean | undefined>) {
      state.multipleInstrumentsMode = action.payload;
    },
    updateEmptySlotSettings(
      state,
      action: PayloadAction<EmptySlotAdminClick>,
    ) {},
    updateEmptySlotSettings_success(
      state,
      action: PayloadAction<EmptySlotAdminClick>,
    ) {
      state.emptySlotAdminClick = action.payload;
    },
    getEmptySlotAdminClick(state, action: PayloadAction) {},
    setNotUseLocation(state, action: PayloadAction<boolean | undefined>) {
      state.notUseLocation = action.payload;
    },
    setSelectedReservationEvents(
      state,
      action: PayloadAction<Array<IEditEvent>>,
    ) {
      state.selectedReservationEvents = action.payload;
    },
    exportToPDF(state, action: PayloadAction) {
      state.exportToPDF = true;
    },
    resetExportToPDF(state, action: PayloadAction) {
      state.exportToPDF = false;
    },
  },
});

export const { actions: schedulerActions } = slice;

export const useSchedulerSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: schedulerSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSchedulerSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
