import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRequestSamplesSlice } from '../../slice';
import { Identifiable } from 'types/common';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';

export interface CloneServiceRequestButtonProps {
  item?: Identifiable<number>;
}
export function CloneServiceRequestButton(
  props: CloneServiceRequestButtonProps,
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useRequestSamplesSlice();

  const handleCloneButtonClick = React.useCallback(() => {
    if (props.item !== undefined) {
      dispatch(actions.cloneServiceRequest(props.item.Id));
    }
  }, [actions, dispatch, props.item]);

  if (props.item === undefined) {
    return null;
  }

  return (
    <Button
      variant="ghost"
      size="small"
      startIcon={<Icon icon="copy" />}
      aria-label="copy"
      onClick={handleCloneButtonClick}
      title={t(translations.Clone)}
    >
      {t(translations.Clone)}
    </Button>
  );
}
