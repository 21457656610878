import { Entity } from 'types/common';
import * as React from 'react';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { IUserGroupDto } from 'api/odata/generated/entities/IUserGroupDto';
import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';

export interface BudgetListenerProps<T> {
  budget: Entity<number> | null;
  ReservationUserGroupGroupByBudgetUserGroup: boolean;
  values: T;
  setUserGroup: (value: Entity<string> | null) => void;
  wait?: boolean;
  fundingTypeVisible?: boolean;
  setFundingType: (value: Entity<number> | null) => void;
  handleValidate?: (
    userGroup: Entity<string> | null,
    budget: IBudgetFilterDto | null,
  ) => void;
}

export function BudgetListener<T>(props: BudgetListenerProps<T>) {
  const {
    budget,
    ReservationUserGroupGroupByBudgetUserGroup,
    setUserGroup,
    wait,
    handleValidate,
    fundingTypeVisible,
    setFundingType,
  } = props;
  const [initilized, setInitilized] = React.useState(true);
  React.useEffect(() => {
    if (!initilized && !wait) {
      if (!!budget?.Id) {
        if (ReservationUserGroupGroupByBudgetUserGroup) {
          if (!!(budget as IBudgetFilterDto).UserGroupId) {
            let ug = {
              Id: (budget as IBudgetFilterDto).UserGroupId,
              Name: (budget as IBudgetFilterDto).UserGroupName,
            } as Entity<string>;
            setUserGroup(ug as IUserGroupDto);
            handleValidate?.(ug, budget as IBudgetFilterDto);
          } else {
            handleValidate?.(null, budget as IBudgetFilterDto);
          }
        }
        if (
          fundingTypeVisible &&
          (budget as IBudgetFilterDto).FundingTypeId !== null
        ) {
          setFundingType(
            getSingleInvoiceFundingType(
              (budget as IBudgetFilterDto).FundingTypeId ?? 0,
            ),
          );
        }
      } else {
        if (ReservationUserGroupGroupByBudgetUserGroup) {
          setUserGroup(null);
        }
        handleValidate?.(null, null);
      }
    }
    setInitilized(false);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budget]);

  return null;
}
