import * as React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  Table,
} from '@material-ui/core/index';
import TablePagination from '@material-ui/core/TablePagination';
import {
  Column,
  useTable,
  useRowSelect,
  usePagination,
  PluginHook,
  useGlobalFilter,
  Row,
} from 'react-table';

import TablePaginationActions from './TablePaginationActions';
import { GlobalFilter } from 'app/components/BasicTable/GlobalFilter';
import { SelectedActions } from 'app/components/BasicTable/SelectedActions';
import { BasicTableProps } from 'app/components/BasicTable';
import { TableToolbar } from 'app/components/BasicTable/BasicFilter/TableToolbar';
import { useRowActionsColumn } from 'app/components/BasicTable/RowActions';
import { ConfigureColumnsButton } from 'app/components/BasicTable/components/ConfigureColumnsButton';
import { ExportButton } from 'app/components/BasicTable/ExportButton';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { toRootedURL } from 'utils/url-utils';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { DetectIsMobile } from 'utils/mobileDetect';
import clsx from 'clsx';
import { Check } from 'app/components/BasicInputs/Check';
import { CheckWrapper } from 'app/components/BasicTable/ControlledTable/addRowSelect';
import { TableRoot } from 'app/components/BasicTable/styled';

export interface EditableTableProps<TRow extends object>
  extends Pick<
    BasicTableProps<TRow>,
    | 'columns'
    | 'selectedRowsActions'
    | 'pageActions'
    | 'rowActions'
    | 'tablePaddingVariant'
  > {
  columns: Column<TRow>[];
  // defaultColumn: any;
  //   skipPageReset: boolean;
  updateMyData?: any;
  data: TRow[];
  toolbar?: (selectedRows: TRow[]) => JSX.Element;
  // controlledPageCount: number;
  // fetchData: (state: ITableState) => void;
  toggleHideColumn?: (columnId: String, value: Boolean) => void;
  useRowSelect: boolean;
  onSelectedRowsChange?: (selectedRows: TRow[]) => void;
  useGlobalFilter: boolean;
  subHeader?: React.ReactNode;
  screenName: string | React.ReactNode;
  handleConfigurableColumns: () => void;
  handleExport: () => Promise<void>;
  useImportLink?: boolean;
  importLink?: string;
  importHandler?: () => void;
  onExportForImport?: (rows: Row<TRow>[]) => void;
  onDownloadTemplate?: () => void;
}

export function EditableTable<TRow extends Record<string, unknown>>({
  selectedRowsActions,
  onSelectedRowsChange,
  screenName,
  ...props
}: EditableTableProps<TRow>) {
  const { t } = useTranslation();
  const {
    columns,
    data,
    // fetchData,
    // controlledPageCount,
    // skipPageReset,
    updateMyData,
  } = props;
  const isMobile = DetectIsMobile();
  const tablePlugins: PluginHook<TRow>[] = [useGlobalFilter, usePagination];

  if (props.useRowSelect) {
    tablePlugins.push(useRowSelect);
    tablePlugins.push(hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <CheckWrapper className="check-root" aria-label="Check All">
              <Check
                {...getToggleAllRowsSelectedProps()}
                inputProps={{ 'aria-label': 'Select All' }}
                withPadding={false}
              />
            </CheckWrapper>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <CheckWrapper
              className="check-root"
              onClick={e => e.stopPropagation()}
            >
              <Check
                {...row.getToggleRowSelectedProps()}
                inputProps={{ 'aria-label': 'Select' }}
                withPadding={false}
              />
            </CheckWrapper>
          ),
        },
        ...columns,
      ]);
    });
  }

  useRowActionsColumn<TRow>(props.rowActions, tablePlugins);
  const tableInstance = useTable(
    {
      columns,
      data,

      // use the skipPageReset option to disable page resetting temporarily
      //   autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      autoResetPage: false,
      // initialState: {  pageSize: 10 },
      // manualPagination: true, // Tell the usePagination
      // pageCount: controlledPageCount,
    },
    ...tablePlugins,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Instead of using 'rows', we'll use page,
    prepareRow,
    //pagination
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds },
    selectedFlatRows,
    toggleAllRowsSelected,
    setGlobalFilter,
  } = tableInstance;

  const isSomeselected = Object.keys(selectedRowIds || {}).length > 0;
  const [selected, setSelected] = React.useState<Record<any, TRow>>({});
  React.useEffect(
    () =>
      onSelectedRowsChange?.(Object.entries(selected).map(value => value[1])),
    [onSelectedRowsChange, selected],
  );

  React.useEffect(() => {
    setSelected(state =>
      Object.keys(selectedRowIds || {}).reduce((accumulator, key) => {
        accumulator[key] =
          state[key] ?? selectedFlatRows.find(row => row.id === key)?.original;
        return accumulator;
      }, {}),
    );
  }, [selectedRowIds, selectedFlatRows]);
  const selectedArray = Object.entries(selected).map(value => value[1]);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value));
  };
  return (
    <>
      <TableRoot
        className={clsx('table-root')}
        key="table-container"
        id={`editable_table_container`}
      >
        <TableToolbar
          screenName={screenName}
          children={
            props.pageActions?.map((item, index) => (
              <span key={index}>{item(0, () => void 0)}</span>
            )) ?? []
          }
        />

        {props.subHeader !== undefined && <div>{props.subHeader}</div>}
        <div
          className={clsx('table-buttons', {
            'table-buttons-short': isMobile,
            'table-padding-dense': props.tablePaddingVariant === 'dense',
          })}
        >
          <div
            className={clsx('table-buttons-left', {
              'table-buttons-left-wrap':
                isSomeselected &&
                selectedRowsActions !== undefined &&
                selectedRowsActions.length > 0,
              'table-buttons-left-no-wrap':
                (isSomeselected &&
                  selectedRowsActions !== undefined &&
                  selectedRowsActions.length > 0) === false,
            })}
          >
            <>
              {isSomeselected && selectedRowsActions !== undefined ? (
                <SelectedActions
                  actions={selectedRowsActions || []}
                  selectedArray={selectedArray}
                  toggleAllRowsSelected={toggleAllRowsSelected}
                  totalRows={data.length}
                />
              ) : (
                <>
                  {/* {screenId && AuthenticatedUser && (
                    <Button
                      title={t(translations.ConfigurableColumnsLink) as string}
                      variant="main"
                      color="filters"
                      size="small"
                      startIcon={<Icon icon="table" size="sm" />}
                      onClick={handleConfigurableColumns}
                    >
                      {t(translations.Columns)}
                    </Button>
                  )} */}
                  {/* {useExport && (
                    <ExportButton
                      onClick={handleExportClick}
                      enableExportForImport={onExportForImport !== undefined}
                      selectedRows={selectedFlatRows}
                      onExportForImportClick={onExportForImport}
                    />
                  )} */}
                  {/* {useImportLink && importLink && (
                    <Button
                      variant="main"
                      color="filters"
                      size="small"
                      startIcon={<Icon icon="file-import" size="sm" />}
                      onClick={() =>
                        window.open(toRootedURL(importLink), '_self')
                      }
                    >
                      {t(translations.Import)}
                    </Button>
                  )} */}

                  <GlobalFilter
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </>
              )}
            </>
          </div>
          <div
            className={clsx('table-buttons-right', {
              'table-buttons-right-short': isMobile,
            })}
          >
            <ConfigureColumnsButton onClick={props.handleConfigurableColumns} />
            <ExportButton onClick={props.handleExport} />
            {props.useImportLink &&
              (props.importLink || props.importHandler) && (
                <Button
                  variant="white"
                  size="small"
                  startIcon={<Icon icon="file-import" />}
                  onClick={() => {
                    if (props.importHandler) {
                      props.importHandler();
                    } else {
                      if (props.importLink) {
                        window.open(toRootedURL(props.importLink), '_self');
                      }
                    }
                  }}
                >
                  {t(translations.Import)}
                </Button>
              )}
          </div>
        </div>
        <div
          id={`table_editable_content`}
          className={clsx('table-content', {
            'table-content-short': isMobile,
            'table-padding-dense': props.tablePaddingVariant === 'dense',
          })}
        >
          <div className={'table-scrolling'}>
            <Table
              {...getTableProps()}
              className={clsx('table', {
                'with-footer': true,
              })}
            >
              <TableHead>
                {
                  // Loop over the header rows
                  headerGroups.map(headerGroup => (
                    // Apply the header row props
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map(column => (
                          // Apply the header cell props
                          <TableCell {...column.getHeaderProps()}>
                            {
                              // Render the header
                              column.render('Header')
                            }
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  ))
                }
              </TableHead>
              {/* Apply the table body props */}
              <TableBody {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  page.map(row => {
                    // Prepare the row for display

                    prepareRow(row);
                    return (
                      // Apply the row props
                      <TableRow {...row.getRowProps()}>
                        {
                          // Loop over the rows cells
                          row.cells.map(cell => {
                            // Apply the cell props
                            return (
                              <TableCell {...cell.getCellProps()}>
                                {
                                  // Render the cell contents
                                  cell.render('Cell')
                                }
                              </TableCell>
                            );
                          })
                        }
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </div>
          <MuiTable {...getTableProps()}>
            <TableFooter>
              <TableRow>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  colSpan={columns.length + (props.useRowSelect ? 1 : 0)}
                  count={data.length}
                  rowsPerPage={pageSize}
                  page={pageIndex}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </MuiTable>
        </div>
      </TableRoot>
    </>
  );
}
