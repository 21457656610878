import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import {
  Divider,
  LinearProgress,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
} from '@material-ui/core';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import {
  AssetsFilterForWorkOrder,
  AssetsPicker,
  initAssetsData,
} from 'app/components/pickers/MultiSelectPickers/AssetsPicker/index';
import { initWOTypeDataParams } from 'app/components/pickers/AutocompletePickers/WorkOrderTypePicker';
import { WorkOrderQueryStringParameters } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details/slice/types';
import { GetRawValue } from 'app/components/BasicTable/types/FilterParam';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { WorkOrderDetailsProps } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import { Body } from 'app/components/Typography';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { useLocationChange } from 'app/hooks/useLocationChange';
import { ButtonsVariant } from 'app/components/BasicButtons/Button';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { DetectIsMobile } from 'utils/mobileDetect';
import styled from 'styled-components';
import clsx from 'clsx';

export interface NewWorkOrderButtonProps {
  assetIds?: string;
  downtime?: boolean;
  text?: string;
  noChangeOnMobile?: boolean;
  fullWidth?: boolean;
  isIconButton?: boolean;
  variant?: ButtonsVariant;
  initAsset?: IAssetDto;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
  id?: string;
}

export const NewWorkOrderButton = (props: NewWorkOrderButtonProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isMobile = DetectIsMobile();
  const { openPanel } = useSidePanelState();
  const {
    assetIds,
    downtime,
    text,
    noChangeOnMobile,
    fullWidth,
    isIconButton,
    variant,
    useSidePanel,
    openPanelWithCover,
    id,
  } = props;
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'workOrderTypeSelection',
  });
  //const [open, setOpen] = useState<boolean>(false);
  const [assets, setAssets] = React.useState<IAssetDto[] | undefined>(
    undefined,
  );
  const [workTypes, setWorkTypes] = React.useState<
    IWorkOrderTypeDto[] | undefined
  >(undefined);
  const [loadingAsset, setLoadingAssets] = React.useState<boolean | undefined>(
    !!assetIds && !!assets && assets.length < 1,
  );
  const handleAssetChange = React.useCallback(
    (value: IAssetDto[] | []) => {
      setAssets(value);
      setLoadingAssets(true);
      initWOTypeDataParams(value, undefined, downtime).then(types => {
        setWorkTypes(types);
        setLoadingAssets(false);
      });
    },
    [downtime],
  );
  const getParamFromUrl = (name: string): string | undefined => {
    return GetRawValue(new URLSearchParams(location.search), name) ?? undefined;
  };
  const handleWorkTypeClick = (event: IWorkOrderTypeDto) => {
    let params = {
      queryParams: {
        id: undefined,
        aType: event.Id.toString(),
        down: (downtime || getParamFromUrl('down')) && event.AssetAlertType,
        source: getParamFromUrl('source'),
        Up: getParamFromUrl('Up'),
        title: getParamFromUrl('title'),
        eStart: getParamFromUrl('eStart'),
        sympt: getParamFromUrl('sympt'),
        aStatus: getParamFromUrl('aStatus'),
        aStart: getParamFromUrl('aStart'),
        desc: getParamFromUrl('desc'),
        assetId: !!assets ? assets.map(a => a.Id).join(',') : undefined,
        offH:
          (downtime || getParamFromUrl('down')) && event.AssetAlertType
            ? getParamFromUrl('offH')
            : undefined,
        offStart: getParamFromUrl('offStart'),
        offEnd: getParamFromUrl('offStart'),
        reason: getParamFromUrl('reason'),
        eqid: getParamFromUrl('eqid'),
        sid: getParamFromUrl('sid'),
      } as WorkOrderQueryStringParameters,
      useSidePanel: true,
    } as WorkOrderDetailsProps;
    const panelProps = {
      renderPageType: RenderPageType.WorkOrderDetails,
      renderPageProps: params,
      expanded: false,
      useSidePanel: useSidePanel,
      isCover: useSidePanel,
    } as SidePanelOpenState;

    !!openPanelWithCover
      ? openPanelWithCover(panelProps)
      : openPanel(panelProps);
  };

  useLocationChange((current: Location, previus: Location) => {
    let ids = getParamFromUrl('aid');
    if ((!!ids && ids !== '') || !!assetIds) {
      setLoadingAssets(true);
      initAssetsData(ids || assetIds).then(response => {
        setAssets(response);
        initWOTypeDataParams(response, undefined, downtime).then(types => {
          setLoadingAssets(false);
          setWorkTypes(types);
        });
      });
    } else {
      setWorkTypes(undefined);
      setAssets(undefined);
      setLoadingAssets(false);
    }
  });
  return (
    <div style={fullWidth ? { width: '100%' } : undefined}>
      <TopActionButton
        text={text ?? (t(translations.AddEvent) as string)}
        id={id}
        title={t(translations.CreateAnAssetRelatedEvent) as string}
        icon="plus"
        startIcon="plus"
        endIcon="chevron-down"
        noChangeOnMobile={noChangeOnMobile}
        isIconButton={isIconButton}
        fullWidth={fullWidth}
        variant={variant}
        {...bindTrigger(popupState)}
      />
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableAutoFocus
        className={isMobile ? 'full-height' : undefined}
      >
        <NewWorkOrderRoot
          id="workOrderTypeSelection"
          className={clsx('work-root', { 'work-root-short': isMobile })}
        >
          <div className="work-root-header" id="workorder_header">
            <Body size="small" bold={true}>
              {t(translations.CreateAnAssetRelatedEvent)}
            </Body>
            <IconButton
              aria-label="close"
              size="xs"
              style={{ position: 'absolute', right: 8, top: 8 }}
              id="workorder_close"
              onClick={() => {
                popupState.close();
                setAssets([]);
                setWorkTypes([]);
              }}
            >
              <Icon icon="times" />
            </IconButton>
          </div>
          <div className="work-root-item">
            <AssetsPicker
              predicates={AssetsFilterForWorkOrder(undefined)}
              key="assetKey"
              restrictByAvType="serviceRelated"
              onChange={value => {
                handleAssetChange(value);
              }}
              placeholder={t(translations.QuickWorkOrderPleaseSelectAssets)}
              fullWidth={true}
              name="assets"
              variant="filled"
              id="asset-id"
              label={t(translations.Asset)}
              value={
                props.initAsset !== undefined
                  ? [props.initAsset]
                  : assets || undefined
              }
              disabled={props.initAsset !== undefined}
            />
          </div>
          {loadingAsset && (
            <div className="work-root-progress">
              <LinearProgress variant="query" color="primary" />
            </div>
          )}
          {assets && assets.length > 0 && (
            <React.Fragment>
              <div className="work-root-item">
                <Divider variant="fullWidth" />
              </div>
              <div className="work-root-item" id="workorder_list_wrap">
                <MenuList
                  variant="menu"
                  dense
                  className="work-root-list"
                  id="workorder_list"
                >
                  <MenuItem>
                    <Body size="small" bold={true}>
                      {t(translations.PleaseSelectAnEventType)}
                    </Body>
                    <Divider />
                  </MenuItem>
                  {workTypes && workTypes.length > 0 ? (
                    workTypes.map(wt => (
                      <MenuItem
                        key={wt.Id}
                        onClick={() => {
                          handleWorkTypeClick(wt);
                          popupState.close();
                          setAssets([]);
                          setWorkTypes([]);
                        }}
                      >
                        <ListItemText>
                          <Body size="small">{wt.Name}</Body>
                        </ListItemText>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>
                      <ListItemText>
                        {t(translations.NoAlertTypesFoundForSelectedAssets)}
                      </ListItemText>
                    </MenuItem>
                  )}
                </MenuList>
              </div>
            </React.Fragment>
          )}
        </NewWorkOrderRoot>
      </Popover>
    </div>
  );
};
const NewWorkOrderRoot = styled.div`
  &.work-root {
    display: flex;
    flex-direction: column;
    min-width: 350px;
    padding: 8px;
    gap: 16px;
    &.work-root-short: {
      min-width: 100%;
      width: 100%;
    }
    & .work-root-header {
      padding: 8px;
      display: flex;
      justify-content: space-between;
      gap: 8px;
    }
    & .work-root-item {
      padding: 8px;
      display: flex;
      gap: 8px;
      & .work-root-list {
        max-height: 420px;
      }
    }
    & .work-root-progress {
      width: 100%;
    }
  }
`;
