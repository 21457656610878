/**
 *
 * OnlineServiceTypeFilter
 *
 */

import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';
import { OnlineServiceTypePicker } from 'app/components/pickers/StaticOptionsPickers/OnlineServiceTypePicker';

export const OnlineServiceTypeFilter = (
  props: IFilterComponentProps,
): JSX.Element => {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  //RENDER
  const { t } = useTranslation();
  return (
    <OnlineServiceTypePicker
      value={props.value as Entity<number> | undefined}
      onChange={handleOnChange}
      size="small"
      fullWidth={props.fullWidth}
      ariaLabel={props.label}
      id={props.id}
      isOpen={props.open}
      placeholder={t(translations.AllValues)}
    />
  );
};

export default OnlineServiceTypeFilter;
