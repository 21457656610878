import { Chip, ChipProps, Theme, withStyles } from '@material-ui/core';

export interface StatusChipProps extends ChipProps {}

const StyledChip = withStyles((theme: Theme) => ({
  //   root: {
  //     borderRadius: 4,
  //     backgroundColor: theme.palette.primary.main,
  //     height: 24,
  //     color: theme.palette.common.white,
  //     gap: 4,
  //     paddingBlock: '2px',
  //     '&$disabled': {
  //       opacity: 1,
  //       backgroundColor: theme.palette.common.grayComponents,
  //       color: theme.palette.common.white,
  //       pointerEvents: 'none',
  //     },
  //     '&:hover, &:focus': {
  //       backgroundColor: theme.palette.primary.dark,
  //       color: theme.palette.common.white,
  //     },
  //   },
  //   disabled: {
  //     backgroundColor: theme.palette.common.grayComponents,
  //     color: theme.palette.common.white,
  //   },
  //   label: {
  //     fontFamily: `'Lato', sans-serif`,
  //     fontStyle: 'normal',
  //     fontWeight: 400,
  //     fontSize: '14px',
  //     lineHeight: '141%',
  //     letterSpacing: '0.1px',
  //   },
  //   labelSmall: {
  //     fontFamily: `'Lato', sans-serif`,
  //     fontStyle: 'normal',
  //     fontWeight: 400,
  //     fontSize: '14px',
  //     lineHeight: '141%',
  //     letterSpacing: '0.1px',
  //   },
  //   clickable: {
  //     backgroundColor: theme.palette.primary.main,
  //     color: theme.palette.common.white,
  //   },
  //   sizeSmall: {
  //     height: 20,
  //   },
  //   deleteIcon: {
  //     color: theme.palette.primary.contrastText,
  //     height: 18,
  //     width: 18,
  //     '&:hover': {
  //       color: fade(theme.palette.primary.contrastText, 0.8),
  //     },
  //   },
}))(Chip);

export const StatusChip = (props: StatusChipProps) => {
  return <StyledChip {...props} />;
};
