import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { toURLSearchParams } from 'app/components/BasicTable/withSavedHistory';

import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Row } from 'react-table';
import { isEmptyOrWhitespace } from 'utils/typeUtils';
import { buildURL } from 'utils/url-utils';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';
import { Condition } from 'api/odata/ODataFilter';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { Roles } from 'api/odata/generated/enums/Roles';
import useSidePanelState, {
  SidePanelCloseState,
} from 'app/hooks/useSidePanelOpen';
import { selectOfflineRefsFilters } from 'app/pages/OfflineTypeReferencesDetails/Details/slice/selectors';
import { IOfflineServiceTypeRefsDto } from 'api/odata/generated/entities/IOfflineServiceTypeRefsDto';
import { OfflineReferenceTypeEnum } from 'enums/OfflineReferenceTypeEnum';
import { getSingleEnumEntityById } from 'utils/enumKeys';
import { AddOfflineRefs } from 'app/pages/Actions/PageTopActions/AddOfflineRefs';
import { useOfflineTypeRefsSlice } from 'app/pages/OfflineTypeReferencesDetails/Details/slice';
import { OfflineTypeReferencesTable } from '../OfflineTypeReferencesTable';
import { filterHandler } from 'app/components/BasicTable/UnconfigurableTable/FilterHandler';
import { GetFilters } from '../Filter';
import { ScreensId } from 'enums/ConfigurableTypes';
import { getColumns } from '../Columns';
import { useAdminPermissions } from 'app/permissions/Asset/assetPermissions';
import { GetResponseMessages, IResponseType } from 'types/ResponseType';
import { httpClient } from 'api/HttpClient';
import { SelectedRowsActionIconButton } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionIconButton';
import { SelectedRowsActionRenderer } from 'app/components/BasicTable/SelectedRowsActionButton/SelectedRowsActionRenderer';

export interface OfflineTypeReferencesPopUpProps
  extends CoverProps,
    SidePanelContentProps {
  refId?: number;
  refTypeId?: number;
  customPredicates?: (string | Condition<IOfflineServiceTypeRefsDto>)[];
  pageLink?: string;
  onCloseTable?: () => void;
}

export function OfflineTypeReferencesPopUp(
  props: OfflineTypeReferencesPopUpProps,
) {
  const { useSidePanel, refId, refTypeId, onCloseTable, closeCover } = props;
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const filters = useSelector(selectOfflineRefsFilters);
  const { actions } = useOfflineTypeRefsSlice();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const publicUrl = useSelector(selectPublicUrl);
  // const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  // const coverExpanded = useSelector(selectIsCoverExpanded);
  // const isMobile = DetectIsMobile();
  // const [cover, setCover] = React.useState<React.ReactNode>();
  const { cover, openPanel, closePanel, coverClosed, onCloseCover } =
    useSidePanelState(
      () => (!!onCloseTable ? false : true),
      undefined,
      useSidePanel,
      props.isCover,
    );
  const { allowedToEditGroup } = useAdminPermissions();

  // const showShortView =
  //   isMobile ||
  //   (useSidePanel && !sidePanelExpanded) ||
  //   (props.isCover && !coverExpanded);
  const pagelink = React.useMemo(
    () =>
      !!props.pageLink
        ? props.pageLink
        : buildURL(
            `${publicUrl}ServiceRelatedRefs`,
            Object.assign({}, filters, {
              refId: refId,
              refTypeId: refTypeId,
            }),
          ),
    [filters, props.pageLink, publicUrl, refId, refTypeId],
  );

  const closeSidePanel = React.useCallback(() => {
    if (useSidePanel) {
      if (!!onCloseTable) {
        onCloseTable();
        dispatch(actions.setFilters(undefined));
      } else {
        closePanel({
          isCover: props.isCover,
          useSidePanel: useSidePanel,
          onClose: () => {
            dispatch(actions.setFilters(undefined));
          },
        } as SidePanelCloseState);
      }
    }
  }, [
    actions,
    closePanel,
    dispatch,
    onCloseTable,
    props.isCover,
    useSidePanel,
  ]);

  // const openAsset = React.useCallback(
  //   (id: number, serviceType: number) => {
  //     const assetProps: AssetPopUpProps = {
  //       identifier: {
  //         serviceId: id,
  //         serviceTypeId: serviceType,
  //       },
  //       useSidePanel: true,
  //     };
  //     openPanel({
  //       renderPageType: RenderPageType.AssetDetails,
  //       renderPageProps: assetProps,
  //       expanded: false,
  //       useSidePanel: useSidePanel,
  //       isCover: useSidePanel,
  //     } as SidePanelOpenState);
  //   },
  //   [openPanel, useSidePanel],
  // );
  //   const IsAdmin = React.useCallback(
  //     (asset: IMyAssetsRow) => {
  //       return isAuthenticated
  //         ? authenticatedUser?.IsAllGroupOrLabTechAdmin(
  //             [asset.ServiceGroupId ?? 0],
  //             [
  //               {
  //                 Id: asset.ServiceId,
  //                 Name: asset.Name,
  //                 ServiceTypeId: asset.ServiceTypeId,
  //               } as IServiceTypeFilterDto,
  //             ],
  //           )
  //         : false;
  //     },
  //     [isAuthenticated, authenticatedUser],
  //   );
  const isRowSelectable = React.useCallback(
    (row: Row<IOfflineServiceTypeRefsDto>): boolean => {
      return (
        authenticatedUser?.HasAdminGroupOnlyPermissions(
          row.original.ServiceGroupId,
        ) || false
      );
    },
    [authenticatedUser],
  );
  const setfilterCallback = React.useCallback(
    (v: IFilterSettings<IOfflineServiceTypeRefsDto>[] | undefined) => {
      const res = {};
      if (!!v) {
        toURLSearchParams(v).forEach((val, name) => {
          res[name] = val;
        });
      }
      dispatch(actions.setFilters(!v || v.length === 0 ? undefined : res));
    },
    [actions, dispatch],
  );
  const screenName = React.useMemo(() => {
    if (refTypeId === undefined || refId === undefined) {
      return t(translations.menu_OfflineTypeRefernces);
    }
    return `${t(translations.menu_OfflineTypeRefernces)} to ${
      getSingleEnumEntityById(refTypeId, OfflineReferenceTypeEnum, t)?.Name
    }`;
  }, [refId, refTypeId, t]);
  const requiredFilter = (search: string) => {
    const result = new URLSearchParams(search);
    if (refTypeId !== undefined) {
      if (
        result.has('refTypeId') === false ||
        result.get('refTypeId') === null ||
        result.get('refTypeId') === ''
      ) {
        result.set('refTypeId', String(refTypeId));
      }
    }
    if (!!refId) {
      if (
        result.has('refId') === false ||
        result.get('refId') === null ||
        result.get('refId') === ''
      ) {
        result.set('refId', String(refId));
      }
    }
    const res = result.toString();
    return isEmptyOrWhitespace(res) ? undefined : res;
  };

  const pageActions: ActionRenderer[] = React.useMemo(() => {
    let actions: ActionRenderer[] = [];

    if (
      !!authenticatedUser &&
      (authenticatedUser.Roles.includes(Roles.Administrators) ||
        authenticatedUser.Roles.includes(Roles.GroupAdministrators))
    ) {
      actions.push(() => (
        <AddOfflineRefs
          useSidePanel={useSidePanel}
          openPanelWithCover={openPanel}
          onCllose={onCloseCover}
          disabled={
            !(!!filters && filters['refId'] && !!Number(filters['refId'])
              ? Number(filters['refId'])
              : refId) ||
            !(!!filters &&
            filters['refTypeId'] &&
            !!Number(filters['refTypeId'])
              ? Number(filters['refTypeId'])
              : refTypeId)
          }
          variant="main"
          refId={
            !!filters && filters['refId'] && !!Number(filters['refId'])
              ? Number(filters['refId'])
              : refId
          }
          refTypeId={
            !!filters && filters['refTypeId'] && !!Number(filters['refTypeId'])
              ? Number(filters['refTypeId'])
              : refTypeId
          }
        />
      ));
    }
    return actions;
  }, [
    authenticatedUser,
    filters,
    onCloseCover,
    openPanel,
    refId,
    refTypeId,
    useSidePanel,
  ]);
  const selectedRowActions: SelectedRowsActionRenderer<IOfflineServiceTypeRefsDto>[] =
    React.useMemo(
      () => [
        (rows, onRefresh) =>
          rows.every(c =>
            allowedToEditGroup({
              Id: c.ServiceGroupId,
            }),
          ) && (
            <SelectedRowsActionIconButton
              key="delete"
              text={t(translations.Delete)}
              title={t(translations.confirm_DeleteOfflineService)}
              confirm={true}
              validConfirm={true}
              confirmTitle={
                t(translations.confirm_DeleteOfflineService) as string
              }
              rows={rows}
              onRefresh={onRefresh}
              variantButton="button"
              action={() =>
                new Promise<IResponseType>((resolve, reject) => {
                  return httpClient
                    .post('/api/odata/v4/OfflineServiceTypeRefs/DeleteRefs', {
                      servicesIds: rows.map(c => c.Id),
                    })
                    .then(response => {
                      //GetResponseMessages is from UsersPage
                      const messages: IResponseType = GetResponseMessages(
                        response,
                        t(translations.Success) as string,
                      );
                      resolve(messages);
                    })
                    .catch(error => {
                      console.log('status error', error);
                      let err = t(translations.Failed) as string;
                      reject(err);
                    });
                })
              }
            />
          ),
      ],
      [allowedToEditGroup, t],
    );
  const TTable = React.useMemo(
    () =>
      filterHandler<IOfflineServiceTypeRefsDto, {}>(
        OfflineTypeReferencesTable,
        GetFilters,
        {
          isSidePanel: useSidePanel,
          setFilters: setfilterCallback,
          reservedQueryStringParameterNames: ['refTypeId', 'refId'],
          getDefaultFilter: undefined,
          getRequiredFilter: requiredFilter,
          getSelectedViewFilter: undefined,
          enableSavedLists: false,
          anyDefinition: false,
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useSidePanel],
  );
  const columns: Column<IOfflineServiceTypeRefsDto>[] = React.useMemo(
    () =>
      getColumns(
        t,
        item => allowedToEditGroup({ Id: item.ServiceGroupId }),
        authenticatedUser,
        useSidePanel,
        openPanel,
        onCloseCover,
      ),
    [
      t,
      authenticatedUser,
      useSidePanel,
      openPanel,
      onCloseCover,
      allowedToEditGroup,
    ],
  );
  return (
    <PageWrapper
      pageLink={pagelink}
      useSidePanel={useSidePanel || false}
      pageName={undefined}
      titlePage={t(translations.menu_OfflineTypeRefernces)}
      closable={useSidePanel || props.isCover}
      isCover={props.isCover}
      coverClosed={coverClosed}
      hideTitleBar={false}
      cover={cover}
      closeCover={!props.isCover ? onCloseCover : closeCover}
      closeSidePanel={closeSidePanel}
      leftTopActions={pageActions}
      children={
        <React.Fragment>
          <TTable
            screenId={ScreensId.ServiceRelatedRefs}
            api={''}
            columns={columns}
            screenNameVariant="section"
            screenName={screenName}
            isRowSelectable={isRowSelectable}
            selectedRowsActions={selectedRowActions}
            //getRequiredFilter={requiredFilter}
            //allowMultiToggle={allowMultiToggle}
            //screenName={screenName}
            //assets={selectedAssets}
            //primaryMultiAction={primaryMultiAction}
            //multiModeDisabled={multiModeDisabled}
            //openAsset={openAsset}
            //useRecentAssets={useRecentAssets}
            //customPredicates={customPredicates}
            //isShortView={showShortView}
            // emptyTable={noTableData}
            //searchTerm={searchTerm}
            //serviceGroupId={serviceGroupId}
            serviceGroups={[]}
          />
        </React.Fragment>
      }
    />
  );
}
