/**
 *
 * Asynchronously loads the component for PlannedWorkOrdersPage
 *
 */

import { lazyLoad } from 'utils/loadable';
import { withoutGlobalServiceGroupFilter } from 'app/components/BasicTable/withoutGlobalServiceGroupFilter';

export const PlannedWorkOrdersPage = lazyLoad(
  () => import('./index'),
  module => withoutGlobalServiceGroupFilter(module.PlannedWorkOrdersPage),
);
