import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import { BasicTable, BasicTableProps } from 'app/components/BasicTable';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function WorkOrdersTable<
  TProps extends Omit<
    BasicTableProps<IWorkOrderDetailsDto>,
    | 'api'
    | 'additionalColumns'
    | 'searchColumns'
    | 'screenName'
    | 'expandedColumns'
    | 'savedListSettings'
  >,
>({ initialState, rowActions, serviceGroups, ...props }: TProps) {
  const { t } = useTranslation();
  const newProps = React.useMemo(() => {
    const p = { ...props };
    Reflect.deleteProperty(p, 'api');
    Reflect.deleteProperty(p, 'expandedColumns');
    Reflect.deleteProperty(p, 'savedListSettings');
    return p;
  }, [props]);
  const url = '/api/odata/v4/WorkOrders';
  return (
    <>
      <BasicTable
        screenName={t(translations.menu_AllWorkOrders)}
        api={url}
        initialState={initialState}
        rowActions={rowActions}
        expandedColumns={['AssignedUsers']}
        additionalColumns={[
          'Id',
          'AssetId',
          'AssetCatId',
          'AssetCatGroupId',
          'AlertTypeId',
          'OfflineEventEnd',
          //'StatusId',
          'IsClosed',
          //'UserTicket',
          'InstrumentWasDown',
          'ReportedByDisplayName',
          'RecurringGroupId',
          'RecurringAssetId',
          'StaffOnly',
          'EventStart',
          'EventEnd',
          'ServiceGroupId',
          'ServiceId',
          'ShowOnCalibrationHistory',
          'NotEditable',
          'ReasonTypeId',
        ]}
        searchColumns={[
          'cast(Id,Edm.String)',
          'AssetName',
          'ServiceGroup',
          'AlertDescription',
          'AlertTitle',
          'StatusName',
          'AlertType',
          'ReportedBy',
          'ReasonTypeName',
        ]}
        serviceGroups={serviceGroups}
        savedListSettings={{
          enableSavedLists: true,
          savedListRelatedPickerName: 'AssetId',
          savedListSerializedKey: 'aid',
          listViewbyAsset: true,
        }}
        {...(newProps as TProps)}
      />
    </>
  );
}
