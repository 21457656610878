import { IUsers } from 'app/pages/UsersPage/IUsers';
import { IUserRow } from './IUserRow';

export const DefaultUserRows: Array<string> = [
  'Name',
  'DisplayName',
  'Overview',
  'ContactDetails',
  'CompanyDetails',
  'AdditionalDetails',
  'Comments',
  'EditableDetails',
  'DefaultBudget',
  'DefaultUserGroup',
  'UserInternalId',
  'UserInternalId2',
];
export const DefaultUserAttributes: Array<string> = [
  'UserGroupId',
  'DefaultBudgetId',
  'DefaultUserGroupId',
  'AllowedUserGroups',
  'IsAffiliated',
  'AD',
];
export const ExpandableUserRows: Array<keyof IUsers> = [
  'Role_s',
  'CustomRoles',
  'Role_sf',
  'AllowedUserGroups',
];
export interface UserSection {
  Id: string;
  row: IUserRow;
  rows: IUserRow[];
  Index: number;
}
export interface UserSections {
  leftSections: UserSection[];
  rightSections: UserSection[];
}
export interface UserSectionsProps {
  sections: UserSection[];
  data?: IUsers;
}
export interface AttributeValue<T extends object> {
  original: T;
  value: any;
  shortView?: boolean;
}
export enum UserRowState {
  Mandatory,
  SectionName,
  Hidden,
}
export interface TabsCounter {
  Enabled: boolean;
  Count: number;
}
export interface UserTabsCounters {
  Reservations: TabsCounter;
  WorkOrders: TabsCounter;
  UserTrainings: TabsCounter;
  SearchHistory: TabsCounter;
}
export type UserScreenType =
  | 'RegistrationForm'
  | 'UserDetails'
  | 'UserSidePanel'
  | 'UserProfile';

export const StaticSections: Array<string> = [
  'Image',
  'Overview',
  'ContactDetails',
  'CompanyDetails',
  'AdditionalDetails',
  'Comments',
  'EditableDetails',
];
export type UserSectionType =
  | 'Image'
  | 'Overview'
  | 'ContactDetails'
  | 'CompanyDetails'
  | 'AdditionalDetails'
  | 'Comments'
  | 'EditableDetails';
export const isValidRow = (row: IUserRow, data: IUsers) => {
  if (
    row.State === UserRowState.SectionName ||
    row.State === UserRowState.Mandatory
  ) {
    return true;
  }
  const value = data![row.PropName ?? row.Id];
  const valid =
    !row.validToRender ||
    (row.validToRender !== undefined &&
      row.validToRender({
        value: data![row.PropName ?? row.Id],
        original: data,
      }));
  if (value !== null && value !== undefined && value !== '' && valid) {
    if (Array.isArray(value)) {
      return value.length > 0;
    } else {
      return true;
    }
  } else {
    return false;
  }
};
function GetUserRawSections(items: IUserRow[], data: IUsers): UserSection[] {
  const sections: UserSection[] = items
    .filter(f => f.State === UserRowState.SectionName)
    .map((r, index) => {
      let section: UserSection = {
        Id: r.Id,
        row: r,
        rows: items
          .filter(
            f =>
              f.section === r.section &&
              f.State !== UserRowState.SectionName &&
              (f.validToRender === undefined ||
                f.validToRender({
                  value: data![f.PropName ?? f.Id],
                  original: data,
                })),
          )
          .sort((a, b) => (a.rowOrder ?? 0) - (b.rowOrder ?? 0)),
        Index: r.sectionOrder ?? 0,
      };
      return section;
    })
    .sort((a, b) => a.Index - b.Index);
  return sections;
}

export function GetUserRowsSections(
  rows: IUserRow[],
  data: IUsers,
  isMobile?: boolean,
): UserSections {
  let leftRes: IUserRow[] = [];
  let rightRes: IUserRow[] = [];
  const checkSection = (row: IUserRow) => {
    return isMobile
      ? ['EditableDetails', 'Comments'].includes(row.Id) === false
      : ['Image', 'EditableDetails', 'Comments'].includes(row.Id) === false;
  };
  for (const row of rows) {
    if (isValidRow(row, data)) {
      if (checkSection(row) === true && row.section !== 'EditableDetails') {
        leftRes.push(row);
      } else {
        rightRes.push(row);
      }
    }
  }
  //leftRes = RemoveLastEmptySections(leftRes);
  const leftsections = GetUserRawSections(leftRes, data).filter(
    f => f.rows.length > 0,
  );
  const rightSections = GetUserRawSections(rightRes, data).filter(
    f => f.rows.length > 0,
  );
  if (!isMobile) {
    if (rightRes.length > 0) {
      if (rightSections.length > 0) {
        moveArrayItem(rightRes, 'Image', 0);
        moveArrayItem(rightRes, 'EditableDetails', 1);
        moveArrayItem(rightRes, 'Comments', 2);
      } else {
        moveArrayItem(rightRes, 'Image', 0);
        moveArrayItem(rightRes, 'Comments', 1);
      }
    }
  } else {
    if (rightRes.length > 0) {
      moveArrayItem(rightRes, 'EditableDetails', 0);
      moveArrayItem(rightRes, 'Comments', 1);
    }
  }

  // leftRes = leftRes.filter(getSectionsFilter(data));
  // rightRes = rightRes.filter(getSectionsFilter(data));
  const resSections: UserSections = {} as UserSections;
  if (isMobile) {
    const imageSection = RowToSections(leftRes, 'Image');
    resSections.leftSections = leftsections; //GetSections(leftRes);
    if (imageSection !== null) {
      resSections.leftSections.unshift(imageSection);
    }
  } else {
    resSections.leftSections = leftsections;
  }
  resSections.rightSections = RowsToSections(
    rightRes.filter(
      f =>
        f.section !== 'EditableDetails' ||
        (f.section === 'EditableDetails' && f.Id === 'EditableDetails') ===
          true,
    ),
    rightSections,
  );
  return resSections;
}
function removeArrayItem(target, id: string) {
  const ri = target.findIndex(v => v.Id === id);
  if (ri >= 0) {
    const rows = target.splice(ri, 1);
    return rows[0];
  } else return null;
}
function moveArrayItem(target, id: string, pos: number) {
  const ri = target.findIndex(v => v.Id === id);
  if (pos < target.length && ri >= 0 && pos !== ri) {
    const row = target.splice(ri, 1);
    target.splice(pos, 0, row[0]);
  }
}
// function moveArrayItemToEnd(target, id: string) {
//   const ri = target.findIndex(v => v.Id === id);
//   if (ri >= 0) {
//     const row = target.splice(ri, 1);
//     target.splice(target.length, 0, row[0]);
//   }
// }
// function moveArrayItemByName(target, id: string, name: string) {
//   if (id !== name) {
//     const ri = target.findIndex(v => v.Id === id);
//     if (ri >= 0) {
//       const rn = target.findIndex(v => v.Id === name);
//       if (rn >= 0) {
//         const row = target.splice(ri, 1);
//         if (rn < ri) {
//           target.splice(rn + 1, 0, row[0]);
//         } else {
//           target.splice(rn, 0, row[0]);
//         }
//       }
//     }
//   }
// }
function RowsToSections(
  rows: IUserRow[],
  initialSections: UserSection[],
): UserSection[] {
  const sections: UserSection[] = [];
  for (let r of rows) {
    if (r.State !== UserRowState.SectionName) {
      sections.push({
        Id: r.Id,
        Index: -1,
        row: r,
        rows: [r],
      } as UserSection);
    } else {
      const section = initialSections.find(f => f.Id === r.Id);
      if (section !== undefined) {
        sections.push(section);
      }
    }
  }
  return sections;
}
function RowToSections(rows: IUserRow[], id: string): UserSection | null {
  const row = removeArrayItem(rows, id);
  if (row !== null) {
    const section = {
      Id: row.Id,
      Index: -1,
      row: row,
      rows: [row],
    } as UserSection;
    return section;
  } else return null;
}
