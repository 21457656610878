/**
 *
 * ContentBox
 *
 */
import * as React from 'react';
import { Theme, useTheme, BoxProps } from '@material-ui/core';
import styled from 'styled-components';

/**
 * Provides standard spacing for direct children of FrontendLayout
 */
export function FrontendLayoutContentBox({ className, ...props }: BoxProps) {
  const theme = useTheme();
  return <StyledDiv theme={theme}>{props.children}</StyledDiv>;
}

const StyledDiv = styled.div.attrs(props => ({ props }))<{
  theme: Theme;
}>`
  margin: 16px 16px 0;
  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    margin: 32px 32px 0;
  }
`;
