/**
 *
 * BasicDialog
 *
 */
import { Dialog, DialogProps } from '@material-ui/core';
import * as React from 'react';
import { BasicDialogActions } from './BasicDialogActions';
import { BasicDialogContentWithTitle } from './BasicDialogContentWithTitle';
import { DialogActionsRenderer } from './DialogActionsRender';
import clsx from 'clsx';
import { DetectIsMobile } from 'utils/mobileDetect';

export interface BasicDialogProps extends Omit<DialogProps, 'open' | 'title'> {
  id: string;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  body: React.ReactNode;
  processing?: boolean;
  isopen: boolean;
  leftActions: DialogActionsRenderer[];
  rightActions: DialogActionsRenderer[];
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  disabled?: boolean;
  rtl?: boolean;
  dialogRootClassName?: string;
  paperClassName?: string;
}

export function BasicDialog({
  id,
  title,
  body,
  isopen,
  leftActions,
  rightActions,
  maxWidth,
  subtitle,
  processing,
  disabled,
  rtl,
  dialogRootClassName,
  paperClassName,
  ...props
}: BasicDialogProps) {
  const [open, setOpen] = React.useState(isopen);
  const isMobile = DetectIsMobile();
  React.useEffect(() => {
    setOpen(isopen);
  }, [isopen]);

  return (
    <Dialog
      maxWidth={maxWidth || 'xs'}
      open={open}
      className={clsx('basic-dialog', props.className, {
        'basic-dialog-mobile': isMobile,
        rtl: rtl,
      })}
      aria-labelledby={id}
      {...props}
    >
      <BasicDialogContentWithTitle
        subtitle={subtitle}
        onClose={props.onClose}
        titles={title}
        processing={processing}
        disabled={disabled}
      >
        {body}
      </BasicDialogContentWithTitle>
      <BasicDialogActions
        leftActions={leftActions.map(element => element)}
        rightActions={rightActions.map(element => element)}
      />
    </Dialog>
  );
}
