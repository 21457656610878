import { UserGroupMultiPicker } from 'app/components/pickers/AutocompletePickers/UserGroupMultiPicker';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export const UserGroupMultiFilter = (props: IFilterComponentProps) => {
  const handleChange = (value: Entity<string>[]) => {
    props.onChange(value);
  };
  return (
    <UserGroupMultiPicker
      onChange={handleChange}
      size="small"
      fullWidth={props.fullWidth}
      value={props.value}
    />
  );
};
