import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { BookitChip } from 'app/components/BasicChip';
import {
  BatchesByServiceTypeFilter,
  convertInventoryBatchName,
  loadInventoryBatchesData,
} from 'app/components/pickers/AutocompletePickers/InventoryBatchesPicker';
import { useAsyncGetWithEffect } from 'app/hooks/useAsyncGetOnMount';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { InventoryBatchList } from './InventoryBatchList';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCreatableService,
  selectInventoryBatchEditable,
} from '../slice/selectors';
import { useOfflineServiceStateSlice } from '../slice';
import { bookitColors } from 'styles/colors/bookitColors';

export interface InventoryBatchChipProps {
  renderService: IOtherServices;
  onServiceClick?: (service: IOtherServices) => void;
  onAddBatchClick?: (
    batch: IInventoryBatchDto | null,
    serviceTypeId: number,
  ) => void;
  renewStock?: boolean;
  isAdmin?: boolean;
  handleServiceChange: (item: IOtherServices) => Promise<any>;
  disabled?: boolean;
  onRenewStockClick?: (service: IOtherServices) => void;
}
export const InventoryBatchChip = React.memo(function InventoryBatchChip(
  props: InventoryBatchChipProps,
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useOfflineServiceStateSlice();
  const {
    renderService,
    onServiceClick,
    onAddBatchClick,
    renewStock,
    isAdmin,
    handleServiceChange,
    disabled,
    onRenewStockClick,
  } = props;
  const batchRequested = useSelector(selectInventoryBatchEditable);
  const savedCreatableRenewal = useSelector(selectCreatableService);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleBatchListOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleBatchListClose = () => {
    setAnchorEl(null);
  };
  const availableBatches = useAsyncGetWithEffect<IInventoryBatchDto[]>(
    async () =>
      loadInventoryBatchesData(
        BatchesByServiceTypeFilter(
          renderService.ServiceTypeID,
          renewStock === true ? undefined : true,
        ),
      )(null, undefined).then(res => res.value),
    [],
    [renderService, batchRequested, savedCreatableRenewal],
  );
  const currentBatch = React.useMemo(() => {
    return renderService.InventoryBatchesEnabled
      ? renderService.InventoryBatchId !== null
        ? availableBatches.find(f => f.Id === renderService.InventoryBatchId)
        : null
      : null;
  }, [
    availableBatches,
    renderService.InventoryBatchId,
    renderService.InventoryBatchesEnabled,
  ]);
  const onChipClick = event => {
    if (renewStock && !onAddBatchClick) {
      !!onServiceClick && onServiceClick(renderService);
    } else {
      if (isAdmin && !disabled) {
        handleBatchListOpen(event);
      }
    }
  };
  const handleChipSelect = React.useCallback(
    (batch: IInventoryBatchDto) => {
      return new Promise(async resolve => {
        try {
          handleBatchListClose();
          await handleServiceChange(
            Object.assign({}, renderService, {
              InventoryBatchId: batch.Id,
              InventoryBatchName: batch.Name,
              InventoryBatchAmount: batch.Inventory,
            }),
          );
        } catch (error) {}
      });
    },
    [handleServiceChange, renderService],
  );
  React.useEffect(() => {
    if (batchRequested !== undefined) {
      handleChipSelect(batchRequested);
      dispatch(actions.setInventoryBatchEditable(undefined));
    }
  }, [actions, batchRequested, dispatch, handleChipSelect]);
  React.useEffect(() => {
    if (savedCreatableRenewal !== undefined) {
      handleChipSelect({
        Id: savedCreatableRenewal.InventoryBatchId,
        Name: savedCreatableRenewal.InventoryBatchName,
        Inventory: savedCreatableRenewal.InventoryBatchAmount,
      } as IInventoryBatchDto);
      dispatch(actions.getCreatableService(undefined));
    }
  }, [actions, dispatch, handleChipSelect, savedCreatableRenewal]);
  return (
    <React.Fragment>
      <BookitChip
        shape="circle"
        customsize="small"
        notremoved={true}
        disabled={!isAdmin || disabled}
        custombgcolor={bookitColors.primary.bgLight}
        customcolor={bookitColors.primary.dark}
        label={
          currentBatch !== null && currentBatch !== undefined
            ? convertInventoryBatchName(
                currentBatch.Id,
                currentBatch.Name,
                currentBatch.Inventory,
              )
            : renderService.AutoGenerateNewBatches === true
            ? t(translations.InventoryBatchEmptyGenerateOn_info)
            : isAdmin
            ? t(translations.InventoryBatchEmptyGenerateOff_info)
            : t(translations.InventoryBatch_notFound)
        }
        onClick={onChipClick}
      />
      {(!renewStock || (renewStock && !!onAddBatchClick)) && (
        <InventoryBatchList
          anchorEl={anchorEl}
          addNewBatchClick={() => {
            !!onAddBatchClick &&
              onAddBatchClick(null, renderService.ServiceTypeID);
            handleBatchListClose();
          }}
          isAdmin={isAdmin ?? false}
          onClose={handleBatchListClose}
          serviceTypeId={renderService.ServiceTypeID}
          onBatchClick={handleChipSelect}
          selectedBatch={currentBatch ?? undefined}
          showRenewStockButtons={!renewStock}
          createdRenewal={savedCreatableRenewal}
          onRenewStockClick={batchId => {
            !!onRenewStockClick &&
              onRenewStockClick(
                Object.assign({}, renderService, {
                  InventoryBatchId: batchId ?? null,
                }),
              );
            handleBatchListClose();
          }}
          batches={[]}
        />
      )}
    </React.Fragment>
  );
});
