import { PayloadAction } from '@reduxjs/toolkit';
import { ILocationListDto } from 'api/odata/generated/entities/ILocationListDto';
import { IRoomDto } from 'api/odata/generated/entities/IRoomDto';
import { ISubLocationListDto } from 'api/odata/generated/entities/ISubLocationListDto';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { assetInventoryStateSaga } from './saga';
import { AssetInventoryState } from './types';

export const initialState: AssetInventoryState = {
  selectedRoomState: null,
  selectedLocationState: null,
  selectedSubLocationState: null,
  scanProcessing: undefined,
  noRoomAccessProcessing: undefined,
  logFilters: undefined,
};

const slice = createSlice({
  name: 'assetInventory',
  initialState,
  reducers: {
    setSelectedRoom(state, action: PayloadAction<IRoomDto | null>) {},
    setSelectedRoom_Success(state, action: PayloadAction<IRoomDto | null>) {
      state.selectedRoomState = action.payload;
    },
    setSelectedLocation(
      state,
      action: PayloadAction<ILocationListDto | null>,
    ) {},
    setSelectedLocation_Success(
      state,
      action: PayloadAction<ILocationListDto | null>,
    ) {
      state.selectedLocationState = action.payload;
    },
    setSelectedSubLocation(
      state,
      action: PayloadAction<ISubLocationListDto | null>,
    ) {},
    setSelectedSubLocation_Success(
      state,
      action: PayloadAction<ISubLocationListDto | null>,
    ) {
      state.selectedSubLocationState = action.payload;
    },
    scanManualBarcode(state, action: PayloadAction<{ data: string }>) {},
    scanManualBarcode_Success(state, action: PayloadAction) {
      state.scanProcessing = false;
    },
    scanManualBarcode_error(state, action: PayloadAction) {
      state.scanProcessing = false;
    },
    setNoRoomAccess(state, action: PayloadAction) {
      state.noRoomAccessProcessing = true;
    },
    setNoRoomAccess_success(state, action: PayloadAction) {
      state.noRoomAccessProcessing = false;
    },
    setNoRoomAccess_error(state, action: PayloadAction) {
      state.noRoomAccessProcessing = false;
    },
    setNoRoomAccessProcessing(
      state,
      action: PayloadAction<boolean | undefined>,
    ) {
      state.noRoomAccessProcessing = action.payload;
    },
    setScanProcessing(state, action: PayloadAction<boolean | undefined>) {
      state.scanProcessing = action.payload;
    },
    setLogFilters(
      state,
      action: PayloadAction<Record<string, any> | undefined>,
    ) {
      state.logFilters = action.payload;
    },
  },
});

export const { actions: assetInventoryActions } = slice;

export const useAssetInventorySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: assetInventoryStateSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAssetInventorySlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
