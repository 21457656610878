import { selectglobalSettings } from 'app/slice/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { AllowedSettings } from 'utils/globalSettings';
import { toRootedURL } from 'utils/url-utils';
import {
  getAssetImageSrc,
  getAssetImageThumbSrc,
  getDefaultImage,
} from '../AssetThumbImage/getAssetImageSrc';
import { ImageRender } from './ImageRender';
import { ImageCarousel } from './styled';
import { ImageDataThumb } from './typeUtils';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { selectLoadingAdditionalData } from 'app/pages/MyAssetsPage/AssetsSelection/slice/selectors';
import { LinearProgress } from '@material-ui/core';
import { Div100 } from '../AssetQuickSearch/styled';

export interface AssetImagesGalleryProps {
  imageNames?: string[];
  baseImageName: string | null;
  onImageClick?: (path?: string | undefined) => void;
  shortView?: boolean;
  deleteImage?: (index: number) => void;
  onChange?: (index: number) => void;
}

export function AssetImagesGallery(props: AssetImagesGalleryProps) {
  const {
    imageNames,
    baseImageName,
    onImageClick,
    shortView,
    deleteImage,
    onChange,
  } = props;
  const { t } = useTranslation();
  const globalSettings = useSelector(selectglobalSettings);
  const loadingAssetData = useSelector(selectLoadingAdditionalData);
  const allImages: ImageDataThumb[] = React.useMemo(() => {
    let vals =
      imageNames === undefined
        ? [baseImageName]
        : [baseImageName].concat(imageNames);
    return vals.map((v, ind) => {
      const imagePath = getAssetImageSrc(
        v,
        undefined,
        (globalSettings.GetNullableByKey(AllowedSettings.AssetImageSizeType) ??
          '2') === '1' && !shortView
          ? '/images/400x300.png'
          : '/images/200x150.png',
      );
      const thumbPath = getAssetImageThumbSrc(
        v,
        'thumbLarge',
        undefined,
        getDefaultImage('thumbLarge'),
      );
      const largePath = getAssetImageThumbSrc(
        v,
        'Large',
        undefined,
        getDefaultImage('Large'),
      );
      return {
        Id: ind,
        Name:
          imagePath !== undefined
            ? imagePath
            : toRootedURL(
                !shortView ? '/images/400x300.png' : '/images/200x150.png',
              ),
        thumb: thumbPath ?? toRootedURL(getDefaultImage('thumbLarge')),
        large: largePath ?? toRootedURL(getDefaultImage('Large')),
      };
    });
  }, [baseImageName, globalSettings, imageNames, shortView]);
  const [selectedImg, setSelectedImg] = React.useState<ImageDataThumb>(
    allImages[0],
  );
  const customRenderThumb = React.useCallback(
    (children: React.ReactChild[]) =>
      children.map((item, index) => {
        return (
          <ImageRender
            imgPath={allImages[index].thumb}
            size={'thumbLarge'}
            key={uniqueId('thumb_')}
            onClose={!!deleteImage ? deleteImage : undefined}
            deletIndex={index}
          />
        );
      }),
    [allImages, deleteImage],
  );
  return (
    <ImageCarousel
      className={clsx('img-carousel-root', {
        'img-short-carusel-root': shortView,
      })}
    >
      <Carousel
        autoPlay={false}
        autoFocus={true}
        showIndicators={false}
        thumbWidth={80}
        showStatus={false}
        showThumbs={allImages.length > 1}
        swipeable={shortView}
        selectedItem={selectedImg?.Id}
        renderThumbs={customRenderThumb}
        onChange={index => {
          setSelectedImg(allImages[index]);
          if (!!onChange) {
            onChange(index);
          }
        }}
        onClickItem={() => !!onImageClick && onImageClick(selectedImg?.large)}
      >
        {allImages.map((img, indx) => (
          <ImageRender
            imgPath={img.Name}
            size={shortView ? 'full' : 'fullLarge'}
            key={uniqueId('img')}
            tooltip={t(translations.ClickToSeeInFullScreen)}
          />
        ))}
      </Carousel>
      {loadingAssetData && (
        <Div100>
          <LinearProgress />
        </Div100>
      )}
    </ImageCarousel>
  );
}
