import { getLogger } from 'utils/logLevel';
import { BARCODE_SCANNED, BARCODE_SCANNING } from 'react-usb-barcode-scanner';

export const barcodeScannerMiddleware = store => next => action => {
  // processing barcode need to be here
  if ([BARCODE_SCANNING, BARCODE_SCANNED].includes(action.type)) {
    log.info(action);
  }
  return next(action);
};

const log = getLogger('barcodeScannerMiddleware');
