import { httpClient } from 'api/HttpClient';
import { Condition } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import * as React from 'react';
import { Entity } from 'types/common';

export interface TimeSlotTypePickerProps
  extends Omit<AutocompletePickerProps<Entity<number>>, 'loadData'> {}

const url = '/api/odata/v4/TimeSlotTypeFilter';
export const initTimeSlotTypeData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (
  predicates: (string | Condition<Entity<number>>)[] | undefined,
) =>
  getAutoCompleteLoadDataFn<Entity<number>>({
    url: url,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select: ['Id', 'Name'],
  });
export const loadTimeSlotTypes = loadData;
export function TimeSlotTypePicker(props: TimeSlotTypePickerProps) {
  const innerLoadData = loadData(props.predicates);
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'TimeSlotTypePickerId'}
      loadData={innerLoadData}
      {...props}
    />
  );
}
