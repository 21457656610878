import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import {
  AutocompleteMultiPicker,
  AutocompleteMultiPickerProps,
} from 'app/components/BasicPickers/AutocompleteMultiPicker';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';
import { InternalServiceRequestStatusesUnion } from 'enums/InternalServiceRequestStatuses';
import { orderBy } from 'lodash';

const url = '/api/odata/v4/ServiceRequestStatusFilter';

export interface ServiceRequestStatusMultiPickerProps
  extends Omit<
    AutocompleteMultiPickerProps<number, IServiceRequestStatusDto>,
    'loadData'
  > {
  exclude?: InternalServiceRequestStatusesUnion[];
}

export const initServiceRequestStatusMultiData = (
  initialValue: string | undefined,
) => {
  if (initialValue === undefined) {
    return new Promise<IServiceRequestStatusDto[]>((resolve, reject) => {
      resolve([] as IServiceRequestStatusDto[]);
    });
  } else {
    const ids = initialValue.split('|').map(v => parseInt(v));
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id in (' + ids.join(',') + '))',
      $select: 'Id,Name,InternalStatusId',
      $skip: 0,
      $top: ids.length,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as IServiceRequestStatusDto[]);
  }
};

export const initServiceRequestStatusMultiDataBySelectOrder = (
  initialValue: string | undefined,
) => {
  const result = initServiceRequestStatusMultiData(initialValue);
  const ids = initialValue?.split('|').map(v => parseInt(v));
  if (ids === undefined) return result;
  return result.then(results =>
    orderBy(results, r => ids.findIndex(v => v === r.Id)),
  );
};

const getLoadDataFn =
  (exclude?: InternalServiceRequestStatusesUnion[]) =>
  (searchTerm: string | null) => {
    const params: { $orderby: string; $filter?: string } = {
      $orderby: 'Name asc',
    };
    const predicates: Array<Condition<IServiceRequestStatusDto>> = [];
    if (exclude !== undefined && exclude.length > 0) {
      predicates.push(
        new Condition<IServiceRequestStatusDto>(
          'InternalStatusId',
          ODataOperators.Excludes,
          exclude,
        ),
      );
    }
    params.$filter = new ODataFilterBuilder<IServiceRequestStatusDto>({
      predicates: predicates,
      stringColumns: ['Name'],
      stringSearch: searchTerm,
    }).toString();

    return httpClient
      .get(url, params)
      .then(response => response.value as IServiceRequestStatusDto[]);
  };

export function ServiceRequestStatusMultiPicker(
  props: ServiceRequestStatusMultiPickerProps,
) {
  const loadData = React.useMemo(
    () => getLoadDataFn(props.exclude),
    [props.exclude],
  );
  return (
    <AutocompleteMultiPicker
      mini={!!props.mini}
      size={props.size}
      id={props.id || 'serviceRequestStatusId'}
      ariaLabel="Service Request Status"
      loadData={loadData}
      {...props}
    />
  );
}
