import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { UsersApi } from 'api/UsersApi';
import { Button } from 'app/components/BasicButtons/Button';
import { FormRow } from 'app/components/Forms/FormsLayout';
import { BudgetPicker } from 'app/components/pickers/AutocompletePickers/BudgetPicker';
import { IUsers } from 'app/pages/UsersPage/IUsers';
import { useAppSettingsSlice } from 'app/slice';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Entity } from 'types/common';
import { IResponseType } from 'types/ResponseType';
import { AttributeValue } from '../../typeUtils';

export function DefaultBudget(props: AttributeValue<IUsers>) {
  const { original } = props;
  const { t } = useTranslation();
  const { actions } = useAppSettingsSlice();
  const dispatch = useDispatch();
  const [updated, setUpdated] = React.useState<boolean>(false);
  const [selectedBudget, setSelectedBudget] =
    React.useState<Entity<number> | null>(
      original.DefaultBudgetId !== undefined &&
        original.DefaultBudgetId !== null
        ? { Id: original.DefaultBudgetId, Name: original.DefaultBudget }
        : null,
    );

  const onChange = React.useCallback((option: Entity<number> | null) => {
    setSelectedBudget(option);
  }, []);
  const handleUpdate = React.useCallback(async () => {
    setUpdated(true);
    try {
      const result: IResponseType = await UsersApi.updateDefaultBudget(
        original.Name,
        selectedBudget?.Id ?? undefined,
      );
      if (result.ErrorMessages.length > 0) {
        dispatch(
          actions.addNotification({
            variant: 'error',
            message: result.ErrorMessages[0],
          }),
        );
        setUpdated(false);
      } else {
        dispatch(
          actions.addNotification({
            variant: 'success',
            message: result.SuccessMessages[0],
          }),
        );
        setUpdated(false);
      }
    } catch (e) {
      dispatch(
        actions.addNotification({
          variant: 'error',
          message: 'Default User Group update failed',
        }),
      );
      setUpdated(false);
    }
  }, [actions, dispatch, original.Name, selectedBudget?.Id]);

  return (
    <FormRow>
      <BudgetPicker
        value={selectedBudget as IBudgetFilterDto}
        onChange={onChange}
        fullWidth
        variant="filled"
        label={t(translations.DefaultBudget)}
        disabled={updated}
      />
      <Button
        variant="main"
        size="large"
        disabled={
          selectedBudget === null ||
          selectedBudget.Id === original.DefaultBudgetId ||
          updated
        }
        processing={updated}
        onClick={handleUpdate}
      >
        {t(translations.Update)}
      </Button>
    </FormRow>
  );
}
