import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export const CommentsWrapper = styled.div`
  &.comments-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    & .comments-title {
      gap: 16px;
      display: flex;
      padding-bottom: 0px;
      align-items: center;
      flex-direction: row;
      align-self: stretch;
    }
    & .comment-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      & .add-comment-box {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-self: stretch;
      }
    }
    & .comment-root {
      gap: 16px;
      display: flex;
      padding: 16px;
      align-items: flex-start;
      flex-direction: row;
      border: 1px dashed #9aa3b2;
      border-radius: 8px;
      & .comment-content {
        gap: 8px;
        display: flex;
        padding: 0px;
        align-items: flex-start;
        flex-direction: column;
        & .comment-text {
          gap: 8px;
          display: flex;
          padding: 0px;
          align-items: center;
          flex-direction: row;
        }
      }
    }
  }
`;
export const CommentSection = styled.div`
  &.comment-section {
    display: flex;
    padding: 16px 0px;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;
    flex-direction: row;
    border-bottom: 1px solid ${bookitColors.grayscale.graySelection};
    width: 100%;
    & .comment-text-files {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      flex: 1 0 0;
      & .comment-text {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        align-self: stretch;
        gap: 8px;
        & .comment-name-time {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 4px;
          align-self: stretch;
          & .comment-name-title-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            align-self: stretch;
            min-height: 32px;
            gap: 8px;
            & .comment-name-title {
              flex: 1 0 0;
            }
          }
        }
      }
    }
  }
`;
