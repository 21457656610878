import { PayloadAction } from '@reduxjs/toolkit';
import { EndUsageData } from 'app/components/EndUsageConfirm';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { PricingState } from 'app/pages/ReservationDetails/Details/slice/types';
import { AxiosError } from 'axios';
import { IResponseType } from 'types/ResponseType';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { ConvertDtoToModel } from './ConvertDtoToModel';
import { usageSaga } from './saga';
import {
  IUsageDto,
  UsageChangesResult,
  UsageChangeStateParameters,
  UsageDetailsState,
  UsageQueryStringParameters,
  UsageSettingsState,
  UsageState,
} from './types';

export const initialState: UsageState = {
  detailsState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
  creditState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
    error: undefined,
  },
  isEdit: false,
  processing: false,
  savedOfflineServices: [],
  hasCharges: undefined,
  refreshOfflineService: undefined,
  usageDtoState: undefined,
  requestedValue: undefined,
  requestedValueFor: undefined,
  valueHandler: undefined,
  usageSettingsState: undefined,
  notFound: undefined,
  stopUsageProcessing: undefined,
  showEndUsageDialog: undefined,
  createManully: undefined,
  usageStarted: undefined,
};

const slice = createSlice({
  name: 'usageState',
  initialState,
  reducers: {
    reset(state, action: PayloadAction) {
      Object.assign(state, initialState);
    },
    initUsage(
      state,
      action: PayloadAction<{
        query: UsageQueryStringParameters;
        globalSettings: GlobalSettingsType;
        prevState?: UsageDetailsState;
      }>,
    ) {
      state.processing = true;
      state.detailsState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
      state.usageDtoState = undefined;
      state.isEdit = false;
      state.savedOfflineServices = [];
      state.createManully = action.payload.query.manual === 'true';
    },
    initUsage_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
        model: IUsageDto;
        isEdit: boolean;
        globalSettings: GlobalSettingsType;
        settings?: UsageSettingsState;
      }>,
    ) {
      state.processing = false;
      state.detailsState.data = ConvertDtoToModel(
        action.payload.model,
        action.payload.globalSettings,
      );
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.usageSettingsState = action.payload.settings;
      state.usageDtoState = action.payload.model;
      state.isEdit = action.payload.isEdit;
      if (action.payload.isEdit) {
        state.savedOfflineServices = [];
      }
    },
    initUsage_Error(state, action: PayloadAction<any>) {
      state.processing = false;
      if ((action.payload as AxiosError)?.response?.status === 404) {
        state.detailsState.hasErrors = true;
        state.notFound = true;
      }
    },
    initUsageNotFound_Error(state, action: PayloadAction) {
      state.processing = false;
      state.detailsState.hasErrors = true;
      state.notFound = true;
    },
    resetDetailsState(state, action: PayloadAction) {
      state.detailsState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
      state.usageSettingsState = undefined;
      state.usageDtoState = undefined;
      state.savedOfflineServices = [];
      state.notFound = undefined;
      state.createManully = undefined;
    },
    calculateCredit(state, action: PayloadAction<UsageDetailsState>) {
      state.creditState.processing = true;
      state.creditState.completed = undefined;
      state.creditState.hasErrors = undefined;
    },
    getCredit_Success(state, action: PayloadAction<PricingState>) {
      state.creditState.processing = false;
      state.creditState.completed = true;
      state.creditState.data = action.payload;
    },
    getCredit_Error(state, action: PayloadAction<any>) {
      state.creditState.processing = false;
      state.creditState.completed = true;
      state.creditState.data = undefined;
    },
    resetCreditState(state, action: PayloadAction) {
      state.creditState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    startUsage(
      state,
      action: PayloadAction<{
        details: UsageDetailsState;
        settings?: UsageSettingsState;
        offlineServices: IOtherServices[];
        manual?: boolean;
      }>,
    ) {
      state.detailsState.processing = true;
      state.detailsState.completed = false;
      state.detailsState.hasErrors = false;
    },
    startUsage_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
        usageId: number;
        manual?: boolean;
      }>,
    ) {
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.detailsState.completed = true;
      if (!action.payload.hasErrors) {
        state.savedOfflineServices = [];
      }
      if (!action.payload.manual) {
        state.usageStarted = true;
      }
    },
    startUsage_Error(state, action: PayloadAction<any>) {
      state.detailsState.processing = false;
      state.detailsState.completed = true;
      state.detailsState.hasErrors = true;
    },
    updateUsage(
      state,
      action: PayloadAction<{
        current: UsageDetailsState;
        offlineServices: IOtherServices[];
        settings: UsageSettingsState | undefined;
        reset?: boolean;
      }>,
    ) {
      state.detailsState.processing = true;
      state.detailsState.completed = undefined;
      state.detailsState.hasErrors = undefined;
    },
    updateUsage_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
        insertModel: IUsageDto;
      }>,
    ) {
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.detailsState.completed = true;
      // state.confirmAssistedReservations =
      //   action.payload.userTrainigData !== null;
      //state.trainingsData = action.payload.userTrainigData;
      if (!action.payload.hasErrors) {
        state.savedOfflineServices = [];
      }
    },
    updateUsage_Error(state, action: PayloadAction<any>) {
      state.detailsState.processing = false;
      state.detailsState.completed = true;
      state.detailsState.hasErrors = true;
    },
    setAnyValue(
      state,
      action: PayloadAction<{
        fieldKey: keyof UsageDetailsState;
        fieldValue: any;
      }>,
    ) {},
    setAnyValueSuccess(
      state,
      action: PayloadAction<{
        fieldKey: keyof UsageDetailsState;
        fieldValue: any;
      }>,
    ) {
      state.valueHandler = {
        run: true,
        fieldKey: action.payload.fieldKey,
        fieldValue: action.payload.fieldValue,
        shouldValidate: true,
      };
    },
    resetValueHandler(state, action: PayloadAction) {
      state.valueHandler = undefined;
    },
    getAnyValue(state, action: PayloadAction<keyof UsageDetailsState>) {
      state.requestedValueFor = action.payload;
    },
    getAnyValueSuccess(state, action: PayloadAction<any>) {
      state.requestedValue = action.payload;
    },
    resetRequestedFor(state, action: PayloadAction) {
      state.requestedValueFor = undefined;
    },
    setSavedOfflineServices(state, action: PayloadAction<IOtherServices[]>) {
      state.savedOfflineServices = action.payload;
    },
    setRefreshOfflineServices(
      state,
      action: PayloadAction<boolean | undefined>,
    ) {
      state.refreshOfflineService = action.payload;
    },
    resetUsageChangeState(state, action: PayloadAction) {
      state.usageChangesState = undefined;
    },
    changeUsageData(
      state,
      action: PayloadAction<{
        parameters: UsageChangeStateParameters;
        warnings: string[];
        errors: string[];
        loadReservations?: boolean;
      }>,
    ) {
      state.asyncChangeLoading = true;
    },
    changeUsageData_Success(state, action: PayloadAction<UsageChangesResult>) {
      state.usageChangesState = action.payload;
      state.asyncChangeLoading = undefined;
    },
    changeUsageData_Error(state, action: PayloadAction) {
      state.usageChangesState = undefined;
      state.asyncChangeLoading = undefined;
    },
    validateUsageData(
      state,
      action: PayloadAction<{
        parameters: UsageChangeStateParameters;
        warnings: string[];
        errors: string[];
      }>,
    ) {
      state.asyncChangeLoading = true;
    },
    validateUsageData_Success(state, action: PayloadAction<IResponseType>) {
      state.usageChangesState = Object.assign({}, state.usageChangesState, {
        ErrorMessages: action.payload.ErrorMessages,
        WarningMessages: action.payload.WarningMessages,
      });
      state.asyncChangeLoading = undefined;
    },
    validateUsageData_Error(state, action: PayloadAction) {
      state.usageChangesState = undefined;
      state.asyncChangeLoading = undefined;
    },
    setAsyncChangeLoading(state, action: PayloadAction<boolean | undefined>) {
      state.asyncChangeLoading = action.payload;
    },
    setStopUsageProcessing(state, action: PayloadAction<boolean | undefined>) {
      state.stopUsageProcessing = action.payload;
    },
    setShowEndUsageDialog(state, action: PayloadAction<boolean | undefined>) {
      state.showEndUsageDialog = action.payload;
    },
    extendUsageSettings(
      state,
      action: PayloadAction<Partial<UsageSettingsState>>,
    ) {},
    updateUsageSettings(
      state,
      action: PayloadAction<Partial<UsageSettingsState>>,
    ) {
      state.usageSettingsState = Object.assign(
        {},
        state.usageSettingsState,
        action.payload,
      );
    },
    stopUsage(
      state,
      action: PayloadAction<{
        usage: EndUsageData;
        globalSettings: GlobalSettingsType;
        isAdmin: boolean;
      }>,
    ) {
      state.detailsState.processing = true;
      state.detailsState.completed = undefined;
      state.detailsState.hasErrors = undefined;
      state.stopUsageProcessing = true;
    },
    stopUsage_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.detailsState.processing = false;
      state.detailsState.completed = true;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.stopUsageProcessing = false;
      state.showEndUsageDialog = false;
      if (!action.payload.hasErrors) {
        state.savedOfflineServices = [];
      }
    },
    stopUsage_Error(state, action: PayloadAction) {
      state.detailsState.processing = false;
      state.detailsState.completed = true;
      state.detailsState.hasErrors = true;
      state.stopUsageProcessing = false;
      state.showEndUsageDialog = false;
    },
    setCreateManully(state, action: PayloadAction<boolean | undefined>) {
      state.createManully = action.payload;
    },
    setUsageStarted(state, action: PayloadAction<boolean | undefined>) {
      state.usageStarted = action.payload;
    },
  },
});

export const { actions: usageStateActions } = slice;

export const useUsageStateSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: usageSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useUsageStateSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
