import { Button } from 'app/components/BasicButtons/Button';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Divider,
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
} from '@material-ui/core';
import { Body } from 'app/components/Typography';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import {
  AssetsFilterForWorkOrder,
  AssetsPicker,
} from 'app/components/pickers/MultiSelectPickers/AssetsPicker/index';
import { initWOTypeDataParams } from 'app/components/pickers/AutocompletePickers/WorkOrderTypePicker';
import { WorkOrderQueryStringParameters } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details/slice/types';
import { GetRawValue } from 'app/components/BasicTable/types/FilterParam';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { WorkOrderDetailsProps } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Skeleton } from '@material-ui/lab';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import styled from 'styled-components';

export interface AddWorkOrderProps<TRow> {
  rows: TRow[];
  getAssets: (rows: TRow[]) => Promise<IAssetDto[]>;
  downtime?: boolean;
  buttonName?: string;
  primary?: boolean;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
  onClick?: () => void;
}
const WorkButton = props => {
  return (
    <Button
      variant={props.primary ? 'main' : 'white'}
      size="small"
      title={props.title}
      startIcon={<Icon icon="plus" />}
      endIcon={<Icon icon="chevron-down" />}
      onClick={props.handleClick}
      aria-controls={props.id}
      aria-haspopup="true"
    >
      {props.buttonName}
    </Button>
  );
};
const WorkOrderContent = styled.div<{ padding?: number }>`
  &.work-root {
    padding: ${props => (props.padding !== undefined ? props.padding : 16)}px;
    min-width: 350px;
    & .work-header {
      display: flex;
      justify-content: space-between;
    }
    & .menu-list {
      max-height: 420px;
      overflow-y: auto;
      & .menu-skeleton {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8px;
        & .skeleton {
          width: 100%;
        }
      }
    }
  }
`;
export function AddWorkOrder<TRow>({
  rows,
  getAssets,
  downtime,
  buttonName,
  primary,
  useSidePanel,
  openPanelWithCover,
  onClick,
}: AddWorkOrderProps<TRow>) {
  const { t } = useTranslation();
  const location = useLocation();
  const { openPanel } = useSidePanelState();
  const [assets, setAssets] = React.useState<IAssetDto[] | undefined>([]);
  const [workTypes, setWorkTypes] = React.useState<
    IWorkOrderTypeDto[] | undefined
  >(undefined);
  const [workTypeLoading, setWorkTypeLoading] = React.useState<
    boolean | undefined
  >(undefined);
  const [assetLoading, setAssetLoading] = React.useState<boolean | undefined>(
    undefined,
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleAssetChange = React.useCallback((value: IAssetDto[] | []) => {
    setAssets(value);
    setWorkTypeLoading(undefined);
    // setWorkTypeLoading(true);
    // initWOTypeDataParams(value, undefined, downtime).then(types => {
    //   setWorkTypes(types);
    //   setWorkTypeLoading(false);
    // });
  }, []);
  const getParamFromUrl = (name: string): string | undefined => {
    return GetRawValue(new URLSearchParams(location.search), name) ?? undefined;
  };
  const handleWorkTypeClick = (event: IWorkOrderTypeDto) => {
    let params = {
      queryParams: {
        id: undefined,
        aType: event.Id.toString(),
        down: (downtime === true && event.AssetAlertType).toString(),
        source: getParamFromUrl('source'),
        Up: getParamFromUrl('Up'),
        title: getParamFromUrl('title'),
        eStart: getParamFromUrl('eStart'),
        sympt: getParamFromUrl('sympt'),
        aStatus: getParamFromUrl('aStatus'),
        aStart: getParamFromUrl('aStart'),
        desc: getParamFromUrl('desc'),
        assetId: !!assets ? assets.map(a => a.Id).join(',') : undefined,
        offH: (downtime === true && event.AssetAlertType).toString(),
        offStart: getParamFromUrl('offStart'),
        offEnd: getParamFromUrl('offStart'),
        reason: getParamFromUrl('reason'),
        eqid: getParamFromUrl('eqid'),
        sid: getParamFromUrl('sid'),
      } as WorkOrderQueryStringParameters,
      useSidePanel: true,
    } as WorkOrderDetailsProps;
    const panelProps = {
      renderPageType: RenderPageType.WorkOrderDetails,
      renderPageProps: params,
      expanded: false,
      useSidePanel: useSidePanel,
      isCover: useSidePanel,
    } as SidePanelOpenState;

    !!openPanelWithCover
      ? openPanelWithCover(panelProps)
      : openPanel(panelProps);
    onClick?.();
    setOpen(false);
  };
  React.useEffect(() => {
    let active =
      open && !assetLoading && (assets === undefined || assets?.length < 1);
    if (active) {
      setAssetLoading(true);
      getAssets(rows).then(ass => {
        setAssets(() => ass);
        setAssetLoading(false);
      });
    }
    return () => {
      active = false;
    };
  }, [assetLoading, assets, getAssets, open, rows]);
  React.useEffect(() => {
    let active =
      open && !!assets && assets.length > 0 && workTypeLoading === undefined;
    if (active) {
      setWorkTypeLoading(true);
      initWOTypeDataParams(assets, undefined, downtime).then(types => {
        setWorkTypes(types);
        setWorkTypeLoading(false);
      });
    }
    return () => {
      active = false;
    };
  }, [assets, downtime, open, workTypeLoading]);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => !prev);
  };
  return (
    <div>
      <WorkButton
        handleClick={handleClick}
        id={`workOrderCreate-${downtime ? 'downtime' : 'new'}`}
        title={t(translations.CreateAnAssetRelatedEvent) as string}
        buttonName={buttonName || (t(translations.AddEvent) as string)}
        primary={primary}
      />
      <Popover
        onClose={handleClick}
        id={`workOrderCreate-${downtime ? 'downtime' : 'new'}`}
        open={open}
        marginThreshold={1}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <WorkOrderContent
          className="work-root"
          padding={assets !== undefined && assets.length > 25 ? 32 : 16}
        >
          {assets !== undefined && assets.length > 25 ? (
            <Grid item>
              <div className="work-header">
                <Body size="small" bold={true}>
                  {t(translations.TooManyInstrumentsSelected).replace(
                    '{0}',
                    '(' + assets?.length.toString() + ')',
                  )}
                </Body>
                <IconButton
                  variant="ghost"
                  aria-label="close"
                  size="xs"
                  absolutePosition="small"
                  onClick={() => {
                    setOpen(false);
                    setAssets([]);
                    setWorkTypes([]);
                  }}
                >
                  <Icon icon="times" />
                </IconButton>
              </div>
            </Grid>
          ) : (
            <Grid container spacing={2} wrap="nowrap" direction="column">
              <Grid item>
                <div className="work-header">
                  <Body size="small" bold={true}>
                    {t(translations.CreateAnAssetRelatedEvent)}
                  </Body>
                  <IconButton
                    variant="ghost"
                    aria-label="close"
                    size="xs"
                    absolutePosition="small"
                    onClick={() => {
                      setOpen(false);
                      setAssets([]);
                      setWorkTypes([]);
                    }}
                  >
                    <Icon icon="times" />
                  </IconButton>
                </div>
              </Grid>
              <Grid item>
                <React.Fragment>
                  <AssetsPicker
                    predicates={AssetsFilterForWorkOrder(undefined)}
                    key="assetKey"
                    onChange={value => {
                      handleAssetChange(value);
                    }}
                    placeholder={t(
                      translations.QuickWorkOrderPleaseSelectAssets,
                    )}
                    fullWidth={true}
                    name="assets"
                    variant="filled"
                    id="asset-id"
                    restrictByAvType="serviceRelated"
                    label={t(translations.Asset)}
                    value={assets || undefined}
                  />
                </React.Fragment>
              </Grid>
              {assets && assets.length > 0 && (
                <React.Fragment>
                  <Grid item>
                    <Divider variant="fullWidth" />
                  </Grid>
                  <Grid item>
                    <MenuList variant="menu" dense className="menu-list">
                      <MenuItem key="selectEventType">
                        <Body size="small" bold={true}>
                          {t(translations.PleaseSelectAnEventType)}
                        </Body>
                        <Divider />
                      </MenuItem>
                      {workTypeLoading ? (
                        <MenuItem key="skeleton-1">
                          <div className="menu-skeleton">
                            <Skeleton className="skeleton" variant="text" />
                            <Skeleton className="skeleton" variant="text" />
                          </div>
                        </MenuItem>
                      ) : workTypes && workTypes.length > 0 ? (
                        workTypes.map(wt => (
                          <MenuItem
                            key={wt.Id}
                            onClick={() => {
                              handleWorkTypeClick(wt);
                              setAssets([]);
                              setWorkTypes([]);
                            }}
                          >
                            <ListItemText>
                              <Body size="small">{wt.Name}</Body>
                            </ListItemText>
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key="notFoundType">
                          <ListItemText>
                            {t(translations.NoAlertTypesFoundForSelectedAssets)}
                          </ListItemText>
                        </MenuItem>
                      )}
                    </MenuList>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          )}
        </WorkOrderContent>
      </Popover>
    </div>
  );
}
