import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.reservation || initialState;

export const selectReservationState = createSelector(
  [selectSlice],
  state => state,
);
export const selectProcessing = createSelector(
  [selectReservationState],
  state => state.processing,
);
export const selectReservation = createSelector(
  [selectReservationState],
  state => state.detailsState,
);
export const selectReservationProcessing = createSelector(
  [selectReservation],
  state => state.processing,
);
export const selectReservationError = createSelector(
  [selectReservation],
  state => state.error,
);
export const selectReservationHasError = createSelector(
  [selectReservation],
  state => state.hasErrors,
);
export const selectReservationCompleted = createSelector(
  [selectReservation],
  state => state.completed,
);
export const selectReservationData = createSelector(
  [selectReservation],
  state => state.data,
);
export const selectReservationSettings = createSelector(
  [selectReservationState],
  state => state.reservationSettingsState,
);
export const selectEquipmentsSettings = createSelector(
  [selectReservationState],
  state => state.equipmentsSettingsState,
);
export const selectConfirmAssistedReservations = createSelector(
  [selectReservationState],
  state => state.confirmAssistedReservations,
);
export const selectUserTrainingData = createSelector(
  [selectReservationState],
  state => state.trainingsData,
);
export const selectFieldHandler = createSelector(
  [selectReservationState],
  state => state.valueHandler,
);
export const selectRequestedValueFor = createSelector(
  [selectReservationState],
  state => state.requestedValueFor,
);
export const selectRequestedValue = createSelector(
  [selectReservationState],
  state => state.requestedValue,
);
export const selectEquipmentChangeState = createSelector(
  [selectReservationState],
  state => state.equipmentChangeResultState,
);
export const selectEquipmentChangeCompleted = createSelector(
  [selectReservationState],
  state => state.isChangeCompleted,
);
export const selectAsyncChangeLoading = createSelector(
  [selectReservationState],
  state => state.asyncChangeLoading,
);

export const selectTransferState = createSelector(
  [selectReservationState],
  state => state.transferState,
);
export const selectTransferProcessing = createSelector(
  [selectTransferState],
  state => state.transferProcessing,
);
export const selectTransferStepCompleted = createSelector(
  [selectTransferState],
  state => state.transferStepCompleted,
);
export const selectTransferToUser = createSelector(
  [selectTransferState],
  state => state.User,
);
export const selectTransferReservation = createSelector(
  [selectTransferState],
  state => state.ReservtionId,
);
export const selectTransferWaitingApproval = createSelector(
  [selectTransferState],
  state => state.WaitingTransferApproval,
);
export const selectCreditState = createSelector(
  [selectSlice],
  state => state.creditState,
);
export const selectCredit = createSelector(
  [selectCreditState],
  state => state.data,
);
export const selectCreditProcessing = createSelector(
  [selectCreditState],
  state => state.processing,
);
export const selectCreditCompleted = createSelector(
  [selectCreditState],
  state => state.completed,
);
export const selectHasCharges = createSelector(
  [selectSlice],
  state => state.hasCharges,
);
export const selectSavedOfflineServices = createSelector(
  [selectSlice],
  state => state.savedOfflineServices,
);
export const selectRemovedOfflineServices = createSelector(
  [selectSlice],
  state => state.removedOfflineServices,
);
export const selectRefreshOfflineServices = createSelector(
  [selectSlice],
  state => state.refeshOfflineService,
);
export const selectTerminateState = createSelector(
  [selectSlice],
  state => state.terminationState,
);
export const selectTerminateProcessing = createSelector(
  [selectTerminateState],
  state => state.terminatProcessing,
);
export const selectTerminateCompleted = createSelector(
  [selectTerminateState],
  state => state.terminateCompleted,
);
export const selectCloneReservations = createSelector(
  [selectSlice],
  state => state.cloneReservation,
);
export const selectReservationDtoState = createSelector(
  [selectSlice],
  state => state.reservationDtoState,
);
export const selectIsEdit = createSelector(
  [selectSlice],
  state => state.isEdit,
);
export const selectSavedForLater = createSelector(
  [selectReservationSettings],
  state => state?.SaveForLater,
);
export const selectIsCreditSubmited = createSelector(
  [selectSlice],
  state => state.submitCredit,
);
export const selectByApprovalErrors = createSelector(
  [selectSlice],
  state => state.ByApprovalErrors,
);
export const selectUserCanRequestApproval = createSelector(
  [selectSlice],
  state => state.userCanRequestApproval,
);
export const selectMaxReservationComplexityValidationResult = createSelector(
  [selectSlice],
  state => state.MaxReservationComplexityValidationResult,
);
export const selectRefreshWorkOrderLink = createSelector(
  [selectSlice],
  state => state.refreshWorkOrderLink,
);
export const selectMultipleProcessing = createSelector(
  [selectSlice],
  state => state.multipleProcessing,
);
export const selectMultiPanelClosed = createSelector(
  [selectSlice],
  state => state.multiPanelClosed,
);
export const selectReservationNotFound = createSelector(
  [selectSlice],
  state => state.notFound,
);
export const selectUserRequestedBackground = createSelector(
  [selectSlice],
  state => state.userRequestedBackground,
);
