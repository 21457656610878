import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { SubscriptionTypesPicker } from '../pickers/AutocompletePickers/SubscriptionTypesPicker';
import { ISubscriptionTypesFilterDto } from 'api/odata/generated/entities/ISubscriptionTypesFilterDto';

export const FormSubscriptionTypesPicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<number> | null> & IFieldProps) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  return (
    <>
      <SubscriptionTypesPicker
        name={props.name}
        placeholder={props.placeholder}
        label={label}
        onChange={value => {
          helpers.setValue(value, true);
          props.onChange?.(value);
        }}
        onBlur={field?.onBlur}
        value={field.value as ISubscriptionTypesFilterDto}
        error={meta?.error !== undefined}
        variant="filled"
        helperText={meta.error}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        info={props.info}
      />
    </>
  );
};
