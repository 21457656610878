import { createSelector } from '@reduxjs/toolkit';
import { AvailabilityTypes } from 'api/odata/generated/enums/AvailabilityTypes';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.articles || initialState;

export const selectArticles = createSelector([selectSlice], state => state);

export const selectArticlesExternalSearchResults = createSelector(
  [selectArticles],
  state => state.externalSearchResults,
);

export const selectArticlesExternalSearchForm = createSelector(
  [selectArticles],
  state => state.externalSearchFormDetails,
);
export const selectArticlesExternalSearchPageIndex = createSelector(
  [selectArticles],
  state => state.externalSearchPageIndex,
);
export const selectArticlesExternalSearchPageSize = createSelector(
  [selectArticles],
  state => state.externalSearchPageSize,
);
export const selectArticlesExternalSearchPageData = createSelector(
  [selectArticlesExternalSearchResults, selectArticlesExternalSearchPageIndex],
  (results, page) => results.data?.[page],
);
export const selectArticleDetails = createSelector(
  [selectArticles],
  state => state.articleDetails,
);
export const selectRefreshEditArticleAssociation = createSelector(
  [selectArticles],
  state => state.refreshEditArticleAssociation,
);
export const selectArticleDetailsBusy = createSelector(
  [selectArticles],
  state => state.articleDetailsBusy,
);
export const selectEditArticleAssociationBusy = createSelector(
  [selectArticles],
  state => state.editArticleAssociationBusy,
);

export const selectArticleDetailsForm = createSelector(
  [selectArticleDetails],
  state => ({
    ...state,
    ...{
      ServiceGroupAssets:
        state?.Assets?.filter(
          f => f.Availability?.Id === AvailabilityTypes.ServiceGroup,
        ) ?? [],
      InstrumentAssets:
        state?.Assets?.filter(f => f.ServiceTypeId === ServiceType.Online) ??
        [],
    },
  }),
);
