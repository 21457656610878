import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import {
  initUnitTypeData,
  UnitType,
  UnitTypePicker,
} from 'app/components/pickers/AutocompletePickers/UnitTypePicker';
import { IMyAssetsRow } from 'app/pages/MyAssetsPage/IMyAssetsRow';
import { translations } from 'locales/translations';
import { debounce } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { valOrNull } from 'utils/typeUtils';
import { FilterValueType } from '../../BasicFilter/FilterValueType';
import { IFilterSettings } from '../../BasicFilter/IFilterSettings';
import { IFilterComponentProps } from '../IFilterComponentProps';

export interface AssetRangeType {
  value: number | null;
  unitType: UnitType | null;
}

export function isAssetRangeType(
  entity: FilterValueType | undefined,
): entity is AssetRangeType {
  if (valOrNull(entity) === null) return false;
  return (
    valOrNull((entity as AssetRangeType).value) !== null &&
    valOrNull((entity as AssetRangeType).unitType) !== null
  );
}

export const initAssetRangeData = async (item: FilterValueType) => {
  if (!isAssetRangeType(item) || valOrNull(item.unitType) === null) {
    return Promise.resolve(item);
  }
  const id = item.unitType?.Id;
  const val = item.value;
  try {
    const data = await initUnitTypeData(id?.toString());
    return {
      value: val,
      unitType: data.length > 0 ? data[0] : null,
    } as AssetRangeType;
  } catch (error) {
    return { value: val, unitType: null } as AssetRangeType;
  }
};

export const assetRangeCustomFilter = (
  value: FilterValueType,
  settings: IFilterSettings<IMyAssetsRow>,
) => {
  if (!isAssetRangeType(value)) return '';
  const name = settings.fieldName;
  const val = value.value ?? 1;
  let predicates: string[] = [];
  let filterBySelectedUnit = `${name}/any(g: g/UnitTypeId eq ${
    value.unitType!.Id
  } and g/MinValue le ${val} and g/MaxValue ge ${val})`;
  predicates.push(filterBySelectedUnit);
  if (value.unitType!.SourceUnits.length > 0) {
    let sourceFilter = `${name}/any(g: ${value
      .unitType!.SourceUnits.map(unit => {
        const rate =
          val / (unit.ConversionRate === 0 ? 1 : unit.ConversionRate);
        return `(g/UnitTypeId eq ${unit.TargetId} and g/MinValue le ${rate} and g/MaxValue ge ${rate})`;
      })
      .join(' or ')})`;
    predicates.push(sourceFilter);
  }
  if (value.unitType!.TargetUnits.length > 0) {
    let targetFilter = `${name}/any(g: ${value
      .unitType!.TargetUnits.map(unit => {
        const rate =
          val * (unit.ConversionRate === 0 ? 1 : unit.ConversionRate);
        return `(g/UnitTypeId eq ${unit.TargetId} and g/MinValue le ${rate} and g/MaxValue ge ${rate})`;
      })
      .join(' or ')})`;
    predicates.push(targetFilter);
  }
  // if (value.unitType!.SourceUnits.length === 0) {
  //   res = `${name}/any(g: g/UnitTypeId eq ${
  //     value.unitType!.Id
  //   } and g/MinValue le ${val} and g/MaxValue ge ${val})`;
  // } else {
  //   const sourceConvert1 = value.unitType!.SourceUnits[0];
  //   const unitId1 = sourceConvert1.TargetId;
  //   const convert1 = sourceConvert1.ConversionRate || 1;
  //   const sourceConvert2 = value.unitType!.SourceUnits[1] ?? sourceConvert1;
  //   const unitId2 = sourceConvert2.TargetId;
  //   const convert2 = sourceConvert2.ConversionRate || 1;
  //   res = `${name}/any(g: (g/UnitTypeId eq ${
  //     value.unitType!.Id
  //   } and g/MinValue le ${val} and g/MaxValue ge ${val}) or (g/UnitTypeId eq ${unitId1} and g/MinValue le ${
  //     val / convert1
  //   } and g/MaxValue ge ${
  //     val / convert1
  //   }) or (g/UnitTypeId eq ${unitId2} and g/MinValue le ${
  //     val / convert2
  //   } and g/MaxValue ge ${val * convert2}))`;
  // }

  // res += ') or (';

  // if (value.unitType!.TargetUnits.length === 0) {
  //   res += `${name}/any(g: g/UnitTypeId eq ${
  //     value.unitType!.Id
  //   } and g/MinValue le ${val} and g/MaxValue ge ${val})`;
  // } else {
  //   const targetConvert1 = value.unitType!.TargetUnits[0];
  //   const unitId1 = targetConvert1.TargetId;
  //   const convert1 = targetConvert1.ConversionRate || 1;
  //   const sourceConvert2 = value.unitType!.TargetUnits[1] ?? targetConvert1;
  //   const unitId2 = sourceConvert2.TargetId;
  //   const convert2 = sourceConvert2.ConversionRate || 1;
  //   res += `${name}/any(g: (g/UnitTypeId eq ${
  //     value.unitType!.Id
  //   } and g/MinValue le ${val} and g/MaxValue ge ${val}) or (g/UnitTypeId eq ${unitId1} and g/MinValue le ${
  //     val * convert1
  //   } and g/MaxValue ge ${
  //     val * convert1
  //   }) or (g/UnitTypeId eq ${unitId2} and g/MinValue le ${
  //     val * convert2
  //   } and g/MaxValue ge ${val * convert2}))`;
  // }

  return '(' + predicates.join(' or ') + ')';
};

export const assetRangeDisplay = (val: FilterValueType | undefined) => {
  if (!isAssetRangeType(val)) return null;
  return `${val.value} ${val.unitType?.Name}`;
};

/**
 *  must recieve CustomData as number else wont work
 */
export const AssetRangeFilter = ({
  value,
  onChange,
  customData,
  ...props
}: IFilterComponentProps) => {
  const { t } = useTranslation();
  const [stateValue, setValue] = useState<AssetRangeType | null>(value);
  const handleValChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = event => {
    const newState = {
      unitType: stateValue?.unitType ?? null,
      value: +event.target.value ?? null,
    };
    setValue(newState);
    if (
      valOrNull(newState.unitType) !== null &&
      valOrNull(newState.value) !== null
    ) {
      handleOnChange(newState);
    }
    // setValue(oldValue => {
    //   const newState = {
    //     unitType: oldValue?.unitType ?? null,
    //     value: +event.target.value ?? null,
    //   };
    //   handleOnChange(newState);
    //   return newState;
    // });
  };
  const handleTypeChange = (val: UnitType | null) => {
    const newState = {
      unitType: val,
      value: stateValue?.value ?? null,
    };
    setValue(newState);
    if (
      valOrNull(newState.unitType) !== null &&
      valOrNull(newState.value) !== null
    ) {
      handleOnChange(newState);
    }
    // setValue(oldVal => {
    //   const newState = {
    //     unitType: val,
    //     value: stateValue?.value ?? null,
    //   };
    //   handleOnChange(newState);
    //   return newState;
    // });
  };
  const handleOnChange = debounce(onChange, 250);
  return (
    <>
      <TextControlField
        value={stateValue?.value ?? ''}
        type="number"
        onChange={handleValChange}
        placeholder={props.label}
        size="small"
        variant="standard"
        inputProps={{ 'aria-label': 'Number Start Filter' }}
      />
      <UnitTypePicker
        placeholder={t(translations.PleaseSelectRangeUnitType)}
        onChange={handleTypeChange}
        value={stateValue?.unitType ?? null}
        size="small"
        variant="standard"
        type={typeof customData === 'number' ? customData : 0}
      />
    </>
  );
};
