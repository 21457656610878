import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  selectExpandedSidePanel,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { StyledFormProps } from '../StyledForm';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Section } from '../wrappers';
import { SIDE_PANEL_WIDTH } from 'styles/constantVariables';

export const StyledFormFieldsContainer = (
  props: Omit<StyledFormProps, 'onSubmit' | 'ref'>,
) => {
  const {
    avoidStyles,
    children,
    useRequestSection,
    isCover,
    noPadding,
    ...other
  } = props;

  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const showShortView = (sidePanelOpen && !sidePanelExpanded) || isCover; // used for side panel view
  const theme = useTheme();
  return (
    <Section
      theme={theme}
      sidePanelWidth={SIDE_PANEL_WIDTH}
      className={
        avoidStyles
          ? undefined
          : clsx('formsection', {
              shortView: showShortView,
              noPadding: noPadding,
              'request-section': useRequestSection,
            })
      }
      {...other}
    >
      {children}
    </Section>
  );
};
