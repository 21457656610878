import DateRenderer from 'app/components/BasicTable/CellRenderers/DateRenderer';
import { ColumnWithDep } from 'app/components/BasicTable/types/ColumnWithDeps';
import { AssetLink } from 'app/pages/AssetPopUp/OpenAssetDetails';
import { UserProfileLink } from 'app/pages/UserDetails/OpenUserProfile';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { AppSettings } from 'types/AppSettings';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { AllowedSettings, GlobalSettings } from 'utils/globalSettings';
import { tryParseBool } from 'utils/string-utils';
import { IBarcodeScanLogDto } from './IBarcodeScanLogDto';

export function getColumns(
  t: TFunction,
  user: AuthenticatedUser | undefined,
  appSettings: AppSettings | null,
  settings: GlobalSettings | null,
) {
  const hideRoomFloor =
    tryParseBool(
      settings?.GetString(AllowedSettings.HideRoomFloorTextBoxes),
    ) === true;
  const res: ColumnWithDep<IBarcodeScanLogDto>[] = [
    {
      Header: t(translations.HashSymbol) as string,
      accessor: 'Id',
    },
    {
      Header: t(translations.UserName) as string,
      accessor: 'UserDisplayName',
      Cell: ({ value, row }) => (
        <>
          <UserProfileLink
            userName={row.original.Username}
            displayName={row.original.UserDisplayName}
          />
        </>
      ),
    },
    {
      Header: t(translations.EntryDate) as string,
      accessor: 'Date',
      Cell: DateRenderer,
    },
    {
      Header: t(translations.AssetName) as string,
      accessor: 'AssetName',
      Cell: ({ value, row }) => {
        if (!!value && value !== null) {
          if (
            row.original.AssetId !== null &&
            row.original.AssetId !== undefined
          ) {
            return (
              <AssetLink id={row.original.AssetId ?? undefined}>
                {value}
              </AssetLink>
            );
          } else {
            return <>{value}</>;
          }
        } else {
          return <>{'N/A'}</>;
        }
      },
    },
    {
      Header: t(translations.BarCode) as string,
      accessor: 'Barcode',
    },
    {
      Header: t(translations.Room) as string,
      accessor: 'RoomName',
    },
    {
      Header: t(translations.Building) as string,
      accessor: 'BuildingName',
    },
    {
      Header: t(translations.LocationName) as string,
      accessor: 'LocationName',
    },
    {
      Header: t(translations.SubLocationName) as string,
      accessor: 'SubLocationName',
    },
    {
      Header: t(translations.StatusName) as string,
      accessor: 'StatusName',
    },
    {
      Header: t(translations.Remarks) as string,
      accessor: 'Remarks',
    },
    {
      Header: t(translations.ServiceGroupName) as string,
      accessor: 'ServiceGroupName',
    },
  ];

  if (!hideRoomFloor) {
    res.push({
      Header: t(translations.FloorName) as string,
      accessor: 'FloorName',
    });
  }
  return res;
}
