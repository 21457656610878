/**
 *
 * FundingTypePicker
 *
 */

import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import { selectAppSettings, selectglobalSettings } from 'app/slice/selectors';
import { OnlineServiceType } from 'enums/OnlineServiceTypeEnum';
import { useSelector } from 'react-redux';
import { AppSettings, IsModuleEnabled } from 'types/AppSettings';
import { Entity } from 'types/common';
import { KnownModules } from 'types/KnownModules';
import { enumToEntityArray } from 'utils/enumKeys';
import { AllowedSettings } from 'utils/globalSettings';

export interface OnlineServiceTypePickerProps
  extends Omit<BaseOptionsPickerProps<Entity<number>>, 'getOptions'> {}

export const getSingleOnlineServiceType = (val: number): Entity<number> => {
  return enumToEntityArray(OnlineServiceType).filter(item => {
    return item.Id === val;
  })[0];
};
export const GetAvailableOnlineServiceTypes = (
  items: Entity<number>[],
  appSettings: AppSettings | null,
  roomsEnabled: boolean,
) => {
  return items.filter(f => {
    if (f.Id === OnlineServiceType.Software) {
      return IsModuleEnabled(appSettings, KnownModules.LocalApp);
    }
    if (f.Id === OnlineServiceType.Relay) {
      return IsModuleEnabled(appSettings, KnownModules.Relay);
    }
    if (f.Id === OnlineServiceType.IpRelay) {
      return IsModuleEnabled(appSettings, KnownModules.IpRelay);
    }
    if (f.Id === OnlineServiceType.WebRelayTm) {
      return IsModuleEnabled(appSettings, KnownModules.WebRelayTm);
    }
    if (f.Id === OnlineServiceType.WebRelayX600m) {
      return IsModuleEnabled(appSettings, KnownModules.WebRelayX600m);
    }
    if (f.Id === OnlineServiceType.UsageForm) {
      return IsModuleEnabled(appSettings, KnownModules.UsageForm);
    }
    if (f.Id === OnlineServiceType.LoanDesk) {
      return IsModuleEnabled(appSettings, KnownModules.LoanDesk);
    }
    if (f.Id === OnlineServiceType.DoorAccess) {
      return IsModuleEnabled(appSettings, KnownModules.DoorAccess);
    }
    if (f.Id === OnlineServiceType.Room) {
      return roomsEnabled;
    }
    if (f.Id === OnlineServiceType.NiDAQmx) {
      return IsModuleEnabled(appSettings, KnownModules.NiDAQmx);
    }
    if (f.Id === OnlineServiceType.KmTronicRelay) {
      return IsModuleEnabled(appSettings, KnownModules.KmTronicRelay);
    }
    if (f.Id === OnlineServiceType.RaspberryPi) {
      return IsModuleEnabled(appSettings, KnownModules.RaspberryPi);
    }
    return false;
  });
};
export const initOnlineServiceTypeData = (
  initval: number | string | undefined,
): Promise<Entity<number>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve(
        enumToEntityArray(OnlineServiceType).filter(item => {
          return item.Id.toString() === initval;
        }),
      );
    });
  }
};
export const getOnlineServiceTypeAssetFilter = (
  appSettings: AppSettings | null,
  roomsEnabled: boolean,
): string => {
  const enabledTypes = GetAvailableOnlineServiceTypes(
    enumToEntityArray(OnlineServiceType),
    appSettings,
    roomsEnabled,
  );
  let filter: string[] = [];
  const enabledUsageTypes = enabledTypes.filter(
    f =>
      f.Id === OnlineServiceType.UsageForm ||
      f.Id === OnlineServiceType.IpRelay ||
      f.Id === OnlineServiceType.WebRelayTm ||
      f.Id === OnlineServiceType.WebRelayX600m ||
      f.Id === OnlineServiceType.NiDAQmx ||
      f.Id === OnlineServiceType.Room ||
      f.Id === OnlineServiceType.RaspberryPi,
  );
  const enabledRemoteTypes = enabledTypes.filter(
    f =>
      f.Id === OnlineServiceType.Relay || f.Id === OnlineServiceType.Software,
  );
  if (enabledUsageTypes.length > 0) {
    filter.push(
      `${new Condition(
        'OperationTypeID',
        ODataOperators.Includes,
        enabledUsageTypes.map(f => f.Id),
      ).toString()}`,
    );
  }
  if (enabledRemoteTypes.length > 0) {
    filter.push(
      `(${new Condition(
        'RemoteControlEnabled',
        ODataOperators.Equals,
        true,
      ).toString()} and ${new Condition(
        'OperationTypeID',
        ODataOperators.Includes,
        enabledRemoteTypes.map(f => f.Id),
      ).toString()})`,
    );
  }
  return filter.length > 1
    ? `(${filter.join(' or ')})`
    : filter.length > 0
    ? filter[0]
    : '';
};
export function OnlineServiceTypePicker(props: OnlineServiceTypePickerProps) {
  const appSettings = useSelector(selectAppSettings);
  const settings = useSelector(selectglobalSettings);
  const roomsEnabled = settings.GetBoolean(
    AllowedSettings[AllowedSettings.RoomUserRoleEnabled],
  );
  const getOptions = (searchTerm: string | null) => {
    let items = GetAvailableOnlineServiceTypes(
      enumToEntityArray(OnlineServiceType),
      appSettings,
      roomsEnabled,
    );

    if (searchTerm !== null && searchTerm !== '') {
      return items.filter(
        f => f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
      );
    }
    return items;
  };
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      value={props.value}
      {...props}
    />
  );
}
