import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import { dateUtils } from 'utils/date-utils';
import {
  EquipmentsChangeStateParameters,
  IReservationEquipmentDto,
  ReservationDetailsState,
  StatusChangeResult,
} from '../slice/types';
import { ReservationsApi } from 'api/ReservationsApi';
import * as Yup from 'yup';
import { debounce } from 'lodash';
import { Entity } from 'types/common';
import { CustomDate } from 'types/CustomDate';
import { IEquipmentAccServiceDto } from 'api/odata/generated/entities/IAccServiceDto';
import { TFunction } from 'i18next';
import { PrintPDFItem } from 'app/components/PrintToPDF/slice/types';
import { translations } from 'locales/translations';
import { isNullOrUndefined } from 'utils/typeUtils';

export const updateFormValues = (
  original: IFormValueDto[],
  coming: IFormValueDto[],
) => {
  const result = coming.map(v => {
    let orn = original.find(o => o.FormFieldId === v.FormFieldId);
    if (!!orn) {
      return Object.assign({}, orn, {
        Value: v.Value,
      });
    } else {
      return v;
    }
  });
  return result;
};
export const getEnd = (
  start: Date,
  equipments: IReservationEquipmentDto[],
): Date => {
  const ForceFullDayReservations = equipments.some(
    f => f.ForceFullDayReservations,
  );
  if (ForceFullDayReservations) {
    return dateUtils.addDays(dateUtils.startOfDay(start), 1);
  }
  let defEnd = dateUtils.addHours(
    start,
    equipments
      .map(f => f.DefaultReservationDuration)
      .sort((a, b) => a - b)[0] || 1,
  );
  let minEnd = dateUtils.addHours(
    start,
    equipments.map(f => f.MinOrderHours).sort((a, b) => a - b)[
      equipments.length - 1
    ] || 1,
  );
  return defEnd < minEnd ? minEnd : defEnd;
};
export const validateReservation = async (
  parameters: EquipmentsChangeStateParameters,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let results = await ReservationsApi.getValidationState(parameters);
      resolve(results);
    } catch (e) {
      resolve(undefined);
    }
  });
};
export const validateDuration = async (
  value: Date | undefined,
  context: Yup.TestContext<any>,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let parent = context.parent as ReservationDetailsState;
      let results = await ReservationsApi.validateDuration({
        Equipments: parent.EquipmentsData.map(f => f.Id),
        AccServices: parent.AccServices.map(f => f.Id),
        ReservationId: parent.Id > 0 ? parent.Id : null,
        Start: parent.StartTime,
        End: parent.EndTime,
        Status: parent.Status?.Id || 1,
        TrainingSignUp: parent.TrainingSignUp,
        Tutoring: parent.Tutoring,
        FullDayReservation: parent.FullDayReservation,
        ADGroup: parent.ADGroup?.Id || null,
        BookedBy: parent.BookedBy?.Id || null,
        FundingTypeId: isNullOrUndefined(parent.FundingType)
          ? null
          : parent.FundingType?.Id,
        CheckStatus: false,
        TrainingSessionId: parent.TrainingSession?.Id || null,
        Remarks: parent.Remarks || null,
        BudgetId: parent.Budget?.Id || null,
        CheckSession: false,
        PurchaseOrder: parent.PurchaseOrder || null,
      });
      resolve(results.value);
    } catch (e) {
      resolve([]);
    }
  });
};
export const validateYupPermissions = async (
  value: Entity<string> | null,
  context: Yup.TestContext<any>,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let parent = context.parent as ReservationDetailsState;
      let results = await ReservationsApi.validatePermissions({
        Equipments: parent.EquipmentsData.map(f => f.Id),
        AccServices: parent.AccServices.map(f => f.Id),
        ReservationId: parent.Id > 0 ? parent.Id : null,
        Start: parent.StartTime,
        End: parent.EndTime,
        Status: parent.Status?.Id || 1,
        TrainingSignUp: parent.TrainingSignUp,
        Tutoring: parent.Tutoring,
        FullDayReservation: parent.FullDayReservation,
        ADGroup: parent.ADGroup?.Id || null,
        BookedBy: parent.BookedBy?.Id || null,
        FundingTypeId: isNullOrUndefined(parent.FundingType)
          ? null
          : parent.FundingType?.Id,
        CheckStatus: false,
        TrainingSessionId: parent.TrainingSession?.Id || null,
        Remarks: parent.Remarks || null,
        BudgetId: parent.Budget?.Id || null,
        CheckSession: false,
        PurchaseOrder: parent.PurchaseOrder || null,
      });
      resolve(results);
    } catch (e) {
      resolve([]);
    }
  });
};
export const validatePermissions = async (values: ReservationDetailsState) => {
  return new Promise(async (resolve, reject) => {
    try {
      let parent = values;
      let results = await ReservationsApi.validatePermissions({
        Equipments: parent.EquipmentsData.map(f => f.Id),
        AccServices: parent.AccServices.map(f => f.Id),
        ReservationId: parent.Id > 0 ? parent.Id : null,
        Start: parent.StartTime,
        End: parent.EndTime,
        Status: parent.Status?.Id || 1,
        TrainingSignUp: parent.TrainingSignUp,
        Tutoring: parent.Tutoring,
        FullDayReservation: parent.FullDayReservation,
        ADGroup: parent.ADGroup?.Id || null,
        BookedBy: parent.BookedBy?.Id || null,
        FundingTypeId: isNullOrUndefined(parent.FundingType)
          ? null
          : parent.FundingType?.Id,
        CheckStatus: false,
        TrainingSessionId: parent.TrainingSession?.Id || null,
        Remarks: parent.Remarks || null,
        BudgetId: parent.Budget?.Id || null,
        CheckSession: false,
        PurchaseOrder: parent.PurchaseOrder || null,
      });
      resolve(results.value);
    } catch (e) {
      resolve(false);
    }
  });
};
export const validateMinEnd = async (
  value: any,
  context: Yup.TestContext<any>,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let val = value as Date;
      if (!!val) {
        resolve(context.parent.StartTime < val);
      } else {
        resolve(true);
      }
    } catch (e) {
      resolve(true);
    }
  });
};
export const validatePrerequisite = async (values: ReservationDetailsState) => {
  return new Promise(async (resolve, reject) => {
    try {
      let parent = values;
      let results = await ReservationsApi.validatePrerequisite({
        Equipments: parent.EquipmentsData.map(f => f.Id),
        AccServices: parent.AccServices.map(f => f.Id),
        ReservationId: parent.Id > 0 ? parent.Id : null,
        Start: parent.StartTime,
        End: parent.EndTime,
        Status: parent.Status?.Id || 1,
        TrainingSignUp: parent.TrainingSignUp,
        Tutoring: parent.Tutoring,
        FullDayReservation: parent.FullDayReservation,
        ADGroup: parent.ADGroup?.Id || null,
        BookedBy: parent.BookedBy?.Id || null,
        FundingTypeId: isNullOrUndefined(parent.FundingType)
          ? null
          : parent.FundingType?.Id,
        CheckStatus: false,
        TrainingSessionId: parent.TrainingSession?.Id || null,
        Remarks: parent.Remarks || null,
        BudgetId: parent.Budget?.Id || null,
        CheckSession: false,
        PurchaseOrder: parent.PurchaseOrder || null,
      });
      resolve(results);
    } catch (e) {
      resolve(false);
    }
  });
};

export const validateAccServices = (
  allEquipments: IReservationEquipmentDto[],
): string[] => {
  let allAcc = allEquipments.reduce((acc, next) => {
    let services: IEquipmentAccServiceDto[] = [];
    acc.forEach(c => {
      if (next.AccServices.some(n => n.Id === c.Id)) {
        services.push(c);
      }
    });
    return services;
  }, allEquipments.filter(f => f.AccServices.length > 0)[0].AccServices);
  if (
    allEquipments.some(f => f.AccServices.length > 0) &&
    allEquipments.some(f => f.AccServices.length < 1)
  ) {
    return allEquipments.filter(f => f.AccServices.length > 0).map(f => f.Name);
  } else if (allAcc.length !== allEquipments.length) {
    let wrongEqs = allEquipments.filter(
      f =>
        !allAcc.some(
          a => a.EquipmentId === f.Id || f.AccServices.some(g => g.Id === a.Id),
        ),
    );
    return wrongEqs.map(f => f.Name);
  } else {
    return [];
  }
};
export const getStatus = async (
  values: ReservationDetailsState,
  trainingSessionOnly: boolean,
): Promise<StatusChangeResult> => {
  return new Promise(async (resolve, reject) => {
    if (trainingSessionOnly) {
      let status = {
        Status: (values.EquipmentsData as IReservationEquipmentDto[])[0]
          .TrainingSignUpApproval,
        Tutoring: false,
      } as StatusChangeResult;
      resolve(status);
    } else {
      try {
        let parent = values;
        let result = await ReservationsApi.changeStatus({
          Equipments: parent.EquipmentsData.map(f => f.Id),
          AccServices: parent.AccServices.map(f => f.Id),
          ReservationId: parent.Id > 0 ? parent.Id : null,
          Start: parent.StartTime,
          End: parent.EndTime,
          Status: parent.Status?.Id || 1,
          TrainingSignUp: parent.TrainingSignUp,
          Tutoring: parent.Tutoring,
          FullDayReservation: parent.FullDayReservation,
          ADGroup: parent.ADGroup?.Id || null,
          BookedBy: parent.BookedBy?.Id || null,
          FundingTypeId: isNullOrUndefined(parent.FundingType)
            ? null
            : parent.FundingType?.Id,
          CheckStatus: false,
          TrainingSessionId: parent.TrainingSession?.Id || null,
          Remarks: parent.Remarks || null,
          BudgetId: parent.Budget?.Id || null,
          CheckSession: false,
          PurchaseOrder: parent.PurchaseOrder || null,
        });
        resolve(result);
      } catch (e) {
        resolve({
          Status: values.Status?.Id || 1,
          Tutoring: values.Tutoring,
        } as StatusChangeResult);
      }
    }
  });
};
export const formatedToString = (date: Date | string) => {
  return dateUtils.formatISO(dateUtils.dateOrStringToDate(date));
};
export const toDate = (date: Date | string) => {
  return dateUtils.dateOrStringToDate(date);
};
export const newDate = (date: Date | string | undefined | null) => {
  if (!date) {
    return new CustomDate(new Date(), 'complete');
  } else {
    return new CustomDate(toDate(date), 'complete');
  }
};
export const greaterThanDate = (
  compared: Date | string,
  toCompare: Date | string,
) => {
  let first = newDate(compared);
  let second = newDate(toCompare);
  if (first.getTime() > second.getTime()) {
    return true;
  }
  return false;
};
export const lessThanDate = (
  compared: Date | string,
  toCompare: Date | string,
) => {
  let first = newDate(compared);
  let second = newDate(toCompare);
  if (first.getTime() < second.getTime()) {
    return true;
  }
  return false;
};
export const getWorkFlowName = (userName: string) => {
  return {
    Id: -1,
    Name: `${userName} ${dateUtils.shortDateTimeFormat(newDate(undefined))}`,
  } as Entity<number>;
};
export const debounceValidateMinEnd = debounce(validateMinEnd, 300);
export function getPrintPDFDefaultSections(
  t: TFunction,
  serviceRequestEnabled: boolean,
  hasCustomForm?: boolean,
): PrintPDFItem[] {
  let sections = [
    {
      id: 'ReservationDetails',
      label: t(translations.ReservationDetails),
      checked: true,
    },
    {
      id: 'OfflineServicesDetails',
      label: t(translations.ReservationConsumables),
      checked: false,
    },
  ];
  if (hasCustomForm) {
    sections.push({
      id: 'CustomFormDetails',
      label: t(translations.CustomFormDetails),
      checked: true,
    });
  }
  if (serviceRequestEnabled) {
    sections.push({
      id: 'ServiceRequest',
      label: t(translations.AssociatedServiceRequestDetails),
      checked: false,
    });
  }
  return sections;
}
