import { Entity } from 'types/common';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { selectConnectedSettings } from '../slice/selectors';

import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { isMember } from 'app/pages/ReservationDetails/Details/components/FormFieldListeners/isMember';

export interface UserNameListenerProps<T> {
  userName: Entity<string> | null;
  userGroup: Entity<string> | null;
  services: Entity<number>[];
  user: AuthenticatedUser | undefined;
  globalSettings: GlobalSettingsType;
  isEdit: boolean;
  startTime: Date | string;
  endTime?: Date | string;
  setUserGroup: (value: Entity<string> | null) => void;
  setBudget: (value: Entity<number> | null) => void;
  values?: T;
  forceDisabled?: boolean;
}
export function UserNameListener<T>(props: UserNameListenerProps<T>) {
  const {
    userName: selectedUser,
    services,
    isEdit,
    userGroup: selectedUserGroupo,
    globalSettings,
    setUserGroup,
    forceDisabled,
  } = props;
  const serviceSettings = useSelector(selectConnectedSettings);
  const ReservationUserGroupGroupByBudgetUserGroup = React.useMemo(() => {
    return serviceSettings.budgetVisible
      ? globalSettings.reservationUserGroupGroupByBudgetUserGroup
      : false;
  }, [
    globalSettings.reservationUserGroupGroupByBudgetUserGroup,
    serviceSettings.budgetVisible,
  ]);

  const [initilized, setInitilized] = React.useState(!forceDisabled);
  React.useEffect(() => {
    if (!initilized) {
      var reservationUserGroupGroupByBudgetUserGroup =
        services.length === 0
          ? globalSettings.reservationUserGroupGroupByBudgetUserGroup
          : ReservationUserGroupGroupByBudgetUserGroup;

      if (!isEdit) {
        if (selectedUser !== null) {
          if (!reservationUserGroupGroupByBudgetUserGroup) {
            if (!isMember(selectedUser, selectedUserGroupo)) {
              const selectedUserDto = selectedUser as IUserFilterDto;
              setUserGroup({
                Id: selectedUserDto.UserGroupId,
                Name: selectedUserDto.UserGroupName,
              } as Entity<string>);
            }
          }
        }
      }
    }
    setInitilized(false);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  return null;
}
