import React from 'react';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { ICanRaiseBasicTableRefreshEvent } from '../../ControlledTable/ControlledTableProps';

export interface RefreshIconProps extends ICanRaiseBasicTableRefreshEvent {
  loading?: boolean;
  title: string;
}
export function RefreshIcon(props: RefreshIconProps) {
  const handleClick = React.useCallback(() => {
    if (props.loading === true) {
      return;
    }

    props.onRefresh?.();
  }, [props]);

  return (
    <IconButton
      onClick={handleClick}
      title={props.title}
      disabled={props.loading}
      size="small"
    >
      <Icon icon="refresh" spin={props.loading} />
    </IconButton>
  );
}
