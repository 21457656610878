import { LinearProgress, useTheme } from '@material-ui/core';
import { Div100 } from 'app/components/AssetQuickSearch/styled';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { Body, ButtonLabel } from 'app/components/Typography';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PricingState } from '../../slice/types';

export interface CalculatePricingProps {
  open: boolean;
  onClose: () => void;
  title: React.ReactNode;
  edit: boolean;
  credit?: PricingState;
  creditProcessing?: boolean;
  creditCompleted?: boolean;
  pricingType: 'Reservation' | 'Usage' | 'OtherService';
}
const CreditContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '16px',
  gap: 32,
  width: '100%',
  '& .creditItem': {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    width: '100%',
  },
}));
export function CalculatePricing(props: CalculatePricingProps) {
  const {
    open,
    onClose,
    credit,
    creditProcessing,
    title,
    creditCompleted,
    edit,
    pricingType,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const pricingBodyKey = React.useMemo(() => {
    return edit
      ? pricingType === 'Reservation'
        ? translations.PricingConfirmationLabelEditText1
        : pricingType === 'Usage'
        ? translations.PricingConfirmationUsageLabelEditText1
        : translations.PricingConfirmationOfflineEditText1
      : pricingType === 'Reservation'
      ? translations.PricingConfirmationLabelText1
      : pricingType === 'Usage'
      ? translations.PricingConfirmationUsageLabelText1
      : translations.PricingConfirmationOfflineText1;
  }, [edit, pricingType]);
  return (
    <DialogConfirm
      isOpen={open}
      title={title}
      onClose={onClose}
      onConfirm={() => onClose()}
      body={
        creditProcessing && !creditCompleted ? (
          <Div100>
            <LinearProgress variant="query" color="primary" />
          </Div100>
        ) : (
          <CreditContainer theme={theme}>
            <div className="creditItem">
              <ButtonLabel size="small">
                {t(translations.Pricing_EstimatedCost)}
              </ButtonLabel>
              <Body size="small">
                {(t(pricingBodyKey) as string).replace(
                  '{0}',
                  (credit?.credit || 0).toFixed(2),
                )}
              </Body>
            </div>
            {!!credit?.balance && credit?.balance !== null && (
              <div className="creditItem">
                <ButtonLabel size="small">
                  {t(translations.Pricing_Balance)}
                </ButtonLabel>
                <Body size="small">
                  {(t(translations.Pricing_BalanceResult) as string).replace(
                    '{0}',
                    (credit?.balance || 0).toFixed(2),
                  )}
                </Body>
              </div>
            )}
          </CreditContainer>
        )
      }
      maxWidth={'sm'}
    />
  );
}
