import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { GlobalSettings } from 'utils/globalSettings';
import { IArticleDto } from 'api/odata/generated/entities/IArticleDto';
import { ListRenderer } from 'app/components/BasicTable/CellRenderers/ListRenderer';
import { AvailabilityTypes } from 'api/odata/generated/enums/AvailabilityTypes';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { ArticleSource } from './ArticlesSourcePicker';
import { ExternalLink } from 'app/components/ExternalLink';
import { ArticleDoiLink } from './ArticleDoiLink';
import { DateTimeLabel } from 'app/components/DateTimeLabel';
import { ArticleStatus } from 'enums/Article';
import { ColumnWithDep } from 'app/components/BasicTable/types/ColumnWithDeps';
import { ArticleDatasetLink } from './AricleDatasetLink';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { AssetLink } from '../AssetPopUp/OpenAssetDetails';
import htmlParse from 'html-react-parser';
import {
  allowedToViewAlert,
  IAlertPermissionCheckRecord,
} from 'app/permissions/WorkOrders/workOrdersPermissionUtils';
import { WorkOrderLink } from '../Actions/GlobalSelectedActions/OpenWorkOrder';
import { ArticleLink } from '../Actions/GlobalSelectedActions/OpenArticleDetails';

export const ArticleServiceGroupsColumnId /*: keyof IArticleDto*/ =
  'FirstServiceGroupAssetName';
export const ArticleInstrumentsColumnId /*: keyof IArticleDto*/ =
  'FirstInstrumentAssetName';

export function getColumns(
  t: TFunction,
  user: AuthenticatedUser | undefined,
  settings: GlobalSettings,
  useSidePanel?: boolean,
  openPanel?: (state: SidePanelOpenState) => void,
) {
  if (user === undefined) {
    return [];
  }

  const result: ColumnWithDep<IArticleDto>[] = [
    {
      Header: t(translations.Id) as string,
      accessor: 'Id',
    },
    {
      Header: t(translations.Title) as string,
      accessor: 'Title',
      Cell: ({ value, row }) => {
        return (
          value !== null && (
            <ArticleLink
              Id={row.original.Id}
              useSidePanel={useSidePanel}
              title={row.original.Title ?? undefined}
              value={row.original}
              externalIdSource={row.original.ExternalIdSource ?? undefined}
              externalId={row.original.ExternalId ?? undefined}
              openPanelWithCover={openPanel}
            >
              {htmlParse(value)}
            </ArticleLink>
          )
        );
      },
    },
    {
      Header: t(translations.Authors) as string,
      accessor: 'Authors',
      Cell: ListRenderer,
    },
    {
      Header: t(translations.Journal) as string,
      accessor: 'Journal',
    },
    {
      Header: t(translations.ArticlePageNumber) as string,
      accessor: 'PageNumber',
    },
    {
      id: ArticleServiceGroupsColumnId,
      accessor: ArticleServiceGroupsColumnId,
      Header: t(translations.ServiceGroups) as string,
      Cell: ({ row }) => {
        let groups =
          row.original.Assets?.filter(
            f => f.Availability?.Id === AvailabilityTypes.ServiceGroup,
          )?.map(item => item) ?? [];
        if (groups.length > 0) {
          const res = groups.map((v, index) => (
            <>
              <AssetLink
                id={v.Id}
                useSidePanel={useSidePanel}
                openPanelWithCover={openPanel}
              >
                {v.Name}
              </AssetLink>
              {index < groups.length - 1 && <br />}
            </>
          ));
          return res;
        } else {
          return <></>;
        }
        //return ListRenderer({ value: groups });
      },
    },
    {
      id: ArticleInstrumentsColumnId,
      accessor: ArticleInstrumentsColumnId,
      Header: t(translations.Instruments) as string,
      Cell: ({ row }) => {
        const groups =
          row.original.Assets?.filter(
            f => f.ServiceTypeId === ServiceType.Online,
          )?.map(item => item) ?? [];
        if (groups.length > 0) {
          const res = groups.map((v, index) => (
            <>
              <AssetLink
                id={v.Id}
                useSidePanel={useSidePanel}
                openPanelWithCover={openPanel}
              >
                {v.Name}
              </AssetLink>

              {index < groups.length - 1 && <br />}
            </>
          ));
          return res;
        } else {
          return <></>;
        }
        // return ListRenderer({ value: groups });
      },
    },
    {
      id: 'PubMedId',
      Header: t(translations.PubMedId) as string,
      accessor: 'ExternalId',
      Cell: ({ value, row }) => {
        const pubmedType: ArticleSource = 'PubMed';
        return (
          row.original.ExternalIdSource === pubmedType && (
            <ExternalLink href={`https://pubmed.ncbi.nlm.nih.gov/${value}/`}>
              {value}
            </ExternalLink>
          )
        );
      },
    },
    {
      id: 'Doi',
      Header: t(translations.Doi) as string,
      accessor: 'Doi',
      Cell: ({ value, row }) => {
        return (
          value !== null && (
            <ArticleDoiLink
              doi={value}
              externalIdSource={row.original.ExternalIdSource}
              externalId={row.original.ExternalId}
            />
          )
        );
      },
    },
    {
      id: 'Dataset',
      Header: t(translations.ArticleDataset) as string,
      accessor: 'Dataset',
      Cell: ({ value, row }) => {
        return (
          value !== null && (
            <ArticleDatasetLink
              dataset={value}
              externalIdSource={row.original.ExternalIdSource}
              externalId={row.original.ExternalId}
            />
          )
        );
      },
    },
    {
      Header: t(translations.InsertedBy) as string,
      accessor: 'UserDisplayName',
      Cell: ({ value, row }) => {
        const res = value ?? '';
        return <> {res} </>;
      },
    },
    {
      Header: t(translations.InsertedAt) as string,
      accessor: 'EnteredAt',
      Cell: ({ value, row }) => {
        return <DateTimeLabel value={value} />;
      },
    },
    {
      Header: t(translations.RelatedUser) as string,
      accessor: 'RelatedUserDisplayName',
      Cell: ({ value, row }) => {
        const res = value ?? '';
        return <> {res} </>;
      },
    },
    {
      Header: t(translations.UserGroup) as string,
      accessor: 'UserGroupName',
      Cell: ({ value, row }) => {
        const res = value ?? '';
        return <> {res} </>;
      },
    },
    {
      Header: t(translations.Department) as string,
      accessor: 'DepartmentName',
      Cell: ({ value, row }) => {
        const res = value ?? '';
        return <> {res} </>;
      },
    },
    {
      Header: t(translations.Institute) as string,
      accessor: 'InstituteName',
      Cell: ({ value, row }) => {
        const res = value ?? '';
        return <> {res} </>;
      },
    },
    {
      Header: t(translations.InstituteType) as string,
      accessor: 'InstituteTypeName',
      Cell: ({ value, row }) => {
        const res = value ?? '';
        return <> {res} </>;
      },
    },
    {
      Header: t(translations.ArticleStatus) as string,
      accessor: 'Status',
      Cell: ({ value, row }) => {
        const key = 'Article' + ArticleStatus[value];
        return <> {t(key)} </>;
      },
    },
    {
      Header: t(translations.FileComment) as string,
      accessor: 'FileComment',
      // Cell: ArticleHTMLLinkRenderer,
      Cell: ({ value, row }) => {
        let v =
          value !== null && value !== undefined && value !== ''
            ? value[0] === '<'
              ? (htmlParse(value) as JSX.Element).props.children
              : value
            : '';
        return (
          <ArticleLink
            Id={row.original.Id}
            useSidePanel={useSidePanel}
            value={row.original}
            externalIdSource={row.original.ExternalIdSource ?? undefined}
            externalId={row.original.ExternalId ?? undefined}
            openPanelWithCover={openPanel}
          >
            {v}
          </ArticleLink>
        );
      },
    },
    {
      Header: t(translations.CalibrationRecord) as string,
      accessor: 'CalibrationRecord',
      Cell: ({ value, row }) => {
        const checkRow = {
          ServiceGroupId:
            row.original.Assets?.length > 0
              ? row.original.Assets[0].ServiceGroupId
              : null,
          ReportedBy: row.original.EnteredBy,
        } as IAlertPermissionCheckRecord;
        return allowedToViewAlert(user, checkRow) && value !== null ? (
          <WorkOrderLink
            id={value}
            useSidePanel={useSidePanel}
            openPanelWithCover={openPanel}
          >
            {value}
          </WorkOrderLink>
        ) : (
          <>{value}</>
        );
      },
      deps: ['CalibrationRecordName'],
    },
  ];
  return result;
}
