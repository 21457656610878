import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { PrimaryActionDynamic } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { WorkOrderDetailsProps } from '../WorkOrders/WorkOrderDetailsPage/Details';
import { allowedToViewAlert } from 'app/permissions/WorkOrders/workOrdersPermissionUtils';

export const workOrderPrimaryAction: PrimaryActionDynamic<
  IWorkOrderDetailsDto
> = (openPanel, row, toggle, user, useSidePanel) => {
  if (allowedToViewAlert(user, row.original)) {
    openPanel({
      renderPageType: RenderPageType.WorkOrderDetails,
      renderPageProps: {
        queryParams: { id: '' + row.original.Id },
        useSidePanel: true,
      } as WorkOrderDetailsProps,
      useSidePanel: useSidePanel,
      expanded: false,
      isCover: useSidePanel,
    });
  }
};

export default workOrderPrimaryAction;
