import { Link } from '@material-ui/core';
import { useOpenReservationPopUp } from 'app/pages/Actions/GlobalSelectedActions/OpenReservation';
import { AssetLink } from 'app/pages/AssetPopUp/OpenAssetDetails';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClosableMessageProps, LoanDesk } from '../slice/type';

export const LoanDeskMessage = (props: LoanDesk & ClosableMessageProps) => {
  const { t } = useTranslation();
  const openSidePanel = useOpenReservationPopUp();
  const { AssetDetails, Id } = props;
  const message = t(translations.ReservationStartedMessage_LoanDesk);
  const link = <Link onClick={() => openSidePanel({ id: Id })}>{Id}</Link>;
  const asset = <AssetLink id={AssetDetails.Id}>{AssetDetails.Name}</AssetLink>;
  const messages = message
    .split('{0}')
    .map(m =>
      m
        .split('{1}')
        .map<React.ReactNode>((f, i) => (
          <React.Fragment key={'LoanDesk' + i}> {f} </React.Fragment>
        ))
        .reduce((prev, curr) => [prev, asset, curr]),
    )
    .reduce((prev, curr) => [prev, link, curr]);
  return <>{messages}</>;
};
