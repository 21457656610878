/**
 *
 * FormPermissionLevelPicker
 *
 */
import { FieldHookConfig, useField } from 'formik';
import { IPermissionLevelDto } from 'api/odata/generated/entities/IPermissionLevelDto';
import { PermissionLevelPicker } from 'app/components/pickers/AutocompletePickers/PermissionLevelPicker';
import { IFieldProps } from '../FormRender/IFormFieldSetting';

export const FormPermissionLevelPicker = ({
  label,
  ...props
}: FieldHookConfig<IPermissionLevelDto | null> & IFieldProps) => {
  const [field, meta, helpers] = useField<IPermissionLevelDto | null>(props);
  return (
    <>
      <PermissionLevelPicker
        name={props.name}
        placeholder={props.placeholder}
        predicates={props.predicates}
        label={label}
        variant="filled"
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        info={props.info}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
      />
    </>
  );
};
