import { Entity } from 'types/common';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';

/**
 * Determines user's membership in provided user group.
 * Can be used to clear user/usergroup selection in connected selectors (user group)
 * @param selectedUser selected user
 * @param selectedUserGroup selected user group
 * @returns true if the user is a member of the user group
 */
export function isMember(
  selectedUser: Entity<string> | null | undefined,
  selectedUserGroup: Entity<string> | null | undefined,
) {
  const userGroupId = (selectedUser as IUserFilterDto)?.UserGroupId;
  const samePrimaryUserGroup = userGroupId === selectedUserGroup?.Id;
  const userGroups = (selectedUser as IUserFilterDto)?.UserGroups;
  const member = userGroups?.some(ug => ug.Id === selectedUserGroup?.Id);
  const result = samePrimaryUserGroup || member;
  return result;
}
