import * as React from 'react';
import { FieldHookConfig } from 'formik';
import { useDropzone } from 'react-dropzone';
import { TextFieldProps } from 'app/components/TextField';
import { FileCard } from './FileCard';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectExpandedSidePanel,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { useLargeFilesUploadSlice } from '../slice';
import clsx from 'clsx';
import { DropBoxWrapper } from '../styled';

export interface SelectFileProps {
  accept: any;
  downloadFileName?: string;
  filesPlaceholder: React.ReactNode;
  maxFilesCount?: number;
  maxSize: number;
  disableFileUpload?: boolean;
}

export const SelectFile = ({
  label,
  onBlur,
  value,
  name,
  error,
  helperText,
  accept,
  downloadFileName,
  filesPlaceholder,
  maxFilesCount,
  maxSize,
  disableFileUpload,
  ...props
}: FieldHookConfig<string> & TextFieldProps & SelectFileProps) => {
  const dispatch = useDispatch();
  const { actions: largeFileActions } = useLargeFilesUploadSlice();
  const isMobile = DetectIsMobile();
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const showShortView = isMobile || (sidePanelOpen && !sidePanelExpanded);
  const maxFiles =
    maxFilesCount === undefined ? 1 : maxFilesCount > 20 ? 20 : maxFilesCount;
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      dispatch(largeFileActions.setSeletedFiles(acceptedFiles.length));
    },
    [dispatch, largeFileActions],
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: maxFiles,
    //minSize: minChunkSize,
    maxSize: maxSize,
    accept: accept,
    disabled: disableFileUpload,
  });
  const remorFile = React.useCallback(
    (filename: string) => {
      const index = acceptedFiles.findIndex(item => item.name === filename);
      acceptedFiles.splice(index, 1);
      dispatch(largeFileActions.setSeletedFiles(acceptedFiles.length));
    },
    [acceptedFiles, dispatch, largeFileActions],
  );
  return (
    <>
      <DropBoxWrapper
        className={clsx('drop-box-wrapper', { 'short-view': showShortView })}
      >
        <div {...getRootProps({ className: 'dropzone drop-box-container' })}>
          <input {...getInputProps()} />
          <p>{filesPlaceholder}</p>
        </div>
        {acceptedFiles.length > 0 && (
          <div className="drop-box-content">
            {acceptedFiles.map(file => (
              <FileCard file={file} remorFile={remorFile} />
            ))}
          </div>
        )}
      </DropBoxWrapper>
    </>
  );
};
