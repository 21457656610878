import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import * as React from 'react';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import clsx from 'clsx';

export interface InfoIconProps {
  title: NonNullable<React.ReactNode>;
  className?: string;
  error?: boolean;
}

export const InfoIcon = (props: InfoIconProps) => {
  const { title, className, error } = props;
  return (
    <Tooltip arrow title={title} className={clsx('info-root', className)}>
      <span>
        <Icon
          icon="info-circle"
          color={error ? 'danger' : 'default'}
          colorExtend="textHover"
          fontSize="13px"
          width="13px"
        />
      </span>
    </Tooltip>
  );
};
