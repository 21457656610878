/**
 *
 * AssetListItem
 *
 */
import {
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { toQueryString, toRootedURL } from 'utils/url-utils';
import { AssetThumbImage } from 'app/components/AssetThumbImage';
import { AssetQuickSearchDetails } from '../slice/types';
import { useSelector } from 'react-redux';
import { selectGlobalSetting, selectglobalSettings } from 'app/slice/selectors';
import { AllowedSettings, GlobalSettings } from 'utils/globalSettings';
import { GetSchedulerPagePath } from 'app/pages/CalendarPage/GetCalendarPagePath';
import cliTruncate from 'cli-truncate';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Body, Tips } from 'app/components/Typography';
import React from 'react';
import { ActionIconButton } from 'app/components/BasicButtons/ActionIconButton';
import { tryParseBool } from 'utils/string-utils';
import { Entity } from 'types/common';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { ApprovalRequiredContent, SecondaryContent } from '../styled';

export interface AssetListItemProps {
  item: AssetQuickSearchDetails;
  useAssetPopup?: boolean;
  isMobile?: boolean;
  selectedServiceGroups?: Entity<number>[];
}

const DefaultQuickAssetSearchAction = [
  'AssetDetails',
  'Calendar',
  'Timeline',
] as const;

const getAssetDetailsLink = (item: AssetQuickSearchDetails) =>
  `/assets/details?id=${item.Id}`;
const getCalendarLink = (
  item: AssetQuickSearchDetails,
  EnableLegacyScheduler: boolean,
) => {
  if (EnableLegacyScheduler) {
    return toRootedURL('/Weekly.aspx', { id: `os${item.ServiceId}` });
  }

  return toRootedURL(
    `${GetSchedulerPagePath('calendar')}?${toQueryString({
      eid: item.ServiceId,
      openToBook: true,
    })}`,
  );
};

const getTimelineLink = (
  item: AssetQuickSearchDetails,
  EnableLegacyScheduler: boolean,
) => {
  if (EnableLegacyScheduler) {
    return toRootedURL('/Timeline.aspx', { services: item.ServiceId });
  }
  return toRootedURL(
    `${GetSchedulerPagePath('timeline')}?eid=${item.ServiceId}&openToBook=true`,
  );
};

function getSettingValue(
  g: GlobalSettings,
  selectedServiceGroups?: Entity<number>[],
): (item: AssetQuickSearchDetails) => { t: string; url: string } {
  const s = g.GetString(AllowedSettings.DefaultQuickAssetSearchAction);
  const t = g.GetBooleanByKey(AllowedSettings.EnableLegacyScheduler);
  var validKey =
    DefaultQuickAssetSearchAction.find(f => f === s) || 'AssetDetails';
  return (item: AssetQuickSearchDetails) => {
    if (
      !item.BookableAsset ||
      (item.BookableAsset &&
        !selectedServiceGroups?.some(f => f.Id === item.ServiceGroupId))
    ) {
      return { t: 'AssetDetails', url: getAssetDetailsLink(item) };
    }
    switch (validKey) {
      case 'AssetDetails':
        return { t: validKey, url: getAssetDetailsLink(item) };
      case 'Calendar':
        return { t: validKey, url: getCalendarLink(item, t) };
      case 'Timeline':
        return { t: validKey, url: getTimelineLink(item, t) };
    }
  };
}

export function AssetListItem({
  item,
  useAssetPopup,
  isMobile,
  selectedServiceGroups,
}: AssetListItemProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const truncateName = isMobile
    ? item.Name?.length > 25
    : item.Name?.length > 50;
  const app = useSelector(selectglobalSettings);
  const defaultLink = getSettingValue(app);
  const EnableLegacyScheduler = useSelector(
    state =>
      tryParseBool(
        selectGlobalSetting(state, AllowedSettings.EnableLegacyScheduler),
      ) === true,
  );
  const showCalendarLinks = React.useMemo(() => {
    return (
      (selectedServiceGroups === undefined && item.BookableAsset) ||
      (!!selectedServiceGroups &&
        selectedServiceGroups.some(f => f.Id === item.ServiceGroupId))
    );
  }, [item.BookableAsset, item.ServiceGroupId, selectedServiceGroups]);
  const href = React.useMemo(() => {
    return defaultLink(item);
  }, [defaultLink, item]);
  const handleAssetClick = React.useCallback(() => {
    history.push(href.url);
  }, [history, href.url]);
  return (
    <Tooltip title={t(translations.ShowFullDetails) as string}>
      <ListItem
        key={item.Id}
        disableGutters
        button
        component="a"
        target={href.t !== 'AssetDetails' ? '_blank' : undefined}
        href={href.t !== 'AssetDetails' ? href.url : undefined}
        onClick={href.t === 'AssetDetails' ? handleAssetClick : undefined}
        className={'assetListItemLink'}
        disableRipple
      >
        <ListItemIcon>
          {item.Image !== null ? (
            <AssetThumbImage imageName={item.Image} size={'small'} />
          ) : (
            <Avatar variant="square">{'A'}</Avatar>
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            isMobile ? (
              <>{cliTruncate(item.Name, 25, { position: 'end' })}</>
            ) : (
              <>
                {truncateName ? (
                  <Tooltip title={item.Name}>
                    <span>{item.Name?.substr(0, 50) + '...'}</span>
                  </Tooltip>
                ) : (
                  <>{item.Name}</>
                )}
              </>
            )
          }
          secondary={
            isMobile ? (
              cliTruncate(item.AvailabilityTypeName, 15, { position: 'middle' })
            ) : item.ApprovalRequired ? (
              <ApprovalRequiredContent>
                <Body>{item.AvailabilityTypeName}</Body>
                <Tips bold>{t(translations.ApprovalRequired) as string}</Tips>
              </ApprovalRequiredContent>
            ) : (
              item.AvailabilityTypeName
            )
          }
        />
        <ListItemSecondaryAction>
          <SecondaryContent>
            {item.BookableAsset && showCalendarLinks && (
              <ActionIconButton
                variant="ghost"
                icon="calendar-alt"
                size="xs"
                title={t(translations.Schedule)}
                href={getCalendarLink(item, EnableLegacyScheduler)}
                shape="square"
              />
            )}
          </SecondaryContent>
        </ListItemSecondaryAction>
      </ListItem>
    </Tooltip>
  );
}
