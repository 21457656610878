import { IconProp } from 'app/AllIcons';
import {
  selectAuthenticatedUser,
  selectSiteMapProvider,
} from 'app/slice/selectors';
import { Roles } from 'api/odata/generated/enums/Roles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AssetsSubTabUnionArray, MainTabsUnion } from 'types/SiteMapSettings';
import * as React from 'react';
import {
  RowActionsMenu,
  RowActionsProps,
} from 'app/components/BasicTable/RowActions';
import { translations } from 'locales/translations';

export interface AdminActionsProps {
  menuTab: MainTabsUnion;
  icon?: IconProp;
  id?: string;
  title?: string;
  action?: RowActionsProps;
  subTab?: any;
}
export function AdminActions(props: AdminActionsProps) {
  const { menuTab, icon, id, title, action, subTab } = props;
  const { t } = useTranslation();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const siteMapProvider = useSelector(selectSiteMapProvider);
  const admin =
    authenticatedUser !== undefined &&
    (authenticatedUser.Roles.includes(Roles.Administrators) ||
      authenticatedUser.Roles.includes(Roles.GroupAdministrators) ||
      authenticatedUser.Roles.includes(Roles.CustomRole));
  const adminSettings = React.useMemo(() => {
    let actions: RowActionsProps[] = [];
    if (admin) {
      if (menuTab === 'Assets' && !subTab) {
        AssetsSubTabUnionArray.forEach(ar => {
          actions = [
            ...actions,
            ...siteMapProvider.adminSettingsActions(
              menuTab,
              authenticatedUser,
              ar,
              undefined, // handleSettingClick,
              t,
            ),
          ];
        });
      } else {
        if (menuTab === 'Budgets' && !subTab) {
          return siteMapProvider.adminSettingsActions(
            menuTab,
            authenticatedUser,
            'BudgetTabSetting',
            undefined, // handleSettingClick,
            t,
          );
        }
        return siteMapProvider.adminSettingsActions(
          menuTab,
          authenticatedUser,
          subTab,
          undefined, // handleSettingClick,
          t,
        );
      }
    }
    if (!!action) {
      actions.push(action);
    }
    return actions;
  }, [admin, action, menuTab, subTab, siteMapProvider, authenticatedUser, t]);

  return adminSettings.length > 0 ? (
    <RowActionsMenu
      id={id ?? `myAssets_settings`}
      variant="textGray"
      items={adminSettings}
      tooltip={title ?? t(translations.AssetsRelatedSetting)}
      icon={icon ?? 'cog'}
    />
  ) : null;
}
