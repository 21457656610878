import * as React from 'react';
import { AddonTypesUnion } from 'api/odata/generated/enums/AddonTypes';
import { ServiceRequestMilestones } from '../ServiceRequestMilestones';
import { useDispatch, useSelector } from 'react-redux';
import { selectServiceRequestMilestones } from '../../RequestSamplesPage/slice/selectors';
import { ServiceRequestAddons } from '../ServiceRequestAddons';
import { assertExhaustive } from 'utils/assertExhaustive';
import { IServiceRequestMilestoneDto } from 'api/odata/generated/entities/IServiceRequestMilestoneDto';
import { IServiceRequestMilestoneChargeDto } from 'api/odata/generated/entities/IServiceRequestMilestoneChargeDto';
import { useRequestSamplesSlice } from '../../RequestSamplesPage/slice';
import { InternalServiceRequestStatusesUnion } from 'enums/InternalServiceRequestStatuses';
import { TableRowsSkeleton } from 'app/components/BasicTable/components/TableSkeleton';
import { Printing } from 'app/components/PrintToPDF';
import { Entity } from 'types/common';

export interface ServiceRequestMilestonesSectionProps {
  AddonType: AddonTypesUnion;
  ServiceRequestId: number;
  isAdmin: boolean;
  InternalStatus: InternalServiceRequestStatusesUnion;
  pageName?: string;
  tableContainerAsSection?: boolean;
  // indicates if this component is opened through side panel
  printing?: Printing;
  useSidePanel: boolean;
  UnitType?: Entity<number>;
  ServiceTypeId: number;
}

export function ServiceRequestMilestonesSection(
  props: ServiceRequestMilestonesSectionProps,
) {
  const dispatch = useDispatch();
  const { actions } = useRequestSamplesSlice();
  React.useEffect(() => {
    dispatch(actions.loadMilestones({ Id: props.ServiceRequestId }));
  }, [actions, dispatch, props.ServiceRequestId]);
  const milestones = useSelector(selectServiceRequestMilestones);
  const data = React.useMemo(() => {
    switch (props.AddonType) {
      case 'Milestones':
        return milestones;
      case 'Addons':
      case 'UserAddons':
        return milestones?.flatMap(f => f.ServiceRequestMilestoneCharges);
      case 'NA':
        return undefined;
      default:
        assertExhaustive(props.AddonType);
    }
  }, [milestones, props.AddonType]);

  const print = React.useMemo(() => {
    return props.printing !== undefined && props.printing.printing === true;
  }, [props.printing]);
  React.useEffect(() => {
    if (
      props.printing !== undefined &&
      print &&
      props.printing.onLoading !== undefined
    ) {
      props.printing.onLoading(props.printing.id, milestones === undefined);
    }
  }, [milestones, print, props.printing]);

  if (milestones === undefined) {
    return <TableRowsSkeleton isCards={false} isMobile={false} />;
  }

  switch (props.AddonType) {
    case 'Milestones':
      return (
        <ServiceRequestMilestones
          milestones={data as IServiceRequestMilestoneDto[]}
          AddonType={props.AddonType}
          InternalStatus={props.InternalStatus}
          isAdmin={props.isAdmin}
          ServiceRequestId={props.ServiceRequestId}
          pageName={props.pageName}
          tableContainerAsSection={props.tableContainerAsSection}
          printing={props.printing}
          useSidePanel={props.useSidePanel}
          UnitType={props.UnitType}
          ServiceTypeId={props.ServiceTypeId}
        />
      );
    case 'Addons':
    case 'UserAddons':
      return (
        <ServiceRequestAddons
          isAdmin={props.isAdmin}
          milestone={milestones?.[0]}
          AddonType={props.AddonType}
          InternalStatus={props.InternalStatus}
          charges={data as IServiceRequestMilestoneChargeDto[]}
          pageName={props.pageName}
          tableContainerAsSection={props.tableContainerAsSection}
          printing={props.printing}
          useSidePanel={props.useSidePanel}
          UnitType={props.UnitType}
        />
      );
    case 'NA':
      return <>Milestones are disabled for this service</>;
    default:
      assertExhaustive(props.AddonType);
  }
}
