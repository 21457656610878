import { httpClient } from 'api/HttpClient';
import { Link } from 'app/components/ExternalLink';
import { FormFieldsSection, FormRow } from 'app/components/Forms/FormsLayout';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { useAsyncGetWithEffect } from 'app/hooks/useAsyncGetOnMount';
import { GetServiceRequestDetailsPath } from 'app/pages/Samples/RequestSamplesPage/slice/path';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RequestReservations } from '../../slice/types';

export interface ReservationRequestDetailsProps {
  reservationId: number;
}

export function ReservationRequestDetails(
  props: ReservationRequestDetailsProps,
) {
  const { reservationId } = props;
  const { t } = useTranslation();
  const url = `api/odata/v4/Reservations/ReservationRequestData`;
  const relatedReservation = useAsyncGetWithEffect<
    RequestReservations | undefined
  >(
    async () => httpClient.post(url, { id: reservationId }).then(res => res),
    undefined,
    [reservationId],
  );
  return !!relatedReservation ? (
    <FormFieldsSection
      titleSection={t(translations.AssociatedServiceRequestDetails) as string}
    >
      <FormRow>
        <RequestWrapper>
          {relatedReservation.RequestId !== null &&
            relatedReservation.RequestId !== undefined && (
              <div className="req-row">
                <BasicTypography variant="bodyS" component="span">
                  {t(translations.ServiceRequestId)}
                </BasicTypography>
                <Link
                  href={GetServiceRequestDetailsPath(
                    relatedReservation.RequestId,
                  )}
                  target="_blank"
                >
                  {`# ${relatedReservation.RequestId}`}
                </Link>
              </div>
            )}

          {!!relatedReservation.TotalRowsCount &&
            relatedReservation.TotalRowsCount !== null && (
              <div className="req-row">
                <BasicTypography variant="bodyS" component="span">
                  {t(translations.TotalSamples)}
                </BasicTypography>
                <BasicTypography variant="boldS" component="span">
                  {relatedReservation.TotalRowsCount}
                </BasicTypography>
              </div>
            )}
          {!!relatedReservation.AssociatedRowsCount &&
            relatedReservation.AssociatedRowsCount !== null && (
              <div className="req-row">
                <BasicTypography variant="bodyS" component="span">
                  {t(translations.SamplesAssociatedWithThisReservation)}
                </BasicTypography>
                <BasicTypography variant="boldS" component="span">
                  {relatedReservation.AssociatedRowsCount}
                </BasicTypography>
              </div>
            )}
        </RequestWrapper>
      </FormRow>
    </FormFieldsSection>
  ) : null;
}

const RequestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  & .req-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: inherit;
  }
`;
