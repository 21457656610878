/**
 *
 * ServiceGroupsFilter
 *
 */
import { ServiceGroupsPicker } from 'app/components/pickers/MultiSelectPickers/ServiceGroupsPicker';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '..';

export const ServiceGroupsFilter = (props: IFilterComponentProps) => {
  const handleChange = (value: Entity<number>[] | undefined) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <ServiceGroupsPicker
      value={props.value ?? []}
      placeholder={t(translations.AllValues)}
      isOpen={props.open}
      onChange={handleChange}
      ariaLabel={props.label}
      id={props.id}
      fullWidth={props.fullWidth}
      predicates={props.predicates}
      size="small"
      onPickerOpen={props.onOpen}
    />
  );
};
