import { Comments } from 'app/components/Comments';
import { useUploadToNewComment } from 'app/components/LargeFilesUpload/Components/useUploadToNewComment';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { useTheme } from '@material-ui/core';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { FormFieldsSection } from 'app/components/Forms/FormsLayout';
import { Identifiable } from 'types/common';
export interface AssetCommentsprops {
  assetId: number;
  isAdmin: boolean;
  staffonly: boolean;
  serviceGroups?: Identifiable<number>[];
}
export const FloatingComments = styled('div')(({ theme }) => ({
  '&.floatingComments': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    height: 'auto',
    maxHeight: '540px',
    zIndex: 1070, //zIndex: 1500,
    padding: '16px 8px',
    //bottom: 'unset',
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    left: 0,
    background: bookitColors.grayscale.grayBackground,
    //borderRadius: 8,
    boxShadow: '0px 2px 18px rgba(8, 24, 41, 0.16)',
  },
}));
export const AssetComments = ({
  assetId,
  isAdmin,
  staffonly,
  serviceGroups,
}: AssetCommentsprops) => {
  const { onCommentAdded } = useUploadToNewComment();
  return (
    <Comments
      commentType={CommentReferenceTypes.Assets}
      referenceId={assetId}
      isAdmin={isAdmin}
      defaultComment={{ IsInternal: staffonly }}
      noTitle
      onCommentAdded={onCommentAdded}
      serviceGroups={serviceGroups}
    />
  );
};
export const AssetMobileComments = ({
  assetId,
  isAdmin,
  staffonly,
}: AssetCommentsprops) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { onCommentAdded } = useUploadToNewComment();
  return (
    <FloatingComments theme={theme} className={'floatingComments'}>
      <FormFieldsSection
        titleSection={t(translations.CommentsAndAttachments) as string}
      >
        <Comments
          commentType={CommentReferenceTypes.Assets}
          referenceId={assetId}
          isAdmin={isAdmin}
          defaultComment={{ IsInternal: staffonly }}
          pageName={t(translations.Instrument)}
          noTitle
          onCommentAdded={onCommentAdded}
        />
      </FormFieldsSection>
    </FloatingComments>
  );
};
