/**
 *
 * SideBar
 *
 */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectSiteMapProvider } from 'app/slice/selectors';
import { SideBarMenu } from './SideBarMenu';
import { SideBarHeader } from './SideBarHeader';
import { isNullOrUndefined } from 'utils/typeUtils';
import { SideBarFooter } from './SideBarFooter';
import { SideBarQuickAction } from './SideBarQuickAction';

interface SideBarComponentProps {
  isOpen: boolean;
  isMobile?: boolean;
  toggleMenu?: () => void;
}

const SideBarComponent = (props: SideBarComponentProps) => {
  const { isOpen, isMobile, toggleMenu } = props;
  const siteMapProvider = useSelector(selectSiteMapProvider);
  const loading = isNullOrUndefined(siteMapProvider);
  return (
    <React.Fragment>
      <SideBarHeader isOpen={isOpen} />
      <SideBarQuickAction isOpen={isOpen} isMobile={isMobile} />
      <SideBarMenu
        isOpen={isOpen}
        loading={loading}
        isMobile={isMobile}
        toggleMenu={toggleMenu}
      />
      {isMobile && <SideBarFooter />}
    </React.Fragment>
  );
};
export const SideBar = React.memo(SideBarComponent);
