import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProps } from 'formik';
import {
  CustomFormValueType,
  getCustomFormDisplayValue,
  getFieldsVisibilities,
  getSerializableIsRequired,
  isRequired,
  SerializeValue,
} from 'app/components/CustomForm/CustomFormUtils';
import { useRequestSamplesSlice } from '../../slice';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getLogger } from 'utils/logLevel';
import { IServiceRequestDetailsFormModel } from '../../slice/types';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { selectServiceRequestCustomFormFields } from '../../slice/selectors';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import { CustomFormField } from 'app/components/CustomForm/CustomFormFields';
import { sortBy } from 'lodash';
import { ICustomFormDto } from 'api/odata/generated/entities/ICustomFormDto';
import { Div100 } from 'app/components/AssetQuickSearch/styled';
import { ReadonlyField } from 'app/pages/Samples/components/ReadonlyField';
import { FormFieldsPerRowVariant } from 'enums/FormFieldsPerRowVariant';

export interface ServiceRequestCustomFormProps
  extends FormikProps<IServiceRequestDetailsFormModel> {
  serviceRequestId?: number;
  customForm: ICustomFormDto;
  isAdmin: boolean;
  disabled: boolean;
  visibleOnCompletion?: () => boolean;
  allowUserUpload?: boolean;
  print?: boolean;
}
const log = getLogger('ServiceRequestCustomForm');
export const ServiceRequestCustomForm = React.memo(
  function ServiceRequestCustomForm({
    isAdmin,
    disabled,
    visibleOnCompletion,
    allowUserUpload,
    ...props
  }: ServiceRequestCustomFormProps) {
    const dispatch = useDispatch();
    const { actions } = useRequestSamplesSlice();
    const formFields = useSelector(selectServiceRequestCustomFormFields);
    const handleChange = React.useCallback(
      (value, formField: IFormFieldDto) => {
        const serviceRequestId = props.serviceRequestId;
        if (serviceRequestId === undefined) {
          log.info('handleSubmit - serviceRequestId is empty');
          return;
        }
        dispatch(
          actions.saveFormValues({
            RequestId: serviceRequestId,
            FormFieldId: formField.Id,
            Value:
              formField.Type === 'File'
                ? value
                : SerializeValue(formField, value?.target?.value ?? value),
            DisplayValue: null,
            Type: formField.Type,
          }),
        );
      },
      [actions, dispatch, props.serviceRequestId],
    );
    const isVisibleField = (
      field: IFormFieldDto,
      isAdmin: boolean,
      values: Record<string, any> | undefined,
      visibleOnCompletion?: () => boolean,
    ): boolean => {
      let visibilities = getFieldsVisibilities(
        formFields,
        isAdmin,
        values,
        visibleOnCompletion,
      );
      if (visibilities) {
        return visibilities[field.Id];
      }
      return true;
    };
    const sortedActiveFormFields = React.useMemo(() => {
      let values = props.values.FormValues as Record<string, any> | undefined;
      return sortBy(
        formFields.filter(f => f.Active === true),
        f => f.Index,
      ).map(f => {
        let parentDepValue = null;
        let parentReqValue = null;

        if (f.DependencyFieldId !== null) {
          if (!!values && Object.keys(values).length > 0) {
            parentDepValue = values[String(f.DependencyFieldId)];
          }
        }
        if (f.RequiredFieldToCompare !== null) {
          if (!!values && Object.keys(values).length > 0) {
            parentReqValue = values[String(f.RequiredFieldToCompare)];
          }
        }
        return {
          fieldRequired:
            isRequired(
              f,
              isAdmin || false,
              props?.values?.FormValues?.[f.Id],
              parentDepValue,
              parentReqValue,
            ) &&
            getSerializableIsRequired(
              f,
              props?.values?.FormValues?.[f.Id] as CustomFormValueType,
            ),
          field: f,
        };
      });
    }, [formFields, isAdmin, props?.values?.FormValues]);

    return props.values.FormValues === undefined ? (
      <Div100>
        <LinearProgress variant="query" color="primary" />
      </Div100>
    ) : (
      <StyledFormFieldsContainer>
        <FormLeftSection
          className={
            props.customForm.FieldsPerRow ===
            FormFieldsPerRowVariant.ThreeFields
              ? 'custom-section'
              : undefined
          }
        >
          <FormFieldsSection
            titleSection={props.customForm.Name}
            useRequest={true}
            useCustomFormSection={
              props.customForm.FieldsPerRow ===
              FormFieldsPerRowVariant.ThreeFields
            }
          >
            {sortedActiveFormFields.map((formField, index, arr) => {
              // paragraphs & form sections deserve to start from the new line in 2 column layout (full screen)
              const fullRow =
                (formField.field.Type !== null &&
                  ['Paragraph', 'FormSection'].includes(
                    formField.field.Type,
                  )) ||
                formField.field.SingleColumnView ||
                props.customForm.FieldsPerRow ===
                  FormFieldsPerRowVariant.OneField;
              const name = `FormValues.${String(formField.field.Id)}`;
              return (
                <React.Fragment>
                  <FormRow
                    key={formField.field.Id}
                    hide={
                      isVisibleField(
                        formField.field,
                        isAdmin || false,
                        props.values.FormValues,
                        visibleOnCompletion,
                      ) === false
                    }
                    fullRow={fullRow}
                  >
                    {props.print ? (
                      <ReadonlyField
                        title={formField.field.Label}
                        value={getCustomFormDisplayValue(
                          formField.field.Type === 'Paragraph'
                            ? formField.field.Description ?? ''
                            : (props?.values?.FormValues?.[
                                formField.field.Id
                              ] as CustomFormValueType),
                        )}
                      />
                    ) : (
                      <CustomFormField
                        name={name}
                        id={name}
                        key={index}
                        formField={formField.field}
                        //autoFocus={formField.fieldRequired}
                        required={formField.fieldRequired}
                        readonly={
                          disabled ||
                          (formField.field.Type === 'File' && !allowUserUpload)
                        }
                        disabled={
                          disabled ||
                          props.isSubmitting ||
                          (formField.field.Type === 'File' && !allowUserUpload)
                        }
                        onChange={value => handleChange(value, formField.field)}
                      />
                    )}
                  </FormRow>
                </React.Fragment>
              );
            })}
          </FormFieldsSection>
        </FormLeftSection>
        {/* <FormRightSection>{` `}</FormRightSection> */}
      </StyledFormFieldsContainer>
    );
  },
);
