import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { selectHasNotSavedChanges } from 'app/Layout/FrontendLayout/slice/selectors';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import { push } from 'connected-react-router';
import { FormikProps } from 'formik';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Beforeunload } from 'react-beforeunload';
import { useOfflineServiceStateSlice } from '../Details/slice';
import {
  selectIsEdit,
  selectRenewStockCompleted,
  selectRenewStockData,
  selectRenewStockHasErrors,
  selectRenewStockProcessing,
} from '../Details/slice/selectors';
import { RenewStockParams, RenewStockState } from '../Details/slice/types';
import useGlobalSettingsHook from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { Progress } from 'app/components/LoadingIndicator';
import { buildURL, toQueryString } from 'utils/url-utils';
import { RenewStockForm } from './RenewStockForm';
import { Roles } from 'api/odata/generated/enums/Roles';
import useSidePanelState, {
  SidePanelCloseState,
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { Div100 } from 'app/components/AssetQuickSearch/styled';

export interface RenewStockProps extends CoverProps, SidePanelContentProps {
  queryParams: RenewStockParams;
  initialService?: IOtherServices;
  editCreatable?: boolean;
  saveCreatable?: boolean;
  preventRefreshTable?: boolean;
  onClose?: () => void;
}
export const RenewStock = React.memo(function RenewStock(
  props: RenewStockProps,
) {
  const {
    useSidePanel,
    queryParams,
    initialService,
    editCreatable,
    preventRefreshTable,
    saveCreatable,
    isCover,
    onClose,
    closeCover,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useOfflineServiceStateSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const processing = useSelector(selectRenewStockProcessing);
  const actionCompleted = useSelector(selectRenewStockCompleted);
  const User = useSelector(selectAuthenticatedUser);
  const hasChanges = useSelector(selectHasNotSavedChanges);
  const hasError = useSelector(selectRenewStockHasErrors);
  const renewStockData = useSelector(selectRenewStockData);
  const publicUrl = useSelector(selectPublicUrl);
  const isEdit = useSelector(selectIsEdit);
  //   const { checkIsMobile } = useIsMobile();
  //   const mobile = checkIsMobile();
  const globalSettings = useGlobalSettingsHook();
  //Local State
  // const [cover, setCover] = React.useState<React.ReactNode>();
  const { cover, openPanel, closePanel, coverClosed, onCloseCover } =
    useSidePanelState(
      () => {
        dispatch(actions.resetRenewStockState());
        if (!!onClose) {
          return false;
        }
        return true;
      },
      undefined,
      useSidePanel,
      isCover,
    );
  const [busy, setBusy] = React.useState<boolean | undefined>(false);
  const [firstLoad, setFirstLoad] = React.useState<boolean | undefined>(true);
  const [requestCompleted, setRequestCompleted] = React.useState<
    boolean | undefined
  >(undefined);

  //Refs
  const submitFormRef = React.useRef<any>(null);
  const innerFormRef = React.useRef<FormikProps<RenewStockState>>(null);

  const isReadOnlyUser = React.useMemo(() => {
    return (
      User !== undefined &&
      (User.Roles.includes(Roles.CalendarOnly) ||
        User.Roles.includes(Roles.Readonly) ||
        User.Roles.includes(Roles.RoomOnly))
    );
  }, [User]);

  //Methods
  // const closeCover = () => {
  //   setCover(undefined);
  // };
  // const handleClose = () => {
  //   if (hasChanges) {
  //     dispatch(layoutActions.setConfirmOpen(true));
  //   } else {
  //     handleCloselClick();
  //   }
  // };
  const handleCloselClick = React.useCallback(() => {
    if (useSidePanel) {
      if (!!onClose) {
        onClose();
        dispatch(actions.resetRenewStockState());
      } else {
        closePanel({
          isCover: isCover,
          useSidePanel: useSidePanel,
          showConfirm: hasChanges,
          onClose: () => {
            dispatch(actions.resetRenewStockState());
          },
        } as SidePanelCloseState);
      }
    } else {
      dispatch(push('/otherServices'));
    }

    // if (!!onClose) {
    //   onClose();
    // } else {
    //   if (useSidePanel) {
    //     dispatch(layoutActions.resetSidePanel());
    //   } else {
    //     dispatch(push('/otherServices'));
    //   }
    // }
    // dispatch(actions.resetRenewStockState());
  }, [
    actions,
    closePanel,
    dispatch,
    hasChanges,
    isCover,
    onClose,
    useSidePanel,
  ]);
  const handleSaveClick = React.useCallback((e: any) => {
    handleSubmitForm(e);
  }, []);

  const handleSubmitForm = (e: any) => {
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmit = React.useCallback(
    (values: RenewStockState) => {
      setBusy(true);
      if (isEdit) {
        dispatch(
          actions.updateRenewStock({
            current: values,
            original: renewStockData || values,
            editCreatable: editCreatable ?? false,
            autoGenerateBatch: initialService?.AutoGenerateNewBatches,
          }),
        );
      } else {
        dispatch(
          actions.createRenewStock({
            model: values,
            editCreatable: editCreatable ?? false,
            preventRefreshTable: preventRefreshTable,
            autoGenerateBatch: initialService?.AutoGenerateNewBatches,
            saveCreatable: saveCreatable,
          }),
        );
      }
    },
    [
      actions,
      dispatch,
      editCreatable,
      initialService?.AutoGenerateNewBatches,
      isEdit,
      preventRefreshTable,
      renewStockData,
      saveCreatable,
    ],
  );

  React.useEffect(() => {
    let active = firstLoad;
    if (globalSettings.loadCompleted && active) {
      setFirstLoad(false);
      dispatch(
        actions.initRenewStock({
          query: queryParams,
          service: initialService,
        }),
      );
    }
    return () => {
      active = false;
    };
  }, [
    actions,
    dispatch,
    firstLoad,
    globalSettings,
    initialService,
    queryParams,
  ]);
  React.useEffect(() => {
    let active = processing === false;
    if (active) {
      if (actionCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
        if (!hasError) {
          dispatch(layoutActions.setNotSavedChanges(false));
          handleCloselClick();
        }
      } else if (actionCompleted === false) {
        setRequestCompleted(undefined);
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [
    actionCompleted,
    dispatch,
    handleCloselClick,
    hasError,
    layoutActions,
    processing,
  ]);
  const onBatchClick = React.useCallback(
    (batch: IInventoryBatchDto | null, serviceType: number) => {
      let batchProps = {
        initialBatch:
          batch?.OfflineServiceTypeId === undefined ? undefined : batch,
        queryParams: {
          id: '' + (batch?.Id ?? -1),
          stid: '' + serviceType,
        },
        saveCreatable: true,
        useSidePanel: true,
      };
      openPanel({
        renderPageType: RenderPageType.InventoryBatch,
        renderPageProps: batchProps,
        expanded: false,
        useSidePanel: useSidePanel,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [openPanel, useSidePanel],
  );
  // React.useEffect(() => {
  //   if (confirmApproved) {
  //     dispatch(actions.resetRenewStockState());
  //     if (continueToOther.continueOnApprove) {
  //       dispatch(layoutActions.resetSidePanel());
  //       setTimeout(() => {
  //         dispatch(
  //           layoutActions.openSidePanel({
  //             type: continueToOther.pageType,
  //             props: continueToOther.pageProps,
  //             expanded: continueToOther.expanded,
  //           }),
  //         );
  //         dispatch(layoutActions.resetContinueState());
  //       }, 200);
  //     } else if (continueLink !== undefined) {
  //       dispatch(appSettingsActions.navigate(continueLink));
  //       dispatch(layoutActions.resetSidePanel());
  //     } else {
  //       dispatch(layoutActions.resetSidePanel());
  //     }
  //     //dispatch(actions.setChangedState(false));
  //   }
  //   if (confirmRejected) {
  //     dispatch(layoutActions.resetConfirmState());
  //   }

  //   return () => undefined;
  // }, [
  //   actions,
  //   appSettingsActions,
  //   confirmApproved,
  //   confirmRejected,
  //   continueLink,
  //   continueToOther,
  //   dispatch,
  //   layoutActions,
  // ]);

  //memo
  const IsAdmin = React.useMemo(() => {
    return !!innerFormRef.current &&
      innerFormRef.current.values.ServiceType !== null
      ? User?.IsAllGroupOrLabTechAdmin(
          [innerFormRef.current.values.ServiceType.ServiceGroupId],
          [
            {
              Id: innerFormRef.current.values.ServiceType.Id,
              Name: innerFormRef.current.values.ServiceType.Name,
              ServiceTypeId: ServiceType.Offline,
            } as IServiceTypeFilterDto,
          ],
        )
      : false;
  }, [User]);
  const rightActions = React.useMemo(() => {
    let actions: ActionRenderer[] = [];
    actions.push(() => (
      <Button
        size="small"
        startIcon={<Icon icon="save" />}
        onClick={handleSaveClick}
        disabled={
          busy ||
          !(
            !!innerFormRef.current &&
            (innerFormRef.current.values.ServiceType ?? null) !== null
          )
        }
        processing={!requestCompleted && busy}
      >
        {t(translations.Save)}
      </Button>
    ));
    actions.push(() => (
      <Button
        variant="ghost"
        size="small"
        startIcon={<Icon icon="times" />}
        onClick={handleCloselClick}
      >
        {t(translations.Close)}
      </Button>
    ));
    return actions;
  }, [busy, handleCloselClick, handleSaveClick, requestCompleted, t]);

  return (
    <React.Fragment>
      <PageWrapper
        pageName={t(translations.RenewStockOfConsumables)}
        titlePage={
          renewStockData
            ? isEdit
              ? ` ${t(translations.RenewStock)} #${queryParams.id ?? 'N/A'}`
              : ` ${t(translations.RenewStock)}`
            : ` ${t(translations.RenewStock)}`
        }
        loading={processing}
        useSidePanel={useSidePanel}
        closable={useSidePanel || isCover}
        disableExpandToggle={true}
        closeSidePanel={handleCloselClick}
        // leftTopActions={topButtons}
        //rightTopActions={topButtons}
        rightTopActionsAsMenu={true}
        //leftTopActions={topActions}
        //topProcessing={equipmentChangeCompleted === false}
        leftActions={isReadOnlyUser ? [] : rightActions}
        //rightActions={rightActions}
        children={
          renewStockData !== undefined &&
          !processing &&
          globalSettings.loadCompleted ? (
            <RenewStockForm
              initialValues={renewStockData || ({} as RenewStockState)}
              bindSubmitForm={bindSubmitForm}
              onSubmit={handleSubmit}
              isAdmin={IsAdmin || false}
              user={User}
              globalSettings={globalSettings}
              innerFormRef={innerFormRef}
              editCreatable={editCreatable}
              isEdit={isEdit ?? false}
              onBatchClick={onBatchClick}
            />
          ) : (
            <React.Fragment>
              {' '}
              <Div100>
                <Progress inProgress={processing || false} size={80} />
              </Div100>
            </React.Fragment>
          )
        }
        pageLink={buildURL(publicUrl + '/renwestock', queryParams)}
        toPageLink={`renwestock?${toQueryString(queryParams)}`}
        isCover={isCover}
        cover={cover}
        closeCover={!isCover ? onCloseCover : closeCover}
        coverClosed={coverClosed}
      />
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </React.Fragment>
  );
});
