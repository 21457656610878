import { CSSProperties } from '@material-ui/styles';
import { bookitColors } from 'styles/colors/bookitColors';

export const verticalScrolling: CSSProperties = {
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    boxShadow: `inset 0 0 2px ${bookitColors.grayscale.grayBorders}`,
    borderRadius: '6px',
    backgroundColor: bookitColors.grayscale.grayBorders,
  },
};
export const cssVariables = {
  verticalScrolling: verticalScrolling,
};
