import { useAppSettingsSlice } from 'app/slice';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IResponseType } from 'types/ResponseType';

export const useResponseModel = () => {
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const res = useCallback(
    (result: IResponseType) => {
      if (result.ErrorMessages.length > 0) {
        dispatch(
          actions.addNotifications(
            result.ErrorMessages.map(e => {
              return { variant: 'error', message: e };
            }),
          ),
        );
      }
      if (result.WarningMessages.length > 0) {
        dispatch(
          actions.addNotifications(
            result.WarningMessages.map(e => {
              return { variant: 'warning', message: e };
            }),
          ),
        );
      }
      if (result.SuccessMessages.length > 0) {
        dispatch(
          actions.addNotifications(
            result.SuccessMessages.map(e => {
              return { variant: 'success', message: e };
            }),
          ),
        );
      }
    },
    [dispatch, actions],
  );
  return res;
};

export const responseFailed = (res: IResponseType) => {
  return res.ErrorMessages.length > 0;
};
