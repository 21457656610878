import {
  FormControl,
  FormHelperText,
  FormLabel,
  styled,
} from '@material-ui/core';
import { RadioGroup, RadioGroupProps } from 'app/components/RadioGroup';
import * as React from 'react';
import { buttonM } from 'styles/typography/bookitTypography';

export interface RadioGroupsControlProps extends RadioGroupProps {}
export const RadioGroupsControl = ({
  label,
  id,
  onChange,
  value,
  name,
  error,
  ...props
}: RadioGroupsControlProps) => {
  return (
    <StyledFormControl>
      {label && <StyledFormLabel>{label}</StyledFormLabel>}
      <RadioGroup
        id={id}
        name={name}
        label={label}
        onChange={onChange}
        value={value}
        disabled={props.disabled}
        {...props}
      />
      <StyledFormHelperText>
        <span>{''}</span>
      </StyledFormHelperText>
    </StyledFormControl>
  );
};

// spacing is needed in the quizzes
const StyledFormControl = styled(FormControl)(props => ({
  marginBottom: props.theme.spacing(0),
}));

// the label needs to be stronger in quizzes
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: '16px',
  color: theme.palette.common.blackRegular,
  ...buttonM,
  '.Mui-focused &': {
    color: theme.palette.common.blackRegular,
  },
}));
const StyledFormHelperText = styled(FormHelperText)(props => ({
  // empty helperText results in zero element height. So when field is validated the appearance of the
  // error message in the helperText causes the form element to increase in height and forces a redraw
  // empty char \00a0 causes the FormHelperText to always occupy one line height and prevents reflows
  '&::before': {
    content: '"\\00a0"',
  },
}));
