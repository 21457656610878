import { httpClient } from './HttpClient';

export const TermsOfUseAgreementApi = {
  getTermsOfUseAgreement: () => {
    const url = '/api/odata/v4/TermsOfUseAgreement';
    return httpClient.get(url);
  },
  agreeTermsOfUseAgreement: (versionId: number) => {
    const url = '/api/odata/v4/TermsOfUseAgreement/AcceptAgreement';
    const data = { versionId: versionId };
    return httpClient.post(url, data);
  },
};
