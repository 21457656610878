import { dateUtils } from 'utils/date-utils';
import { IViewState, ViewLengths, ViewModes, ViewTypes } from '../slice/types';
import { Timestamp } from 'types/Timestamp';
import { assertExhaustive } from 'utils/assertExhaustive';
import {
  getPeriodTypesEntity,
  getPeriodTypesEntityById,
  PeriodTypes,
  PeriodTypesEntity,
} from 'app/components/DatePeriods';
import { DatesRange } from 'types/DatesRange';
import {
  SchedulerDatePositionArray,
  SchedulerDatePositionUnion,
} from 'app/components/BasicTable/Filters/DatesPeriodFilter';
import { serializeSchedulerDate } from './formatSchedulerDateOrUndefined';
import { RestrictDurationUnitTypes } from 'enums/RestrictDurationUnitTypes';
import { isSameMonth, isSameYear } from 'date-fns';
import { PresetDatesRange } from 'types/PresetDatesRange';
import { IEquipmentWorkingHoursDto } from 'api/odata/generated/entities/IEquipmentWorkingHoursDto';
import { orderBy } from 'lodash';
import { fromTimZoneToUTC } from 'utils/date-utils-tz';
import { ITimeZone } from 'types/AppSettings';

/**
 * Returns view length (day/week/etc.) for a given view mode
 * @param viewMode scheduler viewMode
 * @returns view length
 */
export function getViewLength(viewMode: ViewModes) {
  return viewMode.split('_')[0] as ViewLengths;
}

/**
 * Returns the view type part of the viewmode e.g. week_timeline -> timeline
 * @param viewMode scheduler viewMode
 * @returns view type
 */
export function getViewType(viewMode: ViewModes) {
  const parts = viewMode.split('_');
  if (parts.length === 1) {
    return 'calendar';
  } else if (parts[1] === 'unit') {
    return 'calendar';
  } else {
    return parts[1] as ViewTypes;
  }
}

/**
 * Returns parsed viewMode
 * @param viewMode scheduler viewmode
 * @returns
 */
export function parseViewMode(viewMode: ViewModes) {
  return {
    viewType: getViewType(viewMode),
    viewLength: getViewLength(viewMode),
  };
}
export function getNextViewLength(
  length: ViewLengths,
  customDaysEnabled?: boolean,
  twoDaysEnabled?: boolean,
): ViewLengths {
  switch (length) {
    case 'day':
      return twoDaysEnabled
        ? 'twodays'
        : customDaysEnabled
        ? 'customdays'
        : 'week';
    case 'twodays':
      return customDaysEnabled ? 'customdays' : 'week';
    case 'customdays':
      return 'week';
    case 'week':
      return 'month';
    case 'month':
      return 'quarter';
    case 'quarter':
      return 'year';
    default:
      return length;
  }
}
export function getPrevViewLength(
  length: ViewLengths,
  customDaysEnabled?: boolean,
  twoDaysEnabled?: boolean,
): ViewLengths {
  switch (length) {
    case 'twodays':
      return 'day';
    case 'customdays':
      return twoDaysEnabled ? 'twodays' : 'day';
    case 'week':
      return customDaysEnabled
        ? 'customdays'
        : twoDaysEnabled
        ? 'twodays'
        : 'day';
    case 'month':
      return 'week';
    case 'quarter':
      return 'month';
    case 'year':
      return 'quarter';
    default:
      return length;
  }
}
/**
 * Combines view mode and view type into scheduler view mode e.g. 'weekly' & 'calendar' -> 'weekly'
 * @param param0 view mode and view type
 * @returns
 */
export const getSchedulerViewMode = (
  viewType: 'calendar' | 'timeline',
  viewlength: ViewLengths,
  isMobile: boolean,
): ViewModes => {
  switch (viewType) {
    case 'calendar':
      switch (viewlength) {
        case 'day':
        case 'twodays':
        case 'customdays':
          return isMobile ? 'day' : 'day_unit';
        case 'week':
          return 'week';
        case 'month':
          return 'month';
        case 'quarter':
          return 'quarter';
        case 'year':
          return 'year';
        case 'customPeriod':
          return 'day_unit';
        default:
          assertExhaustive(viewlength);
      }
      break;
    case 'timeline':
      return `${viewlength}_${viewType}`;
    default:
      assertExhaustive(viewType);
  }
};
/**
 * Gets the schedulr view type
 * @param viewType view type
 * @param viewlength view length
 * @param isMobile mobile
 * @returns
 */
export const getSchedulerViewType = (
  viewType: 'calendar' | 'timeline',
  viewlength: ViewLengths,
  isMobile: boolean,
): 'calendar' | 'unit' | 'timeline' => {
  switch (viewType) {
    case 'calendar':
      switch (viewlength) {
        case 'day':
        case 'twodays':
        case 'customdays':
          return isMobile ? 'calendar' : 'unit';
        case 'week':
        case 'month':
          return viewType;
        case 'quarter':
        case 'year':
          return 'timeline';
        case 'customPeriod':
          return viewType === 'calendar' ? 'unit' : 'timeline';
        default:
          assertExhaustive(viewlength);
      }
      break;
    case 'timeline':
      return viewType;
    default:
      assertExhaustive(viewType);
  }
};
/**
 * Gets the start of the displayed period
 * @param viewLength view length
 * @param datets date
 * @returns start of the period
 */
export const getViewStartDate = (
  viewLength: ViewLengths,
  datets?: Timestamp,
  customStart?: boolean,
) => {
  const date = datets === undefined ? undefined : dateUtils.parseISO(datets);
  if (date === undefined) {
    return undefined;
  }

  switch (viewLength) {
    case 'day':
    case 'twodays':
    case 'customdays':
      return dateUtils.startOfDay(date);
    case 'week':
      return customStart
        ? dateUtils.startOfDay(date)
        : dateUtils.startOfWeek(date);
    case 'month':
      return customStart
        ? dateUtils.startOfDay(date)
        : dateUtils.startOfMonth(date);
    case 'quarter':
      return customStart
        ? dateUtils.startOfDay(date)
        : dateUtils.startOfQuarter(date);
    case 'year':
      return customStart
        ? dateUtils.startOfDay(date)
        : dateUtils.startOfYear(date);
  }
};
/**
 * Gets the view end date
 * @param viewLength
 * @param datets
 * @returns
 */
export const getViewEndDate = (
  viewLength: ViewLengths,
  datets?: Timestamp,
  days?: number,
  customStart?: boolean,
) => {
  const date = datets === undefined ? undefined : dateUtils.parseISO(datets);
  if (date === undefined) {
    return undefined;
  }
  switch (viewLength) {
    case 'day':
      return dateUtils.endOfDay(date);
    case 'twodays':
      return dateUtils.addDays(dateUtils.endOfDay(date), 1);
    case 'customdays':
      return dateUtils.addDays(dateUtils.endOfDay(date), !!days ? days - 1 : 2);
    case 'week':
      return customStart
        ? dateUtils.addDays(dateUtils.startOfDay(date), 6)
        : dateUtils.endOfWeek(date);
    case 'month':
      return customStart
        ? dateUtils.addMonths(date, 1)
        : dateUtils.endOfMonth(date);
    case 'quarter':
      return customStart
        ? dateUtils.addQuarters(date, 1)
        : dateUtils.endOfQuarter(date);
    case 'year':
      return customStart
        ? dateUtils.addYears(date, 1)
        : dateUtils.endOfYear(date);
  }
};
/**
 * Returns a display label for the dates period display
 * @param viewLength view length
 * @param date view date
 * @returns display label
 */
export const getPeriodDisplayLabel = (
  viewLength: ViewLengths,
  date: Timestamp,
  compact: boolean,
  customDays?: number,
  customStart?: boolean,
) => {
  const start = getViewStartDate(viewLength, date, customStart);
  const end = getViewEndDate(viewLength, date, customDays, customStart);
  if (start === undefined || end === undefined) {
    return undefined;
  }
  switch (viewLength) {
    case 'year':
      return `${dateUtils.format(start, 'yyyy')}`;
    case 'quarter':
      if (isSameYear(start, end)) {
        return `${dateUtils.format(start, 'MMM')} - ${dateUtils.format(
          end,
          'MMM yyyy',
        )}`;
      } else {
        return `${dateUtils.format(start, 'MMM yyyy')} - ${dateUtils.format(
          end,
          'MMM yyyy',
        )}`;
      }
    case 'month':
      if (isSameYear(start, end)) {
        if (isSameMonth(start, end)) {
          return dateUtils.format(date, 'MMM');
        } else {
          return `${dateUtils.format(start, 'MMM dd')} - ${dateUtils.format(
            end,
            'MMM dd yyyy',
          )}`;
        }
      } else {
        return `${dateUtils.format(start, 'MMM dd yyyy')} - ${dateUtils.format(
          end,
          'MMM dd yyyy',
        )}`;
      }
    case 'day':
      return dateUtils.format(date, compact ? 'MMM dd' : 'MMM dd, yyyy');
    case 'week':
      if (isSameMonth(start, end)) {
        return `${dateUtils.format(start, 'MMM dd')} - ${dateUtils.format(
          end,
          'dd',
        )}`;
      } else {
        return `${dateUtils.format(start, 'MMM dd')} - ${dateUtils.format(
          end,
          'MMM dd',
        )}`;
      }
    case 'twodays':
    case 'customdays':
    case 'customPeriod':
      return `${dateUtils.format(
        start,
        compact ? 'MMM dd' : 'MMM dd, yyyy',
      )} - ${dateUtils.format(end, compact ? 'dd' : 'MMM dd')}`;
    default:
      assertExhaustive(viewLength);
  }
};

export const periodTypeToLengthType = (
  periodType: PeriodTypes,
): ViewLengths | undefined => {
  switch (periodType) {
    case 'Today':
    case 'Yesterday':
    case 'Tomorrow':
      return 'day';
    case 'TodayTomorrow':
      return 'twodays';
    case 'ThisWeek':
    case 'LastWeek':
    case 'NextWeek':
      return 'week';
    case 'LastMonth':
    case 'ThisMonth':
    case 'NextMonth':
      return 'month';
    case 'LastQuarter':
    case 'ThisQuarter':
    case 'NextQuarter':
      return 'quarter';
    case 'LastYear':
    case 'ThisYear':
    case 'NextYear':
      return 'year';
    default:
      return undefined;
  }
};
export const lengthTypeToPeriodType = (
  lengthType: ViewLengths,
): PeriodTypes | undefined => {
  switch (lengthType) {
    case 'day':
      return 'Today';
    case 'twodays':
      return 'TodayTomorrow';
    case 'week':
      return 'ThisWeek';
    case 'month':
      return 'ThisMonth';
    case 'customdays':
      return 'CustomDate';
    case 'quarter':
      return 'ThisQuarter';
    case 'year':
      return 'ThisYear';
    default:
      return undefined;
  }
};
export const viewStateFromFilter = (
  periodType: PeriodTypesEntity | SchedulerDatePositionUnion,
): Pick<IViewState, 'preset' | 'viewLength' | 'date'> | undefined => {
  const periodTypeUnion =
    (periodType as PeriodTypesEntity)?.Id ??
    (periodType as SchedulerDatePositionUnion);
  const schedulerPreset: SchedulerDatePositionUnion | undefined =
    SchedulerDatePositionArray.find(
      f => f.toLowerCase() === periodTypeUnion.toLowerCase(),
    );
  if (schedulerPreset === undefined) {
    return undefined;
  }
  const x = DatesRange.fromPreset(
    { Id: schedulerPreset as PeriodTypes, Name: '' },
    null,
    null,
  );

  if (x.start === null) {
    return undefined;
  }
  const length = periodTypeToLengthType(periodTypeUnion);
  if (length === undefined) {
    return undefined;
  }

  return {
    preset: schedulerPreset,
    viewLength: length,
    date: serializeSchedulerDate(x.start),
  };
};
export const getPeriodFromViewLength = (
  length: ViewLengths,
  date: Date,
  customDays?: number,
) => {
  let value = Object.assign(
    new PresetDatesRange(),
    {
      type:
        (getPeriodTypesEntityById(
          lengthTypeToPeriodType(length),
        ) as PeriodTypesEntity) ??
        (getPeriodTypesEntity('CustomDate') as PeriodTypesEntity),
    },
    DatesRange.fromPreset(
      (getPeriodTypesEntityById(
        lengthTypeToPeriodType(length),
      ) as PeriodTypesEntity) ??
        (getPeriodTypesEntity('CustomDate') as PeriodTypesEntity),
      length === 'customdays' ? date : date,
      length === 'customdays'
        ? dateUtils.addDays(date, (customDays ?? 3) - 1)
        : dateUtils.parseISO(date),
    ),
  );
  return value;
};
export function timelineColumnWidth(isMobile: boolean) {
  return isMobile ? 100 : 300;
}
export const MatchUnitSlotToEnd = (
  startTime: Timestamp,
  endTime: Timestamp,
  unitType: RestrictDurationUnitTypes,
  amount: number,
) => {
  const start = dateUtils.parseISO(startTime);
  const end = dateUtils.parseISO(endTime);
  let dateSt = start;
  let slotLength = 0;
  let lastCorrectEnd = new Date();
  let diffDuration = amount;
  switch (unitType) {
    case RestrictDurationUnitTypes.LimitMinutes:
      while (dateSt < end) {
        dateSt = dateUtils.addMinutes(dateSt, amount);
        slotLength++;
      }
      lastCorrectEnd = dateUtils.addMinutes(dateSt, -amount);
      diffDuration = dateUtils.getMinutesDiff(end, dateSt);
      let newMinutesEnd =
        slotLength > 1
          ? diffDuration <= amount / 2
            ? dateSt
            : lastCorrectEnd
          : dateSt;
      return newMinutesEnd;
    case RestrictDurationUnitTypes.LimitHours:
      while (dateSt < end) {
        dateSt = dateUtils.addHours(dateSt, amount);
        slotLength++;
      }
      lastCorrectEnd = dateUtils.addHours(dateSt, -amount);
      diffDuration = dateUtils.getHoursDiff(end, dateSt);
      let newHoursEnd =
        slotLength > 1
          ? diffDuration <= amount / 2
            ? dateSt
            : lastCorrectEnd
          : dateSt;
      return newHoursEnd;
    case RestrictDurationUnitTypes.LimitDays:
      while (dateSt < end) {
        dateSt = dateUtils.addDays(dateSt, amount);
        slotLength++;
      }
      lastCorrectEnd = dateUtils.addDays(dateSt, -amount);
      diffDuration = dateUtils.getDaysDiff(end, dateSt);
      let newDaysEnd =
        slotLength > 1
          ? diffDuration <= amount / 2
            ? dateSt
            : lastCorrectEnd
          : dateSt;
      return newDaysEnd;
    case RestrictDurationUnitTypes.LimitWeeks:
      while (dateSt < end) {
        dateSt = dateUtils.addWeeks(dateSt, amount);
        slotLength++;
      }
      lastCorrectEnd = dateUtils.addWeeks(dateSt, -amount);
      diffDuration = dateUtils.differenceInWeeks(end, dateSt);
      let newWeekEnd =
        slotLength > 1
          ? diffDuration <= amount / 2
            ? dateSt
            : lastCorrectEnd
          : dateSt;
      return newWeekEnd;
    case RestrictDurationUnitTypes.LimitMonths:
      while (dateSt < end) {
        dateSt = dateUtils.addMonths(dateSt, amount);
        slotLength++;
      }
      lastCorrectEnd = dateUtils.addMonths(dateSt, -amount);
      diffDuration = dateUtils.getMonthDiff(end, dateSt);
      let newMonthEnd =
        slotLength > 1
          ? diffDuration <= amount / 2
            ? dateSt
            : lastCorrectEnd
          : dateSt;
      return newMonthEnd;
  }
};
export function adjustToTimeSlot(
  startTime: Date,
  endTime: Date,
  workingHours: Array<IEquipmentWorkingHoursDto>,
  minDuration?: number,
  tz?: ITimeZone,
) {
  let result = {
    start: startTime,
    end: endTime,
  };
  if (workingHours.length > 0) {
    // let correctEnd = endTime > correctStart ? endTime : correctStart;
    let weekStartDay = dateUtils.getDay(startTime);
    let slots = workingHours.map(f => {
      return {
        StartTime: fromTimZoneToUTC(startTime, f.StartTime, tz?.Iana),
        EndTime: fromTimZoneToUTC(endTime, f.EndTime, tz?.Iana),
        DayOfWeek: f.DayOfWeek,
      };
    });
    // .filter(f => f.StartTime < correctEnd && f.EndTime > startTime);
    let startSlots = slots.filter(
      s => s.DayOfWeek === weekStartDay && s.StartTime <= startTime,
    );
    if (startSlots.length > 0) {
      let startOrdered = orderBy(startSlots, f => f.StartTime, 'desc');
      result.start = startOrdered[0].StartTime;
    }
    let correctEnd =
      !!minDuration &&
      minDuration > 0 &&
      dateUtils.differenceInMinutes(endTime, result.start) < minDuration
        ? dateUtils.addMinutes(result.start, minDuration)
        : endTime;
    let weekEndDay = dateUtils.getDay(correctEnd);
    let endSlots = slots.filter(
      s => s.DayOfWeek === weekEndDay && s.EndTime >= correctEnd,
    );
    if (endSlots.length > 0) {
      let endOrdered = orderBy(endSlots, f => f.EndTime, 'asc');
      result.end = endOrdered[0].EndTime;
    }
    return result;
  } else {
    return result;
  }
}
