import { AddReservation } from 'app/pages/Actions/PageTopActions/AddReservation';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { CoreSelectionButton } from 'app/components/GlobalServiceGroupFilter/CoreSelectionButton';
import clsx from 'clsx';
import { AssetQuickSearch } from 'app/components/AssetQuickSearch';
import { useSelector } from 'react-redux';
import { selectShowSearchInSidebar } from 'app/components/AssetQuickSearch/slice/selectors';
import * as React from 'react';
import {
  selectGlobalServiceGroupFilter,
  selectGlobalSetting,
  selectShowGlobalServiceGroupFilter,
} from 'app/slice/selectors';
import { AllowedSettings } from 'utils/globalSettings';
import { toEntityEnumArray } from 'utils/enumKeys';
import { SideBarQuickActionsEnum } from 'enums/enums';
import { NewWorkOrderButton } from 'app/pages/WorkOrdersPage/Actions/NewWorkOrder';
import { useAsyncGetWithEffect } from 'app/hooks/useAsyncGetOnMount';
import { IServiceGroupDto } from 'api/odata/generated/entities/IServiceGroupDto';
import { quoteODataValue } from 'api/odata/ODataFilter';
import { httpClient } from 'api/HttpClient';
import { Div100 } from 'app/components/AssetQuickSearch/styled';

export interface SideBarQuickActionProps {
  isOpen?: boolean;
  isMobile?: boolean;
}

export const SideBarQuickAction = React.memo(function SideBarQuickAction(
  props: SideBarQuickActionProps,
) {
  const { isOpen, isMobile } = props;
  const { t } = useTranslation();
  const showSearchInSidebar = useSelector(selectShowSearchInSidebar);
  const savedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const showGroups = useSelector(selectShowGlobalServiceGroupFilter);
  const actionOptions = toEntityEnumArray(SideBarQuickActionsEnum, t);
  const fetchCoreQuickAction = (
    serviceGroupId: number | undefined,
  ): Promise<IServiceGroupDto | undefined> => {
    return new Promise((resolve, reject) => {
      if (!serviceGroupId) {
        resolve(undefined);
      } else {
        (async () => {
          try {
            const url =
              '/api/odata/v4/servicegroups/GetGlobalServiceGroupsFilter';
            const params = {
              $select: 'Id,Name,SidebarQuickAction',
              $filter: `Id eq ${quoteODataValue(serviceGroupId)}`,
            };

            let groups = await httpClient
              .get(url, params)
              .then(response => response.value as IServiceGroupDto[]);
            resolve(groups[0]);
          } catch {
            resolve(undefined);
          }
        })();
      }
    });
  };
  const defaultSystemAction = useSelector(state =>
    selectGlobalSetting(state, AllowedSettings.SideBarQuickActions),
  );
  const systemAction = React.useMemo(() => {
    if (defaultSystemAction === 'NA') {
      return undefined;
    }
    return actionOptions.some(f => f.OriginalName === defaultSystemAction)
      ? actionOptions.filter(f => f.OriginalName === defaultSystemAction)[0].Id
      : 1;
  }, [actionOptions, defaultSystemAction]);

  const serviceGroup = React.useMemo(() => {
    return showGroups && !!savedServiceGroups && savedServiceGroups.length === 1
      ? savedServiceGroups[0]
      : undefined;
  }, [savedServiceGroups, showGroups]);

  const coreSelected = useAsyncGetWithEffect<IServiceGroupDto | undefined>(
    () => fetchCoreQuickAction(serviceGroup?.Id),
    undefined,
    [serviceGroup],
  );

  const baseAction = React.useMemo(() => {
    return !!coreSelected &&
      coreSelected.SidebarQuickAction !== null &&
      coreSelected.SidebarQuickAction !== undefined &&
      coreSelected.SidebarQuickAction > 0
      ? coreSelected.SidebarQuickAction
      : !!systemAction
      ? systemAction
      : undefined;
  }, [coreSelected, systemAction]);
  return (
    <div
      className={clsx('sideBarQickAction', {
        sideBarQickActionMobile: isMobile,
      })}
    >
      {isMobile && isOpen && showSearchInSidebar && (
        <AssetQuickSearch showInSideBar={showSearchInSidebar} />
      )}
      {isMobile && (
        <Div100>
          <CoreSelectionButton />
        </Div100>
      )}
      {!!baseAction && (
        <React.Fragment>
          {baseAction === 1 ? (
            <AddReservation
              id="quick-actions-menu-add-reservation"
              compact={!isMobile}
              text={t(translations.Reservation) as string}
              size="small"
              isIconButton={isMobile ? false : !isOpen}
              variant="main"
              isExpanded={true}
              fullWidth
            />
          ) : (
            <NewWorkOrderButton
              id="quick-actions-menu-add-workorder"
              fullWidth
              isIconButton={isMobile ? false : !isOpen}
              noChangeOnMobile={isMobile}
              variant="main"
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
});
