import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@material-ui/core';
import { Body } from 'app/components/Typography';
import { TFunctionResult } from 'i18next';
import * as React from 'react';
import styled from 'styled-components';
export type ThemeColor = 'dark' | 'light';
export interface TooltipsProps extends Omit<MuiTooltipProps, 'title'> {
  themeColor?: ThemeColor;
  /**
   * Tooltip title. redefined here to include TFunctionResult
   */
  title: React.ReactNode | TFunctionResult;
  headTitle?: React.ReactNode | TFunctionResult;
  disableContentWrapper?: boolean;
}
const TooltipContent = styled.span`
&.tooltip-content {
  cursor: pointer;
  pointer-events: auto;
`;
export function Tooltip({
  children,
  themeColor = 'dark',
  title,
  headTitle,
  arrow = true,
  interactive,
  disableContentWrapper,
  ...props
}: TooltipsProps) {
  const colorText = themeColor === 'dark' ? 'Dark' : 'Light';
  return (
    <MuiTooltip
      arrow={arrow}
      classes={{ popper: `color${colorText}` }}
      interactive={interactive}
      title={
        !!headTitle || (!!title && title !== '') ? (
          <div className={'content'}>
            {!!headTitle && (
              <Body size="small" bold color={'inherit'}>
                {headTitle}
              </Body>
            )}
            <Body size="small" color={'inherit'}>
              {title}
            </Body>
          </div>
        ) : (
          ''
        )
      }
      {...props}
    >
      {!disableContentWrapper ? (
        <TooltipContent className="tooltip-content">{children}</TooltipContent>
      ) : (
        children
      )}
    </MuiTooltip>
  );
}
