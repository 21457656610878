/**
 *
 * Asynchronously loads the component for UserTrainingPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AssetSearchHistoryPage = lazyLoad(
  () => import('./index'),
  module => module.AssetSearchHistoryPage,
);
