import { httpClient } from 'api/HttpClient';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { VariantType } from 'notistack';
import { IResponseType } from 'types/ResponseType';

export const GetResponseMessages = (
  response,
  infoText: string,
): IResponseType => {
  let messages: IResponseType = {
    SuccessMessages: [],
    ErrorMessages: [],
    WarningMessages: [],
  };
  if (response.SuccessMessages && response.SuccessMessages.length > 0) {
    response.SuccessMessages.forEach(element => {
      messages.SuccessMessages.push(element);
    });
  }
  if (response.ErrorMessages && response.ErrorMessages.length > 0) {
    response.ErrorMessages.forEach(element => {
      messages.ErrorMessages.push(element);
    });
  }
  if (response.WarningMessages && response.WarningMessages.length > 0) {
    response.WarningMessages.forEach(element => {
      messages.WarningMessages.push(element);
    });
  }
  if (
    messages.SuccessMessages.length === 0 &&
    response.ErrorMessages.length === 0 &&
    response.WarningMessages.length === 0
  ) {
    messages.SuccessMessages = [infoText];
  }
  return messages;
};

const GetMessagesArray = (response: IResponseType) => {
  const res: INotification[] = [];
  if (response.ErrorMessages.length > 0) {
    response.ErrorMessages.forEach(errmsg =>
      res.push({
        variant: 'error',
        message: errmsg,
      }),
    );
  }
  if (response.SuccessMessages.length > 0) {
    response.SuccessMessages.forEach(sccmsg =>
      res.push({
        variant: 'success',
        message: sccmsg,
      }),
    );
  }
  if (response.WarningMessages.length > 0) {
    response.WarningMessages.forEach(wrnmsg =>
      res.push({
        variant: 'warning',
        message: wrnmsg,
      }),
    );
  }
  return res;
};

export const DoAction1 = <Model extends unknown>(
  f: (item: Model) => Promise<any>,
  item: Model,
  t: TFunction,
): Promise<IResponseType> => {
  return new Promise<IResponseType>((resolve, reject) => {
    return f(item)
      .then(response => {
        const messages: IResponseType = GetResponseMessages(
          response,
          t(translations.Success) as string,
        );
        resolve(messages);
      })
      .catch(error => {
        console.log('status error', error);
        let err = t(translations.Failed) as string;
        reject(err);
      });
  });
};

export interface INotification {
  variant?: VariantType;
  message: string;
}

export const DoAction = async <Model extends unknown>(
  f: (item: Model) => Promise<any>,
  item: Model,
  t: TFunction,
  handleSubmitCallResult: (values: INotification[]) => void,
) => {
  try {
    const response = await DoAction1(f, item, t);
    handleSubmitCallResult(GetMessagesArray(response));
  } catch (error) {
    handleSubmitCallResult([
      {
        variant: 'error',
        message: error as string,
      },
    ]);
  }
};

export const insertUserAD = (item: { Name: string }) => {
  const params = { Id: -1, Name: item.Name };
  return httpClient.post(`/api/odata/v4/UsersADImport`, params);
};
