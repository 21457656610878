import { Condition, ODataOperators } from 'api/odata/ODataFilter';
//import { useFilters } from './TableFilters';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { WorkOrdersTable } from 'app/pages/WorkOrdersPage/WorkOrdersTable';
import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormSectionTitle } from 'app/components/Forms/FormsLayout/FormSectionTitle';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { NewWorkOrderButton } from 'app/pages/WorkOrdersPage/Actions/NewWorkOrder';
import { getColumns } from 'app/pages/WorkOrdersPage/Columns';
import { Column } from 'react-table';
import { IBasicTableState, IRow } from 'app/components/BasicTable';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import workOrderPrimaryAction from 'app/pages/WorkOrdersPage/PrimaryAction';
import { PrimaryAction } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { GettableCount } from 'app/components/BasicTable/components/GetTableCount';

const UserWorkOrdersColumns: string[] = [
  'Id',
  'AssetName',
  'AlertType',
  'AlertTitle',
  'AlertDescription',
  'AssignedUsers',
  'AlertOfflineTime',
  'LastUpdated',
  'LastComment',
  'LastCommentAt',
  'LastCommentBy',
];
const url = '/api/odata/v4/WorkOrders';

const GetPredicates = (
  userName: string,
): Array<Condition<IWorkOrderDetailsDto> | string> => {
  const res: Array<Condition<IWorkOrderDetailsDto> | string> = [];
  res.push(
    new Condition<IWorkOrderDetailsDto>(
      'ReportedBy',
      ODataOperators.Equals,
      userName,
    ),
  );
  return res;
};
export async function GetWorkOrdersTableCount(
  userName: string,
  name: string,
  updateCounters: (name: string, count: number) => void,
) {
  const res = await GettableCount({
    api: url,
    predicates: GetPredicates(userName) as Array<Condition<IRow> | string>,
    columns: ['Id'],
    globalFilterValue: '',
    customFilter: [],
    serviceGroups: [],
    isOptionalServiceGroup: true,
  });
  updateCounters(name, res);
}
export interface UserWorkOrdersTableProps {
  userName: string;
  getFiltersDefinition?: GetPageFilters<IWorkOrderDetailsDto>;
  isMobile?: boolean;
  useSidePanel?: boolean;
  openPanel: (state: SidePanelOpenState) => void;
  allowToAddNew?: boolean;
}
export function UserWorkOrdersTable(props: UserWorkOrdersTableProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions } = useLayoutSlice();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const workOrdersColumns = React.useMemo(
    () =>
      getColumns(
        t,
        undefined,
        undefined,
        authenticatedUser,
        props.useSidePanel,
        props.openPanel,
      ),
    [authenticatedUser, props.openPanel, props.useSidePanel, t],
  );
  const columns = React.useMemo(() => {
    let res: Column<IWorkOrderDetailsDto>[] = [];
    for (const c of UserWorkOrdersColumns) {
      const column = workOrdersColumns.find(f => f.accessor === c);
      if (column !== undefined) {
        res.push(column);
      }
    }
    return res;
  }, [workOrdersColumns]);

  const PageActions: PageActionRenderer[] = React.useMemo(() => {
    if (!props.allowToAddNew) {
      return [];
    } else {
      return [
        () => (
          <NewWorkOrderButton
            noChangeOnMobile
            text={t(translations.WorkOrder)}
            useSidePanel={props.useSidePanel}
            openPanelWithCover={props.openPanel}
          />
        ),
      ];
    }
  }, [props.allowToAddNew, props.openPanel, props.useSidePanel, t]);
  const primaryAction: PrimaryAction<IWorkOrderDetailsDto> = React.useCallback(
    (...args) => {
      workOrderPrimaryAction(
        props.openPanel,
        args[0],
        args[1],
        args[2],
        props.useSidePanel,
      );
    },
    [props.openPanel, props.useSidePanel],
  );
  const goToWorkOrders = React.useCallback(() => {
    dispatch(actions.toggleSidePanel(false));
    history.push('/workorders?reportedBy=' + props.userName);
  }, [actions, dispatch, history, props.userName]);

  const predicates = React.useMemo(() => {
    return GetPredicates(props.userName);
  }, [props.userName]);
  const initialState: IBasicTableState<IWorkOrderDetailsDto> = React.useMemo(
    () => ({
      sortBy: [{ id: 'Id', desc: true }],
    }),
    [],
  );
  return (
    <WorkOrdersTable
      columns={columns}
      initialState={initialState}
      predicates={predicates}
      pageActions={PageActions}
      useConfigurable={false}
      useCardsByDefault={props.isMobile}
      allowCards={props.isMobile}
      useExport={false}
      serviceGroups={[]}
      primaryAction={primaryAction}
      customScreenName={
        <React.Fragment>
          <FormSectionTitle title={t(translations.ActiveIncidents)} />
          <IconButton aria-label="link" onClick={goToWorkOrders}>
            <Icon icon="arrow-up-right-from-square" />
          </IconButton>
        </React.Fragment>
      }
      hideMenuOnMobile={true}
      screenNameVariant="section"
      tablePaddingVariant={'dense'}
      compactTable={props.isMobile}
    />
  );
}
