import styled from 'styled-components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  MenuList,
  MenuListProps,
  Paper,
  PaperProps,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { bookitColors } from 'styles/colors/bookitColors';

export const StyledFiltersContainer = styled.div<{ theme: Theme }>`
  &.filters-container {
    max-height: ${props => props.theme.spacing(54)}px;
    height: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 12px;
    width: 100%;

    &.horizontal {
      max-height: ${props => props.theme.spacing(21)};
      height: calc(100% - 48px);
    }

    &.mobileRoot {
      justify-content: flex-start;
      max-height: 100%;
      height: 100%;
      gap: 0px;
      & > :not(:last-child) {
        //margin-right: ${props => props.theme.spacing(1.5)}px;

        margin-bottom: ${props => props.theme.spacing(1.5)}px;
      }
    }

    &.mobileHorizontalRoot {
      justify-content: flex-start;
      max-height: 100%;
      height: 100%;
      gap: 0px;
      & > :not(:last-child) {
        //margin-right: ${props => props.theme.spacing(1.5)}px;

        margin-bottom: ${props => props.theme.spacing(1.5)}px;
      }
    }
  }
`;
export const ButtonsContainer = styled.div`
  &.buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px 0px;
    width: 100%;
    gap: 8px;
  }
`;
const StyledFilterBoxRoot = styled.div`
  &.filter-box-root {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 8px;
    position: relative;

    &.filter-box-mobile {
      display: inline-flex;
      flex-wrap: wrap;
      width: inherit;
      gap: 0px;
      & > :not(:last-child) {
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
    &.filter-box-mobile-horizontal {
      display: inline-flex;
      flex-wrap: wrap;
      width: inherit;
      gap: 0px;
      & > :not(:last-child) {
        margin-right: 8px;

        //margin-bottom: 8px;
      }
    }
    &.horizontal {
      display: inline-flex;
      flex-wrap: wrap;
    }
    & .filter-box-header {
      display: flex;
      align-items: center;
      gap: 0px;
      width: 100%;

      // & > :not(:last-child) {
      //   margin-right: 16px;
      // }
      & .filter-box-header-text {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 20ch;
        color: initial;
        flex-shrink: 0;
        margin-right: auto;
        &.filter-box-header-text-open {
          color: ${bookitColors.primary.regular};
        }
      }
    }
    & .filter-box-content {
      display: flex;
      align-items: center;
      gap: 0px;
      width: 100%;

      & > :not(:last-child) {
        margin-right: 16px;
      }
    }
    & .saved-list-label-mobile {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 10ch;
    }
    & .saved-list-content {
      max-width: 400px;
      min-width: 300px;
      width: 100%;
    }
    & .filter-label {
      flex-shrink: 0;
      color: initial;
      &.filter-label-open {
        color: ${bookitColors.primary.regular};
      }
    }
    & .filter-box-component {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: flex-start;
    }
  }
`;
export const FilterBoxRoot = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(function FilterBoxRoot(props, ref) {
  return (
    <StyledFilterBoxRoot ref={ref} {...props}>
      {props.children}
    </StyledFilterBoxRoot>
  );
});
export const AddFilterBoxRoot = styled.div`
  &.add-filter-box-root {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 8px;

    &.add-filter-box-mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
export const StyledMenuList = styled(
  ({
    children,
    ...other
  }: {
    children: React.ReactNode;
  } & MenuListProps) => <MenuList {...other}>{children}</MenuList>,
)`
  &.add-filter-select {
    margin-top: 16px;
    box-shadow: inset 0px 1px 0px #e0e5ec;
    overflow: auto;
    max-height: 385px;

    &.mobHorizontal {
      max-height: calc(100% - 96px);
    }
    &.mobVertical {
      max-height: calc(100% - 96px);
    }
  }
`;
export const FilterRoot = styled.div`
&.filter-root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
  height: 100%;
  overflow: hidden;
  max-width: 700px;
  height: 100%;
`;
export const StyledPaper = styled(
  ({
    children,
    ...other
  }: {
    children: React.ReactNode;
  } & PaperProps) => <Paper {...other}>{children}</Paper>,
)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0;
`;
// export const StyledDialog = styled(Dialog)<{ compact?: boolean }>`
//   align-items: ${props => (props.compact ? 'flex-start' : 'center')};
//   justify-content: ${props => (props.compact ? 'flex-end' : 'center')};
//   padding-top: ${props => (props.compact ? '0' : '40px')};
// `;
export const StyledDialog = styled(({ compact, ...rest }) => (
  <Dialog {...rest} />
))`
  /* Your styles here */
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  ${props =>
    props.compact &&
    `
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 0px;
  `}
`;

export const StyledDialogTitle = styled(DialogTitle)`
  position: relative;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 4px;
  top: 4px;
`;

// export const StyledDialogContent = styled(DialogContent)<{ isMobile: boolean }>`
//   overflow: ${props => (props.isMobile ? 'hidden' : 'visible')};
//   padding: ${props => (props.isMobile ? '8px 16px' : 'unset')};
// `;
export const StyledDialogContent = styled(({ isMobile, ...rest }) => (
  <DialogContent {...rest} />
))`
  /* Your styles here */
  ${props =>
    props.compact &&
    `
      overflow: hidden;
      padding: 8px 16px;
    `}
`;
export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  width: 100%;
`;
