import * as Yup from 'yup';
import { SRSelect } from 'app/pages/Samples/EditableTable/Fields/DropDownList';
import { selectServiceRequestCustomFormColumns } from 'app/pages/Samples/RequestSamplesPage/slice/selectors';
import { useFormikContext, Field, FieldProps } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomFormColumnSchema } from 'app/components/CustomForm/CustomFormUtils';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { httpClient } from 'api/HttpClient';
import { IServiceRequestRelated, IServiceRequestSelectedRows } from '../..';
import { appSettingsActions } from 'app/slice';
import { getServiceRequestRowFieldName } from '../../../ServiceRequestRowEditableCell';
import { FormRow } from 'app/components/Forms/FormsLayout';
import { DialogForm } from 'app/components/DialogForm';
import { dateUtils } from 'utils/date-utils';
import { getCellComponent } from 'app/pages/Samples/EditableTable/Fields/getCellComponent';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';

export interface BulkUpdateProps
  extends IServiceRequestRelated,
    IServiceRequestSelectedRows {}

export function BulkUpdate(props: BulkUpdateProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const columns = useSelector(selectServiceRequestCustomFormColumns);
  const options = React.useMemo(
    () =>
      columns === null
        ? []
        : columns
            .getArray()
            .filter(column => column.ColumnType !== 'Serial')
            .map(column => ({
              Id: column.Id,
              Name: column.Label,
              Column: column,
            })),
    [columns],
  );

  const schema = React.useMemo(
    () =>
      Yup.object({
        column: Yup.mixed().required(),
        value: Yup.mixed().when(['column'], (column, schema) =>
          column === null || column === undefined
            ? schema
            : getCustomFormColumnSchema(column).required().nullable(),
        ),
      }),
    [],
  );
  const formikContext = useFormikContext();

  const handleSubmit = React.useCallback(
    async values => {
      const column = values.column;
      if (column === null) {
        return;
      }
      try {
        const rowIdentifiers = props.selectedFlatRows.map(
          row => row.original.Id,
        );
        await httpClient.put(
          `/api/requests/${props.serviceRequestId}/column/${column?.Id}`,
          {
            value: postValue(values.value),
            rowids: rowIdentifiers,
          },
        );
        props.selectedFlatRows.forEach(row =>
          formikContext.setFieldValue(
            getServiceRequestRowFieldName(row.index, column.getFieldName()),
            values.value,
          ),
        );
        setOpen(false);
      } catch (error) {
        dispatch(
          appSettingsActions.addNotification({
            variant: 'error',
            message: t(translations.GenericUpdateErrorMessage),
          }),
        );
      }
    },
    [
      dispatch,
      formikContext,
      props.selectedFlatRows,
      props.serviceRequestId,
      t,
    ],
  );
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TopActionButton
        variant="white"
        size="small"
        disabled={props.selectedFlatRows.length === 0}
        icon={'pencil'}
        onClick={() => setOpen(x => !x)}
        text={t(translations.Update)}
      />
      <DialogForm
        validationSchema={schema}
        initialValues={{ value: null, column: null }}
        onSubmit={handleSubmit}
        onClose={() => setOpen(false)}
        open={open}
        title={t(translations.UpdateColumnValueAcrossMultipleRows)}
      >
        <FormRow fullRow>
          <Field name="column">
            {({
              field, // { name, value, onChange, onBlur }
              meta,
              form,
            }: FieldProps) => (
              <SRSelect
                fullWidth={true}
                multiple={false}
                options={options}
                //onChange={handleSelectedColumnChange}
                onChange={value => {
                  field.onChange({
                    target: {
                      value: value?.Column,
                      name: 'column',
                    },
                  });
                  form.setFieldValue('value', null, false);
                }}
              />
            )}
          </Field>
        </FormRow>

        <FormRow fullRow>
          <Field name="value">
            {({
              field, // { name, value, onChange, onBlur }
              form,
              meta,
            }: FieldProps) => {
              if (
                form.values.column !== null &&
                form.values.column !== undefined
              ) {
                const component = getCellComponent(form.values.column);
                if (component !== null) {
                  return component({
                    name: field.name,
                    //error: meta.error !== undefined,
                    onBlur: field.onBlur,
                    onChange: event => {
                      field.onChange({
                        target: {
                          name: field.name,
                          value: event?.target?.value ?? event ?? null,
                        },
                      });
                    },
                    value: field.value,
                    helperText: meta.error,
                  });
                }
              }
              return null;
            }}
          </Field>
        </FormRow>
      </DialogForm>
    </>
  );
}

/**
 * Formats custom form value in the format expected by the v3 api:
 * * ddl values are expected to be in {id:"xxx", text:"yyy"} format,
 * * dates - yyyy-MM-dd
 * * other primitive values - string
 * @param value custom form value
 * @returns
 */
function postValue(value: any) {
  if (value instanceof Date) {
    return dateUtils.formatISO(value);
  }
  if (value?.Id !== undefined && value?.Name !== undefined) {
    return { id: value.Id, text: value.text };
  } else {
    return value;
  }
}
