/**
 *
 * FilterBox
 *
 */
import * as React from 'react';
import clsx from 'clsx';
import { FilterBoxRoot } from '../styled';
import { useIsMobile } from 'app/hooks/useIsMobile';

export type Directions = 'vertical' | 'horizontal';
export interface FilterBoxCoreProps {
  direction: Directions;
}
export interface FilterBoxProps
  extends React.PropsWithChildren<
    FilterBoxCoreProps & React.HTMLAttributes<HTMLElement>
  > {}

export const FilterBox = React.forwardRef(function FilterBox(
  props: FilterBoxProps,
  ref: any,
) {
  const { checkIsMobile, isLandscape } = useIsMobile();
  const isMobile = checkIsMobile();
  return (
    <FilterBoxRoot
      className={clsx('filter-box-root', {
        isMobile: isMobile,
        horizontal: props.direction === 'horizontal',
        'filter-box-mobile': isMobile && !isLandscape,
        'filter-box-mobile-horizontal': isMobile && isLandscape,
      })}
      ref={ref}
      {...props}
    >
      {props.children}
    </FilterBoxRoot>
  );
});
