import axios, { CancelToken } from 'axios';
import { Guid } from 'guid-typescript';
import { S3DS_UploadRespunse } from '.';

export class UploadLargeFileApi {
  uploadChunck = async <T>(
    url: string,
    fileName: string,
    fileSize: number,
    chunck: Blob,
    pos: number,
    part: number,
    targetId: number,
    fileId: Guid | null,
    uploadId: string | null,
    cancel?: CancelToken,
  ) => {
    const formData = new FormData();
    formData.append('File', chunck);
    formData.append('rawFileName', fileName);
    formData.append('fileSize', fileSize.toString());
    formData.append('pos', pos.toString());
    formData.append('part', part.toString());
    formData.append('targetId', targetId.toString());
    formData.append('uploadId', uploadId ?? '');
    formData.append('fileId', fileId !== null ? fileId.toString() : Guid.EMPTY);
    try {
      const response = await axios.post<T>(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        cancelToken: cancel,
      });
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  CompleteMultipartUpload = async (
    url: string,
    fileId: Guid | null,
    uploadId: string | null,
    uploadResponses: S3DS_UploadRespunse[],
  ) => {
    const data = {
      fileId: fileId,
      uploadId: uploadId,
      uploadResponses: uploadResponses,
    };
    return axios.post(url, data);
  };
  DeleteUnfinishedLargeFiles = async (
    url: string,
    ids: Guid[],
    uploadId: string | null,
  ) => {
    const data = { ids: ids, uploadId: uploadId };
    return axios.post(url, data);
  };
}
