import { CircularProgress } from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteProps,
  AutocompleteRenderOptionState,
} from '@material-ui/lab';
import { BookitChip } from 'app/components/BasicChip';
import BaseTextField from 'app/components/BasicInputs/BaseTextField';
import React from 'react';
import { CSSProperties } from 'styled-components';
import { Entity } from 'types/common';
import clsx from 'clsx';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useSelector } from 'react-redux';
import {
  selectExpandedSidePanel,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import { capitalize, omit } from 'lodash';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import { addActionToOptions, isAction } from '../Utils/autoCompletePickerUtils';

export interface AutocompleteMultiPickerProps<
  TKey,
  TValue extends Entity<TKey> = Entity<TKey>,
> extends Omit<
    AutocompleteProps<TValue, true, boolean | undefined, false>,
    | 'multiple'
    | 'loading'
    | 'open'
    | 'options'
    | 'renderInput'
    | 'onChange'
    | 'classes'
    | 'renderTags'
    | 'renderOptionLabel'
    | 'renderOptions'
    | 'size'
  > {
  value?: TValue[];
  label?: string;
  loadData: (searchTerm: string | null) => Promise<TValue[]>;
  size?: 'small' | 'medium' | 'large' | 'xl';
  listSize?: 'small' | 'medium' | 'large';
  mini?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  isOpen?: boolean;
  style?: CSSProperties;
  name?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  onChange: (value: TValue[]) => void;
  required?: boolean;
  info?: string;
  id?: string;
  renderTags?: (
    value: TValue[],
    getTagProps: AutocompleteGetTagProps,
  ) => React.ReactNode | undefined;
  renderOptionLabel?: (value: TValue) => string | undefined;
  renderOptions?: (
    option: TValue,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode | undefined;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onChipClick?: (chip: TValue) => void;
  ariaLabel?: string;
  /**
   * Controls width of selected option chips. If true, every selected option will occupy full width of the picker, otherwise width will depend on option text content length
   */
  fullWdithOptions?: boolean;
  onPickerOpen?: () => void;
  className?: string;
  additionalAction?: React.ReactNode;
}

export function AutocompleteMultiPicker<
  TKey,
  TValue extends Entity<TKey> = Entity<TKey>,
>({
  isOpen,
  size = 'large',
  listSize = 'small',
  mini,
  onOpen,
  onClose,
  label,
  fullWidth,
  fullWdithOptions,
  variant = 'standard',
  name,
  placeholder,
  error,
  helperText,
  required,
  renderTags,
  loadData,
  disableClearable,
  onChange,
  onInputChange,
  getOptionSelected,
  getOptionLabel,
  renderOptionLabel,
  renderOptions,
  onBlur,
  value,
  defaultValue,
  info,
  id,
  onChipClick,
  ariaLabel,
  inputValue,
  onPickerOpen,
  className,
  additionalAction,
  ...props
}: AutocompleteMultiPickerProps<TKey, TValue>) {
  let firstOpen = isOpen;
  const [open, setOpen] = React.useState(firstOpen);
  const [options, setOptions] = React.useState<TValue[] | null>(null);
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);
  const [hasSelected, setSelected] = React.useState(
    (defaultValue || []).length > 0 || (value || []).length > 0,
  );
  const [newInputValue, setInputValue] = React.useState(inputValue || '');
  const isMobile = DetectIsMobile();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const showShortView = isMobile || (sidePanelOpen && !sidePanelExpanded);
  const loading = open && options === null;
  React.useEffect(() => {
    setSelected((value || []).length > 0);
  }, [value]);
  React.useEffect(() => {
    let active = true;
    if (!loading) return undefined;

    (async () => {
      try {
        const data = await loadData(searchTerm);
        const res = addActionToOptions(data, additionalAction);
        active && setOptions(res as TValue[]);
      } catch {}
    })();

    return () => {
      active = false;
    };
  }, [loading, loadData, searchTerm, additionalAction]);

  React.useEffect(() => {
    if (!open) setOptions(null);
  }, [open]);

  const handleRenderTags = (
    value: TValue[],
    getTagProps: AutocompleteGetTagProps,
  ) => {
    if (renderTags === undefined) {
      return value.map((option: TValue, index: number) => {
        const color = (option as unknown as IServiceFilterDto).Color;
        return (
          <BookitChip
            onClick={() => onChipClick?.(option)}
            variant="default"
            size="small"
            label={option.Name}
            custombgcolor={color ?? undefined}
            fullwidth={fullWdithOptions}
            {...getTagProps({ index })}
          />
        );
      });
    } else {
      return renderTags(value, getTagProps);
    }
  };
  const handleRenderOptionLabel = (option: TValue) => {
    return renderOptionLabel === undefined
      ? option.Name
      : (renderOptionLabel(option) as string);
  };
  return (
    <Autocomplete
      id={id || 'base-multi-autocomplete'}
      multiple
      className={clsx('autocomplete', 'multiSelect', className, {
        shortView: showShortView && !isMobile,
        hasLabel: !!label,
      })}
      loading={loading}
      options={options ?? []}
      open={open || false}
      onOpen={e => {
        //onOpen && onOpen(e);
        !disableClearable && setOptions(null);
        setSearchTerm(null);
        setOpen(true);
        !!onPickerOpen && onPickerOpen();
      }}
      onClose={(e, r) => {
        //onClose && onClose(e, r);
        setSearchTerm(null);
        setOpen(false);
      }}
      onChange={(e, v, r, d) => {
        if (isAction(d?.option)) return;
        setSearchTerm(null);
        setInputValue('');
        setSelected(v.length > 0);
        onChange(v);
      }}
      onInputChange={(event, newValue, reason) => {
        if (reason === 'reset') return;
        setOptions(null);
        setInputValue(newValue);
        if (reason === 'input') setSearchTerm(newValue);
        //onInputChange && onInputChange(event, newValue, reason);
      }}
      getOptionSelected={
        getOptionSelected ?? ((option, value) => option.Id === value.Id)
      }
      getOptionLabel={handleRenderOptionLabel}
      renderOption={option => {
        if (isAction(option)) {
          return option.action;
        }
        return <>{handleRenderOptionLabel(option)}</>;
      }}
      renderTags={handleRenderTags}
      size={size === 'small' ? 'small' : 'medium'}
      fullWidth={fullWidth}
      disableClearable={disableClearable}
      value={value ?? []}
      inputValue={newInputValue}
      popupIcon={<Icon icon="chevron-down" size="xs" />}
      closeIcon={<Icon icon="circle-xmark" size="xs" />}
      selectOnFocus={isMobile ? false : true}
      ListboxProps={{
        className: clsx('autocompleteList', `listSize${capitalize(listSize)}`),
        ...props.ListboxProps,
      }}
      renderInput={params => (
        <BaseTextField
          {...params}
          label={label}
          fullWidth={fullWidth}
          size={size}
          variant={variant || 'standard'}
          name={name}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          required={required}
          inputProps={{
            ...params.inputProps,
            'aria-label': label
              ? undefined
              : `${ariaLabel ? ariaLabel : 'search input'}`,
            className: 'autocompleteInput',
          }}
          // InputLabelProps={{
          //   classes: { root: 'multiSelect' },
          //   ...params.InputLabelProps,
          // }}
          InputProps={{
            autoFocus: firstOpen,
            className: clsx(
              'autocompleteInputRoot',
              'multiSelect',
              {
                shortView: showShortView && !isMobile,
                hasLabel: !!label,
                hasSelected: hasSelected,
              },
              params.InputProps.className,
            ),
            ...omit(params.InputProps, 'className'),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={params.size === 'small' ? 12 : 16}
                    className="autocomplete"
                  />
                ) : info ? (
                  <InfoIcon
                    title={info}
                    className={clsx('info', {
                      infoWidthPlus: hasSelected && !disableClearable,
                      infoWidthMinus: !hasSelected || disableClearable,
                    })}
                  />
                ) : // <Tooltip
                //   arrow
                //   title={info}
                //   style={{
                //     cursor: 'pointer',
                //     position: 'absolute',
                //     top: 'calc(50% - 10.5px)',
                //     right: hasSelected ? 52 : 32,
                //   }}
                // >
                //   <span>
                //     <Icon
                //       icon="info-circle"
                //       color={error ? 'danger' : 'default'}
                //       colorExtend="textHover"
                //       style={{
                //         width: 12,
                //         height: 12,
                //       }}
                //     />
                //   </span>
                // </Tooltip>
                null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            startAdornment: params.InputProps.startAdornment,
          }}
        />
      )}
      {...props}
    />
  );
}
