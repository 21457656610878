import { Entity } from 'types/common';

/**
 * Combines Id & Name into an object that represents an entity
 * @param id Entity Id
 * @param name Entity Name
 * @param otherData additional Data
 * @returns Entity with Id & Name populated
 */
export function toEntity<T>(
  id: T | null | undefined,
  name?: string | null,
  otherData?: Record<string, any>,
): Entity<T> | null {
  if (id == null || id === undefined || name == null || name === undefined) {
    return null;
  } else {
    return !!otherData
      ? Object.assign({}, { Id: id, Name: name ?? id }, otherData)
      : { Id: id, Name: name ?? id };
  }
}
