import * as Yup from 'yup';
import { ServiceRequestTableColumnsCollection } from '../slice/utils/ServiceRequestTableColumn';
import { getSchema } from 'app/pages/Samples/components/ServiceRequestRowsTable/getServiceRequestRowSchema';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { getFieldSchema } from 'app/components/CustomForm/CustomFormUtils';
import { chain } from 'lodash';

export const getRequestDetailsSchema = (props: {
  columns: ServiceRequestTableColumnsCollection | null;
  fields: IFormFieldDto[];
  isAdmin: boolean;
  ForbiddenFileExtensions: string[];
  UploadFileSizeLimit: number;
  values: Record<string, any> | undefined;
}) => {
  const customFormSchema = Yup.object(
    chain(
      props.fields
        .concat({
          Id: 'NA',
          Active: true,
          Label: 'NA',
        } as unknown as IFormFieldDto)
        .filter(f => f.Active),
    )
      .keyBy(f => f.Id)
      .mapValues(field =>
        getFieldSchema(
          field,
          props.isAdmin,
          props.UploadFileSizeLimit,
          props.ForbiddenFileExtensions,
          props.values,
        ),
      )
      .value(),
  );
  customFormSchema.omit(['NA']);
  let result = Yup.object({
    Rows:
      props.columns === null ||
      !(Object.keys(props.columns.columnsObj).length > 0)
        ? Yup.mixed()
        : getSchema({
            columns: props.columns,
            isAdmin: props.isAdmin,
          })
            .nullable()
            .optional(),
    FormValues: customFormSchema,
  });

  return result;
};
