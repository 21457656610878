import { Entity } from 'types/common';
import * as React from 'react';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { IUserGroupDto } from 'api/odata/generated/entities/IUserGroupDto';
import { useSelector } from 'react-redux';
import { selectConnectedSettings } from '../slice/selectors';
import {
  budgetExperimentsFilter,
  loadBudgetExperiments,
} from 'app/components/pickers/AutocompletePickers/BudgetExperimentsPicker';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { initBudgetData } from 'app/components/pickers/AutocompletePickers/BudgetPicker';
import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';

export interface BudgetListenerProps<T> {
  budget: Entity<number> | null;
  values?: T;
  setUserGroup: (value: Entity<string> | null) => void;
  setBudgetExperiment: (value: Entity<number> | null) => void;
  setBudget: (value: Entity<number> | null) => void;
  userName: Entity<string> | null;
  services: Entity<number>[];
  isEdit: boolean;
  startTime: Date | string;
  endTime?: Date | string;
  user: AuthenticatedUser | undefined;
  serviceTypeEnum?: ServiceType;
  forceDisabled?: boolean;
  userGroup: Entity<string> | null;
  fundingTypeVisible?: boolean;
  setFundingType: (value: Entity<number> | null) => void;
}

export function BudgetListener<T>(props: BudgetListenerProps<T>) {
  const {
    budget,
    setUserGroup,
    setBudgetExperiment,
    setBudget,
    services,
    userName,
    isEdit,
    startTime,
    endTime,
    serviceTypeEnum,
    user,
    forceDisabled,
    userGroup,
    fundingTypeVisible,
    setFundingType,
  } = props;
  const [initilized, setInitilized] = React.useState(!forceDisabled);
  const serviceSettings = useSelector(selectConnectedSettings);
  const currentUser =
    !!userName && userName !== null ? userName.Id : user?.Id || '';
  const fetchBudgetExperiment = async () => {
    try {
      const budgetExperiments = await loadBudgetExperiments(
        budgetExperimentsFilter(
          services as IServiceTypeFilterDto[],
          serviceTypeEnum,
          budget?.Id,
          startTime,
          endTime,
        ),
        currentUser,
      )(null);
      return budgetExperiments.value;
    } catch {
      return [];
    }
  };
  React.useEffect(() => {
    let cancel = false;
    if (!initilized) {
      if (!!budget?.Id) {
        if (serviceSettings.ReservationUserGroupGroupByBudgetUserGroup) {
          //let budget = values.Budget as IBudgetFilterDto;
          if (!!(budget as IBudgetFilterDto).UserGroupId) {
            if ((budget as IBudgetFilterDto).UserGroupId !== userGroup?.Id) {
              setUserGroup({
                Id: (budget as IBudgetFilterDto).UserGroupId,
                Name: (budget as IBudgetFilterDto).UserGroupName,
              } as IUserGroupDto);
            }
          }
        }
        if (serviceSettings.budgetExpirementVisible && !isEdit) {
          if ((budget as IBudgetFilterDto).HideExperiments === false) {
            (async () => {
              await fetchBudgetExperiment()
                .then(experiments => {
                  if (cancel) {
                    return;
                  }
                  if (experiments.length > 0) {
                    if (experiments.length === 1) {
                      setBudgetExperiment(experiments[0]);
                    } else {
                      setBudgetExperiment(null);
                    }
                  } else {
                    setBudgetExperiment(null);
                  }
                })
                .catch(() => {
                  setBudgetExperiment(null);
                });
            })();
          } else {
            setBudgetExperiment(null);
          }
        }
        if (
          fundingTypeVisible &&
          (budget as IBudgetFilterDto).FundingTypeId !== null
        ) {
          setFundingType(
            getSingleInvoiceFundingType(
              (budget as IBudgetFilterDto).FundingTypeId ?? 0,
            ),
          );
        }
      } else {
        if (serviceSettings.ReservationUserGroupGroupByBudgetUserGroup) {
          setUserGroup(null);
        }
        if (serviceSettings.budgetExpirementVisible) {
          setBudgetExperiment(null);
        }
      }
    } else {
      if (isEdit && budget !== null && !cancel) {
        (async () => {
          await initBudgetData(budget.Id.toString())
            .then(budg => {
              if (cancel) {
                return;
              }
              setBudget(budg[0]);
              if (
                !!budg[0] &&
                fundingTypeVisible &&
                (budg[0] as IBudgetFilterDto).FundingTypeId !== null
              ) {
                setFundingType(
                  getSingleInvoiceFundingType(
                    (budg[0] as IBudgetFilterDto).FundingTypeId ?? 0,
                  ),
                );
              }
            })
            .catch(() => {});
        })();
      }
    }
    setInitilized(false);
    return () => {
      cancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budget]);

  return null;
}
