import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export const SpanPaddingInline8 = styled.span`
  padding-inline: 8px;
`;
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
`;
export const FlexColumn16 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &.wrap {
    flex-wrap: wrap;
  }
`;
export const FlexRow8 = styled(FlexRow)`
  gap: 8px;
`;

export const StyledStatusLabel = styled.span<{
  variant?: 'success' | 'info' | 'warning' | 'error';
  size?: 'small' | 'medium' | 'large';
}>`
  &.status-label {
    background-color: ${props =>
      props.variant === 'success'
        ? bookitColors.success.background
        : props.variant === 'info'
        ? bookitColors.primary.background
        : props.variant === 'warning'
        ? bookitColors.warning.background
        : props.variant === 'error'
        ? bookitColors.danger.background
        : bookitColors.base.white};
    color: ${props =>
      props.variant === 'success'
        ? bookitColors.success.dark
        : props.variant === 'info'
        ? bookitColors.primary.dark
        : props.variant === 'warning'
        ? bookitColors.warning.dark
        : props.variant === 'error'
        ? bookitColors.danger.dark
        : bookitColors.grayscale.blackRegular};
    border-radius: 8px;
    cursor: pointer;
    padding: ${props =>
      props.size === 'small' ? 4 : props.size === 'large' ? 10 : 6}px;
  }
`;
export const StyledLimitLabel = styled.span`
  &.limit-label {
    display: inline-flex;
    align-items: center;
    & .infinity-icon {
      font-size: 24px;
    }
  }
`;
export const ErrorsPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  @media (min-width: 600px) {
    flex-direction: row;
    gap: 64px;
  }
  & .icon-section {
    font-size: 200px;
    @media (min-width: 600px) {
      font-size: 400px;
      padding-right: 32px;
    }
  }
  & .content-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 360px;
    @media (min-width: 600px) {
      max-width: 560px;
    }
  }
`;
export const ReadOnlyDetailsRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 4px;
  padding: 0;
  width: 100%;
`;
export const ReadOnlyDetailsColumns = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  padding: 16px 0px;
  width: 100%;
`;
export const ReadOnlyDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: inherit;
`;
