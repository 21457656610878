import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '../BasicButtons/Button';
import { IconButton } from '../BasicButtons/IconButton';
import BasicTypography from '../Typography/BasicTypography';
import clsx from 'clsx';
import { DialogConfirm } from '../DialogConfirm';
import { MessageActions } from './MessageActions';

export interface SnackBarMessageProps {
  message?: string;
  messageContent?: React.ReactNode;
  messageTitle?: string;
  //   variant?: 'error' | 'success' | 'warning' | 'info';
  onClose: () => void;
  icon?: React.ReactNode;
  messageActions?: (() => React.ReactNode)[];
}
export const SnackBarMessage: React.FC<SnackBarMessageProps> = ({
  message,
  messageContent,
  messageTitle,
  icon,
  messageActions,
  onClose,
}) => {
  const { t } = useTranslation();
  const messageRef = React.useRef<HTMLDivElement>(null);
  const [showMoreInDialog, setShowMoreInDialog] = React.useState(false);
  const [showMoreCollapse, setShowMoreCollapse] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  useEffectOnMount(() => {
    if (messageRef.current) {
      setShowMoreCollapse(
        messageRef.current.scrollHeight > 196 &&
          messageRef.current.scrollHeight < 260,
      );
      setShowMoreInDialog(messageRef.current.scrollHeight > 260);
    }
  });
  return (
    <React.Fragment>
      <SnackBarWrapper className="content-root">
        <div
          ref={messageRef}
          className={clsx('message-root', {
            'message-expanded': expanded,
          })}
        >
          <div className="message-content">
            {!!icon && <div className="message-content-icon">{icon}</div>}
            {(!!messageTitle || !!message || !!messageContent) && (
              <div className={'message-content-text-columns'}>
                <span
                  className={clsx('message-content-text', {
                    'message-truncated':
                      (showMoreCollapse && !expanded) || showMoreInDialog,
                  })}
                >
                  {messageTitle && (
                    <BasicTypography variant="bodyS" bold>
                      {messageTitle}
                    </BasicTypography>
                  )}
                  {message && (
                    <BasicTypography variant="bodyS">{message}</BasicTypography>
                  )}
                  {!!messageContent && messageContent}
                </span>
                {showMoreCollapse && (
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded
                      ? t(translations.ShowLess)
                      : t(translations.ShowMore)}
                  </Button>
                )}
                {showMoreInDialog && (
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => setDialogOpen(true)}
                  >
                    {t(translations.ShowMore)}
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className="message-close">
            <IconButton
              variant="textGray"
              icon="close"
              size="xs"
              onClick={onClose}
            />
          </div>
        </div>
        {!!messageActions && messageActions.length > 0 && (
          <MessageActions messageActions={messageActions} />
        )}
      </SnackBarWrapper>
      {showMoreInDialog && (
        <DialogConfirm
          isOpen={dialogOpen}
          onClose={() => setDialogOpen(false)}
          body={
            <div className="message-content">
              {!!icon && <div className="message-content-icon">{icon}</div>}
              {(!!messageTitle || !!message || !!messageContent) && (
                <div className="message-content-text-columns">
                  {messageTitle && (
                    <BasicTypography variant="bodyS" bold>
                      {messageTitle}
                    </BasicTypography>
                  )}
                  {message && (
                    <BasicTypography variant="bodyS">{message}</BasicTypography>
                  )}
                  {!!messageContent && messageContent}
                </div>
              )}
            </div>
          }
          confirmButtonLabel={t(translations.OK)}
          onConfirm={() => setDialogOpen(false)}
        />
      )}
    </React.Fragment>
  );
};
const SnackBarWrapper = styled.div`
  &.content-root {
    /* Close Button=True, Icon=False, Title=False, Color=Black, Buttons=True, Show More Text=None */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 12px 16px 16px;
    gap: 16px;
    width: 100%;

    & .message-root {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0;
      gap: 10px;

      max-width: 352px;
      max-height: 196px;
      width: 100%;
      &.message-expanded {
        max-height: 260px;
      }
      & .message-close {
        // position: absolute;
        // right: 20px;
        // top: 16px;
        /* Close Button */

        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;

        width: 24px;
        height: 24px;
      }
      & .message-content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
        height: auto;
        & .message-content-icon {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 4px 0px 0px;
          gap: 10px;

          // width: 20px;
          // height: 21px;
        }
        & .message-content-text-columns {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 8px;
          & .message-content-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;

            width: 100%;
            height: auto;
            &.message-truncated {
              overflow: hidden;
              text-overflow: ellipsis;
              height: 138px;
              display: -webkit-box;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
              word-break: break-all;
            }
          }
        }
      }
    }
  }
`;
