import { SubscribersDetailsQStringParameters } from 'app/pages/SubscribersDetailsPage/Details/slice/types';
import { IResponseType } from 'types/ResponseType';
import { httpClient } from './HttpClient';
import { ISubscribersDto } from './odata/generated/entities/ISubscribersDto';

export const SubscribersApi = {
  initCreateData: (payload: SubscribersDetailsQStringParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/Subscribers/InitSubscribers';
    return httpClient.post(url, data);
  },
  initUpdateData: (payload: SubscribersDetailsQStringParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/Subscribers/InitSubscribers';
    return httpClient.post(url, data);
  },
  insertSubscribersDetails: (payload: ISubscribersDto) => {
    const url = '/api/odata/v4/Subscribers'; //POST action in Controller
    return httpClient.post(url, payload);
  },
  updateSubscribersDetails: (payload: ISubscribersDto) => {
    const url = `/api/odata/v4/Subscribers(${payload.Id})`; //PUT action in Controller
    return httpClient.put(url, payload);
  },

  deleteSubscription: (Id: number): Promise<IResponseType> => {
    let url = `/api/odata/v4/Subscribers(${Id})`;
    return httpClient.delete(url);
  },
  deleteSelectedRows: (payload: number[]) => {
    const url = `/api/odata/v4/Subscribers/DeleteSelected`;
    const data = { keys: payload };
    return httpClient.post(url, data);
  },
};
