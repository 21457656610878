import * as React from 'react';
import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Roles } from 'api/odata/generated/enums/Roles';
import { intersection } from 'lodash';
import { useSelector } from 'react-redux';
import { allowedroles } from './allowedroles';
import { AuthenticatedUser } from 'types/AuthenticatedUser';

export function useWorkOrdersPermissions() {
  const user = useSelector(selectAuthenticatedUser);
  const roles: number[] | undefined = user?.Roles;

  const callback = React.useCallback(
    (row: IAlertPermissionCheckRecord): boolean =>
      allowedToViewAlert(user, row),
    [user],
  );

  const hasPermissions: boolean = intersection(roles, allowedroles).length > 0;
  const isMainOrInstrumentEventsAdmin: boolean =
    (user?.Roles.includes(Roles.Administrators) ?? false) ||
    (user?.Roles.includes(Roles.InstrumentEventsAdmin) ?? false);
  return {
    hasPermissions,
    allowedToView: callback,
    isMainOrInstrumentEventsAdmin,
  };
}

export type IAlertPermissionCheckRecord = Pick<
  IWorkOrderDetailsDto,
  'ServiceGroupId' | 'ReportedBy' | 'ServiceId' | 'ServiceTypeId'
>;
export const allowedToViewAlert = (
  user: AuthenticatedUser | undefined,
  row: IAlertPermissionCheckRecord,
) => {
  if (!user) return false;
  return (
    user.HasAdminGroupOnlyPermissions(row.ServiceGroupId ?? undefined) ||
    user.HasAdminServiceOnlyPermissions(
      row.ServiceTypeId ?? 1,
      row.ServiceId ?? undefined,
    ) ||
    user.HasLabTechGroupPermissions(row.ServiceGroupId ?? undefined) ||
    user.HasLabTechServicePermissions(
      row.ServiceTypeId ?? 1,
      row.ServiceId ?? undefined,
    ) ||
    (user.Roles.includes(Roles.InventoryAdmin) &&
      user.HasInstrumentEventsGroupPermissions(
        row.ServiceGroupId ?? undefined,
      )) ||
    user.Roles.includes(Roles.InstrumentEventsAdmin) ||
    user.Id === (row.ReportedBy ?? '')
  );
};

export const IsReadOnlyUser = (user: AuthenticatedUser | undefined) => {
  if (!user) return true;
  return (
    user.Roles.includes(Roles.Readonly) ||
    user.Roles.includes(Roles.CalendarOnly)
  );
};
