/**
 *
 * SuperGroupPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { ISuperGroupFilterDto } from 'api/odata/generated/entities/ISuperGroupDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';

import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import {
  selectGlobalServiceGroupFilter,
  selectIsAuthenticated,
} from 'app/slice/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Entity } from 'types/common';

export type DivisionsUrl = 'base' | 'full' | 'public';
export interface SuperGroupPickerProps
  extends Omit<AutocompletePickerProps<ISuperGroupFilterDto>, 'loadData'> {
  urlType?: DivisionsUrl;
}

const url = '/api/odata/v4/SuperGroupFilter';
const publicUrl = '/api/odata/v4/SuperGroupFilter/GetPublicDivisions';
const baseUrl = '/api/odata/v4/SuperGroupFilter/GetDivisions';
export const initSuperGroupData = (
  initval: string | undefined,
  sourceUrl?: DivisionsUrl,
) => {
  if (initval === undefined) {
    return new Promise<ISuperGroupFilterDto[]>((resolve, reject) => {
      resolve([] as ISuperGroupFilterDto[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select:
        sourceUrl !== undefined && sourceUrl !== 'full'
          ? 'Id,Name,ServiceGroups'
          : 'Id,Name',
      $skip: 0,
      $top: 1,
    };
    let initUrl =
      sourceUrl === 'public' ? publicUrl : sourceUrl === 'base' ? baseUrl : url;
    return httpClient
      .get(initUrl, params)
      .then(response => response.value as ISuperGroupFilterDto[]);
  }
};
const loadData = (
  predicates: (string | Condition<ISuperGroupFilterDto>)[] | undefined,
  globalServiceGroupFilter: Entity<number>[],
  isAuthenticated?: boolean,
  urlType?: DivisionsUrl,
) => {
  const sourceUrl =
    urlType === 'public' || !isAuthenticated
      ? publicUrl
      : urlType === 'base'
      ? baseUrl
      : url;

  return getAutoCompleteLoadDataFn<ISuperGroupFilterDto>({
    url: sourceUrl,
    predicates: predicates,
    // all props of the IAssetDto are listed due to potential use of thes somewhere
    // TODO: narrow down used properties list
    select:
      urlType !== undefined && urlType !== 'full'
        ? ['Id', 'Name', 'ServiceGroups']
        : ['Id', 'Name'],
    globalServiceGroupFilter:
      !!globalServiceGroupFilter &&
      globalServiceGroupFilter.length > 0 &&
      urlType !== undefined &&
      urlType !== 'full'
        ? [
            new Condition<ISuperGroupFilterDto>(
              'ServiceGroups',
              ODataOperators.AnyIn,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
  });
};

export function SuperGroupPicker(props: SuperGroupPickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const isAuth = useSelector(selectIsAuthenticated);
  const innerLoadData = loadData(
    props.predicates,
    isAuth ? globalServiceGroupFilter || [] : [],
    isAuth,
    props.urlType,
  );
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      loadData={innerLoadData}
      id={props.id || 'supergroupid'}
      ariaLabel="Divisions search"
      {...props}
    />
  );
}
