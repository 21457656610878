import * as React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Row } from 'react-table';
import { IRow } from 'app/components/BasicTable/IRow';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { ICanRaiseBasicTableRefreshEvent } from './ControlledTableProps';

export interface RenderRowProps<TRow extends IRow>
  extends ICanRaiseBasicTableRefreshEvent {
  row: Row<TRow>;

  onRowClicked?: (row: TRow) => void;
  children?: (cell) => React.ReactNode;
}
export function RenderRow<TRow extends IRow>({
  row,
  onRefresh,
  onRowClicked,
  children = cell =>
    cell.render('Cell', {
      onRefresh: onRefresh,
    }),
}: RenderRowProps<TRow>) {
  const canClickRow = onRowClicked !== undefined;
  return (
    <Tooltip
      title={canClickRow ? 'click to view underlying data' : undefined}
      disableContentWrapper={true}
    >
      <TableRow
        onClick={() => onRowClicked?.(row.original)}
        {...row.getRowProps()}
      >
        {
          // Loop over the rows cells
          row.cells.map(cell => {
            // Apply the cell props
            return (
              <TableCell
                className={row.isGrouped ? 'cell-grouped' : undefined}
                {...cell.getCellProps()}
              >
                <span
                  onClick={e => {
                    if (canClickRow) {
                      e.stopPropagation();
                    }
                  }}
                >
                  {
                    // Render the cell contents
                    children(cell)
                  }
                </span>
              </TableCell>
            );
          })
        }
      </TableRow>
    </Tooltip>
  );
}
