import parse from 'html-react-parser';
import DateRenderer from 'app/components/BasicTable/CellRenderers/DateRenderer';
import { Link } from 'app/components/ExternalLink';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Column } from 'react-table';
import { IInvoicePayment } from './IInvoicePayment';
import { Link as MuiLink } from '@material-ui/core';

export const getColumns = (t: TFunction, openEdit?: (id: number) => void) => {
  const res: Column<IInvoicePayment>[] = [
    {
      accessor: 'Id',
      Header: t(translations.HashSymbol) as string,
      Cell: ({ value }) => {
        return !openEdit ? (
          <>{value}</>
        ) : (
          <MuiLink onClick={() => openEdit(value)}>{value}</MuiLink>
        );
      },
    },
    {
      accessor: 'Date',
      Header: t(translations.Date) as string,
      Cell: DateRenderer,
    },
    {
      accessor: 'Amount',
      Header: t(translations.Amount) as string,
      Cell: ({ value }) => <>{value.toFixed(2)}</>,
    },
    {
      accessor: 'PaymentMethodName',
      Header: t(translations.PaymentMethod) as string,
    },
    {
      accessor: 'CreatedByDN',
      Header: t(translations.CreatedBy) as string,
      Cell: ({ row: { original }, value }) => (
        <Link href={'Users/Profile?id=' + original.CreatedBy} target="_blank">
          {value}
        </Link>
      ),
    },
    {
      accessor: 'CreatedAt',
      Header: t(translations.CreatedAt) as string,
      Cell: DateRenderer,
    },
    {
      accessor: 'Remarks',
      Header: t(translations.Remarks) as string,
      Cell: ({ value }) => (!!value ? <>{parse(value)}</> : <></>),
    },
    {
      accessor: 'Reference',
      Header: t(translations.Reference) as string,
    },
  ];
  return res;
};
