import * as React from 'react';
import { httpClient } from 'api/HttpClient';
import { IAssemblyPartDto } from 'api/odata/generated/entities/IAssemblyPartDto';
import { IODataQueryResponse } from 'api/odata/IODataQueryResponse';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Popover } from 'app/components/Popover';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { PromiseStatus, usePromise } from 'app/hooks/usePromise';
import { translations } from 'locales/translations';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Identifiable } from 'types/common';
import { IReservationEquipmentDto } from '../../slice/types';
import { Skeleton } from '@material-ui/lab';
import { assertExhaustive } from 'utils/assertExhaustive';
import { IReservationAssemblyPartDto } from 'api/odata/generated/entities/IReservationAssemblyPartDto';
import styled from 'styled-components';

/**
 * Pick minimum required props from the EquipmentsData
 */
export interface EquipmentsDataAssembliesProps {
  equipmentsData: Array<
    Pick<IReservationEquipmentDto, 'Id' | 'HasAssemblyParts'>
  >;
  reservedAssemblyParts: IReservationAssemblyPartDto[] | null;
}

interface IAssembly {
  /**
   * Top assembly instrument
   */
  assembly: IAssemblyPartDto;
  /**
   * All of the parts in a flat array
   */
  parts: IAssemblyPartDto[];
}

/**
 * Intenal React.useState interface used by the component
 */
interface IState {
  open: boolean;
  assemblyParts?: IAssembly[];
  reservedAssemblyParts?: IReservationAssemblyPartDto[];
  popperAnchor?: unknown;
}

const fetchAssemblies = async (services: Array<Identifiable<number>>) => {
  // send batch containing one request per each equipment
  const batchResponse = await httpClient.batch(
    services.map(service => ({
      method: 'GET',
      pathName: `servicefilter/${service.Id}/AssemblyParts`,
    })),
  );

  // server returns selected instrument along it's assembly parts in a flat array
  // transform flat array into assembly all assembly parts in a flat array to match the structure displayed to the user
  const parts = batchResponse.responses.map(response => {
    const data = (response.body as IODataQueryResponse<IAssemblyPartDto>).value;
    const sorted = orderBy(data, f => f.Path);
    return {
      assembly: sorted[0],
      parts: sorted.slice(1),
    };
  });
  // return partial<IState> plug able into extendState
  const result: Partial<IState> = { assemblyParts: parts };
  return result;
};

/**
 * Enables assembly info processing only for cases where assemblies have been selected.
 * Otherwise abort the whole ordeal
 * @param props
 * @returns
 */
export function EquipmentsDataAssembliesContainer(
  props: EquipmentsDataAssembliesProps,
) {
  const isAssembly = React.useMemo(
    () =>
      props.equipmentsData.some(service => service.HasAssemblyParts === true),
    [props.equipmentsData],
  );
  if (isAssembly === false) {
    return null;
  } else {
    return <EquipmentsDataAssemblies {...props} />;
  }
}
const AssemblyContent = styled.div`
  width: 360px;
  padding: 0 24px 0 16px;
`;
export function EquipmentsDataAssemblies(props: EquipmentsDataAssembliesProps) {
  const { t } = useTranslation();
  const [state, , extendState] = useAsyncExtendedState<IState>({
    open: false,
    reservedAssemblyParts: props.reservedAssemblyParts ?? undefined,
  });
  const [fetchAssembliesPromiseState, fetchAssembliesPromise] =
    usePromise(fetchAssemblies);
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      // open popover
      extendState(state => ({
        open: !state.open,
        popperAnchor: e.currentTarget,
      }));
      // we're showing already reserved parts for existing reservations as reserved parts can differ from current assembly configuration
      if (state.reservedAssemblyParts === undefined) {
        // start fetching data
        extendState(fetchAssembliesPromise(props.equipmentsData));
      }
    },
    [
      extendState,
      fetchAssembliesPromise,
      props.equipmentsData,
      state.reservedAssemblyParts,
    ],
  );

  if (
    props.equipmentsData.length === 0 ||
    !props.equipmentsData.some(service => service.HasAssemblyParts === true)
  ) {
    return null;
  }

  return (
    <>
      <Button
        variant="ghost"
        onClick={handleClick}
        title={t(translations.AssemblyParts)}
      >
        <Icon icon="list" />
      </Button>

      {state.popperAnchor !== null && (
        <Popover
          open={state.open}
          anchorEl={state.popperAnchor as EventTarget & HTMLDivElement}
          title={t(translations.AssemblyParts)}
          onClose={() => extendState({ open: false })}
        >
          <AssemblyContent>
            {props.reservedAssemblyParts !== undefined && (
              <ul>
                {props.reservedAssemblyParts?.map(reservedAssemblyPart => (
                  <li>{reservedAssemblyPart.Name}</li>
                ))}
              </ul>
            )}
            {fetchAssembliesPromiseState.status !== undefined && (
              <RenderAssemblyParts status={fetchAssembliesPromiseState.status}>
                {state.assemblyParts === undefined ? (
                  <>{t(translations.NoDataFound)}</>
                ) : (
                  <>
                    {state.assemblyParts?.map((item, index) => (
                      <span key={index}>
                        {t(translations.PartsIncludedWithin)}{' '}
                        {item.assembly.Name}
                        <ul>
                          {item.parts.map(service => (
                            <li key={service.Id}>{service.Name}</li>
                          ))}
                        </ul>
                      </span>
                    ))}
                  </>
                )}
              </RenderAssemblyParts>
            )}
          </AssemblyContent>
        </Popover>
      )}
    </>
  );
}

interface RenderAssemblyPartsProps {
  status?: PromiseStatus;
}
/**
 * Renders the current state of assemblies fetch result: e.g. processing/error/data
 * @param param0
 * @returns
 */
function RenderAssemblyParts({
  status = 'pending',
  ...props
}: React.PropsWithChildren<RenderAssemblyPartsProps>): JSX.Element {
  const { t } = useTranslation();

  switch (status) {
    case 'pending':
      return (
        <>
          <Skeleton width="25ch" />
          <ul>
            <li>
              <Skeleton width="25ch" height="22px" />
            </li>
            <li>
              <Skeleton width="25ch" height="22px" />
            </li>
            <li>
              <Skeleton width="25ch" height="22px" />
            </li>
            <li>
              <Skeleton width="25ch" height="22px" />
            </li>
            <li>
              <Skeleton width="25ch" height="22px" />
            </li>
          </ul>
        </>
      );

    case 'resolved':
      return <>{props.children}</>;
    case 'rejected':
      return <>{t(translations.err_ErrorOccured)}</>;
    default:
      assertExhaustive(status);
  }
}
