import * as React from 'react';
import {
  bindHover,
  bindMenu,
  usePopupState,
  anchorRef,
} from 'material-ui-popup-state/hooks';
import { IconButton } from '../BasicButtons/IconButton';
import { Icon } from '../BasicIcons/FontAwesome';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { ReactActionsProps } from '.';
import { StyledMenuItem, ActionsRoot } from './StyledMenuItem';

export const HoverActions = React.forwardRef<any, ReactActionsProps>(
  function HoverActions(props, ref) {
    const { id, items, size, iconVariant } = props;
    const popupState = usePopupState({
      variant: 'popover',
      popupId: id,
    });
    const buttons = items.filter(a => a !== undefined);
    return (
      <React.Fragment>
        <ActionsRoot
          ref={anchorRef(popupState)}
          className={'hover-actions-root'}
        >
          <IconButton
            aria-label={id}
            variant={iconVariant || 'ghost'}
            size={size}
            {...bindHover(popupState)}
          >
            <Icon icon="ellipsis-v" />
          </IconButton>
          <HoverMenu
            ref={ref}
            {...bindMenu(popupState)}
            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            getContentAnchorEl={undefined}
            {...props}
          >
            {buttons.map((item, index) => {
              return (
                <StyledMenuItem
                  key={index}
                  ref={ref}
                  onClick={() => popupState.close()}
                  {...props}
                >
                  {item}
                </StyledMenuItem>
              );
            })}
          </HoverMenu>
        </ActionsRoot>
      </React.Fragment>
    );
  },
);
