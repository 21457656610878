import * as DHTMLX from 'dhtmlx-scheduler';
import { ISchedulerState } from '../types/ISchedulerState';
import { ISchedulerParseEvent } from '../types/ISchedulerParseEvent';
import { ISchedulerEvent } from '../types/ISchedulerEvent';
import { EventType } from '../slice/types';
import { omit } from 'lodash';
import { ITrainingSessionSlotDto } from 'api/odata/generated/entities/ITrainingSessionSlotDto';
import { IWorkingHoursDto } from 'api/odata/generated/entities/IWorkingHoursDto';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';

/**
 * Syncs the event provided from outside of the scheduler (props,state,etc.) into the scheduler instance
 * @param scheduler Scheduler instance
 * @param type event type
 * @param items events array
 * @param mapfn mapping from event to the type parsable by the scheduler
 * @returns nothing
 */
export function syncSchedulerEvents(
  scheduler: DHTMLX.SchedulerStatic | undefined,
  items: Array<ISchedulerParseEvent> | undefined,
) {
  if (scheduler === undefined) {
    return;
  }

  // events internally stored on the scheduler instance (not a part of a public API)
  const _events = (scheduler as any)['_events'] as Record<string | number, any>;
  const mappedEvents = items ?? [];

  //cleanup events
  const mappedEventIds = new Set(mappedEvents.map(f => f.id));
  // clear currently displayed items on the current period
  // and replace these with the fresh data from the store
  // events need to be deleted in case and new filter has been applied
  const state: ISchedulerState = scheduler.getState();
  scheduler
    .getEvents(state.min_date, state.max_date)
    .filter(
      (f: ISchedulerEvent) =>
        typeof f.id === 'number' || !mappedEventIds.has(f.id),
    )
    .filter(event => event.type !== undefined)
    .forEach(event => delete _events[event.id]);
  if (items !== undefined && items.length > 0) {
    //push items into the the scheduler
    scheduler.parse(mappedEvents);
  }
}

export function mapEvents<
  TIn extends object =
    | ITrainingSessionSlotDto
    | IWorkingHoursDto
    | ICalendarReservationDto,
>(
  type: EventType,
  items: Array<TIn> | undefined,
  mapfn: (
    item: TIn,
  ) => Omit<
    ISchedulerParseEvent,
    'original' | 'readonly' | 'notClickable' | 'selectable'
  >,
) {
  const mappedEvents =
    items?.map(item => {
      const original: Omit<TIn, 'Id'> = omit<TIn>(item, 'Id') as Omit<
        TIn,
        'Id'
      >;
      const result = {
        type: type,
        ...mapfn(item),

        original: original,
      };
      return result;
    }) ?? [];
  return mappedEvents;
}
