/**
 *
 * ChipLabelGroup
 *
 */
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@material-ui/core';
import * as React from 'react';
import { BookitChipWithInfo } from '.';
import { CheckEntity, ChipEntity } from '../CheckBoxGroup';

export interface ChipLabelGroupProps {
  options: ChipEntity[];
  value?: ChipEntity[];
  className?: string;
  onChipClick?: (chip: ChipEntity) => void;
  onChange?: (
    value: CheckEntity,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  id: string;
  //formik
  name?: string;
  error?: boolean;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  info?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  margin?: 'none' | 'dense' | 'normal';
  isRow?: boolean;
  style?: React.CSSProperties;
}

export function ChipLabelGroup({
  name,
  id,
  value,
  options,
  onChange,
  label,
  onChipClick,
  required,
  helperText,
  disabled,
  fullWidth,
  margin,
  error,
  size,
  isRow,
  style,
  ...props
}: ChipLabelGroupProps) {
  //   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const value = {
  //       Id: parseInt(event.target.id),
  //       Name: event.target.name,
  //       checked: event.target.checked,
  //     } as CheckEntity;
  //     if (onChange !== undefined) {
  //       onChange(value, event);
  //     }
  //   };
  return (
    <FormControl
      required={required}
      error={error}
      component="fieldset"
      name={name}
      margin={margin}
      id={id}
      variant="filled"
      fullWidth={fullWidth}
      className="chip-form-control"
    >
      <FormLabel className={'chip-label'}>{label}</FormLabel>
      <FormGroup row={isRow} className="chip-form-group">
        {options.map((item, index) => (
          <FormControlLabel
            className="chip-form-label"
            value={item}
            // key={`control_${item.Id}`}
            disabled={disabled || item.disabled}
            label=""
            control={
              <BookitChipWithInfo
                onClick={() => !!onChipClick && onChipClick(item)}
                //fullwidth={fullWidth}
                variant="default"
                size={size || 'small'}
                label={item.Name}
                key={`chip-${item.Id}`}
                customwidth={260}
                info={item.info}
              />
            }
          />
        ))}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
