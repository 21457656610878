import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ConvertEntityToModelUserAttributesDetails,
  UserAttributesDetailsQStringParameters,
  UserAttributesDetailsResponse,
  UserAttributesDetailsState,
} from './types';
import { userAttributesDetailsActions as actions } from '.';
import { UserAttributesApi } from '../../../../../../api/UserAttributesApi';
import { IUserAttributesDto } from 'api/odata/generated/entities/IUserAttributesDto';
import { appSettingsActions } from 'app/slice';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { UserAttributesDetailsProps } from '..';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { AxiosError } from 'axios';

function* doInitCreate(
  action: PayloadAction<UserAttributesDetailsQStringParameters>,
) {
  try {
    const data = yield call(UserAttributesApi.initCreateData, action.payload);
    yield put(actions.initUserAttributesDetails_Success(data));
  } catch (error: unknown) {
    yield put(actions.initUserAttributesDetails_Error(error));
  }
}

function* doInitUpdate(
  action: PayloadAction<UserAttributesDetailsQStringParameters>,
) {
  try {
    const data = yield call(UserAttributesApi.initUpdateData, action.payload);
    yield put(actions.initUpdateUserAttributesDetails_Success(data));
  } catch (error: unknown) {
    yield put(actions.initUpdateUserAttributesDetails_Error(Error));
  }
}

function* doCreate(action: PayloadAction<UserAttributesDetailsState>) {
  const httpPayloads: IUserAttributesDto =
    ConvertEntityToModelUserAttributesDetails(action.payload, undefined);
  try {
    const result = yield call(
      UserAttributesApi.insertUserAttributesDetails,
      httpPayloads,
    );
    let response: UserAttributesDetailsResponse =
      result as UserAttributesDetailsResponse;

    if (response.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.ErrorMessages.map(item => {
            return {
              key: 'userAttributesInsert',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'userAttributesInsert',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      if (response.Id) {
        yield put(
          appSettingsActions.addNotification({
            key: 'userAttributesInsert',
            message: response.SuccessMessages[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id,
              created: true,
              itemName: i18next.t(translations.menu_UserAttributes),
              detailsType: RenderPageType.UserAttributesDetails,
              detailsTypeProps: {
                queryParams: {
                  id: '' + response.Id,
                } as UserAttributesDetailsQStringParameters,
                useSidePanel: true,
              } as UserAttributesDetailsProps,
            },
            variant: 'success',
          }),
        );
        yield put(layoutActions.setRefreshTable(true));
      } else {
        yield put(
          appSettingsActions.addNotification({
            key: 'userAttributesInsertSuccess',
            message: response.SuccessMessages[0],
            variant: 'success',
          }),
        );
        yield put(layoutActions.setRefreshTable(true));
      }
    }

    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.createUserAttributesDetails_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'userAttributesInsert',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createUserAttributesDetails_Error(Error));
  }
}

function* doUpdate(
  action: PayloadAction<{
    current: UserAttributesDetailsState;
    original: UserAttributesDetailsState | undefined;
    syncSSOAttributeValue?: boolean;
  }>,
) {
  const httpPayloads: IUserAttributesDto =
    ConvertEntityToModelUserAttributesDetails(
      action.payload.current,
      action.payload.original,
    );
  httpPayloads.SsoAttrSyncedWithUser = action.payload.syncSSOAttributeValue;
  try {
    const results = yield call(
      UserAttributesApi.updateUserAttributesDetails,
      httpPayloads,
    );
    let response = results as UserAttributesDetailsResponse;
    let respErrors = response.ErrorMessages;
    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: 'userAttributesUpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'userAttributesUpdateWarn',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotification({
          key: 'userAttributesUpdate',
          message: response.SuccessMessages[0],
          messageType: SnackBarMessageType.openSidepanelDetails,
          messageTypeProps: {
            Id: response.Id,
            created: false,
            itemName: i18next.t(translations.menu_UserAttributes),
            detailsType: RenderPageType.UserAttributesDetails,
            detailsTypeProps: {
              queryParams: {
                id: '' + response.Id,
              } as UserAttributesDetailsQStringParameters,
              useSidePanel: true,
            } as UserAttributesDetailsProps,
          },
          variant: 'success',
        }),
      );
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.updateUserAttributesDetails_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'userAttributesUpdateErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateUserAttributesDetails_Error(Error));
  }
}

export function* userAttributesDetailsSaga() {
  yield takeLatest(actions.initUserAttributesDetails.type, doInitCreate);
  yield takeLatest(actions.initUpdateUserAttributesDetails.type, doInitUpdate);
  yield takeLatest(actions.createUserAttributesDetails.type, doCreate);
  yield takeLatest(actions.updateUserAttributesDetails.type, doUpdate);
}
