/**
 *
 * ControlledTable
 *
 */
import * as React from 'react';
import {
  LinearProgress,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import {
  useTable,
  usePagination,
  useSortBy,
  UseSortByState,
  UseSortByInstanceProps,
  UsePaginationState,
  PluginHook,
  useGlobalFilter,
  useExpanded,
  Row,
  HeaderGroup,
  SortingRule,
} from 'react-table';
import TablePaginationActions from 'app/components/BasicTable/TablePaginationActions';
import { GlobalFilter } from 'app/components/BasicTable/GlobalFilter';
import { IRow } from 'app/components/BasicTable/IRow';
import { BasicFilterGroup } from 'app/components/BasicTable/BasicFilter';
import { useState } from 'react';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { DetectIsMobile } from 'utils/mobileDetect';
import { toRootedURL } from 'utils/url-utils';
import { Button } from 'app/components//BasicButtons/Button';
import { TableHeaderIcon } from 'app/components//BasicIcons/SvgIcons';
import { Icon } from 'app/components//BasicIcons/FontAwesome';
import { TableToolbar } from 'app/components/BasicTable/BasicFilter/TableToolbar';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'app/slice/selectors';
//import { Progress } from '../LoadingIndicator';
import { BasicTableProps } from '..';
import { ExportButton } from 'app/components/BasicTable/ExportButton';
import { orderBy, sortedUniq } from 'lodash';
import { FilterContainer } from '../BasicFilter/FilterContainer';
import { PageActionRenderer2 } from '../PageActions/PageActionsRenderer';
import { FilterIconButton } from 'app/components/BasicButtons/FilterIconButton';
import { TableRowsSkeleton } from '../components/TableSkeleton';
import { AlertContentType, BasicAlert } from 'app/components/BasicAlert';
import { ControlledTableProps } from './ControlledTableProps';
import { SelectedActions } from '../SelectedActions';
import { FiltersGroupDisplay } from '../BasicFilter/FiltersGroupDisplay';
import { NoTableData } from '../components/NoTableData';
import { useMemoFilters } from '../useMemoFilters';
import { useInlineRowActionsColumn, useRowActionsColumn } from '../RowActions';
import { useSummary } from '../Hooks/useSummary';
import { TableError } from '../components/TableError';
import { FiltersButton } from '../BasicFilter/FiltersButton';
import { TableCardGrid } from '../components/TableCardGrid';
import { TableCard } from '../components/TableCard';
import { ConfigureColumnsButton } from '../components/ConfigureColumnsButton';
import { TabsToolbar } from '../components/TabsToolbar';
import { selectActionTableProcessing } from 'app/Layout/FrontendLayout/slice/selectors';
import { FloatingActions } from '../components/FloatingActions';
import clsx from 'clsx';
import { ReactActions } from 'app/components/ReactActions';
import { CustomToolbar } from '../components/CustomTableToolbar';
import { RenderRow } from './RenderRow';
import { CustomEmptyTable } from '../components/CustomEmptyTable';
import DefaultRenderer from '../CellRenderers/DefaultRenderer';
import { ScreensId } from 'enums/ConfigurableTypes';
import { TableHeaderCellLabel } from './TableCell';
import { RenderGroupedRow } from './RenderGroupedRow';
import { selectGlobalSearchOpen } from 'app/pages/PublicAssets/slice/selectors';
import { useAppSettingsSlice } from 'app/slice';
import { ImportButton } from '../ImportButton';
import { Notification } from 'types/Notification';
import { addRowSelect } from './addRowSelect';
import { TableRoot } from '../styled';
import { Div100 } from 'app/components/AssetQuickSearch/styled';

export type { IRow };
export type { BasicTableProps };

type IPartialTableState<TRow extends IRow> = Partial<
  UseSortByState<TRow> & UsePaginationState<TRow> & UseSortByInstanceProps<TRow>
>;
export type TableMode = 'card' | 'table';
/**
 * Interface to the (initial) table state
 */
export interface IControlledTableState<TRow extends IRow>
  extends IPartialTableState<TRow> {}

// todo: enable row accessor for rowActions
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultRowIdAccessor = <TRow extends IRow>(row: TRow): string =>
  row.Id?.toString();

const defaultGetSubRows = original =>
  original.Rows && original.Rows.length > 1 ? original.Rows : [];
export const defaultCheckIfRowIsSelectable = <TRow extends IRow>(
  row: TRow,
): boolean => true;
/**
 * Server side driven Table component that will be reused in most of the pages
 * @param param0
 * @returns
 */
export function ControlledTable<TRow extends IRow>({
  columns,
  initialState,
  getRowId = defaultRowIdAccessor,
  searchColumns,
  useExport = true,
  onDownloadTemplate,
  rowActions,
  inlineRowActions,
  pageActions,
  screenName,
  screenNameVariant = 'H1',
  selectedRowsActions,
  filterOpen = false,
  screenId,
  serviceGroups,
  customParams,
  useImportLink,
  importLink,
  importHandler,
  ServiceGroupsOptional,
  topAlertMessage,
  onChangeState,
  onSelectedRowsChange,
  onExport,
  compact = true,
  updateMyData,
  loading,
  tableRowsSize = 'small',
  getSubRows = defaultGetSubRows,
  savedListSettings,
  withToolBar = true,
  allowCards,
  useCardsByDefault,
  onModeChange,
  viewCardsCheck,
  floatingTabSettings,
  tabSectionSettings,
  isRowSelectable,
  onSelectedChange,
  hideMenuOnMobile,
  useConfigurable = true,
  customToolbarSettings,
  showTitleCell,
  getAllFilteredRowsCallback,
  useExportForImport,
  exportForImportFilterKey,
  customEmptyTableSettings,
  manualPagination = true,
  manualSorting = true,
  enablePagination = true,
  onRowClicked,
  printing,
  alwaysShowTableHeader,
  hideFilters,
  baseTableActionsOnToolbar,
  baseSortBy,
  showGlobalSearchIconOnToolbar,
  toggleGlobalSearchIcon,
  customExpandMenu,
  customScreenName,
  getSavedDefaultFilters,
  importMenuItems,
  isContentEditable,
  cardPosition = 'vertical',
  tablePaddingVariant = 'regular',
  minCardHeight = 'md',
  useCustomSearch,
  ns,
  fitContent,
  ...props
}: ControlledTableProps<TRow>) {
  const { t } = useTranslation(ns);
  const isMobile = DetectIsMobile();
  const dispatch = useDispatch();
  const { actions: globalActions } = useAppSettingsSlice();
  // collapsed state of the filters
  // reference to the show/hide filters button
  const [filtersButtonAnchorEl, setFiltersButtonAnchorEl] =
    React.useState(null);
  // collapsed state of the filters
  const [open, setOpen] = React.useState<boolean>(false);
  // data length - total number of the records
  const dataLength = React.useMemo(() => {
    return props.dataLength ?? 0;
  }, [props.dataLength]);
  // table Plugins array assembly
  const tablePlugins: PluginHook<TRow>[] = [
    useSummary,
    useGlobalFilter,
    useSortBy,
    useExpanded,
  ];
  if (enablePagination) {
    tablePlugins.push(usePagination);
  }
  if (searchColumns !== undefined && searchColumns?.length > 0) {
    tablePlugins.unshift(useGlobalFilter);
  }

  if (props.useRowSelect) {
    addRowSelect<TRow>(tablePlugins, isRowSelectable, onSelectedChange);
  }
  useInlineRowActionsColumn<TRow>(inlineRowActions, tablePlugins);
  useRowActionsColumn<TRow>(rowActions, tablePlugins);
  const columnsDefinition = React.useMemo(() => {
    const columnDefaults = {
      Cell: DefaultRenderer,
    };
    return columns.map(column => ({
      ...columnDefaults,
      ...column,
    }));
  }, [columns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows: reactTableRows,
    page: reactTablePage,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    // todo: make use of the selectedRowIds
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: { pageIndex, pageSize, sortBy, selectedRowIds, globalFilter },
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleRowSelected,
    isAllRowsSelected,
    summaryRows,
    setSortBy,
  } = useTable(
    {
      columns: columnsDefinition,
      data: props.data,
      updateMyData,
      initialState,
      // server side controlled pagination
      manualPagination: manualPagination,
      // server side controlled sorting
      manualSortBy: manualSorting,
      // server side controlled global filter
      manualGlobalFilter: true,
      //When true, the selectedRowIds state will automatically reset if any of the following conditions are met:
      autoResetSelectedRows: false,
      //When true, the paging is always reset to initial pageIndex state.
      autoResetPage: false,
      // controlled page count (server sided pagination)
      pageCount: props.pageCount,
      getRowId: getRowId,
      getSubRows,
    },
    ...tablePlugins,
  );
  const rows = React.useMemo(() => {
    return enablePagination ? reactTablePage : reactTableRows;
  }, [enablePagination, reactTablePage, reactTableRows]);

  useEffect(() => {
    if (baseSortBy !== undefined) {
      setSortBy(baseSortBy);
    }
  }, [baseSortBy, setSortBy]);

  useEffect(() => {
    if (initialState?.pageSize !== undefined) {
      setPageSize(initialState.pageSize);
    }
  }, [initialState?.pageSize, setPageSize]);

  useEffect(() => {
    if (initialState?.globalFilter !== undefined) {
      setGlobalFilter(initialState.globalFilter);
    }
  }, [initialState?.globalFilter, setGlobalFilter]);
  // useEffect(() => {
  //   if (initialState?.pageIndex !== undefined) {
  //     gotoPage(initialState.pageIndex);
  //   }
  // }, [gotoPage, initialState?.pageIndex]);

  /**
   * Track selected original row values. selectedRowIds only stores the row Ids.
   * selectedFlatRows only holds selected rows from the current page.
   */
  const isSomeSelected = React.useMemo(() => {
    return Object.keys(selectedRowIds || {}).length > 0;
  }, [selectedRowIds]);
  const [selected, setSelected] = useState<Record<any, TRow>>({});
  useEffect(() => {
    setSelected(state =>
      Object.keys(selectedRowIds || {}).reduce((accumulator, key) => {
        accumulator[key] =
          state[key] ??
          selectedFlatRows
            .filter(isRowSelectable || defaultCheckIfRowIsSelectable)
            .find(row => row.id === key)?.original;
        return accumulator;
      }, {}),
    );
  }, [selectedRowIds, selectedFlatRows, isRowSelectable]);
  const selectedArray = React.useMemo(() => {
    return Object.entries(selected).map(value => value[1]);
  }, [selected]);

  const appliedFiltersLength = React.useMemo(() => {
    return props.appliedFilters && props.appliedFilters.length > 0
      ? props.appliedFilters.filter(
          f => f.value !== null && f.value !== undefined && f.value !== '',
        ).length
      : 0;
  }, [props.appliedFilters]);

  const handleFilterChange = React.useCallback(
    (items: IFilterSettings<TRow>[]) => {
      props.onFilterChange?.(items);
      if (items.length !== appliedFiltersLength) {
        gotoPage(0);
      }
    },
    [appliedFiltersLength, gotoPage, props],
  );
  const [alreadySelected, setAlreadySelected] = React.useState<Row<TRow>[]>(
    selectedFlatRows || [],
  );
  useEffect(() => {
    let active =
      onSelectedRowsChange !== undefined &&
      selectedFlatRows !== undefined &&
      selectedFlatRows.length !== alreadySelected.length;
    if (active) {
      !!onSelectedRowsChange &&
        onSelectedRowsChange(
          selectedFlatRows
            .filter(isRowSelectable || defaultCheckIfRowIsSelectable)
            .map(row => row.original),
        );
      setAlreadySelected(selectedFlatRows);
    }
    return () => {
      active = false;
    };
  }, [
    alreadySelected.length,
    isRowSelectable,
    onSelectedRowsChange,
    selectedFlatRows,
  ]);
  // page change handler
  const handlePageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void = (e, page) => {
    gotoPage(page < 0 ? 0 : page);
  };
  const handlePageSizeChange = newPageSize => {
    // setControlledPageCount(Math.ceil(dataLength / newPageSize));
    setPageSize(newPageSize);
  };
  const filterRef = React.useRef(null);
  const handleFilterOpen = React.useCallback(() => {
    setFiltersButtonAnchorEl(filterRef.current);
    setOpen(!open);
  }, [open]);

  const AuthenticatedUser = useSelector(selectAuthenticatedUser);
  const customProcesing = useSelector(selectActionTableProcessing);
  const globalSearchOpen = useSelector(selectGlobalSearchOpen);
  const showGlobalFilter = React.useMemo(() => {
    return (
      (searchColumns !== undefined &&
        searchColumns?.length > 0 &&
        !hideFilters) ||
      useCustomSearch
    );
  }, [hideFilters, searchColumns, useCustomSearch]);

  const defaultPageActions: PageActionRenderer2<TRow>[] = React.useMemo(() => {
    const actions: PageActionRenderer2<TRow>[] = [];
    if (compact && props.availableFilters !== undefined && !hideFilters) {
      actions.push((dataCount, refresh) => (
        <FilterIconButton
          filterLength={appliedFiltersLength}
          handleClick={handleFilterOpen}
          innerRef={filterRef}
        />
      ));
    }
    if (pageActions !== undefined) {
      if (pageActions.length > 0) {
        pageActions.forEach(element => {
          actions.push(element);
        });
      }
    }
    return actions;
  }, [
    appliedFiltersLength,
    compact,
    handleFilterOpen,
    hideFilters,
    pageActions,
    props.availableFilters,
  ]);

  const handleExportClick = React.useCallback(async () => {
    await onExport();
  }, [onExport]);

  const rowsPerPageOptions = sortedUniq(
    orderBy([5, 10, 25, 100].concat(initialState?.pageSize ?? [])),
  );
  const handleSortBy = React.useCallback(
    (event: any, column: HeaderGroup<TRow>) => {
      const columnId = column.id;
      let sortAction = '';
      let newSortBy: SortingRule<TRow>[] = [];
      let sortDescFirst = column.sortDescFirst;
      let desc = column.isSortedDesc;
      const existingSortBy = sortBy.find(d => d.id === columnId);
      const existingIndex = sortBy.findIndex(d => d.id === columnId);
      const hasDescDefined = typeof desc !== 'undefined' && desc !== null;
      if (existingIndex !== sortBy.length - 1 || sortBy.length !== 1) {
        sortAction = 'replace';
      } else if (existingSortBy) {
        sortAction = 'toggle';
      } else {
        sortAction = 'replace';
      }
      if (sortAction === 'replace') {
        newSortBy = [
          {
            id: columnId,
            desc: hasDescDefined ? desc : sortDescFirst,
          },
        ];
      } else if (sortAction === 'add') {
        newSortBy = [
          ...sortBy,
          {
            id: columnId,
            desc: hasDescDefined ? desc : sortDescFirst,
          },
        ];
      } else if (sortAction === 'toggle') {
        // This flips (or sets) the
        newSortBy = sortBy.map(d => {
          if (d.id === columnId) {
            return {
              ...d,
              desc: hasDescDefined ? !desc : !existingSortBy?.desc,
            };
          }
          return d;
        });
      } else if (sortAction === 'remove') {
        newSortBy = sortBy.filter(d => d.id !== columnId);
      }
      // Object.assign({}, sortBy, newSortBy)
      setSortBy(newSortBy);
    },
    [setSortBy, sortBy],
  );
  React.useEffect(() => {
    if (props.allRowsSelected !== undefined) {
      toggleAllRowsSelected?.(props.allRowsSelected);
    }
  }, [props.allRowsSelected, toggleAllRowsSelected]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const s = React.useMemo(() => searchColumns, []);
  const customFilters = useMemoFilters({
    appliedFilters: props.appliedFilters,
  });

  const sortByActual = React.useMemo(() => JSON.stringify(sortBy), [sortBy]);
  React.useEffect(() => {
    const state = {
      pageIndex,
      pageSize,
      sortBy: JSON.parse(sortByActual) as SortingRule<TRow>[],
      globalFilter,
      customFilters: customFilters,
      searchColumns: s,
    };

    onChangeState(state);
  }, [
    onChangeState,
    globalFilter,
    pageIndex,
    pageSize,

    sortByActual,
    customFilters,
    s,
  ]);

  //used to add top message as Alert on the page if needs
  const [topMessage, setTopMessage] = React.useState<
    AlertContentType | undefined
  >(undefined);
  const setGlobalMessage = React.useCallback(
    (message: Notification) => {
      dispatch(globalActions.addNotification(message));
    },
    [dispatch, globalActions],
  );
  React.useEffect(() => {
    if (ServiceGroupsOptional && !!serviceGroups && serviceGroups.length > 1) {
      setGlobalMessage({
        key: 'NotRelatedServiceGroups',
        message: t(translations.ShowNotRelatedServiceGroups_warning) as string,
        variant: 'warning',
        autoHideDuration: 30000,
        closable: true,
      });
    } else if (!!topAlertMessage) {
      setTopMessage(() => {
        return topAlertMessage;
      });
    }
  }, [
    ServiceGroupsOptional,
    serviceGroups,
    setGlobalMessage,
    t,
    topAlertMessage,
  ]);
  const [isCards, setIsCards] = useState(
    useCardsByDefault || (isMobile && allowCards !== false),
  );
  useEffect(() => {
    setIsCards(useCardsByDefault || (isMobile && allowCards !== false));
  }, [allowCards, isMobile, useCardsByDefault]);
  useEffect(() => {
    if (allowCards) {
      onModeChange?.(isCards === true);
    }
  }, [allowCards, isCards, onModeChange]);
  const print = React.useMemo(() => {
    return printing !== undefined && printing.printing === true;
  }, [printing]);
  useEffect(() => {
    if (printing !== undefined && print && printing.onLoading !== undefined) {
      printing.onLoading(printing.id, loading);
    }
  }, [loading, print, printing]);
  const error = React.useMemo(() => {
    return (props.error ?? false) === true;
  }, [props.error]);
  const baseTableActions: PageActionRenderer2<TRow>[] = React.useMemo(() => {
    const actions: PageActionRenderer2<TRow>[] = [];
    if (screenId && AuthenticatedUser && useConfigurable && !isMobile) {
      actions.push(dataCount => (
        <ConfigureColumnsButton onClick={props.onConfigurableColumns} />
      ));
    }
    if (useExport && !isMobile) {
      actions.push(dataCount => (
        <ExportButton
          onClick={handleExportClick}
          //selectedRows={selectedFlatRows}
          useExportForImport={useExportForImport}
          selectedFilterRowProp={exportForImportFilterKey}
          onDownloadTemplateClick={onDownloadTemplate}
          screenId={screenId}
          getAllFilteredRowsCallback={getAllFilteredRowsCallback}
        />
      ));
    }
    if (
      useImportLink &&
      !isMobile &&
      !compact &&
      (importLink || importHandler || importMenuItems)
    ) {
      actions.push(dataCount => (
        <ImportButton
          menuItems={importMenuItems}
          onClick={() => {
            if (importHandler) {
              importHandler();
            } else {
              if (importLink) {
                window.open(toRootedURL(importLink), '_blank');
              }
            }
          }}
        />
      ));
    }
    if (allowCards && !isMobile) {
      //&& !compact
      actions.push(dataCount => (
        <Button
          variant={isCards ? 'white' : 'gray'}
          size="small"
          startIcon={<Icon icon="list-ul" />}
          onClick={() => setIsCards(false)}
        >
          {t(translations.Table)}
        </Button>
      ));
      actions.push(dataCount => (
        <Button
          variant={isCards ? 'gray' : 'white'}
          size="small"
          startIcon={<Icon icon="grip-horizontal" />}
          onClick={() => setIsCards(true)}
        >
          {t(translations.Cards)}
        </Button>
      ));
    }
    return actions;
  }, [
    AuthenticatedUser,
    allowCards,
    compact,
    exportForImportFilterKey,
    getAllFilteredRowsCallback,
    handleExportClick,
    importHandler,
    importLink,
    importMenuItems,
    isCards,
    isMobile,
    onDownloadTemplate,
    props.onConfigurableColumns,
    screenId,
    t,
    useConfigurable,
    useExport,
    useExportForImport,
    useImportLink,
  ]);
  const table_id = React.useMemo(
    () =>
      props.id ??
      (screenId === undefined ? undefined : ScreensId[screenId]) ??
      'table',
    [props.id, screenId],
  );
  const tableHead: React.ReactNode = React.useMemo(() => {
    let result: React.ReactNode = (
      <TableHead id={`${table_id}_head`}>
        {
          // loop over header groups
          headerGroups.map(headerGroup => (
            // apply header group props
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {
                // loop over headers
                headerGroup.headers.map((column, i) => (
                  // apply header props
                  // disableSortBy controls the sortability
                  <TableCell
                    variant="head"
                    component={props.useRowSelect && i === 0 ? 'td' : 'th'}
                    {...(column.disableSortBy
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                    onClick={ev =>
                      !column.disableSortBy &&
                      column.id !== 'selection' &&
                      handleSortBy(ev, column)
                    }
                  >
                    <TableSortLabel
                      IconComponent={TableHeaderIcon}
                      active={column.isSorted}
                      hideSortIcon={
                        (props.useRowSelect && i === 0) ||
                        column.id === 'selection' ||
                        column.disableSortBy
                      }
                      aria-label="Sorting"
                      title={
                        typeof column.Header === 'string' ? column.Header : ''
                      }
                      onClick={ev =>
                        // column.toggleSortBy(
                        //   !column.isSortedDesc,
                        //   undefined,
                        // )
                        !column.disableSortBy &&
                        column.id !== 'selection' &&
                        handleSortBy(ev, column)
                      }
                      // react-table has a unsorted state which is not treated here
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    >
                      <TableHeaderCellLabel>
                        {column.render('Header')}
                      </TableHeaderCellLabel>
                    </TableSortLabel>
                  </TableCell>
                ))
              }
            </TableRow>
          ))
        }
      </TableHead>
    );
    return result;
  }, [handleSortBy, headerGroups, props.useRowSelect, table_id]);
  return (
    <React.Fragment>
      <React.Fragment>
        {customProcesing !== undefined &&
          customProcesing.processing === true && (
            <Div100>
              <LinearProgress variant="query" color="primary" />
            </Div100>
          )}

        <TableRoot
          className={clsx('table-root', {
            'section-table': props.tableContainerAsSection,
            fitContent,
          })}
          key="table-container"
          id={`${table_id}_table_container`}
        >
          {withToolBar && (
            <TableToolbar
              screenName={screenName}
              screenNameVariant={screenNameVariant}
              onRefresh={props.onRefresh}
              loading={loading}
              showMenu={!hideMenuOnMobile}
              compact={compact}
              customExpandMenu={customExpandMenu}
              customScreenName={customScreenName}
              showGlobalSearchIcon={showGlobalSearchIconOnToolbar}
              toggleGlobalFilter={toggleGlobalSearchIcon}
              variant={tablePaddingVariant}
            >
              {print === false &&
                defaultPageActions.length > 0 &&
                defaultPageActions.map((item, index) => (
                  <span key={index}>
                    {item(
                      dataLength,
                      props.onRefresh,
                      props.appliedFilters,
                      selectedArray,
                    )}
                  </span>
                ))}
              {baseTableActionsOnToolbar &&
                baseTableActions.length > 0 &&
                baseTableActions.map((item, index) => (
                  <span key={'base_actions_' + index}>
                    {item(
                      dataLength,
                      props.onRefresh,
                      props.appliedFilters,
                      selectedArray,
                    )}
                  </span>
                ))}
            </TableToolbar>
          )}
          {print === false ? (
            <>
              {props.subHeader !== undefined && (
                <div className={'table-sub-header'}>{props.subHeader}</div>
              )}
              {!isMobile && !compact && topMessage !== undefined && (
                <div className={'table-sub-header'}>
                  <BasicAlert
                    content={topMessage}
                    showMore
                    collapsedMinHeight={60}
                  />
                </div>
              )}
              {props.availableFilters !== undefined && !hideFilters && (
                <FilterContainer
                  open={open}
                  onClose={() => setOpen(false)}
                  onChange={handleFilterChange}
                  anchorEl={filtersButtonAnchorEl}
                  appliedFilters={props.appliedFilters ?? []}
                  compact={compact}
                >
                  <BasicFilterGroup
                    getSavedDefaultFilters={getSavedDefaultFilters}
                    availableFilters={props.availableFilters || []}
                    onChange={handleFilterChange}
                    appliedFilters={props.appliedFilters || []}
                    onClose={() => setOpen(false)}
                    autoOpenAddFilter={true}
                    showAddResetButtons={true} //!isMobile && !compact
                    savedListSettings={savedListSettings}
                  />
                </FilterContainer>
              )}
              {!error &&
                (showGlobalFilter || useExport || screenId) &&
                !baseTableActionsOnToolbar && (
                  <>
                    <div
                      className={clsx('table-buttons', {
                        'table-buttons-short': isMobile || compact,
                        'table-padding-dense': tablePaddingVariant === 'dense',
                      })}
                    >
                      <div
                        className={clsx('table-buttons-left', {
                          'table-buttons-left-wrap':
                            isSomeSelected &&
                            selectedRowsActions !== undefined &&
                            selectedRowsActions.length > 0,
                          'table-buttons-left-no-wrap':
                            (isSomeSelected &&
                              selectedRowsActions !== undefined &&
                              selectedRowsActions.length > 0) === false,
                        })}
                      >
                        {isSomeSelected &&
                        selectedRowsActions !== undefined &&
                        selectedRowsActions.length > 0 ? (
                          <SelectedActions
                            onRefresh={props.onRefresh}
                            actions={selectedRowsActions || []}
                            selectedArray={selectedArray}
                            toggleAllRowsSelected={toggleAllRowsSelected}
                            totalRows={dataLength}
                            shortView={isMobile || compact}
                          />
                        ) : (
                          <>
                            {(showGlobalSearchIconOnToolbar === true
                              ? globalSearchOpen
                              : showGlobalFilter) && (
                              <GlobalFilter
                                globalFilter={globalFilter}
                                setGlobalFilter={setGlobalFilter}
                                id={`${table_id}_search`}
                              />
                            )}
                            {!compact &&
                              !hideFilters &&
                              (props.availableFilters ?? []).length > 0 && (
                                <FiltersButton
                                  onClick={handleFilterOpen}
                                  appliedFiltersLength={appliedFiltersLength}
                                  innerRef={filterRef}
                                  id={`${table_id}_filter`}
                                />
                              )}
                          </>
                        )}
                        {(isMobile || compact) && (
                          <ReactActions
                            id="baseTableActions"
                            size="small"
                            items={baseTableActions.map((item, index) => (
                              <span key={'base_actions_' + index}>
                                {item(
                                  dataLength,
                                  props.onRefresh,
                                  props.appliedFilters,
                                  selectedArray,
                                )}
                              </span>
                            ))}
                          ></ReactActions>
                        )}
                      </div>
                      {!isMobile && !compact && (
                        <div
                          className={clsx('table-buttons-right', {
                            'table-buttons-right-short': isMobile || compact,
                          })}
                        >
                          {!baseTableActionsOnToolbar &&
                            baseTableActions.length > 0 &&
                            baseTableActions.map((item, index) => (
                              <span key={'base_actions_' + index}>
                                {item(
                                  dataLength,
                                  props.onRefresh,
                                  props.appliedFilters,
                                  selectedArray,
                                )}
                              </span>
                            ))}
                        </div>
                      )}
                    </div>
                    {!isMobile &&
                      !compact &&
                      (props.appliedFilters ?? []).filter(
                        f => f.value !== undefined,
                      ).length > 0 && (
                        <div
                          className={clsx('table-filter-display-root', {
                            'table-filter-display-short': isMobile || compact,
                          })}
                        >
                          <FiltersGroupDisplay
                            appliedFilters={props.appliedFilters ?? []}
                            onChange={handleFilterChange}
                            openFilter={handleFilterOpen}
                          />
                        </div>
                      )}
                  </>
                )}
              {!!customToolbarSettings &&
                customToolbarSettings.useCustomToolbar &&
                !!customToolbarSettings.CustomToolbar && (
                  <CustomToolbar
                    Component={customToolbarSettings.CustomToolbar}
                    compact={compact}
                  />
                )}

              {((!!tabSectionSettings && tabSectionSettings.useTabsSection) ||
                (props.useRowSelect && isCards)) && (
                <TabsToolbar
                  rowsCount={dataLength}
                  rows={rows}
                  leftTabs={tabSectionSettings?.leftTabs}
                  rightTabActions={tabSectionSettings?.rightTabActions}
                  selectedValue={tabSectionSettings?.selectedTabValue}
                  selectedArray={selectedArray}
                  toggleAllRowsSelected={toggleAllRowsSelected}
                  onRefresh={props.onRefresh}
                  appliedFilters={props.appliedFilters}
                  allRowsSelected={isAllRowsSelected}
                  isRowSelectable={
                    isRowSelectable || defaultCheckIfRowIsSelectable
                  }
                  getMultiMode={tabSectionSettings?.getMultiMode}
                  onAllRowsSelected={tabSectionSettings?.onAllRowsSelected}
                  disableMultiMode={tabSectionSettings?.disableMultiMode}
                  defaultMultiMode={
                    tabSectionSettings?.defaultMultiMode ?? true
                  }
                  showClear={tabSectionSettings?.showClear}
                  compact={compact}
                />
              )}
            </>
          ) : (
            <></>
          )}
          <div
            id={`${table_id}_content`}
            className={clsx('table-content', {
              'table-content-short': isMobile || compact,
              'table-padding-dense': tablePaddingVariant === 'dense',
              'table-cards': isCards,
            })}
          >
            {error ? (
              <TableError id={`${table_id}_error`} />
            ) : loading ? (
              <TableRowsSkeleton
                id={`${table_id}_skeleton`}
                isMobile={isMobile || compact}
                isCards={isCards === true || (isMobile && allowCards !== false)}
              />
            ) : dataLength === 0 && !loading ? (
              <React.Fragment>
                {alwaysShowTableHeader && !isCards && (
                  <div className={'table-scrolling'}>
                    <Table
                      {...getTableProps()}
                      className={'table'}
                      size={tableRowsSize}
                    >
                      {tableHead}
                      <TableBody {...getTableBodyProps()}></TableBody>
                    </Table>
                  </div>
                )}
                {!!customEmptyTableSettings ? (
                  <CustomEmptyTable
                    id={`${table_id}_nodata`}
                    headTitle={customEmptyTableSettings.headTitle}
                    title={customEmptyTableSettings.title}
                    customContent={customEmptyTableSettings.customContent}
                    shortView={isMobile || compact}
                  />
                ) : (
                  <NoTableData
                    id={`${table_id}_nodata`}
                    shortView={isMobile || compact}
                  />
                )}
              </React.Fragment>
            ) : (
              <>
                {!isCards ? (
                  <div className={'table-scrolling'}>
                    <Table
                      id={table_id}
                      {...getTableProps()}
                      className={clsx('table', {
                        'with-footer': enablePagination && !isCards,
                      })}
                      size={tableRowsSize}
                      role="table"
                      aria-label={screenName}
                    >
                      {tableHead}
                      <TableBody
                        id={`${table_id}_body`}
                        {...getTableBodyProps()}
                      >
                        {
                          // Loop over the table rows
                          rows.map((row, rowIndex) => {
                            // Prepare the row for display

                            prepareRow(row);
                            return (
                              <React.Fragment
                                key={`data-rows-group_page-${rowIndex}`}
                              >
                                {row.isGrouped &&
                                  row.subRows?.map(subRow => {
                                    prepareRow(subRow);
                                    return (
                                      <RenderRow
                                        row={subRow}
                                        onRefresh={props.onRefresh}
                                        onRowClicked={onRowClicked}
                                      />
                                    );
                                  })}
                                <RenderGroupedRow
                                  row={row}
                                  onRefresh={props.onRefresh}
                                  onRowClicked={onRowClicked}
                                />
                              </React.Fragment>
                            );
                          })
                        }
                        {summaryRows !== undefined &&
                          summaryRows.map(summaryRow => {
                            prepareRow(summaryRow);
                            return (
                              <RenderGroupedRow
                                row={summaryRow}
                                onRefresh={props.onRefresh}
                              />
                            );
                          })}
                      </TableBody>
                    </Table>
                  </div>
                ) : (
                  <TableCardGrid
                    id={table_id}
                    isMobile={isMobile}
                    cardPosition={cardPosition}
                    minCardHeight={minCardHeight}
                    compact={compact}
                  >
                    {rows.map((r, ind) => {
                      prepareRow(r);
                      return (
                        <TableCard
                          key={`card-${ind}`}
                          someSelected={
                            //isSomeSelected
                            selectedArray.length > 0
                            // (!!selectedRowsActions ||
                            //   (!!floatingTabSettings &&
                            //     floatingTabSettings.useFloatingActions ===
                            //       true &&
                            //     !!floatingTabSettings.selectedFloatingActions &&
                            //     floatingTabSettings.selectedFloatingActions
                            //       .length > 0)) &&
                            // selectedFlatRows.filter(
                            //   isRowSelectable || defaultCheckIfRowIsSelectable,
                            // ).length > 0
                          }
                          viewCheckBox={viewCardsCheck}
                          useSelect={
                            !!props.useRowSelect &&
                            (!!isRowSelectable
                              ? isRowSelectable(r)
                              : defaultCheckIfRowIsSelectable(r))
                          }
                          primaryAction={props.primaryAction}
                          row={r}
                          titleKey={props.titleKey ?? sortBy![0].id}
                          descriptionKey={props.descriptionKey}
                          imageSrcKey={props.imageSrcKey}
                          hasAdditionalActions={
                            !!rowActions && rowActions.length > 0
                          }
                          hasInlineRowActions={
                            !!inlineRowActions && inlineRowActions.length > 0
                          }
                          active={selectedFlatRows?.some(f => f.id === r.id)}
                          toggleAllRowsSelected={toggleAllRowsSelected}
                          isRowSelectable={
                            isRowSelectable || defaultCheckIfRowIsSelectable
                          }
                          cardSize={props.cardSize}
                          cardPosition={cardPosition}
                          showTitleCell={showTitleCell}
                          preventInnerClicks={props.preventInnerClicks}
                          compact={compact}
                          contentEditable={
                            !!isContentEditable
                              ? isContentEditable(r)
                              : undefined
                          }
                          loading={loading}
                        />
                      );
                    })}
                  </TableCardGrid>
                )}
                {print === false && enablePagination && (
                  <div
                    id={`${table_id}_footer`}
                    className={clsx('table-footer', {
                      'table-footer-border': !isCards && !(isMobile || compact),
                      'table-footer-short': compact && !isMobile,
                    })}
                  >
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={rowsPerPageOptions}
                      colSpan={headerGroups[0]?.headers?.length || 0}
                      count={dataLength}
                      rowsPerPage={pageSize}
                      page={pageIndex}
                      labelRowsPerPage={
                        isMobile || compact ? (
                          <></>
                        ) : (
                          <>{t(translations.RowsPerPage)}</>
                        )
                      }
                      SelectProps={{
                        label: t(translations.RowsPerPage) as string,
                        inputProps: {
                          'aria-label': `${pageSize || 'pagesize'}`,
                        },
                        native: true,
                        MenuProps: {
                          variant: 'menu',
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: { width: '160px' },
                          },
                        },
                        'aria-label': `${pageSize || 'pagesize'}`,
                      }}
                      labelDisplayedRows={p =>
                        t(
                          isMobile || compact
                            ? translations.PaginationMobile_Info
                            : translations.Pagination_Info,
                          p,
                        )
                      }
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={e =>
                        handlePageSizeChange(Number(e.target.value))
                      }
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </TableRoot>
      </React.Fragment>
      {print === false &&
        !!floatingTabSettings &&
        floatingTabSettings.useFloatingActions && (
          <FloatingActions
            onRefresh={props.onRefresh}
            rows={rows}
            selectedActions={floatingTabSettings.selectedFloatingActions || []}
            selectedArray={selectedArray}
            useSidePanel={floatingTabSettings.useSidePanel}
            toggleAllRowsSelected={toggleAllRowsSelected}
            totalRows={dataLength}
            buttonActions={floatingTabSettings.buttonFloatingActions}
            toggleRowSelected={toggleRowSelected}
            compact={compact}
          />
        )}
    </React.Fragment>
  );
}
