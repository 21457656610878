import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Roles } from 'api/odata/generated/enums/Roles';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAbsolute } from 'utils/url-utils';
import { selectInvoice } from '../Slice/selector';
import { Comments } from 'app/components/Comments';
import { useUploadToNewComment } from 'app/components/LargeFilesUpload/Components/useUploadToNewComment';

export const InvoiceComments = () => {
  const invoice = useSelector(selectInvoice);
  const { onCommentAdded } = useUploadToNewComment();

  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const isAdmin = useMemo(() => {
    return authenticatedUser?.Roles.includes(Roles.Administrators);
  }, [authenticatedUser]);
  const referenceId = invoice?.Id;
  const link = useAbsolute(`Invoices/Details/${referenceId}`);
  if (isAdmin === undefined || referenceId === undefined) {
    return null;
  }
  return (
    <Comments
      commentType={CommentReferenceTypes.Invoice}
      referenceId={referenceId}
      isAdmin={isAdmin}
      link={link}
      onCommentAdded={onCommentAdded}
    />
  );
};
