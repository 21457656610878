import { useDispatch, useSelector } from 'react-redux';
import { TerminateReservationButton } from './ReservationActions';
import * as React from 'react';
import {
  selectTerminateCompleted,
  selectTerminateProcessing,
} from '../slice/selectors';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { ReservationsApi } from 'api/ReservationsApi';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { EndUsageConfirm, EndUsageData } from 'app/components/EndUsageConfirm';
import { HasUsageEndRresult } from '../slice/types';
import { useAppSettingsSlice } from 'app/slice';

export interface TerminateReservationProps {
  text: string;
  reservationId: number;
  serviceId: number;
  isLoaanDesc: boolean;
  title?: string;
  onTerminate: () => void;
}

export function TerminateReservation(props: TerminateReservationProps) {
  const { text, reservationId, isLoaanDesc, title, onTerminate, serviceId } =
    props;
  const dispatch = useDispatch();
  const { actions: settingActions } = useAppSettingsSlice();
  const { t } = useTranslation();
  const terminateProcessing = useSelector(selectTerminateProcessing);
  const terminateCompleted = useSelector(selectTerminateCompleted);
  const [endUsageOfflineServiceIds, setEndUsageOfflineServiceIds] =
    React.useState<number[]>([]);
  const [showConfirmEndUsage, setShowConfirmEndUsage] =
    React.useState<boolean>(false);
  const [showEndUsage, setShowEndUsage] = React.useState<boolean>(false);
  const [usageEndProcessing, setUsageEndProcessing] = React.useState<
    boolean | undefined
  >(undefined);
  const [hasUsageProcessing, setHasUsageProcessing] = React.useState<
    boolean | undefined
  >(undefined);
  const handleTerminateClick = () => {
    (async () => {
      setHasUsageProcessing(true);
      ReservationsApi.hasUsage({ resId: reservationId, eqId: serviceId })
        .then(result => {
          let data = result as HasUsageEndRresult;
          if (data.HasUsage === true && !isLoaanDesc) {
            setShowConfirmEndUsage(true);
            setEndUsageOfflineServiceIds(data.OfflineServiceTypeIds);
          } else {
            onTerminate();
          }
          setHasUsageProcessing(false);
        })
        .catch(reason => {
          setHasUsageProcessing(false);
        });
    })();

    //check if has usage
    //then
    //if yes - show confirm to end usage

    //if confirmed - show usage end popup
    //else - cancel termination
    // if no - terminate;
  };

  const handleEndUsage = (usageData: EndUsageData) => {
    setUsageEndProcessing(true);
    (async () => {
      ReservationsApi.endUsage(reservationId, usageData)
        .then(response => {
          setUsageEndProcessing(false);
          if (response.ErrorMessages.length > 0) {
            response.ErrorMessages.forEach(errmsg =>
              dispatch(
                settingActions.addNotification({
                  variant: 'error',
                  message: errmsg,
                }),
              ),
            );
          } else {
            setShowEndUsage(false);
            onTerminate();
            if (response.SuccessMessages.length > 0) {
              response.SuccessMessages.forEach(msg =>
                dispatch(
                  settingActions.addNotification({
                    variant: 'success',
                    message: msg,
                  }),
                ),
              );
            }
            if (response.WarningMessages.length > 0) {
              response.WarningMessages.forEach(wrnmsg =>
                dispatch(
                  settingActions.addNotification({
                    variant: 'warning',
                    message: wrnmsg,
                  }),
                ),
              );
            }
          }
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => setUsageEndProcessing(false));
    })();
  };
  return (
    <React.Fragment>
      <TerminateReservationButton
        size="small"
        startIcon={<Icon icon="clock" />}
        onClick={e => handleTerminateClick()}
        disabled={
          terminateProcessing || usageEndProcessing || hasUsageProcessing
        }
        variant="white"
        processing={
          (terminateProcessing || usageEndProcessing || hasUsageProcessing) &&
          !terminateCompleted
        }
        title={title}
        text={text}
      />
      <DialogConfirm
        isOpen={showConfirmEndUsage}
        title={t(translations.TerminateReservation)}
        confirmButtonLabel={t(translations.TerminateReservationMobile)}
        body={t(translations.TerminateReservationWithUsage) as string}
        onConfirm={() => {
          setShowEndUsage(true);
          setShowConfirmEndUsage(false);
        }}
        onCancel={() => setShowConfirmEndUsage(false)}
        onClose={() => setShowConfirmEndUsage(false)}
        maxWidth="sm"
      />
      <EndUsageConfirm
        ids={endUsageOfflineServiceIds}
        isOpen={showEndUsage}
        usageId={reservationId}
        usageProcessing={usageEndProcessing}
        onClose={() => {
          setShowEndUsage(false);
        }}
        onConfirm={handleEndUsage}
      />
    </React.Fragment>
  );
}
