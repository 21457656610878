/**
 *
 * auto generated interface for Bookit.Data.AvailabilityTypes
 *
 */

export enum AvailabilityTypes {
  NotAvailable = 0,
  AvailableNotBookable = 1,
  FreelyBookable = 2,
  /**
   * @deprecated use FreelyBookable instead
   */
  BookableWApproval = 3,
  ServiceRelated = 4,
  ServiceGroup = 5,
  SuperGroup = 6,
  //FreelyBookableBillable = 7,
  //ApprovalRequiredBillable = 8,
  RequestRelated = 9,
  TrainingProgram = 10,
  LoanableEquipment = 11,
  LectureRoom = 12,
}
export type AvailabilityTypesUnion = keyof typeof AvailabilityTypes;
export const AvailabilityTypesUnionArray: Array<AvailabilityTypesUnion> = [
  'NotAvailable',
  'AvailableNotBookable',
  'FreelyBookable',
  //'BookableWApproval',
  'ServiceRelated',
  'ServiceGroup',
  'SuperGroup',
  //'FreelyBookableBillable',
  //'ApprovalRequiredBillable',
  'RequestRelated',
  'TrainingProgram',
  'LoanableEquipment',
  'LectureRoom',
];

export const toAvailabilityUnion = (
  availabilityType: AvailabilityTypes | AvailabilityTypesUnion | undefined,
): AvailabilityTypesUnion | undefined => {
  if (availabilityType === undefined) return;
  if (typeof availabilityType === 'string') return availabilityType;
  return getAvailabilityUnion(availabilityType);
};

export const getAvailabilityUnion = (
  availabilityType: AvailabilityTypes,
): AvailabilityTypesUnion | undefined => {
  switch (availabilityType) {
    case AvailabilityTypes.NotAvailable:
      return 'NotAvailable';
    case AvailabilityTypes.AvailableNotBookable:
      return 'AvailableNotBookable';
    case AvailabilityTypes.FreelyBookable:
    case AvailabilityTypes.BookableWApproval:
      return 'FreelyBookable';
    case AvailabilityTypes.ServiceRelated:
      return 'ServiceRelated';
    case AvailabilityTypes.ServiceGroup:
      return 'ServiceGroup';
    case AvailabilityTypes.SuperGroup:
      return 'SuperGroup';
    case AvailabilityTypes.RequestRelated:
      return 'RequestRelated';
    case AvailabilityTypes.TrainingProgram:
      return 'TrainingProgram';
    case AvailabilityTypes.LoanableEquipment:
      return 'LoanableEquipment';
    case AvailabilityTypes.LectureRoom:
      return 'LectureRoom';
    default:
      return undefined;
  }
};
