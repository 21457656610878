import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Link as MuiLink } from '@material-ui/core';
import React from 'react';
import { IReservationWaitingListDto } from 'app/pages/ReservationWaitingListPage/IReservationWaitingListDto';
import { SignUpWaitingListDetailsProps } from 'app/pages/ReservationWaitingListPage/SignUpWaitingListDetails';

export interface OpenSignUpWaitingListButtonProps extends ButtonProps {
  buttonName: React.ReactNode;
  Id?: number;
  model?: IReservationWaitingListDto;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
}
export function OpenWaitingListButton(props: OpenSignUpWaitingListButtonProps) {
  const { buttonName, Id, useSidePanel, openPanelWithCover, model } = props;
  const { t } = useTranslation();
  const { openPanel } = useSidePanelState();
  const openAction = () => {
    let signupProps = {
      Id: Id,
      initialDetails: model,
      useSidePanel: true,
    } as SignUpWaitingListDetailsProps;
    let panelProps = {
      renderPageType: RenderPageType.SignUpWaitingListDetails,
      renderPageProps: signupProps,
      expanded: false,
      useSidePanel: useSidePanel,
      isCover: useSidePanel,
    } as SidePanelOpenState;
    !!openPanelWithCover
      ? openPanelWithCover(panelProps)
      : openPanel(panelProps);
  };

  return (
    <Button
      variant={props.variant || 'white'}
      size="small"
      onClick={() => openAction()}
    >
      {buttonName || t(translations.ReservationSignUpWaitingListDetails)}
    </Button>
  );
}
export const WaitingListSignUpLink = (props: {
  Id?: number;
  model?: IReservationWaitingListDto;
  children: React.ReactNode;
  title?: string;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
}) => {
  const { t } = useTranslation();
  const openSignUp = useOpenWaitingListPopUp();
  const open = () => {
    openSignUp(
      {
        Id: props.Id,
        model: props.model,
        useSidePanel: props.useSidePanel,
      },
      props.openPanelWithCover,
    );
  };
  return (
    <Tooltip
      title={props.title ?? t(translations.ReservationSignUpWaitingListDetails)}
    >
      <MuiLink onClick={() => open()} color="textPrimary">
        {props.children}
      </MuiLink>
    </Tooltip>
  );
};

export const useOpenWaitingListPopUp = () => {
  const { openPanel } = useSidePanelState();
  return React.useCallback(
    (
      props: {
        Id?: number;
        model?: IReservationWaitingListDto;
        useSidePanel?: boolean;
      },
      openPanelWithCover?: (state: SidePanelOpenState) => void,
    ) => {
      let signupProps = {
        Id: props.Id,
        initialDetails: props.model,
        useSidePanel: true,
      } as SignUpWaitingListDetailsProps;
      const panelState = {
        renderPageType: RenderPageType.SignUpWaitingListDetails,
        renderPageProps: signupProps,
        expanded: false,
        useSidePanel: props.useSidePanel,
        isCover: props.useSidePanel,
      } as SidePanelOpenState;
      if (!!openPanelWithCover) {
        openPanelWithCover(panelState);
      } else {
        openPanel({
          renderPageType: RenderPageType.SignUpWaitingListDetails,
          renderPageProps: signupProps,
          expanded: false,
          useSidePanel: false,
          isCover: false,
        } as SidePanelOpenState);
      }
    },
    [openPanel],
  );
};
