import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Entity } from 'types/common';
import { IReservationWaitingListDto } from '../IReservationWaitingListDto';
import { ISignUpModel, ISimpleSignUpModel } from './ISignUpModel';

export interface SimpleModeResults {
  isSimpleMode: boolean;
  hoursCount: number;
  notificationsLength: number;
}

export function getUpcomingReservationsOptions(t: TFunction) {
  return [
    {
      Id: 'false',
      Name: t(translations.doNotNotify) as string,
    },
    {
      Id: 'true',
      Name: t(translations.UpcomingReservationNotifyMeBefor) as string,
    },
  ] as Entity<string>[];
}
export function getNotifyOnResCancelationOptions(t: TFunction) {
  return [
    {
      Id: 'false',
      Name: t(translations.doNotNotify) as string,
    },
    {
      Id: 'true',
      Name: t(translations.NotifyOnReservationHourseBeforeCancel) as string,
      Info: t(
        translations.NotifyOnReservationHourseBeforeCancel_info,
      ) as string,
    },
  ];
}
export function getNotifyOnResTerminationOptions(t: TFunction) {
  return [
    {
      Id: '',
      Name: t(translations.doNotNotify) as string,
    },
    {
      Id: 'false',
      Name: t(translations.NotifyResTermEvery) as string,
    },
    {
      Id: 'true',
      Name: t(translations.NotifyResTermHours) as string,
    },
  ] as Entity<string>[];
}
export function getUpcomingTrainingSessionOptions(t: TFunction) {
  return [
    {
      Id: 'false',
      Name: t(translations.doNotNotify) as string,
    },
    {
      Id: 'true',
      Name: t(translations.UpcomingTrainingSessionNotifyMeBefor) as string,
    },
  ] as Entity<string>[];
}
export function getNotifyOnEndUsageOptions(t: TFunction) {
  return [
    {
      Id: '',
      Name: t(translations.doNotNotify) as string,
    },
    {
      Id: 'false',
      Name: t(translations.NotifyUsageEndEvery) as string,
    },
    {
      Id: 'true',
      Name: t(translations.NotifyUsageEndHours) as string,
    },
  ] as Entity<string>[];
}
function optionToBoolean(rawValue: string | undefined) {
  if (rawValue === undefined) {
    return undefined;
  }
  if (rawValue === null) {
    return undefined;
  }
  if (rawValue === '') {
    return undefined;
  }
  const value = rawValue.toLowerCase() === 'true';
  return value;
}
export function convertSignUpToModel(
  model: ISignUpModel,
  edited?: IReservationWaitingListDto,
) {
  return {
    Id: model.Id,
    EquipmentID: model.Equipment?.Id,
    EquipmentName: model.Equipment?.Name,
    BeforeResCancelHour:
      (optionToBoolean(model.NotifyOnResCancelation) ?? false) === false
        ? 0
        : model.BeforeResCancelHour,
    NotifyOnResCancelation:
      optionToBoolean(model.NotifyOnResCancelation) ?? false,
    NextResTermHours:
      optionToBoolean(model.NotifyOnResTermination) === undefined ||
      optionToBoolean(model.NotifyOnResTermination) === false
        ? 0
        : model.NextResTermHours,
    NotifyOnResTermination:
      optionToBoolean(model.NotifyOnResTermination) === undefined
        ? false
        : true,
    NextReservationHours:
      optionToBoolean(model.NotifyOnEndUsage) === undefined ||
      optionToBoolean(model.NotifyOnEndUsage) === false
        ? 0
        : model.NextReservationHours,
    NotifyOnEndUsage:
      optionToBoolean(model.NotifyOnEndUsage) === undefined ? false : true,
    BeforeResStartHour: edited?.BeforeResStartHour ?? 0,
    BeforeTrainStartHour: edited?.BeforeTrainStartHour ?? 0,
    ServiceGroupId: edited?.ServiceGroupId,
    UpcomingReservations: edited?.UpcomingReservations,
    UpcomingTrainingSession: edited?.UpcomingTrainingSession,
    UserName: model.User?.Id ?? edited?.UserName,
    UserDisplayName: model.User?.Name ?? edited?.UserDisplayName,
    UserEmail: edited?.UserEmail,
  } as IReservationWaitingListDto;
}
export function convertSimpleSignUpToModel(
  model: ISimpleSignUpModel,
  edited?: IReservationWaitingListDto,
  usageEnabled?: boolean,
) {
  return {
    Id: model.Id,
    EquipmentID: model.Equipment?.Id,
    EquipmentName: model.Equipment?.Name,
    BeforeResCancelHour: model.WindowHours,
    NotifyOnResCancelation: true,
    NextResTermHours: model.WindowHours,
    NotifyOnResTermination: true,
    NextReservationHours: model.WindowHours,
    NotifyOnEndUsage: usageEnabled,
    BeforeResStartHour: edited?.BeforeResStartHour ?? 0,
    BeforeTrainStartHour: edited?.BeforeTrainStartHour ?? 0,
    ServiceGroupId: edited?.ServiceGroupId,
    UpcomingReservations: edited?.UpcomingReservations,
    UpcomingTrainingSession: edited?.UpcomingTrainingSession,
    UserName: model.User?.Id ?? edited?.UserName,
    UserDisplayName: model.User?.Name ?? edited?.UserDisplayName,
    UserEmail: edited?.UserEmail,
  } as IReservationWaitingListDto;
}
export const getIsSimpleMode = (
  item: IReservationWaitingListDto,
  usageEnabled: boolean,
) => {
  const allNotifications = [
    {
      enabled: item.NotifyOnResTermination,
      count: item.NextResTermHours,
    },
    {
      enabled: item.NotifyOnResCancelation,
      count: item.BeforeResCancelHour ?? 0,
    },
  ];
  if (usageEnabled) {
    allNotifications.push({
      enabled: item.NotifyOnEndUsage,
      count: item.NextReservationHours,
    });
  }
  const enabledNotifications = allNotifications.filter(f => f.enabled === true);
  const firstCount =
    enabledNotifications.filter(f => f.count > 0)[0]?.count ?? undefined;
  const allTheSame =
    enabledNotifications.length === (usageEnabled ? 3 : 2) &&
    firstCount !== undefined &&
    enabledNotifications.every(f => f.count === firstCount);
  return allTheSame;
};
export const getIsRowSimpleMode = (
  item: IReservationWaitingListDto,
  usageEnabled: boolean,
): SimpleModeResults => {
  const allNotifications = [
    {
      enabled: item.NotifyOnResTermination,
      count: item.NextResTermHours,
    },
    {
      enabled: item.NotifyOnResCancelation,
      count: item.BeforeResCancelHour ?? 0,
    },
  ];
  if (usageEnabled) {
    allNotifications.push({
      enabled: item.NotifyOnEndUsage,
      count: item.NextReservationHours,
    });
  }
  const enabledNotifications = allNotifications.filter(f => f.enabled === true);
  const firstCount =
    enabledNotifications.filter(f => f.count > 0)[0]?.count ?? undefined;
  const allTheSame =
    enabledNotifications.length === (usageEnabled ? 3 : 2) &&
    firstCount !== undefined &&
    enabledNotifications.every(f => f.count === firstCount);
  return {
    isSimpleMode: allTheSame,
    hoursCount: firstCount ?? 0,
    notificationsLength: enabledNotifications.length,
  };
};
