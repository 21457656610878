import React, { useCallback, useMemo } from 'react';
import { CheckBox } from './CheckBox';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Button } from 'app/components/BasicButtons/Button';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useDispatch, useSelector } from 'react-redux';
import { usePrintPDFSlice } from '../slice';
import { PrintPDFItem } from '../slice/types';
import { H4 } from 'app/components/Typography';
import { LinearProgress } from '@material-ui/core';
import { selectPrintPDFSections } from '../slice/selectors';
import { PrintPdfWrapper } from '../styled';
import { Div100 } from 'app/components/AssetQuickSearch/styled';
import BasicTypography from 'app/components/Typography/BasicTypography';

const convertItemsToFlags = (items: PrintPDFItem[]) => {
  const res = {};
  for (const item of items) {
    res[item.id] = item.checked && !item.disabled;
  }
  return res;
};

export interface PopupPrintToPDFComponentProps {
  title?: string;
  printTitle?: string;
  items: PrintPDFItem[];
  processing?: boolean;
  preparing?: boolean;
  disabled?: boolean;
  onSubmit: () => void;
  close: () => void;
  hideDisabled?: boolean;
}

export function PopupPrintToPDFComponent(props: PopupPrintToPDFComponentProps) {
  const {
    items,
    close,
    title,
    processing,
    disabled,
    onSubmit,
    preparing,
    printTitle,
    hideDisabled,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = usePrintPDFSlice();
  const sections = useSelector(selectPrintPDFSections);
  const [checkboxes, setCheckboxes] = React.useState<object>(
    convertItemsToFlags(items),
  );
  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const id =
        items.find(f => f.id === event.target.value)?.id ?? items[0].id;

      if (id !== undefined) {
        dispatch(
          actions.updateSection({ id: id, checked: event.target.checked }),
        );
        setCheckboxes({
          ...checkboxes,
          [id]: event.target.checked,
        });
      }
    },
    [actions, checkboxes, dispatch, items],
  );
  const handleCheckboxChecked = useCallback(
    name => {
      return name !== undefined && checkboxes[name];
    },
    [checkboxes],
  );
  const content = useMemo(() => {
    if (!!sections && sections.length > 0) {
      const res = sections
        .filter(f => !f.static && (!hideDisabled || !f.disabled))
        .map(item => {
          return (
            <CheckBox
              name={item.id}
              id={item.id}
              key={item.id}
              value={item.id}
              disabled={item.disabled}
              checked={handleCheckboxChecked}
              onChange={handleCheckboxChange}
              label={item.label}
            />
          );
        });
      return res;
    } else {
      return <></>;
    }
  }, [handleCheckboxChange, handleCheckboxChecked, hideDisabled, sections]);
  return (
    <>
      <PrintPdfWrapper className={'pdf-root'}>
        <div className={'pdf-header'}>
          <div className={'pdf-header-content'}>
            {!!title && <H4>{title}</H4>}
            <IconButton aria-label="close" onClick={close}>
              <Icon icon="times" />
            </IconButton>
          </div>
          {preparing !== undefined && preparing && (
            <Div100>
              <LinearProgress variant="query" color="primary" />
            </Div100>
          )}
          {preparing !== undefined && preparing === false && !!printTitle && (
            <Div100>
              <BasicTypography
                variant="bodyM"
                bold
              >{`Ready to print: ${printTitle}`}</BasicTypography>
            </Div100>
          )}
        </div>
        <div className={'pdf-content'}>{content}</div>
        <div className={'pdf-footer'}>
          <Button
            processing={processing}
            disabled={disabled}
            onClick={() => onSubmit()}
          >
            {'Print'}
          </Button>
          <Button variant="ghost" onClick={close}>
            {t(translations.Cancel)}
          </Button>
        </div>
      </PrintPdfWrapper>
    </>
  );
}
