import { List, ListProps, Popper, PopperProps, Theme } from '@material-ui/core';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';
import { box_shadow_s } from 'styles/shadows/bookitShadow';

export const AssetQuickSearchForm = styled.form<{ showInSideBar?: boolean }>`
  width: ${props => (props.showInSideBar ? '100%' : 'initial')};
`;
export const SearchContainer = styled.div<{ theme: Theme }>`
  &.search-container {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: ${({ theme }) => theme.shape.borderRadius};
    margin-left: 0;
    padding-top: 2px;
    padding-bottom: 2px;
    gap: 8px;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      width: 355px;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
      width: 463px;
    }
  }
`;
export const AssetQuickSearchPopper = styled(
  ({
    theme,
    ...other
  }: {
    theme: Theme;
  } & PopperProps) => <Popper {...other} />,
)`
  &.asset-quick-search-popper {
    z-index: ${({ theme }) => theme.zIndex.drawer + 2};

    &.popper-mobile {
      z-index: ${({ theme }) => theme.zIndex.modal + 2};
      height: calc(100% - 92px);
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
      width: 700px;
    }

    ${({ theme }) => theme.breakpoints.down('lg')} {
      width: 600px;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 100%;
    }
  }
`;
export const AssetQuickSearchPaper = styled.div`
  &.asset-quick-search-paper {
    display: flex;
    width: 672px;
    padding: 16px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 12px;
    border: 1px solid ${bookitColors.grayscale.graySelection};
    background: ${bookitColors.base.white};
    box-shadow: ${box_shadow_s};

    &.paper-mobile {
      width: 100%;
      height: 100%;
    }
  }
`;
export const SearchList = styled((props: ListProps<'div'>) => (
  <List component="div" {...props} />
))`
  &.search-list {
    width: 100%;
    max-height: 85vh;
    overflow-y: auto;
  }
`;
export const RecentList = styled((props: ListProps) => <List {...props} />)`
  &.recent-list {
    width: 100%;
    overflow-y: auto;
    & .recent-item {
      width: 100%;
    }
  }
`;
export const Div100 = styled.div`
  width: 100%;
`;
export const Div50 = styled.div`
  width: 50%;
`;

export const QuickSearchSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
`;
export const QuickSearchSectionTitle = styled('div')`
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
export const QuickSearchSearchesItems = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  align-self: stretch;
`;
export const QuickSearchRecentItems = styled('div')`
  display: flex;
  padding: 4px 0px;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
  align-self: stretch;
  width: 100%;
`;
export const QuickSearchEmptySection = styled('div')`
  display: flex;
  width: 100%;
  height: 260px;
  padding: 0px 16px 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;
export const QuickSearchEmptyContent = styled('div')`
  &.empty-search-content {
    display: flex;
    padding: 0px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    & .empty-search-content-wrap {
      align-self: stretch;
      & .empty-search-content-text {
        text-align: center;
      }
    }
  }
`;
export const QuickSearchEmptyBody = styled.span`
  &.empty-search-body {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    & .empty-search-body-text {
      text-align: center;
    }
    & .empty-search-last-text {
      text-align: center;
    }
  }
`;
export const QuickSearchSeeAll = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
`;
export const QuickSearchMainTitleSection = styled('div')`
  &.quick-search-title {
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    & .quick-search-title-text {
      flex: 1 0 0;
    }
  }
`;
export const QuickSearchAllInstrumentsSection = styled('div')`
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;
export const MenuSearchEmptyContent = styled('div')`
  display: flex;
  width: 100%;
  padding: 0px 16px 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;
export const SecondaryContent = styled('div')`
  display: flex;
  flex-direction: row;
`;
export const ApprovalRequiredContent = styled('div')`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`;
