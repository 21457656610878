import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { SignUpToInstrument } from 'app/pages/Actions/PageTopActions/SignUpToInstrument';
import * as React from 'react';
export interface GetActionsProps {
  equipmentId?: number;
  userName?: string;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
  isMobile?: boolean;
}

export function GetActions(props: GetActionsProps): PageActionRenderer[] {
  const PageActions = [
    (dataCount, refresh) => (
      <SignUpToInstrument
        variant={props.isMobile ? 'ghost' : 'main'}
        equipmentId={props.equipmentId}
        userName={props.userName}
        useSidePanel={props.useSidePanel}
        openPanelWithCover={props.openPanelWithCover}
        isIconButton={props.isMobile}
      />
    ),
  ];
  return PageActions;
}
