/**
 *
 * BooleanPickerFilter
 *
 */
import { BooleanPicker } from 'app/components/BooleanPicker';
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export function BooleanPickerFilter(
  props: IFilterComponentProps & {
    trueName?: string;
    falseName?: string;
    info?: string;
  },
) {
  const handleOnChange = (value: Entity<boolean> | null) => {
    props.onChange(value);
  };
  const { t } = useTranslation();
  return (
    <BooleanPicker
      value={props.value}
      isOpen={props.open}
      trueName={props.trueName}
      falseName={props.falseName}
      info={props.info}
      size="small"
      onChange={handleOnChange}
      placeholder={t(translations.AllValues)}
      ariaLabel={props.label}
      fullWidth={props.fullWidth}
      id={props.id || 'booleanpickerid'}
    />
  );
}
export default BooleanPickerFilter;
