import { useState } from 'react';
import { dateUtils } from 'utils/date-utils';
import { CommentFile, IComment, NewComment } from './IComment';
import { parse } from './commentParser';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Caption } from '../Typography';
import { Icon } from '../BasicIcons/FontAwesome';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { UserAvatar } from '../UserAvatar';
import { useSystemDate } from 'app/hooks/useSystemDate';
import * as React from 'react';
import BasicTypography from '../Typography/BasicTypography';
import { FileButton } from './FileButton';
import { Tooltip } from '../BasicTooltips/Tooltip';
import { useLargeFilesUploadSlice } from '../LargeFilesUpload/slice';
import {
  selectLargeFilesStartedToUpload,
  selectLargeFilesUpload,
  selectLargeFilesUploadFilesNumber,
  selectLargeFilesUploadTargetId,
  selectLargeFilesUploading,
} from '../LargeFilesUpload/slice/selectoes';
import { CommentsApi } from 'api/CommentsApi';
import { CommentSection } from './styled';
import { AddComment } from './AddComment';
import { IconButton } from '../BasicButtons/IconButton';

export interface CommentCellProps {
  comment: IComment;
  onSubmit: (comment: NewComment) => void;
  onClose: () => void;
  withMentions: boolean;
  isAdmin?: boolean;
  onEditComment?: (comment: IComment, newComment: NewComment) => void;
  onDeleteComment?: (comment: IComment) => void;
  disabled?: boolean;
  bindSubmitComment?: any;
  checkPermissions?: (comment: IComment) => boolean;
  checkEditPermissions?: (comment: IComment) => boolean;
  deletable?: boolean;
  disableFileUpload?: boolean;
}

export const CommentCell = (props: CommentCellProps) => {
  const {
    comment,
    onClose,
    withMentions,
    isAdmin,
    onEditComment,
    onDeleteComment,
    disabled,
    bindSubmitComment,
    checkPermissions,
    checkEditPermissions,
    deletable,
    disableFileUpload,
  } = props;
  const dispatch = useDispatch();
  const { actions: largeFileActions } = useLargeFilesUploadSlice();
  const [hover, setHover] = useState(false);
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const { newDate } = useSystemDate();
  const [files, setFiles] = useState<CommentFile[]>([]);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const currentUserComment = authenticatedUser?.Id === comment.CreatedBy.Id;
  const uploadedFiles = useSelector(selectLargeFilesUploadFilesNumber);
  const commentId = useSelector(selectLargeFilesUploadTargetId);
  const startedToUpload = useSelector(selectLargeFilesStartedToUpload);
  const uploading = useSelector(selectLargeFilesUploading);
  const filesUpload = useSelector(selectLargeFilesUpload);
  const isCommentAdmin = React.useMemo(() => {
    if (checkPermissions) {
      return checkPermissions(comment);
    }
    return isAdmin;
  }, [checkPermissions, comment, isAdmin]);
  const hasEditRights = React.useMemo(() => {
    if (checkEditPermissions) {
      return checkEditPermissions(comment);
    }
    return false;
  }, [checkEditPermissions, comment]);
  React.useEffect(() => {
    setFiles(comment.Files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    const getComments = async () => {
      if (
        filesUpload.commentType !== undefined &&
        (filesUpload.referenceId !== undefined ||
          filesUpload.refernceStringId !== undefined)
      ) {
        var result = await CommentsApi.GetComments(
          filesUpload.commentType,
          filesUpload.referenceId ?? 0,
          commentId,
          filesUpload.refernceStringId,
        );
        if (result.length > 0) {
          setFiles(result[0].Files);
        }
      }
      dispatch(largeFileActions.resetNumberOfUploadedFiles());
    };
    if (
      commentId === comment.Id &&
      startedToUpload &&
      !uploading &&
      uploadedFiles > 0
    ) {
      getComments();
      onClose();
      setEdit(false);
    }
  }, [
    commentId,
    startedToUpload,
    uploading,
    uploadedFiles,
    dispatch,
    largeFileActions,
    filesUpload.commentType,
    filesUpload.referenceId,
    comment.Id,
    onClose,
    filesUpload.refernceStringId,
  ]);
  return (
    <React.Fragment>
      {onEditComment && hasEditRights && edit ? (
        <>
          <AddComment
            withMentions={withMentions}
            isAdmin={isCommentAdmin}
            defaultComment={comment}
            disabled={disabled}
            onSubmit={newComment => {
              onEditComment(comment, newComment);
              //setEdit(false);
            }}
            onClose={() => setEdit(false)}
            bindSubmitComment={bindSubmitComment}
            disableFileUpload={disableFileUpload}
          />
        </>
      ) : (
        <CommentSection className={'comment-section'}>
          <div>
            <UserAvatar user={comment.CreatedBy} />
          </div>
          <div
            className={'comment-text-files'}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <div className={'comment-text'}>
              <div className={'comment-name-time'}>
                <div className={'comment-name-title-buttons'}>
                  <div className={'comment-name-title'}>
                    <BasicTypography variant="boldS">
                      {comment.CreatedBy.Name}
                    </BasicTypography>
                  </div>
                  {!isCommentAdmin && comment.IsInternal && (
                    <Icon icon="lock" color="black" colorExtend="light" />
                  )}
                  {withMentions && hover && (
                    <>
                      {isCommentAdmin && (
                        <>
                          {onEditComment && hasEditRights && (
                            <IconButton
                              variant="text"
                              title={t(translations.Edit)}
                              size="xs"
                              onClick={() => setEdit(true)}
                            >
                              <Icon icon="edit" />
                            </IconButton>
                          )}
                          {onDeleteComment && hasEditRights && deletable && (
                            <IconButton
                              variant="text"
                              title={t(translations.Delete)}
                              size="xs"
                              onClick={() => onDeleteComment(comment)}
                            >
                              <Icon icon="trash" />
                            </IconButton>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                {comment.ServiceGroupName && (
                  <BasicTypography variant="captionBold">
                    {comment.ServiceGroupName}
                  </BasicTypography>
                )}
                <Caption color="secondary">
                  <Tooltip
                    title={
                      dateUtils.format(
                        comment.CreatedAt,
                        dateUtils.DateIOFormats.keyboardDateTime24h,
                      ) ?? ''
                    }
                    arrow
                  >
                    <span>
                      {Math.abs(
                        dateUtils.differenceInDays(
                          dateUtils.dateOrStringToDate(comment.CreatedAt),
                          newDate(),
                        ),
                      ) > 30
                        ? dateUtils.format(
                            comment.CreatedAt,
                            dateUtils.DateIOFormats.keyboardDateTime24h, //'PP hh:mm',
                          )
                        : dateUtils.formatDistanceToNow(comment.CreatedAt)}
                    </span>
                  </Tooltip>
                </Caption>
              </div>
              <BasicTypography variant="bodyS">
                {comment.Text.trim() !== '' ? parse(comment.Text) : ''}
              </BasicTypography>
            </div>

            {files.map(f => {
              return (
                <>
                  <FileButton
                    canRemove={isCommentAdmin || currentUserComment}
                    onDeleteFile={id => {
                      setFiles(prev => prev.filter(file => file.Id !== id));
                    }}
                    key={f.Id}
                    file={f}
                  />
                </>
              );
            })}
          </div>
        </CommentSection>
      )}
    </React.Fragment>
  );
};
