import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.assetsSelection || initialState;

export const selectAssetsSelection = createSelector(
  [selectSlice],
  state => state,
);

export const selectAssetsSelectionLoading = createSelector(
  [selectAssetsSelection],
  state => state.loading,
);

export const selectAssetsSelectionFilters = createSelector(
  [selectAssetsSelection],
  state => state.filters,
);
export const selectLoadingAdditionalData = createSelector(
  [selectAssetsSelection],
  state => state.loadingAdditionalData,
);
