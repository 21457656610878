import { useTranslation } from 'react-i18next';
import {
  getAlertStart,
  WorkOrderDetailsState,
  WorkOrderReadonlyState,
} from '../slice/types';
import * as React from 'react';
import { translations } from 'locales/translations';
import { FormAssetsPicker } from 'app/components/Forms/FormAssetsPicker';
import { FormWorkOrderTypePicker } from 'app/components/Forms/FormWorkOrderTypePicker';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { FormWorkOrderTypeStatusPicker } from 'app/components/Forms/FormWorkOrderTypeStatusPicker';
import { FormRemindBeforePicker } from 'app/components/Forms/FormRemindBeforePicker';
import { FormSymptomCodesPicker } from 'app/components/Forms/FormSymptomCodesPicker';
import { FormRepairCodesPicker } from 'app/components/Forms/FormRepairCodesPicker';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import { Formik, FormikProps } from 'formik';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRightSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import {
  FieldHandler,
  FormListener,
} from 'app/components/Forms/FormRender/FormRenderer';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import {
  IAssetDto,
  mapAssetsToServiceFilterDto,
} from 'api/odata/generated/entities/IAssetDto';
import { FormSwitch } from 'app/components/Forms/Switch';
import { FormReasonTypePicker } from 'app/components/Forms/FormReasonTypePicker';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { FormAdminUsersPicker } from 'app/components/Forms/FormAdminUserPicker';
import { AssetsFilterForWorkOrder } from 'app/components/pickers/MultiSelectPickers/AssetsPicker';
import { dateUtils, WeekStartsOn } from 'utils/date-utils';
import { initReasonTypeData } from 'app/components/pickers/AutocompletePickers/ReasonTypePicker';
import { useDispatch, useSelector } from 'react-redux';
import { selectSystemFirstDayOfWeek } from 'app/slice/selectors';
import { GetAutoCreateTitle } from 'types/IRepetitiveDto';
import { getSingleReminder } from 'app/components/pickers/StaticOptionsPickers/RemindBeforePicker';
import { Entity } from 'types/common';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { RepetitiveControl } from 'app/components/Forms/FormRepetitive/RepetitiveControl';
import { isNullOrUndefined } from 'utils/typeUtils';
import { CreationDetails } from '../CreationDetails';
import { FormUnlimitedDowntime } from './FormUnlimitedDowntime';
import { CustomFormControl } from '../../../../../components/CustomForm/CusomFormControl';
import { CustomFormTypeEnum } from 'enums/CustomFormType';
import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import {
  formFileSchema,
  formValueSchema,
  IFormFileValue,
} from 'app/components/CustomForm/CustomFormUtils';
import { FormCustomFormPicker } from 'app/components/Forms/FormCustomFormPicker';
import { AlertFormFilter } from 'app/components/pickers/AutocompletePickers/CustomFormPicker';
import { WorkOrderComments } from './WorkOrderComments';
import { isEmpty } from 'lodash';
import { RepetitiveFor } from 'app/components/Forms/FormRepetitive/slice/types';
import * as Yup from 'yup';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { WorkOrderDetailsProps } from '..';
import { useWorkOrderSlice } from '../slice';

import {
  selectDowntimeOverlaps,
  selectFieldHandler,
  selectLinksProcessing,
  selectOfflineEventEnd,
  selectOfflineEventStart,
  selectSavedLinkedOrders,
} from '../slice/selectors';
import produce from 'immer';
import {
  AdminServicesFilter,
  initAssigneeAdminUsers,
} from 'app/components/pickers/AutocompletePickers/UserPicker';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { AddCalibrationWorkOrder } from './WorkOrderLinks/AddCalibrationWorkOrder';
import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import { LinkedList, WorkOrderLinks } from './WorkOrderLinks/WorkOrderLinks';
import { useAsyncGetWithEffect } from 'app/hooks/useAsyncGetOnMount';
import { WorkOrderApi } from 'api/WorkOrderApi';
import { FormBookitDateTimePicker } from 'app/components/Forms/FormBookitDateTimePicker';
import { IndexNode } from 'app/components/Forms/FormsLayout/StyledForm';
import { Body, Caption } from 'app/components/Typography';
import { CalendarButton } from 'app/components/CalendarButton';
import { Button } from 'app/components/BasicButtons/Button';
import {
  EntityNumberSchema,
  EntityStringSchema,
} from 'app/components/Forms/Schemas';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import FormDivider from 'app/components/Forms/FormsLayout/FormDivider';
import { Roles } from 'api/odata/generated/enums/Roles';
import { getLogger } from 'utils/logLevel';
import { SymptomCodesAssetCatFilter } from 'app/components/pickers/AutocompletePickers/SymptomCodesPicker';
import { RepairCodesAssetCatFilter } from 'app/components/pickers/AutocompletePickers/RepairCodesPicker';

export interface WorkOrderFormProps {
  onSubmit: (
    item: WorkOrderDetailsState,
    subFormFiles: IFormFileValue[],
    staffFormFiles: IFormFileValue[],
  ) => void;
  initialValues: WorkOrderDetailsState;
  processing?: boolean;
  bindSubmitForm: any;
  isEdit: boolean;
  repetitiveFormRef: React.MutableRefObject<any>;
  workOrderTypeFormRef: React.MutableRefObject<any>;
  workOrderStaffFormRef: React.MutableRefObject<any>;
  readonlyDetails?: WorkOrderReadonlyState;
  isAdmin: boolean;
  user?: AuthenticatedUser;
  handleTypeChange: (type: IWorkOrderTypeDto | undefined) => void;
  canView?: boolean;
  commentsFormRef: React.MutableRefObject<any>;
  onAssetClicked?: (asset: Entity<number>) => void;
  onLinkedClick?: (linked: Entity<number>) => void;
  expanded: boolean;
  formRef: React.MutableRefObject<FormikProps<WorkOrderDetailsState> | null>;
  setWorkOrderOfflineStartChangedValue: React.Dispatch<
    React.SetStateAction<Date | string | null | undefined>
  >;
  setWorkOrderOfflineEndChangedValue: React.Dispatch<
    React.SetStateAction<Date | string | null | undefined>
  >;
  useSidePanel?: boolean;
  pageLink?: string;
  showShortView?: boolean;
  isCover?: boolean;
  bringUp?: boolean;
  openPanel?: (state: SidePanelOpenState) => void;
}

export const WorkOrderForm = React.memo(function WorkOrderForm({
  onSubmit,
  processing,
  initialValues,
  bindSubmitForm,
  isEdit,
  isAdmin,
  user,
  handleTypeChange,
  repetitiveFormRef,
  workOrderTypeFormRef,
  workOrderStaffFormRef,
  readonlyDetails,
  canView,
  commentsFormRef,
  setWorkOrderOfflineStartChangedValue,
  setWorkOrderOfflineEndChangedValue,
  onAssetClicked,
  expanded,
  formRef,
  onLinkedClick,
  useSidePanel,
  pageLink,
  showShortView,
  isCover,
  bringUp,
  openPanel,
}: WorkOrderFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useLayoutSlice();
  const { actions: workOrderActions } = useWorkOrderSlice();
  // const [addToLinked, setAddToLinked] = React.useState<Entity<number> | null>(
  //   null,
  // );
  const readonly = !isAdmin && isEdit;
  const firstDayOfWeek = useSelector(selectSystemFirstDayOfWeek);
  const fieldHandler = useSelector(selectFieldHandler);
  const savedLinkedOrders = useSelector(selectSavedLinkedOrders);
  const linksProcessing = useSelector(selectLinksProcessing);
  const overlaps = useSelector(selectDowntimeOverlaps);
  // const linkedCalibrationsChanged = useSelector(selectCalibrationsChanged);
  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(value, originalSubFormFiles, originalStaffFormFiles);
    }
  };
  const resetFieldHandler = () => {
    dispatch(workOrderActions.resetValueHandler());
  };
  const [unlimitedDowntime, setUnlimitedDowntime] = React.useState<boolean>(
    isEdit &&
      initialValues.OfflineEventEnd !== null &&
      initialValues.OfflineEventEnd !== undefined &&
      dateUtils
        .dateOrStringToDate(initialValues.OfflineEventEnd)
        .getFullYear() === 9999,
  );
  const [typeChanged, setTypeChanged] = React.useState<boolean>(false);
  const [downTimeChanged, setDownTimeChanged] = React.useState<boolean>(false);
  const [allIsValid, setAllIsValid] = React.useState<boolean>(true);
  const [remindBeforeChanged, setRemindBeforeChanged] =
    React.useState<boolean>(false);
  const [originalSubFormFiles, setOriginalSubFormFiles] = React.useState<
    IFormFileValue[]
  >([]);
  const [originalStaffFormFiles, setOriginalStaffFormFiles] = React.useState<
    IFormFileValue[]
  >([]);
  const [selectedType, setSelectedType] =
    React.useState<IWorkOrderTypeDto | null>(
      initialValues.WorkOrderType as IWorkOrderTypeDto,
    );
  const linkedWorkOrders = useAsyncGetWithEffect<LinkedList[]>(
    //WorkOrderApi.getLinkedToCalibration(initialValues.Id),
    async (): Promise<LinkedList[]> => {
      if (isEdit) {
        if (linksProcessing === true) {
          return new Promise<LinkedList[]>(resolve => {
            resolve(linkedWorkOrders);
          });
        } else {
          return WorkOrderApi.getLinkedToCalibration(initialValues.Id).then(
            res => {
              return res.Links as LinkedList[];
            },
          );
        }
      } else {
        return new Promise<LinkedList[]>(resolve => {
          resolve(
            savedLinkedOrders.map(f => {
              return {
                Id: f.Id,
                Name: `#${f.Id}: ${f.AssetName} - ${f.Name}`,
                ParentId: -1,
                Linked: [],
              };
            }),
          );
        });
      }
    },
    [],
    [savedLinkedOrders, linksProcessing],
  );
  const { newDate } = useSystemDate();
  const updateFormValues = (
    original: IFormValueDto[],
    coming: IFormValueDto[],
    alertId: number | null,
    typeId: number | null,
  ) => {
    const result = coming.map(v => {
      let orn = original.find(o => o.FormFieldId === v.FormFieldId);
      if (!!orn) {
        return Object.assign({}, orn, {
          Value: v.Value,
          AlertId: alertId,
          AlertTypeId: typeId,
        });
      } else {
        return Object.assign(v, {
          AlertId: alertId,
          AlertTypeId: typeId,
        });
      }
    });
    return result;
  };
  const workOrderSchema = React.useMemo(() => {
    const wschema = Yup.object({
      Id: Yup.number().notRequired().default(-1),
      Assets: Yup.array()
        .of(EntityNumberSchema)
        .label(t(translations.Asset) as string)
        .min(1)
        .required(t(translations.err_AssetRequired)),
      WorkOrderType: Yup.mixed()
        .label(t(translations.AlertType) as string)
        .required(t(translations.errEvent_Type_Required) as string)
        .typeError(t(translations.errEvent_Type_Required) as string),
      AlertTitle: Yup.string()
        .label(t(translations.AlertName) as string)
        .nullable(true),
      WorkOrderTypeStatus: Yup.mixed()
        .label(t(translations.EventTypeStatus) as string)
        .required(t(translations.errEvent_Type_Status_Required) as string),
      EventStart: Yup.date()
        .label(t(translations.EventDueDate) as string)
        .nullable(),
      EventEnd: Yup.date().notRequired().nullable(),
      RemindBefore: Yup.mixed().label(
        t(translations.ReminderOptions) as string,
      ),
      AlertStart: Yup.date()
        .label(t(translations.AlertStartTimeLabel) as string)
        .nullable()
        .when('RemindBefore', {
          is: val => val.Id === 6,
          then: Yup.date()
            .label(t(translations.AlertStartTimeLabel) as string)
            .default(
              dateUtils.addDays(dateUtils.dateOrStringToDate(new Date()), -1),
            )
            .nullable()
            .required(
              `${t(translations.AlertStartTimeLabel) as string} is required.`,
            )
            .max(Yup.ref('EventStart')),
        }),
      SymptomCodes: Yup.array()
        .of(EntityNumberSchema)
        .label(t(translations.SymptomCodes) as string),

      AssignUsers: Yup.array()
        .of(EntityStringSchema)
        .label(t(translations.AssignedUsers) as string),
      RepairCodes: Yup.array()
        .of(EntityNumberSchema)
        .label(t(translations.RepairCodes) as string),
      Description: Yup.string()
        .label(t(translations.Description) as string)
        .nullable(),
      Downtime: Yup.boolean().label(t(translations.Downtime) as string),
      OfflineEventStart: Yup.date()
        .label(t(translations.OfflineEventStartLabel) as string)
        .nullable()
        .when('Downtime', {
          is: check => check === true,
          then: Yup.date()
            .label(t(translations.OfflineEventStartLabel) as string)
            .nullable()
            .required(t(translations.OfflineEventStartRequired) as string),
        }),
      OfflineEventEnd: Yup.date()
        .label(t(translations.OfflineEventEndLabel) as string)
        .nullable()
        .when('OfflineEventStart', {
          is: start => start != null,
          then: Yup.date()
            .label(t(translations.OfflineEventEndLabel) as string)
            .nullable()
            .min(
              Yup.ref('OfflineEventStart'),
              t(translations.err_DownTimeEndGreaterThenStart) as string,
            ),
        }),
      OfflineRemarks: Yup.string().nullable(),
      ReasonType: Yup.mixed(),
      ToDown: Yup.boolean().default(false),
      AutoCreateTitle: Yup.string().nullable(true).notRequired(),
      RecurringAssetId: Yup.number().nullable(true).notRequired().default(null),
      RecurringGroupId: Yup.number().nullable(true).notRequired().default(null),
      StaffFormValues: Yup.array().of(formValueSchema),
      SubmissionFormValues: Yup.array().of(formValueSchema),
      SubmissionFormFiles: Yup.array().of(formFileSchema),
      StaffFormFiles: Yup.array().of(formFileSchema),
      StuffCustomForm: Yup.mixed().nullable(true).notRequired().default(null),
      HasAletForm: Yup.boolean().default(false),
      IsClosed: Yup.boolean().default(false),
      ClosedStatuses: Yup.array().of(EntityNumberSchema),
      ReportedBy: Yup.string().nullable(true),
      ShowOnCalibrationHistory: Yup.boolean().default(false),
      NotEditable: Yup.boolean().default(false),
      TitleEnabled: Yup.boolean().default(false),
      AssigneeEnabled: Yup.boolean().default(false),
      EventTimeEnabled: Yup.boolean().default(false),
      DowntimeEnabled: Yup.boolean().default(false),
      ReservationId: Yup.number().nullable(true).notRequired().default(null),
    });
    if (selectedType?.TitleEnabled) {
      wschema.fields.AlertTitle.withMutation(schema =>
        schema.required(t(translations.err_AlertNameRequired) as string),
      );
    }
    if (selectedType?.EventTimeEnabled) {
      wschema.fields.EventStart.withMutation(schema =>
        schema
          .default(dateUtils.dateOrStringToDate(newDate()))
          .required(() => t(translations.err_EventDueDateRequired) as string),
      );
    }

    return wschema;
  }, [newDate, selectedType?.EventTimeEnabled, selectedType?.TitleEnabled, t]);
  const handleFormChange = React.useCallback(
    (values: WorkOrderDetailsState, isValid, dirty, setValue) => {
      if (typeChanged) {
        let type = values.WorkOrderType as IWorkOrderTypeDto;
        handleTypeChange(type);
        setSelectedType(type);
        if (!!type) {
          if (type.DefaultSubject !== null && type.TitleEnabled) {
            setValue('AlertTitle', type.DefaultSubject, false);
          }
          if (type.DowntimeEnabled) {
            if (initialValues.ToDown) {
              setValue('Downtime', true, false);
              setValue('OfflineEventStart', newDate(), true);
              (async () => {
                try {
                  let id = type.DowntimeReasonTypeId ?? 3; // - Unexpected Malfunction
                  const reason = await initReasonTypeData(id.toString());
                  if (!!reason && reason.length > 0) {
                    setValue('ReasonType', reason[0], false);
                  }
                } catch {}
              })();
            } else if (type.DowntimeDuration !== null) {
              setValue('Downtime', true, false);
              if (
                values.EventStart !== null &&
                values.EventStart !== undefined
              ) {
                let endTime = dateUtils.addHours(
                  dateUtils.dateOrStringToDate(values.EventStart),
                  type.DowntimeDuration,
                );
                setValue('OfflineEventStart', values.EventStart, false);
                setValue('OfflineEventEnd', endTime, false);
              } else {
                setValue('OfflineEventStart', newDate(), false);
                let endTime = dateUtils.addHours(
                  dateUtils.dateOrStringToDate(newDate()),
                  type.DowntimeDuration,
                );
                setValue('OfflineEventEnd', endTime, false);
              }
              if (type.DowntimeReasonTypeId != null) {
                (async () => {
                  try {
                    let id = type.DowntimeReasonTypeId ?? 0;
                    const reason = await initReasonTypeData(id.toString());
                    if (!!reason && reason.length > 0) {
                      setValue('ReasonType', reason[0], false);
                    }
                  } catch {}
                })();
              }
            } else {
              setValue('Downtime', false, false);
            }
          }

          setValue(
            'AutoCreateTitle',
            GetAutoCreateTitle(
              type,
              values.EventStart,
              firstDayOfWeek as WeekStartsOn,
            ),
            false,
          );
          if (type.DefaultRemaindBefore !== null && type.EventTimeEnabled) {
            let remb = getSingleReminder(type.DefaultRemaindBefore);
            if (!!remb) {
              setValue('RemindBefore', remb, false);
              setValue('AlertStart', getAlertStart(remb.Id, values.EventStart));
            }
          }
          if (type.AssigneeEnabled) {
            let assignee: Entity<string>[] = [];
            if (type.AssignedUsers.length > 0) {
              assignee.concat(type.AssignedUsers);
            }
            if (type.AssignedToCreator && !!user) {
              assignee.push({
                Id: user.Id,
                Name: user.FormatedName || user.Id,
              });
            }
            (async () => {
              try {
                let assets = values.Assets as IAssetDto[]; // - Unexpected Malfunction
                const users = await initAssigneeAdminUsers(
                  type,
                  mapAssetsToServiceFilterDto(assets),
                );
                assignee.concat(users);
                if (assignee.length > 0) {
                  setValue('AssignUsers', assignee, false);
                }
              } catch {}
            })();
          }
        }
        setTypeChanged(false);
      }
      if (downTimeChanged) {
        if (values.Downtime) {
          setValue('OfflineEventStart', newDate(), true);
        }
        setDownTimeChanged(false);
      }
      if (remindBeforeChanged) {
        setValue(
          'AlertStart',
          getAlertStart(values.RemindBefore?.Id ?? 0, values.EventStart),
        );
        setRemindBeforeChanged(false);
      }
    },
    [
      downTimeChanged,
      firstDayOfWeek,
      handleTypeChange,
      initialValues.ToDown,
      newDate,
      remindBeforeChanged,
      typeChanged,
      user,
    ],
  );
  const readOnlyUser = React.useMemo(() => {
    if (!user) return true;
    return (
      user.Roles.includes(Roles.Readonly) ||
      user.Roles.includes(Roles.CalendarOnly)
    );
  }, [user]);
  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.validateForm();
    }
  }, [formRef]);
  const validateForms = React.useCallback((isValid: boolean) => {
    setAllIsValid(isValid);
  }, []);
  const changeLinkedOrders = React.useCallback(
    (
      orders: IWorkOrderDetailsDto[],
      operation: 'add' | 'remove' | 'update',
    ) => {
      if (operation === 'add') {
        let addedOrders = orders.filter(
          f => !savedLinkedOrders.some(s => s.Id === f.Id),
        );
        dispatch(
          workOrderActions.setSavedLinkedOrders([
            ...savedLinkedOrders,
            ...addedOrders,
          ]),
        );
      }
      if (operation === 'remove') {
        let removedOrders = savedLinkedOrders.filter(f => {
          return !orders.some(a => a.Id === f.Id);
        });
        dispatch(workOrderActions.setSavedLinkedOrders(removedOrders));
      }
      if (operation === 'update') {
        dispatch(
          workOrderActions.setSavedLinkedOrders(
            savedLinkedOrders.map(f => {
              let order = orders.find(s => s.Id === f.Id);
              if (!!order && order !== null) {
                return Object.assign({}, f, order);
              } else {
                return f;
              }
            }),
          ),
        );
      }
    },
    [dispatch, savedLinkedOrders, workOrderActions],
  );
  const removeCalibrationOrder = React.useCallback(
    (linked: LinkedList) => {
      dispatch(
        workOrderActions.removeLinkedCalibration({
          id: linked.ParentId,
          link: linked.Id,
        }),
      );
    },
    [dispatch, workOrderActions],
  );
  const addCalibrationOrder = React.useCallback(
    (linked: Entity<number>) => {
      dispatch(
        workOrderActions.addLinkedCalibration({
          id: initialValues.Id,
          link: linked.Id,
        }),
      );
    },
    [dispatch, initialValues.Id, workOrderActions],
  );
  const handleOfflineEventStartChange = (v: any) => {
    dispatch(
      workOrderActions.setOfflineEventStart(
        dateUtils.tryParse(v) === null
          ? undefined
          : dateUtils.formatQueryStringDate(v),
      ),
    );
  };
  const handleOfflineEventEndChange = (v: any) => {
    dispatch(
      workOrderActions.setOfflineEventEnd(
        // dateUtils.tryFormatIso(dateUtils.tryParse(v)) ?? undefined,
        dateUtils.tryParse(v) === null
          ? undefined
          : dateUtils.formatQueryStringDate(v),
      ),
    );
  };
  const OfflineEventStart = useSelector(selectOfflineEventStart);
  const OfflineEventEnd = useSelector(selectOfflineEventEnd);
  const warningMessages: IndexNode[] = React.useMemo(() => {
    let nodes: IndexNode[] = [];
    if (!!overlaps && overlaps.length > 0) {
      nodes.push({
        index: 'overlapsLinks',
        node: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            <Body size="small" bold={true}>
              {t(translations.InstrumentHasOverlappingDowntimeEvents) as string}
            </Body>
            <Caption>
              {t(translations.ShowAllDowntimeEventsOn) as string}
            </Caption>
            <div
              style={{
                display: 'flex',
                alignSelf: 'stretch',
                gap: 8,
              }}
            >
              <Button
                size={'small'}
                href={`/workorders?aid=${overlaps
                  .filter(f => f.AssetId !== -1)
                  .map(f => f.AssetId)
                  .join(',')}&downTime=2`}
                aria-label="work orders"
                target="_blank"
              >
                {t(translations.menu_WorkOrders) as string}
              </Button>
              <CalendarButton
                size="small"
                variant="white"
                equipments={overlaps
                  .filter(f => f.ServiceId !== -1)
                  .map(f => f.ServiceId)}
              >
                {t(translations.Calendar) as string}
              </CalendarButton>
            </div>
          </div>
        ),
        variant: 'outlined',
      });
    }
    return nodes;
  }, [overlaps, t]);

  React.useEffect(() => {
    if (
      isEdit &&
      !!initialValues.OfflineEventStart &&
      initialValues.OfflineEventStart !== null
    ) {
      dispatch(workOrderActions.checkDowntimeOverlaps(initialValues.Id));
    }
  }, [
    bringUp,
    dispatch,
    initialValues.Id,
    initialValues.OfflineEventStart,
    isEdit,
    workOrderActions,
  ]);
  React.useEffect(() => {
    formRef.current?.setValues(
      produce(v => {
        if (OfflineEventStart !== undefined) {
          v.OfflineEventStart = dateUtils.parseISOOrNull(OfflineEventStart);
        }
        if (OfflineEventEnd !== undefined) {
          v.OfflineEventEnd = dateUtils.parseISOOrNull(OfflineEventEnd);
        }
      }),
    );
  }, [OfflineEventStart, OfflineEventEnd, formRef]);

  return (
    <>
      <Formik
        validationSchema={workOrderSchema}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnBlur={false}
        isInitialValid={false}
        validateOnChange={true}
        innerRef={formRef}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          if (allIsValid) {
            formikHelpers.validateForm(values).then(responseErrors => {
              if (!isEmpty(responseErrors)) {
                formikHelpers.setSubmitting(false);
              } else {
                formikHelpers.setSubmitting(true);
                handleSubmit(values);
              }
            });
          } else {
            formikHelpers.setSubmitting(false);
          }
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            log.log('Work order errors', formik.errors);
          }
          return (
            <>
              <StyledForm onSubmit={formik.handleSubmit} isCover={isCover}>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={['WorkOrderType', 'Downtime', 'RemindBefore']}
                />
                <FieldHandler
                  {...fieldHandler}
                  resetFieldState={resetFieldHandler}
                />
                <FormLeftSection
                  isCover={isCover}
                  info={
                    (formik.values.WorkOrderType as IWorkOrderTypeDto)
                      .NotEditable === true && formik.values.IsClosed === true
                      ? (t(translations.LockedClosedRecord) as string)
                      : undefined
                  }
                  warningNodes={warningMessages}
                >
                  <FormFieldsSection
                    titleSection={t(translations.EventDetails) as string}
                  >
                    <FormRow isCover={isCover} fullRow={true}>
                      <FormAssetsPicker
                        predicates={AssetsFilterForWorkOrder(
                          (formik.values.WorkOrderType as IWorkOrderTypeDto) ??
                            undefined,
                        )}
                        name="Assets"
                        id="workorder_assetid"
                        label={t(translations.Asset) as string}
                        placeholder={t(translations.PleaseSelectAsset)}
                        disabled={
                          formik.isSubmitting ||
                          isEdit ||
                          readonly ||
                          ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                            .NotEditable === true &&
                            formik.values.IsClosed === true)
                        }
                        onChipClick={onAssetClicked}
                        //onChange={() => setAssetChanged(true)}
                        fullWidth
                      />
                    </FormRow>
                    {!expanded && (
                      <FormRow isCover={isCover}>
                        <FormRichTextField
                          name="Description"
                          placeholder={t(translations.Description)}
                          id="workorder_descript"
                          disabled={
                            formik.isSubmitting ||
                            readonly ||
                            ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                              .NotEditable === true &&
                              formik.values.IsClosed === true)
                          }
                          fullWidth
                        />
                      </FormRow>
                    )}
                    {(formik.values.WorkOrderType as IWorkOrderTypeDto)
                      .TitleEnabled === true && (
                      <FormRow isCover={isCover}>
                        <FormTextField
                          name="AlertTitle"
                          label={t(translations.AlertName)}
                          placeholder={t(translations.AlertName)}
                          disabled={
                            formik.isSubmitting ||
                            readonly ||
                            ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                              .NotEditable === true &&
                              formik.values.IsClosed === true)
                          }
                          autoComplete="on"
                          id="AlertTitleId"
                          fullWidth
                        />
                      </FormRow>
                    )}

                    {(formik.values.WorkOrderType as IWorkOrderTypeDto)
                      .AssigneeEnabled === true && (
                      <FormRow isCover={isCover}>
                        <FormAdminUsersPicker
                          name="AssignUsers"
                          id="workorder_AssignUsers"
                          label={t(translations.AssignedUsers)}
                          placeholder={t(
                            translations.PleaseSelectAnAssigneeUser,
                          )}
                          predicates={AdminServicesFilter(
                            mapAssetsToServiceFilterDto(
                              formik.values.Assets as IAssetDto[],
                            ),
                          )}
                          multiple={true}
                          disabled={
                            formik.isSubmitting ||
                            readonly ||
                            ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                              .NotEditable === true &&
                              formik.values.IsClosed === true)
                          }
                          fullWidth
                        />
                      </FormRow>
                    )}
                    {!(
                      initialValues.WorkOrderType !== null &&
                      initialValues.ToDown === false
                    ) && (
                      <FormRow isCover={isCover}>
                        <FormWorkOrderTypePicker
                          name="WorkOrderType"
                          id="workorder_type"
                          disableClearable
                          label={t(translations.AlertType)}
                          placeholder={t(translations.PleaseSelectAnAlertType)}
                          disabled={
                            formik.isSubmitting ||
                            isEdit ||
                            readonly ||
                            ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                              .NotEditable === true &&
                              formik.values.IsClosed === true)
                          }
                          onChange={() => setTypeChanged(true)}
                          fullWidth
                        />
                      </FormRow>
                    )}
                    {isEdit && (
                      <FormRow isCover={isCover}>
                        <FormWorkOrderTypeStatusPicker
                          id="workordertypestatusId"
                          name="WorkOrderTypeStatus"
                          typeId={formik.values.WorkOrderType?.Id}
                          label={t(translations.EventTypeStatus)}
                          placeholder={t(
                            translations.PleaseSelectAnAlertTypeStatus,
                          )}
                          disabled={
                            formik.isSubmitting ||
                            readonly ||
                            ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                              .NotEditable === true &&
                              formik.values.IsClosed === true)
                          }
                          //onChange={() => setStatusChanged(true)}
                          fullWidth
                        />
                      </FormRow>
                    )}
                    {formik.values.WorkOrderType !== null &&
                      (formik.values.WorkOrderType as IWorkOrderTypeDto)
                        .ReportSymptoms === true && (
                        <FormRow isCover={isCover}>
                          <FormSymptomCodesPicker
                            name="SymptomCodes"
                            predicates={
                              formik.values.Assets.length > 0
                                ? SymptomCodesAssetCatFilter(
                                    formik.values.Assets.filter(
                                      f =>
                                        !isNullOrUndefined(
                                          (f as IAssetDto).AssetCatId,
                                        ),
                                    ).map(
                                      asset =>
                                        (asset as IAssetDto).AssetCatId ?? 0,
                                    ),
                                  )
                                : undefined
                            }
                            label={t(translations.SymptomCodes)}
                            placeholder={t(
                              translations.PleaseSelectAnAlertSymptomCode,
                            )}
                            disabled={
                              formik.isSubmitting ||
                              readonly ||
                              ((
                                formik.values.WorkOrderType as IWorkOrderTypeDto
                              ).NotEditable === true &&
                                formik.values.IsClosed === true)
                            }
                            fullWidth
                          />
                        </FormRow>
                      )}
                    {isEdit &&
                      formik.values.WorkOrderType !== null &&
                      (formik.values.WorkOrderType as IWorkOrderTypeDto)
                        .RepairCodesEnabled && (
                        <FormRow isCover={isCover}>
                          <FormRepairCodesPicker
                            name="RepairCodes"
                            predicates={
                              formik.values.Assets.length > 0
                                ? RepairCodesAssetCatFilter(
                                    formik.values.Assets.filter(
                                      f =>
                                        !isNullOrUndefined(
                                          (f as IAssetDto).AssetCatId,
                                        ),
                                    ).map(
                                      asset =>
                                        (asset as IAssetDto).AssetCatId ?? 0,
                                    ),
                                  )
                                : undefined
                            }
                            label={t(translations.RepairCodes)}
                            placeholder={t(
                              translations.PleaseSelectAnAlertRepairCode,
                            )}
                            disabled={
                              formik.isSubmitting ||
                              readonly ||
                              ((
                                formik.values.WorkOrderType as IWorkOrderTypeDto
                              ).NotEditable === true &&
                                formik.values.IsClosed === true)
                            }
                            fullWidth
                          />
                        </FormRow>
                      )}
                    {formik.values.RemindBefore?.Id === 6 && (
                      <FormRow isCover={isCover}>
                        <FormBookitDateTimePicker
                          name="AlertStart"
                          // label={t(translations.AlertStart)}
                          id="workorder_AlertStart"
                          label={t(translations.AlertStartTimeLabel)}
                          labelInline={true}
                          disabled={
                            formik.isSubmitting ||
                            readonly ||
                            ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                              .NotEditable === true &&
                              formik.values.IsClosed === true)
                          }
                          fullWidth
                        />
                      </FormRow>
                    )}
                  </FormFieldsSection>
                  {formik.values.Assets.length > 0 &&
                    (formik.values.WorkOrderType as IWorkOrderTypeDto)
                      .DowntimeEnabled === true &&
                    (formik.values.Assets as IAssetDto[])[0].ServiceTypeId ===
                      ServiceType.Online &&
                    ((isEdit &&
                      (isAdmin || initialValues.OfflineEventStart !== null)) ||
                      (!isEdit && isAdmin)) && (
                      <React.Fragment>
                        <FormDivider />
                        <FormFieldsSection>
                          <FormRow isCover={isCover} fullRow>
                            <FormSwitch
                              boldLebel
                              name="Downtime"
                              id="workorder_Downtime"
                              label={t(translations.Downtime)}
                              disabled={
                                formik.isSubmitting ||
                                readonly ||
                                ((
                                  formik.values
                                    .WorkOrderType as IWorkOrderTypeDto
                                ).NotEditable === true &&
                                  formik.values.IsClosed === true)
                              }
                              onChange={() => setDownTimeChanged(true)}
                            />
                          </FormRow>
                          {formik.values.Downtime && (
                            <FormRow isCover={isCover} fullRow>
                              <FormReasonTypePicker
                                name="ReasonType"
                                id="workorder_ReasonType"
                                label={t(translations.Reason)}
                                placeholder={t(
                                  translations.PleaseSelectAReasonType,
                                )}
                                disabled={
                                  formik.isSubmitting ||
                                  readonly ||
                                  ((
                                    formik.values
                                      .WorkOrderType as IWorkOrderTypeDto
                                  ).NotEditable === true &&
                                    formik.values.IsClosed === true)
                                }
                                fullWidth
                              />
                            </FormRow>
                          )}
                          {formik.values.Downtime && (
                            <FormRow isCover={isCover}>
                              <FormBookitDateTimePicker
                                name="OfflineEventStart"
                                id="offlineStartId"
                                label={t(translations.OfflineEventStartLabel)}
                                disabled={
                                  formik.isSubmitting ||
                                  readonly ||
                                  ((
                                    formik.values
                                      .WorkOrderType as IWorkOrderTypeDto
                                  ).NotEditable === true &&
                                    formik.values.IsClosed === true)
                                }
                                onChange={handleOfflineEventStartChange}
                                fullWidth
                              />
                            </FormRow>
                          )}
                          {formik.values.Downtime && !unlimitedDowntime && (
                            <FormRow isCover={isCover}>
                              <FormBookitDateTimePicker
                                name="OfflineEventEnd"
                                id="offlineEndId"
                                label={t(translations.OfflineEventEndLabel)}
                                disabled={
                                  formik.isSubmitting ||
                                  readonly ||
                                  ((
                                    formik.values
                                      .WorkOrderType as IWorkOrderTypeDto
                                  ).NotEditable === true &&
                                    formik.values.IsClosed === true)
                                }
                                onChange={handleOfflineEventEndChange}
                                maskIfEmpty={false}
                                fullWidth
                              />
                            </FormRow>
                          )}
                          {formik.values.Downtime && unlimitedDowntime && (
                            <FormRow isCover={isCover}>
                              <FormUnlimitedDowntime
                                onClick={() => {
                                  formik.setFieldValue(
                                    'OfflineEventEnd',
                                    dateUtils.formatISO(newDate()),
                                  );
                                  setUnlimitedDowntime(false);
                                }}
                              />
                            </FormRow>
                          )}
                          {formik.values.Downtime && (
                            <FormRow isCover={isCover} fullRow>
                              <FormRichTextField
                                name="OfflineRemarks"
                                id="workorder_offline_remarks"
                                placeholder={t(
                                  translations.OfflineRemarksPlaceholder,
                                )}
                                disabled={
                                  formik.isSubmitting ||
                                  readonly ||
                                  ((
                                    formik.values
                                      .WorkOrderType as IWorkOrderTypeDto
                                  ).NotEditable === true &&
                                    formik.values.IsClosed === true)
                                }
                                fullWidth
                              />
                            </FormRow>
                          )}
                        </FormFieldsSection>
                      </React.Fragment>
                    )}
                  {(formik.values.WorkOrderType as IWorkOrderTypeDto)
                    .EventTimeEnabled === true && (
                    <React.Fragment>
                      <FormDivider />
                      <FormFieldsSection>
                        <FormRow isCover={isCover}>
                          <FormBookitDateTimePicker
                            name="EventStart"
                            // label={t(translations.EventDueDate)}
                            label={t(translations.EventDueDateTimeLabel)}
                            id="workorder_EventStart"
                            labelInline={true}
                            disabled={
                              formik.isSubmitting ||
                              readonly ||
                              ((
                                formik.values.WorkOrderType as IWorkOrderTypeDto
                              ).NotEditable === true &&
                                formik.values.IsClosed === true)
                            }
                            fullWidth
                          />
                        </FormRow>
                        <FormRow isCover={isCover}>
                          <FormRemindBeforePicker
                            name="RemindBefore"
                            id="workorder_RemindBefore"
                            label={t(translations.ReminderOptions)}
                            info={t(translations.RemindMeBefore_Description)}
                            disabled={
                              formik.isSubmitting ||
                              readonly ||
                              ((
                                formik.values.WorkOrderType as IWorkOrderTypeDto
                              ).NotEditable === true &&
                                formik.values.IsClosed === true)
                            }
                            onChange={() => setRemindBeforeChanged(true)}
                            fullWidth
                          />
                        </FormRow>
                      </FormFieldsSection>
                      {!isEdit && (
                        <RepetitiveControl
                          // withSeparator={showShortView}
                          startDate={dateUtils.dateOrStringToDate(
                            formik.values.EventStart,
                          )}
                          submitFormRef={repetitiveFormRef}
                          //title={t(translations.RecurringEvents) as string}
                          type={RepetitiveFor.WorkOrder}
                        />
                      )}
                    </React.Fragment>
                  )}

                  {formik.values.WorkOrderType !== null &&
                    (formik.values.WorkOrderType as IWorkOrderTypeDto)
                      .CustomFormId !== null && (
                      <React.Fragment>
                        <FormDivider />
                        <CustomFormControl
                          CustomFormId={
                            (formik.values.WorkOrderType as IWorkOrderTypeDto)
                              .CustomFormId ?? 0
                          }
                          isAdmin={isAdmin}
                          values={formik.values.SubmissionFormValues}
                          FormType={CustomFormTypeEnum.AlertType}
                          submitFormRef={workOrderTypeFormRef}
                          formIsValid={validateForms}
                          formName={t(translations.SubmissionFormDetails)}
                          readonly={formik.isSubmitting || readonly}
                          saveFormValues={(
                            values: IFormValueDto[],
                            files: IFormFileValue[],
                            originalFiles: IFormFileValue[],
                          ) => {
                            formik.setFieldValue(
                              'SubmissionFormValues',
                              updateFormValues(
                                formik.values.SubmissionFormValues,
                                values,
                                formik.values.Id > 0 ? formik.values.Id : null,
                                formik.values.WorkOrderType?.Id || null,
                              ),
                              false,
                            );
                            formik.setFieldValue(
                              'SubmissionFormFiles',
                              files,
                              false,
                            );
                            setOriginalSubFormFiles(originalFiles);
                          }}
                        />
                      </React.Fragment>
                    )}
                </FormLeftSection>
                <FormRightSection isCover={isCover}>
                  <FormFieldsSection>
                    {expanded && (
                      <FormRow isCover={isCover}>
                        <FormRichTextField
                          name="Description"
                          placeholder={t(translations.Description)}
                          id="workorder_Description"
                          disabled={
                            formik.isSubmitting ||
                            readonly ||
                            ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                              .NotEditable === true &&
                              formik.values.IsClosed === true)
                          }
                          fullWidth
                        />
                      </FormRow>
                    )}
                    <WorkOrderComments
                      commentsFormRef={commentsFormRef}
                      isEdit={isEdit}
                      user={user}
                      order={formik.values}
                      isAdmin={isAdmin}
                      disabled={
                        (readonly &&
                          !(user?.Id === formik.values.ReportedBy)) ||
                        ((formik.values.WorkOrderType as IWorkOrderTypeDto)
                          .NotEditable === true &&
                          formik.values.IsClosed === true) ||
                        readOnlyUser
                      }
                    />
                  </FormFieldsSection>

                  {isEdit && formik.values.HasAletForm && (
                    <React.Fragment>
                      <FormDivider />
                      <FormFieldsSection
                        titleSection={t(translations.StaffFilledForm)}
                      >
                        <FormRow isCover={isCover}>
                          <FormCustomFormPicker
                            formType={CustomFormTypeEnum.Alert}
                            name="StuffCustomForm"
                            placeholder={t(translations.PleaseSelectCustomForm)}
                            disabled={
                              formik.isSubmitting ||
                              readonly ||
                              ((
                                formik.values.WorkOrderType as IWorkOrderTypeDto
                              ).NotEditable === true &&
                                formik.values.IsClosed === true)
                            }
                            filter={AlertFormFilter(
                              formik.values.WorkOrderType?.Id || 0,
                              (formik.values.Assets[0] as IAssetDto)
                                .AssetCatId || 0,
                            )}
                            fullWidth
                          />
                        </FormRow>
                      </FormFieldsSection>
                      {formik.values.StuffCustomForm !== null &&
                        formik.values.StuffCustomForm.Id !== 0 && (
                          <CustomFormControl
                            CustomFormId={formik.values.StuffCustomForm.Id ?? 0}
                            readonly={readonly}
                            isAdmin={isAdmin}
                            values={formik.values.StaffFormValues}
                            FormType={CustomFormTypeEnum.Alert}
                            submitFormRef={workOrderStaffFormRef}
                            formIsValid={validateForms}
                            saveFormValues={(
                              values: IFormValueDto[],
                              files: IFormFileValue[],
                              originalFiles: IFormFileValue[],
                            ) => {
                              formik.setFieldValue(
                                'StaffFormValues',
                                updateFormValues(
                                  formik.values.StaffFormValues,
                                  values,
                                  formik.values.Id > 0
                                    ? formik.values.Id
                                    : null,
                                  null,
                                ),
                                false,
                              );
                              formik.setFieldValue(
                                'StaffFormFiles',
                                files,
                                false,
                              );
                              setOriginalStaffFormFiles(originalFiles);
                            }}
                          />
                        )}
                    </React.Fragment>
                  )}

                  {readonlyDetails !== undefined && isEdit && expanded && (
                    <React.Fragment>
                      <FormDivider />
                      <FormFieldsSection>
                        <CreationDetails
                          details={readonlyDetails}
                          useSidePanel={useSidePanel}
                          openPanel={openPanel}
                        />
                      </FormFieldsSection>
                    </React.Fragment>
                  )}
                  {(isEdit
                    ? !!linkedWorkOrders && linkedWorkOrders.length > 0
                    : savedLinkedOrders.length > 0) && (
                    <WorkOrderLinks
                      links={
                        isEdit
                          ? linkedWorkOrders
                          : savedLinkedOrders.map(f => {
                              return {
                                Id: f.Id,
                                Name: `${f.AssetName} - ${f.Name}`,
                                ParentId: -1,
                                Linked: [],
                              };
                            })
                      }
                      onHoverClick={item => {
                        isEdit
                          ? removeCalibrationOrder(item)
                          : changeLinkedOrders(
                              [item as Entity<number> as IWorkOrderDetailsDto],
                              'remove',
                            );
                      }}
                      onItemClcik={item => {
                        isEdit
                          ? dispatch(
                              actions.openSidePanel({
                                type: RenderPageType.WorkOrderDetails,
                                props: {
                                  queryParams: { id: '' + item.Id },
                                  useSidePanel: true,
                                  continueTo: {
                                    pageType: useSidePanel
                                      ? RenderPageType.WorkOrderDetails
                                      : undefined,
                                    pageProps: useSidePanel
                                      ? {
                                          queryParams: {
                                            id: '' + formik.values.Id,
                                          },
                                          useSidePanel: true,
                                        }
                                      : undefined,
                                    continueToLink: useSidePanel
                                      ? undefined
                                      : pageLink,
                                  },
                                } as WorkOrderDetailsProps,
                              }),
                            )
                          : !!onLinkedClick && onLinkedClick(item);
                      }}
                      showProcessing={linksProcessing}
                    />
                  )}
                  {isAdmin &&
                    formik.values.WorkOrderType !== null &&
                    formik.values.Assets.length > 0 && (
                      <FormRow isCover={isCover} fullRow={true}>
                        <AddCalibrationWorkOrder
                          showProcessing={linksProcessing}
                          workOrederIds={
                            isEdit
                              ? [
                                  ...linkedWorkOrders.map(f => f.Id),
                                  ...[formik.values.Id],
                                ]
                              : savedLinkedOrders.map(f => f.Id)
                          }
                          saveLinkedOrders={value => {
                            if (isEdit) {
                              addCalibrationOrder({
                                Id: value.Id,
                                Name: value.Name,
                              });
                            } else {
                              changeLinkedOrders([value], 'add');
                            }
                          }}
                        />
                      </FormRow>
                    )}
                </FormRightSection>
              </StyledForm>
            </>
          );
        }}
      </Formik>
    </>
  );
});
const log = getLogger('WorkOrderForm');
