/**
 *
 * UserPicker
 *
 */
import { InputBaseComponentProps } from '@material-ui/core';
import { httpClient } from 'api/HttpClient';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import {
  Condition,
  ODataOperators,
  quoteODataValue,
} from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Entity, Identifiable } from 'types/common';

export type UserUrl =
  | 'restricted'
  | 'full'
  | 'admin'
  | 'relevant'
  | 'budgetExperiments';
export interface UserPickerProps
  extends Omit<AutocompletePickerProps<IUserFilterDto>, 'loadData'> {
  inputProps?: InputBaseComponentProps;
  selectArray?: Array<keyof IUserFilterDto>;
  urlType?: UserUrl;
  withGroupFilter?: boolean;
  serviceGroups?: Identifiable<number>[];
}

const noExpandURlTypes: (UserUrl | undefined)[] = [
  'budgetExperiments',
  'relevant',
];

const url = '/api/odata/v4/UserFilter';
const fullActiveUrl = '/api/odata/v4/UserFilter/GetUserSelections';
const adminsUrl = '/api/odata/v4/UserFilter/GetAdminUsers';
export const relevantUrl = '/api/odata/v4/UserFilter/GetRelevantUsers';
const budgetExperimentsUrl =
  '/api/odata/v4/UserFilter/GetBudgetExperimentsUsers';
export const initUserData = (id: string, urlType?: UserUrl) => {
  const params: {
    $orderby: string;
    $filter?: string;
    $top: number;
    $skip: number;
  } = {
    $orderby: 'Name asc',
    $filter: new Condition('Id', ODataOperators.Equals, id).toString(),
    $skip: 0,
    $top: 1,
  };
  let initUrl =
    urlType === 'full'
      ? fullActiveUrl
      : urlType === 'admin'
      ? adminsUrl
      : urlType === 'budgetExperiments'
      ? budgetExperimentsUrl
      : url;
  return httpClient
    .get(initUrl, params)
    .then(response => response.value as Entity<string>[]);
};

export function UserPicker(props: UserPickerProps) {
  const { t } = useTranslation();
  const servicegroups = useSelector(selectGlobalServiceGroupFilter);
  const serviceGroups = props.serviceGroups ?? servicegroups;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadData = React.useCallback(
    getAutoCompleteLoadDataFn<IUserFilterDto>({
      url:
        props.urlType === 'full'
          ? fullActiveUrl
          : props.urlType === 'admin'
          ? adminsUrl
          : props.urlType === 'relevant'
          ? relevantUrl
          : props.urlType === 'budgetExperiments'
          ? budgetExperimentsUrl
          : url,
      predicates: props.predicates,
      globalServiceGroupFilter:
        !props.withGroupFilter || !serviceGroups?.length
          ? undefined
          : [
              new Condition<any>(
                'ServiceGroups',
                ODataOperators.AnyIn,
                serviceGroups,
                'Id',
              ),
            ],
      select: props.selectArray ?? [
        'Id',
        'Name',
        'UserGroupId',
        'UserGroupName',
        'UserGroups',
        'Budgets',
        'IsAdminPanel',
        'IsEquipmentAdmin',
        'IsGroupAdmin',
        'IsLabTech',
        'ServiceGroupIds',
        'Services',
        'CustomRoles',
        'UserGroupId',
        'UserGroupName',
        'UserRoles',
        'StaffEquipmentId',
        'DefaultBudgetId',
      ],
      expand: noExpandURlTypes.includes(props.urlType)
        ? ''
        : 'Services,UserGroups',
    }),
    [serviceGroups, props.withGroupFilter],
  );
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      loadData={loadData}
      id={props.id || 'userpickerId'}
      placeholder={t(translations.AllUsers)}
      {...props}
    />
  );
}
export function ServicesFilter(
  services: IServiceTypeFilterDto[],
  budgetExperimentEnabled: boolean,
  userGroupId?: string,
  budgetId?: number,
  defaultBudgetEnabled?: boolean,
  isDefaultSelected?: boolean,
): (string | Condition<IUserFilterDto>)[] {
  const predicates: (string | Condition<IUserFilterDto>)[] = [];
  if (userGroupId !== undefined) {
    predicates.push(
      `(${new Condition<IUserFilterDto>(
        'UserGroups',
        ODataOperators.Any,
        userGroupId,
        'Id',
      )} or ${new Condition<IUserFilterDto>(
        'UserGroupId',
        ODataOperators.Equals,
        userGroupId,
      )})`,
    );
  }
  if (budgetId !== undefined && !isDefaultSelected) {
    if (defaultBudgetEnabled) {
      predicates.push(
        `(${new Condition<IUserFilterDto>(
          'Budgets',
          ODataOperators.Any,
          budgetId,
        ).toString()} or ${new Condition<IUserFilterDto>(
          'DefaultBudgetId',
          ODataOperators.Equals,
          budgetId,
        ).toString()})`,
      );
    } else {
      predicates.push(
        new Condition<IUserFilterDto>('Budgets', ODataOperators.Any, budgetId),
      );
    }
  }
  if (
    services.length > 0 &&
    budgetExperimentEnabled &&
    !services.some(f => f.HideProject)
  ) {
    //TODO: add filter by selected services
  }
  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}
export function AdminServicesFilter(
  serviceItems: IServiceFilterDto[],
): (string | Condition<IUserFilterDto>)[] {
  if (!serviceItems.length || serviceItems.length < 1) {
    return [];
  }
  let groups = [...new Set(serviceItems.map(f => f.ServiceGroupId))];
  let groupIds = groups.map(item => {
    return { Id: item };
  }) as Identifiable<number>[];
  let services = serviceItems
    .map(f => {
      return {
        ServiceId: f.Id ?? 0,
        ServiceTypeId: f.ServiceTypeId ?? 0,
      };
    })
    .filter(f => f.ServiceId !== 0 && f.ServiceTypeId !== 0);
  let groupsF = `(${new Condition(
    'ServiceGroupIds',
    ODataOperators.AnyIn,
    groupIds,
  ).toString()})`;
  // let groupsF = `(${groups
  //   .map(a => new Condition('ServiceGroupIds', ODataOperators.AnyIn, groupIds).toString())
  //   .join(' and ')})`;
  let servF = `(Services/any(s: s/Id in ${quoteODataValue(
    services.map(f => f.ServiceId),
  )}))`;

  return [`${groupsF} or ${servF}`];
}
export const initAssigneeAdminUsers = (
  type: IWorkOrderTypeDto,
  serviceItems: IServiceFilterDto[],
) => {
  const predicates: (string | Condition<IUserFilterDto>)[] = [];
  if (type.AssignedToCoreAdmin) {
    predicates.push(
      new Condition<IUserFilterDto>(
        'IsGroupAdmin',
        ODataOperators.Equals,
        true,
      ),
    );
  }
  if (type.AssignedToInstrumentAdmin) {
    predicates.push(
      new Condition<IUserFilterDto>(
        'IsEquipmentAdmin',
        ODataOperators.Equals,
        true,
      ),
    );
  }
  if (type.AssignedToLabTech) {
    predicates.push(
      new Condition<IUserFilterDto>('IsLabTech', ODataOperators.Equals, true),
    );
  }
  if (serviceItems.length < 1 || predicates.length < 1) {
    return new Promise<Entity<string>[]>((resolve, reject) => {
      resolve([] as Entity<string>[]);
    });
  } else {
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: `${AdminServicesFilter(serviceItems)[0]} and (${predicates
        .map(f => f.toString())
        .join(' or ')})`,
      $select: 'Id,Name',
      $skip: 0,
      $top: 1,
    };

    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<string>[]);
  }
};
