import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormSectionTitle } from 'app/components/Forms/FormsLayout/FormSectionTitle';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { IBasicTableState, IRow } from 'app/components/BasicTable';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { GettableCount } from 'app/components/BasicTable/components/GetTableCount';
import { getColumns } from 'app/pages/AssetSearchHistory/Columns';
import { IAssetSearchHistoryDto } from 'app/pages/AssetSearchHistory/IAssetSearchResultsDto';
import { AssetSearchHistoryTable } from 'app/pages/AssetSearchHistory/AssetSearchHistoryTable';

const UserSearchHistoryColumns: string[] = [
  'SearchDatetime',
  'ServiceName',
  'ServiceGroupName',
  'MainText',
  'Tags',
  'Description',
  'ModelNumber',
  'BuildingNumber',
  'RoomNumber',
  'Availability',
  'AssetCatGroupName',
  'AssetCatName',
  'ResultsCount',
];
const url = '/api/odata/v4/AssetSearchHistory';

const GetPredicates = (
  userName: string,
): Array<Condition<IAssetSearchHistoryDto> | string> => {
  const res: Array<Condition<IAssetSearchHistoryDto> | string> = [];
  res.push(
    new Condition<IAssetSearchHistoryDto>(
      'UserName',
      ODataOperators.Equals,
      userName,
    ),
  );
  return res;
};
export async function GetSearchHistoryTableCount(
  userName: string,
  name: string,
  updateCounters: (name: string, count: number) => void,
) {
  const res = await GettableCount({
    api: url,
    predicates: GetPredicates(userName) as Array<Condition<IRow> | string>,
    columns: ['Id'],
    globalFilterValue: '',
    customFilter: [],
    serviceGroups: [],
    isOptionalServiceGroup: true,
  });
  updateCounters(name, res);
}
export interface UserSearchHistoryTableProps {
  userName: string;
  getFiltersDefinition?: GetPageFilters<IAssetSearchHistoryDto>;
  isMobile?: boolean;
  useSidePanel?: boolean;
  openPanel: (state: SidePanelOpenState) => void;
}
export function UserSearchHistoryTable(props: UserSearchHistoryTableProps) {
  const { t } = useTranslation();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const historyColumns = React.useMemo(
    () => getColumns(t, authenticatedUser, props.useSidePanel, props.openPanel),
    [authenticatedUser, props.openPanel, props.useSidePanel, t],
  );
  const columns = React.useMemo(() => {
    let res: Column<IAssetSearchHistoryDto>[] = [];
    for (const c of UserSearchHistoryColumns) {
      const column = historyColumns.find(f => f.accessor === c);
      if (column !== undefined) {
        res.push(column);
      }
    }
    return res;
  }, [historyColumns]);

  const predicates = React.useMemo(() => {
    return GetPredicates(props.userName);
  }, [props.userName]);
  const initialState: IBasicTableState<IAssetSearchHistoryDto> = React.useMemo(
    () => ({
      sortBy: [{ id: 'SearchDatetime', desc: true }],
    }),
    [],
  );
  return (
    <AssetSearchHistoryTable
      columns={columns}
      initialState={initialState}
      predicates={predicates}
      useConfigurable={false}
      useCardsByDefault={props.isMobile}
      allowCards={props.isMobile}
      useExport={false}
      serviceGroups={[]}
      customScreenName={
        <React.Fragment>
          <FormSectionTitle title={t(translations.menu_AssetSearchHistory)} />
          {/* <IconButton aria-label="link" onClick={goToTrainings}>
            <Icon icon="arrow-up-right-from-square" />
          </IconButton> */}
        </React.Fragment>
      }
      hideMenuOnMobile={true}
      screenNameVariant="section"
      tablePaddingVariant={'dense'}
      compactTable={props.isMobile}
    />
  );
}
