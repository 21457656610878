import { IArticleDto } from 'api/odata/generated/entities/IArticleDto';
import React from 'react';
import { ArticlesTable } from 'app/pages/ArticlesPage/ArticlesTable';
import { IBasicTableState, IRow } from 'app/components/BasicTable';
import { Condition } from 'api/odata/ODataFilter';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { PrimaryAction } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import articlePrimaryAction from 'app/pages/ArticlesPage/PrimaryAction';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { GettableCount } from 'app/components/BasicTable/components/GetTableCount';

const url = '/api/odata/v4/articles';
const GetPredicates = (
  assetId: number,
  serviceGroupId: number | undefined,
  availabilityType?: string,
): string[] => {
  // The asset matches and the service group matches or there is only a service group and it matches.
  const res: string[] = [];
  if (!!availabilityType && availabilityType === 'ServiceGroup') {
    res.push(
      `Assets/any(a: a/Id eq ${assetId} ${
        !!serviceGroupId ? 'or a/ServiceGroupId eq ' + serviceGroupId : ''
      })`,
    );
  } else {
    res.push(
      `Assets/any(a: a/Id eq ${assetId} ${
        !!serviceGroupId ? 'and a/ServiceGroupId eq ' + serviceGroupId : ''
      })`,
    );
  }
  return res;
};
export async function GetArticlesTableCount(
  assetId: number,
  serviceGroupId: number | undefined,
  name: string,
  updateCounters: (name: string, count: number) => void,
  availabilityType: string,
) {
  let res = 0;
  res = await GettableCount({
    api: url,
    predicates: GetPredicates(
      assetId,
      serviceGroupId,
      availabilityType,
    ) as Array<Condition<IRow> | string>,
    columns: ['Id'],
    globalFilterValue: '',
    customFilter: [],
    serviceGroups: [],
    isOptionalServiceGroup: true,
  });
  updateCounters(name, res);
}
export interface AssetArticlesTableProps {
  assetId: number;
  serviceGroupId?: number;
  isMobile?: boolean;
  useSidePanel?: boolean;
  openPanel: (state: SidePanelOpenState) => void;
  availabilityType?: string;
  user?: AuthenticatedUser;
}
export function AssetArticlesTable(props: AssetArticlesTableProps) {
  //const { serviceGroups } = useGlobalServiceGroupsFilter(true);
  /*const {
    allfilters,
    appliedFilters,
    handleFilterChange,
    getFilterValue,
    setFilterValue,
  } = useFilters(props.getFiltersDefinition, 'AssetId');*/
  const predicates = React.useMemo(() => {
    return GetPredicates(
      props.assetId,
      props.serviceGroupId,
      props.availabilityType,
    );
  }, [props.assetId, props.availabilityType, props.serviceGroupId]);
  const initialState: IBasicTableState<IArticleDto> = React.useMemo(
    () => ({
      sortBy: [{ id: 'Id', desc: false }],
    }),
    [],
  );
  const primaryAction: PrimaryAction<IArticleDto> = React.useCallback(
    (...args) => {
      articlePrimaryAction(
        props.openPanel,
        args[0],
        undefined,
        args[2],
        props.useSidePanel,
      );
    },
    [props.openPanel, props.useSidePanel],
  );
  return (
    <ArticlesTable
      initialState={initialState}
      predicates={predicates}
      /*availableFilters={allfilters}
      appliedFilters={appliedFilters}
      onFilterChange={handleFilterChange}
      getFilterValue={getFilterValue}
      setFilterValue={setFilterValue}*/
      useConfigurable={false}
      useCardsByDefault={props.isMobile}
      allowCards={props.isMobile}
      useExport={false}
      //serviceGroups={serviceGroups}
      hideMenuOnMobile={true}
      screenNameVariant="section"
      tablePaddingVariant={'dense'}
      compactTable={props.isMobile}
      useSidePanel={props.useSidePanel}
      openPanel={props.openPanel}
      primaryAction={primaryAction}
    />
  );
}
