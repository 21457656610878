import { RadioGroupsControl } from 'app/components/BasicInputs/RadioGroupsControl';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';

export type SubscriptionForOption = '' | 'usergroup';

export interface SubscriptionForOptionsProps {
  value: SubscriptionForOption | undefined;
  onChange: (value: SubscriptionForOption) => void;
}

export function SubscriptionForOptions({
  value,
  onChange,
}: SubscriptionForOptionsProps) {
  const { t } = useTranslation();

  const options: Entity<string>[] = [
    {
      Id: '',
      Name: t(translations.BySubscriptionUser),
    },
    {
      Id: 'usergroup',
      Name: t(translations.BySubscriptionUserGroup),
    },
  ];

  const handleValueChange = event => {
    const value = event as SubscriptionForOption;
    onChange(value);
  };

  return (
    <RadioGroupsControl
      id="SubscriptionForOptionsID"
      name={'SubscriptionForOptions'}
      options={options}
      value={value}
      onChange={handleValueChange}
    />
  );
}
