import { IAssetDetails2Dto } from 'api/odata/generated/entities/IAssetDetails2Dto';
import { BasicAlert } from 'app/components/BasicAlert';
import { Button } from 'app/components/BasicButtons/Button';
import { FlexColumn16 } from 'app/components/StyledComponents';
import { Body } from 'app/components/Typography';
import { useAppSettingsSlice } from 'app/slice';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Entity } from 'types/common';

export function MismatchCore(
  data: IAssetDetails2Dto | undefined,
  isAuthenticated: boolean | undefined,
  topServiceGroupsIsHidden: boolean,
  selectedServiceGroups: Entity<number>[] | undefined,
): boolean {
  if (isAuthenticated && !topServiceGroupsIsHidden) {
    const showAllCores =
      !!data &&
      !!data.ServiceGroup &&
      selectedServiceGroups?.find(f => f.Id === data?.ServiceGroup.Id) ===
        undefined;
    return showAllCores
      ? !!data &&
          !!data.AvailabilityType &&
          data.AvailabilityType?.Id !== 'ServiceGroup' &&
          data.AvailabilityType?.Id !== 'SuperGroup' &&
          !!selectedServiceGroups &&
          selectedServiceGroups.length > 0 &&
          !selectedServiceGroups.some(f => f.Id === data.ServiceGroup.Id)
      : false;
  } else {
    return false;
  }
}

export interface MismatchCoreMessageProps {
  data: IAssetDetails2Dto | undefined;
  mismatchCore: boolean;
}
export function MismatchCoreMessage({
  data,
  mismatchCore,
}: MismatchCoreMessageProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const { actions } = useAppSettingsSlice();
  const updateGlobalServiceGroup = React.useCallback(() => {
    const showAllCores =
      !!data &&
      !!data.ServiceGroup &&
      selectedServiceGroups?.find(f => f.Id === data?.ServiceGroup.Id) ===
        undefined;
    if (showAllCores && !!data && !!data.ServiceGroup.Id) {
      const groups = [
        ...(selectedServiceGroups ?? []),
        ...(data.ServiceGroup !== null ? [data.ServiceGroup] : []).map(sg => ({
          Id: sg.Id,
          Name: sg.Name,
        })),
      ];

      dispatch(actions.saveGlobalServiceGroupFilter(groups));
    }
  }, [actions, data, dispatch, selectedServiceGroups]);
  return mismatchCore ? (
    <BasicAlert
      content={{
        closable: true,
        type: 'warning',
        children: (
          <FlexColumn16>
            <Body size="small">
              {t(translations.MismatchAssetCore_warning) as string}
            </Body>
            <Button size="small" onClick={() => updateGlobalServiceGroup()}>
              {t(translations.SwitchToThisCore) as string}
            </Button>
          </FlexColumn16>
        ),
        variant: 'outlined',
      }}
    />
  ) : (
    <></>
  );
}
