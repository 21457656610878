import { ISamplePlateDto } from 'api/odata/generated/entities/ISamplePlateDto';
import { AsyncDataState, Entity } from 'types/common';
import { IResponseType } from 'types/ResponseType';
import { toEntity } from 'utils/entity-utils';

/* --- STATE --- */
export interface SamplePlatesSidePanelState {
  createState: AsyncDataState<SamplePlatesDetailsState>;
  updateState: AsyncDataState<SamplePlatesDetailsState>;
  processing: boolean;
}

export interface SamplePlatesDetailsState {
  Id: string;
  Room?: Entity<number> | null | undefined;
  Location?: Entity<number> | null | undefined;
  SamplePlateType: Entity<number> | null | undefined;
  ServiceGroup: Entity<number> | null | undefined;
  Active: boolean | undefined;
  IsRack: boolean | undefined;
}

export interface SamplePlatesDetailsQStringParameters {
  id?: string;
  roomId?: string;
  room?: string;
  locId?: string;
  loc?: string;
  samplePTId?: string;
  samplePT?: string;
  sgId?: string;
  sg?: string;
  active?: string;
  isRack?: string;
}

export interface SamplePlatesDetailsResponse extends IResponseType {
  Id?: number;
}

export function ConvertModelToEntitySamplePlatesDetails(
  entity: ISamplePlateDto,
): SamplePlatesDetailsState | undefined {
  if (!entity) {
    return undefined;
  } else {
    return {
      Id: entity.Id ?? '',
      Room: !!entity.RoomId ? toEntity(entity.RoomId, entity.RoomName) : null,
      Location: !!entity.LocationId
        ? toEntity(entity.LocationId, entity.LocationName)
        : null,
      SamplePlateType: !!entity.SamplePlateTypeId
        ? toEntity(entity.SamplePlateTypeId, entity.SamplePlateTypeName)
        : null,
      ServiceGroup: !!entity.ServiceGroupId
        ? toEntity(entity.ServiceGroupId, entity.ServiceGroupName)
        : null,
      Active: entity.Active ?? true,
      IsRack: entity.IsRack ?? false,
    } as SamplePlatesDetailsState;
  }
}

export function ConvertEntityToModelSamplePlatesDetails(
  model: SamplePlatesDetailsState,
  original: SamplePlatesDetailsState | undefined,
): ISamplePlateDto {
  let entity = {
    Id: original?.Id === undefined ? model.Id : original.Id,
    RoomId: model.Room?.Id,
    RoomName: model.Room?.Name,
    LocationId: model.Location?.Id,
    LocationName: model.Location?.Name,
    SamplePlateTypeId: model.SamplePlateType?.Id,
    SamplePlateTypeName: model.SamplePlateType?.Name,
    ServiceGroupId: model.ServiceGroup?.Id,
    ServiceGroupName: model.ServiceGroup?.Name,
    Active: model.Active ?? true,
    IsRack: model.IsRack ?? false,
  } as ISamplePlateDto;
  return entity;
}
