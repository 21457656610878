import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import {
  CatRateTypePicker,
  CatRateTypeProps,
} from '../pickers/AutocompletePickers/CatRateTypePicker';

interface FormCatRateTypePickerIntrinsicProps
  extends Omit<CatRateTypeProps, 'onBlur' | 'value' | 'error' | 'onChange'> {
  serviceGroupId?: number;
  invoiceId?: number;
}
export const FormCatRateTypePicker = ({
  onChange,
  onBlur,
  ...props
}: FieldHookConfig<Entity<number> | null> &
  FormCatRateTypePickerIntrinsicProps) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  return (
    <>
      <CatRateTypePicker
        onChange={value => helpers.setValue(value, true)}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        {...props}
      />
    </>
  );
};
