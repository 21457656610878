import { WaitingListSignUpLink } from 'app/pages/Actions/GlobalSelectedActions/OpenWaitingList';
import { IReservationWaitingListDto } from 'app/pages/ReservationWaitingListPage/IReservationWaitingListDto';
import { getIsRowSimpleMode } from 'app/pages/ReservationWaitingListPage/SignUpWaitingListDetails/utils';
import { selectUsageEnabled } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CellProps, Renderer } from 'react-table';

type RecordType = IReservationWaitingListDto;
const UserNotificationsStateRenderer: Renderer<CellProps<RecordType>> = ({
  value,
  row: { original },
}) => {
  const { t } = useTranslation();
  const usageEnabled = useSelector(selectUsageEnabled);

  const state = getIsRowSimpleMode(original, usageEnabled);
  return (
    <React.Fragment>
      {state.isSimpleMode ? (
        <WaitingListSignUpLink model={original} useSidePanel={true}>
          {(t(translations.WhenToNotify_SimpleMode) as string).replace(
            '{0}',
            state.hoursCount.toString(),
          )}
        </WaitingListSignUpLink>
      ) : (
        <WaitingListSignUpLink model={original} useSidePanel={true}>
          {(t(translations.WhenToNotify_AdvancedMode) as string).replace(
            '{0}',
            state.notificationsLength.toString(),
          )}
        </WaitingListSignUpLink>
      )}
    </React.Fragment>
  );
};
export default UserNotificationsStateRenderer;
