import { SiteMapNode } from 'types/SiteMapSettings';
import * as React from 'react';
import { isNullOrUndefined } from 'utils/typeUtils';
import { Button } from 'app/components/BasicButtons/Button';
import { LinkBehavior } from 'app/components/ExternalLink';
import { Trans } from 'react-i18next';

export interface MenuSearchItemProps {
  item: SiteMapNode;
}

export function MenuSearchItem(props: MenuSearchItemProps) {
  const { item } = props;
  const convertUrl = (url: string): string => {
    if (url !== null) {
      if (url.indexOf(process.env.PUBLIC_URL) !== -1) {
        return url.replace(process.env.PUBLIC_URL, '');
      }
      return url;
    }
    return url;
  };
  const link: string = React.useMemo(() => {
    return item.ChildNodes.filter(
      ch => ch.HideReact === false && ch.Hide === false,
    ).length > 0
      ? item.LinkWithChildren ?? item.reactUrl ?? convertUrl(item.Url)
      : item.reactUrl ?? convertUrl(item.Url);
  }, [item.ChildNodes, item.LinkWithChildren, item.Url, item.reactUrl]);

  const external = React.useMemo(() => {
    return (
      link.includes('aspx') ||
      (isNullOrUndefined(item.reactUrl) &&
        item.ChildNodes.filter(
          ch => ch.HideReact === false && ch.Hide === false,
        ).length === 0)
    );
  }, [item.ChildNodes, item.reactUrl, link]);
  return (
    <Button
      variant="ghostColor"
      component={LinkBehavior}
      size="small"
      to={external ? undefined : link}
      href={external ? link : undefined}
      target="_blank"
    >
      <Trans>{item.Title}</Trans>
    </Button>
  );
}
