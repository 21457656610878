import { httpClient } from 'api/HttpClient';
import { Condition } from 'api/odata/ODataFilter';
import { buildParams } from 'api/odata/ODataParamsBuilder';
import { IRow } from 'app/components/BasicTable';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { Entity } from 'types/common';
import { IPathAndQuery } from 'utils/url-utils';

function getUrlAndParams(
  api: string | IPathAndQuery,
  oDataParams: {
    $count?: boolean;
    $select?: string;
    $filter?: string | undefined;
  },
) {
  var url = typeof api === 'string' ? api : (api as IPathAndQuery).path;
  var search = (api as IPathAndQuery).search;
  const params = { ...oDataParams, ...search };
  return { url, params };
}
export async function GettableCount(state: {
  api: string;
  predicates: Array<Condition<IRow> | string>;
  columns: Array<keyof IRow>;
  globalFilterValue?: string;
  customFilter: IFilterSettings<IRow>[];
  serviceGroups: Entity<number>[];
  isOptionalServiceGroup: boolean;
}) {
  const oDataParams = buildParams<IRow>({
    ...state,
    select: state.columns,
    serviceGroups: state.serviceGroups,
    pageSize: 1,
    pageIndex: 0,
  });
  var { url, params } = getUrlAndParams(state.api, oDataParams);
  const response = await httpClient.get(url, params /*, cancelToken*/);
  const dataLength = response['@odata.count'];
  return dataLength;
}
