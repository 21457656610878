/**
 *
 * NumberFieldFilter
 *
 */
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import * as React from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { IFilterComponentProps } from '../IFilterComponentProps';

export const NumberFieldFilter = (props: IFilterComponentProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = React.useState<number | null>(null);
  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = event => {
    setValue(+event.target.value);
    props.onChange(+event.target.value);
  };

  const { t } = useTranslation();
  return (
    <TextControlField
      value={props.value || null}
      type="number"
      size="small"
      onChange={handleChange}
      autoFocus={true /*props.open*/}
      fullWidth={props.fullWidth}
      placeholder={t(translations.AllValues)}
      id={props.id}
      inputProps={{ 'aria-label': props.label }}
    />
  );
};
export default NumberFieldFilter;
