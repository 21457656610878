import { toRootedURL } from 'utils/url-utils';
import { AvailabilityTypesUnion } from 'api/odata/generated/enums/AvailabilityTypes';

export const getAssetEditLink = (
  availabilityType?: AvailabilityTypesUnion,
  assetId?: number,
  serviceId?: number,
  serviceGroupId?: number,
  divisionId?: number,
) => {
  if (!availabilityType || !assetId) {
    return undefined;
  }
  switch (availabilityType) {
    case 'NotAvailable':
    case 'AvailableNotBookable':
    case 'FreelyBookable':
    case 'TrainingProgram':
    case 'LoanableEquipment':
    case 'LectureRoom':
      return toRootedURL(
        `/NewResource.aspx?Edit=${serviceId}&back=assetdetails&assetid=${assetId}`,
      );
    case 'ServiceRelated':
      return toRootedURL(
        `/OfflineServiceType.aspx?ID=${serviceId}&back=assetdetails&assetid=${assetId}`,
      );
    case 'RequestRelated':
      return toRootedURL(
        `/SampleType.aspx?ID=${serviceId}&back=assetdetails&assetid=${assetId}`,
      );
    case 'ServiceGroup':
      return toRootedURL(`/newgroup.aspx?edit=${serviceGroupId}`);
    case 'SuperGroup':
      return toRootedURL(`/newsupergroup.aspx?edit=${divisionId}`);
    default:
      return undefined;
  }
};
