import { InputAdornment, InputBaseComponentProps } from '@material-ui/core';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import { omit } from 'lodash';
import * as React from 'react';
import BaseTextField from '../BaseTextField';
import { BaseInputFieldProps } from '../BaseTextField/BaseFieldDefinition';
import clsx from 'clsx';

export interface TextControlFieldProps extends BaseInputFieldProps {
  info?: string;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  autoFocus?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  type?: React.InputHTMLAttributes<unknown>['type'];
  min?: number;
  max?: number;
  className?: string;
  inputProps?: InputBaseComponentProps;
  minInputWidth?: 'xs' | 'small' | 'medium' | 'large' | 'default';
  textBoxWidth?: 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'default';
}

export function TextControlField({
  info,
  startIcon,
  endIcon,
  onBlur,
  onChange,
  onFocus,
  autoFocus,
  variant,
  type,
  inputProps,
  className,
  minInputWidth,
  textBoxWidth,
  ...props
}: TextControlFieldProps) {
  const startIconComponent = startIcon && (
    <InputAdornment position="start">{startIcon}</InputAdornment>
  );

  const endIconComponent = (
    <InputAdornment position="end">{endIcon}</InputAdornment>
  );
  return (
    <BaseTextField
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      autoFocus={autoFocus}
      variant={variant || 'filled'}
      type={type || 'text'}
      inputProps={{
        className: minInputWidth ? `min-width-${minInputWidth}` : undefined,
        ...inputProps,
      }}
      placeholder={props.placeholder}
      InputProps={{
        startAdornment: startIcon && startIconComponent,
        endAdornment:
          (info && <InfoIcon title={info} />) || (endIcon && endIconComponent),
      }}
      className={clsx(className, {
        startIcon: !!startIcon,
        endIcon: !!endIcon,
        'text-box-width-xs': textBoxWidth === 'xs',
        'text-box-width-small': textBoxWidth === 'small',
        'text-box-width-medium': textBoxWidth === 'medium',
        'text-box-width-large': textBoxWidth === 'large',
        'text-box-width-default': textBoxWidth === 'default',
        'text-box-width-xl': textBoxWidth === 'xl',
        'number-field': type === 'number',
      })}
      {...omit(props, 'DialogProps')}
    ></BaseTextField>
  );
}
