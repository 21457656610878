import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.offlineTypeRefs || initialState;

export const selectOfflineTypeRefs = createSelector(
  [selectSlice],
  state => state,
);

export const selectProcessing = createSelector(
  [selectOfflineTypeRefs],
  state => state.processing,
);
export const selectDetails = createSelector(
  [selectOfflineTypeRefs],
  state => state.detailsState,
);
export const selectDetailsProcessing = createSelector(
  [selectDetails],
  state => state.processing,
);
export const selectDetailsError = createSelector(
  [selectDetails],
  state => state.error,
);
export const selectDetailsHasError = createSelector(
  [selectDetails],
  state => state.hasErrors,
);
export const selectDetailsCompleted = createSelector(
  [selectDetails],
  state => state.completed,
);
export const selectDetailsData = createSelector(
  [selectDetails],
  state => state.data,
);
export const selectIsEdit = createSelector(
  [selectSlice],
  state => state.isEdit,
);
export const selectOfflineRefsFilters = createSelector(
  [selectSlice],
  state => state.filters,
);
