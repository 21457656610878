import { IconProp } from 'app/AllIcons';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Entity } from 'types/common';
import * as React from 'react';

export interface HoverLinkedItemProps {
  linked: Entity<number>;
  children: React.ReactChild;
  onHoverClick?: (linked: Entity<number>) => void;
  useHoverButton?: boolean;
  hoverIcon?: IconProp;
}

export function HoverLinkedItem(props: HoverLinkedItemProps) {
  const { linked, children, useHoverButton, onHoverClick, hoverIcon } = props;

  const [hover, setHover] = React.useState(false);
  return (
    <span
      style={{ display: 'flex' }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
      {useHoverButton && hover && (
        <IconButton onClick={() => !!onHoverClick && onHoverClick(linked)}>
          <Icon icon={hoverIcon ?? 'times'} />
        </IconButton>
      )}
    </span>
  );
}
