import { FormPermissionLevelPicker } from 'app/components/Forms/FormPermissionLevelPicker';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormTimeSlotTypePicker } from 'app/components/Forms/FormTimeSlotTypePicker';
import { FormUserGroupsPicker } from 'app/components/Forms/FormUserGroupsPicker';
import { EntityStringSchema } from 'app/components/Forms/Schemas';
import { Formik } from 'formik';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { ICoresDirectoryRow } from 'types/ICoresDirectoryRow';
import * as yup from 'yup';
import { GroupPermissionsModel } from '.';

export interface UpdateGroupPermissionsFormProps {
  groups: ICoresDirectoryRow[];
  handleSubmitForm: (submit: any) => void;
  onSubmit: (model: GroupPermissionsModel) => void;
}

export function UpdateGroupPermissionsForm(
  props: UpdateGroupPermissionsFormProps,
) {
  const { t } = useTranslation();
  const schema: yup.SchemaOf<GroupPermissionsModel> = yup.object({
    AdGroups: yup
      .array()
      .of(EntityStringSchema)
      .label(t(translations.UserGroup) as string)
      .min(1)
      .required(t(translations.err_UserGroupRequired) as string),
    PermissionLevel: yup
      .mixed()
      .label(t(translations.PermissionLevel) as string)
      .required(t(translations.err_SelectPermissionLevelException) as string),
    TimeSlotType: yup
      .mixed()
      .label(t(translations.TimeSlotType) as string)
      .required(t(translations.err_TimeSlotTypeRequired) as string),
  });
  return (
    <Formik
      onSubmit={props.onSubmit}
      initialValues={{
        AdGroups: [],
        PermissionLevel: undefined,
        TimeSlotType: undefined,
      }}
      validationSchema={schema}
    >
      {formik => {
        props.handleSubmitForm(formik.submitForm);
        return (
          <StyledForm>
            <FormLeftSection>
              <FormFieldsSection
                titleSection={t(
                  translations.SetPermissionsOnSelectedServiceGroups,
                )}
              >
                <FormRow fullRow>
                  <FormUserGroupsPicker
                    name="AdGroups"
                    id="UserGroupId"
                    label={t(translations.UserGroup)}
                    fullWidth
                  />
                </FormRow>
                <FormRow fullRow>
                  <FormPermissionLevelPicker
                    name="PermissionLevel"
                    id="permissionlvlId"
                    label={t(translations.PermissionLevel)}
                    fullWidth
                  />
                </FormRow>
                <FormRow fullRow>
                  <FormTimeSlotTypePicker
                    name="TimeSlotType"
                    id="tstId"
                    fullWidth
                    label={t(translations.TimeSlotType)}
                  />
                </FormRow>
              </FormFieldsSection>
            </FormLeftSection>
          </StyledForm>
        );
      }}
    </Formik>
  );
}
