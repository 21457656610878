import { BasicTable, IBasicTableState } from 'app/components/BasicTable';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { filterHandler } from 'app/components/BasicTable/UnconfigurableTable/FilterHandler';
import { ScreensId } from 'enums/ConfigurableTypes';
import * as React from 'react';
import { Column } from 'react-table';
import { AuthenticatedUser, IAuthenticatedUser } from 'types/AuthenticatedUser';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAppSettings, selectglobalSettings } from 'app/slice/selectors';
import { useGlobalServiceGroupsFilter } from 'app/hooks/useGlobalServiceGroupsFilter';
import { DetectIsMobile } from 'utils/mobileDetect';
import { Condition } from 'api/odata/ODataFilter';
import { CustomEmptyTableProps } from 'app/components/BasicTable/components/CustomEmptyTable';
import { translations } from 'locales/translations';
import { IBarcodeScanLogDto } from './IBarcodeScanLogDto';
import { getColumns } from './GetColumns';
import { GetFilters } from './GetFilters';

export interface InventoryLogTableProps {
  useSidePanel?: boolean;
  authenticatedUser?: AuthenticatedUser;
  closeSidePanel: () => void;
  setFilters?: (
    appliedFilters: IFilterSettings<IBarcodeScanLogDto>[] | undefined,
  ) => void;
  //   primaryAction?: (row: Row<IMyAssetsRow>) => void;
  //   primaryMultiAction: (rows: IMyAssetsRow[]) => void;
  screenName?: string;
  openAsset: (id: number) => void;
  customPredicates?: (string | Condition<IBarcodeScanLogDto>)[];
  isShortView?: boolean;
  emptyTable?: CustomEmptyTableProps;
  searchTerm?: string;
  defaultFilter?: (user: IAuthenticatedUser) => URLSearchParams;
}

export function InventoryLogTable(props: InventoryLogTableProps) {
  const {
    useSidePanel,
    authenticatedUser,
    setFilters,
    screenName,
    isShortView = false,
    emptyTable,
    searchTerm,
    defaultFilter,
  } = props;
  const { t } = useTranslation();
  const appSettings = useSelector(selectAppSettings);
  const settings = useSelector(selectglobalSettings);
  const isMobile = DetectIsMobile();
  //   const admins =
  //     authenticatedUser !== undefined &&
  //     (authenticatedUser.Roles.includes(Roles.Administrators) ||
  //       authenticatedUser.Roles.includes(Roles.GroupAdministrators) ||
  //       authenticatedUser.Roles.includes(Roles.LabTech) ||
  //       authenticatedUser.Roles.includes(Roles.InventoryAdmin));

  const searchColumns = React.useMemo(() => {
    let result: string[] = [
      'Username',
      'UserDisplayName',
      'Barcode',
      'ServiceGroupName',
      'AssetName',
      'RoomName',
      'FloorName',
      'BuildingName',
      'LocationName',
      'SubLocationName',
      'StatusName',
      'Remarks',
    ];
    return result;
  }, []);

  const additionalColumns = React.useMemo(() => {
    let result: string[] = [
      'Id',
      'ServiceId',
      'AssetId',
      'ServiceGroupId',
      'RoomId',
      'ServiceTypeId',
      'BuildingId',
      'LocationId',
      'SubLocationId',
      'Username',
    ];
    return result;
  }, []);
  //   const [multiAssetSelectionMode, setMultiAssetSelectionMode] = React.useState<
  //     boolean
  //   >(false);
  //   const [selectedRowIds, setSelectedRowIds] = React.useState<
  //     Record<IdType<number>, boolean>
  //   >({});
  //   const [, setCurrentMode, currentModeRef] = useState<TableMode>('card');
  //   const [loacalAssets, setLoacalAssets, localAssetsRef] = useState<
  //     Entity<number>[]
  //   >([]);
  //   React.useEffect(() => {
  //     let obj = {};
  //     if (!!assets && assets.length > 0) {
  //       for (const key of assets) {
  //         obj[key.Id] = true;
  //       }
  //       setMultiAssetSelectionMode(assets.length > 1);
  //       setLoacalAssets(assets);
  //     }
  //     setSelectedRowIds(obj);
  //   }, [assets, setLoacalAssets]);

  const columns: Column<IBarcodeScanLogDto>[] = React.useMemo(
    () => getColumns(t, authenticatedUser, appSettings, settings),
    [t, authenticatedUser, appSettings, settings],
  );
  const initialState: IBasicTableState<IBarcodeScanLogDto> = React.useMemo(
    () => ({
      sortBy: [{ id: 'Date', desc: true }],
      //selectedRowIds: selectedRowIds,
      globalFilter: searchTerm,
    }),
    [searchTerm],
  );

  //   const customFilter = React.useMemo(() => {
  //     let filter: (string | Condition<IBarcodeScanLogDto>)[] = customPredicates ?? [];
  //     if (actionType === 'StartUsage') {
  //       filter.push(
  //         new Condition<IBarcodeScanLogDto>('AutoMode', ODataOperators.Equals, 1),
  //       );
  //     }
  //     if (actionType === 'TrainingSession') {
  //       filter.push(
  //         new Condition<IBarcodeScanLogDto>(
  //           'TrainingSessionsEnabled',
  //           ODataOperators.Equals,
  //           true,
  //         ),
  //       );
  //     }
  //     return filter.length > 0 ? filter : undefined;
  //   }, [actionType, customPredicates]);

  const url = '/api/odata/v4/BarcodeScanLog';
  const { serviceGroups } = useGlobalServiceGroupsFilter(false);
  const TTable = React.useMemo(
    () =>
      filterHandler<IBarcodeScanLogDto, {}>(BasicTable, GetFilters, {
        isSidePanel: useSidePanel,
        setFilters: setFilters,
        reservedQueryStringParameterNames: undefined,
        getDefaultFilter: defaultFilter,
        getRequiredFilter: undefined,
        getSelectedViewFilter: undefined,
        enableSavedLists: false,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useSidePanel],
  );
  return (
    <React.Fragment>
      <TTable
        screenId={ScreensId.BarcodeScanLog}
        api={url}
        columns={columns}
        screenName={screenName ?? t(translations.menu_AssetInventoryLog)}
        screenNameVariant="section"
        additionalColumns={additionalColumns}
        searchColumns={searchColumns}
        serviceGroups={serviceGroups}
        inlineRowActions={undefined}
        // selectedRowsActions={selectedRowActions}
        initialState={initialState}
        isRowSelectable={undefined}
        hideMenuOnMobile={useSidePanel}
        useExport={true}
        cardSize="small"
        viewCardsCheck={false}
        allowCards={false}
        compactTable={isShortView}
        useConfigurable={true}
        useCardsByDefault={false}
        //pageActions={pageActions}
        useRowSelect={false}
        withToolBar={false}
        customEmptyTableSettings={emptyTable}
        // tabSectionSettings={{
        //   useTabsSection: isAuthenticated,
        //   getMultiMode: value => setMultiAssetSelectionMode(value),
        //   selectedTabValue: undefined,
        //   onAllRowsSelected: toggleAllRows,
        //   disableMultiMode: multiModeDisabled,
        //   defaultMultiMode: !multiModeDisabled,
        //   // defaultMultiMode:
        //   //   multiAssetSelectionMode === true ||
        //   //   (!!localAssetsRef.current && localAssetsRef.current.length > 0),
        // }}
        //predicates={customFilter}
        preventInnerClicks={isMobile}
      />
    </React.Fragment>
  );
}
