import { INotificationSettingsDto } from 'app/pages/NotificationsPage/INotificationSettingsDto';
import { IReservationWaitingListDto } from 'app/pages/ReservationWaitingListPage/IReservationWaitingListDto';
import { IResponseType, IResponseTypeWithId } from 'types/ResponseType';
import { httpClient } from './HttpClient';

var restUrl = '/api/odata/v4/ReservationWaitingList';

export const UserNotificationsApi = {
  insertSignUp: (model: IReservationWaitingListDto) => {
    return httpClient.post<IResponseTypeWithId>(restUrl, model);
  },
  updateSignUp: (model: IReservationWaitingListDto, rowId: number) => {
    return httpClient.put<any, IResponseTypeWithId>(
      restUrl + '(' + rowId + ')',
      model,
    );
  },
  getSignUp: (Id: number) => {
    return httpClient.get(restUrl + '(' + Id + ')');
  },
  signOut: (Id: number) => {
    return httpClient.delete(restUrl + '(' + Id + ')');
  },
  deleteSelectedRows: (payload: number[]) => {
    const url = `/api/odata/v4/ReservationWaitingList/DeleteSelected`;
    const data = { keys: payload };
    return httpClient.post(url, data);
  },
  getSettings: (userName: string) => {
    const data = { key: userName };
    const url = `/api/odata/v4/NotificationSettings`;
    return httpClient.get(url, data).then(response => response.value[0]);
  },
  updateSettings: (model: INotificationSettingsDto, userName: string) => {
    const url = `/api/odata/v4/NotificationSettings`;

    return httpClient.post<IResponseType>(url, model);
  },
};
