import { Dialog } from '@material-ui/core';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export interface PopupPrintToPDFDialogProps {
  open: boolean;
  content: React.ReactNode;
  fullWidth: boolean;
}
export function PopupPrintToPDFDialog(props: PopupPrintToPDFDialogProps) {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={props.open}
      aria-labelledby={'PrintToPDF-dialog-popup'}
      fullScreen={false}
      fullWidth={props.fullWidth}
    >
      <DialogContentRoot className="dialog-content-root">
        {props.content}
      </DialogContentRoot>
    </Dialog>
  );
}
const DialogContentRoot = styled.div`
  &.dialog-content-root {
    width: 100%;
    height: 100%;
    padding: 0px;
    background-color: ${bookitColors.grayscale.grayBackgroundLight};
  }
`;
