import { PayloadAction } from '@reduxjs/toolkit';
import { IRegionDto } from 'app/pages/Regions/IRegionDto';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { regionsSaga } from './saga';
import { RegionsState } from './types';

export const initialState: RegionsState = {
  detailsState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
    error: undefined,
  },
  processing: false,
  isEdit: undefined,
};

const slice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    initRegion(
      state,
      action: PayloadAction<{
        id: number;
        initial?: IRegionDto;
      }>,
    ) {
      state.processing = true;
      state.detailsState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initRegion_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
        data: IRegionDto;
        isEdit: boolean;
      }>,
    ) {
      state.processing = false;
      state.detailsState.data = action.payload.data;
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.isEdit = action.payload.isEdit;
    },
    initRegion_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    resetRegionState(state, action: PayloadAction) {
      state.detailsState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
      state.processing = false;
    },
    createRegion(
      state,
      action: PayloadAction<{
        model: IRegionDto;
      }>,
    ) {
      state.detailsState.processing = true;
      state.detailsState.completed = undefined;
      state.detailsState.hasErrors = undefined;
    },
    createRegion_Success(state, action: PayloadAction<{ hasErrors: boolean }>) {
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.detailsState.completed = true;
    },
    createRegion_Error(state, action: PayloadAction<any>) {
      state.detailsState.processing = false;
      state.detailsState.completed = true;
      state.detailsState.hasErrors = true;
    },
    updateRegion(
      state,
      action: PayloadAction<{
        current: IRegionDto;
        original: IRegionDto;
      }>,
    ) {
      state.detailsState.processing = true;
      state.detailsState.completed = undefined;
      state.detailsState.hasErrors = undefined;
    },
    updateRegion_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.detailsState.processing = false;
      state.detailsState.hasErrors = action.payload.hasErrors;
      state.detailsState.completed = true;
    },
    updateRegion_Error(state, action: PayloadAction<any>) {
      state.detailsState.processing = false;
      state.detailsState.completed = true;
      state.detailsState.hasErrors = true;
    },
  },
});

export const { actions: regionsActions } = slice;

export const useRegionsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: regionsSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useRegionsSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
