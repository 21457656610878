import { LoanDeskMessage } from './components/LoanDeskMessage';
import { ReservationStartedMessage } from './components/ReservationStartedMessage';
import { UsageReservationMessage } from './components/UsageReservationMessage';
import {
  CalendarMessageProps,
  LoanDesk,
  Message,
  MessageTypeEnum,
  StartedReservation,
  UsageReservation,
} from './slice/type';
import { SnackBarMessage } from './SnackBarMessage';
import parse from 'html-react-parser';
import { SnackbarContent } from 'notistack';
import React from 'react';

export interface RenderSnackMessgeProps {
  message: Message;
  onClose: () => void;
  messageActions?: (() => React.ReactNode)[];
}
export const RenderSnackMessage = React.forwardRef(function RenderMessage(
  props: RenderSnackMessgeProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { message, onClose, messageActions } = props;
  const messageContent = () => {
    switch (message.type) {
      case MessageTypeEnum.Announcment:
      case MessageTypeEnum.removableAnnouncment:
        return (
          <SnackBarMessage
            message={message.props as string}
            onClose={onClose}
            messageActions={messageActions}
          />
        );
      case MessageTypeEnum.reservationStarted:
        const resProps = message.props as StartedReservation;
        return (
          <SnackBarMessage
            messageContent={
              <ReservationStartedMessage close={onClose} {...resProps} />
            }
            onClose={onClose}
            messageActions={messageActions}
          />
        );

      case MessageTypeEnum.usageReservation:
        const usageProps = message.props as UsageReservation;
        return (
          <SnackBarMessage
            messageContent={
              <UsageReservationMessage close={onClose} {...usageProps} />
            }
            onClose={onClose}
            messageActions={messageActions}
          />
        );
      case MessageTypeEnum.loadDesk:
        return (
          <SnackBarMessage
            onClose={onClose}
            messageActions={messageActions}
            messageContent={
              <LoanDeskMessage
                close={onClose}
                {...(message.props as LoanDesk)}
              />
            }
          />
        );
      case MessageTypeEnum.calendar:
        const calProps = message.props as CalendarMessageProps;
        if (calProps.Title !== null || calProps.Body !== null) {
          return (
            <SnackBarMessage
              messageTitle={calProps.Title ?? undefined}
              messageContent={calProps.Body && parse(calProps.Body)}
              onClose={onClose}
              messageActions={messageActions}
            />
          );
        }

        return null;
      default:
        return null;
    }
  };
  return (
    <SnackbarContent ref={ref} className={'annoncement-content-item'}>
      {messageContent()}
    </SnackbarContent>
  );
});
