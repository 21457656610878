/**
 *
 * ArticleAssociationForm
 *
 */
import * as React from 'react';
import { translations } from 'locales/translations';
import * as Yup from 'yup';
import {
  selectArticleDetails,
  selectArticleDetailsForm,
  selectArticles,
  selectEditArticleAssociationBusy,
} from '../slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { Formik } from 'formik';
import {
  FormFieldsSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormAssetsPicker } from 'app/components/Forms/FormAssetsPicker';
import { useArticlesSlice } from '../slice';
import { IArticleDto } from 'api/odata/generated/entities/IArticleDto';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { Button } from 'app/components/BasicButtons/Button';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';
import { FormUserPicker } from 'app/components/Forms/FormUserPicker';
import { FormAssetPicker } from 'app/components/Forms/FormAssetPicker';
import { FormListener } from 'app/components/Forms/FormRender/FormRenderer';
import { GetCalibrationRecordFilter, GetInstrumentFilter } from '../utils';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { useAllowedToChangeStatus } from '../Hooks/useAllowedToChangeStatus';
import { ArticleStatus } from 'enums/Article';
import { ArticleComments } from '../ArticleComments';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { ArticleFormPicker } from '../ArticleFormPickers';
import { SidePanelContentProps } from 'app/Layout/FrontendLayout/slice/type';
import useSidePanelState, {
  SidePanelCloseState,
} from 'app/hooks/useSidePanelOpen';
import { FormTextField } from 'app/components/Forms/FormTextField';

export interface ArticleAssociationFormProps
  extends SidePanelContentProps,
    CoverProps {}
export interface IArticleAssociationForm {
  RelatedUser: Entity<string> | null;
  ServiceGroupAsset: IAssetDto | null;
  //ServiceGroupAssets: Array<IAssetDto>;
  InstrumentAssets: Array<IAssetDto>;
  CalibrationRecord: Entity<number> | null;
  PageNumber?: number | null;
}

const assetSchema = Yup.mixed();
const assetsSchema = Yup.array().of(Yup.mixed() as Yup.SchemaOf<IAssetDto>);

export function ArticleAssociationForm(props: ArticleAssociationFormProps) {
  const { useSidePanel = true, closeCover, isCover } = props;
  const { t } = useTranslation();
  const state = useSelector(selectArticles);
  const articleDetails = useSelector(selectArticleDetails);
  const articleDetailsForm = useSelector(selectArticleDetailsForm);
  const user = useSelector(selectAuthenticatedUser);
  const editProcessing = useSelector(selectEditArticleAssociationBusy);
  const allowedToChangeStatus = useAllowedToChangeStatus();
  const { actions: layoutActions } = useLayoutSlice();
  const [canEditStatus, setCanEditStatus] = React.useState<boolean>(false);
  const submitCommentRef = React.useRef<any>(null);
  const dispatch = useDispatch();
  const { actions } = useArticlesSlice();
  const schema: Yup.SchemaOf<IArticleAssociationForm> = Yup.object().shape(
    {
      CalibrationRecord: Yup.mixed().nullable().notRequired(),
      RelatedUser: Yup.mixed().nullable().notRequired(),
      //ServiceGroupAssets: assetsSchema.required(),
      /*ServiceGroupAsset: assetSchema.nullable().required(),
    InstrumentAssets: assetsSchema.required(),*/
      ServiceGroupAsset: assetSchema.when(['InstrumentAssets'], {
        is: v => (v === undefined ? true : v.length === 0),
        then: assetSchema.required().nullable(),
        otherwise: assetSchema.nullable().notRequired(),
      }),
      InstrumentAssets: assetsSchema.when(['ServiceGroupAsset'], {
        is: v => v === null,
        then: assetsSchema.required().min(1),
        otherwise: assetsSchema.notRequired(),
      }),
      PageNumber: Yup.number().nullable().default(null),
    },
    [['ServiceGroupAsset', 'InstrumentAssets']],
  );
  const GetServiceGroupAsset = React.useCallback(asset => {
    /*if (asset !== undefined && asset !== null) {
        return asset.ServiceGroupId !== null &&
          asset.ServiceGroupId !== undefined &&
          (authenticatedUser?.HasAdminGroupOnlyPermissions(
            asset.ServiceGroupId,
          ) ||
            authenticatedUser?.HasLabTechGroupPermissions(asset.ServiceGroupId))
          ? asset
          : null;
      } else {
        return null;
      }*/
    return asset;
  }, []);
  const initialValues: IArticleAssociationForm = React.useMemo(
    () => ({
      CalibrationRecord: {
        Id: articleDetails?.CalibrationRecord,
        Name: !!articleDetails?.CalibrationRecord
          ? '#' +
            articleDetails?.CalibrationRecord?.toString() +
            ': ' +
            articleDetails?.CalibrationRecordName
          : '',
      } as Entity<number>,
      RelatedUser: {
        Id: articleDetails?.RelatedUser,
        Name: articleDetails?.RelatedUserDisplayName,
      } as Entity<string>,
      PageNumber: articleDetails?.PageNumber,
      InstrumentAssets: articleDetailsForm?.InstrumentAssets,
      /*articleDetailsForm?.InstrumentAssets.filter(
        asset =>
          asset.ServiceId !== null &&
          asset.ServiceId !== undefined &&
          (authenticatedUser?.HasAdminServicePermissions(
            asset.ServiceTypeId ?? 1,
            asset.ServiceId,
          ) ||
            authenticatedUser?.HasLabTechServicePermissions(
              asset.ServiceTypeId ?? 1,
              asset.ServiceId,
            ) ||
            authenticatedUser?.HasAdminGroupPermissions(
              asset.ServiceGroupId ?? undefined,
            ) ||
            authenticatedUser?.HasLabTechGroupPermissions(
              asset.ServiceGroupId ?? undefined,
            )),
      ),*/
      /*ServiceGroupAssets: articleDetailsForm?.ServiceGroupAssets.filter(
        asset =>
          asset.ServiceGroupId !== null &&
          asset.ServiceGroupId !== undefined &&
          (authenticatedUser?.HasAdminGroupPermissions(asset.ServiceGroupId) ||
            authenticatedUser?.HasLabTechGroupPermissions(
              asset.ServiceGroupId,
            )),
      ),*/
      ServiceGroupAsset:
        articleDetailsForm !== undefined
          ? GetServiceGroupAsset(articleDetailsForm?.ServiceGroupAssets[0])
          : null,
    }),
    [
      GetServiceGroupAsset,
      articleDetails?.CalibrationRecord,
      articleDetails?.CalibrationRecordName,
      articleDetails?.PageNumber,
      articleDetails?.RelatedUser,
      articleDetails?.RelatedUserDisplayName,
      articleDetailsForm,
    ],
  );
  const [oldServiceGroups, setOldServiceGroups] = React.useState<IAssetDto[]>(
    //initialValues.ServiceGroupAssets,
    initialValues.ServiceGroupAsset === null
      ? []
      : [initialValues.ServiceGroupAsset],
  );
  const [serviceGroups, setServiceGroups] = React.useState<IAssetDto[]>(
    //initialValues.ServiceGroupAssets,
    initialValues.ServiceGroupAsset === null
      ? []
      : [initialValues.ServiceGroupAsset],
  );
  const [instruments, setInstruments] = React.useState<IAssetDto[]>(
    initialValues.InstrumentAssets,
  );

  const { cover, closePanel, coverClosed, onCloseCover } = useSidePanelState();

  const handleCloseClick = React.useCallback(() => {
    closePanel({
      isCover: isCover,
      useSidePanel: useSidePanel,
      showConfirm: undefined,
      onClose: () => {
        dispatch(layoutActions.setNotSavedChanges(false));
        dispatch(actions.resetArticleAssociationState());
      },
    } as SidePanelCloseState);
  }, [actions, closePanel, dispatch, isCover, layoutActions, useSidePanel]);

  React.useEffect(() => {
    if (editProcessing === false) {
      handleCloseClick();
    }
    return () => undefined;
  }, [editProcessing, handleCloseClick]);

  const instrumentFilter = React.useCallback(() => {
    return GetInstrumentFilter(serviceGroups);
  }, [serviceGroups]);
  const CalibrationRecordFilter = React.useCallback(() => {
    return GetCalibrationRecordFilter(instruments);
  }, [instruments]);
  const handleFormChange = React.useCallback(
    (
      values: IArticleAssociationForm,
      isValid,
      dirty,
      setValue,
      setTouched,
      validateField,
      setError,
      validate,
    ) => {
      if (
        serviceGroups.length !== oldServiceGroups.length ||
        serviceGroups[0] !== oldServiceGroups[0]
      ) {
        setOldServiceGroups(serviceGroups);
        setValue('InstrumentAssets', []);
        return;
      }
      if (
        values.ServiceGroupAsset === null &&
        values.InstrumentAssets.length > 0
      ) {
        setOldServiceGroups(serviceGroups);
        setValue('ServiceGroupAsset', {
          Id: values.InstrumentAssets[0].ServiceGroupAssetId,
          Name: values.InstrumentAssets[0].ServiceGroupName,
          ServiceGroupId: values.InstrumentAssets[0].ServiceGroupId,
          ServiceGroupName: values.InstrumentAssets[0].ServiceGroupName,
        });
      }
      setCanEditStatus(allowedToChangeStatus(serviceGroups[0]));
    },
    [allowedToChangeStatus, oldServiceGroups, serviceGroups],
  );

  const handleSubmit = React.useCallback(
    (values: IArticleAssociationForm) => {
      if (articleDetails === undefined) {
        return;
      }
      const payload: IArticleDto = {
        ...articleDetails,
        ...{
          RelatedUser:
            values.RelatedUser !== null && !!values.RelatedUser
              ? values.RelatedUser?.Id
              : null,
        },
        ...{
          CalibrationRecord:
            values.CalibrationRecord !== null && !!values.CalibrationRecord
              ? values.CalibrationRecord?.Id
              : null,
        },
        ...{
          //Assets: values.ServiceGroupAssets.concat(values.InstrumentAssets),
          Assets:
            values.ServiceGroupAsset !== null && !!values.ServiceGroupAsset
              ? values.InstrumentAssets.concat([values.ServiceGroupAsset])
              : values.InstrumentAssets,
        },
        PageNumber:
          articleDetails?.ExternalIdSource === 'PubMed'
            ? values.PageNumber !== undefined
              ? values.PageNumber
              : null
            : articleDetails?.PageNumber,
        Status: allowedToChangeStatus(serviceGroups[0])
          ? ArticleStatus.Approved
          : ArticleStatus.AwaitingApproval,
      };
      dispatch(actions.saveArticleAssociation(payload));
    },
    [actions, allowedToChangeStatus, articleDetails, dispatch, serviceGroups],
  );
  const processing = state.editArticleAssociationBusy;

  return (
    <PageWrapper
      useSidePanel={true}
      closable={true}
      disableExpandToggle={true}
      pageName={t(translations.AssociateThisPublication)}
      titlePage={articleDetails?.Title ?? undefined}
      titleTooltip={articleDetails?.Title ?? undefined}
      closeSidePanel={handleCloseClick}
      topProcessing={processing}
      cover={cover}
      closeCover={!isCover ? onCloseCover : closeCover}
      isCover={isCover}
      coverClosed={coverClosed}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {formik => (
          <StyledForm onSubmit={formik.handleSubmit}>
            <FormFieldsSection>
              <FormListener
                onFormChange={handleFormChange}
                fields={['ServiceGroupAsset', 'InstrumentAssets']}
              />
              <FormRow>
                <FormUserPicker
                  name="RelatedUser"
                  label={t(translations.RelatedUser)}
                  placeholder={t(translations.PleaseSelectAUser)}
                  disabled={formik.isSubmitting || !canEditStatus}
                  fullWidth
                />
              </FormRow>
              <FormRow>
                <ArticleFormPicker
                  name="CalibrationRecord"
                  label={
                    instruments.length > 0
                      ? t(translations.CalibrationRecord)
                      : t(translations.ArticlePleaseSelectEquipmentFirst)
                  }
                  placeholder={
                    instruments.length > 0
                      ? t(translations.PleaseSelectWorkOrder)
                      : t(translations.ArticlePleaseSelectEquipmentFirst)
                  }
                  disabled={
                    formik.isSubmitting ||
                    !canEditStatus ||
                    instruments.length === 0
                  }
                  filter={CalibrationRecordFilter()}
                  info={t(translations.CalibrationRecord_info)}
                  fullWidth
                />
              </FormRow>
              {articleDetails?.ExternalIdSource === 'PubMed' && (
                <FormRow>
                  <FormTextField
                    name="PageNumber"
                    id="PageNumberId"
                    label={t(translations.ArticlePageNumber)}
                    type="number"
                    disabled={processing}
                    fullWidth
                  />
                </FormRow>
              )}
              <FormRow>
                <FormAssetPicker
                  admin={false}
                  showOnlyActiveAssets={true}
                  name="ServiceGroupAsset"
                  restrictByAvType="serviceGroup"
                  label={t(translations.ServiceGroup)}
                  placeholder={t(translations.PleaseSelectAsset)}
                  //setCover
                  //onChipClick={onAssetClicked}
                  //onChange={() => setAssetChanged(true)}
                  onChange={(value: any) => {
                    setOldServiceGroups(serviceGroups);
                    setServiceGroups([value]);
                  }}
                  fullWidth
                />
              </FormRow>
              <FormRow>
                <FormAssetsPicker
                  admin={false}
                  predicates={instrumentFilter()}
                  showOnlyActiveAssets={true}
                  name="InstrumentAssets"
                  restrictByAvType="bookableAndnotbookable"
                  label={t(translations.Instruments)}
                  placeholder={t(translations.PleaseSelectAsset)}
                  //setCover
                  //onChipClick={onAssetClicked}
                  //onChange={() => setAssetChanged(true)}
                  fullWidth
                  withoutServiceGroups={true}
                  onChange={(value: any) => {
                    setInstruments(value);
                  }}
                />
              </FormRow>
              {articleDetails && articleDetails.Id > 0 && (
                <FormFieldsSection>
                  <ArticleComments
                    commentsFormRef={submitCommentRef}
                    isEdit={true}
                    user={user}
                    article={articleDetails}
                    disabled={false}
                  />
                </FormFieldsSection>
              )}
              <FormRow>
                <Button processing={processing} type="submit">
                  {t(translations.Submit)}
                </Button>
              </FormRow>
            </FormFieldsSection>
          </StyledForm>
        )}
      </Formik>
    </PageWrapper>
  );
}
