import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSettingsSlice } from 'app/slice';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { useLocation } from 'react-router-dom';
import { AuthenticatedUser, IAuthenticatedUser } from 'types/AuthenticatedUser';
import {
  selectAppSettings,
  selectAuthenticatedUser,
  selectglobalSettings,
  selectSkipLocationChanged,
  selectUserProfileSettings,
} from 'app/slice/selectors';
import {
  BasicTableProps,
  IBasicTableState,
  IRow,
} from 'app/components/BasicTable';
import { useTranslation } from 'react-i18next';
import { routerActions } from 'connected-react-router';
import { useMemo } from 'react';
import { isEmptyOrWhitespace } from 'utils/typeUtils';
import {
  defaultSerializer,
  findIsfilterInversed,
  setisInversedForUrl,
  URLSearchParamsCI,
} from 'app/components/BasicTable/types/FilterParam';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { GetPageFilters } from './BasicFilter/GetPageFilters';
import { AppSettings } from 'types/AppSettings';
import { parseInt, trimEnd, trimStart } from 'lodash';
import { SavedViewSelected } from 'app/pages/SavedViewsPage/SavedViewPage/slice/types';
import { FilterValueType } from './BasicFilter/FilterValueType';
import { getDependencyFilters } from './BasicFilter';
import { ScreensId } from 'enums/ConfigurableTypes';
import { TableSavedListSettings } from './BasicTableProps';
import { useProfileSetting } from './useProfileSetting';
import { parseProfileUrl } from './useProfileSetting/parseProfileUrl';
import { getNextFilter } from './getNextFilter';
import { getFilterValue } from './getFilterValue';
import { SAVEDVIEW_SEARCH_KEY } from './BasicFilter/SavedListsDropDown';
import { useSavedViewSlice } from 'app/pages/SavedViewsPage/SavedViewPage/slice';
import { tryParseInt } from 'utils/string-utils';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { initFilterData } from './initFilterData';

export function toURLSearchParams<TRow extends IRow = {}>(
  items: Array<
    Pick<
      IFilterSettings<TRow>,
      'queryStringSerializer' | 'value' | 'isInversed'
    >
  >,
  withEmptyValues?: boolean,
) {
  const result = new URLSearchParamsCI();
  const serializableItems = items.filter(
    element => element.queryStringSerializer !== undefined,
  );

  serializableItems.forEach(({ value, queryStringSerializer, isInversed }) => {
    if (value !== undefined || withEmptyValues) {
      const serializer =
        typeof queryStringSerializer === 'string'
          ? defaultSerializer(queryStringSerializer)
          : queryStringSerializer;
      const stringValue = serializer?.(value);

      if (stringValue instanceof URLSearchParams) {
        stringValue.forEach((v, n) =>
          result.set(n, setisInversedForUrl<string>(v, !!isInversed)),
        );
      }
    }
  });

  return result;
}
/**
 *
 * @param savedFilterKey a key to save/load "saved filters" by
 * @param GetFiltersDefinition filters settings provider
 * @param getDefaultFilter default filters settlings
 * @param reservedQueryStringParameterNames query string parameter names to ignore
 * @param getRequiredFilter required filter settings provider
 * @param getSelectedViewFilter ??? something to do with ListId,AssetId
 * @param enableSavedLists adds a dropdown with "saved list" of instruments
 * @param useSyncSearch controls syncing of the filters state into the url's search parameter
 * @returns
 */
export function useFilters<TRow extends IRow>(
  savedFilterKey: string,
  GetFiltersDefinition: GetPageFilters<TRow>,
  getDefaultFilter?: (user: IAuthenticatedUser) => URLSearchParams,
  reservedQueryStringParameterNames?: string[],
  getRequiredFilter?: (
    search: string,
    appSettings?: AppSettings | null,
    user?: AuthenticatedUser,
  ) => string | undefined,
  getSelectedViewFilter?: (
    search: string,
    view: SavedViewSelected,
  ) => string | undefined,
  enableSavedLists?: boolean,
  savedListSettings?: TableSavedListSettings<TRow>,
  useCardsByDefault?: boolean,
  /**
   * Indicates wether filters should return partial results or only return fully loaded filters.
   * By default this will first return Identifiable e.g. {Id:1} and after that the full entity e.g. {Id:1,Name:'Name'} when it'll be ready
   */
  getPartialResults?: boolean,
  useSyncSearch: boolean = true,
) {
  const dispatch = useDispatch();
  const { actions: savedViewActions } = useSavedViewSlice();
  const { actions: appActions } = useAppSettingsSlice();
  const { t } = useTranslation();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const skipLocationChanged = useSelector(selectSkipLocationChanged);
  const location = useLocation();
  const showFiltersFn = React.useCallback(visibleFiltersFilter, []);
  const rowsKey = savedFilterKey + '_rows';
  const useCardsKey = savedFilterKey + '_useCardsMode';
  const { savedValue: savedUrl, updateValue: updateSavedFilterValue } =
    useProfileSetting({
      profileSettingKey: (savedFilterKey ?? location.pathname).toLowerCase(),
    });
  const { search: savedFilterValue } = parseProfileUrl(savedUrl);
  const [filterChange, setFilterChange] = React.useState<boolean>(false);
  React.useEffect(
    () => {
      if (authenticatedUser === undefined) {
        return;
      }
      if (filterChange) {
        setFilterChange(false);
        return;
      }
      //this variable used to prevent filters reinitialization when location changed,
      //for a case when location changed by the query string that not related to filters
      if (skipLocationChanged) {
        dispatch(appActions.setSkipLocationChanged(undefined));
        return;
      }
      const defaultSearch = getPresetFilter(
        savedFilterValue,
        authenticatedUser,
      );
      const isDefault =
        defaultSearch === undefined || (savedFilterValue ?? '') === '';

      let search = isEmptyOrWhitespace(location.search)
        ? defaultSearch ?? ''
        : location.search;
      const requiredSearch =
        getRequiredFilter !== null && getRequiredFilter !== undefined
          ? getRequiredFilter(search, appSettings, authenticatedUser)
          : undefined;
      search = requiredSearch === undefined ? search : requiredSearch ?? '';

      const savedViewId = tryParseInt(
        new URLSearchParamsCI(search).get(SAVEDVIEW_SEARCH_KEY),
      );
      dispatch(savedViewActions.selectSavedView(savedViewId));

      // get the filters
      const localizedFilters = getLocalizedFilters(search);
      let visibleFilters = localizedFilters
        ?.map(f =>
          isDefault
            ? mapDefaultFilters({
                ...f,
                isInversed: findIsfilterInversed(
                  search,
                  f.urlKey ??
                    (typeof f.queryStringSerializer === 'string'
                      ? f.queryStringSerializer
                      : undefined),
                ),
              })
            : {
                ...f,
                isInversed: findIsfilterInversed(
                  search,
                  f.urlKey ??
                    (typeof f.queryStringSerializer === 'string'
                      ? f.queryStringSerializer
                      : undefined),
                ),
              },
        )
        .filter(showFiltersFn);
      let depFilters = getDependencyFilters(visibleFilters);
      if (getPartialResults !== true) {
        setAppliedFilters(depFilters);
      }
      setAppliedFilters(initFilterData(depFilters, globalServiceGroupFilter));
      /**
       * location.search and search can only differ by the leading quotation mark for whatever reason. Such changes differences are useless, therefore the trimStart is used in comparison
       */
      if (
        !isEmptyOrWhitespace(search) &&
        trimStart(search, '?') !== trimStart(location.search, '?')
      ) {
        dispatch(appActions.setSkipLocationChanged(true));
        dispatch(
          routerActions.replace({
            pathname: trimEnd(location.pathname, '?'),
            search: search,
          }),
        );
      }

      const defaultRows = parseInt(
        userProfileSettings.GetSettingByKeyOrDefault(rowsKey).Value ?? '',
      );
      setPageSize(isNaN(defaultRows) ? 10 : defaultRows);
      if (useCardsByDefault !== undefined) {
        const defaultCardsMode =
          userProfileSettings.GetSettingByKeyOrDefault(useCardsKey).Value ?? '';
        setCardsMode(
          defaultCardsMode === ''
            ? !!useCardsByDefault
            : defaultCardsMode === true.toString(),
        );
      }
    },
    // globalServiceGroupFilter dependency has been added to reload the filters after globalServiceGroupFilter change. This is needed to remove/add service group related filter items from already loaded filters. e.g. a service that does not belong to newly selected service group/s
    // other dependencies are excluded for backwards compatibility (this was previously executed in useEffectOnMount). This should probably changed some time later when there's no prod release around the corner
    // location.pathname, location.search are needed to react for their changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalServiceGroupFilter, location.pathname, location.search],
  );

  const userProfileSettings = useSelector(selectUserProfileSettings);

  const removeEmptyFilters = (
    filter: string | undefined,
  ): string | undefined => {
    if (filter === undefined && filter === null) {
      return undefined;
    } else {
      const params = new URLSearchParams(filter);
      let newParams = new URLSearchParams();
      for (const [name, value] of params) {
        if (value !== undefined) {
          newParams.append(name, value);
        }
      }
      return newParams.toString();
    }
  };
  function getPresetFilter(
    savedFilter: string | undefined | null,
    user: IAuthenticatedUser,
  ): string | undefined {
    const result =
      savedFilter !== undefined && savedFilter !== null
        ? removeEmptyFilters(savedFilter)
        : getDefaultFilter !== undefined && getDefaultFilter !== null
        ? getDefaultFilter(user)?.toString()
        : undefined;
    return result;
  }

  const appSettings = useSelector(selectAppSettings);
  const settings = useSelector(selectglobalSettings);
  const getLocalizedFilters = useMemo(() => {
    const result = GetFiltersDefinition(
      t,
      appSettings,
      authenticatedUser ?? null,
      settings,
    );
    return result;
  }, [GetFiltersDefinition, appSettings, authenticatedUser, settings, t]);

  const allFilters = useMemo(() => {
    var result = isEmptyOrWhitespace(location.search)
      ? getLocalizedFilters('')
      : getLocalizedFilters(location.search);
    return result;
  }, [getLocalizedFilters, location.search]);

  const [appliedFilters, setAppliedFilters] = useAsyncExtendedState<
    IFilterSettings<TRow>[] | undefined
  >(undefined);

  const [pageSize, setPageSize] = React.useState<number | undefined>();
  const [pageIndex, setPageIndex] = React.useState<number | undefined>();

  const [cardsMode, setCardsMode] = React.useState<boolean>(
    !!useCardsByDefault,
  );

  const syncSearch = React.useCallback(
    (newState: Array<IFilterSettings<TRow>>) => {
      const currentSearch = getNextFilter(
        newState,
        location.search,
        reservedQueryStringParameterNames,
      );
      const originalSearch = new URLSearchParamsCI(location.search);

      // clear saved view selection when instrument selection changes
      if (enableSavedLists && savedListSettings !== undefined) {
        if (savedListSettings?.savedListSerializedKey !== undefined) {
          const currentInstrumentSelection = currentSearch.get(
            savedListSettings?.savedListSerializedKey,
          );
          const originalInstrumentSelection = originalSearch.get(
            savedListSettings?.savedListSerializedKey,
          );
          if (currentInstrumentSelection !== originalInstrumentSelection) {
            // remove the selection from the search
            currentSearch.delete(SAVEDVIEW_SEARCH_KEY);
            // clear redux state
            dispatch(savedViewActions.selectMyList());
          }
        }
      }

      // push current filter state to the URL search if it's not equivalent
      if (!originalSearch.equals(currentSearch)) {
        const currentSearchString = currentSearch.toString();
        // trim the question mark in case and for any reason the double question marks ended up saved in the "saved filter"
        // in this case the search part will be OK, but the pathname will end with the question mark
        // e.g. /reservations??eid=2 => pathName = "/reservations?"
        const pathName = trimEnd(location.pathname, '?');
        const url = pathName + '?' + currentSearchString;
        updateSavedFilterValue(url);
        dispatch(
          routerActions.push({
            pathname: location.pathname,
            search: currentSearchString,
          }),
        );
      }
    },
    [
      dispatch,
      enableSavedLists,
      location.pathname,
      location.search,
      reservedQueryStringParameterNames,
      savedListSettings,
      savedViewActions,
      updateSavedFilterValue,
    ],
  );

  const handleFilterChange: (items: IFilterSettings<TRow>[]) => void =
    React.useCallback(
      items => {
        const newState = [...items.filter(showFiltersFn)];

        setAppliedFilters(getDependencyFilters(newState));
        if (useSyncSearch) {
          syncSearch(newState);
        }
        setFilterChange(true);
      },
      [setAppliedFilters, showFiltersFn, syncSearch, useSyncSearch],
    );
  const setFilterValueCallback = React.useCallback(
    (id: string, value: FilterValueType) => {
      if (allFilters === undefined) {
        return;
      }
      const filter = allFilters?.find(f => f.id === id);
      // in cases when applied filter is not defined call to setAppliedFilters will just only mutate appliedFilters array and cause duplicate/unnecessary invocation of hooks that have appliedFilters as a dependency
      if (filter !== undefined) {
        filter.value = value;
        const x = allFilters
          .map(f => (f === filter ? { ...filter, ...{ value: value } } : f))
          .filter(showFiltersFn);
        handleFilterChange(x);
      }
    },
    [allFilters, handleFilterChange, showFiltersFn],
  );
  const getFilterValueCallback = React.useCallback(
    (id: string) => {
      const f = getFilterValue(appliedFilters, id);
      return f;
    },
    [appliedFilters],
  );

  const handelPageSizeChange = (size: number) => {
    if (!isNaN(size) && pageSize !== size && size > 0) {
      setPageSize(size);
      dispatch(appActions.savePageSize({ key: rowsKey, value: size }));
    }
  };
  const handelPageIndexChange = (index: number) => {
    if (!isNaN(index) && pageIndex !== index && index >= 0) {
      setPageIndex(index);
    }
  };

  const handleCardsModeChange = (isCardsMode: boolean) => {
    if (cardsMode !== isCardsMode) {
      setCardsMode(isCardsMode);
      dispatch(
        appActions.saveCardsMode({ key: useCardsKey, value: isCardsMode }),
      );
    }
  };
  const [defaultFilters, setDefaultFilters] = useAsyncExtendedState<
    IFilterSettings<TRow>[]
  >([]);
  React.useEffect(() => {
    const defaults = getDependencyFilters(
      getLocalizedFilters(
        getDefaultFilter?.(
          authenticatedUser as IAuthenticatedUser,
        )?.toString() ?? '',
      )
        .map(mapDefaultFilters)
        .filter(showFiltersFn),
    );
    setDefaultFilters(defaults);
    setDefaultFilters(initFilterData(defaults, globalServiceGroupFilter));
  }, [
    authenticatedUser,
    getDefaultFilter,
    getLocalizedFilters,
    globalServiceGroupFilter,
    setDefaultFilters,
    showFiltersFn,
  ]);
  const getSavedDefaultFilters = React.useCallback(() => {
    return defaultFilters;
  }, [defaultFilters]);

  return {
    allfilters: allFilters,
    appliedFilters,
    handleFilterChange,
    handelPageSizeChange,
    pageSize,
    handelPageIndexChange,
    pageIndex,
    getFilterValue: getFilterValueCallback,
    setFilterValue: setFilterValueCallback,
    cardsMode,
    onModeChange: handleCardsModeChange,
    getSavedDefaultFilters,
  } as const;
}
/**
 * Filter function to determine if the filter is needs to be shown
 * @param item
 * @returns
 */
export function visibleFiltersFilter<TRow>(item: IFilterSettings<TRow>) {
  return item.value !== undefined || item.notNullable; //|| item.visible === true,
}

export function mapDefaultFilters<TRow>(
  item: IFilterSettings<TRow>,
): IFilterSettings<TRow> {
  return { ...item, value: !item.notDefault ? item.value ?? null : item.value };
}
export interface IWithSavedHistoryComponentProps<TRow extends IRow> {
  /**
   * Innitial State of the filters container (close / open)
   */
  filterOpen?: boolean;
  /**
   * List of applicable filters
   */
  availableFilters?: IFilterSettings<TRow>[];

  /**
   * List of applicable filters
   */
  appliedFilters?: IFilterSettings<TRow>[];
  /**
   * Enables data export. On by default.
   */
  onFilterChange?: (items: IFilterSettings<TRow>[]) => void;

  /**
   * Initial table state. Part of the react-table API
   * Example: 
   * {
      sortBy: [{ id: 'Name', desc: false }],
    }
   */
  initialState?: IBasicTableState<TRow>;
  /**
   * Id of the screen that table related to. Required!
   */
  screenId?: ScreensId;
  /**
   * Saved List settings
   */
  savedListSettings?: TableSavedListSettings<TRow>;
  /**
   * Retrieves curent value set in a filter
   */
  getFilterValue?: (id: string) => FilterValueType | undefined;
  /**
   * Updates the value of a filter
   */
  setFilterValue?: (id: string, value: FilterValueType) => void;
  /**
   * should use cards mode by default
   */
  useCardsByDefault?: boolean;
  /**
   * updates the card mode
   */
  onModeChange?: (isCardsMode: boolean) => void;
  /**
   * gets this pages default filters
   */
  getSavedDefaultFilters?: () => IFilterSettings<TRow>[];
}
/**
 *
 * @param Component
 * @param savedFilterKey
 * @returns
 */
export function withSavedHistoryT<
  TRow extends IRow,
  TProps extends IWithSavedHistoryComponentProps<TRow> = BasicTableProps<TRow>,
>(
  Component: React.FC<TProps>,
  savedFilterKey: string,
  GetFiltersDefinition: GetPageFilters<TRow>,
  getDefaultFilter?: (user: IAuthenticatedUser) => URLSearchParams,
  reservedQueryStringParameterNames?: string[],
  getRequiredFilter?: (
    search: string,
    appSettings?: AppSettings | null,
    user?: AuthenticatedUser,
  ) => string | undefined,
  getSelectedViewFilter?: (
    search: string,
    view: SavedViewSelected,
  ) => string | undefined,
  enableSavedLists?: boolean,
  useCardsByDefault?: boolean,
): React.FC<TProps> {
  return ({
    onFilterChange,
    availableFilters,
    initialState,
    ...props
  }: TProps) => {
    const {
      allfilters,
      appliedFilters,
      handleFilterChange,
      handelPageSizeChange,
      pageSize,
      handelPageIndexChange,
      pageIndex,
      getFilterValue,
      setFilterValue,
      cardsMode,
      onModeChange,
      getSavedDefaultFilters,
    } = useFilters(
      savedFilterKey,
      GetFiltersDefinition,
      getDefaultFilter,
      reservedQueryStringParameterNames,
      getRequiredFilter,
      getSelectedViewFilter,
      enableSavedLists,
      props.savedListSettings,
      useCardsByDefault,
    );
    return (
      <>
        {appliedFilters !== undefined && (
          <Component
            availableFilters={allfilters}
            appliedFilters={appliedFilters}
            onFilterChange={handleFilterChange}
            onPageSizeChanged={handelPageSizeChange}
            onPageIndexChanged={handelPageIndexChange}
            initialState={{
              pageSize: pageSize,
              pageIndex: pageIndex !== undefined ? pageIndex : 0,
              ...initialState,
            }}
            getFilterValue={getFilterValue}
            setFilterValue={setFilterValue}
            useCardsByDefault={cardsMode}
            onModeChange={onModeChange}
            getSavedDefaultFilters={getSavedDefaultFilters}
            {...(props as TProps)}
          />
        )}
      </>
    );
  };
}

export function getIgnoredSearch(search: string, ignore: string[]) {
  var p = new URLSearchParams(search);
  [...p.keys()].forEach(key => {
    if (!ignore.includes(key)) {
      p.delete(key);
    }
  });

  return p;
}
export function combineSearch(search: string, other?: string) {
  if (other === undefined) {
    return search;
  } else {
    let p = new URLSearchParams(search);
    let o = new URLSearchParams(other);
    for (const key of o.keys()) {
      if (!p.has(key)) {
        const val = o.get(key);
        if (val !== null) {
          p.set(key, val);
        }
      }
    }
    return p.toString();
  }
}
