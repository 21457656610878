import * as React from 'react';
import {
  IconButton as MatIconButton,
  IconButtonProps as MatIconButtonProps,
} from '@material-ui/core';
import clsx from 'clsx';
import { IPathAndQuery, toRootedURL } from 'utils/url-utils';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { IconProp } from 'app/AllIcons';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { capitalize } from 'lodash';
import { ButtonsVariant } from '../Button';
import { RouterLink } from 'app/components/BasicLinks/RouterLink';
import { ExternalLink } from 'app/components/ExternalLink';

export interface IconButtonPropsBase
  extends Omit<MatIconButtonProps, 'size' | 'color'> {
  disabled?: boolean;
  children?: React.ReactNode;
  href?: string | IPathAndQuery;
  to?: string | IPathAndQuery;
  target?: string;
  icon?: IconProp;
}
export interface IPrimaryIconStyleThemeProps {
  size?: 'large' | 'medium' | 'small' | 'xs';
  variant?: ButtonsVariant;
  shape?: 'circle' | 'square';
  absolutePosition?: 'small' | 'medium' | 'large';
}
export type IconButtonProps = IconButtonPropsBase & IPrimaryIconStyleThemeProps;

export const IconButton = React.forwardRef(function IconButton(
  props: IconButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const {
    children,
    disabled,
    size = 'small',
    variant = 'ghost',
    shape = 'square',
    href,
    to,
    title,
    target,
    icon,
    className,
    absolutePosition,
    ...other
  } = props;

  const absolutePath =
    href === undefined
      ? undefined
      : typeof href === 'string'
      ? toRootedURL(href)
      : toRootedURL(
          (href as IPathAndQuery).path,
          (href as IPathAndQuery).search,
        );
  return (
    <WithTitle title={title}>
      <MatIconButton
        className={clsx(
          className,
          `variant${capitalize(variant)} iconSize${capitalize(
            size,
          )} shape${capitalize(shape)}`,
          absolutePosition !== undefined
            ? `absolutePosition${capitalize(absolutePosition)}`
            : undefined,
        )}
        component={
          href !== undefined
            ? ExternalLink
            : to !== undefined
            ? RouterLink
            : 'button'
        }
        disabled={disabled}
        disableRipple
        href={absolutePath}
        to={to}
        title={title}
        {...other}
      >
        {!!children ? children : <Icon icon={icon ?? 'question-circle'} />}
      </MatIconButton>
    </WithTitle>
  );
});

export function WithTitle({
  children,
  title,
}: React.PropsWithChildren<{
  title?: string;
}>) {
  if (title) {
    return (
      <Tooltip title={title}>
        <span>{children}</span>
      </Tooltip>
    );
  } else {
    return <>{children}</>;
  }
}
