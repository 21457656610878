/**
 *
 * TrainingProgramPicker
 *
 */
import { httpClient } from 'api/HttpClient';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import {
  AutocompleteMultiPicker,
  AutocompleteMultiPickerProps,
} from 'app/components/BasicPickers/AutocompleteMultiPicker';
import { TrainingProgramAssetsDto } from 'app/pages/TrainingWaitingListPage/Actions/AddToWaitingList';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Entity, Identifiable } from 'types/common';
import { IApplyGlobalServiceGroupFilter } from 'types/IApplyGlobalServiceGroupFilter';

export interface TrainingProgramsMultiPickerProps
  extends Omit<AutocompleteMultiPickerProps<number>, 'loadData'>,
    IApplyGlobalServiceGroupFilter {
  ServiceGroupId?: number;
}
const url = '/api/odata/v4/TrainingProgramsFilter';
export const initTrainingProgramMultiData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const ids = initval.split('|').map(v => parseInt(v));
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id in (' + ids.map(v => "'" + v + "'").join(',') + '))',
      $select: 'Id,Name,ServiceGroupId',
      $skip: 0,
      $top: ids.length,
    };

    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
export function withServiceGroupFilter(
  globalServiceGroupFilter?: Identifiable<number>[],
) {
  return (searchTerm: string | null) => {
    const params: { $orderby: string; $filter?: string; $select: string } = {
      $orderby: 'Name asc',
      $select: 'Id,Name,ServiceGroupId',
    };

    params.$filter = new ODataFilterBuilder<TrainingProgramAssetsDto>({
      predicates: [],
      stringColumns: ['Name'],
      stringSearch: searchTerm === null ? undefined : searchTerm,
      globalServiceGroupFilter:
        !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
          ? [
              new Condition<TrainingProgramAssetsDto>(
                'ServiceGroupId',
                ODataOperators.Includes,
                globalServiceGroupFilter.map(f => f.Id),
              ),
            ]
          : [],
    }).toString();

    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  };
}
export function TrainingProgramsMultiPicker(
  props: TrainingProgramsMultiPickerProps,
) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const loadData = withServiceGroupFilter(
    props.applyGlobalServiceGroupFilter
      ? globalServiceGroupFilter || []
      : props.ServiceGroupId !== undefined
      ? [{ Id: props.ServiceGroupId }]
      : undefined,
  );

  return (
    <AutocompleteMultiPicker
      loadData={loadData}
      mini={props.mini ? true : undefined}
      ariaLabel="Service Search"
      id={props.id || 'trainingprogramId'}
      size={props.size}
      {...props}
    />
  );
}
