import { AssetLink } from 'app/pages/AssetPopUp/OpenAssetDetails';
import { CellProps, Renderer } from 'react-table';
import { IAssetRelated } from 'types/IAssetRelated';

/**
 * Renders an Asset Link (Popup/sidepanel)
 * Requires for the AssetId property to be present on the row, otherwise the value is rendered without the link
 * @param param0
 * @returns
 */
const AssetRenderer: Renderer<CellProps<IAssetRelated>> = ({
  row: { original },
  value,
}) =>
  !!original.AssetId ? (
    <AssetLink id={original.AssetId}>{value}</AssetLink>
  ) : (
    <>{value}</>
  );
export default AssetRenderer;
