import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { sideBarSaga } from './saga';
import {
  MenuItemEntry,
  MenuItemEntryL1,
  MenuItems,
  SideBarState,
} from './types';

export const initialState: SideBarState = {
  loadingSiteMapSettings: false,
  siteMapSettings: null,
  menuItems: [],
};

const slice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    setMenuItems(state, action: PayloadAction<MenuItems>) {
      state.menuItems = action.payload;
    },
    expand(state, action: PayloadAction<MenuItemEntryL1>) {
      state.menuItems.forEach(group => {
        group.forEach(item => {
          if (item.id === action.payload.id) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
      });
    },
    toggleExpand(
      state,
      action: PayloadAction<{ item: MenuItemEntryL1; toggle?: boolean }>,
    ) {
      state.menuItems.forEach(group => {
        group.forEach(item => {
          item.selected = false;
          if (item.id === action.payload.item.id) {
            item.selected =
              action.payload.toggle ?? !action.payload.item.selected;
          }
        });
      });
    },
    setPathName(state, action: PayloadAction<MenuItemEntry | undefined>) {
      if (state.menuItems === undefined) {
        return;
      }
      state.menuItems.forEach(group => {
        group.forEach(item => {
          item.selected = false;
          item.items?.forEach(item2 => {
            item2.selected = false;
            if (item2.id === action.payload?.id) {
              item2.selected = true;
              item.selected = true;
              return;
            }
          });
        });
      });
    },
    setMenuName(
      state,
      action: PayloadAction<{ entry: MenuItemEntry | undefined; name: string }>,
    ) {
      if (state.menuItems === undefined) {
        return;
      }
      state.menuItems.forEach(group => {
        group.forEach(item => {
          item.items?.forEach(item2 => {
            if (item2.id === action.payload?.entry?.id) {
              item2.name = action.payload.name;
              return;
            }
          });
        });
      });
    },
  },
});

export const { actions: sideBarActions } = slice;

export const useSideBarSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: sideBarSaga });
  return { actions: slice.actions };
};
