import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  FormFieldsSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { AssetFileType, AssetLinkType, AssetTypes } from '.';
import { useState } from 'react';
import { FormAssetPicker } from 'app/components/Forms/FormAssetPicker';
import { FormRadioGroupBase } from 'app/components/Forms/FormRadioGroupBase';
import { FormInputFile } from 'app/components/Forms/FormInputFile';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { EnumPicker } from 'app/components/BasicPickers/EnumPicker';
import { enumStringKeys } from 'utils/enumKeys';
import { FileAvailability } from './fileAvailability';
import { DocumentType } from 'app/components/pickers/AutocompletePickers/DocumentTypePicker';
import { isNullOrUndefined } from 'utils/typeUtils';
import { BaseURLPicker } from 'app/components/BasicPickers/BaseURLPicker';
import { odataUrlPrefix } from 'utils/url-utils';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';

interface FormProps {
  fileSchema: Yup.SchemaOf<AssetFileType>;
  linkSchema: Yup.SchemaOf<AssetLinkType>;
  initialValues: AssetTypes;
  onSubmit: (values: AssetTypes) => void;
  processing: boolean;
  hasAsset: boolean;
  isEdit: boolean;
  setSubmit: (func) => void;
  setValid: (valid: boolean) => void;
}
type FileType = 'link' | 'file';
export const Form = ({
  fileSchema,
  linkSchema,
  initialValues,
  onSubmit,
  processing,
  hasAsset,
  isEdit,
  setSubmit,
  setValid,
}: FormProps) => {
  const { t } = useTranslation();
  const [fileType, setFileType] = useState<FileType>('file');
  const isFile = fileType === 'file';

  return (
    <Formik
      validationSchema={isFile ? fileSchema : linkSchema}
      initialValues={initialValues}
      validateOnMount
      onSubmit={onSubmit}
      validateOnChange
      enableReinitialize
    >
      {formik => {
        setSubmit(formik.submitForm);
        setValid(formik.isValid);
        return (
          <StyledForm onSubmit={formik.handleSubmit}>
            <FormFieldsSection>
              {!hasAsset && (
                <FormRow fullRow>
                  <FormAssetPicker
                    name="asset"
                    admin
                    onChange={val => {
                      formik.setFieldValue('assetId', val?.Id ?? null, true);
                    }}
                    label={t(translations.Asset)}
                  />
                </FormRow>
              )}
              <FormRow>
                <FormRadioGroupBase
                  id="filetypeOptionsPicker"
                  name="fileType"
                  options={[
                    { Id: 'file', Name: t(translations.Attachment) },
                    { Id: 'link', Name: t(translations.Link) },
                  ]}
                  onChange={v => {
                    setFileType(v);
                  }}
                  orientation="horizontal"
                  disabled={isEdit}
                />
              </FormRow>
              {isFileTypeForm(formik) ? (
                <>
                  <FormRow fullRow>
                    <FormInputFile name="file" showPreview />
                  </FormRow>
                  <FormRow fullRow>
                    <EnumPicker
                      EnumNamesArray={enumStringKeys(FileAvailability)}
                      onChange={v => {
                        formik.setFieldValue(
                          'fileAvailable',
                          FileAvailability[
                            v ?? FileAvailability.StaffUsersFiles
                          ],
                        );
                      }}
                      variant="filled"
                      label={t(translations.FileAvailability)}
                      info={t(translations.FileAvailability_info)}
                      value={FileAvailability[formik.values.fileAvailable]}
                    />
                  </FormRow>
                  <FormRow fullRow>
                    <DocumentType
                      fullWidth
                      value={formik.values.docType}
                      onChange={v => formik.setFieldValue('docType', v)}
                      variant="filled"
                      label={t(translations.DocumentType)}
                      error={!!formik.errors.docType}
                      helperText={formik.errors.docType}
                    />
                  </FormRow>
                  <FormRow fullRow>
                    <FormTextField
                      name="title"
                      fullWidth
                      label={t(translations.Title)}
                    />
                  </FormRow>
                  <FormRow fullRow>
                    <FormTextField
                      fullWidth
                      name="description"
                      label={t(translations.Description)}
                    />
                  </FormRow>
                </>
              ) : (
                isLinkTypeForm(formik) && (
                  <>
                    <FormRow fullRow>
                      <FormAssetPicker
                        name="fromAsset"
                        label={t(translations.Asset)}
                      />
                    </FormRow>
                    {!isNullOrUndefined(formik.values.fromAsset) && (
                      <FormRow fullRow>
                        <BaseURLPicker
                          url={odataUrlPrefix + 'FileFilter'}
                          queryfilter={new Condition(
                            'AssetId',
                            ODataOperators.Equals,
                            formik.values.fromAsset,
                          ).toString()}
                          value={formik.values.fileLink}
                          onChange={v => {
                            formik.setFieldValue('fileLink', v, true);
                          }}
                          label={t(translations.File)}
                        />
                      </FormRow>
                    )}
                  </>
                )
              )}
            </FormFieldsSection>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export const isFileType = (file: AssetTypes): file is AssetFileType => {
  return file.fileType === 'file';
};

const isFileTypeForm = (
  form: FormikProps<any>,
): form is FormikProps<AssetFileType> => {
  return isFileType(form.values);
};
const isLinkTypeForm = (
  form: FormikProps<any>,
): form is FormikProps<AssetLinkType> => {
  return !isFileType(form.values);
};
