import styled from 'styled-components';

export const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const FlexRowCenter = styled(FlexRowDiv)`
  align-self: center;
  justify-self: center;
`;
export const NotAllowedContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 16px;
`;
export const FlexRowContent = styled.div`
  text-align: center;
  margin-top: 5%;
`;
export const FlexRowNoContent = styled.div`
  text-align: center;
  padding-block: 48px;
`;
export const FlexRowStart = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  flex: 1 1 auto;
`;
