import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { offlineTypeRefsActions as actions } from '.';
import { appSettingsActions } from 'app/slice';
import { translations } from 'locales/translations';
import {
  ConverModelToDto,
  ConvertDtoToModel,
  getDefaultModel,
  IOfflineTypeRefsModel,
  IOfflineTypeRefsResponse,
  MergeModelsToDto,
  OfflineTypeRefsQueryParams,
} from './types';
import { IOfflineServiceTypeRefsDto } from 'api/odata/generated/entities/IOfflineServiceTypeRefsDto';
import { OfflineTypeRefsApi as api } from 'api/OfflineTypeRefsApi';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { OfflineTypeReferencesDetailsProps } from '..';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { AxiosError } from 'axios';
import i18next from 'i18next';
import { httpClient } from 'api/HttpClient';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';

function* doInitDetails(
  action: PayloadAction<{
    query: OfflineTypeRefsQueryParams;
    model?: IOfflineServiceTypeRefsDto;
  }>,
) {
  try {
    let data: IOfflineServiceTypeRefsDto | null = action.payload.model || null;
    let hasError: boolean = false;
    let offlineType: IOfflineServiceFilterDto | undefined = undefined;
    if (data === null) {
      const result = yield call(api.initDetails, action.payload.query);
      let response = result as IOfflineTypeRefsResponse;
      if (response.ErrorMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.ErrorMessages.map(item => {
              return {
                key: 'initRefsErr',
                message: item,
                variant: 'error',
              };
            }),
          ),
        );
      }
      if (response.WarningMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.WarningMessages.map(item => {
              return {
                key: 'initRefsWarn',
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      if (response.SuccessMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.SuccessMessages.map(item => {
              return {
                key: 'initRefsSucc',
                message: item,
                variant: 'success',
              };
            }),
          ),
        );
      }
      data = response.data ?? getDefaultModel();
      hasError = !response.IsValid;
    }

    let initType = yield call(
      httpClient.get,
      '/api/odata/v4/OfflineServiceFilter',
      {
        $filter: `Id eq ${data.OfflineServiceTypeId}`,
        $expand: 'InventoryBatches,RelatedReferences',
      },
    );
    offlineType = initType.value[0] as IOfflineServiceFilterDto | undefined;
    yield put(
      actions.initDetails_Success({
        hasErrors: hasError,
        data: ConvertDtoToModel(data, offlineType),
        isEdit: data.Id > 0,
      }),
    );
  } catch (error: unknown) {
    yield put(actions.initDetails_Error(error));
  }
}
function* doCreateRefs(action: PayloadAction<IOfflineTypeRefsModel>) {
  const httpPayloads: IOfflineServiceTypeRefsDto = ConverModelToDto(
    action.payload,
  );
  try {
    const result = yield call(api.insertRefs, httpPayloads);
    let response = result as IOfflineTypeRefsResponse;
    let responseErrors = response.ErrorMessages;
    let responseWarnings = response.WarningMessages;
    let responseSuccess = response.SuccessMessages;
    if (responseErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          responseErrors.map(item => {
            return {
              key: 'refInsertError',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    } else {
      if (responseSuccess.length > 0) {
        yield put(
          appSettingsActions.addNotification({
            key: 'refInsertSuccess',
            message: responseSuccess[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id ?? undefined,
              created: true,
              itemName: i18next.t(translations.menu_OfflineTypeRefernces),
              detailsType: RenderPageType.OfflineTypeRefDetails,
              detailsTypeProps: {
                useSidePanel: true,
                queryParams: {
                  id: '' + response.Id ?? -1,
                },
                initialDetails: response.data,
              } as OfflineTypeReferencesDetailsProps,
            },
            variant: 'success',
          }),
        );
      }
      if (responseWarnings.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            responseWarnings.map(item => {
              return {
                key: 'refsInsertWarning',
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = responseErrors.length > 0;
    yield put(
      actions.createRefs_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'refsInsertErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createRefs_Error(Error));
  }
}
function* doUpdateRefs(
  action: PayloadAction<{
    current: IOfflineTypeRefsModel;
    original: IOfflineTypeRefsModel;
  }>,
) {
  let updatedPeriod = MergeModelsToDto(
    action.payload.current,
    action.payload.original,
  );

  try {
    const results = yield call(
      api.updateRefs,
      updatedPeriod,
      action.payload.current.Id,
    );
    let response = results as IOfflineTypeRefsResponse;
    let respErrors = response.ErrorMessages;
    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: 'refsUpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    } else {
      if (response.SuccessMessages.length > 0) {
        yield put(
          appSettingsActions.addNotification({
            key: 'refsUpdateSuccess',
            message: response.SuccessMessages[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id ?? undefined,
              created: false,
              itemName: i18next.t(translations.RelatedService),
              detailsType: RenderPageType.OfflineTypeRefDetails,
              detailsTypeProps: {
                useSidePanel: true,
                queryParams: {
                  id: '' + response.Id ?? -1,
                },
                initialDetails: response.data,
              } as OfflineTypeReferencesDetailsProps,
            },
            variant: 'success',
          }),
        );
      }
      if (response.WarningMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.WarningMessages.map(item => {
              return {
                key: 'refsUpdateWarn',
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.updateRefs_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'refsUpdateErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateRefs_Error(Error));
  }
}

export function* offlineTypeRefsSaga() {
  yield takeLatest(actions.initDetails.type, doInitDetails);
  yield takeLatest(actions.createRefs.type, doCreateRefs);
  yield takeLatest(actions.updateRefs.type, doUpdateRefs);
}
