import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { TabPanelWrapper } from '../TabsWrapper';
import clsx from 'clsx';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  tabsId: string;
  tabsPanelId: string;
  scrollableContent?: boolean;
}

export function TabPanel(props: TabPanelProps) {
  const {
    tabsPanelId,
    tabsId,
    children,
    value,
    index,
    scrollableContent,
    ...other
  } = props;
  const theme = useTheme();
  return (
    <TabPanelWrapper
      theme={theme}
      className={clsx('tabPanel', {
        scrollablePanel: scrollableContent === true,
      })}
      role="tabpanel"
      hidden={value !== index}
      id={`${tabsPanelId}-${index}`}
      aria-labelledby={`${tabsId}-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </TabPanelWrapper>
  );
}
