import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.samplePlatesDetail || initialState;

export const selectSamplePlatesDetails = createSelector(
  [selectSlice],
  state => state,
);
//====================================================================
export const selectCreateSamplePlatesDetails = createSelector(
  [selectSamplePlatesDetails],
  state => state.createState.data,
);

export const selectCreateSamplePlatesDetailsCompleted = createSelector(
  [selectSamplePlatesDetails],
  state => state.createState.completed,
);

export const selectCreateSamplePlatesDetailsHasError = createSelector(
  [selectSamplePlatesDetails],
  state => state.createState.hasErrors,
);
//====================================================================
export const selectUpdateSamplePlatesDetails = createSelector(
  [selectSamplePlatesDetails],
  state => state.updateState.data,
);

export const selectUpdateSamplePlatesDetailsCompleted = createSelector(
  [selectSamplePlatesDetails],
  state => state.updateState.completed,
);
export const selectUpdateSamplePlatesDetailsHasError = createSelector(
  [selectSamplePlatesDetails],
  state => state.updateState.hasErrors,
);
//====================================================================
export const selectSamplePlatesDetailsProcessing = createSelector(
  [selectSamplePlatesDetails],
  state => state.processing,
);
