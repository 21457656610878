import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import * as React from 'react';
import styled from 'styled-components';
import { FileInput, FileInputProps } from './FileInput';
import { FilesPreview } from './FilesPreview';

export interface FileUploadProps {
  values: IFormFileValue[];
  multiple?: boolean;
  uploadLabel?: string;
  showPreview?: boolean;
  fileSizeLimit?: number;
  saveFiles?: (files: IFormFileValue[]) => void;
  fileInputProps: FileInputProps;
  disabled?: boolean;
  uploadText?: string;
  selectedFiles?: (files: File[]) => void;
  notSaveFiles?: boolean;
}

export function FileUpload(props: FileUploadProps) {
  const {
    values,
    multiple,
    showPreview,
    saveFiles,
    fileInputProps,
    disabled,
    uploadLabel,
    uploadText,
    selectedFiles,
    notSaveFiles,
  } = props;

  const [innerFiles, setInnerFiles] = React.useState<Record<string, File>>({});
  const [fileValues, setFileValues] = React.useState<IFormFileValue[] | null>(
    values,
  );
  const referenceId = fileInputProps.referenceId;
  const convertFilesToArray = (files: Record<string, File>) =>
    Object.keys(files).map(key => files[key]);

  const addNewFiles = React.useCallback(
    (newFiles: FileList) => {
      let files = multiple ? innerFiles : {};
      for (let file of newFiles) {
        if (!multiple) {
          // return { file };
          files[file.name] = file;
          return { ...files };
        }
        files[file.name] = file;
      }
      return { ...files };
    },
    [innerFiles, multiple],
  );
  const updateState = React.useCallback(
    (files: File[], deleteFileName?: string, isMultiple?: boolean) => {
      let savedFiles = fileValues ?? [];
      if (deleteFileName) {
        savedFiles =
          fileValues?.filter(f => f.DisplayValue !== deleteFileName) ?? [];
      } else {
        files?.forEach(file => {
          if (
            savedFiles.filter(f => f.DisplayValue === file.name)?.length === 0
          ) {
            let newFile: IFormFileValue = {
              Id: referenceId || -1,
              Value: null,
              DisplayValue: file.name,
              Size: file.size,
              PostedFile: file,
            };
            if (isMultiple) {
              savedFiles = [...savedFiles, newFile];
            } else {
              savedFiles = [newFile];
            }
          }
        });
      }
      return savedFiles;
    },
    [fileValues, referenceId],
  );
  const updateFiles = React.useCallback(
    (
      files: Record<string, File>,
      deleteFileName?: string,
      isMultiple?: boolean,
    ) => {
      if (notSaveFiles !== true) {
        const filesAsArray = convertFilesToArray(files);
        const savedFiles = updateState(
          filesAsArray,
          deleteFileName,
          isMultiple,
        );
        setFileValues(savedFiles);
        if (saveFiles !== undefined) {
          saveFiles(savedFiles ?? []);
        }
      }
    },
    [notSaveFiles, saveFiles, updateState],
  );
  const handleChange = React.useCallback(
    (files: FileList | null) => {
      let updatedFiles = {};
      if (files?.length) {
        updatedFiles = addNewFiles(files);
        setInnerFiles(updatedFiles);
      } else {
        setInnerFiles({});
      }
      if (!!selectedFiles && files !== null) {
        const filesAsArray = convertFilesToArray(updatedFiles);
        selectedFiles(filesAsArray);
      }
      updateFiles(updatedFiles, undefined, multiple);
    },
    [addNewFiles, multiple, selectedFiles, updateFiles],
  );
  const removeFile = React.useCallback(
    (fileName: string) => {
      let files = innerFiles;
      delete files[fileName];
      setInnerFiles({ ...files });

      updateFiles({ ...files }, fileName, multiple);
    },
    [innerFiles, multiple, updateFiles],
  );
  return (
    <FileWrapper className="file-wrap-root">
      <FileInput
        onFileChange={handleChange}
        onRemove={removeFile}
        file={fileValues && fileValues.length > 0 ? fileValues[0] : undefined}
        disabled={disabled}
        uploadLabel={uploadLabel}
        uploadText={uploadText}
        {...fileInputProps}
      />
      {multiple && showPreview && (
        <FilesPreview
          files={fileValues}
          multiple={multiple}
          onRemove={removeFile}
        />
      )}
    </FileWrapper>
  );
}
const FileWrapper = styled.div`
  &.file-wrap-root {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
`;
