/**
 *
 * MenuSearchResultList
 *
 */
import * as React from 'react';
import { ListItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectMenuSearchResults } from '../slice/selectors';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Body, Uppercase } from 'app/components/Typography';
import {
  QuickSearchSection,
  QuickSearchSearchesItems,
  QuickSearchSectionTitle,
  QuickSearchEmptyContent,
  MenuSearchEmptyContent,
} from '../styled';
import { MenuSearchItem } from '../MenuSearchItem';

export interface MenuSearchResultListProps {}

export function MenuSearchResultList(props: MenuSearchResultListProps) {
  const { t } = useTranslation();
  const menuSearchState = useSelector(selectMenuSearchResults);

  return (
    <>
      <ListItem key="menu-searches" disableGutters>
        <QuickSearchSection>
          <QuickSearchSectionTitle>
            <Uppercase color="secondary">
              {t(translations.RelatedScreens)}
            </Uppercase>
          </QuickSearchSectionTitle>
          <QuickSearchSearchesItems>
            {menuSearchState === undefined ||
            menuSearchState.Results.length === 0 ? (
              <MenuSearchEmptyContent>
                <QuickSearchEmptyContent className="empty-search-content">
                  <span className="empty-search-content-wrap">
                    <Body
                      size="large"
                      bold
                      className="empty-search-content-text"
                    >
                      {t(translations.NoRelatedScreensFound)}
                    </Body>
                  </span>
                </QuickSearchEmptyContent>
              </MenuSearchEmptyContent>
            ) : (
              <>
                {menuSearchState.Results.map((item, index) => (
                  <MenuSearchItem key={index} item={item} />
                ))}
              </>
            )}
          </QuickSearchSearchesItems>
        </QuickSearchSection>
      </ListItem>
    </>
  );
}
