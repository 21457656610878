// - [yYQqMLwIdDecihHKkms]o matches any available ordinal number token
//   (one of the certain letters followed by `o`)
// - (\w)\1* matches any sequences of the same letter
// - '' matches two quote characters in a row
// - '(''|[^'])+('|$) matches anything surrounded by two quote characters ('),
//   except a single quote symbol, which ends the sequence.
//   Two quote characters do not end the sequence.
//   If there is no matching single quote
//   then the sequence will continue until the end of the string.
// - . matches any single character unmatched by previous parts of the RegExps
// This RegExp catches symbols escaped by quotes, and also

import { dateUtils } from 'utils/date-utils';
import longFormatters from './longFormatters';

// sequences of symbols P, p, and the combinations like `PPPPPPPppppp`
const formattingTokensRegExp =
  /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g;
const longFormattingTokensRegExp = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g;
// const escapedStringRegExp = /^'([^]*?)'?$/;
// const doubleQuoteRegExp = /''/g;
// const notWhitespaceRegExp = /\S/;
// const unescapedLatinCharacterRegExp = /[a-zA-Z]/;
//returns RegExpMatchArray
export default function getFormatTokens(formatString: string): string[] | null {
  //  if(formatString === null ) return null;
  let tokens = formatString
    .match(longFormattingTokensRegExp)
    ?.map(function (substring) {
      var firstCharacter = substring[0];

      if (firstCharacter === 'p' || firstCharacter === 'P') {
        var longFormatter = longFormatters[firstCharacter];
        return longFormatter(
          substring,
          dateUtils.dateFnsUtils.locale.formatLong,
          // dateUtils.subFnOptions(),
        );
      }

      return substring;
    })
    .join('')
    .match(formattingTokensRegExp);
  if (tokens === undefined || tokens === null) {
    return null;
  }
  return tokens;
}
