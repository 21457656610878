import { IInvoiceTemplateDto } from 'app/pages/InvoiceTemplates/InvoiceTemplatesPage/IInvoiceTemplateDto';
import { Entity } from 'types/common';
import { IResponseType } from 'types/ResponseType';
import { httpClient } from './HttpClient';

const url = '/api/odata/v4/InvoiceTemplates';
export const InvoiceTemplatesApi = {
  insertInvoiceTemplate: invoiceTemplate => {
    const form = new FormData();
    Object.keys(invoiceTemplate).forEach(k => {
      form.append(k, invoiceTemplate[k]);
    });

    if (
      !!invoiceTemplate.HeaderFile &&
      invoiceTemplate.HeaderFile?.length > 0
    ) {
      form.append(
        'TemplateHeaderFile',
        invoiceTemplate.HeaderFile[0].PostedFile,
      );
    }
    if (
      !!invoiceTemplate.FooterFile &&
      invoiceTemplate.FooterFile?.length > 0
    ) {
      form.append(
        'TemplateFooterFile',
        invoiceTemplate.FooterFile[0].PostedFile,
      );
    }
    return httpClient.post(url, form);
  },
  updateInvoiceTemplate: (Id: number, invoiceTemplate) => {
    const form = new FormData();
    Object.keys(invoiceTemplate).forEach(k => {
      form.append(k, invoiceTemplate[k]);
    });

    if (
      !!invoiceTemplate.HeaderFile &&
      invoiceTemplate.HeaderFile?.length > 0
    ) {
      form.append(
        'TemplateHeaderFile',
        invoiceTemplate.HeaderFile[0].PostedFile,
      );
    }
    if (
      !!invoiceTemplate.FooterFile &&
      invoiceTemplate.FooterFile?.length > 0
    ) {
      form.append(
        'TemplateFooterFile',
        invoiceTemplate.FooterFile[0].PostedFile,
      );
    }
    return httpClient.put<any, IResponseType>(url + '(' + Id + ')', form);
  },
  deleteInvoiceTemplates: (
    rows: IInvoiceTemplateDto[],
  ): Promise<IResponseType> => {
    const invoiceTemplatesIds: number[] = rows.map(item => item.Id);
    const data = { invoiceTemplatesIds };
    const url = '/api/odata/v4/InvoiceTemplates/DeleteInvoiceTemplates';
    return httpClient.post(url, data);
  },
  getInvoiceTemplateById: (Id: number) => {
    const params: {
      $filter?: string;
      $select: string;
    } = {
      $filter: '(Id eq ' + Id + ')',
      $select: [
        'Id',
        'Name',
        'CreatedAt',
        'LastUpdated',
        'FundingType',
        'ServiceGroupId',
        'ServiceGroup',
        'Html',
        'TableHeaderColor',
        'TableBodyColor',
        'HeaderFileName',
        'FooterFileName',
      ].join(','),
    };
    return httpClient.get(url, params);
  },
  assignInvoiceTemplateToInvoiceBatch: (
    rows: IInvoiceTemplateDto[],
    invoiceBatches: Entity<number>[] | [],
  ): Promise<IResponseType> => {
    const invoiceTemplateIds: number[] = rows.map(item => item.Id);
    const invoiceBatchIds: number[] = invoiceBatches.map(item => item.Id);
    const data = { invoiceTemplateIds, invoiceBatchIds };
    const url =
      '/api/odata/v4/InvoiceTemplates/AssignInvoiceTemplateToInvoiceBatch';
    return httpClient.post(url, data);
  },
};
