/**
 *
 * AvailabilityTypeFilter
 *
 */
import { AvailabilityTypeBookablePicker } from 'app/components/pickers/AutocompletePickers/AvailabilityTypePicker';
import * as React from 'react';
import { Entity } from 'types/common';
import { IFilterComponentProps } from '../IFilterComponentProps';

export const AvailabilityTypeBookableFilter = (
  props: IFilterComponentProps,
) => {
  const handleOnChange = (value: Entity<number> | null) => {
    props.onChange(value);
  };
  return (
    <AvailabilityTypeBookablePicker
      ariaLabel={props.label}
      id={props.id}
      predicates={props.predicates}
      onPickerOpen={props.onOpen}
      isOpen={props.open}
      size="small"
      onChange={handleOnChange}
      fullWidth={props.fullWidth}
      value={props.value as Entity<number> | undefined}
    />
  );
};
export default AvailabilityTypeBookableFilter;
