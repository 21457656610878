import { httpClient } from 'api/HttpClient';
import {
  BaseURLMultiPicker,
  BaseURLMultiPickerProps,
} from 'app/components/BasicPickers/BaseURLMultiPicker';
import { Entity } from 'types/common';

export interface UserGroupMultiPickerProps
  extends Omit<BaseURLMultiPickerProps<string>, 'url'> {}

const url = '/api/odata/v4/UserGroupFilter';

export const initUserGroupMultiData = (initval: string | undefined) => {
  if (initval === undefined) {
    return Promise.resolve([] as Entity<string>[]);
  }
  const ids = initval.split('|');
  const params: {
    $orderby: string;
    $filter?: string;
    $select: string;
    $top: number;
  } = {
    $orderby: 'Id asc',
    $filter: '(Id in (' + ids.map(v => "'" + v + "'").join(',') + '))',
    $select: 'Id,Name',
    $top: ids.length,
  };
  return httpClient
    .get(url, params)
    .then(response => response.value as Entity<string>[]);
};

export const UserGroupMultiPicker = (props: UserGroupMultiPickerProps) => {
  return <BaseURLMultiPicker url={url} {...props} />;
};
