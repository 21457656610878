import { Entity } from 'types/common';
import { IResponseType } from 'types/ResponseType';
import { httpClient } from './HttpClient';

const SourceUrl = '/api/odata/v4/ServiceRequestList';
export const ServiceRequestsApi = {
  UpdateStatuses: (status: Entity<number> | null, ids: number[]) => {
    const data = {
      status: status?.Name,
      ids,
    };
    const url = SourceUrl + '/UpdateStatuses';
    return httpClient.post(url, data);
  },
  DeleteRequests: (ids: number[]) => {
    const data = { ids };
    const url = SourceUrl + '/DeleteRequests';
    return httpClient.post(url, data);
  },
  AddRequest: (
    osid: number,
    budgetId: number,
    userName: string,
    userGroupId: string | undefined,
    fundingTypeId: number | null,
    purchaseOrder: string | null,
  ): Promise<IResponseType> => {
    const data = {
      osid,
      budgetId,
      userName,
      userGroupId,
      fundingTypeId,
      purchaseOrder,
    };
    const url = SourceUrl + '/AddRequest';
    return httpClient.post(url, data);
  },
  updateAdminOnComment: async (id: number) => {
    const url = SourceUrl + '/UpdateAdminOnComment';
    try {
      await httpClient.post(url, { id });
    } catch {}
  },
};
