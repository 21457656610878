/**
 *
 * PriorityPicker
 *
 */

import {
  PrioritiesUnion,
  PrioritiesUnionArray,
} from 'api/odata/generated/enums/Priorities';
import {
  EnumMultiPicker,
  withEnumMultiOptions,
} from 'app/components/BasicPickers/EnumMultiPicker';
import {
  EnumPicker,
  withEnumOptions,
} from 'app/components/BasicPickers/EnumPicker';

export const PriorityPicker =
  withEnumOptions<PrioritiesUnion>(PrioritiesUnionArray)(EnumPicker);

export const PriorityMultiPicker =
  withEnumMultiOptions<PrioritiesUnion>(PrioritiesUnionArray)(EnumMultiPicker);
