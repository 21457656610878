import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Link as MuiLink } from '@material-ui/core';
import React, { MouseEventHandler } from 'react';
import { ServiceRequestDetailsProps } from 'app/pages/Samples/RequestSamplesPage';
import { GetServiceRequestDetailsPath } from 'app/pages/Samples/RequestSamplesPage/slice/path';
import { DetectIsMobile } from 'utils/mobileDetect';
import { buildURL } from 'utils/url-utils';
import { useSelector } from 'react-redux';
import { selectPublicUrl } from 'app/slice/selectors';

export interface OpenRequestButtonProps extends ButtonProps {
  buttonName: React.ReactNode;
  requestId: number;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
}
export function OpenRequestButton(props: OpenRequestButtonProps) {
  const { buttonName, requestId, useSidePanel, openPanelWithCover } = props;
  const { t } = useTranslation();
  const isMobile = DetectIsMobile();
  const { openPanel } = useSidePanelState();
  const openOrder = () => {
    let orderProps = {
      id: requestId,
      useSidePanel: true,
    } as ServiceRequestDetailsProps;
    let panelProps = {
      renderPageType: RenderPageType.ServiceRequestDetails,
      renderPageProps: orderProps,
      expanded: !useSidePanel && !isMobile,
      useSidePanel: useSidePanel,
      isCover: useSidePanel,
    } as SidePanelOpenState;
    !!openPanelWithCover
      ? openPanelWithCover(panelProps)
      : openPanel(panelProps);
  };
  const handleRightClick: MouseEventHandler<HTMLButtonElement> =
    React.useCallback(
      event => {
        event.preventDefault();
        window.open(GetServiceRequestDetailsPath(requestId));
      },
      [requestId],
    );
  return (
    <Button
      variant={props.variant || 'white'}
      size="small"
      onClick={() => openOrder()}
      onContextMenu={handleRightClick}
    >
      {buttonName || t(translations.menu_EditServiceRequest)}
    </Button>
  );
}
export const RequestLink = (props: {
  id: number;
  children: React.ReactNode;
  title?: string;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
}) => {
  const { t } = useTranslation();
  const openRequest = useOpenRequestPopUp();
  const getLink = useRequestLinkUrl();
  const isMobile = DetectIsMobile();
  const open = () => {
    openRequest(
      {
        id: props.id,
        useSidePanel: props.useSidePanel,
        isMobile: isMobile,
      },
      props.openPanelWithCover,
    );
  };
  const link = getLink(props.id);
  //GetServiceRequestDetailsPath(props.id);
  // const handleRightClick: MouseEventHandler<HTMLAnchorElement> = React.useCallback(
  //   event => {
  //     event.preventDefault();
  //     window.open(GetServiceRequestDetailsPath(props.id));
  //   },
  //   [props.id],/requests/:id
  // );
  return (
    <Tooltip title={props.title ?? t(translations.ServiceRequestDetails)}>
      <MuiLink
        onClick={event => {
          if (!!link) {
            if (!event.ctrlKey) {
              event.preventDefault();
              open();
            }
          } else {
            open();
          }
        }}
        color="textPrimary"
        href={link}
      >
        {props.children}
      </MuiLink>
    </Tooltip>
  );
};

export const useOpenRequestPopUp = () => {
  const { openPanel } = useSidePanelState();
  return React.useCallback(
    (
      props: { id: number; useSidePanel?: boolean; isMobile?: boolean },
      openPanelWithCover?: (state: SidePanelOpenState) => void,
    ) => {
      let requestProps = {
        id: props.id,
        useSidePanel: true,
      } as ServiceRequestDetailsProps;
      const panelState = {
        renderPageType: RenderPageType.ServiceRequestDetails,
        renderPageProps: requestProps,
        expanded: !props.useSidePanel && !props.isMobile,
        useSidePanel: props.useSidePanel,
        isCover: props.useSidePanel,
      } as SidePanelOpenState;
      if (!!openPanelWithCover) {
        openPanelWithCover(panelState);
      } else {
        openPanel({
          renderPageType: RenderPageType.ServiceRequestDetails,
          renderPageProps: requestProps,
          expanded: !props.isMobile,
          useSidePanel: false,
          isCover: false,
        } as SidePanelOpenState);
      }
    },
    [openPanel],
  );
};
export const useRequestLinkUrl = () => {
  const publicUrl = useSelector(selectPublicUrl);
  return React.useCallback(
    (id: number) => {
      return buildURL(publicUrl + `requests/${id}`, {});
    },
    [publicUrl],
  );
};
