import { Button, ButtonProps } from 'app/components/BasicButtons/Button';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { WorkOrderDetailsProps } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { Link as MuiLink } from '@material-ui/core';
import React from 'react';
import { WorkOrderQueryStringParameters } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details/slice/types';
import { useSelector } from 'react-redux';
import { selectPublicUrl } from 'app/slice/selectors';
import { buildURL } from 'utils/url-utils';

export interface OpenWorkOrderButtonProps extends ButtonProps {
  buttonName: React.ReactNode;
  orderId: number;
  bringBackUp?: boolean;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
}
export function OpenWorkOrderButton(props: OpenWorkOrderButtonProps) {
  const { buttonName, orderId, bringBackUp, useSidePanel, openPanelWithCover } =
    props;
  const { t } = useTranslation();
  const { openPanel } = useSidePanelState();
  const openOrder = () => {
    let orderProps = {
      queryParams: {
        id: orderId.toString(),
        Up: (bringBackUp === true).toString(),
      },
      useSidePanel: true,
    } as WorkOrderDetailsProps;
    let panelProps = {
      renderPageType: RenderPageType.WorkOrderDetails,
      renderPageProps: orderProps,
      expanded: false,
      useSidePanel: useSidePanel,
      isCover: useSidePanel,
    } as SidePanelOpenState;
    !!openPanelWithCover
      ? openPanelWithCover(panelProps)
      : openPanel(panelProps);
  };

  return (
    <Button
      variant={props.variant || 'white'}
      size="small"
      onClick={() => openOrder()}
    >
      {buttonName || t(translations.menu_EditWorkOrder)}
    </Button>
  );
}
export const WorkOrderLink = (props: {
  id: number;
  Up?: boolean;
  children: React.ReactNode;
  title?: string;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
}) => {
  const { t } = useTranslation();
  const openOrder = useOpenWorkOrderPopUp();
  const getLink = useWorkOrderLinkUrl();
  const link = getLink({ id: props.id.toString(), Up: props?.Up?.toString() });
  const open = () => {
    openOrder(
      {
        id: props.id,
        Up: props.Up,
        useSidePanel: props.useSidePanel,
      },
      props.openPanelWithCover,
    );
  };
  return (
    <Tooltip title={props.title ?? t(translations.menu_EditWorkOrder)}>
      <MuiLink
        onClick={event => {
          if (!!link) {
            if (!event.ctrlKey) {
              event.preventDefault();
              open();
            }
          } else {
            open();
          }
        }}
        color="textPrimary"
        href={link}
      >
        {props.children}
      </MuiLink>
    </Tooltip>
  );
};

export const useOpenWorkOrderPopUp = () => {
  const { openPanel } = useSidePanelState();
  return React.useCallback(
    (
      props: { id: number; Up?: boolean; useSidePanel?: boolean },
      openPanelWithCover?: (state: SidePanelOpenState) => void,
    ) => {
      let orderProps = {
        queryParams: {
          id: props.id.toString(),
          Up: (props.Up === true).toString(),
        },
        useSidePanel: true,
      } as WorkOrderDetailsProps;
      const panelState = {
        renderPageType: RenderPageType.WorkOrderDetails,
        renderPageProps: orderProps,
        expanded: false,
        useSidePanel: props.useSidePanel,
        isCover: props.useSidePanel,
      } as SidePanelOpenState;
      if (!!openPanelWithCover) {
        openPanelWithCover(panelState);
      } else {
        openPanel({
          renderPageType: RenderPageType.WorkOrderDetails,
          renderPageProps: orderProps,
          expanded: false,
          useSidePanel: false,
          isCover: false,
        } as SidePanelOpenState);
      }
    },
    [openPanel],
  );
};
export const useWorkOrderLinkUrl = () => {
  const publicUrl = useSelector(selectPublicUrl);
  return React.useCallback(
    (params: WorkOrderQueryStringParameters) => {
      return buildURL(publicUrl + 'workorders/details', params);
    },
    [publicUrl],
  );
};
