import { ICalendarMessagesDto } from 'app/pages/CalendarMessages/CalendarMessagesPage/ICalendarMessagesDto';
import { AsyncDataState, Entity } from 'types/common';
import { IResponseType } from 'types/ResponseType';
import { dateUtils } from 'utils/date-utils';
import { toEntity } from 'utils/entity-utils';

/* --- STATE --- */
export interface CalendarMessagesState {
  createState: AsyncDataState<CalendarMessagesDetailsState>;
  updateState: AsyncDataState<CalendarMessagesDetailsState>;
  processing: boolean;
}

export interface CalendarMessagesDetailsState {
  Id: number;
  ServiceGroups: Entity<number>[];
  Equipment: Entity<number> | null;
  Title: string;
  StartDate?: Date | null;
  EndDate?: Date | null;
  PlantDate?: Date | null;
  Body?: string | null;
  Description: string | null;
  DisplaySeconds: number | null;
  ShowOn?: string | null;
  CreatedBy?: Entity<string> | null;
  CreatedAt?: Date | null;
  UpdatedAt?: Date | null;
}

export interface CalendarMessagesDetailsQStringParameters {
  id?: string;
  servGrId?: string;
  servGr?: string;
  eqId?: string;
  eq?: string;
  title?: string;
  sDate?: string;
  eDate?: string;
  plantDate?: string;
  body?: string;
  desc?: string;
  dispSec?: string;
}

export interface CalendarMessagesDetailsResponse extends IResponseType {
  Id?: number;
}

export function ConvertModelToEntityCalendarMessagesDetails(
  entity: ICalendarMessagesDto,
): CalendarMessagesDetailsState | undefined {
  if (!entity) {
    return undefined;
  } else {
    return {
      Id: entity.Id,
      ServiceGroups: entity.ServiceGroups ?? [],
      Equipment:
        entity.EquipmentId !== null
          ? toEntity(entity.EquipmentId, entity.Equipment)
          : null,
      Title: entity.Title,
      /*       StartDate: entity.StartDate ?? new Date(),
      EndDate:
        entity.EndDate ??
        new Date(new Date().setHours(new Date().getHours() + 1)), */
      StartDate:
        entity.StartDate === null
          ? null
          : dateUtils.formatISO(
              dateUtils.parseISO(entity.StartDate ?? new Date()),
            ),
      EndDate:
        entity.EndDate === null
          ? null
          : dateUtils.formatISO(
              dateUtils.parseISO(
                entity.EndDate ??
                  new Date(new Date().setHours(new Date().getHours() + 1)),
              ),
            ),
      PlantDate: entity.PlantDate,
      Body: entity.Body,
      Description: entity.Description,
      DisplaySeconds: entity.DisplaySeconds ?? 60,
      ShowOn: entity.ShowOn,
      CreatedBy: entity['CreatedBy'],
      CreatedAt: entity['CreatedAt'],
      UpdatedAt: entity['UpdatedAt'],
    } as CalendarMessagesDetailsState;
  }
}

export function ConvertEntityToModelCalendarMessagesDetails(
  model: CalendarMessagesDetailsState,
  original: CalendarMessagesDetailsState | undefined,
): ICalendarMessagesDto {
  let entity = {
    Id: original?.Id === undefined ? model.Id : original.Id,
    ServiceGroups:
      model.ServiceGroups.map(f => ({ Id: f.Id, Name: f.Name })) ?? [],
    EquipmentId: model.Equipment?.Id,
    Equipment: model.Equipment?.Name,
    Title: model.Title,
    StartDate:
      model.StartDate === null || model.StartDate === undefined
        ? null
        : dateUtils.formatISO(dateUtils.dateOrStringToDate(model.StartDate)),
    //model.StartDate,
    EndDate:
      model.EndDate === null || model.EndDate === undefined
        ? null
        : dateUtils.formatISO(dateUtils.dateOrStringToDate(model.EndDate)),
    //model.EndDate,
    PlantDate:
      model.PlantDate === null || model.PlantDate === undefined
        ? null
        : dateUtils.formatISO(dateUtils.dateOrStringToDate(model.PlantDate)),
    //model.PlantDate,
    Body: model.Body,
    Description: model.Description,
    DisplaySeconds: model.DisplaySeconds ?? 60,
    ShowOn: model.ShowOn,
  } as ICalendarMessagesDto;
  return entity;
}
