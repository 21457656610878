import { useTheme } from '@material-ui/core';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icon } from '../BasicIcons/FontAwesome';
import { Body } from '../Typography';

export interface ScannerViewProps {
  description?: React.ReactNode;
  Input?: React.ReactNode;
}

const StyledScanView = styled('div')(({ theme }) => ({
  '&.scanner-root': {
    width: '100%',
    minHeight: '144px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px',
    gap: 8,
    background: theme.palette.common.white,
    border: '1px solid #026AA2',
    boxShadow: '0px 0px 0px 4px #E2F6FF',
    borderRadius: 8,
    '& .scanner-icon': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: 0,
      gap: 8.75,
    },
    '& .scanner-input': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      paddingInline: '16px',
      gap: 8,
      [theme.breakpoints.down('sm')]: {
        paddingInline: '0',
      },
      '& .input-wrap': {
        display: 'flex',
        width: 'auto',
        gap: '16px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        '& .text-input': {
          flexShrink: 1,
        },
      },
    },
    '&.selectRoot': {
      padding: theme.spacing(2),
    },
    '&.selectTitle': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '8px 8px',
      justifyContent: 'space-between',
      width: '100%',
      '&>label': {
        width: '100%',
        textAlign: 'center',
      },
    },
  },
}));
export function ScannerView(props: ScannerViewProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <StyledScanView className={'scanner-root'} theme={theme}>
      <div className={'scanner-icon'}>
        <Icon icon="barcode" size="3x" />
      </div>
      <Body color="secondary">
        {props.description || t(translations.BarcodeScannViewDescription)}
      </Body>
      {props.Input && (
        <div className={'scanner-input'}>
          <Body color="secondary">{'or'}</Body>
          {props.Input}
        </div>
      )}
    </StyledScanView>
  );
}
