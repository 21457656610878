import { ImportSamplePlateForm } from '../PopupImport';
import { useDispatch, useSelector } from 'react-redux';
import { useSamplesListSlice } from 'app/pages/Samples/SamplesListPage/slice';
import { ISampleCreateBase } from 'app/pages/Samples/SamplePlatesPage/slice/types';
import {
  selectImportingRunDetails,
  selectImportingRunDetailsFinished,
} from 'app/pages/Samples/SamplesListPage/slice/selectors';

export interface ImportSamplePlatesProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ImportSamplePlates(props: ImportSamplePlatesProps) {
  const { isOpen, onClose } = props;
  const dispatch = useDispatch();
  const { actions } = useSamplesListSlice();

  const importStarted = useSelector(selectImportingRunDetails);
  const importFinished = useSelector(selectImportingRunDetailsFinished);
  const handleConfirm = (item: ISampleCreateBase | null, file: any) => {
    if (file !== undefined) {
      dispatch(actions.setShowImportDialog(true));
      dispatch(actions.readImportFile({ file: file, plate: item }));
    }
  };
  return (
    <>
      {isOpen && importFinished === false && (
        <ImportSamplePlateForm
          onSubmit={handleConfirm}
          processing={importStarted}
          accept=".csv"
          onClose={() => onClose()}
          initialValues={{
            IsRack: false,
            ServiceGroup: undefined,
            SamplePlateType: undefined,
            Active: true,
          }}
        />
      )}
    </>
  );
}
