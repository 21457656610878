import { IAssetRow } from '../IAssetRow';
import React from 'react';
import { IAsset } from '../IAsset';
import { AssetDetailsFiles } from './AssetDetailsFiles';
import {
  FormLeftSection,
  FormRightSection,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import { GetRowsSections } from './Utils';
import { RenderLeftSection, RenderRightSection } from './RenderAssetRows';
import { useSelector } from 'react-redux';
import {
  selectGlobalServiceGroupFilter,
  selectGlobalSettingBool,
  selectIsAuthenticated,
} from 'app/slice/selectors';
import { AllowedSettings } from 'utils/globalSettings';
import { MismatchCore, MismatchCoreMessage } from './MismatchCoreMessage';
import { useAsyncGetWithEffect } from 'app/hooks/useAsyncGetOnMount';
import { httpClient } from 'api/HttpClient';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { IDocumentsDirectoryRow } from 'app/pages/Documents/IDocumentsDirectoryRow';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import BasicTypography from 'app/components/Typography/BasicTypography';

export interface AssetDetailsTabContentProps {
  rows: IAssetRow[];
  data: IAsset;
  loadingAdditionalData?: boolean;
  isAdmin: boolean;
  commentsForStaff?: boolean;
  shortView?: boolean;
  isCover?: boolean;
  info?: string;
}

export function AssetDetailsTabContent(props: AssetDetailsTabContentProps) {
  const { rows, data, shortView, isCover, info } = props;
  const { t } = useTranslation();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const topServiceGroupsIsHidden = useSelector(state =>
    selectGlobalSettingBool(state, AllowedSettings.HideTopServiceGroupFilter),
  );
  const selectedServiceGroups = useSelector(selectGlobalServiceGroupFilter);
  const updRows = React.useMemo(() => {
    return rows.map(f =>
      !!data.AssetImageNames &&
      data.AssetImageNames.length > 0 &&
      f.Id === 'ImageName'
        ? Object.assign({}, f, { Name: t(translations.ImageGallery) as string })
        : f,
    );
  }, [data.AssetImageNames, rows, t]);
  const sections = React.useMemo(() => {
    return GetRowsSections(updRows, data, shortView);
  }, [data, shortView, updRows]);
  const leftSections = React.useMemo(() => {
    return sections.leftSections;
  }, [sections]);
  const rightSections = React.useMemo(() => {
    return sections.rightSections;
  }, [sections]);
  // const getMobileRows = React.useCallback(() => {
  //   let res = leftSections;
  //   if (rightSections.length > 0 && rightSections[0].rows.length > 0) {
  //     res.unshift(...rightSections);
  //   }
  //   return res;
  // }, [leftSections, rightSections]);
  const mismatchCore = React.useMemo(() => {
    return MismatchCore(
      data,
      isAuthenticated,
      topServiceGroupsIsHidden,
      selectedServiceGroups,
    );
  }, [data, isAuthenticated, selectedServiceGroups, topServiceGroupsIsHidden]);

  //Determite if to render the documents table
  const predicates: Array<Condition<IDocumentsDirectoryRow> | string> =
    React.useMemo(() => {
      // The asset matches and the service group matches or there is only a service group and it matches.
      const res: Array<Condition<IDocumentsDirectoryRow> | string> = [];
      const assetPredicate = new Condition<IDocumentsDirectoryRow>(
        'AssetId',
        ODataOperators.Equals,
        data.Id,
      );
      const groupPredicate = new Condition<IDocumentsDirectoryRow>(
        'ServiceGroupId',
        ODataOperators.Equals,
        data.ServiceGroup?.Id,
      );
      if (
        !!data.AvailabilityType.Id &&
        data.AvailabilityType.Id === 'ServiceGroup'
      ) {
        res.push(
          `(${[assetPredicate.toString(), groupPredicate.toString()].join(
            ' or ',
          )})`,
        );
      } else {
        res.push(assetPredicate);
      }
      return res;
    }, [data.Id, data.ServiceGroup?.Id, data.AvailabilityType.Id]);

  const predicatesString: string = predicates
    .map(f => f.toString())
    .join(' and ');

  const documentsDataCount = useAsyncGetWithEffect<number>(
    async () => {
      return await httpClient.get('api/odata/v4/Documents/$count', {
        $filter: predicatesString,
      });
    },
    0,
    ['AssetId', 'ServiceGroupId'],
  );
  const isEmpty = React.useMemo(() => {
    return leftSections.length === 0;
  }, [leftSections.length]);
  return (
    <>
      <StyledFormFieldsContainer noPadding isCover={isCover}>
        <FormLeftSection isCover={isCover} info={info}>
          {shortView && (
            <MismatchCoreMessage data={data} mismatchCore={mismatchCore} />
          )}
          {isEmpty ? (
            <BasicTypography variant="bodyS">
              {t(translations.ThereAreNoAttributesInAssetDetails)}
            </BasicTypography>
          ) : (
            <RenderLeftSection sections={leftSections} data={data} />
          )}
          {!!data && isAuthenticated && documentsDataCount > 0 && (
            <AssetDetailsFiles isMobile={shortView} predicates={predicates} />
          )}
        </FormLeftSection>
        <FormRightSection isCover={isCover} useTopSpacing={false}>
          {!shortView && (
            <MismatchCoreMessage data={data} mismatchCore={mismatchCore} />
          )}

          <RenderRightSection sections={rightSections} data={data} />
        </FormRightSection>
      </StyledFormFieldsContainer>

      {/*TODO: should be implemented mobile floating comment component and separate input from comments section
      Jira task BKT-9685*/}
      {/* {isMobile && (
        <AssetMobileComments
          isAdmin={isAdmin}
          assetId={data.Id}
          staffonly={commentsForStaff || false}
        />
      )} */}
    </>
  );
}
