/**
 *
 * FilterContainer
 *
 */
import * as React from 'react';

import { DetectIsMobile } from 'utils/mobileDetect';
import { Popper } from 'app/components/Popper';
import { useTranslation } from 'react-i18next';
import { IFilterSettings } from '../IFilterSettings';
import { translations } from 'locales/translations';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import useGlobalDOMEvents from 'app/hooks/useGlobalDomEvents';
import { IsEmptyArray } from '../AppliedFilterComponent';
import {
  FilterRoot,
  StyledDialogContent,
  StyledDialog,
  StyledDialogTitle,
  StyledIconButton,
  StyledContentWrapper,
  StyledPaper,
} from '../styled';

interface FilterContainerProps<TRow> {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  anchorEl: any;
  appliedFilters: IFilterSettings<TRow>[];
  onChange: (items: IFilterSettings<TRow>[]) => void;
  compact?: boolean;
  ns?: string;
}

// const StyledDialogActions = styled(DialogActions)`
//   justify-content: space-between;
// `;

export function FilterContainer<TRow>({
  anchorEl,
  onClose,
  onChange,
  open,
  children,
  compact,
  ns,
  ...props
}: FilterContainerProps<TRow>) {
  const { t } = useTranslation(ns);
  const isMobile = DetectIsMobile();
  const handleClose = () => {
    onClose();
  };
  // save provided applied filters to be used in change callbacks
  const [, setVisibleFilters] = React.useState<IFilterSettings<TRow>[]>(
    props.appliedFilters,
  );

  // refresh applied filters if they were changed from outside
  React.useEffect(() => {
    setVisibleFilters(props.appliedFilters);
  }, [props.appliedFilters]);

  // const handleClearClick = () => {
  //   setVisibleFilters(currentState => {
  //     const newState = currentState.map(f => ({ ...f, ...{ value: null } }));
  //     onChange(newState);
  //     return newState;
  //   });
  //   if (isMobile || compact) {
  //     onClose();
  //   }
  // };
  const elementRef = React.useRef<HTMLDivElement>(null);
  // manually trigger rerender using a useless usState hook
  // this ensures that the popper is rendered only after the div is rendered and have provided the elementRef value to ancor the popper
  const countState = React.useState(0);
  React.useEffect(() => {
    countState[1](c => c + 1);
    // setCount (rerender) needs to occur after element elementRef.current value is bound
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current]);
  const appliedFitltersCount = props.appliedFilters.filter(
    f =>
      f.value !== null &&
      f.value !== undefined &&
      f.value !== '' &&
      !IsEmptyArray(f.value),
  ).length;

  useGlobalDOMEvents({
    keyup(ev) {
      if ((ev as KeyboardEvent).key === 'Escape') {
        onClose();
      }
    },
  });

  const title = `${t(translations.Filters)} (${appliedFitltersCount})`;
  if (isMobile || compact) {
    return (
      <StyledDialog
        fullScreen={isMobile}
        open={open}
        compact={compact}
        onClose={handleClose}
        PaperComponent={StyledPaper}
        BackdropProps={{
          invisible: true,
        }}
      >
        <StyledDialogTitle>
          {title}
          <StyledIconButton
            aria-label="close"
            variant="ghost"
            size="small"
            shape="square"
            onClick={() => {
              onClose();
            }}
          >
            <Icon icon="times" />
          </StyledIconButton>
        </StyledDialogTitle>
        <StyledDialogContent isMobile={isMobile}>
          <StyledContentWrapper>{children}</StyledContentWrapper>
        </StyledDialogContent>
        {/* <StyledDialogActions>
          <ButtonsContainer className={'buttons-container'}>
          <Button
            size="small"
            color="primary"
            onClick={() => onClose()}
            aria-label={t(translations.apply)}
          >
            {t(translations.apply)}
          </Button>
         
          <Button
            size="small"
            color="primary"
            onClick={handleClearClick}
            aria-label={t(translations.ClearAll)}
          >
            {t(translations.ClearAll)}
          </Button>
          </ButtonsContainer>
        </StyledDialogActions> */}
      </StyledDialog>
    );
  } else {
    return (
      <>
        <Popper
          id="basic-filter-group-popover"
          open={open}
          anchorEl={anchorEl}
          title={title}
          onClose={() => onClose()}
          clickAway={true}
          clickAwayFunc={e => {
            onClose();
          }}
          maxWidth={'fit-content'}
        >
          <FilterRoot
            className={'filter-root'}
            ref={elementRef}
            role="presentation"
          >
            <>{children}</>
          </FilterRoot>
        </Popper>
      </>
    );
  }
}
