/**
 *
 * Popper
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { translations } from 'locales/translations';
import styled from 'styled-components';
import { Body } from '../Typography';

export interface PopperTitleProps {
  title: React.ReactNode;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  closeButtonTitle?: string;
}

const PopperTitleRoot = styled.div`
  &.title-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    justify-content: space-between;
    width: 100%;

    & > label {
      width: 100%;
      text-align: center;
    }
    & .close-icon {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }
`;

export function PopperTitle({ title, onClose, ...props }: PopperTitleProps) {
  const { t } = useTranslation();

  const closeTitle = props.closeButtonTitle ?? t(translations.Close);
  return (
    <PopperTitleRoot className={'title-root'}>
      <div>
        <Body size="small" bold={true}>
          {title}
        </Body>
        <IconButton
          size="xs"
          aria-label="Close"
          onClick={onClose}
          className="close-icon"
        >
          <Tooltip title={closeTitle}>
            <span>
              <Icon icon="times" color="default" colorExtend="textHover" />
            </span>
          </Tooltip>
        </IconButton>
      </div>
    </PopperTitleRoot>
  );
}
