import { BookitlabLogo } from 'app/components/BookitlabLogo';
import { BookitlabShortLogo } from 'app/components/BookitlabLogo/BookitlabShortLogo';
import { selectMinimizedBookitlabLogo } from 'app/slice/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { DetectIsMobile } from 'utils/mobileDetect';
import { CustomerLogo } from '../../CustomerLogo';
import clsx from 'clsx';
import { ToggleSideMenuButton } from '../../TopBar/components/ToggleSideMenuButton';
import { UserProfileMobile } from '../UserProfileMobile';

export interface SideBarHeaderProps {
  children?: React.ReactNode;
  isOpen: boolean;
}

export const SideBarHeader = React.memo(function SideBarHeader(
  props: SideBarHeaderProps,
) {
  const { children, isOpen } = props;
  const isMobile = DetectIsMobile();
  const logoMinimized = useSelector(selectMinimizedBookitlabLogo);
  return (
    <React.Fragment>
      <div
        className={clsx('sideBarHeader', {
          sideBarHeaderOpen: isOpen,
          sideBarHeaderMobile: isMobile,
        })}
      >
        {isMobile ? (
          <CustomerLogo size="sidebarLogoSize" />
        ) : isOpen && !logoMinimized ? (
          <BookitlabLogo size="80x24" />
        ) : (
          <BookitlabShortLogo />
        )}
        <div
          className={clsx('headerButtons', { headerButtonsMobile: isMobile })}
        >
          {isMobile && isOpen && <UserProfileMobile />}
          {isOpen && !isMobile && <ToggleSideMenuButton />}
        </div>
        {children}
      </div>
    </React.Fragment>
  );
});
