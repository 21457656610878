import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { getLogger } from 'utils/logLevel';
import { toRootedURL } from 'utils/url-utils';

export interface useScriptTagProps {
  /**
   * Script source - can be false or undefined for conditionally loaded scripts.
   * For example useScriptTag({ src: enable && '/Script/foo.js' })
   * useScriptTag({ src: enable ? '/Script/foo.js' : undefined })
   */
  src?: string | false;
  /**
   * Removes the script when the parent unmounts.
   * Default = true. Should be set to false for components that rapidly mount and remount for whatever reason.
   */
  cleanup?: boolean;
  /**
   * Async script element property. Default = true.
   */
  async?: boolean;
}

const log = getLogger('useScriptTag');

/**
 * Appends script tags to the document - useful for dynamic load of legacy scripts that live in the browser like dymo printer framework, etc.
 * Alternatively such scripts can be converted to esm and loaded in a more conservative way without additional hoops.
 * @param param0
 */
export function useScriptTag({
  src,
  cleanup = true,
  async = true,
}: useScriptTagProps) {
  useEffectOnMount(() => {
    if (src === undefined) {
      return;
    }
    if (typeof src === 'boolean') {
      return;
    }
    const id = encodeURIComponent(src);
    let script: HTMLScriptElement | null = document.getElementById(
      id,
    ) as HTMLScriptElement;
    if (script === null) {
      script = document.createElement('script');
      script.src = toRootedURL(src);
      script.id = id;
      script.async = async;
      try {
        document.body.appendChild(script);
        log.debug('appended script: ' + src);
      } catch (error) {
        log.error(error);
      }
    }
    return () => {
      if (cleanup && script !== null) {
        document.body.removeChild(script);
        console.debug('removed script: ' + script.src);
      }
    };
  });
}
