/**
 *
 * BudgetsRestrictedRoute
 *
 */
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import {
  selectAuthenticatedUser,
  selectSiteMapProvider,
} from 'app/slice/selectors';

import React from 'react';
import { useSelector } from 'react-redux';
import { RouteProps, Redirect, Route } from 'react-router-dom';

export function BudgetsRestrictedRoute(
  routeProps: RouteProps & { globalSettings?: GlobalSettingsType },
): React.ReactElement {
  const User = useSelector(selectAuthenticatedUser);
  const siteMapProvider = useSelector(selectSiteMapProvider);

  const { component: Component, ...rest } = routeProps;

  const render = propss => {
    let allowedNode: boolean = siteMapProvider.isBudgetsAccessible(
      User,
      routeProps.globalSettings,
    );
    if (allowedNode === false) {
      return <Redirect to={'/notavailable'} />;
    } else if (allowedNode === true) {
      return <Route component={routeProps.component} {...routeProps} />;
    } else {
      return undefined;
    }
  };
  return <Route {...rest} render={render} />;
}
