import { selectGlobalSetting } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import { AllowedSettings } from 'utils/globalSettings';
import { AllowManualChargesOutsideInvoice } from './index';

export function useAllowManualChargesOutsideInvoices(): AllowManualChargesOutsideInvoice {
  return useSelector(
    state =>
      (selectGlobalSetting(
        state,
        AllowedSettings.AllowManualChargesOutsideInvoice,
      ) as AllowManualChargesOutsideInvoice) ?? 'DoNotAllowManualCharges',
  );
}
