/**
 *
 * Asynchronously loads the component for SystemSettingsAuditTrailPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SystemSettingsAuditTrailPage = lazyLoad(
  () => import('./index'),
  module => module.SystemSettingsAuditTrailPage,
);
