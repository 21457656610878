import { TFunction } from 'i18next';
import { Column } from 'react-table';
import { AppSettings } from 'types/AppSettings';
import { GlobalSettings } from 'utils/globalSettings';
import { ITrainingSessionsRow } from './ITrainingSessionsRow';
import DateTimeRenderer from 'app/components/BasicTable/CellRenderers/DateTimeRenderer';
import { translations } from 'locales/translations';
import UsedSlotsRenderer from 'app/components/BasicTable/CellRenderers/UsedSlotsRenderer';
import AssetRenderer from 'app/components/BasicTable/CellRenderers/AssetRenderer';
import TrainingStateRenderer from 'app/components/BasicTable/CellRenderers/TrainingStateRenderer';

type RowType = ITrainingSessionsRow;
export const getColumns = (
  t: TFunction,
  appSettings?: AppSettings,
  globalSettings?: GlobalSettings,
): Column<RowType>[] => {
  return [
    {
      Header: t(translations.Signups) as string,
      accessor: 'TrainingState',
      Cell: TrainingStateRenderer,
    },
    {
      Header: t(translations.TrainingResource) as string,
      accessor: 'AssetName',
      Cell: AssetRenderer,
    },
    // {
    //   Header: t(translations.NextAvailableSession) as string,
    //   accessor: 'NextSession',
    //   Cell: NextSessionRenderer,
    // },
    // {
    //   Header: t(translations.AllUpcomingSessions) as string,
    //   accessor: 'AllUpcomingSessions',
    //   Cell: AllUpcomingSessionsAssetRenderer,
    // },
    {
      Header: t(translations.DurationH) as string,
      accessor: 'Duration',
    },
    {
      Header: t(translations.Campus) as string,
      accessor: 'Campus',
    },
    {
      Header: t(translations.Building) as string,
      accessor: 'Building',
    },
    {
      Header: t(translations.Room) as string,
      accessor: 'Room',
    },
    {
      Header: t(translations.MinimumAttendance) as string,
      accessor: 'MinAttendance',
    },
    {
      Header: t(translations.MaximumAttendance) as string,
      accessor: 'MaxAttendance',
    },
    {
      Header: t(translations.TotalUsedSlots) as string,
      accessor: 'TotalUsedSlots',
      Cell: UsedSlotsRenderer,
    },
    {
      Header: t(translations.RemainedSlots) as string,
      accessor: 'RemainedSlots',
    },
    {
      Header: t(translations.ServiceGroup) as string,
      accessor: 'ServiceGroup',
    },
    {
      Header: t(translations.HashSymbol) as string,
      accessor: 'Id',
    },
    {
      Header: t(translations.Remarks) as string,
      accessor: 'Remarks',
    },
    {
      Header: t(translations.StartTime) as string,
      accessor: 'StartDate',
      Cell: DateTimeRenderer,
    },
    {
      Header: t(translations.EndTime) as string,
      accessor: 'EndDate',
      Cell: DateTimeRenderer,
    },
    {
      Header: t(translations.TrainerName) as string,
      accessor: 'TrainerName',
    },
  ];
};
