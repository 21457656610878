import { httpClient } from 'api/HttpClient';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { quoteODataValue } from 'api/odata/ODataFilter';
import {
  BaseURLPicker,
  BaseURLPickerProps,
} from 'app/components/BasicPickers/BaseURLPicker';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import { OfflineReferenceTypeEnum } from 'enums/OfflineReferenceTypeEnum';
import { Entity } from 'types/common';

export interface ServiceShortPickerprops
  extends Omit<BaseURLPickerProps<Entity<number>>, 'url'> {}
const url = '/api/odata/v4/ServiceShortFilter';

export const ServiceShortPicker = (props: ServiceShortPickerprops) => {
  return <BaseURLPicker url={url} {...props} />;
};

export const InitServiceShortData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name,TypeId,ServiceGroupId',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
export const InitServiceShortDataByRefType =
  (refTypeId?: number) => (initval: string | undefined) => {
    if (initval === undefined) {
      return new Promise<Entity<number>[]>((resolve, reject) => {
        resolve([] as Entity<number>[]);
      });
    } else {
      const id = parseInt(initval);
      let innerFilter = '';
      if (refTypeId === OfflineReferenceTypeEnum.Offline_ServiceRequest) {
        innerFilter = `(TypeId eq  ${quoteODataValue(
          ServiceType.Sample as number,
        )} and Id eq ${id})`;
      } else {
        innerFilter = `(TypeId eq  ${quoteODataValue(
          ServiceType.Online as number,
        )} and Id eq ${id})`;
      }
      const params: {
        $orderby: string;
        $filter?: string;
        $select: string;
        $top: number;
        $skip: number;
      } = {
        $orderby: 'Name asc',
        $filter: innerFilter,
        $select: 'Id,Name,TypeId,ServiceGroupId',
        $skip: 0,
        $top: 1,
      };
      return httpClient
        .get(url, params)
        .then(response => response.value as Entity<number>[]);
    }
  };
export const ServiceByOfflineRefernceFilter = (value: FilterValueType) => {
  if (value !== undefined && value !== null) {
    if (
      (value as Entity<number>).Id ===
      OfflineReferenceTypeEnum.Offline_ServiceRequest
    ) {
      return [`TypeId eq ${quoteODataValue(ServiceType.Sample as number)}`];
    } else {
      return [`TypeId eq ${quoteODataValue(ServiceType.Online as number)}`];
    }
  }
  return [];
};
