import { Theme } from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';
import { BasicDialog } from 'app/components/BasicDialog';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import { FormRow } from 'app/components/Forms/FormsLayout';
import { InvoiceStatuses } from 'enums/InvoiceStatuses';
import { Form, Formik } from 'formik';
import { translations } from 'locales/translations';
import { uniqueId } from 'lodash';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectInvoice } from '../Slice/selector';
import * as yup from 'yup';
import { removeHtml } from 'utils/string-utils';
import { CommentsApi } from 'api/CommentsApi';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { httpClient } from 'api/HttpClient';
import { InvoiceDetailsUrl } from '..';
import { useInvoiceDetailsSlice } from '../Slice';
import { useAppSettingsSlice } from 'app/slice';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { Roles } from 'api/odata/generated/enums/Roles';
import { Body } from 'app/components/Typography';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

const StyledDiv = styled.div<{
  theme: Theme;
}>`
  border: solid 1px ${props => props.theme.palette.divider};
  border-radius: 6px;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const InvoiceInReview = () => {
  const invoice = useSelector(selectInvoice);
  const { actions } = useInvoiceDetailsSlice();
  const { actions: notifActions } = useAppSettingsSlice();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const submitForm = useRef<any>();
  const theme = useTheme();
  if (!invoice || invoice.StatusId !== InvoiceStatuses.SentToReview)
    return <></>;
  const admin =
    authenticatedUser?.Roles.includes(Roles.Administrators) ||
    (authenticatedUser?.Roles.includes(Roles.GroupAdministrators) &&
      authenticatedUser.UserGroups.some(g => g.Id === invoice.UserGroupId));
  const schema: yup.SchemaOf<{ Text: string }> = yup.object({
    Text: yup
      .string()
      .test({
        name: 'not empty p',
        test: s => s !== undefined && removeHtml(s).trim() !== '',
        message: 'Text is required',
      })
      .required(),
  });

  return (
    <StyledDiv theme={theme}>
      <Body>{t(translations.InvoiceInReviewMessage)}</Body>
      {admin && (
        <>
          <Button
            onClick={() => {
              httpClient
                .post(InvoiceDetailsUrl + '/UpdateStatus', {
                  status: InvoiceStatuses.ReviewApproved,
                  id: invoice.Id,
                })
                .then(() => {
                  dispatch(actions.getInvoice(invoice.Id));
                  dispatch(
                    notifActions.addNotification({
                      variant: 'success',
                      message: t(translations.InvoiceApprovedMsg),
                    }),
                  );
                })
                .catch(e => console.log(e));
            }}
            size="small"
            variant="white"
          >
            {t(translations.Approve)}
          </Button>
          <Button onClick={() => setOpen(true)} size="small" variant="danger">
            {t(translations.Reject)}
          </Button>
          <BasicDialog
            title={t(translations.EnterInvoiceRejectReason)}
            body={
              <Formik
                initialValues={{ Text: undefined }}
                onSubmit={value => {
                  CommentsApi.SendComment(
                    CommentReferenceTypes.Invoice,
                    invoice.Id,
                    {
                      Text:
                        t(translations.InvoiceRejectReasonPrefix) + value.Text,
                    },
                  )
                    .then(() => {
                      httpClient
                        .post(InvoiceDetailsUrl + '/UpdateStatus', {
                          status: InvoiceStatuses.ReviewRejected,
                          id: invoice.Id,
                        })
                        .then(() => {
                          dispatch(actions.getInvoice(invoice.Id));
                        })
                        .catch(e => console.log(e));
                    })
                    .catch(console.error);
                  setOpen(false);
                }}
                validationSchema={schema}
              >
                {formik => {
                  submitForm.current = formik.submitForm;
                  return (
                    <Form onSubmit={formik.handleSubmit}>
                      <FormRow fullRow>
                        <FormRichTextField fullWidth autoFocus name="Text" />
                      </FormRow>
                    </Form>
                  );
                }}
              </Formik>
            }
            id={uniqueId()}
            isopen={open}
            onClose={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            leftActions={[
              () => (
                <Button onClick={() => submitForm.current()}>
                  {t(translations.Reject)}
                </Button>
              ),
            ]}
            rightActions={[
              () => (
                <Button onClick={() => setOpen(false)}>
                  {t(translations.Cancel)}
                </Button>
              ),
            ]}
          />
        </>
      )}
    </StyledDiv>
  );
};
