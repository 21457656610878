import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.samplesList || initialState;

export const selectSamplesList = createSelector([selectSlice], state => state);

export const selectSamplesTableState = createSelector(
  [selectSlice],
  state => state.SamplesListTableState,
);
export const selectSamplesData = createSelector(
  [selectSamplesList],
  state => state.SamplesList,
);
export const selectSelectedRunType = createSelector(
  [selectSlice],
  state => state.SelectedRunType,
);
export const selectRunDetails = createSelector(
  [selectSamplesList],
  state => state.RunDetails,
);
export const selectShowRunDialog = createSelector(
  [selectSamplesList],
  state => state.ShowRunDialog,
);
export const selectSelectedSamples = createSelector(
  [selectSamplesList],
  state => state.SamplesSelected,
);
export const selectSamplesListEditMode = createSelector(
  [selectSamplesList],
  state => state.EditMode,
);
export const selectImportingRunDetails = createSelector(
  [selectSamplesList],
  state => state.ImportStarted,
);
export const selectImportingRunDetailsFinished = createSelector(
  [selectSamplesList],
  state => state.ImportFinished,
);
export const selectRunDetailsInstrument = createSelector(
  [selectSamplesList],
  state => state.RunDetails?.Instrument?.Name,
);
export const selectRunDetailsSecondaryInstrument = createSelector(
  [selectSamplesList],
  state => state.RunDetails?.SecondaryInstrument?.Name,
);
export const selectRunDetailsGradient = createSelector(
  [selectSamplesList],
  state => state.RunDetails?.Gradient?.Name,
);
export const selectRunDetailsCreatedDate = createSelector(
  [selectSamplesList],
  state => state.RunDetails?.CreateDate,
);
export const selectRunDetailsAcquision_type = createSelector(
  [selectSamplesList],
  state => state.RunDetails?.ACQUISITION_TYPE,
);
export const selectRunDetailsCompletion = createSelector(
  [selectSamplesList],
  state => state.Completion,
);
