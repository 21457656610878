import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { downloadFile } from 'utils/url-utils';
import { useDispatch } from 'react-redux';
import { useAppSettingsSlice } from 'app/slice';
import { LinearProgress, Link } from '@material-ui/core';
import styled from 'styled-components';

interface DownloadFileButonProps {
  id: string;
  name?: React.ReactNode;
}

export function DownloadFileButon(props: DownloadFileButonProps) {
  const { t } = useTranslation();
  const url = `/file.ashx?id=${props.id}&ap=true`;
  const [busy, setBusy] = React.useState(false);
  const dispatch = useDispatch();
  const { actions } = useAppSettingsSlice();
  const handleClick = React.useCallback(
    async event => {
      event.preventDefault();
      if (busy) {
        return;
      }
      setBusy(true);
      try {
        await downloadFile({ path: url });
        setBusy(false);
      } catch (error) {
        setBusy(false);
        dispatch(
          actions.addNotification({
            variant: 'error',
            message: t(translations.Error_FileNotAvailable),
          }),
        );
      }

      return false;
    },
    [actions, busy, dispatch, t, url],
  );
  if (!busy) {
    return (
      <Link component="a" href={url} variant="body1" onClick={handleClick}>
        {props.name ?? t(translations.Download)}
      </Link>
    );
  } else {
    return (
      <>
        <Link component="label" variant="body1" onClick={() => {}}>
          {props.name ?? t(translations.Download)}
        </Link>
        <Div100>
          <LinearProgress />
        </Div100>
      </>
    );
  }
}
export const Div100 = styled.div`
  width: 100%;
`;
