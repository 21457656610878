import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { PrimaryActionDynamic } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { ReservationDetailsProps } from '../ReservationDetails/Details';
import { ReservationQueryStringParameters } from '../ReservationDetails/Details/slice/types';

export const reservationsPrimaryAction: PrimaryActionDynamic<{
  Id: number;
}> = (openPanel, row, toggle, user, useSidePanel) => {
  const params = {
    id: String(row.original.Id),
    un: user?.Id,
  } as ReservationQueryStringParameters;
  openPanel({
    renderPageType: RenderPageType.ReservationDetails,
    renderPageProps: {
      useSidePanel: true,
      queryParams: params,
    } as ReservationDetailsProps,
    useSidePanel: useSidePanel,
    expanded: false,
    isCover: useSidePanel,
  });
};

export default reservationsPrimaryAction;
