import { UserAttributesDetailsQStringParameters } from 'app/pages/UserAttributes/UserAttributesDetailsPage/Details/slice/types';
import { IResponseType } from 'types/ResponseType';
import { httpClient } from './HttpClient';
import { IUserAttributesDto } from './odata/generated/entities/IUserAttributesDto';

export const UserAttributesApi = {
  initCreateData: (payload: UserAttributesDetailsQStringParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/UserAttributes/InitUserAttributes';
    return httpClient.post(url, data);
  },
  initUpdateData: (payload: UserAttributesDetailsQStringParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/UserAttributes/InitUserAttributes';
    return httpClient.post(url, data);
  },
  insertUserAttributesDetails: (payload: IUserAttributesDto) => {
    const url = '/api/odata/v4/UserAttributes'; //POST action in Controller
    return httpClient.post(url, payload);
  },
  updateUserAttributesDetails: (payload: IUserAttributesDto) => {
    const url = `/api/odata/v4/UserAttributes(${payload.Id})`; //PUT action in Controller
    return httpClient.put(url, payload);
  },
  deleteUserAttributesDetails: (
    rows: IUserAttributesDto[],
  ): Promise<IResponseType> => {
    const userAttributesIds: number[] = rows.map(item => item.Id);
    const data = { userAttributesIds };
    const url = '/api/odata/v4/UserAttributes/DeleteUserAttributes';
    return httpClient.post(url, data);
  },
};
