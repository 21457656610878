import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { IServiceRequestTicketDto } from 'api/odata/generated/entities/IServiceRequestTicketDto';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  FormFieldsSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormUserPicker } from 'app/components/Forms/FormUserPicker';
import { FormPriorityPicker } from 'app/components/Forms/FormPriorityPicker';
import { FormServiceRequestStatusPicker } from 'app/components/Forms/FormServiceRequestStatusPicker';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IServiceRequestTicketEdit } from 'app/pages/ServiceRequestTicketDetailsPage/ServiceRequestTicketDetails/schema';

interface FormProps {
  schema: Yup.SchemaOf<IServiceRequestTicketEdit>;
  initialValues: IServiceRequestTicketEdit;
  item: Partial<IServiceRequestTicketDto>;
  onSubmit: (values: IServiceRequestTicketEdit) => void;
  onCancel: () => void;
  processing: boolean;
}
export const Form = ({
  schema,
  initialValues,
  onSubmit,
  onCancel,
  processing,
  item,
}: FormProps) => {
  const { t } = useTranslation();

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {formik => (
        <StyledForm onSubmit={formik.handleSubmit}>
          <FormFieldsSection>
            <FormRow>
              <FormServiceRequestStatusPicker
                name="Status"
                id="Status"
                label={t(translations.Status)}
              />
            </FormRow>
            <FormRow>
              <FormUserPicker
                name="AssignedTo"
                id="AssignedTo"
                label={t(translations.AssignedTo)}
                fullWidth
              />
            </FormRow>
            <FormRow>
              <FormPriorityPicker
                name="Priority"
                id="Priority"
                label={t(translations.Priority)}
                fullWidth
              />
            </FormRow>

            <FormRow>
              <Button
                type="submit"
                startIcon={<Icon icon="save" />}
                disabled={processing}
                processing={processing}
              >
                {t(translations.Submit)}
              </Button>
              <Button
                startIcon={<Icon icon="times" />}
                color="default"
                onClick={onCancel}
                disabled={processing}
                variant="ghost"
              >
                {t(translations.Cancel)}
              </Button>
            </FormRow>
          </FormFieldsSection>
        </StyledForm>
      )}
    </Formik>
  );
};
