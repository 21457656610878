import { IUserTrainingDto } from 'api/odata/generated/entities/IUserTrainingDto';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import DateRenderer from 'app/components/BasicTable/CellRenderers/DateRenderer';
import { BooleanRender } from 'app/components/BasicTable/components/BooleanRender';
import { DescriptionCell } from 'app/components/BasicTable/components/DescriptionCell';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { canEditReservation } from 'app/permissions/Reservations/reservationPermissions';
import { allowedToViewUserTraining } from 'app/permissions/UserTrainings/userTrainingPermissionUtils';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Column } from 'react-table';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { dateUtils } from 'utils/date-utils';
import { ReservationLink } from '../Actions/GlobalSelectedActions/OpenReservation';
import { UserTrainingLink } from '../Actions/GlobalSelectedActions/OpenTraining';
import { AssetLink } from '../AssetPopUp/OpenAssetDetails';
import { UserProfileLink } from '../UserDetails/OpenUserProfile';

export const getColumns = (
  t: TFunction,
  user?: AuthenticatedUser,
  useSidePanel?: boolean,
  openPanel?: (state: SidePanelOpenState) => void,
): Column<IUserTrainingDto>[] => {
  return [
    {
      Header: () => (
        <>
          {t(translations.Attachment) as string} <Icon icon="paperclip" />
        </>
      ),
      accessor: 'AttachmentExist',
      Cell: ({ value, row }) => (
        <>
          {value === true ? (
            <Tooltip title={t(translations.AttachmentExist) as string}>
              <Icon icon="paperclip" />
            </Tooltip>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      Header: t(translations.Id) as string,
      accessor: 'Id',
      Cell: ({ value, row }) => {
        return allowedToViewUserTraining(user, row.original) ? (
          <UserTrainingLink
            id={row.original.Id}
            useSidePanel={useSidePanel}
            openPanelWithCover={openPanel}
          >
            {value}
          </UserTrainingLink>
        ) : (
          <>{value}</>
        );
      },
    },
    {
      Header: t(translations.User) as string,
      accessor: 'UserId',
      Cell: ({ value, row }) => (
        <UserProfileLink
          userName={row.original.UserId ?? undefined}
          displayName={row.original.UserDisplayName ?? undefined}
          useSidePanel={useSidePanel}
          openPanelWithCover={openPanel}
        />
      ),
    },
    {
      Header: t(translations.UserDisplayName) as string,
      accessor: 'UserDisplayName',
    },
    {
      Header: t(translations.ServiceGroup) as string,
      accessor: 'ServiceGroupName',
    },
    {
      Header: t(translations.Instrument) as string,
      accessor: 'EquipmentName',
      Cell: ({ value, row }) => {
        return !!row.original.AssetId ? (
          <AssetLink
            id={row.original.AssetId}
            useSidePanel={useSidePanel}
            openPanelWithCover={openPanel}
          >
            {value}
          </AssetLink>
        ) : (
          <>{value}</>
        );
      },
    },
    {
      Header: t(translations.TrainingDate) as string,
      accessor: 'TrainingDate',
      Cell: DateRenderer,
    },
    {
      Header: t(translations.Trainer) as string,
      accessor: 'TrainerName',
      Cell: ({ value, row }) => {
        if (row.original.TrainerName) {
          return (
            <>
              <UserProfileLink
                userName={row.original.TrainerId ?? undefined}
                displayName={row.original.TrainerName ?? undefined}
                useSidePanel={useSidePanel}
                openPanelWithCover={openPanel}
              />
            </>
          );
        } else {
          return <span>{t(translations.NA) as string}</span>;
        }
      },
    },
    {
      Header: t(translations.TrainingLevel) as string,
      accessor: 'TrainingLevelName',
    },
    {
      Header: t(translations.Reservation) as string,
      accessor: 'ReservationId',
      Cell: ({ value, row }) => {
        return canEditReservation(user, {
          ServiceGroupId: row.original.ServiceGroupId ?? 0,
          ServiceId: row.original.EquipmentId ?? 0,
          ServiceTypeId: ServiceType.Online,
          UserId: user?.Id ?? null,
        }) && !!value ? (
          <ReservationLink
            id={value}
            useSidePanel={useSidePanel}
            openPanelWithCover={openPanel}
          >
            {value}
          </ReservationLink>
        ) : (
          <>{value}</>
        );
      },
    },
    {
      Header: t(translations.RequiresAdminApproval) as string,
      accessor: 'RequiresAdminApproval',
      Cell: ({ value }) => <BooleanRender value={value} />,
    },
    {
      Header: t(translations.ExpiredDate) as string,
      accessor: 'ExpiredDate',
      Cell: ({ value, row }) => {
        return value !== null && !!value ? (
          <>{dateUtils.shortDateFormat(value)}</>
        ) : (
          <></>
        );
      },
    },
    {
      Header: t(translations.Remarks) as string,
      accessor: 'Remarks',
      Cell: DescriptionCell({ width: 'medium' }),
    },
    {
      Header: t(translations.Active) as string,
      accessor: 'Active',
      Cell: ({ value }) => <BooleanRender value={value} />,
    },
    {
      Header: t(translations.AssetCatGroupName) as string,
      accessor: 'AssetCatGroupName',
    },
    {
      Header: t(translations.AssetCatName) as string,
      accessor: 'AssetCatName',
    },
    {
      Header: t(translations.UserGroupName) as string,
      accessor: 'UserGroupName',
    },
    {
      Header: t(translations.Institute) as string,
      accessor: 'InstituteName',
    },
    {
      Header: t(translations.Campus) as string,
      accessor: 'CampusName',
    },
  ];
};
