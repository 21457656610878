import { EmailTypeUnion } from 'api/odata/generated/enums/EmailType';
import { buildURL } from 'utils/url-utils';
import { NOTIFICATIONS_LOG_PAGE_PATH } from '.';

export const getNotificationsLogPageLocation = (props: {
  NotificationTypeId: EmailTypeUnion;
  ReferenceId: number | undefined;
}): string => {
  return buildURL(NOTIFICATIONS_LOG_PAGE_PATH, {
    NotificationTypeId: props.NotificationTypeId,
    ReferenceId: props.ReferenceId,
  });
};
