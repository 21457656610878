import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';
import { sideBarActions as actions } from '.';
import { appSettingsActions } from 'app/slice';
import { LOCATION_CHANGE, LocationChangePayload } from 'connected-react-router';
import { getLogger } from 'utils/logLevel';
import { selectMenuItems } from './selectors';
import { SiteMapSettings } from 'types/SiteMapSettings';
import { MenuItems } from './types';
import { selectRouterPathname } from 'app/slice/router-selectors';
import { prepareMenu } from './utils/prepareMenu';
import { findSelectedMenuEntry } from './utils/findSelectedMenuEntry';

const log = getLogger('SidBarSaga');

function* doPrepareMenu(action: PayloadAction<SiteMapSettings>) {
  try {
    const pathName = yield select(selectRouterPathname);
    const menuItems = prepareMenu(action.payload.userNodes);
    yield put(actions.setMenuItems(menuItems));
    const selectedItem = findSelectedMenuEntry(menuItems, pathName);
    yield put(actions.setPathName(selectedItem));
  } catch (err) {
    log.error(err);
  }
}

function* doLocationChange(action: PayloadAction<LocationChangePayload>) {
  try {
    const pathName = action.payload.location.pathname;
    const menuItems: MenuItems = yield select(selectMenuItems);
    const activeItem = findSelectedMenuEntry(menuItems, pathName);
    yield put(actions.setPathName(activeItem));
  } catch (error) {
    log.error(error);
  }
  log.debug(doLocationChange.name);
}
export function* sideBarSaga() {
  yield takeLatest(
    appSettingsActions.loadSiteMapSettings_Success.type,
    doPrepareMenu,
  );
  yield takeLatest(LOCATION_CHANGE, doLocationChange);
}
