import { PopupImport } from 'app/components/PopupImport';
import { useDispatch, useSelector } from 'react-redux';
import { useRequestSamplesSlice } from '../RequestSamplesPage/slice';
import { selectImportState } from '../RequestSamplesPage/slice/selectors';

export interface ImportButtonProps {
  onDownloadTemplateButtonClick?: () => Promise<void>;
  disabled?: boolean;
  hideImportButton?: boolean;
  serviceRequestId?: number;
}

export function ImportButton({
  onDownloadTemplateButtonClick,
  disabled,
  hideImportButton,
  serviceRequestId,
}: ImportButtonProps) {
  const dispatch = useDispatch();
  const { actions } = useRequestSamplesSlice();
  const state = useSelector(selectImportState);

  const handleConfirm = (_: React.MouseEvent<HTMLButtonElement>, file: any) => {
    if (file !== undefined) {
      dispatch(
        actions.import({
          file: file,
          serviceRequestId:
            serviceRequestId === undefined ? 0 : serviceRequestId,
        }),
      );
    }
  };

  return (
    <>
      <PopupImport
        disabled={disabled}
        onImportButtonClick={() => dispatch(actions.toggleImport(true))}
        onConfirm={handleConfirm}
        onCancel={() => dispatch(actions.toggleImport(false))}
        open={state.open}
        processing={state.processing}
        accept=".xlsx"
        hideImportButton={hideImportButton}
        variant="white"
        onDownloadTemplateButtonClick={onDownloadTemplateButtonClick}
      />
    </>
  );
}
