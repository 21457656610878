/**
 *
 * BarcodeLogStatusPicker
 *
 */

import {
  BaseOptionsPicker,
  BaseOptionsPickerProps,
} from 'app/components/BasicPickers/BaseOptionsPicker';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Entity } from 'types/common';

export interface BarcodeLogStatusPickerProps
  extends Omit<BaseOptionsPickerProps<Entity<string>>, 'getOptions'> {}
const Statuses = [
  'Updated',
  'Failed',
  'RoomScanned',
  'LocationScanned',
  'SubLocationScanned',
  'NoRoomAccess',
];
export const initLogStatusesData = (
  initval: string | undefined,
): Promise<Entity<string>[]> => {
  if (initval === undefined) {
    return new Promise<Entity<string>[]>((resolve, reject) => {
      resolve([] as Entity<string>[]);
    });
  } else {
    return new Promise<Entity<string>[]>((resolve, reject) => {
      resolve(
        Statuses.filter(item => {
          return item === initval;
        }).map(f => {
          return {
            Id: f,
            Name: i18next.t(f) as string,
          } as Entity<string>;
        }),
      );
    });
  }
};

export function BarcodeLogStatusPicker(props: BarcodeLogStatusPickerProps) {
  const { t } = useTranslation();
  const getOptions = (searchTerm: string | null) => {
    let items = Statuses.map(f => {
      return {
        Id: f,
        Name: t(f) as string,
      } as Entity<string>;
    });

    if (searchTerm !== null && searchTerm !== '') {
      return items.filter(
        f => f.Name.startsWith(searchTerm) || f.Name.indexOf(searchTerm) !== -1,
      );
    }
    return items;
  };
  return (
    <BaseOptionsPicker
      mini={props.mini ? true : undefined}
      size={props.size}
      getOptions={getOptions}
      id={props.id || 'barcodeStatusId'}
      value={props.value}
      {...props}
    />
  );
}
