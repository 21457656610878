import {
  IReservationDto,
  RecurringReservationUpdateOptions,
  ReservationSettingsState,
} from './types';

export function getReservationSettings(dto: IReservationDto) {
  let settings: ReservationSettingsState = {
    BaseEquipment: dto.BaseEquipment,
    FatherId: dto.FatherId,
    Repeat_Period: dto.Repeat_Period,
    TrainingSessionOnly: dto.TrainingSessionOnly,
    SessionId: dto.SessionId,
    RecordDate: dto.RecordDate,
    LastUpdateDate: dto.LastUpdateDate,
    Entered: dto.Entered,
    Cancelled: dto.Cancelled,
    LastUpdateAdmin: dto.LastUpdateAdmin,
    RepeatEndTime: dto.RepeatEndTime,
    Repeat: dto.Repeat,
    HasCharges: dto.HasCharges,
    FundingTypeId: dto.FundingTypeId,
    ParentAssemblyReservationId: dto.ParentAssemblyReservationId,
    TopAssemblyReservationId: dto.TopAssemblyReservationId,
    TransferInProgress: dto.TransferInProgress,
    HasOfflineServices: dto.HasOfflineServices,
    HasTrainingSession: dto.HasTrainingSession,
    NextReservationTime: dto.NextReservationTime,
    PartReservations: dto.PartReservations,
    AssignToServiceRequestId: dto.AssignToServiceRequestId,
    AssignToServiceRequestRowIds: dto.AssignToServiceRequestRowIds,
    AssignToServiceRequestSampleIds: dto.AssignToServiceRequestSampleIds,
    TutorName: dto.TutorName,
    UserRequestedByApproval: false,
    RecurringUpdateOption: RecurringReservationUpdateOptions.CurrentReservation,
    CustomForms: [],
    MandatoriesEquipments: dto.MandatoriesEquipments,
    OptionalsEquipments: dto.OptionalsEquipments,
    TerminateReservation: dto.TerminateReservation,
    FirstInitEquipments: dto.FirstInitEquipments,
    OfflineMandatory: dto.OfflineMandatory,
    IsForceTutoring: dto.IsForceTutoring,
    ForceFullDayReservations: dto.ForceFullDayReservations,
    FutureReservation: dto.IsFuture,
    Ongoing: dto.IsOngoing,
    PastReservation: dto.IsPast,
    StaffMandatoryEquipment: dto.StaffMandatoryEquipment,
    OfflineOptional: [],
    SaveForLater: false,
    TotalCost: undefined,
  };

  return settings;
}
