/**
 *
 * Asynchronously loads the component for ReservationDetailsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UsageDetailsPage = lazyLoad(
  () => import('./index'),
  module => module.UsageDetailsPage,
);
