import React from 'react';
import styled from 'styled-components';

export interface MessageActionsProps {
  messageActions: (() => React.ReactNode)[];
}

export const MessageActions: React.FC<MessageActionsProps> = ({
  messageActions,
}) => {
  return (
    <ButtonsPanel className="message-actions-root">
      {messageActions.map((action, index) => (
        <React.Fragment key={index}>{action()}</React.Fragment>
      ))}
    </ButtonsPanel>
  );
};

const ButtonsPanel = styled.div`
  &.message-actions-root {
    /* Control Panel */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 352px;
    height: 32px;
  }
`;
