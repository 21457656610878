/**
 *
 * AvailabilityTypePicker
 *
 */
import { httpClient } from 'api/HttpClient';
import { AvailabilityTypes } from 'api/odata/generated/enums/AvailabilityTypes';
import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
} from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import * as React from 'react';
import { Entity } from 'types/common';

export interface AvailabilityTypePickerProps
  extends Omit<AutocompletePickerProps<Entity<number>>, 'loadData'> {}

const url = '/api/odata/v4/AvailabilityTypesFilter';
export const initAvailabilityTypeData = (initval: string | undefined) => {
  if (initval === undefined) {
    return new Promise<Entity<number>[]>((resolve, reject) => {
      resolve([] as Entity<number>[]);
    });
  } else {
    const id = parseInt(initval);
    const params: {
      $orderby: string;
      $filter?: string;
      $select: string;
      $top: number;
      $skip: number;
    } = {
      $orderby: 'Name asc',
      $filter: '(Id eq ' + id + ')',
      $select: 'Id,Name',
      $skip: 0,
      $top: 1,
    };
    return httpClient
      .get(url, params)
      .then(response => response.value as Entity<number>[]);
  }
};
const loadData = (searchTerm: string | null) => {
  const params: { $orderby: string; $filter?: string } = {
    $orderby: 'Name asc',
  };
  params.$filter = new ODataFilterBuilder<Entity<number>>({
    predicates: [],
    stringColumns: ['Name'],
    stringSearch: searchTerm !== null ? searchTerm : undefined,
  }).toString();

  return httpClient
    .get(url, params)
    .then(response => response.value as Entity<number>[]);
};
const loadBookableData = (searchTerm: string | null) => {
  const params: { $orderby: string; $filter?: string } = {
    $orderby: 'Name asc',
  };
  const predicates: (string | Condition<Entity<number>>)[] = [];
  const bookable: number[] = [
    //AvailabilityTypes.ApprovalRequiredBillable as number,
    AvailabilityTypes.BookableWApproval as number,
    AvailabilityTypes.FreelyBookable as number,
    //AvailabilityTypes.FreelyBookableBillable as number,
    AvailabilityTypes.LectureRoom as number,
    AvailabilityTypes.LoanableEquipment as number,
    AvailabilityTypes.TrainingProgram as number,
  ];
  let filter = `(${bookable
    .map(item =>
      new Condition<Entity<number>>(
        'Id',
        ODataOperators.Equals,
        item,
      ).toString(),
    )
    .join(' or ')})`;
  predicates.push(filter);

  params.$filter = new ODataFilterBuilder<Entity<number>>({
    predicates: predicates,
    stringColumns: ['Name'],
    stringSearch: searchTerm !== null ? searchTerm : undefined,
  }).toString();

  return httpClient
    .get(url, params)
    .then(response => response.value as Entity<number>[]);
};

export function AvailabilityTypePicker(props: AvailabilityTypePickerProps) {
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'AvailabilityTypeId'}
      loadData={loadData}
      {...props}
    />
  );
}
export function AvailabilityTypeBookablePicker(
  props: AvailabilityTypePickerProps,
) {
  return (
    <AutocompletePicker
      mini={props.mini ? true : undefined}
      size={props.size}
      id={props.id || 'AvailabilityTypeBId'}
      loadData={loadBookableData}
      {...props}
    />
  );
}
