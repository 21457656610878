import * as React from 'react';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { translations } from 'locales/translations';
import { useEffect, useState } from 'react';
import { Entity } from 'types/common';
import { useTranslation } from 'react-i18next';

import { FormHelperText } from '@material-ui/core';
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledFormFieldsContainer,
} from '../Forms/FormsLayout';
import { SkeletonIfLoading } from 'app/Layout/FrontendLayout/components/PageWrapper/components/SkeletonIfLoading';
import { httpClient } from 'api/HttpClient';
import { odataUrlPrefix } from 'utils/url-utils';
import { Body } from '../Typography';
import { bookitColors } from 'styles/colors/bookitColors';
import styled from 'styled-components';
import { EndUsageOfflineServices } from './EndUsageOfflineServices';
import { IUsageHistory } from 'app/pages/UsageHistoryPage/IUsageHistory';
import { BasicCheckBox } from '../BasicCheckBox';

interface OffService {
  quantity: number | null;
  service: Entity<number> | null;
}

export interface EndUsageData {
  services: OffService[];
  remarks: string | undefined;
}

export interface EndUsageConfirmProps {
  isOpen: boolean;
  onClose: () => void;
  ids?: number[];
  onConfirm: (data: EndUsageData) => void;
  usageProcessing?: boolean;
  message?: string;
  equipmentId?: number;
  usageId: number;
}

export const EndUsageConfirm = ({
  isOpen,
  onClose,
  onConfirm,
  usageProcessing,
  message,
  equipmentId,
  usageId,
  ...props
}: EndUsageConfirmProps) => {
  const [selectedServices, setSelectedServices] = useState<OffService[]>([]);
  const [remarks, setRemarks] = useState<string | undefined>();
  const [ids, setIds] = useState(props.ids);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [reset, setReset] = useState(false);
  const [usageData, setUsageData] = useState<IUsageHistory | undefined>(
    undefined,
  );
  const [confirmUsageEnd, setConfirmUsageEnd] = useState(false);
  useEffect(() => {
    if (!!ids || !equipmentId || !isOpen) return;
    let mounted = true;
    setLoading(true);
    (async () => {
      try {
        const res = await httpClient.get(
          `${odataUrlPrefix}AssetDetails/GetEquipmentServices`,
          { equipmentId },
        );
        const usage = await httpClient.get(`${odataUrlPrefix}UsageHistory`, {
          $filter: `Id eq ${usageId}`,
        });
        if (mounted) {
          setIds(res.value);
          setLoading(false);
          setReset(false);
          setUsageData(usage.value[0]);
        }
      } catch (error) {
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    })();
    return () => {
      mounted = false;
    };
  }, [ids, equipmentId, isOpen, usageId]);
  const { t } = useTranslation();
  const handleClose = () => {
    onClose();
    setReset(true);
    setError(undefined);
  };
  const endUsageWithOffline = React.useMemo(() => {
    return !!ids && ids.length > 0;
  }, [ids]);
  const endUsageOfflineValid = React.useMemo(() => {
    return (!!remarks && remarks !== '') || selectedServices.length > 0;
  }, [remarks, selectedServices]);
  return (
    <DialogConfirm
      title={t(translations.ConfirmEndUsage_title)}
      subtitle={
        endUsageWithOffline ? t(translations.EndUsageConfirmTitle) : undefined
      }
      isOpen={isOpen}
      onCancel={handleClose}
      onClose={handleClose}
      processing={usageProcessing}
      maxWidth="sm"
      body={
        <SkeletonIfLoading loading={loading}>
          <>
            {!!ids && endUsageWithOffline ? (
              <EndUsageRoot className="end-usage-root" id="end-usage">
                {/* <p>{t(translations.EndUsageConfirmTitle)}</p> */}
                <StyledFormFieldsContainer noPadding>
                  <FormLeftSection>
                    <EndUsageOfflineServices
                      offlineIds={ids}
                      isProcessing={loading}
                      title={t(translations.UsageEndOfflineService) as string}
                      setError={err => setError(err.error)}
                      usageId={usageId}
                      reset={reset}
                      usageData={usageData}
                      onChange={services => {
                        setSelectedServices(
                          services.map(s => {
                            return {
                              quantity: s.Quantity,
                              service: {
                                Id: s.ServiceTypeID,
                                Name: s.ServiceTypeName,
                              } as Entity<number>,
                            };
                          }),
                        );
                      }}
                    />
                    <FormFieldsSection
                      titleSection={t(translations.ReasonRemarks)}
                      titleVariant="boldS"
                    >
                      <FormRow>
                        <TextControlField
                          placeholder={
                            t(translations.UsageEndRemarksPlaceholder) as string
                          }
                          variant="filled"
                          fullWidth
                          id="end-usage-remarks"
                          rows={10}
                          multiline
                          autoFocus
                          value={remarks}
                          onChange={v => setRemarks(v.target.value)}
                        />
                      </FormRow>
                      <FormRow>
                        <BasicCheckBox
                          label={t(translations.ConfirmServicesCorrectly)}
                          onChange={(val, e) =>
                            setConfirmUsageEnd(e.target.checked)
                          }
                          checked={confirmUsageEnd}
                          name={'Confirm'}
                          required={confirmUsageEnd !== true}
                          maxLabelWidth={100}
                        />
                      </FormRow>
                    </FormFieldsSection>
                    {error && <FormHelperText error>{error}</FormHelperText>}
                  </FormLeftSection>
                </StyledFormFieldsContainer>
              </EndUsageRoot>
            ) : (
              <Body>{message ?? t(translations.ConfirmEndUsage)}</Body>
            )}
          </>
        </SkeletonIfLoading>
      }
      disabled={
        usageProcessing ||
        (endUsageWithOffline &&
          (confirmUsageEnd !== true || !endUsageOfflineValid))
      }
      onConfirm={() => {
        if (endUsageWithOffline) {
          if (!endUsageOfflineValid) {
            setError(t(translations.ConfirmEndUsage_err) as string);
            return;
          }
        }
        if (!error) {
          onConfirm({ services: selectedServices, remarks: remarks });
        }
      }}
    />
  );
};
const EndUsageRoot = styled.div`
  &.end-usage-root {
    display: flex;
    flex-direction: column;
    gap: 16px;
    & .end-usage-item {
      border: 1px solid ${bookitColors.grayscale.grayBorders};
      border-radius: 5px;
      gap: 10%;
      padding: 10px 0px;
      display: flex;
      justify-content: space-around;
    }
  }
`;
