import { getLogger } from 'utils/logLevel';
import { SagaMonitor } from 'redux-saga';
import log from 'loglevel';

export class LoggingSagaMonitor implements SagaMonitor {
  private log: log.Logger;
  constructor() {
    this.log = getLogger('SagaMonitor');
    // disable all by default
    this.log.disableAll();
  }

  //   sagaMonitor.rootSagaStarted(options) : where options is an object with the following fields
  //   effectId : Number - Unique ID assigned to this root saga execution
  //   saga : Function - The generator function that starts to run
  //   args : Array - The arguments passed to the generator function
  rootSagaStarted(options) {
    this.log.debug('rootSagaStarted', options);
  }
  // effectTriggered(options)
  //   effectId : Number - Unique ID assigned to the yielded effect
  //   parentEffectId : Number - ID of the parent Effect. In the case of a race or parallel effect, all effects yielded inside will have the direct race/parallel effect as a parent. In case of a top-level effect, the parent will be the containing Saga
  //   label : String - In case of a race/all effect, all child effects will be assigned as label the corresponding keys of the object passed to race/all
  //   effect : Object - the yielded effect itself
  effectTriggered(options) {
    this.log.debug('effectTriggered', options);
  }
  // effectResolved(effectId, result)
  //   effectId : Number - The ID of the yielded effect
  //   result : any - The result of the successful resolution of the effect. In case of fork or spawn effects, the result will be a Task object.
  effectResolved(effectId, result) {
    this.log.debug('effectResolved', effectId, result);
  }
  // effectRejected(effectId, error)
  //   effectId : Number - The ID of the yielded effect
  //   error : any - Error raised with the rejection of the effect
  effectRejected(effectId, error) {
    this.log.debug('effectRejected', effectId, error);
  }
  // effectCancelled(effectId)
  //   effectId : Number - The ID of the yielded effect
  effectCancelled(effectId) {
    this.log.debug('effectCancelled', effectId);
  }
  // actionDispatched(action)
  //   action : Object - The dispatched Redux action. If the action was dispatched by a Saga then the action will have a property SAGA_ACTION set to true (SAGA_ACTION can be imported from @redux-saga/symbols).
  actionDispatched(action) {
    this.log.debug('actionDispatched', action);
  }
}
