import { Box, Paper, styled } from '@material-ui/core';
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  DropResult,
  Droppable,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { Entity, Identifiable } from 'types/common';
import { ISection, order } from '../../slice/types';
import { ValueChangeHandler } from 'types/ValueChangeHandler';
import { tryParseInt } from 'utils/string-utils';
import { InfiniteScroll } from 'app/components/InfiniteScroll';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { BaseOptionsPicker } from 'app/components/BasicPickers/BaseOptionsPicker';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSectionOrders } from '../../slice/selectors';
import { useServiceRequestsKanbanSlice } from '../../slice';
import { isNullOrUndefined } from 'utils/typeUtils';

export type KanbanChangeEvent = {
  Id: number;
  sourceIndex: number;
  destinationIndex: number;
  sourceSectionId: number;
  destinationSectionId: number;
};

export interface IKanbanProps<
  TSection extends Identifiable<number>,
  TItem extends Identifiable<number>,
> {
  data: Array<ISection<TSection, TItem>>;
  renderTitle: (section: ISection<TSection, TItem>) => React.ReactNode;
  renderItem: (
    item: TItem,
    section: ISection<TSection, TItem>,

    dragHandleProps?: DraggableProvidedDragHandleProps,
  ) => React.ReactNode;
  onChange: ValueChangeHandler<KanbanChangeEvent>;
  onLoadMore: (sectionId: number) => void;
}
export function Kanban<
  TSection extends Identifiable<number>,
  TItem extends Identifiable<number>,
>(props: IKanbanProps<TSection, TItem>) {
  const orders = useSelector(selectSectionOrders);
  const dispatch = useDispatch();
  const { actions } = useServiceRequestsKanbanSlice();
  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    console.debug(result, provided);
    if (isNullOrUndefined(result.destination)) {
      return;
    }
    const itemId = tryParseInt(result.draggableId);
    if (itemId === undefined) {
      return;
    }
    const sourceSectionId = tryParseInt(result.source.droppableId);
    if (sourceSectionId === undefined) {
      return;
    }
    const destinationSectionId = tryParseInt(result.destination.droppableId);
    if (destinationSectionId === undefined) {
      return;
    }
    const sourceIndex = result.source.index;
    if (sourceIndex === undefined) {
      return;
    }
    const destinationIndex = result.destination.index;
    if (destinationIndex === undefined) {
      return;
    }

    props.onChange({
      Id: itemId,
      sourceIndex,
      destinationIndex: destinationIndex,
      sourceSectionId,
      destinationSectionId: destinationSectionId,
    });
  };

  const next = (sectionId: number) => {
    props.onLoadMore(sectionId);
  };
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <StyledGridContainer>
        {props.data.map(section => (
          <StyledColumn key={`column-${section.id}`}>
            <Droppable
              droppableId={section.section.Id.toString()}
              {...props}
              isDropDisabled={
                (orders.find(o => o.sectionId === section.id)?.order ??
                  null) !== null
              }
            >
              {(droppableProvided, droppableSnapshot) => (
                <InfiniteScroll
                  hasMore={section.data?.more === true}
                  scrollThreshold={0.1}
                  dataLength={section.data?.items.length ?? 0}
                  next={() => next(section.id)}
                >
                  <CardsContainer
                    ref={droppableProvided.innerRef}
                    id={`container_${section.section.Id}`}
                    key={`container_${section.section.Id}`}
                    //   isDraggingOver={droppableSnapshot.isDraggingOver}
                  >
                    {props.renderTitle(section)}
                    <OrderPicker
                      value={
                        orders.find(o => o.sectionId === section.id)?.order ??
                        null
                      }
                      onChange={v =>
                        dispatch(
                          actions.setSectionOrder({
                            sectionId: section.id,
                            order: v,
                          }),
                        )
                      }
                    />
                    {section.data !== undefined &&
                      section.data.items.map((item, index) => {
                        return (
                          <Draggable
                            key={item.Id}
                            draggableId={item.Id.toString()}
                            index={index}
                          >
                            {(
                              draggableProvided,
                              draggableSnapshot,
                              draggableRubric,
                            ) => (
                              <div
                                ref={draggableProvided.innerRef}
                                // onMouseEnter={handleMouseEnter}
                                // onMouseLeave={handleMouseLeave}
                                {...draggableProvided.draggableProps}
                              >
                                {props.renderItem(
                                  item,
                                  section,
                                  draggableProvided.dragHandleProps,
                                )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    {droppableProvided.placeholder}
                  </CardsContainer>
                </InfiniteScroll>
              )}
            </Droppable>
          </StyledColumn>
        ))}
      </StyledGridContainer>
    </DragDropContext>
  );
}
const StyledGridContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  width: '100%',
  overflowX: 'scroll',
  height: '74vh',
}));
const StyledColumn = styled(Box)(({ theme }) => ({
  width: 323,
}));
const CardsContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
  margin: '3px',
}));

const getOptions = (t: TFunction): Entity<order>[] => {
  return [
    { Id: 'asc', Name: t(translations.Ascending) },
    { Id: 'desc', Name: t(translations.Descending) },
  ];
};

const OrderPicker = ({
  value,
  onChange,
}: {
  value: order | null;
  onChange: (order: order | null) => void;
}) => {
  const { t } = useTranslation();
  const currentVal = useMemo(() => {
    return getOptions(t).find(v => v.Id === value) ?? null;
  }, [t, value]);
  return (
    <BaseOptionsPicker
      getOptions={() => getOptions(t)}
      value={currentVal}
      onChange={v => onChange(v?.Id ?? null)}
      label="Order by"
      placeholder="default"
      variant="filled"
      info={t(translations.KanbanOrderedColumns_info)}
    />
  );
};
