import { UsersApi } from 'api/UsersApi';
import { Button } from 'app/components/BasicButtons/Button';
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import { FormRow } from 'app/components/Forms/FormsLayout';
import { FlexRow8 } from 'app/components/StyledComponents';
import { IUsers } from 'app/pages/UsersPage/IUsers';
import { useAppSettingsSlice } from 'app/slice';
import { selectAppSettings } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { IResponseType, IResponseTypeWithStringData } from 'types/ResponseType';
import { AttributeValue } from '../../typeUtils';

export function InternalIdRender(props: AttributeValue<IUsers>) {
  const { original, shortView } = props;
  const { t } = useTranslation();
  const { actions } = useAppSettingsSlice();
  const appSettings = useSelector(selectAppSettings);
  const dispatch = useDispatch();
  const [currentInternalId, setInternalId] = React.useState<string | undefined>(
    original.UserInternalId,
  );
  const DTUStudentCardEnabled = React.useMemo(() => {
    return IsModuleEnabled(
      appSettings,
      KnownModules.DTUStudentCardIntergration,
    );
  }, [appSettings]);

  const [updated, setUpdated] = React.useState<boolean>(false);
  const handleUpdate = React.useCallback(async () => {
    setUpdated(true);
    try {
      const result: IResponseType = await UsersApi.updateInternalId(
        original.Name,
        currentInternalId,
      );
      if (result.ErrorMessages.length > 0) {
        dispatch(
          actions.addNotification({
            variant: 'error',
            message: result.ErrorMessages[0],
          }),
        );
        setUpdated(false);
      } else {
        dispatch(
          actions.addNotification({
            variant: 'success',
            message: result.SuccessMessages[0],
          }),
        );
        setUpdated(false);
      }
    } catch (e) {
      dispatch(
        actions.addNotification({
          variant: 'error',
          message: 'Default User Group update failed',
        }),
      );
      setUpdated(false);
    }
  }, [actions, currentInternalId, dispatch, original.Name]);
  const handleDtuCardUpdate = React.useCallback(async () => {
    setUpdated(true);
    try {
      const result: IResponseTypeWithStringData =
        await UsersApi.updateDtuCardId(original.Name);
      if (result.ErrorMessages.length > 0) {
        dispatch(
          actions.addNotification({
            variant: 'error',
            message: result.ErrorMessages[0],
          }),
        );
        setUpdated(false);
      } else {
        dispatch(
          actions.addNotification({
            variant: 'success',
            message: result.SuccessMessages[0],
          }),
        );
        setInternalId(result.Data);
        setUpdated(false);
      }
    } catch (e) {
      dispatch(
        actions.addNotification({
          variant: 'error',
          message: 'Default User Group update failed',
        }),
      );
      setUpdated(false);
    }
  }, [actions, dispatch, original.Name]);

  return (
    <FormRow fullRow>
      <TextControlField
        name="internel_text_id"
        placeholder={t(translations.UserInternalId)}
        onChange={e => setInternalId(e.target.value)}
        variant="filled"
        value={currentInternalId || ''}
        label={t(translations.UserInternalId)}
        disabled={updated}
        textBoxWidth={shortView && DTUStudentCardEnabled ? 'xl' : undefined}
      />
      <FlexRow8>
        <Button
          variant="main"
          size="large"
          disabled={currentInternalId === original.UserInternalId || updated}
          processing={updated}
          onClick={handleUpdate}
        >
          {t(translations.Update)}
        </Button>
        {DTUStudentCardEnabled && (
          <Button
            variant="main"
            size={shortView ? 'medium' : 'large'}
            disabled={currentInternalId === original.UserInternalId || updated}
            processing={updated}
            onClick={handleDtuCardUpdate}
          >
            {t(translations.DtuBasen)}
          </Button>
        )}
      </FlexRow8>
    </FormRow>
  );
}
