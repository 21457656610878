import {
  IOfflineTypeRefsResponse,
  OfflineTypeRefsQueryParams,
} from 'app/pages/OfflineTypeReferencesDetails/Details/slice/types';
import { httpClient } from './HttpClient';
import { IOfflineServiceTypeRefsDto } from './odata/generated/entities/IOfflineServiceTypeRefsDto';

export const OfflineTypeRefsApi = {
  initDetails: (payload: OfflineTypeRefsQueryParams) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/OfflineServiceTypeRefs/InitDetails`;
    return httpClient.post(url, data);
  },
  insertRefs: (period: IOfflineServiceTypeRefsDto) => {
    const url = '/api/odata/v4/OfflineServiceTypeRefs';
    return httpClient.post(url, period);
  },
  updateRefs: (period: IOfflineServiceTypeRefsDto, rowId: number) => {
    const url = '/api/odata/v4/OfflineServiceTypeRefs';
    return httpClient.put<any, IOfflineTypeRefsResponse>(
      url + '(' + rowId + ')',
      period,
    );
  },
  deleteRef: (Id: number) => {
    const url = `/api/odata/v4/OfflineServiceTypeRefs/${Id}`;
    return httpClient.deleteCustom<IOfflineTypeRefsResponse>(url);
  },
  deleteSelected: (Id: number) => {
    const url = `/api/odata/v4/OfflineServiceTypeRefs/${Id}`;
    return httpClient.deleteCustom<IOfflineTypeRefsResponse>(url);
  },
};
