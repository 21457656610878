import { IRequestStatusesDto } from 'app/pages/RequestStatuses/RequestStatusesPage/IRequestStatusesDto';
import { AsyncDataState } from 'types/common';
import { IResponseType } from 'types/ResponseType';

/* --- STATE --- */
export interface RequestStatusesState {
  createState: AsyncDataState<RequestStatusesDetailsState>;
  updateState: AsyncDataState<RequestStatusesDetailsState>;
  processing: boolean;
}

export interface RequestStatusesDetailsState {
  Id: number;
  Name: string;
}

export interface RequestStatusesDetailsQStringParameters {
  id?: string;
  name?: string;
}

export interface RequestStatusesDetailsResponse extends IResponseType {
  Id?: number;
}

export function ConvertModelToEntityRequestStatusesDetails(
  entity: IRequestStatusesDto,
): RequestStatusesDetailsState | undefined {
  if (!entity) {
    return undefined;
  } else {
    return {
      Id: entity.Id,
      Name: entity.Name,
    } as RequestStatusesDetailsState;
  }
}

export function ConvertEntityToModelRequestStatusesDetails(
  model: RequestStatusesDetailsState,
  original: RequestStatusesDetailsState | undefined,
): IRequestStatusesDto {
  let entity = {
    Id: original?.Id === undefined ? model.Id : original.Id,
    Name: model.Name,
  } as IRequestStatusesDto;
  return entity;
}
