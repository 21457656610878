import * as React from 'react';
import { AddFilterButton } from './AddFilterButton';
import { FilterDependencyData, IFilterSettings } from './IFilterSettings';
import { AppliedFilterComponent } from './AppliedFilterComponent';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

import { areSameFilter } from './utils';
import { Button } from 'app/components/BasicButtons/Button';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { Directions } from 'app/components/BasicTable/BasicFilter/FilterBox';
import { AppliedFiltersContainer } from 'app/components/BasicTable/BasicFilter/AppliedFiltersContainer';
import { SavedListsDropDown } from './SavedListsDropDown';
import { TableSavedListSettings } from '../BasicTableProps';
import { FilterValueType } from './FilterValueType';
import { ButtonsContainer } from './styled';

export interface SingleFilterProps {}

export interface IBasicFilterGroupProps<TRow> {
  availableFilters: IFilterSettings<TRow>[];
  appliedFilters: IFilterSettings<TRow>[];
  appliedFiltersDirection?: Directions;
  onClose: () => void;
  onChange: (items: IFilterSettings<TRow>[]) => void;
  getSavedDefaultFilters?: () => IFilterSettings<TRow>[];
  autoOpenAddFilter: boolean;
  showAddResetButtons: boolean;
  savedListSettings?: TableSavedListSettings<TRow>;
  filterDefaultOpen?: boolean;
}
export function updateDepValues<TRow>(
  filterValues: FilterDependencyData<TRow>[],
  depKey: keyof TRow,
  depValue?: FilterValueType,
) {
  if (depValue === null || depValue === undefined) {
    if (
      filterValues.length > 0 &&
      filterValues.some(f => f.depKey === depKey)
    ) {
      return filterValues.filter(f => f.depKey !== depKey);
    } else return filterValues;
  } else {
    if (
      filterValues.length > 0 &&
      filterValues.some(f => f.depKey === depKey)
    ) {
      return filterValues.map(f => {
        if (f.depKey === depKey) {
          let item = {
            depKey: f.depKey,
            depValue: depValue,
          };
          return Object.assign({}, f, item);
        } else {
          return f;
        }
      });
    } else {
      let item = {
        depKey: depKey,
        depValue: depValue,
      };
      return [...filterValues, item];
    }
  }
}
export function getDependencyFilters<TRow>(
  filters: IFilterSettings<TRow>[],
): IFilterSettings<TRow>[] {
  if (filters.length > 0) {
    if (
      filters.some(
        f =>
          !!f.dependencyProp &&
          f.dependencyProp.length > 0 &&
          !!f.filterPredicates,
      )
    ) {
      let depFilters = filters.filter(
        f =>
          !!f.dependencyProp &&
          f.dependencyProp.length > 0 &&
          !!f.filterPredicates,
      );
      depFilters.forEach(item => {
        let depFiltered = filters.filter(f =>
          item.dependencyProp?.includes(f.fieldName),
        );
        if (depFiltered.length > 0) {
          depFiltered.forEach(filt => {
            let depValue = filt?.value ?? filt?.defaultValue;
            item.filterData = updateDepValues(
              item.filterData ?? [],
              filt.fieldName,
              depValue,
            );
          });
        }
      });
      return filters.map(item => {
        if (depFilters.some(d => d.fieldName === item.fieldName)) {
          return depFilters.filter(d => d.fieldName === item.fieldName)[0];
        } else {
          return item;
        }
      });
    }
  }
  return filters;
}
export function BasicFilterGroup<TRow>({
  onChange,
  onClose,
  appliedFiltersDirection = 'vertical',
  savedListSettings,
  getSavedDefaultFilters,
  filterDefaultOpen,
  showAddResetButtons = true,
  ...props
}: IBasicFilterGroupProps<TRow>) {
  const { t } = useTranslation();
  // save provided applied filters to be used in change callbacks
  const [visibleFilters, setVisibleFilters] = React.useState<
    IFilterSettings<TRow>[]
  >(props.appliedFilters);

  // refresh applied filters if they were changed from outside
  React.useEffect(() => {
    setVisibleFilters(props.appliedFilters);
  }, [props.appliedFilters]);

  // handle addition of a new filter
  const handleAddFilter = (item: IFilterSettings<TRow>) => {
    item.value = null;
    setVisibleFilters(currentState => {
      const newState = [
        ...currentState,
        { ...item, ...{ open: filterDefaultOpen ?? true } },
      ];
      onChange(newState);
      return newState;
    });
  };
  const handleAddFilterWithValue = (item: IFilterSettings<TRow>) => {
    setVisibleFilters(currentState => {
      const newState = [
        ...currentState,
        { ...item, ...{ open: filterDefaultOpen ?? true } },
      ];
      onChange(newState);
      return newState;
    });
  };
  const handleClearClick = () => {
    setVisibleFilters(currentState => {
      const newState =
        getSavedDefaultFilters?.() ??
        currentState.map(f => ({
          ...f,
          ...{ value: null, open: false },
        }));
      onChange([...newState]);
      return newState;
    });
  };

  // handle apply (change) of the applied filter
  const handleApply = item => {
    if (item.value === undefined) {
      item.value = null;
    }
    item.visible = true;
    item.open = false;
    setVisibleFilters(currentState => {
      const newState = currentState.map(f =>
        areSameFilter({ f, item }) ? item : f,
      );
      onChange(newState);
      return newState;
    });
  };

  // handle removal of applied filter
  const handleRemoveFitler = (item: IFilterSettings<TRow>) => {
    setVisibleFilters(currentState => {
      const newState = currentState.filter(f => !areSameFilter({ f, item }));
      onChange(newState);
      return newState;
    });
  };

  /**
   * Filters available in "Add filter" - to be added to the displayed fitlers list
   */
  const availableFilters = props.availableFilters.filter(available => {
    const unavailable = visibleFilters;
    const isApplied = unavailable.some(applied =>
      areSameFilter({ f: available, item: applied }),
    );
    return !isApplied;
  });

  const appliedFitltersCount = visibleFilters.length;
  const [open, setOpen] = React.useState(props.autoOpenAddFilter ?? false);
  useEffectOnMount(() =>
    props.autoOpenAddFilter ? setOpen(appliedFitltersCount === 0) : void 0,
  );

  return (
    <>
      {visibleFilters.length > 0 && (
        <AppliedFiltersContainer direction={appliedFiltersDirection}>
          {visibleFilters.map((item, index) => {
            return (
              <AppliedFilterComponent
                key={`${item.fieldName.toString()}-${index}`}
                item={item}
                direction={appliedFiltersDirection}
                onApply={handleApply}
                onRemove={handleRemoveFitler}
              />
            );
          })}
          {savedListSettings && savedListSettings.enableSavedLists && (
            <SavedListsDropDown
              serviceItem={
                visibleFilters.filter(
                  f =>
                    f.fieldName ===
                    savedListSettings.savedListRelatedPickerName,
                ).length === 1
                  ? visibleFilters.filter(
                      f =>
                        f.fieldName ===
                        savedListSettings.savedListRelatedPickerName,
                    )[0]
                  : props.availableFilters.filter(
                      f =>
                        f.fieldName ===
                        savedListSettings.savedListRelatedPickerName,
                    )[0]
              }
              hasInVisibleFilters={
                visibleFilters.filter(
                  f =>
                    f.fieldName ===
                    savedListSettings.savedListRelatedPickerName,
                ).length === 1
              }
              onApply={handleApply}
              onRemove={handleRemoveFitler}
              addFilter={handleAddFilterWithValue}
              serializedKey={savedListSettings.savedListSerializedKey || 'eid'}
              listViewbyAsset={savedListSettings.listViewbyAsset}
            />
          )}
        </AppliedFiltersContainer>
      )}

      {savedListSettings &&
        savedListSettings.enableSavedLists &&
        visibleFilters.length < 1 && (
          <AppliedFiltersContainer direction={appliedFiltersDirection}>
            <SavedListsDropDown
              serviceItem={
                visibleFilters.filter(
                  f =>
                    f.fieldName ===
                    savedListSettings.savedListRelatedPickerName,
                ).length === 1
                  ? visibleFilters.filter(
                      f =>
                        f.fieldName ===
                        savedListSettings.savedListRelatedPickerName,
                    )[0]
                  : props.availableFilters.filter(
                      f =>
                        f.fieldName ===
                        savedListSettings.savedListRelatedPickerName,
                    )[0]
              }
              hasInVisibleFilters={
                visibleFilters.filter(
                  f =>
                    f.fieldName ===
                    savedListSettings.savedListRelatedPickerName,
                ).length === 1
              }
              onApply={handleApply}
              onRemove={handleRemoveFitler}
              addFilter={handleAddFilterWithValue}
              serializedKey={savedListSettings.savedListSerializedKey || 'eid'}
              listViewbyAsset={savedListSettings.listViewbyAsset}
            />
          </AppliedFiltersContainer>
        )}
      <ButtonsContainer className={'buttons-container'}>
        <AddFilterButton
          availableFilters={availableFilters}
          filtersSelectionOpen={open}
          onChange={handleAddFilter}
          title={t(translations.MoreFilters)}
          disabled={availableFilters.length === 0}
          disabledTitle={t(translations.MoreFiltersDisabled)}
        />
        {showAddResetButtons && (
          <React.Fragment>
            <Button
              size="small"
              variant="main"
              color="primary"
              onClick={() => onClose()}
            >
              {t(translations.apply)}
            </Button>

            <Button
              size="small"
              color="primary"
              variant="ghost"
              onClick={handleClearClick}
              title={t(translations.ResetFilters_info)}
            >
              {t(translations.ResetFilters)}
            </Button>
          </React.Fragment>
        )}
      </ButtonsContainer>
    </>
  );
}
