import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { H4 } from 'app/components/Typography';
import { Button } from 'app/components/BasicButtons/Button';
import { FormCheckbox } from 'app/components/Forms/FormCheckbox';
import { FormLocationPicker } from 'app/components/Forms/FormLocationPicker';
import { FormRoomPicker } from 'app/components/Forms/FormRoomPicker';
import { FormServiceGroupPicker } from 'app/components/Forms/FormServiceGroupPicker';
import { FormSamplePlateTypePicker } from 'app/components/Forms/FromSamplePlateTypePicker';
import { Formik } from 'formik';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ISampleCreateBase } from '../../SamplePlatesPage/slice/types';
import { ImportComponent, ImportProps } from './ImportComponent';
import { Check } from 'app/components/BasicInputs/Check';

export interface ImportSamplePlateProps extends ImportProps {
  onSubmit: (item: ISampleCreateBase | null, file: File) => void;
  onClose: () => void;
  initialValues: ISampleCreateBase;
}
function GetSchema(f: boolean): Yup.SchemaOf<ISampleCreateBase> {
  if (f) {
    const schema: Yup.SchemaOf<ISampleCreateBase> = Yup.object({
      Room: Yup.mixed(),
      Location: Yup.mixed(),
      ServiceGroup: Yup.mixed().required(),
      SamplePlateType: Yup.mixed().required(),
      Active: Yup.boolean(),
      IsRack: Yup.boolean(),
    });
    return schema;
  } else {
    const schema: Yup.SchemaOf<ISampleCreateBase> = Yup.object({
      Room: Yup.mixed(),
      Location: Yup.mixed(),
      ServiceGroup: Yup.mixed(),
      SamplePlateType: Yup.mixed(),
      Active: Yup.boolean(),
      IsRack: Yup.boolean(),
    });
    return schema;
  }
}
export function ImportSamplePlateForm({
  onSubmit,
  onClose,
  processing,
  initialValues,
  ...props
}: ImportSamplePlateProps) {
  const { t } = useTranslation();
  const [file, setFile] = React.useState<File>();
  const [generatePlatesonImport, setGeneratePlatesonImport] =
    React.useState<boolean>(false);
  const handleFileChange = (file: File) => {
    setFile(file);
  };
  const handleCheckboxChange = event => {
    setGeneratePlatesonImport(event.target.checked);
  };
  const handleSubmit = value => {
    if (file !== undefined) {
      if (generatePlatesonImport) {
        onSubmit(value, file);
      } else {
        onSubmit(null, file);
      }
    }
  };
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog open={true} onClose={handleClose}>
      <Formik
        validationSchema={GetSchema(generatePlatesonImport)}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values, formikHelpers) => {
          formikHelpers.setSubmitting(true);
          handleSubmit(values);
        }}
      >
        {formik => (
          <>
            <form onSubmit={formik.handleSubmit}>
              <DialogTitle></DialogTitle>
              <DialogContent>
                <H4>
                  <b>{props.title ?? t(translations.ImportRecords)}</b>
                </H4>
                <div style={{ paddingBottom: '8px' }}>
                  <ImportComponent
                    processing={false}
                    accept=".csv"
                    handleFileChange={handleFileChange}
                    hideMessage={false}
                    onDownloadTemplateButtonClick={
                      props.onDownloadTemplateButtonClick
                    }
                  />
                </div>
                <div style={{ paddingBottom: '8px' }}>
                  <Check
                    name={'GeneratePlatesonImport'}
                    id={'GeneratePlatesonImportId'}
                    color={'primary'}
                    checked={generatePlatesonImport}
                    onChange={handleCheckboxChange}
                    label={t(translations.GeneratePlatesOnImport)}
                  />
                  {t(translations.GeneratePlatesOnImport)}
                </div>
                {generatePlatesonImport && (
                  <>
                    <div style={{ paddingBottom: '8px' }}>
                      <FormServiceGroupPicker
                        placeholder="ServiceGroup"
                        fullWidth={true}
                        label="ServiceGroup"
                        name="ServiceGroup"
                        urlType="base"
                      />
                    </div>
                    <div style={{ paddingBottom: '8px' }}>
                      <FormRoomPicker
                        name="Room"
                        fullWidth={true}
                        label="Room"
                        placeholder="Room"
                        variant="filled"
                      />
                    </div>
                    <div style={{ paddingBottom: '8px' }}>
                      <FormLocationPicker
                        name="Location"
                        fullWidth={true}
                        label="Location"
                        placeholder="Location"
                        variant="filled"
                      />
                    </div>
                    <FormSamplePlateTypePicker
                      name="SamplePlateType"
                      fullWidth={true}
                      label="SamplePlateType"
                      placeholder="SamplePlateType"
                      variant="filled"
                    />
                    <FormCheckbox name="IsRack" label="Rack" />
                    <FormCheckbox name="Active" label="Active" />
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button type="submit" processing={processing}>
                  {t(translations.Import)}
                </Button>
                <Button type="reset" onClick={handleClose} variant="ghost">
                  {t(translations.Cancel)}
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
