import React from 'react';
import { FormFieldsSection, FormRow } from 'app/components/Forms/FormsLayout';
import { GhostInput } from 'app/components/BasicInputs/GhostInput';
import { IUsers } from 'app/pages/UsersPage/IUsers';
import {
  AttributeValue,
  UserSection,
  UserSectionsProps,
} from '../../typeUtils';

export interface SectionRowsProps {
  sections: UserSection[];
  data?: IUsers;
  noLabel?: boolean;
}
export const notFullWidth = [''];
export const noLabelRendersArray = ['Image', 'Comments'];
export const asHtmlRenderArray = [
  'Comments',
  'Image',
  'DefaultUserGroup',
  'DefaultBudget',
  'UserInternalId',
  'UserInternalId2',
];
export const DefautltCell = ({ value }: AttributeValue<IUsers>) => (
  <React.Fragment>{value}</React.Fragment>
);
export function RenderUserRows({
  sections,
  data,
  noLabel,
  ...props
}: SectionRowsProps) {
  return !!data ? (
    <React.Fragment>
      {sections.map(s => {
        return (
          <FormFieldsSection
            titleSection={s.row.Name}
            key={`usersection_${s.Id}`}
          >
            {s.rows
              .filter(f => {
                return (
                  f.validToRender === undefined ||
                  f.validToRender({
                    value: data![f.PropName ?? f.Id],
                    original: data,
                  })
                );
              })
              .map(r => {
                let cell = r.Cell ?? DefautltCell;
                let cellData = {
                  original: data,
                  value: data![r.PropName ?? r.Id],
                };
                return (
                  <FormRow key={`user_row-${r.Id}`}>
                    <GhostInput
                      key={`input-${r.Id}`}
                      labelBolder
                      label={
                        noLabel === true
                          ? undefined
                          : noLabelRendersArray.includes(r.Id)
                          ? undefined
                          : r.Name
                      }
                      valueHtml={
                        asHtmlRenderArray.includes(r.Id)
                          ? cell(cellData)
                          : undefined
                      }
                      valueText={
                        !asHtmlRenderArray.includes(r.Id)
                          ? cell(cellData)
                          : undefined
                      }
                      fullWidth={notFullWidth.includes(r.Id) ? false : true}
                    />
                  </FormRow>
                );
              })}
          </FormFieldsSection>
        );
      })}
    </React.Fragment>
  ) : null;
}
export const RenderLeftSection = React.memo(function RenderSection({
  sections,
  data,
  ...props
}: UserSectionsProps) {
  return sections.length > 0 ? (
    <RenderUserRows key={'leftsection'} sections={sections} data={data} />
  ) : null;
});
export const RenderRightSection = React.memo(function RenderSection({
  sections,
  data,
}: UserSectionsProps) {
  return sections.length > 0 ? (
    <RenderUserRows
      key={'rightsection'}
      sections={sections}
      data={data}
      noLabel={true}
    />
  ) : null;
});
