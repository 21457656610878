import * as React from 'react';
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { InfoIcon } from '../BasicIcons/InfoIcon';
import BaseTextField from '../BasicInputs/BaseTextField';
import { BaseFieldProps } from '../BasicInputs/BaseTextField/BaseFieldDefinition';
import clsx from 'clsx';
import { InputAdornment } from '@material-ui/core';

export const FormNumberField = ({
  label,
  onChange,
  onBlur,
  value,
  name,
  helperText,
  noWarnOnNuN,
  ...props
}: FieldHookConfig<number | null> &
  BaseFieldProps &
  Omit<IFieldProps, 'onChange'> & {
    noWarnOnNuN?: boolean;
    minInputWidth?: 'xs' | 'small' | 'medium' | 'large' | 'default';
    textBoxWidth?: 'xs' | 'small' | 'medium' | 'large' | 'default';
  }) => {
  const [field, meta, helper] = useField<number | null>({ name, ...props });
  // const handleChange = e => {
  //   var number = parseFloat(e.target.value);
  //   if (isNaN(number)) {
  //     if (noWarnOnNuN) {
  //       onChange?.(e);
  //       field.onChange(e);
  //     } else {
  //       console.warn(
  //         `NaN value ("${e.target.value}") encountered in FormNumberField`,
  //       );
  //     }
  //   } else {
  //     onChange?.(e);
  //     field.onChange(e);
  //   }
  // };
  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = e => {
    if (onChange !== undefined) {
      onChange(e);
    }
    if (e.target.value !== '') field.onChange(e);
    else helper.setValue(null, true);
  };
  return (
    <BaseTextField
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <React.Fragment>
            {props.info ? <InfoIcon title={props.info} /> : null}
            {props.InputProps?.endAdornment}
            {!!props.endLabel && (
              <InputAdornment
                position="end"
                disableTypography
                className="text-end"
              >
                {props.endLabel}
              </InputAdornment>
            )}
          </React.Fragment>
        ),
      }}
      variant="filled"
      fullWidth
      autoFocus={props.autoFocus}
      inputProps={{
        ...props.inputProps,
        className: props.minInputWidth
          ? `min-width-${props.minInputWidth}`
          : undefined,
        type: 'number',
        min: 0,
        'aria-label': `${label ? undefined : 'number input'}`,
      }}
      value={field.value || ''}
      onChange={handleChange}
      onBlur={field?.onBlur}
      id={props.id || 'numberfieldId'}
      label={label}
      name={name}
      error={meta?.error !== undefined}
      helperText={meta.error ?? helperText}
      className={clsx(props.className, {
        'text-box-width-xs': props.textBoxWidth === 'xs',
        'text-box-width-small': props.textBoxWidth === 'small',
        'text-box-width-medium': props.textBoxWidth === 'medium',
        'text-box-width-large': props.textBoxWidth === 'large',
        'text-box-width-default': props.textBoxWidth === 'default',
        'number-field': true,
      })}
      {...props}
    />
  );
};
