import { PayloadAction } from '@reduxjs/toolkit';
import { ISavedViewDto } from 'api/odata/generated/entities/ISavedViewDto';
import { Entity } from 'types/common';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { savedViewSaga } from './saga';
import {
  ConvertEntityToModel,
  GetExtraData,
  SavedViewDetailsState,
  SavedViewState,
} from './types';

export const initialState: SavedViewState = {
  processing: false,
  createState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
  updateState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
};

const slice = createSlice({
  name: 'savedView',
  initialState,
  reducers: {
    initNewDetails(state, action: PayloadAction<string | undefined>) {
      state.processing = true;
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initNewDetails_Success(
      state,
      action: PayloadAction<SavedViewDetailsState>,
    ) {
      state.processing = false;
      state.createState.data = action.payload;
    },
    initNewDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
      state.createState.data = {
        Id: -1,
        Name: '',
        Assets: [] as Entity<number>[],
        Shared: false,
        Multiple: false,
        // SharedTo: null,
        // SharedUsers: [] as Entity<string>[],
        // SharedCores: [] as Entity<number>[],
      } as SavedViewDetailsState;
    },
    initDetails(state, action: PayloadAction<number>) {
      state.processing = true;
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initDetails_Success(state, action: PayloadAction<ISavedViewDto>) {
      state.processing = false;
      state.updateState.data = ConvertEntityToModel(action.payload);
      state.savedViewUserName = action.payload?.UserName;
      state.SavedViewExtraData = GetExtraData(action.payload);
    },
    initDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    createView(state, action: PayloadAction<SavedViewDetailsState>) {
      state.createState.processing = true;
      state.createState.completed = undefined;
      state.createState.hasErrors = undefined;
    },
    createView_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.createState.processing = false;
      state.createState.hasErrors = action.payload.hasErrors;
      state.createState.completed = true;
    },
    createView_Error(state, action: PayloadAction<any>) {
      state.createState.processing = false;
      state.createState.completed = true;
      state.createState.hasErrors = true;
    },
    updateView(
      state,
      action: PayloadAction<{
        current: SavedViewDetailsState;
        original: SavedViewDetailsState | undefined;
      }>,
    ) {
      state.updateState.processing = true;
      state.updateState.completed = undefined;
      state.updateState.hasErrors = undefined;
    },
    updateView_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.updateState.processing = false;
      state.updateState.hasErrors = action.payload.hasErrors;
      state.updateState.completed = true;
    },
    updateView_Error(state, action: PayloadAction<any>) {
      state.updateState.processing = false;
      state.updateState.completed = true;
      state.updateState.hasErrors = true;
    },
    updateViewAssets(
      state,
      action: PayloadAction<{ assets: Entity<number>[]; view: ISavedViewDto }>,
    ) {
      state.updateState.processing = true;
      state.updateState.completed = undefined;
      state.updateState.hasErrors = undefined;
    },
    updateViewAssets_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.updateState.processing = false;
      state.updateState.hasErrors = action.payload.hasErrors;
      state.updateState.completed = true;
    },
    updateViewAssets_Error(state, action: PayloadAction<any>) {
      state.updateState.processing = false;
      state.updateState.completed = true;
      state.updateState.hasErrors = true;
    },
    resetCreateSavedViewState(state, action: PayloadAction) {
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    resetUpdateSavedViewState(state, action: PayloadAction) {
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    selectMyList(state, action: PayloadAction<ISavedViewDto | undefined>) {},

    selectSavedView(state, action: PayloadAction<number | undefined>) {
      state.selectedSavedView = undefined;
    },
    selectSavedView_Success(
      state,
      action: PayloadAction<ISavedViewDto | undefined>,
    ) {
      state.selectedSavedView = action.payload;
    },
    selectSavedView_Error(state, action: PayloadAction<string>) {
      state.selectedSavedView = undefined;
    },
    patch(state, action: PayloadAction<Partial<ISavedViewDto>>) {},
    patch_success(state, action: PayloadAction<Partial<ISavedViewDto>>) {
      state.selectedSavedView = Object.assign(
        state.selectedSavedView,
        action.payload,
      );
    },
    useSavedView(state, action: PayloadAction<number | undefined>) {},
    useSavedView_success(state, action: PayloadAction<number>) {
      if (state.SavedViewExtraData?.usages !== undefined) {
        state.SavedViewExtraData.usages = action.payload;
      }
    },
  },
});

export const { actions: savedViewActions } = slice;

export const useSavedViewSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: savedViewSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSavedViewSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
