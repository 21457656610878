/**
 * Degree Enum
 */
export enum Degree {
  FirstDegree = 1,
  SecondDegree = 2,
  ThirdDegree = 3,
  PostDocStudent = 4,
  Staff = 5,
  PrincipalInvestigator = 6,
  Industry = 7,
  Undergraduate = 8,
  Postgraduate = 9,
  Project = 10,
  Department = 11,
}
