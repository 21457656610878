import { Condition, ODataOperators } from 'api/odata/ODataFilter';
//import { useFilters } from './TableFilters';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import { WorkOrdersTable } from 'app/pages/WorkOrdersPage/WorkOrdersTable';
import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormSectionTitle } from 'app/components/Forms/FormsLayout/FormSectionTitle';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { NewWorkOrderButton } from 'app/pages/WorkOrdersPage/Actions/NewWorkOrder';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { getColumns } from 'app/pages/WorkOrdersPage/Columns';
import { Column } from 'react-table';
import { IBasicTableState, IRow } from 'app/components/BasicTable';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import workOrderPrimaryAction from 'app/pages/WorkOrdersPage/PrimaryAction';
import { PrimaryAction } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { GettableCount } from 'app/components/BasicTable/components/GetTableCount';

const AssetWorkOrdersColumns: string[] = [
  'Id',
  'AlertType',
  'AlertTitle',
  'AlertDescription',
  'AssignedUsers',
  'AlertOfflineTime',
  'ReportedBy',
  'LastUpdated',
  'LastComment',
  'LastCommentAt',
  'LastCommentBy',
];
const AssetCalibrationColumns: string[] = [
  'Id',
  'AlertType',
  'EventStart',
  'EventEnd',
  'AlertTitle',
  'AlertDescription',
  'ReportedBy',
];
const url = '/api/odata/v4/WorkOrders';

const GetPredicates = (
  assetId: number,
  availabilityType?: string,
  serviceGroupId?: number,
  calibration?: boolean,
): Array<Condition<IWorkOrderDetailsDto> | string> => {
  const res: Array<Condition<IWorkOrderDetailsDto> | string> = [];
  const assetPredicate = new Condition<IWorkOrderDetailsDto>(
    'AssetId',
    ODataOperators.Equals,
    assetId,
  );
  const groupPredicate = new Condition<IWorkOrderDetailsDto>(
    'ServiceGroupId',
    ODataOperators.Equals,
    serviceGroupId,
  );
  const calibrationPredicate = `(${[
    new Condition<IWorkOrderDetailsDto>(
      'ShowOnCalibrationHistory',
      ODataOperators.Equals,
      true,
    ).toString(),
    new Condition<IWorkOrderDetailsDto>(
      'IsClosed',
      ODataOperators.Equals,
      true,
    ).toString(),
  ].join(' and ')})`;

  if (
    !!availabilityType &&
    availabilityType === 'ServiceGroup' &&
    !!serviceGroupId
  ) {
    res.push(groupPredicate);
  } else {
    res.push(assetPredicate);
  }
  if (calibration) {
    res.push(calibrationPredicate);
  }

  return res;
};
export async function GetWorkOrdersTableCount(
  assetId: number,
  calibration: boolean,
  name: string,
  updateCounters: (name: string, count: number) => void,
  serviceGroupId?: number,
  availabilityType?: string,
) {
  const res = await GettableCount({
    api: url,
    predicates: GetPredicates(
      assetId,
      availabilityType,
      serviceGroupId,
      calibration,
    ) as Array<Condition<IRow> | string>,
    columns: ['Id'],
    globalFilterValue: '',
    customFilter: [],
    serviceGroups: [],
    isOptionalServiceGroup: true,
  });
  updateCounters(name, res);
}
export interface AssetWorkOrdersProps {
  assetId: number;
  assetName: string;
  serviceGroupId: number | undefined;
  calibration?: boolean;
  getFiltersDefinition?: GetPageFilters<IWorkOrderDetailsDto>;
  isMobile?: boolean;
  useSidePanel?: boolean;
  openPanel: (state: SidePanelOpenState) => void;
  allowToAddNew?: boolean;
  availabilityType?: string;
  user?: AuthenticatedUser;
}
export function AssetWorkOrders(props: AssetWorkOrdersProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions } = useLayoutSlice();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  /*const {
    allfilters,
    appliedFilters,
    handleFilterChange,
    getFilterValue,
    setFilterValue,
  } = useFilters(props.getFiltersDefinition, 'AssetId');*/
  const workOrdersColumns = React.useMemo(
    () =>
      getColumns(
        t,
        undefined,
        undefined,
        authenticatedUser,
        props.useSidePanel,
        props.openPanel,
      ),
    [authenticatedUser, props.openPanel, props.useSidePanel, t],
  );
  const columns = React.useMemo(() => {
    let res: Column<IWorkOrderDetailsDto>[] = [];
    if (props.calibration) {
      for (const c of AssetCalibrationColumns) {
        const column = workOrdersColumns.find(f => f.accessor === c);
        if (column !== undefined) {
          res.push(column);
        }
      }
      return res;
    } else {
      for (const c of AssetWorkOrdersColumns) {
        const column = workOrdersColumns.find(f => f.accessor === c);
        if (column !== undefined) {
          res.push(column);
        }
      }
      return res;
    }
  }, [props.calibration, workOrdersColumns]);

  const PageActions: PageActionRenderer[] = React.useMemo(() => {
    if (props.calibration || !props.allowToAddNew) {
      return [];
    } else {
      return [
        () => (
          <NewWorkOrderButton
            noChangeOnMobile
            text={t(translations.WorkOrder)}
            assetIds={props.assetId.toString()}
            initAsset={
              { Id: props.assetId, Name: props.assetName } as IAssetDto
            }
            useSidePanel={props.useSidePanel}
            openPanelWithCover={props.openPanel}
          />
        ),
      ];
    }
  }, [
    props.allowToAddNew,
    props.assetId,
    props.assetName,
    props.calibration,
    props.openPanel,
    props.useSidePanel,
    t,
  ]);
  const primaryAction: PrimaryAction<IWorkOrderDetailsDto> = React.useCallback(
    (...args) => {
      workOrderPrimaryAction(
        props.openPanel,
        args[0],
        args[1],
        args[2],
        props.useSidePanel,
      );
    },
    [props.openPanel, props.useSidePanel],
  );
  const goToWorkOrders = React.useCallback(() => {
    dispatch(actions.toggleSidePanel(false));
    history.push('/workorders?aid=' + props.assetId);
  }, [actions, dispatch, history, props.assetId]);

  const predicates = React.useMemo(() => {
    return GetPredicates(
      props.assetId,
      props.availabilityType,
      props.serviceGroupId,
      props.calibration,
    );
  }, [
    props.assetId,
    props.availabilityType,
    props.calibration,
    props.serviceGroupId,
  ]);
  const initialState: IBasicTableState<IWorkOrderDetailsDto> = React.useMemo(
    () => ({
      sortBy: [{ id: 'Id', desc: true }],
    }),
    [],
  );
  return (
    <WorkOrdersTable
      columns={columns}
      initialState={initialState}
      predicates={predicates}
      pageActions={PageActions}
      useConfigurable={false}
      useCardsByDefault={props.isMobile}
      allowCards={props.isMobile}
      useExport={false}
      serviceGroups={[]}
      primaryAction={primaryAction}
      customScreenName={
        <React.Fragment>
          <FormSectionTitle title={t(translations.ActiveIncidents)} />
          <IconButton aria-label="link" onClick={goToWorkOrders}>
            <Icon icon="arrow-up-right-from-square" />
          </IconButton>
        </React.Fragment>
      }
      hideMenuOnMobile={true}
      screenNameVariant="section"
      tablePaddingVariant={'dense'}
      compactTable={props.isMobile}
    />
  );
}
