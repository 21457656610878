import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { translations } from 'locales/translations';
import { Formik } from 'formik';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { SamplePlatesDetailsState } from '../slice/types';
import { FormServiceGroupPicker } from 'app/components/Forms/FormServiceGroupPicker';
import { FormRoomPicker } from 'app/components/Forms/FormRoomPicker';
import { FormLocationPicker } from 'app/components/Forms/FormLocationPicker';
import { FormSamplePlateTypePicker } from 'app/components/Forms/FromSamplePlateTypePicker';
import { FormCheckbox } from 'app/components/Forms/FormCheckbox';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import React from 'react';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';

export interface SamplePlatesFormProps {
  initialValues: SamplePlatesDetailsState;
  onSubmit: (item: SamplePlatesDetailsState) => void;
  isEdit: boolean;
  isAdmin: boolean;
  bindSubmitForm: any;
  expanded: boolean;
  useSidePanel?: boolean;
  pageLink?: string;
  isCover?: boolean;
  openPanel?: (state: SidePanelOpenState) => void;
}

export const SamplePlatesForm = React.memo(function SamplePlatesForm({
  initialValues,
  onSubmit,
  isEdit,
  isAdmin,
  bindSubmitForm,
  expanded,
  useSidePanel,
  pageLink,
  isCover,
  openPanel,
}: SamplePlatesFormProps): JSX.Element {
  //SETTINGS
  const { t } = useTranslation();

  //FUNCTIONS
  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(value);
    }
  };

  //SCHEMA
  const samplePlatesDetailsSchema: Yup.SchemaOf<SamplePlatesDetailsState> =
    Yup.object({
      Id: Yup.string().required(),
      Room: Yup.mixed(),
      Location: Yup.mixed(),
      ServiceGroup: Yup.mixed().required(),
      SamplePlateType: Yup.mixed().required(),
      Active: Yup.boolean(),
      IsRack: Yup.boolean(),
    });

  //RENDER
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={samplePlatesDetailsSchema}
        validateOnMount={true}
        validateOnBlur={false}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          console.debug('Submitting: ', values);
          formikHelpers.setSubmitting(true);
          handleSubmit(values);
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            //console.log('Sample Plates Details Errors: ', formik.errors);
          }
          return (
            <>
              <StyledForm onSubmit={formik.handleSubmit} isCover={isCover}>
                <FormLeftSection isCover={isCover}>
                  <FormFieldsSection
                    titleSection={t(translations.SamplePlateDetails) as string}
                  >
                    <FormRow isCover={isCover}>
                      <FormTextField
                        id="SamplePlateID"
                        name="Id"
                        label={t(translations.Id)}
                        placeholder={t(translations.Id)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow isCover={isCover}>
                      <FormServiceGroupPicker
                        id="ServiceGroupID"
                        name="ServiceGroup"
                        label={t(translations.ServiceGroup)}
                        placeholder={t(translations.ServiceGroup)}
                        disabled={formik.isSubmitting}
                        urlType="base"
                        fullWidth
                      />
                    </FormRow>
                    <FormRow isCover={isCover}>
                      <FormRoomPicker
                        id="RoomID"
                        name="Room"
                        variant="filled"
                        label={t(translations.Room)}
                        placeholder={t(translations.Room)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow isCover={isCover}>
                      <FormLocationPicker
                        id="LocationID"
                        name="Location"
                        variant="filled"
                        label={t(translations.Location)}
                        placeholder={t(translations.Location)}
                        disabled={formik.isSubmitting}
                        fullWidth
                        predicates={
                          formik.values.Room
                            ? [
                                new Condition(
                                  'RoomId',
                                  ODataOperators.Equals,
                                  formik.values.Room.Id,
                                ),
                              ]
                            : undefined
                        }
                      />
                    </FormRow>
                    <FormRow isCover={isCover}>
                      <FormSamplePlateTypePicker
                        id="SamplePlateTypeID"
                        name="SamplePlateType"
                        variant="filled"
                        label={t(translations.SamplePlateType)}
                        placeholder={t(translations.SamplePlateType)}
                        disabled={formik.isSubmitting}
                        fullWidth
                      />
                    </FormRow>
                    <FormRow isCover={isCover}>
                      <FormCheckbox
                        id="IsRackID"
                        name="IsRack"
                        label="Rack"
                        disabled={formik.isSubmitting}
                      />
                    </FormRow>
                    <FormRow isCover={isCover}>
                      <FormCheckbox
                        id="ActiveID"
                        name="Active"
                        label="Active"
                        disabled={formik.isSubmitting}
                      />
                    </FormRow>
                  </FormFieldsSection>
                </FormLeftSection>
              </StyledForm>
            </>
          );
        }}
      </Formik>
    </>
  );
});
