/**
 *
 * ArticleDetails
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { IArticleDto } from 'api/odata/generated/entities/IArticleDto';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { useDispatch, useSelector } from 'react-redux';
import { useArticlesSlice } from 'app/pages/ArticlesPage/slice';
import { InputLabel } from 'app/components/Typography';
import {
  selectArticleDetails,
  selectArticleDetailsBusy,
  selectArticleDetailsForm,
  selectRefreshEditArticleAssociation,
} from 'app/pages/ArticlesPage/slice/selectors';
import { AssetLink } from 'app/pages/AssetPopUp/OpenAssetDetails';
import { isNullOrUndefined } from 'utils/typeUtils';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import { ArticleStatus } from 'enums/Article';
import { useCanAdd, useCanEdit } from '../Hooks/useAllowedToChangeStatus';
import { WorkOrderLink } from 'app/pages/Actions/GlobalSelectedActions/OpenWorkOrder';
import useSidePanelState, {
  SidePanelCloseState,
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { selectDoRefreshTable } from 'app/Layout/FrontendLayout/slice/selectors';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRightSection,
  FormRow,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import { GhostInput } from 'app/components/BasicInputs/GhostInput';
import { DateTimeLabel } from 'app/components/DateTimeLabel';
import { ArticleDoiLink } from '../ArticleDoiLink';

export interface ArticleDetailsProps extends SidePanelContentProps, CoverProps {
  value?: IArticleDto;
  Id?: number;
  externalId?: string;
  externalIdSource?: string;
  onClose?: () => void;
}

export function ArticleDetails({
  useSidePanel = true,
  closeCover,
  isCover,
  ...props
}: ArticleDetailsProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useArticlesSlice();
  const selectedArticle = useSelector(selectArticleDetails);
  const articleDetails = useSelector(selectArticleDetailsForm);
  const refreshDetails = useSelector(selectRefreshEditArticleAssociation);
  const busy = useSelector(selectArticleDetailsBusy);
  const refresh = useSelector(selectDoRefreshTable);

  const isSaved = React.useMemo(() => {
    return articleDetails?.Id !== undefined && articleDetails?.Id > 0;
  }, [articleDetails?.Id]);
  const [canEdit, msg] = useCanEdit();
  const canAdd = useCanAdd();

  const [firstLoad, setFirstLoad] = React.useState<boolean | undefined>(true);

  React.useEffect(() => {
    let active = firstLoad;

    if (active && !busy) {
      setFirstLoad(false);
      dispatch(
        actions.showDetails({
          Id: props.Id,
          externalId: props.externalId,
          externalIdSource: props.externalIdSource,
          article: props.value,
        }),
      );
    }
    return () => {
      active = false;
    };
  }, [
    actions,
    busy,
    dispatch,
    firstLoad,
    props.Id,
    props.externalId,
    props.externalIdSource,
    props.value,
    refresh,
  ]);
  React.useEffect(() => {
    let active = refreshDetails === true;

    if (active && !busy) {
      dispatch(
        actions.showDetails({
          Id: selectedArticle?.Id,
          externalId: selectedArticle?.ExternalId ?? undefined,
          externalIdSource: selectedArticle?.ExternalIdSource ?? undefined,
          article: selectedArticle,
        }),
      );
      dispatch(actions.refreshEditArticleAssociation(undefined));
    }
    return () => {
      active = false;
    };
  }, [actions, busy, dispatch, refreshDetails, selectedArticle]);

  const { cover, openPanel, closePanel, coverClosed, onCloseCover } =
    useSidePanelState(() => false, undefined, isCover, useSidePanel);
  const openExternalEntry = React.useCallback(
    (article: IArticleDto | undefined) => {
      openPanel({
        renderPageType: RenderPageType.ArticleEdit,
        renderPageProps: {
          useSidePanel: true,
          value: article,
        },
        expanded: false,
        useSidePanel: useSidePanel,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [openPanel, useSidePanel],
  );
  const editArticle = React.useCallback(() => {
    openPanel({
      renderPageType: RenderPageType.ArticleForm,
      renderPageProps: {
        useSidePanel: true,
      },
      expanded: false,
      useSidePanel: useSidePanel,
      isCover: useSidePanel,
    } as SidePanelOpenState);
  }, [openPanel, useSidePanel]);

  const handleCancelClick = React.useCallback(() => {
    closePanel({
      isCover: isCover,
      useSidePanel: useSidePanel,
      showConfirm: false,
      onClose: () => {
        dispatch(layoutActions.setNotSavedChanges(false));
      },
    } as SidePanelCloseState);
  }, [closePanel, dispatch, isCover, useSidePanel]);
  const isEmpty = React.useCallback(value => {
    if (value === undefined || value === null) {
      return true;
    } else {
      if (typeof value === 'string') {
        const res = value.trim();
        return res !== '' ? false : true;
      } else {
        return false;
      }
    }
  }, []);
  const getValue = React.useCallback(
    value => {
      if (value === undefined || value === null) {
        return t(translations.NA);
      } else {
        if (typeof value === 'string') {
          const res = value.trim();
          return res !== '' ? res : t(translations.NA);
        } else {
          return value;
        }
      }
    },
    [t],
  );
  return (
    <PageWrapper
      pageName={t(translations.PublicationDetails)}
      titlePage={articleDetails.Title ?? undefined}
      useSidePanel={true}
      closable={true}
      closeSidePanel={handleCancelClick}
      //loading={busy}
      topProcessing={busy}
      leftTopActions={
        (selectedArticle !== undefined && selectedArticle.Id > 0 && canEdit) ||
        (selectedArticle !== undefined &&
          selectedArticle.Id === 0 &&
          !busy &&
          canAdd)
          ? [
              () => (
                <>
                  <EditAssociationButton
                    item={selectedArticle}
                    onClick={() =>
                      selectedArticle !== undefined &&
                      selectedArticle.ExternalIdSource === 'Manual Entry'
                        ? openExternalEntry(selectedArticle)
                        : editArticle()
                    }
                    disabled={!canEdit}
                  />
                </>
              ),
            ]
          : undefined
      }
      cover={cover}
      closeCover={!isCover ? onCloseCover : closeCover}
      isCover={isCover}
      coverClosed={coverClosed}
      useSwitchActions={true}
    >
      <StyledFormFieldsContainer isCover={isCover}>
        <FormLeftSection isCover={isCover}>
          <FormFieldsSection>
            {msg !== '' && (
              <FormRow>
                <InputLabel size="xs">{msg}</InputLabel>
              </FormRow>
            )}

            <FormRow>
              <GhostInput
                labelBolder
                label={t(translations.Authors)}
                valueText={getValue(articleDetails.Authors?.join(', '))}
              />
            </FormRow>
            {!isNullOrUndefined(articleDetails.ExternalIdSource) && (
              <>
                <FormRow>
                  <GhostInput
                    labelBolder
                    label={t(translations.Source)}
                    valueText={getValue(articleDetails.ExternalIdSource)}
                  />
                </FormRow>
              </>
            )}
            <FormRow>
              <GhostInput
                labelBolder
                label={t(translations.EnteredBy)}
                valueText={getValue(articleDetails.UserDisplayName)}
              />
            </FormRow>
            <FormRow>
              <GhostInput
                labelBolder
                label={t(translations.InsertedAt)}
                valueText={
                  articleDetails.EnteredAt !== undefined &&
                  articleDetails.EnteredAt !== null ? (
                    <DateTimeLabel value={articleDetails.EnteredAt ?? null} />
                  ) : (
                    t(translations.NA)
                  )
                }
              />
            </FormRow>
            <FormRow>
              <GhostInput
                labelBolder
                label={t(translations.Doi)}
                valueText={
                  isEmpty(articleDetails.Doi) === false ? (
                    <ArticleDoiLink doi={articleDetails.Doi} />
                  ) : (
                    t(translations.NA)
                  )
                }
              />
            </FormRow>
            <FormRow>
              <GhostInput
                labelBolder
                label={t(translations.ArticleDataset)}
                valueText={getValue(articleDetails.Dataset)}
              />
            </FormRow>
            <FormRow>
              <GhostInput
                labelBolder
                label={t(translations.ISSN)}
                valueText={getValue(articleDetails.ISSN)}
              />
            </FormRow>
            <FormRow>
              <GhostInput
                labelBolder
                label={t(translations.ORCID)}
                valueText={getValue(articleDetails.ORCIDs?.join(', '))}
              />
            </FormRow>
            <FormRow>
              <GhostInput
                labelBolder
                label={t(translations.Year)}
                valueText={getValue(articleDetails.Year)}
              />
            </FormRow>
            <FormRow>
              <GhostInput
                labelBolder
                label={t(translations.ArticleStatus)}
                valueText={
                  articleDetails.Status !== undefined
                    ? (t(
                        'Article' + ArticleStatus[articleDetails.Status],
                      ) as string)
                    : 'undefined'
                }
              />
            </FormRow>
            <FormRow>
              <GhostInput
                labelBolder
                label={t(translations.RelatedUser)}
                valueText={getValue(articleDetails.RelatedUserDisplayName)}
              />
            </FormRow>
            <FormRow>
              <GhostInput
                labelBolder
                label={t(translations.CalibrationRecord)}
                valueText={
                  articleDetails.CalibrationRecord !== undefined &&
                  articleDetails.CalibrationRecord !== null ? (
                    <WorkOrderLink
                      id={articleDetails.CalibrationRecord}
                      useSidePanel={true}
                      openPanelWithCover={openPanel}
                      //openPanelWithCover={openPanel}
                    >
                      {articleDetails.CalibrationRecord}
                    </WorkOrderLink>
                  ) : (
                    t(translations.NA)
                  )
                }
              />
            </FormRow>
            <FormRow>
              <GhostInput
                labelBolder
                label={t(translations.Journal)}
                valueText={getValue(articleDetails.Journal)}
              />
            </FormRow>
            <FormRow>
              <GhostInput
                labelBolder
                label={t(translations.ArticlePageNumber)}
                valueText={getValue(articleDetails.PageNumber)}
              />
            </FormRow>
            {isSaved && (
              <>
                <FormRow>
                  <GhostInput
                    labelBolder
                    label={t(translations.ParticipatedLabs)}
                    valueText={
                      articleDetails.ServiceGroupAssets.length === 0 ? (
                        t(translations.NA)
                      ) : (
                        <ul>
                          {articleDetails.ServiceGroupAssets.map(asset => (
                            <li key={asset.Id}>
                              <AssetLink
                                openPanelWithCover={openPanel}
                                useSidePanel={true}
                                id={asset.Id}
                              >
                                {asset.Name}
                              </AssetLink>
                            </li>
                          ))}
                        </ul>
                      )
                    }
                  />
                </FormRow>
                <FormRow>
                  <GhostInput
                    labelBolder
                    label={t(translations.ParticipatedInstruments)}
                    valueText={
                      articleDetails.InstrumentAssets.length === 0 ? (
                        t(translations.NA)
                      ) : (
                        <ul>
                          {articleDetails.InstrumentAssets.map(asset => (
                            <li key={asset.Id}>
                              <AssetLink
                                openPanelWithCover={openPanel}
                                useSidePanel={true}
                                id={asset.Id}
                              >
                                {asset.Name}
                              </AssetLink>
                            </li>
                          ))}
                        </ul>
                      )
                    }
                  />
                </FormRow>
              </>
            )}
          </FormFieldsSection>
        </FormLeftSection>
        <FormRightSection isCover={isCover}>
          <FormFieldsSection>
            <FormRow>
              <GhostInput
                labelBolder
                label={t(translations.Abstract)}
                valueText={getValue(articleDetails.Abstract)}
              />
            </FormRow>
          </FormFieldsSection>
        </FormRightSection>
      </StyledFormFieldsContainer>
    </PageWrapper>
  );
}
function EditAssociationButton({
  item,
  onClick,
  disabled,
}: {
  item?: IArticleDto;
  onClick: () => void;
  disabled: boolean;
}) {
  const { t } = useTranslation();
  //const authenticatedUser = useSelector(selectAuthenticatedUser);
  if (item === undefined) {
    return <></>;
  }
  /*const show =
    authenticatedUser?.Roles?.includes(Roles.GroupAdministrators) === true;
  if (!show) {
    return <></>;
  }*/
  if (item.Id > 0) {
    if (item.ExternalIdSource === 'Manual Entry') {
      return (
        <Button
          title={t(translations.EditArticle)}
          startIcon={<Icon icon="edit" />}
          onClick={onClick}
          disabled={disabled}
        >
          {t(translations.EditArticle)}
        </Button>
      );
    } else {
      return (
        <Button
          title={t(translations.EditAssociation)}
          startIcon={<Icon icon="edit" />}
          onClick={onClick}
          disabled={disabled}
        >
          {t(translations.EditAssociation)}
        </Button>
      );
    }
  } else {
    return (
      <Button
        title={t(translations.AssociateThisPublicationTitle)}
        startIcon={<Icon icon="add" />}
        onClick={onClick}
      >
        {t(translations.AssociateThisPublication)}
      </Button>
    );
  }
}
