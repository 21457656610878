import {
  BookitTypographyVariant,
  FontStyleOptions,
  TypographyStyleOptions,
  Variant,
} from '@material-ui/core/styles/createTypography';
import { CSSProperties } from '@material-ui/styles';
export const h1: CSSProperties = {
  fontFamily: `'Lato', sans-serif`,
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '141%',
  letterSpacing: '-0.4px' /* 56.4px */,
};
export const h2: CSSProperties = {
  //styleName: Bkt/Header 2;
  fontFamily: `'Lato', sans-serif`,
  fontSize: '32px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '146%', ///* 46.72px */
  letterSpacing: '-0.4px',
};
export const h3: CSSProperties = {
  //styleName: Bkt/Header 3;
  fontFamily: `'Lato', sans-serif`,
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '160%' /* 38.4px */,
  letterSpacing: '-0.2px',
};
export const h4: CSSProperties = {
  //styleName: Bkt/Header 4:
  fontFamily: `'Lato', sans-serif`,
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '160%' /* 32px */,
  letterSpacing: '-0.1px',
};
export const subtitle1: CSSProperties = {
  /* Bkt/Subtitle 1 */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '24px',
  fontStyle: 'normal',
  lineHeight: '160%' /* 38.4px */,
  fontWeight: 400,
};
export const subtitle2: CSSProperties = {
  /* Bkt/Subtitle 2 */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '20px',
  fontStyle: 'normal',
  lineHeight: '160%' /* 32px */,
  fontWeight: 400,
};
export const boldL: CSSProperties = {
  /* Bkt/Bold L */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '18px',
  fontStyle: 'normal',
  lineHeight: '141%' /* 25.38px */,
  fontWeight: 700,
  letterSpacing: '0px',
};
export const boldM: CSSProperties = {
  /* Bkt/Bold M */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '16px',
  fontStyle: 'normal',
  lineHeight: '141%' /* 25.38px */,
  fontWeight: 700,
  letterSpacing: '0px',
};
export const boldS: CSSProperties = {
  /* Bkt/Bold S */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '141%' /* 19.74px */,
  fontWeight: 700,
  letterSpacing: '0.1px',
};
export const bodyL: CSSProperties = {
  /* Bkt/Body L */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '18px',
  fontStyle: 'normal',
  lineHeight: '141%' /* 22.56px */,
  fontWeight: 400,
  letterSpacing: '0px',
};
export const bodyM: CSSProperties = {
  /* Bkt/Body M */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '16px',
  fontStyle: 'normal',
  lineHeight: '141%' /* 22.56px */,
  fontWeight: 400,
  letterSpacing: '0px',
};
export const bodyS: CSSProperties = {
  /* Bkt/Body S */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '141%' /* 19.74px */,
  fontWeight: 400,
  letterSpacing: '0.1px',
};

export const captionBold: CSSProperties = {
  /* Bkt/Caption Bold */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '12px',
  fontStyle: 'normal',
  lineHeight: '141%' /* 16.92px */,
  fontWeight: 700,
  letterSpacing: '0.2px',
};
export const captionRegular: CSSProperties = {
  /* Bkt/Caption */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '12px',
  fontStyle: 'normal',
  lineHeight: '141%' /* 16.92px */,
  fontWeight: 400,
  letterSpacing: '0.2px',
};
export const uppercase: CSSProperties = {
  /* Bkt/UPPERCASE */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '12px',
  fontStyle: 'normal',
  lineHeight: '160%' /* 19.2px */,
  fontWeight: 400,
  letterSpacing: '0.1px',
  textTransform: 'uppercase',
};
export const tipsBold: CSSProperties = {
  /* Bkt/Tips Bold */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '10px',
  fontStyle: 'normal',
  lineHeight: '141%' /* 19.2px */,
  fontWeight: 700,
  letterSpacing: '0.2px',
};
export const tipsRegular: CSSProperties = {
  /* Bkt/Tips Regular */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '10px',
  fontStyle: 'normal',
  lineHeight: '141%' /* 19.2px */,
  fontWeight: 400,
  letterSpacing: '0.2px',
};

export const inputLabelM: CSSProperties = {
  /* Components Styles/Input Label - M */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '16px',
  fontStyle: 'normal',
  lineHeight: '20px' /* 125% */,
  fontWeight: 400,
  letterSpacing: '0px',
};
export const inputLabelXS: CSSProperties = {
  /* Components Styles/Input Label - XS */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '12px',
  fontStyle: 'normal',
  lineHeight: '16px' /* 133.333% */,
  fontWeight: 400,
  letterSpacing: '0px',
};
export const inputValueM: CSSProperties = {
  /* Components Styles/Input - Value M */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '16px',
  fontStyle: 'normal',
  lineHeight: '20px' /* 125% */,
  fontWeight: 400,
  letterSpacing: '0.2px',
};
export const inputValueS: CSSProperties = {
  /* Components Styles/Input - Value S */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '20px' /* 142.857% */,
  fontWeight: 400,
  letterSpacing: '0.2px',
};
export const buttonM: CSSProperties = {
  /* Components Styles/Button - M */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '16px',
  fontStyle: 'normal',
  lineHeight: '20px' /* 125% */,
  fontWeight: 600,
  letterSpacing: '0.2px',
};
export const buttonS: CSSProperties = {
  /* Components Styles/Button - S */
  fontFamily: `'Lato', sans-serif`,
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '20px' /* 142.857% */,
  fontWeight: 600,
  letterSpacing: '0.2px',
};

export const bookitTypography: BookitTypographyOptions = {
  h1: h1,
  h2: h2,
  h3: h3,
  h4: h4,
  subtitle1: subtitle1,
  subtitle2: subtitle2,
  boldL: boldL,
  boldM: boldM,
  boldS: boldS,
  bodyL: bodyL,
  bodyM: bodyM,
  bodyS: bodyS,
  captionBold: captionBold,
  captionRegular: captionRegular,
  uppercase: uppercase,
  tipsBold: tipsBold,
  tipsRegular: tipsRegular,
  inputLabelM: inputLabelM,
  inputLabelXS: inputLabelXS,
  inputValueM: inputValueM,
  inputValueS: inputValueS,
  buttonM: buttonM,
  buttonS: buttonS,
};

export const defaultVariantMapping: Partial<Record<Variant, string>> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p',
  button: 'label',
  caption: 'label',
  overline: 'label',
};
export const typographyComponentMapping: Partial<
  Record<BookitTypographyVariant, React.ElementType>
> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  bodyL: 'p',
  bodyM: 'p',
  bodyS: 'p',
  boldL: 'p',
  boldM: 'p',
  boldS: 'p',
  buttonM: 'label',
  buttonS: 'label',
  captionRegular: 'label',
  captionBold: 'label',
  inputLabelM: 'label',
  inputLabelXS: 'label',
  inputValueM: 'span',
  inputValueS: 'span',
  subtitle1: 'p',
  subtitle2: 'p',
  tipsBold: 'label',
  tipsRegular: 'label',
  uppercase: 'p',
};
export const typographyNameMapping: Partial<
  Record<BookitTypographyVariant, string>
> = {
  h1: 'Header 1',
  h2: 'Header 2',
  h3: 'Header 3',
  h4: 'Header 4',
  bodyL: 'Body L',
  bodyM: 'Body M',
  bodyS: 'Body S',
  boldL: 'Bold L',
  boldM: 'Bold M',
  boldS: 'Bold S',
  buttonM: 'Button M',
  buttonS: 'Button S',
  captionRegular: 'Caption Regular',
  captionBold: 'Caption Bold',
  inputLabelM: 'Input Label M',
  inputLabelXS: 'Input Label XS',
  inputValueM: 'Input Value M',
  inputValueS: 'Input Value S',
  subtitle1: 'Subtitle 1',
  subtitle2: 'Subtitle 2',
  tipsBold: 'Tips Bold',
  tipsRegular: 'Tips Regular',
  uppercase: 'UPPERCASE',
};

export interface TypographyOptionMeta {
  Name: string;
  variant: BookitTypographyVariant;
  style: CSSProperties;
}
export const getTypographyMeta = (): TypographyOptionMeta[] => {
  const data: TypographyOptionMeta[] = Object.entries(bookitTypography).map(
    ent => {
      return {
        Name: typographyNameMapping[ent[0]] as string,
        style: ent[1] as CSSProperties,
        variant: ent[0] as BookitTypographyVariant,
      };
    },
  );
  return data;
};
export interface BookitTypographyOptions
  extends Partial<
    Record<BookitTypographyVariant, TypographyStyleOptions> & FontStyleOptions
  > {}
