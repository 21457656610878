/**
 *
 * AutocompletePickerS2
 *
 */
import * as React from 'react';
import { CircularProgress, InputBaseComponentProps } from '@material-ui/core';
import Autocomplete, {
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import { EntityType } from 'types/common';
import BaseTextField from 'app/components/BasicInputs/BaseTextField';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import clsx from 'clsx';
import { Condition } from 'api/odata/ODataFilter';
import {
  extractDataFromResponse,
  IDisableable,
  loadDataAndPrepareOptions,
  OptionType,
} from '../Utils/autoCompletePickerUtils';
import { FilterOptionsState } from '@material-ui/lab';
import { highlightSearchTerm } from '../BaseOptionsPicker/highlightSearchTerm';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useSelector } from 'react-redux';
import {
  selectExpandedSidePanel,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { capitalize, omit } from 'lodash';

export interface AutocompletePickerProps<T extends EntityType> {
  loadData: (
    searchTerm: string | null,
    predicates?: (string | Condition<T>)[],
    expandedColumns?: string,
  ) => Promise<T[] | { value: T[]; more: boolean }>;
  filterOptions?: (
    options: OptionType<T>[],
    state: FilterOptionsState<OptionType<T>>,
  ) => OptionType<T>[];
  value?: T | null;
  label?: string;
  onChange?: (value: T | null) => void;
  size?: 'small' | 'medium' | 'large' | 'xl';
  listSize?: 'small' | 'medium' | 'large';
  mini?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  isOpen?: boolean;
  disableClearable?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  additionalItem?: T | null;
  disabled?: boolean;
  name?: string;
  placeholder?: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  error?: boolean;
  helperText?: string;
  info?: string;
  noOptionsText?: React.ReactNode;
  defaultValue?: T | null;
  id?: string;
  select?: boolean;
  customData?: T | T[] | null;
  predicates?: (string | Condition<T>)[];
  ariaLabel?: string;
  className?: string;
  innerClassName?: string;
  innerProps?: Partial<React.ComponentProps<typeof BaseTextField>>;
  title?: string;
  loading?: boolean;
  clearOnBlur?: boolean;
  freeSolo?: boolean;
  handleInputChange?: (value: string) => void;
  additionalSearchTerm?: string | null;
  onPickerOpen?: () => void;
  renderOptionName?: (option: OptionType<T>) => string;
  getOptionLabelName?: (option: OptionType<T>) => string;
  inputProps?: InputBaseComponentProps | undefined;
  autoFocus?: boolean;
  autoHighlight?: boolean;
  autoComplete?: boolean;
  autoSelect?: boolean;
  blurOnSelect?: 'touch' | 'mouse' | true | false;
  clearOnEscape?: boolean;
  includeInputInList?: boolean;
  selectOnFocus?: boolean;
  disableListWrap?: boolean;
  disableCloseOnSelect?: boolean;
  filterSelectedOptions?: boolean;
  openOnFocus?: boolean;
  setSomeOpen?: (open: boolean) => void;
  ListboxProps?: object;
  expandedColumns?: string;
  customRenderOption?: (
    option: OptionType<T>,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  getOptionSelected?: (option: OptionType<T>, value: OptionType<T>) => boolean;
}

export function AutocompletePicker<T extends EntityType>({
  label,
  loadData,
  filterOptions = options => options,
  value,
  onChange,
  size = 'large',
  listSize = 'small',
  mini,
  variant = 'standard',
  isOpen,
  disableClearable,
  fullWidth = false,
  additionalItem,
  disabled,
  info,
  noOptionsText,
  id,
  select,
  required,
  predicates,
  ariaLabel,
  className,
  title,
  loading: propsLoading,
  freeSolo,
  handleInputChange,
  additionalSearchTerm,
  renderOptionName,
  getOptionLabelName,
  inputProps,
  autoFocus,
  autoHighlight = false,
  autoComplete,
  autoSelect,
  blurOnSelect,
  clearOnEscape,
  includeInputInList,
  selectOnFocus,
  disableListWrap,
  filterSelectedOptions,
  disableCloseOnSelect,
  openOnFocus,
  setSomeOpen,
  ListboxProps,
  expandedColumns,
  customRenderOption,
  getOptionSelected,
  ...props
}: AutocompletePickerProps<T>) {
  const { t } = useTranslation();
  const isMobile = DetectIsMobile();
  // const classes = autocompleteStyles();
  // const extClasses = autocompleteExtendStyles();
  const timerRef = React.useRef<any>(null);
  //const miniClass = `${size === 'small' && mini ? 'miniDense' : ''}`;
  let firstOpen = isOpen;
  const [open, setOpen] = React.useState(firstOpen);
  const [options, setOptions] = React.useState<
    Array<OptionType<T>> | undefined
  >([]);
  const [searchTerm, setSearchTerm] = React.useState<string | null>(
    additionalSearchTerm === undefined ? null : additionalSearchTerm,
  );
  const [hasSelected, setSelected] = React.useState(
    value !== undefined && value !== null,
  );
  React.useEffect(() => {
    setSelected(value !== undefined && value !== null);
  }, [value]);
  const [firstMounted, setFirstMounted] = React.useState<boolean | undefined>(
    true,
  );
  useEffectOnMount(() => {
    !!setSomeOpen && setSomeOpen(isOpen ?? false);
  });
  const handleSelectText = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    event.target.select();
    event.target.setSelectionRange(0, event.target.value.length);
    setFirstMounted(undefined);
    console.log('auto focus running');
  };
  React.useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);
  const [loading, setLoading] = React.useState<boolean>(propsLoading ?? false);
  // update internal "loading" state when "loading" property changes it's value
  React.useEffect(() => {
    if (propsLoading !== undefined) {
      setLoading(propsLoading);
    }
  }, [propsLoading]);
  const doload = open && (options === undefined || options?.length === 0);
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const showShortView = isMobile || (sidePanelOpen && !sidePanelExpanded);

  React.useEffect(() => {
    let active = true;
    if (!doload) {
      return undefined;
    }
    (async () => {
      try {
        setLoading(true);
        const rawResponse = await loadData(
          searchTerm,
          predicates,
          expandedColumns,
        );
        const response = extractDataFromResponse(rawResponse);
        const optionsData = loadDataAndPrepareOptions({
          response,
          additionalItem,
          t,
        });

        if (active) {
          setOptions(optionsData);
          setLoading(false);
        }
      } catch {
        if (!active) return;
        setOptions([]);
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [
    additionalItem,
    doload,
    expandedColumns,
    loadData,
    predicates,
    searchTerm,
    t,
  ]);

  React.useEffect(() => {
    if (!open) {
      setOptions(undefined);
    }
  }, [open]);

  return (
    <Autocomplete
      id={id || 'asynchronous-demo'}
      //Material-UI: A component is changing the uncontrolled open state of Autocomplete to be controlled.
      //Elements should not switch from uncontrolled to controlled (or vice versa).
      open={open || false}
      noOptionsText={noOptionsText || 'No Options'}
      disabled={disabled}
      onOpen={() => {
        !disableClearable && setOptions(undefined);
        !selectOnFocus && setSearchTerm(null);
        setOpen(true);
        !!setSomeOpen && setSomeOpen(true);
        !!props.onPickerOpen && props.onPickerOpen();
      }}
      onClose={() => {
        setSearchTerm(null);
        setOpen(false);
        !!setSomeOpen && setSomeOpen(false);
        setLoading(false);
      }}
      onChange={(event, value, reason, details) => {
        if (
          value == null ||
          ((value as T)?.Id !== undefined &&
            (value as unknown as IDisableable)?.disabled !== true)
        ) {
          setSearchTerm(null);
          onChange?.(value as T);
          setSelected(value !== undefined && value !== null);
        }
      }}
      multiple={false}
      onInputChange={(event, newInputValue, reason) => {
        setOptions(undefined);
        if (reason === 'input') {
          let val =
            !!newInputValue && newInputValue !== null
              ? newInputValue.trimStart()
              : newInputValue;
          setSearchTerm(val);
          if (handleInputChange !== undefined) {
            handleInputChange(val);
          }
        }
      }}
      getOptionSelected={
        getOptionSelected ??
        ((option, value) =>
          (option as T)?.Id !== undefined &&
          (value as T)?.Id !== undefined &&
          (option as T)?.Id === (value as T)?.Id)
      }
      getOptionDisabled={option =>
        (option as unknown as IDisableable)?.disabled ?? false
      }
      getOptionLabel={option => {
        if (!!getOptionLabelName) {
          return getOptionLabelName(option);
        } else {
          return option.Name ?? t(translations.NA);
        }
      }}
      renderOption={(option, state) =>
        !!customRenderOption
          ? customRenderOption(option, state)
          : highlightSearchTerm(
              !!renderOptionName ? renderOptionName(option) : option.Name,
              state.inputValue,
            )
      }
      options={(options as OptionType<T>[]) ?? []}
      filterSelectedOptions={filterSelectedOptions}
      /**
       *  filter is needed to be disabled in order to show server filterd items that might not contain the searchterm as is
       *  or to show any other additional label like "showing x records, please search fo more"
       * */
      filterOptions={filterOptions}
      loading={loading}
      value={value ?? null}
      // classes={{
      //   root: clsx(classes.root, showShortView ? 'shortView' : undefined),
      //   tag: classes.tag,
      //   tagSizeSmall: classes.tagSizeSmall,
      //   inputRoot: classes.inputRoot,
      //   input: classes.input,
      //   inputFocused: classes.inputFocused,
      //   endAdornment: classes.endAdornment,
      //   clearIndicator: classes.clearIndicator,
      //   popupIndicator: classes.popupIndicator,
      //   popupIndicatorOpen: classes.popupIndicatorOpen,
      //   paper: classes.paper,
      //   listbox: classes.listbox,
      //   loading: classes.loading,
      //   noOptions: classes.noOptions,
      //   option: classes.option,
      //   groupLabel: classes.groupLabel,
      //   groupUl: classes.groupUl,
      // }}
      fullWidth={fullWidth}
      className={clsx('autocomplete', className, {
        shortView: showShortView && !isMobile,
      })}
      disableClearable={disableClearable}
      onBlur={props?.onBlur}
      size={size === 'small' ? 'small' : 'medium'}
      popupIcon={<Icon icon="chevron-down" size="xs" />}
      closeIcon={<Icon icon="circle-xmark" size="xs" />}
      freeSolo={freeSolo}
      selectOnFocus={selectOnFocus || (isMobile ? false : true)}
      renderInput={params => (
        <BaseTextField
          {...(props.innerProps ?? {})}
          {...params}
          label={label}
          fullWidth={fullWidth}
          variant={variant || 'standard'}
          name={props.name}
          placeholder={props.placeholder ?? t(translations.AllValues)}
          error={props.error}
          select={select}
          required={required}
          helperText={props.helperText}
          size={size}
          className={props.innerClassName}
          //onFocus={event => event.target.select()}
          autoFocus={autoFocus}
          onFocus={event => {
            clearTimeout(timerRef.current);
            if (
              autoFocus &&
              value !== null &&
              value !== undefined &&
              selectOnFocus &&
              firstMounted
            ) {
              timerRef.current = setTimeout(handleSelectText, 100, event);
            }
          }}
          inputProps={{
            ...params.inputProps,
            ...inputProps,
            className: 'autocompleteInput',
            'aria-label': `${
              label ? undefined : ariaLabel ? ariaLabel : 'search input'
            }`,
            // 'aria-labelledby': `${
            //   label ? undefined : id ? `${id}_label` : undefined
            // }`,
            title: title,
          }}
          InputProps={{
            //autoFocus: firstOpen || autoFocus,
            className: clsx(
              'autocompleteInputRoot',
              { shortView: showShortView && !isMobile },
              params.InputProps.className,
            ),
            ...omit(params.InputProps, 'className'),
            endAdornment: (
              <React.Fragment>
                {props.error}
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={params.size === 'small' ? 12 : 16}
                    className="autocomplete"
                  />
                ) : info ? (
                  <InfoIcon
                    title={info}
                    className={clsx('info', {
                      infoWidthPlus: hasSelected && !disableClearable,
                      infoWidthMinus: !hasSelected || disableClearable,
                    })}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      autoHighlight={
        Boolean(searchTerm) ||
        autoHighlight ||
        disableClearable ||
        options?.length === 1
      }
      autoComplete={autoComplete}
      autoSelect={
        autoSelect ||
        Boolean(searchTerm) ||
        autoHighlight ||
        options?.length === 1
      }
      blurOnSelect={blurOnSelect}
      clearOnEscape={clearOnEscape}
      includeInputInList={includeInputInList}
      disableListWrap={disableListWrap}
      openOnFocus={openOnFocus}
      disableCloseOnSelect={disableCloseOnSelect}
      ListboxProps={{
        ...ListboxProps,
        className: clsx('autocompleteList', `listSize${capitalize(listSize)}`),
      }}
    />
  );
}
