import { SiteMapNode } from 'types/SiteMapSettings';

/**
 * Filters out only nodes that should be visible in the menu
 * @param item
 * @returns
 */
export function filterVisible(item: SiteMapNode) {
  return (
    item.HideReact === false &&
    // for whatever reason the Hide prop is set to True on admin settings menus, but these should be shown in the separate hover/actions popup menu
    (item.IsAdminSettings === true || item.Hide === false)
  );
}
