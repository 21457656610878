import i18next from 'i18next';
import { assign, mapValues } from 'lodash';
import { isDefined } from './isDefined';
import {
  ICustomModelError,
  IModelError,
  IModelStateDictionary,
} from 'types/Modelstate';

export function getErrorMessages(
  modelState: IModelStateDictionary,
): Array<string> {
  const result = Object.values(modelState)
    .flatMap(item => item.Errors)
    .map(toErrorMessage)
    .filter(isDefined);
  return result;
}
function toErrorMessage(modelError: IModelError): string | undefined {
  if ((modelError as ICustomModelError).Key !== undefined) {
    try {
      const value: Record<string, any> = mapValues(
        (modelError as ICustomModelError).Value,
        /*
           date/time values should appear formatted in interpolated string instead of transfer format (ISO)
           todo: streamline into i18next formatter in order to avoid unnecessary processing & make it available inother parts of the system
           */
        propertyValue => propertyValue,
        //    {
        //   if (typeof propertyValue === 'string') {
        //     const parsedDate = dateUtils.tryParseISO(propertyValue);
        //     if (parsedDate != null) {
        //       return dateUtils.shortDateTimeFormat(parsedDate);
        //     }
        //   }
        //   return propertyValue;
        // },
      );

      const key = (modelError as ICustomModelError).Key;
      const defaultValue = modelError.ErrorMessage ?? undefined;
      const message = i18next.t(key, defaultValue, value);
      return message;
    } catch (error) {
      console.error(error);
    }
  } else if (modelError.ErrorMessage !== null) {
    return modelError.ErrorMessage;
  } else if (modelError.Exception !== null) {
    return modelError.Exception.Message;
  }
}

export function addModelError(
  modelState: IModelStateDictionary,
  errormessage: string,
) {
  const modelErrors =
    modelState['']?.Errors ??
    (modelState[''] = {
      Value: null,
      Errors: [],
    });
  modelErrors.push({ ErrorMessage: errormessage, Exception: null });
}
export function addModelStateDictionary(
  a: IModelStateDictionary,
  b: IModelStateDictionary,
) {
  // todo: merge per property errors
  assign(a, b);
}
