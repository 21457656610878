import {
  ConvertModelToEntityRequestStatusesDetails,
  RequestStatusesDetailsQStringParameters,
  RequestStatusesDetailsState,
  RequestStatusesState,
} from './types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { IRequestStatusesDto } from 'app/pages/RequestStatuses/RequestStatusesPage/IRequestStatusesDto';
import { requestStatusesDetailsSaga } from './saga';

export const initialState: RequestStatusesState = {
  processing: false,
  createState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
  updateState: {
    data: undefined,
    processing: undefined,
    completed: undefined,
    hasErrors: undefined,
  },
};

const slice = createSlice({
  name: 'requestStatusesDetail',
  initialState,
  reducers: {
    initRequestStatusesDetails(
      state,
      action: PayloadAction<RequestStatusesDetailsQStringParameters>,
    ) {
      state.processing = true;
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initUpdateRequestStatusesDetails(
      state,
      action: PayloadAction<RequestStatusesDetailsQStringParameters>,
    ) {
      state.processing = true;
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    initRequestStatusesDetails_Success(
      state,
      action: PayloadAction<IRequestStatusesDto>,
    ) {
      state.processing = false;
      state.createState.data = ConvertModelToEntityRequestStatusesDetails(
        action.payload,
      );
    },
    initUpdateRequestStatusesDetails_Success(
      state,
      action: PayloadAction<IRequestStatusesDto>,
    ) {
      state.processing = false;
      state.updateState.data = ConvertModelToEntityRequestStatusesDetails(
        action.payload,
      );
    },
    initRequestStatusesDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    initUpdateRequestStatusesDetails_Error(state, action: PayloadAction<any>) {
      state.processing = false;
    },
    //====================================================================
    resetUpdateRequestStatusesDetailsState(state, action: PayloadAction) {
      state.updateState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    resetCreateRequestStatusesDetailsState(state, action: PayloadAction) {
      state.createState = {
        data: undefined,
        processing: undefined,
        completed: undefined,
        hasErrors: undefined,
      };
    },
    //====================================================================
    updateRequestStatusesDetails(
      state,
      action: PayloadAction<{
        current: RequestStatusesDetailsState;
        original: RequestStatusesDetailsState | undefined;
      }>,
    ) {
      state.updateState.processing = true;
      state.updateState.completed = undefined;
      state.updateState.hasErrors = undefined;
    },
    updateRequestStatusesDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.updateState.processing = false;
      state.updateState.hasErrors = action.payload.hasErrors;
      state.updateState.completed = true;
    },
    updateRequestStatusesDetails_Error(state, action: PayloadAction<any>) {
      state.updateState.processing = false;
      state.updateState.completed = true;
      state.updateState.hasErrors = true;
    },
    //====================================================================
    createRequestStatusesDetails(
      state,
      action: PayloadAction<RequestStatusesDetailsState>,
    ) {
      state.createState.processing = true;
      state.createState.completed = undefined;
      state.createState.hasErrors = undefined;
    },
    createRequestStatusesDetails_Success(
      state,
      action: PayloadAction<{
        hasErrors: boolean;
      }>,
    ) {
      state.createState.processing = false;
      state.createState.hasErrors = action.payload.hasErrors;
      state.createState.completed = true;
    },
    createRequestStatusesDetails_Error(state, action: PayloadAction<any>) {
      state.createState.processing = false;
      state.createState.completed = true;
      state.createState.hasErrors = true;
    },
  },
});

export const { actions: requestStatusesDetailsActions } = slice;

export const useRequestStatusesDetailsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: requestStatusesDetailsSaga });
  return { actions: slice.actions };
};
